import PropTypes from 'prop-types';

import { TRANSLATION_SOURCE_TYPE } from "constants/translation.constants";

export default PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    address: PropTypes.string,
    tel: PropTypes.string,
    email: PropTypes.string,
    ticketLanguageCode: PropTypes.string,
    enabled: PropTypes.bool,
    logoId: PropTypes.number,
    dst: PropTypes.oneOf([0,-1, 1]),
    translations: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string,
        translations: PropTypes.arrayOf(PropTypes.shape({
            languageCode: PropTypes.string,
            text: PropTypes.string,
            source: PropTypes.oneOf(Object.values(TRANSLATION_SOURCE_TYPE))
        }))
    }))
})