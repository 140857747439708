import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import useFormat from 'hooks/useFormat';

import { WALLET_TYPE } from 'constants/wallet.constants';

import currencyType from 'types/profile/currency.type';

/** Header Mobile Lite Mode Wallet component */

const MobileLiteModeWallet = ({
    currency,
    currencies
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const makeAmount = () => {
        let amount = 0;

        if (currency) {
            const currencyData = currencies.find(b => b.currencyCode === currency?.toUpperCase());
            if(!currencyData) return amount;
            const wallet = currencyData.balances.find(b => b.type === WALLET_TYPE.BALANCE);
            if(!wallet) return amount;
            return wallet?.amount ?? 0
        }
        return amount;
    }

    return (
        <div className='rt--header-lite-mode-wallet rt--pl-14 rt--pr-14 rt--flex rt--flex-col rt--ml-14'>
            <b className='rt--title rt--font-smallest rt--font-regular'>{t("backoffice.common.balance")}</b>
            <span className='rt--title rt--font-small rt--font-medium'>{`${formatAmount(makeAmount(), currency)}`}</span>
            <span className='rt--title rt--font-small rt--font-medium'>{`${currency ?? ""}`}</span>
        </div>
    )

}

/** MobileLiteModeWallet propTypes
    * PropTypes
*/
MobileLiteModeWallet.propTypes = {
    /** Redux state property, the current user currency */
    currency: PropTypes.string,
    /** Redux state property, Account currencies */
    currencies: PropTypes.arrayOf(currencyType)
}

const mapStateToProps = state => {
    return {
        currency: state.profile.currency,
        currencies: state.profile.currencies
    }
}

export default connect(mapStateToProps, null)(MobileLiteModeWallet);
