import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';

import EntityActions from "components/common/entityActions"
import SetPasswordComponent from "../betshopManager-setPassword.component";

import { changeBetshopManagerState } from 'store/actions/dashboard/retail/betshopManagers/betshopManagers.action';

import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { USER_STATE } from "constants/user.constants";

import betshopManagerGeneralInfoType from "types/betshopManager/generalInfo.type";

/** Betshop Manager Actions Button Component */
const BetshopManagerActionsComponent = ({
    generalInfo,
    changeBetshopManagerState
}) => {

    const { t } = useTranslation();
    const searchParams = useParams();

    /** Flag to show/hide set password popup */
    const [showSetPasswordPopup, setShowSetPasswordPopup] = useState(false);

    /** Actions list */
    const actions = [
         /** Set Password */
         {
            key: "setPassword",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.setPassword'),
            icon: "icon-password",
            onClick: () => setShowSetPasswordPopup(true),
            disabled: !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER, action: PERMISSION_ACTION.MODIFY })
        },

        /** Block/Unblock */
        
        {
            key: "block",
            buttonType: generalInfo.state === USER_STATE.BLOCKED ? "primary" : "secondary",
            title: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            buttonClassName: generalInfo.state === USER_STATE.BLOCKED ? "rt--button-secondary" : "rt--button-danger",
            onClick: () => {
                changeBetshopManagerState({
                    id: searchParams.id,
                    enabled: generalInfo.state === USER_STATE.BLOCKED
                })
            },
            icon: "icon-block",
            isConfirmation: true,
            confirmationMessage: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.betshopmanagers.doYouWanttoUnblockBetshopManager') : t('backoffice.betshopmanagers.doYouWanttoBlockBetshopManager'),
            disabled: !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER, action: PERMISSION_ACTION.MODIFY })
        }
    ]

    return (
        <Fragment>
            <EntityActions 
                actions={actions}
            />

            {
                showSetPasswordPopup && (
                    <SetPasswordComponent
                        onClose={() => setShowSetPasswordPopup(false)}
                    />
                )
            }
        </Fragment>
        
    )
}

/** BetshopManagerActionsComponent propTypes
    * PropTypes
*/
BetshopManagerActionsComponent.propTypes = {
    /** Redux state, represents the general info of current editing betshop Manager  */
    generalInfo: betshopManagerGeneralInfoType,
    /** Redux action to block/unblock betshop manager */
    changeBetshopManagerState: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        changeBetshopManagerState: data => {
            dispatch(changeBetshopManagerState(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.betshopManagers.edit.general
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BetshopManagerActionsComponent)
