import {useTranslation} from "react-i18next";
import {Checkbox, Form, Radio} from "antd";
import {PROJECT_MODE} from "constants/common.constants";
import React, {useEffect} from "react";
import {PROJECT_TYPE} from "constants/project.constants";
import PropTypes from "prop-types";

const LightModeOptions = ({projectTypeChoices, formInstance}) => {
    const {t} = useTranslation();

    const mode = Form.useWatch('mode', formInstance);
    const areCheckboxesDisabled = mode === PROJECT_MODE.LITE;

    useEffect(() => {
        if (mode === PROJECT_MODE.LITE) {
            formInstance.setFieldValue('type', []);
        } else {
            formInstance.setFieldValue('type', [PROJECT_TYPE.RETAIL_SYSTEM]);
        }
    }, [mode]);

    return (
        <Form.Item name="mode" label={t('backoffice.projects.mode')}>
            <Radio.Group className="rt--width-full">
                <div className='rt--payment-section'>
                    <Radio value={PROJECT_MODE.STANDARD}>
                        {t('backoffice.projects.standardVersion')}
                    </Radio>

                    <div className='rt--payment-section-sub'>
                        <Form.Item
                            name="type"
                            className='rt--form-item-inline rt--form-item-without-margin'
                        >
                            <Checkbox.Group
                                disabled={areCheckboxesDisabled}
                                options={projectTypeChoices()}
                            />
                        </Form.Item>
                    </div>
                </div>

                <div className='rt--payment-section'>
                    <Radio value={PROJECT_MODE.LITE}>
                        {t('backoffice.projects.liteVersion')}
                    </Radio>
                </div>
            </Radio.Group>
        </Form.Item>
    );
}

LightModeOptions.propTypes = {
    projectTypeChoices: PropTypes.func.isRequired,
    formInstance: PropTypes.object.isRequired,
}

export default LightModeOptions;
