//#region react
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
//#endregion

//#region libraries
import { Col, Row, Spin } from 'antd';
//#endregion

//#region actions
import {
    getEposSettings,
    saveEposTranslations,
    saveEposSettings
} from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action';
//#endregion

//#region components
import SubTabTableDashboardFormLayout from "components/layouts/tab/subtab/form";
import Table from "components/common/table";
//#endregion

//#region hooks
import useDate from 'hooks/useDate';
//#endregion

//#region utils
import {
    isMobile,
    generateTelegramLink,
    generateWhatsAppLink,
    hasValue,
} from 'utils/common';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { hasPermission } from 'utils/permissions';
//#endregion

//#region constants
import { SOCIAL_MEDIA_TYPE } from "constants/payments.constants"
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import {
    getContactsTableColumns,
    getScheduleTableColumns,
    getTranslationsTableColumns
} from './columns';
//#endregion

//#region types
import eposSettingsType from 'types/agent/eposSettings.type';
//#endregion

/** Construct data for contacts table */
const constructContactsTableRowsData = data => {
    const tableRowsData = [];

    Object.values(SOCIAL_MEDIA_TYPE).forEach(socialMediaType => {
        const link = (data ?? []).find(c => c.socialMediaType === socialMediaType)?.linkInfo ?? "";
        tableRowsData.push({
            socialMediaType,
            linkInfo: link,
            channelLink: link ? socialMediaType === SOCIAL_MEDIA_TYPE.TELEGRAM ? generateTelegramLink(link) : socialMediaType === SOCIAL_MEDIA_TYPE.WHATSAPP ? generateWhatsAppLink(link) : "" : "-"
        })
    })

    return tableRowsData;
}

/** Construct data for schedule table */
const constructScheduleTableRowsData = data => {
    const tableRowsData = [];

    for (let key in data) {
        tableRowsData.push({
            key,
            dayOfWeek: key,
            workingHours: data[key]
        })
    }

    return tableRowsData;
}

/** Construct data for translations table */
const constructTranslationsTableRowsData = (translations) => {
    let tableRowsData = [];

    const cityKey = translations.find(k => k.key.startsWith("eposcity_"));
    const addressKey = translations.find(k => k.key.startsWith("eposaddress_"));

    if (!cityKey || !addressKey) {
        return tableRowsData;
    }

    const cityKeyTranslations = cityKey.translations || [];
    const addressKeyTranslations = addressKey.translations || [];

    const languages = cityKeyTranslations.map(t => t.languageCode);

    languages.forEach(lang => {
        tableRowsData.push({
            cityKey: cityKey.key,
            addressKey: addressKey.key,
            languageCode: lang,
            city: cityKeyTranslations.find(t => t.languageCode === lang)?.text ?? "",
            address: addressKeyTranslations.find(t => t.languageCode === lang)?.text ?? ""
        })
    })
    return tableRowsData;
}

const constructEposPaymentScheduleRequestBody = ({
    timeRange,
    changedDay,
    weekSchedule,
    dateService
}) => {
    const [openTime, closeTime] = timeRange;

    const openTimeStr = dateService.toString(openTime, "H:m");
    const closeTimeStr = dateService.toString(closeTime, "H:m");

    const [openTimeHour, openTimeMinute] = openTimeStr.split(":");
    const [closeTimeHour, closeTimeMinute] = closeTimeStr.split(":");

    const weekDays = {
        ...weekSchedule,
        [changedDay]: {
            ...weekSchedule[changedDay],
            openTime: {
                hour: openTimeHour,
                minute: openTimeMinute,
            },
            closeTime: {
                hour: closeTimeHour,
                minute: closeTimeMinute,
            }
        }
    }

    return weekDays;
}

const SCHEDULE_TABLE_ROW_UNIQUE_KEY = "dayOfWeek";
const TRANSLATION_TABLE_ROW_UNIQUE_KEY = "languageCode";
const CONTACTS_TABLE_ROW_UNIQUE_KEY = "socialMediaType";

const EPOSconfigs = ({
    getEposSettings,
    eposSettings,
    isLoading,
    saveEposTranslations,
    saveEposSettings
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const { dateService } = useDate();

    const isMobileDevice = isMobile();

    const scheduleTableRowsData = hasValue(eposSettings.workingSchedule)
        ? constructScheduleTableRowsData(eposSettings.workingSchedule)
        : [];

    const translationsTableRowsData = eposSettings.translations.length > 0
        ? constructTranslationsTableRowsData(eposSettings.translations)
        : [];

    const contactsTableRowsData = constructContactsTableRowsData(eposSettings.contacts)

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_PAYMENT,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleScheduleFieldChange = ({ timeRange, dayOfWeek }) => {
        saveEposSettings({
            id: searchParams.id,
            workingSchedule: {
                weekDays: constructEposPaymentScheduleRequestBody({
                    timeRange,
                    changedDay: dayOfWeek,
                    weekSchedule: eposSettings.workingSchedule,
                    dateService
                })
            },
            contacts: eposSettings.contacts ?? []
        });
    }

    const handleContactsFieldChange = ({ contacts }) => {
        saveEposSettings({
            id: searchParams.id,
            workingSchedule: {
                weekDays: {
                    ...eposSettings.workingSchedule
                }
            },
            contacts: contacts
        });
    }

    const handleTranslationFieldsChange = ({ text, type, languageCode, key }) => {
        saveEposTranslations(
            {
                id: searchParams.id,
                type,
                languageCode,
                text,
            },
            key
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns: scheduleTableColumns } = tableColumnsCreator({
        mainColumns: getScheduleTableColumns,
        additionalProps: {
            havePermissionForEdit: hasModifyPermission,
            onScheduleFieldChange: handleScheduleFieldChange,
            dateService
        }
    })

    const { mainTableColumns: contactsTableColumns } = tableColumnsCreator({
        mainColumns: getContactsTableColumns,
        additionalProps: {
            eposSettings,
            havePermissionForEdit: hasModifyPermission,
            onContactsFieldChange: handleContactsFieldChange,
        }
    })

    const { mainTableColumns: translationsTableColumns } = tableColumnsCreator({
        mainColumns: getTranslationsTableColumns,
        additionalProps: {
            havePermissionForEdit: hasModifyPermission,
            onTranslationFieldsChange: handleTranslationFieldsChange,
        }
    })

    //#endregion

    // Get Epos Payment Settings
    useEffect(() => {
        getEposSettings(searchParams.id);
    }, [])

    return (
        <SubTabTableDashboardFormLayout>
            <Spin spinning={isLoading}>
                <Row gutter={isMobileDevice ? [0, 0] : [40, 0]}>
                    <Col xs={24} sm={24} xl={10}>
                        <h4
                            className={
                                'rt--subtab-table-layout-title rt--title rt--mb-16 rt--font-bold rt--font-biger ' +
                                (!isMobileDevice ? "rt--mt-8" : "")
                            }
                        >
                            {t("backoffice.payments.scheduleOfWork")}
                        </h4>
                        <div className='rt--mb-24'>
                            <Table
                                columns={scheduleTableColumns}
                                data={scheduleTableRowsData}
                                uniqueKey={SCHEDULE_TABLE_ROW_UNIQUE_KEY}
                                noPagination={true}
                                mobileLayoutVertical={true}
                                disableFullView={true}
                            />
                        </div>
                    </Col>

                    <Col xs={24} sm={24} xl={14} >
                        <h4
                            className={
                                'rt--subtab-table-layout-title rt--title rt--mb-16 rt--font-bold rt--font-biger ' +
                                (!isMobileDevice ? "rt--mt-8" : "")
                            }
                        >
                            {t("backoffice.payments.contactChannel")}
                        </h4>
                        <div className='rt--mb-24'>
                            <Table
                                columns={contactsTableColumns}
                                data={contactsTableRowsData}
                                uniqueKey={CONTACTS_TABLE_ROW_UNIQUE_KEY}
                                noPagination={true}
                                mobileLayoutVertical={true}
                                disableFullView={true}
                            />
                        </div>
                    </Col>
                </Row>

                <h4 className='rt--subtab-table-layout-title rt--title rt--mb-16 rt--font-bold rt--font-biger'>
                    {t("backoffice.payments.translations")}
                </h4>
                <Row>
                    <Col span={24}>
                        <Table
                            columns={translationsTableColumns}
                            data={translationsTableRowsData}
                            uniqueKey={TRANSLATION_TABLE_ROW_UNIQUE_KEY}
                            mobileLayoutVertical={true}
                            noPagination={true}
                            disableFullView={true}
                        />
                    </Col>
                </Row>
            </Spin>
        </SubTabTableDashboardFormLayout>
    );
}

/** EPOSconfigs propTypes
    * PropTypes
*/
EPOSconfigs.propTypes = {
    /** Redux action to get agent epos payment settings */
    getEposSettings: PropTypes.func,
    /** Redux action to save EPOS translations */
    saveEposTranslations: PropTypes.func,
    /** Redux action to save EPOS payment settings */
    saveEposSettings: PropTypes.func,
    /** Redux state property, current editing agent epos payment settings */
    eposSettings: eposSettingsType,
    /** Redux state property, is true when agent payment epos settings are loading */
    isLoading: PropTypes.bool,
}

const mapDispatchToProps = dispatch => (
    {
        getEposSettings: id => {
            dispatch(getEposSettings(id));
        },

        saveEposTranslations: (translations, key) => {
            dispatch(saveEposTranslations(translations, key));
        },

        saveEposSettings: settings => {
            dispatch(saveEposSettings(settings));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.agents.isLoading,
        eposSettings: state.agents.edit.payment.eposSettings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EPOSconfigs);