import React, { Fragment, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Dropdown from "components/common/dropdown";

import { isFranchisingMode, binaryToFlags } from "utils/common";
import { getUser } from 'utils/auth';
import { hasPermission } from 'utils/permissions';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';

import { WALLET_TRANSACTION_TYPE } from "constants/wallet.constants";
import { USER_ROLE } from "constants/user.constants";
import { PAYMENT_METHODS } from 'constants/project.constants';

import useProjectType from "hooks/useProjectType";

import { TRANSFER_OPERATION_GROUP, useTransfer } from 'components/common/transfer';

/** Transfer dropdown component on Header */

const TransferDropdown = () => {

    const { t } = useTranslation();

    const { show: showTransfer} = useTransfer()

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const { hasLiteMode } = useProjectType();

    const paymentTypes = binaryToFlags(Object.values(PAYMENT_METHODS), getUser()?.paymentType ?? 0 );

    const isWalletConfigNonRelated = getUser().isStandartPaymentWithRelatedWallets === false;

    /** Function, fires on dropdown item click
       * @function
       * @param {number} group
       * @memberOf TransferDropdown
   */
    const handleDropdownChange = value => {
        const transferData = {};
        value = Number(value);
        if(
            value === WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT ||
            value === WALLET_TRANSACTION_TYPE.AGENT_CREDIT ||
            value === WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT
        ){
            transferData.transactionType = value;
        } else {
            transferData.group = value;
        }
        showTransfer(transferData);
        setDropdownVisible(false);
    }

    const canDeposit = (
        paymentTypes.includes(PAYMENT_METHODS.EPOS) ||
        hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW }) ||
        hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW }) ||
        (
            hasPermission({ resource: PERMISSION_RESOURCE.PLAYER_GENERALINFO, action: PERMISSION_ACTION.VIEW }) &&
            !isFranchisingMode() &&
            getUser()?.role === USER_ROLE.AGENT
        )
    )

    const menuItems = (() => {
        const res = [];

        if ( canDeposit ) {
            res.push(
                {
                    key: TRANSFER_OPERATION_GROUP.DEPOSIT,
                    label: (
                        <span className='rt--title rt--font-normal rt--font-regular'>
                            {t("backoffice.wallet.deposit")}
                        </span>
                    )
                }
            )
        }

        res.push(
            {
                key: TRANSFER_OPERATION_GROUP.WITHDRAWAL,
                label: (
                    <span className='rt--title rt--font-normal rt--font-regular'>
                        {t("backoffice.wallet.withdraw")}
                    </span>
                )
            }
        )

        if (!hasLiteMode && hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW })) {
            res.push(
                {
                    key: WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT,
                    label: (
                        <span className='rt--title rt--font-normal rt--font-regular'>
                            {t("backoffice.wallet.payCommission")}
                        </span>
                    )
                }
            )
        }

        if (
            !hasLiteMode &&
            hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW }) &&
            !isFranchisingMode() &&
            !isWalletConfigNonRelated
        ) {
            res.push(
                {
                    key: WALLET_TRANSACTION_TYPE.AGENT_CREDIT,
                    label: (
                        <span className='rt--title rt--font-normal rt--font-regular'>
                        {t("backoffice.wallet.giveCredit")}
                    </span>
                    )
                }
            )
        }

        if (
            hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW }) &&
            isFranchisingMode()
        ) {
            res.push(
                {
                    key: WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT,
                    label: (
                        <span className='rt--title rt--font-normal rt--font-regular'>
                            {t("backoffice.wallet.limitAdjustment")}
                        </span>
                    )
                }
            )
        }

        return res
    })();

    return (
        <Fragment>
            {
                !hasLiteMode ? (
                    <Dropdown
                        menu={{
                            onClick: e => handleDropdownChange(e ? e.key : null),
                            items: menuItems
                        }}
                        disabled={false}
                        popupVisible={dropdownVisible}
                        onVisibleChange={visible => setDropdownVisible(visible)}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        overlayClassName="rt--transfer-dropdown"
                        placement="bottom"
                    >

                        <div
                            className='rt--header-actions rt--header-actions-content-icon rt--header-item rt--pr-16 rt--mr-16 rt--flex rt--align-center'
                        >
                            <i className='icon-transfer rt--font-bigest' />
                        </div>

                    </Dropdown>
                ) : (
                    <div className='rt--mr-16 rt--flex rt--align-center'>
                        {
                            canDeposit && (
                                <div
                                    className='rt--header-actions rt--pl-8 rt--pr-8 rt--mr-4 rt--flex rt--align-center rt--cursor-pointer'
                                    onClick={() => handleDropdownChange(TRANSFER_OPERATION_GROUP.DEPOSIT)}
                                >
                                    <i className='icon-deposit rt--font-bigest' />
                                </div>
                            )
                        }
                        
                        <div
                            className='rt--header-actions rt--pl-8 rt--pr-8 rt--ml-4 rt--flex rt--align-center rt--cursor-pointer'
                            onClick={() => handleDropdownChange(TRANSFER_OPERATION_GROUP.WITHDRAWAL)}
                        >
                            <i className='icon-withdrawal rt--font-bigest' />
                        </div>
                    </div>
                )
            }
            
        </Fragment>
    )
}


export default TransferDropdown;

