import i18n from "translations/config";

import { binaryToFlags, isFranchisingMode } from "utils/common";

import { PROJECT_TYPE } from "constants/project.constants";

const PROJECT_TYPE_TRANSLATION_RESOURCES = {
    [PROJECT_TYPE.AGENT_SYSTEM]: "backoffice.projects.franchising",
    [PROJECT_TYPE.RETAIL_SYSTEM]: "backoffice.projects.retail",
}

const renderFeatures = value => {
    let features = binaryToFlags(Object.values(PROJECT_TYPE), Number(value));

    if (isFranchisingMode()) {
        features = features.filter(f => f !== PROJECT_TYPE.RETAIL_SYSTEM)
    } else {
        features = features.filter(f => f !== PROJECT_TYPE.AGENT_SYSTEM)
    }

    if (features.length === 0) {
        return "-"
    }

    features = features.map(value => {
        return (
            PROJECT_TYPE_TRANSLATION_RESOURCES[value]
                ? i18n.t(PROJECT_TYPE_TRANSLATION_RESOURCES[value])
                : ""
        )
    })

    return features.join(", ");
}

export const getTableColumns = ({isFranchisingMode}) => {
    const columns = [
        {
            title: "backoffice.projects.name",
            dataIndex: "name",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
            render: (value, record) => record.isTesting ? `${value} (Test)` : value,
        },
        {
            title: "backoffice.projects.features",
            dataIndex: "type",
            mobileLevel: 4,
            render: value => renderFeatures(value),
        },
        {
            title: "backoffice.projects.platform",
            dataIndex: "platform",
            mobileLevel: 5,
            render: value => value || "-",
        },
        {
            title: "backoffice.projects.gateway",
            dataIndex: "gateway",
            mobileLevel: 6,
            render: value => value || "-",
        },
        {
            title: "backoffice.projects.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.projects.createdBy",
            dataIndex: "createdBy",
            sorter: true,
        },
        {
            title: "backoffice.projects.lastUpdatedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.projects.lastUpdatedBy",
            dataIndex: "modifiedBy",
            sorter: true,
            mobileLevel: 3,
            render: value => value || "-",
        }
    ];

    if (!isFranchisingMode()) {
        columns.push({
            title: "backoffice.projects.currency",
            dataIndex: "currencies",
            mobileLevel: 7,
            multi: true,
        });
    }

    return columns;
};
