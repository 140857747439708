import { constructArrayForGivenRanges } from "utils/common"
import { isMobile } from "utils/common";

import { TIME_PICKER_MODES } from "./constants";

export const getShowTimeOptionValue = ({ showTime, timePickerMode, dateService }) => {

    // let today = dateService.getNow();
    // today = dateService.setUnit(today, "hour", 0);
    // today = dateService.setUnit(today, "minute", 0);

    const modes = {
        [TIME_PICKER_MODES.DEFAULT]: {
            format: dateService.getFormat(true, false),
        },
    
        [TIME_PICKER_MODES.ONLY_HOURS]: {
            hideDisabledOptions: true,
            disabledMinutes: () => constructArrayForGivenRanges({ start: 1, end: 60 }),
            //defaultValue: [today, today],
        }
    }
    if (showTime) {
        return modes[timePickerMode];
    }

    if (isMobile()) {
        return { format: "HH:mm" };
    }

    return false; // time picker is not active
}