import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setCommissionPlansActionBefore, setCommissionPlansActionFinished } from './commissionPlans.action';

import { isMobile } from "utils/common";

import { 
    SET_COMMISSION_PLAN_AGENTS,
    SET_COMMISSION_PLAN_AGENTS_SORTING,
    SET_COMMISSION_PLAN_AGENTS_FILTERS
} from "../../../../actionTypes";

const setCommissionPlanAgents = (agents, add) => ({
    type: SET_COMMISSION_PLAN_AGENTS,
    payload: { agents, add }
})

export const setCommissionPlanAgentsSorting = sorting => ({
    type: SET_COMMISSION_PLAN_AGENTS_SORTING,
    payload: { sorting },
});

export const setCommissionPlanAgentsFilters = filters => ({
    type: SET_COMMISSION_PLAN_AGENTS_FILTERS,
    payload: { filters },
});


export const getCommissionPlanAgents = (id, nextPage) => {
    return (dispatch, getState) => {
        const agentsData = getState().commissionPlans.edit.agents;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? agentsData.sorting.page + 1 : agentsData.sorting.page;
        
        let params = {
            ...agentsData.sorting,
            ...agentsData.filters,
            id
        };

        if(page > 1 && agentsData.total <= (page - 1 ) * agentsData.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && agentsData.total === 0){
            return Promise.resolve();
        }

        dispatch(setCommissionPlansActionBefore());
        return axios({
            url: ApiUrls.GET_COMMISSION_PLAN_AGENTS,
            method: Methods.GET,
            params: params
        })
        .then(({data : {value: agents}}) => {
            dispatch(setCommissionPlanAgents(agents, isMobile() && page !== 1));
            if (nextPage)
                dispatch(setCommissionPlanAgentsSorting({ ...agentsData.sorting, page: page }));
            dispatch(setCommissionPlansActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansActionFinished());
        })
    }
}



