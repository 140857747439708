import React, {useRef, useState} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import MainDashboardLayout from "components/layouts/main";
import RadioButtons from "components/common/radioButtons";

import { USER_ROLE } from "constants/user.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { NETWORK_VIEW_MODE } from "pages/agentSystem/network/constants";

import { getUser } from "utils/auth";
import {isFranchisingMode, isMobile} from "utils/common";

import NetworkGraph from "pages/agentSystem/network/sections/networkGraph";
import NetworkTree from "pages/agentSystem/network/sections/networkTree";
import Search from "components/common/search";
import Tooltip from "components/common/tooltip";

/** Network Overview Page Component */
const NetworkComponent = ({
    globalProjectId
}) => {
    const [selectedView, setSelectedView] = useState(NETWORK_VIEW_MODE.TREE);
    const [agentSearchValue, setAgentSearchValue] = useState(null);
    const [playerSearchValue, setPlayerSearchValue] = useState(null);

    const agentSearchRef = useRef(null);
    const playerSearchRef = useRef(null);

    const { t } = useTranslation();

    const getLayoutProps = () => {
        let extraContent = null;
        const agentSearchProps = {
            inputRef: agentSearchRef,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AGENT,
                dependencies: [globalProjectId],
                actionParams: {
                    excludeAgent: getUser()?.role === USER_ROLE.ADMIN
                }
            },
            placeholder: (
                isFranchisingMode()
                    ? t("backoffice.agents.betshopOwnerUserName")
                    : t("backoffice.agents.agentUserName")
            ),
            onSearch: handleAgentSearch
        }
        const playerSearchProps = {
            inputRef: playerSearchRef,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PLAYER,
                dependencies: [globalProjectId],
                searchFieldName: "userNameOrId",
                autoGet: false,
                actionParams: {
                    excludeAgent: getUser()?.role === USER_ROLE.ADMIN
                }
            },
            placeholder: t("backoffice.agents.playerUserName"),
            onSearch: handlePlayerSearch
        };

        const header = {
            search: [],

            breadcrumbs: {
                items: [
                    { title: t('backoffice.menu.networkOverview') }
                ]
            },

            extra: (
                <RadioButtons
                    value={selectedView}
                    options={[
                        {
                            value: NETWORK_VIEW_MODE.TREE,
                            icon: (
                                <Tooltip title={t('backoffice.agents.vertical')} placement="bottom">
                                    <i className="icon-list" />
                                </Tooltip>
                            )
                        },
                        {
                            value: NETWORK_VIEW_MODE.GRAPH,
                            icon: (
                                <Tooltip title={t('backoffice.agents.horizontal')} placement="bottom">
                                    <i className="icon-graph" />
                                </Tooltip>
                            )
                        }
                    ]}
                    onChange={setSelectedView}
                />
            )
        };

        if (isMobile()) {
            if (selectedView === NETWORK_VIEW_MODE.TREE) {
                extraContent = {
                    content: (
                        <>
                            <Search {...agentSearchProps} isInForm />
                            {!isFranchisingMode() && <Search className="rt--mt-16" {...playerSearchProps} isInForm/>}
                        </>
                    )
                }
            } else {
                extraContent = {
                    content: (
                        <Search {...agentSearchProps} isInForm />
                    )
                }
            }
        } else {
            header.search.push(agentSearchProps);

            if (selectedView === NETWORK_VIEW_MODE.TREE && !isFranchisingMode()) {
                header.search.push(playerSearchProps);
            }
        }

        return { header, extraContent };
    }

    const handleAgentSearch = (value, item) => {
        if (!value) {
            setAgentSearchValue(null);
        } else {
            if (item) {
                setAgentSearchValue(item.id);

                setPlayerSearchValue(null);
                playerSearchRef.current?.clearValue();
            }
        }
    }

    const handlePlayerSearch = (value, item) => {
        if (!value) {
            setPlayerSearchValue(null);
        } else {
            if (item) {
                setPlayerSearchValue(item.id);

                setAgentSearchValue(null);
                agentSearchRef.current?.clearValue();
            }
        }
    }

    return (
        <MainDashboardLayout {...getLayoutProps()}>
            {selectedView === NETWORK_VIEW_MODE.TREE && <NetworkTree agentId={agentSearchValue} playerId={playerSearchValue} />}

            {selectedView === NETWORK_VIEW_MODE.GRAPH && <NetworkGraph searchValue={agentSearchValue} />}
        </MainDashboardLayout>
    );
};

/** NetworkComponent propTypes
 * PropTypes
*/
NetworkComponent.propTypes = {
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps
)(NetworkComponent);
