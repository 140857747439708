import { useEffect, useState } from "react";

import { getOperationalPieChartData } from "../api";

import { OPERATIONAL_DASHBOARD_FILTER_NAMES } from "../constants";

const useOperationalPieChart = (entityType, filters, updateLastUpdatedDate, isActive) => {
    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: {
            count: 0,
            data: {},
        },
    })

    useEffect(() => {
        if (isActive !== undefined && !isActive) {
            return;
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getOperationalPieChartData({
            entityType,
            filterType: filters[OPERATIONAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE],
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;

                updateLastUpdatedDate(payload.lastUpdateAt);
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                        chartData: {
                            data: payload.data,
                            count: payload.count,
                        }
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [entityType, filters, updateLastUpdatedDate, isActive])

    return chartState;

}

export default useOperationalPieChart;
