import React, {useEffect, useMemo, useState} from 'react';

import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import TotalsComponent from "components/common/totals";
import Filters from "pages/reports/newProductReport/sections/agent/filters.component";

import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import {NEW_PRODUCT_REPORT_AGENT} from "constants/pageName.constants";
import {AGENT_REPORT_TYPES, REPORT_TOTAL_TYPE} from "constants/reports.constants";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";

import {tableColumnsCreator} from "utils/tableColumnsCreator";
import dateService from "utils/date";
import {hasPermission} from "utils/permissions";

import useIncludedColumns from "hooks/useIncludedColumns";
import useFormat from "hooks/useFormat";

import {getTableColumns, getTableExpandColumns} from "pages/reports/newProductReport/sections/agent/columns";
import {getAgentsProductReport, getAgentsProductReportTotals} from "pages/reports/newProductReport/sections/agent/api";
import {getPossibleParentsName, isMobile} from "utils/common";
import useAutosuggestion from "hooks/useAutosuggestion";

const AgentComponent = ({ globalProjectId, currencies, networkLayers }) => {
    const [totals, setTotals] = useState({});
    const [data, setData] = useState({
        data: [],
        total: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState({
        page: 1,
        limit: 10
    });
    const [filters, setFilters] = useState({
        from: dateService.toISOString(dateService.yesterday(true)),
        to: dateService.toISOString(dateService.startOfTomorrow()),
        playerRegistrationDateFrom: null,
        playerRegistrationDateTo: null,
        currency: '',
        agentLevel: '',
        type: AGENT_REPORT_TYPES.DIRECT
    });

    const { t } = useTranslation();
    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: NEW_PRODUCT_REPORT_AGENT });
    const { formatAmount } = useFormat();

    const [ agents ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        autoGet: true,
        dependencies: [globalProjectId],
        actionParams: {
            excludeAgent: false
        }
    });

    const hasTotalsPermission = hasPermission({ resource: PERMISSION_RESOURCE.REPORTS_PRODUCTS_PERFORMANCE_TOTALS, action: PERMISSION_ACTION.VIEW });
    const hasExportPermission = hasPermission({ resource: PERMISSION_RESOURCE.REPORTS_PRODUCTS_PERFORMANCE, action: PERMISSION_ACTION.EXPORT });

    const filteredTotals = useMemo(() => {
        const currency = filters.currency.toLowerCase();

        if (currency && Object.keys(totals).length > 0 && totals[currency.toLowerCase()]) {
            return { [currency]: totals[currency] };
        }

        return totals;
    }, [filters, totals]);

    const {
        mainTableColumns,
        expandTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        const columnsDataObj = tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            expandColumns: getTableExpandColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                formatAmount
            }
        });

        if (!isMobile()) {
            columnsDataObj.columnsForExport = [
                ...columnsDataObj.columnsForExport,
                ...getTableExpandColumns({ formatAmount })
                    .map(column => (
                        {
                            title: t(column.title),
                            key: column.dataIndex,
                        }
                    )),
            ]
        }

        return columnsDataObj;
    }, [includedColumns, formatAmount]);

    useEffect(() => {
        setIsLoading(true);

        getAgentsProductReport(filters, sorting)
            .then((data) => {
                setData(data);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [filters, sorting, globalProjectId]);

    useEffect(() => {
        getAgentsProductReportTotals(filters)
            .then((data) => {
                setTotals(data);
            })
    }, [filters, globalProjectId]);

    const handleSearchChange = (searchValue) => {
        setFilters(state => ({
            ...state,
            agentUsernameOrId: searchValue,
        }));
    }

    const handleFiltersChange = (changedFilters) => {
        setFilters(changedFilters);
    }

    const handleSortingChange = (changedSorting) => {
        setSorting(changedSorting);
    }

    const headerPartsData = useMemo(() => {
        const data = {
            filters: (
                <Filters
                    filters={filters}
                    currencies={currencies}
                    networkLayers={networkLayers}
                    onFiltersChange={handleFiltersChange}
                    globalProjectId={globalProjectId}
                />
            ),
            search: {
                placeholder: t("backoffice.reports.agentUsernameId"),
                loadFn: () => {},
                autoSuggestion: {
                    items: getPossibleParentsName({agentNames: agents, globalProjectId}),
                },
                onSearch: handleSearchChange,
            },
            columns: {
                columns: columnsThatCanBeIncluded,
                onApply: keepAppliedColumns,
                defaultSelectedColumns: includedColumns,
            },
        };

        if (hasExportPermission) {
            data.export = {
                url: ApiUrls.EXPORT_AGENTS_PRODUCT_REPORT,
                columns: columnsForExport,
                filters: filters,
                tableName: t('backoffice.menu.newProductReport'),
            }
        }

        return data;
    }, [
        columnsForExport,
        columnsThatCanBeIncluded,
        currencies,
        filters,
        globalProjectId,
        includedColumns,
        keepAppliedColumns,
        networkLayers,
        agents,
        t
    ]);

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={
                hasTotalsPermission && (
                    <TotalsComponent
                        totals={filteredTotals}
                        isLoading={isLoading}
                        totalType={REPORT_TOTAL_TYPE.NEW_PRODUCT_REPORT}
                    />
                )
            }
            isContentScrollable={isMobile() && hasTotalsPermission}
        >
            <Table
                uniqueKey="agentId"
                loading={isLoading}
                columns={mainTableColumns}
                data={data?.data}
                sorting={sorting}
                setSortingFn={handleSortingChange}
                total={data?.total}
                expandAllAvailable
                shouldShowExpandedColumn
                expandable={{
                    title: t("backoffice.agents.wallets"),
                    details: (rowData) => ({
                        columns: expandTableColumns,
                        data: rowData?.reports,
                        uniqueKey: "currency",
                    }),
                }}
            />
        </TabTableDashboardLayout>
    );
};

const mapStateToProps = (state) => ({
    globalProjectId: state.common.globalProjectId,
    networkLayers: state.network.layers,
    currencies: state.profile.currencies,
})

export default connect(mapStateToProps)(AgentComponent);
