import { lazy } from "react";

import withSuspenseHoc from "NEW/src/core/HOCs/withSuspense";
import { ROUTES } from "NEW/src/core/data/constants/routes";

const ResetPasswordContainer = withSuspenseHoc(lazy(() => import("NEW/src/pages/resetPassword/resetPassword-container")));

export const resetPasswordPageRoute = {
	path: ROUTES.RESET_PASSWORD,
	element: <ResetPasswordContainer />,
};
