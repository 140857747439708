import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { CASHIER_TYPE } from "constants/cashier.constants";

import { getBetshopManagerGeneralInfo } from "./general.action"

import {
    SET_BETSHOP_MANAGERS_ACTION_BEFORE,
    SET_BETSHOP_MANAGERS_ACTION_FINISH,
    SET_BETSHOP_MANAGERS,
    SET_BETSHOP_MANAGERS_SORTING,
    SET_BETSHOP_MANAGERS_FILTERS,
    SET_BETSHOP_MANAGERS_SAVE_ACTION_BEFORE,
    SET_BETSHOP_MANAGERS_SAVE_ACTION_FINISH,
    SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_BEFORE,
    SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_FINISH,
    SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS
} from "../../../../actionTypes";

export const setBetshopManagersActionBefore = () => ({
    type: SET_BETSHOP_MANAGERS_ACTION_BEFORE,
});

export const setBetshopManagersActionFinished = () => ({
    type: SET_BETSHOP_MANAGERS_ACTION_FINISH,
});

export const setBetshopManagersSaveActionBefore = () => ({
    type: SET_BETSHOP_MANAGERS_SAVE_ACTION_BEFORE,
});

export const setBetshopManagersSaveActionFinished = () => ({
    type: SET_BETSHOP_MANAGERS_SAVE_ACTION_FINISH,
});

const setBetshopManagers = (betshopManagers, add) => ({
    type: SET_BETSHOP_MANAGERS,
    payload: { betshopManagers, add },
});

export const setBetshopManagersSorting = sorting => ({
    type: SET_BETSHOP_MANAGERS_SORTING,
    payload: { sorting },
});

export const setBetshopManagersFilters = filters => ({
    type: SET_BETSHOP_MANAGERS_FILTERS,
    payload: { filters },
});


export const getBetshopManagers = nextPage => {
    return (dispatch, getState) => {
        
        const page= nextPage === "first" ? 1 : nextPage === "next" ? getState().betshopManagers.sorting.page + 1 : getState().betshopManagers.sorting.page;
        let params = {
            ...getState().betshopManagers.sorting,
            ...getState().betshopManagers.filters,
            page: page,
            type: CASHIER_TYPE.INTERNAL
        };

        if(page > 1 && getState().betshopManagers.total <= (page - 1 ) * getState().betshopManagers.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().betshopManagers.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setBetshopManagersActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_MANAGERS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: betshopManagers } }) => {
                dispatch(setBetshopManagers(betshopManagers, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setBetshopManagersSorting({ ...getState().betshopManagers.sorting, page: page }));
                dispatch(setBetshopManagersActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopManagersActionFinished());
            });
    }
}

export const createBetshopManager = (betshopManager, onSuccess) => {
    return (dispatch) => {
        dispatch(setBetshopManagersSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_BETSHOP_MANAGER,
            method: Methods.POST,
            data: {
                ...betshopManager
            },
        })
            .then(({ data }) => {
                dispatch(setBetshopManagersSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setBetshopManagersSaveActionFinished());
            });
    };
};

export const changeBetshopManagerState = d => {
    return dispatch => {
        dispatch(setBetshopManagersSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_BETSHOP_MANAGER_STATE,
            method: Methods.POST,
            data: d
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getBetshopManagerGeneralInfo(d.id));
            } 
            dispatch(setBetshopManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopManagersSaveActionFinished());
        })
    }
}

export const forceLogout = () => {
    return (dispatch, getState) => {
        dispatch(setBetshopManagersSaveActionBefore());

        const filters = { ... getState().betshopManagers.filters };

        Object.keys(filters).map(k => {
            if(filters[k] === "" || filters[k] === undefined){
                delete filters[k];
            }
        })

        return axios({
            url: ApiUrls.FORCE_LOGOUT_BETSHOP_MANAGER,
            method: Methods.POST,
            data: {
                ... getState().betshopManagers.sorting,
                ... filters,
                page: getState().betshopManagers.sorting.page,
                type: CASHIER_TYPE.INTERNAL
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getBetshopManagers());
            } 
            dispatch(setBetshopManagersSaveActionFinished());
        })
        .catch(() => {
            dispatch(setBetshopManagersSaveActionFinished());
        })
    }
}

export const forceLogoutSingleBetshopManager = id => {
    return (dispatch, getState) => {
        dispatch(setBetshopManagersSaveActionBefore());

        return axios({
            url: ApiUrls.FORCE_LOGOUT_BETSHOP_MANAGER,
            method: Methods.POST,
            data: {
                userNameOrId: id,
                page: 1,
                type: CASHIER_TYPE.INTERNAL
            }
        })
        .then((data) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getBetshopManagers());
            } 
            dispatch(setBetshopManagersSaveActionFinished());
        })
        .catch(() => {
            dispatch(setBetshopManagersSaveActionFinished());
        })
    }
}


const setBetshopManagerAvailableBetshopsBefore = () => ({
    type: SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_BEFORE,
});

const setBetshopManagerAvailableBetshopsFinished = () => ({
    type: SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_FINISH,
});

const setBetshopManagerAvailableBetshops = betshops => ({
    type: SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS,
    payload: { betshops }
})

export const getBetshopManagerAvailableBetshops = id => {
    return dispatch => {
        dispatch(setBetshopManagerAvailableBetshopsBefore());
        return axios({
            url: ApiUrls.GET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: betshops}}) => {
            dispatch(setBetshopManagerAvailableBetshops(betshops))
            dispatch(setBetshopManagerAvailableBetshopsFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopManagerAvailableBetshopsFinished());
        })
    }
}

export const setBetshopManagerPassword = ( data, userId, onSuccess) => {
    return dispatch => {
        dispatch(setBetshopManagersSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_BETSHOP_MANAGER_PASSWORD,
            method: Methods.POST,
            data: { 
                ...data,
                userId 
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                onSuccess && onSuccess();
            }
            dispatch(setBetshopManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopManagersSaveActionFinished());
        })
    }
}
