import PropTypes from 'prop-types';

import { USER_STATE } from "constants/user.constants";

export default PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    lastUpdateBy: PropTypes.string,
    lastLogin: PropTypes.string,
    betShop: PropTypes.string,
    isOnline: PropTypes.bool
})