import React from 'react';

import i18n from "translations/config";

import { binaryToFlags } from 'utils/common';

import { TRANSLATION_TYPE } from "constants/translation.constants";

const TRANSLATION_TYPE_NAMES = {
    [TRANSLATION_TYPE.RETAIL]: "retail",
    [TRANSLATION_TYPE.BO]: "bo",
}

export const tableColumns = [
    {
        title: "backoffice.languages.name",
        dataIndex: "name",
        showOnlyValue: true,
        alwaysVisible: true,
        mobileLevel: 1,
        render: (_, record) => (
            <span className="rt--font-normal">
                {`${i18n.t(`backoffice.languages.${record.code.toLowerCase()}`)}`}
            </span>
        ),
        mobileRender: (_, record) => (
            <span className="rt--font-normal rt--font-extra-bold">
                {`${i18n.t(`backoffice.languages.${record.code.toLowerCase()}`)} - ${record.code}`}
            </span>
        ),
    },
    {
        title: "backoffice.languages.code",
        dataIndex: "code",
    },
    {
        title: "backoffice.languages.published",
        dataIndex: "publishType",
        mobileLevel: 2,
        showOnlyValue: true,
        render: (value) => {
            const translationTypes = binaryToFlags(Object.values(TRANSLATION_TYPE), value);
            
            if (translationTypes.length === 0) {
                return "-"
            }

            const translationNames = translationTypes.map((type) => (
                TRANSLATION_TYPE_NAMES[type]
                    ? i18n.t(`backoffice.common.${TRANSLATION_TYPE_NAMES[type]}`)
                    : i18n.t("backoffice.common.widget")
            ));

            return translationNames.join(", ");
        },
    },
];
