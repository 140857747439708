import PropTypes from 'prop-types';

import { BET_STATE, ONLINE_BET_TYPE } from "constants/bet.constants"

export default PropTypes.shape({
    id: PropTypes.string,
    betDate: PropTypes.string,
    lastUpdateDate: PropTypes.string,
    type: PropTypes.oneOf(Object.values(ONLINE_BET_TYPE)),
    providerName: PropTypes.string,
    gameName: PropTypes.string,
    amount: PropTypes.number,
    winAmount: PropTypes.number,
    state: PropTypes.oneOf(Object.values(BET_STATE)),
})