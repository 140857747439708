import React, {useMemo} from 'react';

import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import {tableColumnsCreator} from "utils/tableColumnsCreator";

import useIncludedColumns from "hooks/useIncludedColumns";
import useFormat from "hooks/useFormat";

import {getTableColumns} from "pages/players/edit/sections/playerTransactionHistory/columns";
import {PLAYER_TRANSACTIONS_HISTORY_LITE} from "constants/pageName.constants";

import {
    getPlayerTransactionsHistory, setPlayerTransactionsHistoryFilters,
    setPlayerTransactionsHistorySorting
} from "store/actions/dashboard/players/playerTransactionsHistory";
import Filters from "pages/players/edit/sections/playerTransactionHistory/filters.component";
import ApiUrls from "constants/api.constants";
import {hasPermission} from "utils/permissions";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import dateService from "utils/date";

const PlayerTransactionHistory = ({
    isLoading,
    history,
    sorting,
    filters,
    total,
    getPlayerTransactionHistory,
    setPlayerTransactionsHistorySorting,
    setPlayerTransactionsHistoryFilters
}) => {
    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PLAYER_TRANSACTIONS_HISTORY_LITE });

    const { t } = useTranslation();
    const { formatAmount, formatCurrencyWithSymbol } = useFormat();
    const searchParams = useParams();

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER_TRANSACTIONS_HISTORY,
        action: PERMISSION_ACTION.EXPORT,
    })

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount,
                formatCurrencyWithSymbol
            }
        });
    }, [includedColumns, formatAmount]);

    const header = useMemo(() => {
        const headerDataProps = {
            filters: <Filters />,
            columns: {
                columns: columnsThatCanBeIncluded,
                onApply: keepAppliedColumns,
                defaultSelectedColumns: includedColumns
            }
        }

        if (hasDataExportPermission) {
            let from = dateService.mergeDateAndTime(filters.day, filters.time?.[0]);
            let to = dateService.mergeDateAndTime(filters.day, filters.time?.[1]);

            from = dateService.toISOString(from);
            to = dateService.toISOString(to);

            const { day: _, time: __, ...restFilters } = filters;

            headerDataProps.export = {
                tableName: t("backoffice.players.playerTransactionsHistory"),
                columns: columnsForExport,
                url: `${ApiUrls.EXPORT_PLAYER_TRANSACTION_HISTORY}?playerId=${searchParams.id}`,
                filters: {
                    ...restFilters,
                    from,
                    to
                },
            }
        }

        return headerDataProps;
    }, [filters, columnsThatCanBeIncluded, includedColumns, columnsForExport, hasDataExportPermission]);

    return (
        <TabTableDashboardLayout header={header}>
            <Table
                disableFullView
                loading={isLoading}
                columns={mainTableColumns}
                data={history}
                filters={filters}
                sorting={sorting}
                total={total}
                setSortingFn={setPlayerTransactionsHistorySorting}
                setFiltersFn={setPlayerTransactionsHistoryFilters}
                loadFn={nextPage => getPlayerTransactionHistory(searchParams.id, nextPage)}
            />
        </TabTableDashboardLayout>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.players.isLoading,
    history: state.players.edit.playerTransactionsHistory.history,
    sorting: state.players.edit.playerTransactionsHistory.sorting,
    filters: state.players.edit.playerTransactionsHistory.filters,
    total: state.players.edit.playerTransactionsHistory.total,
});

const mapDispatchToProps = (dispatch) => ({
    getPlayerTransactionHistory: (id, page) => {
        dispatch(getPlayerTransactionsHistory(id, page));
    },

    setPlayerTransactionsHistorySorting: (sorting) => {
        dispatch(setPlayerTransactionsHistorySorting(sorting));
    },

    setPlayerTransactionsHistoryFilters: (sorting, keepPage) => {
        dispatch(setPlayerTransactionsHistoryFilters(sorting, keepPage));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayerTransactionHistory);
