import React from "react";

import AgentPerformanceReportComponent from "pages/reports/performanceReport/sections/agent/agentPerformanceReport.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const AgentPerformanceReportRoute = () => {
    return <AgentPerformanceReportComponent />;
};

export default withPermission(
    withAuth(AgentPerformanceReportRoute),
    {
        resource: PERMISSION_RESOURCE.REPORTS_AGENT_PERFORMANCE,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
