import i18next from "translations/config";

import { hasPermission } from "utils/permissions";
import { isFranchisingMode } from "utils/common";
import { getUser } from "utils/auth";

import { WALLET_TRANSACTION_TYPE } from "constants/wallet.constants";
import { USER_ROLE } from "constants/user.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

import { TRANSFER_ENTITY_TYPE, TRANSFER_OPERATION_GROUP } from "../constants";

export const getEntityTypesByGroupAndTransactionType = ({
    group,
    transactionType,
    hasStandartPayment,
    hasEpos,
    hasRetail
}) => {
    const entityTypes = [];
    /** Payment request operation */
    if(
        [
            WALLET_TRANSACTION_TYPE.CONFIRM_TRANSACTION_REQUEST,
            WALLET_TRANSACTION_TYPE.REJECT_TRANSACTION_REQUEST,
            WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST
        ].includes(transactionType)
    ) {
        entityTypes.push(TRANSFER_ENTITY_TYPE.PLAYER);
        return entityTypes;
    }

    /** Betshop operation */
    if(
        [
            WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_RESET,
            WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT
        ].includes(transactionType)
    ) {
        entityTypes.push(TRANSFER_ENTITY_TYPE.BETSHOP);
        return entityTypes;
    }

    /** Agent operation */
    if(
        [
            WALLET_TRANSACTION_TYPE.AGENT_CREDIT,
            WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT
        ].includes(transactionType)
    ) {
        entityTypes.push(TRANSFER_ENTITY_TYPE.AGENT);
        return entityTypes;
    }

    if (group === TRANSFER_OPERATION_GROUP.DEPOSIT || group === TRANSFER_OPERATION_GROUP.WITHDRAWAL) {

        // Agent
        if (hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW })) {
            entityTypes.push(TRANSFER_ENTITY_TYPE.AGENT)
        }

        // Player
        if (
            !isFranchisingMode() &&
            (
                hasEpos ||
                hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW })
            ) &&
            getUser()?.role === USER_ROLE.AGENT &&
            (
                hasStandartPayment || hasEpos
            )
        ) {
            entityTypes.push(TRANSFER_ENTITY_TYPE.PLAYER)
        }

        /** Betshop */
        if (
            hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW }) &&
            hasRetail &&
            hasStandartPayment
        ) {
            entityTypes.push(TRANSFER_ENTITY_TYPE.BETSHOP)
        }

    }

    return entityTypes;
}

export const isWalletConfigNonRelated = () => {
    return getUser().isStandartPaymentWithRelatedWallets === false
}

export const formatNotes = (notes = "") => {
    return notes?.replaceAll(/\n+/g, '\n');
}


export const amountValidator = (_, value) => {
    if(value === "" || value === null || value === undefined) return Promise.resolve();
    const v = Number(value);
    if(isNaN(v)) return Promise.resolve();

    if (v < 0.0001) {
        return Promise.reject(i18next.t("backoffice.validation.mustBeMore").replace("%X%", 0.0001))
    }

    return Promise.resolve()
}
