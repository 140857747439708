import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Input } from "antd";

import Tooltip from "components/common/tooltip";

import { isMobile } from 'utils/common';

const InputCell = ({
    value,
    onEdit,
    record,
    extraButtons,
    maxLength,
    regex,
}) => {
    const [editing, setEditing] = useState(false);
    const [initialValue, setInitialValue] = useState(value);
    const [currentValue, setCurrentValue] = useState(value);

    /** Fires on Edit button click
       * @function
       * @memberOf InputCell
    */
    const handleEditButtonClick = () => {
        setInitialValue(value);
        setCurrentValue(value);
        setEditing(true);
    }

    /** Fires on Editing cancel
       * @function
       * @memberOf InputCell
    */
    const cancelEditing = () => {
        setEditing(false);
        setCurrentValue(initialValue);
    }

    /** Check if current value is valid
       * @function
       * @returns {boolean}
       * @memberOf InputCell
    */
    const isValid = () => !regex || regex.test(currentValue)

    /** Fires on ok button click
       * @function
       * @memberOf InputCell`
    */
    const handleEdit = () => {
        if (isValid()) {
            onEdit && onEdit(currentValue, record);
        }

        setEditing(false);
    }

    useEffect(() => {
        setInitialValue(value);
        setCurrentValue(value);
    }, [value])

    return (
        <div className="rt--table-col rt--table-col-editable rt--flex rt--align-center">
            {
                !editing ? (
                    <Fragment>
                        <span className="rt--title rt--font-normal rt--font-regular rt--table-col-editable-text">
                            {
                                <Tooltip
                                    title={value}
                                    placement="top"
                                >
                                    {value}
                                </Tooltip>
                            }
                        </span>
                        <div
                            className="rt--table-col-button rt--ml-16"
                            onClick={handleEditButtonClick}
                        >
                            <i className="icon-edit rt--font-big" />
                        </div>
                        {
                            extraButtons && extraButtons.map(button => !button.disabled(record) ? (
                                <Tooltip title={button.title} key={button.icon} >
                                    <div
                                        className="rt--table-col-button rt--ml-8"
                                        onClick={() => button.onClick(record)}
                                    >
                                        <i className={`${button.icon} rt--font-bigest`} />
                                    </div>
                                </Tooltip>

                            ) : <Fragment key={button.icon} />)
                        }
                    </Fragment>
                ) : (
                    <div className="rt--table-col-editable-input">
                        <Input
                            value={currentValue}
                            autoFocus={(isMobile() ? false : true)}
                            autoComplete="off"
                            onChange={e => setCurrentValue(e.target.value)}
                            onBlur={() => {
                                setTimeout(cancelEditing, 200)
                            }}
                            onPressEnter={handleEdit}
                            maxLength={maxLength}
                            className={!isValid() ? "rt--table-col-editable-input-invalid" : ""}
                        />
                        <div className="rt--table-col-editable-input-actions rt--flex rt--align-center rt--justify-end">
                            <div className="rt--table-col-input-actions-wrapper">
                                <i className="icon-ok rt--font-big rt--table-col-editable-input-actions-ok" onClick={handleEdit} />
                            </div>
                            <div className="rt--table-col-input-actions-wrapper">
                                <i className="icon-cancel rt--font-big rt--table-col-editable-input-actions-cancel" onClick={cancelEditing} />
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

/** InputCell propTypes
 * PropTypes
*/

InputCell.propTypes = {
    /** Cell value */
    value: PropTypes.string,
    /** Function to call on cell edit */
    onEdit: PropTypes.func,
    /** The record of row */
    record: PropTypes.object,
    /** The array of extra buttons */
    extraButtons: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        disabled: PropTypes.func,
        onClick: PropTypes.func,
        title: PropTypes.string
    })),
    /** Max char count of input */
    maxLength: PropTypes.number,
    /** Regex */
    regex: PropTypes.instanceOf(RegExp)
}
 
export default InputCell;