import React, {useEffect} from 'react';

import {connect} from "react-redux";
import {HotJarManager} from "utils/hotjar";

import useProjectType from "hooks/useProjectType";

import {getCompanyAndProjectNames} from "components/common/hotjar/helpers";
import {getUser} from "utils/auth";

const Hotjar = ({ userInfo, globalCompanyId, globalProjectId }) => {
    const { hasLiteMode, hasAgentSystem } = useProjectType();

    useEffect(() => {
        if (userInfo && hasAgentSystem) {
            const { companyName, projectName } = getCompanyAndProjectNames(userInfo.companies || [], globalCompanyId, globalProjectId);

            HotJarManager.identify(userInfo.id, {
                longId: userInfo.longId,
                name: `${userInfo.firstName} ${userInfo.lastName}`,
                companyId: globalCompanyId,
                projectId: globalProjectId,
                companyName,
                projectName,
                isLiteMode: hasLiteMode,
                role: getUser()?.role,
                userName: getUser().userName
            });
        }
    }, [hasAgentSystem, userInfo, globalCompanyId, globalProjectId, hasLiteMode]);

    return null;
};

const mapStateToProps = (state) => ({
    userInfo: state.profile.userInfo,
    globalCompanyId: state.common.globalCompanyId,
    globalProjectId: state.common.globalProjectId,
})

export default connect(mapStateToProps)(Hotjar);
