import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import CommissionPlanSettingsComponent from './commissionPlan-settings.component';
import CommissionPlanSportSettingsComponent from './commissionPlan-sportSettings.component';
import CommissionPlanAgentsComponent from './commissionPlanAgents';

import { getCommissionPlanSettings } from "store/actions/dashboard/agentSystem/commissionPlans/settings.action";
import { getGameProviders } from "store/actions/dashboard/common/common.action";

import { binaryToFlags, isFranchisingMode } from 'utils/common';

import { AGENT_TYPE } from "constants/agent.constants"
import { COMMISSION_SETTINGS_TYPE, COMMISSION_PLAN_SOURCE } from "constants/commission.constants";
import { PROJECT_GAME_PROVIDER_TYPE, PROJECT_PROVIDER_TYPE } from "constants/project.constants"

import commissionPlanSettingsType from 'types/agent/commissionPlanSettings.type';
import gameProviderType from "types/project/gameProvider.type";

/** Agent Edit Page Commision Tab edit Component */
const CommissionPlanEditComponent = ({
    commissionPlanSettings,
    commissionPlanId,
    getCommissionPlanSettings,
    agentType,
    gameProviders,
    getGameProviders,
    onTabChange
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    /** Load online providers */
    useEffect(() => {
        if (!isFranchisingMode() && [
            COMMISSION_PLAN_SOURCE.GGR,
            COMMISSION_PLAN_SOURCE.NGR,
            COMMISSION_PLAN_SOURCE.TURNOVER
        ].includes(commissionPlanSettings.source)) {
            getGameProviders();
        }
    }, [commissionPlanSettings.source])

    useEffect(() => {
        getCommissionPlanSettings(commissionPlanId, searchParams.id)
    }, []);


    const sportProviders = gameProviders.filter(provider => provider.type === PROJECT_GAME_PROVIDER_TYPE.SPORT).map(p => p.id)

    const hasSportProvider = commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.DEPOSIT &&
        commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.WITHDRAWAL &&
        (
            (commissionPlanSettings.onlineProviders || []).some(pr => sportProviders.includes(pr)) ||
            (commissionPlanSettings.retailProviders && binaryToFlags(Object.values(PROJECT_PROVIDER_TYPE), commissionPlanSettings.retailProviders).includes(PROJECT_PROVIDER_TYPE.SPORTBOOK))
        )

    const items = [
        {
            title: t("backoffice.commissionplans.settings"),
            component: <CommissionPlanSettingsComponent agentType={agentType} />
        },
        {
            title: t("backoffice.commissionplans.sportPreMatchSettings"),
            component: <CommissionPlanSportSettingsComponent type={COMMISSION_SETTINGS_TYPE.SPORT_PRE_MATCH} agentType={agentType} />,
            disabled: !hasSportProvider
        },
        {
            title: t("backoffice.commissionplans.sportLiveSettings"),
            component: <CommissionPlanSportSettingsComponent type={COMMISSION_SETTINGS_TYPE.SPORT_LIVE} agentType={agentType} />,
            disabled: !hasSportProvider
        },
        {
            title: isFranchisingMode() ? t("backoffice.commissionplans.betshopOwners") : t("backoffice.commissionplans.agents"),
            component: <CommissionPlanAgentsComponent/>,
            disabled: agentType !== AGENT_TYPE.SUPER_AGENT
        }
    ]

    return commissionPlanSettings.id ? (
        <Tabs.SubTabs
            items={items}
            onTabChange={onTabChange}
            isDeepView={true}
        />
    ) : <Fragment />
}

/** CommissionPlanEditComponent propTypes
    * PropTypes
*/
CommissionPlanEditComponent.propTypes = {
    /** Redux action to get commision plan settings */
    getCommissionPlanSettings: PropTypes.func,
    /** Redux state, represents the commision plan settings of current editing agent  */
    commissionPlanSettings: commissionPlanSettingsType,
    /** Current editing commission plan id */
    commissionPlanId: PropTypes.string,
    /** Agent type */
    agentType: PropTypes.oneOf(Object.values(AGENT_TYPE)),
    /** Redux action to get game providers */
    getGameProviders: PropTypes.func,
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType),
    /** Function fires on tab change */
    onTabChange: PropTypes.func
}

const mapStateToProps = state => {
    return {
        commissionPlanSettings: state.commissionPlans.edit.settings,
        gameProviders: state.common.gameProviders
    }
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionPlanSettings: (id, agentId) => {
            dispatch(getCommissionPlanSettings(id, agentId))
        },

        getGameProviders: () => {
            dispatch(getGameProviders());
        }
    }
)


export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanEditComponent)
