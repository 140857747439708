import React from "react";

import CashiersEditComponent from "pages/retail/cashiers/edit/cashier-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const CashiersEditRoute = () => {
    return <CashiersEditComponent />;
};

export default withPermission(
    withAuth(CashiersEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.CASHIER_ADDITIONAL_ACCESS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.CASHIER_SESSIONS,
            action: PERMISSION_ACTION.VIEW,
        },
    ],
    Paths.DEFAULT
);
