import {message} from "antd";
import {translations} from "NEW/src/core/lib/translations";
import {RESPONSE_CODES} from "NEW/src/core/data/constants/api";

export const showError = (msg, status, resource) => {
    if(!status) {
        return message.error(msg);
    }

    const isUnknownStatus = !Object.values(RESPONSE_CODES).includes(status);

    if (isUnknownStatus) {
        return message.error(msg);
    }

    const errorStatusName = Object.keys(RESPONSE_CODES).find(key => RESPONSE_CODES[key] === status);

    let text = (
        translations
            .translate(`backoffice.errors.${errorStatusName}`)
            .replace("%resource%",
                resource && resource !== "None"
                    ? translations.translate(`backoffice.resources.${resource}`)
                    : ""
            )
    );

    if (errorStatusName === "InvalidParameters" && msg) {
        text = msg;
    }

    return message.error(text);
}
