import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Spin } from "antd";

import NotificationSound from './sound';
import NotificationPopup from './popup';
import OutsideAlerter from '../outsideAlerter';
import Modal from "components/common/modal";

import {
    getNotifications,
    markNotificationAsRead,
    deleteNotification, markAllNotificationsAsRead, deleteAllNotifications
} from 'store/actions/dashboard/notifications/notifications.action';
import {changeNotificationSettings, changeProject} from 'store/actions/dashboard/profile/userInfo.action';

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import { isMobile } from 'utils/common';
import sessionStorageUtils from 'utils/sessionStorage';
import {
    constructObjectFromJSON,
    constructRedirectionURL,
    getQueryString,
    hasAccessToCurrentProject,
    hasViewPermission
} from 'utils/notifications';

import {
    NOTIFICATION_INFO_TYPES,
    NOTIFICATION_SOUND_TYPES,
    NOTIFICATION_TYPES_ENUM,
    PATHS_FOR_EACH_TYPE_BY_RESOURCE
} from "constants/notification.constants";
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import notificationType from 'types/notification/notification.type';
import companyType from "types/company/company.type";
import NotificationHeader from "./header";
import NotificationItem from "./item";
import NotificationsEmpty from "./empty";
import VirtualScroll from "../virtualScroll";


/** Notifications Component */
const NotificationsComponent = ({
    renderButton,
    userId,
    getNotifications,
    markNotificationAsRead,
    markAllNotificationsAsRead,
    deleteNotification,
    deleteAllNotifications,
    notifications,
    unreadNotificationsCount,
    notificationSound,
    changeNotificationSettings,
    sound,
    changeProject,
    globalCompanyId,
    globalProjectId,
    allCompanies,
    isLoading,
    canLoadMore,
    onClose
}) => {
    const [opened, setOpened] = useState(false);
    const [clearAllOpened, setClearAllOpened] = useState(false);
    const [info, setInfo] = useState(null);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentPage = useRef(0);
    const initialized = useRef(false);

    const redirectToTarget = (notification) => {
        if (NOTIFICATION_INFO_TYPES.includes(notification.type)) {
            setInfo(notification);
            return;
        }

        const { type, data } = notification;

        const { resource, querydata, id } = constructObjectFromJSON(data)

        const dataForRedirection = PATHS_FOR_EACH_TYPE_BY_RESOURCE[type]?.[resource];

        // -------------------- CHECK THE POSSIBILITY OF REDIRECTION ------------------ //

        if (!Boolean(dataForRedirection)) {
            return;
        }

        let {
            permissionResource,
            path,
            hash,
            queryStringGenerator,
        } = dataForRedirection;

        if (!hasViewPermission(permissionResource)) {
            return;
        }

        // ------------------------------ DO REDIRECTION ------------------------------ //

        if (Boolean(id)) {
            path = `${path}/${id}`;
        }

        const queryString = getQueryString(queryStringGenerator, querydata);

        if (querydata.cid !== globalCompanyId || querydata.pid !== globalProjectId) {
            const hasAccessToProject = hasAccessToCurrentProject({
                allCompanies,
                companyId: querydata.cid,
                projectId: querydata.pid,
            })

            changeProject(
                querydata.cid,
                hasAccessToProject ? querydata.pid : null,
                () => {
                    navigate("redirect", {
                        replace: true,
                        state: {
                            url: constructRedirectionURL(path, queryString, hash)
                        }
                    })
                }
            )


            return;
        }

        navigate("redirect", {
            replace: true,
            state: {
                url: constructRedirectionURL(path, queryString, hash)
            }
        })
    }

    const redirectToTargetWithConfirmation = useUnsavedFormConfirmation({
        cb: redirectToTarget,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const handleNotificationDelete = (e, id) => {
        e.stopPropagation();

        deleteNotification(id);
    }

    const handleNotificationClick = notification => {
        markNotificationAsRead(notification.id);
        setOpened(false);
        onClose && onClose();
        redirectToTargetWithConfirmation(notification)
    }

    const renderContent = (slicedNotifications) => {
        if (isLoading && (!notifications || !notifications.length)) {
            return (
                <div className="rt--notifications-empty rt--flex rt--flex-col rt--align-center rt--justify-center">
                    <Spin/>
                </div>
            )
        }

        if (slicedNotifications.length > 0 ) {
            const res = slicedNotifications.map(n => ({ ...n })).map((notification, index) => (
                    <>
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                            onClick={handleNotificationClick}
                            onDelete={handleNotificationDelete}
                        />
                        {index !== slicedNotifications.length - 1 && <div key={notification.id + 'divider'} className="rt--notifications-divider"/>}
                    </>
                ));

            if (isLoading) {
                res.push(<Spin />);
            }

            return res;
        }

        return <NotificationsEmpty />
    }

    const handleClearAllOpen = () => {
        setClearAllOpened(true);
        setOpened(false);
        onClose && onClose();
    }

    const handleCancelClearAll = () => {
        setClearAllOpened(false);
    }

    const handleClearAll = () => {
        setClearAllOpened(false);
        deleteAllNotifications();
    }

    const handleMarkAllAsRead = () => {
        markAllNotificationsAsRead();
    }

    const handleMuteToggle = () => {
        changeNotificationSettings({
            id: userId,
            notificationSound:
                notificationSound === NOTIFICATION_SOUND_TYPES.SILENT
                    ? NOTIFICATION_SOUND_TYPES.DEFAULT
                    : NOTIFICATION_SOUND_TYPES.SILENT
        });
    }

    const handleLoadMore = () => {
        if (canLoadMore) {
            getNotifications({page: ++currentPage.current, limit: 20});
        }
    }

    /** Load notifications */
    useEffect(() => {
        if ((
            opened || sessionStorageUtils.get("unreadNotificationsCount") === null
        ) && !initialized.current) {
            getNotifications({page: ++currentPage.current, limit: 20});
            initialized.current = true;
        }
    }, [opened])

    return (

        <Fragment>
            {
                isMobile() ? (
                    renderButton( unreadNotificationsCount, () => setOpened(!opened))
                ) : (
                    <OutsideAlerter
                        callback={() => setOpened(false)}
                        preventClassName="rt--notifications"
                    >
                        {
                            renderButton(
                                unreadNotificationsCount,
                                () => setOpened(!opened)
                            )
                        }
                    </OutsideAlerter>
                )
            }

            {
                opened && (
                    <Fragment>
                        {
                            isMobile() ? (
                                <Modal
                                    className='rt--notifications-modal'
                                    closable={false}
                                    title={(
                                        <NotificationHeader
                                            markAllReadDisabled={unreadNotificationsCount <= 0}
                                            clearAllDisabled={notifications <= 0}
                                            notificationSound={notificationSound}
                                            onClearAll={handleClearAllOpen}
                                            onMarkAllAsRead={handleMarkAllAsRead}
                                            onMuteToggle={handleMuteToggle}
                                            onBack={() => setOpened(false)}
                                        />
                                    )}
                                    onCancel={() => setOpened(false)}
                                >
                                    <div className="rt--notifications-content">
                                        <VirtualScroll
                                            className="rt--notifications-content-virtual"
                                            itemHeight={104}
                                            data={notifications}
                                            renderItem={slice => renderContent(slice)}
                                            onScrollEnd={handleLoadMore}
                                        />
                                    </div>
                                </Modal>
                            ) : (
                                <div className="rt--notifications">
                                    <NotificationHeader
                                        markAllReadDisabled={unreadNotificationsCount <= 0}
                                        clearAllDisabled={notifications <= 0}
                                        notificationSound={notificationSound}
                                        onClearAll={handleClearAllOpen}
                                        onMarkAllAsRead={handleMarkAllAsRead}
                                        onMuteToggle={handleMuteToggle}
                                    />
                                    <div className="rt--notifications-content">
                                        <VirtualScroll
                                            className="rt--notifications-content-virtual"
                                            itemHeight={112}
                                            data={notifications}
                                            renderItem={slice => renderContent(slice)}
                                            onScrollEnd={handleLoadMore}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </Fragment>
                )
            }

            {sound && <NotificationSound notificationSound={notificationSound} />}

            {
                info && (
                    <NotificationPopup
                        onCancel={() => setInfo(null)}
                        notification={info}
                    />
                )
            }

            {
                clearAllOpened && (
                    <Modal
                        closable={false}
                        title={t('backoffice.notifications.clearAllTitle')}
                        okText={t('backoffice.common.yes')}
                        cancelText={t('backoffice.common.no')}
                        onCancel={handleCancelClearAll}
                        onOk={handleClearAll}
                    >
                        {t('backoffice.notifications.clearAllDescription')}
                    </Modal>
                )
            }
        </Fragment>
    )
}

/** NotificationsComponent propTypes
    * PropTypes
*/
NotificationsComponent.propTypes = {
    /** Button, clicking on will open the popup */
    renderButton: PropTypes.func,
    /** Redux state property, logged in user id */
    userId: PropTypes.string,
    /** Redux action to get user profile */
    getNotifications: PropTypes.func,
    /** Redux action to mark notifications as read */
    markNotificationAsRead: PropTypes.func,
    /** Redux action to mark all notifications as read */
    markAllNotificationsAsRead: PropTypes.func,
    /** Redux action to delete notification */
    deleteNotification: PropTypes.func,
    /** Redux action to delete all notifications */
    deleteAllNotifications: PropTypes.func,
    /** Redux state, represents the array of notifications  */
    notifications: PropTypes.arrayOf(notificationType),
    /** Redux state, represents the unread notifications count  */
    unreadNotificationsCount: PropTypes.number,
    /** Redux state, represents notification sound setting */
    notificationSound: PropTypes.number,
    /** Redux action to change notification settings */
    changeNotificationSettings: PropTypes.func,
    /** Redux state property for notification sound */
    sound: PropTypes.bool,
    /** Redux state property, is true when loading notifications */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when there is more notifications to load */
    canLoadMore: PropTypes.bool,
    /** Redux action to change global company/project id */
    changeProject: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, represents the array of all companies  */
    allCompanies: PropTypes.arrayOf(companyType),
    /** Call to close modal on mobile */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getNotifications: (filters) => {
            dispatch(getNotifications(filters));
        },

        markNotificationAsRead: id => {
            dispatch(markNotificationAsRead(id));
        },

        markAllNotificationsAsRead: () => {
            dispatch(markAllNotificationsAsRead());
        },

        deleteNotification: id => {
            dispatch(deleteNotification(id));
        },

        deleteAllNotifications: () => {
            dispatch(deleteAllNotifications());
        },

        changeNotificationSettings: (settings) => {
            dispatch(changeNotificationSettings(settings))
        },

        changeProject: (companyId, projectId, onSuccess) => {
            dispatch(changeProject(companyId, projectId, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        userId: state.profile.userInfo.id,
        notifications: state.notifications.notifications,
        unreadNotificationsCount: state.notifications.unreadNotificationsCount,
        notificationSound: state.profile.userInfo.notificationSetting.notificationSound,
        isLoading: state.notifications.isLoading,
        canLoadMore: state.notifications.canLoadMore,
        sound: state.notifications.sound,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        allCompanies: state.profile.userInfo.companies
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsComponent)
