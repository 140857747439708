import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import {
    COMPANY_PASSWORD_PATTERN,
    COMPANY_PASSWORD_PATTERN_RULE
} from 'constants/company.constants';

import companyPasswordSettingsType from "types/company/passwordSettings.type";

/** Password Rules Component */
const PasswordRules = ({
    passwordSettings
}) => {

    const { t } = useTranslation();

    const contain = passwordSettings.pattern?.contain ?? [];

    /** Get 'must contain' rules from password settings
        *@returns {Array} 
       * @memberOf PasswordRules
   */
    const getMustRules = () => contain.filter(rule => rule.value === COMPANY_PASSWORD_PATTERN_RULE.MUST)

    /** Get 'must not contain' rules from password settings
        *@returns {Array} 
       * @memberOf PasswordRules
   */
    const getNoneRules = () => contain.filter(rule => rule.value === COMPANY_PASSWORD_PATTERN_RULE.NONE)

    return (
        <div className="rt--password-rules">
            <span className='rt--password-rules-title rt--font-bold rt--font-normal' >{t('backoffice.authentication.passwordRules')}</span>
            <ul className='rt--mt-8 rt--mb-2'>
                <li className='rt--font-regular rt--font-normal'>{t('backoffice.authentication.mustBeBetween').replace("%X%", passwordSettings.passwordMinLimit).replace("%Y%", passwordSettings.passwordMaxLimit)}<br /></li>
                {
                    passwordSettings.pattern?.type === COMPANY_PASSWORD_PATTERN.CONTAINS ?
                        <Fragment>
                            {
                                getMustRules().length > 0 ?
                                    <li className='rt--font-regular rt--font-normal'>{
                                        t('backoffice.authentication.mustContain')
                                            .replace(
                                                "%X%",
                                                getMustRules()
                                                    .map(rule => t(`backoffice.companies.containType${rule.type}`))
                                                    .join(", ")
                                            )
                                    } <br />
                                    </li> : null
                            }
                            {
                                getNoneRules().length > 0 ?
                                    <li className='rt--font-regular rt--font-normal'>{
                                        t('backoffice.authentication.mustNotContain')
                                            .replace(
                                                "%X%",
                                                getNoneRules()
                                                    .map(rule => t(`backoffice.companies.containType${rule.type}`))
                                                    .join(", ")
                                            )
                                    } <br />
                                    </li> : null
                            }
                        </Fragment>
                        : passwordSettings.pattern?.type === COMPANY_PASSWORD_PATTERN.PATTERN ?
                            (
                                <li className='rt--font-regular rt--font-normal'>{t('backoffice.authentication.mustMatchRegularExpression').replace("%X%", passwordSettings.pattern?.regularExpression ?? "" )}<br /></li>
                            ) : null
                }
            </ul>
        </div>
    )
}

/** PasswordRules propTypes
    * PropTypes
*/
PasswordRules.propTypes = {
    /** The password settings */
    passwordSettings: companyPasswordSettingsType
}

export default PasswordRules;