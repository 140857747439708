import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isFunction } from 'utils/common';

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { SET_PROJECT_CONFIGS } from "../../../actionTypes";

const setProjectConfigs = configs => ({
    type: SET_PROJECT_CONFIGS,
    payload: { configs }
})

export const getProjectConfigs = ( ) => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_CONFIGS,
            method: Methods.GET,
            params: { }
        })
        .then(({data : {value: configs}}) => {
            dispatch(setProjectConfigs(configs))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectConfigs = (data, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_CONFIGS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectConfigs(data.value));

                if (isFunction(onSuccess)) {
                    onSuccess()
                }
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
