import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Form } from "antd";

import { TRANSFER_ENTITY_TYPE } from '../../../../constants';

import currencyType from 'types/profile/currency.type';

/** Favorite Amounts */
const FavoriteAmounts = ({
    currencyCode,
    currencies,
    entityType,
    handleClick
}) => {

    const favoriteAmounts = useMemo(() => {
        if(!currencyCode) return [];
        const currencyData = currencies.find(c => c.currencyCode === currencyCode);
        if(!currencyData) return [];
        const favAmounts = currencyData.currency?.transactionFavoriteAmounts;
        if(entityType === TRANSFER_ENTITY_TYPE.PLAYER){
            return favAmounts?.playerFavoriteAmounts ?? []
        } else if(entityType === TRANSFER_ENTITY_TYPE.BETSHOP){
            return favAmounts?.betShopFavoriteAmounts ?? []
        } else{
            return favAmounts?.agentFavoriteAmounts ?? []
        }
    }, [currencyCode, currencies, entityType, handleClick])

    return favoriteAmounts.length > 0 ? (
        <Form.Item>
            <div className='rt--transfer-favamounts rt--flex rt--align-center'>
                {
                    favoriteAmounts.map(fa => (
                        <div 
                            className='rt--transfer-favamounts-item rt--flex rt--align-center rt--justify-center rt--mr-6 rt--ml-6 rt--pl-2 rt--pr-2' 
                            key={fa}
                            onClick={() => handleClick(fa)}
                            title={fa}
                        >
                            <span className='rt--font-normal rt--font-bold'>{fa}</span>
                        </div>
                    ))
                }
            </div>
        </Form.Item> 
    ) : null 
}

/** FavoriteAmounts propTypes
    * PropTypes
*/
FavoriteAmounts.propTypes = {
    /** Entity Type */
    entityType: PropTypes.oneOf(Object.values(TRANSFER_ENTITY_TYPE)),
    /** Call on click */
    handleClick: PropTypes.func,
    /** Currency Code */
    currencyCode: PropTypes.string,
    /** Redux state property, Account currencies */
    currencies: PropTypes.arrayOf(currencyType)
}

const mapStateToProps = state => {
    return {
        currencies: state.profile.currencies,
    }
}

export default connect(mapStateToProps, null)(FavoriteAmounts);
