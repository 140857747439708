import React, { useMemo } from 'react';

import Table from "components/common/table";

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { RESPONSE_CODES } from 'constants/errorCode.constants';
import { columns } from './columns';

import useTransfer from '../../hooks/useTransfer';

/** Transfer result component */
const TransferResult = () => {

    const { transferResultData } = useTransfer();
    
    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ mainColumns: columns })
    }, [])

    return (
        <div className='rt--transfer-error'>
            <div className="rt--modal-table rt--modal-table-regular">
                <Table
                    columns={mainTableColumns}
                    data={transferResultData}
                    noPagination={true}
                    disableFullView={true}
                    chooser={{
                        type: "icon",
                        dataIndex: "status",
                        renderIcon: record => (
                            record.status === RESPONSE_CODES.Success
                                ? <i className='icon-success rt--font-bigest' />
                                : <i className='icon-warning rt--font-bigest' />
                        )
                    }}
                />
            </div>
        </div>
    )
}   

export default TransferResult;