import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_PAYMENT_HISTORY_ACTION_BEFORE,
    SET_PAYMENT_HISTORY_ACTION_FINISH,
    SET_PAYMENT_HISTORY,
    SET_PAYMENT_HISTORY_SORTING,
    SET_PAYMENT_HISTORY_FILTERS
} from "../../../../actionTypes";
import { WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

export const setPaymentHistoryActionBefore = () => ({
    type: SET_PAYMENT_HISTORY_ACTION_BEFORE,
});

export const setPaymentHistoryActionFinished = () => ({
    type: SET_PAYMENT_HISTORY_ACTION_FINISH,
});

const setPaymentHistory = (paymentHistory, type, add) => ({
    type: SET_PAYMENT_HISTORY,
    payload: { paymentHistory, type, add },
});

export const setPaymentHistorySorting = (sorting, type) => ({
    type: SET_PAYMENT_HISTORY_SORTING,
    payload: { sorting, type },
});

export const setPaymentHistoryFilters = (filters, type) => ({
    type: SET_PAYMENT_HISTORY_FILTERS,
    payload: { filters, type },
});


export const getPaymentHistory = (nextPage, type) => {
    return (dispatch, getState) => {
        const paymentHistory = getState().paymentHistory[type];

        const page = nextPage === "first" ? 1 : nextPage === "next" ? paymentHistory.sorting.page + 1 : paymentHistory.sorting.page;
        let params = {
            ...paymentHistory.sorting,
            ...paymentHistory.filters,
            page: page
        };

        if(page > 1 && paymentHistory.total <= (page - 1 ) * paymentHistory.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && paymentHistory.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setPaymentHistoryActionBefore());

        const apiURL = type === WIDGET_TRANSACTION_TYPE.DEPOSIT ? ApiUrls.GET_PAYMENT_DEPOSIT_HISTORY : ApiUrls.GET_PAYMENT_WITHDRAW_HISTORY;

        return axios({
            url: apiURL,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: history } }) => {
                
                dispatch(setPaymentHistory(history, type, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setPaymentHistorySorting({ ...paymentHistory.sorting, page: page }, type));
                dispatch(setPaymentHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setPaymentHistoryActionFinished());
            });
    }
}
