import {
    SET_PAYMENT_HISTORY_ACTION_BEFORE,
    SET_PAYMENT_HISTORY_ACTION_FINISH,
    SET_PAYMENT_HISTORY,
    SET_PAYMENT_HISTORY_SORTING,
    SET_PAYMENT_HISTORY_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    let paymentHistoryData = [];
    switch (type) {
        case SET_PAYMENT_HISTORY_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_PAYMENT_HISTORY_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_PAYMENT_HISTORY:
            paymentHistoryData = payload.paymentHistory.item2.map(h => {
				let userInfo = h.userInfo ? JSON.parse(h.userInfo) : h.userInfo;
                if(userInfo){
                    delete userInfo.amount;
                }
				
                return {...h, userInfo}
			});

            return {
                ...state,
                [payload.type]: {
                    ...state[payload.type],
                    paymentHistory: !payload.add ? paymentHistoryData : state[payload.type].paymentHistory.concat(paymentHistoryData),
                    total: payload.paymentHistory.item1,
                }
            };

        case SET_PAYMENT_HISTORY_FILTERS:
            return {
                ...state,
                [payload.type]: {
                    ...state[payload.type],
                    filters: payload.filters,
                    sorting: {
                        ...state[payload.type].sorting,
                        page: 1
                    }
                }
                
            };
        case SET_PAYMENT_HISTORY_SORTING:
            return {
                ...state,
                [payload.type]: {
                    ...state[payload.type],
                    sorting: payload.sorting,
                }
            };
        default:
            return state;
    }
};
