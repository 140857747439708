import React from 'react';

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';

export const tableColumns = [
    {
        title: "backoffice.payments.bankId",
        dataIndex: "id",
        copy: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.payments.bankName",
        dataIndex: "name",
        alwaysVisible: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.payments.bankLogo",
        dataIndex: "logoPath",
        alwaysVisible: true,
        mobileLevel: 3,
        render: (value, record) => (
            value
                ? <img
                    className="rt--table-image"
                    alt={record.name}
                    src={`${import.meta.env.SYSTEM_CDN_URL}/${value}`}
                />
                : '-'
        ),
    },
    {
        title: "backoffice.payments.creationDate",
        dataIndex: "createdAt",
        mobileLevel: 4,
        isDateTime: true,
    },
    {
        title: "backoffice.common.status",
        dataIndex: "state",
        render: state => <Status status={state} type={STATUS_TYPES.BANK} />,
        mobileLevel: 5,
    },
    {
        title: "backoffice.payments.currencies",
        dataIndex: "currencies",
        multi: true,
        mobileLevel: 6,
    }
];
