import i18n from 'translations/config';

import { BET_STATE } from "constants/bet.constants";

/** Make bet state text
     * @function
     * @param {number} state
     * @param {number} type
     * @returns {string}
 */
export const makeBetStateText = state => {
    const betState = Number(state);
    switch (betState) {
        case BET_STATE.PENDING:
            return i18n.t("backoffice.bets.pending");
        case BET_STATE.RETURN:
            return i18n.t("backoffice.bets.return");
        case BET_STATE.WON:
            return i18n.t("backoffice.bets.won");
        case BET_STATE.SEMIWON:
            return i18n.t("backoffice.bets.semiWon");
        case BET_STATE.SEMILOST:
            return i18n.t("backoffice.bets.semiLost");
        case BET_STATE.LOST:
            return i18n.t("backoffice.bets.lost");
        case BET_STATE.CANCELLED:
            return i18n.t("backoffice.bets.cancelled");
        case BET_STATE.REJECTED_BY_PROVIDER:
            return i18n.t("backoffice.bets.rejectedByProvider");
        default:
            return ""
    }
}

export const getDisabledExpandRowIds = (bets) => {
    const filteredBets = bets.filter(bet => bet.bets?.length === 0);

    return filteredBets.map(bet => bet.id);
}

export const constructDataForTotals = ({ totalsData, specificCurrency }) => {
    const tableData = [];

    if (!totalsData) {
        return tableData;
    }

    const tableDataKeyValuePairs = Object.entries(totalsData);
    tableDataKeyValuePairs.forEach(([currency, betData]) => {
        if (!Boolean(specificCurrency)) {
            tableData.push({
                ...betData,
                currency
            })

            return;
        }

        if (Array.isArray(specificCurrency)) {
            if (specificCurrency.length === 0 || specificCurrency.some(c => c.toLowerCase() === currency.toLowerCase())) {
                tableData.push({
                    ...betData,
                    currency
                })
            }
            return;
        }

        if (specificCurrency.toLowerCase() === currency.toLowerCase()) {
            tableData.push({
                ...betData,
                currency
            })
        }
    })

    return tableData;
}