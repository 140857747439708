import i18n from "translations/config";

import { PAYMENT_METHODS, WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

export const getTableColumns = (additionalProps) => {
    const {
        type
    } = additionalProps;

    return [
        {
            title: "backoffice.payments.id",
            dataIndex: "transactionId",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        type === WIDGET_TRANSACTION_TYPE.WITHDRAWAL && {
            title: "backoffice.payments.paymentMethod",
            dataIndex: "paymentType",
            mobileLevel: 2,
            render: (paymentType) => (
                paymentType === PAYMENT_METHODS.BANK_TRANSFER
                    ? i18n.t("backoffice.payments.bankTransfer")
                    : i18n.t("backoffice.payments.epos")
            )
        },
        {
            title: "backoffice.payments.playerExternalId",
            dataIndex: "playerId",
            copy: true,
        },
        {
            title: "backoffice.payments.playerUserName",
            dataIndex: "playerUserName",
            alwaysVisible: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.payments.currency",
            dataIndex: "currencyCode",
            mobileLevel: 4,
        },
        {
            title: "backoffice.common.amount",
            dataIndex: "amount",
            isNumeric: true,
            sorter: true,
            mobileLevel: 5,
        },
        {
            title: "backoffice.payments.bankName",
            dataIndex: "bankName",
            mobileLevel: 6,
            render: value => value || "-"
        },
        {
            title: "backoffice.payments.agentUserName",
            dataIndex: "agentUserName",
            render: value => value || "-"
        },

        {
            title: "backoffice.common.creationDate",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
    ];
}