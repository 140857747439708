import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import NumericInput from "components/common/numericInput";

import {
    createSystemCurrency,
    saveSystemCurrency,
    getSystemAvailableCurrencies,
    getAvailableCurrencies
} from "store/actions/dashboard/settings/systemCurrencies/systemCurrencies.action";

import currencyType from "types/currency/currency.type";

import { numberTransform } from "utils/common";

/** System Currency Creating Popup Component */
const SystemCurrencyAddEditComponent = ({
    isSaving,
    isAvailableLoading,
    availableCurrencies,
    systemAvailableCurrencies,
    currencies,
    createSystemCurrency,
    saveSystemCurrency,
    getSystemAvailableCurrencies,
    getAvailableCurrencies,
    editingCurrency,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const [selectedCur, setSelectedCur] = useState(editingCurrency ? editingCurrency.toUpperCase() : "");

    /** Load system available currencies */
    useEffect(() => {
        if (Object.keys(availableCurrencies).length === 0) {
            getAvailableCurrencies();
        }
        getSystemAvailableCurrencies();
    }, [])


    /** Get initial values for form
       * @function
       * @returns {object} - initial values
       * @memberOf CurrencyAddEditComponent
   */
    const getDefaultValues = () => {
        if (editingCurrency) {
            const cur = currencies.find(c => c.code === editingCurrency);
            if (cur) {
                return {
                    code: cur.code,
                    //rate: cur.rate
                }
            }

        }

        return {
            code: undefined,
            //rate: ""
        };
    }

    /** Fires when form submitted
       * @function
       * @memberOf CurrencyAddEditComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    code: data.code.toUpperCase(),
                    rate: 1
                }

                if (editingCurrency) {
                    saveSystemCurrency(d, onClose);
                } else {
                    createSystemCurrency(d, onClose);
                }

            }).catch(() => { })
    }

    return (
        <Modal
            title={editingCurrency ? t('backoffice.currencies.editCurrency') : t('backoffice.currencies.addCurrency')}
            cancelText={t('backoffice.common.cancel')}
            okText={editingCurrency ? t('backoffice.common.save') : t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={getDefaultValues()}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.currencies.selectCurrency')} *`}
                            name="code"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className={"rt--form-item-without-margin" + ( editingCurrency ? " rt--form-item-disabled" : "") }
                        >
                            <Select
                                options={
                                    Object.keys(availableCurrencies)
                                        .filter(item => !systemAvailableCurrencies.some(c => c.code.toLowerCase() === item.toLowerCase()))
                                        .map(item => (
                                            { value: item.toUpperCase(), text: item.toUpperCase() + " - " + availableCurrencies[item] }
                                        ))
                                }
                                loading={isAvailableLoading}
                                disabled={editingCurrency}
                                placeholder={t('backoffice.currencies.selectCurrencyPlaceholder')}
                                onChange={e => setSelectedCur(e.toUpperCase())}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** SystemCurrencyAddEditComponent propTypes
    * PropTypes
*/
SystemCurrencyAddEditComponent.propTypes = {
    /** Redux state property, is true when adding system currency request is in process */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading system available currencies */
    isAvailableLoading: PropTypes.bool,
    /** Redux action to add system currency */
    createSystemCurrency: PropTypes.func,
    /** Redux action to save system currency */
    saveSystemCurrency: PropTypes.func,
    /** Redux action to get system available currencies */
    getSystemAvailableCurrencies: PropTypes.func,
    /** Redux action to get available currencies */
    getAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the array of system currencies  */
    currencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, represents available currencies  */
    availableCurrencies: PropTypes.object,
    /** Redux state property, represents system available currencies  */
    systemAvailableCurrencies: PropTypes.arrayOf(currencyType),
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Current editing currecny */
    editingCurrency: currencyType
}

const mapDispatchToProps = dispatch => (
    {
        createSystemCurrency: (currency, onSuccess) => {
            dispatch(createSystemCurrency(currency, onSuccess));
        },
        saveSystemCurrency: (currency, onSuccess) => {
            dispatch(saveSystemCurrency(currency, onSuccess));
        },
        getSystemAvailableCurrencies: () => {
            dispatch(getSystemAvailableCurrencies())
        },
        getAvailableCurrencies: () => {
            dispatch(getAvailableCurrencies())
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.systemCurrencies.isSaving,
        isAvailableLoading: state.systemCurrencies.isAvailableLoading,
        currencies: state.systemCurrencies.currencies,
        availableCurrencies: state.systemCurrencies.availableCurrencies,
        systemAvailableCurrencies: state.systemCurrencies.systemAvailableCurrencies,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemCurrencyAddEditComponent)