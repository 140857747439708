import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import MainDashboardLayout from "components/layouts/main";
import PermissionsList from 'components/common/permissions';

import { getPermissions, getPermissionResources } from 'store/actions/dashboard/userManagement/permissions/permissions.action';

import { isFranchisingMode } from "utils/common";

import { USER_TYPE } from "constants/user.constants";
import { PERMISSION_TYPE } from 'constants/permissions.constants';

import permissionType from 'types/permission/permission.type';

/** Permissions Page Component */

const PermissionsComponent = ({
    getPermissions,
    getPermissionResources,
    permissions,
    resources,
    isLoading
}) => {

    const { t } = useTranslation();
    const { search } = useLocation();

    const [currentResource, setCurrentResource] = useState(null);

    const userType = (new URLSearchParams(search)).get("type") || USER_TYPE.ADMIN;
    const permissionType = Number(userType) === USER_TYPE.ADMIN ? PERMISSION_TYPE.ADMIN : isFranchisingMode() ? PERMISSION_TYPE.BETSHOP_OWNER : PERMISSION_TYPE.AGENT;
    
    /** Get permission resources */
    useEffect(() => {
        getPermissionResources(permissionType);

        return () => {
            setCurrentResource(null)
        }
    }, [userType])
    
    /** Load permissions */
    useEffect(() => {
        if (resources && resources[0]) {
            setCurrentResource(resources[0])
        }
    }, [resources, userType])

    useEffect(() => {
        if(currentResource){
            getPermissions(currentResource, permissionType);
        }
    }, [userType, currentResource])


    return (
        <MainDashboardLayout
            header={
                {
                    breadcrumbs: {
                        items: [
                            { title: t('backoffice.menu.permissions') }
                        ]
                    }
                }
            }
            dropdowns={
                [
                    {
                        placeholder: t("backoffice.permissions.resources"),
                        value: currentResource,
                        onChange: value => setCurrentResource(value),
                        items: resources.map(r => ({
                            key:r,
                            value: r === "All" ? t("backoffice.common.all") : r === "Agent" && isFranchisingMode() ? t(`backoffice.permissions.BetshopOwner`) : t(`backoffice.permissions.${r}`)
                        }))
                    }
                ]
            }
        >
            <PermissionsList
                permissions={permissions}
                isLoading={isLoading}
                editable={false}
            />
        </MainDashboardLayout>
    )

    
}

/** PermissionsComponent propTypes
    * PropTypes
*/
PermissionsComponent.propTypes = {
    /** Redux action to get permissions */
    getPermissions: PropTypes.func,
    /** Redux action to get permissin resources */
    getPermissionResources: PropTypes.func,
    /** Redux state property, represents the array of permissions */
    permissions: PropTypes.arrayOf(permissionType),
    /** Redux state property, represents the array of permission resources */
    resources: PropTypes.arrayOf(PropTypes.string),
    /** Redux state property, is true when loading permissions */
    isLoading: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        getPermissions: (resource, type) => {
            dispatch(getPermissions(resource, type))
        },
        getPermissionResources: type => {
            dispatch(getPermissionResources(type))
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.permissions.isLoading,
        permissions: state.permissions.permissions,
        resources: state.permissions.resources
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    PermissionsComponent
);
