import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";

import Tabs from 'components/common/tabs';

import AgentPaymentConfigsComponent from './configs';
import PaymentBanksComponent from './banks';
import EPOSconfigs from './epos';

import { getAgentPaymentSettings } from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action'

import { PAYMENT_METHODS } from "constants/project.constants"

import {binaryToFlags, getHashValue} from 'utils/common';

import agentPaymentConfigType from 'types/agent/agentPaymentConfig.type';

/** Agent Edit Page AgentSystem Tab Component */
const AgentPaymentsComponent = ({
    tabId,
    onTabChange,
    getAgentPaymentSettings,
    config,
}) => {
    const { t } = useTranslation();
    const { search } = useLocation();

    const paymentSettings = binaryToFlags(Object.values(PAYMENT_METHODS), config.type);

    const searchParams = useParams();

    const isActive = tabId === getHashValue('tab');

    /** Get payment settings */
    useEffect(() => {
        getAgentPaymentSettings(searchParams.id)
    }, [])

    const bankId = (new URLSearchParams(search)).get("bankId");

    const items = [
        {
            title: t("backoffice.payments.configs"),
            component: <AgentPaymentConfigsComponent />
        },
        {
            title: t("backoffice.payments.bankList"),
            component: <PaymentBanksComponent />,
            disabled: !paymentSettings.includes(PAYMENT_METHODS.BANK_TRANSFER) && !bankId
        },
        {
            title: t("backoffice.payments.epos"),
            component: <EPOSconfigs />,
            disabled: !paymentSettings.includes(PAYMENT_METHODS.EPOS)
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
            hidden={Boolean(bankId)}
        />
    )
}

const mapDispatchToProps = dispatch => (
    {
        getAgentPaymentSettings: id => {
            dispatch(getAgentPaymentSettings(id));
        },
    }
)

const mapStateToProps = state => {
    return {
        config: state.agents.edit.payment.config
    }
}

/** PaymentsComponent propTypes
    * PropTypes
*/
AgentPaymentsComponent.propTypes = {
    /** Tab identifier*/
    tabId: PropTypes.string,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Redux action to get agent payment */
    getAgentPaymentSettings: PropTypes.func,
    /** Redux state property, current editing agent payment config */
    config: agentPaymentConfigType,
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentPaymentsComponent);
