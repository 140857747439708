import PropTypes from 'prop-types';

import { BET_STATE, BET_TYPE } from 'constants/bet.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/project.constants';

export default PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    amount: PropTypes.number,
    betTime: PropTypes.string,
    betType: PropTypes.oneOf(Object.values(BET_TYPE)),
    calculateTime: PropTypes.string,
    event: PropTypes.string,
    eventId: PropTypes.number,
    externalBetId: PropTypes.number,
    externalPartnerId: PropTypes.string,
    factor: PropTypes.number,
    paidoutTime: PropTypes.string,
    market: PropTypes.string,
    possibleWin: PropTypes.number,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
    provider: PropTypes.oneOf(Object.values(PROJECT_PROVIDER_TYPE)),
    result: PropTypes.string,
    sportName: PropTypes.string,
    tournamentName: PropTypes.string,
    status: PropTypes.oneOf(Object.values(BET_STATE)),
    winning: PropTypes.number
})