import { lazy } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import {ROUTES} from "NEW/src/core/data/constants/routes";
import withSuspenseHoc from "NEW/src/core/HOCs/withSuspense";
import {Spin} from "antd";

const CAPTCHA_KEY = "6LcAgikaAAAAAOTs9Oa8h6Qi_p0-L92W_yxnp0_9";

const LoginContainer = withSuspenseHoc(lazy(() => import("NEW/src/pages/login/login-container")), <Spin />);

export const loginPageRoute = {
	path: ROUTES.LOGIN,
	element: (
		<GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
			<LoginContainer />
		</GoogleReCaptchaProvider>
	),
};
