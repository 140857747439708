import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Tabs from "components/common/tabs";

import LimitsComponent from './limits';
import FavoriteAmounts from './favoriteAmounts';

import { getProjectAvailableCurrencies } from "store/actions/dashboard/projects/projects.action";

import {binaryToFlags, getHashValue} from "utils/common";

import useConfirmation from 'hooks/useConfirmation';
import useProjectType from 'hooks/useProjectType';
import VSBetsAmounts from "pages/projects/edit/sections/favAmountsAndLimits/vsBetAmounts";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import {PROJECT_PROVIDER_TYPE} from "constants/project.constants";

/** Company Edit Page Security Settings Tab Component */
const FavAmountsAndLimitsComponent = ({
    tabId,
    provider,
    getProjectAvailableCurrencies,
    availableCurrencies,
    onTabChange
}) => {
    const { t } = useTranslation();

    const { hasStandartPayment } = useProjectType();

    const isActive = tabId === getHashValue('tab');

    const [currency, setCurrency] = useState(null);

    const { showConfirmationPopup } = useConfirmation({
        title: t("backoffice.common.confirmAction"),
        message: t("backoffice.common.withoutSavingMessage"),
        okText: t("backoffice.common.discard"),
        cancelText: t("backoffice.common.cancel")
    })

    const availableProviders = binaryToFlags(Object.values(PROJECT_PROVIDER_TYPE), provider);
    const virtualSportEnabled = availableProviders.includes(PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS);

    const handleCurrencyChange = (value, isFormTouched, setIsFormTouched) => {
        if (!isFormTouched) {
            setCurrency(value);
            return;
        }

        showConfirmationPopup({
            onConfirm: () => {
                setCurrency(value);
                setIsFormTouched?.(false);
            }
        })
    }

    /** Load available currencies */
    useEffect(() => {
        getProjectAvailableCurrencies();
    }, [])

    useEffect(() => {
        if (Object.keys(availableCurrencies).length > 0) {
            const cur = Object.keys(availableCurrencies)[0];
            handleCurrencyChange(cur, false)
        }
    }, [availableCurrencies])

    const items = [
        {
            title: t("backoffice.projects.limits"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_LIMITS, action: PERMISSION_ACTION.VIEW }],
            component: (
                <LimitsComponent
                    availableCurrencies={availableCurrencies}
                    currency={currency}
                    handleCurrencyChange={handleCurrencyChange}
                />
            )
        },

        {
            title: t("backoffice.projects.favAmounts"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_LIMITS, action: PERMISSION_ACTION.VIEW }],
            disabled: !hasStandartPayment,
            component: (
                <FavoriteAmounts
                    availableCurrencies={availableCurrencies}
                    currency={currency}
                    handleCurrencyChange={handleCurrencyChange}
                />
            )
        },

        {
            title: t("backoffice.projects.vsBetAmount"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_VS_BET_LIMITS, action: PERMISSION_ACTION.VIEW }],
            disabled: !virtualSportEnabled,
            component: (
                <VSBetsAmounts
                    availableCurrencies={availableCurrencies}
                    currency={currency}
                    handleCurrencyChange={handleCurrencyChange}
                />
            )
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
        />
    )
}

/** FavAmountsAndLimitsComponent propTypes
    * PropTypes
*/
FavAmountsAndLimitsComponent.propTypes = {
    /** Tab identifier*/
    tabId: PropTypes.string,
    /** Redux action to get available currencies of project */
    getProjectAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the object of available currencies of company */
    availableCurrencies: PropTypes.object,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getProjectAvailableCurrencies: () => {
            dispatch(getProjectAvailableCurrencies())
        }
    }
)

const mapStateToProps = state => {
    return {
        availableCurrencies: state.projects.availableCurrencies.availableCurrencies,
        provider: state.projects.edit.provider,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavAmountsAndLimitsComponent);
