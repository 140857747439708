import React from 'react';

import { useParams, useLocation } from "react-router-dom";

import Sessions from '../../../../../sessions/sessions.component';

import { USER_TYPE } from "constants/user.constants";
import { PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Agent Edit Page Sessions Tab Component */
const SessionsComponent = () => {
    const searchParams = useParams();

    const { search } = useLocation();

    const entityRole = (new URLSearchParams(search)).get("role");

    return (
        <Sessions
            userRole={Number(entityRole)}
            userId={searchParams.id}
            permissionResource={PERMISSION_RESOURCE.AGENT_SESSIONS}
        />
    )
}

export default SessionsComponent;
