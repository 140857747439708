export const CREATE_MODAL_TYPE = {
    AGENT: 'agent',
    PLAYER: 'player'
}

export const chooserItems = (t) => [
    {
        title: t('backoffice.common.agent'),
        value: CREATE_MODAL_TYPE.AGENT,
        icon: <i className="icon-agent" />
    },
    {
        title: t('backoffice.common.player'),
        value: CREATE_MODAL_TYPE.PLAYER,
        icon: <i className="icon-player" />
    }
];
