//#region React
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
//#endregion

//#region Utils
import { classNames, isFunction } from "utils/common";
//#endregion

const DesktopNavBar = ({
    navBarInstance,
    onItemClick,
    separator = "/",
}) => {
    const { navBarItems, navBarActiveItem, _INTERNAL_ } = navBarInstance;
    const { removeItemsAfterClickedItem } = _INTERNAL_;

    const handleItemInternalClick = (navBarKey, item, e) => {
        removeItemsAfterClickedItem(navBarKey);

        if (isFunction(onItemClick)) {
            onItemClick(item, e);
        }
    }

    return (
        <div className="rt--navigation">
            {navBarItems.map((item, index) => {
                return (
                    <Fragment key={item.navBarTitle}>
                        {index !== 0 && (
                            <span className="rt--navigation-separator">{separator}</span>
                        )}
                        <span
                            className={classNames(
                                "rt--navigation-item",
                                item === navBarActiveItem && "rt--navigation-item-active"
                            )}
                            onClick={(e) => handleItemInternalClick(item.navBarKey, item, e)}
                        >
                            {item.navBarTitle}
                        </span>
                    </Fragment>
                );
            })}
        </div>
    );
}

/** DesktopNavBar propTypes
 * PropTypes
*/
DesktopNavBar.propTypes = {
    navBarInstance: PropTypes.object.isRequired,
    onItemClick: PropTypes.func,
    separator: PropTypes.node,
}

export default DesktopNavBar;