import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Col, Row, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Input from 'components/common/input';
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import {
    getPaymentRequests,
    setPaymentRequestsFilters,
} from 'store/actions/dashboard/payments/paymentRequests/paymentRequests.action';

import { PAYMENT_METHODS, WIDGET_TRANSACTION_TYPE } from 'constants/project.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { DATE_PICKER_RANGES } from 'constants/common.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

const FILTER_FIELDS_NAME = {
    EXTERNAL_ID: "playerExternalId",
    PAYMENT_TYPE: "paymentType",
    TRANSACTION_TYPE: "transactionType",
    BANK_NAME: "bankId",
}

const Filters = ({
    getPaymentRequests,
    filters,
    setPaymentRequestsFilters,
    globalProjectId,
    type
}) => {
    const { t } = useTranslation();

    const [paymentType, setPaymentType] = useState("");

    const [ bankNames, getBankNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BANK, 
        dependencies: [globalProjectId], 
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={() => getPaymentRequests(type)}
            setFiltersFn={filters => setPaymentRequestsFilters(filters, type)}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true }
            ]}
            searchFieldName="userNameOrId"
            onInit={() => {
                getBankNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.payments.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            disabledRanges={[DATE_PICKER_RANGES.YTD]}
                        />
                    </Form.Item>
                </Col>
                {
                    type === WIDGET_TRANSACTION_TYPE.WITHDRAWAL && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.payments.paymentMethod')}
                                name={FILTER_FIELDS_NAME.PAYMENT_TYPE}
                            >
                                <Select
                                    options={[
                                        { value: "", text: t('backoffice.payments.both') },
                                        { value: PAYMENT_METHODS.BANK_TRANSFER, text: t('backoffice.payments.bankTransfer') },
                                        { value: PAYMENT_METHODS.EPOS, text: t('backoffice.payments.epos') },
                                    ]}
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.payments.paymentMethod")}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    onChange={value => setPaymentType(value)}
                                />
                            </Form.Item>
                        </Col>
                    )
                }


                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.payments.playerExternalId')}
                        name={FILTER_FIELDS_NAME.EXTERNAL_ID}
                    >
                        <Input
                            placeholder={`${t("backoffice.common.enter")} ${t("backoffice.payments.playerExternalId")}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.payments.bankName')}
                        name={FILTER_FIELDS_NAME.BANK_NAME}
                        className={paymentType === PAYMENT_METHODS.EPOS ? "rt--form-item-disabled" : ""}
                    >
                        <Select
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.payments.bankName')}`}
                            options={
                                [{value: "", text: t("backoffice.common.all")}]
                                .concat(
                                    bankNames.map(bankName => ({
                                        value: bankName.id,
                                        text: bankName.name
                                    }))
                                )
                            }
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                            disabled={paymentType === PAYMENT_METHODS.EPOS}
                        />
                    </Form.Item>
                </Col>

            </Row>
        </FiltersWrapper>
    );
}

/** Filters propTypes
 * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get payment requests requests */
    getPaymentRequests: PropTypes.func,
    /** Redux state property, payment requests filters */
    filters: PropTypes.object,
    /** Redux action to set payment requests filters */
    setPaymentRequestsFilters: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Transfer type */
    type: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE))
};

const mapDispatchToProps = dispatch => ({
    getPaymentRequests: type => {
        dispatch(getPaymentRequests(type));
    },
    setPaymentRequestsFilters: (filters, type) => {
        dispatch(setPaymentRequestsFilters(filters, type));
    }
});

const mapStateToProps = (state, props) => {
    const type = props.type;
    return {
        filters: state.paymentRequests[type].filters,
        globalProjectId: state.common.globalProjectId,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Filters);