import PropTypes from 'prop-types';

import { PAYMENT_METHODS, WIDGET_TRANSACTION_TYPE } from 'constants/project.constants';
import { TRANSACTION_STATE } from 'constants/transaction.constants';

export default PropTypes.shape({
    agentId : PropTypes.number,
    amount: PropTypes.number,
    amountBefore: PropTypes.number,
    bankId: PropTypes.string,
    location: PropTypes.string,
    changes: PropTypes.arrayOf(PropTypes.shape({
        userId: PropTypes.number,
        amount: PropTypes.number,
        amountBefore: PropTypes.number,
        transactionTime: PropTypes.string,
        notes: PropTypes.string,
        state: PropTypes.number
    })),
    createdAt: PropTypes.string,
    currencyCode: PropTypes.string,
    id: PropTypes.number,
    notes: PropTypes.string,
    paymentType: PropTypes.oneOf(Object.values(PAYMENT_METHODS)),
    playerId: PropTypes.string,
    projectId: PropTypes.number,
    state: PropTypes.oneOf(Object.values(TRANSACTION_STATE)),
    transactionTime: PropTypes.string,
    transactionType: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE)),
    userId: PropTypes.number,
    userInfo: PropTypes.object,
    isAdjustable: PropTypes.bool
})