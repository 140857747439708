import React, { useEffect, Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import TableView from './tableView';
import CardView from './cardView';

import { constructDataForTotals } from 'utils/bet';

import betsTotalsType from 'types/bet/betsTotals.type';

import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';
import { TOTALS_VIEW_TYPE } from './constants';
import { isFunction } from 'utils/common';

const TotalsComponent = ({
    isLoading,
    getTotals,
    dependencies = [],
    totals,
    currencyCode,
    viewType = TOTALS_VIEW_TYPE.TABLE,

    // Table View
    totalType,
    closable = true,

    // Card View
    enableReload = true,
    tooltipText,
}) => {

    const shouldLoadOnOpen = useRef(true);

    const [isPanelOpened, setIsPanelOpened] = useState(viewType === TOTALS_VIEW_TYPE.CARD);

    const totalsData = constructDataForTotals({
        totalsData: totals,
        specificCurrency: currencyCode
    });

    const handleReloadIconClick = () => {
        if (enableReload && isFunction(getTotals)) {
            getTotals()
        }
    }

    const handlePanelOpen = (open) => {
        setIsPanelOpened(open);
    }

    // Load total data
    useEffect(() => {
        shouldLoadOnOpen.current = true;
    }, [...dependencies]);

    // Load total data
    useEffect(() => {
        if (isFunction(getTotals) && isPanelOpened) {
            if(shouldLoadOnOpen.current){
                getTotals();
                shouldLoadOnOpen.current = false;
            }
            
        }
    }, [...dependencies, isPanelOpened]);



    return (
        <>
            {
                viewType === TOTALS_VIEW_TYPE.TABLE
                    ? (
                        <TableView
                            totalType={totalType}
                            tableData={totalsData}
                            closable={closable}
                            isLoading={isLoading}
                            onPanelOpen={handlePanelOpen}
                        />
                    ) : (
                        <CardView
                            totalsData={totalsData}
                            enableReload={enableReload}
                            tooltipText={tooltipText}
                            onReloadIconClick={handleReloadIconClick}
                            isLoading={isLoading}
                        />
                    )
            }
        </>
    );
}

/** TotalsComponent propTypes
 * PropTypes
*/
TotalsComponent.propTypes = {
    totals: betsTotalsType,
    getTotals: PropTypes.func,
    isLoading: PropTypes.bool,
    currencyCode: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    totalType: PropTypes.oneOf(Object.values(REPORT_TOTAL_TYPE)),
    closable: PropTypes.bool,
    viewType: PropTypes.oneOf(Object.values(TOTALS_VIEW_TYPE)),
    enableReload: PropTypes.bool,
    tooltipText: PropTypes.string,
    onReloadIconClick: PropTypes.func,
    dependencies: PropTypes.array,
};

export default TotalsComponent;
