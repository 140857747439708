import React from "react";

import PaymentHistory from "pages/payments/paymentHistory/paymentHistory.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const PaymentHistoryRoute = () => {
    return <PaymentHistory />;
};

export default withPermission(
    withAuth(PaymentHistoryRoute),
    [
        {
            resource: PERMISSION_RESOURCE.PAYMENT_DEPOSIT_TRANSACTION_HISTORY,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PAYMENT_WITHDRAW_TRANSACTION_HISTORY,
            action: PERMISSION_ACTION.VIEW,
        }
    ],
    Paths.DEFAULT
);
