import React from 'react';

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';

import { TRANSFER_OPERATION_GROUP } from 'components/common/transfer';

export const getTableColumns = (additionalProps) => {
    const {
        userInfo,
        hasStandartPayment,
        formatAmount,
        hasLiteMode,
        canTransferToPlayer,
        showTransferPopup,
        hasPlayerGeneralInfoViewPermission
    } = additionalProps;

    return [
        {
            title: 'backoffice.players.id',
            dataIndex: 'id',
            sorter: true,
            copy: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.players.email",
            dataIndex: "email",
            mobileLevel: 2,
            render: value => value || "_",
        },
        {
            title: "backoffice.players.username",
            dataIndex: "userName",
            sorter: true,
            mobileLevel: 3,
        },
        (hasLiteMode && hasPlayerGeneralInfoViewPermission) && {
            title: "backoffice.players.operations",
            dataIndex: "operations",
            alwaysVisible: true,
            disableFillViewTooltip: true,
            mobileLevel: 4,
            render: (_, record) => {
                if (!canTransferToPlayer({ player: record, userInfo, hasStandartPayment })) {
                    return null;
                }

                return (
                    <div className="rt--table-operations">
                        <button onClick={() => showTransferPopup(record, TRANSFER_OPERATION_GROUP.DEPOSIT)}>
                            <i className="icon-deposit"/>
                        </button>
                        <button onClick={() => showTransferPopup(record, TRANSFER_OPERATION_GROUP.WITHDRAWAL)}>
                            <i className="icon-withdrawal"/>
                        </button>
                    </div>
                );
            }
        },
        {
            title: "backoffice.players.firstName",
            dataIndex: "firstName",
            mobileLevel: 5,
            render: value => value || "_",
        },
        {
            title: "backoffice.players.lastName",
            dataIndex: "lastName",
            mobileLevel: 6,
            render: value => value || "_",
        },
        {
            title: "backoffice.players.status",
            dataIndex: "state",
            mobileLevel: 7,
            render: value => <Status status={value} type={STATUS_TYPES.PLAYER} />,
        },
        {
            title: "backoffice.players.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 8,
        },
        ...(
            !hasLiteMode
                ? [{
                    title: "backoffice.players.currency",
                    dataIndex: "currencyCode",
                    mobileLevel: 9,
                }]
                :[]
        ),
        {
            title: "backoffice.common.balance",
            dataIndex: "balance",
            mobileLevel: 10,
            sorter: true,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode),
        },
        {
            title: "backoffice.players.withdrawableBalance",
            dataIndex: "withdrawableBalance",
            mobileLevel: 11,
            sorter: true,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode),
        },
    ]
}
