import {
    SET_PAYOUT_TICKET_ACTION_BEFORE,
    SET_PAYOUT_TICKET_ACTION_FINISH,
    SET_PAYOUT_TICKET,
    SET_CURRENT_PAYOUT_TICKET,
    SET_PAYOUT_TICKET_SAVE_ACTION_BEFORE,
    SET_PAYOUT_TICKET_SAVE_ACTION_FINISH,
    SET_PAYOUT_TICKET_LOGO
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PAYOUT_TICKET_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_PAYOUT_TICKET_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_PAYOUT_TICKET_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_PAYOUT_TICKET_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case SET_PAYOUT_TICKET:
            return {
                ...state,
                payoutTicket: payload.ticket
            }
        case SET_CURRENT_PAYOUT_TICKET:
            return {
                ...state,
                currentPayoutTicket: payload.ticket
            }
        case SET_PAYOUT_TICKET_LOGO:
            return {
                ...state,
                payoutTicket: {
                    ...state.payoutTicket,
                    ticketLogoId: payload.ticketLogoId
                }
            }
        default:
            return state;
    }
};
