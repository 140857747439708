import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import useUserCurrencies from 'hooks/useUserCurrencies';
import useAutosuggestion from 'hooks/useAutosuggestion';
import useProjectType from 'hooks/useProjectType';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import Input from 'components/common/input';
import AutoComplete from "components/common/autoComplete";
import DateRangePicker from "components/common/dateRangePicker";

import { getPlayers, setPlayersFilters } from "store/actions/dashboard/players/players.action";

import { FILTER_PLAYER_STATE } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import { getPossibleParentsName } from "utils/common";
import { getCurrencyFieldOptions } from 'utils/currency';

/** Players Page Filters Component */
const Filters = ({
    setPlayersFilters,
    filters,
    globalProjectId,
    isEmptyData,
    setIsEmptyData,
    getPlayers,
    disabled
}) => {
    const { t } = useTranslation();

    const { hasLiteMode } = useProjectType();

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        autoGet: false
    });

    const [ userCurrencies, getUserCurrencies ] = useUserCurrencies(false);

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    return (
        <FiltersWrapper
            loadFn={() => {
                if(isEmptyData){
                    setIsEmptyData(false)
                } else {
                    getPlayers();
                }

            }}
            setFiltersFn={setPlayersFilters}
            updateProps={[globalProjectId]}
            filters={filters}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false }
            ]}
            searchFieldName={["playerId", "playerUsername"]}
            onInit={() => {
                getAgentNames();
                getUserCurrencies();
            }}
            onReset={() => {
                setIsEmptyData(true)
            }}
            disabled={disabled}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.players.externalId')}
                        name="externalId"
                        className='rt--general-form-item'
                        data-placeholder={`${t("backoffice.common.select")} ${t("backoffice.players.externalId")}`}
                    >
                        <Input
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.players.externalId")}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name="state"
                    >
                        <Select
                            options={[
                                { value: FILTER_PLAYER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_PLAYER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: FILTER_PLAYER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                { value: FILTER_PLAYER_STATE.PARTIALY_BLOCKED, text: t('backoffice.players.partiallyBlocked') },
                                { value: FILTER_PLAYER_STATE.UNKNOWN, text: t('backoffice.players.unknown') }
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.common.status")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                {
                    !hasLiteMode && (
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.players.currency')}
                                name="currencies"
                            >
                                <Select
                                    options={currencyFieldOptions}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.players.currency')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    isMultiple={true}
                                />
                            </Form.Item>
                        </Col>
                    )
                }



                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.players.email')}
                        name="email"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.email')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.email')}`}
                            maxLength={50} />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.players.belongsTo')}
                        name="parentUserNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.belongsTo')}`}
                            items={getPossibleParentsName({
                                globalProjectId,
                                agentNames,
                            })}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to set players filters */
    setPlayersFilters: PropTypes.func,
    /** Redux state property, players filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Is Empty data */
    isEmptyData: PropTypes.bool,
    /** Function to set Empty data */
    setIsEmptyData: PropTypes.func,
    /** Is button disabled */
    disabled: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        setPlayersFilters: filters => {
            dispatch(setPlayersFilters(filters))
        },
        getPlayers: nextPage => {
            dispatch(getPlayers(nextPage))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.players.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
