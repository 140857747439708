import React from 'react';

import PropTypes from 'prop-types';

const TreeNodeTag = ({icon, children}) => {
    return (
        <div className="rt--expandable-tree-node-tag">
            {icon}
            {children}
        </div>
    );
};

TreeNodeTag.propTypes = {
    icon: PropTypes.node,
    children: PropTypes.node
}

export default TreeNodeTag;
