import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from "components/common/tabs";

import PasswordSettingsComponent from './passwordSettings';
import TokenSettingsComponent from './tokenSettings';
import AuthenticationSettingsComponent from './authenticationSettings';
import {getHashValue} from "../../../../../utils/common";

/** Company Edit Page Security Settings Tab Component */
const SecuritySettingsComponent = ({
    tabId,
    onTabChange
}) => {
    const { t } = useTranslation();

    const isActive = tabId === getHashValue('tab');

    const items = [
        {
            title: t("backoffice.companies.passwordSettings"),
            component: <PasswordSettingsComponent/>
        },
        {
            title: t("backoffice.companies.tokenAndmultidevice"),
            component: <TokenSettingsComponent />
        },
        {
            title: t("backoffice.companies.authenticationSettings"),
            component: <AuthenticationSettingsComponent />
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
        />
    )
}

/** SecuritySettingsComponent propTypes
    * PropTypes
*/
SecuritySettingsComponent.propTypes = {
    /** Tab identifier*/
    tabId: PropTypes.string,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default SecuritySettingsComponent;
