import PropTypes from 'prop-types';

import { 
    COMMISSION_PLAN_SOURCE, 
    COMMISSION_PLAN_TYPE,
    COMMISSION_PLAN_CALCULATION_PERIOD,
} from "constants/commission.constants"

import commissionPlanSingleSettingType from './commissionPlanSingleSetting.type';

export default PropTypes.shape({
    name: PropTypes.string,
    currencyCode: PropTypes.string,
    source: PropTypes.oneOf(Object.values(COMMISSION_PLAN_SOURCE)),
    type: PropTypes.oneOf(Object.values(COMMISSION_PLAN_TYPE)),
    sourceDirections: PropTypes.number,
    onlineProviders: PropTypes.arrayOf(PropTypes.string),
    retailProviders: PropTypes.number,
    period: PropTypes.oneOf(Object.values(COMMISSION_PLAN_CALCULATION_PERIOD)),
    id: PropTypes.number,
    nextCalculationDateTime: PropTypes.string,
    settings: PropTypes.arrayOf(commissionPlanSingleSettingType)
})