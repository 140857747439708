import i18n from "translations/config";

export const getTableColumns = (additionalProps) => {
    const {
        onTranslationValueChange,
        havePermissionForEdit,
    } = additionalProps;

    return [
        {
            title: "backoffice.payments.fieldName",
            dataIndex: "text",
            maxLength: 50,
            width: "50%",
            mobileLevel: 1,
        },
        {
            title: "backoffice.payments.value",
            dataIndex: "value",
            editable: havePermissionForEdit,
            maxLength: 100,
            width: "50%",
            mobileLevel: 2,
            editableLabel: () => i18n.t("backoffice.payments.value"),
            editableTitle: () => i18n.t("backoffice.payments.value"),
            onEdit: (newValue, record) => {
                onTranslationValueChange(
                    newValue,
                    record.translationKey
                )
            },
        }
    ];
}