import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import Tooltip from "components/common/tooltip";

import { STATUS_RESOURCES_BY_TYPE } from "./constants";
import {
    STATUS_SIZE,
    STATUS_TOOLTIP_PLACEMENT,
    STATUS_TYPES
} from "constants/status.constants";
import { classNames } from "utils/common";

const Status = ({
    status,
    content,
    type,
    size,
    showIcon = false,
    icon = <i className="icon-info rt--font-big rt--ml-4" />,
    highlighted = true,
    showIndication = false,
    tooltipOptions = {
        title: null,
        placement: null,
    },
    tooltipClassName = "",
    additionalData = {}
}) => {
    const { t } = useTranslation();

    const { color, translationSource } = STATUS_RESOURCES_BY_TYPE[type];

    const statusColor = color?.[status];
    const statusContent = (
        content ?? (
            typeof translationSource?.[status] === 'function'
                ? t(translationSource?.[status](additionalData))
                : t(translationSource?.[status])
        )
    );

    return (
        <Tooltip
            title={
                tooltipOptions?.placement === STATUS_TOOLTIP_PLACEMENT.WHOLE_BLOCK
                    ? tooltipOptions.title
                    : null
            }
            trigger={["hover", "click"]}
            placement="top"
            enableMobile={true}
            className={tooltipClassName}
        >
            <div
                className={classNames(
                    "rt--status",
                    size === STATUS_SIZE.SMALL && "rt--status-small"
                )}
                data-highlight={highlighted ? statusColor : undefined}
                data-indication-color={showIndication ? statusColor : undefined}
            >
                <div className="rt--flex rt--align-center">
                    {showIndication && (
                        <Tooltip
                            title={
                                tooltipOptions?.placement === STATUS_TOOLTIP_PLACEMENT.INDICATION
                                    ? tooltipOptions.title
                                    : null
                            }
                            trigger={["hover", "click"]}
                            placement="top"
                            enableMobile={true}
                            className={tooltipClassName}
                        >
                            <div className='rt--status-indication rt--mr-6' />
                        </Tooltip>
                    )}

                    <span className="rt--status-content rt--title rt--font-small">
                        {statusContent}
                    </span>

                    {showIcon && (
                        <Tooltip
                            title={
                                tooltipOptions?.placement === STATUS_TOOLTIP_PLACEMENT.ICON
                                    ? tooltipOptions.title
                                    : null
                            }
                            trigger={["hover", "click"]}
                            placement="top"
                            enableMobile={true}
                            className={tooltipClassName}
                        >
                            {icon}
                        </Tooltip>
                    )}
                </div>
            </div>
        </Tooltip>
    )
};

/** Status propTypes
 * PropTypes
 */
Status.propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf(Object.values(STATUS_TYPES)),
    icon: PropTypes.node,
    showIcon: PropTypes.bool,
    highlighted: PropTypes.bool,
    showIndication: PropTypes.bool,
    content: PropTypes.node,
    size: PropTypes.oneOf(Object.values(STATUS_SIZE)),
    tooltipOptions: PropTypes.shape({
        placement: PropTypes.oneOf(Object.values(STATUS_TOOLTIP_PLACEMENT)),
        title: PropTypes.node,
    }),
};

export default Status;
