import {useEffect, useState} from "react";
import {FINANCIAL_DASHBOARD_FILTER_NAMES} from "pages/dashboard/constants";
import {getFinancialLineChartData, getRetailLineChartData} from "pages/dashboard/api";


const useRetailLineChart = (dataType, filters, updateLastUpdatedDate, isActive) => {
    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: [],
    })

    useEffect(() => {
        // debugger;
        if (!filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]) {
            return;
        }

        if (isActive !== undefined && !isActive) {
            return;
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getRetailLineChartData({
            filterType: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE],
            currencyCode: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY],
            timePeriod: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD],
            betShopNameOrId: filters.betShopNameOrId,
            dataType,
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;

                updateLastUpdatedDate(payload.lastUpdateAt)
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        chartData: payload.data,
                        isLoading: false,
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [dataType, filters, updateLastUpdatedDate, isActive]);

    return chartState;
}

export default useRetailLineChart;
