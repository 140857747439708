import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

import { getAgentBankWidget, setAgentBankWidget } from "store/actions/dashboard/agentSystem/agents/paymentsConfig.action";

import { WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

/** Widget Preview Component */
const WidgetPreviewComponent = ({
    widget,
    getAgentBankWidget,
    resetAgentBankWidget,
    languageCode,
    transactionType
}) => {

    const { search } = useLocation();

    const currentBankId = (new URLSearchParams(search)).get("bankId");

    /** Get widget data */
    useEffect(() => {
        if (languageCode) {
            getAgentBankWidget(transactionType, languageCode);
        }
    }, [languageCode])

    const widgetURL = useMemo(() => widget[transactionType] === null || languageCode === null ? null :
        (widget[transactionType] + "&isMobile=true&isPreview=true&fromAgent=true&bankId=" + currentBankId + "&LanguageCode=" + languageCode), [widget[transactionType]]);

    /** Reset Widget */
    useEffect(() => {
        return () => {
            resetAgentBankWidget(transactionType)
        }
    }, [])

    return (
        <div className='rt--payment-widget'>
            {
                widgetURL && (
                    <iframe
                        id={"payment-widget-iframe-" + transactionType}
                        title="Widget"
                        frameBorder="0"
                        src={widgetURL}
                    />
                )
            }

        </div>
    );
}

/** WidgetPreviewComponent propTypes
    * PropTypes
*/
WidgetPreviewComponent.propTypes = {
    /** Redux action to get agent bank widget url */
    getAgentBankWidget: PropTypes.func,
    /** Redux action to reset agent bank widget url */
    resetAgentBankWidget: PropTypes.func,
    /** Redux state property, current bank widget data */
    widget: PropTypes.shape({
        [WIDGET_TRANSACTION_TYPE.DEPOSIT]: PropTypes.string,
        [WIDGET_TRANSACTION_TYPE.WITHDRAWAL]: PropTypes.string,
    }),
    /** Registration Form Type */
    transactionType: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE)),
    /** Language code */
    languageCode: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAgentBankWidget: (transactionType, languageCode) => {
            dispatch(getAgentBankWidget(transactionType, languageCode));
        },
        resetAgentBankWidget: transactionType => {
            dispatch(setAgentBankWidget(transactionType, null));
        }
    }
)

const mapStateToProps = state => {
    return {
        widget: state.agents.edit.payment.banks.edit.widget
    }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(WidgetPreviewComponent));