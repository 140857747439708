import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { SET_PROJECT_INTEGRATION } from "../../../actionTypes";

const setProjectIntegration = integration => ({
    type: SET_PROJECT_INTEGRATION,
    payload: { integration }
})

export const getProjectIntegration = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_INTEGRATION,
            method: Methods.GET
        })
        .then(({data : {value: integration}}) => {
            dispatch(setProjectIntegration(integration))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectIntegration = integration => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_INTEGRATION,
            method: Methods.POST,
            data: {
                ...integration
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectIntegration(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
