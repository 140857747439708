import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.number,
    currencyCode: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    provider: PropTypes.string,
    playerExternalId: PropTypes.string,
    playerUserName: PropTypes.string,
    agentId: PropTypes.number,
    agentUserName: PropTypes.string,
    turnover: PropTypes.number,
    won: PropTypes.number,
    ggr: PropTypes.number,
    ngr: PropTypes.number,
    bonusTurnover: PropTypes.number,
    bonusWon: PropTypes.number,
    bonusGGR: PropTypes.number
})