import React, { Fragment } from "react";
import PropTypes from "prop-types";

import {Button, Menu} from "antd";

import Select from "components/common/select";
import Search from "components/common/search";
import ExportButton from "components/common/exportButton";
import ColumnsButton from "components/common/columnsButton";
import Dropdown from "components/common/dropdown";

import { isMobile, classNames } from "utils/common";

import buttonType from 'types/components/button.type';
import exportButtonType from 'types/components/exportButton.type';
import columnsButtonType from 'types/components/columnsButton.type';

import searchType from 'types/components/search.type';

/** Sub Tab Table dashboard layout */
const SubTabTableDashboardLayout = ({
    children,
    header,
    extraContent,
    dropdowns,
    button
}) => {

    /** Function to Render dropdown
         * @function
         * @returns {JSX}
         * @memberOf SubTabTableDashboardLayout
    */
    const renderDropdown = dropdown => (
        <Select
            options={
                dropdown.items.map(item => (
                    { value: item.key, text: item.value }
                ))
            }
            placeholder={dropdown.placeholder}
            value={dropdown.value}
            onChange={dropdown.onChange}
            isDark={!isMobile()}
            className={
                "rt--subtab-table-layout-actions-dropdown" +
                (dropdown.small ? " rt--subtab-table-layout-actions-dropdown-small" : "")
            }
            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
        />
    )

    return (
        <div className="rt--subtab-table-layout">
            <div className="rt--subtab-table-layout-inner rt--flex rt--flex-col">
                {
                    (
                        header && (
                            (header.button && header.button.enabled) ||
                            (header.export && !isMobile()) ||
                            (header.columns && !isMobile()) ||
                            (header.search && !isMobile()) ||
                            (header.filters && !isMobile()) ||
                            header.content || dropdowns
                        )
                    ) && (
                        <div className={"rt--subtab-table-layout-header rt--flex rt--align-center rt--justify-between " + (!isMobile() ? "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16" : "rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12")}>

                            {
                                header.content ? header.content : (
                                    <Fragment>
                                        {
                                            !isMobile() && (
                                                <div className="rt--subtab-table-layout-header-search">
                                                    {
                                                        header.search && (
                                                            <Search
                                                                {...header.search}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                        <div className="rt--subtab-table-layout-header-actions rt--flex rt--align-center rt--justify-end rt--flex-equal">

                                            {
                                                !isMobile() && dropdowns && dropdowns.map((dr, index) => (
                                                    <div className="rt--ml-16" key={index}>
                                                        {renderDropdown(dr)}
                                                    </div>
                                                ))
                                            }

                                            {
                                                !isMobile() && (
                                                    <Fragment>
                                                        {
                                                            header.columns && (
                                                                <ColumnsButton
                                                                    {...header.columns}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            header.export && (
                                                                <ExportButton
                                                                    {...header.export}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            header.filters ? header.filters : null
                                                        }
                                                    </Fragment>
                                                )
                                            }
                                            {
                                                header.button && header.button.enabled ? (
                                                    header.button.dropdown ?
                                                        (
                                                            <Dropdown
                                                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                                menu={{
                                                                    onClick: e => e.key && header.button.dropdown.onClick && header.button.dropdown.onClick(e.key),
                                                                    items: (
                                                                        header.button.dropdown.items
                                                                            .map(i => (
                                                                                {
                                                                                    key: i,
                                                                                    label: <span className='rt--title rt--font-normal rt--font-regular'>{header.button.dropdown.renderText(i)}</span>
                                                                                }
                                                                        ))
                                                                    )
                                                                }}
                                                                disabled={header.button.disabled ? true : false}
                                                                button={{
                                                                    icon: "icon-plus",
                                                                    onClick: header.button.onClick,
                                                                    className: `rt--button-dropdown ${Boolean(header.columns) && !isMobile() ? "rt--ml-16" : "" }`
                                                                }}
                                                                title={header.button.text}
                                                            >
                                                                {
                                                                    header.button.text
                                                                }
                                                            </Dropdown>
                                                        ) :
                                                        (
                                                            <Button
                                                                icon={<i className={header.button.icon + " rt--font-bigest"}></i>}
                                                                type={header.button.type}
                                                                className={classNames(
                                                                    "rt--button",
                                                                    !isMobile() && "rt--ml-16",
                                                                    (header.button.type === "primary" ? "rt--button-main" : ""),
                                                                    header.button.className,
                                                                )}
                                                                onClick={header.button.onClick}
                                                                disabled={header.button.disabled ? true : false}
                                                            >
                                                                {
                                                                    header.button.text
                                                                }

                                                            </Button>
                                                        )
                                                ) : null
                                            }
                                        </div>
                                    </Fragment>
                                )
                            }


                        </div>
                    )
                }
                {
                    isMobile() && header && (
                        (header.button && header.button.enabled && header.content) ||
                        header.filters || header.search
                    ) && (
                        <div className="rt--subtab-table-layout-header-mobile-actions rt--flex rt--justify-end rt--align-center rt--pr-12 rt--pt-8">
                            {
                                header.button && header.button.enabled && header.content && (
                                    <Button
                                        icon={<i className={header.button.icon + " rt--font-bigest"}></i>}
                                        type={header.button.type}
                                        className={
                                            "rt--button " +
                                            (!isMobile() ? "rt--ml-16 " : "rt--ml-8 ") +
                                            (header.button.className ? header.button.className : "") +
                                            (header.button.type === "primary" ? "rt--button-main" : "")
                                        }
                                        onClick={header.button.onClick}
                                        disabled={header.button.disabled ? true : false}
                                    ></Button>
                                )
                            }

                            {
                                header.filters ? header.filters : null
                            }
                            {
                                header.search && (
                                    <Search
                                        {...header.search}
                                    />
                                )
                            }
                        </div>
                    )
                }
                <div className="rt--subtab-table-layout-content rt--flex rt--flex-col rt--flex-equal">
                    {
                        (
                            (button && button.enabled) ||
                            isMobile() && dropdowns
                        ) && (
                            <div className={isMobile() ? "rt--pl-12 rt--pr-12 rt--pb-16" : "rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24"}>
                                {
                                    button && button.enabled && (
                                        <div className="rt--subtab-table-layout-content-header rt--align-center rt--flex rt--jsutify-between rt--mb-16">
                                            <Button
                                                icon={<i className={button.icon + " rt--font-bigest"}></i>}
                                                type={button.type}
                                                className={classNames(
                                                    "rt--button",
                                                    button.type === "primary" ? "rt--button-main" : "",
                                                    button.className,
                                                )}
                                                onClick={button.onClick}
                                                disabled={button.disabled ? true : false}
                                            >
                                                {
                                                    button.text
                                                }

                                            </Button>
                                        </div>
                                    )
                                }
                                {
                                    isMobile() && dropdowns && (
                                        <div className="rt--subtab-table-layout-content-extra rt--flex rt--align-center">
                                            {
                                                dropdowns.map((dr, index) => (
                                                    <Fragment key={index}>
                                                        {renderDropdown(dr)}
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                    )
                                }

                            </div>
                        )
                    }
                    {
                        extraContent && (
                            <div className={"rt--subtab-table-layout-content-extra " + (!isMobile() ? " rt--pl-24 rt--pr-24 rt--pb-16" : " rt--pl-16 rt--pr-16")}>
                                {extraContent}
                            </div>
                        )
                    }
                    <div
                        className={
                            "rt--subtab-table-layout-content-table  rt--flex-equal rt--main-scrollable " +
                            (isMobile() ? "rt--pb-16 rt--pl-16 rt--pr-16 rt--pt-16" : "rt--pb-24 rt--pl-24 rt--pr-24")
                        }
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

/** SubTabTableDashboardLayout propTypes
 * PropTypes
*/
SubTabTableDashboardLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node,
    /** Header */
    header: PropTypes.shape({
        /** Header button */
        button: PropTypes.shape(buttonType),
        /** Search options */
        search: PropTypes.shape(searchType),
        /** Filters component */
        filters: PropTypes.node,
        /** Columns Button */
        columns: PropTypes.shape(columnsButtonType),
        /** Export Button */
        export: PropTypes.shape(exportButtonType),
        /** Header content */
        content: PropTypes.node
    }),
    /** Extra content under table */
    extraContent: PropTypes.node,
    /** Dropdowns options */
    dropdowns: PropTypes.arrayOf(PropTypes.object),
    /** Content Header button */
    button: PropTypes.shape(buttonType),
}

export default SubTabTableDashboardLayout;
