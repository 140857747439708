//#region react
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getCashiers,
    setCashiersSorting,
    setCashiersFilters,
    forceLogout,
    forceLogoutSingleCashier,
} from "store/actions/dashboard/retail/cashiers/cashiers.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Confirmation from "components/common/confirmation";
import Filters from "./filters.component";
import CashierCreateComponent from "./cashier-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { isMobile } from 'utils/common';
import { hasPermission, hasOneOfPermissions } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { CASHIERS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import cashierType from "types/cashier/cashier.type";
import sortingType from "types/common/sorting.type";
//#endregion

const ROW_UNIQUE_KEY_PROP = "userName";

const CashiersComponent = ({
    getCashiers,
    cashiers,
    isLoading,
    total,
    setCashiersSorting,
    setCashiersFilters,
    sorting,
    filters,
    forceLogout,
    forceLogoutSingleCashier,
    globalCompanyId,
    globalProjectId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);
    const [isForceLogoutPopupVisible, setIsForceLogoutPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: CASHIERS });

    const isMobileDevice = isMobile();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.CASHIER,
        action: PERMISSION_ACTION.EXPORT,
    });

    const hasCashersForceLogoutPermission = hasPermission({
        resource: PERMISSION_RESOURCE.CASHIER_FORCE_LOGOUT,
        action: PERMISSION_ACTION.MODIFY
    })

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.CASHIER,
        action: PERMISSION_ACTION.CREATE
    })

    const hasGeneralInfoOrAdditionalAccessOrSessionViewPermissions = hasOneOfPermissions([
        {
            resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.CASHIER_ADDITIONAL_ACCESS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.CASHIER_SESSIONS,
            action: PERMISSION_ACTION.VIEW,
        },
    ])

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setCashiersFilters({
            ...filters,
            userNameOrId: value ? item ? item.id : value : ""
        })
    }

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    const showForceLogoutPopup = () => {
        setIsForceLogoutPopupVisible(true);
    }

    const hideForceLogoutPopup = () => {
        setIsForceLogoutPopupVisible(false);
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.CASHIERS_EDIT}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.userName}`
        )
    }

    const handleForceLogoutSingleCashierClick = (record) => {
        forceLogoutSingleCashier(record.id);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasCashersForceLogoutPermission) {
        tableRowActions.push({
            title: t('backoffice.cashiers.forceLogout'),
            icon: "icon-logout",
            showConfirmation: true,
            confirmationTitle: t("backoffice.cashiers.forceLogout"),
            confirmationMessage: t("backoffice.cashiers.singleCashierForceLogoutConfirmationMessage"),
            onClick: handleForceLogoutSingleCashierClick,
            disabled: record => !record.isOnline,
        })
    }

    if (hasGeneralInfoOrAdditionalAccessOrSessionViewPermissions) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick,
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getCashiers,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.CASHIER,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.cashiers.userNameOrId")
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        buttons: [
            {
                icon: (isMobileDevice ? "icon-logout" : null),
                type: "secondary",
                className: 'rt--button-secondary',
                onClick: showForceLogoutPopup,
                text: t("backoffice.cashiers.forceLogout"),
                enabled: hasCashersForceLogoutPermission
            },

            {
                icon: "icon-plus",
                type: "primary",
                onClick: showCreatePopup,
                text: t("backoffice.cashiers.createCashier"),
                enabled: hasCreatePermission
            }
        ],
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.cashiers') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.cashiers"),
            url: ApiUrls.EXPORT_CASHIERS,
            filters: filters
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={cashiers}
                loadFn={getCashiers}
                sorting={sorting}
                setSortingFn={setCashiersSorting}
                total={total}
                updateProps={[globalProjectId]}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                actions={tableRowActions}
            />

            <Confirmation
                title={t('backoffice.cashiers.forceLogout')}
                message={t('backoffice.cashiers.forceLogoutConfirmationMessage')}
                onOk={forceLogout}
                onCancel={hideForceLogoutPopup}
                isVisible={isForceLogoutPopupVisible}
            />

            {isCreatePopupVisible && <CashierCreateComponent onClose={hideCreatePopup} />}
        </MainDashboardLayout>
    )
};

/** CashiersComponent propTypes
 * PropTypes
*/
CashiersComponent.propTypes = {
    /** Redux action to get cashiers */
    getCashiers: PropTypes.func,
    /** Redux state property, represents the array of cashiers  */
    cashiers: PropTypes.arrayOf(cashierType),
    /** Redux state property, is true when loading cashiers */
    isLoading: PropTypes.bool,
    /** Redux state property, cashiers total count */
    total: PropTypes.number,
    /** Redux action to set cashiers sorting details */
    setCashiersSorting: PropTypes.func,
    /** Redux action to set cashiers sorting details */
    setCashiersFilters: PropTypes.func,
    /** Redux state property, cashiers sorting details */
    sorting: sortingType,
    /** Redux state property, cashiers filters */
    filters: PropTypes.object,
    /** Redux action to force logout */
    forceLogout: PropTypes.func,
    /** Redux action to force logout single cashier */
    forceLogoutSingleCashier: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getCashiers: nextPage => {
        dispatch(getCashiers(nextPage));
    },
    setCashiersSorting: sorting => {
        dispatch(setCashiersSorting(sorting));
    },
    setCashiersFilters: filters => {
        dispatch(setCashiersFilters(filters));
    },
    forceLogout: () => {
        dispatch(forceLogout());
    },
    forceLogoutSingleCashier: id => {
        dispatch(forceLogoutSingleCashier(id));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.cashiers.isLoading,
        cashiers: state.cashiers.cashiers,
        total: state.cashiers.total,
        sorting: state.cashiers.sorting,
        filters: state.cashiers.filters,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CashiersComponent);
