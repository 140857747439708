import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";

import Tooltip from "components/common/tooltip";

import { classNames, isFunction, copyToClipboard, isRTL } from "utils/common";

import { COPY_SIZES } from '../../constants';

import LanguageUtils from 'utils/languages';

const ColumnWithCopyButton = ({
    value,
    onClick,
    size = COPY_SIZES.DEFAULT
}) => {
    const { t } = useTranslation();

    const currentLn = LanguageUtils.getSelectedLanguage();

    const handleInternalClick = (text, e) => {
        e.stopPropagation();

        copyToClipboard(text);

        if (isFunction(onClick)) {
            onClick(text, e);
        }
    }

    return (
        <div
            className={classNames(
                "rt--table-col",
                "rt--flex",
                "rt--align-center",
                "rt--table-col-copy",
                size === COPY_SIZES.MIDDLE && "rt--table-col-copy-large"
            )}
        >
             <Tooltip title={value} placement={(isRTL(currentLn) ? 'left' : 'right')}>
                    <span
                        className="ok rt--title rt--font-normal rt--font-regular rt--flex-equal"
                    >
                        {value}
                    </span>
            </Tooltip>
           
            <div
                className="rt--table-col-button rt--ml-8"
                onClick={(e) => handleInternalClick(value, e)}
            >
                <Tooltip title={t("backoffice.common.copy") + " ID"}>
                    <i className="icon-copy rt--font-bigest" />
                </Tooltip>
            </div>
        </div>
    );
}

/** ColumnWithCopyButton propTypes
 * PropTypes
*/
ColumnWithCopyButton.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.string,
    value: PropTypes.node
}

export default ColumnWithCopyButton;