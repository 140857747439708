import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setCommissionPlansActionBefore, setCommissionPlansActionFinished, setCommissionPlansSaveActionBefore, setCommissionPlansSaveActionFinished } from './commissionPlans.action';

import { SET_COMMISSION_PLAN_SPORT_SETTINGS } from "../../../../actionTypes";

const setCommissionPlanSportSettings = (data, type) => ({
    type: SET_COMMISSION_PLAN_SPORT_SETTINGS,
    payload: { data, type }
})

export const getCommissionPlanSportSettings = (id, agentId, type)  => {
    return dispatch => {
        dispatch(setCommissionPlansActionBefore());

        return axios({
            url: ApiUrls.GET_COMMISSION_PLAN_SPORT_SETTINGS,
            method: Methods.GET,
            params: {
                id, agentId, type 
            }
        })
        .then(({data : {value: settings}}) => {
            dispatch(setCommissionPlanSportSettings(settings, type))
            dispatch(setCommissionPlansActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansActionFinished());
        })
    }
}

export const saveCommissionPlanSportSettings = (settings, type) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_COMMISSION_PLAN_SPORT_SETTINGS,
            method: Methods.POST,
            data: {
                ...settings
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                const stngs = data.value.settings.filter(s => s.type === type);
                dispatch(setCommissionPlanSportSettings(stngs, type));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}
