import i18n from "translations/config";

import { BET_TYPE } from "constants/bet.constants";
import { PROJECT_PROVIDER_TYPE } from "constants/project.constants";

const BET_TYPE_NAMES = {
    [BET_TYPE.SINGLE]: "single",
    [BET_TYPE.MULTI]: "multi",
}

const PROJECT_PROVIDER_TYPE_NAMES = {
    [PROJECT_PROVIDER_TYPE.SPORTBOOK]: "Sportsbook",
    [PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS]: "DGVirtuals",
}

export const getTableColumns = (additionalProps) => {
    const {
        formatAmount,
        isNonAnonymousBetsActive
    } = additionalProps;

    return [
        {
            title: "backoffice.bets.betslipId",
            dataIndex: "id",
            copy: true,
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        isNonAnonymousBetsActive && {
            title: "backoffice.bets.playerId",
            dataIndex: "playerId",
            copy: true,
        },
        isNonAnonymousBetsActive && {
            title: "backoffice.bets.playerUserName",
            dataIndex: "playerUserName",
        },
        {
            title: 'backoffice.bets.date',
            dataIndex: 'betTime',
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.bets.provider",
            dataIndex: "provider",
            sorter: true,
            mobileLevel: 3,
            render: value => (
                Boolean(PROJECT_PROVIDER_TYPE_NAMES[value])
                    ? i18n.t(`backoffice.common.${PROJECT_PROVIDER_TYPE_NAMES[value]}`)
                    : ""
            ),
        },
        {
            title: "backoffice.bets.type",
            dataIndex: "betType",
            sorter: true,
            mobileLevel: 4,
            render: value => (
                Boolean(BET_TYPE_NAMES[value])
                    ? i18n.t(`backoffice.bets.${BET_TYPE_NAMES[value]}`)
                    : i18n.t("backoffice.bets.system")
            ),
        },
        {
            title: 'backoffice.bets.amount',
            dataIndex: 'amount',
            sorter: true,
            mobileLevel: 5,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime)
        },
        {
            title: 'backoffice.bets.currency',
            dataIndex: 'currencyCode',
            sorter: true,
            mobileLevel: 6,
        },
        {
            title: 'backoffice.bets.odds',
            dataIndex: 'factor',
            render: (value, record) => (
                (record.betType === BET_TYPE.SINGLE && record.bets.length > 1)
                    ? "-"
                    : Number(value).toFixed(2) + "x"
            )
        },
        {
            title: 'backoffice.bets.possibleWin',
            dataIndex: 'possibleWin',
            sorter: true,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime)
        },
        {
            title: 'backoffice.bets.placedBy',
            dataIndex: 'userName',
            alwaysVisible: true,
        },
        {
            title: 'backoffice.bets.betshopId',
            dataIndex: 'projectId',
        },
        {
            title: 'backoffice.bets.betshopName',
            dataIndex: 'projectName',
        },
    ];
}