import React from "react";

import { useTranslation } from "react-i18next";

import Tabs from "components/common/tabs";

import AggregatedComponent from "./aggregated/aggregated.component";
import OnlineBetsComponent from "./onlineBets/onlineBets.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Online Bet History Page Component */
const BetHistoryComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.bets.aggregated"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETS, action: PERMISSION_ACTION.VIEW }],
            component: <AggregatedComponent />
        },
        {
            title: t("backoffice.bets.sportBets"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETS, action: PERMISSION_ACTION.VIEW }],
            component: <OnlineBetsComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={[
                { title: t('backoffice.menu.betHistory') },
                { title: t('backoffice.menu.online') }
            ]}
        />
    )
};

export default BetHistoryComponent
