import React, { useEffect, useState } from 'react';

import PropTypes from "prop-types";

import { connect } from "react-redux";
import axios from "axios";

import { Spin } from "antd";

import Tree from "components/common/tree";

import { mapToTreeNodes } from "pages/agentSystem/network/sections/networkTree/helpers/mapToTreeNodes";
import { setAgentNodes } from "pages/agentSystem/network/sections/networkTree/helpers/setAgentNodes";
import { getLastNodeId } from "pages/agentSystem/network/sections/networkTree/helpers/getLastNodeId";

import { getRootNodes } from "pages/agentSystem/network/sections/networkTree/api";

import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

const NetworkTree = ({ globalProjectId, agentId, playerId }) => {
    const [network, setNetwork] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [loadedKeys, setLoadedKeys] = useState([]);

    const loadAll = !agentId && !playerId

    useEffect(() => {
        if (loadAll) {
            setIsLoading(true);
            setExpandedKeys([]);
            setLoadedKeys([]);

            loadNodes()
                .then(({data: { value }}) => {
                    setNetwork(mapToTreeNodes([value]));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [globalProjectId, loadAll]);

    useEffect(() => {
        if (agentId || playerId) {
            setIsLoading(true);

            getRootNodes(agentId, playerId).then(({data: {value}}) => {
                const precessedKeys = [];
                const lastNodeId = getLastNodeId(value);

                const extraMapper = (node) => {
                    if (node.id === lastNodeId) {
                        return {
                            className: 'rt--expandable-tree-item__selected'
                        };
                    }

                    precessedKeys.push(node.id);

                    return ({
                        className: 'rt--expandable-tree-hide-expand',
                    });
                }

                const newNetwork = mapToTreeNodes([value], extraMapper);

                setNetwork(newNetwork);
                setExpandedKeys(precessedKeys);
                setLoadedKeys(precessedKeys);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [agentId, playerId]);

    const loadNodes = (id = '') => {
        return axios({
            url: ApiUrls.GET_NETWORK_SUB_NODES,
            method: Methods.GET,
            params: { id, includeBetShops: true, includePlayers: true },
        })
    }

    const handleLoadAgentNodes = ({key}) => {
        return new Promise(res => {
            loadNodes(key).then(({data: { value }}) => {
                setNetwork(setAgentNodes(key, network, mapToTreeNodes(value.nodes)));

                res();
            });
        })
    }

    const handleNodeExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys);
    }

    const handleNodeLoad = (loadedKeys) => {
        setLoadedKeys(loadedKeys);
    }

    return (
        <Spin
            spinning={isLoading}
            wrapperClassName="rt--height-full rt--network-tree-spin-wrapper"
        >
            <div className="rt--overflow-x rt--height-full">
                <div className="rt--width-full">
                    <Tree
                        showLine
                        blockNode
                        selectable={false}
                        data={network}
                        expandedKeys={expandedKeys}
                        loadedKeys={loadedKeys}
                        loadData={handleLoadAgentNodes}
                        onExpand={handleNodeExpand}
                        onLoad={handleNodeLoad}
                    />
                </div>
            </div>
        </Spin>
    );
};

NetworkTree.propTypes = {
    globalProjectId: PropTypes.string,
    agentId: PropTypes.string,
    playerId: PropTypes.string
}

const mapStateToProps = (state) => {
    return {
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(mapStateToProps)(NetworkTree);
