import { binaryToFlags, isFranchisingMode } from "utils/common";
import { hasOneOfPermissions, hasPermission } from 'utils/permissions';
import { getUser } from "utils/auth";

import { PROJECT_TYPE } from "constants/project.constants";
import { USER_ROLE, USER_TYPE } from "constants/user.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Check if has access to project edit page
     * @function
     * @param {number} type - project type
     * @returns {boolean}
 */
export const hasProjectEditPageAccess = type => {
    return hasOneOfPermissions([
        { resource: PERMISSION_RESOURCE.PROJECT_CURRENCIES, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_WALLETS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_LIMITS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_PAYMENT, action: PERMISSION_ACTION.VIEW }
    ]) || 
    (
        hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.VIEW }) &&
        binaryToFlags(Object.values(PROJECT_TYPE), Number(type)).includes(PROJECT_TYPE.RETAIL_SYSTEM)
    ) || 
    (
        hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_REGULATIONS, action: PERMISSION_ACTION.VIEW }) &&
        binaryToFlags(Object.values(PROJECT_TYPE), Number(type)).includes(PROJECT_TYPE.RETAIL_SYSTEM)
    ) || 
    (
        (
            hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW }) ||
            (
                hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_AGENT_SYSTEM, action: PERMISSION_ACTION.VIEW }) &&
                !isFranchisingMode()
            )
        ) && binaryToFlags(Object.values(PROJECT_TYPE), Number(type)).includes(PROJECT_TYPE.AGENT_SYSTEM)
    )
}

/** Check if has access to agent edit page
     * @function
     * @param {number} role - agent role
     * @returns {boolean}
 */
 export const hasAgentEditPageAccess = role => {
    return hasOneOfPermissions([
        { resource: PERMISSION_RESOURCE.AGENT_CURRENCIES, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AGENT_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: getUser()?.role === USER_ROLE.ADMIN ? PERMISSION_RESOURCE.AGENT_WALLETS : PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AGENT_SESSIONS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AGENT_PERMISSIONS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AGENT_PAYMENT, action: PERMISSION_ACTION.VIEW }
    ]) || (
        hasPermission({ resource: PERMISSION_RESOURCE.AGENT_LIMITS, action: PERMISSION_ACTION.VIEW }) &&
        ( role === USER_TYPE.AGENT || !role)
    ) || (
        hasPermission({ resource: PERMISSION_RESOURCE.AGENT_ADDITIONAL_ACCESSES, action: PERMISSION_ACTION.VIEW }) &&
        !isFranchisingMode() && ( role === USER_TYPE.AGENT || !role)
    )
}

/** Check if has access to admin edit page
     * @function
     * @returns {boolean}
 */
 export const hasAdminEditPageAccess = () => {
    return hasOneOfPermissions([
        { resource: PERMISSION_RESOURCE.ADMIN_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_PERMISSIONS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_SESSIONS, action: PERMISSION_ACTION.VIEW }
    ]) || (
        hasPermission({ resource: PERMISSION_RESOURCE.ADMIN_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.VIEW }) &&
        !isFranchisingMode()
    )
}

/** Check if has access to access manager edit page
     * @function
     * @returns {boolean}
 */
export const hasAccessManagerEditPageAccess = () => {
    return hasOneOfPermissions([
        { resource: PERMISSION_RESOURCE.ACCESS_MANAGER_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ACCESS_MANAGER_SESSIONS, action: PERMISSION_ACTION.VIEW }
    ]) 
}