import React from 'react';
import i18n from "translations/config";

import { isMobile, toUpperCaseFirstLetter } from "utils/common";

import { SOCIAL_MEDIA_TYPE } from 'constants/payments.constants';

const EPOS_TRANSLATION_TYPE = {
    CITY: 1 << 0,
    ADDRESS: 1 << 1,
}

const renderTime = (openCloseTime, editable, dateService) => {
    const { openTime, closeTime } = openCloseTime;

    if (!openTime || !closeTime) {
        return [null, null];
    }

    const { hour: openHour = 0, minute: openMin = 0 } = openTime;
    const { hour: closeHour = 23, minute: closeMin = 59 } = closeTime;

    const openDate = dateService.getDate(`${openHour}:${openMin}`, "HH:mm");
    const closeDate = dateService.getDate(`${closeHour}:${closeMin}`, "HH:mm")

    if (isMobile() || !editable) {
        return `${dateService.toString(openDate, "HH:mm")} - ${dateService.toString(closeDate, "HH:mm")}`
    }

    return [
        openDate,
        closeDate,
    ];
}

export const getScheduleTableColumns = (additionalProps) => {
    const {
        havePermissionForEdit,
        onScheduleFieldChange,
        dateService
    } = additionalProps;

    return [
        {
            title: "backoffice.payments.dayOfWeek",
            dataIndex: "dayOfWeek",
            width: 200,
            mobileLevel: 1,
            render: (weekDay) => toUpperCaseFirstLetter(weekDay),
        },
        {
            title: "backoffice.payments.workingHours",
            dataIndex: "workingHours",
            editable: havePermissionForEdit,
            mobileLevel: 2,
            editableFieldType: "timePicker",
            render: value => renderTime(value, havePermissionForEdit, dateService),
            editableLabel: () => i18n.t("backoffice.payments.workingHours"),
            editableTitle: () => i18n.t("backoffice.payments.scheduleOfWork"),
            onEdit: (timeRange, rowData) => {
                onScheduleFieldChange({
                    timeRange,
                    dayOfWeek: rowData.dayOfWeek
                });
            },
        }
    ];
}

export const getContactsTableColumns = (additionalProps) => {
    const {
        havePermissionForEdit,
        onContactsFieldChange,
        eposSettings,
    } = additionalProps;

    return [
        {
            title: "backoffice.payments.channel",
            dataIndex: "socialMediaType",
            width: 200,
            mobileLevel: 1,
            render: value => (
                value === SOCIAL_MEDIA_TYPE.TELEGRAM
                    ? i18n.t("backoffice.payments.telegram")
                    : value === SOCIAL_MEDIA_TYPE.WHATSAPP
                        ? i18n.t("backoffice.payments.whatsapp")
                        : ""
            )
        },
        {
            title: "backoffice.payments.contactNumberOrUsername",
            dataIndex: "linkInfo",
            editable: havePermissionForEdit,
            maxLength: 30,
            mobileLevel: 2,
            render: value => Boolean(value) ? value : "-",
            editableLabel: () => i18n.t("backoffice.payments.contactNumberOrUsername"),
            editableTitle: () => i18n.t("backoffice.payments.contactNumberOrUsername"),
            onEdit: (value, record) => {
                const contacts = [];

                Object.values(SOCIAL_MEDIA_TYPE).forEach(socialMediaType => {
                    if (record.socialMediaType === socialMediaType) {
                        if (value && value !== "-") {
                            contacts.push({
                                socialMediaType: socialMediaType,
                                linkInfo: value
                            })
                        }
                    } else {
                        const c = eposSettings.contacts.find(contact => contact.socialMediaType === socialMediaType)
                        if (c) {
                            contacts.push({ ...c })
                        }
                    }
                })

                onContactsFieldChange({ contacts })
            },
        },
        {
            title: "backoffice.payments.channelLink",
            dataIndex: "channelLink",
            mobileLevel: 3,
            render: value => (
                <div className='rt--table-col-link'>
                    <b
                        className='rt--title rt--font-bold rt--font-normal'
                        onClick={() => window.open(value, "_blank")}
                    >
                        {value}
                    </b>
                </div>
            ),
        }
    ];
}

export const getTranslationsTableColumns = (additionalProps) => {
    const {
        havePermissionForEdit,
        onTranslationFieldsChange,
    } = additionalProps;

    return [
        {
            title: "backoffice.payments.language",
            dataIndex: "languageCode",
            width: 180,
            mobileLevel: 1,
        },
        {
            title: "backoffice.payments.city",
            dataIndex: "city",
            maxLength: 100,
            mobileLevel: 2,
            editable: havePermissionForEdit,
            editableLabel: () => i18n.t("backoffice.payments.city"),
            editableTitle: () => i18n.t("backoffice.payments.language"),
            onEdit: (value, record) => {
                onTranslationFieldsChange({
                    text: value,
                    type: EPOS_TRANSLATION_TYPE.CITY,
                    languageCode: record.languageCode,
                    key: record.cityKey,
                })
            },
        },
        {
            title: "backoffice.payments.eposAddress",
            dataIndex: "address",
            maxLength: 100,
            mobileLevel: 3,
            editable: havePermissionForEdit,
            editableLabel: () => i18n.t("backoffice.payments.eposAddress"),
            editableTitle: () => i18n.t("backoffice.payments.eposAddress"),
            onEdit: (value, record) => {
                onTranslationFieldsChange({
                    text: value,
                    type: EPOS_TRANSLATION_TYPE.ADDRESS,
                    languageCode: record.languageCode,
                    key: record.addressKey,
                })
            },
        }
    ];
}
