import React from 'react';

import { Navigate, useLocation } from "react-router";

const RedirectRoute = () => {
    const location = useLocation();

    if (location.state?.url) {
        return <Navigate to={location.state?.url} />
    }

    return <></>
}

export default RedirectRoute;