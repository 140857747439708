import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";

import { FILTER_TYPE } from 'constants/common.constants';
import { ENTITY_TYPE, OPERATIONAL_DASHBOARD_FILTER_NAMES, TIME_PERIOD } from '../../constants';

const MobileFilters = ({
    setFinancialFilters,
    filters,
    hasRetail,
    showTimePeriodEntityType = true,
}) => {
    const { t } = useTranslation();

    return (
        <FiltersWrapper
            loadFn={() => { }}
            setFiltersFn={setFinancialFilters}
            updateProps={[]}
            filters={filters}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.dashboard.filterDirectAgents')}
                        name={OPERATIONAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE}
                    >
                        <Select
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={[
                                { value: FILTER_TYPE.DIRECT, text: t("backoffice.dashboard.direct") },
                                { value: FILTER_TYPE.NETWORK, text: t("backoffice.dashboard.network") },
                            ]}
                        />
                    </Form.Item>
                </Col>
                {showTimePeriodEntityType && (
                    <>
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.dashboard.timePeriod')}
                                name={OPERATIONAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD}
                            >
                                <Select
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    options={[
                                        { value: TIME_PERIOD.TODAY, text: t("backoffice.dashboard.today") },
                                        { value: TIME_PERIOD.YESTERDAY, text: t("backoffice.dashboard.yesterday") },
                                        { value: TIME_PERIOD.THIS_WEEK, text: t("backoffice.dashboard.thisWeek") },
                                        { value: TIME_PERIOD.LAST_WEEK, text: t("backoffice.dashboard.lastWeek") },
                                        { value: TIME_PERIOD.THIS_MONTH, text: t("backoffice.dashboard.thisMonth") },
                                        { value: TIME_PERIOD.LAST_MONTH, text: t("backoffice.dashboard.lastMonth") },
                                        { value: TIME_PERIOD.LAST_QUARTER, text: t("backoffice.dashboard.lastQuarter") },
                                        { value: TIME_PERIOD.THIS_YEAR, text: t("backoffice.dashboard.ytd") },
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.dashboard.entityType')}
                                name={OPERATIONAL_DASHBOARD_FILTER_NAMES.ENTITY_TYPE}
                            >
                                <Select
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    options={[
                                        { value: ENTITY_TYPE.AGENT, text: t("backoffice.dashboard.byAgent") },
                                        { value: ENTITY_TYPE.PLAYER, text: t("backoffice.dashboard.byPlayer") }
                                    ].concat(hasRetail ? [
                                        { value: ENTITY_TYPE.BETSHOP, text: t("backoffice.dashboard.byBetshop") }
                                    ]: [])}
                                />
                            </Form.Item>
                        </Col>
                    </>
                )}

            </Row>
        </FiltersWrapper>
    );
}

MobileFilters.propTypes = {
    hasRetail: PropTypes.bool,
    showTimePeriodEntityType: PropTypes.bool,
    filters: PropTypes.object,
    setFinancialFilters: PropTypes.func,
}

export default MobileFilters;