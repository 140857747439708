//#region react
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region libraries
import { Row, Col, Form } from 'antd';
//#endregion

//#region actions
import { setAgentPerformanceReportFilters } from 'store/actions/dashboard/reports/agentPerformanceReport.action';
import { getNeworkLayers } from 'store/actions/dashboard/agentSystem/network/network.action';
//#endregion

//#region components
import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";
//#endregion

//#region hooks
import useUserCurrencies from 'hooks/useUserCurrencies';
//#endregion

//#region utils
import { getCurrencyFieldOptions } from 'utils/currency';
//#endregion

//#region constants
import { TIME_PICKER_MODES } from 'components/common/dateRangePicker/constants';
import { AGENT_REPORT_TYPES } from 'constants/reports.constants';
import useProjectType from "hooks/useProjectType";
//#endregion

const FILTERS_FIELD_NAMES = {
    DATE: "date",
    CURRENCIES: "currencies",
    REGISTERRED_PERIOD: "registerredPeriod",
    AGENT_LAYER: "agentLevel",
    TYPE: "type"
}

/** Agent Performance Report Page Filters Component */

const Filters = ({
    globalProjectId,
    filters,
    networkLayers,
    loadFn,
    setAgentPerformanceReportFilters,
    getNeworkLayers,
}) => {
    const { t } = useTranslation();

    const [ userCurrencies, getUserCurrencies ] = useUserCurrencies(false);
    const { hasLiteMode } = useProjectType();

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    const userLayerFieldOptions = networkLayers.map(layer => ({
        value: layer,
        text: layer
    }))

    return (
        <FiltersWrapper
            loadFn={loadFn}
            setFiltersFn={setAgentPerformanceReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: true },
                { name: FILTERS_FIELD_NAMES.REGISTERRED_PERIOD, time: false },
            ]}
            searchFieldName="userNameOrId"
            onInit={() => {
                getUserCurrencies();
                getNeworkLayers()
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.date')}
                        name={FILTERS_FIELD_NAMES.DATE}
                    >
                        <DateRangePicker
                            showTime={true}
                            enabledMountsCount={12}
                            timePickerMode={TIME_PICKER_MODES.ONLY_HOURS}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>

                {!hasLiteMode && (
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.reports.currency')}
                            name={FILTERS_FIELD_NAMES.CURRENCIES}
                        >
                            <Select
                                options={currencyFieldOptions}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.currency')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                isMultiple={true}
                            />
                        </Form.Item>
                    </Col>
                )}

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.performancereport.registrationDate')}
                        name={FILTERS_FIELD_NAMES.REGISTERRED_PERIOD}
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.performancereport.agentLevel')}
                        name={FILTERS_FIELD_NAMES.AGENT_LAYER}
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                ...userLayerFieldOptions
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.performancereport.agentLevel')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                {!hasLiteMode && (
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.performancereport.type')}
                            name={FILTERS_FIELD_NAMES.TYPE}
                        >
                            <Select
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.performancereport.type')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                options={[
                                    {value: AGENT_REPORT_TYPES.DIRECT, text: t('backoffice.performancereport.direct')},
                                    {value: AGENT_REPORT_TYPES.NETWORK, text: t('backoffice.performancereport.network')},
                                ]}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </FiltersWrapper>
    );
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux state property, performance report filters */
    filters: PropTypes.object,
    /** Redux state property, represents current user available layers */
    networkLayers: PropTypes.arrayOf(PropTypes.number),
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to set agent performance report filters */
    setAgentPerformanceReportFilters: PropTypes.func,
    /** function to get performance report */
    loadFn: PropTypes.func,
    /** Redux action to get network layers */
    getNeworkLayers: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getNeworkLayers: () => {
            dispatch(getNeworkLayers());
        },
        setAgentPerformanceReportFilters: filters => {
            dispatch(setAgentPerformanceReportFilters(filters));
        },
    }
)

const mapStateToProps = state => {
    return {
        filters: state.agentPerformanceReport.filters,
        networkLayers: state.network.layers,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
