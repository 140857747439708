import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import AdditionalAccesses from '../edit/sections/additionalAccesses';
import SessionsComponent from "../edit/sections/sessions";

import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Cashier Edit Page Component */
const CashierEditComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.cashiers.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.cashiers.additionalAccesses"),
            permissions: [{ resource: PERMISSION_RESOURCE.CASHIER_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.VIEW }],
            component: <AdditionalAccesses />
        },
        {
            title: t("backoffice.cashiers.sessions"),
            permissions: [{ resource: PERMISSION_RESOURCE.CASHIER_SESSIONS, action: PERMISSION_ACTION.VIEW }],
            component: <SessionsComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.cashiers'), path: Paths.CASHIERS }}
        />
    )
}


export default CashierEditComponent;