import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import TransactionsReport from "../transactionsReport";
import {
    getAgentTransactionsReport,
    setAgentTransactionsReportSorting,
    setAgentTransactionsReportFilters
} from "store/actions/dashboard/reports/agentTransactions.action";

import { WALLET_OWNER_TYPE } from "constants/wallet.constants";

import sortingType from "types/common/sorting.type";
import transactionsReportType from "types/reports/transactionsReport.type";

/** Agent Transaction Report Page Component */
const AgentTransactionReportComponent = ({
    getAgentTransactionsReport,
    report,
    isLoading,
    total,
    setAgentTransactionsReportSorting,
    setAgentTransactionsReportFilters,
    sorting,
    filters
}) => {

    const { t } = useTranslation();

    return (
        <TransactionsReport 
            getTransactionsReport={getAgentTransactionsReport}
            setTransactionsReportSorting={setAgentTransactionsReportSorting}
            setTransactionsReportFilters={setAgentTransactionsReportFilters}
            report={report}
            isLoading={isLoading}
            total={total}
            sorting={sorting}
            filters={filters}
            title={ t('backoffice.menu.userTransactions') }
            type={WALLET_OWNER_TYPE.AGENT}
        />
    );
};

/** AgentTransactionReportComponent propTypes
 * PropTypes
*/
AgentTransactionReportComponent.propTypes = {
    /** Redux action to get agent transactions report */
    getAgentTransactionsReport: PropTypes.func,
    /** Redux state property, represents the agent transactions report  */
    report: PropTypes.arrayOf(transactionsReportType),
    /** Redux state property, is true when loading agent transactions report */
    isLoading: PropTypes.bool,
    /** Redux state property, agent transactions report total count */
    total: PropTypes.number,
    /** Redux action to set agent transactions report sorting details */
    setAgentTransactionsReportSorting: PropTypes.func,
    /** Redux action to set agent transactions report sorting details */
    setAgentTransactionsReportFilters: PropTypes.func,
    /** Redux state property, agent transactions report sorting details */
    sorting: sortingType,
    /** Redux state property, agent transactions report filters */
    filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    getAgentTransactionsReport: nextPage => {
        dispatch(getAgentTransactionsReport(nextPage));
    },
    setAgentTransactionsReportSorting: sorting => {
        dispatch(setAgentTransactionsReportSorting(sorting));
    },
    setAgentTransactionsReportFilters: filters => {
        dispatch(setAgentTransactionsReportFilters(filters));
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.agentTransactionsReport.isLoading,
        report: state.agentTransactionsReport.report,
        total: state.agentTransactionsReport.total,
        sorting: state.agentTransactionsReport.sorting,
        filters: state.agentTransactionsReport.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgentTransactionReportComponent);
