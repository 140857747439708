import React, { Component } from 'react';

import { Navigate } from 'react-router-dom';

import { getUser } from 'utils/auth';

import Paths from 'constants/path.constants';

/** Hocs which allows access to page only if user
       * @function
       * @param {Component} WrappedComponent - Component to add functionality 
       * @param {number} environment - environment 
       * @param {string} role - user role
   */
const WithEnvironment = (WrappedComponent, environments) => {

    const canOpenPage = environments => environments.some(env => env.environmentType === getUser()?.environmentType && (!env.role || env.role === getUser()?.role))

    class WithEnvironment extends Component {

        render() {
            if (!canOpenPage(environments)) {
                return <Navigate to={Paths.DEFAULT} />
            }

            return <WrappedComponent {...this.props} />;
        }
    }

    WithEnvironment.displayName = `WithEnvironment(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithEnvironment;
}

export default WithEnvironment;