export const BETSHOP_LIMIT_FREQUENCY = {
    DAY: 1,
    MONDAY: 2,
    TUESDAY: 4,
    WEDNESDAY: 8,
    THURSDAY: 16,
    FRIDAY: 32,
    SATURDAY: 64,
    SUNDAY: 128
}

export const BETSHOP_TRANSLATION_TYPE = {
    NAME: 1,
    ADDRESS: 2
}

export const BETSHOP_ACCESS_TYPE = {
    PLAYER_CREATE: 1 << 0,
    PAYMENT: 1 << 1,
    BETS: 1 << 2
}

export const BETSHOP_BET_TYPE = {
    ANONYMOUS: 1,
    NON_ANONYMOUS: 2
}