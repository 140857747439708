export const LINE_CHART_DEFAULT_CONFIGS = {
    chart: {
        type: 'areaspline',
        events: {
            load: function () {
                let chart = this;
                let yAxis = chart.yAxis[0];
                let yExtremes = yAxis.getExtremes();

                let newMin = yExtremes.dataMin - 0;
                let newMax = yExtremes.dataMax + 50;

                yAxis.setExtremes(newMin, newMax, true, false);
            }
        }
    },

    boost: {
        useGPUTranslations: true,
        seriesThreshold: 1,
    },

    accessibility: {
        enabled: false
    },

    exporting: {
        enabled: false
    },

    credits: {
        enabled: false
    },

    legend: {
        enabled: false,
    },

    title: {
        text: '',
    },

    plotOptions: {
        series: {
            states: {
                hover: {
                    halo: {
                        size: 0
                    }
                }
            },
        }
    },
}

export const LINE_CHART_AVAILABLE_COLOR = {
    WHITE: "rgba(255, 255, 255, 1)",
    LIGHT_BLUE: "rgba(58, 134, 254, 1)",
    LIGHT_GREEN: "rgba(83, 202, 67, 1)",
    GRAY: "rgba(160, 163, 189, 1)",
    PURPLE: "rgba(161, 100, 247, 1)",
    MAGENTA: "rgba(255, 0, 110, 1)",
    YELLOW: "rgba(255, 190, 12, 1)",
    LIGHT_GREY: "rgba(238, 238, 238, 1)",
}

export const MARKET_SYMBOL_TYPES = {
    CIRCLE: "circle",
}
