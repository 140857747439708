import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";

import { createSystemLanguage, getAvailableLanguages, getSystemAvailableLanguages } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";

/** System Language Creating Popup Component */
const SystemLanguageAddComponent = ({
    isSaving,
    isAvailableLoading,
    availableLanguages,
    systemAvailableLanguages,
    createSystemLanguage,
    getAvailableLanguages,
    getSystemAvailableLanguages,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    /** Load available languages */
    useEffect(() => {
        if(Object.keys(availableLanguages).length === 0){
            getAvailableLanguages();
        }
        getSystemAvailableLanguages();
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf LanguageAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    code: data.code.toUpperCase()
                }

                createSystemLanguage(d, onClose);

            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.languages.addLanguage')}
            cancelText={t('backoffice.common.cancel')}
            okText={ t('backoffice.common.add') }
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.languages.selectLanguage')} *`}
                            name="code"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                        >
                            <Select
                                options={
                                    Object.keys(availableLanguages).
                                        filter(item => !Object.keys(systemAvailableLanguages).includes(item))
                                        .map(item => (
                                            { value: item.toUpperCase(), text: item.toUpperCase() + " - " + availableLanguages[item] }
                                        ))
                                }
                                loading={isAvailableLoading}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.languages.language')}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>              
                </Row>
            </Form>
        </Modal>
    )
}

/** SystemLanguageAddComponent propTypes
    * PropTypes
*/
SystemLanguageAddComponent.propTypes = {
    /** Redux state property, is true when adding system language request is in process */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading system available languages */
    isAvailableLoading: PropTypes.bool,
    /** Redux action to add system language */
    createSystemLanguage: PropTypes.func,
    /** Redux action to get available languages */
    getAvailableLanguages: PropTypes.func,
    /** Redux action to get system available languages */
    getSystemAvailableLanguages: PropTypes.func,
    /** Redux state property, represents available currencies  */
    availableLanguages: PropTypes.object,
    /** Redux state property, represents system available currencies  */
    systemAvailableLanguages: PropTypes.object,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createSystemLanguage: (language, onSuccess) => {
            dispatch(createSystemLanguage(language, onSuccess));
        },
        getAvailableLanguages: () => {
            dispatch(getAvailableLanguages())
        },
        getSystemAvailableLanguages: () => {
            dispatch(getSystemAvailableLanguages())
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.systemLanguages.isSaving,
        isAvailableLoading: state.systemLanguages.isAvailableLoading,
        availableLanguages: state.systemLanguages.availableLanguages,
        systemAvailableLanguages: state.systemLanguages.systemAvailableLanguages
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemLanguageAddComponent)