import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';
import { getCurrencies } from '../profile/currencies.action';

import { SET_PROJECT_FAVORITE_AMOUNTS } from "../../../actionTypes";


const setProjectFavoriteAmounts = favoriteAmounts => ({
    type: SET_PROJECT_FAVORITE_AMOUNTS,
    payload: { favoriteAmounts }
})

export const getProjectFavoriteAmounts = currencyCode => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_FAVORITE_AMOUNTS,
            method: Methods.GET,
            params: { currencyCode }
        })
        .then(({data : {value: favoriteAmounts}}) => {
            dispatch(setProjectFavoriteAmounts(favoriteAmounts))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectFavoriteAmounts = data => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_FAVORITE_AMOUNTS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectFavoriteAmounts(data.value));
                dispatch(getCurrencies())
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
