import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { 
    SET_PROJECT_AGENT_REGISTRATION_FORM,
    SET_PROJECT_PLAYER_REGISTRATION_FORM,
} from "../../../actionTypes";


const setAgentRegistrationForm = registrationForm => ({
    type: SET_PROJECT_AGENT_REGISTRATION_FORM,
    payload: { registrationForm }
})

export const getAgentRegistrationForm = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_AGENT_REGISTRATION_FORM,
            method: Methods.GET
        })
        .then(({data : {value: registrationForm}}) => {
            dispatch(setAgentRegistrationForm(registrationForm))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveAgentRegistrationForm = data => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_AGENT_REGISTRATION_FORM,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAgentRegistrationForm(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

const setPlayerRegistrationForm = registrationForm => ({
    type: SET_PROJECT_PLAYER_REGISTRATION_FORM,
    payload: { registrationForm }
})

export const getPlayerRegistrationForm = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_PLAYER_REGISTRATION_FORM,
            method: Methods.GET
        })
        .then(({data : {value: registrationForm}}) => {
            dispatch(setPlayerRegistrationForm(registrationForm))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const savePlayerRegistrationForm = data => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_PLAYER_REGISTRATION_FORM,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPlayerRegistrationForm(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
