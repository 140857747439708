import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from "react-router-dom";

import EntityActions from "components/common/entityActions";
import SetPasswordComponent from "components/common/setPassword";

import { changeUserState, unlockUser, deleteUser } from "store/actions/dashboard/userManagement/users/users.action";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { USER_STATE } from 'constants/user.constants';
import Paths from 'constants/path.constants';

import userInfoType from 'types/profile/userInfo.type';
import userGeneralInfoType from "types/user/generalInfo.type";

/** User Actions Button Component */
const UserActionsComponent = ({
    generalInfo,
    userInfo,
    unlockUser,
    deleteUser,
    changeUserState
}) => {

    const { t } = useTranslation();

    const searchParams = useParams();
    const { search } = useLocation();
    const navigate = useNavigate();

    const mainCompany = (new URLSearchParams(search)).get("mainCompany");
    const mainCompanyId = (new URLSearchParams(search)).get("mainCompanyId");

    /** Flag to show/hide set password popup */
    const [showSetPasswordPopup, setShowSetPasswordPopup] = useState(false);

    const canEdit = (
        hasPermission({ resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.MODIFY }) &&
        searchParams.id !== userInfo.id &&
        mainCompany
    )



    /** Actions list */
    const actions = [
        /** Set Password */
        {
            key: "setPassword",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.setPassword'),
            icon: "icon-password",
            onClick: () => setShowSetPasswordPopup(true),
            disabled: !canEdit || generalInfo.state === USER_STATE.BLOCKED
        },
        /** Block/Unblock */
        {
            key: "block",
            buttonType: "secondary",
            title: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            buttonClassName: generalInfo.state === USER_STATE.BLOCKED ? "rt--button-secondary" : "rt--button-danger",
            onClick: () => {
                changeUserState({
                    id: searchParams.id,
                    enabled: generalInfo.state === USER_STATE.BLOCKED
                })
            },
            icon: "icon-block",
            isConfirmation: true,
            confirmationMessage: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.users.doYouWanttoUnblockAdmin') : t('backoffice.users.doYouWanttoBlockAdmin'),
            disabled: !canEdit
        },
        /** Unlock */
        {
            key: "unlock",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.unlock'),
            icon: "icon-lock",
            onClick: () => {
                unlockUser(searchParams.id)
            },
            isConfirmation: true,
            confirmationMessage: t('backoffice.users.doYouWanttoUnlock'),
            disabled: !canEdit || ( generalInfo.state !== USER_STATE.LOCKED2FA && generalInfo.state !== USER_STATE.LOCKEDLOGIN )
        },
        /** Delete */
        {
            key: "delete",
            buttonType: "danger",
            title: t('backoffice.common.delete'),
            icon: "icon-delete",
            buttonClassName: "rt--button-danger",
            onClick: () => {
                deleteUser(searchParams.id, () => {
                    navigate(Paths.USERS, { replace: true })
                })
            },
            isConfirmation: true,
            confirmationMessage: t('backoffice.users.doYouWanttoDelete'),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.DELETE }) ||
                searchParams.id === userInfo.id 
            )
        },
    ]

    return (
        <Fragment>
            <EntityActions 
                actions={actions}
            />

            {
                showSetPasswordPopup && (
                    <SetPasswordComponent
                        onClose={() => setShowSetPasswordPopup(false)}
                        companyId={mainCompanyId}
                    />
                )
            }
        </Fragment>
        
    )
}

/** UserActionsComponent propTypes
    * PropTypes
*/
UserActionsComponent.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux state, represents the general info of current editing admin  */
    generalInfo: userGeneralInfoType,
    /** Redux action to unlock user */
    unlockUser: PropTypes.func,
    /** Redux action to delete user */
    deleteUser: PropTypes.func,
    /** Redux action to block/unblock users */
    changeUserState: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        unlockUser: id => {
            dispatch(unlockUser(id));
        },

        changeUserState: data => {
            dispatch(changeUserState(data));
        },

        deleteUser: (id, onSuccess) => {
            dispatch(deleteUser(id, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        generalInfo: state.users.edit.general
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActionsComponent)