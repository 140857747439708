import PropTypes from "prop-types";

import { isMobile } from "NEW/src/core/helpers/appEnvironment/isMobile";

const Responsive = ({ mobile, desktop }) => {
	if (isMobile()) {
		return mobile;
	}

	return desktop;
};

Responsive.propTypes = {
	mobile: PropTypes.node.isRequired,
	desktop: PropTypes.node.isRequired,
};

export default Responsive;
