import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";
import { refreshToken, getUser } from 'utils/auth';

import { getUserInfo, changeProject } from "store/actions/dashboard/profile/userInfo.action";

import {
    SET_PROJECTS_ACTION_BEFORE,
    SET_PROJECTS_ACTION_FINISH,
    SET_PROJECTS,
    SET_PROJECTS_SORTING,
    SET_PROJECTS_FILTERS,
    SET_PROJECTS_SAVE_ACTION_BEFORE,
    SET_PROJECTS_SAVE_ACTION_FINISH,
    SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE,
    SET_PROJECT_AVAILABLE_CURRENCIES_FINISH,
    SET_PROJECT_AVAILABLE_CURRENCIES,
    SET_RETAIL_PROVIDERS
} from "../../../actionTypes";

export const setProjectsActionBefore = () => ({
    type: SET_PROJECTS_ACTION_BEFORE,
});

export const setProjectsActionFinished = () => ({
    type: SET_PROJECTS_ACTION_FINISH,
});

export const setProjectsSaveActionBefore = () => ({
    type: SET_PROJECTS_SAVE_ACTION_BEFORE,
});

export const setProjectsSaveActionFinished = () => ({
    type: SET_PROJECTS_SAVE_ACTION_FINISH,
});

const setProjects = (projects, add) => ({
    type: SET_PROJECTS,
    payload: { projects, add },
});

export const setProjectsSorting = sorting => ({
    type: SET_PROJECTS_SORTING,
    payload: { sorting },
});

export const setProjectsFilters = filters => ({
    type: SET_PROJECTS_FILTERS,
    payload: { filters },
});


export const getProjects = nextPage => {
    return (dispatch, getState) => {
        
        const page= nextPage === "first" ? 1 : nextPage === "next" ? getState().projects.sorting.page + 1 : getState().projects.sorting.page;
        let params = {
            ...getState().projects.sorting,
            ...getState().projects.filters,
            page: page
        };

        if(page > 1 && getState().projects.total <= (page - 1 ) * getState().projects.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().projects.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECTS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: projects } }) => {
                dispatch(setProjects(projects, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setProjectsSorting({ ...getState().projects.sorting, page: page }));
                dispatch(setProjectsActionFinished());
            })
            .catch((ex) => {
                dispatch(setProjectsActionFinished());
            });
    }
}

export const createProject = (project, onSuccess) => {
    return (dispatch, getState) => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_PROJECT,
            method: Methods.POST,
            data: {
                ...project
            },
        })
            .then(({ data }) => {
                dispatch(setProjectsSaveActionFinished());
                if (isResponseSuccess(data)) {

                    const token = getUser()?.refreshToken;
                    const companyId = getState().common.globalCompanyId;

                    refreshToken(token)
                        .then(() => {
                            return dispatch(getUserInfo(true));
                        })
                        .then(() => {
                            return dispatch(changeProject(companyId, data.value.id))
                        })
                        .then(() => {
                            onSuccess && onSuccess(data.value);
                        });
                }
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
};


/** Available Currencies */

const setProjectAvailableCurrenciesBefore = () => ({
    type: SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE,
});

const setProjectAvailableCurrenciesFinished = () => ({
    type: SET_PROJECT_AVAILABLE_CURRENCIES_FINISH,
});

const setProjectAvailableCurrencies = currencies => ({
    type: SET_PROJECT_AVAILABLE_CURRENCIES,
    payload: { currencies }
})

export const getProjectAvailableCurrencies = () => {
    return dispatch => {

        dispatch(setProjectAvailableCurrenciesBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_AVAILABLE_CURRENCIES,
            method: Methods.GET
        })
        .then(({data : {value: currencies}}) => {
            dispatch(setProjectAvailableCurrencies(currencies))
            dispatch(setProjectAvailableCurrenciesFinished());
        })
        .catch((ex) => {
            dispatch(setProjectAvailableCurrenciesFinished());
        })
    }
}

/** Project Retail Providers */

const setRetailProviders = providers => ({
    type: SET_RETAIL_PROVIDERS,
    payload: { providers }
})

export const getRetailProviders = ()  => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_RETAIL_PROVIDERS,
            method: Methods.GET
        })
        .then(({data : {value: providers}}) => {
            dispatch(setRetailProviders(providers || 0))
        })
        .catch((ex) => {
            console.log(ex)
        })
    }
}

