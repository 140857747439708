import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_CASHIER_TRANSACTIONS_REPORT_ACTION_BEFORE,
    SET_CASHIER_TRANSACTIONS_REPORT_ACTION_FINISH,
    SET_CASHIER_TRANSACTIONS_REPORT,
    SET_CASHIER_TRANSACTIONS_REPORT_SORTING,
    SET_CASHIER_TRANSACTIONS_REPORT_FILTERS
} from "../../../actionTypes";

import { WALLET_OPERATION_TYPE, WALLET_OWNER_TYPE } from "constants/wallet.constants";

export const setCashierTransactionsReportActionBefore = () => ({
    type: SET_CASHIER_TRANSACTIONS_REPORT_ACTION_BEFORE,
});

export const setCashierTransactionsReportActionFinished = () => ({
    type: SET_CASHIER_TRANSACTIONS_REPORT_ACTION_FINISH,
});

const setCashierTransactionsReport = (report, add) => ({
    type: SET_CASHIER_TRANSACTIONS_REPORT,
    payload: { report, add },
});

export const setCashierTransactionsReportSorting = sorting => ({
    type: SET_CASHIER_TRANSACTIONS_REPORT_SORTING,
    payload: { sorting },
});

export const setCashierTransactionsReportFilters = filters => ({
    type: SET_CASHIER_TRANSACTIONS_REPORT_FILTERS,
    payload: { filters },
});


export const getCashierTransactionsReport = nextPage => {
    return (dispatch, getState) => {

        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().cashierTransactionsReport.sorting.page + 1 : getState().cashierTransactionsReport.sorting.page;
        const filters = {...getState().cashierTransactionsReport.filters}

        if(!filters.transactionTypes || filters.transactionTypes.length === 0){
            filters.transactionTypes = [
                WALLET_OPERATION_TYPE.BET,
                WALLET_OPERATION_TYPE.CANCEL_BET,
                WALLET_OPERATION_TYPE.PAIDOUT,
                WALLET_OPERATION_TYPE.WON,
                WALLET_OPERATION_TYPE.REJECT_BET,
                WALLET_OPERATION_TYPE.RECALCULATE,
                WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BETSHOP,
                WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_FROM_BETSHOP,
                WALLET_OPERATION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER
            ]
        }

        let participants = "";
        if(filters.cashier || filters.player){
            participants = [];

            if(filters.cashier){
                participants.push({
                    ownerType: WALLET_OWNER_TYPE.CASHIER,
                    userNameOrId: filters.cashier
                })
            }

            if(filters.player){
                participants.push({
                    ownerType: WALLET_OWNER_TYPE.PLAYER,
                    userNameOrId: filters.player
                })
            }
        }
        filters.participants = participants;

        delete filters.cashier;
        delete filters.player;

        let data = {
            ...getState().cashierTransactionsReport.sorting,
            ...filters,
            page: page
        };

        if(page > 1 && getState().cashierTransactionsReport.total <= ( page - 1 ) * getState().cashierTransactionsReport.sorting.limit){
            return Promise.resolve();
        }

        dispatch(setCashierTransactionsReportActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_TRANSACTIONS_REPORT,
            method: Methods.POST,
            data: data,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setCashierTransactionsReport(report, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCashierTransactionsReportSorting({ ...getState().cashierTransactionsReport.sorting, page: page }));
                dispatch(setCashierTransactionsReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashierTransactionsReportActionFinished());
            });
    }
}
