import React, {useState} from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import DatePicker from 'components/common/datePicker';

import useDate from 'hooks/useDate';

import { classNames, isMobile, getFilterRanges } from 'utils/common';

import { getShowTimeOptionValue } from './helpers';

import { TIME_PICKER_MODES } from './constants';

/** DateRangePicker Component */
const DateRangePicker = ({
    showTime,
    timePickerMode = TIME_PICKER_MODES.DEFAULT,
    onChange,
    value,
    allowClear = true,
    disabledRanges= [],
    enabledMountsCount = 3,
}) => {
    const [opened, setOpened] = useState(false);

    const { t } = useTranslation();
    const { dateService } = useDate();

    const format = isMobile() && timePickerMode === TIME_PICKER_MODES.ONLY_HOURS ? dateService.getFormat(false) + " " + "HH:mm" : dateService.getFormat(showTime)

    const disabledDate = currentDate => {
        if (showTime) {
            return !dateService.isInRange(currentDate, dateService.monthsAgo(enabledMountsCount), dateService.midnight(dateService.tomorrow()))
        } else {
            if (!currentDate) return false;

            if (dateService.isAfter( currentDate, dateService.endOfTomorrow() ) ) {
                 return true
            }

            return false;
        }

    }

    const disabledTime = (currentDate) => {
        if (showTime) {
            const maxDate = dateService.midnight(dateService.tomorrow());

            const isSameDate = currentDate.isSame(maxDate);
            const isAfterDate = currentDate.isAfter(maxDate)

            return {
                disabledHours: () => {
                    if (isSameDate) {
                        return Array.from({length: 23}).map((_, index) => index + 1);
                    }

                    if (isAfterDate) {
                        return Array.from({length: 24}).map((_, index) => index);
                    }

                    return [];
                },
                disabledMinutes: () => {
                    if (isSameDate) {
                        return Array.from({length: 59}).map((_, index) => index + 1);
                    }

                    if (isAfterDate) {
                        return Array.from({length: 60}).map((_, index) => index);
                    }

                    return [];
                },
            };
        }

        return {};
    }

    const handleInternalChange = (...args) => {
        const [startDate, endDate] = args[0] ? args[0] : [null, null];

        if (!showTime && startDate && endDate) {
            if(startDate.isSame(endDate, "day")) {
                return;
            }
        }

        onChange(...args);
    }

    const handleSelectPreset = (range) => {
        handleInternalChange(range);
        setOpened(false);
    }

    const handleOpenChange = (value) => {
        setOpened(value);
    }

    const pickerRanges = showTime ? getFilterRanges(disabledRanges) : [];

    return (
        <DatePicker.RangePicker
            open={opened}
            onOpenChange={handleOpenChange}
            placeholder={[t('backoffice.common.from'), t('backoffice.common.to')]}
            format={format}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
            showTime={getShowTimeOptionValue({ showTime, timePickerMode, dateService })}
            allowClear={allowClear}
            inputReadOnly={isMobile()}
            onChange={handleInternalChange}
            value={value}
            suffixIcon={<i className='icon-date rt--font-bigest' />}
            separator={<div className='rt--datepicker-separator' />}
            className={classNames(
                "rt--datepicker",
                showTime && "rt--datepicker-withtime",
            )}
            popupClassName={classNames(
                "rt--datepicker-popup",
                showTime && "rt--datepicker-withtime-popup",
                (showTime && timePickerMode === TIME_PICKER_MODES.ONLY_HOURS) && "rt--datepicker-popup-only-hours",
            )}
            renderExtraFooter={() => (
                pickerRanges.map(range => (
                    <button className='rt--datepicker-withtime-popup-footer-button' onClick={() => handleSelectPreset(range.value)}>
                        {range.label}
                    </button>
                ))
            )}
        />
    )
}

/** DateRangePicker propTypes
    * PropTypes
*/
DateRangePicker.propTypes = {
    /** Show time picker */
    showTime: PropTypes.bool,
    /** On Change function */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.array,
    /** Allow empty picker value */
    allowClear: PropTypes.bool,
    /** Modes of Time Picker */
    timePickerMode: PropTypes.oneOf([
        TIME_PICKER_MODES.DEFAULT,
        TIME_PICKER_MODES.ONLY_HOURS
    ]),
    /** Disabled predefined ranges */
    disabledRanges: PropTypes.array,
    /** Depend predefined ranges enabled mounts count */
    enabledMountsCount: PropTypes.number
}

export default DateRangePicker;
