import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';

import { TOKEN_TYPE } from 'constants/auth.constants';

import { getTwoFactorQR, verifyQRCode } from 'store/actions/auth/auth.action';
import TwoFactorInputs from 'components/auth/login/twoFactor/twoFactoorInputs';

import TwoFactorQRView from './twoFactorQR.component';
import TwoFactorDigitalCodeView from './twoFactorDigitalCodeView.component';

/** Login Page Two Factor Authentication Component */

const TwoFactorComponent = ({
    tokenType,
    token,
    getTwoFactorQR,
    verifyQRCode,
    QRBase64,
    onClose
}) => {
    const { t } = useTranslation();

    const inputsResetFnRef = useRef(null);

    // Get QR code image, if two factor authentication state is QR code
    useEffect(() => {
        if (tokenType !== TOKEN_TYPE.QR) {
            return;
        }

        getTwoFactorQR(token);
    }, [])

    const onVerifyFail = () => {
        inputsResetFnRef.current.resetInputsValue();
    }

    const handlePasswordInsertFinish = (passwordArr) => {
        verifyQRCode(
            token,
            passwordArr.join(""),
            onVerifyFail,
        )
    }

    return (
        <Modal
            title={t('backoffice.authentication.twoFactorVerification')}
            closable={true}
            onCancel={onClose}
        >
            <div>
                <div className="rt--flex rt--flex-col rt--align-center rt--justify-center">
                    {tokenType === TOKEN_TYPE.QR
                        ? <TwoFactorQRView QRsrc={QRBase64} />
                        : <TwoFactorDigitalCodeView />
                    }
                </div>
                <div className='rt--form-layout rt--mt-16'>
                    <TwoFactorInputs
                        onFinish={handlePasswordInsertFinish}
                        ref={inputsResetFnRef}
                        label={t('backoffice.authentication.twoFactorVerificationCode')}
                    />
                </div>
            </div>
        </Modal>
    )
}

TwoFactorComponent.propTypes = {
    /** TwoFactor authentication token type */
    tokenType: PropTypes.string,
    /** Token for TwoFactor authentication */
    token: PropTypes.string,
    /** UserName for TwoFactor authentication */
    userName: PropTypes.string,
    /** Base64 image urk for QR code */
    QRBase64: PropTypes.string,
    /** Redux action to get base64 image if QR code */
    getTwoFactorQR: PropTypes.func,
    /** Redux action to verify code for QR scanning */
    verifyQRCode: PropTypes.func,
    /** Fires on Two Factor authentication modal close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getTwoFactorQR: token => {
            dispatch(getTwoFactorQR(token))
        },
        verifyQRCode: (token, code, onVerifyFailCB) => {
            dispatch(verifyQRCode(token, code, onVerifyFailCB))
        }
    }
)

const mapStateToProps = state => {
    return {
        QRBase64: state.auth.QRBase64
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    TwoFactorComponent
);