import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { refreshToken, getUser } from "utils/auth";

import { getUserInfo } from "store/actions/dashboard/profile/userInfo.action";

import { arrayMove } from "@dnd-kit/sortable";

import {
    setProjectsActionBefore,
    setProjectsActionFinished,
    setProjectsSaveActionBefore,
    setProjectsSaveActionFinished
} from './projects.action';

import {
    SET_PROJECT_PAYMENT_METHODS,
    SET_PROJECT_BANKS,
    SET_PROJECT_BANK_GENERAL_INFO,
    SET_PROJECT_BANK_FORM,
    SET_PROJECT_BANK_FORM_TRANSLATION,
    SET_PROJECT_PAYMENT_WIDGET,
    SET_PROJECT_BANK_NAME_TRANSLATION
} from "../../../actionTypes";

import {BANK_STATUS, PAYMENT_METHODS} from "constants/project.constants"

import LanguageUtils from 'utils/languages';

const setProjectPaymentSettings = config => ({
    type: SET_PROJECT_PAYMENT_METHODS,
    payload: { config }
})

const setProjectBanks = banks => ({
    type: SET_PROJECT_BANKS,
    payload: banks
})

const setProjectBankGeneralInfo = info => ({
    type: SET_PROJECT_BANK_GENERAL_INFO,
    payload: info
})

const setProjectBankForm = (type, form, setTranslations) => ({
    type: SET_PROJECT_BANK_FORM,
    payload: { type, form, setTranslations }
})

const setProjectBankFormTranslation = (type, key, translation) => ({
    type: SET_PROJECT_BANK_FORM_TRANSLATION,
    payload: { type, key, translation }
})

const setBankNameTranslation = (translation, key) => ({
    type: SET_PROJECT_BANK_NAME_TRANSLATION,
    payload: { translation, key }
})

export const setProjectWidget = (transactionType, url) => ({
    type: SET_PROJECT_PAYMENT_WIDGET,
    payload: { transactionType, url }
})

/** Payment Settings */

export const getProjectPaymentSettings = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_PAYMENT_SETTINGS,
            method: Methods.GET
        })
        .then(({data : {value: config}}) => {
            dispatch(setProjectPaymentSettings(config))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectPaymentSettings = requestBody => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_PAYMENT_SETTINGS,
            method: Methods.POST,
            data: requestBody
        })
        .then(({ data }) => {
            dispatch(setProjectsSaveActionFinished());
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectPaymentSettings(data.value));
                const token = getUser()?.refreshToken;
                refreshToken(token)
                    .then(() => {
                        return dispatch(getUserInfo(true));
                    })
            }
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

/** Banks */

export const getProjectBanks = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_BANKS,
            method: Methods.GET
        })
        .then(({ data }) => {
            dispatch(setProjectBanks(data.value.sort((a, b) => {
                if (a.orderNumber === null) {
                    return 1;
                }

                if (b.orderNumber === null) {
                    return -1;
                }

                return a.orderNumber - b.orderNumber;
            })))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const reorderProjectBanks = (ids) => {
    return (dispatch, getState) => {
        const [banksList, staticBankList] = getState().projects.edit.payment.banks.banks.reduce((acc, bank) => {
            if (bank.state !== BANK_STATUS.DELETED) {
                acc[0].push(bank);
            } else {
                acc[1].push(bank);
            }

            return acc;
        }, [[], []]);
        const prevBanksList = banksList.map(bank => ({...bank}));

        const bankToMoveIndex = banksList.findIndex(bank => bank.id === ids[0]);
        const targetBankIndex = banksList.findIndex(bank => bank.id === ids[1]);

        const reorderedBankList = (
            arrayMove(banksList, bankToMoveIndex, targetBankIndex)
                .map((bank, index) => (
                    {
                        ...bank,
                        orderNumber: index + 1
                    }
                ))
        )
        const data = {
            banks: reorderedBankList.map(bank => ({
                id: bank.id,
                orderNumber: bank.orderNumber
            }))
        }

        dispatch(setProjectBanks([...reorderedBankList, ...staticBankList]));

        return axios({
            url: ApiUrls.CHANGE_BANKS_ORDER,
            method: Methods.POST,
            data
        }).catch(() => {
            dispatch(setProjectBanks([...prevBanksList, ...staticBankList]));
        })
    }
}

export const createProjectBank = (data, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.CREATE_PROJECT_BANK,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: data,
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                onSuccess && onSuccess(data.value.id);
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const deactivateProjectBank = (id) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.DEACTIVATE_PROJECT_BANK,
            method: Methods.POST,
            data: {
                id,
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectBankGeneralInfo(data.value));
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const activateProjectBank = (id) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.ACTIVATE_PROJECT_BANK,
            method: Methods.POST,
            data: {
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectBankGeneralInfo(data.value));
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const deleteProjectBank = (id, currencies, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.DELETE_PROJECT_BANK,
            method: Methods.DELETE,
            data: {
                id,
                currencies,
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                if(typeof onSuccess === 'function') {
                    onSuccess();
                }
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

/** Bank General Info */

export const saveProjectBankGeneralInfo = requestBody => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_BANK_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...requestBody
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                dispatch(setProjectBankGeneralInfo(data.value))
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const saveBankTranslation = (requestBody, key) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_BANK_NAME_TRANSLATION,
            method: Methods.POST,
            data: {
                ...requestBody
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBankNameTranslation(data.value, key));
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const getProjectBankGeneralInfo = (id) => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_BANK_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({ data }) => {
            dispatch(setProjectBankGeneralInfo(data.value))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

/** Bank Form */

export const getProjectBankForm = (bankId, type) => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_BANK_FORM,
            method: Methods.GET,
            params: {
                id: bankId,
                type
            }
        })
        .then(({ data }) => {
            dispatch(setProjectBankForm(type, data.value, true))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectBankForm = (bankId, type, data) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_PROJECT_BANK_FORM,
            method: Methods.POST,
            data: {
                ...data,
                id: bankId,
                type
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectBankForm(type, data.value, false));
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const saveProjectBankFormTranslation = translation => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_PROJECT_BANK_FORM_TRANSLATION,
            method: Methods.POST,
            data: {
                ...translation
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectBankFormTranslation(translation.type, translation.translationKey, data.value));
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

/** Bank Widget */

export const getProjectWidget = (transactionType, languageCode) => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.LAUNCH_PROJECT_WIDGET,
            method: Methods.GET,
            params: {
                paymentType: PAYMENT_METHODS.BANK_TRANSFER,
                transactionType,
                languageCode
            }
        })
        .then(({ data }) => {
            dispatch(setProjectWidget(transactionType, data.value))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}
