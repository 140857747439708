import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

import { getProjectWidget, setProjectWidget } from "store/actions/dashboard/projects/paymentsConfig.action";

import { WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

/** Widget Preview Component */
const WidgetPreviewComponent = ({
    widget,
    getProjectWidget,
    resetProjectWidget,
    transactionType,
    languageCode
}) => {

    const { search } = useLocation();

    const currentBankId = (new URLSearchParams(search)).get("bankId");

    /** Get project data */
    useEffect(() => {
        getProjectWidget(transactionType, languageCode);
    }, [languageCode])

    const widgetURL = widget[transactionType] === null ? widget[transactionType] : 
        (widget[transactionType] + "&isMobile=true&isPreview=true&bankId=" + currentBankId);
        
    /** Reset Project widget */
    useEffect(() => {
        return () => {
            resetProjectWidget(transactionType)
        }
    }, [])

    return (
        <div className='rt--payment-widget'>
            {
                widgetURL && (
                    <iframe
                        id={"payment-widget-iframe-" + transactionType}
                        title="Widget"
                        frameBorder="0"
                        src={widgetURL}
                    />
                )
            }

        </div>
    );
}

/** WidgetPreviewComponent propTypes
    * PropTypes
*/
WidgetPreviewComponent.propTypes = {
    /** Redux action to get project widget url */
    getProjectWidget: PropTypes.func,
    /** Redux action to reset project widget url */
    resetProjectWidget: PropTypes.func,
    /** Redux state property, current bank widget data */
    widget: PropTypes.shape({
        [WIDGET_TRANSACTION_TYPE.DEPOSIT]: PropTypes.string,
        [WIDGET_TRANSACTION_TYPE.WITHDRAWAL]: PropTypes.string,
    }),
    /** Registration Form Type */
    transactionType: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE)),
    /** The languageCode */
    languageCode: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getProjectWidget: (transactionType, languageCode) => {
            dispatch(getProjectWidget(transactionType, languageCode));
        },
        resetProjectWidget: transactionType => {
            dispatch(setProjectWidget(transactionType, null));
        },
    }
)

const mapStateToProps = state => {
    return {
        widget: state.projects.edit.payment.banks.edit.widget,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPreviewComponent);