export const TRANSFER_POPUP_STEP = {
    ENTITY_TYPE: 1,
    FORM: 2,
    RESULT: 3
}

export const TRANSFER_ENTITY_TYPE = {
    AGENT: 1,
    SUPER_AGENT: 2,
    PLAYER: 3,
    BETSHOP: 4,
    TRANSACTION: 5
}

export const TRANSFER_OPERATION_GROUP = {
    DEPOSIT: 1,
    WITHDRAWAL: 2
}

export const EPOS_TRANSFER_FORM_STEP = {
    SEARCH: 1,
    FORM: 2
}

export const TRANSACTION_SOURCE = {
    FROM_BALANCE: 1,
    BY_CASH: 2
}

export const TRANSACTION_PAYMENT_SOURCE = {
    FROM_BALANCE: 1,
    FROM_CREDIT: 2
}

export const TRANSACTION_AMOUNT_ACTION_TYPE = {
    ADD: 1,
    REDUCE: 2
}

export const TRANSFER_POPUP_OK_ACTION_EVENT_NAME = "onTransferOkActionEvent";

export const TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME = "onTransferEposPlayerFoundEvent";

export const TRANSFER_SUCCESS_EVENT_NAME = "onTransferSuccessEvent";