import axios from "axios";

import { message } from "antd";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";
import { isResponseInternalServerError, isResponseNotAvailable, isResponseSuccess } from "utils/request";

import {
    SET_FAILED_TRANSACTIONS_ACTION_BEFORE,
    SET_FAILED_TRANSACTIONS_ACTION_FINISH,
    SET_FAILED_TRANSACTIONS,
    SET_FAILED_TRANSACTIONS_SORTING,
    SET_FAILED_TRANSACTIONS_FILTERS
} from "../../../actionTypes";

export const setFailedTransactionsActionBefore = () => ({
    type: SET_FAILED_TRANSACTIONS_ACTION_BEFORE,
});

export const setFailedTransactionsActionFinished = () => ({
    type: SET_FAILED_TRANSACTIONS_ACTION_FINISH,
});


const setFailedTransactions = (failedTransactions, add) => ({
    type: SET_FAILED_TRANSACTIONS,
    payload: { failedTransactions, add },
});

export const setFailedTransactionsSorting = sorting => ({
    type: SET_FAILED_TRANSACTIONS_SORTING,
    payload: { sorting },
});

export const setFailedTransactionsFilters = filters => ({
    type: SET_FAILED_TRANSACTIONS_FILTERS,
    payload: { filters },
});


export const getFailedTransactions = nextPage => {
    return (dispatch, getState) => {

        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().failedTransactions.sorting.page + 1 : getState().failedTransactions.sorting.page;
        let params = {
            ...getState().failedTransactions.sorting,
            ...getState().failedTransactions.filters,
            page: page
        };

        if (page > 1 && getState().failedTransactions.total <= (page - 1) * getState().failedTransactions.sorting.limit) {
            return Promise.resolve();
        }

        if (nextPage === "next" && getState().failedTransactions.total === 0) {
            return Promise.resolve();
        }

        dispatch(setFailedTransactionsActionBefore());

        return axios({
            url: ApiUrls.GET_FAILED_TRANSACTIONS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value } }) => {
                dispatch(setFailedTransactions(value, isMobile() && page !== 1));

                if (nextPage) {
                    dispatch(setFailedTransactionsSorting({ ...getState().failedTransactions.sorting, page: page }));
                }

                dispatch(setFailedTransactionsActionFinished());
            })
            .catch((ex) => {
                dispatch(setFailedTransactionsActionFinished());
            });
    }
}

export const resendFailedTransaction = (id, createdAt) => {
    return dispatch => {
        dispatch(setFailedTransactionsActionBefore());

        return axios({
            url: ApiUrls.RESEND_FAILED_TRANSACTIONS,
            method: Methods.POST,
            data: {
                id,
                createdAt
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);

                    dispatch(getFailedTransactions())
                }
                dispatch(setFailedTransactionsActionFinished());
            })
            .catch(({ response: { data } }) => {
                if (isResponseNotAvailable(data.resultCode) || isResponseInternalServerError(data.resultCode)) {
                    dispatch(getFailedTransactions())
                }
                dispatch(setFailedTransactionsActionFinished());
            })
    }
}
