import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Col, Row, Radio } from 'antd';

import Modal from "components/common/modal";
import Input from 'components/common/input';

import { addBetshopAllowedIP } from "store/actions/dashboard/retail/betshops/ipAccessRule.action";

import { ip6addrCompare } from 'utils/ipAddress';

import { IP_V4_ADDRESS_REGEX, IP_V6_ADDRESS_REGEX } from "constants/regex.constants";

import { isMobile } from 'utils/common';

const IP_TYPE = {
    ADDRESS: 1,
    RANGE: 2
}

/** System Language Creating Popup Component */
const IPAccessAddComponent = ({
    isSaving,
    addBetshopAllowedIP,
    onClose
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    const [selectedType, setSelectedType] = useState(IP_TYPE.ADDRESS)


    /** Fires when form submitted
       * @function
       * @memberOf IPAccessAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    id: searchParams.id
                }
                if (selectedType === IP_TYPE.ADDRESS) {
                    d.from = data.from;
                } else {
                    d.from = data.from;
                    d.to = data.to;
                }
                addBetshopAllowedIP(d, onClose);

            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.betshops.addIPAddress')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    type: IP_TYPE.ADDRESS
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={""}
                            name="type"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            className='rt--form-item-radio'
                        >
                            <Radio.Group
                                options={[
                                    { label: t('backoffice.betshops.ipAddress'), value: IP_TYPE.ADDRESS },
                                    { label: t('backoffice.betshops.ipRange'), value: IP_TYPE.RANGE }
                                ]}
                                onChange={e => setSelectedType(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    {
                        selectedType === IP_TYPE.ADDRESS ? (
                            <Col xs={24} sm={24}>
                                <Form.Item
                                    label={`${t('backoffice.betshops.ipAddress')} *`}
                                    name="from"
                                    className="rt--form-item-without-margin rt--general-form-item"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.ipAddress')}`}
                                    rules={selectedType === IP_TYPE.ADDRESS ? [
                                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                        () => ({
                                            validator(rule, value) {
                                                if (value) {
                                                    if (!IP_V4_ADDRESS_REGEX.test(value) && !IP_V6_ADDRESS_REGEX.test(value)) {
                                                        return Promise.reject(t('backoffice.validation.fieldInvalid'))
                                                    }
                                                }
                                                return Promise.resolve();
                                            }
                                        })
                                    ] : []}
                                >
                                    <Input
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.ipAddress')}`}
                                        autoFocus={(isMobile() ? false : true)}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            </Col>
                        ) : (
                            <Fragment>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('backoffice.betshops.ipAddressFrom')} *`}
                                        className='rt--general-form-item'
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.ipAddressFrom')}`}
                                        name="from"
                                        rules={selectedType === IP_TYPE.RANGE ? [
                                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                            () => ({
                                                validator(rule, value) {
                                                    if (value) {
                                                        if (!IP_V4_ADDRESS_REGEX.test(value) && !IP_V6_ADDRESS_REGEX.test(value)) {
                                                            return Promise.reject(t('backoffice.validation.fieldInvalid'))
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    const toValue = getFieldValue("to");
                                                    if (value && toValue && (IP_V4_ADDRESS_REGEX.test(toValue) || IP_V6_ADDRESS_REGEX.test(toValue))) {
                                                        if (ip6addrCompare(value, toValue) !== -1) {
                                                            return Promise.reject(t('backoffice.validation.mustBeLess_and_not_equal_than_other').replace("%X%", t('backoffice.betshops.ipAddressFrom')).replace("%Y%", t('backoffice.betshops.ipAddressTo')))
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ] : []}
                                        validateFirst
                                    >
                                        <Input
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.ipAddressFrom')}`}
                                            onChange={() => {
                                                setTimeout(() => {
                                                    if (getFieldValue('to') !== "")
                                                        validateFields(['to'])
                                                }, 0)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('backoffice.betshops.ipAddressTo')} *`}
                                        className="rt--form-item-without-margin rt--general-form-item"
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.ipAddressTo')}`}
                                        name="to"
                                        rules={selectedType === IP_TYPE.RANGE ? [
                                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                            () => ({
                                                validator(rule, value) {
                                                    if (value) {
                                                        if (!IP_V4_ADDRESS_REGEX.test(value) && !IP_V6_ADDRESS_REGEX.test(value)) {
                                                            return Promise.reject(t('backoffice.validation.fieldInvalid'))
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    const fromValue = getFieldValue("from");
                                                    if (value && fromValue && (IP_V4_ADDRESS_REGEX.test(fromValue) || IP_V6_ADDRESS_REGEX.test(fromValue))) {
                                                        if (ip6addrCompare(value, fromValue) !== 1) {
                                                            return Promise.reject(t('backoffice.validation.mustBeMore_and_not_equal_than_other').replace("%X%", t('backoffice.betshops.ipAddressTo')).replace("%Y%", t('backoffice.betshops.ipAddressFrom')))
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ] : []}
                                        validateFirst
                                    >
                                        <Input
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.ipAddressTo')}`}
                                            onChange={() => {
                                                setTimeout(() => {
                                                    if (getFieldValue('from') !== "")
                                                        validateFields(['from'])
                                                }, 0)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Fragment>
                        )
                    }

                </Row >
            </Form >
        </Modal >
    )
}

/** IPAccessAddComponent propTypes
    * PropTypes
*/
IPAccessAddComponent.propTypes = {
    /** Redux state property, is true when adding IP address */
    isSaving: PropTypes.bool,
    /** Redux action to add IP address */
    addBetshopAllowedIP: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addBetshopAllowedIP: (allowedIp, onSuccess) => {
            dispatch(addBetshopAllowedIP(allowedIp, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.betshops.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IPAccessAddComponent)