import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Spin } from "antd";

import Select from "components/common/select";
import Input from "components/common/input";
import NumericInput from "components/common/numericInput";

import { triggerCustomEvent } from 'utils/common';

import { PLAYER_FIND_BY } from 'constants/player.constants';
import { EMAIL_REGEX, TEL_REGEX, USERNAME_REGEX } from 'constants/regex.constants';

import { TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME, TRANSFER_POPUP_OK_ACTION_EVENT_NAME, TRANSFER_OPERATION_GROUP } from '../../../../constants';

import useTransfer from '../../../../hooks/useTransfer';

import { getPlayerInfoByExternalId } from '../../../../api';

const EposTransferSearchPlayer = () => {

    const { t } = useTranslation();

    const [findBy, setFindBy] = useState(PLAYER_FIND_BY.ID);

    const [isLoading, setIsLoading] = useState(false);

    const [notFound, setNotfound] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const { transferData } = useTransfer();

    const { group } = transferData ?? {};

    const searchFieldValidationRules = useMemo(() => {
        const rules = [
            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
        ];
        switch (findBy) {
            case PLAYER_FIND_BY.USERNAME:
                rules.push({ pattern: USERNAME_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case PLAYER_FIND_BY.EMAIL:
                rules.push({ pattern: EMAIL_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case PLAYER_FIND_BY.MOBILE:
                rules.push({ pattern: TEL_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            default:
                break;
        }
        return rules;
    }, [findBy])


    const makeSearchFieldLabel = findByParam => {
        switch (findByParam) {
            case PLAYER_FIND_BY.ID:
                return t("backoffice.players.playerId");
            case PLAYER_FIND_BY.USERNAME:
                return t("backoffice.players.username");
            case PLAYER_FIND_BY.EMAIL:
                return t("backoffice.players.email");
            case PLAYER_FIND_BY.MOBILE:
                return t("backoffice.players.phoneNumber");
            default:
                return ""
        }
    }

    const handler = useCallback(() => {
        validateFields()
            .then(data => {
                setIsLoading(true);

                const d = {};
                switch (data.findBy) {
                    case PLAYER_FIND_BY.ID:
                        d["externalId"] = data.field;
                        break;
                    case PLAYER_FIND_BY.USERNAME:
                        d["userName"] = data.field;
                        break;
                    case PLAYER_FIND_BY.EMAIL:
                        d["email"] = data.field;
                        break;
                    case PLAYER_FIND_BY.MOBILE:
                        d["mobile"] = data.field;
                        break;
                    default:
                        break;
                }

                if(group === TRANSFER_OPERATION_GROUP.WITHDRAWAL){
                    d.verificationCode = data.verificationCode;
                }

                getPlayerInfoByExternalId(d)
                    .then(response => {
                        if (!response) {
                            setNotfound(true)
                        } else {
                            setNotfound(false);
                            triggerCustomEvent(
                                TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME, 
                                { info: { ...response, verificationCode: d.verificationCode } }
                            );
                        }
                    })
                    .finally(() => setIsLoading(false));

            }).catch(ex => {
                console.log(ex)
            })

    }, [])

    /** On Transfer */
    useEffect(() => {
        document.addEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);
        }
    }, [handler])

    return (
        <Spin
            spinning={isLoading} wrapperClassName="rt--form-spin"
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    findBy: PLAYER_FIND_BY.ID
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={
                                (
                                    <span className='rt--title rt--font-regular rt--font-normal'>{t("backoffice.players.findBy")}</span>
                                )
                            }
                            name="findBy"
                        >
                            <Select
                                options={
                                    Object.values(PLAYER_FIND_BY)
                                        .map(item => (
                                            { value: item, text: makeSearchFieldLabel(item) }
                                        ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t("backoffice.players.findBy")}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                onChange={value => setFindBy(value)}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label={`${makeSearchFieldLabel(findBy)} *`}
                            name="field"
                            rules={searchFieldValidationRules}
                            className={"rt--general-form-item " + (group === TRANSFER_OPERATION_GROUP.DEPOSIT ? " rt--form-item-without-margin" : "")}
                            data-placeholder={`${t('backoffice.common.enter')} ${makeSearchFieldLabel(findBy)}`}
                            validateFirst
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${makeSearchFieldLabel(findBy)}`}
                            />

                        </Form.Item>
                    </Col>

                    {
                        group === TRANSFER_OPERATION_GROUP.WITHDRAWAL && (
                            <Col span={24}>
                                <Form.Item
                                    label={`${t('backoffice.players.securityCode')} *`}
                                    name="verificationCode"
                                    rules={[
                                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                        { min: 3, message: t('backoffice.validation.fieldInvalid') }
                                    ]}
                                    className="rt--general-form-item rt--form-item-without-margin"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.securityCode')}`}
                                    validateFirst
                                >
                                    <NumericInput
                                        disableFormatting
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.securityCode')}`}
                                        maxLength={6}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }

                    {
                        notFound && (
                            <Col span={24}>
                                <div className="rt--transfer-empty rt--flex rt--flex-col rt--justify-center rt--align-center rt--mt-40">
                                    <i className="icon-empty rt--font-bigest" />
                                    <h5 className="rt--font-bold rt--font-big rt--mb-6 rt--mt-8">{t('backoffice.players.playerNotFound')}</h5>
                                    <span className="rt--title rt--font-regular rt--font-normal">{t('backoffice.players.playerNotFoundInfo')}</span>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </Form>
        </Spin>
    )
}   

export default EposTransferSearchPlayer;