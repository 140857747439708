import { useEffect, useState } from "react";
import { Outlet } from "react-router";

import { Spin } from "antd";

import { translations } from "NEW/src/core/lib/translations";
import { globalStore } from "NEW/src/core/stores/globalStore";
import { isAuthenticatedSelector, useAuthSelector } from "NEW/src/core/stores/authStore";
import {initLocale} from "NEW/src/core/helpers/language/initLocale";

const AppWrapper = () => {
	const isAuthenticated = useAuthSelector(isAuthenticatedSelector);

	const [isInitialized, setIsInitialized] = useState(isAuthenticated);

	useEffect(() => {
		if (!isAuthenticated) {
			initLocale();
		}
	}, []);

	useEffect(() => {
		// TODO: integration specific condition
		if (!isAuthenticated) {
			Promise.all([
				globalStore.fetchSystemLanguages(),
				translations.init()
			])
				.finally(() => {
					setIsInitialized(true);
				});
		}
	}, [isAuthenticated]);

	if (!isInitialized) {
		return <Spin />;
	}

	return <Outlet />;
};

export default AppWrapper;
