import React from 'react';

import Level1Image from "assets/images/level_1.svg";
import Level2Image from "assets/images/level_2.svg";
import Level3Image from "assets/images/level_3.svg";
import Level4Image from "assets/images/level_4.svg";
import Level5Image from "assets/images/level_5.svg";
import Level6Image from "assets/images/level_6.svg";
import Level7Image from "assets/images/level_7.svg";

const SecurityLevel = ({ permission }) => {
    const securityLevel = permission?.securityLevel ?? null;

    return securityLevel ? (
        <div className="rt--permissions-table-level rt--ml-4 rt--flex rt--align-center rt--justify-center">
            <img
                src={
                    securityLevel === 1 ? Level1Image :
                        securityLevel === 2 ? Level2Image :
                            securityLevel === 3 ? Level3Image :
                                securityLevel === 4 ? Level4Image :
                                    securityLevel === 5 ? Level5Image :
                                        securityLevel === 6 ? Level6Image :
                                            securityLevel === 7 ? Level7Image : null
                }
                alt="Level"
            />
        </div>
    ) : <div className="rt--permissions-table-level rt--ml-4 rt--flex rt--align-center rt--justify-center" />;
};

export default SecurityLevel;
