import React, { useEffect } from "react";

import withSuspense from "NEW/src/core/HOCs/withSuspense";
import { isRTL } from "NEW/src/core/helpers/language/isRTL";
import { useTranslation } from "NEW/src/core/hooks/useTranslation";

const RTL = React.lazy(() => import("NEW/src/layouts/global/auth/RTLStyles/rtl"));
const LTR = React.lazy(() => import("NEW/src/layouts/global/auth/RTLStyles/ltr"));

const Styles = () => {
	// TODO: not to find better way to load styles
	const { locale } = useTranslation();

	const canLoadRTL = isRTL(locale);

	useEffect(() => {
		if (canLoadRTL) {
			document.body.classList.add("rt--rtl");
		} else {
			document.body.classList.remove("rt--rtl");
		}
	}, [locale]);

	return canLoadRTL ? <RTL /> : <LTR />;
};

export default withSuspense(Styles);
