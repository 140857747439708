import {isFranchisingMode} from "utils/common";

/** Function to make name text
 * @param {string} resource
 * @returns {string}
 * @function
 * @memberOf PermissionsComponent
 */
export const makeName = (resource, t) => {
    let res = resource;

    if (isFranchisingMode()) {
        if (
            (resource.startsWith("Agent") && resource !== "Agent_AdditionalAccesses") ||
            resource.startsWith("Reports_Agent")
        ) {
            res = resource.replace("Agent", "BetshopOwner")
        } else if (resource === "Project_AgentSystem") {
            res = "Project_Franchising"
        }
    }

    return t(`backoffice.permissions.${res}`)
}
