import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import 'translations/config';
import { RouterProvider } from 'react-router-dom';

import ConfirmationProvider from 'components/common/confirmationProvider';
import UnsavedFormsProvider from 'components/common/unsavedFormsProvider';

import router from './routes/router';
import store from 'store/configureStore';
import 'utils/axios';
import { isAuthenticated, startWatchingRefreshTokenExpiration } from 'utils/auth';
import { isMobile, isIOS, IOSversion } from 'utils/common';
import {ConfigProvider} from "antd";
import {theme} from "./theme";


if (isAuthenticated()) {
	startWatchingRefreshTokenExpiration();
}

store.subscribe(() => { });

const App = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
		if (isMobile()) {
			document.documentElement.setAttribute("data-ismobile", 1)
		} else {
			document.documentElement.setAttribute("data-ismobile", 0)
		}
	}, [])

	const appHeight = () => {
		const doc = document.documentElement
		doc.style.setProperty('--app-height', window.innerHeight + "px")
	}


	useEffect(() => {
		appHeight();
		window.addEventListener("resize", appHeight)
	}, [])


	const iosScrollHandler = e => {
		const target = e.target;
		const overlay = document.getElementsByClassName("rt--block-scroll")[0];


		window.onscroll = function (ev) {
			if ((document.body.offsetHeight + window.pageYOffset) > window.innerHeight) {
				overlay.classList.add("rt--block-scroll-active");
			} else {
				overlay.classList.remove("rt--block-scroll-active");
			}
		};



		if (target.classList.contains("ant-input")) {
			if (overlay && !overlay.classList.contains("rt--block-scroll-active")) {
				overlay.classList.add("rt--block-scroll-active");
			}

			/** Is in modal */
			if (target.closest(".ant-drawer-wrapper-body") && !target.closest(".rt--select-dropdown")) {
				const overlayModal = target.closest(".ant-drawer-wrapper-body");
				overlayModal.classList.add("rt--modal-overlay-active");
			}
		} else {

			if (overlay) {
				overlay.classList.remove("rt--block-scroll-active");
			}

			const overlayModal = document.getElementsByClassName("rt--modal-overlay-active")[0];
			if (overlayModal) {
				overlayModal.classList.remove("rt--modal-overlay-active");
			}

			const searchFocused = document.getElementsByClassName("ant-input-affix-wrapper-focused")[0];
			if (searchFocused) {
				overlay.classList.add("rt--block-scroll-active");
			} else {
				overlay.classList.remove("rt--block-scroll-active");
			}
		}
	}

	const iosKeyPress = e => {
		const overlay = document.getElementsByClassName("rt--block-scroll")[0];
		if (e.which === 0) {
			if (overlay) {
				overlay.classList.remove("rt--block-scroll-active");
			}

			if ((document.body.offsetHeight + window.pageYOffset) > window.innerHeight) {
				overlay.classList.add("rt--block-scroll-active");
			} else {
				overlay.classList.remove("rt--block-scroll-active");
			}

			const overlayModal = document.getElementsByClassName("rt--modal-overlay-active")[0];
			if (overlayModal) {
				overlayModal.classList.remove("rt--modal-overlay-active");
			}
		}
	}

	const versionInfo = IOSversion();

	useEffect(() => {
		if (isIOS()) {
			if (versionInfo.version < 16) {
				document.getElementsByClassName("App")[0].classList.add("app-ios-low");
			} else {
				document.getElementsByClassName("App")[0].classList.add("app-ios");
			}
		}

		document.addEventListener("click", iosScrollHandler);
		document.addEventListener("focusout", iosKeyPress);
		return () => {
			document.removeEventListener("click", iosScrollHandler);
			document.removeEventListener("focusout", iosKeyPress);
		}

	}, [])


	window.addEventListener("orientationchange", function () {

		if (window.orientation == 0 || window.orientation == 180) {
			null
		} else {
				const x = document.getElementsByClassName("App")[0];
				x.focus()
		}
	}, false);


	return (
		<Provider store={store}>
			<ConfigProvider theme={theme}>
				<UnsavedFormsProvider>
					<ConfirmationProvider>
						<div className="App" tabIndex="0">
							<div className='rt--block-scroll'></div>
							<div className='rt--rotate'>
								<div className='rt--rotate-phone'></div>
								<span className='rt--rotate-message'>Please rotate your device!</span>
							</div>
							<RouterProvider router={router} />
						</div>
					</ConfirmationProvider>
				</UnsavedFormsProvider>
			</ConfigProvider>
		</Provider>
	)
}

export default App;
