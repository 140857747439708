import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Checkbox, Select as AntSelect } from 'antd';

import NotFound from '../notFound';
import Tag from '../tag';

import { classNames } from 'utils/common';
import {
    getMergedSelectedValues,
    filterAndSortBySearchValue,
    getSelectedValues,
    getPropFromMatchObj,
} from '../helpers';

import { ALL_VALUE } from '../constants';

function getScrollParent(node) {
    if (node == null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentNode);
    }
}

const SelectDesktop = ({
    options,
    value,
    onChange,
    disabled,
    placeholder,
    className = "",
    withLabel,
    search = false,
    isMultiple,
    showSelectAllButton = true,
    isDark,
    onSearch,
    disableFiltering=false,
    showArrow=true,
    ...restProps
}) => {

    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState("");

    const antSelectRef = useRef(null);

    const filteredOptions = (
        search && !disableFiltering
            ? filterAndSortBySearchValue({ options, searchValue })
            : options
    )

    const isAllOptionVisible = (
        isMultiple &&
        showSelectAllButton &&
        filteredOptions.length > 1 &&
        searchValue === ""
    );

    const mergedSelectedValues = getMergedSelectedValues({
        isMultiple,
        options,
        selectedValues: value,
        isAllOptionVisible,
    });

    const handleInternalChange = (newSelectedValues) => {
        const updatedSelectedValues = getSelectedValues({
            isMultiple: isAllOptionVisible,
            options,
            prevSelectedValues: mergedSelectedValues,
            newSelectedValues,
        })

        onChange(updatedSelectedValues);
    }

    // Close dropdown on scroll
    useEffect(() => {
        const onScroll = e => {
            const scrollableParent = getScrollParent(e.target);

            if (!scrollableParent) {
                return;
            }

            if (!e.target.classList.contains('rc-virtual-list-holder')) {
                antSelectRef.current.blur();
            }
        }

        document.addEventListener('scroll', onScroll, true);

        return () => {
            document.removeEventListener('scroll', onScroll, true);
        }
    }, [])

    /** Multi mode props */
    const multipleModeProps = isMultiple ? {
        maxTagCount: "responsive",
        maxTagTextLength: 18,
        mode: "multiple",
        menuItemSelectedIcon: (
            <i
                className="icon-ok"
                style={{
                    visibility: "hidden",
                }}
            />
        ),
        tagRender: ({ value, onClose, isMaxTag, ...props }) => {
            if (value === ALL_VALUE) {
                return;
            }

            const label = isMaxTag ? props.label : getPropFromMatchObj({ value, options, prop: "text"});

            return (
                <Tag
                    label={label}
                    isMaxTag={isMaxTag}
                    onClose={onClose}
                    disabled={disabled}
                />
            )
        }
    } : {}

    /** Search mode props */
    const searchModeProps = search ? {
        filterOption: () => true,
        onSearch: inputValue => {
            onSearch && onSearch(inputValue);
            setSearchValue(inputValue)
        },
        onSelect: () => setSearchValue(""),
    } : {}

    return (
        <AntSelect
            {...restProps}
            ref={antSelectRef}
            suffixIcon={showArrow === false ? null : <i className="icon-down" />}
            value={mergedSelectedValues}
            onChange={handleInternalChange}
            disabled={disabled}
            placeholder={placeholder}
            showSearch={search}
            notFoundContent={<NotFound />}
            className={classNames(
                withLabel && "rt--select-with-label",
                isDark && "rt--select-dark",
                className,
            )}
            {...multipleModeProps}
            {...searchModeProps}
        >
            {
                isAllOptionVisible && (
                    <AntSelect.Option value={ALL_VALUE} className={"rt--select-all rt--mb-2"}>
                        <div
                            className={classNames(
                                "rt--select-with-label-item",
                                "rt--flex",
                                "rt--align-center",
                            )}
                        >

                            {
                                isMultiple && (
                                    <Checkbox
                                        className="rt--select-checkbox"
                                        checked={value && value.length === options.length}
                                    />
                                )
                            }
                            <span
                                className={classNames(
                                    "rt--title",
                                    "rt--font-normal",
                                    "rt--font-regular",
                                )}
                            >
                                {value?.length === options.length ? t("backoffice.common.deselectAll") : t("backoffice.common.selectAll")}
                            </span>
                        </div>
                    </AntSelect.Option>
                )
            }
            {
                filteredOptions.map(option => !option.subs ? (
                    <AntSelect.Option key={option.value || option.text} value={option.value}>
                        {
                            withLabel
                                ? (
                                    <div
                                        className={classNames(
                                            "rt--select-with-label-item",
                                            "rt--flex",
                                            "rt--align-center",
                                            "rt--justify-between",
                                        )}
                                    >
                                        <span
                                            className={classNames(
                                                "rt--title",
                                                "rt--font-normal",
                                                "rt--font-regular",
                                            )}
                                        >
                                            {option.text}
                                        </span>
                                        <small
                                            className={classNames(
                                                "rt--title",
                                                "rt--font-small",
                                                "rt--font-regular",
                                                "rt--pl-16",
                                                "rt--pr-16",
                                            )}
                                        >
                                            {option.label}
                                        </small>
                                    </div>
                                ) : (
                                    <span
                                        className={classNames(
                                            "rt--flex",
                                            "rt--align-center",
                                        )}
                                    >
                                        {
                                            isMultiple && (
                                                <Checkbox
                                                    className="rt--select-checkbox"
                                                    checked={value && value.includes(option.value)}
                                                />
                                            )
                                        }
                                        <span
                                            className={classNames(
                                                "rt--title",
                                                "rt--font-normal",
                                                "rt--font-regular",
                                            )}
                                        >
                                            {option.text}
                                        </span>
                                    </span>
                                )

                        }
                    </AntSelect.Option>
                ) : (
                    <AntSelect.OptGroup key={option.value || option.text} label={option.text}>
                        {
                            option.subs.map(sub => (
                                <AntSelect.Option key={sub.value || sub.text} value={sub.value}>
                                    {sub.text}
                                </AntSelect.Option>
                            ))
                        }
                    </AntSelect.OptGroup>
                ))
            }
        </AntSelect>
    );
}

/** SelectDesktop propTypes
    * PropTypes
*/
SelectDesktop.propTypes = {
    /** Select options */
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        label: PropTypes.string,
        subs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })),
    /** Callback which called on input change */
    onChange: PropTypes.func,
    /** Input value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
    /** Is disabled */
    disabled: PropTypes.bool,
    /** Placeholder */
    placeholder: PropTypes.string,
    /** Classname */
    className: PropTypes.string,
    /** Are the options with label */
    withLabel: PropTypes.bool,
    /** Enable search */
    search: PropTypes.bool,
    /** Is Multiple mode select */
    isMultiple: PropTypes.bool,
    /** Is dark mode dropdown */
    isDark: PropTypes.bool,
    /** Show select all button or not */
    showSelectAllButton: PropTypes.bool,
    /** On Search handler */
    onSearch: PropTypes.func,
    /** If true then will disable filtering by frontend */
    disableFiltering: PropTypes.bool,
    /** Show arrow */
    showArrow: PropTypes.bool
}

export default SelectDesktop;
