//#region react
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region actions
import {
    getFailedTransactions,
    resendFailedTransaction,
    setFailedTransactionsFilters,
    setFailedTransactionsSorting
} from "store/actions/dashboard/failedTransactions/failedTransactions.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Filters from "./filters.component";
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import ApiUrls from 'constants/api.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { RESPONSE_CODES } from "constants/errorCode.constants";
import { expandColumns, tableColumns } from "./columns";
import { SEARCH_TYPE } from "components/common/search/constants";
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import useFormat from "../../hooks/useFormat";
//#endregion

const EXPAND_TABLE_ROW_UNIQUE_KEY = "historyId";

const FailedTransactions = ({
    getFailedTransactions,
    resendFailedTransaction,
    setFailedTransactionsSorting,
    setFailedTransactionsFilters,
    failedTransactions,
    isLoading,
    total,
    sorting,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();
    const {formatNumber} = useFormat();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.FAILED_TRANSACTIONS,
        action: PERMISSION_ACTION.EXPORT
    })

    const hasResendPermission = hasPermission({
        resource: PERMISSION_RESOURCE.FAILED_TRANSACTIONS,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setFailedTransactionsFilters({
            ...filters,
            id: value
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        expandTableColumns,
        columnsForExport
    } = useMemo(() => {
        return tableColumnsCreator({
            expandColumns,
            mainColumns: tableColumns,
            constructForExport: true,
            additionalProps: {formatNumber}
        });
    }, [formatNumber])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasResendPermission) {
        tableRowActions.push({
            title: t("backoffice.failedtransactions.resend"),
            confirmationMessage: t("backoffice.failedtransactions.confirmationMessage"),
            confirmationTitle: t("backoffice.failedtransactions.confirmationTitle"),
            icon: "icon-publish",
            showConfirmation: true,
            onClick: record => resendFailedTransaction(record.id, record.createdAt),
            disabled: record => !(record.lastResultCode === RESPONSE_CODES.NotAvailable || record.lastResultCode === RESPONSE_CODES.InternalServerError)
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            type: SEARCH_TYPE.NUMERIC,
            placeholder: t("backoffice.failedtransactions.transactionId"),
            loadFn: getFailedTransactions,
            onSearch: handleSearchChange,
            numeric: true,
            maxLength: 12
        },
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.failedTransactions') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.failedTransactions"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_FAILED_TRANSACTIONS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={failedTransactions}
                enableReload={true}
                loadFn={getFailedTransactions}
                sorting={sorting}
                setSortingFn={setFailedTransactionsSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                expandable={{
                    type: EXPAND_TABLE_TYPES.TABLE,
                    details: (rowData) => ({
                        columns: expandTableColumns,
                        data: rowData?.history,
                        uniqueKey: EXPAND_TABLE_ROW_UNIQUE_KEY,
                    }),
                }}
            />
        </MainDashboardLayout>
    )
};

/** FailedTransactions propTypes
 * PropTypes
*/
FailedTransactions.propTypes = {
    /** Redux action to get failed transactions */
    getFailedTransactions: PropTypes.func,
    /** Redux action to resend failed transactions */
    resendFailedTransaction: PropTypes.func,
    /** Redux action to get user logs details */
    getUserLogDetails: PropTypes.func,
    /** Redux state property, represents the array of failed Transactions  */
    failedTransactions: PropTypes.array, //TODO: complete
    /** Redux state property, is true when loading failed Transactions */
    isLoading: PropTypes.bool,
    /** Redux state property, failed Transactions total count */
    total: PropTypes.number,
    /** Redux action to set failed transactions sorting */
    setFailedTransactionsSorting: PropTypes.func,
    /** Redux action to set failed transactions filters */
    setFailedTransactionsFilters: PropTypes.func,
    /** Redux state property, failed Transactions sorting */
    sorting: sortingType,
    /** Redux state property, failed Transactions filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getFailedTransactions: nextPage => {
        dispatch(getFailedTransactions(nextPage));
    },
    resendFailedTransaction: (id, createdAt) => {
        dispatch(resendFailedTransaction(id, createdAt));
    },
    setFailedTransactionsSorting: sorting => {
        dispatch(setFailedTransactionsSorting(sorting));
    },
    setFailedTransactionsFilters: filters => {
        dispatch(setFailedTransactionsFilters(filters));
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.failedTransactions.isLoading,
        failedTransactions: state.failedTransactions.failedTransactions,
        total: state.failedTransactions.total,
        sorting: state.failedTransactions.sorting,
        filters: state.failedTransactions.filters,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FailedTransactions);
