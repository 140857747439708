import React from 'react';
import PropTypes from 'prop-types';

import BankFormComponent from '../bankForm';

import { REGISTRATION_FORM_TYPE, WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

const WithdrawalConfigComponent = ({
    onTabChange
}) => {
    return (
        <BankFormComponent
            type={REGISTRATION_FORM_TYPE.PLAYER_WITHDRAWAL}
            onTabChange={onTabChange}
            widgetTransactionType={WIDGET_TRANSACTION_TYPE.WITHDRAWAL}
        />
    );
}

/** WithdrawalConfigComponent propTypes
    * PropTypes
*/
WithdrawalConfigComponent.propTypes = {
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default WithdrawalConfigComponent;