import {
    SET_COMPANY_AVAILABLE_LANGUAGES_BEFORE,
    SET_COMPANY_AVAILABLE_LANGUAGES_FINISH,
    SET_COMPANY_AVAILABLE_LANGUAGES,
    SET_GLOBAL_COMPANY_ID,
    SET_GLOBAL_PROJECT_ID,
    SET_GLOBAL_PROJECT_TYPE,
    SET_GLOBAL_PAYMENT_TYPE,
    SET_GAME_PROVIDERS,
    SET_GLOBAL_PLAT_VERSION, SET_GLOBAL_PROJECT_MODE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_GLOBAL_COMPANY_ID:
            return {
                ...state,
                globalCompanyId: payload.id ? payload.id : state.globalCompanyId,
                availableLanguages: {
                    isLoading: false,
                    availableLanguages: {}
                }
            };
        case SET_GLOBAL_PROJECT_ID:
            return {
                ...state,
                globalProjectId: payload.id ? payload.id : undefined,
                gameProviders: []
            };
        case SET_GLOBAL_PROJECT_TYPE:
            return {
                ...state,
                globalProjectType: payload.type ? payload.type : undefined,
            };
        case SET_GLOBAL_PROJECT_MODE:
            return {
                ...state,
                globalProjectMode: payload.mode ? payload.mode : undefined,
            };
        case SET_GLOBAL_PAYMENT_TYPE:
            return {
                ...state,
                globalPaymentType: payload.type ? payload.type : 0,
            };
        case SET_GLOBAL_PLAT_VERSION:
            return {
                ...state,
                platVersion: payload.version ? payload.version : 'v1.0',
            };
        case SET_COMPANY_AVAILABLE_LANGUAGES_BEFORE:
            return {
                ...state,
                availableLanguages: {
                    ...state.availableLanguages,
                    isLoading: true
                }
            }
        case SET_COMPANY_AVAILABLE_LANGUAGES_FINISH:
            return {
                ...state,
                availableLanguages: {
                    ...state.availableLanguages,
                    isLoading: false
                }
            }
        case SET_COMPANY_AVAILABLE_LANGUAGES:
            return {
                ...state,
                availableLanguages: {
                    ...state.availableLanguages,
                    availableLanguages: payload.languages
                }
            }
        case SET_GAME_PROVIDERS:
            return {
                ...state,
                gameProviders: payload.providers
            };
        default:
            return state;
    }
};
