import React from "react";

import RealTimeSportBets from "pages/betHistory/realTimeSportBets/realTimeSportBets.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const RealTimeSportBetsRoute = () => {
    return <RealTimeSportBets />;
};

export default withPermission(
    withAuth(RealTimeSportBetsRoute),
    { resource: PERMISSION_RESOURCE.BETS_ONLINE, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
