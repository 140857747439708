export const PERMISSION_RESOURCE = {

    DASHBOARD: "dashboard",

    COMPANY: "company",
    COMPANY_SECURITY_SETTINGS: "company_SecuritySettings",
    COMPANY_LANGUAGES: "company_Languages",
    COMPANY_GENERALINFO: "company_GeneralInfo",

    PROJECT: "project",
    PROJECT_CURRENCIES: "project_Currencies",
    PROJECT_AGENT_SYSTEM: "project_AgentSystem",
    PROJECT_REGISTRATION_FORM: "project_RegistrationForm",
    PROJECT_PROVIDER_SETTINGS: "project_ProviderSettings",
    PROJECT_GENERALINFO: "project_GeneralInfo",
    PROJECT_WALLETS: "project_Wallets",
    PROJECT_PAYOUT_TICKET: "project_PayoutTicket",
    PROJECT_LIMITS: "project_Limits",
    PROJECT_VS_BET_LIMITS: "project_VSBetLimits",
    PROJECT_COMMISSION_PLAN: "project_CommissionPlan",
    PROJECT_INTEGRATION: "project_Integration",
    PROJECT_PAYMENT: "project_Payment",
    PROJECT_REGULATIONS: "project_Regulations",

    BETSHOP: "betShop",
    BETSHOP_GENERALINFO: "betShop_GeneralInfo",
    BETSHOP_CURRENCIES : "betShop_Currencies",
    BETSHOP_LIMIT_SETTINGS: "betShop_LimitSettings",
    BETSHOP_IP_ACCESS_RULE: "betShop_IPAccessRule",
    BETSHOP_WALLETS: "betShop_Wallets",
    BETSHOP_ADDITIONAL_ACCESS: "betShop_AdditionalAccesses",

    SYSTEM_LANGUAGES: "systemLanguages",

    SYSTEM_CURRENCIES: "systemCurrencies",

    PERMISSION: "permission",
    PERMISSION_GROUP: "permission_Group",
    PERMISSION_REQUESTS: "permission_Requests",

    ADMIN: "admin",
    ADMIN_FORCE_LOGOUT: "admin_ForceLogout",
    ADMIN_GENERALINFO: "admin_GeneralInfo",
    ADMIN_SESSIONS: "admin_Sessions",
    ADMIN_COMPANY_ACCESS: "admin_CompanyAccess",
    ADMIN_PROJECT_ACCESS: "admin_ProjectAccess",
    ADMIN_ADDITIONAL_ACCESS: "admin_AdditionalAccess",
    ADMIN_PERMISSIONS: "admin_Permissions",

    ACCESS_MANAGER: "accessManager",
    ACCESS_MANAGER_GENERALINFO: "accessManager_GeneralInfo",
    ACCESS_MANAGER_SESSIONS: "accessManager_Sessions",

    BETS: "bets",
    BETS_ONLINE: "bets_OnlineBets",
    BETS_ONLINE_TOTALS: "bets_OnlineBetsTotals",
    BETS_RETAIL: "bets_RetailBets",
    BETS_RETAIL_TOTALS: "bets_RetailBetsTotals",

    CASHIER: "cashier",
    CASHIER_GENERALINFO: "cashier_GeneralInfo",
    CASHIER_FORCE_LOGOUT: "cashier_ForceLogout",
    CASHIER_SESSIONS: "cashier_Sessions",
    CASHIER_ADDITIONAL_ACCESS: "cashier_AdditionalAccesses",

    BETSHOP_MANAGER: "betShopManager",
    BETSHOP_MANAGER_GENERALINFO: "betShopManager_GeneralInfo",
    BETSHOP_MANAGER_SESSIONS: "betShopManager_Sessions",

    TRANSLATION: "translation",

    PLAYER: "player",
    PLAYER_GENERALINFO: "player_GeneralInfo",
    PLAYER_TOTALS: "player_WalletsTotals",
    PLAYER_TRANSACTIONS_HISTORY: "player_TransactionHistory",

    AGENT: "agent",
    AGENT_FORCE_LOGOUT: "agent_ForceLogout",
    AGENT_CURRENCIES : "agent_Currencies",
    AGENT_GENERALINFO: "agent_GeneralInfo",
    AGENT_WALLETS: "agent_Wallets",
    AGENT_SESSIONS: "agent_Sessions",
    AGENT_NETWORK: "agent_NetWork",
    AGENT_PERMISSIONS: "agent_Permissions",
    AGENT_LIMITS: "agent_Limits",
    AGENT_ADDITIONAL_ACCESSES: "agent_AdditionalAccesses",
    AGENT_COMMISSION_PLAN: "agent_CommissionPlan",
    AGENT_PAYMENT: "agent_Payment",
    AGENT_WALLETS_TOTALS: "agent_WalletsTotals",

    REPORTS: "reports",
    REPORTS_AGENT_TRANSACTIONS: "reports_AgentTransactions",
    REPORTS_BETSHOP_TRANSACTIONS: "reports_BetShopTransactions",
    REPORTS_CASHIER_TRANSACTIONS: "reports_CashierTransactions",
    REPORTS_COMMISSION_CALCULATIONS: "reports_CommissionCalculations",

    REPORTS_AGENT_PERFORMANCE: "reports_AgentPerformance",
    REPORTS_AGENT_PERFORMANCE_TOTALS: "reports_AgentPerformanceTotals",
    REPORTS_PLAYER_PERFORMANCE: "reports_PlayerPerformance",
    REPORTS_PLAYER_PERFORMANCE_TOTALS: "reports_PlayerPerformanceTotals",

    REPORTS_BETSHOP_PERFORMANCE: "reports_BetShopPerformance",
    REPORTS_BETSHOP_PERFORMANCE_TOTALS: "reports_BetShopPerformanceTotals",

    REPORTS_PRODUCTS_PERFORMANCE: 'reports_ProductsPerformance',
    REPORTS_PRODUCTS_PERFORMANCE_TOTALS: 'reports_ProductsPerformanceTotals',

    USER_LOGS: "userLogs",

    DEVELOPER: "developer",

    FAILED_TRANSACTIONS: "failedTransactions",

    PAYMENT: "payment",
    PAYMENT_DEPOSIT_TRANSACTION_HISTORY: "payment_DepositTransactionHistory",
    PAYMENT_WITHDRAW_TRANSACTION_HISTORY: "payment_WithdrawTransactionHistory",
    PAYMENT_DEPOSIT_TRANSACTION_REQUEST: "payment_DepositTransactionRequest",
    PAYMENT_WITHDRAW_TRANSACTION_REQUEST: "payment_WithdrawTransactionRequest",
}

export const PERMISSION_ACTION = {
    VIEW: "View",
    MODIFY: "Modify",
    DELETE: "Delete",
    CREATE: "Create",
    EXPORT: "Export"
}

export const PERMISSION_TYPE = {
    ADMIN : 1 << 0,
    AGENT : 1 << 1,
    AGENT_PLAYER: 1 << 2,
    BETSHOP_OWNER: 1 << 4
}
