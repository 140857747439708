import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

import { Spin } from 'antd';

import {
    getProjectBankForm,
    saveProjectBankForm,
} from "store/actions/dashboard/projects/paymentsConfig.action";
import { getCompanyAvailableLanguages } from 'store/actions/dashboard/common/common.action';

import SubTabFormDashboardLayout from 'components/layouts/tab/subtab/form';
import FormBuilder from 'components/common/formBuilder';
import Modal from "components/common/modal";

import BankFormTranslations from '../bankFormTranslations';

import WidgetPreview from "../widgetPreview";

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import LanguageUtils from 'utils/languages';
import { sendDataToIframe, isMobile } from 'utils/common';
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { REGISTRATION_FORM_TYPE, WIDGET_TRANSACTION_TYPE } from "constants/project.constants";
import { COMPANY_CONTROL_RULE } from "constants/company.constants";
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import registrationFormType from "types/project/registrationForm.type";

const BankFormComponent = ({
    isLoading,
    isSaving,
    bankForm,
    getProjectBankForm,
    saveProjectBankForm,
    type,
    widgetTransactionType,
    onTabChange,
    getCompanyAvailableLanguages,
    companyAvailableLanguages,
    globalCompanyId
}) => {
    const { t } = useTranslation();
    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const [widgetPreview, setWidgetPreview] = useState(false);

    const navigateWithConfirmation = useUnsavedFormConfirmation({
        cb: navigate,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })


    const currentBankId = (new URLSearchParams(search)).get("bankId");
    const currentBankName = (new URLSearchParams(search)).get("bankName");

    const currentLn = LanguageUtils.getSelectedLanguage().toUpperCase();

    /** State for selected language code */
    const [languageCode, setLanguageCode] = useState(currentLn)

    const formData = bankForm[type].forms;
    const translations = bankForm[type].translations;

    /** Mapped translations for table */
    const bankTranslations = translations
        .filter(tr =>
            (tr.key.startsWith("playerdeposit_") && type === REGISTRATION_FORM_TYPE.PLAYER_DEPOSIT) ||
            (tr.key.startsWith("playerwithdrawal_") && type === REGISTRATION_FORM_TYPE.PLAYER_WITHDRAWAL)
        )
        .map(tr => ({
            translationKey: tr.key,
            text: tr.key.replace("playerdeposit_", "").replace("playerwithdrawal_", ""),
            value: tr.translations?.find(t => t.languageCode === languageCode)?.text ?? tr.text,
        }))


    const controlsToSendRef = useRef({});
    const translationsToSendRef = useRef({});

    const formBuilderRef = useRef({
        handleForm: null,
        isFormTouched: null,
    })

    const userCanModify = hasPermission(
        { resource: PERMISSION_RESOURCE.PROJECT_PAYMENT, action: PERMISSION_ACTION.MODIFY }
    );

    useEffect(() => {
        getProjectBankForm((new URLSearchParams(search)).get("bankId"), type);
    }, [])

    /** Load company available languages */
    useEffect(() => {
        getCompanyAvailableLanguages()
    }, [globalCompanyId])


    useEffect(() => {
        /** Send form fields */
        controlsToSendRef.current = {
            action: "bankFields",
            transactionType: widgetTransactionType,
            form: formData.filter(control => control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN),
        };
        sendDataToIframe("payment-widget-iframe-" + widgetTransactionType, controlsToSendRef.current);

    }, [formData])

    /** Send form translations */
    useEffect(() => {

        translationsToSendRef.current = {
            action: "bankFieldsTranslations",
            transactionType: widgetTransactionType,
            translations: translations
        };
        sendDataToIframe("payment-widget-iframe-" + widgetTransactionType, translationsToSendRef.current);

    }, [translations])

    /** Initialize post message listeners */
    useEffect(() => {
        const handler = e => {
            if (e.data && e.data.transactionType === widgetTransactionType) {
                if (e.data.action === "getBankForm") {
                    sendDataToIframe("payment-widget-iframe-" + widgetTransactionType, controlsToSendRef.current);

                    sendDataToIframe("payment-widget-iframe-" + widgetTransactionType, translationsToSendRef.current)
                }
            }
        }
        window.addEventListener("message", handler);

        return () => {
            window.removeEventListener("message", handler);
        }
    }, [])

    /** Function to translate field name */
    const fieldNameRender = fieldName => {
        const prefix = type === REGISTRATION_FORM_TYPE.PLAYER_DEPOSIT ? "playerdeposit_" : "playerwithdrawal_";
        return bankTranslations.find(tr => tr.translationKey === `${prefix}${fieldName}`)?.value ?? fieldName;
    }

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: () => formBuilderRef.current.handleForm(),
                        text: t("backoffice.common.save"),
                        enabled: true,
                        loading: isSaving,
                        disabled: !formBuilderRef.current.isFormTouched
                    },
                    {
                        type: "secondary",
                        onClick: () => {
                            navigateWithConfirmation((pathname + search + hash)
                                .replace("&bankId=" + encodeURIComponent(currentBankId), "")
                                .replace("&bankName=" + encodeURIComponent(currentBankName), ""),
                                { replace: true }
                            )
                        },
                        text: (
                            <span className='rt--back-button'>
                                <i className="icon-left rt--font-big" />
                                <span>
                                    {t("backoffice.common.back")}
                                </span>
                            </span>
                        ),
                        enabled: true,
                        className: "rt--button-secondary",
                        placement: "left",
                    },
                ]
            }

            dropdown={
                {
                    value: languageCode,
                    onChange: value => setLanguageCode(value),
                    items: Object.keys(companyAvailableLanguages).map(item => ({
                        key: item.toUpperCase(),
                        value: t(`backoffice.languages.${item}`)
                    }))
                }
            }

            header={
                {
                    button: {
                        icon: 'icon-eye',
                        type: "primary",
                        onClick: () => setWidgetPreview(true),
                        text: t("backoffice.common.preview"),
                        enabled: isMobile()
                    }
                }
            }
        >
            <div className={isMobile() ? '' : 'rt--payment rt--flex rt--justify-between'}>
                <Spin spinning={isLoading}>

                <span
                    className='rt--form-section-title rt--title rt--flex rt--pb-16 rt--font-bold rt--font-biger'>
                    {
                        t("backoffice.payments.playerFields")
                    }
                </span>

                    <FormBuilder
                        registrationForm={formData}
                        userCanModify={userCanModify}
                        onTabChange={onTabChange}
                        onFormFinish={(formControlsObj) => {
                            saveProjectBankForm((new URLSearchParams(search)).get("bankId"), type, formControlsObj)
                        }}
                        onFormChange={controls => {
                            controlsToSendRef.current = {
                                action: "bankFields",
                                transactionType: widgetTransactionType,
                                form: controls
                            };

                            sendDataToIframe("payment-widget-iframe-" + widgetTransactionType, controlsToSendRef.current)
                        }}
                        ref={formBuilderRef}
                        fieldNameRender={fieldNameRender}
                    />

                    <BankFormTranslations
                        type={type}
                        languageCode={languageCode}
                        onChange={translations => {
                            translationsToSendRef.current = {
                                action: "bankFieldsTranslations",
                                transactionType: widgetTransactionType,
                                translations: translations
                            };

                            sendDataToIframe("payment-widget-iframe-" + widgetTransactionType, translationsToSendRef.current)
                        }}
                        translations={bankTranslations}
                    />

                </Spin>
                {
                    !isMobile() && (
                        <WidgetPreview
                            languageCode={languageCode}
                            transactionType={widgetTransactionType}
                        />
                    )
                }

                {
                    widgetPreview && (
                        <Modal
                            title={t("backoffice.common.preview")}
                            onCancel={() => setWidgetPreview(false)}
                        >
                            <div className="rt--payment-preview">
                                <WidgetPreview
                                    languageCode={languageCode}
                                    transactionType={widgetTransactionType}
                                />
                            </div>
                        </Modal>
                    )
                }
            </div>
        </SubTabFormDashboardLayout>
    );
}

/** BankFormComponent propTypes
    * PropTypes
*/
BankFormComponent.propTypes = {
    /** Redux action to get project project payment bank registration form */
    getProjectBankForm: PropTypes.func,
    /** Redux action to save project project payment bank registration form */
    saveProjectBankForm: PropTypes.func,
    /** Redux state property, current bank form data */
    bankForm: PropTypes.shape({
        [REGISTRATION_FORM_TYPE.PLAYER_DEPOSIT]: PropTypes.shape({
            forms: PropTypes.arrayOf(registrationFormType)
        }),
        [REGISTRATION_FORM_TYPE.PLAYER_WITHDRAWAL]: PropTypes.shape({
            forms: PropTypes.arrayOf(registrationFormType)
        })
    }),
    /** Redux state property, is true when project payment is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when project payment is saving */
    isSaving: PropTypes.bool,
    /** Registration Form Type */
    type: PropTypes.oneOf(Object.values(REGISTRATION_FORM_TYPE)),
    /** Widget Transaction Type */
    widgetTransactionType: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE)),
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Redux action to get company available languages */
    getCompanyAvailableLanguages: PropTypes.func,
    /** Redux state property, represents the object of company available  languages */
    companyAvailableLanguages: PropTypes.object,
    /** Redux state, represents global company id */
    globalCompanyId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getProjectBankForm: (bankId, type) => {
            dispatch(getProjectBankForm(bankId, type));
        },

        saveProjectBankForm: (bankId, type, data) => {
            dispatch(saveProjectBankForm(bankId, type, data));
        },

        getCompanyAvailableLanguages: () => {
            dispatch(getCompanyAvailableLanguages())
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        bankForm: state.projects.edit.payment.banks.edit.bankForm,
        companyAvailableLanguages: state.common.availableLanguages.availableLanguages,
        globalCompanyId: state.common.globalCompanyId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankFormComponent);
