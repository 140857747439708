import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { SET_PROJECT_PROVIDER_SETTINGS } from "../../../actionTypes";

const setProjectProviderSettings = data => ({
    type: SET_PROJECT_PROVIDER_SETTINGS,
    payload: { data }
})

export const getProjectProviderSettings = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_PROVIDER_SETTINGS,
            method: Methods.GET
        })
        .then(({data : {value: providerSettings}}) => {
            dispatch(setProjectProviderSettings(providerSettings))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectProviderSettings = settings => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_PROVIDER_SETTINGS,
            method: Methods.POST,
            data: {
                ...settings
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectProviderSettings(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
