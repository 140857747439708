import { AuthStore } from "NEW/src/core/stores/authStore/store";

import { createSelector } from "NEW/src/core/lib/baseStore";
import {TOKEN_TYPE} from "NEW/src/core/data/constants/auth";

export const authStore = new AuthStore();

export const useAuthSelector = createSelector(authStore);

export const isAuthenticatedSelector = (state) => !!state.user && state.user.tokenType === TOKEN_TYPE.NONE
