import React from "react";

import CashiersComponent from "pages/retail/cashiers/cashiers.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const CashiersRoute = () => {
    return <CashiersComponent />;
};

export default withPermission(
    withAuth(CashiersRoute),
    { resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
