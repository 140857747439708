//#region react
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
//#endregion

//#region utils
import { isMobile, classNames } from "utils/common";
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
//#endregion

//#region constants
import { WALLET_TYPE_NAMES } from './constants';
//#endregion

const WalletBalanceChanges = ({
    balanceChanges = [],
    currencyCode,
    transactionTime
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const sortedBalanceChanges = [...balanceChanges].sort((c1, c2) => c1.type >= c2.type ? 1 : -1);

    const isMobileDevice = isMobile();

    return (
        <div
            className={classNames(
                "rt--flex rt--align-center",
                isMobileDevice ? "rt--justify-center rt--width-full rt--width-full-wallets rt--mb-16" : "rt--pr-8"
            )}
        >
            {!isMobileDevice && <i className="icon-pointing_arrow rt--font-bigest" />}

            {sortedBalanceChanges.map((change, index) => (
                <div className="rt--flex rt--align-center rt--pl-8 rt--pr-8" key={index}>
                    <div className="rt--flex rt--align-center">
                        <span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
                            {
                                Boolean(WALLET_TYPE_NAMES[change.type])
                                    ? t(`backoffice.common.${WALLET_TYPE_NAMES[change.type]}`)
                                    : t("backoffice.common.limit")
                            }

                            {(isMobileDevice ? '' : ':')}
                        </span>
                    </div>
                    <div className="rt--flex rt--align-center">
                        <span className="rt--title rt--font-normal rt--font-regular rt--pr-4">
                            {
                                formatAmount( change.before, currencyCode, transactionTime)
                            }
                        </span>

                        <i
                            className={classNames(
                                change.before < change.after ? "icon-arrow-up-right" : "icon-arrow-down-right",
                                "rt--font-normal"
                            )}
                        />

                        <span className="rt--title rt--font-normal rt--font-regular rt--pl-4">
                            {
                                formatAmount( change.after, currencyCode, transactionTime)
                            }
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}

/** WalletBalanceChanges propTypes
 * PropTypes
*/
WalletBalanceChanges.propTypes = {
    balanceChanges: PropTypes.array,
    currencyCode: PropTypes.string,
    transactionTime: PropTypes.string
}

export default WalletBalanceChanges