//#region react
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import { TRANSFER_ENTITY_TYPE, useTransfer } from 'components/common/transfer';
//#endregion

//#region actions
import {
    getAgentBetshops,
    setAgentBetshopsSorting
} from "store/actions/dashboard/agentSystem/agents/agents.action";
//#endregion

//#region components
import Table from "components/common/table";
//#endregion

//#region utils
import { isFranchisingMode } from "utils/common";
import { getUser } from 'utils/auth';
import { hasBetshopNonDirectTransactionAccess } from "utils/access";
import { hasPermission, hasOneOfPermissions } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { USER_STATE, USER_ROLE } from 'constants/user.constants';
import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';
import Paths from 'constants/path.constants';
import { getTableColumns } from "./columns";
//#endregion

//#region types
import betshopType from "types/betshop/betshop.type";
import sortingType from "types/common/sorting.type";
import userInfoType from 'types/profile/userInfo.type';
//#endregion

const {
    BETSHOP_GENERALINFO,
    BETSHOP_CURRENCIES,
    BETSHOP_WALLETS,
    BETSHOP,
    BETSHOP_LIMIT_SETTINGS,
    BETSHOP_ADDITIONAL_ACCESS,
    BETSHOP_IP_ACCESS_RULE,
} = PERMISSION_RESOURCE;

const { VIEW, MODIFY } = PERMISSION_ACTION;

const canTransferToBetShop = ({ betshop, userInfo, isAdmin }) => {
    if (!isAdmin) {
        return (
            hasBetshopNonDirectTransactionAccess(userInfo) ||
            betshop.agentId === userInfo.id
        )
    }

    return true;
}

/** Agent List Details Page Betshops List Component */
const BetshopsComponent = ({
    getAgentBetshops,
    betshops,
    isLoading,
    total,
    setAgentBetshopsSorting,
    sorting,
    globalProjectId,
    globalCompanyId,
    userInfo,
    agentId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const franchisingMode = isFranchisingMode();

    const { formatAmount } = useFormat();

    const { show: showTransfer} = useTransfer();

    const { userState, role } = getUser();
    const isAdmin = role === USER_ROLE.ADMIN;
    const isUserActive = userState === USER_STATE.ACTIVE;

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasBetshopWalletsModifyPermission = hasPermission({
        resource: BETSHOP_WALLETS,
        action: MODIFY
    })

    const canNavigateBetshopEditPage = hasOneOfPermissions([
        { resource: BETSHOP_GENERALINFO, action: VIEW },
        { resource: BETSHOP_CURRENCIES, action: VIEW },
        { resource: BETSHOP_LIMIT_SETTINGS, action: VIEW },
        { resource: BETSHOP_ADDITIONAL_ACCESS, action: VIEW },
        { resource: BETSHOP_IP_ACCESS_RULE, action: VIEW },
        { resource: isAdmin ? BETSHOP_WALLETS : BETSHOP, action: VIEW },
    ])

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.BETSHOPS_EDIT}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.code}`
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                formatAmount
            }
        })
    }, [formatAmount])
    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (isUserActive && (!isAdmin || hasBetshopWalletsModifyPermission)) {
        tableRowActions.push({
            title: t("backoffice.betshops.transfer"),
            icon: "icon-transfer",
            onClick: record => {
                const transferData = {
                    entityType: TRANSFER_ENTITY_TYPE.BETSHOP,
                    userId: record.id,
                    currencyCode: record.currencyCode
                }
                if(isAdmin && record.agentId !== globalProjectId && franchisingMode){
                    transferData.transactionType = WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT
                }

                showTransfer(transferData)
            },
            disabled: record => !canTransferToBetShop({
                betshop: record,
                isAdmin,
                userInfo,
            }),
        })
    }

    if (canNavigateBetshopEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick
        })
    }

    //#endregion

    return (
        <>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={betshops}
                loadFn={nextPage => getAgentBetshops(agentId, nextPage)}
                sorting={sorting}
                setSortingFn={setAgentBetshopsSorting}
                total={total}
                updateProps={[agentId]}
                actions={tableRowActions}
                disableFullView={true}
            />
        </>
    );
};

/** BetshopsComponent propTypes
 * PropTypes
*/
BetshopsComponent.propTypes = {
    /** Redux action to get agent betshops list */
    getAgentBetshops: PropTypes.func,
    /** Redux state property, represents the array of betshops  */
    betshops: PropTypes.arrayOf(betshopType),
    /** Redux state property, is true when loading betshops */
    isLoading: PropTypes.bool,
    /** Redux state property, betshops total count */
    total: PropTypes.number,
    /** Redux action to set agent betshops sorting details */
    setAgentBetshopsSorting: PropTypes.func,
    /** Redux state property, betshops sorting details */
    sorting: sortingType,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Agent id */
    agentId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getAgentBetshops: (agentId, nextPage) => {
        dispatch(getAgentBetshops(agentId, nextPage));
    },
    setAgentBetshopsSorting: sorting => {
        dispatch(setAgentBetshopsSorting(sorting));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.agents.betshops.isLoading,
        betshops: state.agents.betshops.betshops,
        total: state.agents.betshops.total,
        sorting: state.agents.betshops.sorting,
        globalProjectId: state.common.globalProjectId,
        globalCompanyId: state.common.globalCompanyId,
        userInfo: state.profile.userInfo
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BetshopsComponent);
