import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_NETWORK_OVERVIEW_ACTION_BEFORE,
    SET_NETWORK_OVERVIEW_ACTION_FINISH,
    SET_NETWORK_SUB_AGENTS,
    RESET_NETWORK_SUB_AGENTS,
    SET_NETWORK_LAYERS
} from "../../../../actionTypes";

export const setNetworkActionBefore = () => ({
    type: SET_NETWORK_OVERVIEW_ACTION_BEFORE,
});

export const setNetworkActionFinished = () => ({
    type: SET_NETWORK_OVERVIEW_ACTION_FINISH,
});

const setNetworkSubNodes = (value, id) => ({
    type: SET_NETWORK_SUB_AGENTS,
    payload: { value, id },
});

const setNetworkLayers = (networkLayers) => ({
    type: SET_NETWORK_LAYERS,
    payload: networkLayers,
})

export const getNetworkSubNodes = id => {
    return dispatch => {
        if(!id){
            dispatch(setNetworkActionBefore());
        }

        return axios({
            url: ApiUrls.GET_NETWORK_SUB_NODES,
            method: Methods.GET,
            params: { id, includeBetShops: true, includePlayers: true },
        })
            .then(({ data: { value } }) => {
                dispatch(setNetworkSubNodes(value, id));
                dispatch(setNetworkActionFinished());
            })
            .catch((ex) => {
                if(!id){
                    dispatch(setNetworkActionFinished());
                }
            });
    }
}

export const resetNetworkSubNodes = () => ({
    type: RESET_NETWORK_SUB_AGENTS,
});

export const getNeworkLayers = () => {
    return (dispatch) => {
        dispatch(setNetworkActionBefore());

        return axios({
            url: ApiUrls.GET_NETWORK_LAYERS,
            method: Methods.GET,
        })
            .then(({ data }) => {
                const networkLayers = data.value;

                dispatch(setNetworkLayers(networkLayers))
                dispatch(setNetworkActionFinished())
            })
            .catch((ex) => {
                dispatch(setNetworkActionFinished());
            });
    }
}