import { isFunction } from "utils/common";
import { hasPermission } from "utils/permissions";

import {
    ERROR_NOTIFICATION_CONTENT,
    NOTIFICATION_STATE_ENUM,
    NOTIFICATION_TYPES_ENUM,
    PREDEFINED_DATA_BY_TYPE
} from "constants/notification.constants"
import { PERMISSION_ACTION } from "constants/permissions.constants";

import sessionStorageUtils from "utils/sessionStorage";

const findPredefinedData = (type, action, objectType) => {
    if (!Boolean(type) || !Boolean(action)) {
        console.error("Notification Should Have Type And Action, Please Check");
        return;
    }

    const foundData = PREDEFINED_DATA_BY_TYPE[type]?.[action];

    if (Boolean(objectType)) {
        return foundData?.[objectType];
    }

    return foundData;
}

export const constructObjectFromJSON = (dataJSON) => {
    if (!Boolean(dataJSON)) {
        return {};
    }

    const parsedData = JSON.parse(dataJSON);

    const data = parsedData.reduce((acc, { item1: key, item2: value }) => {
        return {
            ...acc,
            [key.toLowerCase()]: value,
        }
    }, {})

    return data;
}

export const getNotificationContent = (notificationInfo = {}) => {
    const { type, data: dataJSON } = notificationInfo;

    const data = constructObjectFromJSON(dataJSON);

    //TODO: change after clarifying which massage should be displayed
    if ([NOTIFICATION_TYPES_ENUM.PAYMENT_REQUEST, NOTIFICATION_TYPES_ENUM.REQUEST_PROCESSED].includes(type)) {
        return {
            title: data.subject,
            description: data.message,
        }
    }

    const predefinedData = findPredefinedData(type, data.action, data.objecttype);

    if (!Boolean(predefinedData)) {
        return ERROR_NOTIFICATION_CONTENT;
    }

    return {
        title: predefinedData.titleMaker?.(data, predefinedData?.titleTranslationSource),
        description: predefinedData.descriptionMaker?.(
            data,
            predefinedData.descriptionTranslationSource
        )
    }
}

export const constructRedirectionURL = (path, queryString, hash) => {
    let urlStr = path;

    if (Boolean(queryString)) {
        urlStr += queryString;
    }

    if (Boolean(hash)) {
        urlStr += hash;
    }

    return urlStr;
}

export const defaultQueryStringGenerator = (data) => {
    let queryString = "";

    Object.entries(data).forEach(([key, value], index) => {
        if (index === 0) {
            queryString += `?${key}=${value}`;

            return;
        }

        queryString += `&${key}=${value}`
    })

    return queryString;
}

export const hasViewPermission = (resource) => {
    return hasPermission({
        resource: resource,
        action: PERMISSION_ACTION.VIEW
    })
}

export const getQueryString = (queryStringGenerator, queryData) => {
    if (!Boolean(queryData)) {
        return "";
    }

    if (!isFunction(queryStringGenerator)) {
        return ""
    }

    return queryStringGenerator(queryData);
}

export const hasAccessToCurrentProject = ({
    projectId,
    companyId,
    allCompanies,
}) => {
    const currentCompanyData = allCompanies.find(
        companyData => companyData.id === companyId
    );

    const hasCurrentProjectAccess = currentCompanyData?.projects.some(
        projectData => projectData.id === projectId
    );

    return hasCurrentProjectAccess;
}
