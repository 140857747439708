export const TICKET_GENERAL_DETAILS = {
    BETSHOP_NAME: 1,
    BETSHOP_ADDRESS: 2,
    CASHIER_USERNAME: 4,
    TICKET_ID: 8
}

export const TICKET_BET_DETAILS = {
    BET_AMOUNT: 1,
    BET_TYPE: 2,
    FACTOR: 4,
    POSSIBLE_WIN: 8,
    WIN_AMOUNT: 16,
    CALCULATION_DATE: 32,
    BET_STATUS: 64,
    PAYOUT_TIME: 128,
    PAYOUT_BY: 256
}

export const TICKET_PREVIEW_VALUES = {
    CURRENCY: "EUR",
    BETSHOP_NAME: "Name",
    BETSHOP_ADDRESS: "Address",
    TICKET_ID: 132141,
    CASHIER_USERNAME: "Cashier",
    BET_AMOUNT: "100",
    BET_TYPE: "Single",
    FACTOR: "1.55",
    POSSIBLE_WIN: "155",
    WIN_AMOUNT: "155",
    CALCULATION_DATE: "2020-12-12 12:00",
    BET_STATUS: "Win",
    PAYOUT_TIME: "2020-12-12 12:00",
    PAYOUT_BY: "Cashier",
    BARCODE_NUMBERS: "5 54 74 56 488 17 5 7"
}