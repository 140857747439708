import { authStore } from "NEW/src/core/stores/authStore";

import { AUTHORIZATION } from "NEW/src/core/data/constants/api";
import axiosInstance from "NEW/src/core/lib/axios";

export class TokenManager {
	static _instance = null;

	static get instance() {
		return TokenManager._instance || new TokenManager();
	}

	constructor() {
		if (TokenManager._instance) {
			return TokenManager._instance;
		}

		TokenManager._instance = this;

		this._pendingRequestsQueue = [];
		this.isTokenRefreshing = false;
	}

	updateToken() {
		this.isTokenRefreshing = true;
		try {
			authStore
				.refreshToken()
				.then(({ token }) => {
					this._pendingRequestsQueue.forEach((request) => {
						request.configs.headers[AUTHORIZATION] = `Bearer ${token}`;

						axiosInstance
							.request(request.configs)
							.then((response) => request.resolve(response))
							.catch((error) => request.reject(error));
					});

					this._pendingRequestsQueue = [];
				})
				.catch((error) => {
					authStore.logout();

					console.error(error);
				})
				.finally(() => {
					this.isTokenRefreshing = false;
				});
		} catch (error) {
			console.error(error);
		}
	}

	registerPendingRequest(configs) {
		const request = {
			configs,
		};

		request.promise = new Promise((resolve, reject) => {
			request.resolve = resolve;
			request.reject = reject;
		});

		this._pendingRequestsQueue.push(request);

		return request.promise;
	}
}
