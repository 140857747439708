import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import { isMobile } from "utils/common";

import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import breadcrumbsType from 'types/components/breadcrumbs.type';


/** Breadcrumbs Component */
const Breadcrumbs = ({
    items,
    className,
    onBack,
    extra
}) => {

    const navigate = useNavigate();

    const navigateWithConfirmationDependFromBoth = useUnsavedFormConfirmation({
        cb: navigate,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const navigateWithConfirmationDependFromSubTab = useUnsavedFormConfirmation({
        cb: navigate,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    return !isMobile() ? (
            <Breadcrumb
                className={"rt--breadcrumbs rt--flex-equal " + (className ? className : "")}
                items={[
                    {
                        title: <i className='icon-home rt--font-big'></i>
                    },
                    ...items.map((item, index) => ({
                        key: item.title,
                        title: item.title,
                        className: item.path ? "rt--cursor-pointer" : "",
                        onClick: () => {
                            return item.path && (
                                index === 0
                                    ? navigateWithConfirmationDependFromBoth(item.path, item.replace ? {replace: true} : undefined)
                                    : navigateWithConfirmationDependFromSubTab(item.path, item.replace ? {replace: true} : undefined)
                            );
                        }
                    }))
                ]}
            />
        ) : (
            <div className={'rt--breadcrumbs rt--flex rt--align-center rt--justify-between ' + (className ? className : "")}>
                <div className="rt--flex rt--align-center">
                    {
                        (items.filter(i => i.path).length > 0 || onBack ) && (
                            <i
                                className='icon-left rt--font-bigest rt--mr-4'
                                onClick={onBack ? onBack : () => items[items.length - 2].path && (
                                    items.length > 2
                                        ? navigateWithConfirmationDependFromSubTab(items[items.length - 2].path)
                                        : navigateWithConfirmationDependFromBoth(items[items.length - 2].path)
                                )}
                            />
                        )
                    }
                    <h4 className='rt--title rt--font-extra-bold rt--font-biger'>
                        {items[items.length - 1].title}
                    </h4>
                </div>

                { extra ? extra : <Fragment />}
            </div>
        )
}

/** Breadcrumbs propTypes
    * PropTypes
*/
Breadcrumbs.propTypes = breadcrumbsType;

export default Breadcrumbs;
