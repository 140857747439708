import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_REAL_TIME_SPORT_BETS,
    SET_REAL_TIME_SPORT_BETS_SORTING,
    SET_REAL_TIME_SPORT_BETS_FILTERS,
    SET_REAL_TIME_SPORT_BET_DETAILS,
    SET_REAL_TIME_CASINO_BETS,
    SET_REAL_TIME_CASINO_BETS_SORTING,
    SET_REAL_TIME_CASINO_BETS_FILTERS,
} from "store/actionTypes";
import { setBetHistoryActionBefore, setBetHistoryActionFinished } from "./betHistory.action";

import { isMobile } from "utils/common";

import dateService from "utils/date";

const setRealTimeSportBets = (bets, add) => ({
    type: SET_REAL_TIME_SPORT_BETS,
    payload: { bets, add },
});

export const setRealTimeSportBetsSorting = sorting => ({
    type: SET_REAL_TIME_SPORT_BETS_SORTING,
    payload: { sorting },
});

export const setRealTimeSportBetsFilters = filters => ({
    type: SET_REAL_TIME_SPORT_BETS_FILTERS,
    payload: { filters },
});


export const getRealTimeSportBets = (nextPage) => {
    return (dispatch, getState) => {
        const sportBets = getState().betHistory.realTimeBets.sportBets;

        const filters = { ...sportBets.filters };
        const sorting = { ...sportBets.sorting };
        const total = sportBets.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;

        let from = dateService.mergeDateAndTime(filters.day, filters.time[0]);
        let to = dateService.mergeDateAndTime(filters.day, filters.time[1]);

        from = dateService.toISOString(from);
        to = dateService.toISOString(to);

        delete filters.day;
        delete filters.time;

        let params = {
            ...sorting,
            ...filters,
            page: page,
            from,
            to
        };

        if (page > 1 && total <= (page - 1) * sorting.limit) {
            return Promise.resolve();
        }

        dispatch(setBetHistoryActionBefore());

        return axios({
            url: ApiUrls.GET_REAL_TIME_SPORT_BETS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                dispatch(setRealTimeSportBets(bets, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setRealTimeSportBetsSorting({ ...sorting, page: page }));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
            });
    }
}

const setRealTimeCasinoBets = (bets, add) => ({
    type: SET_REAL_TIME_CASINO_BETS,
    payload: { bets, add },
});

export const setRealTimeCasinoBetsSorting = sorting => ({
    type: SET_REAL_TIME_CASINO_BETS_SORTING,
    payload: { sorting },
});

export const setRealTimeCasinoBetsFilters = filters => ({
    type: SET_REAL_TIME_CASINO_BETS_FILTERS,
    payload: { filters },
});

export const getRealTimeCasinoBets = (nextPage) => {
    return (dispatch, getState) => {
        const casinoBets = getState().betHistory.realTimeBets.casinoBets;

        const filters = { ...casinoBets.filters };
        const sorting = { ...casinoBets.sorting };
        const total = casinoBets.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;

        let from = dateService.mergeDateAndTime(filters.day, filters.time[0]);
        let to = dateService.mergeDateAndTime(filters.day, filters.time[1]);

        from = dateService.toISOString(from);
        to = dateService.toISOString(to);

        delete filters.day;
        delete filters.time;

        let params = {
            ...sorting,
            ...filters,
            page: page,
            from,
            to
        };

        if (page > 1 && total <= (page - 1) * sorting.limit) {
            return Promise.resolve();
        }

        dispatch(setBetHistoryActionBefore());

        return axios({
            url: ApiUrls.GET_REAL_TIME_CASINO_BETS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                dispatch(setRealTimeCasinoBets(bets, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setRealTimeCasinoBetsSorting({ ...sorting, page: page }));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
            });
    }
}

const setRealTimeSportBetDetails = (details, id) => ({
    type: SET_REAL_TIME_SPORT_BET_DETAILS,
    payload: { details, id },
});

export const getRealTimeSportBetDetails = betslipId => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_REAL_TIME_SPORT_BET_DETAILS,
            method: Methods.GET,
            params: {
                betslipId
            },
        })
            .then(({ data: { value: details } }) => {
                dispatch(setRealTimeSportBetDetails(details, betslipId));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
            });
    }
}
