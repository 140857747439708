import PropTypes from 'prop-types';

import { USER_STATE } from "constants/user.constants";

import walletType from "../wallet/wallet.type";

export default PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    hierarchy: PropTypes.string,
    parentId: PropTypes.string,
    parentName: PropTypes.string,
    companyId: PropTypes.string,
    subAgentsCount: PropTypes.number,
    playersCount: PropTypes.number,
    betShopsCount: PropTypes.number,
    createdAt: PropTypes.string,
    lastLogin: PropTypes.string,
    label: PropTypes.string,
    securityLevel: PropTypes.number,
    wallets: PropTypes.arrayOf(walletType)
})