import {DATA_TYPES, LEADERBOARD_TYPES} from "pages/dashboard/constants";
import {isFranchisingMode} from "utils/common";

const getCorrespondingDataTypes = (type, hasLiteMode) => {
    const types = { ...DATA_TYPES };

    delete types.COMMISSIONS_LITE;

    if (!isFranchisingMode()) {
        delete types.REGISTERED_PLAYERS;
    }

    if (type === LEADERBOARD_TYPES.PLAYER) {
        delete types.COMMISSIONS;
    }

    if (hasLiteMode) {
        delete types.NGR;
        delete types.NET_DEPOSIT;

        if (type !== LEADERBOARD_TYPES.AGENT) {
            delete types.COMMISSIONS;
        }
    }

    return types;
}

export default getCorrespondingDataTypes;
