import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DatePicker from 'components/common/datePicker';
import TimePicker from "components/common/timepicker";

import useDate from 'hooks/useDate';

import { getPlayerBets, setPlayerBetsFilters } from "store/actions/dashboard/players/bets.action";
import { getGameProviders } from "store/actions/dashboard/common/common.action";

import { isMobile } from "utils/common";

import gameProviderType from "types/project/gameProvider.type";

/** Player Bets Page Filters Component */

const Filters = ({
    setPlayerBetsFilters,
    getPlayerBets,
    filters,
    gameProviders,
    getGameProviders,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    const { dateService } = useDate();

    return (
        <FiltersWrapper
            loadFn={() => getPlayerBets(searchParams.id)}
            updateProps={[globalProjectId]}
            setFiltersFn={setPlayerBetsFilters}
            filters={filters}
            searchFieldName="betId"
            onInit={() => {
                getGameProviders()
            }}
        >
            <Row gutter={[16, 0]}>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.commissionplans.providers")}
                        name="providerId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t("backoffice.common.all") },
                                    ...gameProviders
                                        .map(provider => (
                                            { value: provider.id, text: provider.name }
                                        ))
                                ]
                            }
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.providers')}`}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={12} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="day"
                    >
                        <DatePicker
                            className="rt--datepicker"
                            popupClassName='rt--datepicker-popup'
                            format={`${dateService.getFormat(false)}`}
                            disabledDate={d => !dateService.isInRange(d, dateService.daysAgo(4), dateService.endOfDay() )}
                            showToday={false}
                            showNow={false}
                            allowClear={false}
                            placeholder={t('backoffice.common.selectDate')}
                            suffixIcon={<i className='icon-date rt--font-bigest' />}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            inputReadOnly={isMobile()}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} >
                    <Form.Item
                        label=" "
                        name="time"
                        className='rt--form-item-without-label'
                    >
                        <TimePicker.RangePicker
                            format='HH:mm'
                            allowClear={false}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            className='rt--timepicker'
                            popupClassName='rt--timepicker-dropdown'
                            separator={<div className='rt--timepicker-separator'/>}
                            placement="bottomRight"
                            showNow={false}
                            inputReadOnly={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get player bets */
    getPlayerBets: PropTypes.func,
    /** Redux action to set player bets filters */
    setPlayerBetsFilters: PropTypes.func,
    /** Redux state property, players filters */
    filters: PropTypes.object,
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType),
    /** Redux action to get game providers */
    getGameProviders: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getPlayerBets: (id, nextPage) => {
            dispatch(getPlayerBets(id, nextPage))
        },

        setPlayerBetsFilters: sorting => {
            dispatch(setPlayerBetsFilters(sorting));
        },

        getGameProviders: () => {
            dispatch(getGameProviders());
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.players.edit.bets.filters,
        gameProviders: state.common.gameProviders,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
