import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import axios from "axios";

import { isMobile } from "utils/common";

import {
    SET_BETSHOP_PERFORMANCE_REPORT,
    SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_BETSHOP_PERFORMANCE_REPORT_FILTERS,
    SET_BETSHOP_PERFORMANCE_REPORT_SORTING,
    SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_BEFORE,
    SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_FINISH,
    SET_BETSHOP_PERFORMANCE_REPORT_TOTALS
} from "../../../actionTypes";

const setBetshopPerformanceReportActionBefore = () => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE,
});

const setBetshopPerformanceReportActionFinished = () => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH,
});

const setBetshopPerformanceReport = (data, add) => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT,
    payload: { report: data.reports, total: data.total, add },
});

export const setBetshopPerformanceReportFilters = (filters, keepPage) => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_FILTERS,
    payload: { filters, keepPage },
});

export const setBetshopPerformanceReportSorting = sorting => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_SORTING,
    payload: { sorting },
});

export const getBetshopPerformanceReport = nextPage => {
    return (dispatch, getState) => {
        const state = getState()

        const page = nextPage === "first" ? 1 : nextPage === "next" ? state.betshopPerformanceReport.sorting.page + 1 : state.betshopPerformanceReport.sorting.page;
        let data = {
            ...state.betshopPerformanceReport.sorting,
            ...state.betshopPerformanceReport.filters,
            page: page
        };

        if (page > 1 && state.betshopPerformanceReport.total <= (page - 1) * state.betshopPerformanceReport.sorting.limit) {
            return Promise.resolve();
        }

        if (nextPage === "next" && state.betshopPerformanceReport.total === 0) {
            return Promise.resolve();
        }

        dispatch(setBetshopPerformanceReportActionBefore());

        let currenciesStr = "";
        let currencies = state.betshopPerformanceReport.filters.currencies;
        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }

        delete data.currencies;

        return axios({
            url: `${ApiUrls.GET_BETSHOP_PERFORMANCE_REPORT}?${currenciesStr}`,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const reports = data.value?.item2;
                const total = data.value?.item1;

                dispatch(setBetshopPerformanceReport({ reports, total }, isMobile() && page !== 1));

                if (nextPage) {
                    dispatch(setBetshopPerformanceReportSorting({ ...getState().betshopPerformanceReport.sorting, page: page }));
                }

                dispatch(setBetshopPerformanceReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopPerformanceReportActionFinished());
            });
    }
}

/** Totals */

const setBetshopPerformanceReportTotalsBefore = () => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_BEFORE,
});

const setBetshopPerformanceReportTotalsFinished = () => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_FINISH,
});

const setBetshopPerformanceReportTotals = totals => ({
    type: SET_BETSHOP_PERFORMANCE_REPORT_TOTALS,
    payload: { totals },
});

export const getBetshopPerformanceReportTotals = () => {
    return (dispatch, getState) => {
        const state = getState();
        let data = {
            ...state.betshopPerformanceReport.filters
        };

        let currenciesStr = "";
        let currencies = state.betshopPerformanceReport.filters.currencies;
        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }

        delete data.currencies;

        dispatch(setBetshopPerformanceReportTotalsBefore());

        return axios({
            url: `${ApiUrls.GET_BETSHOP_PERFORMANCE_REPORT_TOTALS}?${currenciesStr}`,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const totals = data.value;
                dispatch(setBetshopPerformanceReportTotals(totals));
                dispatch(setBetshopPerformanceReportTotalsFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopPerformanceReportTotalsFinished());
            });
    }
}