import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Tabs as AntTabs } from 'antd';

import TabDashboardLayout from "components/layouts/tab";
import General from "./tabs/general";
import Commission from "./tabs/commission";
import TopUsers from "./tabs/topUsers";
import LastUpdated from "../components/lastUpdated";

import useUserCurrencies from "hooks/useUserCurrencies";
import useFirstRender from "hooks/useFirstRender";
import useDate from "hooks/useDate";
import useProjectType from "hooks/useProjectType";

import { getUser } from "utils/auth";
import { getHashValue, isMobile, updateLocationHash } from "utils/common";
import sessionStorage from "utils/sessionStorage";

import {
    FINANCIAL_DASHBOARD_FILTER_NAMES,
    FINANCIAL_TAB_NAMES,
    TIME_PERIOD
} from "../constants";
import { USER_ROLE } from "constants/user.constants";
import PATHS from "constants/path.constants";
import { FILTER_TYPE } from "constants/common.constants";

const selectGlobalProjectId = state => state.common.globalProjectId;

const getDefaultFilters = (currencyFilterInitialValue = "") => ({
    [FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD]: TIME_PERIOD.TODAY,
    [FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]: currencyFilterInitialValue,
    [FINANCIAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE]: FILTER_TYPE.NETWORK,
})

const getActiveTab = () => {
    const tabKey = getHashValue("tab");

    return tabKey ? tabKey : FINANCIAL_TAB_NAMES.GENERAL
}

const FinancialDashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { dateService } = useDate();

    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
    const [defaultFilters, setDefaultFilters] = useState(() => getDefaultFilters())

    const activeTabRef = useRef(getActiveTab())
    const isDataLoadedRef = useRef(false);

    const { hasStandartPayment, hasLiteMode } = useProjectType();
    const globalProjectId = useSelector(selectGlobalProjectId);

    const [userCurrencies] = useUserCurrencies();

    const { role } = getUser();

    const isAdmin = role === USER_ROLE.ADMIN;
    const showCommissionTab = !hasLiteMode || !isAdmin;

    useEffect(() => {
        isDataLoadedRef.current = false;
    }, [globalProjectId])

    /** Leave from page if globalPayment type is not standart */
    useEffect(() => {
        if (!hasStandartPayment) {
            navigate(PATHS.DEFAULT);
        }
    }, [hasStandartPayment])

    // Set filters initial value
    // We omit the first render to avoid an bug in case currencies exist in Redux
    useFirstRender({
        afterFirstRenderCB: () => {
            if (isDataLoadedRef.current) {
                return;
            }

            isDataLoadedRef.current = true;

            let currencyFilterInitialValue = "";

            if (role === USER_ROLE.ADMIN) {
                currencyFilterInitialValue = userCurrencies.find(currencyData => currencyData.isDefault).code;
            }

            if (role === USER_ROLE.AGENT) {
                const userSelectedCurrency = sessionStorage.get("currency");
                currencyFilterInitialValue = userSelectedCurrency ? userSelectedCurrency : userCurrencies.at(0).currency;
            }
            setDefaultFilters(getDefaultFilters(currencyFilterInitialValue))
        },
        dependencies: [userCurrencies]
    })

    const updateLastUpdatedDate = useCallback((newDate) => {
        setLastUpdatedDate((prevState) => {
            if (lastUpdatedDate === null || dateService.isAfter(newDate, lastUpdatedDate)) {
                return newDate;
            }

            return prevState;
        })
    }, [])

    const handleTabChange = (tab) => {
        updateLocationHash("tab=" + tab);
    }

    return (
        <TabDashboardLayout
            header={isMobile() ? { extra: <LastUpdated lastUpdatedDate={lastUpdatedDate} /> } : undefined}
            breadcrumbs={{
                items: [{
                    title: t('backoffice.menu.financial')
                }]
            }}
        >
            <AntTabs
                animated={false}
                destroyInactiveTabPane={true}
                defaultActiveKey={activeTabRef.current}
                onChange={handleTabChange}
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={!isMobile() ? <LastUpdated lastUpdatedDate={lastUpdatedDate} /> : undefined}
                items={[
                    {
                        key: FINANCIAL_TAB_NAMES.GENERAL,
                        label: (<span>{t("backoffice.dashboard.general")}</span>),
                        children: (
                            <General
                                userCurrencies={userCurrencies}
                                defaultFilters={defaultFilters}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        )
                    },
                    ...(showCommissionTab
                            ? [{
                                key: FINANCIAL_TAB_NAMES.COMMISSION,
                                label: (<span>{t("backoffice.dashboard.commission")}</span>),
                                children: (
                                    <Commission
                                        userCurrencies={userCurrencies}
                                        defaultFilters={defaultFilters}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                    />
                                )
                            }]
                            : []
                        ),
                    {
                        key: FINANCIAL_TAB_NAMES.TOP_USERS,
                        label: (<span>{t("backoffice.dashboard.topUsers")}</span>),
                        children: (
                            <TopUsers
                                userCurrencies={userCurrencies}
                                defaultFilters={defaultFilters}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        )
                    }
                ]}
            />
        </TabDashboardLayout>
    )
}

export default FinancialDashboard;
