import PropTypes from 'prop-types';

export default PropTypes.shape({
    sportsbookSettings: PropTypes.shape({
        enabled: PropTypes.bool,
        partnerId: PropTypes.number,
        secretKey: PropTypes.string
    }),
    virtualSportsSettings: PropTypes.shape({
        enabled: PropTypes.bool,
        externalId: PropTypes.string,
        secret: PropTypes.string
    }),
    goldenRaceSettings: PropTypes.shape({
        enabled: PropTypes.bool,
        siteId: PropTypes.string,
        privateKey: PropTypes.string,
        publicKey: PropTypes.string,
        accessKey: PropTypes.string
    }),
})