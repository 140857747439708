import React from "react";

import { Dropdown as AntDropdown } from "antd";
import PropTypes from "prop-types";

const DropdownDesktop = ({ className, children, popupOpened, menu, ...props }) => {
	return (
		<AntDropdown
			className={"rt--dropdown " + (className || "")}
			trigger={["click"]}
			open={popupOpened}
			menu={menu}
			{...props}
		>
			{children}
		</AntDropdown>
	);
};

DropdownDesktop.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	popupOpened: PropTypes.bool,
	menu: PropTypes.object,
};

export default DropdownDesktop;
