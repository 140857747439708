import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from "react-router-dom";

import { Form, Row, Col, Spin } from 'antd';

import NumericInput from 'components/common/numericInput';
import TabFormDashboardLayout from "components/layouts/tab/form";

import { getAgentLimitSettings, saveAgentLimitSettings } from "store/actions/dashboard/agentSystem/agents/limitSettings.action";
import { getAgentCurrencies } from "store/actions/dashboard/agentSystem/agents/currencies.action";

import useConfirmation from 'hooks/useConfirmation';
import useProjectType from 'hooks/useProjectType';

import limitSettingsType from "types/agentSystem/limitSettings.type";
import currencyType from "types/currency/currency.type";
import userInfoType from 'types/profile/userInfo.type';

import { isFranchisingMode, binaryToFlags } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { USER_TYPE } from "constants/user.constants";
import { PAYMENT_METHODS } from 'constants/project.constants';
import { AMOUNT_REGEX } from "constants/regex.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Agent Edit Page AgentSystem Tab Limits subtab Component */
const LimitSettingsComponent = ({
    getAgentLimitSettings,
    saveAgentLimitSettings,
    isSaving,
    isLoading,
    limitSettings,
    getAgentCurrencies,
    agentCurrencies,
    userInfo,
    onTabChange
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();
    const { search } = useLocation();

    const { hasRetail } = useProjectType();

    const [isFormTouched, setIsFormTouched] = useState(false);
    const [currency, setCurrency] = useState(null);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const { showConfirmationPopup } = useConfirmation({
        title: t("backoffice.common.confirmAction"),
        message: t("backoffice.common.withoutSavingMessage"),
        okText: t("backoffice.common.discard"),
        cancelText: t("backoffice.common.cancel")
    })

    const entityRole = Number((new URLSearchParams(search)).get("role"));
    const paymentSettings = binaryToFlags(Object.values(PAYMENT_METHODS), limitSettings.paymentType);
    const userCanModify = (
        hasPermission({ resource: PERMISSION_RESOURCE.AGENT_LIMITS, action: PERMISSION_ACTION.MODIFY }) &&
        searchParams.id !== userInfo.id
    );

    const walletTypeIsCreditLine = limitSettings.walletTypeIsCreditLine;

    const handleCurrencyChange = (value) => {
        if (!isFormTouched) {
            setCurrency(value);
            return;
        }

        showConfirmationPopup({
            onConfirm: () => {
                setCurrency(value);
                setIsFormTouched(false);
            }
        })
    }

    useEffect(() => {
        if (agentCurrencies[0] && agentCurrencies[0].currency) {
            const cur = agentCurrencies[0].currency.toLowerCase();
            handleCurrencyChange(cur)
        }
    }, [agentCurrencies])

    /** Set default values */
    useEffect(() => {
        const fields = {
            maxNegativeCreditLine: limitSettings.maxNegativeCreditLine,
        }

        if (entityRole === USER_TYPE.AGENT) {
            if (hasRetail) {
                fields.maxBetShopBalance = limitSettings.maxBetShopBalance;
                fields.maxBetShopLimit = limitSettings.maxBetShopLimit;
            }

            fields.maxPositiveCreditLine = limitSettings.maxPositiveCreditLine;

            if (paymentSettings.includes(PAYMENT_METHODS.BANK_TRANSFER)) {
                fields.maxBankDepositLimit = limitSettings.maxBankDepositLimit;
                fields.maxBankWithdrawalLimit = limitSettings.maxBankWithdrawalLimit;
            }

            if (paymentSettings.includes(PAYMENT_METHODS.EPOS)) {
                fields.maxEposDepositLimit = limitSettings.maxEposDepositLimit;
                fields.maxEposWithdrawalLimit = limitSettings.maxEposWithdrawalLimit;
            }

            if (!isFranchisingMode()) {
                fields.maxPositiveCreditLineSubTree = limitSettings.maxPositiveCreditLineSubTree;
                fields.maxNegativeCreditLineSubTree = limitSettings.maxNegativeCreditLineSubTree;
                fields.maxAgentBalance = limitSettings.maxAgentBalance;
                fields.maxAgentBalanceSubTree = limitSettings.maxAgentBalanceSubTree;
            }
        }

        setFieldsValue({
            ...fields
        })
    }, [limitSettings])

    /** Get agent limit settings */
    useEffect(() => {
        if (currency) {
            getAgentLimitSettings(searchParams.id, currency);
        }
    }, [currency])

    /** Load available currencies */
    useEffect(() => {
        getAgentCurrencies(searchParams.id);
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf LimitSettingsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveAgentLimitSettings({
                    id: searchParams.id,
                    currencyCode: currency,
                    ...data
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    /** Function to validate limit
       * @function
       * @param {number} value - value
       * @param {number} parentValue - parent value
       * @memberOf LimitSettingsComponent
   */
    const validateLimit = (value, parentValue) => {
        if (value !== null && value !== "" && parentValue !== null) {
            if (Number(value) > parentValue) {
                return Promise.reject(t('backoffice.validation.mustBeLess').replace("%X%", parentValue))
            } else {
                return Promise.resolve()
            }
        }
        return Promise.resolve()
    }

    /** Function to validate deposit/withdraw amount depend on max agent balance
       * @function
       * @param {number} deposittWithdrawalAmount - deposit/withdraw amount
       * @param {number} balance - max agent balance
       * @memberOf LimitSettingsComponent
   */
    const getDepositWithdrawalValidationAsync = ({ deposittWithdrawalAmount, balance, fieldName }) => {
        const deposittWithdrawalAmountNum = Number(deposittWithdrawalAmount);
        const balanceNum = Number(balance);

        if (balance === "" || balance === null) {
            return Promise.resolve();
        }

        if (deposittWithdrawalAmount === "" || deposittWithdrawalAmount === null || balanceNum < deposittWithdrawalAmountNum) {
            return Promise.reject(
                t('backoffice.validation.mustBeLess')
                    .replace("%X%", 
                        isFranchisingMode() ? t('backoffice.projects.maxBetshopOwnerBalance') : 
                        !walletTypeIsCreditLine ? t('backoffice.projects.maxAgentBalance') : 
                        fieldName === "maxEposDepositLimit" ? t('backoffice.projects.maxPositiveCreditLine') : t('backoffice.projects.maxNegativeCreditLine')
                    )
                );        
        }

        return Promise.resolve();
    }

    const onFormValuesChange = (changed, formValues) => {
        const formVlauesObj = {
            maxNegativeCreditLine: formValues.maxNegativeCreditLine,
        };
        const initialValuesObj = {
            maxNegativeCreditLine: limitSettings?.maxNegativeCreditLine ?? null,
        };

        if (entityRole === USER_TYPE.AGENT) {
            if (hasRetail) {
                formVlauesObj.maxBetShopBalance = formValues.maxBetShopBalance;
                formVlauesObj.maxBetShopLimit = formValues.maxBetShopLimit;
            }

            formVlauesObj.maxPositiveCreditLine = formValues.maxPositiveCreditLine;

            if (hasRetail) {
                initialValuesObj.maxBetShopBalance = limitSettings?.maxBetShopBalance ?? null;
                initialValuesObj.maxBetShopLimit = limitSettings?.maxBetShopLimit ?? null;
            }

            initialValuesObj.maxPositiveCreditLine = limitSettings?.maxPositiveCreditLine ?? null;

            if (paymentSettings.includes(PAYMENT_METHODS.BANK_TRANSFER)) {
                formVlauesObj.maxBankDepositLimit = formValues.maxBankDepositLimit;
                formVlauesObj.maxBankWithdrawalLimit = formValues.maxBankWithdrawalLimit;

                initialValuesObj.maxBankDepositLimit = limitSettings?.maxBankDepositLimit ?? null;
                initialValuesObj.maxBankWithdrawalLimit = limitSettings?.maxBankWithdrawalLimit ?? null;
            }

            if (paymentSettings.includes(PAYMENT_METHODS.EPOS)) {
                formVlauesObj.maxEposDepositLimit = formValues.maxEposDepositLimit;
                formVlauesObj.maxEposWithdrawalLimit = formValues.maxEposWithdrawalLimit;

                initialValuesObj.maxEposDepositLimit = limitSettings?.maxEposDepositLimit ?? null;
                initialValuesObj.maxEposWithdrawalLimit = limitSettings?.maxEposWithdrawalLimit ?? null;
            }

            if (!isFranchisingMode()) {
                formVlauesObj.maxPositiveCreditLineSubTree = formValues.maxPositiveCreditLineSubTree;
                formVlauesObj.maxNegativeCreditLineSubTree = formValues.maxNegativeCreditLineSubTree;
                formVlauesObj.maxAgentBalance = formValues.maxAgentBalance;
                formVlauesObj.maxAgentBalanceSubTree = formValues.maxAgentBalanceSubTree;

                initialValuesObj.maxPositiveCreditLineSubTree = limitSettings?.maxPositiveCreditLineSubTree ?? null;
                initialValuesObj.maxNegativeCreditLineSubTree = limitSettings?.maxNegativeCreditLineSubTree ?? null;
                initialValuesObj.maxAgentBalance = limitSettings?.maxAgentBalance ?? null;
                initialValuesObj.maxAgentBalanceSubTree = limitSettings?.maxAgentBalanceSubTree ?? null;
            }
        }

        setIsFormTouched(isFormChanged(formVlauesObj, initialValuesObj));
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: userCanModify,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            dropdown={
                {
                    value: currency,
                    onChange: handleCurrencyChange,
                    items: agentCurrencies.map(item => ({
                        key: item.currency.toLowerCase(),
                        value: item.currency.toUpperCase()
                    })),
                    small: true
                }
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{}}
                    onValuesChange={onFormValuesChange}
                >


                    <Row className='rt--limits'>
                        <Col xs={24} sm={12} xl={6}>
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                {
                                    isFranchisingMode() ? t("backoffice.agents.betshopOwners") : t("backoffice.agents.agents")
                                }
                            </h4>
                            {
                                entityRole === USER_TYPE.AGENT && (
                                    <Form.Item
                                        label={t('backoffice.agents.maxPositiveCreditLine')}
                                        name="maxPositiveCreditLine"
                                        dependencies={walletTypeIsCreditLine ? ["maxEposDepositLimit"] : []}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    const fields = walletTypeIsCreditLine ? ["maxEposDepositLimit"] : []
                                                    for (const fieldName of fields) {
                                                        const limitNum = Number(getFieldValue(fieldName));
                                                        const valueNum = Number(value);

                                                        if (value === "" || value === null) {
                                                            continue;
                                                        }

                                                        if (getFieldValue(fieldName) === "" || getFieldValue(fieldName) === null || limitNum > valueNum) {
                                                            return Promise.reject(t('backoffice.validation.creditMustBeMoreOrEqualToMaxDeposit'))
                                                        }

                                                    }

                                                    return validateLimit(value, limitSettings.parentMaxPositiveCreditLine);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxPositiveCreditLine')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxPositiveCreditLine')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                )
                            }
                            {
                                (
                                    entityRole === USER_TYPE.AGENT &&
                                    !isFranchisingMode()
                                ) && (
                                    <Form.Item
                                        label={t('backoffice.agents.maxPositiveCreditLineSubTree')}
                                        name="maxPositiveCreditLineSubTree"
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxPositiveCreditLineSubTree);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxPositiveCreditLineSubTree')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxPositiveCreditLineSubTree')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                )
                            }

                            <Form.Item
                                label={t('backoffice.agents.maxNegativeCreditLine')}
                                name="maxNegativeCreditLine"
                                dependencies={walletTypeIsCreditLine ? ["maxEposWithdrawalLimit"] : []}
                                rules={[
                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            const fields = walletTypeIsCreditLine ? ["maxEposWithdrawalLimit"] : []
                                            for (const fieldName of fields) {
                                                const limitNum = Number(getFieldValue(fieldName));
                                                const valueNum = Number(value);

                                                if (value === "" || value === null) {
                                                    continue;
                                                }

                                                if (getFieldValue(fieldName) === "" || getFieldValue(fieldName) === null || limitNum > valueNum) {
                                                    return Promise.reject(t('backoffice.validation.creditMustBeMoreOrEqualToMaxWithdrawal'))
                                                }

                                            }

                                            return validateLimit(value, limitSettings.parentMaxNegativeCreditLine);
                                        }
                                    })
                                ]}
                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxNegativeCreditLine')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxNegativeCreditLine')}`}
                                    disabled={!userCanModify}
                                />
                            </Form.Item>
                            {entityRole === USER_TYPE.AGENT && (
                                <>
                                    {
                                        !isFranchisingMode() && (
                                            <Form.Item
                                                label={t('backoffice.agents.maxNegativeCreditLineSubTree')}
                                                name="maxNegativeCreditLineSubTree"
                                                rules={[
                                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            return validateLimit(value, limitSettings.parentMaxNegativeCreditLineSubTree);
                                                        }
                                                    })
                                                ]}
                                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.jobs.cronExpression')}`}
                                            >
                                                <NumericInput
                                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxNegativeCreditLineSubTree')}`}
                                                    disabled={!userCanModify}
                                                />
                                            </Form.Item>
                                        )
                                    }

                                    {
                                        !isFranchisingMode() && (
                                            <Form.Item
                                                label={isFranchisingMode() ? t('backoffice.agents.maxBetshopOwnerBalance') : t('backoffice.agents.maxAgentBalance')}
                                                name="maxAgentBalance"
                                                dependencies={
                                                    ["maxBankDepositLimit", "maxBankWithdrawalLimit"].concat(!walletTypeIsCreditLine ? ["maxEposDepositLimit", "maxEposWithdrawalLimit"] : [])
                                                }
                                                rules={[
                                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            const fields = ["maxBankDepositLimit", "maxBankWithdrawalLimit"].concat(!walletTypeIsCreditLine ? ["maxEposDepositLimit", "maxEposWithdrawalLimit"] : [])

                                                            for (const fieldName of fields) {
                                                                const limitNum = Number(getFieldValue(fieldName));
                                                                const valueNum = Number(value)

                                                                if (value === "" || value === null) {
                                                                    continue;
                                                                }

                                                                if (getFieldValue(fieldName) === "" || getFieldValue(fieldName) === null || limitNum > valueNum) {
                                                                    return Promise.reject(t('backoffice.validation.balanceMustBeMoreOrEqual'))
                                                                }

                                                            }

                                                            return validateLimit(value, limitSettings.parentMaxAgentBalance);
                                                        }
                                                    })
                                                ]}
                                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                                data-placeholder={`${t('backoffice.common.enter')} ${isFranchisingMode() ? t('backoffice.agents.maxBetshopOwnerBalance') : t('backoffice.agents.maxAgentBalance')}`}
                                            >
                                                <NumericInput
                                                    placeholder={`${t('backoffice.common.enter')} ${isFranchisingMode() ? t('backoffice.agents.maxBetshopOwnerBalance') : t('backoffice.agents.maxAgentBalance')}`}
                                                    disabled={!userCanModify}
                                                />
                                            </Form.Item>
                                        )
                                    }


                                    {
                                        !isFranchisingMode() && (
                                            <Form.Item
                                                label={t('backoffice.agents.maxAgentBalanceSubTree')}
                                                name="maxAgentBalanceSubTree"
                                                rules={[
                                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            return validateLimit(value, limitSettings.parentMaxAgentBalanceSubTree);
                                                        }
                                                    })
                                                ]}
                                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxAgentBalanceSubTree')}`}
                                            >
                                                <NumericInput
                                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxAgentBalanceSubTree')}`}
                                                    disabled={!userCanModify}
                                                />
                                            </Form.Item>
                                        )
                                    }

                                </>
                            )}
                        </Col>

                        {
                            (
                                entityRole === USER_TYPE.AGENT && hasRetail
                            ) && (
                                <Col xs={24} sm={12} xl={6}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.agents.betshops")
                                        }
                                    </h4>

                                    <Form.Item
                                        label={t('backoffice.agents.maxBetShopBalance')}
                                        name="maxBetShopBalance"
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxBetShopBalance);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxBetShopBalance')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxBetShopBalance')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.agents.maxBetShopLimit')}
                                        name="maxBetShopLimit"
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxBetShopLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxBetShopLimit')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxBetShopLimit')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                </Col>
                            )}

                        {
                            (paymentSettings.includes(PAYMENT_METHODS.BANK_TRANSFER) && entityRole === USER_TYPE.AGENT) && (
                                <Col xs={24} sm={12} xl={6}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.agents.bankTransfer")
                                        }
                                    </h4>

                                    <Form.Item
                                        label={t('backoffice.agents.maxDepositAmount')}
                                        name="maxBankDepositLimit"
                                        dependencies={["maxAgentBalance"]}
                                        validateFirst={true}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return getDepositWithdrawalValidationAsync({
                                                        deposittWithdrawalAmount: value,
                                                        balance: getFieldValue("maxAgentBalance"),
                                                        fieldName: "maxBankDepositLimit"
                                                    });
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxBankDepositLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxDepositAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxDepositAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.agents.maxWithdrawalAmount')}
                                        name="maxBankWithdrawalLimit"
                                        dependencies={["maxAgentBalance"]}
                                        validateFirst={true}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return getDepositWithdrawalValidationAsync({
                                                        deposittWithdrawalAmount: value,
                                                        balance: getFieldValue("maxAgentBalance"),
                                                        fieldName: "maxBankWithdrawalLimit"
                                                    });
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxBankWithdrawalLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxWithdrawalAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxWithdrawalAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }


                        {
                            (paymentSettings.includes(PAYMENT_METHODS.EPOS) && entityRole === USER_TYPE.AGENT) && (
                                <Col xs={24} sm={12} xl={6}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.agents.epos")
                                        }
                                    </h4>

                                    <Form.Item
                                        label={t('backoffice.agents.maxDepositAmount')}
                                        name="maxEposDepositLimit"
                                        dependencies={[walletTypeIsCreditLine ? "maxPositiveCreditLine" : "maxAgentBalance"]}
                                        validateFirst={true}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return getDepositWithdrawalValidationAsync({
                                                        deposittWithdrawalAmount: value,
                                                        balance: getFieldValue(walletTypeIsCreditLine? "maxPositiveCreditLine" : "maxAgentBalance"),
                                                        fieldName: "maxEposDepositLimit"
                                                    });
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxEposDepositLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxDepositAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxDepositAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.agents.maxWithdrawalAmount')}
                                        name="maxEposWithdrawalLimit"
                                        dependencies={[walletTypeIsCreditLine ? "maxNegativeCreditLine" : "maxAgentBalance"]}
                                        validateFirst={true}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return getDepositWithdrawalValidationAsync({
                                                        deposittWithdrawalAmount: value,
                                                        balance: getFieldValue(walletTypeIsCreditLine? "maxNegativeCreditLine" : "maxAgentBalance"),
                                                        fieldName: "maxEposWithdrawalLimit"
                                                    });
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxEposWithdrawalLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxWithdrawalAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.maxWithdrawalAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }

                    </Row>

                </Form>

            </Spin>
        </TabFormDashboardLayout>

    )
}

/** LimitSettingsComponent propTypes
    * PropTypes
*/
LimitSettingsComponent.propTypes = {
    /** Redux state property, is true when limit settings is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when limit settings is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the limit settings of current editing agent  */
    limitSettings: limitSettingsType,
    /** Redux action to save agent limit settings */
    saveAgentLimitSettings: PropTypes.func,
    /** Redux action to get agent limit settings */
    getAgentLimitSettings: PropTypes.func,
    /** Redux action to get agent currencies */
    getAgentCurrencies: PropTypes.func,
    /** Redux state, represents the currencies of current editing agent  */
    agentCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        saveAgentLimitSettings: data => {
            dispatch(saveAgentLimitSettings(data));
        },

        getAgentLimitSettings: (id, currencyCode) => {
            dispatch(getAgentLimitSettings(id, currencyCode))
        },

        getAgentCurrencies: id => {
            dispatch(getAgentCurrencies(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        limitSettings: state.agents.edit.limitSettings,
        isSaving: state.agents.isSaving,
        isLoading: state.agents.isLoading,
        agentCurrencies: state.agents.edit.currencies.currencies,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LimitSettingsComponent)