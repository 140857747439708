import React from 'react';
import i18n from "translations/config";

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';

export const tableColumns = [
    {
        title: "backoffice.companies.name",
        dataIndex: "name",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
        render: (value, record) => (record.isTesting ? `${value} (Test)` : value),
    },
    {
        title: "backoffice.companies.id",
        dataIndex: "longId",
        copy: true,
        alwaysVisible: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.companies.country",
        dataIndex: "countryCode",
        mobileLevel: 3,
        render: value => value ? i18n.t(`backoffice.countries.${value}`) : "",
    },
    {
        title: "backoffice.companies.status",
        dataIndex: "state",
        sorter: true,
        mobileLevel: 4,
        render: state => <Status status={state} type={STATUS_TYPES.COMPANY} />,
    },
    {
        title: "backoffice.companies.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 5
    },
    {
        title: "backoffice.companies.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 6
    },
];