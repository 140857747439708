import React from "react";
import { Navigate } from "react-router-dom";

import {isAuthenticatedSelector, useAuthSelector} from "NEW/src/core/stores/authStore";

import { ROUTES } from "NEW/src/core/data/constants/routes";

/**
 * HOC which navigate to the default path if the user is logged in..
 */
const withAnonymousHoc = (WrappedComponent, path = ROUTES.ROOT) => {
	return (props) => {
		const isAuthenticated = useAuthSelector(isAuthenticatedSelector);

		if (isAuthenticated) {
			return <Navigate to={path} />;
		}

		return <WrappedComponent {...props} />;
	};
};

export default withAnonymousHoc;
