import React from "react";

import CashierPerformanceReport from "pages/reports/betshopPerformanceReport/cashierPerformanceReport.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const CashierPerformanceReportRoute = () => {
    return <CashierPerformanceReport />;
};

export default withPermission(
    withAuth(CashierPerformanceReportRoute),
    {
        resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
