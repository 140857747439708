import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

import {
    deactivateProjectBank,
    activateProjectBank,
    deleteProjectBank,
} from "store/actions/dashboard/projects/paymentsConfig.action";

import EntityActions from "components/common/entityActions";
import Confirmation from "components/common/confirmation";

import { BANK_STATUS } from 'constants/project.constants';

const CONFIRMATION_POPUP_DEFAULT_DATA = {
    isVisible: false,
    title: "",
    message: "",
    onCancel: null,
    onOk: null,
}

/** Bank Edit Actions Button Component */
const BankEditActionsComponent = ({
    deactivateProjectBank,
    activateProjectBank,
    deleteProjectBank,
    info,
}) => {
    const { t } = useTranslation();
    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const currentBankId = (new URLSearchParams(search)).get("bankId");
    const currentBankName = (new URLSearchParams(search)).get("bankName");

    /** Flag to show/hide bank action popup */
    const [confirmationPopupData, setConfirmationPopupData] = useState({
         ...CONFIRMATION_POPUP_DEFAULT_DATA
    });

    const hideConfirmationPopUp = () => {
        setConfirmationPopupData({ ...CONFIRMATION_POPUP_DEFAULT_DATA })
    }

    const showConfirmationPopup = (popupData) => {
        setConfirmationPopupData({
            isVisible: true,
            onCancel: hideConfirmationPopUp,
            ...popupData
        })
    }

    /** Actions list */
    const actions = [
        /** Activate/Deactivate and Delete Bank */
        info.state === BANK_STATUS.ACTIVE
        ? {
            icon: "icon-reparent",
            key: "deactivate",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.deactivate'),
            onClick: () => showConfirmationPopup({
                title: t('backoffice.payments.deactivateBank'),
                message: t('backoffice.payments.deactivateBankMessage'),
                onOk: () => {
                    deactivateProjectBank(currentBankId)
                },
            }),
            disabled: false
        } : {
            icon: "icon-reparent",
            key: "activate",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.activate'),
            onClick: () => showConfirmationPopup({
                title: t('backoffice.payments.activateBank'),
                message: t('backoffice.payments.activateBankMessage'),
                onOk: () => {
                    activateProjectBank(currentBankId)
                },
            }),
            disabled: false
        },
        {
            icon: "icon-trash",
            key: "delete",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.delete'),
            onClick: () => showConfirmationPopup({
                title: t('backoffice.payments.deleteBank'),
                message: t('backoffice.payments.deleteBankMessage'),
                onOk: () => {
                    deleteProjectBank(
                        currentBankId,
                        info.currencies,
                        () => {
                            navigate(
                                (pathname + search + hash)
                                    .replace("&bankId=" + encodeURIComponent(currentBankId), "")
                                    .replace("&bankName=" + encodeURIComponent(currentBankName), ""),
                                { replace: true },
                            )
                        },
                    )
                },

            }),
            disabled: false
        },
    ]

    return (
        <Fragment>
            <EntityActions
                actions={actions}
            />

            <Confirmation
                {...confirmationPopupData}
            />
        </Fragment>

    )
}

/** BankEditActionsComponent propTypes
    * PropTypes
*/
BankEditActionsComponent.propTypes = {
    /** Redux action to deactivate payment bank */
    deactivateProjectBank: PropTypes.func,
    /** Redux action to activate payment bank */
    activateProjectBank: PropTypes.func,
    /** Redux action to delete payment bank */
    deleteProjectBank: PropTypes.func,
    /** Redux state property, current bank general info */
    info: PropTypes.object,
}

const mapDispatchToProps = dispatch => (
    {
        deactivateProjectBank: (id) => {
            dispatch(deactivateProjectBank(id));
        },
        activateProjectBank: (id) => {
            dispatch(activateProjectBank(id));
        },
        deleteProjectBank: (id, currencies, onSuccess) => {
            dispatch(deleteProjectBank(id, currencies, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        info: state.projects.edit.payment.banks.edit.info
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BankEditActionsComponent)
