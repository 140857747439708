import { getUrlVars } from "./common";

import LocalStorageUtils from "./localStorage";

export default {

	defaultLang: 'en',

	getSelectedLanguageFromStorage: function(){

		const selectedLang = LocalStorageUtils.get('locale');

		if (selectedLang) {
			return selectedLang;
		}

		return this.defaultLang;
	},

	setSelectedLanguageToStorage: function(langCode, languages){

		if (languages[langCode]) {
			LocalStorageUtils.set("locale", langCode);
		}
	},


	getSelectedLanguage: function(){
		return getUrlVars()["language"] || this.getSelectedLanguageFromStorage();
	}

}
