export const getLastNodeId = (node) => {
    if (!node) {
        return '';
    }

    if (!node.nodes) {
        return node.id;
    }

    return getLastNodeId(node.nodes[0]);
}
