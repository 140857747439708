import React from "react";

import DashboardComponent from "pages/dashboard/dashboard.component";

import withAuth from "hocs/withAuth";

import withPermission from "hocs/withPermission";
import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const DashboardChartsRoute = () => {
    return <DashboardComponent />;
};

export default withPermission(
    withAuth(DashboardChartsRoute),
    { resource: PERMISSION_RESOURCE.DASHBOARD, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
