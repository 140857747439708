import React from 'react';

const CardChooser = ({items, onSelect}) => {
    const handleSelect = (selectedValue) => {
        onSelect?.(selectedValue);
    }

    return (
        <div className='rt--chooser rt--flex rt--align-center rt--justify-center'>
            {items.map(item => (
                <div
                    key={item.value}
                    className='rt--chooser-item rt--chooser-item-small rt--flex rt--flex-col rt--align-center rt--justify-center rt--mr-16 rt--pl-16 rt--pr-16'
                    onClick={() => handleSelect(item.value)}
                >
                    {item.icon}
                    <b className='rt--title rt--pt-16 rt--font-normal rt--font-bold'>
                        {item.title}
                    </b>
                </div>
            ))}
        </div>
    );
};

export default CardChooser;
