export const AUTOSUGGESTION_TYPE = {
    BETSHOP: 1,
    CASHIER: 2,
    BETSHOP_MANAGER: 3,
    PROJECT: 4,
    COMPANY: 5,
    USER: 6,
    ACCESS_MANAGER: 7,
    AGENT: 8,
    AGENT_PLAYER: 9,
    PLAYER: 10,
    PERMISSION_GROUP: 11,
    CONTROLLER: 12,
    BANK: 13,
    COMMISSION_PLAN: 14,
    USER_AVAILABLE_PERMISSION_GROUP: 15,
    PLAYERS_TOP: 16
}