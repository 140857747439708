import { useCallback, useEffect, useRef, useState } from 'react';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import * as API from "./api";

const AUTOSUGGESTION_MAPPING = {
    [AUTOSUGGESTION_TYPE.BETSHOP] : "getBetshops",
    [AUTOSUGGESTION_TYPE.CASHIER] : "getCashiers",
    [AUTOSUGGESTION_TYPE.BETSHOP_MANAGER] : "getBetshopManagers",
    [AUTOSUGGESTION_TYPE.PROJECT] : "getProjects",
    [AUTOSUGGESTION_TYPE.COMPANY] : "getCompanies",
    [AUTOSUGGESTION_TYPE.USER] : "getUsers",
    [AUTOSUGGESTION_TYPE.ACCESS_MANAGER] : "getAccessManagers",
    [AUTOSUGGESTION_TYPE.AGENT] : "getAgents",
    [AUTOSUGGESTION_TYPE.AGENT_PLAYER] : "getAgentPlayers",
    [AUTOSUGGESTION_TYPE.PLAYER] : "getPlayers",
    [AUTOSUGGESTION_TYPE.PERMISSION_GROUP] : "getPermissionGroups",
    [AUTOSUGGESTION_TYPE.CONTROLLER] : "getControllers",
    [AUTOSUGGESTION_TYPE.BANK] : "getBanks",
    [AUTOSUGGESTION_TYPE.COMMISSION_PLAN] : "getCommissionPlans",
    [AUTOSUGGESTION_TYPE.USER_AVAILABLE_PERMISSION_GROUP] : "getPermissionGroupAvailableUsers",
    [AUTOSUGGESTION_TYPE.PLAYERS_TOP] : "getTopPlayers"
}

const useAutosuggestion = ({ 
    type, 
    dependencies=[], 
    actionParams, 
    disable = false, 
    autoGet = true 
} ) => {

    const autosuggestionApi = AUTOSUGGESTION_MAPPING[type];

    const defaultValue = useRef([]);

    const [loading, setLoading] = useState(false)

    const [autoSuggestionData, setAutoSuggestionData] = useState([]);

    //#endregion

    const fn = useCallback (additionalParams => {
        if(autosuggestionApi && !disable){
            let params = actionParams;
            if(additionalParams){
                params = {
                    ...actionParams,
                    ...additionalParams
                }
            }
            setLoading(true);
            API[autosuggestionApi](params)
                .then(data => setAutoSuggestionData(data))
                .finally(() => setLoading(false));
        }
    }, [disable, actionParams])

    const resetFn = useCallback(() => {
        setAutoSuggestionData([])
    }, [])

    useEffect(() => {
        if(autoGet){
            fn() 
        }
    }, dependencies);


    if(!autosuggestionApi || disable){
        return [
            defaultValue.current
        ];
    }


    return (
        [
            autoSuggestionData,
            fn,
            resetFn,
            loading
        ]
    )
}

export default useAutosuggestion;