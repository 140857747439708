import i18n from "../../../../translations/config";
import Status from "../../../../components/common/status";
import {STATUS_TYPES} from "../../../../constants/status.constants";
import React from "react";
import {ONLINE_BET_TYPE} from "../../../../constants/bet.constants";

const BET_TYPE_NAMES = {
    [ONLINE_BET_TYPE.BET]: "bet",
    [ONLINE_BET_TYPE.BONUS]: "bonus",
};

export const getTableColumns = (additionalProps) => {
    const {
        hasLiteMode,
        formatAmount,
    } = additionalProps;

    return [
        {
            mobileLevel: 1,title: "backoffice.bets.betId",
            dataIndex: "id",
            copy: true,
            alwaysVisible: true,
        },
        {
            title: "backoffice.bets.userName",
            dataIndex: "playerUserName",
            alwaysVisible: true,
            mobileLevel: 2,
            render: value => value || "-",
        },
        {
            title: "backoffice.bets.id",
            dataIndex: "playerId",
            copy: true,
        },
        {
            title: "backoffice.bets.status",
            dataIndex: "state",
            mobileLevel: 4,
            render: (status) => <Status status={status} type={STATUS_TYPES.BET} />,
        },
        {
            title: "backoffice.bets.date",
            dataIndex: "betDate",
            isDateTime: true,
        },
        {
            title: "backoffice.bets.provider",
            dataIndex: "provider",
            mobileLevel: 3,
        },
        {
            title: "backoffice.bets.betType",
            dataIndex: "type",
            render: (betType) =>
                Boolean(BET_TYPE_NAMES[betType])
                    ? i18n.t(`backoffice.bets.${BET_TYPE_NAMES[betType]}`)
                    : "-",
        },
        {
            title: "backoffice.bets.game",
            dataIndex: "game",
        },
        {
            title: "backoffice.bets.lastUpdateDate",
            dataIndex: "lastUpdateDate",
            isDateTime: true
        },
        {
            title: "backoffice.bets.currency",
            dataIndex: "currencyCode",
        },
        {
            title: "backoffice.bets.amount",
            dataIndex: "amount",
            mobileLevel: 5,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
        },
        {
            title: "backoffice.bets.win",
            dataIndex: "winAmount",
            mobileLevel: 6,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
        },
        {
            title: "backoffice.bets.balanceBefore",
            dataIndex: "balanceBefore",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
        },
        {
            title: "backoffice.bets.balanceAfter",
            dataIndex: "balanceAfter",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
        },
    ];
}
