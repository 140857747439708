export const STATUS_TYPES = {
    BANK: "bank",
    BET: "bet",
    COMPANY: "company",
    JOB: "job",
    PLAYER: "player",
    ONLINE_OFFLINE: "onlineOffline",
    USER: "user",
    COMMISSION: "commission",
    DBCONNECTION: "dbConnection"
}

export const STATUS_TOOLTIP_PLACEMENT = {
    WHOLE_BLOCK: "wholeBlock",
    INDICATION: "indication",
    ICON: "icon"
}

export const STATUS_SIZE = {
    SMALL: "small"
}