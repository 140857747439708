import React from 'react';

import i18n from "translations/config";

export const tableColumns = [
    {
        title: "backoffice.languages.name",
        dataIndex: "name",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
        render: (_, record) => (
            <span className="rt--font-normal">
                {`${i18n.t(`backoffice.languages.${record.code.toLowerCase()}`)}`}
            </span>
        ),
    },
    {
        title: "backoffice.languages.code",
        dataIndex: "code",
        sorter: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.languages.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 2,
    }
];