import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

import { getProjectCurrencies } from "store/actions/dashboard/projects/currencies.action";
import {
    getProjectBankGeneralInfo,
    saveBankTranslation,
    saveProjectBankGeneralInfo,
} from "store/actions/dashboard/projects/paymentsConfig.action";

import {Col, Form, message, Row, Spin} from 'antd';
import BankEditActionsComponent from "./bank-edit-actions.component"
import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Select from "components/common/select";
import Input from 'components/common/input';
import NumericInput from 'components/common/numericInput';
import Table from "components/common/table";

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isFormChanged } from "utils/form";

import { NAME_REGEX } from 'constants/regex.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import {IMAGE_TYPE, UNSAVED_FORM_PAGE_TYPE} from 'constants/common.constants';
import { getTableColumns } from './columns';

import currencyType from "types/currency/currency.type";
import ImageUploader from "components/common/imageUploader";
import ApiUrls from "constants/api.constants";

const TRANSLATION_TABLE_ROW_UNIQUE_KEY = "languageCode";

const groupTranslations = (info) => {
    let translations = [];
    let infoTranslations = info.translations || [];

    if (infoTranslations.length === 0) {
        return translations;
    }

    const nameKey = infoTranslations.find(k => k.key.startsWith("name_"));

    if (!nameKey) {
        return translations;
    }

    const nameKeyTranslations = nameKey.translations || [];

    const languages = nameKeyTranslations.map(t => t.languageCode);

    languages.forEach(lang => {
        translations.push({
            nameKey: nameKey.key,
            languageCode: lang,
            name: nameKeyTranslations.find(t => t.languageCode === lang)?.text ?? "",
        })
    })
    return translations;
}

const GeneralInfoComponent = ({
    isAvailableLoading,
    getProjectCurrencies,
    projectCurrencies,
    getProjectBankGeneralInfo,
    saveProjectBankGeneralInfo,
    saveBankTranslation,
    info,
    isLoading,
    isSaving,
    onTabChange,
}) => {
    const { t } = useTranslation();
    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const navigateWithConfirmation = useUnsavedFormConfirmation({
        cb: navigate,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const currentBankId = (new URLSearchParams(search)).get("bankId");
    const currentBankName = (new URLSearchParams(search)).get("bankName");

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const userCanModify = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_PAYMENT,
        action: PERMISSION_ACTION.MODIFY
    });

    const handleForm = () => {
        validateFields()
            .then((fieldValuesObj) => {
                saveProjectBankGeneralInfo({
                    ...fieldValuesObj,
                    id: (new URLSearchParams(search)).get("bankId"),
                })
            })
            .catch(Function.prototype)
    }

    const handleTranslationFieldsChange = (newValue, languageCode, key) => {
        saveBankTranslation(
            {
                bankId: currentBankId,
                languageCode,
                text: newValue
            },
            key,
        )
    }

    const handleSuccessfulUpload = logo => {
        const msg = logo?.message ?? null;
        msg && message.success(msg);
    };

    const { mainTableColumns } = tableColumnsCreator({
        mainColumns: getTableColumns,
        additionalProps: {
            havePermissionForEdit: userCanModify,
            onTranslationFieldsChange: handleTranslationFieldsChange,
        }
    })

    /** Load general info and project currencies */
    useEffect(() => {
        getProjectCurrencies();
        getProjectBankGeneralInfo((new URLSearchParams(search)).get("bankId"));
    }, [])

    // Set form field values when data is loaded
    useEffect(() => {
        setFieldsValue({
            name: info.name,
            currencies: info.currencies,
            avgProcessingMinutes: info.avgProcessingMinutes
        })
        setIsFormTouched(false);
    }, [info])

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={[
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: userCanModify,
                        loading: isSaving,
                        disabled: !isFormTouched
                    },
                    {
                        type: "secondary",
                        onClick: () => {
                            navigateWithConfirmation((pathname + search + hash)
                                .replace("&bankId=" + encodeURIComponent(currentBankId), "")
                                .replace("&bankName=" + encodeURIComponent(currentBankName), ""),
                                { replace: true }
                            )
                        },
                        text: (
                            <span className='rt--back-button'>
                                <i className="icon-left rt--font-big" />
                                <span>
                                    {t("backoffice.common.back")}
                                </span>
                            </span>
                        ),
                        enabled: true,
                        className: "rt--button-secondary",
                        placement: "left",
                    },
                ]}
            actions={userCanModify && <BankEditActionsComponent />}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        "name": info.name,
                        "currencies": info.currencies,
                        "avgProcessingMinutes": info.avgProcessingMinutes,
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({
                        currencies: formValues.currencies,
                        avgProcessingMinutes: formValues.avgProcessingMinutes
                    }, {
                        currencies: info.currencies,
                        avgProcessingMinutes: info.avgProcessingMinutes
                    }))}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} lg={8}>
                            <Form.Item
                                label={`${t('backoffice.payments.bankName')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ]}
                                className={"rt--form-item-disabled"}
                            >
                                <Input
                                    placeholder={t('backoffice.payments.bankName')}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <Form.Item
                                label={`${t('backoffice.payments.currencies')} *`}
                                name="currencies"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                                className={!userCanModify || isSaving ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        projectCurrencies
                                        .filter(item => item.enabled)
                                        .map(item => (
                                            { value: item.code.toUpperCase(), text: item.code.toUpperCase() }
                                        ))
                                    }
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    loading={isAvailableLoading}
                                    placeholder={t('backoffice.payments.selectCurrencies')}
                                    search={true}
                                    isMultiple={true}
                                    disabled={!userCanModify}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <Form.Item
                                className={!userCanModify ? "rt--form-item-disabled" : ""}
                                label={`${t('backoffice.payments.avarageProcessingMinutes')} *`}
                                name="avgProcessingMinutes"
                                validateFirst
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            const maxLimit = 3*24*60;
                                            if(value !== ""){
                                                if(Number(value) < 0 || Number(value) > maxLimit){
                                                    return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", "0").replace("%Y%", maxLimit))
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        }
                                    })
                                ]}
                            >
                                <NumericInput
                                    placeholder={t('backoffice.payments.avarageProcessingMinutes')}
                                    disabled={!userCanModify}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12} lg={8}>
                                    <div
                                        className='rt--project-logo-title rt--font-small rt--font-bold rt--mb-8'
                                    >
                                        {t('backoffice.payments.logo') + ' *'}
                                    </div>
                                    <ImageUploader
                                        uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.CHANGE_BANK_LOGO}`}
                                        defaultFile={info.logoPath ? {
                                            url: `${import.meta.env.SYSTEM_CDN_URL}/${info.logoPath}`,
                                            status: "done",
                                            percent: 100
                                        } : null}
                                        data={{
                                            id: currentBankId
                                        }}
                                        updateProps={[info?.logoPath]}
                                        acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                        size={1024 * 1024 * 1}
                                        onSuccess={handleSuccessfulUpload}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <h4 className='rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-biger'>
                    {t("backoffice.payments.translations")}
                </h4>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Table
                            columns={mainTableColumns}
                            data={groupTranslations(info)}
                            uniqueKey={TRANSLATION_TABLE_ROW_UNIQUE_KEY}
                            mobileLayoutVertical={true}
                            noPagination={true}
                            disableFullView={true}
                        />
                    </Col>
                </Row>
            </Spin>
        </SubTabFormDashboardLayout>
    );
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux state property, is true when loading available currencies */
    isAvailableLoading: PropTypes.bool,
    /** Redux action to get system available currencies */
    getProjectCurrencies: PropTypes.func,
    /** Redux state property, represents the array of system available currencies  */
    projectCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux action to get project project payment bank general info */
    getProjectBankGeneralInfo: PropTypes.func,
    /** Redux action to save project project payment bank general info */
    saveProjectBankGeneralInfo: PropTypes.func,
    /** Redux action to save project bank name translation */
    saveBankTranslation: PropTypes.func,
    /** Redux state property, current bank general info */
    info: PropTypes.object,
    /** Redux state property, is true when project payment is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when project payment is saving */
    isSaving: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectCurrencies: () => {
            dispatch(getProjectCurrencies());
        },

        getProjectBankGeneralInfo: (bankId) => {
            dispatch(getProjectBankGeneralInfo(bankId));
        },

        saveProjectBankGeneralInfo: (requestBody) => {
            dispatch(saveProjectBankGeneralInfo(requestBody));
        },

        saveBankTranslation: (requestBody, key) => {
            dispatch(saveBankTranslation(requestBody, key));
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        currencies: state.projects.edit.currencies,
        isAvailableLoading: state.systemCurrencies.isAvailableLoading,
        projectCurrencies: state.projects.edit.currencies,
        info: state.projects.edit.payment.banks.edit.info,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
