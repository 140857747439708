import React from "react";

import PropTypes from "prop-types";

import arFlag from "NEW/public/images/flags/ar.svg";
import bgFlag from "NEW/public/images/flags/bg.svg";
import bnFlag from "NEW/public/images/flags/bn.svg";
import deFlag from "NEW/public/images/flags/de.svg";
import enFlag from "NEW/public/images/flags/en.svg";
import esFlag from "NEW/public/images/flags/es.svg";
import faFlag from "NEW/public/images/flags/fa.svg";
import frFlag from "NEW/public/images/flags/fr.svg";
import heFlag from "NEW/public/images/flags/he.svg";
import hiFlag from "NEW/public/images/flags/hi.svg";
import hyFlag from "NEW/public/images/flags/hy.svg";
import koFlag from "NEW/public/images/flags/ko.svg";
import ptFlag from "NEW/public/images/flags/pt.svg";
import roFlag from "NEW/public/images/flags/ro.svg";
import ruFlag from "NEW/public/images/flags/ru.svg";
import trFlag from "NEW/public/images/flags/tr.svg";
import zhFlag from "NEW/public/images/flags/zh.svg";

const mapping = {
	"ar": arFlag,
	"bg": bgFlag,
	"bn": bnFlag,
	"de": deFlag,
	"en": enFlag,
	"es": esFlag,
	"fa": faFlag,
	"fr": frFlag,
	"he": heFlag,
	"hi": hiFlag,
	"hy": hyFlag,
	"ko": koFlag,
	"pt": ptFlag,
	"ro": roFlag,
	"ru": ruFlag,
	"tr": trFlag,
	"zh": zhFlag
}

const Flag = ({ code }) => {
	const style = code
		? {
				backgroundImage: `url(${mapping[code.toLowerCase()]}`,
			}
		: {};

	return (
		<div className="rt--flag">
			<div className="rt--flag-icon" style={style} />
		</div>
	);
};

Flag.propTypes = {
	code: PropTypes.string,
};

export default Flag;
