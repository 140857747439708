import React from "react";
import PropTypes from 'prop-types';

import LeaderboardMobileView from "./mobileView";
import LeaderboardDesktopView from "./desktopView";
import LeaderboardHeader from "./header";

import { classNames, isMobile } from "utils/common";

import { EXTRA_CONTENT_POSITIONS, LEADERBOARD_TYPE } from "./constants";

import leadersType from "types/dashboard/leaders.type";

const Leaderboard = ({
    // common
    type = LEADERBOARD_TYPE.FINANCIAL,

    // data
    leaders = [],
    isLoading = false,
    currencyCode,

    // header
    title,
    showTitle,
    extraContent,
    extraContentPosition,
}) => {
    const isMobileDevice = isMobile();

    return (
        <div
            className={classNames(
                isMobileDevice ? "rt--pb-16 rt--pl-16 rt--pr-16 rt--pt-16" : "rt--pb-24 rt--pl-24 rt--pr-24 rt--pt-24",
            )}
        >
            {
                isMobileDevice
                    ? (
                        <LeaderboardMobileView
                            leaders={leaders}
                            isLoading={isLoading}
                            type={type}
                            currencyCode={currencyCode}
                        />
                    ) : (
                        <LeaderboardDesktopView
                            leaders={leaders}
                            isLoading={isLoading}
                            type={type}
                            currencyCode={currencyCode}
                        />
                    )
            }
        </div>
    )
};

/** Leaderboard propTypes*/
Leaderboard.propTypes = {
    leaders: PropTypes.arrayOf(leadersType),
    isLoading: PropTypes.bool,
    currencyCode: PropTypes.string,
    /**
     * Leaderboard header title
     */
    title: PropTypes.string,
    showTitle: PropTypes.bool,
    /**
     * Extra content in header
     */
    extraContent: PropTypes.node,
    extraContentPosition: PropTypes.oneOf([
        EXTRA_CONTENT_POSITIONS.RIGHT,
        EXTRA_CONTENT_POSITIONS.LEFT,
    ]),
    /**
     * Leaderboard type (this adds/removes some display information)
     */
    type: PropTypes.oneOf([
        LEADERBOARD_TYPE.FINANCIAL,
        LEADERBOARD_TYPE.OPERATIONAL,
    ])
};

export default Leaderboard;
