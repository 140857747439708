import React from "react";

import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import Status from "components/common/status";
import Tooltip from 'components/common/tooltip';

import { isMobile } from "utils/common";

import useFormat from 'hooks/useFormat';
import useDate from 'hooks/useDate';

import { BET_TYPE } from "constants/bet.constants";
import { STATUS_TYPES } from "constants/status.constants";

import singleBetType from "types/bet/singleBet.type";

/** Betslips Table Bets details Component */
const BetDetailsComponent = ({
    bets,
    currencyCode,
    isRealTimeBet
}) => {

    const { t } = useTranslation();

    const { dateService } = useDate();

    const { formatAmount } = useFormat();

    let fields = ["id", "game", "status", "market", "factor", "amount", "possibleWin", "winning", "eventId", "event"];

    if (isRealTimeBet) {
        fields = fields.concat(["matchDate", "competitionName"]);
        fields = fields.filter(f => f !== "winning")
    }

    const renderField = (field, bet) => {
        switch (field) {
            case "id":
                return {
                    title: t("backoffice.bets.betId"),
                    content: bet.id
                }
            case "game":
                return {
                    title: t("backoffice.bets.game"),
                    content: bet.sportName || bet.game
                }
            case "status":
                return {
                    title: t("backoffice.bets.status"),
                    content: <Status type={STATUS_TYPES.BET} status={bet.state ?? bet.status} />
                }
            case "market":
                return {
                    title: t("backoffice.bets.market"),
                    content: (
                        <div className="rt--bets-list-item-col-with-icon rt--flex rt--align-center">
                            <span className="rt--title rt--font-normal rt--font-bold rt--pr-4">{bet.market || "-"}</span>
                            {
                                bet.result && (
                                    <Tooltip
                                        title={(
                                            <div className="rt--tooltip-bet-result rt--flex">
                                                <b className="rt--title rt--font-normal rt--font-regular rt--mr-4">{t("backoffice.bets.result")}: </b>
                                                <span className="rt--title rt--font-normal rt--font-regular">{bet.result}</span>
                                            </div>
                                        )}
                                        trigger={["hover", "click"]}
                                        placement="bottomLeft"
                                        enableMobile={true}
                                    >
                                        <i className="icon-info rt--font-big rt--cursor-pointer"></i>
                                    </Tooltip>
                                )
                            }


                        </div>
                    )
                }
            case "factor":
                return {
                    title: t("backoffice.bets.odds"),
                    content: bet.factor + "x"
                }
            case "amount":
                return {
                    title: t("backoffice.bets.amount"),
                    content: bet.betType === BET_TYPE.SINGLE ? formatAmount(bet.amount, currencyCode, bet.betTime) : "-"
                }
            case "possibleWin":
                return {
                    title: t("backoffice.bets.possibleWin"),
                    content: bet.betType === BET_TYPE.SINGLE ? formatAmount(bet.possibleWin, currencyCode, bet.betTime) : "-"
                }
            case "winning":
                return {
                    title: t("backoffice.bets.win"),
                    content: bet.betType === BET_TYPE.SINGLE ? formatAmount(bet.winning, currencyCode, bet.betTime) : "-"
                }
            case "eventId":
                return {
                    title: t("backoffice.bets.eventId"),
                    content: bet.eventId || "-"
                }
            case "event":
                return {
                    title: t("backoffice.bets.event"),
                    content: bet.event ? (
                        <Tooltip
                            title={(
                                <div className="rt--tooltip-bet-result rt--flex">
                                    <span className="rt--title rt--font-normal rt--font-regular">{bet.event}</span>
                                </div>
                            )}
                            trigger={["hover", "click"]}
                            placement="bottomLeft"
                            enableMobile={true}
                        >
                            <span className="rt--title rt--font-normal rt--font-bold">{bet.event}</span>
                        </Tooltip>
                    ) : "-",
                }
            case "matchDate":
                return {
                    title: t("backoffice.bets.matchDate"),
                    content: bet.matchDate ? dateService.format(bet.matchDate) : "-"
                }
            case "competitionName":
                return {
                    title: t("backoffice.bets.competitionName"),
                    content: bet.competitionName || "-"
                }
            default:
                return {
                    title: "",
                    content: ""
                }
        }
    }

    /** Render for desktop
      * @function
      * @memberOf BetDetailsComponent
  */
    const renderForDesktop = () => {
        return (
            <div className="rt--bets-list">
                {
                    bets.map(bet => (
                        <div className="rt--bets-list-item rt--pt-8 rt--pb-8 rt--flex" key={bet.id}>
                            {
                                Array.from(Array(Math.ceil(fields.length / 2)).keys()).map(key => (
                                    <div className="rt--bets-list-item-col rt--flex-equal" key={key}>
                                        <div className="rt--flex rt--align-center rt--mb-8">
                                            <b className="rt--title rt--font-normal rt--font-regular">{renderField(fields[2 * key], bet).title}:</b>
                                            <span className="rt--title rt--font-normal rt--font-bold rt--pl-8 rt--pr-8">{renderField(fields[2 * key], bet).content}</span>
                                        </div>
                                        {
                                            fields[2 * key + 1] && (
                                                <div className="rt--flex rt--align-center">
                                                    <b className="rt--title rt--font-normal rt--font-regular">{renderField(fields[2 * key + 1], bet).title}:</b>
                                                    <span className="rt--title rt--font-normal rt--font-bold rt--pl-8 rt--pr-8">{renderField(fields[2 * key + 1], bet).content}</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

    /** Render for mobile
       * @function
       * @memberOf BetDetailsComponent
   */
    const renderForMobile = () => {
        return (
            <div className="rt--bets-list">
                {
                    bets.map(bet => (
                        <div className="rt--bets-list-item rt--flex" key={bet.id}>
                            {
                                fields.map(field => (
                                    <div key={field} className="rt--flex rt--flex-col rt--mb-12" >
                                        <b className="rt--title rt--font-normal rt--font-regular">{renderField(field, bet).title}:</b>
                                        <Tooltip
                                            trigger={["click"]}
                                            enableMobile={true}
                                            className="rt--tooltip-table"
                                            title={renderField(field, bet).content}
                                        >
                                            <span className="rt--title rt--font-big rt--font-bold">{renderField(field, bet).content}</span>
                                        </Tooltip>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <div className="rt--bets">
            {
                !isMobile() && (
                    <b className="rt--title rt--font-bold rt--font-normal rt--bets-title">{t("backoffice.bets.bets")}</b>
                )
            }
            {
                isMobile() ? renderForMobile() : renderForDesktop()
            }

        </div>

    );
};

/** BetDetailsComponent propTypes
 * PropTypes
*/
BetDetailsComponent.propTypes = {
    /** Array of bets */
    bets: PropTypes.arrayOf(singleBetType),
    /** Currency code of bet */
    currencyCode: PropTypes.string,
    /** Is real time bet */
    isRealTimeBet: PropTypes.bool
};


export default BetDetailsComponent;
