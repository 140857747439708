import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { isMenuItemAvailable } from "utils/menu";
import { getUser } from "utils/auth";

import menuItems from "components/common/sidebar/items"

import userInfoType from "types/profile/userInfo.type";

/** Wrapper Component for Dashboard, which will redirect to default page */
const DashboardComponent = ({
    userInfo,
    globalProjectType,
    globalProjectMode,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    /** Is true when current user has no permission */
    const [noPermission, setNoPermission] = useState(false);

    /** Function to get default url to redirect
         * @function
         * @returns {string}
         * @memberof DashboardComponent
     */

    const paymentType = getUser()?.paymentType ?? 0;

    const getDefaultUrl = () => {
        const items = menuItems((userInfo.companies ?? []).map(p => p.type));
        let defaultURL = null;

        loop: for (let i = 0; i < items.length; i++) {
            if (items[i].url) {
                if (isMenuItemAvailable(items[i], globalProjectType, globalProjectId, paymentType, globalProjectMode)) {
                    defaultURL = items[i].url;
                    break loop;
                }
            } else if (items[i].items) {
                for (let j = 0; j < items[i].items.length; j++) {
                    if (items[i].items[j].url) {

                        if (isMenuItemAvailable(items[i].items[j], globalProjectType, globalProjectId, paymentType, globalProjectMode)) {
                            defaultURL = items[i].items[j].url;
                            break loop;
                        }
                    } else if (items[i].items[j].items) {
                        for (let k = 0; k < items[i].items[j].items.length; k++) {
                            if (items[i].items[j].items[k].url) {
                                if (isMenuItemAvailable(items[i].items[j].items[k], globalProjectType, globalProjectId, paymentType, globalProjectMode)) {
                                    defaultURL = items[i].items[j].items[k].url;
                                    break loop;
                                }
                            }
                        }
                    }
                }
            }
        }

        return defaultURL;
    }

    /** Redirect to default page */
    useEffect(() => {
        if(userInfo.id){
            const defaultURL = getDefaultUrl();

            if(defaultURL) {
                navigate(defaultURL);
            } else {
                setNoPermission(true);
            }
        }
    }, [userInfo.id])

    return noPermission ? (
        <span className='rt--title rt--font-bold rt--text-center rt--font-bigest rt--no-perm'>{t("backoffice.common.noPermission")}</span>
    ) : <Fragment />
}

/** DashboardComponent propTypes
    * PropTypes
*/
DashboardComponent.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux state property, represents global project type */
    globalProjectType: PropTypes.number,
    /** Redux state property, represents global project type */
    globalProjectMode: PropTypes.number,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        globalProjectType: state.common.globalProjectType,
        globalProjectMode: state.common.globalProjectMode,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    null
)(
    DashboardComponent
);
