import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Dropdown from "components/common/dropdown";

import { changeCurrency, getUser } from 'utils/auth';

import useFormat from 'hooks/useFormat';
import useProjectType from 'hooks/useProjectType';

import { WALLET_TYPE } from "constants/wallet.constants";
import { USER_ROLE } from 'constants/user.constants';

import currencyType from 'types/profile/currency.type';

/** Desktop Currencies dropdown component on Header */

const DesktopCurrenciesDropdown = ({
    currency,
    currencies,
    availableWallets,
    makeWalletAmount
}) => {

    const { t } = useTranslation();

    const { formatAmount } = useFormat();
    const { hasLiteMode } = useProjectType();

    const [dropdownVisible, setDropdownVisible] = useState(false);

    /** Function, fires on dropdown item click
       * @function
       * @param {string} code
       * @memberOf CurrenciesDropdown
   */
    const handleDropdownChange = currencyCode => {
        if (currencyCode === "*") return false;
        
        if (currencyCode) {
            const curr = currencies.find(c => c.currencyCode === currencyCode?.toUpperCase());
            
            if (currency !== curr?.currencyCode) {
                changeCurrency(curr?.currencyCode)
            }

        }
        setDropdownVisible(false)
    }

    const singleWalletMode = hasLiteMode && getUser()?.role === USER_ROLE.AGENT;

    return (
        <div className="rt--header-actions rt--header-item rt--header-actions-currencies rt--mr-16">
            {
                getUser() && (
                    <Dropdown
                        overlayClassName="rt--header-actions-currencies-dropdown"
                        menu={{
                            onClick: e => handleDropdownChange(e ? e.key : null),
                            items: [
                                {
                                    key: '*',
                                    className: 'rt--header-actions-wallet-item-header',
                                    label: (
                                        <div
                                            className='rt--header-actions-wallet rt--header-actions-wallet-title rt--flex rt--pl-12 rt--pr-12'
                                        >
                                            {
                                                availableWallets.map(wallet => (
                                                    <div
                                                        className='rt--flex rt--flex-col rt--flex-equal rt--justify-center rt--align-start'
                                                        key={wallet.type}>
                                                        <span
                                                            className='rt--title rt--font-small rt--font-regular'>{wallet.title}</span>
                                                    </div>
                                                ))
                                            }
                                            <div></div>
                                        </div>
                                    )
                                },
                                ...currencies.map(c => (
                                    {
                                        key: c.currencyCode,
                                        className: "rt--header-actions-wallet-item" + (c.currencyCode === currency ? " rt--dropdown-item-active" : ""),
                                        label: (
                                            <div className='rt--header-actions-wallet'>
                                                {
                                                    availableWallets.map(wallet => (
                                                        <div
                                                            className='rt--flex rt--flex-col rt--flex-equal rt--justify-center rt--align-start'
                                                            key={wallet.type}>
                                                            <b className='rt--title rt--font-normal rt--font-bold'>
                                                                {
                                                                    formatAmount(
                                                                        makeWalletAmount(wallet.type, c.currencyCode),
                                                                        c.currencyCode
                                                                    )
                                                                }
                                                            </b>
                                                        </div>
                                                    ))
                                                }
                                                <div
                                                    className='rt--flex rt--flex-col rt--flex-equal rt--justify-center rt--align-end'>
                                                    <b className='rt--title rt--font-normal'>{c.currencyCode}</b>
                                                </div>
                                            </div>
                                        )
                                    }
                                ))
                            ]
                        }}
                        disabled={singleWalletMode}
                        popupVisible={dropdownVisible}
                        onVisibleChange={visible => setDropdownVisible(visible)}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    >
                        <div className='rt--header-actions-content rt--flex rt--align-center rt--pl-8 rt--pr-8'>
                            <div className='rt--header-actions-content-icon rt--flex rt--align-center rt--justify-center rt--mr-12'>
                                <i className='icon-wallet rt--font-bigest' />
                            </div>

                            {
                                availableWallets.map(wallet => (
                                    <div className={
                                            'rt--flex rt--flex-col rt--justify-center rt--align-start rt--mr-12 rt--header-actions-wallet'
                                            + (singleWalletMode ? " rt--header-actions-wallet-single-mode": "")
                                        } 
                                        key={wallet.type}
                                    >
                                        <span className='rt--title rt--font-small rt--font-regular'>{wallet.title}:</span>
                                        <b className='rt--title rt--font-small'>
                                            {
                                                `${formatAmount(makeWalletAmount(wallet.type, currency), currency)} ${singleWalletMode ? currency ?? "" : ""}`
                                            }
                                        </b>
                                    </div>
                                ))
                            }

                            {
                                !singleWalletMode && (
                                    <Fragment>
                                        <b className='rt--title rt--font-normal'>
                                            { currency ?? "" }
                                        </b>
                                        <i className='icon-down-small rt--font-bigest rt--header-actions-content-arrow' />
                                    </Fragment>
                                )
                            }
                            
                        </div>
                    </Dropdown>
                )
            }

        </div>
    )
}

/** CurrenciesDropdown propTypes
    * PropTypes
*/
DesktopCurrenciesDropdown.propTypes = {
    /** Redux state property, the current user currency */
    currency: PropTypes.string,
    /** Redux state property, current user currencies */
    currencies: PropTypes.arrayOf(currencyType),
    /** Available Wallets */
    availableWallets: PropTypes.arrayOf(PropTypes.shape({
        /** Title */
        title: PropTypes.string,
        /** Type */
        type: PropTypes.oneOf(Object.values(WALLET_TYPE))
    })),
    /** Make wallet amount */
    makeWalletAmount: PropTypes.func
}

const mapStateToProps = state => {
    return {
        currency: state.profile.currency
    }
}

export default connect(mapStateToProps, null)(DesktopCurrenciesDropdown)

