export const theme = {
	token: {
		colorPrimary: "#181827",
		colorInfo: "#181827",
		colorPrimaryHover: "#006f8f",
		colorSuccess: "#00ba88",
		colorError: "#ed2e7e",
		colorLink: "#01abc2",
		colorTextPlaceholder: "rgb(160, 163, 189)",
		boxShadow: "0px 8px 16px rgba(17, 17, 17, 0.04)",
		lineHeight: "1.193",
		fontFamily: "Sf-Pro-Display",
	},
	components: {
		Skeleton: {
			controlHeight: 35,
		},
		Button: {
			colorPrimary: "#0097C3",
			colorPrimaryHover: "#0097c31a",
			controlHeight: 38,
			borderColorDisabled: "rgb(128, 203, 225)",
			paddingInline: 24,
			paddingBlock: 0,
			marginXS: 0,
			contentLineHeight: 0.5714285714285714,
			textHoverBg: "rgba(255, 0, 0, 0)",
			defaultShadow: "none",
			primaryShadow: "none",
			colorTextDisabled: "rgb(255, 255, 255)",
			colorBgContainerDisabled: "rgb(128, 203, 225)",
			contentFontSizeSM: 16,
			fontWeight: 500,
		},
		Select: {
			controlHeight: 38,
			optionHeight: 36,
			optionPadding: "7px 12px",
			colorBorder: "rgba(217, 217, 217, 0)",
			optionSelectedColor: "rgb(24, 24, 39)",
			optionSelectedFontWeight: 400,
			optionSelectedBg: "rgb(239, 240, 246)",
			optionActiveBg: "rgb(242, 250, 252)",
			selectorBg: "rgb(247, 247, 252)",
			paddingSM: 16,
		},
		Input: {
			colorTextPlaceholder: "rgb(160, 163, 189)",
			controlHeight: 36,
			colorText: "rgb(24, 24, 39)",
			hoverBorderColor: "rgba(64, 150, 255, 0)",
			colorBgContainer: "rgb(247, 247, 252)",
			paddingInline: 16,
			paddingInlineSM: 16,
			paddingInlineLG: 16,
			colorBorder: "rgba(217, 217, 217, 0)",
		},
		Menu: {
			itemBg: "rgb(24, 24, 39)",
			popupBg: "rgb(24, 24, 39)",
			subMenuItemBg: "rgb(24, 24, 39)",
			iconSize: 24,
			horizontalLineHeight: "24px",
			itemActiveBg: "rgb(36, 36, 50)",
			iconMarginInlineEnd: 8,
			activeBarBorderWidth: 5,
			itemSelectedColor: "rgb(255, 255, 255)",
			itemColor: "rgb(255, 255, 255)",
			itemSelectedBg: "rgb(36, 36, 50)",
		},
		Tooltip: {
			colorBgSpotlight: "rgb(24, 24, 39)",
		},
		Layout: {
			headerHeight: 60,
			headerBg: "rgb(247, 247, 252)",
			headerPadding: "0",
			bodyBg: "rgb(247, 247, 252)",
		},
		Table: {
			headerBg: "rgb(255, 255, 255)",
			cellPaddingBlock: 0,
			rowHoverBg: "rgb(242, 250, 252)",
			headerColor: "rgb(160, 163, 189)",
		},
		Pagination: {
			itemBg: "rgb(247, 247, 252)",
			itemActiveBg: "rgb(0, 151, 195)",
			itemLinkBg: "rgb(247, 247, 252)",
			fontWeightStrong: 500,
		},
		Breadcrumb: {
			itemColor: "rgb(24, 24, 39)",
			lastItemColor: "rgb(160, 163, 189)",
			iconFontSize: 12,
			separatorColor: "rgb(153, 153, 153)",
			separatorMargin: 6,
			linkColor: "rgb(24, 24, 39)",
			linkHoverColor: "rgb(160, 163, 189)",
			fontSize: 12,
		},
		Checkbox: {
			controlInteractiveSize: 18,
		},
		Radio: {
			radioSize: 14,
			lineWidth: 1,
			dotSize: 11,
		},
		Form: {
			labelColor: "rgb(20, 20, 43)",
			itemMarginBottom: 16,
		},
		Tabs: {
			horizontalItemPadding: "8px 24px",
			titleFontSize: 16,
			itemSelectedColor: "rgb(0, 151, 195)",
		},
		Drawer: {
			padding: "24px 16px 40px",
			paddingLG: 16,
			footerPaddingBlock: 14,
		},
		Divider: {
			colorSplit: "rgb(217, 219, 233)",
		},
		Switch: {
			trackHeight: 16,
			trackMinWidth: 28,
			opacityLoading: 0.4,
			handleBg: "rgb(217, 219, 233)",
			handleShadow: "transparent",
			handleSize: 12,
		},
		DatePicker: {
			colorBgContainer: "rgb(247, 247, 252)",
			cellHoverBg: "rgb(242, 250, 252)",
			cellActiveWithRangeBg: "rgb(247, 247, 252)",
			cellHoverWithRangeBg: "rgb(247, 247, 252)",
			cellRangeBorderColor: "rgb(247, 247, 252)",
			timeColumnWidth: 80,
			timeCellHeight: 40,
			cellHeight: 32,
			cellWidth: 32,
			paddingInline: 16,
			paddingXXS: 2,
			borderRadiusSM: 6,
		},
	},
};
