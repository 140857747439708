import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

import { getProjectCurrencies } from "store/actions/dashboard/projects/currencies.action";
import { createProjectBank } from "store/actions/dashboard/projects/paymentsConfig.action";

import {Col, Form, message, Progress, Row} from "antd";
import Modal from "components/common/modal";
import Select from "components/common/select";
import Input from 'components/common/input';

import { isFormChanged } from "utils/form";

import {IMAGE_TYPE, POPUP_SIZE} from 'constants/common.constants';

import currencyType from "types/currency/currency.type";
import ImageUploader from "components/common/imageUploader";

const BankCreateComponent = ({
    onClose,
    isAvailableLoading,
    getProjectCurrencies,
    projectCurrencies,
    createProjectBank,
    isSaving,
}) => {
    const { t } = useTranslation();
    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const hasOneCurrency = projectCurrencies.length === 1;

    /** Load project currencies */
    useEffect(() => {
        getProjectCurrencies();
    }, [])

    const handleForm = () => {
        validateFields()
            .then((fieldValuesObj) => {
                const formData = new FormData();

                formData.append('Name', fieldValuesObj.bankName);

                if (hasOneCurrency) {
                    formData.append('Currencies', projectCurrencies[0]?.code.toUpperCase());
                } else {
                    fieldValuesObj.currency.forEach(currency => {
                        formData.append('Currencies', currency);
                    });
                }

                formData.append('File', fieldValuesObj.file);

                const onSuccess = (bankId) => {
                    navigate(`${pathname}${search}&bankId=${bankId}&bankName=${fieldValuesObj.bankName}${hash}`);
                }

                createProjectBank(formData, onSuccess);
            })
            .catch((error) => {
                console.log('validateFields failed', error);
            })
    }

    const handleSuccessfulUpload = logo => {
        const msg = logo?.message ?? null;
        msg && message.success(msg);
    };

    const formInitialValues = {
        bankName: null,
        currencies: []
    }

    return (
        <Modal
            title={t('backoffice.payments.createBank')}
            okText={t('backoffice.common.create')}
            cancelText={t('backoffice.common.cancel')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
            disableOkButton={!isFormTouched}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={formInitialValues}
                onValuesChange={(_, fieldValuesObj) => {
                    setIsFormTouched(
                        isFormChanged({ ...fieldValuesObj }, { ...formInitialValues })
                    )
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.payments.bankName')} *`}
                            name="bankName"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                            className={"rt--general-form-item" + (isSaving ? "rt--form-item-disabled" : "")}
                            data-placeholder={t('backoffice.payments.bankName')}
                        >
                            <Input
                                placeholder={t('backoffice.payments.bankName')}
                            />
                        </Form.Item>
                    </Col>
                    {!hasOneCurrency && (
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.payments.currencies')} *`}
                                name="currency"
                                rules={[
                                    {required: true, message: t('backoffice.validation.fieldRequired')}
                                ]}
                                className={isSaving ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        projectCurrencies
                                            .filter(item => item.enabled)
                                            .map(item => (
                                                {value: item.code.toUpperCase(), text: item.code.toUpperCase()}
                                            ))
                                    }
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    loading={isAvailableLoading}
                                    placeholder={t('backoffice.payments.selectCurrencies')}
                                    search={true}
                                    isMultiple={true}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    <Col span={24}>
                        <Form.Item
                            name="file"
                            label={`${t('backoffice.payments.logo')} *`}
                            rules={[{required: true, message: t('backoffice.validation.fieldRequired')}]}
                        >
                            <ImageUploader
                                size={1024 * 1024 * 1}
                                acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                onSuccess={handleSuccessfulUpload}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    );
}

/** BankCreateComponent propTypes
    * PropTypes
*/
BankCreateComponent.propTypes = {
    /** Redux state property, is true when loading available currencies */
    isAvailableLoading: PropTypes.bool,
    /** Redux action to get system available currencies */
    getProjectCurrencies: PropTypes.func,
    /** Redux state property, represents the array of system available currencies  */
    projectCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux action to create project project payment bank */
    createProjectBank: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Redux state property, is true when project payment bank is saving */
    isSaving: PropTypes.bool,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectCurrencies: () => {
            dispatch(getProjectCurrencies());
        },

        createProjectBank: (data, onSuccess) => {
            dispatch(createProjectBank(data, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        currencies: state.projects.edit.currencies,
        isAvailableLoading: state.systemCurrencies.isAvailableLoading,
        projectCurrencies: state.projects.edit.currencies,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankCreateComponent);
