import PropTypes from 'prop-types';

import { BET_STATE, BET_TYPE } from 'constants/bet.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/project.constants';
import singleBetType from './singleBet.type';

export default PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.number,
    betTime: PropTypes.string,
    betType: PropTypes.oneOf(Object.values(BET_TYPE)),
    calculateTime: PropTypes.string,
    currencyCode: PropTypes.string,
    externalBetSlipId: PropTypes.number,
    externalPartnerId: PropTypes.string,
    factor: PropTypes.number,
    paidoutTime: PropTypes.string,
    possibleWin: PropTypes.number,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
    provider: PropTypes.oneOf(Object.values(PROJECT_PROVIDER_TYPE)),
    status: PropTypes.oneOf(Object.values(BET_STATE)),
    userId: PropTypes.number,
    userName: PropTypes.string,
    winning: PropTypes.number,
    bets: PropTypes.arrayOf(singleBetType),
    ggr: PropTypes.number
})