//#region libraries
import i18n from 'translations/config';
//#endregion

//#region utils
import { isFranchisingMode } from 'utils/common';
import { getUser } from 'utils/auth';
import { hasAgentNonDirectTransactionAccess } from "utils/access";
import sessionStorageUtils from "utils/sessionStorage";
//#endregion

//#region constants
import { USER_STATE, USER_ROLE } from "constants/user.constants";
import { CREATION_FORM_FIELDS_ORDER } from "constants/agent.constants";
import {AGENTS} from "constants/pageName.constants";
//#endregion

export const isRedirectToSubAgentsEnabledFn = (record) => {
    const { betShopsCount, subAgentsCount, playersCount, state } = record;

    if (state === USER_STATE.DELETED) {
        return false;
    }

    if (isFranchisingMode()) {
        return betShopsCount !== 0;
    }

    return subAgentsCount + playersCount + betShopsCount !== 0;
}

export const getDisabledExpandRowsIds = (agents) => {
    return (
        agents.filter(agentData => agentData.wallets.length === 0 || agentData.state === USER_STATE.DELETED).map(agentData => agentData.id)
    )
}

export const getAutoSuggestionItems = ({ agentNames, role, userName, id }) => {
    const items = [...agentNames];

    if (role !== USER_ROLE.ADMIN) {
        items.concat(
            { value: userName ?? "", key: id }
        )
    }

    return items;
}

export const canTransferToAgent = ({ agentData, userInfo }) => {
    if (agentData.state !== USER_STATE.IN_PROGRESS && agentData.state !== USER_STATE.ACTIVE && agentData.state !== USER_STATE.BLOCKED) {
        return false;
    }

    if (agentData.id === userInfo.id) {
        return false;
    }

    if (getUser()?.role !== USER_ROLE.ADMIN) {
        return hasAgentNonDirectTransactionAccess(userInfo) || agentData.parentId === userInfo.id
    }

    return true;
}

export const canPayCommissionToAgent = ({ agentData, userInfo }) => {

    if (agentData.state !== USER_STATE.IN_PROGRESS && agentData.state !== USER_STATE.ACTIVE && agentData.state !== USER_STATE.BLOCKED) {
        return false;
    }

    if (getUser()?.role !== USER_ROLE.ADMIN) {
        return agentData.parentId === userInfo.id
    }

    return (agentData?.hierarchy?.match(/\//g) ?? []).length === 1;
}

export const constructAgentsWalletData = (agentsList) => {
    return agentsList?.map(agentData => {
        const combinedWalletDataByCurrency = {};

        agentData?.wallets?.forEach(walletData => {
            if (!combinedWalletDataByCurrency[walletData.currencyCode]) {

                combinedWalletDataByCurrency[walletData.currencyCode] = {
                    "currencyCode": walletData.currencyCode,
                    [walletData.type]: {
                        ...walletData
                    }
                }

                return;
            }

            combinedWalletDataByCurrency[walletData.currencyCode][walletData.type] = {
                ...walletData
            }
        })

        agentData.wallets = Object.values(combinedWalletDataByCurrency);

        return agentData;
    })
}

export const getNavBarInitialItems = (agentId) => {
    if (agentId) {
        return sessionStorageUtils.get(AGENTS);
    }

    return [{ navBarTitle: i18n.t(`backoffice.menu.${AGENTS}`) }]
}

export const getOrderedCreationFormFields = (registrationForm) => {
    const orderedFields = [];

    CREATION_FORM_FIELDS_ORDER.forEach(fieldName => {
        const foundField = registrationForm.find(fieldData => (
            fieldData.name?.toLowerCase() === fieldName.toLowerCase()
        ))

        if (Boolean(foundField)) {
            orderedFields.push(foundField);
        }
    })

    return orderedFields;
}
