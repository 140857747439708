import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Spin } from 'antd';

import NumericInput from 'components/common/numericInput';
import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import { getProjectLimitSettings, saveProjectLimitSettings } from "store/actions/dashboard/projects/limitSettings.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';
import {
    isCurrentProjectRetail,
    isCurrentProjectAgentSystem,
    isFranchisingMode,
    binaryToFlags,
    isMobile
} from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { PAYMENT_METHODS } from 'constants/project.constants';
import { AMOUNT_REGEX } from "constants/regex.constants";

import companyType from 'types/company/company.type';
import limitSettingsType from "types/agentSystem/limitSettings.type";
import useProjectType from "hooks/useProjectType";
import {compareNumbers} from "utils/validators";
import {COMPARISON_TYPE} from "constants/validator.constants";


/** Project Edit Page FavAmountsAndLimits Tab Limits subtab Component */
const LimitsComponent = ({
    getProjectLimitSettings,
    saveProjectLimitSettings,
    isSaving,
    isLoading,
    limitSettings,
    onTabChange,
    allCompanies,
    globalCompanyId,
    globalProjectId,
    availableCurrencies,
    handleCurrencyChange,
    currency,
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();
    const { hasLiteMode } = useProjectType();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const paymentSettings = binaryToFlags(Object.values(PAYMENT_METHODS), limitSettings.paymentType);
    const userCanModify = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_LIMITS, action: PERMISSION_ACTION.MODIFY });

    const walletTypeIsCreditLine = limitSettings.walletTypeIsCreditLine;

    /** Set default values */
    useEffect(() => {
        const fieldValuesObj = {};

        if (isCurrentProjectAgentSystem(allCompanies, globalCompanyId, globalProjectId)) {
            fieldValuesObj.maxNegativeCreditLine = limitSettings.maxNegativeCreditLine;
            fieldValuesObj.maxPositiveCreditLine = limitSettings.maxPositiveCreditLine;

            if (!isFranchisingMode()) {
                fieldValuesObj.maxPositiveCreditLineSubTree = limitSettings.maxPositiveCreditLineSubTree;
                fieldValuesObj.maxNegativeCreditLineSubTree = limitSettings.maxNegativeCreditLineSubTree;
                fieldValuesObj.maxAgentBalance = limitSettings.maxAgentBalance;
                fieldValuesObj.maxAgentBalanceSubTree = limitSettings.maxAgentBalanceSubTree;
            }
        }

        if (paymentSettings.includes(PAYMENT_METHODS.BANK_TRANSFER)) {
            fieldValuesObj.maxBankDepositLimit = limitSettings.maxBankDepositLimit;
            fieldValuesObj.maxBankWithdrawalLimit = limitSettings.maxBankWithdrawalLimit;
        }

        if (paymentSettings.includes(PAYMENT_METHODS.EPOS)) {
            fieldValuesObj.maxEposDepositLimit = limitSettings.maxEposDepositLimit;
            fieldValuesObj.maxEposWithdrawalLimit = limitSettings.maxEposWithdrawalLimit;
        }

        if (paymentSettings.includes(PAYMENT_METHODS.STANDART)) {
            fieldValuesObj.minPlayerDepositLimit = limitSettings.minPlayerDepositLimit;
            fieldValuesObj.maxPlayerDepositLimit = limitSettings.maxPlayerDepositLimit;
            fieldValuesObj.minPlayerWithdrawalLimit = limitSettings.minPlayerWithdrawalLimit;
            fieldValuesObj.maxPlayerWithdrawalLimit = limitSettings.maxPlayerWithdrawalLimit;
            fieldValuesObj.playerDailyDepositLimit = limitSettings.playerDailyDepositLimit;
            fieldValuesObj.playerDailyWithdrawalLimit = limitSettings.playerDailyWithdrawalLimit;
        }

        if (isCurrentProjectRetail(allCompanies, globalCompanyId, globalProjectId)) {
            fieldValuesObj.maxBetShopBalance = limitSettings.maxBetShopBalance;
            fieldValuesObj.maxBetShopLimit = limitSettings.maxBetShopLimit;
        }

        setFieldsValue(fieldValuesObj)

    }, [limitSettings])

    /** Get project limit settings */
    useEffect(() => {
        if (currency) {
            getProjectLimitSettings(searchParams.id, currency);
        }
    }, [currency])


    /** Fires when form submitted
       * @function
       * @memberOf LimitSettingsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveProjectLimitSettings({
                    id: searchParams.id,
                    ...data,
                    currencyCode: currency
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    /** Function to validate limit
       * @function
       * @param {number} value - value
       * @param {number} parentValue - parent value
       * @memberOf LimitSettingsComponent
   */
    const validateLimit = (value, parentValue) => {
        if (value !== null && value !== "" && parentValue !== null) {
            if (Number(value) > parentValue) {
                return Promise.reject(t('backoffice.validation.mustBeLess').replace("%X%", parentValue))
            } else {
                return Promise.resolve()
            }
        }

        return Promise.resolve()
    }

    const validateMinMax = (value, comparentValue, method) => {
        if (
            value          === "" || value          === null || value          === undefined ||
            comparentValue === "" || comparentValue === null || comparentValue === undefined
        ) {
            return Promise.resolve();
        }

        const { result, error } = compareNumbers(Number(value), Number(comparentValue), method);

        if (!result) {
            return Promise.reject(t(error).replace("%X%", comparentValue));
        }

        return Promise.resolve();
    }

    /** Function to validate deposit/withdraw amount depend on max agent balance
       * @function
       * @param {number} deposittWithdrawalAmount - deposit/withdraw amount
       * @param {number} balance - max agent balance
       * @memberOf LimitSettingsComponent
   */
    const getDepositWithdrawalValidationAsync = ({ deposittWithdrawalAmount, balance, fieldName }) => {
        const deposittWithdrawalAmountNum = Number(deposittWithdrawalAmount);
        const balanceNum = Number(balance);

        if (balance === "" || balance === null) {
            return Promise.resolve();
        }

        if (deposittWithdrawalAmount === "" || deposittWithdrawalAmount === null || balanceNum < deposittWithdrawalAmountNum) {
            return Promise.reject(
                t('backoffice.validation.mustBeLess')
                    .replace("%X%",
                        isFranchisingMode() ? t('backoffice.projects.maxBetshopOwnerBalance') :
                        !walletTypeIsCreditLine ? t('backoffice.projects.maxAgentBalance') :
                        fieldName === "maxEposDepositLimit" ? t('backoffice.projects.maxPositiveCreditLine') : t('backoffice.projects.maxNegativeCreditLine')
                    )
                );
        }

        return Promise.resolve();
    }

    const onFormValuesChange = (changed, formValues) => {
        const formVlauesObj = {
            maxNegativeCreditLine: formValues.maxNegativeCreditLine,
        };
        const initialValuesObj = {
            maxNegativeCreditLine: limitSettings?.maxNegativeCreditLine ?? null,
        };

        if (isCurrentProjectAgentSystem(allCompanies, globalCompanyId, globalProjectId)) {
            formVlauesObj.maxPositiveCreditLine = formValues.maxPositiveCreditLine;

            initialValuesObj.maxPositiveCreditLine = limitSettings?.maxPositiveCreditLine ?? null;

            if (!isFranchisingMode()) {
                formVlauesObj.maxPositiveCreditLineSubTree = formValues.maxPositiveCreditLineSubTree;
                formVlauesObj.maxNegativeCreditLineSubTree = formValues.maxNegativeCreditLineSubTree;
                formVlauesObj.maxAgentBalance = formValues.maxAgentBalance;
                formVlauesObj.maxAgentBalanceSubTree = formValues.maxAgentBalanceSubTree;

                initialValuesObj.maxPositiveCreditLineSubTree = limitSettings?.maxPositiveCreditLineSubTree ?? null;
                initialValuesObj.maxNegativeCreditLineSubTree = limitSettings?.maxNegativeCreditLineSubTree ?? null;
                initialValuesObj.maxAgentBalance = limitSettings?.maxAgentBalance ?? null;
                initialValuesObj.maxAgentBalanceSubTree = limitSettings?.maxAgentBalanceSubTree ?? null;
            }
        }

        if (paymentSettings.includes(PAYMENT_METHODS.BANK_TRANSFER)) {
            formVlauesObj.maxBankDepositLimit = formValues.maxBankDepositLimit;
            formVlauesObj.maxBankWithdrawalLimit = formValues.maxBankWithdrawalLimit;

            initialValuesObj.maxBankDepositLimit = limitSettings?.maxBankDepositLimit ?? null;
            initialValuesObj.maxBankWithdrawalLimit = limitSettings?.maxBankWithdrawalLimit ?? null;
        }

        if (paymentSettings.includes(PAYMENT_METHODS.EPOS)) {
            formVlauesObj.maxEposDepositLimit = formValues.maxEposDepositLimit;
            formVlauesObj.maxEposWithdrawalLimit = formValues.maxEposWithdrawalLimit;

            initialValuesObj.maxEposDepositLimit = limitSettings?.maxEposDepositLimit ?? null;
            initialValuesObj.maxEposWithdrawalLimit = limitSettings?.maxEposWithdrawalLimit ?? null;
        }

        if (paymentSettings.includes(PAYMENT_METHODS.STANDART)) {
            formVlauesObj.minPlayerDepositLimit = formValues?.minPlayerDepositLimit;
            formVlauesObj.maxPlayerDepositLimit = formValues?.maxPlayerDepositLimit;
            formVlauesObj.minPlayerWithdrawalLimit = formValues?.minPlayerWithdrawalLimit;
            formVlauesObj.maxPlayerWithdrawalLimit = formValues?.maxPlayerWithdrawalLimit;
            formVlauesObj.playerDailyDepositLimit = formValues?.playerDailyDepositLimit;
            formVlauesObj.playerDailyWithdrawalLimit = formValues?.playerDailyWithdrawalLimit;

            initialValuesObj.minPlayerDepositLimit = limitSettings?.minPlayerDepositLimit;
            initialValuesObj.maxPlayerDepositLimit = limitSettings?.maxPlayerDepositLimit;
            initialValuesObj.minPlayerWithdrawalLimit = limitSettings?.minPlayerWithdrawalLimit;
            initialValuesObj.maxPlayerWithdrawalLimit = limitSettings?.maxPlayerWithdrawalLimit;
            initialValuesObj.playerDailyDepositLimit = limitSettings?.playerDailyDepositLimit;
            initialValuesObj.playerDailyWithdrawalLimit = limitSettings?.playerDailyWithdrawalLimit;
        }

        if (isCurrentProjectRetail(allCompanies, globalCompanyId, globalProjectId)) {
            formVlauesObj.maxBetShopBalance = formValues.maxBetShopBalance;
            formVlauesObj.maxBetShopLimit = formValues.maxBetShopLimit;

            initialValuesObj.maxBetShopBalance = limitSettings?.maxBetShopBalance ?? null;
            initialValuesObj.maxBetShopLimit = limitSettings?.maxBetShopLimit ?? null;
        }

        console.log({formVlauesObj, initialValuesObj})

        setIsFormTouched(isFormChanged(formVlauesObj, initialValuesObj));
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])


    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: userCanModify,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            dropdown={
                {
                    value: currency,
                    onChange: value => handleCurrencyChange(value, isFormTouched, setIsFormTouched),
                    items: Object.keys(availableCurrencies).map(item => ({
                        key: item.toLowerCase(),
                        value: item.toUpperCase()
                    })),
                    small: true,
                    disabled: hasLiteMode
                }
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{}}
                    onValuesChange={onFormValuesChange}
                >

                    <Row className='rt--limits' gutter={[isMobile() ? 0 : 40, 0]} >
                        {
                            isCurrentProjectAgentSystem(allCompanies, globalCompanyId, globalProjectId) && (
                                <Col xs={24} sm={12} xl={6}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            isFranchisingMode() ? t("backoffice.projects.betshopOwners") : t("backoffice.projects.agents")
                                        }
                                    </h4>

                                    {!hasLiteMode && (
                                        <Form.Item
                                            label={t('backoffice.projects.maxPositiveCreditLine')}
                                            name="maxPositiveCreditLine"
                                            dependencies={walletTypeIsCreditLine ? ["maxEposDepositLimit"] : []}
                                            rules={[
                                                {pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid')},

                                                ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        const fields = walletTypeIsCreditLine ? ["maxEposDepositLimit"] : []
                                                        for (const fieldName of fields) {
                                                            const limitNum = Number(getFieldValue(fieldName));
                                                            const valueNum = Number(value);

                                                            if (value === "" || value === null) {
                                                                continue;
                                                            }

                                                            if (getFieldValue(fieldName) === "" || getFieldValue(fieldName) === null || limitNum > valueNum) {
                                                                if (walletTypeIsCreditLine) {
                                                                    return Promise.reject(t('backoffice.validation.creditMustBeMoreOrEqualToMaxDeposit'))
                                                                } else {
                                                                    return Promise.reject(t('backoffice.validation.balanceMustBeMoreOrEqual'))
                                                                }
                                                            }

                                                        }

                                                        return validateLimit(value, limitSettings.parentMaxPositiveCreditLine);
                                                    }
                                                })
                                            ]}
                                            className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxPositiveCreditLine')}`}
                                        >
                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxPositiveCreditLine')}`}
                                                disabled={!userCanModify}
                                            />
                                        </Form.Item>
                                    )}

                                    {
                                        (!isFranchisingMode() && !hasLiteMode) && (
                                            <Form.Item
                                                label={t('backoffice.projects.maxPositiveCreditLineSubTree')}
                                                name="maxPositiveCreditLineSubTree"
                                                rules={[
                                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            return validateLimit(value, limitSettings.parentMaxPositiveCreditLineSubTree);
                                                        }
                                                    })
                                                ]}
                                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxPositiveCreditLineSubTree')}`}
                                            >
                                                <NumericInput
                                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxPositiveCreditLineSubTree')}`}
                                                    disabled={!userCanModify}
                                                />
                                            </Form.Item>
                                        )
                                    }

                                    {!hasLiteMode && (
                                        <Form.Item
                                            label={t('backoffice.projects.maxNegativeCreditLine')}
                                            name="maxNegativeCreditLine"
                                            dependencies={walletTypeIsCreditLine ? ["maxEposWithdrawalLimit"] : []}
                                            rules={[
                                                {pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid')},
                                                ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        const fields = walletTypeIsCreditLine ? ["maxEposWithdrawalLimit"] : []
                                                        for (const fieldName of fields) {
                                                            const limitNum = Number(getFieldValue(fieldName));
                                                            const valueNum = Number(value);

                                                            if (value === "" || value === null) {
                                                                continue;
                                                            }

                                                            if (getFieldValue(fieldName) === "" || getFieldValue(fieldName) === null || limitNum > valueNum) {
                                                                if (walletTypeIsCreditLine) {
                                                                    return Promise.reject(t('backoffice.validation.creditMustBeMoreOrEqualToMaxWithdrawal'))
                                                                } else {
                                                                    return Promise.reject(t('backoffice.validation.balanceMustBeMoreOrEqual'))
                                                                }
                                                            }

                                                        }
                                                        return validateLimit(value, limitSettings.parentMaxNegativeCreditLine);
                                                    }
                                                })
                                            ]}
                                            className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxNegativeCreditLine')}`}
                                        >
                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxNegativeCreditLine')}`}
                                                disabled={!userCanModify}
                                            />
                                        </Form.Item>
                                    )}

                                    {
                                        (!isFranchisingMode() && !hasLiteMode) && (
                                            <Form.Item
                                                label={t('backoffice.projects.maxNegativeCreditLineSubTree')}
                                                name="maxNegativeCreditLineSubTree"
                                                rules={[
                                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            return validateLimit(value, limitSettings.parentMaxNegativeCreditLineSubTree);
                                                        }
                                                    })
                                                ]}
                                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxNegativeCreditLineSubTree')}`}
                                            >
                                                <NumericInput
                                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxNegativeCreditLineSubTree')}`}
                                                    disabled={!userCanModify}
                                                />
                                            </Form.Item>
                                        )
                                    }

                                    {
                                        !isFranchisingMode() && (
                                            <Form.Item
                                                label={isFranchisingMode() ? t('backoffice.projects.maxBetshopOwnerBalance') : t('backoffice.projects.maxAgentBalance')}
                                                name="maxAgentBalance"
                                                dependencies={
                                                    ["maxBankDepositLimit", "maxBankWithdrawalLimit"].concat(!walletTypeIsCreditLine ? ["maxEposDepositLimit", "maxEposWithdrawalLimit"] : [])
                                                }
                                                rules={[
                                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            const fields = ["maxBankDepositLimit", "maxBankWithdrawalLimit"].concat(!walletTypeIsCreditLine ? ["maxEposDepositLimit", "maxEposWithdrawalLimit"] : [])
                                                            for (const fieldName of fields) {
                                                                const limitNum = Number(getFieldValue(fieldName));
                                                                const valueNum = Number(value);

                                                                if (value === "" || value === null) {
                                                                    continue;
                                                                }

                                                                if (getFieldValue(fieldName) === "" || getFieldValue(fieldName) === null || limitNum > valueNum) {
                                                                    return Promise.reject(t('backoffice.validation.balanceMustBeMoreOrEqual'))
                                                                }

                                                            }

                                                            return validateLimit(value, limitSettings.parentMaxAgentBalance);
                                                        }
                                                    })
                                                ]}
                                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                                data-placeholder={`${t('backoffice.common.enter')} ${isFranchisingMode() ? t('backoffice.projects.maxBetshopOwnerBalance') : t('backoffice.projects.maxAgentBalance')}`}
                                            >
                                                <NumericInput
                                                    placeholder={`${t('backoffice.common.enter')} ${isFranchisingMode() ? t('backoffice.projects.maxBetshopOwnerBalance') : t('backoffice.projects.maxAgentBalance')}`}
                                                    disabled={!userCanModify}
                                                />
                                            </Form.Item>
                                        )
                                    }


                                    {
                                        !isFranchisingMode() && (
                                            <Form.Item
                                                label={t('backoffice.projects.maxAgentBalanceSubTree')}
                                                name="maxAgentBalanceSubTree"
                                                rules={[
                                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            return validateLimit(value, limitSettings.parentMaxAgentBalanceSubTree);
                                                        }
                                                    })
                                                ]}
                                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxAgentBalanceSubTree')}`}
                                            >
                                                <NumericInput
                                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxAgentBalanceSubTree')}`}
                                                    disabled={!userCanModify}
                                                />
                                            </Form.Item>
                                        )
                                    }
                                </Col>
                            )
                        }

                        {
                            paymentSettings.includes(PAYMENT_METHODS.STANDART) && (
                                <Col xs={24} sm={12} xl={6}>
                                    <h4 className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {t('backoffice.projects.playerTransaction')}
                                    </h4>

                                    <Form.Item
                                        label={t('backoffice.projects.minDepositAmount')}
                                        name="minPlayerDepositLimit"
                                        dependencies={["maxPlayerDepositLimit"]}
                                        rules={[
                                            {pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid')},

                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    return validateMinMax(value, getFieldValue("maxPlayerDepositLimit"), COMPARISON_TYPE.LESS_OR_EQUAL);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.minDepositAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.minDepositAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.projects.maxDepositAmount')}
                                        name="maxPlayerDepositLimit"
                                        dependencies={["minPlayerDepositLimit"]}
                                        rules={[
                                            {pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid')},

                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    return validateMinMax(value, getFieldValue("minPlayerDepositLimit"), COMPARISON_TYPE.GREATER_OR_EQUAL);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxDepositAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxDepositAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.projects.minWithdrawalAmount')}
                                        name="minPlayerWithdrawalLimit"
                                        dependencies={["maxPlayerWithdrawalLimit"]}
                                        rules={[
                                            {pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid')},

                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    return validateMinMax(value, getFieldValue("maxPlayerWithdrawalLimit"), COMPARISON_TYPE.LESS_OR_EQUAL);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.minWithdrawalAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.minWithdrawalAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.projects.maxWithdrawalAmount')}
                                        name="maxPlayerWithdrawalLimit"
                                        dependencies={["minPlayerWithdrawalLimit"]}
                                        rules={[
                                            {pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid')},

                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    return validateMinMax(value, getFieldValue("minPlayerWithdrawalLimit"), COMPARISON_TYPE.GREATER_OR_EQUAL);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxWithdrawalAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxWithdrawalAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.projects.dailyDepositAmount')}
                                        name="playerDailyDepositLimit"
                                        rules={[
                                            {pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid')}
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.dailyDepositAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.dailyDepositAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.projects.dailyWithdrawalAmount')}
                                        name="playerDailyWithdrawalLimit"
                                        rules={[
                                            {pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid')}
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.dailyWithdrawalAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.dailyWithdrawalAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }

                        {
                            isCurrentProjectRetail(allCompanies, globalCompanyId, globalProjectId) && (
                                <Col xs={24} sm={12} xl={6}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                    {
                                            t("backoffice.projects.betshops")
                                        }
                                    </h4>

                                    <Form.Item
                                        label={t('backoffice.projects.maxBetShopBalance')}
                                        name="maxBetShopBalance"
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxBetShopBalance);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxBetShopBalance')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxBetShopBalance')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.projects.maxBetShopLimit')}
                                        name="maxBetShopLimit"
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxBetShopLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxBetShopLimit')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxBetShopLimit')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }

                        {
                            paymentSettings.includes(PAYMENT_METHODS.BANK_TRANSFER) && (
                                <Col xs={24} sm={12} xl={6}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.projects.bankTransfer")
                                        }
                                    </h4>

                                    <Form.Item
                                        label={t('backoffice.projects.maxDepositAmount')}
                                        name="maxBankDepositLimit"
                                        dependencies={["maxAgentBalance"]}
                                        validateFirst={true}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return getDepositWithdrawalValidationAsync({
                                                        deposittWithdrawalAmount: value,
                                                        balance: getFieldValue("maxAgentBalance"),
                                                        fieldName: "maxBankDepositLimit"
                                                    });
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxBankDepositLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxDepositAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxDepositAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.projects.maxWithdrawalAmount')}
                                        name="maxBankWithdrawalLimit"
                                        dependencies={["maxAgentBalance"]}
                                        validateFirst={true}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return getDepositWithdrawalValidationAsync({
                                                        deposittWithdrawalAmount: value,
                                                        balance: getFieldValue("maxAgentBalance"),
                                                        fieldName: "maxBankWithdrawalLimit"
                                                    });
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxBankWithdrawalLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxWithdrawalAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxWithdrawalAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }
                        {
                            paymentSettings.includes(PAYMENT_METHODS.EPOS) && (
                                <Col xs={24} sm={12} xl={6}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.projects.epos")
                                        }
                                    </h4>

                                    <Form.Item
                                        label={t('backoffice.projects.maxDepositAmount')}
                                        name="maxEposDepositLimit"
                                        dependencies={[walletTypeIsCreditLine ? "maxPositiveCreditLine" : "maxAgentBalance"]}
                                        validateFirst={true}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return getDepositWithdrawalValidationAsync({
                                                        deposittWithdrawalAmount: value,
                                                        balance: getFieldValue(walletTypeIsCreditLine? "maxPositiveCreditLine" : "maxAgentBalance"),
                                                        fieldName: "maxEposDepositLimit"
                                                    });
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxEposDepositLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxDepositAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxDepositAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('backoffice.projects.maxWithdrawalAmount')}
                                        name="maxEposWithdrawalLimit"
                                        dependencies={[walletTypeIsCreditLine ? "maxNegativeCreditLine" : "maxAgentBalance"]}
                                        validateFirst={true}
                                        rules={[
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return getDepositWithdrawalValidationAsync({
                                                        deposittWithdrawalAmount: value,
                                                        balance: getFieldValue(walletTypeIsCreditLine? "maxNegativeCreditLine" : "maxAgentBalance"),
                                                        fieldName: "maxEposWithdrawalLimit"
                                                    });
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    return validateLimit(value, limitSettings.parentMaxEposWithdrawalLimit);
                                                }
                                            })
                                        ]}
                                        className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxWithdrawalAmount')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.maxWithdrawalAmount')}`}
                                            disabled={!userCanModify}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }
                    </Row>

                </Form>

            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** LimitsComponent propTypes
    * PropTypes
*/
LimitsComponent.propTypes = {
    /** Redux state property, is true when limit settings is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when limit settings is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the limit settings of current editing projects  */
    limitSettings: limitSettingsType,
    /** Redux action to save project limit settings */
    saveProjectLimitSettings: PropTypes.func,
    /** Redux action to get project limit settings */
    getProjectLimitSettings: PropTypes.func,
    /** Redux state property, represents the object of available currencies of company */
    availableCurrencies: PropTypes.object,
    /** Redux state property, represents the array of global companies  */
    allCompanies: PropTypes.arrayOf(companyType),
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        saveProjectLimitSettings: data => {
            dispatch(saveProjectLimitSettings(data));
        },

        getProjectLimitSettings: (id, currencyCode) => {
            dispatch(getProjectLimitSettings(id, currencyCode))
        }
    }
)

const mapStateToProps = state => {
    return {
        limitSettings: state.projects.edit.limitSettings,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        allCompanies: state.profile.userInfo.companies,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LimitsComponent)
