import PropTypes from 'prop-types';

import { ALL } from 'constants/common.constants';

export default {
    /** Array of columns of table which can be excluded */
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        key: PropTypes.string
    })),
    /** Function which will fire on apply button click */
    onApply: PropTypes.func,
    /** Default Selected columns */
    defaultSelectedColumns: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.oneOf([ALL])
    ])
}