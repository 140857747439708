import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Spin } from 'antd';

import AvatarUpload from 'components/common/avatarUpload';
import FormId from "components/common/formId";
import NumericInput from 'components/common/numericInput';
import Input from 'components/common/input';

import TabFormDashboardLayout from "components/layouts/tab/form";

import { getPersonalInformation, savePersonalInformation } from 'store/actions/dashboard/profile/personalInformation.action';
import { updateUserAvatar } from 'store/actions/dashboard/profile/userInfo.action';

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { EMAIL_REGEX, TEL_REGEX } from "constants/regex.constants";
import { USER_TYPE } from "constants/user.constants";

import personalInformationType from 'types/profile/personalInformation.type';


/** User Profile Page Personal Information Component */
const PersonalInformationComponent = ({
    getPersonalInformation,
    savePersonalInformation,
    isSaving,
    isLoading,
    personalInformation,
    updateUserAvatar,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Load user profile personal information */
    useEffect(() => {
        getPersonalInformation()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            firstName: personalInformation.firstName,
            lastName: personalInformation.lastName,
            address: personalInformation.address,
            phoneNumber: personalInformation.phoneNumber,
            email: personalInformation.email
        });

    }, [personalInformation])

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Fires when form submitted
       * @function
       * @memberOf PersonalInformationComponent
   */
    const handleForm = forceSave => {
        validateFields()
            .then(data => {
                savePersonalInformation(data);
                setIsFormTouched(false)
            }).catch(err => {
                console.log(err)
            })
    }

    const name = (
        personalInformation.firstName && personalInformation.lastName
            ? `${personalInformation.firstName} ${personalInformation.lastName}`
            : personalInformation.firstName
                ? personalInformation.firstName
                : personalInformation.lastName
                    ? personalInformation.lastName
                    : ''
    );

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: personalInformation.allowEdit,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, {
                        firstName: personalInformation.firstName,
                        lastName: personalInformation.lastName,
                        phoneNumber: personalInformation.phoneNumber,
                        address: personalInformation.address,
                        email: personalInformation.email
                    }))}
                >


                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} xl={8} >
                            <AvatarUpload
                                userId={personalInformation.id}
                                userLongId={personalInformation.longId}
                                name={name}
                                avatarId={personalInformation.avatarId}
                                canEdit={personalInformation.allowEdit}
                                onSuccess={updateUserAvatar}
                                isProfile={true}
                            />
                        </Col>

                        <Col xs={24} sm={24} xl={16} >
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'
                            >
                                {
                                    t('backoffice.profile.personalInformation')
                                }
                            </h4>

                            <Row gutter={[16, 0]}>
                                <Col span={24} >
                                    <div className='rt--mb-16 rt--flex rt--align-center'>
                                        {
                                            hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }) && (
                                                <FormId
                                                    id={personalInformation.id}
                                                    text={t("backoffice.common.id")}
                                                />
                                            )
                                        }
                                        {
                                            personalInformation.longId && (
                                                <div className='rt--ml-16'><FormId id={personalInformation.longId} text={t("backoffice.common.longId")} /></div>
                                            )
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 0]}>
                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={t('backoffice.users.username')}
                                        className='rt--form-item-disabled'
                                    >
                                        <Input
                                            value={personalInformation.userName}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        name="email"
                                        label={t('backoffice.users.email')}
                                        className={'rt--general-form-item' + (!personalInformation.allowEdit ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.email')}`}
                                        rules={[
                                            { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                            { pattern: EMAIL_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        validateFirst={true}
                                    >
                                        <Input
                                            maxLength={50}
                                            disabled={!personalInformation.allowEdit}
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.email')}`}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={`${t('backoffice.users.firstName')} *`}
                                        name="firstName"
                                        rules={[
                                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                            { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                            { min: 2, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        validateFirst={true}
                                        className={'rt--general-form-item' + (!personalInformation.allowEdit ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.firstName')}`}
                                    >
                                        <Input
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.firstName')}`}
                                            maxLength={50}
                                            disabled={!personalInformation.allowEdit}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={`${t('backoffice.users.lastName')} *`}
                                        name="lastName"
                                        rules={[
                                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                            { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                            { min: 2, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        validateFirst={true}
                                        className={'rt--general-form-item' + (!personalInformation.allowEdit ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.lastName')}`}
                                    >
                                        <Input
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.lastName')}`}
                                            maxLength={50}
                                            disabled={!personalInformation.allowEdit}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={t('backoffice.users.tel')}
                                        name="phoneNumber"
                                        rules={[
                                            { pattern: TEL_REGEX, message: t('backoffice.validation.telFormat') },
                                            { max: 18, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        className={'rt--general-form-item' + (!personalInformation.allowEdit ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.tel')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.tel')}`}
                                            maxLength={18}
                                            disabled={!personalInformation.allowEdit}
                                            isMobileNumber={true}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={14} xl={12} >
                                    <Form.Item
                                        label={t('backoffice.users.address')}
                                        name="address"
                                        rules={[
                                            { max: 100, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        className={'rt--general-form-item' + (!personalInformation.allowEdit ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.address')}`}
                                    >
                                        <Input
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.address')}`}
                                            maxLength={100}
                                            disabled={!personalInformation.allowEdit}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {
                                personalInformation.role === USER_TYPE.ADMIN &&
                                (
                                    <Row gutter={[16, 0]}>
                                        <Col span={24}>
                                            <h1 className="dashboard-section-name">{t('backoffice.profile.companies')}</h1>
                                            {
                                                personalInformation.companies && personalInformation.companies.length > 0 ?
                                                    (
                                                        <div className='rt--mt-16 rt--mb-32'>
                                                            {
                                                                personalInformation.companies.map(p => (
                                                                    <div key={p} className='rt--tag rt--tag-info rt--flex rt--justify-between rt--align-center rt--mb-8 rt--mr-8'>
                                                                        <span className='rt--title rt--font-normal rt--font-bold'>{p}</span>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className='rt--mb-40'>
                                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.profile.noCompany')}</span>
                                                        </div>
                                                    )


                                            }
                                        </Col>
                                    </Row>
                                )
                            }

                        </Col>

                    </Row>


                </Form>

            </Spin>
        </TabFormDashboardLayout>
    )
}

/** PersonalInformationComponent propTypes
    * PropTypes
*/
PersonalInformationComponent.propTypes = {
    /** Redux action to get profile personal Information */
    getPersonalInformation: PropTypes.func,
    /** Redux action to save profile personal Information */
    savePersonalInformation: PropTypes.func,
    /** Redux action to update profile personal Information avatar */
    updateUserAvatar: PropTypes.func,
    /** Redux state property, is true when profile personal Information is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when profile personal Information is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the user profile personal Information  */
    personalInformation: personalInformationType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getPersonalInformation: () => {
            dispatch(getPersonalInformation());
        },

        savePersonalInformation: data => {
            dispatch(savePersonalInformation(data));
        },

        updateUserAvatar: avatarId => {
            dispatch(updateUserAvatar(avatarId))
        }
    }
)

const mapStateToProps = state => {
    return {
        personalInformation: state.profile.personalInformation,
        isSaving: state.profile.isSaving,
        isLoading: state.profile.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationComponent)
