import PropTypes from 'prop-types';

import { WIDGET_TRANSACTION_TYPE } from 'constants/project.constants';

export default PropTypes.shape({
    agentUserName: PropTypes.string,
    amount: PropTypes.number,
    bankId: PropTypes.string,
    createdAt: PropTypes.string,
    currencyCode: PropTypes.string,
    playerId: PropTypes.string,
    playerUserName: PropTypes.string,
    transactionId: PropTypes.number,
    transactionType: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE)),
    userInfo: PropTypes.object,
    isEditable: PropTypes.bool
})