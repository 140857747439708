export const WALLET_OPERATION_TYPE = {
    BET: 1,
    CANCEL_BET: 2,
    PAIDOUT: 3,
    WON: 4,
    ROLLBACK: 5,
    REJECT_BET: 6,
    RECALCULATE: 7,

    // Agent - Agent
    AGENT_DEPOSIT_FROM_BALANCE: 11,
    AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT: 12,
    AGENT_DEPOSIT_FROM_CREDIT: 13,
    AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT: 14,

    AGENT_WITHDRAWAL_FROM_BALANCE: 15,
    AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT: 16,
    AGENT_WITHDRAWAL_FROM_CREDIT: 17,
    AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT: 18,

    // SAgent, Agent - Agent
    AGENT_GIVE_CREDIT: 21,
    AGENT_DEPOSIT_TO_CREDIT_LINE: 22, // For only non related wallet
    AGENT_WITHDRAW_FROM_CREDIT_LINE: 23, // For non related wallet

    // SAgent, Agent - Agent
    AGENT_DEBT_PAYOUT_BY_CASH: 31,
    AGENT_DEBT_PAYOUT_NON_CASH: 32,
    AGENT_DEBT_PAYOUT_FROM_BALANCE: 33,

    // Agent - Player
    PLAYER_DEPOSIT_FROM_BALANCE: 41,
    PLAYER_DEPOSIT_WITH_DEBT: 42,
    PLAYER_WITHDRAWAL_TO_BALANCE: 43,
    PLAYER_WITHDRAWAL_TO_DEBT: 44,

    // Agent - Bet shop
    AGENT_BETSHOP_DEPOSIT: 51,
    AGENT_BETSHOP_WITHDRAWAL: 52,

    // Cashier - Player
    PLAYER_DEPOSIT_FROM_BETSHOP: 61,
    PLAYER_WITHDRAWAL_FROM_BETSHOP: 62,

    //Bet shop manager - Cashier
    BETSHOP_MANAGER_DEPOSIT_CASHIER: 71,
    BETSHOP_MANAGER_WITHDRAWAL_CASHIER: 72,

    // SAgent - SAgent
    SUPER_AGENT_BALANCE_DEPOSIT_AGENT: 81,
    SUPER_AGENT_BALANCE_WITHDRAW_AGENT: 82,

    // SAgent - Agent
    SUPER_AGENT_PAY_DEBT_AGENT_BY_CASH: 91,
    SUPER_AGENT_PAY_DEBT_AGENT: 92,

    // Cashier - Cashier
    BETSHOP_CASHIER_HANDOVER_CASHIER: 101,

    // Bet shop manager - Bet shop (Limit)
    BETSHOP_LIMIT_AUTO_ADJUSTMENT: 111,
    BETSHOP_LIMIT_MANUAL_ADJUSTMENT: 112,

    // SAgent - Bet shop (Retail)
    SUPER_AGENT_DEPOSIT_BETSHOP: 121,
    SUPER_AGENT_WITHDRAWAL_BETSHOP: 122,

    // System - Agent
    AGENT_COMISSION_CALCULATION: 131,
    AGENT_COMISSION_RECALCULATION: 132
}

export const MAX_WALLET_ADJUSTMENT_VALUE = 1000000;

export const MIN_WALLET_ADJUSTMENT_VALUE = 1;

export const WALLET_TYPE = {
    BALANCE: 1 << 0,
    CREDIT_LINE: 1 << 1,
    CREDIT: 1 << 2,
    LIMIT: 1 << 3
}

export const WALLET_OWNER_TYPE = {
    AGENT: 1 << 0,
    BETSHOP: 1 << 1,
    CASHIER: 1 << 2,
    SUPER_AGENT: 1 << 3,
    AGENT_PLAYER: 1 << 4,
    PLAYER: 1 << 5
}

export const WALLET_TRANSACTION_TYPE = {
    AGENT_DEPOSIT: 1,
    AGENT_WITHDRAWAL: 2,
    AGENT_CREDIT: 3,
    AGENT_PAY_DEBT: 4,
    PLAYER_DEPOSIT: 5,
    PLAYER_WITHDRAWAL: 6,
    BETSHOP_DEPOSIT: 7,
    BETSHOP_WITHDRAWAL: 8,
    ADJUST_BALANCE: 9,
    BETSHOP_LIMIT_ADJUSTMENT: 10,
    BETSHOP_LIMIT_RESET: 11,
    ROLLBACK: 12,
    CONFIRM_TRANSACTION_REQUEST: 13,
    REJECT_TRANSACTION_REQUEST: 14, 
    ADJUST_TRANSACTION_REQUEST: 15 
}