export const isUnCheckedAll = ({
   resource,
   action,
   fieldName,
   getFieldsValue,
   getFieldValue,
}) => {
    const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };
    let isChecked = false;
    if(resource && !values[resource]) return true;
    const resources = resource ? [resource] : Object.keys(values);
    resources.forEach(r => {
        if (isChecked) {
            return;
        }
        for (let i = 0; i < Object.keys(values[r]).length; i++) {
            let actions = values[r][Object.keys(values[r])[i]];
            if (action) {
                if (actions[action] === true) {
                    isChecked = true;
                }
            } else {
                Object.keys(actions).forEach(a => {
                    if (actions[a] === true) {
                        isChecked = true;
                    }
                })
            }
            if (isChecked) break;
        }
    })

    return !isChecked
}
