//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region actions
import {
    getPlayerPerformanceReport,
    getPlayerPerformanceReportTotals,
    setPlayerPerformanceReportFilters,
    setPlayerPerformanceReportSorting
} from 'store/actions/dashboard/reports/playerPerformanceReport.action';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from 'pages/reports/performanceReport/sections/player/filters.components';
import TotalsComponent from 'components/common/totals';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
import useDate from 'hooks/useDate';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isMobile } from 'utils/common';
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';
import { PLAYER_PERFORMANCE_REPORT } from 'constants/pageName.constants';
import { getTableColumns } from 'pages/reports/performanceReport/sections/player/columns';
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import playerPerformanceReportType from "types/reports/playerPerformanceReport.type";
import playerPerformanceReportTotalsType from "types/reports/playerPerformanceReportTotals.type";
import useProjectType from "hooks/useProjectType";
//#endregion

/** Player Performance Report Page Component */
const PlayerPerformanceReport = ({
    globalProjectId,
    isLoading,
    report,
    total,
    sorting,
    filters,
    getPlayerPerformanceReport,
    setPlayerPerformanceReportFilters,
    setPlayerPerformanceReportSorting,
    getPlayerPerformanceReportTotals,
    totals,
    isTotalsLoading,
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const { dateService } = useDate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PLAYER_PERFORMANCE_REPORT });

    const { hasLiteMode } = useProjectType();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_PLAYER_PERFORMANCE,
        action: PERMISSION_ACTION.EXPORT
    })

    const hasTotalsPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_PLAYER_PERFORMANCE_TOTALS,
        action: PERMISSION_ACTION.VIEW
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setPlayerPerformanceReportFilters({
            ...filters,
            userNameOrId: value ? item ? item.id : value : ""
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                formatAmount,
                dateService,
                hasLiteMode
            }
        });
    }, [includedColumns, dateService, formatAmount])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: (
            <Filters />
        ),
        search: {
            placeholder: t("backoffice.performancereport.searchByPlayerUsernameOrId"),
            loadFn: getPlayerPerformanceReport,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PLAYER,
                dependencies: [globalProjectId],
                autoGet: false,
                searchFieldName: "userNameOrId"
            },
            onSearch: handleSearchChange,
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.playerPerformanceReport"),
            url: ApiUrls.EXPORT_PLAYER_PERFORMANCE_REPORT,
            filters: filters,
            columns: columnsForExport
        }
    }

    //#endregion


    const tableData = useMemo(() => {
        return report.map(r => ({
            ...r,
            uniqueKey: r.id + "_" + r.agentId
        }))
    }, [report])

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobile() && hasTotalsPermission}
            extraContent={hasTotalsPermission ? (
                    <TotalsComponent
                        getTotals={getPlayerPerformanceReportTotals}
                        totals={totals}
                        dependencies={[globalProjectId, filters]}
                        isLoading={isTotalsLoading}
                        currencyCode={filters.currencies}
                        totalType={REPORT_TOTAL_TYPE.PLAYER_PERFORMANCE_REPORT}
                    />
                ) : null
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={tableData}
                loadFn={getPlayerPerformanceReport}
                updateProps={[globalProjectId]}
                sorting={sorting}
                total={total}
                enableReload={true}
                setSortingFn={setPlayerPerformanceReportSorting}
                filters={filters}
                setFiltersFn={setPlayerPerformanceReportFilters}
                uniqueKey="uniqueKey"
            />
        </TabTableDashboardLayout>
    );
}

/** PlayerPerformanceReport propTypes
 * PropTypes
*/
PlayerPerformanceReport.propTypes = {
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to get player performance report */
    getPlayerPerformanceReport: PropTypes.func,
    /** Redux state property, is true when loading player performance report */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when loading player performance report totals */
    isTotalsLoading: PropTypes.bool,
    /** Redux state property, represents the player performance report  */
    report: PropTypes.arrayOf(playerPerformanceReportType),
    /** Redux state property, player performance report total count */
    total: PropTypes.number,
    /** Redux state property, player performance report sorting details */
    sorting: sortingType,
    /** Redux state property, player performance report filters */
    filters: PropTypes.object,
    /** Redux action to set player performance report filters */
    setPlayerPerformanceReportFilters: PropTypes.func,
    /** Redux action to set player performance report sorting details */
    setPlayerPerformanceReportSorting: PropTypes.func,
    /** Redux action to get player performance report totals */
    getPlayerPerformanceReportTotals: PropTypes.func,
    /** Redux state property, represents the player performance report totals */
    totals: playerPerformanceReportTotalsType
};

const mapDispatchToProps = dispatch => ({
    getPlayerPerformanceReport: nextPage => {
        dispatch(getPlayerPerformanceReport(nextPage));
    },
    setPlayerPerformanceReportFilters: (filters, keepPage) => {
        dispatch(setPlayerPerformanceReportFilters(filters, keepPage));
    },
    setPlayerPerformanceReportSorting: sorting => {
        dispatch(setPlayerPerformanceReportSorting(sorting));
    },
    getPlayerPerformanceReportTotals: () => {
        dispatch(getPlayerPerformanceReportTotals());
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.playerPerformanceReport.isLoading,
        isTotalsLoading: state.playerPerformanceReport.isTotalsLoading,
        report: state.playerPerformanceReport.report,
        total: state.playerPerformanceReport.total,
        sorting: state.playerPerformanceReport.sorting,
        filters: state.playerPerformanceReport.filters,
        globalProjectId: state.common.globalProjectId,
        totals: state.playerPerformanceReport.totals
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPerformanceReport);
