//#region react
import React, { memo, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
//#endregion

//#region components
import LineChart from "components/common/lineChart";
import Select from "components/common/select";
//#endregion

//#region hooks
import useOperationalLineChart from "pages/dashboard/hooks/useOperationalLineChart";
import useDate from "hooks/useDate";
//#endregion

//#region utils
import {
    getOperationsLineChartData,
    getOperationsLineChartTooltip
} from "utils/dashboard";
import { constructLineChartConfigs } from "utils/lineChart";
import {
    getCheckPointsTooltipConfigs,
    getMainLineConfigs,
    getXAxisConfigs
} from "utils/lineChart/configCreators";
import { isMobile } from "utils/common";
//#endregion

//#region constants
import { ENTITY_TYPE, TIME_PERIOD } from "pages/dashboard/constants";
//#endregion

const calcTotalCount = (chartData) => {
    return chartData.reduce((acc, data) => acc + data.count, 0);
}

const OperationalLineChart = ({
    filters,
    entityType = ENTITY_TYPE.PLAYER,
    updateLastUpdatedDate,
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const { chartData, isLoading } = useOperationalLineChart(
        filters,
        entityType,
        updateLastUpdatedDate,
    );

    /** Chart Data */
    const configs = useMemo(() => {
        const chart = getOperationsLineChartData(chartData || [], filters.timePeriod, undefined, dateService);

        return constructLineChartConfigs({
            xAxisConfigs: getXAxisConfigs({
                checkPoints: chart.categories,
            }),
            checkPointsTooltipConfigs: getCheckPointsTooltipConfigs({
                tooltipHTMLGenerator: (element) => (
                    getOperationsLineChartTooltip({
                        element,
                        chartData: chart,
                        timePeriod: filters.timePeriod,
                        entityType,
                        dateService
                    })
                )
            }),
            lines: [
                getMainLineConfigs({
                    data: chart.values,
                }),
            ]
        })
    }, [chartData, entityType, dateService]);

    const totalCount = calcTotalCount(chartData);
    const total = totalCount ? `${t('backoffice.dashboard.total')}: `+ totalCount : 0;

    return (
        <LineChart
            configs={configs}
            hideChart={chartData.length === 0}
            isLoading={isLoading}
            total={total}
        />
    )
};

OperationalLineChart.propTypes = {
    filters: PropTypes.object,
    isLoading: PropTypes.bool,
    hasRetail: PropTypes.bool,
    updateLastUpdatedDate: PropTypes.func,
};

export default memo(OperationalLineChart);

