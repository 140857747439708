import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setAgentsSaveActionBefore, setAgentsSaveActionFinished } from './agents.action';
import { getAgentWallets } from "store/actions/dashboard/wallet/agentWallet.action";

import { getUser } from "utils/auth"
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { USER_ROLE } from "constants/user.constants";

import { 
    SET_AGENT_CURRENCIES,
    SET_AGENT_CURRENCIES_ACTION_BEFORE,
    SET_AGENT_CURRENCIES_ACTION_FINISH,
    SET_AGENT_AVAILABLE_CURRENCIES_BEFORE,
    SET_AGENT_AVAILABLE_CURRENCIES_FINISHED,
    SET_AGENT_AVAILABLE_CURRENCIES
} from "../../../../actionTypes";

const setAgentsCurrenciesActionBefore = () => ({
    type: SET_AGENT_CURRENCIES_ACTION_BEFORE,
});

const setAgentsCurrenciesActionFinished = () => ({
    type: SET_AGENT_CURRENCIES_ACTION_FINISH,
});

const setAgentCurrencies = currencies => ({
    type: SET_AGENT_CURRENCIES,
    payload: { currencies }
})

export const getAgentCurrencies = ( id, state )  => {
    return dispatch => {
        dispatch(setAgentsCurrenciesActionBefore());
        return axios({
            url: ApiUrls.GET_AGENT_CURRENCIES,
            method: Methods.GET,
            params: { id, state  }
        })
        .then(({data : {value: currencies}}) => {
            dispatch(setAgentCurrencies(currencies))
            dispatch(setAgentsCurrenciesActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsCurrenciesActionFinished());
        })
    }
}

export const addAgentCurrency = (id, currency, ownerType, onSuccess) => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_AGENT_CURRENCY,
            method: Methods.POST,
            data: {
                id, currency
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAgentCurrencies(id));
                if(hasPermission({ resource: getUser()?.role === USER_ROLE.ADMIN ? PERMISSION_RESOURCE.AGENT_WALLETS : PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW })){
                    dispatch(getAgentWallets(id, ownerType))
                }

                onSuccess && onSuccess();
            }  
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

export const activateAgentCurrency = (id, currency) => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.ACTIVATE_AGENT_CURRENCY,
            method: Methods.POST,
            data: {
                id, currency
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAgentCurrencies(id));
            }  
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

export const deactivateAgentCurrency = (id, currency) => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.DEACTIVATE_AGENT_CURRENCY,
            method: Methods.POST,
            data: {
                id, currency
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAgentCurrencies(id));
            }  
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

const setAgentAvailableCurrenciesBefore = () => ({
    type: SET_AGENT_AVAILABLE_CURRENCIES_BEFORE
})

const setAgentAvailableCurrenciesFinished = () => ({
    type: SET_AGENT_AVAILABLE_CURRENCIES_FINISHED
})

const setAgentAvailableCurrencies = currencies => ({
    type: SET_AGENT_AVAILABLE_CURRENCIES,
    payload: { currencies }
})


export const getAgentAvailableCurrencies = id  => {
    return dispatch => {
        dispatch(setAgentAvailableCurrenciesBefore());
        return axios({
            url: ApiUrls.GET_AGENT_AVAILABLE_CURRENCIES,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: currencies}}) => {
            dispatch(setAgentAvailableCurrencies(currencies))
            dispatch(setAgentAvailableCurrenciesFinished());
        })
        .catch((ex) => {
            dispatch(setAgentAvailableCurrenciesFinished());
        })
    }
}