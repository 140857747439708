import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";

import useDate from 'hooks/useDate';

const LockInfo = ({
    is2FALocked,
    lastLogin,
    lockTime,
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    return (
        <div className="rt--tooltip-locked">
            <div className="rt--flex rt--align-center rt--justify-between rt--pb-14 rt--pt-14">
                <span className="rt--title rt--font-normal rt--font-regular">
                    {t("backoffice.agents.reason")}
                </span>
                <b className="rt--title rt--font-normal rt--font-regular">
                    {
                        is2FALocked
                            ? t("backoffice.agents.lockedForFailed2FA")
                            : t("backoffice.agents.lockedForFailedLogin")
                    }
                </b>
            </div>

            <div className="rt--flex rt--align-center rt--justify-between rt--pb-14 rt--pt-14">
                <span className="rt--title rt--font-normal rt--font-regular">
                    {t("backoffice.agents.lastSuccessfulLogin")}
                </span>
                <b className="rt--title rt--font-normal rt--font-regular">
                    {lastLogin ? dateService.format(lastLogin) : "-"}
                </b>
            </div>

            <div className="rt--flex rt--align-center rt--justify-between rt--pb-14 rt--pt-14">
                <span className="rt--title rt--font-normal rt--font-regular">
                    {t("backoffice.agents.blockedDateTime")}
                </span>
                <b className="rt--title rt--font-normal rt--font-regular">
                    {dateService.format(lockTime ?? "")}
                </b>
            </div>
        </div>
    )
}

/** LockInfo propTypes
 * PropTypes
*/
LockInfo.propTypes = {
    is2FALocked: PropTypes.bool,
    lastLogin: PropTypes.string,
    lockTime: PropTypes.string,
};

export default LockInfo;