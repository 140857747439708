import i18n from "translations/config";

import { ACTION_TYPES } from "constants/failedTransactions.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";
import { PAYMENT_METHODS } from "constants/project.constants";

export const tableColumns = ({formatNumber}) => [
    {
        title: "backoffice.failedtransactions.transactionId",
        dataIndex: "id",
        alwaysVisible: true,
        copy: true,
        mobileLevel: 1
    },
    {
        title: "backoffice.failedtransactions.groupId",
        dataIndex: "groupId",
        copy: true
    },
    {
        title: "backoffice.failedtransactions.transactionDate",
        dataIndex: 'createdAt',
        sorter: true,
        isDateTime: true,
        mobileLevel: 2
    },
    {
        title: 'backoffice.failedtransactions.actionType',
        dataIndex: 'actionType',
        sorter: true,
        render: value => (
            value === ACTION_TYPES.DEPOSIT
                ? i18n.t('backoffice.common.deposit')
                : i18n.t('backoffice.common.withdrawal')
        ),
        mobileLevel: 3
    },
    {
        title: "backoffice.common.status",
        dataIndex: "lastResultCode",
        sorter: true,
        mobileLevel: 4,
        render: value => {
            const st = Object.keys(RESPONSE_CODES).find(key => RESPONSE_CODES[key] === value);
            return i18n.t(`backoffice.failedtransactions.${st}`);
        }
    },
    {
        title: "backoffice.failedtransactions.player",
        dataIndex: "playerUserName",
        sorter: true,
        mobileLevel: 5
    },
    {
        title: "backoffice.failedtransactions.paymentType",
        dataIndex: "paymentType",
        sorter: true,
        mobileLevel: 6,
        render: value => value === PAYMENT_METHODS.STANDART ? i18n.t("backoffice.payments.standart") :
                        value === PAYMENT_METHODS.EPOS ? i18n.t("backoffice.payments.epos") :
                        value === PAYMENT_METHODS.BANK_TRANSFER ? i18n.t("backoffice.payments.bankTransfer") : ""
    },
    {
        title: "backoffice.failedtransactions.lastResendAt",
        dataIndex: "lastResentAt",
        isDateTime: true,
        sorter: true,
    },
    {
        title: "backoffice.failedtransactions.lastResendBy",
        dataIndex: "lastResentBy",
        sorter: true
    },
    {
        title: "backoffice.failedtransactions.count",
        dataIndex: "count"
    },
    {
        title: "backoffice.failedtransactions.amount",
        dataIndex: "amount",
        render: value => formatNumber(value),
    }
];

export const expandColumns = [
    {
        title: 'backoffice.failedtransactions.historyId',
        dataIndex: "historyId",
        copy: true,
    },
    {
        title: 'backoffice.common.date',
        dataIndex: "createdAt",
        isDateTime: true,
    },
    {
        title: 'backoffice.failedtransactions.createdBy',
        dataIndex: "createdBy",
        render: value => value || i18n.t('backoffice.common.system'),
    },
    {
        title: 'backoffice.failedtransactions.message',
        dataIndex: "message",
    },
    {
        title: 'backoffice.common.status',
        dataIndex: "resultCode",
        render: value => {
            const st = Object.keys(RESPONSE_CODES).find(key => RESPONSE_CODES[key] === value);
            return i18n.t(`backoffice.failedtransactions.${st}`);
        }
    },
];
