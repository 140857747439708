import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { Layout as AntLayout, ConfigProvider, Spin, message } from 'antd';

const { Content: AntContent, Header: AntHeader, Sider: AntSider } = AntLayout;

import Sidebar from 'components/common/sidebar';
import Header from 'components/common/header';
import LogoutModal from 'components/common/logoutModal';
import PlayerDepositSearch from 'components/common/playerDepositSearch';

import { TransferProvider, Transfer, TRANSFER_SUCCESS_EVENT_NAME } from 'components/common/transfer';

import { getCurrencies, updateCurrencies } from "store/actions/dashboard/profile/currencies.action";
import { addNotification } from "store/actions/dashboard/notifications/notifications.action";

import { getUser, refreshToken, logout } from 'utils/auth';
import { isMobile } from "utils/common";
import SignalRUtils from 'utils/signalR';
import { initChatIntegration } from 'utils/integration';
import LanguageUtils from 'utils/languages';
import { hasPermission } from 'utils/permissions';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { USER_ROLE, USER_TYPE } from "constants/user.constants";
import { SIGNALR_CONNECTION_TYPES } from 'constants/common.constants';

import userInfoType from 'types/profile/userInfo.type';

import dateService from "utils/date";

import enLang from 'antd/es/locale/en_GB';
import frLang from 'antd/es/locale/fr_FR';
import esLang from 'antd/es/locale/es_ES';
import ruLang from 'antd/es/locale/ru_RU';
import faLang from 'antd/es/locale/fa_IR';
import arLang from 'antd/es/locale/ar_EG';
import koLang from 'antd/es/locale/ko_KR';
import zhLang from 'antd/es/locale/zh_CN';
import hiLang from 'antd/es/locale/hi_IN';
import bnLang from 'antd/es/locale/bn_BD';
import heLang from 'antd/es/locale/he_IL';

const languageCode = LanguageUtils.getSelectedLanguage().toLowerCase();
const languagesMapping = {
    "en": enLang,
    "fr": frLang,
    "es": esLang,
    "ru": ruLang,
    "fa": faLang,
    "ar": arLang,
    "ko": koLang,
    "zh": zhLang,
    "hi": hiLang,
    "bn": bnLang,
    "he": heLang,
}

const mapLocaleToDateServiceLocale = {
    "en": "en",
    "fr": "fr",
    "es": "es",
    "ru": "ru",
    "fa": "fa",
    "ar": "ar",
    "ko": "ko",
    "zh": "zh-cn",
    "hi": "hi",
    "bn": "bn-bd",
    "he": "he"
}

const locale = languagesMapping[languageCode] || enLang;

dateService.setLocale(mapLocaleToDateServiceLocale[languageCode])

let interval = null;

/** General wrapper for dashboard pages */
const Container = ({
    children,
    isUserInfoLoading,
    addNotification,
    wsToken,
    globalProjectId,
    getCurrencies,
    userInfo,
    isProjectChanging,
    isLogging,
    updateCurrencies,
}) => {

    const [collapsed, setCollapsed] = useState(false);

    /** Handle SignalR */
    useEffect(() => {
        if (wsToken) {
            SignalRUtils.buildConnections(handleSignalREvents);
        }

        return () => {
            clearInterval(interval);
            SignalRUtils.getConnections().forEach(connection => {
                connection.getConnection().off('UpdateSession');
                connection.getConnection().off('RefreshToken');
                connection.getConnection().off('Notification');
                connection.getConnection().off('Logout');
                connection.getConnection().off('WalletChange');
                connection.stopConnection();
            })
            SignalRUtils.removeConnections();
        }
    }, [wsToken])

    /** Function to subscribe and handle signalR events
       * @function
       * @description checks to allow only numeric characters
       * @memberOf Container
   */
    const handleSignalREvents = (connection, signalRType) => {
        if (signalRType === SIGNALR_CONNECTION_TYPES.ADMIN) {
            interval = setInterval(() => {
                if (connection.getConnection()._connectionState === "Connected") {
                    connection.getConnection().invoke(
                        "UpdateSession",
                        getUser()?.sessionId,
                        userInfo.id,
                        getUser()?.role === USER_ROLE.ADMIN ? USER_TYPE.ADMIN :
                            getUser()?.role === USER_ROLE.AGENT ? USER_TYPE.AGENT : USER_TYPE.AGENT_PLAYER
                    );
                }
            }, 60000 * 10)
        }
        connection.getConnection().on('RefreshToken', () => {
            const token = getUser()?.refreshToken ?? null;
            refreshToken(token);
        });

        connection.getConnection().on('Logout', () => {
            if(!getUser()?.hasPrevious){
                logout();
            }
        });

        connection.getConnection().on('Notification', data => {
            if(getUser()?.role !== USER_ROLE.ACCESS_MANAGER){
                addNotification(JSON.parse(data))
            }
        });

        connection.getConnection().on('WalletChange', data => {
            updateCurrencies(JSON.parse(data))
        })
    }

    /** Init antd configs */
    useEffect(() => {
        message.config({
            maxCount: 3
        });
    }, [])


    /** Init Chat Integration */
    // useEffect(() => {
    //     if(userInfo.id){
    //         if(config.ENV === "dev" || config.ENV === "tst"){
    //             if(getUser()?.role === USER_ROLE.AGENT){
    //                 initChatIntegration(userInfo.id, globalProjectId)
    //             }
    //         }
    //     }

    // }, [userInfo.id])

    useEffect(() => {
        const formatSetting = userInfo.formatSetting;
        dateService.setDateFormat(formatSetting?.dateFormatting);
        dateService.setTimeFormat(formatSetting?.timeFormatting);
    }, [userInfo.formatSetting])

    useEffect(() => {
        if (globalProjectId && userInfo.id) {
            if(
                (
                    getUser()?.role === USER_ROLE.ADMIN &&
                    hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_WALLETS, action: PERMISSION_ACTION.VIEW })
                ) ||
                (
                    getUser()?.role === USER_ROLE.AGENT || getUser()?.role === USER_ROLE.AGENT_PLAYER
                )
            ){
                getCurrencies();
            }
        }
    }, [globalProjectId, userInfo.id])


    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                if(
                    getUser()?.role === USER_ROLE.ADMIN && hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_WALLETS, action: PERMISSION_ACTION.VIEW })
                ){
                    getCurrencies()
                }
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [])


    return !isUserInfoLoading ? (
        <ConfigProvider
            getPopupContainer={trigger => trigger && trigger.parentNode ? trigger.parentNode : document.body}
            locale={locale}
        >
            <AntLayout className='rt--dashboard-layout'>
                <AntSider
                    className='rt--sidebar'
                    width={isMobile() ? "100vw" : 256}
                    collapsedWidth={56}
                    collapsed={isMobile() ? false : collapsed}
                >
                    <Sidebar
                        onCollapse={setCollapsed}
                        collapsed={isMobile() ? false : collapsed}
                    />
                </AntSider>
                <TransferProvider>
                    <AntLayout className="rt--flex rt--flex-col">
                        <Spin spinning={isProjectChanging || isLogging}>
                            <AntHeader className={'rt--header-wrapper' + (getUser()?.hasPrevious ? " rt--header-wrapper-asAgent" : "")}>
                                <Header />
                            </AntHeader>
                            <AntContent className='rt--flex-equal'>
                                { children }
                                
                                <LogoutModal />
                                <Transfer />
                                {
                                    isMobile() && <PlayerDepositSearch />
                                }

                            </AntContent>
                        </Spin>
                    </AntLayout>
                </TransferProvider>   

            </AntLayout>
        </ConfigProvider >
    ) : <Spin spinning={isUserInfoLoading} />
}

/** Container propTypes
    * PropTypes
*/
Container.propTypes = {
    /** Page content */
    children: PropTypes.node,
    /** Redux state property, is true when loading user info */
    isUserInfoLoading: PropTypes.bool,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux action to add notification */
    addNotification: PropTypes.func,
    /** Redux state property, current WS Token */
    wsToken: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to get account currencies */
    getCurrencies: PropTypes.func,
    /** Redux action to update account currencies */
    updateCurrencies: PropTypes.func,
    /** Redux state property, is true when project change request is in process */
    isProjectChanging: PropTypes.bool,
    /** Redux state property, is true when logging */
    isLogging: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        isUserInfoLoading: state.profile.isUserInfoLoading,
        userInfo: state.profile.userInfo,
        wsToken: state.auth.wsToken,
        globalProjectId: state.common.globalProjectId,
        isProjectChanging: state.profile.isProjectChanging,
        isLogging: state.auth.isLoading
    }
}

const mapDispatchToProps = dispatch => (
    {
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        getCurrencies: () => {
            dispatch(getCurrencies());
        },
        updateCurrencies: data => {
            dispatch(updateCurrencies(data));
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(Container);
