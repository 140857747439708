//#region react
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region actions
import {
    getPermissionRequestHistory,
    setPermissionRequestHistorySorting
} from "store/actions/dashboard/userManagement/requests/history.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import Table from "components/common/table";
import TabTableDashboardLayout from "components/layouts/tab/table";
import Filters from "./filters.component";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { PERMISSION_REQUEST_HISTORY } from "constants/pageName.constants";
import { getTableColumns, expandColumns } from "../columns";
//#endregion

//#region types
import permissionRequestType from "types/permissionRequest/request.type";
import sortingType from 'types/common/sorting.type';
//#endregion

const EXPANDED_ROW_UNIQUE_KEY_PROP = "index";

const mapPropToEachRowData = (rowsData) => {
    return (
        rowsData.map((rowData, index) => ({
            change: rowData,
            index: index + 1
        }))
    );
}

const getExpandTableDetails = ({ record, expandTableColumns }) => {
    if (!record.changes) {
        return null;
    }

    const expandTableData = mapPropToEachRowData(record.changes);

    return {
        columns: expandTableColumns,
        data: expandTableData,
        uniqueKey: EXPANDED_ROW_UNIQUE_KEY_PROP,
    }
}

/** Permission Requests History Page Component */
const PermissionRequestsHistoryComponent = ({
    getPermissionRequestHistory,
    setPermissionRequestHistorySorting,
    history,
    sorting,
    total,
    isLoading,
    isSaving,
    globalCompanyId
}) => {
    const { t } = useTranslation();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PERMISSION_REQUEST_HISTORY });

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        expandTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns,
            includedColumns,
            constructForInclude: true,
            additionalProps: {
                isHistory: true
            }
        });
    }, [includedColumns])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
    }
    //#endregion

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={history}
                loadFn={getPermissionRequestHistory}
                total={total}
                sorting={sorting}
                setSortingFn={setPermissionRequestHistorySorting}
                updateProps={[globalCompanyId, isSaving]}
                expandable={{
                    title: t('backoffice.users.changes'),
                    type: EXPAND_TABLE_TYPES.TABLE,
                    details: record => (
                        getExpandTableDetails({
                            record,
                            expandTableColumns,
                        })
                    ),
                }}
            />
        </TabTableDashboardLayout>
    )
};

/** PermissionRequestsHistoryComponent propTypes
 * PropTypes
*/
PermissionRequestsHistoryComponent.propTypes = {
    /** Redux action to get permission request history */
    getPermissionRequestHistory: PropTypes.func,
    /** Redux state property, represents the array of permission request history  */
    history: PropTypes.arrayOf(permissionRequestType),
    /** Redux state property, is true when loading permission request history */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving permission request */
    isSaving: PropTypes.bool,
    /** Redux state property, permission request history total count */
    total: PropTypes.number,
    /** Redux action to set permission request history sorting details */
    setPermissionRequestHistorySorting: PropTypes.func,
    /** Redux state property, permission request history sorting */
    sorting: sortingType,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getPermissionRequestHistory: nextPage => {
        dispatch(getPermissionRequestHistory(nextPage));
    },
    setPermissionRequestHistorySorting: sorting => {
        dispatch(setPermissionRequestHistorySorting(sorting));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.permissionRequests.isLoading,
        isSaving: state.permissionRequests.isSaving,
        history: state.permissionRequests.history.history,
        total: state.permissionRequests.history.total,
        sorting: state.permissionRequests.history.sorting,
        globalCompanyId: state.common.globalCompanyId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermissionRequestsHistoryComponent);
