import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { 
    SET_PROJECT_AGENT_SYSTEM_NETWORK,
    SET_PROJECT_AGENT_SYSTEM_NETWORK_LABEL 
} from "../../../actionTypes";

const setProjectAgentSystemNetwork = network => ({
    type: SET_PROJECT_AGENT_SYSTEM_NETWORK,
    payload: { network }
})

const setProjectAgentSystemNetworkLabel = level => ({
    type: SET_PROJECT_AGENT_SYSTEM_NETWORK_LABEL,
    payload: { level }
})

export const getProjectAgentSystemNetwork = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_AGENT_SYSTEM_NETWORK,
            method: Methods.GET
        })
        .then(({data : {value: network}}) => {
            dispatch(setProjectAgentSystemNetwork(network))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const changeProjectAgentSystemNetworkLabel = (layerId, label) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());

        return axios({
            url: ApiUrls.CHANGE_PROJECT_AGENT_SYSTEM_NETWORK_LABEL,
            method: Methods.POST,
            data: {
                layerId,
                label
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectAgentSystemNetworkLabel(data.value));
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const addProjectAgentSystemNetworkLayer = count => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.ADD_PROJECT_AGENT_SYSTEM_NETWORK_LAYER,
            method: Methods.POST,
            data: {
                count
            }
        })
        .then(({data}) => {
            if (isResponseSuccess(data)) {
                dispatch(getProjectAgentSystemNetwork())
            }
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const deleteProjectAgentSystemNetworkLayer = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.DELETE_PROJECT_AGENT_SYSTEM_NETWORK_LAYER,
            method: Methods.DELETE,
            data: {}
        })
        .then(({data}) => {
            if (isResponseSuccess(data)) {
                dispatch(getProjectAgentSystemNetwork())
            }
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}
