import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import Tabs from "components/common/tabs";

import PaymentConfigsComponent from './configs';
import PaymentBanksComponent from './banks';

import { getProjectPaymentSettings } from "store/actions/dashboard/projects/paymentsConfig.action";

import {binaryToFlags, getHashValue} from 'utils/common';

import { PAYMENT_METHODS } from "constants/project.constants"

import projectPaymentConfigType from 'types/project/projectPaymentConfig.type';

/** Project Edit Page Payament Tab Component */
const ProjectPaymentsComponent = ({
    tabId,
    onTabChange,
    getProjectPaymentSettings,
    config,
}) => {
    const { t } = useTranslation();
    const { search } = useLocation();

    const projectHaveBankTransfer =  binaryToFlags(Object.values(PAYMENT_METHODS), config.type).includes(PAYMENT_METHODS.BANK_TRANSFER)

    /** Set payment settings */
    useEffect(() => {
        getProjectPaymentSettings()
    }, [])

    const bankId = (new URLSearchParams(search)).get("bankId");
    const isActive = tabId === getHashValue('tab');

    const items = [
        {
            title: t("backoffice.payments.configs"),
            component: <PaymentConfigsComponent />
        },
        {
            title: t("backoffice.payments.bankList"),
            component: <PaymentBanksComponent />,
            disabled: !projectHaveBankTransfer && !bankId,
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
            hidden={Boolean(bankId)}
        />
    )
}

/** PaymentsComponent propTypes
    * PropTypes
*/
ProjectPaymentsComponent.propTypes = {
    /** Tab identifier*/
    tabId: PropTypes.string,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Redux state property, current editing project payment type */
    config: projectPaymentConfigType,
    /** Redux action to get project project payment */
    getProjectPaymentSettings: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectPaymentSettings: () => {
            dispatch(getProjectPaymentSettings());
        }
    }
)

const mapStateToProps = state => {
    return {
        config: state.projects.edit.payment.config
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPaymentsComponent);
