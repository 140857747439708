//#region react
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from "react-router-dom";
//#endregion

//#region actions
import { getAgentBanks } from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import Table from "components/common/table";
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import BankAddComponent from './bank-add.component';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { BANK_STATUS } from 'constants/project.constants';
import { USER_ROLE } from "constants/user.constants";
import { AGENT_BANK_LIST } from 'constants/pageName.constants';
import { tableColumns } from './columns';
//#endregion

const BankListComponent = ({
    getAgentBanks,
    isLoading,
    banks
}) => {
    const { t } = useTranslation();
    const { search, pathname, hash } = useLocation();
    const searchParams = useParams();
    const navigate = useNavigate();

    const [isAddBankPopupVisible, setIsAddBankPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AGENT_BANK_LIST });

    const { role } = getUser();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasPaymentModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_PAYMENT,
        action: PERMISSION_ACTION.MODIFY
    });

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${pathname}${search}` +
            `&bankId=${record.id}` +
            `&bankName=${record.name}${hash}`
        )
    }

    const showAddBankPopup = () => {
        setIsAddBankPopupVisible(true);
    }

    const hideAddBankPopup = () => {
        setIsAddBankPopupVisible(false);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            includedColumns,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t("backoffice.common.deepView"),
            icon: "icon-right",
            onClick: handleRightArrowIconClick,
            disabled: record => record.status === BANK_STATUS.DELETED
        }
    ];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "icon-plus",
            type: "primary",
            onClick: showAddBankPopup,
            text: t("backoffice.payments.addBank"),
            enabled: hasPaymentModifyPermission && role === USER_ROLE.ADMIN,
        }
    }

    //#endregion

    return (
        <SubTabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={banks}
                loadFn={() => getAgentBanks(searchParams.id)}
                noPagination={true}
                disableFullView={true}
                total={banks.length}
                actions={tableRowActions}
            />

            {isAddBankPopupVisible && <BankAddComponent onClose={hideAddBankPopup} />}
        </SubTabTableDashboardLayout>
    );
}

/** BankListComponent propTypes
    * PropTypes
*/
BankListComponent.propTypes = {
    /** Redux action to get project agent payment bank list */
    getAgentBanks: PropTypes.func,
    /** Redux state property, is true when project payment is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, Agent banks info */
    banks: PropTypes.array,
}

const mapDispatchToProps = dispatch => (
    {
        getAgentBanks: (id) => {
            dispatch(getAgentBanks(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.agents.isLoading,
        banks: state.agents.edit.payment.banks.banks
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankListComponent);