import React, { useState } from 'react';

import TableContext from './contex';

import TableMobile from './mobile';
import TableDesktop from './desktop';
import Confirmation from "components/common/confirmation";

import { isMobile } from "utils/common";

import useFormat from "hooks/useFormat";

import { DEFAULT_CONFIRMATION_MODAL_DATA } from './constants';

import { chooserPropTypes } from './propTypes';

const Table = ({ chooser, ...props }) => {

    const { formatNumber, formatCurrencyWithSymbol } = useFormat()

    const [confirmationModalData, setConfirmationModalData] = useState({
        ...DEFAULT_CONFIRMATION_MODAL_DATA
    });

    const showConfiramtionModal = (confirmationModalData) => {
        setConfirmationModalData({
            isVisible: true,
            ...confirmationModalData
        })
    }

    const hideConfiramtionModal = () => {
        setConfirmationModalData({
            ...DEFAULT_CONFIRMATION_MODAL_DATA
        })
    }

    return (
        <>
            <TableContext.Provider value={{ showConfiramtionModal }}>
                {
                    isMobile()
                        ? (
                            <TableMobile
                                chooser={chooser}
                                formatNumber={formatNumber}
                                formatCurrencyWithSymbol={formatCurrencyWithSymbol}
                                {...props}
                            />
                        )
                        : (
                            <TableDesktop
                                chooser={chooser} 
                                formatNumber={formatNumber}
                                formatCurrencyWithSymbol={formatCurrencyWithSymbol}
                                {...props}
                            />
                        )
                }
            </TableContext.Provider>

            <Confirmation
                title={confirmationModalData.title}
                message={confirmationModalData.message}
                onOk={() => confirmationModalData.onOk(confirmationModalData.rowData)}
                onCancel={hideConfiramtionModal}
                isVisible={confirmationModalData.isVisible}
            />
        </>
    );
}

/** Table propTypes
 * PropTypes
 */
Table.propTypes = {
    chooser: chooserPropTypes,
}

export default Table;
