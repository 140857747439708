import React, { Fragment, useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Input } from "antd";

import AutoComplete from "components/common/autoComplete";
import Modal from 'components/common/modal';

import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { USER_ROLE, USER_STATE } from "constants/user.constants";
import { PLAYER_STATE } from "constants/player.constants";
import { FILTER_TYPE } from "constants/common.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

import useAutosuggestion from "hooks/useAutosuggestion";
import useProjectType from "hooks/useProjectType";
import { TRANSFER_ENTITY_TYPE, useTransfer } from "components/common/transfer";

import { debounce, isMobile, isFranchisingMode } from "utils/common";
import { getUser } from "utils/auth";
import { hasPlayerNonDirectTransactionAccess } from "utils/access";
import { hasPermission } from "utils/permissions";

import userInfoType from 'types/profile/userInfo.type';

/** Player Deposit Search Component */
const PlayerDepositSearch = ({
    userInfo,
    globalProjectId
}) => {

    const { t } = useTranslation();

    const { role, userState } = getUser() || {};

    const { show: showTransfer} = useTransfer()

    const inputRef = useRef(null);

    const { hasStandartPayment } = useProjectType();

    const [loading, setLoading] = useState(false);

    const [showAutosuggestionModal, setShowAutosuggestionModal] = useState(false);

    const [value, setValue] = useState("");

    const [playerNames, getPlayerNames, resetPlayerNames, isPlayersLoading] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PLAYERS_TOP,
        autoGet: false,
        actionParams: {
            state: PLAYER_STATE.ACTIVE,
            filterType: !hasPlayerNonDirectTransactionAccess(userInfo) ? FILTER_TYPE.DIRECT : FILTER_TYPE.NETWORK,
        }
    });

    const handleTransferClick = (e, item) => {
        e.stopPropagation();

        showTransfer({
            entityType: TRANSFER_ENTITY_TYPE.PLAYER,
            userId: item.id
        })

        resetPlayerNames();
        setTimeout(() => {
            setValue("")
        }, 200)
        if(isMobile()){
            setShowAutosuggestionModal(false)
        }
    }

    const handleDebounceFn = v => {
        if (v?.length >= 3) {
            getPlayerNames({ "userNameOrId": v })
        } else {
            resetPlayerNames()
        }
    }

    const debounceSearch = useCallback(
        debounce(
            handleDebounceFn,
            1000,
            {
                before: v => {
                    if (v?.length >= 3) {
                        setLoading(true);
                    }
                },
                after: () => setLoading(false)
            }
        ), []);


    const renderAutoSuggestionItem = item => (
        <div
            className="rt--header-search-item rt--flex rt--align-center rt--justify-between rt--width-full rt--pl-8 rt--pr-8"
            onClick={e => handleTransferClick(e, item)}
        >
            <span className="rt--title rt--font-regular rt--font-normal rt--flex-equal">{item.name}</span>
        </div>
    )


    const handleSearchButtonClick = () => {
        setShowAutosuggestionModal(true);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 400)
    }

    const canTransfer = (
        !isFranchisingMode() &&
        globalProjectId &&
        hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW }) &&
        userState === USER_STATE.ACTIVE &&
        (role === USER_ROLE.AGENT || role === USER_ROLE.ADMIN) &&
        hasStandartPayment
    )

    if (!canTransfer) return null;

    const renderAutoSuggestion = () => (
        <AutoComplete
            items={playerNames}
            getPopupContainer={trigger => trigger.parentNode}
            disableFiltering={true}
            onChange={setValue}
            value={value}
            loading={isPlayersLoading || loading}
            renderItem={renderAutoSuggestionItem}
        >
            <Input
                type='search'
                placeholder={t("backoffice.players.searchPlayerForTransfer")}
                prefix={(<i className="icon-search rt--font-bigest" />)}
                value={value}
                onChange={e => debounceSearch(e.target.value)}
                ref={inputRef}
            />
        </AutoComplete>
    )

    return (
        <div className="rt--header-search rt--flex rt--align-center rt--mr-16">
            {
                isMobile() ? (
                    <Fragment>
                        <i
                            className="icon-player rt--font-bigest"
                            onClick={() => handleSearchButtonClick(true)}
                        />
                        {
                            showAutosuggestionModal && (
                                <Modal
                                    title={`${t("backoffice.common.select")} ${t("backoffice.common.player")}`}
                                    onCancel={() => setShowAutosuggestionModal(false)}
                                >
                                    <div className="rt--form-item-search-modal">
                                        <div
                                            className="rt--form-item-search"
                                        >
                                            {renderAutoSuggestion()}
                                        </div>
                                    </div>
                                </Modal>
                            )
                        }
                    </Fragment>
                ) : (
                    renderAutoSuggestion()
                )
            }

        </div>
    )
}

/** PlayerDepositSearch propTypes
 * PropTypes
*/
PlayerDepositSearch.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        userInfo: state.profile.userInfo,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    null
)(PlayerDepositSearch);
