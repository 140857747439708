import React from 'react';
import i18n from "translations/config"

import { NAME_REGEX } from "constants/regex.constants";

export const getTableColumns = (additionalInfo) => {
    const { onLabelEdit } = additionalInfo;

    return [
        {
            title: "backoffice.projects.agentLevel",
            dataIndex: "layer",
            width: 180,
            showOnlyValue: true,
            alwaysVisible: true,
            mobileLevel: 1,
            mobileRender: layer => (
                <span className="rt--font-normal rt--font-extra-bold">
                    {`${i18n.t("backoffice.projects.level")} ${layer}`}
                </span>
            ),
        },
        {
            title: "backoffice.projects.label",
            dataIndex: "label",
            editable: true,
            regex: NAME_REGEX,
            maxLength: 30,
            mobileLevel: 2,
            showOnlyValue: true,
            editableLabel: () => i18n.t("backoffice.projects.label"),
            editableTitle: record => `${i18n.t("backoffice.projects.level")}: ${record.layer}`,
            onEdit: (newLabel, record) => onLabelEdit(record.layer, newLabel),
        }
    ]
}