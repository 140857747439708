import { useSyncExternalStore } from "react";

import { ImmutableState } from "NEW/src/core/lib/immutableState";
import { Observable } from "NEW/src/core/lib/observable";

export class BaseStore {
	#state;

	constructor(initialState) {
		this.observer = new Observable();
		this._initialState = initialState;

		this.#state = new ImmutableState(initialState, this.#handleStateUpdate.bind(this));
	}

	get _pureState() {
		return this.#state.pureState;
	}

	get state() {
		return this.#state.proxyState;
	}

	set state(newState) {
		this.#state = new ImmutableState(newState, this.#handleStateUpdate.bind(this));
		this.#handleStateUpdate();
	}

	reset() {
		this.state = this._initialState;
	}

	#handleStateUpdate() {
		this.observer.notify(this.#state.pureState);
	}

	subscribe(callback) {
		this.observer.subscribe(callback);

		return () => {
			this.observer.unsubscribe(callback);
		};
	}

	getSnapshot() {
		return this._pureState;
	}
}

export const createSelector =
	(stateInstance) =>
	(selectorFn = null) => {
		return useSyncExternalStore(
			stateInstance.subscribe.bind(stateInstance),
			selectorFn
				? () => selectorFn.call(null, stateInstance._pureState)
				: stateInstance.getSnapshot.bind(stateInstance),
		);
	};
