export const ROUTES = {
	ROOT: "/",

	// Login
	LOGIN: "/login",
	RESET_PASSWORD: "/login/resetPassword",

	// Profil
	PROFILE: "/profile",

	// Dashboard
	DASHBOARD_OPERATIONAL: "/operational",
	DASHBOARD_FINANCIAL: "/financial",

	// Companies
	COMPANIES: "/companies",
	COMPANIES_EDIT: "/companies/edit",

	// Projects
	PROJECTS: "/projects",
	PROJECTS_EDIT: "/projects/edit",

	// Agent System/Franchising
	AGENTS: "/agents",
	AGENTS_EDIT: "/agents/edit",
	BETSHOP_OWNERS: "/betshopOwners",
	BETSHOP_OWNERS_EDIT: "/betshopOwners/edit",
	NETWORK: "/network",

	// Payments
	PAYMENTS_REQUESTS: "/payments/requests",
	PAYMENT_HISTORY: "/payments/history",

	// Reports
	REPORTS_AGENT_TRANSACTIONS: "/report/agentTransactions",
	REPORTS_BETSHOP_OWNER_TRANSACTIONS: "/report/betshopOwnerTransactions",
	REPORTS_CASHIER_TRANSACTIONS: "/report/cashierTransactions",
	REPORTS_BETSHOP_TRANSACTIONS: "/report/betshopTransactions",
	REPORTS_COMMISSION_CALCULATIONS: "/report/commissionCalculations",
	REPORTS_AGENT_PERFORMANCE_REPORT: "/report/agentPerformanceReport",
	REPORTS_AGENT_PERFORMANCE_REPORT_EDIT: "/report/agentPerformanceReport/edit",
	REPORTS_PLAYER_PERFORMANCE_REPORT: "/report/playerPerformanceReport",
	REPORTS_BETSHOP_PERFORMANCE_REPORT: "/report/betshopPerformanceReport",
	REPORTS_CASHIER_PERFORMANCE_REPORT: "/report/cashierPerformanceReport",
	REPORTS_AGENT_CALCULATION_HISTORY: "/report/agentCalculationHistory",

	// Players
	PLAYERS: "/players",
	PLAYERS_EDIT: "/players/edit",

	// Bets
	REAL_TIME_SPORT_BETS: "/betslips/realTimeSportBets",
	ONLINE_BETHISTORY: "/betslips/online",
	RETAIL_BETHISTORY: "/betslips/retail",

	// Retail
	BETSHOPS: "/betshops",
	BETSHOPS_EDIT: "/betshops/edit",
	CASHIERS: "/cashiers",
	CASHIERS_EDIT: "/cashiers/edit",
	BETSHOP_MANAGERS: "/betshopManagers",
	BETSHOP_MANAGERS_EDIT: "/betshopManagers/edit",

	// Admin Management
	ADMINS: "/admins",
	ADMINS_EDIT: "/admins/edit",
	ACCESS_MANAGERS: "/accessManagers",
	ACCESS_MANAGERS_EDIT: "/accessManagers/edit",
	PERMISSIONS: "/permissions",
	PERMISSION_GROUPS: "/permissionGroups",
	PERMISSION_GROUPS_EDIT: "/permissionGroups/edit",
	PERMISSION_REQUESTS: "/permissionRequests",

	// Failed Transactions
	FAILED_TRANSACTIONS: "/failedTransactions",

	// Users
	USERS: "/users",
	USERS_EDIT: "/users/edit",

	// User Logs
	USER_LOGS: "/userLogs",

	// CMS
	TRANSLATIONS_RETAIL: "/cms/retailTranslations",
	TRANSLATIONS_BO: "/cms/BOTranslations",
	TRANSLATIONS_WIDGET: "/cms/widgetTranslations",
	PAYOUT_TICKET: "/cms/payoutTicket",

	// Settings
	SETTINGS_CURRENCIES: "/settings/currencies",
	SETTINGS_LANGUAGES: "/settings/languages",

	// Developer Space
	DEVELOPER_ERRORS: "/developer/errors",
	DEVELOPER_REQUESTS: "/developer/requests",
	DEVELOPER_JOBS: "/developer/jobs",
	DEVELOPER_MONITORING: "/developer/monitoring",
	DEVELOPER_PLATFORM_TEST: "/developer/platformTest",
	DEVELOPER_GENERATIONS: "/developer/generations",
	DEVELOPER_CALCULATE_REPORTS: "/developer/calculateReports",
	DEVELOPER_POST_DEPLOYMENT_ACTIONS: "/developer/postDeploymentActions",
	DEVELOPER_DB_CONNECTIONS: "/developer/dbConnections",
};
