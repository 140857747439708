//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region libraries
import { Col, Row, Form, Divider } from 'antd';
//#endregion

//#region actions
import {
    addCommissionPlan,
    getAvailableCommissionPlans
} from "store/actions/dashboard/agentSystem/commissionPlans/commissionPlans.action";
import {
    getAgentCurrencies
} from "store/actions/dashboard/agentSystem/agents/currencies.action";
//#endregion

//#region components
import Modal from "components/common/modal";
import Table from "components/common/table";
import Select from "components/common/select";
//#endregion

//#region utils
import { isFranchisingMode } from 'utils/common';
//#endregion

//#region constants
import {
    COMMISSION_PLAN_TYPE,
} from "constants/commission.constants";
import { POPUP_SIZE } from "constants/common.constants";
//#endregion

//#region types
import commissionPlanType from 'types/agent/commissionPlan.type';
import currencyType from "types/currency/currency.type";
import gameProviderType from "types/project/gameProvider.type";
import { getTableColumns } from './columnsForAdd';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

/** Agent Edit Page Commission plan Add popup Component */
const CommissionPlanAddComponent = ({
    isSaving,
    addCommissionPlan,
    getAvailableCommissionPlans,
    isAvailableCommissionPlansLoading,
    availableCommissionPlans,
    gameProviders,
    getAgentCurrencies,
    currencies,
    onClose
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [selected, setSelected] = useState(null);
    const [selectedType, setSelectedType] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState("");

    const [formInstance] = Form.useForm();

    const filteredAvailableCommissionPlans = availableCommissionPlans.filter(p =>
        (selectedType === "" || p.type === selectedType) &&
        (selectedCurrency === "" || p.currencyCode === selectedCurrency)
    )

    /** Fires when form submitted
       * @function
       * @memberOf CommissionPlanAddComponent
    */
    const handleForm = () => {
        addCommissionPlan(selected, searchParams.id, onClose)
    }

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                gameProviders,
            }
        });
    }, [gameProviders])

    //#endregion

    // Set first as selected
    useEffect(() => {
        if (filteredAvailableCommissionPlans.length > 0) {
            setSelected(filteredAvailableCommissionPlans[0].id)
        } else {
            setSelected(null)
        }
    }, [availableCommissionPlans, selectedType, selectedCurrency])

    // Load Agent Currencies
    useEffect(() => {
        getAgentCurrencies(searchParams.id)
    }, [])

    return (
        <Modal
            title={t('backoffice.commissionplans.addCommissionPlan')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            disableOkButton={filteredAvailableCommissionPlans.length === 0}
            size={POPUP_SIZE.BIGEST}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form
                        className="rt--form"
                        form={formInstance}
                        colon={false}
                        requiredMark={false}
                        layout="vertical"
                    >
                        <Row gutter={[16, 0]}>
                            {
                                !isFranchisingMode() && (
                                    <Col span={12}>
                                        <Form.Item
                                            label={t('backoffice.commissionplans.incomeType')}
                                            className="rt--form-item-without-margin"
                                        >
                                            <Select
                                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.agents.currency')}`}
                                                onChange={value => setSelectedType(value)}
                                                value={selectedType}
                                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                options={[
                                                    { value: "", text: t('backoffice.common.all') },
                                                    { value: COMMISSION_PLAN_TYPE.NETWORK, text: t('backoffice.commissionplans.network') },
                                                    { value: COMMISSION_PLAN_TYPE.DIRECT, text: t('backoffice.commissionplans.direct') },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                )
                            }

                            <Col span={12} >
                                <Form.Item
                                    label={t('backoffice.commissionplans.currency')}
                                    className="rt--form-item-without-margin"
                                >
                                    <Select
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.currency')}`}
                                        value={selectedCurrency}
                                        onChange={v => setSelectedCurrency(v)}
                                        search={true}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        options={[
                                            { value: "", text: t("backoffice.common.all") },
                                            ...currencies.map(item => (
                                                { value: item.currency, text: item.currency }
                                            ))
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                <Col span={24}>
                    <Divider className='rt--form-section-divider' />

                    <div className="rt--modal-table">
                        <Table
                            loading={isAvailableCommissionPlansLoading}
                            columns={mainTableColumns}
                            data={filteredAvailableCommissionPlans}
                            loadFn={() => getAvailableCommissionPlans(searchParams.id)}
                            noPagination={true}
                            disableFullView={true}
                            chooser={{
                                type: "radio",
                                valueIndex: "id",
                                dataIndex: "selected",
                                value: selected,
                                onChange: value => setSelected(value),
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

/** CommissionPlanAddComponent propTypes
    * PropTypes
*/
CommissionPlanAddComponent.propTypes = {
    /** Redux state property, is true when adding commission plan request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add commission plan for agent */
    addCommissionPlan: PropTypes.func,
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType),
    /** Redux action to load available commission plans */
    getAvailableCommissionPlans: PropTypes.func,
    /** Redux state property, is true when loading available commission plans */
    isAvailableCommissionPlansLoading: PropTypes.bool,
    /** Redux state, represents the available commision plans of current editing agent  */
    availableCommissionPlans: PropTypes.arrayOf(commissionPlanType),
    /** Redux action to get agent currencies */
    getAgentCurrencies: PropTypes.func,
    /** Redux state, represents the currencies of current editing agent  */
    currencies: PropTypes.arrayOf(currencyType),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addCommissionPlan: (id, agentId, onSuccess) => {
            dispatch(addCommissionPlan(id, agentId, onSuccess));
        },
        getAvailableCommissionPlans: id => {
            dispatch(getAvailableCommissionPlans(id));
        },
        getAgentCurrencies: id => {
            dispatch(getAgentCurrencies(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.commissionPlans.isSaving,
        availableCommissionPlans: state.commissionPlans.availableCommissionPlans,
        isAvailableCommissionPlansLoading: state.commissionPlans.isAvailableCommissionPlansLoading,
        gameProviders: state.common.gameProviders,
        currencies: state.agents.edit.currencies.currencies
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanAddComponent)