import React from "react";

import { ConfigProvider } from "antd";
import PropTypes from "prop-types";

import { theme } from "NEW/src/core/data/constants/theme";

const AntdProvider = ({ children }) => {
	return (
		<ConfigProvider
			getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
			theme={theme}
		>
			{children}
		</ConfigProvider>
	);
};

AntdProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AntdProvider;
