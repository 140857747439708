import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from './sections/general';
import DepositConfigComponent from './sections/configs/deposit';
import WithdrawalConfigComponent from './sections/configs/withdrawal'

const BankEditComponent = () => {
    const { t } = useTranslation();


    const items = [
        {
            title: t("backoffice.payments.general"),
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.payments.depositDetails"),
            component: <DepositConfigComponent />,
        },
        {
            title: t("backoffice.payments.withdrawalDetails"),
            component: <WithdrawalConfigComponent />,
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isDeepView={true}
        />
    )
    
}

export default BankEditComponent;
