import axios from "axios";
import { message } from 'antd';

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_PAYOUT_TICKET_ACTION_BEFORE,
    SET_PAYOUT_TICKET_ACTION_FINISH,
    SET_PAYOUT_TICKET,
    SET_CURRENT_PAYOUT_TICKET,
    SET_PAYOUT_TICKET_SAVE_ACTION_BEFORE,
    SET_PAYOUT_TICKET_SAVE_ACTION_FINISH,
    SET_PAYOUT_TICKET_LOGO
} from "../../../../actionTypes";

const setPayoutTicketActionBefore = () => ({
    type: SET_PAYOUT_TICKET_ACTION_BEFORE,
});

const setPayoutTicketActionFinished = () => ({
    type: SET_PAYOUT_TICKET_ACTION_FINISH,
});

const setPayoutTicketSaveActionBefore = () => ({
    type: SET_PAYOUT_TICKET_SAVE_ACTION_BEFORE,
});

const setPayoutTicketSaveActionFinished = () => ({
    type: SET_PAYOUT_TICKET_SAVE_ACTION_FINISH,
});

const setPayoutTicket = ticket => ({
    type: SET_PAYOUT_TICKET,
    payload: { ticket }
})

export const setCurrentPayoutTicket = ticket => ({
    type: SET_CURRENT_PAYOUT_TICKET,
    payload: { ticket }
})

export const getPayoutTicket = () => {
    return dispatch => {
        
        dispatch(setPayoutTicketActionBefore());

        return axios({
            url: ApiUrls.GET_PAYOUT_TICKET,
            method: Methods.GET
        })
            .then(({ data: { value: ticket } }) => {
                dispatch(setPayoutTicket(ticket));
                dispatch(setCurrentPayoutTicket({
                    generalDetails: ticket.generalDetails,
                    betDetails: ticket.betDetails,
                    customText: ticket.customText,
                    enableBarcodePrinting: ticket.enableBarcodePrinting
                }));
                dispatch(setPayoutTicketActionFinished());
            })
            .catch((ex) => {
                dispatch(setPayoutTicketActionFinished());
            });
    }
}

export const savePayoutTicket = ticket => {
    return (dispatch) => {
        dispatch(setPayoutTicketSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PAYOUT_TICKET,
            method: Methods.POST,
            data: ticket
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(setPayoutTicket(data.value));
                }
                dispatch(setPayoutTicketSaveActionFinished());
            })
            .catch(() => {
                dispatch(setPayoutTicketSaveActionFinished());
            });
    };
};

export const setPayoutTicketLogo = ticketLogoId => ({
    type: SET_PAYOUT_TICKET_LOGO,
    payload: { ticketLogoId }
})

export const deletePayoutTicketLogo = () => {
    return dispatch => {

        return axios({
            url: ApiUrls.DELETE_PAYOUT_TICKET_LOGO,
            method: Methods.DELETE
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPayoutTicketLogo(null));
            }
        })
        .catch((ex) => {})
    }
}