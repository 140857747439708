import i18n from "translations/config";

import { BETSHOP_TRANSLATION_TYPE } from "constants/betshop.constants";

export const getTableColumns = (additionalProps) => {
    const {
        havePermissionForEdit,
        onFieldEdit,
    } = additionalProps;

    return [
        {
            title: "backoffice.betshops.language",
            dataIndex: "languageCode",
            width: 180,
            mobileLevel: 1,
        },
        {
            title: "backoffice.betshops.name",
            dataIndex: "name",
            editable: havePermissionForEdit,
            maxLength: 100,
            mobileLevel: 2,
            editableLabel: () => i18n.t("backoffice.betshops.name"),
            editableTitle: () => i18n.t("backoffice.betshops.name"),
            onEdit: (newValue, record) => {
                onFieldEdit(
                    newValue,
                    record.languageCode,
                    BETSHOP_TRANSLATION_TYPE.NAME,
                    record.nameKey
                )
            },
        },
        {
            title: "backoffice.betshops.address",
            dataIndex: "address",
            editable: havePermissionForEdit,
            maxLength: 100,
            mobileLevel: 3,
            editableLabel: () => i18n.t("backoffice.betshops.address"),
            editableTitle: () => i18n.t("backoffice.betshops.address"),
            onEdit: (newValue, record) => {
                onFieldEdit(
                    newValue,
                    record.languageCode,
                    BETSHOP_TRANSLATION_TYPE.ADDRESS,
                    record.addressKey,
                )
            },
        }
    ];
}