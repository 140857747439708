//#region react
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region actions
import {
    getCommissionPlans
} from "store/actions/dashboard/agentSystem/commissionPlans/commissionPlans.action";
import { getGameProviders } from "store/actions/dashboard/common/common.action";
//#endregion

//#region components
import Table from "components/common/table";
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import TabTableDashboardLayout from "components/layouts/tab/table";
import CommissionPlanCreateComponent from "./commissionPlan-create.component";
import CommissionPlanAddComponent from "./commissionPlan-add.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission } from "utils/permissions";
import { isFranchisingMode } from "utils/common";
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { AGENT_TYPE } from "constants/agent.constants";
import { COMMISSION_PLAN_LIST } from 'constants/pageName.constants';
import { getTableColumns } from './columnsForList';
//#endregion

//#region types
import commissionPlanType from 'types/agent/commissionPlan.type';
import userInfoType from 'types/profile/userInfo.type';
import gameProviderType from "types/project/gameProvider.type";
//#endregion

const {
    AGENT_COMMISSION_PLAN,
    PROJECT_COMMISSION_PLAN,
} = PERMISSION_RESOURCE;

/** Commission Plans List Component */
const CommissionPlanListComponent = ({
    getCommissionPlans,
    getGameProviders,
    isLoading,
    commissionPlans,
    userInfo,
    gameProviders,
    agentType
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();
    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    /** State to show/hide currency add popup */
    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: COMMISSION_PLAN_LIST });

    const franchisingMode = isFranchisingMode();

    const isAgent = agentType === AGENT_TYPE.AGENT;
    const isSuperAgent = agentType === AGENT_TYPE.SUPER_AGENT

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = hasPermission({
        resource: isAgent ? AGENT_COMMISSION_PLAN : PROJECT_COMMISSION_PLAN,
        action: PERMISSION_ACTION.CREATE,
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${pathname}${search}` +
            `&commissionPlanId=${record.id}` +
            `&commissionPlanName=${record.name}${hash}`
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            additionalProps: {
                gameProviders,
            }
        });
    }, [gameProviders, includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [{
        title: t('backoffice.common.deepView'),
        icon: "icon-right",
        onClick: handleRightArrowIconClick,
    }];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            text: isAgent ? t("backoffice.common.add") : t("backoffice.common.create"),
            icon: "icon-plus",
            type: "primary",
            onClick: showCreatePopup,
            enabled: hasCreatePermission && userInfo.id !== searchParams.id,
        }
    }

    //#endregion

    // Load providers
    useEffect(() => {
        if (franchisingMode) {
            return;
        }

        getGameProviders();
    }, [])

    // Choose corresponding component
    const CommissionsLayout = (
        isSuperAgent ? SubTabTableDashboardLayout : TabTableDashboardLayout
    )

    return (
        <CommissionsLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={commissionPlans}
                loadFn={() => getCommissionPlans(searchParams.id)}
                noPagination={true}
                actions={tableRowActions}
            />

            {isCreatePopupVisible && (
                isAgent
                    ? <CommissionPlanAddComponent onClose={hideCreatePopup} />
                    : <CommissionPlanCreateComponent onClose={hideCreatePopup} />
            )}
        </CommissionsLayout>
    )
}

/** CommissionPlanListComponent propTypes
    * PropTypes
*/
CommissionPlanListComponent.propTypes = {
    /** Redux action to get commission plans */
    getCommissionPlans: PropTypes.func,
    /** Redux state property, is true when agent commission plans are loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the commision plans of current editing agent/project  */
    commissionPlans: PropTypes.arrayOf(commissionPlanType),
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux action to get game providers */
    getGameProviders: PropTypes.func,
    /** Agent type */
    agentType: PropTypes.oneOf(Object.values(AGENT_TYPE)),
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType),
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionPlans: agentId => {
            dispatch(getCommissionPlans(agentId));
        },
        getGameProviders: () => {
            dispatch(getGameProviders());
        }
    }
)

const mapStateToProps = state => {
    return {
        commissionPlans: state.commissionPlans.commissionPlans,
        isLoading: state.commissionPlans.isLoading,
        userInfo: state.profile.userInfo,
        gameProviders: state.common.gameProviders
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanListComponent)