import React from "react";

import AgentsComponent from "pages/agentSystem/agents/agents.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import withEnvironment from "hocs/withEnvironment";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import { ENVIRONMENT_TYPE } from "constants/common.constants";
import { USER_ROLE } from "constants/user.constants";

const AgentsRoute = () => {
    return <AgentsComponent />;
};

export default withPermission(
    withEnvironment(withAuth(AgentsRoute), [
        { environmentType: ENVIRONMENT_TYPE.AGENT_SYSTEM },
        { environmentType: ENVIRONMENT_TYPE.RETAIL, role: USER_ROLE.ADMIN },
    ]),
    { resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
