import axios from 'axios';

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import { isResponseSuccess } from "utils/request";

import {
    SET_NOTIFICATIONS_ACTION_BEFORE,
    SET_NOTIFICATIONS_ACTION_FINISH,
    SET_NOTIFICATIONS,
    ADD_NOTIFICATION,
    SET_NOTIFICATION_SOUND,
    DELETE_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION, SET_UNREAD_NOTIFICATION_COUNT, MARK_ALL_AS_READ_NOTIFICATIONS, DELETE_ALL_NOTIFICATIONS
} from '../../../actionTypes';

const setNotificationsActionBefore = () => ({
    type: SET_NOTIFICATIONS_ACTION_BEFORE
})

const setNotificationsActionFinished = () => ({
    type: SET_NOTIFICATIONS_ACTION_FINISH
})

const setNotifications = notifications => {
    return {
        type: SET_NOTIFICATIONS,
        payload: { notifications }
    }
}

const setUnreadNotificationCount = (count) => ({
    type: SET_UNREAD_NOTIFICATION_COUNT,
    payload: { count }
})

const deleteNotificationSuccess = id => ({
    type: DELETE_NOTIFICATION,
    payload: { id }
});

const deleteAllNotificationsSuccess = () => ({
    type: DELETE_ALL_NOTIFICATIONS
})

const markNotificationAsReadSuccess = id => ({
    type: MARK_AS_READ_NOTIFICATION,
    payload: { id }
})

const markAllNotificationsAsReadSuccess = () => ({
    type: MARK_ALL_AS_READ_NOTIFICATIONS,
})

export const setNotificationSound = show => ({
    type: SET_NOTIFICATION_SOUND,
    payload: { show }
})

export const addNotification = notification => ({
    type: ADD_NOTIFICATION,
    payload: { notification }
})


export const getNotifications = (filters) => {
    return dispatch => {
        dispatch(setNotificationsActionBefore());

        return axios({
            url: ApiUrls.GET_NOTIFICATIONS,
            method: Methods.GET,
            params: filters
        })
        .then(({data : {value: notifications}}) => {
            dispatch(setUnreadNotificationCount(notifications.item1));
            dispatch(setNotifications(notifications.item2));
            dispatch(setNotificationsActionFinished());
        })
        .catch(() => {
            dispatch(setNotificationsActionFinished());
        })
    }
}

export const markNotificationAsRead = id => {
    return dispatch => {
        dispatch(markNotificationAsReadSuccess(id));
        return axios({
            url: ApiUrls.MARK_AS_READ_NOTIFICATION,
            method: Methods.POST,
            data: { id }
        })
        .then(({ data }) => {
            console.log(data)
        })
        .catch((ex) => {
            console.log(ex);
        })
    }
}

export const markAllNotificationsAsRead = () => {
    return dispatch => {
        dispatch(markAllNotificationsAsReadSuccess());
        return axios({
            url: ApiUrls.MARK_ALL_AS_READ_NOTIFICATIONS,
            method: Methods.POST
        })
        .catch((ex) => {
            console.log(ex);
        })
    }
}

export const deleteNotification = id => {
    return dispatch => {
        dispatch(deleteNotificationSuccess(id));
        return axios({
            url: ApiUrls.DELETE_NOTIFICATION,
            method: Methods.DELETE,
            data: { id }
        })
        .then(({ data }) => {
            console.log(data)
        })
        .catch((ex) => {
            console.log(ex);
        })
    }
}

export const deleteAllNotifications = () => {
    return dispatch => {
        return axios({
            url: ApiUrls.DELETE_ALL_NOTIFICATIONS,
            method: Methods.DELETE
        })
        .then(({ data }) => {
            dispatch(deleteAllNotificationsSuccess());
        })
        .catch((ex) => {
            console.log(ex);
        })
    }
}

