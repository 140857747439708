import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setCommissionPlansActionBefore, setCommissionPlansActionFinished, setCommissionPlansSaveActionBefore, setCommissionPlansSaveActionFinished } from './commissionPlans.action';

import { SET_COMMISSION_PLAN_SETTINGS } from "../../../../actionTypes";

const setCommissionPlanSettings = data => ({
    type: SET_COMMISSION_PLAN_SETTINGS,
    payload: { data }
})

export const getCommissionPlanSettings = (id, agentId)  => {
    return dispatch => {
        dispatch(setCommissionPlansActionBefore());
        return axios({
            url: ApiUrls.GET_COMMISSION_PLAN_SETTINGS,
            method: Methods.GET,
            params: {
                id , 
                agentId
            }
        })
        .then(({data : {value: settings}}) => {
            dispatch(setCommissionPlanSettings(settings))
            dispatch(setCommissionPlansActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansActionFinished());
        })
    }
}

export const saveCommissionPlanSettings = settings => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_COMMISSION_PLAN_SETTINGS,
            method: Methods.POST,
            data: {
                ...settings
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCommissionPlanSettings(data.value));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const changeCalculationPeriod = periodSettings => {
    return (dispatch, getState) => {
        dispatch(setCommissionPlansSaveActionBefore());

        const currentSettings = getState().commissionPlans.edit.settings;

        return axios({
            url: ApiUrls.SAVE_COMMISSION_PLAN_SETTINGS,
            method: Methods.POST,
            data: {
                id: periodSettings.id,
                agentId: periodSettings.agentId,
                period: periodSettings.period,
                calculationStartFromCurrentPeriod: periodSettings.calculationStartFromCurrentPeriod,
                source: currentSettings.source,
                name: currentSettings.name,
                onlineProviders: currentSettings.onlineProviders,
                retailProviders: currentSettings.retailProviders,
                settings: currentSettings.settings
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCommissionPlanSettings({
                    ...currentSettings,
                    period: data.value.period
                }));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}
