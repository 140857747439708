import React, {useMemo} from 'react';
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {RowContext} from "components/common/table/rowContext";
import {isMobile} from "utils/common";

const DraggableRow = ({sortableData, uniqueKey, ...props}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props['data-row-key'] });

    const isNotSortable = sortableData.findIndex(item => item[uniqueKey] === props['data-row-key']) === -1;
    const isNotDraggable = (
        ['ant-table-placeholder', 'ant-table-expanded-row']
            .some((className) => props.className.includes(className))
        || isNotSortable
    );

    const style = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging && !isNotDraggable ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners],
    );

    if (isNotDraggable) {
        return (
            isMobile()
                ? <div {...props} />
                : <tr {...props} />
        )
    }

    return (
        <RowContext.Provider value={contextValue}>
            {isMobile()
                ? <div {...props} ref={setNodeRef} style={style} {...attributes} />
                : <tr {...props} ref={setNodeRef} style={style} {...attributes} />
            }
        </RowContext.Provider>
    );
};

export default DraggableRow;
