import React, { useState } from "react";

import { Drawer as AntDrawer, Menu } from "antd";
import PropTypes from "prop-types";

import { classNames } from "NEW/src/core/helpers/common/classNames";
import DrawerFooterMobile from "NEW/src/core/ui/dropdown/mobile/footer";

const DropdownMobile = ({
	children,
	onOpenChange,
	disabled,
	popupOpened,
	dropdownRender,
	footer,
	className,
	title,
	onClose,
	menu,
}) => {
	const [opened, setOpened] = useState(false);

	const drawerClassName = classNames(
		"rt--modal rt--dropdown ",
		footer && "rt--dropdown-with-footer",
		className,
	);

	const handleClose = () => {
		if (onClose) {
			onClose();
		} else {
			setOpened(false);
			onOpenChange?.(false);
		}
	};

	const handleAfterOpenChange = (opened) => {
		onOpenChange?.(opened);
		setOpened(opened);
	};

	const renderContent = () => {
		let renderedMenu = null;

		if (dropdownRender || menu) {
			renderedMenu = <Menu {...menu} />;

			if (dropdownRender) {
				return dropdownRender(renderedMenu);
			}

			return renderedMenu;
		}

		return null;
	};

	return (
		<>
			<div
				onClick={(e) => {
					if (!disabled) {
						e.stopPropagation();
						e.preventDefault();
						setOpened(true);
						onOpenChange && onOpenChange(true);
					}
				}}
			>
				{children}
			</div>
			<AntDrawer
				// headerStyle={{ display: "none" }}
				open={popupOpened !== undefined ? popupOpened : opened}
				className={"rt--modal " + drawerClassName}
				title={title}
				placement="bottom"
				closable={true}
				maskClosable={true}
				onClose={handleClose}
				closeIcon={<i className="icon-close rt--font-bigest" />}
				getContainer={() =>
					document.getElementsByClassName("rt--dashboard-layout")[0] ||
					document.getElementsByClassName("rt--auth")[0]
				}
				afterOpenChange={handleAfterOpenChange}
				footer={footer ? <DrawerFooterMobile {...footer} /> : undefined}
			>
				<div>{renderContent()}</div>
			</AntDrawer>
		</>
	);
};

DropdownMobile.propTypes = {
	popupOpened: PropTypes.bool,
	footer: PropTypes.shape({
		okText: PropTypes.string,
		cancelText: PropTypes.string,
		onOk: PropTypes.func,
		onCancel: PropTypes.func,
	}),
	dropdownRender: PropTypes.func,
	className: PropTypes.string,
	title: PropTypes.string,
	onClose: PropTypes.func,
	menu: PropTypes.object,
	onOpenChange: PropTypes.func,
	disabled: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default DropdownMobile;
