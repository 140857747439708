//#region react
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
//#endregion

//#region actions
import {
    saveProjectBankFormTranslation
} from "store/actions/dashboard/projects/paymentsConfig.action";
//#endregion

//#region components
import Table from "components/common/table";
//#endregion

//#region utils
import { isFunction, isMobile } from "utils/common";
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { REGISTRATION_FORM_TYPE } from "constants/project.constants";
import { getTableColumns } from './columns';
//#endregion

const TABLE_ROW_UNIQUE_KEY = "text";

/** Bank Form Translations Component */
const BankFormTranslations = ({
    type,
    languageCode,
    saveProjectBankFormTranslation,
    translations,
    onChange
}) => {
    const { t } = useTranslation();
    const { search } = useLocation();

    const queryParams = new URLSearchParams(search);
    const currentBankId = queryParams.get("bankId");

    const isMobileDevice = isMobile();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_PAYMENT,
        action: PERMISSION_ACTION.MODIFY
    });

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleTranslationValueChange = (newValue, translationKey) => {
        saveProjectBankFormTranslation({
            bankId: currentBankId,
            languageCode: languageCode,
            text: newValue,
            type: type,
            translationKey,
        });

        if (isFunction(onChange)) {
            onChange(
                translations.map(translationData => ({
                    ...translationData,
                    key: translationData.translationKey
                }))
            );
        }
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = tableColumnsCreator({
        mainColumns: getTableColumns,
        additionalProps: {
            onTranslationValueChange: handleTranslationValueChange,
            havePermissionForEdit: hasModifyPermission,
        }
    })

    //#endregion

    return (
        <>
            <span className={"rt--form-section-title rt--title rt--flex rt--pb-16 rt--font-bold rt--font-biger" + (isMobileDevice ? " rt--mt-24" : " rt--mt-16")}>
                {t("backoffice.payments.translations")}
            </span>

            {translations.length > 0 && (
                <Table
                    columns={mainTableColumns}
                    data={translations}
                    uniqueKey={TABLE_ROW_UNIQUE_KEY}
                    noPagination={true}
                    disableFullView={true}
                />
            )}
        </>
    );
}

/** BankFormTranslations propTypes
    * PropTypes
*/
BankFormTranslations.propTypes = {
    /** Registration Form Type */
    type: PropTypes.oneOf(Object.values(REGISTRATION_FORM_TYPE)),
    /** The language code */
    languageCode: PropTypes.string,
    /** Redux action to save project project payment bank registration form */
    saveProjectBankFormTranslation: PropTypes.func,
    /** Function to fire on change */
    onChange: PropTypes.func,
    /** Translations */
    translations: PropTypes.arrayOf(PropTypes.shape({
        translationKey: PropTypes.string,
        text: PropTypes.string,
        value: PropTypes.string
    }))
}

const mapDispatchToProps = dispatch => (
    {
        saveProjectBankFormTranslation: translation => {
            dispatch(saveProjectBankFormTranslation(translation));
        },
    }
)


export default connect(null, mapDispatchToProps)(BankFormTranslations);