import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { Menu } from "antd";

import TableContext from '../../contex';

import { useTranslation } from "react-i18next";

import Dropdown from "components/common/dropdown";

import { isMobile, classNames, isFunction } from 'utils/common';

const DropdownView = ({ actions, rowData }) => {
    const { t } = useTranslation();

    const { showConfiramtionModal } = useContext(TableContext);

    const dropdownRef = useRef(null);

    const isMobileDevice = isMobile();

    const handleInternalClick = (action) => {
        if (action.disabled && action.disabled(rowData)) {
            return false
        }

        if (dropdownRef.current !== null) {
            dropdownRef.current.close()
        }

        if (action.showConfirmation) {
            showConfiramtionModal({
                rowData,
                title: action.confirmationTitle,
                message: action.confirmationMessage,
                onOk: action.onClick
            });
            return;
        }

        action.onClick(rowData);
    }


    return (
        <div className="rt--table-col-actions rt--flex rt--align-center rt--justify-end">
            {
                <Dropdown
                    ref={dropdownRef}
                    title={t("backoffice.common.actions")}
                    disabled={false}
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    menu={{
                        selectedKeys: [],
                        className: "rt--table-actions-menu",
                        items: actions.map((actionData, index) => ({
                            key: index,
                            label: (
                                <div className="rt--flex rt--align-center">
                                    {isMobile() && <i className={`${actionData.icon} rt--font-bigest`}/>}

                                    <span className="rt--title rt--font-regular rt--font-normal rt--pl-4 rt--pr-4">
                                        {
                                            isFunction(actionData.title)
                                                ? actionData.title(rowData)
                                                : actionData.title
                                        }
                                    </span>
                                </div>
                            ),
                            onClick: () => handleInternalClick(actionData),
                        }))
                    }}
                >
                    <div className="rt--table-col-actions-item rt--ml-8">
                        <i
                            className={classNames(
                                isMobileDevice ? "icon-more" : "icon-more",
                                "rt--font-bigest"
                            )}
                        />
                    </div>
                </Dropdown>
            }
        </div>
    );
}

/** DropdownView propTypes
 * PropTypes
*/
DropdownView.propTypes = {
    actions: PropTypes.array,
    rowData: PropTypes.object,
}

export default DropdownView;
