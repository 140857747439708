import {ENVIRONMENT_TYPE, ENVIRONMENT_TYPE_PARAM_NAME} from "NEW/src/core/data/constants/environment";
import {getQueryParams} from "NEW/src/core/helpers/common/getQueryParams";

export const getEnvironment = () => {
    if (import.meta.env.SYSTEM_APP_MODE === "production") {
        if (window.location.origin === import.meta.env.SYSTEM_RETAIL_DOMAIN) {
            return ENVIRONMENT_TYPE.RETAIL;
        } else if (window.location.origin === import.meta.env.SYSTEM_AGENT_SYSTEM_DOMAIN) {
            return ENVIRONMENT_TYPE.AGENT_SYSTEM;
        }
    }

    return Number(getQueryParams()[ENVIRONMENT_TYPE_PARAM_NAME]) ?? ENVIRONMENT_TYPE.AGENT_SYSTEM;
}
