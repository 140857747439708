import DesktopNavBar from "./desktop";
import MobileNavBar from "./mobile";

import useNavBar from "./hooks/useNavBar";

import { isMobile } from "utils/common";

const NavBar = isMobile() ? MobileNavBar : DesktopNavBar

NavBar.useNavBar = useNavBar

export default NavBar;