import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from "react-router-dom";

import EntityActions from "components/common/entityActions"
import ReparentComponent from "components/common/reparent";
import AgentDeleteComponent from "../agent-delete.component";
import SetPasswordComponent from "components/common/setPassword";

import { deleteAgent, changeAgentState, unlockAgent } from "store/actions/dashboard/agentSystem/agents/agents.action";

import { isFranchisingMode } from 'utils/common';
import { hasPermission } from 'utils/permissions';
import { hasAgentBlockAccess, hasAgentDeleteAccess, hasAgentReparentAccess, hasAgentSetPasswordAccess } from "utils/access"

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { REPARENT_ENTITY_TYPE } from 'constants/agent.constants';
import { USER_STATE } from 'constants/user.constants';
import Paths from 'constants/path.constants';

import userInfoType from 'types/profile/userInfo.type';
import agentGeneralInfoType from "types/agent/generalInfo.type";

/** Agent Actions Button Component */
const AgentActionsComponent = ({
    generalInfo,
    userInfo,
    deleteAgent,
    changeAgentState,
    unlockAgent
}) => {

    const { t } = useTranslation();

    const searchParams = useParams();
    const { search } = useLocation();
    const navigate = useNavigate();

    /** Flag to show/hide reparent popup */
    const [showReparentPopup, setShowReparentPopup] = useState(false);

    /** Flag to show/hide delete popup */
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    /** Flag to show/hide set password popup */
    const [showSetPasswordPopup, setShowSetPasswordPopup] = useState(false);

    /** Functoin to detect if can reparent on delete
       * @function
       * @memberOf AgentActionsComponent
   */
    const canReparentOnDelete = () => {
        const agentHasChildren = (
            generalInfo.subAgentsCount + generalInfo.playersCount + generalInfo.betShopsCount > 0
        )

        return agentHasChildren && !isFranchisingMode();
    }

    /** Actions list */
    const actions = [
        /** Reparent */
        {
            key: "reparent",
            buttonType: "primary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.reparent'),
            icon: "icon-reparent",
            onClick: () => setShowReparentPopup(true),
            disabled: (
                isFranchisingMode() ||
                !hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.MODIFY }) ||
                searchParams.id === userInfo.id ||
                !hasAgentReparentAccess(userInfo, generalInfo.parentId)
            )
        },
        /** Set Password */
        {
            key: "setPassword",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.setPassword'),
            icon: "icon-password",
            onClick: () => setShowSetPasswordPopup(true),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.MODIFY }) ||
                searchParams.id === userInfo.id ||
                !hasAgentSetPasswordAccess(userInfo, generalInfo.parentId)
            )
        },
        /** Block/Unblock */
        {
            key: "block",
            buttonType: "secondary",
            buttonClassName: generalInfo.state === USER_STATE.BLOCKED ? "rt--button-secondary" : "rt--button-danger",
            title: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            onClick: () => {
                changeAgentState({
                    id: searchParams.id,
                    enabled: generalInfo.state === USER_STATE.BLOCKED
                })
            },
            icon: "icon-block",
            isConfirmation: true,
            confirmationMessage: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.agents.doYouWanttoUnblock') : t('backoffice.agents.doYouWanttoBlock'),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.MODIFY }) ||
                searchParams.id === userInfo.id ||
                !hasAgentBlockAccess(userInfo, generalInfo.parentId)
            )
        },
        /** Unlock */
        {
            key: "unlock",
            buttonClassName: "rt--button-secondary",
            buttonType: "secondary",
            title: t('backoffice.common.unlock'),
            icon: "icon-lock",
            onClick: () => {
                unlockAgent(searchParams.id)
            },
            isConfirmation: true,
            confirmationMessage: t('backoffice.agents.doYouWanttoUnlock'),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.MODIFY }) ||
                searchParams.id === userInfo.id ||
                (generalInfo.state !== USER_STATE.LOCKED2FA && generalInfo.state !== USER_STATE.LOCKEDLOGIN)
            )
        },
        /** Delete */
        {
            key: "delete",
            buttonType: "danger",
            title: t('backoffice.common.delete'),
            icon: "icon-delete",
            buttonClassName: "rt--button-danger",
            onClick: () => {
                if (canReparentOnDelete()) {
                    setShowDeletePopup(true)
                } else {
                    deleteAgent(searchParams.id, () => {
                        navigate(isFranchisingMode() ? Paths.BETSHOP_OWNERS : Paths.AGENTS, { replace: true })
                    })
                }
            },
            isConfirmation: !canReparentOnDelete(),
            confirmationMessage: t('backoffice.agents.doYouWanttoDelete'),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.DELETE }) ||
                searchParams.id === userInfo.id ||
                !hasAgentDeleteAccess(userInfo, generalInfo.parentId) ||
                !generalInfo.isDeletable
            )
        },

    ]

    return (
        <Fragment>
            <EntityActions
                actions={actions}
            />

            {
                showReparentPopup && (
                    <ReparentComponent
                        onClose={() => setShowReparentPopup(false)}
                        type={REPARENT_ENTITY_TYPE.AGENT}
                        ids={[searchParams.id]}
                    />
                )
            }

            {
                showDeletePopup && (
                    <AgentDeleteComponent
                        onClose={() => setShowDeletePopup(false)}
                    />
                )
            }

            {
                showSetPasswordPopup && (
                    <SetPasswordComponent
                        onClose={() => setShowSetPasswordPopup(false)}
                    />
                )
            }
        </Fragment>

    )
}

/** AgentActionsComponent propTypes
    * PropTypes
*/
AgentActionsComponent.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux state, represents the general info of current editing agent  */
    generalInfo: agentGeneralInfoType,
    /** Redux action to delete agent */
    deleteAgent: PropTypes.func,
    /** Redux action to block/unblock Agent */
    changeAgentState: PropTypes.func,
    /** Redux action to unlock Agent */
    unlockAgent: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        deleteAgent: (id, onSuccess) => {
            dispatch(deleteAgent(id, undefined, onSuccess));
        },

        changeAgentState: data => {
            dispatch(changeAgentState(data));
        },

        unlockAgent: id => {
            dispatch(unlockAgent(id));
        },
    }
)

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        generalInfo: state.agents.edit.general,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentActionsComponent)