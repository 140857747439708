import PropTypes from 'prop-types';

export default PropTypes.shape({
    enabled: PropTypes.bool,
    allowedIps: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        from: PropTypes.string,
        to: PropTypes.string,
        blockedAt: PropTypes.string,
        blockedBy: PropTypes.string
    }))
})