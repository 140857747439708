import PATHS from "NEW/src/core/api/paths";
import { httpService } from "NEW/src/core/services/httpService";

export const companyService = {
	async getCompanies(data, configs) {
		const response = await httpService.get({
			path: PATHS.GET_COMPANIES,
			queryData: data,
			signal: configs.signal,
		});

		return response.data.value;
	},
};
