import React from 'react';
import PropTypes from 'prop-types';

import { classNames, isMobile } from 'utils/common';
import { getExtraContentAlignmentClass } from './helpers';

import { EXTRA_CONTENT_POSITIONS } from './constants';

const LeaderboardHeader = ({
    extraContent,
    extraContentPosition = EXTRA_CONTENT_POSITIONS.RIGHT,
    showTitle = true,
    title = "Top Users"
}) => {
    const isMobileDevice = isMobile();

    return (
        <div
            className={classNames(
                "rt--flex rt--align-center",
                isMobileDevice ? "rt--mb-16" : "rt--mb-24",
                Boolean(extraContent) && (
                    getExtraContentAlignmentClass({
                        isTitleVisible: showTitle, extraContentPosition
                    })
                ),
            )}
        >
            {showTitle && (
                <span
                    className={classNames(
                        "rt--chart-section-title rt--font-extra-bold",
                        isMobileDevice ? "rt--font-big" : "rt--font-bigest",
                        extraContentPosition === EXTRA_CONTENT_POSITIONS.LEFT && (
                            "rt--mr-6"
                        )
                    )}
                >
                    {title}
                </span>
            )}

            {Boolean(extraContent) && (
                <div>
                    {extraContent}
                </div>
            )}
        </div>
    );
}

/** LeaderboardHeader propTypes*/
LeaderboardHeader.propTypes = {
    extraContent: PropTypes.node,
    extraContentPosition: PropTypes.oneOf([
        EXTRA_CONTENT_POSITIONS.RIGHT,
        EXTRA_CONTENT_POSITIONS.LEFT,
    ]),
    showTitle: PropTypes.bool,
    title: PropTypes.string
};

export default LeaderboardHeader;