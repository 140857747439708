export const isCheckedAll = ({
    fieldName,
    resource,
    action,
    getFieldValue,
    getFieldsValue,
}) => {
    const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };
    let foundChecked = false;
    let foundUnChecked = false;

    if(resource && !values[resource]) return false;
    const resources = resource ? [resource] : Object.keys(values);

    resources.forEach(r => {
        if (foundUnChecked || !values[r]) {
            return;
        }
        for (let i = 0; i < Object.keys(values[r]).length; i++) {
            let actions = values[r][Object.keys(values[r])[i]];

            if (action) {
                if (actions[action] === false) {
                    foundUnChecked = true;
                } else if (actions[action] === true) {
                    foundChecked = true;
                }
            } else {
                Object.keys(actions).forEach(a => {
                    if (actions[a] === false) {
                        foundUnChecked = true;
                    } else if (actions[a] === true) {
                        foundChecked = true;
                    }
                })
            }

            if (foundUnChecked) {
                break;
            }
        }
    })

    return !foundUnChecked && foundChecked;
}
