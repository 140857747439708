export const COMMISSION_PLAN_SOURCE = {
    NGR: 1,
    GGR: 2,
    TURNOVER: 3,
    DEPOSIT: 4,
    WITHDRAWAL: 5
}

export const COMMISSION_PLAN_SOURCE_TYPE = {
    FIXED_AMOUNT: 1,
    PERCENT: 2
}

export const COMMISSION_PLAN_SOURCE_DIRECTION = {
    PLAYERS: 1,
    BETSHOPS: 2
}

export const COMMISSION_PLAN_STRUCTURE = {
    FLAT: 1,
    STRUCTURED: 2
}

export const COMMISSION_PLAN_TYPE = {
    DIRECT: 1,
    NETWORK: 2,
    MIX: 4
}

export const COMMISSION_PLAN_CALCULATION_PERIOD = {
    HOUR: 1,
    DAY: 2,
    WEEK: 4,
    TWO_WEEK: 8,
    THREE_WEEK: 16,
    MONTH: 32
}

export const COMMISSION_PLAN_RANGE_TYPE = {
    DEFAULT: 1,
    MIX: 2,
    SPORT_LIVE_SINGLE: 4,
    SPORT_LIVE_EXPRESS: 8,
    SPORT_PREMATCH_SINGLE: 16,
    SPORT_PREMATCH_EXPRESS: 32
}

export const INFINITY = "∞";
export const MINUS_INFINITY = 0;

export const COMMISSION_PLAN_CALCULATE_BY = {
    BET_COUNT: 1,
    ODD_RANGE: 2,
    DEFAULT_SOURCE: 3
}

export const COMMISSION_SETTINGS_TYPE = {
    GENERAL: 1,
    SPORT_PRE_MATCH: 2,
    SPORT_LIVE: 3
}

export const COMMISSION_INCOME_TYPE = {
    DIRECT: 1,
    NETWORK: 2,
    CARRY_OVER: 4
}

export const COMMISSION_PLAN_TURNOVER_MAX_PERCENT = 7;

export const COMMISSION_PLAN_STATUS = {
    ACTIVE: 1,
    DEACTIVE: 2,
}