import React from "react";

import Status from "components/common/status";

import { STATUS_TYPES } from "constants/status.constants";

import { TRANSFER_OPERATION_GROUP } from "components/common/transfer";

export const getTableColumns = (additionalProps) => {
    const {
        userInfo,
        formatAmount,
        hasLiteMode,
        showPlayerTransferPopup,
        transferToPlayerIconEnabled,
        hasPlayerGeneralInfoViewPermission
    } = additionalProps;

    return [
        {
            title: "backoffice.players.username",
            dataIndex: "userName",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        (hasLiteMode && hasPlayerGeneralInfoViewPermission) && 
        {
            title: "backoffice.players.operations",
            dataIndex: "operations",
            alwaysVisible: true,
            disableFillViewTooltip: true,
            mobileLevel: 2,
            render: (_, record) => {

                if (!transferToPlayerIconEnabled({ playerData: record, userInfo })) {
                    return null;
                }

                return (
                    <div className="rt--table-operations">
                        <button
                            onClick={() => showPlayerTransferPopup(record, TRANSFER_OPERATION_GROUP.DEPOSIT)}>
                            <i className="icon-deposit"/>
                        </button>
                        <button
                            onClick={() => showPlayerTransferPopup(record, TRANSFER_OPERATION_GROUP.WITHDRAWAL)}>
                            <i className="icon-withdrawal"/>
                        </button>
                    </div>
                );
            }
        },
        {
            title: "backoffice.players.id",
            dataIndex: "id",
            sorter: true,
            copy: true,
            alwaysVisible: true,
        },
        {
            title: "backoffice.common.balance",
            dataIndex: "balance",
            mobileLevel: hasLiteMode ? 6 : 7,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode),
        },
        {
            title: "backoffice.players.withdrawableBalance",
            dataIndex: "withdrawableBalance",
            mobileLevel: hasLiteMode ? 7 : 8,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode),
        },

        !hasLiteMode && {
            title: "backoffice.players.currency",
            dataIndex: "currencyCode",
            mobileLevel: 6,
        },
        {
            title: "backoffice.players.externalId",
            dataIndex: "externalId",
            sorter: true,
            copy: true,
        },
        {
            title: "backoffice.players.belongsTo",
            dataIndex: "parentName",
            mobileLevel: 4,
            render: (value) => value || "-",
        },
        {
            title: "backoffice.players.firstName",
            dataIndex: "firstName",
            render: (value) => value || "-",
        },
        {
            title: "backoffice.players.lastName",
            dataIndex: "lastName",
            render: (value) => value || "-",
        },
        {
            title: "backoffice.players.status",
            dataIndex: "state",
            sorter: true,
            mobileLevel: 5,
            render: (value) => <Status status={value} type={STATUS_TYPES.PLAYER} />,
        },
        {
            title: "backoffice.players.email",
            dataIndex: "email",
            render: (value) => value || "_",
        },

        {
            title: "backoffice.players.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: hasLiteMode ? undefined : 3,
        },
        {
            title: "backoffice.players.createdBy",
            dataIndex: "createdBy",
            sorter: true,
        },
        {
            title: "backoffice.players.lastUpdatedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.players.lastUpdateBy",
            dataIndex: "modifiedBy",
            sorter: true,
        },
    ]
};
