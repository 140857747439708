import PropTypes from 'prop-types';

import { WALLET_OPERATION_TYPE, WALLET_TYPE } from "constants/wallet.constants"

export default PropTypes.shape({
    additionalInfo: PropTypes.string,
    amountAfter: PropTypes.number,
    amountBefore: PropTypes.number,
    currencyCode: PropTypes.string,
    notes: PropTypes.string,
    transactionAmount: PropTypes.number,
    transactionId: PropTypes.number,
    transactionTime: PropTypes.string,
    transactionType: PropTypes.oneOf(Object.values(WALLET_OPERATION_TYPE)),
    userName: PropTypes.string,
    changes: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf(Object.values(WALLET_TYPE)), 
        before: PropTypes.number, 
        after: PropTypes.number
    }))
})