import { createSelector } from "NEW/src/core/lib/baseStore";
import { GlobalStore } from "NEW/src/core/stores/globalStore/store";

export const globalStore = new GlobalStore();

export const useGlobalSelector = createSelector(globalStore);

export const userInfoPermissionsSelector = (state) => state.userInfo.permissions;

export const userInfoSelector = (state) => state.userInfo;
