import React, { Fragment, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';

import {Dropdown as AntDropdown, Drawer, Button, Menu} from 'antd';

import { isMobile } from "utils/common";

/** Dropdown Component */
const Dropdown = forwardRef(({
    disabled,
    children,
    menu,
    dropdownRender,
    title,
    button,
    getPopupContainer,
    popupVisible,
    onVisibleChange,
    footer,
    onClose,
    className,
    overlayClassName,
    placement="bottomLeft"
}, ref) => {

    const [visible, setVisible] = useState(false);

    const scrollTop = useRef(0);

    const prps = {};
    if (popupVisible !== undefined) {
        prps.open = popupVisible;
    }

    /** Render for desktop
       * @function
       * @memberOf Dropdown
   */
    const renderForDesktop = () => {
        if (!button) {
            return (
                <AntDropdown
                    className={'rt--dropdown ' + (className ? className : "")}
                    disabled={disabled}
                    dropdownRender={dropdownRender}
                    menu={menu}
                    trigger={['click']}
                    placement={placement}
                    getPopupContainer={getPopupContainer}
                    onOpenChange={onVisibleChange}
                    overlayClassName={overlayClassName}
                    {
                    ...prps
                    }
                >
                    {children}
                </AntDropdown>
            )
        }

        return (
            <AntDropdown.Button
                type="primary"
                dropdownRender={dropdownRender}
                menu={menu}
                disabled={disabled}
                onClick={button.onClick}
                icon={(<i className={"icon-down-small rt--font-bigest"} />)}
                trigger={["click"]}
                className={'rt--button ' + (button.className || "")}
                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
            >
                <i className={button.icon + " rt--font-bigest rt--mr-4"}></i>
                {children}
            </AntDropdown.Button>
        )
    }

    /** Render for mobile
       * @function
       * @memberOf Dropdown
   */
    const renderForMobile = () => {
        if (!button) {
            return (
                <div onClick={e => {
                    if (!disabled) {
                        e.stopPropagation();
                        e.preventDefault();
                        setVisible(true);
                        onVisibleChange && onVisibleChange(true, {source: 'trigger'});
                    }
                }}>
                    {
                        children
                    }
                </div>
            )
        }

        return (
            <AntDropdown.Button
                type="primary"
                disabled={disabled}
                onClick={button.onClick}
                icon={(<i className={"icon-down-small rt--font-bigest"} />)}
                trigger={["click"]}
                className={'rt--button ' + (button.className || "")}
                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                open={false}
                onOpenChange={setVisible}
            >
                <i className={button.icon + " rt--font-bigest rt--mr-4"}></i>
                {
                    children
                }
            </AntDropdown.Button>
        )
    }

    useEffect(() => {
        const v = popupVisible !== undefined ? popupVisible : visible;
        if (v) {
            if (isMobile()) {
                scrollTop.current = document.documentElement.scrollTop;
                document.documentElement.classList.add("rt--no-scroll");
            }


        } else {
            if (isMobile()) {
                if (document.documentElement.classList.contains("rt--no-scroll")) {
                    document.documentElement.classList.remove("rt--no-scroll");
                    document.documentElement.scrollTop = scrollTop.current;
                }
            }

        }

    }, [popupVisible !== undefined ? popupVisible : visible])

    useEffect(() => () => {
        if (isMobile()) {
            document.documentElement.classList.remove("rt--no-scroll");
        }
    }, [])

    useImperativeHandle(ref, () => ({
        close: () => {
            setVisible(false);
            onVisibleChange && onVisibleChange(false, {source: 'trigger'});
        },
        open: () => {
            setVisible(true);
            onVisibleChange && onVisibleChange(true, {source: 'trigger'});
        }
    }));

    const renderedMenu = menu ? <Menu {...menu} /> : null

    return !isMobile() ? renderForDesktop() : (
        <Fragment>
            {
                renderForMobile()
            }
            <Drawer
                open={popupVisible !== undefined ? popupVisible : visible}
                className={'rt--modal rt--dropdown ' + (footer ? "rt--dropdown-with-footer " : "") + (className ? className : "")}
                title={title}
                placement="bottom"
                closable={true}
                maskClosable={true}
                onClose={() => {
                    if (onClose) {
                        onClose()
                    } else {
                        setVisible(false);
                        onVisibleChange && onVisibleChange(false, {source: 'trigger'});
                    }

                }}
                closeIcon={(<i className='icon-close rt--font-bigest' />)}
                getContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0] || document.getElementsByClassName("rt--auth")[0]}
                afterOpenChange={visibility => {
                    onVisibleChange && onVisibleChange(visibility, {source: 'trigger'});
                    setVisible(visibility);
                }}
                footer={footer ? (
                    <div className='rt--modal-footer rt--flex rt--justify-end rt--align-center'>
                        {
                            footer.cancelText && (
                                <Button
                                    type="link"
                                    className="rt--button rt--button-ghost rt--ml-16"
                                    onClick={footer.onCancel}
                                >
                                    <span>{footer.cancelText}</span>
                                </Button>
                            )
                        }

                        {
                            footer.okText && (
                                <Button
                                    type="primary"
                                    className="rt--button rt--button-primary rt--ml-16"
                                    onClick={footer.onOk}
                                >
                                    <span>{footer.okText}</span>
                                </Button>
                            )
                        }
                    </div>
                ) : undefined}
            >
                <div>
                    {
                        dropdownRender
                            ? dropdownRender(renderedMenu)
                            : renderedMenu
                    }
                </div>
            </Drawer>
        </Fragment>

    )
})

/** Dropdown propTypes
    * PropTypes
*/
Dropdown.propTypes = {
    /** Dropdown content */
    children: PropTypes.node,
    /** Dropdown menu props */
    menu: PropTypes.object,
    /** Dropdown menu render function */
    dropdownRender: PropTypes.func,
    /** Is dropdown disabled */
    disabled: PropTypes.bool,
    /** Modal title for mobile */
    title: PropTypes.string,
    /** Button dropdown props */
    button: PropTypes.shape({
        /** Button Icon */
        icon: PropTypes.string,
        /** Button text */
        text: PropTypes.string,
        /** Button click handler */
        onClick: PropTypes.func,
        /** Button class name */
        className: PropTypes.string
    }),
    /** Function to pass container of popup */
    getPopupContainer: PropTypes.func,
    /** Is popup visible */
    popupVisible: PropTypes.bool,
    /** Fires on popup visibility change */
    onVisibleChange: PropTypes.func,
    /** Footer settings for mobile */
    footer: PropTypes.shape({
        okText: PropTypes.string,
        cancelText: PropTypes.string,
        onOk: PropTypes.func,
        onCancel: PropTypes.func
    }),
    /** Function to fire on modal close */
    onClose: PropTypes.func,
    /** Class name for dropdown */
    className: PropTypes.string,
    /** Overlay Class name for dropdown */
    overlayClassName: PropTypes.string,
    /** Dropdown placement */
    placement: PropTypes.string
}

export default Dropdown;
