//#region react
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getBetshopManagers,
    setBetshopManagersSorting,
    setBetshopManagersFilters
} from "store/actions/dashboard/retail/betshopManagers/betshopManagers.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Filters from "./filters.component";
import BetshopManagerCreateComponent from "./betshopManager-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission, hasOneOfPermissions } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { BETSHOP_MANAGERS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import betshopManagerType from "types/betshopManager/betshopManager.type";
import sortingType from "types/common/sorting.type";
//#endregion

const ROW_UNIQUE_KEY_PROP = "userName";

const BetshopManagersComponent = ({
    getBetshopManagers,
    betshopManagers,
    isLoading,
    total,
    setBetshopManagersSorting,
    setBetshopManagersFilters,
    sorting,
    filters,
    globalCompanyId,
    globalProjectId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: BETSHOP_MANAGERS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETSHOP_MANAGER,
        action: PERMISSION_ACTION.EXPORT,
    });

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETSHOP_MANAGER,
        action: PERMISSION_ACTION.CREATE,
    });

    const hasGeneralInfoOrSessionViewPermission = hasOneOfPermissions([
        {
            resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_SESSIONS,
            action: PERMISSION_ACTION.VIEW,
        }
    ]);

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setBetshopManagersFilters({
            ...filters,
            userNameOrId: value ? item ? item.id : value : ""
        })
    }

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.BETSHOP_MANAGERS_EDIT}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.userName}`
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasGeneralInfoOrSessionViewPermission) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            placeholder: t("backoffice.betshopmanagers.userNameOrId"),
            loadFn: getBetshopManagers,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.BETSHOP_MANAGER,
                dependencies: [globalProjectId]
            },
            onSearch: handleSearchChange,
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        buttons: [{
            text: t("backoffice.betshopmanagers.createBetshopManager"),
            icon: "icon-plus",
            type: "primary",
            enabled: hasCreatePermission,
            onClick: showCreatePopup,
        }],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.betshopManagers') }]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.betshopManagers"),
            url: ApiUrls.EXPORT_BETSHOP_MANAGERS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={betshopManagers}
                loadFn={getBetshopManagers}
                sorting={sorting}
                setSortingFn={setBetshopManagersSorting}
                total={total}
                updateProps={[globalProjectId]}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                actions={tableRowActions}
            />

            {isCreatePopupVisible && <BetshopManagerCreateComponent onClose={hideCreatePopup} />}
        </MainDashboardLayout>
    )
};

/** BetshopManagersComponent propTypes
 * PropTypes
*/
BetshopManagersComponent.propTypes = {
    /** Redux action to get betshop Managers */
    getBetshopManagers: PropTypes.func,
    /** Redux state property, represents the array of betshop Managers  */
    betshopManagers: PropTypes.arrayOf(betshopManagerType),
    /** Redux state property, is true when loading betshop Managers */
    isLoading: PropTypes.bool,
    /** Redux state property, betshop Managers total count */
    total: PropTypes.number,
    /** Redux action to set betshop Managers sorting details */
    setBetshopManagersSorting: PropTypes.func,
    /** Redux action to set betshop Managers sorting details */
    setBetshopManagersFilters: PropTypes.func,
    /** Redux state property, betshop Managers sorting details */
    sorting: sortingType,
    /** Redux state property, betshop Managers filters */
    filters: PropTypes.object,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getBetshopManagers: nextPage => {
        dispatch(getBetshopManagers(nextPage));
    },
    setBetshopManagersSorting: sorting => {
        dispatch(setBetshopManagersSorting(sorting));
    },
    setBetshopManagersFilters: filters => {
        dispatch(setBetshopManagersFilters(filters));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.betshopManagers.isLoading,
        betshopManagers: state.betshopManagers.betshopManagers,
        total: state.betshopManagers.total,
        sorting: state.betshopManagers.sorting,
        filters: state.betshopManagers.filters,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BetshopManagersComponent);
