import { RESPONSE_CODES } from 'constants/errorCode.constants';

export const isResponseSuccess = data => {
    return data.resultCode === RESPONSE_CODES.Success
}

export const isResponseInternalServerError = (resultCode) => {
    return resultCode === RESPONSE_CODES.InternalServerError;
}

export const isResponseNotAvailable = (resultCode) => {
    return resultCode === RESPONSE_CODES.NotAvailable;
}