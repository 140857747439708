import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { SET_PROJECT_CHATUI } from "../../../actionTypes";

const setProjectChatUI = chatUI => ({
    type: SET_PROJECT_CHATUI,
    payload: { chatUI }
})

export const getProjectChatUI = () => {
    return (dispatch, getState) => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_CHATUI,
            method: Methods.GET,
            params:{
                id: getState().common.globalProjectId
            }
        })
        .then(({data : {value: chatUI}}) => {
            dispatch(setProjectChatUI(chatUI))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectChatUI = data => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_CHATUI,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectChatUI(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
