import React from "react"
import PropTypes from "prop-types";

import {Button, Col, Row} from "antd";
import Breadcrumbs from "components/common/breadcrumbs";

import { isMobile } from "utils/common";

import buttonType from 'types/components/button.type';
import breadcrumbsType from 'types/components/breadcrumbs.type';
import {hasPermission} from "utils/permissions";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import FormId from "components/common/formId";
import {useTranslation} from "react-i18next";

/** Main Form dashboard layout */
const MainFormDashboardLayout = ({
    children,
    buttons,
    breadcrumbs,
    header,
    id,
    longId,
    actions
}) => {
    const { t } = useTranslation();

    return (
        <div className={"rt--main-form-layout " + (!isMobile() ? "rt--pt-16 rt--pl-16 rt--pr-16 rt--pb-16" : "")}>
            <div className={"rt--main-form-layout-inner rt--flex rt--flex-col" + (!breadcrumbs ? " rt--main-form-layout-inner-without-header" : "")}>
                {
                    breadcrumbs && (
                        <div className={"rt--main-form-layout-header " + (isMobile() ? "rt--pl-16 rt--pr-12" : "rt--pb-8")}>
                            <div className="rt--main-form-layout-header-inner rt--flex rt--align-center rt--justify-between">
                                <Breadcrumbs
                                    {...breadcrumbs}
                                    className="rt--breadcrumbs-with-margin"
                                />
                            </div>
                        </div>
                    )
                }


                <div className="rt--main-form-layout-content rt--flex rt--flex-col rt--flex-equal">
                    <div className="rt--main-form-layout-content-content rt--flex rt--flex-col rt--flex-equal">

                        {
                            header && header.button && header.button.enabled && (
                                <div className={"rt--main-form-layout-content-content-header rt--align-center rt--flex rt--jsutify-brtween " + (!isMobile() ? "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16" : "rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12")}>
                                    <Button
                                        icon={<i className={header.button.icon + " rt--font-bigest"}></i>}
                                        type={header.button.type}
                                        className={
                                            "rt--button " +
                                            (header.button.className ? header.button.className : "") +
                                            (header.button.type === "primary" ? "rt--button-main" : "")
                                        }
                                        onClick={header.button.onClick}
                                        disabled={header.button.disabled ? true : false}
                                    >
                                        {
                                            header.button.text
                                        }

                                    </Button>
                                </div>
                            )
                        }
                        <div className={"rt--main-form-layout-content-content-form rt--flex-equal " + (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16" : "rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24")}>
                            {
                                (id || longId) && (
                                    <Row gutter={[16, 0]}>
                                        <Col span={24} >
                                            <div className={'rt--flex rt--justify-between rt--align-center ' + (isMobile() ? "rt--mb-16" : "rt--mb-24")}>
                                                <div className="rt--flex rt--align-center rt--flex-equal">
                                                    {
                                                        (
                                                            id && hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW })
                                                        ) && (
                                                            <FormId id={id} text={t("backoffice.common.id")} />
                                                        )
                                                    }
                                                    {longId && (
                                                        <div className="rt--ml-16">
                                                            <FormId
                                                                id={longId}
                                                                text={t("backoffice.common.longId")}
                                                            />
                                                        </div>)}
                                                </div>

                                                <div className='rt--flex rt--align-center rt--justify-end'>
                                                    {
                                                        actions
                                                            ? !isMobile() && actions
                                                                ? <div className="rt--tab-form-layout-actions">{actions}</div>
                                                                : null
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            }
                            {children}
                        </div>
                    </div>
                    {
                        buttons && buttons.some(b => b.enabled) && (
                            <div
                                className={
                                    "rt--main-form-layout-content-footer rt--flex rt--align-center " +
                                    (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8" : "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16") +
                                    (buttons.filter(b => b.enabled).length > 1 && isMobile() ? " rt--justify-between" : " rt--justify-end")
                                }
                            >
                                {
                                    buttons.map(b => b.enabled ? (
                                        <Button
                                            type={b.type}
                                            className={
                                                "rt--button" +
                                                (b.className ? b.className : "")
                                            }
                                            onClick={b.onClick}
                                            key={b.text}
                                            disabled={b.disabled ? true : false}
                                            htmlType={b.htmlType || "submit"}
                                            loading={b.loading}
                                        >
                                            {
                                                b.text
                                            }

                                        </Button>
                                    ) : null)
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>

    )
}

/** MainFormDashboardLayout propTypes
 * PropTypes
*/
MainFormDashboardLayout.propTypes = {
    /** Id */
    id: PropTypes.string,
    /** Long Id */
    longId: PropTypes.string,
    /** Actions */
    actions: PropTypes.node,
    /** Layout Content */
    children: PropTypes.node,
    /** Buttons */
    buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
    /** Breadcrumbs */
    breadcrumbs: PropTypes.shape(breadcrumbsType),
    /** Header */
    header: PropTypes.shape({
        /** Header button */
        button: PropTypes.shape(buttonType)
    }),
}

export default MainFormDashboardLayout;
