import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DateRangePicker from "components/common/dateRangePicker";

import { getCashiers, setCashiersFilters } from "store/actions/dashboard/retail/cashiers/cashiers.action";

import { FILTER_USER_STATE, FILTER_USER_ONLINE_STATE } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

/** Cashiers Page Filters Component */

const Filters = ({
    setCashiersFilters,
    getCashiers,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP, 
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={getCashiers}
            setFiltersFn={setCashiersFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false },
            ]}
            searchFieldName="userNameOrId"
            onInit={() => {
                getBetshopNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name="state"
                    >
                        <Select
                            options={[
                                { value: FILTER_USER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_USER_STATE.IN_PROGRESS, text: t('backoffice.common.inactive') },
                                { value: FILTER_USER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: FILTER_USER_STATE.BLOCKED, text: t('backoffice.common.blocked') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.cashiers.betshop')}
                        name="betShopId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') },
                                    ...betshopNames.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                ]
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.cashiers.betshop")}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.cashiers.state')}
                        name="onlineState"
                    >
                        <Select
                            options={[
                                { value: FILTER_USER_ONLINE_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_USER_ONLINE_STATE.ONLINE, text: t('backoffice.cashiers.online') },
                                { value: FILTER_USER_ONLINE_STATE.OFFLINE, text: t('backoffice.cashiers.offline') },
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.cashiers.state')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get cashiers */
    getCashiers: PropTypes.func,
    /** Redux action to set cashiers filters */
    setCashiersFilters: PropTypes.func,
    /** Redux state property, cashiers filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getCashiers: nextPage => {
            dispatch(getCashiers(nextPage));
        },
        setCashiersFilters: filters => {
            dispatch(setCashiersFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.cashiers.filters,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);