import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from "components/common/modal";
import ExpandTableView from '../expandtableView';

const ExpandViewModal = ({
    onCancel,
    modlaTitle,
    detailsObj,
    renderEmpty,
    isLoading,
    mobileListView,
    detailsLoadFn,
    rowData
}) => {

    return (
        <Modal
            title={modlaTitle}
            onCancel={onCancel}
            isLoading={false}
            className="rt--table-modal-details"
        >
            <ExpandTableView
                detailsObj={detailsObj}
                renderEmpty={renderEmpty}
                isLoading={isLoading}
                mobileListView={mobileListView}
                detailsLoadFn={detailsLoadFn}
                rowData={rowData}
            />
        </Modal>
    )
}

/** ExpandViewModal propTypes
 * PropTypes
*/
ExpandViewModal.propTypes = {
    modlaTitle: PropTypes.node,
    detailsObj: PropTypes.object,
    isLoading: PropTypes.bool,
    renderEmpty: PropTypes.func,
    mobileListView: PropTypes.bool,
    onCancel: PropTypes.func,
    detailsLoadFn: PropTypes.func,
    rowData: PropTypes.object
};

export default ExpandViewModal;