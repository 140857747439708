import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from 'components/common/select';

import { ENTITY_TYPE, OPERATIONAL_DASHBOARD_FILTER_NAMES, TIME_PERIOD } from 'pages/dashboard/constants';
import { FILTER_TYPE } from 'constants/common.constants';

const DesktopFilters = ({
    updateFilters,
    filters,
    showTimePeriodEntityType = true,
    hasRetail
}) => {
    const { t } = useTranslation();

    return (
        <>
            {showTimePeriodEntityType && (
                    <>
                        <Select
                            options={
                                [
                                    { value: ENTITY_TYPE.AGENT, text: t("backoffice.dashboard.byAgent") },
                                    { value: ENTITY_TYPE.PLAYER, text: t("backoffice.dashboard.byPlayer") }
                                ].concat(hasRetail ? [
                                    { value: ENTITY_TYPE.BETSHOP, text: t("backoffice.dashboard.byBetshop") }
                                ]: [])
                            }
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            onChange={value => updateFilters({ [OPERATIONAL_DASHBOARD_FILTER_NAMES.ENTITY_TYPE]: value })}
                            className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle rt--mr-16"
                            value={filters[OPERATIONAL_DASHBOARD_FILTER_NAMES.ENTITY_TYPE]}
                        />
                        <Select
                            options={
                                [
                                    { value: TIME_PERIOD.TODAY, text: t("backoffice.dashboard.today") },
                                    { value: TIME_PERIOD.YESTERDAY, text: t("backoffice.dashboard.yesterday") },
                                    { value: TIME_PERIOD.THIS_WEEK, text: t("backoffice.dashboard.thisWeek") },
                                    { value: TIME_PERIOD.LAST_WEEK, text: t("backoffice.dashboard.lastWeek") },
                                    { value: TIME_PERIOD.THIS_MONTH, text: t("backoffice.dashboard.thisMonth") },
                                    { value: TIME_PERIOD.LAST_MONTH, text: t("backoffice.dashboard.lastMonth") },
                                    { value: TIME_PERIOD.LAST_QUARTER, text: t("backoffice.dashboard.lastQuarter") },
                                    { value: TIME_PERIOD.THIS_YEAR, text: t("backoffice.dashboard.ytd") },
                                ]
                            }
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle rt--mr-16"
                            onChange={value => updateFilters({ [OPERATIONAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD]: value })}
                            value={filters[OPERATIONAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD]}
                        />
                    </>
                )}
                <Select
                    options={[
                        { value: FILTER_TYPE.DIRECT, text: t("backoffice.dashboard.direct") },
                        { value: FILTER_TYPE.NETWORK, text: t("backoffice.dashboard.network") },
                    ]}
                    value={filters[OPERATIONAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE]}
                    onChange={(value) => updateFilters({ [OPERATIONAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE]: value })}
                    className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle"
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                />
        </>
    )
}

DesktopFilters.propTypes = {
    updateFilters: PropTypes.func.isRequired,
    filters: PropTypes.object,
    hasRetail: PropTypes.bool,
    showTimePeriodEntityType: PropTypes.bool,
}

export default DesktopFilters;