import React from 'react';
import PropTypes from 'prop-types';

import MobileFilters from './mobile';
import DesktopFilters from './desktop';

import { isMobile } from 'utils/common';

const Filters = ({ filters, updateFilters, userCurrencies }) => {
    return (
        isMobile()
            ? <MobileFilters filters={filters} setFinancialFilters={updateFilters} userCurrencies={userCurrencies} />
            : <DesktopFilters filters={filters} updateFilters={updateFilters} userCurrencies={userCurrencies} />
    )
}

Filters.propTypes = {
    filters: PropTypes.object.isRequired,
    updateFilters: PropTypes.func.isRequired,
    userCurrencies: PropTypes.array,
}

export default Filters