import React from "react";

import { useTranslation } from "react-i18next";

import Tabs from "components/common/tabs";

import PendingComponent from "./pending/pending.component";
import SettledComponent from "./settled/settled.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Retail Bet History Page Component */
const BetHistoryComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.bets.pending"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETS, action: PERMISSION_ACTION.VIEW }],
            component: <PendingComponent />
        },
        {
            title: t("backoffice.bets.settled"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETS, action: PERMISSION_ACTION.VIEW }],
            component: <SettledComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={[
                { title: t('backoffice.menu.betHistory') },
                { title: t('backoffice.menu.retail') }
            ]}
        />
    )
};

export default BetHistoryComponent
