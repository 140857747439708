import PATHS from "NEW/src/core/api/paths";
import { httpService } from "NEW/src/core/services/httpService";

export const notificationService = {
	getAll(filters) {
		return httpService.get({
			path: PATHS.GET_NOTIFICATIONS,
			queryData: filters,
		});
	},

	markAsRead(id) {
		return httpService.post({
			path: PATHS.MARK_AS_READ_NOTIFICATION,
			requestBody: { id },
		});
	},

	markAllAsRead() {
		return httpService.post({
			path: PATHS.MARK_ALL_AS_READ_NOTIFICATIONS,
		});
	},

	delete(id) {
		return httpService.delete({
			path: PATHS.DELETE_NOTIFICATION,
			requestBody: { id },
		});
	},

	deleteAll() {
		return httpService.delete({
			path: PATHS.DELETE_ALL_NOTIFICATIONS,
		});
	},
};
