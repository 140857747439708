import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_BEFORE,
    SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_FINISH,
    SET_BETSHOP_TRANSACTIONS_REPORT,
    SET_BETSHOP_TRANSACTIONS_REPORT_SORTING,
    SET_BETSHOP_TRANSACTIONS_REPORT_FILTERS
} from "../../../actionTypes";

import { WALLET_OPERATION_TYPE, WALLET_OWNER_TYPE } from "constants/wallet.constants";

export const setBetshopTransactionsReportActionBefore = () => ({
    type: SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_BEFORE,
});

export const setBetshopTransactionsReportActionFinished = () => ({
    type: SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_FINISH,
});

const setBetshopTransactionsReport = (report, add) => ({
    type: SET_BETSHOP_TRANSACTIONS_REPORT,
    payload: { report, add },
});

export const setBetshopTransactionsReportSorting = sorting => ({
    type: SET_BETSHOP_TRANSACTIONS_REPORT_SORTING,
    payload: { sorting },
});

export const setBetshopTransactionsReportFilters = filters => ({
    type: SET_BETSHOP_TRANSACTIONS_REPORT_FILTERS,
    payload: { filters },
});


export const getBetshopTransactionsReport = nextPage => {
    return (dispatch, getState) => {

        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().betshopTransactionsReport.sorting.page + 1 : getState().betshopTransactionsReport.sorting.page;
        const filters = {...getState().betshopTransactionsReport.filters}

        if(!filters.transactionTypes || filters.transactionTypes.length === 0){
            filters.transactionTypes = [
                WALLET_OPERATION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER,
                WALLET_OPERATION_TYPE.BETSHOP_MANAGER_WITHDRAWAL_CASHIER,
                WALLET_OPERATION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT,
                WALLET_OPERATION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT,
            ]
        }

        let participants = "";
        if(filters.cashier || filters.betShopId){
            participants = [];

            if(filters.betShopId){
                participants.push({
                    ownerType: WALLET_OWNER_TYPE.BETSHOP,
                    userNameOrId: filters.betShopId
                })
            }

            if(filters.cashier){
                participants.push({
                    ownerType: WALLET_OWNER_TYPE.CASHIER,
                    userNameOrId: filters.cashier
                })
            }
        }

        filters.participants = participants;

        delete filters.cashier;

        let data = {
            ...getState().betshopTransactionsReport.sorting,
            ...filters,
            page: page
        };

        if(page > 1 && getState().betshopTransactionsReport.total <= ( page - 1 ) * getState().betshopTransactionsReport.sorting.limit){
            return Promise.resolve();
        }

        dispatch(setBetshopTransactionsReportActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_TRANSACTIONS_REPORT,
            method: Methods.POST,
            data: data,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setBetshopTransactionsReport(report, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setBetshopTransactionsReportSorting({ ...getState().betshopTransactionsReport.sorting, page: page }));
                dispatch(setBetshopTransactionsReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopTransactionsReportActionFinished());
            });
    }
}
