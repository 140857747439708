import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';

import ReparentFormComponent from "./form";
import ReparentResultComponent from "./result";
import Modal from "components/common/modal";

import {
    reparentAgent,
    getAvailableAgentsForReparenting,
    getAvailableAgentsForPlayersReparenting
} from "store/actions/dashboard/agentSystem/agents/agents.action";
import { reparentBetshop } from "store/actions/dashboard/retail/betshops/betshops.action";
import { reparentPlayer, bulkReparentPlayer } from "store/actions/dashboard/players/players.action";

import { POPUP_SIZE } from "constants/common.constants";
import { REPARENT_ENTITY_TYPE } from "constants/agent.constants";

/** Reparent Popup Component */
const ReparentComponent = ({
    reparentAgent,
    reparentBetshop,
    reparentPlayer,
    bulkReparentPlayer,
    getAvailableAgentsForReparenting,
    getAvailableAgentsForPlayersReparenting,
    type,
    ids = [],
    multiple = false,
    onClose,
    onSuccess
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const [result, setResult] = useState(null)

    useEffect(() => {
        if (!multiple) {
            getAvailableAgentsForReparenting(ids[0], type)
        } else {
            if (type === REPARENT_ENTITY_TYPE.PLAYER) {
                getAvailableAgentsForPlayersReparenting(ids)
            }
        }
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf ReparentComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                if (type === REPARENT_ENTITY_TYPE.AGENT) {
                    reparentAgent(ids[0], data.parentId || undefined, () => {
                        onSuccess && onSuccess();
                        onClose();
                    })
                } else if (type === REPARENT_ENTITY_TYPE.BETSHOP) {
                    reparentBetshop(ids[0], data.parentId || undefined, () => {
                        onSuccess && onSuccess();
                        onClose();
                    })
                } else if (type === REPARENT_ENTITY_TYPE.PLAYER) {
                    if (!multiple) {
                        reparentPlayer(ids[0], data.parentId || undefined, () => {
                            onSuccess && onSuccess();
                            onClose();
                        })
                    } else {
                        bulkReparentPlayer(ids, data.parentId || undefined, d => {
                            onSuccess && onSuccess(d);
                            setResult({
                                response: d,
                                ids: ids
                            })
                        })
                    }

                }

            }).catch(err => {
                console.log(err)
            })
    }
    return (
        <Modal
            title={ 
                result === null ? 
                t('backoffice.common.reparent') : 
                t('backoffice.common.reparentResult')
            }
            cancelText={t('backoffice.common.close')}
            okText={ result === null ? t('backoffice.common.save') : null}
            onOk={handleForm}
            onCancel={onClose}
            size={POPUP_SIZE.SMALL}
            hideOkButton={result !== null}
        >
            {
                result ? (
                    <ReparentResultComponent 
                        type={type}
                        result={result}
                    />
                ) : (
                    <ReparentFormComponent
                        formInstance={formInstance}
                    />
                )
            }

        </Modal>
    )
}

/** ReparentComponent propTypes
    * PropTypes
*/
ReparentComponent.propTypes = {
    /** Redux action to reparent agent */
    reparentAgent: PropTypes.func,
    /** Redux action to reparent betshop */
    reparentBetshop: PropTypes.func,
    /** Redux action to reparent player */
    reparentPlayer: PropTypes.func,
    /** Redux action to bulk reparent player */
    bulkReparentPlayer: PropTypes.func,
    /** Redux action to load available agents for reparenting */
    getAvailableAgentsForReparenting: PropTypes.func,
    /** Redux action to load available agents for multiple players reparenting */
    getAvailableAgentsForPlayersReparenting: PropTypes.func,
    /** Entity type */
    type: PropTypes.oneOf(Object.values(REPARENT_ENTITY_TYPE)),
    /** Array of id's which should be reparent */
    ids: PropTypes.arrayOf(PropTypes.string),
    /** Multiple Reparent */
    multiple: PropTypes.bool,
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on reparent success */
    onSuccess: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        reparentAgent: (id, parentId, onSuccess) => {
            dispatch(reparentAgent(id, parentId, onSuccess));
        },

        reparentBetshop: (id, parentId, onSuccess) => {
            dispatch(reparentBetshop(id, parentId, onSuccess));
        },

        reparentPlayer: (id, parentId, onSuccess) => {
            dispatch(reparentPlayer(id, parentId, onSuccess));
        },

        bulkReparentPlayer: (ids, parentId, onSuccess) => {
            dispatch(bulkReparentPlayer(ids, parentId, onSuccess));
        },

        getAvailableAgentsForReparenting: (id, type) => {
            dispatch(getAvailableAgentsForReparenting(id, false, type));
        },

        getAvailableAgentsForPlayersReparenting: ids => {
            dispatch(getAvailableAgentsForPlayersReparenting(ids));
        },
    }
)

export default connect(null, mapDispatchToProps)(ReparentComponent)