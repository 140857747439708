import React from "react";

import { languagesDropdownMenu } from "NEW/src/layouts/global/auth/constants";

import { isMobile } from "NEW/src/core/helpers/appEnvironment/isMobile";
import { setLocale } from "NEW/src/core/helpers/language/setLocale";
import { useTranslation } from "NEW/src/core/hooks/useTranslation";
import { translations } from "NEW/src/core/lib/translations";
import {useGlobalSelector} from "NEW/src/core/stores/globalStore";
import Dropdown from "NEW/src/core/ui/dropdown";
import Flag from "NEW/src/core/ui/flag";

const LanguagesDropdown = () => {
	const { languages = {} } = useGlobalSelector();

	const { t, locale } = useTranslation();

	const menuItems = languagesDropdownMenu(locale, languages, t);

	const handleChangeLocale = ({ key }) => {
		translations.changeLocale(key).then((changedLocale) => {
			setLocale(changedLocale);
		});
	};

	return (
		<Dropdown
			className={!isMobile() ? "rt--languages" : ""}
			title={t("backoffice.languages.language")}
			menu={{
				className: "rt--languages-list",
				items: menuItems,
				onClick: handleChangeLocale,
			}}
		>
			<div
				className={
					"rt--languages-element rt--flex rt--align-center rt--pl-12 rt--pr-12" +
					(Object.keys(languages).length <= 1 ? " rt--languages-element-disabled" : "")
				}
			>
				<Flag code={locale} />
			</div>
		</Dropdown>
	);
};

export default LanguagesDropdown;
