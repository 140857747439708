export const SOCIAL_MEDIA_TYPE = {
    TELEGRAM: 1,
    WHATSAPP: 2
}

export const BANK_EPOS_VALUE = -1;

export const FIELD_NAMES = {
    HIDE_HISTORY: "hideHistory",
    WALLET_TYPES: "walletTypes",
    WALLET_CONFIGS: "walletConfigs",
    BANK: "bank",
    EPOS: "epos",
}