import React from "react";

import OperationalDashboard from "pages/dashboard/operational";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import withEnvironment from "hocs/withEnvironment";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import { ENVIRONMENT_TYPE } from "constants/common.constants";

const OperationalRoute = () => {
    return <OperationalDashboard />;
};

export default withPermission(
    withEnvironment(withAuth(OperationalRoute), [
        { environmentType: ENVIRONMENT_TYPE.AGENT_SYSTEM },
    ]),
    { resource: PERMISSION_RESOURCE.DASHBOARD_OPERATIONS, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);