import i18n from 'translations/config';

/** Validate function for min limit field
       * @function
       * @param {string} minValue - value of min limit field
       * @param {string} maxValue - value of max limit field
   */
export const validateMin = (minValue, maxValue) => {
    let max = Number(maxValue);
    let min = Number(minValue);
    if (!isNaN(min) && min >= 1 && !isNaN(max) && max >= 1 && min > max) {
        return Promise.reject(i18n.t('backoffice.validation.minMaxLimit'))
    }
    return Promise.resolve();
}

/** Validate function for max limit field
   * @function
   * @param {string} minValue - value of min limit field
   * @param {string} maxValue - value of max limit field
   * @param {string} posMaxValue - value of possibleWinMax field
*/
export const validateMax = (minValue, maxValue, posMaxValue) => {
    let max = Number(maxValue);
    let min = Number(minValue);
    let posMax = Number(posMaxValue);
    if (!isNaN(min) && min >= 1 && !isNaN(max) && max >= 1 && min > max) {
        return Promise.reject(i18n.t('backoffice.validation.minMaxLimit'))
    } else if (!isNaN(max) && max >= 1 && !isNaN(posMax) && posMax >= 1 && max > posMax) {
        return Promise.reject(i18n.t('backoffice.validation.maxPosMax'))
    }
    return Promise.resolve();
}

/** Validate function for singlePossibleWinMax field
   * @function
   * @param {string} maxValue - value of max limit field
   * @param {string} posMaxValue - value of possibleWinMax field
*/
export const validatePosMax = (maxValue, posMaxValue) => {
    let max = Number(maxValue);
    let posMax = Number(posMaxValue);
    if (!isNaN(max) && max >= 1 && !isNaN(posMax) && posMax >= 1 && max > posMax) {
        return Promise.reject(i18n.t('backoffice.validation.maxPosMax'))
    }
    return Promise.resolve();
}

/** Get favorite bets fields, which have duplicate values
       * @function
       * @param {array} favoriteBets - value of favorite bets fields
       * @returns {array} - array of indexes of fields
*/
export const favoriteBetsRepeatingIndexes = favoriteBets => {
    let duplicates = [];
    const favBets = [... favoriteBets];
    favBets.forEach((el, i) => {
        if (favBets.filter(f => f === el).length > 1 && el !== "") duplicates.push(i)
    })
    return duplicates
}

/** 
 * Gets the currency field options for the currency field
*/
export const getCurrencyFieldOptions = (userCurrencies, propsKeyNames = {}) => {
    if(!Array.isArray(userCurrencies)) {
        console.error(new Error("userCurrencies must be an array"));
        return [];
    }
    
    const { key = "value", text = "text" } = propsKeyNames;

    return (
        userCurrencies.map(currencyData => {
            const currencyCode = currencyData.currency || currencyData.code;
    
            return {
                [key]: currencyCode.toUpperCase(),
                [text]: currencyCode.toUpperCase(),
            }
        })
    )
}
