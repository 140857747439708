export const setAgentNodes = (id, data, nodes) => {
    return data.map(item => {
        if (item.key === id) {
            return {
                ...item,
                children: nodes
            }
        }

        if (item.children) {
            return {
               ...item,
                children: setAgentNodes(id, item.children, nodes)
            }
        }

        return item;
    })
}
