import React, {useEffect, useMemo, useState} from 'react';
import Tooltip from "components/common/tooltip";
import useFormat from "hooks/useFormat";
import {BETSLIP_FILTER_TYPE} from "constants/filter.constants";
import {getTotalsCardData} from "pages/dashboard/api";
import {TOTALS_TYPES_MAP} from "pages/dashboard/constants";
import {Spin} from "antd";
import {isNull, isUndefined} from "utils/javaScriptTypes";

const pickTotalsValues = (type, data) => {
    return {
        amount: data[type + 'Amount'],
        count: data[type + 'Count']
    }
}

const useTotals = ({ type, filters, canCall, updateLastUpdatedDate }) => {
    const [loading, setLoading] = useState(false);
    const [totals, setTotals] = useState({});

    const callOptions = useMemo(() => ({
        betSlipFilterType: TOTALS_TYPES_MAP[type]
    }), [type]);

    useEffect(() => {
        if (canCall && filters.currencyCode) {
            setLoading(true);

            const {request} = getTotalsCardData({ ...callOptions, ...filters });

            request
                .then(({data: {value}}) => {
                    setTotals(pickTotalsValues(type, value.data));
                    updateLastUpdatedDate(value.lastUpdatedDate);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [type, callOptions, filters, canCall, updateLastUpdatedDate]);

    return {
        loading,
        totals
    }
}

const TotalsCard = ({
    type,
    filters,
    color = "#000",
    title,
    subtitle,
    infoText,
    isActive,
    updateLastUpdatedDate,
}) => {
    const { formatNumber } = useFormat();

    const canCall = isActive || isNull(isActive) || isUndefined(isActive)

    const { loading, totals} = useTotals({
        type,
        filters,
        canCall,
        updateLastUpdatedDate
    });

    let amount = isUndefined(totals.amount) ? '-' : formatNumber(totals.amount);
    let count = isUndefined(totals.count) ? '-' : formatNumber(totals.count);

    return (
        <div className="rt--totals-card">
            {loading ? (
                <Spin spinning />
            ) : (
                <div
                    style={{
                        "--rt-total-color": color,
                    }}
                    className="rt--totals-card-contetnWrapper"
                >
                    <div className="rt--totals-card-title">
                        {title}

                        {!!infoText && (
                            <Tooltip title="Some title" placement="top">
                                <i className="icon-info"/>
                            </Tooltip>
                        )}
                    </div>
                    <div className="rt--totals-card-total">
                        {amount}
                    </div>
                    <div className="rt--totals-card-subtotal">
                        {subtitle}: <span>{count}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TotalsCard;
