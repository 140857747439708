import React from 'react';
import { useTranslation } from "react-i18next";

const NoData = () => {
    const { t } = useTranslation();

    return (
        <div className="rt--chart-operational-top-agents-empty rt--flex rt--flex-col rt--justify-center rt--align-center rt--pt-24 rt--pb-24">
            <i className="icon-empty rt--font-bigest" />

            <h5 className="rt--font-bold rt--font-bigest rt--mb-6 rt--mt-16">
                {t('backoffice.common.noData')}
            </h5>
        </div>
    );
}

export default NoData;