export const PERMISSION_REQUEST_TYPE = {
    GROUP_MODIFY: 1 << 0,
    ADD_COMPANY_ACCESS: 1 << 1,
    DELETE_COMPANY_ACCESS: 1 << 2,
    ADD_PROJECT_ACCESS: 1 << 3,
    DELETE_PROJECT_ACCESS: 1 << 4,
    ADD_PERMISSION_GROUP: 1 << 5,
    DELETE_PERMISSION_GROUP: 1 << 6,
    SET_PASSWORD: 1 << 7,
}

export const PERMISSION_REQUEST_OBJECT_TYPE = {
    PERMISSION_GROUP: 1 << 0,
    USER: 1 << 1
}

export const PERMISSION_REQUEST_STATUS = {
    APPROVED: 1,
    REJECTED: 2
}