import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from "react-router-dom";

import EntityActions from "components/common/entityActions"
import ReparentComponent from "components/common/reparent";

import { changeBetshopState } from "store/actions/dashboard/retail/betshops/betshops.action";

import { hasPermission } from 'utils/permissions';
import { hasBetshopBlockAccess, hasBetshopReparentAccess } from "utils/access";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { REPARENT_ENTITY_TYPE } from 'constants/agent.constants';
import { USER_STATE } from 'constants/user.constants';

import userInfoType from 'types/profile/userInfo.type';
import betshopGeneralInfoType from "types/betshop/generalInfo.type";

/** Betshop Actions Button Component */
const BetshopActionsComponent = ({
    generalInfo,
    userInfo,
    changeBetshopState
}) => {

    const { t } = useTranslation();

    const searchParams = useParams();
    const navigate = useNavigate();

    /** Flag to show/hide reparent popup */
    const [showReparentPopup, setShowReparentPopup] = useState(false);

    /** Actions list */
    const actions = [
        /** Reparent */
        {
            key: "reparent",
            buttonType: "primary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.reparent'),
            icon: "icon-reparent",
            onClick: () => setShowReparentPopup(true),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.MODIFY }) ||
                !hasBetshopReparentAccess(userInfo, generalInfo.agentId)
            )
        },
        /** Block/Unblock */
        {
            key: "block",
            buttonType: generalInfo.state === USER_STATE.BLOCKED ? "primary" : "secondary",
            title: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            buttonClassName: generalInfo.state === USER_STATE.BLOCKED ? "rt--button-secondary" : "rt--button-danger",
            onClick: () => {
                changeBetshopState({
                    id: searchParams.id,
                    enabled: generalInfo.state === USER_STATE.BLOCKED
                })
            },
            icon: "icon-block",
            isConfirmation: true,
            confirmationMessage: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.betshops.doYouWanttoUnblockBetshop') : t('backoffice.betshops.doYouWanttoBlockBetshop'),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.MODIFY }) ||
                !hasBetshopBlockAccess(userInfo, generalInfo.agentId)
            )
        }
    ]

    return (
        <Fragment>
            <EntityActions 
                actions={actions}
            />

            {
                showReparentPopup && (
                    <ReparentComponent
                        onClose={() => setShowReparentPopup(false)}
                        type={REPARENT_ENTITY_TYPE.BETSHOP}
                        ids={[searchParams.id]}
                    />
                )
            }
        </Fragment>
        
    )
}

/** BetshopActionsComponent propTypes
    * PropTypes
*/
BetshopActionsComponent.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux state, represents the general info of current editing betshop  */
    generalInfo: betshopGeneralInfoType,
    /** Redux action to block/unblock betshops */
    changeBetshopState: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        changeBetshopState: data => {
            dispatch(changeBetshopState(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        generalInfo: state.betshops.edit.general
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BetshopActionsComponent)