import { setLocaleToStorage } from "NEW/src/core/helpers/language/setLocaleToStorage";

export const setLocale = (locale) => {
	setLocaleToStorage(locale);

	const params = new URLSearchParams(window.location.search);
	params.set("language", locale);

	const url = window.location.origin + window.location.pathname + "?" + params.toString();

	window.history.pushState({ path: url }, "", url);
};
