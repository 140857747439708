//#region react
import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getAgentPerformanceReport,
    setAgentPerformanceReportFilters,
    setAgentPerformanceReportSorting,
    getAgentPerformanceReportTotals
} from 'store/actions/dashboard/reports/agentPerformanceReport.action';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import NavBar from 'components/common/navBar';
import Filters from 'pages/reports/performanceReport/sections/agent/filters.components';
import TotalsComponent from 'components/common/totals';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
//#endregion

//#region utils
import {getPossibleParentsName, isMobile} from "utils/common";
import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { USER_ROLE, USER_STATE } from "constants/user.constants";
import { AGENT_REPORT_TYPES, REPORT_TOTAL_TYPE} from 'constants/reports.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { getExpandColumns, getTableColumns } from 'pages/reports/performanceReport/sections/agent/columns';
import {
    EXPANDED_ROW_UNIQUE_KEY_PROP,
    PERFORMANCE_REPORT,
    ROW_UNIQUE_KEY_PROP
} from "pages/reports/performanceReport/sections/agent/constants";
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import userInfoType from 'types/profile/userInfo.type';
import agentPerformanceReportType from "types/reports/agentPerformanceReport.type";
import agentPerformanceReportTotalsType from "types/reports/agentPerformanceReportTotals.type";
import useProjectType from "hooks/useProjectType";
import useAutosuggestion from "hooks/useAutosuggestion";
//#endregion

/** Agent Performance Report Page Component */
const AgentPerformanceReport = ({
    globalProjectId,
    globalCompanyId,
    isLoading,
    report,
    total,
    sorting,
    filters,
    getAgentPerformanceReport,
    setAgentPerformanceReportFilters,
    setAgentPerformanceReportSorting,
    getAgentPerformanceReportTotals,
    totals,
    userInfo,
    isTotalsLoading,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { formatAmount } = useFormat();

    const { hasLiteMode } = useProjectType();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PERFORMANCE_REPORT });

    const [navBarInstance] = NavBar.useNavBar({
        defaultPage: t(`backoffice.menu.${PERFORMANCE_REPORT}`),
    });
    const { addToNavBar, resetNavBar, navBarActiveItem } = navBarInstance;

    const { role: userRole } = getUser();
    const isMobileDevice = isMobile();

    const [ agents ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        autoGet: true,
        dependencies: [globalProjectId],
        actionParams: {
            id: userRole !== USER_ROLE.ADMIN ? userInfo.id : undefined,
            excludeAgent: userRole === USER_ROLE.ADMIN
        }
    });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_AGENT_PERFORMANCE,
        action: PERMISSION_ACTION.EXPORT
    })

    const hasAgentGeneralInfoViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_GENERALINFO,
        action: PERMISSION_ACTION.VIEW
    })

    const hasTotalsPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_AGENT_PERFORMANCE_TOTALS,
        action: PERMISSION_ACTION.VIEW
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleAgentUserNameClick = useCallback((agentUsername) => {
        addToNavBar({
            navBarTitle: agentUsername,
            navBarKey: agentUsername
        });
    }, [])

    const handleSearchChange = value => {
        resetNavBar();
        setAgentPerformanceReportFilters({
            ...filters,
            userNameOrId: value
        })
    }

    const handleRightArrowIconClick = (rowData) => {
        navigate(
            `${Paths.REPORTS_AGENT_PERFORMANCE_REPORT_EDIT}/${rowData.agentUserName}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${rowData.agentUserName}` +
            `&fromPerformanceReport=${true}`
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        expandTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            expandColumns: getExpandColumns,
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            includeExpandColumnsInExport: true,
            additionalProps: {
                onUserNameClick: handleAgentUserNameClick,
                navBarActiveItem: navBarActiveItem?.navBarTitle,
                formatAmount,
                hasLiteMode
            }
        })
    }, [includedColumns, navBarActiveItem?.navBarTitle, handleAgentUserNameClick, hasLiteMode, formatAmount])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [{
        title: t('backoffice.common.deepView'),
        icon: "icon-right",
        onClick: handleRightArrowIconClick,
        disabled: rowData => (
            rowData.agentState === USER_STATE.DELETED ||
            rowData.agentLevel === 0 ||
            !hasAgentGeneralInfoViewPermission
        )
    }];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: (
            <Filters loadFn={() => {
                getAgentPerformanceReport("first", navBarActiveItem?.navBarTitle);
            }} />
        ),
        search: {
            onSearch: handleSearchChange,
            loadFn: getAgentPerformanceReport,
            autoSuggestion: {
                items: getPossibleParentsName({agentNames: agents, globalProjectId}),
            },
            placeholder: t("backoffice.performancereport.searchByAgentUsernameOrId")
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.agentPerformanceReport"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_AGENT_PERFORMANCE_REPORT,
            filters: {
                ...filters,
                type: hasLiteMode ? AGENT_REPORT_TYPES.NETWORK: filters.type
            },
        }
    }

    //#endregion

    // In case of project change reset navBar
    useEffect(() => {
        resetNavBar()
    }, [globalProjectId]);


    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            toolbar={
                Boolean(navBarActiveItem) ? <NavBar navBarInstance={navBarInstance} /> : undefined
            }
            isContentScrollable={isMobile() && hasTotalsPermission}
            extraContent={ hasTotalsPermission ? (
                    <TotalsComponent
                        getTotals={() => getAgentPerformanceReportTotals( navBarActiveItem?.navBarTitle)}
                        totals={totals}
                        dependencies={[globalProjectId, filters, navBarActiveItem?.navBarTitle]}
                        isLoading={isTotalsLoading}
                        currencyCode={filters.currencies}
                        totalType={REPORT_TOTAL_TYPE.AGENT_PERFORMANCE_REPORT}
                    />
                ) : null
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={report}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                loadFn={(nextPage) => getAgentPerformanceReport(nextPage, navBarActiveItem?.navBarTitle)}
                updateProps={[globalProjectId, navBarActiveItem?.navBarTitle]}
                total={total}
                actions={tableRowActions}
                sorting={sorting}
                setSortingFn={setAgentPerformanceReportSorting}
                filters={filters}
                setFiltersFn={setAgentPerformanceReportFilters}
                shouldShowExpandedColumn={true}
                expandAllAvailable={true}
                enableReload={true}
                expandable={(
                    !hasLiteMode
                        ? {
                            title: t("backoffice.performancereport.additional"),
                            details: (rowData) => ({
                                columns: expandTableColumns,
                                data: rowData?.performanceDetails,
                                uniqueKey: EXPANDED_ROW_UNIQUE_KEY_PROP,
                                content: isMobileDevice && (
                                    <Table
                                        columns={expandTableColumns}
                                        data={rowData.performanceDetails}
                                    />
                                )
                            }),
                        }
                        : null
                )}
            />
        </TabTableDashboardLayout>
    );
}

/** AgentPerformanceReport propTypes
 * PropTypes
*/
AgentPerformanceReport.propTypes = {
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux action to get agent performance report report */
    getAgentPerformanceReport: PropTypes.func,
    /** Redux state property, is true when loading agent performance report */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when loading agent performance report totals */
    isTotalsLoading: PropTypes.bool,
    /** Redux state property, represents the agent performance report  */
    report: PropTypes.arrayOf(agentPerformanceReportType),
    /** Redux state property, agent performance report total count */
    total: PropTypes.number,
    /** Redux state property, agent performance report sorting details */
    sorting: sortingType,
    /** Redux state property, agent performance report filters */
    filters: PropTypes.object,
    /** Redux action to set agent performance report filters */
    setAgentPerformanceReportFilters: PropTypes.func,
    /** Redux action to set agent performance report sorting details */
    setAgentPerformanceReportSorting: PropTypes.func,
    /** Redux action to get agent performance report totals */
    getAgentPerformanceReportTotals: PropTypes.func,
    /** Redux state property, represents the agent performance report totals */
    totals: agentPerformanceReportTotalsType,
    /** Redux state property, the user info */
    userInfo: userInfoType,
};

const mapDispatchToProps = dispatch => ({
    getAgentPerformanceReport: (nextPage, agentUsername) => {
        dispatch(getAgentPerformanceReport(nextPage, agentUsername));
    },
    setAgentPerformanceReportFilters: (filters, keepPage) => {
        dispatch(setAgentPerformanceReportFilters(filters, keepPage));
    },
    setAgentPerformanceReportSorting: sorting => {
        dispatch(setAgentPerformanceReportSorting(sorting));
    },
    getAgentPerformanceReportTotals: agentUsername => {
        dispatch(getAgentPerformanceReportTotals(agentUsername));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.agentPerformanceReport.isLoading,
        isTotalsLoading: state.agentPerformanceReport.isTotalsLoading,
        report: state.agentPerformanceReport.report,
        total: state.agentPerformanceReport.total,
        sorting: state.agentPerformanceReport.sorting,
        filters: state.agentPerformanceReport.filters,
        totals: state.agentPerformanceReport.totals,
        globalProjectId: state.common.globalProjectId,
        globalCompanyId: state.common.globalCompanyId,
        userInfo: state.profile.userInfo,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentPerformanceReport);
