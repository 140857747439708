import { isObjectEmpty } from "NEW/src/core/helpers/common/isObjectEmpty";
import { isArray } from "NEW/src/core/helpers/typeChecks/isArray";
import { isNull } from "NEW/src/core/helpers/typeChecks/isNull";
import { isUndefined } from "NEW/src/core/helpers/typeChecks/isUndefined";

export const getURLMergedWithQueryData = (url, queryData, addTimestamp = false) => {
	const urlInstance = new URL(url);

	if (addTimestamp) {
		// By adding a timestamp to the URL, we disable caching.
		urlInstance.searchParams.append("timestamp", Date.now());
	}

	const paramsNotProvided = isUndefined(queryData) || isObjectEmpty(queryData);

	if (paramsNotProvided) {
		return urlInstance.href;
	}

	const paramEntries = Object.entries(queryData);

	paramEntries.forEach(([key, value]) => {
		if (isUndefined(value) || isNull(value)) {
			return;
		}

		if (isArray(value)) {
			return value.forEach((item) => {
				urlInstance.searchParams.append(key, item);
			});
		}

		urlInstance.searchParams.append(key, value);
	});

	return urlInstance.href;
};

export const getURL = (path) => {
	const baseURL = import.meta.env.SYSTEM_API_URL;

	return `${baseURL}${path}`;
};
