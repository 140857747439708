import Hotjar from '@hotjar/browser';

import {getAppEnvType} from "utils/environment";
import {isObjectLiteral} from "utils/javaScriptTypes";

import {HOTJAR_IDS, HOTJAR_VERSION} from "constants/common.constants";
import {APP_ENV_TYPE} from "constants/environment.constants";

export class HotJarManager {
    static _isInitied = false;

    static init() {
        let id = HOTJAR_IDS[getAppEnvType()];

        if (isObjectLiteral(id) && getAppEnvType() === APP_ENV_TYPE.PRD) {
            if (window.location.origin === import.meta.env.SYSTEM_RETAIL_DOMAIN) {
                id = id.retail;
            } else {
                id = id.agent;
            }
        }

        if (!id) {
            console.warn('No HotJar ID found for the current environment', getAppEnvType());
            return;
        }

        HotJarManager._isInitied = Hotjar.init(id, HOTJAR_VERSION, {
            debug: true
        });
    }

    static identify(userId, userInfo) {
        if (HotJarManager._isInitied) {
            Hotjar.identify(userId, userInfo);
        }
    }
}
