import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import axios from "axios";

import {isMobile, uid} from "utils/common";

import {
    SET_AGENT_PERFORMANCE_REPORT,
    SET_AGENT_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_AGENT_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_AGENT_PERFORMANCE_REPORT_FILTERS,
    SET_AGENT_PERFORMANCE_REPORT_SORTING,
    SET_AGENT_PERFORMANCE_REPORT_TOTALS_BEFORE,
    SET_AGENT_PERFORMANCE_REPORT_TOTALS_FINISH,
    SET_AGENT_PERFORMANCE_REPORT_TOTALS
} from "../../../actionTypes";

import {PROJECT_MODE, FILTER_TYPE} from "constants/common.constants";
import {AGENT_REPORT_TYPES} from "constants/reports.constants";
import {ROW_UNIQUE_KEY_PROP} from "pages/reports/performanceReport/sections/agent/constants";

const setAgentPerformanceReportActionBefore = () => ({
    type: SET_AGENT_PERFORMANCE_REPORT_ACTION_BEFORE,
});

const setAgentPerformanceReportActionFinished = () => ({
    type: SET_AGENT_PERFORMANCE_REPORT_ACTION_FINISH,
});

const setAgentPerformanceReport = (data, add) => ({
    type: SET_AGENT_PERFORMANCE_REPORT,
    payload: { report: data.reports, total: data.total, add },
});

export const setAgentPerformanceReportFilters = (filters, keepPage) => ({
    type: SET_AGENT_PERFORMANCE_REPORT_FILTERS,
    payload: { filters, keepPage },
});

export const setAgentPerformanceReportSorting = sorting => ({
    type: SET_AGENT_PERFORMANCE_REPORT_SORTING,
    payload: { sorting },
});

export const getAgentPerformanceReport = (nextPage, agentUsername) => {
    return (dispatch, getState) => {
        const state = getState();

        const isLiteMode = state.common.globalProjectMode === PROJECT_MODE.LITE;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? state.agentPerformanceReport.sorting.page + 1 : state.agentPerformanceReport.sorting.page;
        let data = {
            ...state.agentPerformanceReport.sorting,
            ...state.agentPerformanceReport.filters,
            page: page,
            filterType: agentUsername ? FILTER_TYPE.DIRECT : FILTER_TYPE.NETWORK
        };

        if (isLiteMode) {
            data.type = AGENT_REPORT_TYPES.NETWORK;
        }

        if (agentUsername) {
            data.parentUserNameOrId = agentUsername;
            data.userNameOrId = "";
        }

        if (page > 1 && state.agentPerformanceReport.total <= (page - 1) * state.agentPerformanceReport.sorting.limit) {
            return Promise.resolve();
        }

        if (nextPage === "next" && state.agentPerformanceReport.total === 0) {
            return Promise.resolve();
        }

        dispatch(setAgentPerformanceReportActionBefore());

        let currenciesStr = "";
        let currencies = state.agentPerformanceReport.filters.currencies;
        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }

        delete data.currencies;

        return axios({
            url: `${ApiUrls.GET_AGENT_PERFORMANCE_REPORT}?${currenciesStr}`,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const reports = data.value?.item2?.map(item => ({...item, [ROW_UNIQUE_KEY_PROP]: uid()}));
                const total = data.value?.item1;

                // Add currencies to all main data of report
                reports.map(reportMainData => {
                    const { performanceDetails } = reportMainData;

                    reportMainData.currencies = performanceDetails.map(detailsData => detailsData.currencyCode);
                })

                dispatch(setAgentPerformanceReport({ reports, total }, isMobile() && page !== 1));

                if (nextPage) {
                    dispatch(setAgentPerformanceReportSorting({ ...getState().agentPerformanceReport.sorting, page: page }));
                }

                dispatch(setAgentPerformanceReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentPerformanceReportActionFinished());
            });
    }
}

/** Totals */

const setAgentPerformanceReportTotalsBefore = () => ({
    type: SET_AGENT_PERFORMANCE_REPORT_TOTALS_BEFORE,
});

const setAgentPerformanceReportTotalsFinished = () => ({
    type: SET_AGENT_PERFORMANCE_REPORT_TOTALS_FINISH,
});

const setAgentPerformanceReportTotals = totals => ({
    type: SET_AGENT_PERFORMANCE_REPORT_TOTALS,
    payload: { totals },
});


export const getAgentPerformanceReportTotals = agentUsername => {
    return (dispatch, getState) => {
        const state = getState();

        const isLiteMode = state.common.globalProjectMode === PROJECT_MODE.LITE;

        let data = {
            ...state.agentPerformanceReport.filters,
            filterType: agentUsername ? FILTER_TYPE.DIRECT : FILTER_TYPE.NETWORK
        };

        if (isLiteMode) {
            data.type = FILTER_TYPE.NETWORK;
        }

        let currenciesStr = "";
        let currencies = state.agentPerformanceReport.filters.currencies;
        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }

        delete data.currencies;

        if (agentUsername) {
            data.parentUserNameOrId = agentUsername;
        }

        dispatch(setAgentPerformanceReportTotalsBefore());

        return axios({
            url: `${ApiUrls.GET_AGENT_PERFORMANCE_REPORT_TOTALS}?${currenciesStr}`,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const totals = data.value;
                dispatch(setAgentPerformanceReportTotals(totals));
                dispatch(setAgentPerformanceReportTotalsFinished());
            })
            .catch((ex) => {
                dispatch(setAgentPerformanceReportTotalsFinished());
            });
    }
}
