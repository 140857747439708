import React from "react";

import TranslationsComponent from "pages/cms/translations/translations.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import { TRANSLATION_TYPE } from "constants/translation.constants";

const TranslationsRoute = () => {
    return <TranslationsComponent translationType={TRANSLATION_TYPE.WIDGET} />;
};

export default withPermission(
    withAuth(TranslationsRoute),
    { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
