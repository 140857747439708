import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { CASHIER_TYPE } from "constants/cashier.constants";

import {
    SET_CASHIERS_ACTION_BEFORE,
    SET_CASHIERS_ACTION_FINISH,
    SET_CASHIERS,
    SET_CASHIERS_SORTING,
    SET_CASHIERS_FILTERS,
    SET_CASHIERS_SAVE_ACTION_BEFORE,
    SET_CASHIERS_SAVE_ACTION_FINISH,
    SET_CASHIER_AVAILABLE_BETSHOPS_BEFORE,
    SET_CASHIER_AVAILABLE_BETSHOPS_FINISH,
    SET_CASHIER_AVAILABLE_BETSHOPS
} from "../../../../actionTypes";

import { getCashierGeneralInfo } from './general.action';

export const setCashiersActionBefore = () => ({
    type: SET_CASHIERS_ACTION_BEFORE,
});

export const setCashiersActionFinished = () => ({
    type: SET_CASHIERS_ACTION_FINISH,
});

export const setCashiersSaveActionBefore = () => ({
    type: SET_CASHIERS_SAVE_ACTION_BEFORE,
});

export const setCashiersSaveActionFinished = () => ({
    type: SET_CASHIERS_SAVE_ACTION_FINISH,
});

const setCashiers = (cashiers, add) => ({
    type: SET_CASHIERS,
    payload: { cashiers, add },
});

export const setCashiersSorting = sorting => ({
    type: SET_CASHIERS_SORTING,
    payload: { sorting },
});

export const setCashiersFilters = filters => ({
    type: SET_CASHIERS_FILTERS,
    payload: { filters },
});


export const getCashiers = nextPage => {
    return (dispatch, getState) => {
        
        const page= nextPage === "first" ? 1 : nextPage === "next" ? getState().cashiers.sorting.page + 1 : getState().cashiers.sorting.page;
        let params = {
            ...getState().cashiers.sorting,
            ...getState().cashiers.filters,
            page: page,
            type: CASHIER_TYPE.INTERNAL
        };

        if(page > 1 && getState().cashiers.total <= (page - 1 ) * getState().cashiers.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().cashiers.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setCashiersActionBefore());

        return axios({
            url: ApiUrls.GET_CASHIERS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: cashiers } }) => {
                dispatch(setCashiers(cashiers, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCashiersSorting({ ...getState().cashiers.sorting, page: page }));
                dispatch(setCashiersActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashiersActionFinished());
            });
    }
}

export const createCashier = (cashier, onSuccess) => {
    return (dispatch) => {
        dispatch(setCashiersSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_CASHIER,
            method: Methods.POST,
            data: {
                ...cashier
            },
        })
            .then(({ data }) => {
                dispatch(setCashiersSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setCashiersSaveActionFinished());
            });
    };
};

export const forceLogout = () => {
    return (dispatch, getState) => {
        dispatch(setCashiersSaveActionBefore());

        const filters = { ... getState().cashiers.filters };

        Object.keys(filters).map(k => {
            if(filters[k] === "" || filters[k] === undefined){
                delete filters[k];
            }
        })

        return axios({
            url: ApiUrls.FORCE_LOGOUT_CASHIER,
            method: Methods.POST,
            data: {
                ... getState().cashiers.sorting,
                ... filters,
                page: getState().cashiers.sorting.page,
                type: CASHIER_TYPE.INTERNAL
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCashiers());
            } 
            dispatch(setCashiersSaveActionFinished());
        })
        .catch(() => {
            dispatch(setCashiersSaveActionFinished());
        })
    }
}

export const forceLogoutSingleCashier = id => {
    return (dispatch, getState) => {
        dispatch(setCashiersSaveActionBefore());

        return axios({
            url: ApiUrls.FORCE_LOGOUT_CASHIER,
            method: Methods.POST,
            data: {
                userNameOrId: id,
                page: 1,
                type: CASHIER_TYPE.INTERNAL
            }
        })
        .then((data) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCashiers());
            } 
            dispatch(setCashiersSaveActionFinished());
        })
        .catch(() => {
            dispatch(setCashiersSaveActionFinished());
        })
    }
}


const setCashierAvailableBetshopsBefore = () => ({
    type: SET_CASHIER_AVAILABLE_BETSHOPS_BEFORE,
});

const setCashierAvailableBetshopsFinished = () => ({
    type: SET_CASHIER_AVAILABLE_BETSHOPS_FINISH,
});

const setCashierAvailableBetshops = betshops => ({
    type: SET_CASHIER_AVAILABLE_BETSHOPS,
    payload: { betshops }
})

export const getCashierAvailableBetshops = id => {
    return dispatch => {
        dispatch(setCashierAvailableBetshopsBefore());
        return axios({
            url: ApiUrls.GET_CASHIER_AVAILABLE_BETSHOPS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: betshops}}) => {
            dispatch(setCashierAvailableBetshops(betshops))
            dispatch(setCashierAvailableBetshopsFinished());
        })
        .catch((ex) => {
            dispatch(setCashierAvailableBetshopsFinished());
        })
    }
}

export const setCashierPassword = ( data, userId, onSuccess) => {
    return dispatch => {
        dispatch(setCashiersSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_CASHIER_PASSWORD,
            method: Methods.POST,
            data: { 
                ...data,
                userId 
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                onSuccess && onSuccess();
            }
            dispatch(setCashiersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCashiersSaveActionFinished());
        })
    }
}

export const changeCashierState = d => {
    return dispatch => {
        dispatch(setCashiersSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_CASHIER_STATE,
            method: Methods.POST,
            data: d
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCashierGeneralInfo(d.id));
            } 
            dispatch(setCashiersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCashiersSaveActionFinished());
        })
    }
}