import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Tooltip from "components/common/tooltip";

import TableContext from '../../contex';

import { isFunction } from 'utils/common';


const FloatingView = ({ actions, rowData }) => {
    const { showConfiramtionModal } = useContext(TableContext);

    const hasMoreThenOneAction = actions.length > 1;

    const handleInternalClick = (action) => {
        if (action.disabled && action.disabled(rowData)) {
            return false
        }

        if (action.showConfirmation) {
            showConfiramtionModal({
                rowData,
                title: action.confirmationTitle,
                message: action.confirmationMessage,
                onOk: action.onClick
            });
            return;
        }

        action.onClick(rowData);
    }

    return (
        <>
            {hasMoreThenOneAction && (
                <div className="rt--ml-8">
                    <i className='icon-more rt--font-bigest' />
                </div>
            )}
            <div className={hasMoreThenOneAction ? 'rt--table-col-actions-icons rt--flex' : ' rt--flex rt--cursor-pointer'}>
                {
                    actions.map((actionData, index) => (
                        <div
                            key={index}
                            className={hasMoreThenOneAction ? 'rt--table-col-actions-icons-content rt--flex' : ' rt--flex'}
                            onClick={() => handleInternalClick(actionData)}
                        >
                            <Tooltip
                                placement='top'
                                title={
                                    <span>
                                        {
                                            isFunction(actionData.title)
                                                ? actionData.title(rowData)
                                                : actionData.title
                                        }
                                    </span>
                                }
                            >
                                <i className={`${actionData.icon} rt--font-bigest`} />
                            </Tooltip>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

/** FloatingView propTypes
 * PropTypes
*/
FloatingView.propTypes = {
    actions: PropTypes.array,
    rowData: PropTypes.object,
}

export default FloatingView;