import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import MultiItems from 'components/common/multiItems';

const TotalsCollapsePanelHeader = ({
    closable,
}) => {
    const { t } = useTranslation()

    return (
        <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
            <div className="rt--flex rt--align-center rt--justify-between rt--collapse-header">
                {closable && (
                    <i className="icon-down rt--font-bigest rt--mr-2" />
                )}

                <span className="rt--title rt--font-normal rt--font-bold rt--pl-4 rt--pr-4">
                    {t('backoffice.bets.totals')}
                </span>
            </div>
        </div>
    )
}

/** TotalsCollapsePanelHeader propTypes
 * PropTypes
*/
TotalsCollapsePanelHeader.propTypes = {
    /** is Collapse can be closed */
    closable: PropTypes.bool,
};


export default TotalsCollapsePanelHeader;

