const formatLargeNumbers = (value) => {
    const suffixes = ['k', 'm', 'b', 't'];
    const divisors = [1e3, 1e6, 1e9, 1e12];

    const isNegative = value < 0;
    const absoluteValue = Math.abs(value);

    for (let i = divisors.length - 1; i >= 0; i--) {
        if (absoluteValue >= divisors[i]) {
            let formattedValue = (absoluteValue / divisors[i]).toFixed(1);
            if (formattedValue.endsWith('.0')) {
                formattedValue = formattedValue.slice(0, -2);
            }
            return (isNegative ? ' - ' : '') + formattedValue + suffixes[i];
        }
    }
    return value.toString();
};

export const yAxisTextDefaultFormatter = (value) => {
    const formattedValue = formatLargeNumbers(value);
    return formattedValue;
};

export const customizeIndicationLine = ({ element, color }) => {
    const chart = element.series.chart;
    const x = element.plotX + chart.plotLeft;
    const y1 = element.plotY + chart.plotTop;
    const y2 = chart.plotHeight + chart.plotTop;

    if (!Boolean(chart.customcrosshair)) {
        chart.customcrosshair = (
            chart.renderer
                .path()
                .attr({
                    "stroke-width": 1.5,
                    "stroke-dasharray": 7.5,
                    stroke: color
                })
                .add()
        );
    }

    chart.customcrosshair.attr({
        d: ["M", x, y1, "L", x, y2]
    });
};

export const changeRGBAOpacityToGivenNumber = ({ rgba, opacity }) => {
    const arrOfRGBAnumbers = rgba.match(/(\d+)/g);

    if (!arrOfRGBAnumbers) {
        return null;
    }

    const colorBytes = arrOfRGBAnumbers.slice(0, 3);
    colorBytes.push(opacity);

    const strOfChangedRGBANumbers = colorBytes.join(", ");

    return `rgba(${strOfChangedRGBANumbers})`;
};

export const validateRGBA = (inputString) => {
    const regexPattern = /^rgba\(\s*((1?\d?\d|25[0-5]|2[0-4]\d)(\s*,\s*(1?\d?\d|25[0-5]|2[0-4]\d)){2})\s*,\s*(0?\.\d+|1(\.0+)?|\.\d+|0(\.0+)?)\s*\)$/;

    return regexPattern.test(inputString);
};

export const hasAtLeastOneNonZeroValue = (data) => {
    for (const item of data) {
        if (item !== 0) {
            return true;
        }
    }

    return false;
};
