import React from "react";

import CashierTransactionsReportComponent from "pages/reports/cashierTransactionsReport/cashierTransactionsReport.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const CashierTransactionsReportRoute = () => {
    return <CashierTransactionsReportComponent />;
};

export default withPermission(
    withAuth(CashierTransactionsReportRoute),
    {
        resource: PERMISSION_RESOURCE.REPORTS_CASHIER_TRANSACTIONS,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
