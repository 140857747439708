import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import BankListComponent from './bank-list.component';
import BankEditComponent from './edit';

const PaymentBanksComponent = () => {
    const { search } = useLocation();

    const [currentBankId, setCurrentBankId] = useState((new URLSearchParams(search)).get("bankId"));

    /** Change view depend on query string param change */
    useEffect(() => {
        const currentBankId = (new URLSearchParams(search)).get("bankId");
        setCurrentBankId(currentBankId);
    }, [search])

    return !currentBankId ? (
        <BankListComponent />
    ) : (
        <BankEditComponent
            bankId={currentBankId}
        />
    )
}

export default PaymentBanksComponent