import React from "react";

import BetshopTransactionsReportComponent from "pages/reports/betshopTransactionsReport/betshopTransactionsReport.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const BetshopTransactionsReportRoute = () => {
    return <BetshopTransactionsReportComponent />;
};

export default withPermission(
    withAuth(BetshopTransactionsReportRoute),
    {
        resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_TRANSACTIONS,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
