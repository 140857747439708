import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import dateService from "utils/date";

import { setBetHistoryActionBefore, setBetHistoryActionFinished } from "./betHistory.action";

import {
    SET_RETAIL_PENDING_BET_HISTORY,
    SET_RETAIL_PENDING_BET_HISTORY_SORTING,
    SET_RETAIL_PENDING_BET_HISTORY_FILTERS
} from "../../../actionTypes";

const setRetailPendingBetHistory = (bets, add) => ({
    type: SET_RETAIL_PENDING_BET_HISTORY,
    payload: { bets, add },
});

export const setRetailPendingBetHistorySorting = sorting => ({
    type: SET_RETAIL_PENDING_BET_HISTORY_SORTING,
    payload: { sorting },
});

export const setRetailPendingBetHistoryFilters = (filters, keepPage) => ({
    type: SET_RETAIL_PENDING_BET_HISTORY_FILTERS,
    payload: { filters, keepPage },
});


export const getRetailPendingBetHistory = nextPage => {
    return (dispatch, getState) => {
        const productT = "retail";

        const betHistory = getState().betHistory[productT].pendings;

        const filters = { ... betHistory.filters };
        const sorting = { ... betHistory.sorting };
        const total = betHistory.total;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;
        
        let params = {
            ...sorting,
            ...filters,
            page: page,
            from: dateService.toISOString(dateService.monthsAgo(1)),
            to: dateService.toISOString(dateService.getNow()),
        };

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }
        
        dispatch(setBetHistoryActionBefore());

        return axios({
            url: ApiUrls.GET_RETAIL_PENDING_BET_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                dispatch(setRetailPendingBetHistory(bets, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setRetailPendingBetHistorySorting({ ...sorting, page: page }));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
            });
    }
}
