//#region react
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region libraries
import { Switch } from "antd";
//#endregion

//#region actions
import {
    getBetshopIPAccessRule,
    changeBetshopIPAccessRuleState,
    deleteBetshopAllowedIP
} from "store/actions/dashboard/retail/betshops/ipAccessRule.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import Table from "components/common/table";
import TabTableDashboardLayout from "components/layouts/tab/table";
import IPAccessAddComponent from "./ipAccess-add.component";
//#endregion

//#region utils
import { hasPermission } from "utils/permissions";
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { BETSHOP_IP_ACCESS } from 'constants/pageName.constants';
import { tableColumns } from './columns';
//#endregion

//#region types
import betshopIPAccessRuleType from "types/betshop/ipAccessRule.type";
//#endregion

/** Betshop Edit Page IP Access Rule Tab Component */
const IPAccessRuleComponent = ({
    getBetshopIPAccessRule,
    changeBetshopIPAccessRuleState,
    deleteBetshopAllowedIP,
    isLoading,
    ipAccessRule
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: BETSHOP_IP_ACCESS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDeletePermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETSHOP_IP_ACCESS_RULE,
        action: PERMISSION_ACTION.DELETE,
    });

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETSHOP_IP_ACCESS_RULE,
        action: PERMISSION_ACTION.CREATE,
    });

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETSHOP_IP_ACCESS_RULE,
        action: PERMISSION_ACTION.MODIFY,
    });

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddPopup = () => {
        setIsAddPopupVisible(true);
    }

    const hideAddPopup = () => {
        setIsAddPopupVisible(false);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            includedColumns,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            icon: "icon-trash",
            showConfirmation: true,
            confirmationTitle: t("backoffice.betshops.deleteIPAddress"),
            confirmationMessage: t("backoffice.betshops.doYouWanttoDeleteIPAddress"),
            onClick: record => deleteBetshopAllowedIP(searchParams.id, record.id),
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            text: t("backoffice.betshops.addIPAddress"),
            icon: "icon-plus",
            type: "primary",
            enabled: hasCreatePermission,
            onClick: showAddPopup,
        }
    }

    //#endregion

    // Close currency add/edit popup, after save
    useEffect(() => {
        hideAddPopup();
    }, [ipAccessRule])

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={(
                <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                    <Switch
                        disabled={!hasModifyPermission}
                        checked={ipAccessRule.enabled}
                        onChange={enabled => {
                            changeBetshopIPAccessRuleState(searchParams.id, enabled)
                        }}
                    />
                    <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>
                        {t('backoffice.betshops.allowLoginFromWhitelistedIPsOnly')}
                    </label>
                </div>
            )}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={ipAccessRule.allowedIps}
                loadFn={() => getBetshopIPAccessRule(searchParams.id)}
                noPagination={true}
                disableFullView={true}
                renderEmpty={() => <></>}
                smallInMobile={true}
                mobileLayoutVertical={true}
                actions={tableRowActions}
            />

            {isAddPopupVisible && <IPAccessAddComponent onClose={hideAddPopup} />}
        </TabTableDashboardLayout>
    )
}

/** IPAccessRuleComponent propTypes
    * PropTypes
*/
IPAccessRuleComponent.propTypes = {
    /** Redux action to get betshop IP access rule */
    getBetshopIPAccessRule: PropTypes.func,
    /** Redux action to change company betshop IP access rule state */
    changeBetshopIPAccessRuleState: PropTypes.func,
    /** Redux action to delete betshop allowed IP */
    deleteBetshopAllowedIP: PropTypes.func,
    /** Redux state property, is true when betshop IP access rule are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, represents the IP access rule of current editing betshop  */
    ipAccessRule: betshopIPAccessRuleType
}

const mapDispatchToProps = dispatch => (
    {
        getBetshopIPAccessRule: id => {
            dispatch(getBetshopIPAccessRule(id));
        },

        changeBetshopIPAccessRuleState: (id, enabled) => {
            dispatch(changeBetshopIPAccessRuleState(id, enabled));
        },

        deleteBetshopAllowedIP: (id, ipId) => {
            dispatch(deleteBetshopAllowedIP(id, ipId));
        }
    }
)

const mapStateToProps = state => {
    return {
        ipAccessRule: state.betshops.edit.ipAccessRule,
        isLoading: state.betshops.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IPAccessRuleComponent)