import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Collapse } from "antd";

import Table from 'components/common/table';
import TotalsCollapsePanelHeader from './header';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { getTableColumns } from './columns';

import useFormat from "hooks/useFormat"

import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';
import useProjectType from "hooks/useProjectType";

const ROW_UNIQUE_KEY_PROP = "currency";

const TableView = ({
    totalType,
    tableData,
    closable,
    isLoading,
    onPanelOpen
}) => {
    const { formatAmount } = useFormat();
    const { hasLiteMode } = useProjectType();

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                totalType,
                formatAmount,
                hasLiteMode
            }
        });
    }, [totalType, hasLiteMode, formatAmount]);

    const handlePanelChange = (openedPanelKeys) => {
        const isOpened = openedPanelKeys.length > 0;
        onPanelOpen(isOpened);
    }

    return (
        <Collapse
            onChange={handlePanelChange}
            className="rt--collapse rt--collapse-totals"
            {...(closable ? {} : { activeKey: ["*"] })}
            items={[
                {
                    key: "*",
                    label: (
                        <TotalsCollapsePanelHeader
                            totalType={totalType}
                            closable={closable}
                        />
                    ),
                    showArrow: false,
                    children: (
                        <Table
                            loading={isLoading}
                            columns={mainTableColumns}
                            data={tableData}
                            uniqueKey={ROW_UNIQUE_KEY_PROP}
                            disableFullView={
                                totalType!== REPORT_TOTAL_TYPE.AGENT_PERFORMANCE_REPORT &&
                                totalType!== REPORT_TOTAL_TYPE.PLAYER_PERFORMANCE_REPORT &&
                                totalType!== REPORT_TOTAL_TYPE.NEW_PRODUCT_REPORT &&
                                totalType!== REPORT_TOTAL_TYPE.BETSHOP_PERFORMANCE_REPORT
                            }
                            noPagination={true}
                        />
                    )
                }
            ]}
        />
    );
}

/** TableView propTypes
 * PropTypes
*/
TableView.propTypes = {
    tableData: PropTypes.array,
    totalType: PropTypes.oneOf(Object.values(REPORT_TOTAL_TYPE)),
    closable: PropTypes.bool,
    isLoading: PropTypes.bool,
    onPanelOpen: PropTypes.func,
};

export default TableView;
