import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row, Checkbox } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import Input from 'components/common/input';

import { createCashier, getCashierAvailableBetshops, getCashiers } from 'store/actions/dashboard/retail/cashiers/cashiers.action';

import { getCompanyPasswordSettings } from "store/actions/dashboard/companies/passwordSettings.action";

import Paths from 'constants/path.constants';

import { POPUP_SIZE } from "constants/common.constants";
import { EMAIL_REGEX, USERNAME_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX } from "constants/regex.constants";

import { hasOneOfPermissions } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { validatePassword } from "utils/password";

import companyType from "types/company/company.type";
import companyPasswordSettingsType from "types/company/passwordSettings.type";

import { isMobile } from 'utils/common';

/** Cashier Creating Popup Component */
const CashierCreateComponent = ({
    isSaving,
    createCashier,
    getCashiers,
    globalCompanyId,
    globalProjectId,
    allCompanies,
    availableBetshops,
    isAvailableBetshopsLoading,
    getCashierAvailableBetshops,
    getCompanyPasswordSettings,
    passwordSettings,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;
    const navigate = useNavigate();

    /** Load cashier available betshops, password settings */
    useEffect(() => {
        getCashierAvailableBetshops();
        getCompanyPasswordSettings();
    }, [])

    /** Detect if current company is test partner
       * @function
       * @returns {boolean}
       * @memberOf CashierCreateComponent
   */
    const isCurrentCompanyTest = allCompanies.find(p => p.id === globalCompanyId)?.isTesting ?? false


    /** Fires when form submitted
       * @function
       * @memberOf CashierCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                createCashier(
                    data,
                    record => {

                        if (hasOneOfPermissions(
                            [
                                { resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.VIEW },
                                { resource: PERMISSION_RESOURCE.CASHIER_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.VIEW },
                                { resource: PERMISSION_RESOURCE.CASHIER_SESSIONS, action: PERMISSION_ACTION.VIEW }
                            ])) {
                            navigate(`${Paths.CASHIERS_EDIT}/${record.id}?cid=${globalCompanyId}&pid=${globalProjectId}&name=${record.userName}`);
                        } else {
                            getCashiers();
                            onClose();
                        }
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.cashiers.createCashier')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    isTesting: isCurrentCompanyTest
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.cashiers.firstName')} *`}
                            name="firstName"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid')}
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.firstName')}`}
                        >
                            <Input
                                maxLength={48}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.firstName')}`}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={`${t('backoffice.cashiers.lastName')} *`}
                            name="lastName"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid')}
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.lastName')}`}
                        >
                            <Input
                                maxLength={48}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.lastName')}`}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={t('backoffice.cashiers.email')}
                            name="email"
                            rules={[
                                { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                                { max: 50, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.email')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.email')}`}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.cashiers.username')} *`}
                            name="username"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: USERNAME_REGEX, message: t('backoffice.validation.fieldInvalid') },
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.username')}`}
                            validateFirst
                        >
                            <Input
                                maxLength={30}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.username')}`}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.cashiers.password')} *`}
                            name="password"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: passwordSettings.passwordMaxLimit || 24, message: t('backoffice.validation.fieldInvalid') },
                                ({
                                    validator(rule, value) {
                                        return validatePassword(value, passwordSettings);
                                    }
                                })
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.password')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.password')}`}
                                type='Password'
                                maxLength={passwordSettings.passwordMaxLimit || 24}
                                autoComplete="new-password"
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.cashiers.confirmPassword')} *`}
                            name="confirmPassword"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value !== getFieldValue("password")) {
                                            return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.confirmPassword')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.confirmPassword')}`}
                                type='Password'
                                maxLength={passwordSettings.passwordMaxLimit || 24}
                                autoComplete="new-password"
                                onPaste={e => e.preventDefault()}
                                onContextMenu={e => e.preventDefault()}
                                onCopy={e => e.preventDefault()}
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.cashiers.betshop')} *`}
                            name="betShopIds"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={
                                    Object.keys(availableBetshops).map(item => (
                                        { value: item, text: availableBetshops[item] }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.cashiers.betshop')}`}
                                loading={isAvailableBetshopsLoading}
                                isMultiple={true}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isTesting"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.cashiers.isTestCashier')}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** CashierCreateComponent propTypes
    * PropTypes
*/
CashierCreateComponent.propTypes = {
    /** Redux state property, is true when creating cashier request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create cashier */
    createCashier: PropTypes.func,
    /** Redux action to get cashiers */
    getCashiers: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, represents the array of global companies  */
    allCompanies: PropTypes.arrayOf(companyType),
    /** Redux action to get available betshops of cashier */
    getCashierAvailableBetshops: PropTypes.func,
    /** Redux state property, represents the object of available betshops of cashier */
    availableBetshops: PropTypes.object,
    /** Redux state property, is true when loading available betshops of cashier */
    isAvailableBetshopsLoading: PropTypes.bool,
    /** Redux action to get password settings */
    getCompanyPasswordSettings: PropTypes.func,
    /** Redux state property, represents company agent system password settings */
    passwordSettings: companyPasswordSettingsType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createCashier: (cashier, onSuccess) => {
            dispatch(createCashier(cashier, onSuccess));
        },

        getCashiers: () => {
            dispatch(getCashiers());
        },

        getCashierAvailableBetshops: () => {
            dispatch(getCashierAvailableBetshops())
        },

        getCompanyPasswordSettings: () => {
            dispatch(getCompanyPasswordSettings());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.cashiers.isSaving,
        allCompanies: state.profile.userInfo.companies,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        availableBetshops: state.cashiers.availableBetshops.availableBetshops,
        isAvailableBetshopsLoading: state.cashiers.availableBetshops.isLoading,
        passwordSettings: state.companies.edit.passwordSettings,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashierCreateComponent)