import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import TransactionsReport from "../transactionsReport";

import {
    getBetshopTransactionsReport,
    setBetshopTransactionsReportSorting,
    setBetshopTransactionsReportFilters
} from "store/actions/dashboard/reports/betshopTransactions.action";

import { WALLET_OWNER_TYPE } from "constants/wallet.constants";

import sortingType from "types/common/sorting.type";
import transactionsReportType from "types/reports/transactionsReport.type";

/** Betshop Transaction Report Page Component */
const BetshopTransactionReportComponent = ({
    getBetshopTransactionsReport,
    report,
    isLoading,
    total,
    setBetshopTransactionsReportSorting,
    setBetshopTransactionsReportFilters,
    sorting,
    filters
}) => {

    const { t } = useTranslation();

    return (
        <TransactionsReport 
            getTransactionsReport={getBetshopTransactionsReport}
            setTransactionsReportSorting={setBetshopTransactionsReportSorting}
            setTransactionsReportFilters={setBetshopTransactionsReportFilters}
            report={report}
            isLoading={isLoading}
            total={total}
            sorting={sorting}
            filters={filters}
            title={t("backoffice.menu.betshopTransactions")}
            type={WALLET_OWNER_TYPE.BETSHOP}
        />
    );
};

/** BetshopTransactionReportComponent propTypes
 * PropTypes
*/
BetshopTransactionReportComponent.propTypes = {
    /** Redux action to get betshop transactions report */
    getBetshopTransactionsReport: PropTypes.func,
    /** Redux state property, represents the betshop transactions report  */
    report: PropTypes.arrayOf(transactionsReportType),
    /** Redux state property, is true when loading betshop transactions report */
    isLoading: PropTypes.bool,
    /** Redux state property, betshop transactions report total count */
    total: PropTypes.number,
    /** Redux action to set betshop transactions report sorting details */
    setBetshopTransactionsReportSorting: PropTypes.func,
    /** Redux action to set betshop transactions report sorting details */
    setBetshopTransactionsReportFilters: PropTypes.func,
    /** Redux state property, betshop transactions report sorting details */
    sorting: sortingType,
    /** Redux state property, betshop transactions report filters */
    filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    getBetshopTransactionsReport: nextPage => {
        dispatch(getBetshopTransactionsReport(nextPage));
    },
    setBetshopTransactionsReportSorting: sorting => {
        dispatch(setBetshopTransactionsReportSorting(sorting));
    },
    setBetshopTransactionsReportFilters: filters => {
        dispatch(setBetshopTransactionsReportFilters(filters));
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.betshopTransactionsReport.isLoading,
        report: state.betshopTransactionsReport.report,
        total: state.betshopTransactionsReport.total,
        sorting: state.betshopTransactionsReport.sorting,
        filters: state.betshopTransactionsReport.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BetshopTransactionReportComponent);
