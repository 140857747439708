import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { PAYMENT_METHODS } from 'constants/project.constants';

import {
    setAgentsActionBefore,
    setAgentsActionFinished,
    setAgentsSaveActionBefore,
    setAgentsSaveActionFinished
} from './agents.action';

import {
    SET_AGENT_PAYMENT_METHODS,
    SET_AGENT_EPOS_SETTINGS,
    SET_AGENT_EPOS_TRANSLATIONS,
    SET_AGENT_BANK_LIST,
    SET_AGENT_AVAILABLE_BANK_LIST,
    SET_AGENT_BANK_INFO,
    SET_AGENT_BANK_DETAILS,
    SET_AGENT_BANK_WIDGET
} from "../../../../actionTypes";

const setAgentPaymentSettings = config => ({
    type: SET_AGENT_PAYMENT_METHODS,
    payload: { config }
})

const setEposSettings = settings => ({
    type: SET_AGENT_EPOS_SETTINGS,
    payload: settings
})

const setEposTranslation = (translation, key) => ({
    type: SET_AGENT_EPOS_TRANSLATIONS,
    payload: { translation, key }
})

const setAgentPaymentBanks = banks => ({
    type: SET_AGENT_BANK_LIST,
    payload: banks
})

const setAgentAvailableBanks = bankList => ({
    type: SET_AGENT_AVAILABLE_BANK_LIST,
    payload: bankList
})

const setAgentBankGeneralInfo = info => ({
    type: SET_AGENT_BANK_INFO,
    payload: info
})

const setAgentBankDetails = (type, details) => ({
    type: SET_AGENT_BANK_DETAILS,
    payload: { type, details }
})

/** Agent Payments */

export const getAgentPaymentSettings = id => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_PAYMENT_SETTINGS,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: config}}) => {
            dispatch(setAgentPaymentSettings(config))
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const saveAgentPaymentSettings = data => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AGENT_PAYMENT_SETTINGS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAgentPaymentSettings(data.value));
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

/** Epos Payments */

export const saveEposSettings = changedSchedule => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_EPOS_SETTINGS,
            method: Methods.POST,
            data: {
                ...changedSchedule
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                const eposData = {
                    translations: data.value.translations,
                    workingSchedule: data.value.workingSchedule.weekDays,
                    contacts: data.value.contacts ?? []
                }
                dispatch(setEposSettings(eposData));
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

export const getEposSettings = id => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_EPOS_SETTINGS,
            method: Methods.GET,
            params: { id }
        })
        .then(({ data }) => {
            const eposData = {
                translations: data.value.translations,
                workingSchedule: data.value.workingSchedule.weekDays,
                contacts: data.value.contacts ?? []
            }
            dispatch(setEposSettings(eposData));
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

/** Epos Translations */

export const saveEposTranslations = (translation, key) => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_EPOS_TRANSLATIONS,
            method: Methods.POST,
            data: {
                ...translation
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setEposTranslation(data.value, key));
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

/** Agent Banks */

export const getAgentBanks = (id) => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_BANKS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({ data }) => {
            dispatch(setAgentPaymentBanks(data.value))
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const getAgentAvailableBanks = (id) => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_AVAILABLE_BANKS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({ data }) => {
            dispatch(setAgentAvailableBanks(data.value))
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const addAgentBank = (data, onSuccess) => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_AGENT_BANK,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                onSuccess && onSuccess(data.value.id);
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

export const getAgentBankGeneralInfo = (id, agentId) => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_BANK_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id,
                agentId,
            }
        })
        .then(({ data }) => {
            const { name, currencies, state } = data.value;
            dispatch(setAgentBankGeneralInfo({
                name,
                currencies,
                state,
            }))
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const deactivateAgentBank = (id, agentId) => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());

        return axios({
            url: ApiUrls.DEACTIVATE_AGENT_BANK,
            method: Methods.POST,
            data: {
                agentId,
                id,
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAgentBankGeneralInfo(data.value));
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

export const activateAgentBank = (id, agentId) => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());

        return axios({
            url: ApiUrls.ACTIVATE_AGENT_BANK,
            method: Methods.POST,
            data: {
                id,
                agentId,
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAgentBankGeneralInfo(data.value));
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

export const deleteAgentBank = (id, agentId, currencies, onSuccess) => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());

        return axios({
            url: ApiUrls.DELETE_AGENT_BANK,
            method: Methods.DELETE,
            data: {
                id,
                agentId,
                currencies,
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                onSuccess && onSuccess()
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

/** Agent Bank details */

export const getAgentBankDetails = (type, agentId, id) => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_BANK_DETAILS,
            method: Methods.GET,
            params: {
                type, agentId, id
            }
        })
        .then(({ data }) => {
            dispatch(setAgentBankDetails(type, data.value))
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const addAgentBankDetails = ( details, onSuccess) => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.ADD_AGENT_BANK_DETAILS,
            method: Methods.POST,
            data: {
                ...details
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAgentBankDetails(details.type, details.agentId, details.id))
                onSuccess && onSuccess();
            }
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const addAgentBankImg = (formData, onSuccess) => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.ADD_AGENT_BANK_IMAGE,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAgentBankDetails(formData.get("type"), formData.get("agentId"), formData.get("id")))
                onSuccess && onSuccess();
            }
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const deleteAgentBankDetails = details => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.DELETE_AGENT_BANK_DETAILS,
            method: Methods.DELETE,
            data: {
                ...details
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAgentBankDetails(details.type, details.agentId, details.id))
            }
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const saveBankTranslation = translation => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.SAVE_AGENT_BANK_DETAILS,
            method: Methods.POST,
            data: {
                ...translation
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAgentBankDetails(translation.type, translation.agentId, translation.id))
            }
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

/** Agent Bank Widget */

export const setAgentBankWidget = (transactionType, url) => ({
    type: SET_AGENT_BANK_WIDGET,
    payload: { transactionType, url }
})

export const getAgentBankWidget = (transactionType, languageCode) => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.LAUNCH_AGENT_WIDGET,
            method: Methods.GET,
            params: {
                paymentType: PAYMENT_METHODS.BANK_TRANSFER,
                transactionType,
                languageCode
            }
        })
        .then(({ data }) => {
            dispatch(setAgentBankWidget(transactionType, data.value))
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}
