import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import UnsavedFormsContext from './unsavedFormsContext';

import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

const INITIAL_UNSAVED_FORMS_DATA = {
    [UNSAVED_FORM_PAGE_TYPE.TAB]: {},
    [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]: {},
}

const UnsavedFormsProvider = ({ children }) => {
    const unsavedFormsRef = useRef({
        ...INITIAL_UNSAVED_FORMS_DATA
    })

    const setToUnsavedForms = useCallback(({ type, key, value = "changed" }) => {
        unsavedFormsRef.current[type][key] = value;
    }, [])

    const removeFromUnsavedForms = useCallback(({ type, key }) => {
        delete unsavedFormsRef.current[type][key];
    }, [])

    const resetUnsavedForms = useCallback((type) => {
        if (!Boolean(type)) {
            unsavedFormsRef.current = {
                ...INITIAL_UNSAVED_FORMS_DATA
            }

            return;
        }

        unsavedFormsRef.current[type] = {};
    }, [])

    const providerValue = useMemo(() => {
        return {
            unsavedForms: unsavedFormsRef.current,
            setToUnsavedForms,
            removeFromUnsavedForms,
            resetUnsavedForms,
        }
    }, [removeFromUnsavedForms, resetUnsavedForms, setToUnsavedForms])

    

    return (
        <UnsavedFormsContext.Provider value={providerValue}>
            {children}
        </UnsavedFormsContext.Provider>
    );
}

/** UnsavedFormsProvider propTypes
    * PropTypes
*/
UnsavedFormsProvider.propTypes = {
    children: PropTypes.node,
}

export default UnsavedFormsProvider;