import { NUMBER_FORMAT } from 'constants/common.constants';

export const getSeparators = (formatSettings) => {
    const numberFormatting = formatSettings.numberFormatting ? formatSettings.numberFormatting : NUMBER_FORMAT.FORMAT_1;
    let separator, decimal = null;
    switch (numberFormatting) {
        case NUMBER_FORMAT.FORMAT_1:
            separator = " ";
            decimal = ".";
            break;
        case NUMBER_FORMAT.FORMAT_2:
            separator = ",";
            decimal = ".";
            break;
        case NUMBER_FORMAT.FORMAT_3:
            separator = ".";
            decimal = ",";
            break;
        case NUMBER_FORMAT.FORMAT_4:
            separator = " ";
            decimal = ",";
            break;
        case NUMBER_FORMAT.FORMAT_5:
            separator = "";
            decimal = ".";
            break;
        case NUMBER_FORMAT.FORMAT_6:
            separator = "";
            decimal = ",";
            break;
        default:
            separator = " ";
            decimal = ".";
            break;
    }

    return {separator, decimal};
}