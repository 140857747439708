import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { Form, Row, Col, Spin, Radio, Collapse, Switch } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import Select from "components/common/select";
import NumericInput from "components/common/numericInput"
import NumericRanges from "components/common/numericRanges";
import Tooltip from 'components/common/tooltip';

import { getCommissionPlanSportSettings, saveCommissionPlanSportSettings } from "store/actions/dashboard/agentSystem/commissionPlans/sportSettings.action";

import commissionPlanSettingsType from "types/agent/commissionPlanSettings.type";
import commissionPlanSportSettingsType from "types/agent/commissionPlanSportSettings.type";
import userInfoType from 'types/profile/userInfo.type';

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import { numberTransform, isMobile } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { AGENT_TYPE } from "constants/agent.constants"
import { BET_TYPE } from 'constants/bet.constants';

import {
    COMMISSION_PLAN_SOURCE_TYPE,
    COMMISSION_PLAN_CALCULATE_BY,
    COMMISSION_SETTINGS_TYPE,
    COMMISSION_PLAN_SOURCE,
    COMMISSION_PLAN_TYPE,
    COMMISSION_INCOME_TYPE,
    COMMISSION_PLAN_TURNOVER_MAX_PERCENT,
    INFINITY,
    MINUS_INFINITY,
} from "constants/commission.constants";
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

/** Agent Edit Page Commision Plan Tab Edit subtab Sport Settings Component */
const CommissionPlanSportSettingsComponent = ({
    saveCommissionPlanSportSettings,
    getCommissionPlanSportSettings,
    isSaving,
    isLoading,
    commissionPlanSportSettings,
    commissionPlanSettings,
    userInfo,
    type,
    agentType,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();
    const searchParams = useParams();

    const commissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");
    const currentCommissionPlanName = (new URLSearchParams(search)).get("commissionPlanName");

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const sportSettings = commissionPlanSportSettings[type];

    /** Available Settings Types */
    const incomeTypes = commissionPlanSettings.type === COMMISSION_PLAN_TYPE.MIX ?
        [COMMISSION_INCOME_TYPE.NETWORK, COMMISSION_INCOME_TYPE.DIRECT] :
        commissionPlanSettings.type === COMMISSION_PLAN_TYPE.DIRECT ? [COMMISSION_INCOME_TYPE.DIRECT] : [COMMISSION_INCOME_TYPE.NETWORK]

    const [currentValues, setCurrentValues] = useState(null)

    const navigateWithConfirmation = useUnsavedFormConfirmation({
        cb: navigate,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    useEffect(() => {
        getCommissionPlanSportSettings(commissionPlanId, searchParams.id, type)
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...getDefaultValues()
        })
        setCurrentValues({ ...getDefaultValues() })
    }, [sportSettings])

    /** Function to get form default values
       * @function
       * @returns {object}
       * @memberOf CommissionPlanSportSettingsComponent
   */
    const getDefaultValues = () => {

        const values = {};

        incomeTypes.forEach(incomeType => {
            values[incomeType] = {};
            Object.values(BET_TYPE).forEach(betType => {
                const betTypeSetting = sportSettings.find(ss => ss.incomeType === incomeType && ss.betType === betType)
                if (betTypeSetting) {
                    values[incomeType][betType] = {
                        enabled: betTypeSetting.enabled,
                        sourceType: betTypeSetting.sourceType,
                        calculateBy: betTypeSetting.calculateBy,
                        ranges: betTypeSetting.ranges.map((r, i) => ({
                            ...r,
                            from: i === 0 ? MINUS_INFINITY : r.from,
                            to: i === betTypeSetting.ranges.length - 1 ? INFINITY : r.to,
                        })),
                    }

                    if (betType === BET_TYPE.SINGLE) {
                        values[incomeType][betType]["minOdds"] = betTypeSetting.minOdds;
                    } else if (betType === BET_TYPE.MULTI) {
                        values[incomeType][betType]["minOdds"] = betTypeSetting.minOdds;
                        values[incomeType][betType]["minTotalOdds"] = betTypeSetting.minTotalOdds;
                        values[incomeType][betType]["minBetCount"] = betTypeSetting.minBetCount;
                    }
                }
            })
        })


        return values;
    }

    /** Function to map values from from
       * @function
       * @returns {object}
       * @memberOf CommissionPlanSportSettingsComponent
   */
    const mapSettingsValues = data => {
        const values = [];

        incomeTypes.forEach(incomeType => {
            Object.values(BET_TYPE).forEach(betType => {
                const dataItem = data[incomeType][betType];
                if (dataItem) {
                    let item = {
                        betType: betType,
                        incomeType: incomeType,
                        type: type,
                        enabled: dataItem.enabled,
                        sourceType: dataItem.sourceType,
                        calculateBy: dataItem.calculateBy,
                        ranges: dataItem.ranges.map(r => ({ ...r, to: r.to === INFINITY ? null : r.to, from: r.from === MINUS_INFINITY ? null : r.from }))
                    }

                    if (betType === BET_TYPE.SINGLE) {
                        item["minOdds"] = dataItem.minOdds;
                    } else if (betType === BET_TYPE.MULTI) {
                        item["minOdds"] = dataItem.minOdds;
                        item["minTotalOdds"] = dataItem.minTotalOdds;
                        item["minBetCount"] = dataItem.minBetCount;
                    }

                    values.push(item)
                }
            })
        })



        return values;
    }

    /** Fires when form submitted
       * @function
       * @memberOf CommissionPlanSportSettingsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const commissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");

                const d = {
                    id: commissionPlanId,
                    agentId: searchParams.id,
                    settings: mapSettingsValues(data)
                }

                saveCommissionPlanSportSettings(d, type);

                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    /** Function, to validate limit
       * @function
       * @returns {number} value
       * @param {string} limit
       * @memberOf CommissionPlanSportSettingsComponent
   */
    const validateLimit = (value, limit) => {
        if (value === "" || value === null) return Promise.resolve();
        const min = limit === "minBetCount" ? 2 : 1
        if (Number(value) < min || Number(value) > 1000) {
            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", min).replace("%Y%", 1000))
        }
        return Promise.resolve();
    }

    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf CommissionPlanSportSettingsComponent
   */
    const formChanged = formValues => {
        const values = { ...formValues };

        return isFormChanged({ ...values }, { ...getDefaultValues() })
    }

    const isDisabled = agentType === AGENT_TYPE.AGENT || !hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.MODIFY }) || searchParams.id === userInfo.id

    /** Function to check if the field should be disabled
         * @function
         * @param {string} settingsFieldName - settings/mixSettings
         * @param {string} type - single/multi/system
         * @returns {JSX}
         * @memberOf CommissionPlanSportSettingsComponent
    */
    const isFieldDisabled = (incomeType, betType, allowForAgent) => {

        if (!allowForAgent) {
            if (isDisabled || commissionPlanSettings.isUsed) return true;
        } else {
            if (
                !hasPermission({ resource: agentType === AGENT_TYPE.AGENT ? PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN : PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.MODIFY }) || searchParams.id === userInfo.id) {
                return true;
            }
        }

        return !(currentValues?.[incomeType]?.[betType]?.enabled ?? false);
    }

    const handleCalculateByChange = (value, incomeType, betType) => {
        const s = sportSettings.find(s => s.incomeType === incomeType && s.betType === betType);

        if (s.calculateBy !== value) {
            setFieldsValue({
                [incomeType]: {
                    [betType]: {
                        ranges: [
                            { from: MINUS_INFINITY, to: INFINITY, value: 0 }
                        ],
                        sourceType: value !== COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE ? COMMISSION_PLAN_SOURCE_TYPE.PERCENT : s.sourceType
                    }
                }
            })

        } else {
            setFieldsValue({
                [incomeType]: {
                    [betType]: {
                        ranges: s.ranges.map((r, i) => ({
                            ...r,
                            from: i === 0 ? MINUS_INFINITY : r.from,
                            to: i === s.ranges.length - 1 ? INFINITY : r.to,
                        })),
                        sourceType: s.sourceType
                    }
                }
            })
        }
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Function to render JSX Content for type
       * @function
       * @param {string} incomeType - commission plan type
       * @returns {JSX}
       * @memberOf CommissionPlanSportSettingsComponent
   */
    const renderSettings = incomeType => {
        return !isLoading ? (
            <Collapse
                className={"rt--collapse" + (incomeTypes.length > 1 ? " rt--collapse-dark rt--collapse-without-border" : isMobile() ? " rt--collapse-white" : " rt--collapse-with-border")}
                items={
                    Object.values(BET_TYPE).map((betType, j) => (
                        {
                            key: betType,
                            showArrow: false,
                            forceRender: false,
                            label: (
                                <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
                                    <div className="rt--flex rt--align-center rt--collapse-header">
                                        <i className="icon-down rt--font-bigest"/>
                                        <span className="rt--title rt--font-normal rt--font-bold rt--pl-8 rt--pr-8">
                                                {
                                                    betType === BET_TYPE.SINGLE ? t("backoffice.commissionplans.single") :
                                                        betType === BET_TYPE.MULTI ? t("backoffice.commissionplans.multi") :
                                                            t("backoffice.commissionplans.system")
                                                }
                                            </span>
                                    </div>
                                    <div
                                        className={'rt--switcher' + (!isMobile() ? " rt--ml-16 rt--mr-16" : "")}
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Form.Item
                                            name={[incomeType, betType, "enabled"]}
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch
                                                disabled={isDisabled}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            ),
                            children: (
                                <div className={incomeTypes.length > 1 ? isMobile() ? "rt--pl-12 rt--pr-12 rt--pb-16" : "" : ""}>
                                    <div className='rt--collapse-content-dark rt--pl-16 rt--pr-16 rt--pt-16'>
                                        <Row gutter={[16, 0]}>
                                            {
                                                (betType === BET_TYPE.SINGLE || betType === BET_TYPE.MULTI) && (
                                                    <Col xs={24} sm={12} xl={6}>
                                                        <Form.Item
                                                            label={
                                                                <div className="rt--flex rt--align-center">
                                                                    {t('backoffice.commissionplans.minOdds')}
                                                                    <Tooltip
                                                                        title={t(`backoffice.commissionplans.minOddsTooltip`)}
                                                                        trigger={["hover", "click"]}
                                                                        placement="bottomLeft"
                                                                        enableMobile={true}
                                                                    >
                                                                        <i className='icon-info rt--font-normal rt--ml-6'/>
                                                                    </Tooltip>
                                                                </div>
                                                            }
                                                            name={[incomeType, betType, "minOdds"]}
                                                            rules={[
                                                                {
                                                                    type: "number",
                                                                    message: t('backoffice.validation.fieldInvalid'),
                                                                    transform: numberTransform
                                                                },
                                                                ({getFieldValue}) => ({
                                                                    validator(rule, value) {
                                                                        return validateLimit(value, "minOdds");
                                                                    }
                                                                })
                                                            ]}
                                                            className={'rt--general-form-item ' + (isFieldDisabled(incomeType, betType) ? "rt--form-item-disabled" : "")}
                                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minOdds')}`}
                                                        >
                                                            <NumericInput
                                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minOdds')}`}
                                                                autoFocus={(isMobile() ? false : true)}
                                                                disabled={isFieldDisabled(incomeType, betType)}
                                                                maxLength={9}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                            {
                                                betType === BET_TYPE.MULTI && (
                                                    <Col xs={24} sm={12} xl={6}>
                                                        <Form.Item
                                                            label={
                                                                <div className="rt--flex rt--align-center">
                                                                    {t('backoffice.commissionplans.minTotalOdds')}
                                                                    <Tooltip
                                                                        title={t(`backoffice.commissionplans.minTotalOddsTooltip`)}
                                                                        trigger={["hover", "click"]}
                                                                        placement="bottomLeft"
                                                                        enableMobile={true}
                                                                    >
                                                                        <i className='icon-info rt--font-normal rt--ml-6'/>
                                                                    </Tooltip>
                                                                </div>
                                                            }
                                                            name={[incomeType, betType, "minTotalOdds"]}
                                                            rules={[
                                                                {
                                                                    type: "number",
                                                                    message: t('backoffice.validation.fieldInvalid'),
                                                                    transform: numberTransform
                                                                },
                                                                ({getFieldValue}) => ({
                                                                    validator(rule, value) {
                                                                        return validateLimit(value, "minTotalOdds");
                                                                    }
                                                                })
                                                            ]}
                                                            className={'rt--general-form-item ' + (isFieldDisabled(incomeType, betType) ? "rt--form-item-disabled" : "")}
                                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.jobs.cronExpression')}`}
                                                        >
                                                            <NumericInput
                                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minOdds')}`}
                                                                autoFocus={(isMobile() ? false : true)}
                                                                disabled={isFieldDisabled(incomeType, betType)}
                                                                maxLength={9}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                            {
                                                betType === BET_TYPE.MULTI && (
                                                    <Col xs={24} sm={12} xl={6}>
                                                        <Form.Item
                                                            label={
                                                                <div className="rt--flex rt--align-center">
                                                                    {t('backoffice.commissionplans.minBetCount')}
                                                                    <Tooltip
                                                                        title={t(`backoffice.commissionplans.minBetCountTooltip`)}
                                                                        trigger={["hover", "click"]}
                                                                        placement="bottomLeft"
                                                                        enableMobile={true}
                                                                    >
                                                                        <i className='icon-info rt--font-normal rt--ml-6'/>
                                                                    </Tooltip>
                                                                </div>

                                                            }
                                                            name={[incomeType, betType, "minBetCount"]}
                                                            rules={[
                                                                {
                                                                    type: "number",
                                                                    message: t('backoffice.validation.fieldInvalid'),
                                                                    transform: numberTransform
                                                                },
                                                                ({getFieldValue}) => ({
                                                                    validator(rule, value) {
                                                                        return validateLimit(value, "minBetCount");
                                                                    }
                                                                })
                                                            ]}
                                                            className={isFieldDisabled(incomeType, betType) ? "rt--form-item-disabled rt--general-form-item" : " rt--general-form-item"}
                                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minBetCount')}`}
                                                        >
                                                            <NumericInput
                                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minBetCount')}`}
                                                                autoFocus={(isMobile() ? false : true)}
                                                                disabled={isFieldDisabled(incomeType, betType)}
                                                                maxLength={9}
                                                                isInteger={true}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                            <Col xs={24} sm={12} xl={6}>
                                                <Form.Item
                                                    label={`${t('backoffice.commissionplans.sourceType')} *`}
                                                    name={[incomeType, betType, "sourceType"]}
                                                    rules={[{
                                                        required: true,
                                                        message: t('backoffice.validation.fieldRequired')
                                                    }]}
                                                    className={
                                                        isFieldDisabled(incomeType, betType) ||
                                                        (currentValues?.[incomeType]?.[betType]?.calculateBy ?? COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE) !== COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE
                                                            ? "rt--form-item-disabled" : ""
                                                    }
                                                >
                                                    <Select
                                                        options={[
                                                            ...(currentValues?.[incomeType]?.[betType]?.calculateBy ?? COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE) == COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE ? [
                                                                {
                                                                    value: COMMISSION_PLAN_SOURCE_TYPE.FIXED_AMOUNT,
                                                                    text: t('backoffice.commissionplans.fixedAmount')
                                                                }
                                                            ] : [],
                                                            {
                                                                value: COMMISSION_PLAN_SOURCE_TYPE.PERCENT,
                                                                text: t('backoffice.commissionplans.percent')
                                                            }
                                                        ]}
                                                        placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.sourceType")}`}
                                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                        disabled={
                                                            isFieldDisabled(incomeType, betType) ||
                                                            (currentValues?.[incomeType]?.[betType]?.calculateBy ?? COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE) !== COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={24}>
                                                <Form.Item
                                                    label={t("backoffice.commissionplans.calculateBy")}
                                                    name={[incomeType, betType, "calculateBy"]}
                                                    rules={[{
                                                        required: true,
                                                        message: t('backoffice.validation.fieldRequired')
                                                    }]}
                                                    className={'rt--form-item-inline rt--form-item-radio ' + (betType === BET_TYPE.SYSTEM || isFieldDisabled(incomeType, betType) ? "rt--form-item-disabled" : "")}
                                                >

                                                    <Radio.Group
                                                        disabled={betType === BET_TYPE.SYSTEM || isFieldDisabled(incomeType, betType)}
                                                        onChange={e => handleCalculateByChange(e.target.value, incomeType, betType)}
                                                        options={betType === BET_TYPE.SINGLE ? [
                                                            {
                                                                label: t('backoffice.commissionplans.oddsRange'),
                                                                value: COMMISSION_PLAN_CALCULATE_BY.ODD_RANGE
                                                            },
                                                            {
                                                                label: `${t('backoffice.commissionplans.source')} (${commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.GGR ? t('backoffice.commissionplans.ggr') : commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.NGR ? t('backoffice.commissionplans.ngr') : commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.TURNOVER ? t('backoffice.commissionplans.turnover') : ""})`,
                                                                value: COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE
                                                            }
                                                        ] : betType === BET_TYPE.MULTI ? [
                                                            {
                                                                label: t('backoffice.commissionplans.betCount'),
                                                                value: COMMISSION_PLAN_CALCULATE_BY.BET_COUNT
                                                            },
                                                            {
                                                                label: t('backoffice.commissionplans.oddsRange'),
                                                                value: COMMISSION_PLAN_CALCULATE_BY.ODD_RANGE
                                                            },
                                                            {
                                                                label: `${t('backoffice.commissionplans.source')} (${commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.GGR ? t('backoffice.commissionplans.ggr') : commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.NGR ? t('backoffice.commissionplans.ngr') : commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.TURNOVER ? t('backoffice.commissionplans.turnover') : ""})`,
                                                                value: COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE
                                                            }
                                                        ] : [
                                                            {
                                                                label: `${t('backoffice.commissionplans.source')} (${commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.GGR ? t('backoffice.commissionplans.ggr') : commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.NGR ? t('backoffice.commissionplans.ngr') : commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.TURNOVER ? t('backoffice.commissionplans.turnover') : ""})`,
                                                                value: COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE
                                                            }
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} sm={12} xl={8}>
                                                <Form.Item
                                                    className="rt--form-item-without-margin"
                                                >
                                                    <NumericRanges
                                                        formInstance={formInstance}
                                                        permission={agentType === AGENT_TYPE.AGENT ? PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN : PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN}
                                                        isPercent={currentValues?.[incomeType]?.[betType]?.sourceType === COMMISSION_PLAN_SOURCE_TYPE.PERCENT}
                                                        fieldName={[incomeType, betType, "ranges"]}
                                                        editable={!isFieldDisabled(incomeType, betType)}
                                                        maxPercent={
                                                            commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.TURNOVER &&
                                                            (
                                                                (betType === BET_TYPE.SINGLE && currentValues?.[incomeType]?.[betType]?.calculateBy === COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE) ||
                                                                (betType === BET_TYPE.MULTI && currentValues?.[incomeType]?.[betType]?.calculateBy === COMMISSION_PLAN_CALCULATE_BY.DEFAULT_SOURCE) ||
                                                                betType === BET_TYPE.SYSTEM
                                                            ) ? COMMISSION_PLAN_TURNOVER_MAX_PERCENT : 100
                                                        }
                                                        disabled={isFieldDisabled(incomeType, betType, true)}
                                                        hideIndex={true}
                                                        isInteger={betType === BET_TYPE.MULTI && currentValues?.[incomeType]?.[betType]?.calculateBy === COMMISSION_PLAN_CALCULATE_BY.BET_COUNT}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )
                        }
                    ))
                }
            />
        ) : <Fragment />
    }

    /** Function to detect if can activate/deactivate delete commission plan
       * @function
       * @memberOf CommissionPlanSportSettingsComponent
   */
    const canEdit = () => {
        return hasPermission({ resource: agentType === AGENT_TYPE.AGENT ? PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN : PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.MODIFY }) && (agentType !== AGENT_TYPE.AGENT || searchParams.id !== userInfo.id)
    }

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit(),
                        loading: isSaving,
                        disabled: !isFormTouched || searchParams.id === userInfo.id
                    },
                    {
                        type: "secondary",
                        onClick: () => {
                            navigateWithConfirmation((pathname + search + hash)
                                .replace("&commissionPlanId=" + commissionPlanId, "")
                                .replace("&commissionPlanName=" + currentCommissionPlanName, ""),
                                { replace: true }
                            )
                        },
                        text: (
                            <span className='rt--back-button'>
                                <i className="icon-left rt--font-big" />
                                <span>
                                    {t("backoffice.common.back")}
                                </span>
                            </span>
                        ),
                        enabled: true,
                        className: "rt--button-secondary",
                        placement: "left",
                    },
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => {
                        setCurrentValues(formValues);
                        setIsFormTouched(formChanged({ ...formValues }))
                    }}
                >

                    {
                        incomeTypes.length > 1 ? (
                            <div>
                                {
                                    incomeTypes.map(incomeType => (
                                        <div className='rt--widget rt--widget-commission-settings rt--mb-24' key={incomeType}>
                                            <div className='rt--widget-header rt--pl-16 rt--pr-8 rt--flex rt--align-center'>
                                                <i className="icon-direct rt--font-bigest" />
                                                <b className='rt--title rt--font-big rt--font-bold rt--pl-8 rt--pr-8'>
                                                    {
                                                        incomeType === COMMISSION_INCOME_TYPE.DIRECT ? t('backoffice.commissionplans.direct') : t('backoffice.commissionplans.network')
                                                    }
                                                </b>
                                            </div>
                                            <div className='rt--widget-content'>
                                                {renderSettings(incomeType)}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <div>
                                {renderSettings(incomeTypes[0])}
                            </div>
                        )
                    }
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** CommissionPlanSportSettingsComponent propTypes
    * PropTypes
*/
CommissionPlanSportSettingsComponent.propTypes = {
    /** Redux state property, is true when commision plan sport settings are saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading commission plan sport settings */
    isLoading: PropTypes.bool,
    /** Redux state, represents the sport settings of current editing commision plan  */
    commissionPlanSportSettings: commissionPlanSportSettingsType,
    /** Redux state, represents the commision plan settings of current editing agent  */
    commissionPlanSettings: commissionPlanSettingsType,
    /** Redux action to save commision plan sport settings */
    saveCommissionPlanSportSettings: PropTypes.func,
    /** Redux action to get commision plan sport settings */
    getCommissionPlanSportSettings: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Agent type */
    agentType: PropTypes.oneOf(Object.values(AGENT_TYPE)),
    /** Sport type */
    type: PropTypes.oneOf(Object.values(COMMISSION_SETTINGS_TYPE)),
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionPlanSportSettings: (id, agentId, type) => {
            dispatch(getCommissionPlanSportSettings(id, agentId, type));
        },
        saveCommissionPlanSportSettings: (data, type) => {
            dispatch(saveCommissionPlanSportSettings(data, type));
        }
    }
)

const mapStateToProps = state => {
    return {
        commissionPlanSportSettings: state.commissionPlans.edit.sportSettings,
        commissionPlanSettings: state.commissionPlans.edit.settings,
        isSaving: state.commissionPlans.isSaving,
        isLoading: state.commissionPlans.isLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanSportSettingsComponent)
