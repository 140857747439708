import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";

import { getCurrencyFieldOptions } from 'utils/currency';
import { getSelectFieldOptions } from 'utils/dashboard';

import { FILTER_TYPE } from 'constants/common.constants';
import { FINANCIAL_DASHBOARD_FILTER_NAMES, TIME_PERIOD, TIME_PERIOD_TRANSLATION_SOURCE } from 'pages/dashboard/constants';
import useProjectType from "hooks/useProjectType";

const MobileFilters = ({
    userCurrencies,
    setFinancialFilters,
    filters,
}) => {
    const { t } = useTranslation();

    const { hasLiteMode } = useProjectType();

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);
    const timePeriodFieldOptions = getSelectFieldOptions({
        dataSource: TIME_PERIOD,
        translationSource: TIME_PERIOD_TRANSLATION_SOURCE,
    })

    // do not show filters until currencies are loaded
    if (!filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]) {
        return <></>
    }

    return (
        <FiltersWrapper
            loadFn={() => { }}
            setFiltersFn={setFinancialFilters}
            updateProps={[]}
            filters={filters}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.dashboard.filterDirectAgents')}
                        name={FINANCIAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE}
                    >
                        <Select
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={[
                                { value: FILTER_TYPE.DIRECT, text: t("backoffice.dashboard.direct") },
                                { value: FILTER_TYPE.NETWORK, text: t("backoffice.dashboard.network") },
                            ]}
                        />
                    </Form.Item>
                </Col>

                {!hasLiteMode && (
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.dashboard.currency')}
                            name={FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY}
                        >
                            <Select
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                options={currencyFieldOptions}
                            />
                        </Form.Item>
                    </Col>
                )}

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.dashboard.timePeriod')}
                        name={FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD}
                    >
                        <Select
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={timePeriodFieldOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    );
}

MobileFilters.propTypes = {
    userCurrencies: PropTypes.array,
    filters: PropTypes.object,
    setFinancialFilters: PropTypes.func,
}

export default MobileFilters;
