import React, { useEffect, useRef } from "react";

import logoSignWhite from "NEW/public/images/logos/logo-sign-white.svg";
import logoSign from "NEW/public/images/logos/logo-sign.svg";

import { isRetailProject } from "NEW/src/core/helpers/projectEnvironmanet/isRetailProject";
import particles from "NEW/src/core/lib/particles";

const AuthSidebar = () => {
	const sideBarRef = useRef(null);

	useEffect(() => {
		particles({ container: sideBarRef.current });
	}, []);

	const logoSrc = isRetailProject() ? logoSignWhite : logoSign;

	return (
		<div ref={sideBarRef} className="rt--auth-sidebar">
			<img src={logoSrc} alt="logo" />
		</div>
	);
};

export default AuthSidebar;
