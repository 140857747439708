import React from 'react';

import { useParams } from "react-router-dom";

import Sessions from '../../../../../sessions/sessions.component';

import { USER_TYPE } from "constants/user.constants";
import { PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Betshop Manager Edit Page Sessions Tab Component */
const SessionsComponent = () => {
    const searchParams = useParams();

    return (
        <Sessions
            excludeColumns={['previousUserName']}
            userRole={USER_TYPE.BETSHOP_MANAGER}
            userId={searchParams.id}
            permissionResource={PERMISSION_RESOURCE.BETSHOP_MANAGER_SESSIONS}
        />
    )
}

export default SessionsComponent;
