import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";

import useDate from 'hooks/useDate';

import { Form, Col, Row, Radio } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";

import { changeCalculationPeriod } from "store/actions/dashboard/agentSystem/commissionPlans/settings.action";

import { POPUP_SIZE } from "constants/common.constants";
import { COMMISSION_PLAN_CALCULATION_PERIOD } from "constants/commission.constants"

import commissionPlanSettingsType from "types/agent/commissionPlanSettings.type";

/** Commission Plan chnage period popup Component */
const CommissionPlanChangePeriodComponent = ({
    changeCalculationPeriod,
    commissionPlanSettings,
    period,
    onClose
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const { search } = useLocation();

    const searchParams = useParams();

    const [newPeriod, setNewPeriod] = useState(period);

    const [calculationStartFromCurrentPeriod, setCalculationStartFromCurrentPeriod] = useState(true)

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    /** Fires when form submitted
       * @function
       * @memberOf CommissionPlanChangePeriodComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const commissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");

                const d = {
                    id: commissionPlanId,
                    agentId: searchParams.id,
                    period: data.newPeriod,
                    calculationStartFromCurrentPeriod: data.calculationStartFromCurrentPeriod
                }

                changeCalculationPeriod(d);
                onClose();
            }).catch(ex => {
                console.log(ex)
            })
    }

    /** Function to calculate next calculation time
       * @function
       * @returns {string}
       * @memberOf CommissionPlanChangePeriodComponent
    */
    const calculateNextCalculationTime = () => {

        if(!calculationStartFromCurrentPeriod || commissionPlanSettings.period === newPeriod){
            return dateService.format(commissionPlanSettings.nextCalculationDateTime);
        }
        let date = dateService.makeUtcDateToLocal(commissionPlanSettings.createdTime);

        if (commissionPlanSettings.lastCalculatedTime) {
            date = dateService.makeUtcDateToLocal(commissionPlanSettings.lastCalculatedTime);
        }

        switch (newPeriod) {
            case COMMISSION_PLAN_CALCULATION_PERIOD.HOUR:
                date = dateService.addUnit(date, 1, 'hour');
                break;
            case COMMISSION_PLAN_CALCULATION_PERIOD.DAY:
                date = dateService.addUnit(date, 1, 'days');
                break;
            case COMMISSION_PLAN_CALCULATION_PERIOD.WEEK:
                date = dateService.addUnit(date, 7, 'days');
                break;
            case COMMISSION_PLAN_CALCULATION_PERIOD.TWO_WEEK:
                date = dateService.addUnit(date, 14, 'days');
                break;
            case COMMISSION_PLAN_CALCULATION_PERIOD.THREE_WEEK:
                date = dateService.addUnit(date, 21, 'days');
                break;
            case COMMISSION_PLAN_CALCULATION_PERIOD.MONTH:
                date = dateService.addUnit(date, 1, 'month');
                break;
            default:
                break;
        }


        date = dateService.isBefore(date, dateService.getNow()) ? dateService.getNow() : date;

        return dateService.format(date, true, true, false);
    }

    return (
        <Modal
            title={t('backoffice.commissionplans.changeCalculationPeriod')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.change')}
            onOk={handleForm}
            onCancel={onClose}
            size={POPUP_SIZE.SMALL}
            disableOkButton={commissionPlanSettings.period === newPeriod}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    calculationStartFromCurrentPeriod: true,
                    currentPeriod: commissionPlanSettings.period,
                    newPeriod: period
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <div className='rt--mb-24'>
                            <Form.Item
                                label={t("backoffice.commissionplans.calculationPeriodChange")}
                                name="calculationStartFromCurrentPeriod"
                                className='rt--form-item-inline rt--form-item-radio-buttons'
                            >
                                <Radio.Group
                                    optionType="button"
                                    options={[
                                        { label: t('backoffice.commissionplans.fromNow'), value: true },
                                        { label: t('backoffice.commissionplans.afterCurrentPeriod'), value: false }
                                    ]}
                                    onChange={e => setCalculationStartFromCurrentPeriod(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label={t("backoffice.commissionplans.currentPeriod")}
                            name="currentPeriod"
                            className="rt--form-item-disabled"
                        >
                            <Select
                                options={[
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.HOUR, text: t('backoffice.commissionplans.hour') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.DAY, text: t('backoffice.commissionplans.day') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.WEEK, text: t('backoffice.commissionplans.week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.TWO_WEEK, text: t('backoffice.commissionplans.2week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.THREE_WEEK, text: t('backoffice.commissionplans.3week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.MONTH, text: t('backoffice.commissionplans.month') }
                                ]}
                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.currentPeriod")}`}
                                disabled={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label={t("backoffice.commissionplans.newPeriod")}
                            name="newPeriod"
                        >
                            <Select
                                options={[
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.HOUR, text: t('backoffice.commissionplans.hour') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.DAY, text: t('backoffice.commissionplans.day') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.WEEK, text: t('backoffice.commissionplans.week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.TWO_WEEK, text: t('backoffice.commissionplans.2week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.THREE_WEEK, text: t('backoffice.commissionplans.3week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.MONTH, text: t('backoffice.commissionplans.month') }
                                ]}
                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.newPeriod")}`}
                                onChange={e => setNewPeriod(e)}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <div className='rt--flex rt--form-item-note rt--align-center rt--form-item-note-calculation'>
                            <i className='icon-info rt--font-bigest rt--mr-4' />
                            <span className='rt--title rt--font-normal rt--font-regular rt--flex-equal'>
                                {
                                    `${t("backoffice.commissionplans.nextCalculationWillOccurOn")} ${calculateNextCalculationTime()}`
                                }
                            </span>
                        </div>
                    </Col>
                </Row>

            </Form>

        </Modal>
    )
}

/** CommissionPlanChangePeriodComponent propTypes
    * PropTypes
*/
CommissionPlanChangePeriodComponent.propTypes = {
    /** Redux action to change commission plan period */
    changeCalculationPeriod: PropTypes.func,
    /** Redux state, represents the ranges settings of current editing commision plan  */
    commissionPlanSettings: commissionPlanSettingsType,
    /** Selected Period */
    period: PropTypes.oneOf(Object.values(COMMISSION_PLAN_CALCULATION_PERIOD)),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        changeCalculationPeriod: periodSettings => {
            dispatch(changeCalculationPeriod(periodSettings));
        }
    }
)

const mapStateToProps = state => {
    return {
        commissionPlanSettings: state.commissionPlans.edit.settings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanChangePeriodComponent)
