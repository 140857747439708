import {
    SET_FAILED_TRANSACTIONS_ACTION_BEFORE,
    SET_FAILED_TRANSACTIONS_ACTION_FINISH,
    SET_FAILED_TRANSACTIONS,
    SET_FAILED_TRANSACTIONS_FILTERS,
    SET_FAILED_TRANSACTIONS_SORTING
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_FAILED_TRANSACTIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_FAILED_TRANSACTIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_FAILED_TRANSACTIONS:
            return {
                ...state,
                failedTransactions: !payload.add ? payload.failedTransactions.item2 : state.failedTransactions.concat(payload.failedTransactions.item2),
                total: payload.failedTransactions.item1,
            }
        case SET_FAILED_TRANSACTIONS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_FAILED_TRANSACTIONS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        default:
            return state;
    }
}