import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row, Checkbox } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import Input from 'components/common/input';

import { createBetshop, getBetshops, getBetshopAvailableCurrencies } from "store/actions/dashboard/retail/betshops/betshops.action";

import useAutosuggestion from 'hooks/useAutosuggestion';

import Paths from 'constants/path.constants';

import { USER_ROLE, USER_TYPE, USER_STATE } from "constants/user.constants";
import { NAME_REGEX } from "constants/regex.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import { getUser } from 'utils/auth';
import { isCurrentProjectAgentSystem, isFranchisingMode, flagsToBinary, getGlobalProjectData } from "utils/common";
import { hasOneOfPermissions } from 'utils/permissions';

import userInfoType from 'types/profile/userInfo.type';

import timezones from 'systemData/timezones';

/** Betshops Creating Popup Component */
const BetshopCreateComponent = ({
    isSaving,
    createBetshop,
    getBetshops,
    globalCompanyId,
    globalProjectId,
    getBetshopAvailableCurrencies,
    availableCurrencies,
    isAvailableCurrenciesLoading,
    userInfo,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const navigate = useNavigate();

    const [selectedAgent, setSelectedAgent] = useState(null);

    const { name: globalProjectName } = getGlobalProjectData(globalProjectId);

    const allCompanies = userInfo?.companies ?? [];
    const hasOneCurrency = Object.keys(availableCurrencies).length === 1;

    /** Detect if current company is test partner
       * @function
       * @returns {boolean}
       * @memberOf BetshopAddComponent
    */
    const canCreateNonDirectBetShops = () => (
        (
            getUser()?.role === USER_ROLE.AGENT &&
            ( userInfo?.additionalAccesses?.allowCreateNonDirectBetShops ?? false )
        ) ||
        (
            getUser()?.role === USER_ROLE.ADMIN &&
            isCurrentProjectAgentSystem(allCompanies, globalCompanyId, globalProjectId)
        )
    )

    /** Agent Names */
    const [ agentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        actionParams: {
            state: flagsToBinary([USER_STATE.ACTIVE, USER_STATE.IN_PROGRESS, USER_STATE.EXPIRED]),
            userRole: USER_TYPE.AGENT
        },
        disable: !canCreateNonDirectBetShops()
    })

    /** Load betshop available currencies */
    useEffect(() => {
        getBetshopAvailableCurrencies( selectedAgent ? selectedAgent : getUser()?.role !== USER_ROLE.ADMIN ? userInfo.id : undefined );
    }, [selectedAgent])


    useEffect(() => {
        if (agentNames.length > 0 && agentNames.some(a => a.id === userInfo.id)) {
            setFieldsValue({ parentId: userInfo.id })
        } else {
            setFieldsValue({ parentId: "" })
        }
    }, [agentNames])

    /** Detect if current company is test partner
       * @function
       * @returns {boolean}
       * @memberOf BetshopAddComponent
   */
    const isCurrentCompanyTest = allCompanies.find(p => p.id === globalCompanyId)?.isTesting ?? false

    /** Fires when form submitted
       * @function
       * @memberOf BetshopCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                if (hasOneCurrency) {
                    data.defaultCurrency = Object.keys(availableCurrencies)[0];
                }

                createBetshop(
                    data,
                    record => {
                        if (hasOneOfPermissions(
                            [
                                { resource: PERMISSION_RESOURCE.BETSHOP_GENERALINFO, action: PERMISSION_ACTION.VIEW },
                                { resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.VIEW },
                                { resource: getUser()?.role === USER_ROLE.ADMIN ? PERMISSION_RESOURCE.BETSHOP_WALLETS : PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW },
                                { resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.VIEW },
                                { resource: PERMISSION_RESOURCE.BETSHOP_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.VIEW },
                                { resource: PERMISSION_RESOURCE.BETSHOP_IP_ACCESS_RULE, action: PERMISSION_ACTION.VIEW }
                            ])) {
                            navigate(`${Paths.BETSHOPS_EDIT}/${record.id}?cid=${globalCompanyId}&pid=${globalProjectId}&name=${record.code}`);
                        } else {
                            getBetshops();
                            onClose();
                        }
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.betshops.createBetshop')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    name: "",
                    isTesting: isCurrentCompanyTest
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.betshops.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                { min: 3, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.name')}`}
                                maxLength={50}
                                autoFocus={true}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    {
                        canCreateNonDirectBetShops() && (
                            <Col span={24}>
                                <Form.Item
                                    label={isFranchisingMode() ? t('backoffice.common.betshopOwner') : t('backoffice.common.agent')}
                                    name="parentId"
                                >
                                    <Select
                                        options={
                                            [
                                                { value: "", text: getUser()?.role === USER_ROLE.ADMIN ? globalProjectName : getUser()?.userName },
                                                ...agentNames.map(item => (
                                                    { value: item.id, text: item.name }
                                                ))
                                            ]
                                        }
                                        placeholder={`${t('backoffice.common.select')} ${isFranchisingMode() ? t('backoffice.common.betshopOwner') : t('backoffice.common.agent')}`}
                                        onChange={e => setSelectedAgent(e)}
                                        search={true}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                    {!hasOneCurrency && (
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.betshops.defaultCurrency')} *`}
                                name="defaultCurrency"
                                rules={[{required: true, message: t('backoffice.validation.fieldRequired')}]}
                            >
                                <Select
                                    options={
                                        Object.keys(availableCurrencies).map(item => (
                                            {
                                                value: item.toUpperCase(),
                                                text: item.toUpperCase() + " - " + availableCurrencies[item]
                                            }
                                        ))
                                    }
                                    loading={isAvailableCurrenciesLoading}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.betshops.defaultCurrency')}`}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={24} >
                        <Form.Item
                            label={`${t('backoffice.betshops.timezone')} *`}
                            name="timeZoneId"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={
                                    timezones.map(item => (
                                        { value: item.id, text: item.description }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.betshops.timezone')}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isTesting"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.betshops.isTestBetshop')}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** BetshopCreateComponent propTypes
    * PropTypes
*/
BetshopCreateComponent.propTypes = {
    /** Redux state property, is true when creating betshop request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create betshop */
    createBetshop: PropTypes.func,
    /** Redux action to get betshops */
    getBetshops: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to get available currencies of betshop */
    getBetshopAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the object of available currencies of company */
    availableCurrencies: PropTypes.object,
    /** Redux state property, is true when loading available currencies of company */
    isAvailableCurrenciesLoading: PropTypes.bool,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createBetshop: (betshop, onSuccess) => {
            dispatch(createBetshop(betshop, onSuccess));
        },

        getBetshops: () => {
            dispatch(getBetshops());
        },

        getBetshopAvailableCurrencies: agentId => {
            dispatch(getBetshopAvailableCurrencies(agentId))
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.betshops.isSaving,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        availableCurrencies: state.betshops.availableCurrencies.availableCurrencies,
        isAvailableCurrenciesLoading: state.betshops.availableCurrencies.isLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BetshopCreateComponent)
