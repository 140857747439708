import { binaryToFlags, flagsToBinary } from "./common";

import { WALLET_TYPE } from "constants/wallet.constants";
import { BANK_EPOS_VALUE, FIELD_NAMES } from "constants/payments.constants";
import { PAYMENT_METHODS, WALLET_CONFIGS } from "constants/project.constants";

/**
 * This function is used in the projects and agents payment config section
 * Only payment types are available in the Agents section.
*/
export const constructFinaleData = (fieldsValue, selectedPaymentType) => {
    const requestBody = {};

    if (Object.hasOwn(fieldsValue, FIELD_NAMES.HIDE_HISTORY)) {
        requestBody.hidePaymentHistory = fieldsValue[FIELD_NAMES.HIDE_HISTORY];
    }

    if (Object.hasOwn(fieldsValue, FIELD_NAMES.WALLET_TYPES)) {
        requestBody.walletTypeIsCreditLine = fieldsValue[FIELD_NAMES.WALLET_TYPES] === WALLET_TYPE.CREDIT_LINE
    }

    if (Object.hasOwn(fieldsValue, FIELD_NAMES.WALLET_CONFIGS)) {
        requestBody.isStandartPaymentWithRelatedWallets = fieldsValue[FIELD_NAMES.WALLET_CONFIGS] === WALLET_CONFIGS.RELATED
    }

    if (selectedPaymentType === PAYMENT_METHODS.STANDART) {
        requestBody.type = PAYMENT_METHODS.STANDART;

        return requestBody;
    }

    const isBankAndEposSelected = fieldsValue[FIELD_NAMES.BANK] && fieldsValue[FIELD_NAMES.EPOS];
    const isBankSelected = fieldsValue[FIELD_NAMES.BANK];
    const isEposSelected = fieldsValue[FIELD_NAMES.EPOS];

    if (isBankAndEposSelected) {
        requestBody.type = flagsToBinary([PAYMENT_METHODS.EPOS, PAYMENT_METHODS.BANK_TRANSFER]);
    } else if (isBankSelected) {
        requestBody.type = flagsToBinary([PAYMENT_METHODS.BANK_TRANSFER]);
    } else if (isEposSelected) {
        requestBody.type = flagsToBinary([PAYMENT_METHODS.EPOS]);
    }

    return requestBody;
}

export const constructFormData = (paymentConfigs) => {
    const paymentTypes = binaryToFlags(Object.values(PAYMENT_METHODS), paymentConfigs.type);

    const formFieldsValue = {
        [FIELD_NAMES.HIDE_HISTORY]: paymentConfigs.hidePaymentHistory,
        [FIELD_NAMES.BANK]: paymentTypes.includes(PAYMENT_METHODS.BANK_TRANSFER),
        [FIELD_NAMES.EPOS]: paymentTypes.includes(PAYMENT_METHODS.EPOS),
        [FIELD_NAMES.WALLET_TYPES]: (
            paymentConfigs.walletTypeIsCreditLine
                ? WALLET_TYPE.CREDIT_LINE
                : WALLET_TYPE.BALANCE
        ),
        [FIELD_NAMES.WALLET_CONFIGS]: (
            paymentConfigs.isStandartPaymentWithRelatedWallets
                ? WALLET_CONFIGS.RELATED
                : WALLET_CONFIGS.NON_RELATED
        )
    };

    const selectedPaymentType = (
        paymentTypes.includes(PAYMENT_METHODS.STANDART)
            ? PAYMENT_METHODS.STANDART
            : BANK_EPOS_VALUE
    )

    return { formFieldsValue, selectedPaymentType }
}

export const doesUserMadeChanges = (fieldsValue, selectedPaymentType, initialData) => {
    const finaleData = constructFinaleData(fieldsValue, selectedPaymentType);

    for (const key in finaleData) {
        if (finaleData[key] !== initialData[key]) {
            return true;
        }
    }

    return false;
}