import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Form, Col, Row, Spin } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import Input from 'components/common/input';

import { createPlayer, getPlayerRegistrationForm, resetPlayerRegistrationForm, getPlayers } from 'store/actions/dashboard/players/players.action';
import { getProjectAvailableCurrencies } from "store/actions/dashboard/projects/projects.action";
import { getAgentCurrencies } from "store/actions/dashboard/agentSystem/agents/currencies.action";
import { getProjectCurrencies } from "store/actions/dashboard/projects/currencies.action";

import Paths from 'constants/path.constants';

import { POPUP_SIZE } from "constants/common.constants";
import { COMPANY_CONTROL_RULE, COMPANY_CONTROL_TYPE, COMPANY_DOCUMENT_TYPE } from 'constants/company.constants';
import { USER_ROLE, USER_STATE } from 'constants/user.constants';
import { CURRENCY_STATE } from "constants/currency.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import PATHS from 'constants/path.constants';

import { toLowerCaseFirstLetter, flagsToBinary, getGlobalProjectData } from "utils/common";
import { getUser } from 'utils/auth';
import { hasOneOfPermissions } from 'utils/permissions';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import registrationFormType from "types/project/registrationForm.type";
import userInfoType from 'types/profile/userInfo.type';
import currencyType from "types/currency/currency.type";

const FIELDS_ORDER = ["UserName", "Email", "PhoneNumber", "Password", "ConfirmPassword", "ParentId", "CurrencyCode", "DocumentType", "DocumentNumber"]

const addConfirmPassword = (registrationFormFields) => {
    return registrationFormFields.reduce((acc, filedRules) => {
        if (filedRules.name === "Password") {
            const confirmPasswordRules = {
                ...filedRules,
                name: "ConfirmPassword",
            }

            return [
                ...acc,
                filedRules,
                confirmPasswordRules,
            ]
        }

        return [...acc, filedRules];
    }, [])
}

/** Player Creating Popup Component */
const PlayerCreateComponent = ({
    isSaving,
    createPlayer,
    getPlayers,
    isRegistrationFormLoading,
    registrationForm,
    getPlayerRegistrationForm,
    resetPlayerRegistrationForm,
    globalCompanyId,
    globalProjectId,
    getAgentCurrencies,
    getProjectCurrencies,
    agentCurrencies,
    projectCurrencies,
    userInfo,
    onClose,
    isProjectCurrenciesLoading,
    isAgentCurrenciesLoading
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue, setFieldsValue } = formInstance;
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const [selectedParent, setSelectedParent] = useState(null);

    const { name: globalProjectName } = getGlobalProjectData(globalProjectId);

    const hasOneCurrencyLastValue = useRef(null);

    const shouldUseProjectCurrencies = !selectedParent && getUser()?.role === USER_ROLE.ADMIN;
    const currencies = shouldUseProjectCurrencies ? projectCurrencies.filter(item => item.enabled) : agentCurrencies;

    const hasOneCurrency = useMemo(() => {
        if (isProjectCurrenciesLoading || isAgentCurrenciesLoading) {
            return hasOneCurrencyLastValue.current;
        }

        hasOneCurrencyLastValue.current = currencies.length <= 1

        return hasOneCurrencyLastValue.current;
    }, [currencies, isProjectCurrenciesLoading, isAgentCurrenciesLoading]);

    /** Get Player Registration form */
    useEffect(() => {
        getPlayerRegistrationForm();

        return () => {
            resetPlayerRegistrationForm()
        }
    }, [])

    useEffect(() => {
        if (registrationForm?.length) {
            if (getUser()?.role !== USER_ROLE.ADMIN) {
                handleParentChange(userInfo.id);
            } else {
                handleParentChange("");
            }
        }
    }, [registrationForm]);

    /** Should show parent dropdown
      * @function
      * @returns {boolean}
      * @memberOf PlayerCreateComponent
   */
    const getParentDropdownRule = () => {

        if (getUser()?.role === USER_ROLE.ADMIN) {
            return COMPANY_CONTROL_RULE.OPTIONAL;
        }

        if (getUser()?.role === USER_ROLE.AGENT) {
            if (userInfo?.additionalAccesses?.allowCreateNonDirectPlayers ?? false) {
                return COMPANY_CONTROL_RULE.OPTIONAL
            }
        }
        return COMPANY_CONTROL_RULE.HIDDEN;
    }

    /** Get Agents */
    const hasParentField = registrationForm.some(control => control.name === "ParentId" && control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN) && getParentDropdownRule() !== COMPANY_CONTROL_RULE.HIDDEN;

    const [ agentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        dependencies: [registrationForm],
        actionParams: {state: flagsToBinary([USER_STATE.ACTIVE, USER_STATE.IN_PROGRESS, USER_STATE.EXPIRED])},
        disable: !hasParentField
    });


    useEffect(() => {
        if (getParentDropdownRule() !== COMPANY_CONTROL_RULE.HIDDEN) {
            if (getParentDropdownRule() === COMPANY_CONTROL_RULE.REQUIRED) {
                if (agentNames.length > 0 && selectedParent !== agentNames[0].id) {
                    handleParentChange(agentNames[0].id);
                }
            } else {
                if (selectedParent === null) {
                    handleParentChange("");
                }
            }
        }

    }, [agentNames]);

    const handleParentChange = (id) => {
        const shouldUseProjectCurrencies = !id && getUser()?.role === USER_ROLE.ADMIN;

        if (
            registrationForm.some(control => control.name === "CurrencyCode" && control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN
            )
        ) {
            if (shouldUseProjectCurrencies) {
                getProjectCurrencies()
            } else {
                getAgentCurrencies(id ? id : userInfo.id);
            }

            setFieldsValue({
                currencyCode: null
            })
        }

        setSelectedParent(id);
        setFieldsValue({
            "parentId": id
        })
    }

    /** Fires when form submitted
       * @function
       * @memberOf PlayerCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                if (hasOneCurrency) {
                    data.currencyCode = shouldUseProjectCurrencies ? currencies[0].code : currencies[0].currency;
                }

                createPlayer(
                    {
                        ...data
                    },
                    record => {
                        // if (hasOneOfPermissions(
                        //     [
                        //         { resource: PERMISSION_RESOURCE.PLAYER_GENERALINFO, action: PERMISSION_ACTION.VIEW },
                        //         { resource: PERMISSION_RESOURCE.BETS_ONLINE, action: PERMISSION_ACTION.VIEW }
                        //     ]
                        // )) {
                        //     navigate(`${Paths.PLAYERS_EDIT}/${record.id}?cid=${globalCompanyId}&pid=${globalProjectId}&name=${record.userName}`)
                        // } else {
                        //     if(pathname === PATHS.PLAYERS){
                        //         getPlayers();
                        //     }
                        // }
                        if(pathname === PATHS.PLAYERS){
                            getPlayers();
                        }
                        onClose();
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    /** Get rules for control
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf AgentCreateComponent
   */
    const getRulesForField = control => {
        const rules = [];

        if (control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED) {
            if (control.name !== "ParentId") {
                if (control.controlType === COMPANY_CONTROL_TYPE.SELECT) {
                    rules.push({ required: true, message: t('backoffice.validation.fieldRequired') })
                } else {
                    rules.push({ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') })
                }
            }
        }

        switch (control.name) {
            case "UserName":
            case "Password":
            case "Email":
            case "PhoneNumber":
            case "DocumentNumber":
                rules.push({ pattern: control.regExp, message: t('backoffice.validation.fieldInvalid') });
                break;
            case "ConfirmPassword":
                rules.push(
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (control.selectedRule !== COMPANY_CONTROL_RULE.REQUIRED) {
                                if (!getFieldValue("password")) {
                                    return Promise.resolve()
                                }
                            }

                            if (value !== getFieldValue("password")) {
                                return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                            }
                            return Promise.resolve();
                        }
                    })
                )
                break;
            case "ParentId":
                if (getParentDropdownRule() === COMPANY_CONTROL_RULE.REQUIRED) {
                    rules.push({ required: true, message: t('backoffice.validation.fieldRequired') })
                }
                break;
            default:
                break;
        }

        return rules;
    }

    /** Get data for select control
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf PlayerCreateComponent
   */
    const getSelectData = control => {
        const data = {
            "ParentId": {
                items: (
                    getParentDropdownRule() === COMPANY_CONTROL_RULE.OPTIONAL ?
                        [
                            { 
                                value: getUser()?.role === USER_ROLE.ADMIN ? "" : userInfo.id , 
                                text: getUser()?.role === USER_ROLE.ADMIN ? globalProjectName : getUser()?.userName 
                            }
                        ] : []
                ).concat(
                    agentNames.map(agent => ({ value: agent.id, text: agent.name }))
                ),
                showSearch: true,
            },
            "CurrencyCode": {
                items: shouldUseProjectCurrencies ?
                    projectCurrencies
                        .filter(item => item.enabled)
                        .map(cur => (
                            { value: cur.code.toUpperCase(), text: cur.code.toUpperCase() }
                        )) :
                    agentCurrencies.map(cur => (
                        { value: cur.currency.toUpperCase(), text: cur.currency }
                    )),
                showSearch: false,
            },
            "DocumentType": {
                items: [
                    { value: COMPANY_DOCUMENT_TYPE.PASSPORT, text: t("backoffice.common.passport") },
                    { value: COMPANY_DOCUMENT_TYPE.ID, text: t("backoffice.common.passportID") },
                    { value: COMPANY_DOCUMENT_TYPE.DRIVING_LICENCES, text: t("backoffice.common.drivingLicences") }
                ],
                showSearch: false,
            },
        };
        return data[control.name];
    }

    const registrationFormFields = registrationForm.filter(control => (
        control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN &&
        (control.name !== "ParentId" || getParentDropdownRule() !== COMPANY_CONTROL_RULE.HIDDEN)
    ))

    registrationFormFields.sort((field1, field2) => {
        const fieldName1 = field1.name;
        const fieldName2 = field2.name;
        const fieldIndex1 = FIELDS_ORDER.indexOf(fieldName1);
        const fieldIndex2 = FIELDS_ORDER.indexOf(fieldName2);
        if(fieldIndex2 > fieldIndex1 && fieldIndex1 !== -1){
            return -1;
        }
        return 1;
    })

     /** If only 1 currency available, set it by default */
     useEffect(() => {
        if (
            registrationForm.some(control => control.name === "CurrencyCode" && control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN)
        ){
            const itemsCount = shouldUseProjectCurrencies ? projectCurrencies.filter(item => item.enabled).length : agentCurrencies.length;
            if(itemsCount === 1){
                const currencyCode = shouldUseProjectCurrencies ? projectCurrencies.filter(item => item.enabled)[0].code.toUpperCase() : agentCurrencies[0].currency.toUpperCase()
                setFieldsValue({
                    currencyCode: currencyCode
                })
            }
        }
    }, [registrationForm, projectCurrencies, agentCurrencies])

    const formRules = addConfirmPassword(registrationFormFields)

    return (
        <Modal
            title={t('backoffice.players.createPlayer')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Spin spinning={isRegistrationFormLoading}>
                <Form
                    className="rt--form"
                    form={formInstance}
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        {
                            formRules
                                .filter(control => !(control.name === 'CurrencyCode' && hasOneCurrency))
                                .map((control, index) => (
                                    <Col
                                        xs={24} sm={12}
                                        key={control.name}
                                    >
                                        {
                                            control.controlType === COMPANY_CONTROL_TYPE.INPUT ?
                                                (
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        validateFirst
                                                        className='rt--general-form-item'
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                    >
                                                        <Input
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                        />
                                                    </Form.Item>
                                                ) : control.controlType === COMPANY_CONTROL_TYPE.PASSWORD ? (

                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        validateFirst
                                                        className='rt--general-form-item'
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                    >
                                                        <Input
                                                            type='Password'
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            autoComplete="new-password"
                                                            onPaste={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                            onContextMenu={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                            onCopy={control.name === "ConfirmPassword" ? e => e.preventDefault() : undefined}
                                                            onChange={control.name === "Password" ? () => {
                                                                setTimeout(() => {
                                                                    if (getFieldValue('confirmPassword') !== "")
                                                                        validateFields(['confirmPassword'])
                                                                }, 0)
                                                            } : undefined} />
                                                    </Form.Item>
                                                ) : control.controlType === COMPANY_CONTROL_TYPE.SELECT ?
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        validateFirst
                                                        className='rt--general-form-item'
                                                    >
                                                        <Select
                                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                            options={
                                                                getSelectData(control).items
                                                            }
                                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            onChange={control.name === "ParentId" ? handleParentChange : undefined}
                                                            search={getSelectData(control).showSearch}
                                                        />
                                                    </Form.Item> : null
                                        }

                                    </Col>
                                ))
                        }
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

/** PlayerCreateComponent propTypes
    * PropTypes
*/
PlayerCreateComponent.propTypes = {
    /** Redux state property, is true when creating agent request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create player */
    createPlayer: PropTypes.func,
    /** Redux action to get players */
    getPlayers: PropTypes.func,
    /** Redux state property, is true when registration form is loading */
    isRegistrationFormLoading: PropTypes.bool,
    /** Redux state property, represents the registration form  */
    registrationForm: PropTypes.arrayOf(registrationFormType),
    /** Redux action to get agent player registration form */
    getPlayerRegistrationForm: PropTypes.func,
    /** Redux action to reset agent player registration form */
    resetPlayerRegistrationForm: PropTypes.func,
    /** Redux action to get agent currencies */
    getAgentCurrencies: PropTypes.func,
    /** Redux state, represents the currencies of current editing agent  */
    agentCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux state, represents the currencies of current editing project  */
    projectCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux action to get project currencies */
    getProjectCurrencies: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Redux state property, is agent currencies loading */
    isAgentCurrenciesLoading: PropTypes.bool,
    /** Redux state property, is project currencies loading */
    isProjectCurrenciesLoading: PropTypes.bool,
}

const mapDispatchToProps = dispatch => (
    {
        createPlayer: (player, onSuccess) => {
            dispatch(createPlayer(player, onSuccess));
        },

        getProjectAvailableCurrencies: () => {
            dispatch(getProjectAvailableCurrencies())
        },

        getPlayerRegistrationForm: () => {
            dispatch(getPlayerRegistrationForm());
        },

        resetPlayerRegistrationForm: () => {
            dispatch(resetPlayerRegistrationForm());
        },

        getAgentCurrencies: id => {
            dispatch(getAgentCurrencies(id, CURRENCY_STATE.ACTIVE));
        },

        getProjectCurrencies: () => {
            dispatch(getProjectCurrencies());
        },

        getPlayers: nextPage => {
            dispatch(getPlayers(nextPage));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.players.isSaving,
        registrationForm: state.players.registrationForm,
        isRegistrationFormLoading: state.players.isRegistrationFormLoading,
        availableCurrencies: state.projects.availableCurrencies.availableCurrencies,
        isAvailableCurrenciesLoading: state.projects.availableCurrencies.isLoading,
        agentCurrencies: state.agents.edit.currencies.currencies,
        projectCurrencies: state.projects.edit.currencies,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        userInfo: state.profile.userInfo,
        isAgentCurrenciesLoading: state.agents.edit.currencies.isLoading,
        isProjectCurrenciesLoading: state.projects.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerCreateComponent)
