import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { isMobile, isIOS } from 'utils/common';

/** GeneralInput */

const TextAreaInput = ({
	value,
	placeholder,
	...rest

}) => {

	const [hidePlaceholder, setHidePlaceholder] = useState(false)

	const inputRef = useRef(null);

	/** Fires on input change
	   * @function
	   * @memberOf TextAreaInput
   */

	useEffect(() => {
		if(inputRef.current && inputRef.current.resizableTextArea.textArea){
			if(value){
				setHidePlaceholder(true)
			} else {
				setHidePlaceholder(false)
			}
		}
		
	}, [value])


	return (
		<Input.TextArea
			{...rest}
			ref={inputRef}
			value={value}
			placeholder={(isMobile() && isIOS() ? null : placeholder)}
			className={hidePlaceholder ? "rt--hide-placeholder" : ""}
		/>
	)
}

/** Input propTypes
	* PropTypes
*/

TextAreaInput.propTypes = {
	/** Callback which called on input change */
	onChange: PropTypes.func,
	/** Input value */
	value: PropTypes.string,
	/** Input placeholder */
	placeholder: PropTypes.string
}

export default TextAreaInput;
