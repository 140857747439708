import PropTypes from 'prop-types';

import { PLAYER_STATE } from 'constants/player.constants';

export default PropTypes.shape({
    id: PropTypes.number,
    playerUserName: PropTypes.string,
    currencyCode: PropTypes.string,
    agentId: PropTypes.number,
    agentUserName: PropTypes.string,
    playerState: PropTypes.oneOf(Object.values(PLAYER_STATE)),
    playerRegistrationDate: PropTypes.string,
    firstTimeDepositDate: PropTypes.string,
    depositCount: PropTypes.number,
    depositAmount: PropTypes.number,
    withdrawCount: PropTypes.number,
    withdrawAmount: PropTypes.number,
    netDepositAmount: PropTypes.number,
    turnover: PropTypes.number,
    won: PropTypes.number,
    ggr: PropTypes.number,
    ngr: PropTypes.number,
    otherCosts: PropTypes.number
})