import React from 'react';

import PropTypes from "prop-types";

import RadioOption from "components/common/radioButtons/components/radioOption";

const RadioButtons = ({value, options, onChange}) => {
    const handleOptionClick = (value) => {
        onChange(value);
    }

    return (
        <div className="rt--radio-buttons">
            {options.map(option => (
                <RadioOption
                    key={option.value}
                    value={option.value}
                    selected={value === option.value}
                    onClick={handleOptionClick}
                >
                    {option.icon}
                </RadioOption>
            ))}
        </div>
    );
};

RadioButtons.propTypes = {
    value: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.node,
            value: PropTypes.any
        })
    ),
    onChange: PropTypes.func
}

export default RadioButtons;
