import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { TIME_PERIOD } from "../constants";

import dateService from "utils/date"
import {isFranchisingMode} from "utils/common";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";
import {FILTER_TYPE} from "constants/common.constants";

export const getRetailLineChartData = ({ filterType, currencyCode, timePeriod, betShopNameOrId, dataType }) => {
    const abortController = new AbortController();

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;
    const hasSelectedBetShop = !!betShopNameOrId;

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_RETAIL_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            betShopNameOrId,
            filterType: hasSelectedBetShop || isBetShopOwner ? FILTER_TYPE.DIRECT : filterType,
            currencyCode,
            timePeriod,
            dataType,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialLineChartData = ({ filterType, currencyCode, timePeriod, dataType }) => {
    const abortController = new AbortController();

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_FINANCES_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            filterType: isBetShopOwner ? FILTER_TYPE.DIRECT : filterType,
            currencyCode,
            timePeriod,
            dataType,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialLeaderBoardData = ({ filterType, currencyCode, timePeriod, dataType, endpoint, betShopNameOrId }) => {
    const abortController = new AbortController();

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;
    const hasSelectedBetShop = !!betShopNameOrId;

    const request = axios({
        url: endpoint,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            filterType: isBetShopOwner || hasSelectedBetShop ? FILTER_TYPE.DIRECT : filterType,
            currencyCode,
            timePeriod,
            dataType,
            betShopNameOrId,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalPieChartData = ({ filterType, entityType }) => {
    const abortController = new AbortController();

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_PIE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            filterType: isBetShopOwner ? FILTER_TYPE.DIRECT : filterType,
            entityType,
            timePeriod: TIME_PERIOD.TODAY,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalLineChartData = ({ filterType, entityType, timePeriod }) => {
    const abortController = new AbortController();

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            filterType: isBetShopOwner ? FILTER_TYPE.DIRECT : filterType,
            entityType,
            timePeriod,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalTopAgents = ({ filterType, entityType, timePeriod, }) => {
    const abortController = new AbortController();

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_TOP_AGENTS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            filterType: isBetShopOwner ? FILTER_TYPE.DIRECT : filterType,
            entityType,
            timePeriod,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getTotalsCardData = ({ currencyCode, betSlipFilterType, betShopNameOrId, filterType, timePeriod }) => {
    const abortController = new AbortController();

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;
    const hasSelectedBetShop = !!betShopNameOrId;

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_RETAIL_CARDS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            currencyCode,
            betSlipFilterType,
            betShopNameOrId,
            filterType: isBetShopOwner || hasSelectedBetShop ? FILTER_TYPE.DIRECT : filterType,
            timePeriod,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}
