import React from "react";

import NetworkComponent from "pages/agentSystem/network/network.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const NetworkRoute = () => {
    return <NetworkComponent />;
};

export default withPermission(
    withAuth(NetworkRoute),
    {
        resource: PERMISSION_RESOURCE.AGENT_NETWORK,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
