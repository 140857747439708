import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    getCompanyLanguages
} from "store/actions/dashboard/companies/languages.action";

import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";

import useIncludedColumns from 'hooks/useIncludedColumns';

import { isMobile } from 'utils/common';
import { tableColumnsCreator } from "utils/tableColumnsCreator";

import { COMPANY_LANGUAGES } from "constants/pageName.constants";
import { tableColumns } from "./columns";

import languageType from "types/language/language.type";

const ROW_UNIQUE_KEY_PROP = "code";

/** Company Edit Page Languages Tab Component */
const LanguagesComponent = ({
    getCompanyLanguages,
    languages,
    isLoading
}) => {
    const isMobileDevice = isMobile();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: COMPANY_LANGUAGES });

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            includedColumns,
            constructForInclude: true,
        });
    }, [includedColumns])

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
    }

    useEffect(() => {
        if (isMobileDevice) {
            getCompanyLanguages();
        }
    }, [])

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={languages}
                loadFn={getCompanyLanguages}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                noPagination={true}
                smallInMobile={true}
                disableFullView={true}
            />
        </TabTableDashboardLayout>
    );
};

/** LanguagesComponent propTypes
 * PropTypes
*/
LanguagesComponent.propTypes = {
    /** Redux action to get company languages */
    getCompanyLanguages: PropTypes.func,
    /** Redux state property, represents the array of company languages  */
    languages: PropTypes.arrayOf(languageType),
    /** Redux state property, is true when loading company languages */
    isLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    getCompanyLanguages: () => {
        dispatch(getCompanyLanguages());
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.companies.isLoading,
        languages: state.companies.edit.languages.languages,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguagesComponent);
