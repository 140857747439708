import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Carousel, Col, Row } from 'antd';

import FinancialLineChart from 'pages/dashboard/components/financialLineChart';
import TabTableDashboardLayout from 'components/layouts/tab/table'
import Filters from '../../filters';

import useFirstRender from 'hooks/useFirstRender';
import useProjectType from "hooks/useProjectType";

import { isMobile } from 'utils/common';

import { COMMISSION_LINE_CHARTS_DATA, COMMISSION_LINE_CHARTS_DATA_LITE_MODE } from 'pages/dashboard/constants';

const Commission = ({
    userCurrencies,
    updateLastUpdatedDate,
    defaultFilters,
}) => {
    const { t } = useTranslation();

    const { hasLiteMode } = useProjectType();

    const [filters, setFilters] = useState(defaultFilters)
    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);

    const handleCarouselChange = (prevIndex, nextIndex) => {
        setCarouselActiveIndex(nextIndex);
    }

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(defaultFilters)
        },
        dependencies: [defaultFilters, updateFilters]
    })

    let chartsData = COMMISSION_LINE_CHARTS_DATA;

    if (hasLiteMode) {
        chartsData = COMMISSION_LINE_CHARTS_DATA_LITE_MODE;
    }

    return (
        !isMobile()
            ? (
                <TabTableDashboardLayout
                    header={{
                        content: (
                            <div className="rt--flex rt--align-center rt--justify-end rt--width-full">
                                <Filters userCurrencies={userCurrencies} filters={filters} updateFilters={updateFilters} />
                            </div>
                        )
                    }}

                    headerBorder={false}
                >
                    <div className='rt--financial-line-chart-commission'>
                        <Row gutter={[16, 24]}>
                            {chartsData.map((data) => (
                                <Col {...data.colSizes} key={data.id}>
                                    <FinancialLineChart
                                        title={t(data.title)}
                                        dataType={data.dataType}
                                        playerLineColor={data.playerLineColor}
                                        betshopLineColor={data.betshopLineColor}
                                        disableLegend={data.disableLegend}
                                        filters={filters}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>

                </TabTableDashboardLayout>
            ) : (
                <TabTableDashboardLayout
                    header={{
                        filters: <Filters userCurrencies={userCurrencies} filters={filters} updateFilters={updateFilters} />
                    }}
                >
                    <div className='rt--financial-line-chart rt--financial-line-chart-commission'>
                        <Carousel infinite={false} beforeChange={handleCarouselChange}>
                            {chartsData.map((data, index) => (
                                <FinancialLineChart
                                    key={data.id}
                                    title={t(data.title)}
                                    dataType={data.dataType}
                                    playerLineColor={data.playerLineColor}
                                    betshopLineColor={data.betshopLineColor}
                                    disableLegend={data.disableLegend}
                                    filters={filters}
                                    updateLastUpdatedDate={updateLastUpdatedDate}
                                    isActive={carouselActiveIndex === index}
                                />
                            ))}
                        </Carousel>
                    </div>
                </TabTableDashboardLayout>
            )
    )
}

Commission.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
    userCurrencies: PropTypes.array,
    defaultFilters: PropTypes.object,
};

export default Commission;
