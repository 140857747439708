//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getUserCompanyAccess,
    deleteUserCompanyAccess
} from "store/actions/dashboard/userManagement/users/companyAccess.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Notes from 'components/common/notes';
import PendingRequestsNote from 'components/common/pendingRequestsNote';
import CompanyAddComponent from "./company-add.component";
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { hasValue } from 'utils/common';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';
import { USER_ROLE } from 'constants/user.constants';
import { tableColumns } from './columns';
//#endregion

//#region types
import userCompanyAccessType from 'types/user/companyAccess.type';
//#endregion

/** User Edit Page Companies Tab Component */
const CompaniesComponent = ({
    companyAccess,
    getUserCompanyAccess,
    deleteUserCompanyAccess,
    isLoading,
    userId
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const userRole = getUser()?.role;

    /** Notes */
    const [notes, setNotes] = useState(null);

    // State to show/hide company add/edit popup
    const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);

    const mainCompanyLoaded = Boolean(companyAccess.mainCompany) && hasValue(companyAccess.mainCompany);


    const tableData = (
        mainCompanyLoaded
            ? [companyAccess.mainCompany, ...companyAccess.companies]
            : companyAccess.companies
    )

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS,
        action: PERMISSION_ACTION.CREATE
    })

    const hasDeletePermission = hasPermission({
        resource: PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS,
        action: PERMISSION_ACTION.DELETE
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddPopup = () => {
        setIsAddPopupVisible(true);
    }

    const hideAddPopup = () => {
        setIsAddPopupVisible(false);
    }

    const handleDelete = (id, companyId) => {
        if (userRole === USER_ROLE.ACCESS_MANAGER) {
            return setNotes({ id, companyId });
        }

        deleteUserCompanyAccess(id, companyId);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ mainColumns: tableColumns });
    }, [])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission && searchParams.id !== userId) {
        tableRowActions.push({
            icon: "icon-trash",
            title: t('backoffice.common.delete'),
            confirmationTitle: t("backoffice.users.deleteCompany"),
            confirmationMessage: t("backoffice.users.doYouWanttoDeleteCompany"),
            showConfirmation: true,
            onClick: record => handleDelete(searchParams.id, record.id),
            disabled: record => !record.isDeletable,
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        button: {
            icon: "icon-plus",
            type: "primary",
            text: t("backoffice.users.addCompany"),
            onClick: showAddPopup,
            enabled: hasCreatePermission && searchParams.id !== userId,
            disabled: companyAccess.companies.some(p => p.id === "*"),
        },
    }

    //#endregion

    // Close user add/edit popup, after save
    useEffect(() => {
        hideAddPopup()
    }, [companyAccess])

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={(
                <PendingRequestsNote
                    objectId={searchParams.id}
                    actionTypes={[PERMISSION_REQUEST_TYPE.ADD_COMPANY_ACCESS, PERMISSION_REQUEST_TYPE.DELETE_COMPANY_ACCESS]}
                    objectType={PERMISSION_REQUEST_OBJECT_TYPE.USER}
                    dependencies={[companyAccess]}
                />
            )}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={tableData}
                loadFn={() => getUserCompanyAccess(searchParams.id)}
                noPagination={true}
                disableFullView={true}
                smallInMobile={true}
                mobileLayoutVertical={true}
                actions={tableRowActions}
            />

            {isAddPopupVisible && <CompanyAddComponent onClose={hideAddPopup} />}
            {
                notes && (
                    <Notes
                        onClose={() => setNotes(null)}
                        onSuccess={value => {
                            deleteUserCompanyAccess(notes.id, notes.companyId, value);
                        }}
                    />
                )
            }
        </TabTableDashboardLayout>
    )
}

/** CompaniesComponent propTypes
    * PropTypes
*/
CompaniesComponent.propTypes = {
    /** Redux action to get user company access */
    getUserCompanyAccess: PropTypes.func,
    /** Redux action to delete user company access */
    deleteUserCompanyAccess: PropTypes.func,
    /** Redux state property, is true when user company access is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current user id */
    userId: PropTypes.string,
    /** Redux state, represents the users of current editing user company access  */
    companyAccess: userCompanyAccessType
}

const mapDispatchToProps = dispatch => (
    {
        getUserCompanyAccess: id => {
            dispatch(getUserCompanyAccess(id));
        },

        deleteUserCompanyAccess: (id, companyId, note) => {
            dispatch(deleteUserCompanyAccess(id, companyId, note));
        }
    }
)

const mapStateToProps = state => {
    return {
        companyAccess: state.users.edit.companyAccess,
        isLoading: state.users.isLoading,
        userId: state.profile.userInfo.id
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesComponent)