import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setBetshopManagersActionBefore, setBetshopManagersActionFinished, setBetshopManagersSaveActionBefore, setBetshopManagersSaveActionFinished } from './betshopManagers.action';

import { SET_BETSHOP_MANAGER_GENERAL_INFO } from "../../../../actionTypes";

const setBetshopManagerGeneralInfo = info => ({
    type: SET_BETSHOP_MANAGER_GENERAL_INFO,
    payload: { info }
})

export const getBetshopManagerGeneralInfo = id => {
    return dispatch => {
        dispatch(setBetshopManagersActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_MANAGER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setBetshopManagerGeneralInfo(info))
            dispatch(setBetshopManagersActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopManagersActionFinished());
        })
    }
}

export const saveBetshopManagerGeneralInfo = betshopManager => {
    return dispatch => {
        dispatch(setBetshopManagersSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_BETSHOP_MANAGER_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...betshopManager
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBetshopManagerGeneralInfo(data.value));
            } 
            dispatch(setBetshopManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopManagersSaveActionFinished());
        })
    }
}
