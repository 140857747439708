//#region react
import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
//#endregion

//#region constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
//#endregion

//#region components
import Tabs from "components/common/tabs";
import SportBets from "./sportBets/sportBets.component";
import CasinoBets from "./casinoBets/casinoBets.component";
//#endregion

//#region utils
import { Version } from "utils/version";
//#endregion

/** Real Time Sport Bets Page Component */
const RealTimeSportBets = ({platVersion}) => {
    const { t } = useTranslation();

    const currentVersion = new Version(platVersion);
    const casinoBetsVersion = new Version('v1.2');

    const showCasinoBets = currentVersion.isGreaterThanOrEqualTo(casinoBetsVersion);

    const items = [
        {
            title: t("backoffice.bets.realTimeCasinoBets"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETS, action: PERMISSION_ACTION.VIEW }],
            component: <CasinoBets />
        },
        {
            title: t("backoffice.bets.realTimeSportBets"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETS, action: PERMISSION_ACTION.VIEW }],
            component: <SportBets isInTab />
        }
    ];

    if (showCasinoBets) {
        return (
            <Tabs
                items={items}
                mainPage={[
                    { title: t('backoffice.menu.betHistory') },
                    { title: t('backoffice.menu.realTimeBets') }
                ]}
            />
        )
    }

    return <SportBets />
}

RealTimeSportBets.propTypes = {
    platVersion: PropTypes.number
}

const mapStateToProps = (state) => ({
    platVersion: state.common.platVersion
})

export default connect(mapStateToProps)(RealTimeSportBets);
