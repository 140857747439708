import React from "react";
import i18n from "translations/config";

import Status from "components/common/status";

import {
    STATUS_SIZE,
    STATUS_TOOLTIP_PLACEMENT,
    STATUS_TYPES,
} from "constants/status.constants";

export const tableColumns = [
    {
        title: "backoffice.cashiers.firstName",
        dataIndex: "firstName",
        mobileLevel: 2,
        sorter: true,
        render: (firstName, record) => (
            <Status
                status={record.isOnline ? "online" : "offline"}
                highlighted={false}
                showIndication={true}
                content={firstName}
                size={STATUS_SIZE.SMALL}
                type={STATUS_TYPES.ONLINE_OFFLINE}
                tooltipOptions={{
                    placement: STATUS_TOOLTIP_PLACEMENT.INDICATION,
                    title: (
                        record.isOnline
                            ? i18n.t("backoffice.common.online")
                            : i18n.t("backoffice.common.offline")
                    )
                }}
            />
        ),
    },
    {
        title: "backoffice.cashiers.lastName",
        dataIndex: "lastName",
        sorter: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.cashiers.username",
        dataIndex: "userName",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.cashiers.betshop",
        dataIndex: "betShops",
        sorter: true,
        mobileLevel: 5,
        disableExport: true,
        multi: true,
        multiMapper: (value) => (value ? Object.values(value) : []),
    },
    {
        title: "backoffice.cashiers.status",
        dataIndex: "state",
        sorter: true,
        render: (userState) => (
            <Status
                status={userState}
                type={STATUS_TYPES.USER}
            />
        ),
    },
    {
        title: "backoffice.cashiers.id",
        dataIndex: "longId",
        sorter: true,
        copy: true,
        alwaysVisible: true,
    },
    {
        title: "backoffice.cashiers.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
    },
    {
        title: "backoffice.cashiers.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 4,
    },
    {
        title: "backoffice.cashiers.lastUpdateBy",
        dataIndex: "modifiedBy",
        sorter: true,
    },
    {
        title: "backoffice.cashiers.lastLoginAt",
        dataIndex: "lastLogin",
        sorter: true,
        isDateTime: true,
    },
];
