//#region react
import React, { memo, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
//#endregion

//#region lib
import { Spin } from "antd";
//#endregion

//#region chart
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
//#endregion

//#region hooks
import useOperationalPieChart from "pages/dashboard/hooks/useOperationalPieChart";
//#endregion

//#region utils
import {isFranchisingMode, isMobile} from 'utils/common'
import { getOperationsPieChartOptions } from "utils/dashboard"
//#endregion

//#region constants
import { ENTITY_TYPE } from "pages/dashboard/constants";
//#endregion

const PieChart = ({
    entityType,
    filters,
    updateLastUpdatedDate,
    isActive,
}) => {
    const { t } = useTranslation();

    const [inactiveStates, setInactiveStates] = useState([]);

    const memoFilters = useMemo(() => ({ ...filters }), [filters.filterType]);

    const { chartData, isLoading } = useOperationalPieChart(entityType, memoFilters, updateLastUpdatedDate, isActive)

    const chartOptions = useMemo(() => {
        return getOperationsPieChartOptions(
            chartData,
            entityType,
            inactiveStates,
            (name, isVisible) => {
                if (!isVisible) {
                    setInactiveStates(prevState => prevState.filter(s => s !== name))
                } else {
                    setInactiveStates(prevState => prevState.concat([name]))
                }
            }
        );
    }, [entityType, inactiveStates, chartData]);

    const getTitle = (entityType) => {
        const titles = {
            [ENTITY_TYPE.AGENT]: isFranchisingMode() ? t("backoffice.dashboard.betShopOwnerCount") : t("backoffice.dashboard.agentsCount"),
            [ENTITY_TYPE.PLAYER]: t("backoffice.dashboard.playersCount"),
            [ENTITY_TYPE.BETSHOP]: t("backoffice.dashboard.betshopsCount"),
            [ENTITY_TYPE.CASHIER]: t("backoffice.dashboard.cashiersCount"),
        }

        return titles[entityType] || '';
    }

    return (
        <div className={
            entityType === ENTITY_TYPE.CASHIER
                ?'rt--chart-operational-pie rt--chart-section rt--chart-operational-pie-cashier'
                : entityType === ENTITY_TYPE.PLAYER
                    ? 'rt--chart-operational-pie rt--chart-section rt--chart-operational-pie-player'
                    : entityType === ENTITY_TYPE.BETSHOP
                        ? 'rt--chart-operational-pie rt--chart-section rt--chart-operational-pie-betshop'
                        : 'rt--chart-operational-pie rt--chart-section'
        }>
            <div className={"rt--flex rt--flex-col " + (isMobile() ? ' rt--pl-16 rt--pt-16' : ' rt--pl-24 rt--pt-24')}>
                <span className={"rt--chart-section-title rt--font-extra-bold " + (isMobile() ? ' rt--font-big rt--mb-4' : ' rt--font-bigest rt--mb-8')}>

                    {
                        isMobile() ? '' : getTitle(entityType)
                    }
                </span>
            </div>
            <div className={"rt--chart-operational-pie-block" + (isMobile() ? ' rt--flex rt--flex-col' : '')}>
                {
                    isMobile() ? <div className="rt--mb-16">
                        <span className="rt--chart-section-title rt--font-extra-bold rt--font-prebigest"> {
                            getTitle(entityType)
                        }</span>
                    </div> : ''
                }

                {
                    isLoading
                        ? (
                            <Spin spinning={isLoading} />
                        ) : (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                            />
                        )
                }
            </div>
        </div>
    )
};

PieChart.propTypes = {
    entityType: PropTypes.oneOf(Object.values(ENTITY_TYPE)),
    filters: PropTypes.object,
    updateLastUpdatedDate: PropTypes.func,
    isActive: PropTypes.bool,
};

export default memo(PieChart)

