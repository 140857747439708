import React, { useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Menu, Button } from "antd";

import Dropdown from 'components/common/dropdown';
import Confirmation from "components/common/confirmation";

import { isMobile } from "utils/common";

/** Entity Actions Component */
const EntityActions = ({
    actions
}) => {

    const { t } = useTranslation();

    const _dropdownRef = useRef(null);

    /** Flag to show/hide delete confirmation popup */
    const [confirmationPopup, setConfirmationPopup] = useState(null);

    /**Available Actions */
    const availableActions = actions.filter(action => !action.disabled)

    return availableActions.length > 0 ? (
        <Fragment>
            {
                (isMobile() || availableActions.length > 1) ? (
                    <Dropdown
                        ref={_dropdownRef}
                        title={t("backoffice.common.actions")}
                        menu={{
                            selectedKeys: [],
                            className: isMobile() ? "rt--table-actions-menu" : "rt--actions-menu",
                            onClick: e => {
                                const action = availableActions.find(ac => ac.key === e.key);
                                if (action) {
                                    if (action.isConfirmation) {
                                        setConfirmationPopup(action)
                                    } else {
                                        action.onClick && action.onClick()
                                    }
                                }

                                _dropdownRef.current && _dropdownRef.current.close()
                            },
                            items: (
                                availableActions.map(action => (
                                    {
                                        key: action.key,
                                        label: (
                                            <div className="rt--flex rt--align-center">
                                                {
                                                    isMobile() &&
                                                    <i className={`${action.icon} rt--font-bigest rt--mr-8`}/>
                                                }

                                                <span
                                                    className="rt--title rt--font-regular rt--font-normal">{action.title}</span>
                                            </div>
                                        )
                                    }
                                ))
                            )
                        }}
                        disabled={availableActions.length === 0}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    >

                        <div
                            className='rt--actions-button rt--flex rt--align-center rt--justify-center'
                        >
                            <i className={`${isMobile() ? "icon-ellipsis" : "icon-more"} rt--font-bigest`} />
                        </div>

                    </Dropdown>
                ) : (
                    <div className='rt--flex rt--align-center rt--justify-end'>
                        {
                            availableActions.map(action => (
                                <Button
                                    key={action.key}
                                    type={action.buttonType}
                                    htmlType="button"
                                    className={"rt--button rt--ml-16 " + (action.buttonClassName || "")}
                                    onClick={() => {
                                        if (action.isConfirmation) {
                                            setConfirmationPopup(action)
                                        } else {
                                            action.onClick && action.onClick()
                                        }
                                    }}
                                >
                                    <span>{action.title}</span>
                                </Button>
                            ))
                        }
                    </div>
                )
            }

            {
                confirmationPopup && (
                    <Confirmation
                        title={confirmationPopup.title}
                        message={confirmationPopup.confirmationMessage}
                        onOk={() => {
                            confirmationPopup.onClick && confirmationPopup.onClick();
                            setConfirmationPopup(null);
                        }}
                        onCancel={() => setConfirmationPopup(null)}
                        isVisible={true}
                    />
                )
            }

        </Fragment>
    ) : <Fragment />
}

/** EntityActions propTypes
    * PropTypes
*/
EntityActions.propTypes = {
    /** Available actions  */
    actions: PropTypes.arrayOf(PropTypes.shape({
        /** Unique key */
        key: PropTypes.string,
        /** Button Type */
        buttonType: PropTypes.oneOf(["primary", "secondary", "danger"]),
        /** Additional class name for the button */
        buttonClassName: PropTypes.string,
        /** Title */
        title: PropTypes.string,
        /** Icon */
        icon: PropTypes.string,
        /** Function to fire on click */
        onClick: PropTypes.func,
        /** Is the action disabled */
        disabled: PropTypes.bool,
        /** Should show confirmation popup on click */
        isConfirmation: PropTypes.bool,
        /** Confirmation popup ,essage */
        confirmationMessage: PropTypes.string
    }))
}

export default EntityActions
