import { accountService } from "NEW/src/core/services/apiServices/accountService";
import { companyService } from "NEW/src/core/services/apiServices/companyService";
import { notificationService } from "NEW/src/core/services/apiServices/notificationService";
import { projectService } from "NEW/src/core/services/apiServices/projectService";
import { systemLanguageService } from "NEW/src/core/services/apiServices/systemLanguageService";

export const apiServices = {
	accountService,
	projectService,
	systemLanguageService,
	companyService,
	notificationService,
};
