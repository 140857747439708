import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Row } from 'antd';

import TabTableDashboardLayout from 'components/layouts/tab/table';
import OperationalLineChart from 'pages/dashboard/components/operationalLineChart';
import Filters from '../../filters';

import useFirstRender from 'hooks/useFirstRender';

import { ENTITY_TYPE, OPERATIONAL_DASHBOARD_FILTER_NAMES, TIME_PERIOD } from 'pages/dashboard/constants';
import { FILTER_TYPE } from 'constants/common.constants';

const getDefaultFilters = (hasRetail) =>  ({
    [OPERATIONAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD]: TIME_PERIOD.TODAY,
    [OPERATIONAL_DASHBOARD_FILTER_NAMES.ENTITY_TYPE]: hasRetail ? ENTITY_TYPE.BETSHOP : ENTITY_TYPE.PLAYER,
    [OPERATIONAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE]: FILTER_TYPE.NETWORK,
})

const selectGlobalProjectId = state => state.common.globalProjectId;

const Registrations = ({ hasRetail, updateLastUpdatedDate }) => {
    const [filters, setFilters] = useState(getDefaultFilters(hasRetail))

    const globalProjectId = useSelector(selectGlobalProjectId);

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters(hasRetail))
        },
        dependencies: [globalProjectId, updateFilters]
    })

    return (
        <TabTableDashboardLayout
            header={{
                filters: <Filters hasRetail={hasRetail} filters={filters} updateFilters={updateFilters} />
            }}
        >
            <div className='rt--operational-line-chart-registration'>
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        <OperationalLineChart
                            filters={filters}
                            hasRetail={hasRetail}
                            updateLastUpdatedDate={updateLastUpdatedDate}
                        />
                    </Col>
                </Row>
            </div>
        </TabTableDashboardLayout>
    )
}

Registrations.propTypes = {
    hasRetail: PropTypes.bool,
    updateLastUpdatedDate: PropTypes.func,
}

export default Registrations;