import PropTypes from 'prop-types';

export default PropTypes.objectOf(PropTypes.shape({
    ftdCount: PropTypes.number,
    depositCount: PropTypes.number,
    withdrawCount: PropTypes.number,
    depositAmount: PropTypes.number,
    withdrawAmount: PropTypes.number,
    netDepositAmount: PropTypes.number,
    turnover: PropTypes.number,
    won: PropTypes.number,
    ggr: PropTypes.number,
    ngr: PropTypes.number,
    otherCosts: PropTypes.number
}))