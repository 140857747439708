import PropTypes from 'prop-types';

export default PropTypes.objectOf(PropTypes.shape({
    betAmount: PropTypes.number,
    winAmount: PropTypes.number,
    ggr: PropTypes.number,
    ngr: PropTypes.number,
    bonusAmount: PropTypes.number,
    bonusWinAmount: PropTypes.number,
    bonusGGR: PropTypes.number,
    won: PropTypes.number,
    possibleWin: PropTypes.number,
    currencyCode: PropTypes.string,
    amount: PropTypes.number
}))