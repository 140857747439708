import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from "react-router-dom";

import {
    getAgentAvailableBanks,
    addAgentBank,
} from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action';

import { Col, Form, Row } from "antd";
import Modal from "components/common/modal";
import Select from "components/common/select";

import { POPUP_SIZE } from 'constants/common.constants';
import autoSuggestionType from 'types/autoSuggestion/autoSuggestion.type';

const BankAddComponent = ({
    onClose,
    getAgentAvailableBanks,
    availableBanks,
    isLoading,
    isSaving,
    addAgentBank,
}) => {
    const { t } = useTranslation();
    const { search, pathname, hash } = useLocation();
    const searchParams = useParams();
    const navigate = useNavigate();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, isFieldsTouched } = formInstance;

    /** Load available banks */
    useEffect(() => {
        getAgentAvailableBanks(searchParams.id)
    }, [])

    const handleForm = () => {
        validateFields()
            .then((fieldValuesObj) => {
                const requestBody = {
                    id: fieldValuesObj.bankName,
                    agentId: searchParams.id
                }
                const bankInfo = availableBanks.find(bankInfo => bankInfo.id === fieldValuesObj.bankName);

                const onSuccess = (bankId) => {
                    navigate(`${pathname}${search}&bankId=${bankId}&bankName=${bankInfo.name}${hash}`);
                }

                addAgentBank(requestBody, onSuccess);
            })
            .catch((ex) => {
                console.log(ex);
            });
    }

    return (
        <Modal
            title={t('backoffice.payments.addBank')}
            okText={t('backoffice.common.add')}
            cancelText={t('backoffice.common.cancel')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
            disableOkButton={!isFormTouched}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    bankName: null
                }}
                onValuesChange={() => {
                    setIsFormTouched(isFieldsTouched());
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                                label={`${t('backoffice.payments.bankName')} *`}
                                name="bankName"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                                className={isSaving ? "rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={availableBanks.map(bankInfo => {
                                    return {
                                        value: bankInfo.id,
                                        text: bankInfo.name,
                                    }
                                })}
                                loading={isLoading}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                placeholder={t('backoffice.payments.bankName')}
                                search={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

/** BankAddComponent propTypes
    * PropTypes
*/
BankAddComponent.propTypes = {
    /** Redux state property, is true when agent banks is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when adding bank */
    isSaving: PropTypes.bool,
    /** Redux action to get agent available banks */
    getAgentAvailableBanks: PropTypes.func,
    /** Redux action to add agent bank */
    addAgentBank: PropTypes.func,
    /** Redux state property, Agent banks info */
    availableBanks: PropTypes.arrayOf(autoSuggestionType),
    /** Fires on popup close */
    onClose: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getAgentAvailableBanks: (id) => {
            dispatch(getAgentAvailableBanks(id))
        },

        addAgentBank: (data, onSuccess) => {
            dispatch(addAgentBank(data, onSuccess))
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.agents.isSaving,
        isLoading: state.agents.isLoading,
        availableBanks: state.agents.edit.payment.banks.availableBanks
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAddComponent);