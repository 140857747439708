import React from 'react';

import BankDetailsComponent from '../details';

import { REGISTRATION_FORM_TYPE, WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

/** Bank Withdrawal Details component */
const WithdrawalConfigComponent = () => {

    return (
        <BankDetailsComponent 
            type={REGISTRATION_FORM_TYPE.PLAYER_WITHDRAWAL}
            widgetTransactionType={WIDGET_TRANSACTION_TYPE.WITHDRAWAL}
        />
    );
}
 
export default WithdrawalConfigComponent;