import PropTypes from 'prop-types';

const providerReportType = PropTypes.shape({
    providerId: PropTypes.string,
    providerName: PropTypes.string,
    betAmount: PropTypes.number,
    winAmount: PropTypes.number,
    ggr: PropTypes.number,
    commissionPercent: PropTypes.number,
    earning: PropTypes.number,
    toPay: PropTypes.number
})

export default PropTypes.shape({
    sportReport: providerReportType,
    casinoReport: PropTypes.shape({
        totalReport: providerReportType,
        providersReport: PropTypes.arrayOf(providerReportType)
    }),
    total: providerReportType
})