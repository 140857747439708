import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setCompaniesActionBefore, setCompaniesActionFinished } from './companies.action';

import { 
    SET_COMPANY_LANGUAGES
} from "../../../actionTypes";

const setCompanyLanguages = languages => ({
    type: SET_COMPANY_LANGUAGES,
    payload: { languages }
})

export const getCompanyLanguages = () => {
    return dispatch => {
        dispatch(setCompaniesActionBefore());
        return axios({
            url: ApiUrls.GET_COMPANY_LANGUAGES,
            method: Methods.GET
        })
        .then(({data : {value: languages}}) => {
            dispatch(setCompanyLanguages(languages))
            dispatch(setCompaniesActionFinished());
        })
        .catch((ex) => {
            dispatch(setCompaniesActionFinished());
        })
    }
}

