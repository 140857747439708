import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { switchBackAccount } from "store/actions/auth/auth.action";

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import { isMobile } from 'utils/common';
import { getUser } from 'utils/auth';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

/** LoggedinAsAgent component */

const LoggedinAsAgent = ({
    switchBackAccount
}) => {
    const { t } = useTranslation();

    const switchBackAccountWithConfirmation = useUnsavedFormConfirmation({
        cb: switchBackAccount,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    return getUser()?.hasPrevious ? (
        <div className={'rt--header-backToAdmin rt--flex rt--align-center rt--pl-12 rt--pr-12' + (isMobile() ? " rt--justify-between" : "")}>
            <div className='rt--flex rt--flex-col'>
                <span className='rt--header-backToAdmin-title rt--title rt--font-bold rt--font-smallest'>{t("backoffice.common.youAreloggedinAsAgent")}</span>
                <span className='rt--title rt--font-bold rt--font-normal'>{getUser()?.userName}</span>
            </div>
            {
                isMobile() ?
                    <div
                        className='rt--flex rt--align-center rt--cursor-pointer rt--pl-12'
                        onClick={() => switchBackAccountWithConfirmation()}
                    >
                        <b className='rt--title rt--font-bold rt--font-normal rt--pr-2'>{t("backoffice.common.backToAdmin")}</b>
                        <i className='icon-arrow-up-right rt--font-prebigest' />
                    </div>
                    :

                    <Button
                        className='rt--header-backToAdmin-button rt--button rt--button-primary rt--flex rt--align-center rt--cursor-pointer rt--ml-24'
                        onClick={() => switchBackAccountWithConfirmation()}
                    >
                        <b className='rt--title rt--font-bold rt--font-normal rt--pr-2'>{t("backoffice.common.backToAdmin")}</b>
                    </Button>
            }
        </div>
    ) : <Fragment />

}

LoggedinAsAgent.propTypes = {
    /** Redux action to switch back account */
    switchBackAccount: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        switchBackAccount: () => {
            dispatch(switchBackAccount())
        }
    }
)

export default connect(
    null,
    mapDispatchToProps
)(
    LoggedinAsAgent
);
