//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    deleteAccountProjectAccess
} from "store/actions/dashboard/account/projects.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Notes from 'components/common/notes';
import PendingRequestsNote from 'components/common/pendingRequestsNote';

//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { USER_ROLE } from 'constants/user.constants';
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import accountProjectAccessType from 'types/account/projectAccess.type';
//#endregion

const getSelectedCompany = ({ projectAccess, selectedCompanyId }) => {
    const selected = projectAccess.companies.find(p => p.id === selectedCompanyId);

    return {
        projects: selected?.projects || [],
        availableProjects: selected?.availableProjects || []
    }
}

/** User Edit Page Project Settings Tab Table Component */
const ProjectTableComponent = ({
    deleteAccountProjectAccess,
    isLoading,
    projectAccess,
    onAddButtonClick,
    userId
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const userRole = getUser()?.role;

    const [selectedCompanyId, setSelectedCompanyId] = useState(null);

    const selectedCompany = getSelectedCompany({ projectAccess, selectedCompanyId });

    /** Notes */
    const [notes, setNotes] = useState(null);

    const isAllProjectsAvailable = selectedCompany.availableProjects.some(p => p.id === "*") || userRole === USER_ROLE.ACCESS_MANAGER;
    // Field that gives access to all projects
    const isAllProjectsSelected = selectedCompany.projects.some(e => e.name === "*");

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasAdminProjectCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS,
        action: PERMISSION_ACTION.CREATE
    })

    const hasAdminProjectDeletePermission = hasPermission({
        resource: PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS,
        action: PERMISSION_ACTION.DELETE
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleDelete = projectId => {
        if (userRole === USER_ROLE.ACCESS_MANAGER) {
            return setNotes({ id: projectId })
        }

        deleteAccountProjectAccess(
            searchParams.id,
            selectedCompanyId,
            projectId
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                isAllProjectsSelected
            }
        });
    }, [isAllProjectsSelected])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (
        hasAdminProjectDeletePermission &&
        (searchParams.id !== userId) &&
        (isAllProjectsAvailable || !isAllProjectsSelected)
    ) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            confirmationTitle: t("backoffice.users.deleteProject"),
            confirmationMessage: t("backoffice.users.doYouWanttoDeleteProject"),
            icon: "icon-trash",
            showConfirmation: true,
            onClick: record => handleDelete(record.id),
            disabled: record => !record.isDeletable,
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        button: {
            text: t("backoffice.users.addNewProject"),
            icon: "icon-plus",
            type: "primary",
            onClick: () => onAddButtonClick(selectedCompanyId),
            enabled: (
                hasAdminProjectCreatePermission &&
                (searchParams.id !== userId) &&
                (!isAllProjectsSelected && selectedCompany.availableProjects.length > 0)
            ),
        },
    }

    const headerDropdownData = {
        placeholder: `${t('backoffice.common.select')} ${t('backoffice.users.company')}`,
        value: selectedCompanyId,
        onChange: value => setSelectedCompanyId(value),
        items: projectAccess.companies.map(r => ({
            key: r.id,
            value: r.name
        }))
    }

    //#endregion

    useEffect(() => {
        if (!selectedCompanyId) {
            const companies = projectAccess.companies;

            if (companies[0]) {
                setSelectedCompanyId(companies[0].id)
            }
        }

    }, [projectAccess]);

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            dropdown={headerDropdownData}
            extraContent={ (
                <PendingRequestsNote
                    objectId={searchParams.id}
                    actionTypes={[PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS, PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS]}
                    objectType={PERMISSION_REQUEST_OBJECT_TYPE.USER}
                    dependencies={[projectAccess]}
                />
            )}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={selectedCompany.projects}
                total={selectedCompany.projects.length}
                actions={tableRowActions}
                noPagination={true}
                disableFullView={true}
                smallInMobile={true}
                mobileLayoutVertical={true}
            />
            {
                notes && (
                    <Notes
                        onClose={() => setNotes(null)}
                        onSuccess={value => {
                            deleteAccountProjectAccess(
                                searchParams.id,
                                selectedCompanyId,
                                notes.id,
                                value
                            );
                        }}
                    />
                )
            }
        </TabTableDashboardLayout>
    )
}

/** ProjectTableComponent propTypes
    * PropTypes
*/
ProjectTableComponent.propTypes = {
    /** Redux action to delete project from user */
    deleteAccountProjectAccess: PropTypes.func,
    /** Redux state property, is true when loading user project access */
    isLoading: PropTypes.bool,
    /** Redux state property, represents the projects access info of user  */
    projectAccess: accountProjectAccessType,
    /** Redux state property, current user id */
    userId: PropTypes.string,
    /** Function, which will file on add button click */
    onAddButtonClick: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        deleteAccountProjectAccess: (id, companyId, projectId, note) => {
            dispatch(deleteAccountProjectAccess(id, companyId, projectId, note))
        }
    }
)

const mapStateToProps = state => {
    return {
        projectAccess: state.account.projectAccess,
        isLoading: state.account.isLoading,
        userId: state.profile.userInfo.id
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTableComponent)