import i18n from "translations/config";

import { binaryToFlags } from "utils/common";

import {
    COMMISSION_PLAN_SOURCE,
    COMMISSION_PLAN_SOURCE_DIRECTION,
    COMMISSION_PLAN_TYPE
} from "constants/commission.constants";
import { PROJECT_PROVIDER_TYPE } from "constants/project.constants";

const COMMISSION_PLAN_SOURCE_NAMES = {
    [COMMISSION_PLAN_SOURCE.NGR]: "ngr",
    [COMMISSION_PLAN_SOURCE.GGR]: "ggr",
    [COMMISSION_PLAN_SOURCE.TURNOVER]: "turnover",
    [COMMISSION_PLAN_SOURCE.DEPOSIT]: "deposit",
    [COMMISSION_PLAN_SOURCE.WITHDRAWAL]: "withdrawal",
}

const COMMISSION_PLAN_TYPE_NAMES = {
    [COMMISSION_PLAN_TYPE.DIRECT]: "direct",
    [COMMISSION_PLAN_TYPE.NETWORK]: "network",
}

export const getTableColumns = (additionalProps) => {
    const { gameProviders } = additionalProps;

    const mappedGameProviders = {};

    gameProviders.forEach(provider => {
        mappedGameProviders[provider.id] = provider.name;
    })

    return [
        {
            title: "backoffice.commissionplans.name",
            dataIndex: "name",
            mobileLevel: 1,
        },
        {
            title: "backoffice.commissionplans.incomeType",
            dataIndex: "type",
            mobileLevel: 2,
            disabledForFranchisingMode: true,
            render: type => (
                Boolean(COMMISSION_PLAN_TYPE_NAMES[type])
                    ? i18n.t(`backoffice.commissionplans.${COMMISSION_PLAN_TYPE_NAMES[type]}`)
                    : i18n.t("backoffice.commissionplans.mix")
            ),
        },
        {
            title: "backoffice.commissionplans.currency",
            dataIndex: "currencyCode",
            mobileLevel: 3,
        },
        {
            title: "backoffice.commissionplans.source",
            dataIndex: "source",
            mobileLevel: 4,
            render: source => (
                Boolean(COMMISSION_PLAN_SOURCE_NAMES[source])
                    ? i18n.t(`backoffice.commissionplans.${COMMISSION_PLAN_SOURCE_NAMES[source]}`)
                    : ""
            ),
        },
        {
            title: "backoffice.commissionplans.sourceDirection",
            dataIndex: "sourceDirections",
            multi: true,
            disabledForFranchisingMode: true,
            mobileLevel: 5,
            multiMapper: value => {
                if (!Boolean(value)) {
                    return [];
                }

                const sourceDirections = binaryToFlags(Object.values(COMMISSION_PLAN_SOURCE_DIRECTION), value);

                return sourceDirections.map(direction => (
                    direction === COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS
                        ? i18n.t("backoffice.commissionplans.players")
                        : i18n.t("backoffice.commissionplans.betshops")
                ))
            },
        },
        {
            title: "backoffice.commissionplans.onlineProviders",
            dataIndex: "onlineProviders",
            multi: true,
            disabledForFranchisingMode: true,
            mobileLevel: 6,
            multiMapper: onlineProviders => (
                Boolean(onlineProviders)
                    ? onlineProviders.map(provider => mappedGameProviders[provider])
                    : []
            )
        },
        {
            title: "backoffice.commissionplans.retailProviders",
            dataIndex: "retailProviders",
            multi: true,
            mobileLevel: 7,
            multiMapper: value => {
                if (!Boolean(value)) {
                    return [];
                }

                const projectProviderTypes = binaryToFlags(Object.values(PROJECT_PROVIDER_TYPE), value);

                return projectProviderTypes.map(provider => (
                    provider === PROJECT_PROVIDER_TYPE.SPORTBOOK
                        ? i18n.t("backoffice.common.Sportsbook")
                        : i18n.t("backoffice.common.DGVirtuals")
                ))
            }
        },
    ];
}