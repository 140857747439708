import { useRouteError } from "react-router";

/**
 * Need to catch all errors with our error boundary
 * https://github.com/remix-run/react-router/discussions/10166
 */
const BubbleError = () => {
	const error = useRouteError();

	if (error) {
		throw error;
	}

	return null;
};

export default BubbleError;
