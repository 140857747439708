import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { refreshToken, getUser } from 'utils/auth';

import { getBetshopGeneralInfo } from "./general.action";

import {
    SET_BETSHOPS_ACTION_BEFORE,
    SET_BETSHOPS_ACTION_FINISH,
    SET_BETSHOPS,
    SET_BETSHOPS_SORTING,
    SET_BETSHOPS_FILTERS,
    SET_BETSHOPS_SAVE_ACTION_BEFORE,
    SET_BETSHOPS_SAVE_ACTION_FINISH,
    SET_BETSHOP_AVAILABLE_CURRENCIES_BEFORE,
    SET_BETSHOP_AVAILABLE_CURRENCIES_FINISH,
    SET_BETSHOP_AVAILABLE_CURRENCIES,
    SET_BETSHOP_BALANCE
} from "../../../../actionTypes";

export const setBetshopsActionBefore = () => ({
    type: SET_BETSHOPS_ACTION_BEFORE,
});

export const setBetshopsActionFinished = () => ({
    type: SET_BETSHOPS_ACTION_FINISH,
});

export const setBetshopsSaveActionBefore = () => ({
    type: SET_BETSHOPS_SAVE_ACTION_BEFORE,
});

export const setBetshopsSaveActionFinished = () => ({
    type: SET_BETSHOPS_SAVE_ACTION_FINISH,
});

const setBetshops = (betshops, add) => ({
    type: SET_BETSHOPS,
    payload: { betshops, add },
});

export const setBetshopsSorting = sorting => ({
    type: SET_BETSHOPS_SORTING,
    payload: { sorting },
});

export const setBetshopsFilters = filters => ({
    type: SET_BETSHOPS_FILTERS,
    payload: { filters },
});


export const getBetshops = nextPage => {
    return (dispatch, getState) => {

        const page= nextPage === "first" ? 1 : nextPage === "next" ? getState().betshops.sorting.page + 1 : getState().betshops.sorting.page;
        let params = {
            ...getState().betshops.sorting,
            ...getState().betshops.filters,
            page: page
        };

        if(page > 1 && getState().betshops.total <= (page - 1 ) * getState().betshops.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().betshops.total === 0){
            return Promise.resolve();
        }

        dispatch(setBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOPS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: betshops } }) => {
                dispatch(setBetshops(betshops, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setBetshopsSorting({ ...getState().betshops.sorting, page: page }));
                dispatch(setBetshopsActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetshopsActionFinished());
            });
    }
}

export const createBetshop = (betshop, onSuccess) => {
    return (dispatch) => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_BETSHOP,
            method: Methods.POST,
            data: {
                ...betshop
            },
        })
            .then(({ data }) => {
                dispatch(setBetshopsSaveActionFinished());
                if (isResponseSuccess(data)) {

                    const token = getUser()?.refreshToken;

                    if (!getUser()?.hasPrevious) {
                        refreshToken(token)
                            .then(() => {
                                onSuccess && onSuccess(data.value);
                            });
                    } else {
                        onSuccess && onSuccess(data.value)
                    }
                }
            })
            .catch(() => {
                dispatch(setBetshopsSaveActionFinished());
            });
    };
};

export const changeBetshopWallet = (wallet, onSuccess) => {
    return (dispatch) => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_BETSHOP_WALLET,
            method: Methods.POST,
            data: {
                ...wallet
            },
        })
            .then(({ data }) => {
                dispatch(setBetshopsSaveActionFinished());
                if (isResponseSuccess(data)) {

                    dispatch(getBetshops());

                    onSuccess && onSuccess(data);
                }
            })
            .catch(() => {
                dispatch(setBetshopsSaveActionFinished());
            });
    };
}

const setBetshopAvailableCurrenciesBefore = () => ({
    type: SET_BETSHOP_AVAILABLE_CURRENCIES_BEFORE,
});

const setBetshopAvailableCurrenciesFinished = () => ({
    type: SET_BETSHOP_AVAILABLE_CURRENCIES_FINISH,
});

const setBetshopAvailableCurrencies = currencies => ({
    type: SET_BETSHOP_AVAILABLE_CURRENCIES,
    payload: { currencies }
})

export const getBetshopAvailableCurrencies = agentId => {
    return dispatch => {
        dispatch(setBetshopAvailableCurrenciesBefore());
        return axios({
            url: ApiUrls.GET_BETSHOP_AVAILABLE_CURRENCIES,
            method: Methods.GET,
            params: { agentId }
        })
        .then(({data : {value: currencies}}) => {
            dispatch(setBetshopAvailableCurrencies(currencies))
            dispatch(setBetshopAvailableCurrenciesFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopAvailableCurrenciesFinished());
        })
    }
}

export const changeBetshopState = d => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_BETSHOP_STATE,
            method: Methods.POST,
            data: d
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getBetshopGeneralInfo(d.id));
            }
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}

export const setBetshopBalance = wallets => ({
    type: SET_BETSHOP_BALANCE,
    payload: { wallets }
});


export const reparentBetshop = (id, parentId, onSuccess) => {
    return (dispatch) => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.REPARENT_BETSHOP,
            method: Methods.POST,
            data: {
                id,
                parentId
            },
        })
            .then(({ data }) => {
                dispatch(setBetshopsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setBetshopsSaveActionFinished());
            });
    };
};
