import {setLocale} from "NEW/src/core/helpers/language/setLocale";

export const initLocale = () => {
    const params = new URLSearchParams(window.location.search);
    const language = params.get("language");

    if (language) {
        setLocale(language);
    }
}
