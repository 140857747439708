import React, {useMemo} from 'react';
import Select from "components/common/select";
import {
    DATA_TYPES,
    LEADERBOARD_TYPES,
    TIME_PERIOD
} from "pages/dashboard/constants";
import useProjectType from "hooks/useProjectType";
import {useTranslation} from "react-i18next";
import {isFranchisingMode} from "utils/common";
import FiltersWrapper from "components/common/filters";
import {Form} from "antd";
import {useDashboardContext} from "pages/dashboard/contexts/dashboardContext";
import useAutosuggestion from "hooks/useAutosuggestion";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import isOperationalDataType from "pages/dashboard/helpers/isOperationalDataType";

const LeaderboardFilters = ({ filters, onChange, currencyOptions, dataTypeOptions }) => {
    const { state: { leaderboardTab } } = useDashboardContext();

    const { t } = useTranslation();

    const { hasLiteMode } = useProjectType();

    const [ betshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: isFranchisingMode()
    });

    const retailFilters = useMemo(() => {
        const {dataType: _, filterType: __, ...restFilters} = filters;

        return restFilters;
    }, [filters]);

    const betShopOptions = useMemo(() => ([
        {
            text: t('backoffice.dashboard.allBetshops'),
            value: ''
        },
        ...betshopNames.map(betshop => ({
            text: betshop.name,
            value: betshop.id
        }))
    ]), [betshopNames, t]);

    const filteredDataTypeOptions = useMemo(() => {
        return dataTypeOptions.filter(option => option.value !== DATA_TYPES.COMMISSIONS);
    }, [dataTypeOptions]);

    const showCurrency = !isOperationalDataType(filters.dataType);

    if (isFranchisingMode()) {
        return (
            <div className="rt--flex">
                <Select
                    value={filters.dataType}
                    onChange={value => onChange({ dataType: value })}
                    options={filteredDataTypeOptions}
                    className="rt--main-layout-header-actions-dropdown-big"
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                />

                <FiltersWrapper
                    loadFn={() => {}}
                    setFiltersFn={onChange}
                    updateProps={[]}
                    filters={retailFilters}
                >
                    {leaderboardTab === LEADERBOARD_TYPES.CASHIER && (
                        <Form.Item
                            name="betShopNameOrId"
                            label={t("backoffice.dashboard.betShop")}
                        >
                            <Select
                                placeholder={t('backoffice.dashboard.allBetshops')}
                                options={betShopOptions}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                search
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        label={t('backoffice.dashboard.date')}
                        name="timePeriod"
                    >
                        <Select
                            options={
                                [
                                    { value: TIME_PERIOD.TODAY, text: t("backoffice.dashboard.today") },
                                    { value: TIME_PERIOD.YESTERDAY, text: t("backoffice.dashboard.yesterday") },
                                    { value: TIME_PERIOD.THIS_WEEK, text: t("backoffice.dashboard.thisWeek") },
                                    { value: TIME_PERIOD.LAST_WEEK, text: t("backoffice.dashboard.lastWeek") },
                                    { value: TIME_PERIOD.THIS_MONTH, text: t("backoffice.dashboard.thisMonth") },
                                    { value: TIME_PERIOD.LAST_MONTH, text: t("backoffice.dashboard.lastMonth") },
                                    { value: TIME_PERIOD.LAST_QUARTER, text: t("backoffice.dashboard.lastQuarter") },
                                    { value: TIME_PERIOD.THIS_YEAR, text: t("backoffice.dashboard.ytd") },
                                ]
                            }
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>

                    {showCurrency && (
                        <Form.Item
                            name="currencyCode"
                            label={t("backoffice.dashboard.currency")}
                        >
                            <Select
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                options={[
                                    ...currencyOptions
                                ]}
                            />
                        </Form.Item>
                    )}
                </FiltersWrapper>
            </div>
        )
    }

    return (
        <>
            <Select
                value={filters.dataType}
                onChange={value => onChange({ dataType: value })}
                options={filteredDataTypeOptions}
                className="rt--main-layout-header-actions-dropdown-big rt--mr-16"
                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
            />

            {!hasLiteMode && showCurrency && (
                <Select
                    className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle rt--mr-16"
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    value={filters.currencyCode}
                    options={[
                        ...currencyOptions
                    ]}
                    onChange={(value) => onChange({currencyCode: value})}
                />
            )}

            <Select
                options={
                    [
                        { value: TIME_PERIOD.TODAY, text: t("backoffice.dashboard.today") },
                        { value: TIME_PERIOD.YESTERDAY, text: t("backoffice.dashboard.yesterday") },
                        { value: TIME_PERIOD.THIS_WEEK, text: t("backoffice.dashboard.thisWeek") },
                        { value: TIME_PERIOD.LAST_WEEK, text: t("backoffice.dashboard.lastWeek") },
                        { value: TIME_PERIOD.THIS_MONTH, text: t("backoffice.dashboard.thisMonth") },
                        { value: TIME_PERIOD.LAST_MONTH, text: t("backoffice.dashboard.lastMonth") },
                        { value: TIME_PERIOD.LAST_QUARTER, text: t("backoffice.dashboard.lastQuarter") },
                        { value: TIME_PERIOD.THIS_YEAR, text: t("backoffice.dashboard.ytd") },
                    ]
                }
                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle"
                onChange={value => onChange({ timePeriod: value })}
                value={filters.timePeriod}
            />
        </>
    );
};

export default LeaderboardFilters;
