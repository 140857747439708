import axiosInstance from "NEW/src/core/lib/axios";

import {getURL, getURLMergedWithQueryData} from "./helpers";

class HttpService {
	#request({ url, method, queryData, requestBody, signal, onProgress, configs = {} }) {
		return axiosInstance({
			url,
			method,
			signal,
			params: queryData,
			data: requestBody,
			onDownloadProgress: onProgress,
			...configs,
		});
	}

	get({ path, queryData, signal, onProgress, configs }) {
		const url = getURL(path);
		const mergedURL = getURLMergedWithQueryData(url, queryData);

		return this.#request({
			method: "get",
			url: mergedURL,
			signal,
			onProgress,
			configs,
		});
	}

	post({ path, requestBody, signal, onProgress, configs }) {
		const url = getURL(path);

		return this.#request({
			method: "post",
			url,
			requestBody,
			signal,
			onProgress,
			configs,
		});
	}

	delete({ path, requestBody, signal, onProgress, configs }) {
		const url = getURL(path);

		return this.#request({
			method: "delete",
			url,
			requestBody,
			signal,
			onProgress,
			configs,
		});
	}
}

export const httpService = new HttpService();
