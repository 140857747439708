import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Modal from "components/common/modal";

import TransferForm from '../form';
import TransferResult from '../result';
import TransferEntityType from '../entityType';

import useTransfer from "../../hooks/useTransfer";

import useProjectType from 'hooks/useProjectType';

import { 
    TRANSFER_ENTITY_TYPE, 
    TRANSFER_OPERATION_GROUP, 
    TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME, 
    TRANSFER_POPUP_OK_ACTION_EVENT_NAME, 
    TRANSFER_POPUP_STEP 
} from '../../constants';
import { POPUP_SIZE } from 'constants/common.constants';
import { RESPONSE_CODES } from 'constants/errorCode.constants';
import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';

import { isFranchisingMode, triggerCustomEvent } from 'utils/common';

import { getEntityTypesByGroupAndTransactionType } from '../../helpers';

import currencyType from 'types/profile/currency.type';

/** Transfer Popup Component */
const Transfer = ({
    currencies
}) => {
    const { t } = useTranslation();

    const { hasRetail, hasEpos, hasStandartPayment } = useProjectType();

    const { transferData, hide: hidePopup, isSaving, transferResultData } = useTransfer();

    const { group, entityType, transactionType } = transferData || {};
    
    const [step, setStep] = useState(null);

    const [ selectedEntityType, setSelectedEntityType ] = useState(entityType ? entityType: null);

    const [player, setPlayer] = useState(null);

    /** Entity Type */
    const entityTypes = useMemo(() => {
        return getEntityTypesByGroupAndTransactionType({
            group, 
            transactionType,
            hasStandartPayment,
            hasEpos,
            hasRetail
        })
    }, [hasStandartPayment, hasEpos, hasRetail, transferData]);

    useEffect(() => {
        if(entityType){
            setStep(TRANSFER_POPUP_STEP.FORM);
            return;
        }

        if(transactionType){
            setSelectedEntityType(entityTypes[0]);
            setStep(TRANSFER_POPUP_STEP.FORM);
            return;
        }

        setStep(entityTypes.length > 1 ? TRANSFER_POPUP_STEP.ENTITY_TYPE : TRANSFER_POPUP_STEP.FORM)
        setSelectedEntityType(entityTypes.length === 1 ? entityTypes[0] : null)
    }, [transferData, entityTypes])


    /** Modal Props, depend on current view */
    const modalProps = useMemo(() => {
        const props = {};
        props.title = "";

        switch(step){
            case TRANSFER_POPUP_STEP.ENTITY_TYPE:
                /** Modal Title */
                switch (group) {
                    case TRANSFER_OPERATION_GROUP.DEPOSIT:
                        props.title = t('backoffice.wallet.deposit');
                        break;
                    case TRANSFER_OPERATION_GROUP.WITHDRAWAL:
                        props.title = t('backoffice.wallet.withdraw');
                        break;
                }

                props.hideOkButton = true;
                props.size = entityTypes.length === 2 ? POPUP_SIZE.SMALLEST : POPUP_SIZE.MIDDLE;
                props.cancelText = t('backoffice.common.cancel');

                props.onCancel = hidePopup;

                break;
            case TRANSFER_POPUP_STEP.FORM:
                switch (selectedEntityType) {
                    /** Project */
                    case TRANSFER_ENTITY_TYPE.SUPER_AGENT:
                        props.title = t('backoffice.wallet.adjustBalance');
                        props.okText = t('backoffice.wallet.transfer');
                        break;
                    /** Player */
                    case TRANSFER_ENTITY_TYPE.PLAYER:
                        if(hasStandartPayment){
                            props.okText = t('backoffice.wallet.transfer');
                        } else if(hasEpos){
                            props.okText = !player ? t('backoffice.players.find') : t('backoffice.wallet.transfer');
                        }

                        props.title = t('backoffice.wallet.transferToPlayer');

                        break;
                    // Betshop
                    case TRANSFER_ENTITY_TYPE.BETSHOP:
                        
                        if(transactionType === WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT){
                            props.title = t('backoffice.wallet.adjustBetshopLimit');
                            props.okText = t('backoffice.wallet.transfer');
                        } else if(transactionType === WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_RESET){
                            props.title = t("backoffice.betshops.resetLimit");
                            props.okText = t('backoffice.common.reset');
                        } else {
                            props.title = t('backoffice.wallet.transferToBetshop');
                            props.okText = t('backoffice.wallet.transfer');
                        }
                        break;
                    // Agent
                    case TRANSFER_ENTITY_TYPE.AGENT:
                        props.okText = t('backoffice.wallet.transfer');
                        if(transactionType === WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT){
                            props.title = t('backoffice.wallet.payCommission');
                        } else {
                            props.title = isFranchisingMode() ? t('backoffice.wallet.transferToBetshopOwner') : t('backoffice.wallet.transferToAgent');
                        }
                        break;
                    case TRANSFER_ENTITY_TYPE.TRANSACTION:
                        if(transactionType === WALLET_TRANSACTION_TYPE.ROLLBACK){
                            props.title = t('backoffice.wallet.rollback')
                            props.okText = t('backoffice.wallet.rollback');
                        } else if(transactionType === WALLET_TRANSACTION_TYPE.CONFIRM_TRANSACTION_REQUEST) {
                            props.title = group === TRANSFER_OPERATION_GROUP.DEPOSIT ? t('backoffice.payments.confirmDepositRequest') : t('backoffice.payments.confirmWithdrawalRequest')
                            props.okText = t('backoffice.common.confirm')
                        } else if(transactionType === WALLET_TRANSACTION_TYPE.REJECT_TRANSACTION_REQUEST) {
                            props.title = group === TRANSFER_OPERATION_GROUP.DEPOSIT ? t('backoffice.payments.rejectDepositRequest') : t('backoffice.payments.rejectWithdrawalRequest')
                            props.okText = t('backoffice.common.reject')
                        } else if(transactionType === WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST) {
                            props.title = t('backoffice.payments.adjustTransaction')
                            props.okText = t('backoffice.payments.adjust')
                        } 
                        break;
                    default:
                        break;
                }
    
                props.size = POPUP_SIZE.SMALL;
                props.cancelText = t('backoffice.common.cancel');
                props.disableCancelButton = isSaving;
                 
                props.isLoading = isSaving;
                props.okButtonProps = { loading: isSaving };
                if(selectedEntityType === TRANSFER_ENTITY_TYPE.PLAYER && hasEpos){
                    if(player){
                        const hasCurrencySupport = currencies.some(c => c.currencyCode === (player?.currencyCode ?? ""))
                        if(!hasCurrencySupport){
                            props.disableOkButton = true
                        }
                    }
                }
                
                props.onOk = () => {
                    triggerCustomEvent(TRANSFER_POPUP_OK_ACTION_EVENT_NAME);
                };
    
                props.onCancel = () => {
                    if(isSaving) return;
                    hidePopup();
                }
                break;
            case TRANSFER_POPUP_STEP.RESULT:
                const isTransferFailed = (transferResultData || []).some(r => r.status !== RESPONSE_CODES.Success);
                props.title = (
                    <div className='rt--flex rt--align-center rt--justify-center'>
                        {
                            isTransferFailed && <i className='icon-warning rt--font-bigest' />
                        }
                        
                        <span className='rt--title rt--font-bold rt--font-big rt--pl-4'>
                            {
                                isTransferFailed ? t("backoffice.wallet.transferFailed") : t("backoffice.wallet.transferSuccessful") 
                            }
                        </span>
                    </div>
                );
                props.size = POPUP_SIZE.BIG;
                props.className = isTransferFailed ? "rt--modal-error" : "rt--modal-success";
                props.cancelText = t('backoffice.common.close')
            
                props.hideOkButton = true;
    
                props.onCancel = hidePopup;
                break;
            default:
                break;
        }

        return props;
    }, [transferData, step, entityTypes, selectedEntityType, isSaving, player]);


    const handleEntityTypeClick = useCallback(type => {
        setSelectedEntityType(type);
        setStep(TRANSFER_POPUP_STEP.FORM);
    }, [])

    /** Show Result view, on Transfer success */
    useEffect(() => {
        if(transferResultData){
            setStep(TRANSFER_POPUP_STEP.RESULT)
        }
    }, [transferResultData])


    useEffect(() => {
        if(hasEpos){
            const handler = event => {
                const info = event?.detail?.info;
                if(info){
                    setPlayer(info);
                } else {
                    setPlayer(null);
                }
            }
    
            document.addEventListener(TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME, handler);
    
            return () => {
                document.removeEventListener(TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME, handler);
            }
        }
    }, [hasEpos])

    return (
        <Modal
            { ...modalProps }
        >
            {
                step === TRANSFER_POPUP_STEP.ENTITY_TYPE ? (
                    <TransferEntityType 
                        onClick={handleEntityTypeClick}
                        entityTypes={entityTypes}
                    />
                ) :
                step === TRANSFER_POPUP_STEP.FORM ? (
                    <TransferForm 
                        entityType={selectedEntityType}
                    />
                ) : 
                step === TRANSFER_POPUP_STEP.RESULT ? <TransferResult /> : null
            }
        </Modal>
    )
}

Transfer.propTypes = {
    /** Redux state property, Account currencies */
    currencies: PropTypes.arrayOf(currencyType),
}

const mapStateToProps = state => {
    return {
        currencies: state.profile.currencies
    }
}

export default connect(mapStateToProps, null)(Transfer);