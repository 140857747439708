import React from "react";

import BetshopManagersComponent from "pages/retail/betshopManagers/betshopManagers.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const BetshopManagersRoute = () => {
    return <BetshopManagersComponent />;
};

export default withPermission(
    withAuth(BetshopManagersRoute),
    {
        resource: PERMISSION_RESOURCE.BETSHOP_MANAGER,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
