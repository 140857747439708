import {useCallback, useMemo} from 'react';
import { useSelector } from 'react-redux';

import useProjectType from "hooks/useProjectType";

import { getSeparators } from 'utils/numberFormat';

const selectFormatSettings = state => state.profile.userInfo.formatSetting;
const selectCurrencies = state => state.profile.currencies;

const getCurrencyDecimalCount = (currencyCode, currencies = []) => {
    let curr = currencies.find(c => c.currencyCode.toLowerCase() === (currencyCode ?? '').toLowerCase());

    if (curr) {
        return curr.currency?.decimalCount ?? 0;
    }
    return 0;
}

const getCurrencySymbolFromCurrencies = (currencyCode, currencies = []) => {
    let curr = currencies.find(c => c.currencyCode.toLowerCase() === (currencyCode ?? '').toLowerCase());

    if (curr) {
        return curr.currency?.symbol || curr.currency?.currencyCode || '';
    }

    return '';
}

const useFormat = () => {

    //#region ------------------------------------------- REDUX --------------------------------------------//

    const formatSettings = useSelector(selectFormatSettings);
    const currencies = useSelector(selectCurrencies);

    const { hasLiteMode } = useProjectType();

    //#endregion

    const { separator, decimal } = getSeparators(formatSettings);

    const getCurrencySymbol = useCallback((currencyCode) => {
        return getCurrencySymbolFromCurrencies(currencyCode, currencies);
    }, [currencies])

    // Format Number
    const formatNumber = useCallback(num => {
        if (num === null || num === undefined) return "";

        const str = num.toString();
        const parts = str.split(".");
        if (separator !== "") {
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
        }
        return parts.join(decimal);
    }, [separator, decimal])

    const normalizeNumber = number => {
        if (number === null || number === undefined) return "";

        let str = number.toString();

        return str.replaceAll(separator, '').replace(decimal, '.');
    }

    // Format amount
    const formatAmount = useCallback((amount, currencyCode) => {
        if (amount === null || amount === undefined) return "-";

        const decimalCount = getCurrencyDecimalCount(currencyCode, currencies);

        let num = Number(amount);
        const degree = Math.pow(10, decimalCount);

        num = Math.trunc(num * degree) / degree

        return formatNumber(Number(num).toFixed(decimalCount));
    }, [formatNumber, currencies, hasLiteMode]);

    const formatCurrencyWithSymbol = useCallback((value, currencyCode) => {
        const symbol = getCurrencySymbol(currencyCode);

        return `${value} (${symbol})`;
    }, [currencies, getCurrencySymbol]);

    return {
        formatNumber,
        formatAmount,
        normalizeNumber,
        formatCurrencyWithSymbol,
        getCurrencySymbol
    }
}

export default useFormat;
