import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Spin } from "antd";

import NoData from './noData';

import { LEADERBOARD_TYPE } from '../constants';

import useFormat from 'hooks/useFormat';

import leadersType from "types/dashboard/leaders.type";

const LeaderboardMobileView = ({
    leaders,
    type,
    isLoading,
    currencyCode
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    if (isLoading) {
        return (
            <div className="rt--chart-operational-top-agents">
                <Spin spinning={isLoading} />
            </div>
        )
    }

    return (
        <div className="rt--chart-operational-top-agents">
            {leaders.length === 0 && (
                <NoData />
            )}

            {leaders.length > 0 && (
                leaders.map((leaderData, index) => (
                    <div
                        className="rt--chart-operational-top-agents-item rt--mb-8 rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12 rt--flex rt--align-center rt--justify-between"
                        key={leaderData.id}
                    >
                        <div className='rt--flex rt--align-center'>
                            <div  className={'rt--chart-top-user-num rt--chart-top-user-num' + '-' + (index + 1)}>
                                <span>{index + 1}</span>
                            </div>

                            <div className="rt--flex rt--flex-col rt--justify-center rt--flex-equal">
                                <b className="rt--title rt--pb-4 rt--font-medium rt--font-big">
                                    {leaderData.userName || leaderData.name}
                                </b>
                                <span>
                                    <span className="rt--title rt--text-secondary rt--font-medium rt--font-small">
                                        {t("backoffice.dashboard.id")}:
                                    </span>
                                    <span className="rt--title rt--text-secondary rt--font-normal rt--font-small rt--pl-2 rt--pr-2">
                                        {leaderData.id}
                                    </span>
                                </span>

                                {type === LEADERBOARD_TYPE.FINANCIAL && (
                                    <div className='rt--chart-operational-top-agents-item-info rt--flex rt--align-center'>
                                        <div className='rt--chart-operational-top-agents-item-info-amount'>
                                            <span className="rt--title rt--font-bold rt--text-secondary">
                                                {t("backoffice.dashboard.amount")}:
                                            </span>
                                            <span className="rt--title rt--text-secondary rt--font-normal rt--font-small rt--pl-2 rt--pr-2">
                                                {formatAmount(leaderData.amount, currencyCode)}
                                            </span>
                                        </div>

                                        <div className='rt--chart-operational-top-agents-item-info-separator' />

                                        <div className='rt--chart-operational-top-agents-item-info-count'>
                                            <span className="rt--title rt--font-bold rt--text-secondary">
                                                {t("backoffice.dashboard.count")}:
                                            </span>
                                            <span className="rt--title rt--text-secondary rt--font-normal rt--font-small rt--pl-2 rt--pr-2">
                                                {leaderData.count}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {type === LEADERBOARD_TYPE.OPERATIONAL && (
                            <div className="rt--flex rt--align-center">
                                <div className="rt--pl-2 rt--pr-2 rt--flex rt--align-center">
                                    <i className="icon-users rt--font-prebigest" />
                                </div>

                                <small className="rt--title rt--font-medium rt--font-normal">
                                    {leaderData.count}
                                </small>
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );
}

/** LeaderboardMobileView propTypes*/
LeaderboardMobileView.propTypes = {
    leaders: PropTypes.arrayOf(leadersType),
    isLoading: PropTypes.bool,
    currencyCode: PropTypes.string,
    /**
     * Leaderboard type (this adds/removes some display information)
     */
    type: PropTypes.oneOf([
        LEADERBOARD_TYPE.FINANCIAL,
        LEADERBOARD_TYPE.OPERATIONAL,
    ])
};

export default LeaderboardMobileView;
