import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useParams } from "react-router-dom";

import { Spin } from "antd"

import SubTabTableDashboardLayout from 'components/layouts/tab/subtab/table';

import Table from "components/common/table";
import Modal from "components/common/modal";

import DetailsAddComponent from "./details-add.component";
import WidgetPreview from "./widgetPreview";

import { getAgentBankDetails, saveBankTranslation, deleteAgentBankDetails } from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action';
import { getCompanyAvailableLanguages } from 'store/actions/dashboard/common/common.action';

import { sendDataToIframe, isMobile } from 'utils/common';
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { REGISTRATION_FORM_TYPE, WIDGET_TRANSACTION_TYPE } from "constants/project.constants";
import { getTableColumns } from './columns';

import agentBankDetailsType from "types/agent/bankDetails.type"

/** Agent Bank Details Component */
const BankDetailsComponent = ({
    type,
    widgetTransactionType,
    getAgentBankDetails,
    saveBankTranslation,
    deleteAgentBankDetails,
    bankDetails,
    isLoading,
    info,
    getCompanyAvailableLanguages,
    companyAvailableLanguages,
    globalCompanyId
}) => {
    const { t } = useTranslation();

    const { search } = useLocation();
    const searchParams = useParams();

    const currentBankId = (new URLSearchParams(search)).get("bankId");

    const details = bankDetails[type];

    const userCanModify = hasPermission({ resource: PERMISSION_RESOURCE.AGENT_PAYMENT, action: PERMISSION_ACTION.MODIFY })

    /** State for selected language code */
    const [languageCode, setLanguageCode] = useState(null)

    /** State for selected currency code */
    const [currencyCode, setCurrencyCode] = useState(null)

    /** State to show/hide add popup */
    const [showAddPopup, setShowAddPopup] = useState(false);

    const [widgetPreview, setWidgetPreview] = useState(false);

    const detailsToSendRef = useRef({})


    /** Load Bank Details */
    useEffect(() => {
        getAgentBankDetails(type, searchParams.id, currentBankId)
    }, [])

    /** Load company available languages */
    useEffect(() => {
        getCompanyAvailableLanguages()
    }, [globalCompanyId])

    /** Set default Currency */
    useEffect(() => {
        setCurrencyCode(info.currencies[0])
    }, [info.currencies])

    /** Set default Language */
    useEffect(() => {
        if (Object.keys(companyAvailableLanguages).length !== 0) {
            const lang = Object.keys(companyAvailableLanguages)[0]
            setLanguageCode(lang.toUpperCase())
        }
    }, [companyAvailableLanguages])

    /** Get detail translation
       * @function
       * @returns {string}
       * @memberOf BankDetailsComponent
    */
    const getTranslation = (value, keyType) => {
        const allTranslations = details.translations;
        if (!currencyCode || !languageCode || !allTranslations) return "";
        let currentCurrencyCode = currencyCode.toLowerCase();

        const translations = allTranslations[currentCurrencyCode];
        if (!translations) return {};

        let translationItem = value;
        if (!translationItem) return {};
        translationItem = translationItem.toLowerCase();

        const searchedTranslation = translations.find(tr => tr.key.endsWith(`_${currentCurrencyCode}_${type}_${keyType}_${translationItem}`));

        if (!searchedTranslation) return {}

        const searchedTranslationTranslations = searchedTranslation.translations;

        const languageTranslation = searchedTranslationTranslations.find(t => t.languageCode === languageCode.toUpperCase());

        if (!languageTranslation) return {}
        return {
            key: searchedTranslation.key,
            text: languageTranslation.text
        };

    }


    /** Get currency details
       * @function
       * @returns {array}
       * @memberOf BankDetailsComponent
    */
    const getDetails = () => {
        const allDetails = details.details;
        if (!currencyCode || !languageCode || !allDetails) return [];
        let currentCurrencyCode = currencyCode.toLowerCase();

        let currencyDetails = allDetails[currentCurrencyCode];
        if (!currencyDetails) return [];

        currencyDetails = currencyDetails.map(detail => {
            const ketTranslation = getTranslation(detail.key, "key");
            const valueTranslation = getTranslation(detail.value, "value");
            return {
                key: ketTranslation.text,
                value: valueTranslation.text,
                keyName: detail.key,
                valueName: detail.value,
                keyKey: ketTranslation.key,
                valueKey: valueTranslation.key,
            }
        })

        return currencyDetails;
    }

    const handleFieldsChange = ({ key, value, text, translationKey }) => {
        saveBankTranslation({
            agentId: searchParams.id,
            id: currentBankId,
            currencyCode: currencyCode,
            languageCode: languageCode,
            type: type,
            key,
            value,
            text,
            translationKey,
        })
    }

    const { mainTableColumns } = tableColumnsCreator({
        mainColumns: getTableColumns,
        additionalProps: {
            havePermissionForEdit: userCanModify,
            onFieldsChange: handleFieldsChange,
        }
    })

    /** Details update */
    useEffect(() => {
        const data = getDetails();
        const obj = {}
        data.forEach(d => {
            obj[d['key']] = d['value']
        })

        detailsToSendRef.current = {
            action: "agentDetails",
            transactionType: widgetTransactionType,
            details: obj
        }

        sendDataToIframe("payment-widget-iframe-" + widgetTransactionType, detailsToSendRef.current)
    }, [details, currencyCode, languageCode])

    /** Initialize post message listeners */
    useEffect(() => {
        const handler = e => {
            if (e.data && e.data.transactionType === widgetTransactionType) {
                if (e.data.action === "getAgentDetails") {
                    sendDataToIframe("payment-widget-iframe-" + widgetTransactionType, detailsToSendRef.current)
                }
            }
        }
        window.addEventListener("message", handler);

        return () => {
            window.removeEventListener("message", handler);
        }
    }, [])

    return (
        <SubTabTableDashboardLayout
            header={
                {
                    button: {
                        icon: "icon-plus",
                        type: "primary",
                        onClick: () => {
                            setShowAddPopup(true)
                        },
                        text: t("backoffice.payments.addDetailRow"),
                        enabled: userCanModify,
                    }
                }
            }
            dropdowns={
                [
                    {
                        value: languageCode,
                        onChange: value => setLanguageCode(value),
                        items: Object.keys(companyAvailableLanguages).map(item => ({
                            key: item.toUpperCase(),
                            value: t(`backoffice.languages.${item}`)
                        }))
                    },

                    {
                        value: currencyCode,
                        onChange: value => setCurrencyCode(value),
                        items: (info.currencies || []).map(item => ({
                            key: item.toUpperCase(),
                            value: item.toUpperCase()
                        })),
                        small: true
                    }
                ]
            }
            button={
                {
                    icon: 'icon-eye',
                    type: "primary",
                    onClick: () => setWidgetPreview(true),
                    text: t("backoffice.common.preview"),
                    enabled: isMobile(),
                    className: isMobile() ? "rt--width-full" : ""
                }
            }
        >
            <Fragment>
                <div className={isMobile() ? "rt--payment" : 'rt--payment rt--flex rt--justify-between'}>
                    <div className={isMobile() ? "" : 'rt--payment-content rt--flex-equal rt--mr-40'}>
                        <Spin spinning={isLoading}>
                            <Table
                                columns={mainTableColumns}
                                data={getDetails()}
                                uniqueKey="key"
                                mobileLayoutVertical={true}
                                noPagination={true}
                                disableFullView={true}
                                actions={[
                                    ...(userCanModify ? [
                                        {
                                            title: t('backoffice.common.delete'),
                                            icon: "icon-trash",
                                            onClick: record => deleteAgentBankDetails({
                                                agentId: searchParams.id,
                                                id: currentBankId,
                                                key: record.keyName,
                                                value: record.valueName,
                                                currencyCode: currencyCode,
                                                type: type
                                            })
                                        }
                                    ] : [])
                                ]}
                            />
                        </Spin>
                    </div>
                    {
                        !isMobile() && (
                            <WidgetPreview
                                transactionType={widgetTransactionType}
                                languageCode={languageCode}
                            />
                        )
                    }

                </div>
            </Fragment>
            {
                showAddPopup && (
                    <DetailsAddComponent
                        onClose={() => setShowAddPopup(false)}
                        type={type}
                        currencyCode={currencyCode}
                    />
                )
            }

            {
                widgetPreview && (
                    <Modal
                        title={t("backoffice.common.preview")}
                        onCancel={() => setWidgetPreview(false)}
                    >
                        <div className="rt--payment-preview">
                            <WidgetPreview
                                transactionType={widgetTransactionType}
                                languageCode={languageCode}
                            />
                        </div>
                    </Modal>
                )
            }
        </SubTabTableDashboardLayout>
    );
}

/** BankDetailsComponent propTypes
    * PropTypes
*/
BankDetailsComponent.propTypes = {
    /** Bank details type */
    type: PropTypes.oneOf(Object.values(REGISTRATION_FORM_TYPE)),
    /** Widget transaction type */
    widgetTransactionType: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE)),
    /** Redux action to get agent bank details */
    getAgentBankDetails: PropTypes.func,
    /** Redux action to save agent bank details translation */
    saveBankTranslation: PropTypes.func,
    /** Redux action to delete agent bank details */
    deleteAgentBankDetails: PropTypes.func,
    /** Redux state property, is true when bank details is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current bank details */
    bankDetails: agentBankDetailsType,
    /** Redux state property, current bank general info */
    info: PropTypes.object,
    /** Redux action to get company available languages */
    getCompanyAvailableLanguages: PropTypes.func,
    /** Redux state property, represents the object of company available  languages */
    companyAvailableLanguages: PropTypes.object,
    /** Redux state, represents global company id */
    globalCompanyId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getAgentBankDetails: (type, agentId, id) => {
            dispatch(getAgentBankDetails(type, agentId, id));
        },

        saveBankTranslation: translation => {
            dispatch(saveBankTranslation(translation));
        },

        deleteAgentBankDetails: details => {
            dispatch(deleteAgentBankDetails(details));
        },

        getCompanyAvailableLanguages: () => {
            dispatch(getCompanyAvailableLanguages())
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.agents.isLoading,
        bankDetails: state.agents.edit.payment.banks.edit.details,
        info: state.agents.edit.payment.banks.edit.info,
        companyAvailableLanguages: state.common.availableLanguages.availableLanguages,
        globalCompanyId: state.common.globalCompanyId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsComponent);