import {
    SET_AGENTS_ACTION_BEFORE,
    SET_AGENTS_ACTION_FINISH,
    SET_AGENTS_SAVE_ACTION_BEFORE,
    SET_AGENTS_SAVE_ACTION_FINISH,
    SET_AGENTS,
    SET_AGENTS_SORTING,
    SET_AGENTS_FILTERS,
    SET_AGENT_SUB_AGENTS_ACTION_BEFORE,
    SET_AGENT_SUB_AGENTS_ACTION_FINISH,
    SET_AGENT_SUB_AGENTS,
    SET_AGENT_SUB_AGENTS_SORTING,
    SET_AGENT_SUB_AGENTS_FILTERS,
    SET_AGENT_PLAYERS_ACTION_BEFORE,
    SET_AGENT_PLAYERS_ACTION_FINISH,
    SET_AGENT_PLAYERS,
    SET_AGENT_PLAYERS_SORTING,
    SET_AGENT_PLAYERS_FILTERS,
    SET_UPDATE_AGENT_PLAYER_DATA,
    SET_AGENT_BETSHOPS_ACTION_BEFORE,
    SET_AGENT_BETSHOPS_ACTION_FINISH,
    SET_AGENT_BETSHOPS,
    SET_AGENT_BETSHOPS_SORTING,
    SET_AGENT_BETSHOPS_FILTERS,
    SET_AGENT_BALANCE_OR_CREDIT,
    SET_AGENT_REGISTRATION_FORM_BEFORE,
    SET_AGENT_REGISTRATION_FORM_FINISH,
    SET_AGENT_REGISTRATION_FORM,
    SET_AVAILABLE_AGENTS_FOR_REPARENTING_BEFORE,
    SET_AVAILABLE_AGENTS_FOR_REPARENTING_FINISH,
    SET_AVAILABLE_AGENTS_FOR_REPARENTING,
    SET_AGENT_GENERAL_INFO,
    SET_AGENT_ADDITIONAL_ACCESSES,
    SET_AGENT_CURRENCIES,
    SET_AGENT_CURRENCIES_ACTION_BEFORE,
    SET_AGENT_CURRENCIES_ACTION_FINISH,
    SET_AGENT_AVAILABLE_CURRENCIES_BEFORE,
    SET_AGENT_AVAILABLE_CURRENCIES_FINISHED,
    SET_AGENT_AVAILABLE_CURRENCIES,
    SET_AGENT_LIMIT_SETTINGS,
    SET_AGENT_PAYMENT_METHODS,
    SET_AGENT_EPOS_SETTINGS,
    SET_AGENT_EPOS_TRANSLATIONS,
    SET_AGENT_BANK_LIST,
    SET_AGENT_AVAILABLE_BANK_LIST,
    SET_AGENT_BANK_INFO,
    SET_AGENT_BANK_DETAILS,
    SET_AGENT_BANK_WIDGET,
    SET_AGENT_TOTALS_ACTION_BEFORE,
    SET_AGENT_TOTALS_ACTION_FINISH,
    SET_AGENT_TOTALS,
    SET_AGENT_PLAYER_TOTALS,
    SET_AGENT_PLAYER_TOTALS_ACTION_BEFORE,
    SET_AGENT_PLAYER_TOTALS_ACTION_FINISH
} from "../../actionTypes";

import { WALLET_OWNER_TYPE } from "constants/wallet.constants"

export default (state = {}, { type, payload }) => {
    let path = [];
    switch (type) {
        case SET_AGENTS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_AGENTS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_AGENTS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_AGENTS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AGENTS:
            return {
                ...state,
                agents: !payload.add ? payload.agents.item2 : state.agents.concat(payload.agents.item2),
                total: payload.agents.item1,
            };

        case SET_AGENTS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_AGENTS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_AGENT_SUB_AGENTS_ACTION_BEFORE:
            return {
                ...state,
                subAgents: {
                    ...state.subAgents,
                    isLoading: true
                }
            };
        case SET_AGENT_SUB_AGENTS_ACTION_FINISH:
            return {
                ...state,
                subAgents: {
                    ...state.subAgents,
                    isLoading: false
                }
            };
        case SET_AGENT_SUB_AGENTS:
            return {
                ...state,
                subAgents: {
                    ...state.subAgents,
                    agents: !payload.add ? payload.agents.item2 : state.subAgents.agents.concat(payload.agents.item2),
                    total: payload.agents.item1,
                }
            };
        case SET_AGENT_SUB_AGENTS_SORTING:
            return {
                ...state,
                subAgents: {
                    ...state.subAgents,
                    sorting: payload.sorting
                }
            };
        case SET_AGENT_SUB_AGENTS_FILTERS:
            return {
                ...state,
                subAgents: {
                    ...state.subAgents,
                    filters: payload.filters,
                    sorting: {
                        ...state.subAgents.sorting,
                        page: 1
                    }
                }
            };
        case SET_AGENT_PLAYERS_ACTION_BEFORE:
            return {
                ...state,
                players: {
                    ...state.players,
                    isLoading: true
                }
            };
        case SET_AGENT_PLAYERS_ACTION_FINISH:
            return {
                ...state,
                players: {
                    ...state.players,
                    isLoading: false
                }
            };
        case SET_AGENT_PLAYERS:
            return {
                ...state,
                players: {
                    ...state.players,
                    players: !payload.add ? payload.players.item2 : state.players.players.concat(payload.players.item2),
                    total: payload.players.item1,
                }
            };
        case SET_UPDATE_AGENT_PLAYER_DATA:
            return {
                ...state,
                players: {
                    ...state.players,
                    players: state.players.players.map(player => player.id === payload.id ? payload.data : player)
                }
            };
        case SET_AGENT_PLAYER_TOTALS_ACTION_BEFORE:
            return {
                ...state,
                players: {
                    ...state.players,
                    isTotalsLoading: true,
                }
            };
        case SET_AGENT_PLAYER_TOTALS_ACTION_FINISH:
            return {
                ...state,
                players: {
                    ...state.players,
                    isTotalsLoading: false,
                }
            };
        case SET_AGENT_PLAYER_TOTALS:
            return {
                ...state,
                players: {
                    ...state.players,
                    totals: payload.totals
                }
            };
        case SET_AGENT_PLAYERS_SORTING:
            return {
                ...state,
                players: {
                    ...state.players,
                    sorting: payload.sorting
                }
            };
        case SET_AGENT_PLAYERS_FILTERS:
            return {
                ...state,
                players: {
                    ...state.players,
                    filters: payload.filters,
                    sorting: {
                        ...state.players.sorting,
                        page: 1
                    }
                }
            };
        case SET_AGENT_BETSHOPS_ACTION_BEFORE:
            return {
                ...state,
                betshops: {
                    ...state.betshops,
                    isLoading: true
                }
            };
        case SET_AGENT_PAYMENT_METHODS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    payment: {
                        ...state.edit.payment,
                        config: payload.config
                    }
                }
            };
        case SET_AGENT_EPOS_SETTINGS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    payment: {
                        ...state.edit.payment,
                        eposSettings: {
                            ...payload
                        }
                    }
                }
            };
        case SET_AGENT_EPOS_TRANSLATIONS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    payment: {
                        ...state.edit.payment,
                        eposSettings: {
                            ...state.edit.payment.eposSettings,
                            translations: state.edit.payment.eposSettings.translations.map(
                                tr => tr.key === payload.key ?
                                    {
                                        ...tr,
                                        translations: tr.translations.map(t => t.languageCode === payload.translation.languageCode ? payload.translation : t)
                                    } : tr
                            )
                        }
                    }
                }
            }
        case SET_AGENT_BANK_LIST:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    payment: {
                        ...state.edit.payment,
                        banks: {
                            ...state.edit.payment.banks,
                            banks: payload,
                        }
                    }
                }
            }
        case SET_AGENT_BANK_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    payment: {
                        ...state.edit.payment,
                        banks: {
                            ...state.edit.payment.banks,
                            edit: {
                                ...state.edit.payment.banks.edit,
                                info: { ...payload }
                            },
                        }
                    }
                }
            }
        case SET_AGENT_AVAILABLE_BANK_LIST:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    payment: {
                        ...state.edit.payment,
                        banks: {
                            ...state.edit.payment.banks,
                            availableBanks: [...payload],
                        }
                    }
                }
            }
        case SET_AGENT_BANK_DETAILS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    payment: {
                        ...state.edit.payment,
                        banks: {
                            ...state.edit.payment.banks,
                            edit: {
                                ...state.edit.payment.banks.edit,
                                details: {
                                    ...state.edit.payment.banks.edit.details,
                                    [payload.type]: {
                                        ...payload.details
                                    }
                                }
                            },
                        }
                    }
                }
            }
        case SET_AGENT_BANK_WIDGET:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    payment: {
                        ...state.edit.payment,
                        banks: {
                            ...state.edit.payment.banks,
                            edit: {
                                ...state.edit.payment.banks.edit,
                                widget: {
                                    ...state.edit.payment.banks.edit.widget,
                                    [payload.transactionType]: payload.url
                                }
                            },
                        }
                    }
                }
            }
        case SET_AGENT_BETSHOPS_ACTION_FINISH:
            return {
                ...state,
                betshops: {
                    ...state.betshops,
                    isLoading: false
                }
            };
        case SET_AGENT_BETSHOPS:
            return {
                ...state,
                betshops: {
                    ...state.betshops,
                    betshops: !payload.add ? payload.betshops.item2 : state.betshops.betshops.concat(payload.betshops.item2),
                    total: payload.betshops.item1,
                }
            };
        case SET_AGENT_BETSHOPS_SORTING:
            return {
                ...state,
                betshops: {
                    ...state.betshops,
                    sorting: payload.sorting
                }
            };
        case SET_AGENT_BETSHOPS_FILTERS:
            return {
                ...state,
                betshops: {
                    ...state.betshops,
                    filters: payload.filters,
                    sorting: {
                        ...state.betshops.sorting,
                        page: 1
                    }
                }
            };
        case SET_AGENT_BALANCE_OR_CREDIT:
            return {
                ...state,
                agents: state.agents.map(agent => ({
                    ...agent,
                    wallets: agent.wallets.map(wallet => {
                        let w = { ...wallet };
                        let updatedWallet = payload.wallets.filter(
                            u => u.ownerType === WALLET_OWNER_TYPE.AGENT && u.currencyCode === wallet.currencyCode && u.userName === agent.userName
                        )

                        updatedWallet.forEach(u => {
                            if (w[u.type]) {
                                w[u.type].amount = u.totalAmount;
                            }
                        })

                        return w;
                    })
                })),
                subAgents: {
                    ...state.subAgents,
                    agents: state.subAgents.agents.map(agent => ({
                        ...agent,
                        wallets: agent.wallets.map(wallet => ({
                            ...wallet,
                            amount: payload.wallets.some(w =>
                                w.userName === agent.userName &&
                                w.ownerType === WALLET_OWNER_TYPE.AGENT &&
                                w.currencyCode === wallet.currencyCode &&
                                w.type === wallet.type
                            ) ? payload.wallets.find(w =>
                                w.userName === agent.userName &&
                                w.ownerType === WALLET_OWNER_TYPE.AGENT &&
                                w.currencyCode === wallet.currencyCode &&
                                w.type === wallet.type
                            ).totalAmount : wallet.amount
                        }))
                    }))
                },
                betshops: {
                    ...state.betshops,
                    betshops: state.betshops.betshops.map(betshop => ({
                        ...betshop,
                        balance: payload.wallets.some(w => w.ownerType === WALLET_OWNER_TYPE.BETSHOP && w.userName === betshop.name) ? payload.wallets.find(w => w.ownerType === WALLET_OWNER_TYPE.BETSHOP && w.userName === betshop.name).totalAmount : betshop.balance
                    }))
                }
            };
        case SET_AGENT_REGISTRATION_FORM_BEFORE:
            return {
                ...state,
                isRegistrationFormLoading: true,
            };
        case SET_AGENT_REGISTRATION_FORM_FINISH:
            return {
                ...state,
                isRegistrationFormLoading: false,
            };
        case SET_AGENT_REGISTRATION_FORM:
            return {
                ...state,
                registrationForm: payload.registrationForm
            }
        case SET_AVAILABLE_AGENTS_FOR_REPARENTING_BEFORE:
            return {
                ...state,
                isAvailableAgentsForReparentingLoading: true,
            };
        case SET_AVAILABLE_AGENTS_FOR_REPARENTING_FINISH:
            return {
                ...state,
                isAvailableAgentsForReparentingLoading: false,
            };
        case SET_AVAILABLE_AGENTS_FOR_REPARENTING:
            return {
                ...state,
                availableAgentsForReparenting: payload.agents
            }
        case SET_AGENT_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case SET_AGENT_ADDITIONAL_ACCESSES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    additionalAccesses: {
                        ...payload.data
                    }
                }
            }
        case SET_AGENT_CURRENCIES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    currencies: {
                        ...state.edit.currencies,
                        currencies: payload.currencies
                    }
                }
            }
        case SET_AGENT_CURRENCIES_ACTION_BEFORE:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    currencies: {
                        ...state.edit.currencies,
                        isLoading: true
                    }
                }
            }
        case SET_AGENT_CURRENCIES_ACTION_FINISH:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    currencies: {
                        ...state.edit.currencies,
                        isLoading: false
                    }
                }
            }
        case SET_AGENT_AVAILABLE_CURRENCIES_BEFORE:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    currencies: {
                        ...state.edit.currencies,
                        isAvailableLoading: true
                    }
                }
            }
        case SET_AGENT_AVAILABLE_CURRENCIES_FINISHED:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    currencies: {
                        ...state.edit.currencies,
                        isAvailableLoading: false
                    }
                }
            }
        case SET_AGENT_AVAILABLE_CURRENCIES:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    currencies: {
                        ...state.edit.currencies,
                        availableCurrencies: payload.currencies
                    }
                }
            }
        case SET_AGENT_LIMIT_SETTINGS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    limitSettings: payload.limitSettings
                }
            }
        case SET_AGENT_TOTALS_ACTION_BEFORE:
            return {
                ...state,
                isTotalsLoading: true
            }
        case SET_AGENT_TOTALS_ACTION_FINISH:
            return {
                ...state,
                isTotalsLoading: false
            }
        case SET_AGENT_TOTALS:
            return {
                ...state,
                totals: payload.totals
            }
        default:
            return state;
    }
};
