import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Spin, Row, Col, Divider } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import ColorPicker from "components/common/colorPicker";

import { getProjectChatUI, saveProjectChatUI } from "store/actions/dashboard/projects/chatUI.action";

import projectChatUIType from "types/project/chatUI.type";

import { copyToClipboard } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { COLORS_LIST, COLOR_DEFAULTS } from "constants/chatColors.constants";


/** Project Edit Page Integration Tab Chat subtab Component */
const ChatIntegrationComponent = ({
    getProjectChatUI,
    saveProjectChatUI,
    isSaving,
    isLoading,
    chatUI,
    onTabChange,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [values, setValues] = useState(COLOR_DEFAULTS)

    /** Get project chat ui */
    useEffect(() => {
        getProjectChatUI()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        if(chatUI.parameters){
            const v = {};
            chatUI.parameters.forEach(parameter => {
                v[parameter["key"]] = parameter["value"];
            })
            setValues(v);
        }
    }, [chatUI])

    /** Fires when form submitted
       * @function
       * @memberOf ChatIntegrationComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveProjectChatUI({
                    parameters: Object.keys(values).map(v => ({key: v, value: values[v]}))
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Update color picker values from saved data
		* @function
		* @param{string} name - color name
		* @param{string} value - color value
		* @memberOf ChatIntegrationComponent
	*/
	const onChange = (name, value) => {
        const newValues = {
            ...values,
			[name]: value
		};
		setValues(newValues)
        let oldValues = {};
        if(chatUI.parameters){
            chatUI.parameters.forEach(parameter => {
                oldValues[parameter["key"]] = parameter["value"];
            })
        } else {
            oldValues = COLOR_DEFAULTS;
        }
        
        setIsFormTouched(isFormChanged(oldValues, newValues))
	}

    const scriptText = `<script type="text/javascript">
        var cho = {}; cho["_uid"] ="{{USER_ID}}"; cho["_pid"] = "${globalProjectId}"; cho["_domain"] = "${import.meta.env.SYSTEM_CHAT_DOMAIN}";
        var _chls = _chls || []; _chls.push(cho);
        (function() { var sc = document.createElement("script"); sc.type = "text/javascript";
        sc.async = true; sc.src = "${import.meta.env.SYSTEM_CDN_URL}${import.meta.env.SYSTEM_CHAT_INTEGRATION_SCRIPT}";
        var c = document.getElementsByTagName("script")[0];
        c.parentNode.insertBefore(sc, c); })();
    </script>`


    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <h4 className="rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-biger">{t('backoffice.projects.customization')}</h4>
                            <Form.Item
                                label=""
                            >
                                {
                                    COLORS_LIST.map((l, i) => (
                                        <div className="rt--color-picker-list rt--mb-12 rt--flex rt--align-center rt--justify-between" key={i}>
                                            <span className="rt--color-picker-list-title rt--title rt--font-normal rt--font-bold">
                                                {
                                                    i === 0 ? t("backoffice.projects.brandColors") :
                                                        i === 1 ? t("backoffice.projects.backgroundColors") :
                                                            i === 2 ? t("backoffice.projects.textAndIconColors") :
                                                                t("backoffice.projects.avatarBackgroundColors")
                                                }
                                            </span>
                                            <div className="rt--color-picker-list-items rt--flex rt--align-center rt--flex-equal">
                                                {
                                                    l.map(c => (
                                                        <ColorPicker
                                                            color={values[c]}
                                                            key={c}
                                                            onChange={value => onChange(c, value)}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className='rt--form-section-divider' />
                    <Row gutter={[16, 0]}>
                        <Col xs={24} xl={18}>
                            <h4 className="rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-biger">{t('backoffice.projects.script')}</h4>

                            <Form.Item
                                label=""
                                className='rt--form-item-disabled rt--form-item-code-editor'
                            >
                                <div className='rt--form-item-code-editor-area rt--pl-16 rt--pb-16 rt--pt-16 rt--pr-16'>
                                    <span className='rt--title rt--font-normal rt--font-regular'>{scriptText}</span>
                                    <i className='icon-copy rt--font-bigest' onClick={() => copyToClipboard(scriptText)}></i>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** ChatIntegrationComponent propTypes
    * PropTypes
*/
ChatIntegrationComponent.propTypes = {
    /** Redux action to save project chat ui */
    saveProjectChatUI: PropTypes.func,
    /** Redux state property, is true when chat UI is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when chat UI is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current editing project chat UI */
    chatUI: projectChatUIType,
    /** Redux action to get project chat ui */
    getProjectChatUI: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectChatUI: () => {
            dispatch(getProjectChatUI());
        },

        saveProjectChatUI: data => {
            dispatch(saveProjectChatUI(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        chatUI: state.projects.edit.chatUI,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatIntegrationComponent)