import PropTypes from 'prop-types';


export default PropTypes.shape({
    allowCreateNonDirectAgents: PropTypes.bool,
    allowBlockNonDirectAgents: PropTypes.bool,
    allowDeleteNonDirectAgents: PropTypes.bool,
    allowReparentNonDirectAgents: PropTypes.bool,
    allowSetPasswordNonDirectAgents: PropTypes.bool,
    allowCreateNonDirectPlayers: PropTypes.bool,
    allowCreateNonDirectBetShops: PropTypes.bool,
    allowBlockNonDirectPlayers: PropTypes.bool,
    allowReparentNonDirectPlayers: PropTypes.bool,
    allowSetPasswordNonDirectPlayers: PropTypes.bool,
    allowBlockNonDirectBetShops: PropTypes.bool,
    allowReparentNonDirectBetShops: PropTypes.bool,
    allowNonDirectBetShopTransactions: PropTypes.bool,
    allowNonDirectPlayerTransactions: PropTypes.bool,
    allowNonDirectTransactions: PropTypes.bool
})