export const isString = (param) => {
    return typeof param === "string";
};

export const isNumber = (param) => {
    return typeof param === "number";
};

export const isArray = (value) => {
    return Array.isArray(value);
};

export const isObject = (value) => {
    if (isNull(value)) {
        return false;
    }

    return typeof value === "object";
};

export const isObjectLiteral = (value) => {
    if (isNull(value)) {
        return false;
    }

    if (isArray(value)) {
        return false;
    }

    return typeof value === "object";
};

export const isNull = (param) => {
    return param === null;
}

export const isUndefined = (param) => {
    return param === undefined;
}
