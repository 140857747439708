import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setPlayersActionBefore, setPlayersActionFinished } from './players.action';

import { SET_PLAYER_GENERAL_INFO } from "../../../actionTypes";

const setPlayerGeneralInfo = info => ({
    type: SET_PLAYER_GENERAL_INFO,
    payload: { info }
})

export const getPlayerGeneralInfo = id => {
    return dispatch => {
        dispatch(setPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setPlayerGeneralInfo(info))
            dispatch(setPlayersActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayersActionFinished());
        })
    }
}

