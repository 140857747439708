//#region react
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getBetshops,
    setBetshopsSorting,
    setBetshopsFilters,
    setBetshopBalance
} from "store/actions/dashboard/retail/betshops/betshops.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import BetshopCreateComponent from "./betshop-create.component";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
import { TRANSFER_ENTITY_TYPE, TRANSFER_SUCCESS_EVENT_NAME, useTransfer } from 'components/common/transfer';
//#endregion

//#region utils
import { hasBetshopNonDirectTransactionAccess } from "utils/access";
import { hasPermission, hasOneOfPermissions } from 'utils/permissions';
import { getUser } from "utils/auth";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
import { isFranchisingMode } from "utils/common";
//#endregion

//#region constants
import {
    WALLET_OWNER_TYPE,
    WALLET_TRANSACTION_TYPE
} from 'constants/wallet.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { USER_ROLE, USER_STATE } from 'constants/user.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";

import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import { BETSHOPS } from "constants/pageName.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import betshopType from "types/betshop/betshop.type";
import sortingType from "types/common/sorting.type";
import userInfoType from 'types/profile/userInfo.type';
//#endregion

const {
    BETSHOP_GENERALINFO,
    BETSHOP_CURRENCIES,
    BETSHOP_WALLETS,
    BETSHOP,
    BETSHOP_LIMIT_SETTINGS,
    BETSHOP_ADDITIONAL_ACCESS,
    BETSHOP_IP_ACCESS_RULE,
} = PERMISSION_RESOURCE;

const { VIEW, EXPORT, CREATE, MODIFY } = PERMISSION_ACTION;

const canTransferToBetShop = ({ betshop, userInfo, isAdmin }) => {
    if (!isAdmin) {
        return (
            hasBetshopNonDirectTransactionAccess(userInfo) ||
            betshop.agentId === userInfo.id
        )
    }

    return true;
}

/** Betshops Page Component */
const BetshopsComponent = ({
    getBetshops,
    betshops,
    isLoading,
    total,
    setBetshopsSorting,
    setBetshopsFilters,
    setBetshopBalance,
    sorting,
    filters,
    globalCompanyId,
    globalProjectId,
    userInfo
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { show: showTransfer} = useTransfer();

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);

    const { formatAmount } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: BETSHOPS });

    const { userState, role } = getUser();
    const isAdmin = role === USER_ROLE.ADMIN;
    const isUserActive = userState === USER_STATE.ACTIVE;

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: BETSHOP,
        action: EXPORT
    })

    const hasBetshopCreatePermission = hasPermission({
        resource: BETSHOP,
        action: CREATE
    })

    const hasBetshopWalletsModifyPermission = hasPermission({
        resource: BETSHOP_WALLETS,
        action: MODIFY
    })

    const canNavigateBetshopEditPage = hasOneOfPermissions([
        { resource: BETSHOP_GENERALINFO, action: VIEW },
        { resource: BETSHOP_CURRENCIES, action: VIEW },
        { resource: BETSHOP_LIMIT_SETTINGS, action: VIEW },
        { resource: BETSHOP_ADDITIONAL_ACCESS, action: VIEW },
        { resource: BETSHOP_IP_ACCESS_RULE, action: VIEW },
        { resource: isAdmin ? BETSHOP_WALLETS : BETSHOP, action: VIEW },
    ])

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setBetshopsFilters({
            ...filters,
            nameOrId: value ? item ? item.id : value : ""
        })
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.BETSHOPS_EDIT}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.code}`
        )
    }

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true)
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsForExport is only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount
            }
        });
    }, [includedColumns, formatAmount])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (isUserActive && (!isAdmin || hasBetshopWalletsModifyPermission)) {
        tableRowActions.push({
            title: t("backoffice.betshops.transfer"),
            icon: "icon-transfer",
            onClick: record => {
                const transferData = {
                    entityType: TRANSFER_ENTITY_TYPE.BETSHOP,
                    userId: record.id,
                    currencyCode: record.currencyCode
                }
                if(isAdmin && record.agentId !== globalProjectId && isFranchisingMode()){
                    transferData.transactionType = WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT
                }

                showTransfer(transferData);
            },
            disabled: record => !canTransferToBetShop({
                betshop: record,
                isAdmin,
                userInfo,
            }),
        })
    }

    if (canNavigateBetshopEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            placeholder: t("backoffice.betshops.betshopNameOrId"),
            loadFn: getBetshops,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.BETSHOP,
                dependencies: [globalProjectId]
            },
            onSearch: handleSearchChange,
        },
        buttons: [{
            text: t("backoffice.betshops.createBetshop"),
            icon: "icon-plus",
            type: "primary",
            enabled: hasBetshopCreatePermission,
            onClick: showCreatePopup,
        }],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.betshops') }]
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.betshops"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_BETSHOPS,
            filters: filters,
        }
    }

    //#endregion

    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                if(
                    data.entityType === TRANSFER_ENTITY_TYPE.BETSHOP
                ){
                    const updatedBetshopWallets = (data?.wallets ?? [])
                    .filter(w => w.ownerType === WALLET_OWNER_TYPE.BETSHOP && w.status === RESPONSE_CODES.Success);
                    if(updatedBetshopWallets.length > 0){
                        setBetshopBalance(updatedBetshopWallets)
                    }
                }
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [])

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={betshops}
                loadFn={getBetshops}
                sorting={sorting}
                setSortingFn={setBetshopsSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
            />

            {isCreatePopupVisible && <BetshopCreateComponent onClose={hideCreatePopup} />}

        </MainDashboardLayout>
    )
};

/** BetshopsComponent propTypes
 * PropTypes
*/
BetshopsComponent.propTypes = {
    /** Redux action to get betshops */
    getBetshops: PropTypes.func,
    /** Redux state property, represents the array of betshops  */
    betshops: PropTypes.arrayOf(betshopType),
    /** Redux state property, is true when loading betshops */
    isLoading: PropTypes.bool,
    /** Redux state property, betshops total count */
    total: PropTypes.number,
    /** Redux action to set betshops sorting details */
    setBetshopsSorting: PropTypes.func,
    /** Redux action to set betshops sorting details */
    setBetshopsFilters: PropTypes.func,
    /** Redux action to set betshops balance */
    setBetshopBalance: PropTypes.func,
    /** Redux state property, betshops sorting details */
    sorting: sortingType,
    /** Redux state property, betshops filters */
    filters: PropTypes.object,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global company id */
    globalProjectId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
};

const mapDispatchToProps = dispatch => ({
    getBetshops: nextPage => {
        dispatch(getBetshops(nextPage));
    },
    setBetshopsSorting: sorting => {
        dispatch(setBetshopsSorting(sorting));
    },
    setBetshopsFilters: filters => {
        dispatch(setBetshopsFilters(filters));
    },
    setBetshopBalance: wallets => {
        dispatch(setBetshopBalance(wallets));
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.betshops.isLoading,
        betshops: state.betshops.betshops,
        total: state.betshops.total,
        sorting: state.betshops.sorting,
        filters: state.betshops.filters,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        userInfo: state.profile.userInfo
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BetshopsComponent);
