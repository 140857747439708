import React from "react";

import SystemCurrenciesComponent from "pages/settings/systemCurrencies/systemCurrencies.component";

import withPermission from "hocs/withPermission";
import withAuth from "hocs/withAuth";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const SystemCurrenciesRoute = () => {
    return <SystemCurrenciesComponent />;
};

export default withPermission(
    withAuth(SystemCurrenciesRoute),
    {
        resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
