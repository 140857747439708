import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { setBetHistoryActionBefore, setBetHistoryActionFinished } from "./betHistory.action";

import {
    SET_AGGREGATED_BET_HISTORY,
    SET_AGGREGATED_BET_HISTORY_SORTING,
    SET_AGGREGATED_BET_HISTORY_FILTERS,
    SET_AGGREGATED_BET_HISTORY_LOADING,
    SET_AGGREGATED_BETS_TOTALS, SET_AGGREGATED_BETS_TOTALS_BEFORE, SET_AGGREGATED_BETS_TOTALS_FINISH
} from "../../../actionTypes";

const setAggregatedBetHistoryLoading = loading => ({
    type: SET_AGGREGATED_BET_HISTORY_LOADING,
    payload: { loading },
});

const setAggregatedBetHistory = (bets, add) => ({
    type: SET_AGGREGATED_BET_HISTORY,
    payload: { bets, add },
});

export const setAggregatedBetHistorySorting = sorting => ({
    type: SET_AGGREGATED_BET_HISTORY_SORTING,
    payload: { sorting },
});

export const setAggregatedBetHistoryFilters = filters => ({
    type: SET_AGGREGATED_BET_HISTORY_FILTERS,
    payload: { filters },
});


export const getAggregatedBetHistory = nextPage => {
    return (dispatch, getState) => {

        const betHistory = getState().betHistory.online.aggregated;

        const filters = { ... betHistory.filters };
        const sorting = { ... betHistory.sorting };
        const total = betHistory.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;

        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }

        dispatch(setBetHistoryActionBefore());
        dispatch(setAggregatedBetHistoryLoading(true))
        return axios({
            url: ApiUrls.AGGREGATED_BET_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                dispatch(setAggregatedBetHistory(bets, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAggregatedBetHistorySorting({ ...sorting, page: page }));
                dispatch(setBetHistoryActionFinished());
                dispatch(setAggregatedBetHistoryLoading(false))
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
                dispatch(setAggregatedBetHistoryLoading(false))
            });
    }
}

export const setAggregatedBetTotals = totals => ({
    type: SET_AGGREGATED_BETS_TOTALS,
    payload: { totals },
});

export const setAggregatedBetTotalsBefore = () => ({
    type: SET_AGGREGATED_BETS_TOTALS_BEFORE,
})

export const setAggregatedBetTotalsFinish = () => ({
    type: SET_AGGREGATED_BETS_TOTALS_FINISH,
})

export const getAggregatedBetTotals = () => {
    return (dispatch, getState) => {

        const betHistory = getState().betHistory.online.aggregated;

        const filters = { ... betHistory.filters };

        let params = {
            ...filters
        };

        dispatch(setAggregatedBetTotalsBefore());

        return axios({
            url: ApiUrls.AGGREGATED_BETS_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: totals } }) => {
                dispatch(setAggregatedBetTotals(totals));
                dispatch(setAggregatedBetTotalsFinish());
            })
            .catch((ex) => {

            });
    }
}
