import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setBetshopsActionBefore, setBetshopsActionFinished, setBetshopsSaveActionBefore, setBetshopsSaveActionFinished } from './betshops.action';

import { SET_BETSHOP_IP_ACCESS_RULE } from "../../../../actionTypes";

const setBetshopIPAccessRule = ipAccess => ({
    type: SET_BETSHOP_IP_ACCESS_RULE,
    payload: { ipAccess }
})

export const getBetshopIPAccessRule = id => {
    return dispatch => {
        dispatch(setBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_IP_ACCESS_RULE,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: ipAccess}}) => {
            dispatch(setBetshopIPAccessRule(ipAccess))
            dispatch(setBetshopsActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsActionFinished());
        })
    }
}

export const addBetshopAllowedIP = (allowedIp, onSuccess) => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_BETSHOP_ALLOWED_IP,
            method: Methods.POST,
            data: {
                ...allowedIp
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBetshopIPAccessRule(data.value));
                onSuccess && onSuccess(data.value)
            } 
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}

export const deleteBetshopAllowedIP = (id, ipId) => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_BETSHOP_ALLOWED_IP,
            method: Methods.DELETE,
            data: {
                id, ipId
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getBetshopIPAccessRule(id));
            } 
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}

export const changeBetshopIPAccessRuleState = (id, enabled) => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_IP_ACCESS_RULE_STATE,
            method: Methods.POST,
            data: {
                id, enabled
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBetshopIPAccessRule(data.value));
            } 
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}
