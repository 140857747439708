import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import Input from 'components/common/input';
import DateRangePicker from "components/common/dateRangePicker";

import { getBetshops, setBetshopsFilters } from "store/actions/dashboard/retail/betshops/betshops.action";

import { FILTER_USER_STATE, FILTER_TEST_STATE } from "constants/filter.constants";
import { USER_TYPE } from "constants/user.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { getPossibleParentsName } from "utils/common";

/** Betshops Page Filters Component */
const Filters = ({
    setBetshopsFilters,
    getBetshops,
    filters,
    globalProjectId,
}) => {
    const { t } = useTranslation();

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        actionParams: { userRole: USER_TYPE.AGENT, excludeAgent: false },
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={getBetshops}
            setFiltersFn={setBetshopsFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false },
            ]}
            searchFieldName="nameOrId"
            onInit={() => {
                getAgentNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.betshops.belongsTo')}
                        name="parentUserNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.belongsTo')}`}
                            items={getPossibleParentsName({
                                globalProjectId,
                                agentNames,
                            })}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.betshops.code')}
                        name="code"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.code')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.code')}`}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name="state"
                    >
                        <Select
                            options={[
                                { value: FILTER_USER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_USER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: FILTER_USER_STATE.BLOCKED, text: t('backoffice.common.blocked') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dataType')}
                        name="isTesting"
                    >
                        <Select
                            options={[
                                { value: FILTER_TEST_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_TEST_STATE.TESTING, text: t('backoffice.common.testing') },
                                { value: FILTER_TEST_STATE.NOTTESTING, text: t('backoffice.common.notTesting') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.dataType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get betshops */
    getBetshops: PropTypes.func,
    /** Redux action to set betshops filters */
    setBetshopsFilters: PropTypes.func,
    /** Redux state property, betshops filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getBetshops: () => {
            dispatch(getBetshops())
        },
        setBetshopsFilters: filters => {
            dispatch(setBetshopsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.betshops.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
