import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useParams } from "react-router-dom";

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from './sections/general';
import DepositConfigComponent from './sections/deposit';
import WithdrawalConfigComponent from './sections/withdrawal';

import { getAgentBankGeneralInfo } from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action';

/** Agent Bank Edit Component */
const BankEditComponent = ({
    getAgentBankGeneralInfo
}) => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const searchParams = useParams()

    /** Load general info fir bank */
    useEffect(() => {
        getAgentBankGeneralInfo( (new URLSearchParams(search)).get("bankId"), searchParams.id );
    }, [])

    const items = [
        {
            title: t("backoffice.payments.general"),
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.payments.depositDetails"),
            component: <DepositConfigComponent />,
        },
        {
            title: t("backoffice.payments.withdrawalDetails"),
            component: <WithdrawalConfigComponent />,
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isDeepView={true}
            withPaddingInMobile={true}
        />
    )
}

/** BankEditComponent propTypes
    * PropTypes
*/
BankEditComponent.propTypes = {
    /** Redux action to get agent payment bank general info */
    getAgentBankGeneralInfo: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAgentBankGeneralInfo: (bankId, agentId) => {
            dispatch(getAgentBankGeneralInfo(bankId, agentId));
        }
    }
)

export default connect(null, mapDispatchToProps)(BankEditComponent);
