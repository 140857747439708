import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import {connect} from "react-redux";

import { Col, Form, Row, Spin } from "antd";

import TabFormDashboardLayout from "components/layouts/tab/form";
import NumericInput from "components/common/numericInput";

import {
    getProjectAgentAdministrativeFee,
    saveProjectAgentAdministrativeFee
} from "store/actions/dashboard/projects/administartiveFee.action";

import { isFormChanged } from "utils/form";

const AdministrativeFee = ({
   isLoading,
   isSaving,
   administrativeFee,
   getProjectAgentAdministrativeFee,
   saveProjectAgentAdministrativeFee,
   onTabChange
}) => {
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();

    const { t } = useTranslation();

    useEffect(() => {
        getProjectAgentAdministrativeFee();
    }, []);

    useEffect(() => {
        formInstance.setFieldsValue(administrativeFee);
    }, [administrativeFee]);

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched]);

    const validateMinMax = (value) => {
        if (value === '') {
            return Promise.resolve();
        }

        if (Number(value) >= 0 && Number(value) <= 100) {
            return Promise.resolve();
        }

        return Promise.reject(
            t("backoffice.validation.mustBeBetween")
                .replace("%X%", 0)
                .replace("%Y%", 100)
        );
    }

    const handleFormChange = () => {
        setIsFormTouched(isFormChanged(formInstance.getFieldsValue(), administrativeFee));
    }

    const handleSave = () => {
        formInstance.validateFields().then(values => {
            saveProjectAgentAdministrativeFee(values);
            setIsFormTouched(false);
        })
    }

    return (
        <TabFormDashboardLayout
            className="rt--project-agent-system-administrative-fee-layout"
            fullHeight
            buttons={[
                {
                    type: "primary",
                    onClick: handleSave,
                    text: t("backoffice.common.save"),
                    enabled: true,
                    loading: isSaving,
                    disabled: !isFormTouched
                }
            ]}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Row>
                    <Col lg={6} md={12} xs={24}>
                        <h4
                            className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'
                        >
                            {t("backoffice.projects.administrativeFee")}
                        </h4>

                        <div className='rt--form-section-hint rt--mb-12'>
                            {t("backoffice.projects.administrativeFeeHint")}
                        </div>


                        <Form
                            colon={false}
                            form={formInstance}
                            requiredMark={false}
                            layout="vertical"
                            onChange={handleFormChange}
                        >
                            <Form.Item
                                name='casinoFee'
                                label={t("backoffice.projects.casinoFee")}
                                className='rt--general-form-item'
                                rules={[
                                    {
                                        required: true,
                                        message: t('backoffice.validation.fieldRequired')
                                    },
                                    () => ({
                                        validator: (_, value) => validateMinMax(value)
                                    })
                                ]}
                            >
                                <NumericInput suffix="%" />
                            </Form.Item>

                            <Form.Item
                                name='sportFee'
                                label={t("backoffice.projects.sportFee")}
                                className='rt--general-form-item'
                                rules={[
                                    {
                                        required: true,
                                        message: t('backoffice.validation.fieldRequired')
                                    },
                                    () => ({
                                        validator: (_, value) => validateMinMax(value)
                                    })
                                ]}
                            >
                                <NumericInput suffix="%" />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        </TabFormDashboardLayout>
    );
};

AdministrativeFee.propTypes = {
    isLoading: PropTypes.bool,
    isSaving: PropTypes.bool,
    administrativeFee: PropTypes.object,
    getProjectAgentAdministrativeFee: PropTypes.func,
}

const mapStateToProps = state => ({
    isLoading: state.projects.isLoading,
    isSaving: state.projects.isSaving,
    administrativeFee: state.projects.edit.agentSystem.administrativeFee,
})

const mapDispatchToProps = dispatch => ({
    getProjectAgentAdministrativeFee: () => {
        dispatch(getProjectAgentAdministrativeFee());
    },
    saveProjectAgentAdministrativeFee: (data) => {
        dispatch(saveProjectAgentAdministrativeFee(data));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AdministrativeFee);
