import React, {useEffect, useMemo} from 'react';

import TabTableDashboardLayout from "components/layouts/tab/table";
import {isFranchisingMode, isMobile} from "utils/common";
import UsersTabMobile from "pages/dashboard/tabs/usersTab/mobile";
import UsersTabDesktop from "pages/dashboard/tabs/usersTab/desktop";
import UsersLeaderboardFinancial from "pages/dashboard/tabs/usersTab/components/usersLeaderboardFinancial";
import usersLeaderboardOperational from "pages/dashboard/tabs/usersTab/components/usersLeaderboardOperational";
import {
    AGENT_LEADERBOARD_DATA, AGENT_PIE_CHARTS_DATA, DATA_TYPES, ENTITY_TYPE,
    LEADERBOARD_TYPES,
    OPERATIONAL_FAKE_VALUES,
    RETAIL_LEADERBOARD_DATA, RETAIL_PIE_CHARTS_DATA
} from "pages/dashboard/constants";
import {useTranslation} from "react-i18next";
import UsersLeaderboardOperational from "pages/dashboard/tabs/usersTab/components/usersLeaderboardOperational";
import {useDashboardContext} from "pages/dashboard/contexts/dashboardContext";
import getDataTypeOptions from "pages/dashboard/helpers/getDataTypeOptions";
import useProjectType from "hooks/useProjectType";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";
import {FILTER_TYPE} from "constants/common.constants";

const UsersTab = ({ filters, onFiltersChange, updateLastUpdatedDate, currencyOptions }) => {
    const { state: { leaderboardTab }, setLeaderboardTab } = useDashboardContext();

    const { hasLiteMode, hasRetail } = useProjectType();

    const { t } = useTranslation();

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;

    const leaderboardFilters = useMemo(() => ({
        ...filters.global,
        ...filters.leaderboard,
    }), [filters]);

    const pieChartFilters = useMemo(() => ({
        ...filters.global
    }), [filters.global]);

    const dataTypeOptions = useMemo(() => (
        getDataTypeOptions(leaderboardTab, hasLiteMode, hasRetail)
    ), [leaderboardTab, hasLiteMode, hasRetail])

    const items = useMemo(() => {
        let leaderboards = AGENT_LEADERBOARD_DATA;

        if (isFranchisingMode()) {
            leaderboards = RETAIL_LEADERBOARD_DATA;

            if (isBetShopOwner) {
                leaderboards = leaderboards.filter(leaderboard =>
                    leaderboard.id !== LEADERBOARD_TYPES.AGENT
                );
            }

            if (leaderboardFilters.betShopNameOrId) {
                leaderboards = leaderboards.filter(leaderboard =>
                    ![LEADERBOARD_TYPES.BETSHOP, LEADERBOARD_TYPES.AGENT].includes(leaderboard.id)
                );
            }
        }

        const isOperational = (
            leaderboardTab === LEADERBOARD_TYPES.AGENT &&
            Object.entries(OPERATIONAL_FAKE_VALUES).some(([_, value]) => leaderboardFilters.dataType === value)
        );

        return leaderboards.map(leaderboard => ({
            key: leaderboard.id,
            label: t(leaderboard.label),
            children: isOperational
                ? (
                    <UsersLeaderboardOperational
                        filters={leaderboardFilters}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                    />
                )
                : (
                    <UsersLeaderboardFinancial
                        endpoint={leaderboard.endpoint}
                        filters={leaderboardFilters}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                    />
                )
        }))
    }, [leaderboardFilters, leaderboardTab, t, updateLastUpdatedDate]);

    const pieChartItems = useMemo(() => {
        let pieCharts = AGENT_PIE_CHARTS_DATA;

        if (isFranchisingMode()) {
            pieCharts = RETAIL_PIE_CHARTS_DATA;

            if (isBetShopOwner) {
                pieCharts = pieCharts.filter(pieChart => pieChart.entityType !== ENTITY_TYPE.AGENT);
            }
        } else {
            if (!hasRetail) {
                pieCharts = pieCharts.filter(pieChart => pieChart.entityType !== ENTITY_TYPE.BETSHOP);
            }
        }

        return pieCharts;
    }, [hasRetail])

    const handleFiltersChange = (value) => {
        if (
            isFranchisingMode() &&
            value.betShopNameOrId
        ) {
            setLeaderboardTab(LEADERBOARD_TYPES.CASHIER);
        }

        if (value.betShopNameOrId) {
            onFiltersChange('global', { filterType: FILTER_TYPE.NETWORK });
        }

        onFiltersChange('leaderboard', value);
    }

    const handleTabChange = (key) => {
        setLeaderboardTab(key);

        const resetFilters = {};

        const currentOptions = getDataTypeOptions(key, hasLiteMode, hasRetail);

        if (!currentOptions.some(option => option.value === leaderboardFilters.dataType)) {
            resetFilters.dataType = DATA_TYPES.GGR;
        }

        if (key !== LEADERBOARD_TYPES.CASHIER) {
            resetFilters.betShopNameOrId = "";
        }

        if (Object.keys(resetFilters).length) {
            handleFiltersChange(resetFilters);
        }
    }

    return (
        <TabTableDashboardLayout
            headerBorder={false}
        >
            {isMobile()
                ? (
                    <UsersTabMobile
                        items={items}
                        pieChartItems={pieChartItems}
                        leaderboardFilters={leaderboardFilters}
                        pieChartFilters={pieChartFilters}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                        onFiltersChange={handleFiltersChange}
                        onTabChange={handleTabChange}
                    />
                )
                : (
                    <UsersTabDesktop
                        items={items}
                        pieChartItems={pieChartItems}
                        dataTypeOptions={dataTypeOptions}
                        currencyOptions={currencyOptions}
                        leaderboardFilters={leaderboardFilters}
                        pieChartFilters={pieChartFilters}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                        onFiltersChange={handleFiltersChange}
                        onTabChange={handleTabChange}
                    />
                )
            }
        </TabTableDashboardLayout>
    );
};

export default UsersTab;
