import React from 'react';

import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Col, Form, Row} from "antd";

import DatePicker from "components/common/datePicker";
import TimePicker from "components/common/timepicker";
import FiltersWrapper from "components/common/filters";

import {
    getPlayerTransactionsHistory,
    setPlayerTransactionsHistoryFilters
} from "store/actions/dashboard/players/playerTransactionsHistory";

import useDate from "hooks/useDate";

import {isMobile} from "utils/common";

const Filters = ({
    filters,
    getPlayerTransactionsHistory,
    setPlayerTransactionsHistoryFilters,
}) => {
    const { t } = useTranslation();
    const { dateService } = useDate();
    const searchParams = useParams();

    return (
        <FiltersWrapper
            filters={filters}
            loadFn={() => getPlayerTransactionsHistory(searchParams.id)}
            setFiltersFn={setPlayerTransactionsHistoryFilters}
        >
            <Row gutter={[16, 0]}>
                <Col span={12} >
                    <Form.Item
                        label={t('backoffice.players.dateAndTime')}
                        name="day"
                    >
                        <DatePicker
                            className="rt--datepicker"
                            popupClassName='rt--datepicker-popup'
                            format={`${dateService.getFormat(false)}`}
                            disabledDate={d => !dateService.isInRange(d, dateService.daysAgo(90), dateService.endOfDay() )}
                            showToday={false}
                            showNow={false}
                            allowClear={false}
                            placeholder={t('backoffice.common.selectDate')}
                            suffixIcon={<i className='icon-date rt--font-bigest' />}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            inputReadOnly={isMobile()}
                        />
                    </Form.Item>
                </Col>

                <Col span={12} >
                    <Form.Item
                        label=" "
                        name="time"
                        className='rt--form-item-without-label'
                    >
                        <TimePicker.RangePicker
                            format='HH:mm'
                            allowClear={false}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            className='rt--timepicker'
                            popupClassName='rt--timepicker-dropdown'
                            separator={<div className='rt--timepicker-separator'/>}
                            placement="bottomRight"
                            showNow={false}
                            inputReadOnly={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    );
};

const mapStateToProps = state => ({
    filters: state.players.edit.playerTransactionsHistory.filters,
})

const mapDispatchToProps = dispatch => ({
    getPlayerTransactionsHistory: (id) => {
        dispatch(getPlayerTransactionsHistory(id))
    },

    setPlayerTransactionsHistoryFilters: filters => {
        dispatch(setPlayerTransactionsHistoryFilters(filters))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
