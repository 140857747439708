import React from 'react';

export const tableColumns = [
    {
        title: "backoffice.betshops.ipAddress",
        dataIndex: "from",
        showOnlyValue: true,
        mobileLevel: 1,
        render: (value, record) => value + (record.to ? " - " + record.to : ""),
        mobileRender: (value, record) => (
            <span className="rt--font-normal rt--font-extra-bold">
                {value + (record.to ? " - " + record.to : "")}
            </span>
        ),
    },
    {
        title: "backoffice.betshops.addedAt",
        dataIndex: "addedAt",
        isDateTime: true,
        showOnlyValue: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.betshops.addedBy",
        dataIndex: "addedBy",
        alwaysVisible: true,
    }
];