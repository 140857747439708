//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";

//#endregion

//#region actions
import {
    getCashierPerformanceReport
} from 'store/actions/dashboard/reports/cashierPerformanceReport.action';
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import Paths from 'constants/path.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { FILTER_TYPE } from 'constants/common.constants';
import { CASHIER_PERFORMANCE_REPORT } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import cashierPerformanceReportType from 'types/reports/cashierPerformanceReport.type';
//#endregion

const TABLE_ROW_UNIQUE_KEY = "cashierId";

/** Cashier Performance Report Page Component */
const CashierPerformanceReport = ({
    isLoading,
    report,
    getCashierPerformanceReport
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const searchParams = useParams();
    const { search } = useLocation();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: CASHIER_PERFORMANCE_REPORT });

    let periodFrom = null;
    let periodTo = null;
    try{
        const data = JSON.parse(atob((new URLSearchParams(search)).get("data")));
        periodFrom = data.from;
        periodTo = data.to;
    }catch(ex){
        console.log(ex)
    }

    const loadCashierPerformanceReport = () => {
        getCashierPerformanceReport(searchParams.id, periodFrom, periodTo)
    }

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE,
        action: PERMISSION_ACTION.EXPORT
    })

    //#endregion


    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                isCashierReport: true,
                formatAmount
            }
        });
    }, [includedColumns, formatAmount])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.betshopPerformanceReport'), path: Paths.REPORTS_BETSHOP_PERFORMANCE_REPORT },
                { title: searchParams.id || "Betshop" }
            ]
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.cashierPerformanceReport"),
            url: `${ApiUrls.EXPORT_CASHIER_PERFORMANCE_REPORT}?BetShopNameOrId=${searchParams.id}&From=${periodFrom}&To=${periodTo}&FilterType=${FILTER_TYPE.NETWORK}`,
            filters: {},
            columns: columnsForExport
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={report}
                loadFn={loadCashierPerformanceReport}
                noPagination={true}
                enableReload={true}
                uniqueKey={TABLE_ROW_UNIQUE_KEY}
            />
        </MainDashboardLayout>
    );
}

/** CashierPerformanceReport propTypes
 * PropTypes
*/
CashierPerformanceReport.propTypes = {
    /** Redux action to get cashier performance report */
    getCashierPerformanceReport: PropTypes.func,
    /** Redux state property, is true when loading betshop performance report */
    isLoading: PropTypes.bool,
    /** Redux state property, represents the betshop performance report  */
    report: PropTypes.arrayOf(cashierPerformanceReportType)
};

const mapDispatchToProps = dispatch => ({
    getCashierPerformanceReport: (betShopNameOrId, from, to) => {
        dispatch(getCashierPerformanceReport(betShopNameOrId, from, to));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.cashierPerformanceReport.isLoading,
        report: state.cashierPerformanceReport.report,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CashierPerformanceReport);
