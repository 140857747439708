import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { getErrors } from "../errors/errors.action";

import dateService from "utils/date"

import {
    SET_MONITORING_ACTION_BEFORE,
    SET_MONITORING_ACTION_FINISH,
    SET_MONITORING_SERVICE_ACTION_BEFORE,
    SET_MONITORING_SERVICE_ACTION_FINISH,
    SET_MONITORING_SERVICES,
    SET_MONITORING_SERVICE
} from "../../../../actionTypes";

const setMonitoringActionBefore = () => ({
    type: SET_MONITORING_ACTION_BEFORE
});

const setMonitoringActionFinished = () => ({
    type: SET_MONITORING_ACTION_FINISH
});

const setMonitoringServiceActionBefore = () => ({
    type: SET_MONITORING_SERVICE_ACTION_BEFORE
});

const setMonitoringServiceActionFinished = () => ({
    type: SET_MONITORING_SERVICE_ACTION_FINISH
});

const setMonitoringService = service => ({
    type: SET_MONITORING_SERVICE,
    payload: { service }
})

const setMonitoringServices = services => ({
    type: SET_MONITORING_SERVICES,
    payload: { services }
})

export const getMonitoringServices = () => {
    return dispatch => {
        dispatch(setMonitoringActionBefore());
        return axios({
            url: ApiUrls.GET_MONITORING_ALL,
            method: Methods.GET
        })
        .then(({data : {value: services}}) => {
            dispatch(setMonitoringServices(services))
            dispatch(setMonitoringActionFinished());
        })
        .catch(() => {
            dispatch(setMonitoringActionFinished());
        })
    }
}

export const getMonitoringService = id => {
    return dispatch => {
        dispatch(setMonitoringServiceActionBefore());
        return axios({
            url: ApiUrls.GET_MONITORING,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: service}}) => {
            dispatch(setMonitoringService(service))
            dispatch(setMonitoringServiceActionFinished());
        })
        .catch(() => {
            dispatch(setMonitoringServiceActionFinished());
        })
    }
}

export const getServiceErrors = name => {
    return dispatch => {
        dispatch(getErrors(undefined, {controller: name, year: dateService.getCurrentYMD().year, month: dateService.getCurrentYMD().month}))
    }
}