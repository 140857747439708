import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { TRANSFER_ENTITY_TYPE, TRANSFER_OPERATION_GROUP } from '../../constants';

import useTransfer from '../../hooks/useTransfer';

const entityTypeIcons = {
    [TRANSFER_ENTITY_TYPE.AGENT]: "icon-agent",
    [TRANSFER_ENTITY_TYPE.PLAYER]: "icon-player",
    [TRANSFER_ENTITY_TYPE.BETSHOP]: "icon-retail"
}

const TransferEntityType = ({
    onClick,
    entityTypes
}) => {
    const { t } = useTranslation();

    const { transferData } = useTransfer();

    const { group } = transferData || {};

    return (
        <div className='rt--transfer rt--flex rt--align-center rt--justify-center'>
            {
                entityTypes.map(entityType => (
                    <div
                        className='rt--transfer-item rt--transfer-item-small rt--flex rt--flex-col rt--align-center rt--justify-center rt--mr-16 rt--pl-16 rt--pr-16'
                        onClick={() => onClick(entityType)}
                        key={entityType}
                    >
                        <i className={entityTypeIcons[entityType]} />
                        <b className='rt--title rt--pt-16 rt--font-normal rt--font-bold'>
                            {
                                entityType === TRANSFER_ENTITY_TYPE.AGENT ?
                                    group === TRANSFER_OPERATION_GROUP.WITHDRAWAL ? t("backoffice.wallet.fromAgent") : t("backoffice.wallet.toAgent") : 
                                entityType === TRANSFER_ENTITY_TYPE.PLAYER ?
                                    group === TRANSFER_OPERATION_GROUP.WITHDRAWAL ? t("backoffice.wallet.fromPlayer") : t("backoffice.wallet.toPlayer") :
                                entityType === TRANSFER_ENTITY_TYPE.BETSHOP ?
                                    t("backoffice.wallet.toBetshop") : null
                            }
                        </b>
                    </div>
                ))
            }

        </div>
    )
}   

/** TransferEntityType propTypes
    * PropTypes
*/
TransferEntityType.propTypes = {
    /** Entity Types */
    entityTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TRANSFER_ENTITY_TYPE))),
    /** Handler for click */
    onClick: PropTypes.func
}

export default TransferEntityType;