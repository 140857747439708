import PropTypes from 'prop-types';

import { USER_STATE } from "constants/user.constants";

export default PropTypes.shape({
    id: PropTypes.number,
    externalId: PropTypes.string,
    userName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string
})