import React from "react";

import { makeTransactionText } from "utils/wallet";

import { WALLET_OPERATION_TYPE } from "constants/wallet.constants";

export const getTableColumns = (additionalProps) => {
    const { formatAmount, hasLiteMode } = additionalProps;

    return [
        {
            title: "backoffice.reports.transactionGroupId",
            dataIndex: "groupId",
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.reports.date",
            dataIndex: "transactionTime",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.reports.amount",
            dataIndex: "transactionAmount",
            mobileLevel: 3,
            render: (value, record) => (
                    <span
                        className={
                            record.transactionType == WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_FROM_BETSHOP ||
                            record.transactionType == WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE ||
                            record.transactionType == WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_DEBT ? 'rt--table-withdrawal' : ''
                        }
                    >
                        {formatAmount(Math.abs(value), record.currencyCode, record.transactionTime)}
                    </span>
                )
        },
        ...(
            !hasLiteMode
                ? [{
                    title: "backoffice.reports.currency",
                    dataIndex: "currencyCode",
                    mobileLevel: 4,
                }]
                : []
        ),
        {
            title: "backoffice.reports.transactionType",
            dataIndex: "transactionType",
            mobileLevel: 5,
            render: (value) => makeTransactionText(value),
            tooltip: (value) => makeTransactionText(value),
        },
        {
            title: "backoffice.reports.participants",
            dataIndex: "participants",
            multi: true,
            disableExport: true,
            alwaysVisible: true,
            mobileLevel: 6,
        },
    ];
};
