import React from 'react';

import {useTranslation} from "react-i18next";

import Tabs from 'components/common/tabs';
import AgentPerformanceReportComponent from "pages/reports/performanceReport/sections/agent/agentPerformanceReport.component";
import PlayerPerformanceReportComponent from "pages/reports/performanceReport/sections/player/playerPerformanceReport.component";

import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";

const PerformanceReportComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t('backoffice.reports.agent'),
            permissions: [{ resource: PERMISSION_RESOURCE.REPORTS_AGENT_PERFORMANCE, action: PERMISSION_ACTION.VIEW }],
            component: <AgentPerformanceReportComponent />
        },
        {
            title: t('backoffice.reports.player'),
            permissions: [{ resource: PERMISSION_RESOURCE.REPORTS_PLAYER_PERFORMANCE, action: PERMISSION_ACTION.VIEW }],
            component: <PlayerPerformanceReportComponent />
        }
    ]

    return (
        <Tabs
            items={items}
            mainPage={[
                { title: t('backoffice.menu.performanceReport') }
            ]}
        />
    );
};

export default PerformanceReportComponent;
