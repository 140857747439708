import React from "react";
import i18n from "translations/config";

import Status from "components/common/status";

import { ONLINE_BET_TYPE } from "constants/bet.constants";
import { STATUS_TYPES } from "constants/status.constants";

const BET_TYPE_NAMES = {
    [ONLINE_BET_TYPE.BET]: "bet",
    [ONLINE_BET_TYPE.BONUS]: "bonus",
};

export const getTableColumns = (additionalProps) => {
    const {
        formatNumber
    } = additionalProps;

    return [
        {
            title: "backoffice.bets.betId",
            dataIndex: "id",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.bets.status",
            dataIndex: "state",
            mobileLevel: 4,
            render: (status) => <Status status={status} type={STATUS_TYPES.BET} />,
        },
        {
            title: "backoffice.bets.date",
            dataIndex: "betDate",
            isDateTime: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.bets.provider",
            dataIndex: "provider",
            mobileLevel: 3,
        },
        {
            title: "backoffice.bets.type",
            dataIndex: "type",
            render: (betType) =>
                Boolean(BET_TYPE_NAMES[betType])
                    ? i18n.t(`backoffice.bets.${BET_TYPE_NAMES[betType]}`)
                    : "-",
        },
        {
            title: "backoffice.bets.game",
            dataIndex: "game",
        },
        {
            title: "backoffice.bets.lastUpdateDate",
            dataIndex: "lastUpdateDate",
            isDateTime: true
        },

        {
            title: "backoffice.bets.amount",
            dataIndex: "amount",
            isNumeric: true,
            mobileLevel: 5,
            render: (value) => Number(value),
        },
        {
            title: "backoffice.bets.win",
            dataIndex: "winAmount",
            mobileLevel: 6,
            render: (value) => (Boolean(value) ? formatNumber(Number(value)) : "-"),
        },
    ]
};
