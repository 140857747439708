import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Spin, Switch } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Select from "components/common/select";

import { getCompanyTokenSettings, saveCompanyTokenSettings } from "store/actions/dashboard/companies/tokenSettings.action";

import companyTokenSettingsType from "types/company/tokenSettings.type";

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

const TOKEN_LIFETIME_POSSIBLE_VALUES = Array.from(Array(56).keys()).map(n => n + 5);

/** Company Edit Page Token Settings SubTab Component */
const TokenSettingsComponent = ({
    isLoading,
    isSaving,
    getCompanyTokenSettings,
    saveCompanyTokenSettings,
    tokenSettings,
    onTabChange
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Load company token settings */
    useEffect(() => {
        getCompanyTokenSettings()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...tokenSettings
        });
    }, [tokenSettings])

    /** Fires when form submitted
       * @function
       * @memberOf TokenSettingsComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                saveCompanyTokenSettings({
                    ...data
                })

                setIsFormTouched(false);
            }).catch(() => { })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        lifetimeMinutes: tokenSettings.lifetimeMinutes,
                        multideviceLogin: tokenSettings.multideviceLogin
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...tokenSettings }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} lg={6} xl={4}>
                            <Form.Item
                                label={t('backoffice.companies.tokenLifetime')}
                                name="lifetimeMinutes"
                                className={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        TOKEN_LIFETIME_POSSIBLE_VALUES.map(el => (
                                            { value: el, text: el + " " + t("backoffice.companies.min")}
                                        ))
                                    }
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.tokenLifetime")}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY }) }
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                                <Form.Item
                                    name="multideviceLogin"
                                    valuePropName="checked"
                                    className={'rt--form-item-without-margin' + (!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                >
                                    <Switch
                                        disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.companies.multideviceLogin')}</label>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** TokenSettingsComponent propTypes
 * PropTypes
*/
TokenSettingsComponent.propTypes = {
    /** Redux state property, is true when loading company token settings data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving company token settings data */
    isSaving: PropTypes.bool,
    /** Redux action to get company token settings */
    getCompanyTokenSettings: PropTypes.func,
    /** Redux action to save company token settings */
    saveCompanyTokenSettings: PropTypes.func,
    /** Redux state property, represents company token settings  */
    tokenSettings: companyTokenSettingsType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => (
    {
        getCompanyTokenSettings: () => {
            dispatch(getCompanyTokenSettings());
        },
        saveCompanyTokenSettings: rules => {
            dispatch(saveCompanyTokenSettings(rules));
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.companies.isLoading,
        isSaving: state.companies.isSaving,
        tokenSettings: state.companies.edit.tokenSettings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenSettingsComponent)