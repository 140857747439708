import PropTypes from "prop-types";

export default PropTypes.shape({
    id: PropTypes.string,
    playerId: PropTypes.number,
    playerUserName: PropTypes.string,
    currencyCode: PropTypes.string,
    betDate: PropTypes.string,
    lastUpdateDate: PropTypes.string,
    type: PropTypes.number,
    provider: PropTypes.string,
    game: PropTypes.string,
    amount: PropTypes.number,
    winAmount: PropTypes.number,
    state: PropTypes.number
});
