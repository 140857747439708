//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getUserPermissionGroups,
    deleteUserPermissionGroup
} from "store/actions/dashboard/userManagement/users/permissionGroups.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from "components/common/table";
import Notes from 'components/common/notes';
import GroupAddComponent from "./group-add.component";
import PendingRequestsNote from 'components/common/pendingRequestsNote';

//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import { PERMISSION_ACTION } from 'constants/permissions.constants';
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';
import { USER_ROLE, USER_TYPE } from 'constants/user.constants';
import { ADMIN_PERMISSION_GROUPS_EDIT } from 'constants/pageName.constants';
import { tableColumns } from './columns';
//#endregion

//#region types
import userPermissionGroupType from 'types/user/permissionGroup.type';
//#endregion

const ROW_UNIQUE_KEY_PROP = "name";

/** User Edit Page Permissions and Groups Tab Groups Sub Tab Component */
const GroupsComponent = ({
    getUserPermissionGroups,
    deleteUserPermissionGroup,
    isLoading,
    groups,
    userId,
    permissionResource,
    userType
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const userRole = getUser()?.role;

    /** Notes */
    const [notes, setNotes] = useState(null);

    // State to show/hide user add/edit popup
    const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({
        pageName: ADMIN_PERMISSION_GROUPS_EDIT
    });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = hasPermission({
        resource: permissionResource,
        action: PERMISSION_ACTION.CREATE
    })

    const hasDeletePermission = hasPermission({
        resource: permissionResource,
        action: PERMISSION_ACTION.DELETE
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddPopup = () => {
        setIsAddPopupVisible(true);
    }

    const hideAddPopup = () => {
        setIsAddPopupVisible(false);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            includedColumns,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission && searchParams.id !== userId) {
        tableRowActions.push({
            icon: "icon-trash",
            title: t('backoffice.common.delete'),
            confirmationTitle: t("backoffice.users.deleteGroup"),
            confirmationMessage: t("backoffice.users.doYouWanttoDeleteGroup"),
            showConfirmation: true,
            onClick: record => handleDelete(record.id),
        })
    }

    /** Actions */
    const handleDelete = id => {
        if (userRole !== USER_ROLE.ACCESS_MANAGER) {
            deleteUserPermissionGroup(
                searchParams.id,
                id
            )
        } else {
            setNotes({
                id
            })
        }
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "icon-plus",
            type: "primary",
            onClick: showAddPopup,
            text: t("backoffice.users.addGroup"),
            enabled: hasCreatePermission && userId !== searchParams.id
        }
    }

    //#endregion

    // Close user add/edit popup, after save
    useEffect(() => {
        hideAddPopup();
    }, [groups])
    // TODO: check unique key warning
    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
            extraContent={
                userType === USER_TYPE.ADMIN ? (
                    <PendingRequestsNote
                        objectId={searchParams.id}
                        actionTypes={[PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP, PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP]}
                        objectType={PERMISSION_REQUEST_OBJECT_TYPE.USER}
                        dependencies={[groups]}
                    />
                ) : undefined
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={groups}
                loadFn={() => getUserPermissionGroups(searchParams.id)}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                noPagination={true}
                disableFullView={true}
                smallInMobile={true}
                mobileLayoutVertical={true}
                actions={tableRowActions}
            />

            {isAddPopupVisible && <GroupAddComponent onClose={hideAddPopup} />}

            {
                notes && (
                    <Notes
                        onClose={() => setNotes(null)}
                        onSuccess={value => {
                            deleteUserPermissionGroup(
                                searchParams.id,
                                notes.id,
                                value
                            );
                        }}
                    />
                )
            }

        </SubTabTableDashboardLayout>
    )
}

/** GroupsComponent propTypes
    * PropTypes
*/
GroupsComponent.propTypes = {
    /** Redux action to get user permission groups */
    getUserPermissionGroups: PropTypes.func,
    /** Redux action to delete user permission group */
    deleteUserPermissionGroup: PropTypes.func,
    /** Redux state property, is true when loading user permission group */
    isLoading: PropTypes.bool,
    /** Redux state, represents the permission groups of current editing user */
    groups: PropTypes.arrayOf(userPermissionGroupType),
    /** Redux state property, current user id */
    userId: PropTypes.string,
    /** The permission resource */
    permissionResource: PropTypes.string,
    /** User type(agent/admin) */
    userType: PropTypes.oneOf([USER_TYPE.ADMIN, USER_TYPE.AGENT])
}

const mapDispatchToProps = dispatch => (
    {
        getUserPermissionGroups: id => {
            dispatch(getUserPermissionGroups(id));
        },

        deleteUserPermissionGroup: (id, groupId, note) => {
            dispatch(deleteUserPermissionGroup(id, groupId, note));
        }
    }
)

const mapStateToProps = state => {
    return {
        groups: state.users.edit.permissionGroups.groups,
        isLoading: state.users.isLoading,
        userId: state.profile.userInfo.id
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsComponent)