import { FILTER_TYPE } from "constants/common.constants"
import { LINE_CHART_AVAILABLE_COLOR } from "constants/lineChart.constants"
import PATHS from "constants/api.constants";
import {BETSLIP_FILTER_TYPE} from "constants/filter.constants";

export const STATES = {
    ACTIVE: "active",
    IN_PROGRESS: "inProgress",
    BLOCKED: "blocked",
    DELETED: "deleted",
    EXPIRED: "expired",
    LOCKED_2_FA: "locked2FA",
    LOCKED_LOGIN: "lockedLogin",
    PARTIALLY_BLOCK: "partiallyBlock",
    UNKNOWN: "unknown",
}

// Don't change the order
export const TIME_PERIOD = {
    TODAY: 1,
    YESTERDAY: 2,
    THIS_WEEK: 8,
    LAST_WEEK: 4,
    THIS_MONTH: 32,
    LAST_MONTH: 16,
    LAST_QUARTER: 64,
    THIS_YEAR: 128
}

export const ENTITY_TYPE = {
    AGENT: 1,
    PLAYER: 2,
    BETSHOP: 4,
    CASHIER: 8
}

export const TIME_PERIOD_TRANSLATION_SOURCE = {
    [TIME_PERIOD.TODAY]: "backoffice.dashboard.today",
    [TIME_PERIOD.YESTERDAY]: "backoffice.dashboard.yesterday",
    [TIME_PERIOD.THIS_WEEK]: "backoffice.dashboard.thisWeek",
    [TIME_PERIOD.LAST_WEEK]: "backoffice.dashboard.lastWeek",
    [TIME_PERIOD.THIS_MONTH]: "backoffice.dashboard.thisMonth",
    [TIME_PERIOD.LAST_MONTH]: "backoffice.dashboard.lastMonth",
    [TIME_PERIOD.LAST_QUARTER]: "backoffice.dashboard.lastQuarter",
    [TIME_PERIOD.THIS_YEAR]: "backoffice.dashboard.ytd",
}

export const FINANCIAL_DASHBOARD_FILTER_NAMES = {
    TIME_PERIOD: "timePeriod",
    FILTER_TYPE: "filterType",
    CURRENCY: "currencyCode",
}

export const FINANCIAL_TAB_NAMES = {
    GENERAL: "general",
    COMMISSION: "commission",
    TOP_USERS: "topUsers",
}

export const OPERATIONAL_DASHBOARD_FILTER_NAMES = {
    TIME_PERIOD: "timePeriod",
    FILTER_TYPE: "filterType",
    ENTITY_TYPE: "entityType",
}

export const OPERATIONAL_TAB_NAMES = {
    GENERAL: "general",
    REGISTRATIONS: "registrations",
    TOP_USERS: "topUsers",
}

export const DATA_TYPES = {
    GGR: 1,
    NGR: 2,
    TURNOVER: 4,
    COMMISSIONS: 8,
    DEPOSIT_AMOUNT: 16,
    WITHDRAW_AMOUNT: 32,
    NET_DEPOSIT: 64,
    COMMISSIONS_LITE: 128,
    REGISTERED_PLAYERS: 256
}

export const OPERATIONAL_FAKE_VALUES = {
    [ENTITY_TYPE.AGENT]: "agent",
    [ENTITY_TYPE.PLAYER]: "player",
    [ENTITY_TYPE.BETSHOP]: "betshop",
}

export const DATA_TYPE_TRANSLATION_SOURCE = {
    [DATA_TYPES.GGR]: "backoffice.dashboard.ggr",
    [DATA_TYPES.NGR]: "backoffice.dashboard.ngr",
    [DATA_TYPES.TURNOVER]: "backoffice.dashboard.turnover",
    [DATA_TYPES.COMMISSIONS]: "backoffice.dashboard.commissions",
    [DATA_TYPES.DEPOSIT_AMOUNT]: "backoffice.dashboard.deposit",
    [DATA_TYPES.WITHDRAW_AMOUNT]: "backoffice.dashboard.withdrawal",
    [DATA_TYPES.NET_DEPOSIT]: "backoffice.dashboard.netDeposit",
    [DATA_TYPES.REGISTERED_PLAYERS]: "backoffice.dashboard.registeredPlayers",
}

export const OPERATIONAL_DATA_TYPE_TRANSLATION_SOURCE = {
    [OPERATIONAL_FAKE_VALUES[ENTITY_TYPE.AGENT]]: "backoffice.dashboard.registeredAgents",
    [OPERATIONAL_FAKE_VALUES[ENTITY_TYPE.PLAYER]]: "backoffice.dashboard.registeredPlayers",
    [OPERATIONAL_FAKE_VALUES[ENTITY_TYPE.BETSHOP]]: "backoffice.dashboard.registeredBetshops",
}

export const TOTALS_TYPES = {
    BET: 'bets',
    PENDING: 'pendingBets',
    WIN: 'wonBets',
    PAYOUT: 'paidoutBets',
}

export const TOTALS_TYPES_MAP = {
    [TOTALS_TYPES.BET]: BETSLIP_FILTER_TYPE.BET_TIME,
    [TOTALS_TYPES.PENDING]: BETSLIP_FILTER_TYPE.BET_TIME,
    [TOTALS_TYPES.WIN]: BETSLIP_FILTER_TYPE.CALCULATION_TIME,
    [TOTALS_TYPES.PAYOUT]: BETSLIP_FILTER_TYPE.PAYOUT_TIME,
}

export const TYPE_TO_TOTAL_PROP_MAP = {
    [DATA_TYPES.GGR]: "amount",
    [DATA_TYPES.NGR]: "amount",
    [DATA_TYPES.TURNOVER]: "amount",
    [DATA_TYPES.DEPOSIT_AMOUNT]: "depositAmount",
    [DATA_TYPES.WITHDRAW_AMOUNT]: "withdrawAmount",
    [DATA_TYPES.COMMISSIONS]: "amount",
    [DATA_TYPES.NET_DEPOSIT]: "amount",
    [DATA_TYPES.REGISTERED_PLAYERS]: "count",
}

export const LEADERBOARD_TYPES = {
    AGENT: 'agent',
    PLAYER: 'player',
    BETSHOP: 'betshop',
    CASHIER: 'cashier',
}

export const RETAIL_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA = [
    {
        id: 1,
        dataType: DATA_TYPES.GGR,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.GGR],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: true
    },
    {
        id: 2,
        dataType: DATA_TYPES.NGR,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.NGR],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: true
    },
    {
        id: 3,
        dataType: DATA_TYPES.TURNOVER,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.TURNOVER],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: true
    },
    {
        id: 4,
        dataType: DATA_TYPES.DEPOSIT_AMOUNT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.DEPOSIT_AMOUNT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: true
    },
    {
        id: 5,
        dataType: DATA_TYPES.WITHDRAW_AMOUNT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.WITHDRAW_AMOUNT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: true
    },
    {
        id: 6,
        dataType: DATA_TYPES.NET_DEPOSIT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.NET_DEPOSIT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: true
    },
    {
        id: 7,
        dataType: DATA_TYPES.REGISTERED_PLAYERS,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.REGISTERED_PLAYERS],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: true
    }
]

export const AGENT_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA = [
    {
        id: 1,
        dataType: DATA_TYPES.GGR,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.GGR],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 2,
        dataType: DATA_TYPES.NGR,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.NGR],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 3,
        dataType: DATA_TYPES.TURNOVER,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.TURNOVER],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 4,
        dataType: DATA_TYPES.COMMISSIONS,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.COMMISSIONS],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.YELLOW,
        disableLegend: true
    },
    {
        id: 5,
        dataType: DATA_TYPES.NET_DEPOSIT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.NET_DEPOSIT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.PURPLE,
        disableLegend: false
    }
]

export const AGENT_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA_LITE = [
    {
        id: 1,
        dataType: DATA_TYPES.GGR,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.GGR],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 2,
        dataType: DATA_TYPES.TURNOVER,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.TURNOVER],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 3,
        dataType: DATA_TYPES.DEPOSIT_AMOUNT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.DEPOSIT_AMOUNT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 4,
        dataType: DATA_TYPES.WITHDRAW_AMOUNT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.WITHDRAW_AMOUNT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
]

export const AGENT_REGISTRATIONS_STATISTICS_LINE_CHARTS_DATA = [
    {
        id: 1,
        title: "backoffice.dashboard.player",
        entityType: ENTITY_TYPE.PLAYER,
    },
    {
        id: 2,
        title: "backoffice.dashboard.agent",
        entityType: ENTITY_TYPE.AGENT,
    },
    {
        id: 3,
        title: "backoffice.dashboard.betShop",
        onlyRetailEnabled: true,
        entityType: ENTITY_TYPE.BETSHOP,
    },
]

export const GENERAL_LINE_CHARTS_DATA = [
    {
        id: 1,
        colSizes: { xl: 12, lg: 12, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.GGR,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.GGR],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 2,
        colSizes: { xl: 12, lg: 12, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.NGR,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.NGR],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 3,
        colSizes: { xl: 24, lg: 24, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.TURNOVER,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.TURNOVER],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
];

export const GENERAL_LINE_CHARTS_DATA_LITE_MODE = [
    {
        id: 1,
        colSizes: { xl: 12, lg: 12, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.GGR,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.GGR],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_GREEN,
        disableLegend: false
    },
    {
        id: 2,
        colSizes: { xl: 12, lg: 12, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.TURNOVER,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.TURNOVER],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.PURPLE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false
    },
    {
        id: 3,
        colSizes: { xl: 12, lg: 12, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.DEPOSIT_AMOUNT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.DEPOSIT_AMOUNT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_BLUE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_GREEN,
        disableLegend: false,
        calcTotalAmount: (chartData) => (
            chartData.reduce((acc, data) => acc + data.depositAmount, 0)
        )
    },
    {
        id: 4,
        colSizes: { xl: 12, lg: 12, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.WITHDRAW_AMOUNT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.WITHDRAW_AMOUNT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.PURPLE,
        betshopLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA,
        disableLegend: false,
        calcTotalAmount: (chartData) => (
            chartData.reduce((acc, data) => acc + data.withdrawAmount, 0)
        )
    },
];

export const COMMISSION_LINE_CHARTS_DATA = [
    {
        id: 4,
        colSizes: { xl: 12, lg: 12, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.COMMISSIONS,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.COMMISSIONS],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.YELLOW,
        disableLegend: true
    },
    {
        id: 5,
        colSizes: { xl: 12, lg: 12, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.NET_DEPOSIT,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.NET_DEPOSIT],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.PURPLE,
        disableLegend: false
    },
]

export const COMMISSION_LINE_CHARTS_DATA_LITE_MODE = [
    {
        id: 4,
        colSizes: { xl: 24, lg: 24, md: 24, }, // only for desktop view
        dataType: DATA_TYPES.COMMISSIONS_LITE,
        title: DATA_TYPE_TRANSLATION_SOURCE[DATA_TYPES.COMMISSIONS],
        playerLineColor: LINE_CHART_AVAILABLE_COLOR.YELLOW,
        disableLegend: true
    }
]

export const AGENT_PIE_CHARTS_DATA = [
    {
        id: 1,
        entityType: ENTITY_TYPE.PLAYER,
    },
    {
        id: 2,
        entityType: ENTITY_TYPE.AGENT,
    },
    {
        id: 1,
        entityType: ENTITY_TYPE.BETSHOP,
    }
]

export const RETAIL_PIE_CHARTS_DATA = [
    {
        id: 1,
        entityType: ENTITY_TYPE.CASHIER,
    },
    {
        id: 2,
        entityType: ENTITY_TYPE.BETSHOP,
    },
    {
        id: 1,
        entityType: ENTITY_TYPE.AGENT,
    }
]

export const AGENT_LEADERBOARD_DATA = [
    {
        id: LEADERBOARD_TYPES.AGENT,
        label: 'backoffice.dashboard.topAgents',
        endpoint: PATHS.GET_DASHBOARD_FINANCES_TOP_AGENTS
    },
    {
        id: LEADERBOARD_TYPES.PLAYER,
        label: 'backoffice.dashboard.topPlayers',
        endpoint: PATHS.GET_DASHBOARD_TOP_PLAYERS
    }
]

export const RETAIL_LEADERBOARD_DATA = [
    {
        id: LEADERBOARD_TYPES.CASHIER,
        label: 'backoffice.dashboard.topCashiers',
        endpoint: PATHS.GET_DASHBOARD_TOP_CASHIERS
    },
    {
        id: LEADERBOARD_TYPES.BETSHOP,
        label: 'backoffice.dashboard.topBetshops',
        endpoint: PATHS.GET_DASHBOARD_TOP_BETSHOPS
    },
    {
        id: LEADERBOARD_TYPES.AGENT,
        label: 'backoffice.dashboard.topBetshopOwners',
        endpoint: PATHS.GET_DASHBOARD_TOP_BETSHOP_OWNERS
    }
]
