import PropTypes from 'prop-types';

import { 
    COMMISSION_PLAN_STRUCTURE, 
    COMMISSION_PLAN_SOURCE_TYPE,
    COMMISSION_SETTINGS_TYPE,
    COMMISSION_INCOME_TYPE
} from "constants/commission.constants";

import { BET_TYPE } from 'constants/bet.constants';

export default PropTypes.shape({
    type: PropTypes.oneOf(Object.values(COMMISSION_SETTINGS_TYPE)),
    incomeType: PropTypes.oneOf(Object.values(COMMISSION_INCOME_TYPE)),
    betType: PropTypes.oneOf(Object.values(BET_TYPE)),
    minOdds: PropTypes.number,
    minTotalOdds: PropTypes.number,
    minBetCount: PropTypes.number,
    sourceType: PropTypes.oneOf(Object.values(COMMISSION_PLAN_SOURCE_TYPE)),
    structure: PropTypes.oneOf(Object.values(COMMISSION_PLAN_STRUCTURE)),
    enabled: PropTypes.bool,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
    ranges: PropTypes.arrayOf(PropTypes.shape({
        from: PropTypes.number,
        to: PropTypes.number,
        value: PropTypes.number
    }))
})