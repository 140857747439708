import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { isMobile } from 'utils/common';

import Tooltip from "components/common/tooltip";

/** Multi items Component */
const MultiItems = ({
    items,
    tagTitle,
    showItemsCount = true
}) => {
    const { t } = useTranslation();
    if (items.length === 1) {
        if(!isMobile()){
            return items[0] ?? ""
        } else {
            return (
                <Tooltip
                    title={items[0]}
                    placement="bottom"
                    enableMobile={true}
                >
                    { items[0] }
                </Tooltip>
            )
        }
    }

    const tagTitleToRender = (
        Boolean(tagTitle)
            ? tagTitle
            : t("backoffice.common.items")
    )

    const tooltipTitle = (
        <ul className="rt--tooltip-list">
            {items.map((item) => (
                <li key={item}>
                    {item}
                </li>
            ))}
        </ul>
    )

    return (
        <Tooltip
            title={tooltipTitle}
            placement="bottom"
            enableMobile={true}
        >
            <div 
                className='rt--tag rt--flex rt--justify-between rt--align-center'
                onClick={e => e.stopPropagation()}
            >
                <span className='rt--title rt--font-normal rt--font-regular'>
                    {showItemsCount && (
                        <span className="rt--mr-4">
                            {items.length}
                        </span>
                    )}
                    {tagTitleToRender}
                </span>
            </div>
        </Tooltip>
    )
}

/** MultiItems propTypes
 * PropTypes
*/
MultiItems.propTypes = {
    items: PropTypes.array.isRequired,
    showItemsCount: PropTypes.bool,
    /** The tag shows the number of items and the title after that. */
    tagTitle: PropTypes.node,
};


export default MultiItems;