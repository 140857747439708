import React, { useState, Fragment, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Progress, Upload, Row, Col, message } from 'antd';

const { Dragger } = Upload;

import Tooltip from "components/common/tooltip";
import Confirmation from "components/common/confirmation";
import Modal from "components/common/modal";

import { doUploadRequest } from "utils/upload";
import {classNames, downloadURI, humanFileSize, isMobile} from 'utils/common';

import { IMAGE_TYPE } from 'constants/common.constants';

const defaultFileBuilder = (file) => {
    const url = window.URL.createObjectURL(file);

    return { url, previewUrl: url };
}

/** Image uploader Component */
const ImageUploaderComponent = ({
    uploadUrl,
    defaultFile,
    remove = false,
    data = {},
    fileBuilder = defaultFileBuilder,
    size,
    onSuccess,
    onFileReady,
    onBeforeUpload,
    onAfterUpload,
    updateProps,
    title,
    titleTooltip = "",
    disabled,
    onChange,
    acceptedFormats = [ IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG, IMAGE_TYPE.GIF, IMAGE_TYPE.WEBP, IMAGE_TYPE.SVG ],
    removeWithoutConfirmation = false,
    disableDownload = false,
    maxDimensions
}) => {
    const { t } = useTranslation();

    const [file, setFile] = useState(defaultFile || null);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const [showActionsModal, setShowActionsModal] = useState(false);

    const { infoText, extensions } = useMemo(() => {
        let infoText = "";
        const extensions = [];
        if(acceptedFormats.includes(IMAGE_TYPE.JPEG)){
            infoText += " JPG,";
            extensions.push("image/jpeg");
            extensions.push("image/jpg");
        }
        if(acceptedFormats.includes(IMAGE_TYPE.BMP)){
            infoText += " BMP,";
            extensions.push("image/bmp");
        }
        if(acceptedFormats.includes(IMAGE_TYPE.PNG)){
            infoText += " PNG,";
            extensions.push("image/png");
        }
        if(acceptedFormats.includes(IMAGE_TYPE.GIF)){
            infoText += " GIF,";
            extensions.push("image/gif");
        }
        if(acceptedFormats.includes(IMAGE_TYPE.WEBP)){
            infoText += " WEBP,";
            extensions.push("image/webp");
        }
        if(acceptedFormats.includes(IMAGE_TYPE.SVG)){
            infoText += " SVG,";
            extensions.push("image/svg");
            extensions.push("image/svg+xml");
        }
        infoText += " " + t("backoffice.common.maxSizeOf") + " " + humanFileSize(size);

        if(maxDimensions){
            infoText += "\n" + t("backoffice.common.maxDimensionsOf") + " " + `${maxDimensions.width}x${maxDimensions.height}`;
        }

        return { infoText, extensions }
    }, [size, acceptedFormats, maxDimensions]);

    /** Update data */
    useEffect(() => {
        setTimeout(() => {
            updateDefaultFile();
        }, 20)
    }, updateProps || [])

    const uploadCancelation = useRef(null);

    /** Fires on remove button click
         * @function
         * @param {object} e - event object
         * @memberOf ImageUploaderComponent
     */
    const handleRemove = e => {
        e.stopPropagation();
        setShowRemoveConfirmation(true);
    }

    /** Fires on download button click
         * @function
         * @param {object} e - event object
         * @memberOf ImageUploaderComponent
     */
    const handleDownload = e => {
        e.stopPropagation();
        const fileURL = file.previewUrl || file.url;
        const fileName = fileURL.split("/")[fileURL.split("/").length - 1];
        downloadURI(fileURL, fileName)
    }

    /** Function to remove the file
         * @function
         * @memberOf ImageUploaderComponent
     */
    const removeFile = (e) => {
        if (removeWithoutConfirmation) {
            e.stopPropagation();
        }
        setShowRemoveConfirmation(false);
        remove?.handler && remove.handler();
        setFile(null);
        onChange && onChange(undefined)
        setShowActionsModal(false)
    }

    /** Function to update default file
         * @function
         * @memberOf ImageUploaderComponent
     */
    const updateDefaultFile = () => {
        setFile(defaultFile);
    }

    /** Function to abort upload
         * @function
         * @param {object} e - event object
         * @memberOf ImageUploaderComponent
     */
    const abortUpload = e => {
        e.stopPropagation();
        if (file.status === "error") {
            setFile(null);
        } else {
            uploadCancelation.current.cancel();
        }
    }

    /** Function to reTry upload
         * @function
         * @param {object} e - event object
         * @memberOf ImageUploaderComponent
     */
    const retryUpload = e => {
        e.stopPropagation();
        uploadCancelation.current = doUploadRequest({
            action: uploadUrl,
            onSuccess: (data, file) => {
                const f = {};
                const builtFile = fileBuilder(data.value || file);
                f.name = file.name;
                f.status = data.status;
                f.fileObject = file.originFileObj;
                f.total = file.size;
                f.percent = 100;
                f.loaded = file.size
                f.url = builtFile.url;
                f.previewUrl = builtFile.previewUrl;
                f.dimensions = builtFile.dimensions;
                f.format = builtFile.format;
                setFile(f);
                onAfterUpload?.();
                onSuccess && onSuccess(data.value);
            },
            onError: () => {
                setFile({
                    ...file,
                    status: "error"
                });
                onAfterUpload?.();
            },
            onProgress: event => {
                const f = {};
                f.name = file.name;
                f.status = "uploading";
                f.fileObject = file.originFileObj;
                f.total = event.total;
                f.loaded = event.loaded;
                f.percent = (f.loaded * 100) / f.total;
                setFile(f);
            },
            file: file.fileObject,
            data
        })
    }

    /** Function which fires on upload start
         * @function
         * @param {object} file
         * @memberOf ImageUploaderComponent
     */
    const handleBeforeUpload = file => {
        onBeforeUpload?.();

        if (extensions && !extensions.includes(file.type)) {
            message.info(t('backoffice.errors.InvalidFile'));
            setFile(null);
            return false;
        } else if (size && size < file.size) {
            message.info(t('backoffice.errors.InvalidFileSize'));
            setFile(null);
            return false;
        }

        setFile({
            name: file.name,
            total: file.size,
            percent: 0,
            loaded: 0,
            status: "uploading",
            fileObject: file
        })
    }

    /** Function which fires on upload start
         * @function
         * @param {object} file
         * @memberOf ImageUploaderComponent
     */
     const handleBeforeAsyncUpload = file => {
        onBeforeUpload?.();

        return new Promise((resolve, reject) => {
            if (extensions && !extensions.includes(file.type)) {
                message.info(t('backoffice.errors.InvalidFile'));
                setFile(null);
                return reject();
            } else if (size && size < file.size) {
                message.info(t('backoffice.errors.InvalidFileSize'));
                setFile(null);
                return  reject();
            }

            const f = {
                name: file.name,
                total: file.size,
                percent: 0,
                loaded: 0,
                status: "uploading",
                fileObject: file
            }

            if(maxDimensions){
                const img = new Image();
                const objectUrl = window.URL.createObjectURL(file);
                img.onload = function () {
                    window.URL.revokeObjectURL(objectUrl);
                    if(this.width > maxDimensions.width || this.height > maxDimensions.height){
                        message.info(t('backoffice.errors.InvalidFileDimensions'));
                        return reject();
                    }
                    setFile(f)
                    return resolve(f)
                };
                img.src = objectUrl;
            } else {
                setFile(f)
                return resolve(f)
            }

        })
    }

    /** Function which fires during upload
         * @function
         * @param {object} info
         * @memberOf BannerUploadComponent
     */
    const onFileChange = info => {
        const uploadedFile = info?.fileList?.length > 0 ? info?.fileList[info?.fileList?.length - 1] : null;
        if (
            ( extensions && !extensions.includes(uploadedFile.type) ) ||
            ( size && size < uploadedFile.size )
        ) return;

        if(maxDimensions){
            const img = new Image();
            const objectUrl = window.URL.createObjectURL(uploadedFile?.originFileObj);
            img.onload = function () {
                window.URL.revokeObjectURL(objectUrl);
                if(this.width > maxDimensions.width || this.height > maxDimensions.height){
                    return;
                }
                handleFileChange(uploadedFile, info);
            };
            img.src = objectUrl;
        } else {
            handleFileChange(uploadedFile, info);
        }
    }

    const handleFileChange = (uploadedFile, info) => {
        const f = {};

        f.name = uploadedFile?.name ?? "";
        f.status = uploadUrl ? (uploadedFile?.status ?? "") : "done";
        f.fileObject = uploadedFile?.originFileObj ?? {};

        if (f.status === "uploading") {
            f.total = info?.event?.total ?? 0;
            f.loaded = info?.event?.loaded ?? 0;
            f.percent = f.total === 0 ? 0 : (f.loaded * 100) / f.total;
        } else if (f.status === "done") {
            f.total = uploadedFile?.size ?? 0;
            f.percent = 100;
            f.loaded = f.total;
            const responseValue = uploadedFile?.response?.value ?? "";
            const builtFile = fileBuilder(f.fileObject);
            f.url = builtFile.url;
            f.previewUrl = builtFile.previewUrl;
            f.dimensions = builtFile.dimensions;
            f.format = builtFile.format;
            onAfterUpload?.()
            onSuccess && onSuccess(uploadedFile?.response);
        } else if (f.status === "error") {
            f.total = file.total;
            f.percent = file.percent;
            f.loaded = file.loaded;
            onAfterUpload?.()
        }
        if (f.status) {
            setFile(f);
            onChange && onChange(f.fileObject);
        }
    }

    return (
        <Fragment>
            {
                (title || titleTooltip) && (
                    <div className="rt--flex rt--align-center rt--mb-8 rt--form-section-title-with-info">
                        {title && (
                            <h4 className='rt--form-section-title rt--title rt--mb-0 rt--mt-0 rt--font-bold rt--font-big'>
                                {title}
                            </h4>
                        )}

                        {titleTooltip && (
                            <Tooltip
                                title={titleTooltip}
                                trigger={["hover", "click"]}
                                placement="bottomLeft"
                                enableMobile={true}
                            >

                                <i className='icon-info rt--font-big rt--ml-4' />
                            </Tooltip>
                        )}
                    </div>
                )
            }

            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <div
                        className={classNames(
                            "rt--image-uploader",
                            ["uploading"].includes(file?.status) && "rt--image-uploader-uploading",
                            ["done"].includes(file?.status) && "rt--image-uploader-done",
                            ["error"].includes(file?.status) && "rt--image-uploader-error"
                        )}>
                        <Dragger
                            action={uploadUrl}
                            multiple={false}
                            onChange={onFileChange}
                            beforeUpload={uploadUrl ? handleBeforeUpload : handleBeforeAsyncUpload}
                            disabled={disabled}
                            showUploadList={{
                                showPreviewIcon: false,
                                showDownloadIcon: false,
                                showRemoveIcon: false
                            }}
                            customRequest={({ onSuccess, onError, onProgress, file }) => {
                                onFileReady && onFileReady(file);
                                if (uploadUrl) {
                                    uploadCancelation.current = doUploadRequest({ action: uploadUrl, onSuccess, onError, onProgress, file, data });
                                }
                            }}
                            accept={extensions?.toString()}
                        >
                            {
                                file ?
                                    file.status === "done" ? (
                                        <div className="rt--image-uploader-preview rt--flex rt--align-center rt--justify-center rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16">
                                            <img src={file.url} alt="banner" />
                                            {
                                                !isMobile() && (
                                                    <div className="rt--image-uploader-preview-actions rt--flex rt--align-center rt--justify-center">
                                                        {!disabled && (
                                                            <div className="rt--image-uploader-preview-actions-button rt--flex rt--align-center rt--justify-center rt--mr-16">
                                                                <i className='icon-edit rt--font-bigest'/>
                                                            </div>
                                                        )}
                                                        {
                                                            !disableDownload && !disabled && (
                                                                <div
                                                                    className="rt--image-uploader-preview-actions-button rt--flex rt--align-center rt--justify-center rt--mr-16"
                                                                    onClick={handleDownload}
                                                                >
                                                                    <i className='icon-download rt--font-bigest'/>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            remove && !disabled && (
                                                                <div
                                                                    className="rt--image-uploader-preview-actions-button rt--image-uploader-preview-actions-button-delete rt--flex rt--align-center rt--justify-center"
                                                                    onClick={removeWithoutConfirmation ? removeFile : handleRemove}>
                                                                    <i className='icon-trash rt--font-bigest'/>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                )
                                            }

                                        </div>) : file.status === "uploading" || file.status === "error" ? (
                                        <div
                                            className="rt--image-uploader-progress rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16">
                                            <div
                                                className="rt--image-uploader-progress-inner rt--flex rt--align-center rt--justify-center">
                                            {
                                                        file.status !== "error" && (
                                                            <span className='rt--image-uploader-progress-inner-text rt--title rt--font-small rt--font-bold'>{parseInt(file.percent) + "%"}</span>
                                                        )
                                                    }
                                                    <div className="rt--image-uploader-progress-inner-progress rt--ml-8 rt--mr-8 rt--flex rt--align-center">
                                                        <Progress
                                                            percent={file.percent}
                                                            size="small"
                                                            showInfo={false}
                                                            strokeWidth={4}
                                                            status={file.status === "error" ? "exception" : "normal"}
                                                        />
                                                    </div>
                                                    <div className="rt--image-uploader-progress-inner-actions rt--flex rt--align-center">
                                                        {
                                                            file.status === "error" && (
                                                                <div
                                                                    className="rt--image-uploader-progress-inner-actions-item rt--flex rt--align-center rt--justify-center"
                                                                    data-action="retry"
                                                                    onClick={retryUpload}
                                                                >
                                                                    <i className='icon-reload rt--font-bigest' />
                                                                </div>
                                                            )
                                                        }

                                                        <div
                                                            className="rt--image-uploader-progress-inner-actions-item rt--flex rt--align-center rt--justify-center"
                                                            data-action="abort"
                                                            onClick={abortUpload}
                                                        >
                                                            <i className='icon-cancel rt--font-bigest' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='rt--image-uploader-progress-text rt--title rt--font-normal rt--font-bold'>
                                                    {
                                                        file.status === "uploading" ? t("backoffice.common.uploadingFile") : t("backoffice.common.uploadingError")
                                                    }
                                                </span>
                                            </div>
                                        ) : null
                                    : (
                                        <div className='rt--flex rt--align-center rt--justify-center rt--image-uploader-empty'>
                                            <div className='rt--flex rt--flex-col rt--align-center rt--justify-center rt--mr-8'>
                                                <span className="rt--title rt--font-bold rt--font-normal rt--image-uploader-empty-text rt--mb-2">{t('backoffice.common.upload')}</span>
                                                <span className="rt--title rt--font-regular rt--font-normal rt--image-uploader-empty-sub-text">{infoText}</span>
                                                <span className="rt--title rt--font-bold rt--font-normal rt--image-uploader-empty-hovered">{
                                                    isMobile() ? t('backoffice.common.selectToUpload') : t('backoffice.common.selectOrDragToUpload')
                                                }</span>
                                                <span className="rt--title rt--font-bold rt--font-normal rt--image-uploader-empty-dragging">{t('backoffice.common.dragHere')}</span>
                                            </div>
                                            <i className='icon-upload rt--font-bigest' />
                                        </div>
                                    )

                            }
                        </Dragger>
                        {
                            (isMobile() && file?.status === "done") && (
                                <div className='rt--flex rt--align-center rt--flex-equal rt--image-uploader-actions'>
                                    <div
                                        className="rt--flex rt--align-center rt--justify-center"
                                        onClick={handleDownload}
                                    >
                                        <i className='icon-download rt--font-prebigest' />
                                    </div>
                                    <div className="rt--flex rt--align-center rt--justify-center rt--ml-2" onClick={() => setShowActionsModal(true)}>
                                        <i className='icon-edit rt--font-prebigest' />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
            {
                showRemoveConfirmation && (
                    <Confirmation
                        title={t('backoffice.common.delete')}
                        message={t('backoffice.common.uploadRemoveConfirmationMessage')}
                        onOk={removeFile}
                        onCancel={() => setShowRemoveConfirmation(false)}
                        isVisible={true}
                    />)
            }
            {
                showActionsModal && (
                    <Modal
                        title={`${t('backoffice.common.change')} ${title ?? ""}`}
                        cancelText={t(`backoffice.common.${remove ? "delete" : "cancel"}`)}
                        okText={t('backoffice.common.change')}
                        onOk={() => {
                            setShowActionsModal(false);
                            const uploadButton = document.getElementsByClassName("ant-upload-btn")[0];
                            uploadButton && uploadButton.click();
                        }}
                        onCancel={() => {
                            setShowActionsModal(false);
                            remove && removeFile();
                        }}
                    >
                        <div className='rt--flex rt--justify-center'>
                            <span className='rt--title rt--font-regular rt--font-normal rt--text-center'>{titleTooltip ? titleTooltip : infoText}</span>
                        </div>
                    </Modal>
                )
            }
        </Fragment>
    )
}

/** ImageUploaderComponent propTypes
    * PropTypes
*/
ImageUploaderComponent.propTypes = {
    /** Upload URL */
    uploadUrl: PropTypes.string,
    /** Default file */
    defaultFile: PropTypes.shape({
        /** File url */
        url: PropTypes.string,
        /** File dimensions */
        dimensions: PropTypes.string,
        /** File name */
        name: PropTypes.string,
        /** File status */
        status: PropTypes.oneOf(["uploading", "done", "error"]),
        /** File uploaded percent */
        percent: PropTypes.number,
        /** File loaded part size */
        loaded: PropTypes.number,
        /** File total size */
        total: PropTypes.number,
        /** File file object */
        fileObject: PropTypes.object,
        /** File format */
        format: PropTypes.string,
        /** File preview url */
        previewUrl: PropTypes.string,
    }),
    /** Function which will fire on remove button click */
    remove: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            /** Remove Confirmation popup message */
            message: PropTypes.string,
            /** Remove handler */
            handler: PropTypes.func
        })
    ]),
    /** Files data of upload */
    data: PropTypes.object,
    /** Function which will build file url from response */
    fileBuilder: PropTypes.func,
    /** Max file size allowed for upload */
    size: PropTypes.number,
    /** Should upload be disabled */
    disabled: PropTypes.bool,
    /** Fires on upload success */
    onSuccess: PropTypes.func,
    /** Fires when File is Ready */
    onFileReady: PropTypes.func,
    /** Fires before file upload */
    onBeforeUpload: PropTypes.func,
    /** Fires after file upload */
    onAfterUpload: PropTypes.func,
    /** Array of props, which update will triger data load */
    updateProps: PropTypes.array,
    /** Title of component */
    title: PropTypes.string,
    /** Title tooltip text */
    titleTooltip: PropTypes.string,
    /** OnChange handler */
    onChange: PropTypes.func,
    /** Accepted formats */
    acceptedFormats: PropTypes.arrayOf(PropTypes.number),
    /** Do remove action without confirmation */
    removeWithoutConfirmation: PropTypes.bool,
    /** Disable download uploaded img */
    disableDownload: PropTypes.bool,
    /** Max Dimensions */
    maxDimensions: PropTypes.object
}


export default ImageUploaderComponent;
