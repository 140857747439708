import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";

import { deleteAgent, getAvailableAgentsForReparenting } from "store/actions/dashboard/agentSystem/agents/agents.action";

import Paths from 'constants/path.constants';

import { POPUP_SIZE } from "constants/common.constants";
import { REPARENT_ENTITY_TYPE } from "constants/agent.constants";

import { isFranchisingMode } from 'utils/common';

import agentType from "types/agent/agent.type";

/** Agent Deleting Popup Component */
const AgentDeleteComponent = ({
    isSaving,
    deleteAgent,
    getAvailableAgentsForReparenting,
    availableAgentsForReparenting,
    isAvailableAgentsForReparentingLoading,
    onClose
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const navigate = useNavigate();

    useEffect(() => {
        getAvailableAgentsForReparenting(searchParams.id)
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf AgentDeleteComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                deleteAgent(searchParams.id, data.parentId || undefined, () => {
                    navigate( isFranchisingMode() ? Paths.BETSHOP_OWNERS : Paths.AGENTS, { replace: true })
                })
            }).catch(err => {
                console.log(err)
            })
    }
    return (
        <Modal
            title={t('backoffice.common.delete')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.delete')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.agents.newParentAgent')}
                            name="parentId"
                        >
                            <Select
                                options={
                                    [
                                        { value: null, text: t("backoffice.common.none"), label: ""},
                                        ...availableAgentsForReparenting.map(agent => ({
                                            value: agent.id, 
                                            text: agent.userName,
                                            label: `${t("backoffice.agents.level")} ${agent.hierarchyLevel}`
                                        }))
                                    ]
                                    
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.agents.newParentAgent')}`}
                                loading={isAvailableAgentsForReparentingLoading}
                                withLabel={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className='rt--flex rt--form-item-note rt--align-center'>
                            <i className='icon-info rt--font-bigest rt--mr-8' />
                            <span className='rt--title rt--font-normal rt--font-regular rt--flex-equal'>{t("backoffice.agents.deleteAgentNote")}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** AgentDeleteComponent propTypes
    * PropTypes
*/
AgentDeleteComponent.propTypes = {
    /** Redux state property, is true when deleting agent request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to delete agent */
    deleteAgent: PropTypes.func,
    /** Redux action to load available agents for reparenting */
    getAvailableAgentsForReparenting: PropTypes.func,
    /** Redux state property, is true when available agents list for reparenting is loading */
    isAvailableAgentsForReparentingLoading: PropTypes.bool,
    /** Redux state property,, available agents list for reparenting */
    availableAgentsForReparenting: PropTypes.arrayOf(agentType),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        deleteAgent: (id, parentId, onSuccess) => {
            dispatch(deleteAgent(id, parentId, onSuccess));
        },

        getAvailableAgentsForReparenting: id => {
            dispatch(getAvailableAgentsForReparenting(id, true, REPARENT_ENTITY_TYPE.AGENT));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.agents.isSaving,
        availableAgentsForReparenting: state.agents.availableAgentsForReparenting,
        isAvailableAgentsForReparentingLoading: state.agents.isAvailableAgentsForReparentingLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentDeleteComponent)