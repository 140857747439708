//#region react
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getPaymentHistory,
    setPaymentHistorySorting,
    setPaymentHistoryFilters,
} from 'store/actions/dashboard/payments/paymentHistory/paymentHistory.action';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useDate from 'hooks/useDate';
import useProjectType from 'hooks/useProjectType';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from '../filters.component';
import TransactionDetails from '../../transactionDetails';
//#endregion

//#region utils
import { objectHasDefinedValue } from "utils/common";
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import PATHS from "constants/path.constants";
import { PAYMENT_METHODS, WIDGET_TRANSACTION_TYPE } from 'constants/project.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { PAYMENT_HISTORY } from 'constants/pageName.constants';
import { expandColumns, getTableColumns } from '../columns';
//#endregion

//#region hooks
import { useTransfer, TRANSFER_ENTITY_TYPE, TRANSFER_SUCCESS_EVENT_NAME } from 'components/common/transfer';
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import paymentHistoryType from "types/paymentHistory/paymentHistory.type"
//#endregion

const EXPAND_TABLE_ROWS_UNIQUE_KEY = "index";

const mapUserInfo = userInfo => {
    if (!userInfo) {
        return userInfo;
    }

    const info = { ...userInfo };
    delete info["Amount"];

    return info;
}

/** Payment History Page */
const PaymentHistoryComponent = ({
    isLoading,
    getPaymentHistory,
    paymentHistory,
    total,
    setPaymentHistorySorting,
    setPaymentHistoryFilters,
    sorting,
    filters,
    globalProjectId,
    type
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { dateService } = useDate();

    const { show: showTransfer} = useTransfer();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PAYMENT_HISTORY });

    const { hasBankTransfer, hasEpos } = useProjectType(); 

    /** Leave from page if global Payment Type is not bank transfer */
    useEffect(() => {
        if(!hasBankTransfer && !hasEpos){
            navigate(PATHS.DEFAULT);
        }
    }, [hasBankTransfer, hasEpos])

    const expandDisabledRowKeys = (
        paymentHistory
            .filter(paymentData => (
                paymentData.changes.length === 0 &&
                !objectHasDefinedValue(mapUserInfo(paymentData.userInfo))
            ))
            .map(paymentData => paymentData.id)
    );

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const permissionResource = type === WIDGET_TRANSACTION_TYPE.DEPOSIT ? PERMISSION_RESOURCE.PAYMENT_DEPOSIT_TRANSACTION_HISTORY : PERMISSION_RESOURCE.PAYMENT_WITHDRAW_TRANSACTION_HISTORY

    const hasDataExportPermission = hasPermission({
        resource: permissionResource,
        action: PERMISSION_ACTION.EXPORT,
    })

    const hasPaymentModifyPermission = hasPermission({
        resource: permissionResource,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setPaymentHistoryFilters({
            ...filters,
            userNameOrId: value
        }, type)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        expandTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            expandColumns,
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                dateService
            }
        });
    }, [includedColumns, dateService])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasPaymentModifyPermission && paymentHistory.some(p => p.isAdjustable)) {
        tableRowActions.push({
            title: t('backoffice.payments.adjust'),
            icon: "icon-adjustment",
            disabled: record => !record.isAdjustable,
            onClick: record => {
                showTransfer({
                    entityType: TRANSFER_ENTITY_TYPE.TRANSACTION,
                    transactionType: WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST,
                    group: record.transactionType,
                    additionalData: {
                        groupId: record.groupId,
                        amount: record.amount,
                        widgetType: type
                    }
                })
            },
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters type={type} />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            placeholder: t("backoffice.payments.agentUsernameID"),
            loadFn: () => getPaymentHistory(false, type),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AGENT,
                dependencies: [globalProjectId]
            },
            onSearch: handleSearchChange,
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.paymentHistory"),
            columns: columnsForExport,
            url: type === WIDGET_TRANSACTION_TYPE.DEPOSIT ? ApiUrls.EXPORT_PAYMENT_DEPOSIT_HISTORY : ApiUrls.EXPORT_PAYMENT_WITHDRAW_HISTORY,
            filters: filters,
        }
    }

    //#endregion

    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                if(
                    data.transactionType === WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST
                ){
                    getPaymentHistory(false, data?.additionalData?.widgetType)
                }
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [])

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={paymentHistory}
                total={total}
                loadFn={nextPage => getPaymentHistory(nextPage, type)}
                sorting={sorting}
                setSortingFn={sorting => setPaymentHistorySorting(sorting, type)}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                expandable={{
                    tableTitle: t('backoffice.payments.adjustments'),
                    type: EXPAND_TABLE_TYPES.BOTH,
                    disabled: expandDisabledRowKeys,
                    disabledForTable: paymentHistory.filter(h => h.changes.length === 0).map(h => h.id),
                    title: record => (
                        record.paymentType === PAYMENT_METHODS.BANK_TRANSFER
                            ? t('backoffice.payments.PlayerInfo')
                            : t('backoffice.payments.eposInfo')
                    ),
                    details: record => Boolean(record.changes) && ({
                        columns: expandTableColumns,
                        data: record.changes.map((d, i) => ({ ...d, index: i + 1 })),
                        uniqueKey: EXPAND_TABLE_ROWS_UNIQUE_KEY,
                        hideEmpty: true,
                        content: objectHasDefinedValue(mapUserInfo(record.userInfo)) && (
                            <TransactionDetails
                                userInfo={record.userInfo}
                                paymentType={record.paymentType}
                                widgetTransactionType={record.transactionType}
                                bankId={record.bankId}
                            />
                        ),
                    }),
                }}
            />

        </TabTableDashboardLayout>
    );
}

/** PaymentHistoryComponent propTypes
 * PropTypes
*/
PaymentHistoryComponent.propTypes = {
    /** Redux action to get payments history */
    getPaymentHistory: PropTypes.func,
    /** Redux state property, is true when loading payments history */
    isLoading: PropTypes.bool,
    /** Redux state property, payments history sorting details */
    sorting: sortingType,
    /** Redux state property, payments history filters */
    filters: PropTypes.object,
    /** Redux state property, payments history total count */
    total: PropTypes.number,
    /** Redux state property, represents the array of payments history */
    paymentHistory: PropTypes.arrayOf(paymentHistoryType),
    /** Redux action to set payments history sorting details */
    setPaymentHistorySorting: PropTypes.func,
    /** Redux action to set payments history filters */
    setPaymentHistoryFilters: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Transfer type */
    type: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE))
};

const mapDispatchToProps = dispatch => ({
    getPaymentHistory: (nextPage, type) => {
        dispatch(getPaymentHistory(nextPage, type));
    },
    setPaymentHistorySorting: (sorting, type) => {
        dispatch(setPaymentHistorySorting(sorting, type));
    },
    setPaymentHistoryFilters: (filters, type) => {
        dispatch(setPaymentHistoryFilters(filters, type));
    }
});

const mapStateToProps = (state, props) => {
    const type = props.type;
    return {
        isLoading: state.paymentHistory.isLoading,
        paymentHistory: state.paymentHistory[type].paymentHistory,
        sorting: state.paymentHistory[type].sorting,
        filters: state.paymentHistory[type].filters,
        total: state.paymentHistory[type].total,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentHistoryComponent);