import React from 'react';
import i18n from "translations/config"

import Status from 'components/common/status';

import { BETTING_TYPE, BET_TYPE, ONLINE_BET_TYPE } from "constants/bet.constants"
import { STATUS_TYPES } from 'constants/status.constants';

const BET_TYPE_NAMES = {
    [BET_TYPE.SINGLE]: "single",
    [BET_TYPE.MULTI]: "multi",
    [BET_TYPE.SYSTEM]: "system",
}

const BETTING_TYPE_NAMES = {
    [BETTING_TYPE.PREMATCH]: "preMatch",
    [BETTING_TYPE.LIVE]: "live",
    [BETTING_TYPE.KOMBI]: "kombi",
}

const ONLINE_BET_TYPE_NAMES = {
    [ONLINE_BET_TYPE.BET]: "bet",
    [ONLINE_BET_TYPE.BONUS]: "bonus",
}

export const getTableColumns = (additionalProps) => {
    const {
        hasLiteMode,
        formatAmount,
    } = additionalProps;

    return [
        {
            title: "backoffice.bets.betslipId",
            dataIndex: "id",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.bets.playerUserName",
            dataIndex: "userName",
            alwaysVisible: true,
            mobileLevel: 3,
            render: value => value || "-",
        },
        {
            title: "backoffice.bets.playerId",
            dataIndex: "playerId",
            copy: true,
        },
        {
            title: "backoffice.bets.betDate",
            dataIndex: "betDate",
            isDateTime: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.bets.settledDate",
            dataIndex: "settledDate",
            isDateTime: true,
        },
        {
            title: "backoffice.bets.type",
            dataIndex: "type",
            render: type => i18n.t(`backoffice.bets.${ONLINE_BET_TYPE_NAMES[type]}`),
        },
        {
            title: "backoffice.bets.betType",
            dataIndex: "betSlipType",
            render: betSlipType => i18n.t(`backoffice.bets.${BET_TYPE_NAMES[betSlipType]}`),
            mobileLevel: 4,
        },
        {
            title: "backoffice.bets.bettingType",
            dataIndex: "bettingType",
            render: bettingType => i18n.t(`backoffice.common.${BETTING_TYPE_NAMES[bettingType]}`),
        },
        {
            title: "backoffice.bets.amount",
            dataIndex: "amount",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
            mobileLevel: 5,
        },
        {
            title: "backoffice.bets.currency",
            dataIndex: "currencyCode",
            mobileLevel: 6,
        },
        {
            title: "backoffice.bets.factor",
            dataIndex: "factor",
            render: (value, record) => (
                (record.betType === BET_TYPE.SINGLE && record.bets.length > 1)
                    ? "-"
                    : Number(value).toFixed(2) + "x"
            ),
        },
        {
            title: "backoffice.bets.status",
            dataIndex: "state",
            render: status => <Status status={status} type={STATUS_TYPES.BET} />,
            mobileLevel: 7,
        },
        {
            title: "backoffice.bets.win",
            dataIndex: "won",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
        },
        {
            title: "backoffice.bets.possibleWin",
            dataIndex: "possibleWin",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
        },
        {
            title: "backoffice.bets.balanceBefore",
            dataIndex: "balanceBefore",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
        },
        {
            title: "backoffice.bets.balanceAfter",
            dataIndex: "balanceAfter",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime ),
        },
    ];
}
