import {
    SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_BEFORE,
    SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_FINISH,
    SET_BETSHOP_TRANSACTIONS_REPORT,
    SET_BETSHOP_TRANSACTIONS_REPORT_SORTING,
    SET_BETSHOP_TRANSACTIONS_REPORT_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_BETSHOP_TRANSACTIONS_REPORT:
            return {
                ...state,
                report: !payload.add ? payload.report.item2 : state.report.concat(payload.report.item2),
                total: payload.report.item1,
            };

        case SET_BETSHOP_TRANSACTIONS_REPORT_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_BETSHOP_TRANSACTIONS_REPORT_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        default:
            return state;
    }
};
