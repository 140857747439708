import React from "react";

export const getTableColumns = (additionalProps) => {
    const {
        formatAmount,
        dateService,
        hasLiteMode
    } = additionalProps;

    return [
        {
            title: "backoffice.performancereport.playerId",
            dataIndex: "id",
            copy: true,
            //sorter: true,
            alwaysVisible: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.performancereport.playerUserName",
            dataIndex: "playerUserName",
            //sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.performancereport.agentUserName",
            dataIndex: "agentUserName",
            mobileLevel: 3,
        },
        {
            title: "backoffice.performancereport.registrationDate",
            dataIndex: "playerRegistrationDate",
            isDateTime: true,
            //sorter: true,
        },
        {
            title: "backoffice.performancereport.firstTimeDepositDate",
            dataIndex: "firstTimeDepositDate",
            render: (value) =>
                Boolean(value)
                    ? dateService.format(value, false)
                    : "-",
        },
        ...(
            !hasLiteMode
                ? [{
                    title: "backoffice.performancereport.currency",
                    dataIndex: "currencyCode",
                    //sorter: true,
                    mobileLevel: 4,
                }]
                : []
        ),
        {
            title: "backoffice.performancereport.depositCount",
            dataIndex: "depositCount",
            sorter: true,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.depositAmount",
            dataIndex: "depositAmount",
            sorter: true,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.withdrawCount",
            dataIndex: "withdrawCount",
            //sorter: true,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.withdrawalAmount",
            dataIndex: "withdrawAmount",
            //sorter: true,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => <span className='rt--table-withdrawal'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: "backoffice.performancereport.netDepositAmount",
            dataIndex: "netDepositAmount",
            sorter: true,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.performancereport.turnover",
            dataIndex: "turnover",
            //sorter: true,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.winAmount",
            dataIndex: "won",
            //sorter: true,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.ggr",
            dataIndex: "gGR",
            //sorter: true,
            mobileLevel: 5,
            withCurrencySymbol: hasLiteMode,
            render: (_, record) => (
                <span className={record.ggr < 0 ? "rt--error-text" : ""}>
                    {formatAmount(record.ggr, record.currencyCode)}
                </span>
            )
        },
        !hasLiteMode && {
            title: "backoffice.performancereport.otherCosts",
            dataIndex: "otherCosts",
            //sorter: true,
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        !hasLiteMode && {
            title: "backoffice.performancereport.ngr",
            dataIndex: "nGR",
            //sorter: true,
            mobileLevel: 6,
            withCurrencySymbol: hasLiteMode,
            render: (_, record) => (
                <span className={record.ngr < 0 ? "rt--error-text" : ""}>
                    {formatAmount(record.ngr, record.currencyCode)}
                </span>
            )
        },
    ];
}
