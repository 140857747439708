import React from "react";

import PermissionRequestsComponent from "pages/userManagement/requests/requests.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const PermissionRequestsRoute = () => {
    return <PermissionRequestsComponent />;
};

export default withPermission(
    withAuth(PermissionRequestsRoute),
    {
        resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
