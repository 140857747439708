import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Spin, message } from 'antd';

import { deleteProjectLogo, getProjectGeneralInfo, saveProjectGeneralInfo } from "store/actions/dashboard/projects/general.action";
import { getUserInfo } from 'store/actions/dashboard/profile/userInfo.action';

import TabFormDashboardLayout from "components/layouts/tab/form";
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';
import ImageUploader from 'components/common/imageUploader';

import projectGeneralInfoType from "types/project/generalInfo.type";

import { getProjectLogosPaths, isMobile, openInNewTab } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { DESCRIPTION_REGEX, NAME_REGEX } from 'constants/regex.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { IMAGE_TYPE, LOGO_TYPES } from 'constants/common.constants';
import ApiUrls from 'constants/api.constants';

/** Project Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    getProjectGeneralInfo,
    saveProjectGeneralInfo,
    isSaving,
    isLoading,
    generalInfo,
    onTabChange,
    companies,
    globalProjectId,
    deleteProjectLogo,
    getUserInfo,
}) => {
    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const [isDeleteInProgress, setDeleteInProgress] = useState(false);

    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_GENERALINFO,
        action: PERMISSION_ACTION.MODIFY
    });

    const areUploadsDisabled = !hasModifyPermission || isUploadInProgress || isDeleteInProgress;

    /** Set current editing project id as global project id */
    useEffect(() => {
        getProjectGeneralInfo()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...generalInfo
        })
    }, [generalInfo])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            name: generalInfo.name,
            description: generalInfo.description,
            url: generalInfo.url,
        });
    }, [generalInfo])

    const handleRedirectIconClick = () => {
        const url = getFieldValue("url");

        openInNewTab(url)
    }

    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveProjectGeneralInfo({
                    ...data,
                    enabled: generalInfo.enabled
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    const handleSuccessfulUpload = logo => {
		const msg = logo?.message ?? null;
		msg && message.success(msg);

        getUserInfo(true);
	};

    const handleLogoRemove = (logoType) => {
        setDeleteInProgress(true);
        deleteProjectLogo(logoType).finally(() => {
            setDeleteInProgress(false);
        });
    }

    const logoPaths = useMemo(() => {
        return getProjectLogosPaths(companies, globalProjectId)
    }, [companies, globalProjectId])

    const handleBeforeUpload = () => {
        setIsUploadInProgress(true);
    }

    const handleAfterUpload = () => {
        setIsUploadInProgress(false);
    }

    return (
        <TabFormDashboardLayout
            buttons={
                [{
                    type: "primary",
                    onClick: handleForm,
                    text: t("backoffice.common.save"),
                    enabled: hasModifyPermission,
                    loading: isSaving,
                    disabled: !isFormTouched
                }]
            }
            id={generalInfo.id}
            longId={generalInfo.longId}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        name: generalInfo.name,
                        description: generalInfo.description,
                        url: generalInfo.url,
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...generalInfo }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.projects.name')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item rt--form-item-disabled'}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                    maxLength={30}
                                    disabled
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.projects.projectWebsite')}
                                name="url"
                                validateFirst={true}
                                className={"rt--general-form-item" + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.projectWebsite')}`}
                            >
                                <Input.Dropdown
                                    disabled={!hasModifyPermission}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.projectWebsite')}`}
                                    items={[{
                                        label: "https://",
                                        value: "https://",
                                    }]}
                                    suffix={
                                        <span
                                            className="rt--cursor-pointer rt--flex rt--align-center rt--justify-center"
                                            onClick={handleRedirectIconClick}
                                        >
                                            <i className='icon-external rt--font-prebigest' />
                                        </span>
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.projects.description')}
                                name="description"
                                rules={[
                                    { pattern: DESCRIPTION_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={"rt--general-form-item" + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.description')}`}
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.description')}`}
                                    maxLength={150}
                                    rows={4}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12} xl={6}>
                        <div className={areUploadsDisabled ? "rt--disabled" : ""}>
                            <div className='rt--project-logo-title rt--font-small rt--font-bold rt--mb-8'>{t('backoffice.projects.logo')}</div>
                            <ImageUploader
                                disabled={areUploadsDisabled}
                                size={1024 * 1024 * 1}
                                acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                data={{ logoType: LOGO_TYPES.LOGO }}
                                onSuccess={handleSuccessfulUpload}
                                uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.UPLOAD_LOGO}`}
                                remove={{
                                    message: "Hello",
                                    handler: () => handleLogoRemove(LOGO_TYPES.LOGO),
                                }}
                                defaultFile={logoPaths[LOGO_TYPES.LOGO] ? {
                                    url: `${import.meta.env.SYSTEM_CDN_URL}/${logoPaths[LOGO_TYPES.LOGO]}`,
                                    status: "done",
                                    percent: 100
                                } : null}
                                onBeforeUpload={handleBeforeUpload}
                                onAfterUpload={handleAfterUpload}
                            />
                        </div>
                    </Col>
                    {!isMobile() && (
                        <>
                            <Col xs={24} sm={12} xl={6}>
                                <div className={areUploadsDisabled ? "rt--disabled" : ""}>
                                    <div className='rt--project-logo-title rt--font-small rt--font-bold rt--mb-8'>{t('backoffice.projects.collapsedMenuLogo')}</div>
                                    <ImageUploader
                                        disabled={areUploadsDisabled}
                                        size={1024 * 1024 * 1}
                                        acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                        data={{ logoType: LOGO_TYPES.COLLAPSED_LOGO }}
                                        onSuccess={handleSuccessfulUpload}
                                        uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.UPLOAD_LOGO}`}
                                        remove={{
                                            message: "Hello",
                                            handler: () => handleLogoRemove(LOGO_TYPES.COLLAPSED_LOGO),
                                        }}
                                        defaultFile={logoPaths[LOGO_TYPES.COLLAPSED_LOGO] ? {
                                            url: `${import.meta.env.SYSTEM_CDN_URL}/${logoPaths[LOGO_TYPES.COLLAPSED_LOGO]}`,
                                            status: "done",
                                            percent: 100
                                        } : null}
                                        onBeforeUpload={handleBeforeUpload}
                                        onAfterUpload={handleAfterUpload}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} sm={12} xl={6}>
                                <div className={areUploadsDisabled ? "rt--disabled" : ""}>
                                    <div className='rt--project-logo-title rt--font-small rt--font-bold rt--mb-8'>{t('backoffice.projects.favicon')}</div>
                                    <ImageUploader
                                        disabled={areUploadsDisabled}
                                        size={1024 * 1024 * 1}
                                        acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                        data={{ logoType: LOGO_TYPES.FAVICON }}
                                        onSuccess={handleSuccessfulUpload}
                                        uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.UPLOAD_LOGO}`}
                                        remove={{
                                            message: "Hello",
                                            handler: () => handleLogoRemove(LOGO_TYPES.FAVICON),
                                        }}
                                        defaultFile={logoPaths[LOGO_TYPES.FAVICON] ? {
                                            url: `${import.meta.env.SYSTEM_CDN_URL}/${logoPaths[LOGO_TYPES.FAVICON]}`,
                                            status: "done",
                                            percent: 100
                                        } :  null}
                                        onBeforeUpload={handleBeforeUpload}
                                        onAfterUpload={handleAfterUpload}
                                    />
                                </div>
                            </Col>
                        </>
                    )}
                </Row>

            </Spin>
        </TabFormDashboardLayout>


    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux action to save project General info */
    saveProjectGeneralInfo: PropTypes.func,
    /** Redux action to delete project logo */
    deleteProjectLogo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current editing agent general info */
    generalInfo: projectGeneralInfoType,
    /** Redux action to get project General info */
    getProjectGeneralInfo: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Redux state property, represents available companies */
    companies: PropTypes.array,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to get user info */
    getUserInfo: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectGeneralInfo: () => {
            dispatch(getProjectGeneralInfo());
        },

        saveProjectGeneralInfo: data => {
            dispatch(saveProjectGeneralInfo(data));
        },

        deleteProjectLogo: logoType => {
            return dispatch(deleteProjectLogo(logoType));
        },

        getUserInfo: (loadInBackground) => {
            dispatch(getUserInfo(loadInBackground))
        },
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.projects.edit.general,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        globalProjectId: state.common.globalProjectId,
        companies: state.profile.userInfo.companies,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)
