import React from 'react';
import PropTypes from "prop-types";

import {useTranslation} from "react-i18next";

import {Button} from "antd";
import {NOTIFICATION_SOUND_TYPES} from "../../../constants/notification.constants";
import Tooltip from "../tooltip";
import { isMobile } from 'utils/common';

const NotificationHeader = ({
    markAllReadDisabled,
    clearAllDisabled,
    notificationSound,
    onMarkAllAsRead,
    onClearAll,
    onMuteToggle,
    onBack
}) => {
    const { t } = useTranslation();
    const isMuted = notificationSound === NOTIFICATION_SOUND_TYPES.SILENT;

    return (
        <div className={'rt--notifications-header rt--flex rt--justify-between rt--align-center ' + (!isMobile() ? 'rt--pb-12 rt--pt-16 rt--pl-16 rt--pr-16' : '')}>
            <div className='rt--flex rt--align-center'>
                {
                    isMobile() && <i className='icon-left rt--font-bigest rt--mr-4' onClick={onBack} />
                }
                
                <span className='rt--font-big rt--font-extra-bold'>{t('backoffice.notifications.notifications')}</span>
            </div>

            <div className="rt--notifications-header-actions">
                <Tooltip
                    title={t('backoffice.notifications.markAllAsRead')}
                    trigger={["hover"]}
                    placement="top"
                    getPopupContainer={null}
                >
                    <Button
                        disabled={markAllReadDisabled}
                        shape='round'
                        onClick={onMarkAllAsRead}
                        icon={<i className="icon-email-read"/>}
                    />
                </Tooltip>
                <Tooltip
                    title={t('backoffice.notifications.deleteAll')}
                    trigger={["hover"]}
                    placement="top"
                    getPopupContainer={null}
                >
                    <Button
                        className="rt--notifications-header-actions-clear-all"
                        disabled={clearAllDisabled}
                        shape='round'
                        onClick={onClearAll}
                        icon={<i className="icon-delete-trash"/>}
                    />
                </Tooltip>
                <Tooltip
                    title={isMuted ? t('backoffice.notifications.unmute') : t('backoffice.notifications.mute')}
                    trigger={["hover"]}
                    placement="top"
                    getPopupContainer={null}
                >
                    <Button
                        shape='round'
                        onClick={onMuteToggle}
                        icon={<i className={isMuted ? "icon-mute" : "icon-unmute"}/>}
                    />
                </Tooltip>
            </div>
        </div>
    );
};

NotificationHeader.propTypes = {
    markAllReadDisabled: PropTypes.bool,
    clearAllDisabled: PropTypes.bool,
    onMuteToggle: PropTypes.func,
    onMarkAllAsRead: PropTypes.func,
    onClearAll: PropTypes.func,
    notificationSound: PropTypes.number,
    onBack: PropTypes.func
}

export default NotificationHeader;
