import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Outlet } from "react-router-dom";
import { connect } from 'react-redux';

import { Spin } from "antd";

import withAnanymous from 'hocs/withAnanymous';

import AuthSidebar from "./sidebar";
import LanguagesDropdown from "components/common/languagesDropdown"

import { getLanguages } from 'store/actions/auth/translations.action';

import { isMobile, setUrlParam, getEnvironment } from 'utils/common';
import LanguageUtils from 'utils/languages';

import { ENVIRONMENT_TYPE } from 'constants/common.constants';

import { initTranslations } from 'translations/config';

import logoMobile from "assets/images/logoMobile.svg";

import Styles from '../../components/styles'

const Layout = ({
    isLanguagesLoaded,
    getLanguages,
    translationsLoaded,
    languages
}) => {

    /** Set Page title */
    useEffect(() => {
        const titleElement = document.getElementsByTagName("title")[0];
        if(titleElement){
            titleElement.innerText = getEnvironment() === ENVIRONMENT_TYPE.RETAIL ? "Retail Solution" : "Agent System"
        }
    }, [])

    /** Get system languages */
    useEffect(() => {
        getLanguages();
    }, [])


    /** Set default language */
    useEffect(() => {
        if (isLanguagesLoaded) {
            let language = LanguageUtils.getSelectedLanguage()

            if (!Object.keys(languages).map(l => l.toLowerCase()).includes(language.toLowerCase())) {
                language = LanguageUtils.defaultLang;
            }
            initTranslations(language);
            setUrlParam('language', language)
        }
    }, [isLanguagesLoaded])    

    return translationsLoaded ? (
        <div className="rt--auth rt--flex">
            <Styles/>
            <div className={'rt--auth-header rt--flex rt--align-center' + (isMobile() ? ' rt--justify-between' : ' rt--justify-end')}>
                {
                    (isMobile() ? <img src={logoMobile}/> : null)
                }
                <LanguagesDropdown />
            </div>

            {
                !isMobile() && <AuthSidebar />
            }

            <div className={"rt--auth-content rt--flex rt--align-center" + (isMobile() ? '' : ' rt--flex-equal')}>
                <Styles/>
                <Outlet />
            </div>
        </div>
    ) : <Spin spinning={true} />
}

/** Layout propTypes
    * PropTypes
*/
Layout.propTypes = {
    /** Redux state property , is true when languages are loaded */
    isLanguagesLoaded: PropTypes.bool,
    /** Redux action to get system languages */
    getLanguages: PropTypes.func,
    /** Redux state property , is true when translations are loaded*/
    translationsLoaded: PropTypes.bool,
    /** Redux state property, system languages */
    languages: PropTypes.object
}

const mapStateToProps = state => {
    return {
        translationsLoaded: state.auth.translations.loaded,
        languages: state.auth.translations.languages,
        isLanguagesLoaded: state.auth.translations.isLanguagesLoaded,
    }
}

const mapDispatchToProps = dispatch => (
    {
        getLanguages: () => {
            dispatch(getLanguages());
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withAnanymous(Layout));