import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { Form, Row, Col, Spin, Radio, Switch, Checkbox } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import Select from "components/common/select";
import NumericInput from "components/common/numericInput";
import NumericRanges from "components/common/numericRanges";
import FormId from "components/common/formId";
import Tooltip from "components/common/tooltip";
import Input from 'components/common/input';

import CommissionPlanChangePeriodPopup from "./commissionPlan-changePeriod.component";
import CommissionPlanActionsComponent from "./commissionPlan-actions.component";

import { saveCommissionPlanSettings } from "store/actions/dashboard/agentSystem/commissionPlans/settings.action";
import { getRetailProviders } from "store/actions/dashboard/projects/projects.action";

import commissionPlanSettingsType from "types/agent/commissionPlanSettings.type";
import userInfoType from 'types/profile/userInfo.type';

import { AGENT_TYPE } from "constants/agent.constants"
import { NAME_REGEX } from "constants/regex.constants";

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import { numberTransform, isMobile, binaryToFlags, isFranchisingMode, flagsToBinary } from "utils/common";
import { hasPermission } from 'utils/permissions';
import {
    COMMISSION_PLAN_SOURCE_TYPE,
    COMMISSION_PLAN_TYPE,
    COMMISSION_PLAN_SOURCE_DIRECTION,
    COMMISSION_PLAN_SOURCE,
    COMMISSION_PLAN_CALCULATION_PERIOD,
    COMMISSION_PLAN_STRUCTURE,
    COMMISSION_INCOME_TYPE,
    COMMISSION_SETTINGS_TYPE,
    COMMISSION_PLAN_TURNOVER_MAX_PERCENT,
    INFINITY,
    MINUS_INFINITY
} from "constants/commission.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { PROJECT_PROVIDER_TYPE } from "constants/project.constants"
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import gameProviderType from "types/project/gameProvider.type";

/** Agent Edit Page Commision Plan Tab Edit subtab Settings Component */
const CommissionPlanSettingsComponent = ({
    saveCommissionPlanSettings,
    isSaving,
    isLoading,
    commissionPlanSettings,
    getRetailProviders,
    gameProviders,
    retailProviders,
    userInfo,
    agentType,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();
    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const [changePeriodPopup, setChangePeriodPopup] = useState(null);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [carryOver, setCarryOver] = useState(false)

    const navigateWithConfirmation = useUnsavedFormConfirmation({
        cb: navigate,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const currentCommissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");
    const currentCommissionPlanName = (new URLSearchParams(search)).get("commissionPlanName");

    /** Available Settings Types */
    const incomeTypes = commissionPlanSettings.type === COMMISSION_PLAN_TYPE.MIX ?
        [COMMISSION_INCOME_TYPE.NETWORK, COMMISSION_INCOME_TYPE.DIRECT] :
        commissionPlanSettings.type === COMMISSION_PLAN_TYPE.DIRECT ? [COMMISSION_INCOME_TYPE.DIRECT] : [COMMISSION_INCOME_TYPE.NETWORK]

    const sourceDirections = binaryToFlags(Object.values(COMMISSION_PLAN_SOURCE_DIRECTION), commissionPlanSettings.sourceDirections);

    const settings = commissionPlanSettings?.settings ?? [];

    /** Load retail providers */
    useEffect(() => {
        if (sourceDirections.includes(COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS)) {
            getRetailProviders();
        }
    }, [commissionPlanSettings.sourceDirections])


    /** Set form fields values, when data is loaded */
    useEffect(() => {
       
        const fieldValues = makeFieldValuesFromSettings(commissionPlanSettings)

        setFieldsValue(fieldValues);

        setCarryOver(settings.find(s => s.incomeType === COMMISSION_INCOME_TYPE.CARRY_OVER)?.enabled ?? false)

    }, [commissionPlanSettings])

    /** Carry over, on disable, set initial value */
    useEffect(() => {
        if (!carryOver) {
            const initialCarryOverValue = settings.find(s => s.incomeType === COMMISSION_INCOME_TYPE.CARRY_OVER)?.ranges?.[0]?.value ?? ""
            setFieldsValue({
                settings: {
                    [COMMISSION_INCOME_TYPE.CARRY_OVER]: {
                        ranges: {
                            [0]: {
                                value: initialCarryOverValue
                            }
                        }
                    }
                }
            });
        }
    }, [carryOver])

    /** Fires when form submitted
       * @function
       * @memberOf CommissionPlanSettingsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const commissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");

                let allIncomeTypes = [...incomeTypes];

                const d = {
                    id: commissionPlanId,
                    agentId: searchParams.id,
                    onlineProviders: !isFranchisingMode() && sourceDirections.includes(COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS) ? data.onlineProviders : null,
                    retailProviders: sourceDirections.includes(COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS) ? flagsToBinary(data.retailProviders) : null,
                    name: data.name,
                    period: data.period,
                    includeOtherCosts: data.includeOtherCosts,
                    settings: []
                }

                if (commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.DEPOSIT && commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.WITHDRAWAL) {
                    allIncomeTypes = allIncomeTypes.concat([COMMISSION_INCOME_TYPE.CARRY_OVER])
                }


                allIncomeTypes.forEach(incomeType => {
                    let item = {
                        incomeType: incomeType,
                        type: COMMISSION_SETTINGS_TYPE.GENERAL,
                        enabled: data.settings[incomeType].enabled,
                        isAutoPay: data.settings[incomeType].isAutoPay,
                        sourceType: data.settings[incomeType].structure === COMMISSION_PLAN_STRUCTURE.FLAT ? COMMISSION_PLAN_SOURCE_TYPE.PERCENT : data.settings[incomeType].sourceType,
                        ranges: data.settings[incomeType].ranges.map(r => ({ ...r, to: r.to === INFINITY ? null : r.to, from: r.from === MINUS_INFINITY ? null : r.from }))
                    }
                    d.settings.push(item)
                })

                saveCommissionPlanSettings(d);

                setIsFormTouched(false);

            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    const isDisabled = agentType === AGENT_TYPE.AGENT || !hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.MODIFY })

    /** Function to detect if can activate/deactivate delete commission plan
       * @function
       * @memberOf CommissionPlanSettingsComponent
   */
    const canEdit = () => {
        return hasPermission({ resource: agentType === AGENT_TYPE.AGENT ? PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN : PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.MODIFY }) && (agentType !== AGENT_TYPE.AGENT || searchParams.id !== userInfo.id)
    }

    const makeFieldValuesFromSettings = commissionPlanSettings => {
        const fieldValues = {
            name: commissionPlanSettings.name,
            currencyCode: commissionPlanSettings.currencyCode,
            source: commissionPlanSettings.source,
            period: commissionPlanSettings.period,
            includeOtherCosts: commissionPlanSettings.includeOtherCosts,
            onlineProviders: commissionPlanSettings.onlineProviders ? commissionPlanSettings.onlineProviders.map(v => v.toString()) : commissionPlanSettings.onlineProviders,
            retailProviders: commissionPlanSettings.retailProviders ? binaryToFlags(Object.values(PROJECT_PROVIDER_TYPE), commissionPlanSettings.retailProviders) : commissionPlanSettings.retailProviders,
        }

        if (!isFranchisingMode()) {
            fieldValues.type = commissionPlanSettings.type;
            fieldValues.sourceDirections = sourceDirections
        }

        const settingsValues = {};

        commissionPlanSettings.settings.filter(s => s.type === COMMISSION_SETTINGS_TYPE.GENERAL).forEach(setting => {
            if(setting.incomeType){
                settingsValues[setting.incomeType] = {
                    enabled: setting.enabled,
                    structure: setting.structure,
                    sourceType: setting.sourceType,
                    isAutoPay: setting.isAutoPay,
                    ranges: setting.ranges.map((r, i) => ({
                        ...r,
                        from: i === 0 ? MINUS_INFINITY : r.from,
                        to: i === setting.ranges.length - 1 ? INFINITY : r.to,
                    })),
                }
            }
            
        })
        fieldValues.settings = settingsValues;

        return fieldValues;
    }

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit(),
                        loading: isSaving,
                        disabled: !isFormTouched || searchParams.id === userInfo.id
                    },
                    {
                        type: "secondary",
                        onClick: () => {
                            navigateWithConfirmation((pathname + search + hash)
                                .replace("&commissionPlanId=" + currentCommissionPlanId, "")
                                .replace("&commissionPlanName=" + currentCommissionPlanName, ""),
                                { replace: true }
                            )
                        },
                        text: (
                            <span className='rt--back-button'>
                                <i className="icon-left rt--font-big" />
                                <span>
                                    {t("backoffice.common.back")}
                                </span>
                            </span>
                        ),
                        enabled: true,
                        className: "rt--button-secondary",
                        placement: "left",
                    },
                ]
            }
            actions={<CommissionPlanActionsComponent agentType={agentType} />}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => {
                        setIsFormTouched(true)
                    }}
                >

                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className={'rt--flex rt--align-center ' + (isMobile() ? " rt--mb-8" : " rt--mb-16")}>
                                {
                                    hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }) && (
                                        <FormId
                                            id={(new URLSearchParams(search)).get("commissionPlanId")}
                                            text={t("backoffice.common.id")}
                                        />
                                    )
                                }

                                {
                                    commissionPlanSettings.longId && (
                                        <div className='rt--ml-16'>
                                            <FormId
                                                id={commissionPlanSettings.longId}
                                                text={t("backoffice.common.longId")}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={!isMobile() ? [40, 0] : [0, 0]}>
                        <Col xs={24} sm={12} xl={8}>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.commissionplans.general")
                                        }
                                    </h4>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('backoffice.commissionplans.name')} *`}
                                        name="name"
                                        rules={[
                                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                            { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                            { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        className={isDisabled ? "rt--general-form-item rt--form-item-disabled" : "rt--general-form-item"}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.name')}`}
                                        validateFirst
                                    >
                                        <Input
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.name')}`}
                                            maxLength={30}
                                            autoFocus={(isMobile() ? false : true)}
                                            autoComplete="off"
                                            disabled={isDisabled} />

                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} xl={8}>
                                    <Form.Item
                                        label={`${t('backoffice.commissionplans.currency')} *`}
                                        name="currencyCode"
                                        rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                        className="rt--form-item-disabled"
                                    >
                                        <Select
                                            options={[]}
                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.currency')}`}
                                            search={true}
                                            disabled={true}
                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    !isFranchisingMode() && (
                                        <Col xs={24} sm={12} xl={8}>
                                            <Form.Item
                                                label={`${t('backoffice.commissionplans.incomeType')} *`}
                                                name="type"
                                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                                className="rt--form-item-disabled"
                                            >
                                                <Select
                                                    options={[
                                                        { value: COMMISSION_PLAN_TYPE.DIRECT, text: t('backoffice.commissionplans.direct') },
                                                        { value: COMMISSION_PLAN_TYPE.NETWORK, text: t('backoffice.commissionplans.network') },
                                                        { value: COMMISSION_PLAN_TYPE.MIX, text: t('backoffice.commissionplans.mix') }
                                                    ]}
                                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.incomeType")}`}
                                                    disabled={true}
                                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                <Col xs={24} sm={12} xl={8}>
                                    <Form.Item
                                        label={`${t('backoffice.commissionplans.source')} *`}
                                        name="source"
                                        rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                        className="rt--form-item-disabled"
                                    >
                                        <Select
                                            options={[
                                                ... (commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.TURNOVER ? [
                                                    { value: COMMISSION_PLAN_SOURCE.TURNOVER, text: t('backoffice.commissionplans.turnover') }
                                                ] : []),
                                                ... (
                                                    commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.GGR ||
                                                        commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.NGR
                                                        ? [
                                                            { value: COMMISSION_PLAN_SOURCE.GGR, text: t('backoffice.commissionplans.ggr') },
                                                            { value: COMMISSION_PLAN_SOURCE.NGR, text: t('backoffice.commissionplans.ngr') },
                                                        ] : []),
                                                ... (
                                                    commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.DEPOSIT ||
                                                        commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.WITHDRAWAL
                                                        ? [
                                                            { value: COMMISSION_PLAN_SOURCE.DEPOSIT, text: t('backoffice.commissionplans.deposit') },
                                                            { value: COMMISSION_PLAN_SOURCE.WITHDRAWAL, text: t('backoffice.commissionplans.withdrawal') },
                                                        ] : [])
                                            ]}
                                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.source")}`}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    !isFranchisingMode() && (
                                        <Col span={24}>
                                            <Form.Item
                                                label={`${t("backoffice.commissionplans.sourceDirection")} *`}
                                                name="sourceDirections"
                                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                                className="rt--form-item-disabled"
                                            >
                                                <Select
                                                    options={[
                                                        { value: COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS, text: t("backoffice.commissionplans.players") },
                                                        { value: COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS, text: t("backoffice.commissionplans.betshops") }
                                                    ]}
                                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.sourceDirection')}`}
                                                    isMultiple={true}
                                                    disabled={true}
                                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                {
                                    (
                                        !isFranchisingMode() &&
                                        sourceDirections.includes(COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS) &&
                                        commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.DEPOSIT &&
                                        commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.WITHDRAWAL
                                    ) && (
                                        <Col span={24}>
                                            <Form.Item
                                                label={`${t("backoffice.commissionplans.onlineProviders")} *`}
                                                name="onlineProviders"
                                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                                className={isDisabled ? "rt--form-item-disabled" : ""}
                                            >
                                                <Select
                                                    options={
                                                        gameProviders
                                                            .map(provider => (
                                                                { value: provider.id, text: provider.name }
                                                            )
                                                            )
                                                    }
                                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.onlineProviders')}`}
                                                    isMultiple={true}
                                                    disabled={isDisabled}
                                                    search={true}
                                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                {
                                    sourceDirections.includes(COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS) && (
                                        <Col span={24}>
                                            <Form.Item
                                                label={`${t("backoffice.commissionplans.retailProviders")} *`}
                                                name="retailProviders"
                                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                                className={isDisabled ? "rt--form-item-disabled" : ""}
                                            >
                                                <Select
                                                    options={
                                                        retailProviders.map(pr => ({
                                                            value: pr, text: pr === PROJECT_PROVIDER_TYPE.SPORTBOOK ? t("backoffice.common.Sportsbook") : t("backoffice.common.DGVirtuals")
                                                        }))
                                                    }
                                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.retailProviders')}`}
                                                    isMultiple={true}
                                                    disabled={isDisabled}
                                                    search={true}
                                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                {
                                    !isFranchisingMode() && (
                                        <Col span={24}>
                                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                                <Form.Item
                                                    name="includeOtherCosts"
                                                    valuePropName="checked"
                                                    className='rt--form-item-without-margin'
                                                >
                                                    <Switch
                                                        disabled={
                                                            isDisabled ||
                                                            !sourceDirections.includes(COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS) ||
                                                            commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.NGR ||
                                                            commissionPlanSettings.existIncludedOtherCost
                                                        }
                                                    />
                                                </Form.Item>
                                                <div className='rt--flex rt--align-center'>
                                                    <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.commissionplans.includeOtherCosts')}</label>
                                                    {
                                                        commissionPlanSettings.existIncludedOtherCost && (
                                                            <Tooltip
                                                                title={t(`backoffice.commissionplans.otherCostsDisabledText`)}
                                                                trigger={["hover", "click"]}
                                                                placement="bottomLeft"
                                                                enableMobile={true}
                                                            >
                                                                <i className='icon-info rt--font-bigest rt--ml-8' />
                                                            </Tooltip>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }

                                <Col span={24}>
                                    <Form.Item
                                        label={t("backoffice.commissionplans.calculationPeriod")}
                                        name="period"
                                        className={isDisabled ? "rt--form-item-disabled" : ""}
                                    >
                                        <Select
                                            options={[
                                                { value: COMMISSION_PLAN_CALCULATION_PERIOD.HOUR, text: t('backoffice.commissionplans.hour') },
                                                { value: COMMISSION_PLAN_CALCULATION_PERIOD.DAY, text: t('backoffice.commissionplans.day') },
                                                { value: COMMISSION_PLAN_CALCULATION_PERIOD.WEEK, text: t('backoffice.commissionplans.week') },
                                                { value: COMMISSION_PLAN_CALCULATION_PERIOD.TWO_WEEK, text: t('backoffice.commissionplans.2week') },
                                                { value: COMMISSION_PLAN_CALCULATION_PERIOD.THREE_WEEK, text: t('backoffice.commissionplans.3week') },
                                                { value: COMMISSION_PLAN_CALCULATION_PERIOD.MONTH, text: t('backoffice.commissionplans.month') }
                                            ]}
                                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.calculationPeriod")}`}
                                            disabled={isDisabled}
                                            onChange={e => {
                                                setChangePeriodPopup(e);
                                                setFieldsValue({
                                                    period: commissionPlanSettings.period
                                                })
                                                setIsFormTouched(isFormTouched)
                                            }}
                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    (
                                        commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.DEPOSIT &&
                                        commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.WITHDRAWAL
                                    ) && (
                                        <Col span={24}>
                                            <div className={'rt--flex rt--flex-col' + (isDisabled ? " rt--form-item-disabled" : "")}>
                                                <label className='rt--title rt--font-small rt--font-bold rt--pb-8'>{t('backoffice.commissionplans.carryOver')}</label>
                                                <div className="rt--switcher rt--flex-inline rt--align-center rt--mb-16">
                                                    <Form.Item
                                                        name={["settings", COMMISSION_INCOME_TYPE.CARRY_OVER, "enabled"]}
                                                        valuePropName="checked"
                                                        className="rt--form-item-without-margin"
                                                    >
                                                        <Switch
                                                            onChange={value => {
                                                                setCarryOver(value);
                                                                setTimeout(() => {
                                                                    validateFields([["settings", COMMISSION_INCOME_TYPE.CARRY_OVER, "ranges", 0, "value"]])
                                                                }, 0)
                                                            }}
                                                            disabled={isDisabled}
                                                        />
                                                    </Form.Item>
                                                    <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.commissionplans.negative')}</label>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }

                                {
                                    (
                                        commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.DEPOSIT &&
                                        commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.WITHDRAWAL
                                    ) && (
                                        <Col span={24}>
                                            <Form.Item
                                                label={`${t('backoffice.commissionplans.percent')} *`}
                                                name={["settings", COMMISSION_INCOME_TYPE.CARRY_OVER, "ranges", 0, "value"]}
                                                rules={[
                                                    { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                                    () => ({
                                                        validator(rule, value) {
                                                            if (!carryOver) return Promise.resolve();
                                                            if (value === "" || value === null || value === undefined) {
                                                                return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                            }
                                                            if (Number(value) <= 0 || Number(value) > 100) {
                                                                return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                                validateFirst
                                                className={'rt--general-form-item ' + (!carryOver || searchParams.id === userInfo.id ? 'rt--form-item-disabled' : "")}
                                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.percent')}`}
                                            >
                                                <NumericInput
                                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.percent')}`}
                                                    disabled={!carryOver || searchParams.id === userInfo.id}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }



                            </Row>
                        </Col>

                        {
                            incomeTypes.map(incomeType => (
                                <Col xs={24} sm={12} xl={8} key={incomeType} className='rt--direct'>
                                    <Row gutter={[16, 0]}>
                                        <Col span={24}>
                                            <h4
                                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                                {
                                                    isFranchisingMode() ? t("backoffice.commissionplans.commissionPlan") : incomeType === COMMISSION_INCOME_TYPE.NETWORK ?
                                                        t("backoffice.commissionplans.networkPlan") :
                                                        t("backoffice.commissionplans.directPlan")
                                                }
                                            </h4>
                                        </Col>
                                        <Col span={24}>
                                            {
                                                settings.find(s => s.incomeType === incomeType)?.structure === COMMISSION_PLAN_STRUCTURE.STRUCTURED ?
                                                    (
                                                        <Form.Item
                                                            label={`${t('backoffice.commissionplans.sourceType')} *`}
                                                            name={["settings", incomeType, "sourceType"]}
                                                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                                            className="rt--form-item-disabled"
                                                        >
                                                            <Select
                                                                options={[
                                                                    { value: COMMISSION_PLAN_SOURCE_TYPE.FIXED_AMOUNT, text: t('backoffice.commissionplans.fixedAmount') },
                                                                    { value: COMMISSION_PLAN_SOURCE_TYPE.PERCENT, text: t('backoffice.commissionplans.percent') }
                                                                ]}
                                                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.sourceType")}`}
                                                                disabled={true}
                                                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                            />
                                                        </Form.Item>
                                                    ) :
                                                    (
                                                        <Form.Item
                                                            label={`${t('backoffice.commissionplans.commissionPercent')} *`}
                                                            name={["settings", incomeType, "ranges", 0, "value"]}
                                                            rules={[
                                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                                { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                                                () => ({
                                                                    validator(rule, value) {
                                                                        const max = commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.TURNOVER ? COMMISSION_PLAN_TURNOVER_MAX_PERCENT : 100;
                                                                        if (Number(value) < 0 || Number(value) > max) {
                                                                            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", max))
                                                                        }
                                                                        return Promise.resolve();
                                                                    }
                                                                })
                                                            ]}
                                                            validateFirst
                                                            className={'rt--general-form-item ' + (!canEdit() ? "rt--form-item-disabled" : "")}
                                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.commissionPercent')}`}
                                                        >
                                                            <NumericInput
                                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.commissionPercent')}`}
                                                                disabled={!canEdit()}
                                                            />
                                                        </Form.Item>
                                                    )
                                            }
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label={`${t('backoffice.commissionplans.structure')} *`}
                                                name={["settings", incomeType, "structure"]}
                                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                                className="rt--form-item-radio rt--form-item-disabled"
                                            >
                                                <Radio.Group
                                                    options={[
                                                        { label: t('backoffice.commissionplans.flat'), value: COMMISSION_PLAN_STRUCTURE.FLAT },
                                                        { label: t('backoffice.commissionplans.structured'), value: COMMISSION_PLAN_STRUCTURE.STRUCTURED }
                                                    ]}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {
                                            (
                                                !isFranchisingMode() &&
                                                commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.DEPOSIT &&
                                                commissionPlanSettings.source !== COMMISSION_PLAN_SOURCE.WITHDRAWAL
                                            ) && (
                                                <Col span={24}>
                                                    <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mb-16">
                                                        <Form.Item
                                                            className={'rt--form-item-inline rt--form-item-without-margin' + (isDisabled ? " rt--form-item-disabled" : "")}
                                                            name={["settings", incomeType, "isAutoPay"]}
                                                            valuePropName='checked'
                                                        >
                                                            <Checkbox
                                                                disabled={isDisabled}
                                                            />
                                                        </Form.Item>
                                                        <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.commissionplans.autopayCommission')}</span>
                                                    </div>
                                                </Col>
                                            )
                                        }


                                        {
                                            settings.find(s => s.incomeType === incomeType)?.structure === COMMISSION_PLAN_STRUCTURE.STRUCTURED && (
                                                <Col span={24}>

                                                    <NumericRanges
                                                        label={t('backoffice.commissionplans.commissionRanges')}
                                                        formInstance={formInstance}
                                                        permission={agentType === AGENT_TYPE.AGENT ? PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN : PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN}
                                                        isPercent={settings.find(s => s.incomeType === incomeType)?.sourceType === COMMISSION_PLAN_SOURCE_TYPE.PERCENT}
                                                        fieldName={["settings", incomeType, "ranges"]}
                                                        editable={!commissionPlanSettings.isUsed && agentType === AGENT_TYPE.SUPER_AGENT}
                                                        maxPercent={commissionPlanSettings.source === COMMISSION_PLAN_SOURCE.TURNOVER ? COMMISSION_PLAN_TURNOVER_MAX_PERCENT : 100}
                                                        disabled={searchParams.id === userInfo.id}
                                                        minRangesCount={2}
                                                    />
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Col>
                            ))
                        }
                    </Row>

                </Form>

                {
                    changePeriodPopup && (
                        <CommissionPlanChangePeriodPopup
                            period={changePeriodPopup}
                            onClose={() => setChangePeriodPopup(null)}
                        />
                    )
                }

            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** CommissionPlanSettingsComponent propTypes
    * PropTypes
*/
CommissionPlanSettingsComponent.propTypes = {
    /** Redux state property, is true when commision plan settings are saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading commission plan ranges */
    isLoading: PropTypes.bool,
    /** Redux state, represents the ranges settings of current editing commision plan  */
    commissionPlanSettings: commissionPlanSettingsType,
    /** Redux action to save commision plan  settings */
    saveCommissionPlanSettings: PropTypes.func,
    /** Redux action to get retail providers */
    getRetailProviders: PropTypes.func,
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType),
    /** Redux state property, game providers */
    retailProviders: PropTypes.arrayOf(PropTypes.number),
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Agent type */
    agentType: PropTypes.oneOf(Object.values(AGENT_TYPE)),
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        saveCommissionPlanSettings: data => {
            dispatch(saveCommissionPlanSettings(data));
        },
        getRetailProviders: () => {
            dispatch(getRetailProviders());
        }
    }
)

const mapStateToProps = state => {
    return {
        commissionPlanSettings: state.commissionPlans.edit.settings,
        isSaving: state.commissionPlans.isSaving,
        isLoading: state.commissionPlans.isLoading,
        gameProviders: state.common.gameProviders,
        retailProviders: state.projects.retailProviders,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanSettingsComponent)