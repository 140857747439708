import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import TabTableDashboardLayout from 'components/layouts/tab/table';
import Leaderboard from 'components/common/leaderboard';
import { LEADERBOARD_TYPE } from 'components/common/leaderboard/constants';
import Filters from '../../filters';

import useOperationalLeaderboard from 'pages/dashboard/hooks/useOperationalLeaderboard';
import useFirstRender from 'hooks/useFirstRender';

import { isMobile } from 'utils/common';

import { ENTITY_TYPE, OPERATIONAL_DASHBOARD_FILTER_NAMES, TIME_PERIOD } from 'pages/dashboard/constants';
import { FILTER_TYPE } from 'constants/common.constants';

const getDefaultFilters = (hasRetail) =>  ({
    [OPERATIONAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD]: TIME_PERIOD.TODAY,
    [OPERATIONAL_DASHBOARD_FILTER_NAMES.ENTITY_TYPE]: hasRetail ? ENTITY_TYPE.BETSHOP : ENTITY_TYPE.PLAYER,
    [OPERATIONAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE]: FILTER_TYPE.NETWORK,
})

const selectGlobalProjectId = state => state.common.globalProjectId;

const TopUsers = ({ updateLastUpdatedDate, hasRetail }) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState(getDefaultFilters(hasRetail))

    const globalProjectId = useSelector(selectGlobalProjectId);

    const { leaderBoardData, isLoading } = useOperationalLeaderboard(filters, updateLastUpdatedDate)

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters(hasRetail))
        },
        dependencies: [globalProjectId, updateFilters]
    })

    return (
        <TabTableDashboardLayout
            header={{
                filters: isMobile() ? <Filters hasRetail={hasRetail} filters={filters} updateFilters={updateFilters} /> : undefined,
                content: !isMobile() ? (
                    <div className="rt--flex rt--align-center rt--justify-end rt--width-full">
                        <Filters hasRetail={hasRetail} filters={filters} updateFilters={updateFilters} />
                    </div>
                ) : undefined
            }}
            headerBorder={false}
        >
            <div className='rt--financial-top-user'>
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <Leaderboard
                        leaders={leaderBoardData}
                        isLoading={isLoading}
                        title={t("backoffice.dashboard.topAgents")}
                        type={LEADERBOARD_TYPE.OPERATIONAL}
                    />
                </Col>
            </Row>
            </div>
        </TabTableDashboardLayout>
    )
}

TopUsers.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
    hasRetail: PropTypes.bool,
}

export default TopUsers;