import {useCallback, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectCurrencies } from "store/actions/dashboard/projects/currencies.action";
import { getAgentCurrencies } from "store/actions/dashboard/agentSystem/agents/currencies.action";

import { getUser } from 'utils/auth';

import { USER_ROLE } from "constants/user.constants";

const selectGlobalProjectId = state => state.common.globalProjectId;
const selectAgentCurrencies = state => state.agents.edit.currencies.currencies;
const selectProjectCurrencies = state => state.projects.edit.currencies;
const selectUserInfo = state => state.profile.userInfo;

const useUserCurrencies = (autoCall = true) => {
    const userData = getUser();

    //#region ------------------------------------------- REDUX --------------------------------------------//

    const dispatch = useDispatch();
    const globalProjectId = useSelector(selectGlobalProjectId);
    const agentCurrencies = useSelector(selectAgentCurrencies);
    const projectCurrencies = useSelector(selectProjectCurrencies);
    const userInfo = useSelector(selectUserInfo);

    //#endregion

    const fn = useCallback(() => {
        if (userData?.role === USER_ROLE.AGENT || userData?.role === USER_ROLE.AGENT_PLAYER) {
            dispatch(getAgentCurrencies(userInfo.id));
        }

        if (userData?.role === USER_ROLE.ADMIN) {
            dispatch(getProjectCurrencies());
        }
    }, [globalProjectId]);

    const hasOneCurrency = useMemo(() => {
        if (userData?.role === USER_ROLE.AGENT || userData?.role === USER_ROLE.AGENT_PLAYER) {
            return agentCurrencies.length === 1;
        }

        if (userData?.role === USER_ROLE.ADMIN) {
            return projectCurrencies.length === 1;
        }

        return false;
    }, [agentCurrencies, projectCurrencies]);

    // Load Currencies
    useEffect(() => {
         if(autoCall){
            fn()
         }
    }, [globalProjectId, dispatch]);


    return (
        userData?.role === USER_ROLE.AGENT
            ? [agentCurrencies, fn, hasOneCurrency]
            : [projectCurrencies, fn, hasOneCurrency]
    )
}

export default useUserCurrencies;
