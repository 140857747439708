import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import useIncludedColumns from 'hooks/useIncludedColumns';

import Table from "components/common/table";
import Tooltip from "components/common/tooltip";

import MainDashboardLayout from "components/layouts/main";

import AgentTransactionsReportFilters from "../agentTransactionsReport/filters.component";
import CashierTransactionsReportFilters from "../cashierTransactionsReport/filters.component";
import BetshopTransactionsReportFilters from "../betshopTransactionsReport/filters.component";

import { isMobile } from "utils/common";
import { makeTransactionTextShort } from "utils/wallet";
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import ApiUrls from 'constants/api.constants';

import { WALLET_TYPE, WALLET_OWNER_TYPE, WALLET_OPERATION_TYPE } from "constants/wallet.constants";
import { TRANSACTIONS_REPORT } from "constants/pageName.constants";
import { getTableColumns } from "./columns";
import { SEARCH_TYPE } from "components/common/search/constants";

import useFormat from "hooks/useFormat";
import useDate from "hooks/useDate";

import sortingType from "types/common/sorting.type";
import transactionsReportType from "types/reports/transactionsReport.type";
import useProjectType from "hooks/useProjectType";

/** Transactions Report Page Component */
const TransactionsReportComponent = ({
    getTransactionsReport,
    report,
    isLoading,
    total,
    setTransactionsReportSorting,
    setTransactionsReportFilters,
    sorting,
    filters,
    title,
    globalProjectId,
    type
}) => {
    const { t } = useTranslation();

    const { formatAmount, formatNumber } = useFormat();

    const { dateService } = useDate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: TRANSACTIONS_REPORT });

    const isMobileDevice = isMobile();

    const { hasLiteMode } = useProjectType();

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => setTransactionsReportFilters({
        ...filters,
        groupId: value
    })

    //#endregion

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        const columnsDataObj = tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount,
                hasLiteMode
            }
        })

        if (!isMobileDevice) {
            columnsDataObj.columnsForExport = columnsDataObj.columnsForExport.concat([
                { title: t("backoffice.reports.transactionId"), key: "transactionId" },
                { title: t("backoffice.reports.id"), key: "id" },
                { title: t("backoffice.reports.notes"), key: "notes" },
                { title: t("backoffice.reports.ownerType"), key: "ownerType" },
                { title: t("backoffice.reports.walletType"), key: "walletType" },
                { title: t("backoffice.reports.before"), key: "before" },
                { title: t("backoffice.reports.after"), key: "after" },
                { title: t("backoffice.reports.ownerId"), key: "ownerId" },
                { title: t("backoffice.reports.ownerName"), key: "ownerName" },
                { title: t("backoffice.reports.userId"), key: "userId" },
                { title: t("backoffice.reports.userName"), key: "userName" },
                { title: t("backoffice.reports.associatedTransactionId"), key: "associatedTransactionId" },
                { title: t("backoffice.reports.associatedTransactionTime"), key: "associatedTransactionTime" },
                { title: t("backoffice.reports.associatedTransactionType"), key: "associatedTransactionType" }
            ])
        }

        return columnsDataObj;
    }, [isMobileDevice, includedColumns, formatNumber, formatAmount])

    /** Function for transactions mapping
       * @function
       * @param {array} transactions
       * @returns {array}
       * @memberOf TransactionsReportComponent
    */
    const groupTransactionByUserName = transactions => {
        const result = {};
        transactions.forEach(transaction => {
            let key = transaction.ownerName + "_" + transaction.ownerType;
            if (!result[key]) {
                result[key] = {}
            }
            result[key]['ownerName'] = transaction.ownerName;
            result[key]['ownerType'] = transaction.ownerType;
            result[key][transaction.walletType] = {
                before: transaction.before,
                after: transaction.after
            }
        })
        return Object.keys(result).map(key => ({ ownerName: key, ...(result[key]) }));
    }

    const getTransactions = record => record.transactions.filter(transaction => transaction.transactionItems || filters.includeRelated)

    /** Function to render expendable part of table row
       * @function
       * @param {object} record
       * @returns {JSX}
       * @memberOf TransactionsReportComponent
    */
    const renderTransactions = record => {
        return (
            <div className="rt--wallet-report rt--pl-30 rt--pr-30">
                {
                    getTransactions(record).map((transaction, index) => (
                        <div
                            className={"rt--wallet-report-item rt--mb-8" + (index > 0 && !getTransactions(record)[0].transactionItems ? " rt--wallet-report-item-first-hidden" : "")}
                            key={transaction.transactionId}
                        >
                            {
                                index > 0 && !getTransactions(record)[0].transactionItems && <i className="icon-pointing_arrow rt--font-bigest rt--mr-4" />
                            }
                            <div className="rt--wallet-report-item-header rt--mb-6 rt--flex rt--align-center">

                                {
                                    !transaction.transactionItems && (
                                        <Fragment>
                                            {
                                                index !== 0 && <i className="icon-pointing_arrow rt--font-bigest rt--mr-4" />
                                            }


                                            <div className="rt--wallet-report-item-header-marker rt--mr-4" />
                                        </Fragment>
                                    )
                                }
                                {
                                    transaction.transactionItems ? (
                                        <Fragment>
                                            <div>
                                                <b className="rt--title rt--font-normal rt--font-bold ">{makeTransactionTextShort(transaction.transactionType)}</b>
                                                <span className="rt--title rt--font-normal rt--font-regular rt--pl-4 rt--pr-4">{`${t("backoffice.common.on")} ${dateService.format(transaction.transactionTime)}`}</span>
                                                <span className="rt--title rt--font-normal rt--font-regular rt--pl-4 rt--pr-4">{`${t("backoffice.common.by")} ${transaction.transactionItems[0]?.userName}`}</span>
                                            </div>
                                            <Tooltip
                                                className="rt--notes"
                                                title={transaction.notes}
                                                trigger={["hover", "click"]}
                                                placement="bottomLeft"
                                                enableMobile={true}
                                            >
                                                <i className="icon-notes rt--font-normal rt--cursor-pointer" />
                                            </Tooltip>

                                        </Fragment>
                                    ) : (
                                        <span className="rt--title rt--font-small rt--font-regular">{`${makeTransactionTextShort(transaction.transactionType)} ${t("backoffice.common.on")} ${dateService.format(transaction.transactionTime)}`}</span>
                                    )
                                }
                            </div>
                            <div className="rt--wallet-report-item-content">
                                {
                                    transaction.transactionItems && transaction.transactionItems.length > 1 && (
                                        <div className="rt--wallet-report-item-content-border" />
                                    )
                                }

                                {
                                    transaction.transactionItems ?
                                        isMobile() ?
                                            renderTransactionItemsForMobile(transaction.transactionItems) :
                                            renderTransactionItemsForDesktop(transaction.transactionItems) :
                                        null

                                }

                            </div>
                            {
                                (transaction.transactionItems && record.transactions.filter(tr => tr.transactionItems).length > 1 && index !== getTransactions(record).length - 1) && (
                                    <div className="rt--wallet-report-item-separator rt--mt-8" />
                                )
                            }

                        </div>
                    ))
                }
            </div>
        )
    }

    /** Function to render single transaction for desktop
       * @function
       * @param {object} record
       * @returns {JSX}
       * @memberOf TransactionsReportComponent
    */
    const renderTransactionItemsForDesktop = transactionItems => {
        return (
            <div className="rt--flex rt--align-center rt--wallet-report-item-content-inner">
                <div className="rt--wallet-report-item-content-inner-user">
                    {
                        groupTransactionByUserName(transactionItems).map(item => (
                            <div className="rt--wallet-report-item-content-inner-user-item rt--mb-10 rt--pr-8" key={item.ownerName}>
                                <div className="rt--flex rt--align-center">
                                    <i className={
                                        (
                                            item.ownerType === WALLET_OWNER_TYPE.BETSHOP ? "icon-retail" :
                                                item.ownerType === WALLET_OWNER_TYPE.PLAYER ? "icon-player" : "icon-agent"
                                        ) + " rt--font-biger"
                                    } />
                                    <span className="rt--title rt--font-normal rt--font-regular rt--pl-4" title={item.ownerName}>{item.ownerName}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="rt--wallet-report-item-content-inner-wallet">
                    {
                        groupTransactionByUserName(transactionItems).map(item => (
                            <div className="rt--wallet-report-item-content-inner-wallet-item rt--mb-10" key={item.ownerName}>
                                <div className="rt--flex rt--align-center">
                                    {
                                        Object.values(WALLET_TYPE).map(walletType => (
                                            <Fragment key={walletType}>
                                                {
                                                    item[walletType] && (
                                                        <div className="rt--flex rt--align-center rt--pl-8 rt--pr-8">
                                                            <div className="rt--flex rt--align-center">
                                                                <span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
                                                                    {
                                                                        walletType === WALLET_TYPE.BALANCE ? t("backoffice.common.balance") :
                                                                            walletType === WALLET_TYPE.CREDIT ? t("backoffice.common.credit") :
                                                                                walletType === WALLET_TYPE.CREDIT_LINE ? t("backoffice.common.creditLine") : t("backoffice.common.limit")
                                                                    }
                                                                    :</span>
                                                            </div>
                                                            <div className="rt--flex rt--align-center">
                                                                <span className="rt--title rt--font-normal rt--font-regular rt--pr-4">
                                                                    {
                                                                        formatNumber(item[walletType]['before'])
                                                                    }
                                                                </span>
                                                                <i className={(item[walletType]['before'] < item[walletType]['after'] ? "icon-arrow-up-right" : "icon-arrow-down-right") + " rt--font-normal"} />
                                                                <span className="rt--title rt--font-normal rt--font-regular rt--pl-4">
                                                                    {
                                                                        formatNumber(item[walletType]['after'])
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </Fragment>
                                        ))
                                    }

                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    /** Function to render single transaction for mobile
       * @function
       * @param {object} record
       * @returns {JSX}
       * @memberOf TransactionsReportComponent
    */
    const renderTransactionItemsForMobile = transactionItems => {
        return (
            <div className="rt--wallet-report-item-content-inner">
                {
                    groupTransactionByUserName(transactionItems).map(item => (
                        <div className="rt--flex rt--flex-col rt--wallet-report-item-content-inner-item rt--pt-6 rt--pb-6 rt--pl-16 rt--pr-16 rt--mb-8" key={item.ownerName}>
                            <div className="rt--flex rt--align-center rt--mb-4">
                                <i className={
                                    (
                                        item.ownerType === WALLET_OWNER_TYPE.BETSHOP ? "icon-retail" :
                                            item.ownerType === WALLET_OWNER_TYPE.PLAYER ? "icon-player" : "icon-agent"
                                    ) + " rt--font-biger"
                                } />
                                <span className="rt--title rt--font-bid rt--font-regular rt--pl-4" title={item.ownerName}>{item.ownerName}</span>
                            </div>
                            <div className="rt--flex rt--align-center rt--wallet-report-item-content-inner-item-wallet">
                                {
                                    Object.values(WALLET_TYPE).map(walletType => (
                                        <Fragment key={walletType}>
                                            {
                                                item[walletType] && (
                                                    <div className="rt--flex-equal">
                                                        <div className="rt--flex rt--align-center">
                                                            <span className="rt--title rt--font-small rt--wallet-report-item-content-inner-item-wallet-title">
                                                                {
                                                                    walletType === WALLET_TYPE.BALANCE ? t("backoffice.common.balance") :
                                                                        walletType === WALLET_TYPE.CREDIT ? t("backoffice.common.credit") :
                                                                            walletType === WALLET_TYPE.CREDIT_LINE ? t("backoffice.common.creditLine") : t("backoffice.common.limit")
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="rt--flex rt--align-center">
                                                            <span className="rt--title rt--font-normal rt--font-regular rt--pr-4">
                                                                {
                                                                    formatNumber(item[walletType]['before'])
                                                                }
                                                            </span>
                                                            <i className={(item[walletType]['before'] < item[walletType]['after'] ? "icon-arrow-up-right" : "icon-arrow-down-right") + " rt--font-small"} />
                                                            <span className="rt--title rt--font-normal rt--font-regular rt--pl-4">
                                                                {
                                                                    formatNumber(item[walletType]['after'])
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </Fragment>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    /** Function to map filters to request
       * @function
       * @returns {object}
       * @memberOf TransactionsReportComponent
    */
    const mapFiltersToRequest = () => {
        let result = {
            ...filters
        }
        let participants = null;
        if (type === WALLET_OWNER_TYPE.AGENT) {

            if (result.participant1Type || result.participant2Type) {
                participants = [];
                if (result.participant1Type) {
                    const participant1 = {
                        ownerType: result.participant1Type,
                    }
                    if (result.participant1) {
                        participant1.userNameOrId = filters.participant1
                    }
                    participants.push(participant1)
                }
                if (result.participant2Type) {
                    const participant2 = {
                        ownerType: result.participant2Type,
                    }

                    if (result.participant2) {
                        participant2.userNameOrId = filters.participant2
                    }

                    participants.push(participant2)
                }
            }
            result.participants = participants;
            delete result.participant1;
            delete result.participant2;
            delete result.participant1Type;
            delete result.participant2Type;
            delete result.includeRelated;
        } else if (type === WALLET_OWNER_TYPE.CASHIER) {
            if (!result.transactionTypes || result.transactionTypes.length === 0) {
                result.transactionTypes = [
                    WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BETSHOP,
                    WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_FROM_BETSHOP,
                    WALLET_OPERATION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER
                ]
            }

            let participants = "";
            if (result.cashier || result.player) {
                participants = [];

                if (result.cashier) {
                    participants.push({
                        ownerType: WALLET_OWNER_TYPE.CASHIER,
                        userNameOrId: result.cashier
                    })
                }

                if (result.player) {
                    participants.push({
                        ownerType: WALLET_OWNER_TYPE.PLAYER,
                        userNameOrId: filters.player
                    })
                }
            }
            result.participants = participants;

            delete result.cashier;
            delete result.player;
        } else if (type === WALLET_OWNER_TYPE.BETSHOP) {
            if (!result.transactionTypes || result.transactionTypes.length === 0) {
                result.transactionTypes = [
                    WALLET_OPERATION_TYPE.BET,
                    WALLET_OPERATION_TYPE.CANCEL_BET,
                    WALLET_OPERATION_TYPE.PAIDOUT,
                    WALLET_OPERATION_TYPE.WON,
                    WALLET_OPERATION_TYPE.REJECT_BET,
                    WALLET_OPERATION_TYPE.RECALCULATE,
                    WALLET_OPERATION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER,
                    WALLET_OPERATION_TYPE.BETSHOP_MANAGER_WITHDRAWAL_CASHIER,
                    WALLET_OPERATION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT,
                    WALLET_OPERATION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT,
                ]
            }

            let participants = "";
            if (result.cashier) {
                participants = [];

                if (result.cashier) {
                    participants.push({
                        ownerType: WALLET_OWNER_TYPE.CASHIER,
                        userNameOrId: result.cashier
                    })
                }
            }

            result.participants = participants;

            delete result.cashier;
        }

        return result;
    }

    return (
        <MainDashboardLayout
            header={
                {
                    columns: {
                        columns: columnsThatCanBeIncluded,
                        onApply: keepAppliedColumns,
                        defaultSelectedColumns: includedColumns
                    },
                    search: {
                        type: SEARCH_TYPE.NUMERIC,
                        onSearch: handleSearchChange,
                        loadFn: getTransactionsReport,
                        placeholder: t("backoffice.reports.transactionGroupId"),
                        numeric: true,
                        maxLength: 12
                    },

                    filters: type === WALLET_OWNER_TYPE.AGENT ? <AgentTransactionsReportFilters /> :
                        type === WALLET_OWNER_TYPE.CASHIER ? <CashierTransactionsReportFilters /> :
                            type === WALLET_OWNER_TYPE.BETSHOP ? <BetshopTransactionsReportFilters /> : undefined,

                    export: hasPermission({
                        resource: type === WALLET_OWNER_TYPE.AGENT ? PERMISSION_RESOURCE.REPORTS_AGENT_TRANSACTIONS :
                            type === WALLET_OWNER_TYPE.BETSHOP ? PERMISSION_RESOURCE.REPORTS_BETSHOP_TRANSACTIONS :
                                PERMISSION_RESOURCE.REPORTS_CASHIER_TRANSACTIONS, action: PERMISSION_ACTION.EXPORT
                    }) ? {
                        columns: columnsForExport,
                        tableName: title,
                        url: type === WALLET_OWNER_TYPE.AGENT ? ApiUrls.EXPORT_AGENT_TRANSACTIONS_REPORT : ApiUrls.EXPORT_BETSHOP_TRANSACTIONS_REPORT,
                        filters: mapFiltersToRequest(),
                        isPostRequest: true
                    } : undefined,

                    breadcrumbs: {
                        items: [
                            { title: title }
                        ]
                    }
                }
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={report}
                loadFn={getTransactionsReport}
                sorting={sorting}
                setSortingFn={setTransactionsReportSorting}
                total={total}
                disableFullView={true}
                uniqueKey="groupId"
                updateProps={[globalProjectId]}
                expandable={{
                    details: record => record.transactions ? ({
                        content: renderTransactions(record),
                        data: record.transactions || [],
                        uniqueKey: "transactionId",
                    }) : null,
                    type: "content",
                    title: t("backoffice.reports.viewTransaction")
                }}
            />
        </MainDashboardLayout>
    )
};

/** TransactionsReportComponent propTypes
 * PropTypes
*/
TransactionsReportComponent.propTypes = {
    /** Function to get transactions report */
    getTransactionsReport: PropTypes.func,
    /** Transactions report  */
    report: PropTypes.arrayOf(transactionsReportType),
    /** Is true when loading transactions report */
    isLoading: PropTypes.bool,
    /** Transactions report total count */
    total: PropTypes.number,
    /** Function to set transactions report sorting details */
    setTransactionsReportSorting: PropTypes.func,
    /** Function to set transactions report sorting details */
    setTransactionsReportFilters: PropTypes.func,
    /** Transactions report sorting details */
    sorting: sortingType,
    /** Transactions report filters */
    filters: PropTypes.object,
    /** Table title */
    title: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Report type */
    type: PropTypes.oneOf([WALLET_OWNER_TYPE.CASHIER, WALLET_OWNER_TYPE.BETSHOP, WALLET_OWNER_TYPE.AGENT])
};

const mapStateToProps = (state) => {
    return {
        globalProjectId: state.common.globalProjectId,
    };
};

export default connect(
    mapStateToProps,
    null
)(TransactionsReportComponent);
