import React, { useState, Fragment, useMemo, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Spin, Space, Radio } from "antd";

import TextAreaInput from 'components/common/textAreaInput';
import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';

import FavoriteAmounts from '../favoriteAmounts';

import { isMobile, triggerCustomEvent } from 'utils/common';
import { isResponseSuccess } from 'utils/request';

import { amountValidator, formatNotes} from "../../../../helpers";

import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';
import { AMOUNT_REGEX } from 'constants/regex.constants';

import {
    TRANSACTION_AMOUNT_ACTION_TYPE,
    TRANSFER_ENTITY_TYPE,
    TRANSFER_POPUP_OK_ACTION_EVENT_NAME,
    TRANSFER_SUCCESS_EVENT_NAME
} from '../../../../constants';

import useProjectType from 'hooks/useProjectType';

import useTransfer from '../../../../hooks/useTransfer';

import {
    getProjectCurrencies,
    adjustBalance
} from '../../../../api';

const SuperAgentTransferForm = () => {
    const { t } = useTranslation();

    const { transferData, setSaving, setTransferResultData } = useTransfer();

    const { currencyCode } = transferData ?? {};

    const { hasLiteMode } = useProjectType();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue } = formInstance;

    const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);

    const [currencies, setCurrencies] = useState([])

    const [selectedCurrency, setSelectedCurrency] = useState(currencyCode ?? null);

    const currencyFields = useMemo(() => {
        return currencies.map(cur => (
            { value: cur.code.toUpperCase(), text: cur.code.toUpperCase()}
        ))
    }, [currencies])

    /** Load currencies */
    useEffect(() => {
        if(!currencyCode){
            setIsCurrencyLoading(true);
            getProjectCurrencies()
                .then(
                    result => {
                        setCurrencies(result);
                        if(result.length > 0){
                            let defaultCurrency = null;
                            if(result.length === 1){
                                defaultCurrency = result[0].code;
                            } else {
                                defaultCurrency = result.find(c => c.isDefault);
                                if(defaultCurrency){
                                    defaultCurrency = defaultCurrency.code
                                } else {
                                    defaultCurrency = result[0].code;
                                }
                            }
                            setSelectedCurrency(defaultCurrency);
                            setFieldsValue({currencyCode: defaultCurrency})
                        }
                    }
                )
                .finally(
                    () => setIsCurrencyLoading(false)
                );
            }
    }, [])

    const handleCurrencyChange = value => {
        setSelectedCurrency(value);
    }

    const handleFavAmountClick = value => {
        const currentValue = getFieldValue("amount") ?? 0;
        setFieldsValue({amount: Number(currentValue) + value})
    }

    const onTransferRequest = data => {
        if (isResponseSuccess(data)) {
            const resp = data.value;
            if(resp){
                setTransferResultData(resp?.wallets);
                triggerCustomEvent(
                    TRANSFER_SUCCESS_EVENT_NAME,
                    {
                        transactionType : WALLET_TRANSACTION_TYPE.AGENT_DEPOSIT,
                        entityType: TRANSFER_ENTITY_TYPE.PLAYER,
                        wallets: resp?.wallets
                    }
                );
            }
        }
    }

    const handler = useCallback(() => {
        validateFields()
            .then(data => {
                setSaving(true);

                const d = {
                    notes: formatNotes(data.notes),
                    currencyCode: data.currencyCode,
                    amount: Number(data.amount)
                }

                adjustBalance(d, data.amountActionType)
                    .then(data => onTransferRequest(data))
                    .finally(() => setSaving(false));

            }).catch(ex => {
                console.log(ex)
            })

    }, [])

    /** On Transfer */
    useEffect(() => {
        document.addEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);
        }
    }, [handler])

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
            initialValues={{
                amountActionType: TRANSACTION_AMOUNT_ACTION_TYPE.ADD,
                currencyCode: currencyCode ?? null
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        name="amountActionType"
                        className="rt--form-item-without-height"
                        label={`${t("backoffice.payments.amountAdjustment")} *`}
                    >
                        <Radio.Group
                            options={[
                                { label: t('backoffice.wallet.increase'), value: TRANSACTION_AMOUNT_ACTION_TYPE.ADD },
                                { label: t('backoffice.wallet.decrease'), value: TRANSACTION_AMOUNT_ACTION_TYPE.REDUCE }
                            ]}
                        />
                    </Form.Item>
                </Col>
                {
                    !isCurrencyLoading ? (
                        <Fragment>
                            <Col span={24}>
                                {
                                    hasLiteMode ? (
                                        <Form.Item
                                            label={`${t('backoffice.wallet.amountToSend')} *`}
                                            name="amount"
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                { validator: amountValidator }
                                            ]}
                                            validateFirst
                                            className="rt--general-form-item"
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                        >

                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                autoFocus={!isMobile()}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            label={`${t('backoffice.wallet.amountToSend')} *`}
                                            className="rt--form-item-without-margin rt--spend-amount"
                                        >
                                            <Space.Compact
                                                className='rt--transfer-amount'
                                            >
                                                <Form.Item
                                                    className='rt--transfer-amount-currency'
                                                    rules={[
                                                        { required: true, message: t('backoffice.validation.fieldRequired') },
                                                    ]}
                                                    name="currencyCode"
                                                >
                                                    <Select
                                                        placeholder="CUR"
                                                        value={selectedCurrency?.toUpperCase()}
                                                        onChange={handleCurrencyChange}
                                                        options={currencyFields}
                                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                        showArrow={!Boolean(currencyCode)}
                                                        disabled={Boolean(currencyCode)}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="amount"
                                                    rules={[
                                                        { required: true, message: t('backoffice.validation.fieldRequired') },
                                                        { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                        { validator: amountValidator }
                                                    ]}
                                                    validateFirst
                                                    className="rt--transfer-amount-amount rt--general-form-item"
                                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                >

                                                    <NumericInput
                                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                        autoFocus={!isMobile()}
                                                    />
                                                </Form.Item>

                                            </Space.Compact>
                                        </Form.Item>
                                    )
                                }
                            </Col>
                            <Col span={24}>
                                <FavoriteAmounts
                                    handleClick={handleFavAmountClick}
                                    entityType={TRANSFER_ENTITY_TYPE.AGENT}
                                    currencyCode={selectedCurrency?.toUpperCase()}
                                />
                            </Col>
                        </Fragment>
                    ) : (
                        <div className='rt--width-full rt--transfer-loading'>
                            <Spin />
                        </div>
                    )
                }

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.wallet.notes')}
                        name="notes"
                        rules={[
                            {max: 1000, message: t('backoffice.validation.fieldInvalid')}
                        ]}
                        className="rt--form-item-without-margin rt--general-form-item"
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                    >
                        <TextAreaInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                            maxLength={1000}
                            rows={6}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default SuperAgentTransferForm;
