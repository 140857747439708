import { isNumber } from "NEW/src/core/helpers/typeChecks/isNumber";
import { isString } from "NEW/src/core/helpers/typeChecks/isString";

export const classNames = (...classNames) => {
	let classes = "";

	for (let className of classNames) {
		if (!className) {
			continue;
		}

		if (isString(className) || isNumber(className)) {
			classes = classes !== "" ? `${classes} ${className}` : `${className}`;
		}
	}

	return classes;
};
