import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';

import { createPermissionGroup } from "store/actions/dashboard/userManagement/permissionGroups/permissionGroups.action";

import { isFranchisingMode } from "utils/common";

import { SECURITY_LEVELS, USER_TYPE } from 'constants/user.constants';
import { PERMISSION_TYPE } from 'constants/permissions.constants';
import { DESCRIPTION_REGEX, NAME_REGEX } from 'constants/regex.constants';

import Paths from 'constants/path.constants';

import userInfoType from 'types/profile/userInfo.type';

import { isMobile } from 'utils/common';

/** Company Creating Popup Component */
const PermissionGroupCreateComponent = ({
    isSaving,
    createPermissionGroup,
    globalCompanyId,
    userInfo,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const navigate = useNavigate();

    const userType = Number((new URLSearchParams(location.search)).get("type") || USER_TYPE.ADMIN);

    /** Fires when form submitted
       * @function
       * @memberOf PermissionGroupCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(({ name, description, securityLevel, type = PERMISSION_TYPE.AGENT }) => {
                const permissionType = Number(userType) === USER_TYPE.ADMIN ? PERMISSION_TYPE.ADMIN : isFranchisingMode() ? PERMISSION_TYPE.BETSHOP_OWNER : type;
                const d = { name, description };
                if (userType === USER_TYPE.ADMIN) {
                    d.securityLevel = securityLevel;
                }
                createPermissionGroup(
                    d,
                    permissionType,
                    ({ id, name, type }) => {
                        navigate(`${Paths.PERMISSION_GROUPS_EDIT}/${id}?type=${userType}&cid=${globalCompanyId}&name=${name}&groupType=${type}`)
                    });
            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.permissiongroups.createPermissionGroup')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    type: PERMISSION_TYPE.AGENT,
                    ...(userType === USER_TYPE.ADMIN ? { securityLevel: userInfo.securityLevel } : {})
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.permissiongroups.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.permissiongroups.name')}`}
                        >
                            <Input
                                maxLength={30}
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.permissiongroups.name')}`}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.permissiongroups.description')}
                            name="description"
                            rules={[
                                { pattern: DESCRIPTION_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className="rt--general-form-item"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.permissiongroups.description')}`}
                        >
                            <TextAreaInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.permissiongroups.description')}`}
                                maxLength={150}
                                rows={4}
                            />
                        </Form.Item>
                    </Col>
                    {
                        userType === USER_TYPE.ADMIN && (
                            <Col span={24} >
                                <Form.Item
                                    label={t('backoffice.permissiongroups.securityLevel')}
                                    name="securityLevel"
                                    className='rt--form-item-without-margin'
                                >
                                    <Select
                                        options={
                                            SECURITY_LEVELS
                                                .filter(level => level <= userInfo.securityLevel)
                                                .map(level => (
                                                    { value: level, text: level }
                                                ))
                                        }                                    
                                        placeholder= {`${t('backoffice.common.select')} ${t('backoffice.permissiongroups.securityLevel')}`}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>
            </Form>
        </Modal>
    )
}

/** PermissionGroupCreateComponent propTypes
    * PropTypes
*/
PermissionGroupCreateComponent.propTypes = {
    /** Redux state property, is true when creating permission group request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create permission group */
    createPermissionGroup: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createPermissionGroup: (permissionGroup, type, onSuccess) => {
            dispatch(createPermissionGroup(permissionGroup, type, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.permissionGroups.isSaving,
        globalCompanyId: state.common.globalCompanyId,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroupCreateComponent)