import React, {Fragment, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Menu } from 'antd';

import Dropdown from "components/common/dropdown";
import Search from "components/common/search";

import { changeProject } from 'store/actions/dashboard/profile/userInfo.action';

import { getUser } from 'utils/auth';

import { USER_ROLE } from 'constants/user.constants';
import { SEARCH_TYPE } from 'components/common/search/constants';

import companyType from "types/company/company.type";
import projectType from 'types/project/project.type';

const DROPDOWN_TYPE = {
    COMPANIES: 1,
    PROJECTS: 2
}

/** Companies global filter dropdown component on Header for mobile */
const GlobalCompaniesMobileDropdown = ({
    changeProject,
    globalCompanyId,
    globalProjectId,
    allCompanies,
    companies,
    projects,
    searchCompanyValue,
    searchProjectValue,
    setSearchCompanyValue,
    setSearchProjectValue,
    disabled
}) => {
    const { t } = useTranslation();

    const hideProjectsDropdown = getUser()?.role !== USER_ROLE.ADMIN;

    const [selectedDropdownType, setSelectedDropdownType] = useState(hideProjectsDropdown ? DROPDOWN_TYPE.COMPANIES : DROPDOWN_TYPE.PROJECTS)
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [selectedProject, setSelectedProject] = useState(globalProjectId);
    const [selectedCompany, setSelectedCompany] = useState(globalCompanyId)

    const items = selectedDropdownType === DROPDOWN_TYPE.PROJECTS ? projects : companies;

    const searchFn = selectedDropdownType === DROPDOWN_TYPE.PROJECTS ? setSearchProjectValue : setSearchCompanyValue;

    const globalItemId = selectedDropdownType === DROPDOWN_TYPE.PROJECTS ? selectedProject : selectedCompany;

    const menuItems = useMemo(() => {
        let res = [];

        if (selectedDropdownType === DROPDOWN_TYPE.PROJECTS && globalCompanyId) {
            res.push(
                {
                    className: "rt--companies-dropdown-menu-title",
                    disabled: true,
                    key: "menuTitle",
                    label: (
                        <span className='rt--title rt--font-big rt--font-bold'>
                            {companies.find(p => p.id === globalCompanyId)?.name ?? ""}
                        </span>
                    )
                }
            );
        }

        if (items.length > 0) {
            res = [
                ...res,
                ...items.map(item => (
                    {
                        key: item.id,
                        className: (
                            (item.id === globalItemId ? "rt--companies-dropdown-menu-active" : "") +
                            (selectedDropdownType === DROPDOWN_TYPE.PROJECTS ? " rt--companies-dropdown-menu-nested" : "")
                        ),
                        label: (
                            <>
                                <span className='rt--title rt--font-regular rt--font-normal'>{item.name}</span>
                                {
                                    item.id === globalItemId && <i className='icon-ok rt--font-bigest' />
                                }
                            </>
                        )
                    }
                ))
            ]
        } else {
            res.push(
                {
                    key: "notFound",
                    className: "rt--companies-dropdown-empty",
                    disabled: true,
                    label: t("backoffice.common.notFound")
                }
            );
        }

        return res;
    }, [items, globalCompanyId, globalItemId, selectedDropdownType]);

    useEffect(() => {
        setSelectedCompany(globalCompanyId)
    }, [globalCompanyId])

    useEffect(() => {
        setSelectedProject(globalProjectId)
    }, [globalProjectId])

    useEffect(() => {
        if(hideProjectsDropdown){
            setSelectedDropdownType(DROPDOWN_TYPE.COMPANIES)
        } else {
            setSelectedDropdownType(DROPDOWN_TYPE.PROJECTS)
        }
    }, [hideProjectsDropdown])

    /** Fires on dropdown change
       * @function
       * @param {string}
       * @param {string} type - company/project
       * @memberOf GlobalCompaniesMobileDropdown
   */
    const onChange = (value, type) => {
        if (type === DROPDOWN_TYPE.COMPANIES) {
            setSelectedCompany(value);
        } else {
            setSelectedProject(value);
        }
    }

    useEffect(() => {
        if (!dropdownVisible) {
            setSelectedDropdownType(hideProjectsDropdown ? DROPDOWN_TYPE.COMPANIES : DROPDOWN_TYPE.PROJECTS);
            setSearchCompanyValue("");
            setSearchProjectValue("");
        } else {
            setSelectedCompany(globalCompanyId);
            setSelectedProject(globalProjectId);

        }
    }, [dropdownVisible])


    /** Fires on dropdown ok Button click
       * @function
       * @memberOf GlobalCompaniesMobileDropdown
   */
    const handleCancel = () => {
        if (selectedDropdownType === DROPDOWN_TYPE.PROJECTS) {
            setSelectedDropdownType(DROPDOWN_TYPE.COMPANIES)
        } else {
            setDropdownVisible(false)
        }
    }

    /** Fires on dropdown ok Button click
       * @function
       * @memberOf GlobalCompaniesMobileDropdown
   */
    const handleOk = () => {
        if (selectedDropdownType === DROPDOWN_TYPE.PROJECTS) {
            changeProject(globalCompanyId, selectedProject)
            setDropdownVisible(false)
        } else {
            if(selectedCompany !== globalCompanyId){
                const c = allCompanies.find(c => c.id === selectedCompany);
                let newProjectId;
                if(c && c.projects && c.projects[0]){
                    newProjectId = c.projects[0].id;
                }
                changeProject(selectedCompany, newProjectId)

            }
            if(!hideProjectsDropdown){
                setSelectedDropdownType(DROPDOWN_TYPE.PROJECTS);
            } else {
                setDropdownVisible(false)
            }

        }
    }

    return <Dropdown
        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
        disabled={disabled}
        dropdownRender={(menu) => (
            <Fragment>
                <div className='rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8'>
                    <Search
                        type={SEARCH_TYPE.INPUT}
                        onChange={searchFn}
                        value={selectedDropdownType === DROPDOWN_TYPE.PROJECTS ? searchProjectValue : searchCompanyValue}
                    />
                </div>

                {menu}
            </Fragment>
        )}
        menu={{
            onClick: e => e.key && onChange(e.key, selectedDropdownType),
            className: "rt--companies-dropdown-menu",
            selectedKeys: [globalItemId, "search"],
            items: menuItems
        }}
        title={`${t("backoffice.common.choose")} ${selectedDropdownType === DROPDOWN_TYPE.PROJECTS ? t("backoffice.common.project") : t("backoffice.companies.company")}`}
        popupVisible={dropdownVisible}
        onVisibleChange={visible => setDropdownVisible(visible)}
        footer={{
            okText: selectedDropdownType === DROPDOWN_TYPE.PROJECTS ? t("backoffice.common.save") : t("backoffice.common.next"),
            cancelText: selectedDropdownType === DROPDOWN_TYPE.PROJECTS ? t("backoffice.companies.changeCompany") : t("backoffice.common.cancel"),
            onOk: handleOk,
            onCancel: handleCancel
        }}
    >
        <div className="rt--companies-dropdown-content rt--companies-dropdown-content-last rt--flex rt--align-center rt--pl-12 rt--pr-4 rt--ml-1">
            <span className='rt--title rt--font-normal rt--font-bold rt--pr-4'>
                {
                    !hideProjectsDropdown ? (
                        globalProjectId ? projects.find(p => p.id === globalProjectId)?.name ?? "" : `-- ${t("backoffice.common.choose")} ${t("backoffice.common.project")} --`
                    ) : (
                        globalCompanyId ? allCompanies.find(p => p.id === globalCompanyId)?.name ?? "" : `-- ${t("backoffice.common.choose")} ${t("backoffice.common.company")} --`
                    )
                }
            </span>
            {
                !disabled && <i className='icon-down-small rt--font-bigest' />
            }

        </div>
    </Dropdown>

}

/** GlobalCompaniesMobileDropdown propTypes
    * PropTypes
*/
GlobalCompaniesMobileDropdown.propTypes = {
    /** Redux action to change global company/project id */
    changeProject: PropTypes.func,
    /** Redux action to set global project id */
    setGlobalProjectId: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Companies array */
    companies: PropTypes.arrayOf(companyType),
    /** Redux state property, represents the array of all companies  */
    allCompanies: PropTypes.arrayOf(companyType),
    /** Projects array */
    projects: PropTypes.arrayOf(projectType),
    /** Funtion to set search value for companies */
    setSearchCompanyValue: PropTypes.func,
    /** Funtion to set search value for projects */
    setSearchProjectValue: PropTypes.func,
    /** Search value for companies */
    searchCompanyValue: PropTypes.string,
    /** Search value for projects */
    searchProjectValue: PropTypes.string,
    /** Is dropdown disabled */
    disabled: PropTypes.bool,
    /** Wheter to hide projects dropdown */
    hideProjectsDropdown: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        changeProject: (companyId, projectId) => {
            dispatch(changeProject(companyId, projectId));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        allCompanies: state.profile.userInfo.companies,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    GlobalCompaniesMobileDropdown
);
