import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";

import { getProjectCurrencies } from "store/actions/dashboard/projects/currencies.action";

import { Col, Form, Row, Spin } from 'antd';
import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Select from "components/common/select";
import Input from 'components/common/input';
import BankEditActionsComponent from "./bank-edit-actions.component"

import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';

import { NAME_REGEX } from 'constants/regex.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import currencyType from "types/currency/currency.type";
import { USER_ROLE } from 'constants/user.constants';

const FORM_FIELD_NAMES = {
    BANK_NAME: "name",
    CURRENCIES: "currencies",
}

const COL_SIZES = { lg: 6, md: 12, xs: 24 }

const GeneralInfoComponent = ({
    isAvailableLoading,
    getProjectCurrencies,
    projectCurrencies,
    info,
    isLoading,
}) => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const searchParams = useParams()

    const [formInstance] = Form.useForm();
    const { setFieldsValue } = formInstance;

    const userCanModify = hasPermission({ resource: PERMISSION_RESOURCE.AGENT_PAYMENT, action: PERMISSION_ACTION.MODIFY });
    const userCanViewProjectCurrencies = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_CURRENCIES, action: PERMISSION_ACTION.VIEW });

    const isAdmin = getUser()?.role === USER_ROLE.ADMIN;

    /** Load general info and project currencies */
    useEffect(() => {
        if(!userCanViewProjectCurrencies) {
            return;
        }

        getProjectCurrencies();
    }, [])

    // Set form field values when data is loaded
    useEffect(() => {
        setFieldsValue({
            [FORM_FIELD_NAMES.BANK_NAME]: info.name,
            [FORM_FIELD_NAMES.CURRENCIES]: info.currencies,
        })
    }, [info])

    return (
        <SubTabFormDashboardLayout
            actions={userCanModify && isAdmin && <BankEditActionsComponent />}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        [FORM_FIELD_NAMES.BANK_NAME]: info.name,
                        [FORM_FIELD_NAMES.CURRENCIES]: info.currencies,
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col {...COL_SIZES}>
                            <Form.Item
                                label={`${t('backoffice.payments.bankName')} *`}
                                name={FORM_FIELD_NAMES.BANK_NAME}
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                                className={"rt--form-item-disabled"}
                            >
                                <Input
                                    placeholder={t('backoffice.payments.bankName')}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col {...COL_SIZES}>
                            <Form.Item
                                label={`${t('backoffice.payments.currencies')} *`}
                                name={FORM_FIELD_NAMES.CURRENCIES}
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                                className={"rt--form-item-disabled"}
                            >
                                <Select
                                    options={
                                        projectCurrencies.map(item => (
                                            { value: item.code.toUpperCase(), text: item.code.toUpperCase() }
                                        ))
                                    }
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    loading={isAvailableLoading}
                                    placeholder={t('backoffice.payments.selectCurrencies')}
                                    search={true}
                                    isMultiple={true}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    );
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux state property, is true when loading available currencies */
    isAvailableLoading: PropTypes.bool,
    /** Redux action to get system available currencies */
    getProjectCurrencies: PropTypes.func,
    /** Redux state property, represents the array of system available currencies  */
    projectCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, current bank general info */
    info: PropTypes.object,
    /** Redux state property, is true when agent payment is loading */
    isLoading: PropTypes.bool,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectCurrencies: () => {
            dispatch(getProjectCurrencies());
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.agents.isLoading,
        isAvailableLoading: state.systemCurrencies.isAvailableLoading,
        projectCurrencies: state.projects.edit.currencies,
        info: state.agents.edit.payment.banks.edit.info
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);