import React from 'react';
import i18n from 'translations/config';

import { WALLET_TYPE } from 'constants/wallet.constants';
import { RESPONSE_CODES } from 'constants/errorCode.constants';
import {Tooltip} from "antd";

const WALLET_TYPE_TRANSLATION_RESOURCES = {
    [WALLET_TYPE.BALANCE]: "backoffice.common.balance",
    [WALLET_TYPE.CREDIT]: "backoffice.common.credit",
    [WALLET_TYPE.CREDIT_LINE]: "backoffice.common.creditLine",
    [WALLET_TYPE.LIMIT]: "backoffice.common.limit",
}

export const columns = [
    {
        title: "backoffice.wallet.name",
        dataIndex: "userName",
        mobileLevel: 1,
        render: value => <Tooltip title={value} placement="bottomRight">{value}</Tooltip>
    },
    {
        title: "backoffice.wallet.walletType",
        dataIndex: "type",
        mobileLevel: 2,
        render: (value) =>
            WALLET_TYPE_TRANSLATION_RESOURCES[value]
                ? i18n.t(WALLET_TYPE_TRANSLATION_RESOURCES[value])
                : "",
    },
    {
        title: "backoffice.wallet.amount",
        dataIndex: "changeAmount",
        isNumeric: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.wallet.message",
        dataIndex: "message",
        mobileLevel: 4,
        render: (value, record) =>
            record.status === RESPONSE_CODES.Success ? (
                <span className="rt--success-text">
                    {i18n.t("backoffice.wallet.success")}
                </span>
            ) : (
                <Tooltip title={value}>
                    <span className="rt--error-text">
                        {value}
                    </span>
                </Tooltip>
            ),
    },
];
