import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Col, Row, Tabs } from 'antd';

import FinancialLeaderboard from 'pages/dashboard/components/financialLeaderboard';
import TabTableDashboardLayout from 'components/layouts/tab/table';
import Filters from '../../filters';

import useFirstRender from 'hooks/useFirstRender';

import { isMobile } from 'utils/common';

import ApiUrls from "constants/api.constants";
import {LEADERBOARD_TYPES} from "pages/dashboard/constants";

const TopUsers = ({
    userCurrencies,
    defaultFilters,
    updateLastUpdatedDate,
}) => {
    const { t } = useTranslation();

    const [filters, setFilters] = useState(defaultFilters)

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(defaultFilters)
        },
        dependencies: [defaultFilters, updateFilters]
    })

    return (
        !isMobile()
            ? (
                <TabTableDashboardLayout
                    header={{
                        content: (
                            <div className="rt--flex rt--align-center rt--justify-end rt--width-full">
                                <Filters userCurrencies={userCurrencies} filters={filters} updateFilters={updateFilters} />
                            </div>
                        )
                    }}

                    headerBorder={false}
                >
                    <div className='rt--financial-top-user'>
                        <Row gutter={[16, 24]}>
                            <Col lg={12} md={24}>
                                <FinancialLeaderboard
                                    endpoint={ApiUrls.GET_DASHBOARD_FINANCES_TOP_PLAYERS}
                                    updateLastUpdatedDate={updateLastUpdatedDate}
                                    filters={filters}
                                    title={t("backoffice.dashboard.topPlayers")}
                                    type={LEADERBOARD_TYPES.PLAYER}
                                />
                            </Col>
                            <Col lg={12} md={24}>
                                <FinancialLeaderboard
                                    endpoint={ApiUrls.GET_DASHBOARD_FINANCES_TOP_AGENTS}
                                    filters={filters}
                                    updateLastUpdatedDate={updateLastUpdatedDate}
                                    title={t("backoffice.dashboard.topAgents")}
                                    type={LEADERBOARD_TYPES.AGENT}
                                />
                            </Col>
                        </Row>
                    </div>
                </TabTableDashboardLayout>
            ) : (
                <TabTableDashboardLayout
                    header={{
                        filters: <Filters userCurrencies={userCurrencies} filters={filters} updateFilters={updateFilters} />
                    }}
                >
                    <Tabs
                        animated={false}
                        className="rt--tabs rt--financial-leaderboard-tabs"
                        destroyInactiveTabPane={true}
                        items={[
                            {
                                key: "0",
                                label: <span className="rt--font-big">{t("backoffice.dashboard.topAgents")}</span>,
                                children: (
                                    <FinancialLeaderboard
                                        endpoint={ApiUrls.GET_DASHBOARD_FINANCES_TOP_AGENTS}
                                        filters={filters}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                        title={t("backoffice.dashboard.topAgents")}
                                        type={LEADERBOARD_TYPES.AGENT}
                                    />
                                )
                            },
                            {
                                key: "1",
                                label: <span className="rt--font-big">{t("backoffice.dashboard.topPlayers")}</span>,
                                children: (
                                    <FinancialLeaderboard
                                        endpoint={ApiUrls.GET_DASHBOARD_FINANCES_TOP_PLAYERS}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                        filters={filters}
                                        title={t("backoffice.dashboard.topPlayers")}
                                        type={LEADERBOARD_TYPES.PLAYER}
                                    />
                                )
                            }
                        ]}
                    />
                </TabTableDashboardLayout>
            )
    );
}

TopUsers.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
    userCurrencies: PropTypes.array,
    defaultFilters: PropTypes.object,
};

export default TopUsers;
