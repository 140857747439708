import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setCashiersActionBefore, setCashiersActionFinished, setCashiersSaveActionBefore, setCashiersSaveActionFinished } from './cashiers.action';

import { SET_CASHIER_GENERAL_INFO } from "../../../../actionTypes";

const setCashierGeneralInfo = info => ({
    type: SET_CASHIER_GENERAL_INFO,
    payload: { info }
})

export const getCashierGeneralInfo = id => {
    return dispatch => {
        dispatch(setCashiersActionBefore());

        return axios({
            url: ApiUrls.GET_CASHIER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setCashierGeneralInfo(info))
            dispatch(setCashiersActionFinished());
        })
        .catch((ex) => {
            dispatch(setCashiersActionFinished());
        })
    }
}

export const saveCashierGeneralInfo = cashier => {
    return dispatch => {
        dispatch(setCashiersSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_CASHIER_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...cashier
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCashierGeneralInfo(data.value));
            } 
            dispatch(setCashiersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCashiersSaveActionFinished());
        })
    }
}
