import React from "react";

export const getTableColumns = () => ([
    {
        title: "backoffice.reports.agentId",
        dataIndex: "agentId",
        copy: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.reports.agentUserName",
        dataIndex: "userName",
        alwaysVisible: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.reports.agentLevel",
        dataIndex: "agentLevel",
        mobileLevel: 3,
        render: (value) => {
            return (
                <div className={"rt--flex rt--align-center rt--table-col-with-icon"}>
                    <i className="icon-level rt--font-bigest" />
                    <span>{value}</span>
                </div>
            )
        },
    },
    {
        title: "backoffice.reports.registrationDate",
        dataIndex: "registrationDate",
        isDateTime: true,
        mobileLevel: 4,
    },
])

export const getTableExpandColumns = ({ formatAmount }) => ([
    {
        title: "backoffice.reports.currency",
        dataIndex: "currency",
        mobileLevel: 1,
    },
    {
        title: "backoffice.reports.sportTurnover",
        dataIndex: "sportTurnover",
        mobileLevel: 2,
        render: (value, record) => formatAmount(value, record.currency),
    },
    {
        title: "backoffice.reports.sportWin",
        dataIndex: "sportWin",
        mobileLevel: 3,
        render: (value, record) => formatAmount(value, record.currency),
    },
    {
        title: "backoffice.reports.sportGgr",
        dataIndex: "sportGGR",
        mobileLevel: 4,
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.sportNgr",
        dataIndex: "sportNGR",
        mobileLevel: 5,
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.casinoTurnover",
        dataIndex: "casinoTurnover",
        mobileLevel: 6,
        render: (value, record) => formatAmount(value, record.currency),
    },
    {
        title: "backoffice.reports.casinoWin",
        dataIndex: "casinoWin",
        render: (value, record) => formatAmount(value, record.currency),
    },
    {
        title: "backoffice.reports.casinoGgr",
        dataIndex: "casinoGGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.casinoNgr",
        dataIndex: "casinoNGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.totalTurnover",
        dataIndex: "totalTurnover",
        render: (value, record) => formatAmount(value, record.currency),
    },
    {
        title: "backoffice.reports.totalWin",
        dataIndex: "totalWin",
        render: (value, record) => formatAmount(value, record.currency),
    },
    {
        title: "backoffice.reports.totalGgr",
        dataIndex: "totalGGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.totalNgr",
        dataIndex: "totalNGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
])
