import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Row, Col, Spin } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import VirtualSportsSettingsComponent from './virtualSports';
import SportbookSettingsComponent from './sportsbook';
import GoldenRaceSettingsComponent from './glodenRace';

import { getProjectProviderSettings, saveProjectProviderSettings } from "store/actions/dashboard/projects/providerSettings.action";

import { PROJECT_PROVIDER_TYPE } from "constants/project.constants"

import providerSettingsType from "types/project/providerSettings.type";


/** Project Edit Page Provider Settings Tab Component */
const ProviderSettingsComponent = ({
    getProjectProviderSettings,
    saveProjectProviderSettings,
    isSaving,
    isLoading,
    providerSettings,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    /** Fires when form submitted
       * @function
       * @param {number} type
       * @param { object } data
       * @memberOf ProviderSettingsComponent
   */
    const handleSave = (type, data) => {
        let d = {};
        d.type = type;
        if (type === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS) {
            d["virtualSportsSettings"] = data;
        } else if (type === PROJECT_PROVIDER_TYPE.SPORTBOOK) {
            d["sportsbookSettings"] = data;
        } else if (type === PROJECT_PROVIDER_TYPE.GOLDEN_RACE) {
            d["goldenRaceSettings"] = data;
        }
        saveProjectProviderSettings(d)
    }

    useEffect(() => {
        getProjectProviderSettings();
    }, [])

    return (
        <SubTabFormDashboardLayout>
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={24} xl={8} >
                        <VirtualSportsSettingsComponent
                            settings={ providerSettings?.virtualSportsSettings ?? {}}
                            onSave={data => handleSave(PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS, data)}
                            isSaving={isSaving}
                            onTabChange={onTabChange}
                        />
                    </Col>
                    <Col xs={24} sm={24} xl={8} >
                        <SportbookSettingsComponent
                            settings={ providerSettings?.sportsbookSettings ?? {}}
                            onSave={data => handleSave(PROJECT_PROVIDER_TYPE.SPORTBOOK, data)}
                            isSaving={isSaving}
                            onTabChange={onTabChange}
                        />
                    </Col>
                    <Col xs={24} sm={24} xl={8} >
                        <GoldenRaceSettingsComponent
                            settings={ providerSettings?.goldenRaceSettings ?? {}}
                            onSave={data => handleSave(PROJECT_PROVIDER_TYPE.GOLDEN_RACE, data)}
                            isSaving={isSaving}
                            onTabChange={onTabChange}
                        />
                    </Col>
                </Row>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** ProviderSettingsComponent propTypes
    * PropTypes
*/
ProviderSettingsComponent.propTypes = {
    /** Redux action to save project Provider settings */
    saveProjectProviderSettings: PropTypes.func,
    /** Redux state property, is true when provider settings is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when provider settings is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the provider settings of current editing project  */
    providerSettings: providerSettingsType,
    /** Redux action to get project provider settings */
    getProjectProviderSettings: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        saveProjectProviderSettings: data => {
            dispatch(saveProjectProviderSettings(data));
        },

        getProjectProviderSettings: () => {
            dispatch(getProjectProviderSettings())
        }
    }
)

const mapStateToProps = state => {
    return {
        providerSettings: state.projects.edit.providerSettings,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSettingsComponent)