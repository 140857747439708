import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setBetshopsActionBefore, setBetshopsActionFinished, setBetshopsSaveActionBefore, setBetshopsSaveActionFinished } from './betshops.action';

import { SET_BETSHOP_LIMIT_SETTINGS } from "../../../../actionTypes";
import { isFunction } from 'utils/common';

const setBetshopLimitSettings = data => ({
    type: SET_BETSHOP_LIMIT_SETTINGS,
    payload: { data }
})

export const getBetshopLimitSettings = id => {
    return dispatch => {
        dispatch(setBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_LIMIT_SETTINGS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: limitSettings}}) => {
            dispatch(setBetshopLimitSettings(limitSettings))
            dispatch(setBetshopsActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsActionFinished());
        })
    }
}

export const saveBetshopLimitSettings = (betshop, onSuccess) => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_BETSHOP_LIMIT_SETTINGS,
            method: Methods.POST,
            data: {
                ...betshop
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBetshopLimitSettings(data.value));

                if (isFunction(onSuccess)) {
                    onSuccess()
                }
            } 
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}
