import {ENTITY_TYPE, LEADERBOARD_TYPES} from "pages/dashboard/constants";
import {isFranchisingMode} from "utils/common";

const getCorrespondingOperationalDataTypes = (type, hasLiteMode, hasRetail) => {
    const types = { ...ENTITY_TYPE };

    delete types.CASHIER;

    if (!hasRetail || hasLiteMode) {
        delete types.BETSHOP;
    }

    if (type === LEADERBOARD_TYPES.AGENT && isFranchisingMode()) {
        delete types.AGENT;
        delete types.PLAYER;
        delete types.BETSHOP;
    }

    return types;
}

export default getCorrespondingOperationalDataTypes;
