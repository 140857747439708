import React, {useEffect, useMemo, useState} from 'react';

import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import Table from "components/common/table";
import TabTableDashboardLayout from "components/layouts/tab/table";
import Filters from "pages/reports/newProductReport/sections/player/filters.component";
import TotalsComponent from "components/common/totals";

import {NEW_PRODUCT_REPORT_PLAYER} from "constants/pageName.constants";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import {REPORT_TOTAL_TYPE} from "constants/reports.constants";
import {FILTER_TYPE} from "constants/common.constants";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";

import {tableColumnsCreator} from "utils/tableColumnsCreator";
import dateService from "utils/date";
import {hasPermission} from "utils/permissions";

import useIncludedColumns from "hooks/useIncludedColumns";
import useFormat from "hooks/useFormat";

import {getTableColumns} from "pages/reports/newProductReport/sections/player/columns";
import {
    getPlayersProductReport,
    getPlayersProductReportTotals
} from "pages/reports/newProductReport/sections/player/api";
import {isMobile} from "utils/common";

const PlayerComponent = ({ globalProjectId, currencies }) => {
    const [totals, setTotals] = useState({});
    const [data, setData] = useState({
        data: [],
        total: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState({
        page: 1,
        limit: 10
    });
    const [filters, setFilters] = useState({
        from: dateService.toISOString(dateService.yesterday(true)),
        to: dateService.toISOString(dateService.startOfTomorrow()),
        playerRegistrationDateFrom: null,
        playerRegistrationDateTo: null,
        currency: '',
        agentUserNameOrId: '',
        type: FILTER_TYPE.NETWORK
    });

    const { t } = useTranslation();
    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: NEW_PRODUCT_REPORT_PLAYER });
    const { formatAmount } = useFormat();

    const hasTotalsPermission = hasPermission({ resource: PERMISSION_RESOURCE.REPORTS_PRODUCTS_PERFORMANCE_TOTALS, action: PERMISSION_ACTION.VIEW });
    const hasExportPermission = hasPermission({ resource: PERMISSION_RESOURCE.REPORTS_PRODUCTS_PERFORMANCE, action: PERMISSION_ACTION.EXPORT });

    const filteredTotals = useMemo(() => {
        const currency = filters.currency.toLowerCase();

        if (currency && Object.keys(totals).length > 0 && totals[currency.toLowerCase()]) {
            return { [currency]: totals[currency] };
        }

        return totals;
    }, [filters, totals]);

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                formatAmount
            }
        })
    }, [includedColumns, formatAmount]);

    useEffect(() => {
        setIsLoading(true);

        getPlayersProductReport(filters, sorting)
            .then((data) => {
                setData(data);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [filters, sorting, globalProjectId]);

    useEffect(() => {
        if (!hasTotalsPermission) {
            return;
        }

        getPlayersProductReportTotals(filters)
            .then((data) => {
                setTotals(data);
            })
    }, [filters, globalProjectId, hasTotalsPermission]);

    const handleSearchChange = (searchValue) => {
        setFilters(state => ({
            ...state,
            playerUsernameOrId: searchValue,
        }));
    }

    const handleFiltersChange = (changedFilters) => {
        setFilters(changedFilters);
    }

    const handleSortingChange = (changedSorting) => {
        setSorting(changedSorting);
    }

    const headerPartsData = useMemo(() => {
        const data = {
            filters: (
                <Filters
                    filters={filters}
                    currencies={currencies}
                    onFiltersChange={handleFiltersChange}
                    globalProjectId={globalProjectId}
                />
            ),
            search: {
                placeholder: t("backoffice.reports.playerUsernameID"),
                loadFn: () => {},
                autoSuggestion: {
                    type: AUTOSUGGESTION_TYPE.PLAYER,
                    searchFieldName: "userNameOrId",
                    autoGet: false,
                    dependencies: [globalProjectId],
                },
                onSearch: handleSearchChange,
            },
            columns: {
                columns: columnsThatCanBeIncluded,
                onApply: keepAppliedColumns,
                defaultSelectedColumns: includedColumns,
            },
        };

        if (hasExportPermission) {
            data.export = {
                url: ApiUrls.EXPORT_PLAYERS_PRODUCT_REPORT,
                columns: columnsForExport,
                filters: filters,
                tableName: t('backoffice.menu.newProductReport'),
            }
        }

        return data;
    }, [columnsForExport, columnsThatCanBeIncluded, currencies, filters, globalProjectId, includedColumns, keepAppliedColumns, hasExportPermission, t]);

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={(
                hasTotalsPermission && (
                    <TotalsComponent
                        totals={filteredTotals}
                        isLoading={isLoading}
                        totalType={REPORT_TOTAL_TYPE.NEW_PRODUCT_REPORT}
                    />
                )
            )}
            isContentScrollable={isMobile() && hasTotalsPermission}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={data?.data}
                sorting={sorting}
                setSortingFn={handleSortingChange}
                total={data?.total}
                expandAllAvailable
                shouldShowExpandedColumn
            />
        </TabTableDashboardLayout>
    );
};

const mapStateToProps = (state) => ({
    globalProjectId: state.common.globalProjectId,
    currencies: state.profile.currencies,
})

export default connect(mapStateToProps)(PlayerComponent);
