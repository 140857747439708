import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {Form, Col, Row, Checkbox} from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';
import Tooltip from 'components/common/tooltip';

import LiteModeOptions from "pages/projects/edit/components/liteModeOptions";

import { createProject, getProjects } from "store/actions/dashboard/projects/projects.action";
import { getPlatforms } from "store/actions/dashboard/platform/platform.action";
import { changeProject } from 'store/actions/dashboard/profile/userInfo.action';
import { getSystemAvailableCurrencies } from "store/actions/dashboard/settings/systemCurrencies/systemCurrencies.action";

import { flagsToBinary, isFranchisingMode } from "utils/common";
import { hasProjectEditPageAccess } from 'utils/pageAccess';

import { PROJECT_TYPE } from "constants/project.constants";
import { DESCRIPTION_REGEX, NAME_REGEX } from 'constants/regex.constants';

import Paths from 'constants/path.constants';

import companyType from "types/company/company.type";
import platformType from 'types/platform/platform.type';
import currencyType from "types/currency/currency.type";

import { isMobile } from 'utils/common';
import {PROJECT_MODE, POPUP_SIZE} from 'constants/common.constants';

/** Project Creating Popup Component */
const ProjectCreateComponent = ({
    isSaving,
    createProject,
    getProjects,
    globalCompanyId,
    allCompanies,
    platforms,
    getPlatforms,
    changeProject,
    getSystemAvailableCurrencies,
    systemAvailableCurrencies,
    isAvailableLoading,
    onClose
}) => {
    const {t} = useTranslation();
    const [formInstance] = Form.useForm();
    const {validateFields, setFieldsValue} = formInstance;
    const navigate = useNavigate();

    const [selectedPlatform, setSelectedPlatform] = useState(null)

    /** Is current compnay test */
    const isCurrentCompanyTest = allCompanies.find(p => p.id === globalCompanyId)?.isTesting ?? false

    /** Load Platforms */
    useEffect(() => {
        getPlatforms();
    }, [])

    /** Load system currencies */
    useEffect(() => {
        getSystemAvailableCurrencies();
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf ProjectCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const projectType = data.type.concat([isFranchisingMode() ? PROJECT_TYPE.RETAIL_SYSTEM : PROJECT_TYPE.AGENT_SYSTEM])

                createProject(
                    {
                        ...data,
                        type: flagsToBinary(projectType)
                    },
                    record => {
                        if (hasProjectEditPageAccess(record.type)) {
                            changeProject(globalCompanyId, record.id, () => {
                                navigate(`${Paths.PROJECTS_EDIT}/${record.id}?cid=${globalCompanyId}&name=${record.name}&type=${record.type}`);
                            })
                        } else {
                            getProjects();
                            onClose();
                        }
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    const platform = platforms.find(p => p.id === selectedPlatform);

    const gateWays = platform ? platform.gateways : [];

    useEffect(() => {
        setFieldsValue({ gatewayId: undefined })
    }, [platform])

    /** Function to get project feauters list depend on envirement mode
       * @function
       * @returns {array}
       * @memberOf ProjectCreateComponent
    */
    const projectTypeChoices = () => {
        const choices = [];
        if (isFranchisingMode()) {
            choices.push({
                label: t('backoffice.projects.franchising'),
                value: PROJECT_TYPE.AGENT_SYSTEM
            })
        } else {
            choices.push({
                label: t('backoffice.projects.retail'),
                value: PROJECT_TYPE.RETAIL_SYSTEM
            })
        }

        return choices;
    }

    /** If only 1 currency available, set it by default */
    useEffect(() => {
        if (systemAvailableCurrencies.length === 1) {
            setFieldsValue({
                currencyCode: systemAvailableCurrencies[0].code.toUpperCase()
            })
        }
    }, [systemAvailableCurrencies])

    return (
        <Modal
            title={t('backoffice.projects.createProject')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    name: "",
                    isTesting: isCurrentCompanyTest,
                    type: [PROJECT_TYPE.RETAIL_SYSTEM, PROJECT_TYPE.AGENT_SYSTEM],
                    mode: PROJECT_MODE.STANDARD,
                    decimalCount: 0,
                    currencyCode: undefined,
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                maxLength={30}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.projects.description')}
                            name="description"
                            rules={[
                                { pattern: DESCRIPTION_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className="rt--general-form-item"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.description')}`}
                        >
                            <TextAreaInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.description')}`}
                                maxLength={150}
                                rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {
                            isFranchisingMode()
                                ? (
                                    <Form.Item
                                        label={t('backoffice.projects.features')}
                                        name="type"
                                        className='rt--form-item-inline'
                                    >
                                        <Checkbox.Group
                                            options={projectTypeChoices()}
                                        />
                                    </Form.Item>
                                )
                                : (
                                    <LiteModeOptions
                                        projectTypeChoices={projectTypeChoices}
                                        formInstance={formInstance}
                                    />
                                )
                        }
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className='rt--flex rt--align-center'>
                                    <span className='rt--mr-6'>{`${t('backoffice.projects.currency')} *`}</span>
                                    <Tooltip
                                        title={t("backoffice.projects.currencyFieldInfoTooltip")}
                                        trigger={["hover", "click"]}
                                        placement="topLeft"
                                        enableMobile={true}
                                    >
                                        <i className='icon-info rt--font-big' />
                                    </Tooltip>
                                </div>
                            }
                            name="currencyCode"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                        >
                            <Select
                                options={
                                    systemAvailableCurrencies
                                        .map(item => (
                                            { value: item.code.toUpperCase(), text: item.code.toUpperCase() + " - " + item.name }
                                        ))
                                }
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                loading={isAvailableLoading}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.currency')}`}
                                search={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label={t('backoffice.currencies.decimalCount')}
                            name="decimalCount"
                        >
                            <Select
                                options={[
                                    { value: 0, text: "0" },
                                    { value: 1, text: "0,1" },
                                    { value: 2, text: "0,12" },
                                    { value: 3, text: "0,123" },
                                    { value: 4, text: "0,1234" },
                                    { value: 5, text: "0,12345" },
                                    { value: 6, text: "0,123456" },
                                    { value: 7, text: "0,1234567" },
                                    { value: 8, text: "0,12345678" },
                                ]}
                                placeholder={`${t("backoffice.common.select")} ${t('backoffice.currencies.decimalCount')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.projects.platform')} *`}
                            name="platformId"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                options={
                                    platforms.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.platform')}`}
                                onChange={e => setSelectedPlatform(e)}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.projects.gateway')} *`}
                            name="gatewayId"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                            className={!platform ? "rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={
                                    gateWays.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.gateway')}`}
                                disabled={!platform}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isTesting"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.projects.isTestProject')}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** ProjectCreateComponent propTypes
    * PropTypes
*/
ProjectCreateComponent.propTypes = {
    /** Redux state property, is true when creating project request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to get projects */
    getProjects: PropTypes.func,
    /** Redux action to create project */
    createProject: PropTypes.func,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents the array of global companies  */
    allCompanies: PropTypes.arrayOf(companyType),
    /** Redux action to get platforms */
    getPlatforms: PropTypes.func,
    /** Redux state property, platforms */
    platforms: PropTypes.arrayOf(platformType),
    /** Redux action to change global company/project id */
    changeProject: PropTypes.func,
    /** Redux action to get system available currencies */
    getSystemAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the array of system available currencies  */
    systemAvailableCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, is true when loading available currencies */
    isAvailableLoading: PropTypes.bool,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createProject: (project, onSuccess) => {
            dispatch(createProject(project, onSuccess));
        },

        getPlatforms: () => {
            dispatch(getPlatforms())
        },

        getProjects: () => {
            dispatch(getProjects());
        },

        changeProject: (companyId, projectId, onSuccess) => {
            dispatch(changeProject(companyId, projectId, onSuccess));
        },

        getSystemAvailableCurrencies: () => {
            dispatch(getSystemAvailableCurrencies())
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        globalCompanyId: state.common.globalCompanyId,
        allCompanies: state.profile.userInfo.companies,
        platforms: state.platforms.platforms,
        systemAvailableCurrencies: state.systemCurrencies.systemAvailableCurrencies,
        isAvailableLoading: state.systemCurrencies.isAvailableLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreateComponent)
