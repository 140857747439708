import {DATA_TYPES, OPERATIONAL_FAKE_VALUES} from "pages/dashboard/constants";

const isOperationalDataType = (dataType) => {
    return (
        Object.entries(OPERATIONAL_FAKE_VALUES).some(([_, value]) => value === dataType) ||
        dataType === DATA_TYPES.REGISTERED_PLAYERS
    )
}

export default isOperationalDataType;
