import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';

import { isMobile, isIOS, IOSversion, isFunction } from 'utils/common';

/** InternalInput Component */

const InternalInput = forwardRef(({
	value,
	placeholder,
	type,
	...rest
}, ref) => {

	const inputRef = useRef(null);

	const versionInfo = IOSversion();

	const [hidePlaceholder, setHidePlaceholder] = useState(false)

	useEffect(() => {
		if (inputRef.current && inputRef.current.input) {
			if (value !== '' && value !== null && value !== undefined) {
				setHidePlaceholder(true)
			} else {
				setHidePlaceholder(false)
			}
		}
	}, [value])

	const assignToExternalRef = (elem) => {
		inputRef.current = elem;

		if (!Boolean(ref)) {
			return;
		}

		if (isFunction(ref)) {
			ref(elem)
		} else {
			ref.current = elem;
		}
	}

	return type !== "Password" ? (
		<AntInput
			ref={assignToExternalRef}
			{...rest}
			value={value}
			placeholder={(isMobile() && isIOS() && versionInfo.version >= 16 ? null : placeholder)}
			className={hidePlaceholder ? "rt--hide-placeholder" : ""}
		/>
	) : (
		<AntInput.Password
			ref={assignToExternalRef}
			{...rest}
			value={value}
			placeholder={(isMobile() && isIOS() && versionInfo.version >= 16 ? null : placeholder)}
			className={hidePlaceholder ? "rt--hide-placeholder" : ""}
		/>
	)
})

/** InternalInput propTypes
	* PropTypes
*/

InternalInput.propTypes = {
	/** Input value */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Input placeholder */
	placeholder: PropTypes.string,
	/** Input type */
	type: PropTypes.string
}

export default InternalInput;