import React, { Fragment } from 'react';

import { useLocation } from "react-router-dom";

import WalletHistory from "pages/wallet/history";

import { WALLET_OWNER_TYPE } from "constants/wallet.constants";
import { USER_TYPE } from "constants/user.constants";

/** Agent Edit Page Wallets Tab Component */
const WalletsComponent = () => {

    const { search } = useLocation();

    const entityRole = (new URLSearchParams(search)).get("role") || WALLET_OWNER_TYPE.AGENT;

    return Number(entityRole) ? (
        <WalletHistory
            ownerType={ Number(entityRole) === USER_TYPE.AGENT_PLAYER ? WALLET_OWNER_TYPE.AGENT_PLAYER : WALLET_OWNER_TYPE.AGENT }
        />
    ) : <Fragment />
}

export default WalletsComponent;