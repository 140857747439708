import axios from "axios";

import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const getVSBetAmounts = (currencyCode) => {
    return axios({
        url: ApiUrls.GET_PROJECT_VS_BET_LIMITS,
        method: Methods.GET,
        params: { currencyCode },
    })
}

export const saveVSBetAmounts = (data) => {
    return axios({
        url: ApiUrls.SAVE_PROJECT_VS_BET_LIMITS,
        method: Methods.POST,
        data,
    })
}
