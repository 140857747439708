import React from "react";

import PropTypes from "prop-types";

import DropdownDesktop from "NEW/src/core/ui/dropdown/desktop";
import DropdownMobile from "NEW/src/core/ui/dropdown/mobile";
import Responsive from "NEW/src/core/ui/responsive";

const Dropdown = ({
	children,
	className,
	disabled,
	footer,
	getPopupContainer,
	onClose,
	onOpenChange,
	menu,
	dropdownRender,
	overlayClassName,
	placement = "bottomLeft",
	popupOpened,
	title,
}) => {
	return (
		<Responsive
			mobile={
				<DropdownMobile
					className={className}
					disabled={disabled}
					footer={footer}
					onClose={onClose}
					onOpenChange={onOpenChange}
					menu={menu}
					dropdownRender={dropdownRender}
					popupOpened={popupOpened}
					title={title}
				>
					{children}
				</DropdownMobile>
			}
			desktop={
				<DropdownDesktop
					className={className}
					disabled={disabled}
					getPopupContainer={getPopupContainer}
					onClose={onClose}
					onOpenChange={onOpenChange}
					dropdownRender={dropdownRender}
					menu={menu}
					overlayClassName={overlayClassName}
					placement={placement}
					popupOpened={popupOpened}
					title={title}
				>
					{children}
				</DropdownDesktop>
			}
		/>
	);
};

Dropdown.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	footer: PropTypes.shape({
		okText: PropTypes.string,
		cancelText: PropTypes.string,
		onOk: PropTypes.func,
		onCancel: PropTypes.func,
	}),
	dropdownRender: PropTypes.func,
	getPopupContainer: PropTypes.func,
	onClose: PropTypes.func,
	onOpenChange: PropTypes.func,
	menu: PropTypes.object,
	overlayClassName: PropTypes.string,
	placement: PropTypes.oneOf([
		"bottom",
		"bottomLeft",
		"bottomRight",
		"top",
		"topLeft",
		"topRight",
	]),
	popupOpened: PropTypes.bool,
	title: PropTypes.node,
};

export default Dropdown;
