export const COMPARISON_TYPE = {
    GREATER_THEN: 'gt',
    LESS_THEN: 'lt',
    EQUAL :'eq',
    NOT_EQUAL: 'neq',
    GREATER_OR_EQUAL:'gte',
    LESS_OR_EQUAL:'lte',
}

// TODO: need to add translations for these error messages

export const COMPARISON_ERROR_MESSAGES = {
    [COMPARISON_TYPE.GREATER_THEN]: 'backoffice.validation.mustBeMore',
    [COMPARISON_TYPE.LESS_THEN]: 'backoffice.validation.mustBeLess',
    [COMPARISON_TYPE.EQUAL]: 'backoffice.validation.mustBeEqual',
    [COMPARISON_TYPE.NOT_EQUAL]: 'backoffice.validation.mustBeNotEqual',
    [COMPARISON_TYPE.GREATER_OR_EQUAL]: 'backoffice.validation.mustBeMore',
    [COMPARISON_TYPE.LESS_OR_EQUAL]: 'backoffice.validation.mustBeLess',
}
