import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from 'components/common/select';

import { getCurrencyFieldOptions } from 'utils/currency';
import { getSelectFieldOptions } from 'utils/dashboard';

import { FINANCIAL_DASHBOARD_FILTER_NAMES, TIME_PERIOD, TIME_PERIOD_TRANSLATION_SOURCE } from 'pages/dashboard/constants';
import { FILTER_TYPE } from 'constants/common.constants';
import useProjectType from "hooks/useProjectType";

const DesktopFilters = ({ updateFilters, filters, userCurrencies }) => {
    const { t } = useTranslation();

    const { hasLiteMode } = useProjectType();

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);
    const timePeriodFieldOptions = getSelectFieldOptions({
        dataSource: TIME_PERIOD,
        translationSource: TIME_PERIOD_TRANSLATION_SOURCE,
    })

    return (
        <>
            <Select
                options={[
                    { value: FILTER_TYPE.DIRECT, text: t("backoffice.dashboard.direct") },
                    { value: FILTER_TYPE.NETWORK, text: t("backoffice.dashboard.network") },
                ]}
                value={filters[FINANCIAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE]}
                onChange={(value) => updateFilters({ [FINANCIAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE]: value })}
                className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle rt--mr-16"
                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
            />

            {!hasLiteMode && (
                <Select
                    options={currencyFieldOptions}
                    value={filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]}
                    onChange={(value) => updateFilters({[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]: value})}
                    className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-small rt--mr-16"
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                />
            )}

            <Select
                options={timePeriodFieldOptions}
                value={filters[FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD]}
                onChange={(value) => updateFilters({ [FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD]: value })}
                className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle"
                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
            />
        </>
    )
}

DesktopFilters.propTypes = {
    updateFilters: PropTypes.func.isRequired,
    filters: PropTypes.object,
    userCurrencies: PropTypes.array,
}

export default DesktopFilters;
