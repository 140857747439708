import PropTypes from 'prop-types';

import { EXPANDABLE_TYPES, ROW_CHOOSER_TYPES } from './constants';

import sortingType from "types/common/sorting.type";

// ------------------------------------------------- EXPANDABLE PROPTYPES -------------------------------------------------- //

const expandablePropTypes = PropTypes.shape({
    detailsLoadFn: PropTypes.func,
    details: PropTypes.func,
    loading: PropTypes.bool,
    expandAll: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    tableTitle: PropTypes.string,
    disabled: PropTypes.array,
    disabledForTable: PropTypes.array,
    type: PropTypes.oneOf([
        EXPANDABLE_TYPES.CONTENT,
        EXPANDABLE_TYPES.TABLE,
        EXPANDABLE_TYPES.BOTH
    ])
});

// -------------------------------------------------- CLICKABLE PROPTYPES -------------------------------------------------- //

const clickablePropTypes = PropTypes.shape({
    onClick: PropTypes.func,
    disabled: PropTypes.func
});

// --------------------------------------------------- COLUMNS PROPTYPES --------------------------------------------------- //

const columnsPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        dataIndex: PropTypes.string,
        render: PropTypes.func,
        sorter: PropTypes.bool,
    })
);

// -------------------------------------------------- SWITCHER PROPTYPES --------------------------------------------------- //

const switcherPropTypes = PropTypes.shape({
    onChange: PropTypes.func,
    valueIndex: PropTypes.string,
    dataIndex: PropTypes.string,
    disabled: PropTypes.func,
    valueHandler: PropTypes.func
});

// ---------------------------------------------------- DATA PROPTYPES ----------------------------------------------------- //

const dataPropTypes = PropTypes.arrayOf(PropTypes.object);

// --------------------------------------------------- CHOOSER PROPTYPES --------------------------------------------------- //

export const chooserPropTypes = PropTypes.shape({
    type: PropTypes.oneOf(Object.values(ROW_CHOOSER_TYPES)),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    onChange: PropTypes.func,
    dataIndex: PropTypes.string,
    valueIndex: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    valueText: PropTypes.string,
    disabled: PropTypes.func,
    renderIcon: PropTypes.func
});

// ========================================================================================================================= //
// =                                                    TABLE PROPTYPES                                                    = //
// ========================================================================================================================= //

export const TablePropTypes = {
    // Both
    noPagination: PropTypes.bool,
    uniqueKey: PropTypes.string,
    loading: PropTypes.bool,
    actions: PropTypes.array,
    loadFn: PropTypes.func,
    updateProps: PropTypes.array,
    data: dataPropTypes,
    expandable: expandablePropTypes,
    chooser: chooserPropTypes,
    clickable: clickablePropTypes,
    columns: columnsPropTypes,
    switcher: switcherPropTypes,
    renderEmpty: PropTypes.func,
    formatNumber: PropTypes.func,
    sortable: PropTypes.bool,
    sortableData: PropTypes.array,
    onRowSort: PropTypes.func,

    // Desktop
    total: PropTypes.number,
    sorting: sortingType,
    enableReload: PropTypes.bool,
    filters: PropTypes.object,
    setFiltersFn: PropTypes.func,
    setSortingFn: PropTypes.func,

    // Mobile
    disableFullView: PropTypes.bool,
    mobileListView: PropTypes.bool,
    mobileLayoutVertical: PropTypes.bool,
    smallInMobile: PropTypes.bool,
    mobileHeaderContent: PropTypes.func,
    fullViewClassName: PropTypes.string,
};
