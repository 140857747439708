import React from 'react';
import PropTypes from 'prop-types';

import CommonActions from '../../commonActions';


const renderExpandableIcons = ({ expandableData = {}, rowData }) => {
    const { fullViewData, innerTableData } = expandableData;

    const handleFullViewIconClick = (rowData, e) => {
        e.stopPropagation();
        e.preventDefault();

        fullViewData.onClick?.(rowData)
    }

    const handleInnerTableIconClick = (rowData, e) => {
        e.stopPropagation();
        e.preventDefault();

        innerTableData.onClick?.(rowData)
    }

    return (
        <>
            {
                innerTableData?.isActive && (
                    <i
                        className="icon-right rt--font-bigest rt--mb-8"
                        onClick={(e) => handleInnerTableIconClick(rowData, e)}
                    />
                )
            }
            {
                fullViewData?.isActive && (
                    <i
                        className="icon-eye rt--font-bigest rt--mb-8"
                        onClick={(e) => handleFullViewIconClick(rowData, e)}
                    />
                )
            }
        </>
    )
}

export const Actions = ({
    actions,
    rowData,
    expandableData,
    maxNumberOfActionsInRow
}) => {

    return (
        <div className="rt--table-item-row-actions rt--flex rt--flex-col rt--align-center">
            {Boolean(expandableData) && renderExpandableIcons({ expandableData, rowData })}
            <CommonActions
                actions={actions}
                rowData={rowData}
                maxNumberOfActionsInRow={maxNumberOfActionsInRow}
            />
        </div>
    );
}

/** Actions propTypes
 * PropTypes
*/
Actions.propTypes = {
    actions: PropTypes.array,
    rowData: PropTypes.object,
    expandableData: PropTypes.object,
    maxNumberOfActionsInRow: PropTypes.number
}

export default Actions;