import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import useDate from 'hooks/useDate';

const LastUpdated = ({ lastUpdatedDate }) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    return (
        <div className='rt--flex rt--align-center'>
            <i className='icon-time rt--font-biger rt--mr-6'/>
            <span className="rt--title rt--font-medium rt--font-big rt--chart-last-update rt--mr-16">     
                {`${t("backoffice.dashboard.lastUpdatedAt")} ${!lastUpdatedDate ? "" : dateService.format(lastUpdatedDate, true, false)}`}
            </span>
        </div>
    )
}

LastUpdated.propTypes = {
    lastUpdatedDate: PropTypes.string
}

export default LastUpdated;
