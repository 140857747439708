import React, { Fragment, useEffect } from "react";
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Tooltip from "components/common/tooltip";

import { loadBankFieldTranslations } from 'store/actions/dashboard/payments/paymentRequests/paymentRequests.action';

import { PAYMENT_METHODS, REGISTRATION_FORM_TYPE, WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

import { isMobile, toUpperCaseFirstLetter, toLowerCaseFirstLetter } from "utils/common";
import LanguageUtils from 'utils/languages';

const constructDataFromDefinedFields = userInfo => {
    const definedFields = [];
    const pair = [];

    for (const key in userInfo) {
        if (userInfo[key] === null || userInfo[key] === "" || key === "Amount") {
            continue;
        }

        if (pair.length === 2) {
            definedFields.push([...pair]);
            pair.length = 0;
        }

        pair.push(key);
    }

    if (pair.length !== 0) {
        definedFields.push([...pair])
    }

    return definedFields;
}


/** Transaction Details Component */
const TransactionDetails = ({
    userInfo,
    translations,
    widgetTransactionType,
    paymentType,
    bankId,
    loadBankFieldTranslations
}) => {

    const { t } = useTranslation();
    const currentLn = LanguageUtils.getSelectedLanguage();

    const definedValues = constructDataFromDefinedFields(userInfo);

    const formType = widgetTransactionType === WIDGET_TRANSACTION_TYPE.DEPOSIT ? REGISTRATION_FORM_TYPE.PLAYER_DEPOSIT : REGISTRATION_FORM_TYPE.PLAYER_WITHDRAWAL;

    const translationsKey = bankId + "_" + formType;

    /** Load Bank Fields translations Trnslations */
    useEffect(() => {

        if (!translations[translationsKey] && paymentType === PAYMENT_METHODS.BANK_TRANSFER) {
            loadBankFieldTranslations(bankId, formType)
        }

    }, [])

    /** Render dield
       * @function
       * @returns {object}
       * @memberOf TransactionDetails
   */
    const renderField = key => {
        let filePaths = [];
        let title = "", fieldName = "";
        const bankFieldTranslations = translations[translationsKey] ?? [];

        fieldName = key === "FilePath" ? "Attachment" : toUpperCaseFirstLetter(key);

        const prefix = formType === REGISTRATION_FORM_TYPE.PLAYER_DEPOSIT ? "playerdeposit_" : "playerwithdrawal_";

        const translation = bankFieldTranslations.find(tr => tr.key === prefix + fieldName);

        title = translation?.translations?.find((translationData) => {
            if (translationData.languageCode.toLowerCase() === currentLn.toLowerCase()) {
                return translationData.text;
            }
        })?.text ?? translation?.text ?? fieldName;

        let fileName = "";
        switch (key) {
            case "FilePath":
                filePaths = userInfo[key] ? userInfo[key].split(",") : [];
                fileName = filePaths[0].split("/").at(-1);
                fileName = fileName.replace(fileName.split("_")[0] + "_", "");
                return {
                    title: title,
                    content: filePaths[0] ? (
                        <a className="rt--flex rt--align-center" href={`${import.meta.env.SYSTEM_CDN_URL}/${filePaths[0]}`} target="_blank" rel="noreferrer">
                            <i className="icon-picture rt--mr-4 rt--font-big" />
                            <span>{fileName}</span>
                        </a>
                    ) : "-",
                    tooltip: fileName,
                    mobileTooltip: null
                }
            case "EposCity":
            case "eposCity":
            case "EposAddress":
            case "eposAddress":
                return {
                    title: t(`backoffice.payments.${toLowerCaseFirstLetter(key)}`),
                    content: userInfo[key],
                    tooltip: userInfo[key],
                    mobileTooltip: userInfo[key],
                }
            default:
                return {
                    title: title,
                    content: userInfo[key],
                    tooltip: userInfo[key],
                    mobileTooltip: userInfo[key],
                }
        }
    }

    /** Render for desktop
      * @function
      * @memberOf TransactionDetails
  */
    const renderForDesktop = () => {
        return (
            <div className="rt--payment-list rt--flex">

                {
                    definedValues.map((fieldPair, index) => {
                        return (
                            <div className="rt--pt-8 rt--pb-8 rt--flex" key={index}>
                                <div className="rt--payment-list-item-col rt--flex-equal">
                                    {
                                        fieldPair.map((key, index) => {
                                            const field = renderField(key);
                                            return (
                                                <div className="rt--flex rt--align-center rt--mb-8" key={index}>
                                                    <b className="rt--title rt--font-normal rt--font-regular">{field.title}:</b>
                                                    <span
                                                        className="rt--title rt--font-normal rt--font-bold rt--pl-8 rt--pr-8"
                                                        title={field.tooltip}
                                                    >
                                                        {
                                                            field.content
                                                        }
                                                    </span>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    /** Render for mobile
       * @function
       * @memberOf TransactionDetails
   */
    const renderForMobile = () => {
        return (
            <div className="rt--payment-list">
                {
                    definedValues.map((fieldPair, i) => (
                        <Fragment key={i}>
                            {
                                fieldPair.map((key, index) => {
                                    const field = renderField(key);
                                    return (
                                        <div className="rt--payment-list-item rt--flex rt--mb-16" key={index}>
                                            <div className="rt--flex">
                                                <b className="rt--title rt--font-big rt--font-regular">{field.title}:</b>
                                                {
                                                    field.mobileTooltip ? (
                                                        <Tooltip
                                                            title={field.mobileTooltip}
                                                            trigger={["click"]}
                                                            placement="bottomLeft"
                                                            enableMobile={true}
                                                        >
                                                            <span className="rt--title rt--font-big rt--font-medium">
                                                                {
                                                                    field.content
                                                                }
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <span className="rt--title rt--font-big rt--font-medium">
                                                            {
                                                                field.content
                                                            }
                                                        </span>
                                                    )
                                                }


                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Fragment>
                    ))
                }
            </div>
        )
    }

    return (
        <div className="rt--payment">
            {
                !isMobile() && (
                    <b className="rt--title rt--font-bold rt--font-normal rt--payment-title">
                        {
                            paymentType === PAYMENT_METHODS.BANK_TRANSFER ? t("backoffice.payments.PlayerInfo") : t("backoffice.payments.eposInfo")
                        }
                    </b>
                )
            }
            {
                isMobile() ? renderForMobile() : renderForDesktop()
            }

        </div>

    );
};

/** TransactionDetails propTypes
 * PropTypes
*/
TransactionDetails.propTypes = {
    /** Details */
    userInfo: PropTypes.object,
    /** Widget transaction type */
    widgetTransactionType: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE)),
    /** Payment type */
    paymentType: PropTypes.oneOf(Object.values(PAYMENT_METHODS)),
    /** Bank Id */
    bankId: PropTypes.string,
    /** Redux state property, bank form fields translations */
    translations: PropTypes.object,
    /** Redux action to load bank field translations */
    loadBankFieldTranslations: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    loadBankFieldTranslations: (bankId, type) => {
        dispatch(loadBankFieldTranslations(bankId, type));
    },
});

const mapStateToProps = (state) => {
    return {
        translations: state.paymentRequests.translations
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionDetails);
