import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { getPlayerGeneralInfo } from "./general.action";

import {
    SET_PLAYERS_ACTION_BEFORE,
    SET_PLAYERS_ACTION_FINISH,
    SET_PLAYERS,
    SET_PLAYERS_SORTING,
    SET_PLAYERS_FILTERS,
    SET_PLAYERS_SAVE_ACTION_BEFORE,
    SET_PLAYERS_SAVE_ACTION_FINISH,
    SET_UPDATE_PLAYER_DATA,
    SET_PLAYER_REGISTRATION_FORM_BEFORE,
    SET_PLAYER_REGISTRATION_FORM_FINISH,
    SET_PLAYER_REGISTRATION_FORM,
    SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID,
    SET_PLAYER_WITHDRAWAL
} from "../../../actionTypes";

export const setPlayersActionBefore = () => ({
    type: SET_PLAYERS_ACTION_BEFORE,
});

export const setPlayersActionFinished = () => ({
    type: SET_PLAYERS_ACTION_FINISH,
});

export const setPlayersSaveActionBefore = () => ({
    type: SET_PLAYERS_SAVE_ACTION_BEFORE,
});

export const setPlayersSaveActionFinished = () => ({
    type: SET_PLAYERS_SAVE_ACTION_FINISH,
});

const setPlayers = (players, add) => ({
    type: SET_PLAYERS,
    payload: { players, add },
});

export const setPlayersSorting = sorting => ({
    type: SET_PLAYERS_SORTING,
    payload: { sorting },
});

export const setPlayersFilters = filters => ({
    type: SET_PLAYERS_FILTERS,
    payload: { filters },
});

const setUpdatePlayerData = ( data, id ) => ({
    type: SET_UPDATE_PLAYER_DATA,
    payload: { data, id },
});


export const getPlayers = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().players.sorting.page + 1 : getState().players.sorting.page;
        let params = {
            ...getState().players.sorting,
            ...getState().players.filters,
            page: page
        };

        if(page > 1 && getState().players.total <= (page - 1 ) * getState().players.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().players.total === 0){
            return Promise.resolve();
        }

        let currenciesStr = "";
        let currencies = getState().players.filters.currencies;

        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }
        
        delete params.currencies;
        
        dispatch(setPlayersActionBefore());

        return axios({
            url: `${ApiUrls.GET_PLAYERS}?${currenciesStr}`,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: players } }) => {
                dispatch(setPlayers(players, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setPlayersSorting({ ...getState().players.sorting, page: page }));
                dispatch(setPlayersActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayersActionFinished());
            });
    }
}

export const createPlayer = (player, onSuccess) => {
    return (dispatch) => {
        dispatch(setPlayersSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_PLAYER,
            method: Methods.POST,
            data: {
                ...player
            },
        })
            .then(({ data }) => {
                dispatch(setPlayersSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value);
                    message.success(data.message);
                }
            })
            .catch(() => {
                dispatch(setPlayersSaveActionFinished());
            });
    };
};

export const changePlayerState = d => {
    return dispatch => {
        dispatch(setPlayersSaveActionBefore());
        return axios({
            url: ApiUrls.SET_PLAYER_STATE,
            method: Methods.POST,
            data: d
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getPlayerGeneralInfo(d.id));
            } 
            dispatch(setPlayersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayersSaveActionFinished());
        })
    }
}

export const bulkReparentPlayer = (ids, parentId, onSuccess) => {
    return (dispatch) => {
        dispatch(setPlayersSaveActionBefore());
        return axios({
            url: ApiUrls.BULK_REPARENT_PLAYER,
            method: Methods.POST,
            data: {
                ids,
                parentId
            },
        })
            .then(({ data }) => {
                dispatch(setPlayersSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value);
                    dispatch(getPlayers())
                }
            })
            .catch(() => {
                dispatch(setPlayersSaveActionFinished());
            });
    };
};

export const reparentPlayer = (id, parentId, onSuccess) => {
    return (dispatch) => {
        dispatch(setPlayersSaveActionBefore());
        return axios({
            url: ApiUrls.REPARENT_PLAYER,
            method: Methods.POST,
            data: {
                id,
                parentId
            },
        })
            .then(({ data }) => {
                dispatch(setPlayersSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setPlayersSaveActionFinished());
            });
    };
};

export const setPlayerPassword = ( data, id, onSuccess) => {
    return dispatch => {
        dispatch(setPlayersSaveActionBefore());
        return axios({
            url: ApiUrls.SET_PLAYER_PASSWORD,
            method: Methods.POST,
            data: { ...data, id }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                onSuccess && onSuccess();
            }
            dispatch(setPlayersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayersSaveActionFinished());
        })
    }
}

export const updatePlayerData = id => {
    return dispatch => {
        dispatch(setPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({ data }) => {
            if(data.value) {
                dispatch(setUpdatePlayerData(data.value, id))
            }
            dispatch(setPlayersActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayersActionFinished());
        })
    }
}

/** Registration form */

const setPlayerRegistrationFormBefore = () => ({
    type: SET_PLAYER_REGISTRATION_FORM_BEFORE,
});

const setPlayerRegistrationFormFinished = () => ({
    type: SET_PLAYER_REGISTRATION_FORM_FINISH,
});

const setPlayerRegistrationForm = registrationForm => ({
    type: SET_PLAYER_REGISTRATION_FORM,
    payload: { registrationForm }
})

export const resetPlayerRegistrationForm = () => {
    return dispatch => {
        dispatch(setPlayerRegistrationForm([]))
    }
}

export const getPlayerRegistrationForm = () => {
    return dispatch => {

        dispatch(setPlayerRegistrationFormBefore());
        return axios({
            url: ApiUrls.GET_PLAYER_REGISTRATION_FORM,
            method: Methods.GET
        })
        .then(({data : {value: registrationForm}}) => {
            dispatch(setPlayerRegistrationForm(registrationForm))
            dispatch(setPlayerRegistrationFormFinished());
        })
        .catch((ex) => {
            dispatch(setPlayerRegistrationFormFinished());
        })
    }
}

/** Get player by external Id */
const setPlayerGeneralInfoByExternalId = info => ({
    type: SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID,
    payload: { info }
})

export const getPlayerGeneralInfoByExternalId = ( params, onSuccess ) => {
    return dispatch => {
        dispatch(setPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID,
            method: Methods.GET,
            params: {
                ...params
            }
        })
        .then(({data}) => {
            dispatch(setPlayerGeneralInfoByExternalId(data.value ? { ...data.value, verificationCode: params.verificationCode} : null));

            onSuccess && onSuccess(data.value);

            dispatch(setPlayersActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayersActionFinished());
        })
    }
}

export const resetPlayerGeneralInfoByExternalId = () => {
    return dispatch => {
        dispatch(setPlayerGeneralInfoByExternalId(null));
    }
}


/** Get player withdrawal */
const setPlayerWithdrawal = withdrawal => ({
    type: SET_PLAYER_WITHDRAWAL,
    payload: { withdrawal }
})

export const getPlayerWithdrawal = data => {
    return dispatch => {
        dispatch(setPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_EPOS_REQUEST,
            method: Methods.GET,
            params: {
                ...data
            }
        })
        .then(({data}) => {
            dispatch(setPlayerWithdrawal(data.value));
            dispatch(setPlayersActionFinished());
        })
        .catch((ex) => {
            dispatch(setPlayersActionFinished());
        })
    }
}