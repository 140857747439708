import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';

import {useLocation, useNavigate} from 'react-router';

import { Tabs as AntTabs } from 'antd';

import SubTabDashboardLayout from "components/layouts/tab/subtab";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import { getHashValue, isMobile, updateLocationHash } from 'utils/common';

import useUnsavedForm from 'hooks/useUnsavedForm';
import {isTabVisible} from "components/common/tabs/helpers/isTabVisible";

/** SubTabs */
const SubTabs = ({
    items,
    isParentActive,
    onTabChange,
    hidden,
    isDeepView,
    withPaddingInMobile
}) => {

    const [unsavedTabs, setUnsavedTabs] = useState([]);

    const [activeKey, setActiveKey] = useState(null);

    const { setToUnsavedForms, removeFromUnsavedForms } = useUnsavedForm(UNSAVED_FORM_PAGE_TYPE.SUB_TAB);

    const { pathname, search, hash } = useLocation();
    const navigate = useNavigate();

    const queryParamName = isDeepView ? "subsubtab" : "subtab";

    const tabsWithIds = useMemo(() => (
        items.map((item, index) => ({...item, key: index.toString() }))
    ), [items]);

    const availableItems = useMemo(() => (
        tabsWithIds.filter(isTabVisible)
    ), [tabsWithIds]);

    /** Function fires on tab change
       * @function
       * @param {string} key - tab key
       * @memberOf Tabs
   */
    const handleTabsChange = (key) => {
        setActiveKey(key);
        updateLocationHash(queryParamName + "=" + key, true);
    }

    /** Function to get first tab key
       * @function
       * @returns {number}
       * @memberOf Tabs
   */
    const getInitialActiveKey = () => availableItems[0]?.key;

    /** Function to make tab class name
       * @function
       * @description addes class "unsaved-tab" if tab has unsaved changes
       * @param {string} key - tab key
       * @returns {string}
       * @memberOf SubTabs
   */
    const tabClassName = key => unsavedTabs.indexOf(key) > -1 ? "rt--unsaved-sub-tab" : "";

    /** Fires when tab saved status changed
       * @function
       * @param {boolean} status - does tab have unsaved change
       * @param {string} key - tab key
       * @memberOf SubTabs
   */
    const changeTabSavedStatus = (status, key) => {
        if (status && unsavedTabs.indexOf(key) === -1) {
            if(isDeepView){
                setToUnsavedForms({ key });
            }
            setUnsavedTabs([...unsavedTabs, key]);
        } else if (!status) {
            if(isDeepView){
                removeFromUnsavedForms({ key });
            }
            setUnsavedTabs(unsavedTabs.filter(t => t !== key));
        }
    }

    /** Set default tab */
    useEffect(() => {
        onTabChange && onTabChange(unsavedTabs.length > 0);

        if (isParentActive || isDeepView) {
            const tabKey = getHashValue(queryParamName);
            const selectedKey = tabKey ? tabKey : getInitialActiveKey()?.toString();
            const keyPresent = availableItems.find(item => item.key === selectedKey);

            if (keyPresent) {
                setActiveKey(selectedKey);
            } else {
                const defaultKey = getInitialActiveKey()?.toString();

                if (defaultKey) {
                    setActiveKey(defaultKey);

                    setTimeout(() => {
                        navigate(
                            (pathname + search + hash).replace(`${queryParamName}=${tabKey}`, `${queryParamName}=${defaultKey}`),
                            { replace: true }
                        );
                    });
                }
            }
        }
    }, [unsavedTabs, isParentActive, isDeepView, hash])


    return (
        <SubTabDashboardLayout>
            <AntTabs
                animated={false}
                className={`rt--tabs-sub${hidden ? " rt--tabs-hidden" : ""} ${(isMobile() && withPaddingInMobile) ? " rt--tabs-sub-with-padding" : ""}`}
                activeKey={activeKey}
                onChange={handleTabsChange}
                items={
                    availableItems
                        .map((tab) => (
                            {
                                key: tab.key,
                                label: (
                                    <span className={tabClassName(tab.key)}>
                                        {tab.title}
                                    </span>
                                ),
                                children: (
                                    React.cloneElement(
                                        tab.component,
                                        { onTabChange: status => changeTabSavedStatus(status, tab.key) }
                                    )
                                )
                            }
                        ))
                }
            />
        </SubTabDashboardLayout>
    )
}

/** SubTabs propTypes
    * PropTypes
*/

SubTabs.propTypes = {
    /** Items */
    items: PropTypes.arrayOf(PropTypes.shape({
        /** Tab title */
        title: PropTypes.string,
        /** Permissions */
        permissions: PropTypes.arrayOf(PropTypes.shape({
            /** Permission resource */
            resource: PropTypes.oneOf(Object.values(PERMISSION_RESOURCE)),
            /** Permission action */
            action: PropTypes.oneOf(Object.values(PERMISSION_ACTION))
        })),
        /** Component */
        component: PropTypes.node,
        /** Is disabled */
        disabled: PropTypes.bool,
        /** Additional pathParams */
        pathParams: PropTypes.shape({
            /** Name */
            name: PropTypes.string,
            /** id */
            id: PropTypes.string
        })
    })),
    /** Is parent tab active */
    isParentActive: PropTypes.bool,
    /** On Tab cchange function which comes from parent */
    onTabChange: PropTypes.func,
    /** Is hidden */
    hidden: PropTypes.bool,
    /** Is deep view sub tabs */
    isDeepView: PropTypes.bool,
    /** Add additional padding in mobile */
    withPaddingInMobile: PropTypes.bool
}

export default SubTabs;
