import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_PAYMENT_REQUESTS_ACTION_BEFORE,
    SET_PAYMENT_REQUESTS_ACTION_FINISH,
    SET_PAYMENT_REQUESTS_SORTING,
    SET_PAYMENT_REQUESTS_FILTERS,
    SET_PAYMENT_REQUESTS,
    SET_BANK_FIELD_TRANSLATIONS_ACTION_BEFORE,
    SET_BANK_FIELD_TRANSLATIONS_ACTION_FINISH,
    SET_BANK_FIELD_TRANSLATIONS
} from "../../../../actionTypes";

import { WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

const setPaymentRequestsActionBefore = () => ({
    type: SET_PAYMENT_REQUESTS_ACTION_BEFORE,
})

const setPaymentRequestsActionFinish = () => ({
    type: SET_PAYMENT_REQUESTS_ACTION_FINISH,
})

export const setPaymentRequestsSorting = (sorting, type) => ({
    type: SET_PAYMENT_REQUESTS_SORTING,
    payload: { sorting, type },
});

export const setPaymentRequestsFilters = (filters, type, keepPage) => ({
    type: SET_PAYMENT_REQUESTS_FILTERS,
    payload: { filters, type, keepPage },
});

const setPaymentRequests = (transactions, type) => ({
    type: SET_PAYMENT_REQUESTS,
    payload: { transactions, type },
}) 

export const getPaymentRequests = type => {
    return (dispatch, getState) => {

        const paymentsData = getState().paymentRequests[type];
        
        let params = {
            ...paymentsData.sorting,
            ...paymentsData.filters,
        };

        dispatch(setPaymentRequestsActionBefore());

        const apiURL = type === WIDGET_TRANSACTION_TYPE.DEPOSIT ? ApiUrls.GET_PAYMENT_DEPOSIT_REQUESTS : ApiUrls.GET_PAYMENT_WITHDRAW_REQUESTS;

        return axios({
            url: apiURL,
            method: Methods.GET,
            params: params
        })
        .then(({ data }) => {
            dispatch(setPaymentRequests(data.value, type));
            dispatch(setPaymentRequestsActionFinish());
        })
        .catch(err => {
            dispatch(setPaymentRequestsActionFinish());
        })
    }
}

const setBankFieldTranslationsActionBefore = () => ({
    type: SET_BANK_FIELD_TRANSLATIONS_ACTION_BEFORE,
})

const setBankFieldTranslationsActionFinish = () => ({
    type: SET_BANK_FIELD_TRANSLATIONS_ACTION_FINISH,
})

const setBankFieldTranslations = (bankId, type, translations) => ({
    type: SET_BANK_FIELD_TRANSLATIONS,
    payload: { bankId, type, translations }
})

export const loadBankFieldTranslations = (bankId, type) => {
    return dispatch => {
        dispatch(setBankFieldTranslationsActionBefore());

        return axios({
            url: ApiUrls.GET_BANK_TRANSLATIONS,
            method: Methods.GET,
            params: {
                id: bankId,
                type
            }
        })
        .then(({ data }) => {
            dispatch(setBankFieldTranslations(bankId, type, data.value?.translations ?? []))
            dispatch(setBankFieldTranslationsActionFinish());
        })
        .catch((ex) => {
            dispatch(setBankFieldTranslationsActionFinish());
        })
    }
}