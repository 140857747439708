import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setAgentsActionBefore, setAgentsActionFinished, setAgentsSaveActionBefore, setAgentsSaveActionFinished } from './agents.action';

import { SET_AGENT_LIMIT_SETTINGS } from "../../../../actionTypes";

const setAgentLimitSettings = limitSettings => ({
    type: SET_AGENT_LIMIT_SETTINGS,
    payload: { limitSettings }
})

export const getAgentLimitSettings = (id, currencyCode) => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_LIMITS,
            method: Methods.GET,
            params: { id, currencyCode }
        })
        .then(({data : {value: limitSettings}}) => {
            dispatch(setAgentLimitSettings(limitSettings))
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const saveAgentLimitSettings = data => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AGENT_LIMITS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAgentLimitSettings(data.value));
            } 
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}
