import React, { useState } from "react";

import TransferContext from "./context";

const TransferProvider = ({
    children
}) => {
    const [opened, setOpened] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const [transferData, setTransferData] = useState(null);

    const [transferResultData, setTransferResultData] = useState(null);

    const show = data => {
        setOpened(true);
        setTransferData(data)
    };
    
    const hide = () => {
        setOpened(false);
        setTransferData(null);
        setTransferResultData(null);
        setIsSaving(false);
    };

    const setSaving = value => {
        setIsSaving(value)
    };

    return (
        <TransferContext.Provider value={{ 
            transferData, 
            opened, 
            show, 
            hide,
            setSaving,
            isSaving,
            transferResultData,
            setTransferResultData 
        }}>
            { children }
        </TransferContext.Provider>
    )
}

export default TransferProvider;