import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'components/common/select';
import Leaderboard from 'components/common/leaderboard';

import useFinancialLeaderboard from '../hooks/useFinancialLeaderboard';
import useProjectType from "hooks/useProjectType";

import { isMobile } from 'utils/common';
import { getSelectFieldOptions } from 'utils/dashboard';

import {
    DATA_TYPES,
    DATA_TYPE_TRANSLATION_SOURCE,
    FINANCIAL_DASHBOARD_FILTER_NAMES
} from '../constants';

const getCorrespondingDataTypes = (type, hasLiteMode) => {
    const types = { ...DATA_TYPES };

    delete types.COMMISSIONS_LITE;

    if (type === "player") {
        delete types.COMMISSIONS;
    }

    if (hasLiteMode) {
        delete types.NGR;
        delete types.NET_DEPOSIT;
        delete types.COMMISSIONS;
    }

    return types;
}

const FinancialLeaderboard = ({
    type,
    endpoint,
    filters,
    updateLastUpdatedDate,
    ...restProps
}) => {
    const [dataType, setDataType] = useState(DATA_TYPES.GGR);

    const { hasLiteMode } = useProjectType();
    const { leaderBoardData, isLoading } = useFinancialLeaderboard(dataType, filters, updateLastUpdatedDate, endpoint)

    const handleLeaderboardDataTypeChange = (dataType) => {
        setDataType(dataType);
    }

    return (
        <Leaderboard
            leaders={leaderBoardData}
            isLoading={isLoading}
            showTitle={!isMobile()}
            currencyCode={filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]}
            extraContent={
                <Select
                    value={dataType}
                    onChange={handleLeaderboardDataTypeChange}
                    options={getSelectFieldOptions({
                        dataSource: getCorrespondingDataTypes(type, hasLiteMode),
                        translationSource: DATA_TYPE_TRANSLATION_SOURCE
                    })}
                    className={"rt--main-layout-header-actions-dropdown-middle"}
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                />
            }
            {...restProps}
        />
    );
}

FinancialLeaderboard.propTypes = {
    filters: PropTypes.object,
    type: PropTypes.string,
    updateLastUpdatedDate: PropTypes.func,
    endpoint: PropTypes.string,
};

export default FinancialLeaderboard;
