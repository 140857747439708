import React from "react";

import CommissionCalculationsReportComponent from "pages/reports/commissionCalculationReport/commissionCalculationReport.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const CommissionCalculationsReportRoute = () => {
    return <CommissionCalculationsReportComponent />;
};

export default withPermission(
    withAuth(CommissionCalculationsReportRoute),
    {
        resource: PERMISSION_RESOURCE.REPORTS_COMMISSION_CALCULATIONS,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
