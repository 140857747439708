import {
    AUTHENTICATE_ACTION_SET_EXPIRE,
    AUTHENTICATE_ACTION_BEFORE,
    AUTHENTICATE_ACTION_FINISH,
    AUTHENTICATE_ACTION_SET_QRBASE64,
    AUTHENTICATE_ACTION_SET_WS_TOKEN,
    AUTHENTICATE_ACTION_SET_LOCK_DATA,
    SET_PASSWORD_SETTINGS_ACTION_BEFORE,
    SET_PASSWORD_SETTINGS_ACTION_FINISH,
    SET_PASSWORD_SETTINGS,
    SET_RESET_PASSWORD_ACTION_BEFORE,
    SET_RESET_PASSWORD_ACTION_FINISH,
    SET_PUBLISH_LANGUAGES_ACTION_BEFORE,
    SET_PUBLISH_LANGUAGES_ACTION_FINISH,
    SET_PUBLISH_LANGUAGES,
    SET_TRANSLATIONS_LOADED, 
    SET_LAST_LOGINED_USERNAME
} from '../../actionTypes';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case AUTHENTICATE_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case AUTHENTICATE_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case AUTHENTICATE_ACTION_SET_EXPIRE:
            return {
                ...state,
                expires: payload.expires
            }
        case AUTHENTICATE_ACTION_SET_QRBASE64:
            return {
                ...state,
                QRBase64: payload.base64
            }
        case AUTHENTICATE_ACTION_SET_WS_TOKEN:
            return {
                ...state,
                wsToken: payload.wsToken
            }
        case AUTHENTICATE_ACTION_SET_LOCK_DATA:
            return {
                ...state,
                lockData: payload.lockData
            }
        case SET_LAST_LOGINED_USERNAME:
            return {
                ...state,
                lastLoginedUserName: payload.userName
            }
        case SET_PASSWORD_SETTINGS_ACTION_BEFORE:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    isLoading: true
                }
            }
        case SET_PASSWORD_SETTINGS_ACTION_FINISH:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    isLoading: false
                }
            }
        case SET_PASSWORD_SETTINGS:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    passwordSettings: payload.passwordSettings
                }
            }
        case SET_RESET_PASSWORD_ACTION_BEFORE:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isReseting: true
                }
            }
        case SET_RESET_PASSWORD_ACTION_FINISH:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isReseting: false
                }
            }
        case SET_PUBLISH_LANGUAGES_ACTION_BEFORE:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    isLanguagesLoading: true
                }
            }
        case SET_PUBLISH_LANGUAGES_ACTION_FINISH:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    isLanguagesLoading: false
                }
            }
        case SET_PUBLISH_LANGUAGES:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    languages: payload.data,
                    isLanguagesLoaded: true
                }
            }
        case SET_TRANSLATIONS_LOADED:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    loaded: true
                }
            } 
        default:
            return state;
    }
}