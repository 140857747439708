//#region react
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getCommissionCalculationsReport,
    setCommissionCalculationsReportSorting,
    setCommissionCalculationsReportFilters,
    recalculateCommissionPlan
} from "store/actions/dashboard/reports/commissionCalculations.action";
import { getGameProviders } from "store/actions/dashboard/common/common.action";
//#endregion

//#region components
import Table from "components/common/table";
import Confirmation from "components/common/confirmation";
import MainDashboardLayout from "components/layouts/main";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useProjectType from "hooks/useProjectType";
//#endregion

//#region utils
import { isFranchisingMode, isMobile } from "utils/common";
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import Paths from 'constants/path.constants';
import { COMMISSION_PLAN_SOURCE } from "constants/commission.constants";
import { EXPAND_TABLE_TYPES } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { COMMISSION_CALCULATIONS } from "constants/pageName.constants";
import { expandColumns, getTableColumns } from "./columns";
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import commissionCalculationsReportType from "types/reports/commissionCalculationsReport.type";
import gameProviderType from "types/project/gameProvider.type";
//#endregion

const DEFAULT_RECALCULATE_CONFIRMATION_DATA = {
    isVisible: false,
    id: null
}

const CommissionCalculationReportComponent = ({
    getCommissionCalculationsReport,
    report,
    isLoading,
    total,
    setCommissionCalculationsReportSorting,
    setCommissionCalculationsReportFilters,
    sorting,
    filters,
    globalProjectId,
    gameProviders,
    getGameProviders,
    recalculateCommissionPlan
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { hasRetail } = useProjectType();

    const [recalculateConfirmationData, setRecalculateConfirmationData] = useState({
        ...DEFAULT_RECALCULATE_CONFIRMATION_DATA
    })

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: COMMISSION_CALCULATIONS });

    const franchisingMode = isFranchisingMode();

    const nonExpandRowIds = report.filter(r => r.previousCalculations.length === 0).map(r => r.id);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_COMMISSION_CALCULATIONS,
        action: PERMISSION_ACTION.EXPORT
    });

    const hasDataModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_COMMISSION_CALCULATIONS,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setCommissionCalculationsReportFilters({
            ...filters,
            nameOrId: value
        })
    }

    const handleRightIconClick = (record) => {
        navigate(`${Paths.REPORTS_AGENT_CALCULATION_HISTORY}/${record.id}?name=${record.name}`)
    }

    const showRecalculateConfirmation = (record) => {
        setRecalculateConfirmationData({
            isVisible: true,
            id: record.groupId
        })
    }

    const hideRecalculateConfirmation = () => {
        setRecalculateConfirmationData({
            ...DEFAULT_RECALCULATE_CONFIRMATION_DATA,
        })
    }

    const handleRecalculate = () => {
        recalculateCommissionPlan(recalculateConfirmationData.id);
        hideRecalculateConfirmation();
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        expandTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            expandColumns,
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                gameProviders,
                franchisingMode,
                hasRetail
            }
        })
    }, [includedColumns, franchisingMode, gameProviders, hasRetail])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t('backoffice.commissioncalculations.perAgent'),
            icon: "icon-right",
            onClick: handleRightIconClick,
        }
    ];

    if (hasDataModifyPermission) {
        tableRowActions.unshift({
            title: t('backoffice.commissionplans.recalculate'),
            icon: "icon-recalculate",
            onClick: showRecalculateConfirmation,
            disabled: record => (
                record.source === COMMISSION_PLAN_SOURCE.DEPOSIT ||
                record.source === COMMISSION_PLAN_SOURCE.WITHDRAWAL
            )
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getCommissionCalculationsReport,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.COMMISSION_PLAN
            },
            placeholder: t("backoffice.commissioncalculations.commissionPlanNameOrId"),
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.commissionCalculations') }]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.commissionCalculations"),
            url: ApiUrls.GET_COMMISSION_CALCULATIONS_REPORT_EXPORT,
            filters: filters
        }
    }

    //#endregion

    // Load providers
    useEffect(() => {
        if (franchisingMode) {
            return;
        }

        getGameProviders();
    }, [])

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={report}
                loadFn={getCommissionCalculationsReport}
                sorting={sorting}
                setSortingFn={setCommissionCalculationsReportSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                expandable={{
                    title: t('backoffice.commissioncalculations.recalculationHistory'),
                    disabled: nonExpandRowIds,
                    details: record => (Boolean(record.previousCalculations) && ({
                        columns: expandTableColumns,
                        data: record.previousCalculations ?? [],
                        type: EXPAND_TABLE_TYPES.BOTH,
                        actions: [tableRowActions.at(-1)], // This is the same action as the last action in the main table row
                        hideEmpty: true,
                        content: !isMobile() && (
                            <h4 className="rt--form-section-title rt--font-normal rt--font-bold rt--mb-14">
                                {t('backoffice.commissioncalculations.recalculationHistory')}
                            </h4>
                        ),
                    })),
                }}
            />

            <Confirmation
                title={t('backoffice.commissionplans.recalculate')}
                message={t('backoffice.commissionplans.doYouWanttoRecalculate')}
                onOk={handleRecalculate}
                onCancel={hideRecalculateConfirmation}
                isVisible={recalculateConfirmationData.isVisible}
            />
        </MainDashboardLayout>
    )
};

/** CommissionCalculationReportComponent propTypes
 * PropTypes
*/
CommissionCalculationReportComponent.propTypes = {
    /** Redux action to get commission calculation report */
    getCommissionCalculationsReport: PropTypes.func,
    /** Redux state property, represents the commission calculation report  */
    report: PropTypes.arrayOf(commissionCalculationsReportType),
    /** Redux state property, is true when loading commission calculation report */
    isLoading: PropTypes.bool,
    /** Redux state property, commission calculation report total count */
    total: PropTypes.number,
    /** Redux action to set commission calculation report sorting details */
    setCommissionCalculationsReportSorting: PropTypes.func,
    /** Redux action to set commission calculation report filters */
    setCommissionCalculationsReportFilters: PropTypes.func,
    /** Redux state property, commission calculation report sorting details */
    sorting: sortingType,
    /** Redux state property, commission calculation report filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to get game providers */
    getGameProviders: PropTypes.func,
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType),
    /** Redux action to recalculate commission plan */
    recalculateCommissionPlan: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getCommissionCalculationsReport: nextPage => {
        dispatch(getCommissionCalculationsReport(nextPage));
    },
    setCommissionCalculationsReportSorting: sorting => {
        dispatch(setCommissionCalculationsReportSorting(sorting));
    },
    setCommissionCalculationsReportFilters: filters => {
        dispatch(setCommissionCalculationsReportFilters(filters));
    },
    getGameProviders: () => {
        dispatch(getGameProviders());
    },
    recalculateCommissionPlan: id => {
        dispatch(recalculateCommissionPlan(id))
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.commissionCalculationsReport.isLoading,
        report: state.commissionCalculationsReport.report,
        total: state.commissionCalculationsReport.total,
        sorting: state.commissionCalculationsReport.sorting,
        filters: state.commissionCalculationsReport.filters,
        globalProjectId: state.common.globalProjectId,
        gameProviders: state.common.gameProviders
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionCalculationReportComponent);
