import PropTypes from 'prop-types';
import {
    COMPANY_PASSWORD_PATTERN,
    COMPANY_PASSWORD_PATTERN_CONTAINS,
    COMPANY_CONTROL_RULE
} from 'constants/company.constants';

export default PropTypes.shape({
    passwordMinLimit: PropTypes.number,
    passwordMaxLimit: PropTypes.number,
    forceChange: PropTypes.number,
    pattern: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(COMPANY_PASSWORD_PATTERN)),
        contain: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.oneOf(Object.values(COMPANY_PASSWORD_PATTERN_CONTAINS)),
            value: PropTypes.oneOf(Object.values(COMPANY_CONTROL_RULE)),
        })),
        regularExpression: PropTypes.string
    })
})