import React from "react";
import PropTypes from 'prop-types';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Spin } from "antd";

import { classNames, isFunction, isMobile } from "utils/common";

// To hide the indication line after the mouse leaves the chart area
Highcharts.wrap(
    Highcharts.Pointer.prototype,
    'onContainerMouseLeave',
    function (proceed) {
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));

        if (Boolean(this.chart.customcrosshair)) {
            this.chart.customcrosshair.destroy();
            this.chart.customcrosshair = null;
        }
    }
);

/** LineChart Component */
const LineChart = ({
    renderHeader,
    hideChart = false,
    configs = {},
    isLoading = false,
    total,
}) => {
    const isMobileDevice = isMobile();

    return (
        <div
            style={{height: '100%'}}
            className="rt--chart-operational-line rt--pb-16 rt--pl-16 rt--pr-16"
        >
            {
                isFunction(renderHeader)
                    ? (
                        renderHeader()
                    ) : (
                        <div
                            className={classNames(
                                "rt--flex rt--mb-8",
                                isMobileDevice ? "rt--flex-col" : "rt--justify-between",
                            )}
                        >
                            {Boolean(total) && (
                                <span
                                    className="rt--font-extra-bold rt--font-big"
                                >
                                    {total}
                                </span>
                            )}
                        </div>
                    )
            }

            <div className="rt--chart-section-block">
                {
                    isLoading ? (
                        <Spin spinning={isLoading} />
                    ) : (!hideChart) && (
                        <HighchartsReact isLoading={isLoading} highcharts={Highcharts} options={configs} />
                    )
                }
            </div>
        </div>
    )
};

/** LineChart propTypes*/
LineChart.propTypes = {
    hideChart: PropTypes.bool,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    renderHeader: PropTypes.func,
    total: PropTypes.string,
    /**
     * All configs for line chart (can be constructed via <constructLineChartConfigs> function)
     */
    configs: PropTypes.object,
};

export default LineChart;
