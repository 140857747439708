import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import TransactionsReport from "../transactionsReport";

import {
    getCashierTransactionsReport,
    setCashierTransactionsReportSorting,
    setCashierTransactionsReportFilters
} from "store/actions/dashboard/reports/cashierTransactions.action";

import { WALLET_OWNER_TYPE } from "constants/wallet.constants";

import sortingType from "types/common/sorting.type";
import transactionsReportType from "types/reports/transactionsReport.type";

/** Cashier Transaction Report Page Component */
const CashierTransactionReportComponent = ({
    getCashierTransactionsReport,
    report,
    isLoading,
    total,
    setCashierTransactionsReportSorting,
    setCashierTransactionsReportFilters,
    sorting,
    filters
}) => {

    const { t } = useTranslation();

    return (
        <TransactionsReport 
            getTransactionsReport={getCashierTransactionsReport}
            setTransactionsReportSorting={setCashierTransactionsReportSorting}
            setTransactionsReportFilters={setCashierTransactionsReportFilters}
            report={report}
            isLoading={isLoading}
            total={total}
            sorting={sorting}
            filters={filters}
            title={t("backoffice.menu.cashierTransactions")}
            type={WALLET_OWNER_TYPE.CASHIER}
        />
    );
};

/** CashierTransactionReportComponent propTypes
 * PropTypes
*/
CashierTransactionReportComponent.propTypes = {
    /** Redux action to get cashier transactions report */
    getCashierTransactionsReport: PropTypes.func,
    /** Redux state property, represents the cashier transactions report  */
    report: PropTypes.arrayOf(transactionsReportType),
    /** Redux state property, is true when loading cashier transactions report */
    isLoading: PropTypes.bool,
    /** Redux state property, cashier transactions report total count */
    total: PropTypes.number,
    /** Redux action to set cashier transactions report sorting details */
    setCashierTransactionsReportSorting: PropTypes.func,
    /** Redux action to set cashier transactions report sorting details */
    setCashierTransactionsReportFilters: PropTypes.func,
    /** Redux state property, cashier transactions report sorting details */
    sorting: sortingType,
    /** Redux state property, cashier transactions report filters */
    filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    getCashierTransactionsReport: nextPage => {
        dispatch(getCashierTransactionsReport(nextPage));
    },
    setCashierTransactionsReportSorting: sorting => {
        dispatch(setCashierTransactionsReportSorting(sorting));
    },
    setCashierTransactionsReportFilters: filters => {
        dispatch(setCashierTransactionsReportFilters(filters));
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.cashierTransactionsReport.isLoading,
        report: state.cashierTransactionsReport.report,
        total: state.cashierTransactionsReport.total,
        sorting: state.cashierTransactionsReport.sorting,
        filters: state.cashierTransactionsReport.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CashierTransactionReportComponent);
