import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row, Spin } from 'antd';

import Select from "components/common/select";

import { REPARENT_ENTITY_TYPE } from "constants/agent.constants";

import agentType from "types/agent/agent.type";

/** Reparent Popup Form Component */
const ReparentFormComponent = ({
    availableAgentsForReparenting,
    isAvailableAgentsForReparentingLoading,
    formInstance,
    isPlayersSaving,
    isAgentsSaving,
    isBetshopsSaving
}) => {
    const { t } = useTranslation();

    const isSaving = isPlayersSaving || isAgentsSaving || isBetshopsSaving;

    const availableAgents = useMemo(() => {
        const available = [...availableAgentsForReparenting];
        return available.sort((a, b) => a.hierarchyLevel < b.hierarchyLevel ? -1 : 1)
    }, [availableAgentsForReparenting])

    return (
        <Spin spinning={isSaving}>
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.agents.newParentAgent')}
                            name="parentId"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                        >
                            <Select
                                options={
                                    availableAgents.map(agent => ({
                                        text: agent.userName,
                                        value: agent.id,
                                        label: agent.hierarchyLevel > 0 ? `${t("backoffice.agents.level")} ${agent.hierarchyLevel}` : t("backoffice.common.project")
                                    }))
                                }
                                search={true}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.agents.newParentAgent')}`}
                                loading={isAvailableAgentsForReparentingLoading}
                                withLabel={true}
                                allowClear
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className='rt--flex rt--form-item-note rt--align-center'>
                            <i className='icon-info rt--font-bigest rt--mr-8' />
                            <span className='rt--title rt--font-normal rt--font-regular rt--flex-equal'>{t("backoffice.agents.reparentAgentNote")}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

/** ReparentFormComponent propTypes
    * PropTypes
*/
ReparentFormComponent.propTypes = {
    /** Redux state property, is true when available agents list for reparenting is loading */
    isAvailableAgentsForReparentingLoading: PropTypes.bool,
    /** Redux state property, available agents list for reparenting */
    availableAgentsForReparenting: PropTypes.arrayOf(agentType),
    /** Form instance */
    formInstance: PropTypes.object,
    /** Redux state property, is players saving */
    isPlayersSaving: PropTypes.bool,
    /** Redux state property, is agents saving */
    isAgentsSaving: PropTypes.bool,
    /** Redux state property, is betshops saving */
    isBetshopsSaving: PropTypes.bool,
    /** Entity type */
    type: PropTypes.oneOf(Object.values(REPARENT_ENTITY_TYPE)),
    /** Is true when reparent process in running */
    isLoading: PropTypes.bool
}


const mapStateToProps = state => {
    return {
        availableAgentsForReparenting: state.agents.availableAgentsForReparenting,
        isAvailableAgentsForReparentingLoading: state.agents.isAvailableAgentsForReparentingLoading,
        isPlayersSaving: state.players.isSaving,
        isAgentsSaving: state.agents.isSaving,
        isBetshopsSaving: state.betshops.isSaving
    }
}

export default connect(mapStateToProps, null)(ReparentFormComponent)