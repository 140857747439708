import TreeNodeTitle from "pages/agentSystem/network/sections/networkTree/components/treeNodeTitle";

export const mapToTreeNodes = (nodes, extraMapper) => {
    return nodes.map(node => ({
        key: node.id,
        title: (
            <TreeNodeTitle
                id={node.id}
                name={node.userName}
                type={node.nodeType}
                playersCount={node.playerCount}
                subAgentsCount={node.subAgentsCount}
                betshopsCount={node.betshopCount}
            />
        ),
        isLeaf: node.subAgentsCount === 0,
        children: node.nodes ? mapToTreeNodes(node.nodes, extraMapper) : [],
        ...(extraMapper ? extraMapper(node) : {})
    }))
}
