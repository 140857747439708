import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { connect } from 'react-redux';

import { Menu, Button } from 'antd';

import Clock from "./clock";

import LogoRetail from "assets/images/logo-retail-sidebar.svg";
import LogoRetailWait from "assets/images/logo-white.svg";
import CollapseLogo from 'assets/images/collapse-logo.svg'
import Favicon from 'assets/images/favicon.svg'

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import { isMobile, isFranchisingMode, getProjectLogosPaths } from "utils/common";
import { isMenuItemAvailable } from "utils/menu";
import { logout } from 'utils/auth';

import getMenuItems from "components/common/sidebar/items";

import { LOGO_TYPES, UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';
import useProjectType from "hooks/useProjectType";

/** Sidebar component */
const Sidebar = ({
    collapsed,
    onCollapse,
    globalProjectType,
    globalPaymentType,
    globalProjectMode,
    globalProjectId,
    companies,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { pathname, search } = useLocation();
    const params = useParams();
    const { hasLiteMode } = useProjectType();

    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openedKeys, setOpenedKeys] = useState([]);

    const handleLogoClick = () => {
        window.location.reload();
    }

    const navigateWithConfirmation = useUnsavedFormConfirmation({
        cb: navigate,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const handleLogoClickWithConfirmation = useUnsavedFormConfirmation({
        cb: handleLogoClick,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    /** Detects which menu items should be opened by default depend on route
       * @function
       * @memberOf Sidebar
   */
    const setDefaultOpenedKeys = () => {
        const result = [];
        let path = pathname;

        if (params) {
            Object.keys(params).forEach(k => {
                path = path.replace(params[k], ":" + k)
            })
        }

        if (search) {
            path = path + search;
        }

        getMenuItems({hasLiteMode}).forEach(item => {
            if (item.items) {
                item.items.forEach(i => {
                    if (i.items) {
                        i.items.forEach(it => {
                            if (it.match && (it.match.some(p => path.includes(p)))) {
                                result.push(item.id);
                                result.push(i.id);
                            }
                        })
                    } else {
                        if (i.match && (i.match.some(p => path.includes(p)))) {
                            result.push(item.id);
                        }
                    }

                })
            }
        })
        setOpenedKeys(result);
        return result;
    }

    /** Update active menu items depend on url change
       * @function
       * @memberOf Sidebar
   */
    const setDefaultSelectedKeys = () => {
        const result = [];

        getMenuItems({hasLiteMode}).forEach(item => {
            let path = pathname;

            if (params) {
                Object.keys(params).forEach(k => {
                    path = path.replace(params[k], ":" + k)
                })
            }

            if (search) {
                path = path + search;
            }

            if (item.match && (item.match.some(p => path.includes(p)))) {
                result.push(item.id);
            }
            if (item.items) {
                item.items.forEach(i => {

                    if (i.items) {
                        i.items.forEach(it => {
                            if (it.match && (it.match.some(p => path.includes(p)))) {
                                result.push(it.id);
                            }
                        })
                    } else {
                        if (i.match && (i.match.some(p => path.includes(p)))) {
                            result.push(i.id);
                        }
                    }

                })
            }
        })
        setSelectedKeys(result);
    }

    /** Fires on menu item click
       * @function
       * @memberOf Sidebar
   */
    const handleMenuItemClick = url => {
        navigateWithConfirmation(url);
        document.body.classList.remove("rt--body-open-sidebar")
    }

    /** Update active menu items, when path changes */
    useEffect(() => {
        if (!collapsed)  {
            setDefaultOpenedKeys()
        }
        setDefaultSelectedKeys();
    }, [pathname + search])

    /** Function event listener for window resize
       * @function
       * @memberOf Sidebar
   */
    const onWindowResize = () => {
        if (window.innerWidth < 1440) {
            onCollapse(true)
        } else {
            onCollapse(false)
        }
    }


    useEffect(() => {
        if (!isMobile()) {
            onWindowResize();
            window.removeEventListener('resize', onWindowResize);
            window.addEventListener('resize', onWindowResize);

            return () => {
                window.removeEventListener('resize', onWindowResize);
            };
        }
    }, [])

    const logoPaths = useMemo(() => {
        const paths = getProjectLogosPaths(companies, globalProjectId);

        const favicon = document.querySelector('link[rel="icon"]');

        if (favicon) {
            favicon.href = (
                paths[LOGO_TYPES.FAVICON]
                    ? `${import.meta.env.SYSTEM_CDN_URL}/${paths[LOGO_TYPES.FAVICON]}`
                    : Favicon
            )
        }

        return paths;
    }, [companies, globalProjectId])

    return (
        <div className='rt--sidebar-content rt--pt-24 rt--flex rt--flex-col'>
            {
                isMobile() && (
                    <div
                        className='rt--sidebar-content-close'
                        onClick={() => document.body.classList.remove("rt--body-open-sidebar")}
                    >
                        <i className='icon-close' />
                    </div>
                )

            }
            <div className='rt--sidebar-content-logo rt--cursor-pointer rt--flex rt--align-center rt--pl-16 rt--pr-16' onClick={handleLogoClickWithConfirmation}>
                {
                    isFranchisingMode()
                        ? <img alt="logo" src={logoPaths[LOGO_TYPES.LOGO] ? `${import.meta.env.SYSTEM_CDN_URL}/${logoPaths[LOGO_TYPES.LOGO]}` : LogoRetailWait} />
                        : <img alt="logo" src={logoPaths[LOGO_TYPES.LOGO] ? `${import.meta.env.SYSTEM_CDN_URL}/${logoPaths[LOGO_TYPES.LOGO]}` : LogoRetail} />
                }
            </div>

            <div className='rt--sidebar-content-logo-collapse rt--cursor-pointer' onClick={handleLogoClickWithConfirmation}>
                {
                    collapsed
                        ? <img alt='logo' src={logoPaths[LOGO_TYPES.COLLAPSED_LOGO] ? `${import.meta.env.SYSTEM_CDN_URL}/${logoPaths[LOGO_TYPES.COLLAPSED_LOGO]}` : CollapseLogo} />
                        : ''
                }
            </div>

            <div className='rt--sidebar-content-menu rt--flex-equal'>
                <Menu
                    mode="inline"
                    expandIcon={<i className="icon-down rt--sidebar-content-menu-down" />}
                    selectedKeys={selectedKeys}
                    openKeys={openedKeys}
                    onOpenChange={keys => { setOpenedKeys(keys.filter(k => k.length === 1 || keys.includes(k[0]))) }}
                    items={
                        getMenuItems({hasLiteMode})
                            .filter(item => isMenuItemAvailable(item, globalProjectType, globalProjectId, globalPaymentType ?? 0, globalProjectMode))
                            .map(item => item.items
                                ? {
                                    key: item.id,
                                    icon: <i className={item.icon}/>,
                                    label: <span title={t(item.title)}>{item.renderTitle ? item.renderTitle(t(item.title)) : t(item.title)}</span>,
                                    popupClassName: "rt--sidebar-content-menu-sub",
                                    expandIcon: <i className="icon-down rt--sidebar-content-menu-down" />,
                                    children: (
                                        item.items
                                            .filter(i => isMenuItemAvailable(i, globalProjectType, globalProjectId, globalPaymentType ?? 0, globalProjectMode))
                                            .map(i => (
                                                {
                                                    key: i.id,
                                                    onClick: () => handleMenuItemClick(i.url),
                                                    label: <span title={t(i.title)}>{i.renderTitle ? i.renderTitle(t(i.title)) : t(i.title)}</span>
                                                }
                                            ))
                                    )
                                }
                                : (
                                    {
                                        key: item.id,
                                        icon: <i className={item.icon}/>,
                                        onClick: () => handleMenuItemClick(item.url),
                                        label: <span title={t(item.title)}>{item.renderTitle ? item.renderTitle(t(item.title)) : t(item.title)}</span>
                                    }
                                )
                            )
                    }
                />
            </div>
            {
                isMobile() && (
                    <div className='rt--sidebar-content-logout rt--pl-16 rt--pr-16 rt--mt-24'>
                        <div className='rt--sidebar-content-logout-separator rt--mb-32' />
                        <Button
                            className='rt--sidebar-content-logout-button'
                            onClick={logout}
                        >
                            <i className='icon-logout' />
                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.common.logout')}</span>
                        </Button>
                    </div>
                )
            }

            <div className='rt--sidebar-content-footer rt--pl-24 rt--pr-16 rt--flex rt--justify-between rt--align-center rt--pb-26'>
                <Clock collapsed={collapsed} />
                {
                    !isMobile() && (
                        <div className='rt--sidebar-content-footer-collapse' onClick={() => onCollapse(!collapsed)}>
                            <i className={collapsed ? 'icon-expand' : 'icon-collapse'} />
                        </div>
                    )
                }

            </div>
        </div>
    )

}

Sidebar.propTypes = {
    /** Is Sidebar collapsed */
    collapsed: PropTypes.bool,
    /** Function to call to collapse sidebar*/
    onCollapse: PropTypes.func,
    /** Redux state property, represents global project type */
    globalProjectType: PropTypes.number,
    /** Redux state property, represents global payment type */
    globalPaymentType: PropTypes.number,
    /** Redux state property, represents global payment mode */
    globalProjectMode: PropTypes.number,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, represents available companies */
    companies: PropTypes.array
}

const mapStateToProps = state => {
    return {
        globalProjectType: state.common.globalProjectType,
        globalProjectId: state.common.globalProjectId,
        globalPaymentType: state.common.globalPaymentType,
        globalProjectMode: state.common.globalProjectMode,
        companies: state.profile.userInfo.companies,
    }
}

export default connect(
    mapStateToProps,
    null
)(
    Sidebar
);
