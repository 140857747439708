import {PERMISSION_ACTION} from "constants/permissions.constants";

export const getPermissionDependency = (permissions, permission, parent, action) => {
    if (permission.isParent && action === PERMISSION_ACTION.VIEW) {
        return [permission.resource];
    }

    if (permission.isParent) {
        return [permission.resource, 'parent', action];
    }

    if (permission.resource.includes('_GeneralInfo') && action === PERMISSION_ACTION.VIEW && parent) {
        return [parent.resource];
    }

    if (action === PERMISSION_ACTION.VIEW) {
        return [parent.resource, permission.resource];
    }

    return [parent.resource, permission.resource, action];
}
