import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Descriptions } from "antd";

import Modal from "components/common/modal";

import { makeTransactionText } from "utils/wallet";
import { isMobile } from "utils/common";

import { POPUP_SIZE } from 'constants/common.constants';
import { NOTIFICATION_TYPES_ENUM } from "constants/notification.constants";

import notificationType from 'types/notification/notification.type';

/** Notification info popup */
const NotificationPopup = ({
    onCancel,
    notification
}) => {
    const { t } = useTranslation();

    return <Modal
        title={notification.name}
        okText={isMobile() ? undefined : t('backoffice.common.close')}
        onOk={onCancel}
        onCancel={onCancel}
        size={POPUP_SIZE.BIG}
        hideCancelButton={true}
        closable={isMobile()}
        maskClosable={isMobile()}
    >
        {
            notification.type === NOTIFICATION_TYPES_ENUM.LIMIT_EXCEEDED ? (
                <Descriptions
                    className='rt--description'
                    column={1}
                >
                    <Descriptions.Item label={t("backoffice.wallet.transactionType")}>{makeTransactionText(notification?.data?.transactionType ?? "")}</Descriptions.Item>
                    <Descriptions.Item label={t("backoffice.agents.agent")}>{notification?.data?.agent ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label={t("backoffice.agents.subAgent")}>{notification?.data?.subAgent ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label={t("backoffice.wallet.amount")}>{notification?.data?.amount ?? ""}</Descriptions.Item>
                    <Descriptions.Item label={t("backoffice.agents.allowedMaxNegativeCreditSubTree")}>{notification?.data?.allowedMaxNegativeCreditSubTree ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label={t("backoffice.agents.allowedMaxPositiveCreditSubTree")}>{notification?.data?.allowedMaxPositiveCreditSubTree ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label={t("backoffice.agents.subTreeCredit")}>{notification?.data?.subTreeCredit ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label={t("backoffice.agents.subTreeAgent")}>{notification?.data?.subTreeAgent ?? "-"}</Descriptions.Item>
                </Descriptions>
            ) : <span></span>
        }

    </Modal>
}

/** NotificationPopup propTypes
    * PropTypes
*/
NotificationPopup.propTypes = {
    /** Notification  */
    notification: notificationType,
    /** Modal Cancel button click handler */
    onCancel: PropTypes.func
}

export default NotificationPopup;