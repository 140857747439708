import axios from "axios";

import Paths from "NEW/src/core/api/paths";
import { TokenManager } from "NEW/src/core/api/tokenManager";
import {AUTHORIZATION, RESPONSE_CODES} from "NEW/src/core/data/constants/api";
import { getAuthData } from "NEW/src/core/helpers/auth/getAuthData";
import { SessionManager } from "NEW/src/core/lib/sessionManager";
import {processError} from "NEW/src/core/helpers/common/processError";
import {authStore} from "NEW/src/core/stores/authStore";

const axiosInstance = axios.create();

// Function that is executed before the request
axiosInstance.interceptors.request.use(
	(request) => {
		const authData = getAuthData();

		SessionManager.instance.refresh();

		if (!authData) {
			return request;
		}

		if (!request.headers[AUTHORIZATION]) {
			request.headers[AUTHORIZATION] = "Bearer " + authData.token;
		}

		return request;
	},
	(error) => {
		return Promise.reject(error);
	},
);

// Function that is executed after the request completes
axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const isLoginURL = error.config.url.includes(Paths.AUTHENTICATE);
		const errorData = error.response.data;

		if (error.response.status === 401 && !isLoginURL) {
			if (!TokenManager.instance.isTokenRefreshing) {
				TokenManager.instance.updateToken();
			}

			return TokenManager.instance.registerPendingRequest(error.config).catch(processError);
		}

		if ([RESPONSE_CODES.AccountLoginLocked, RESPONSE_CODES.AccountLoginTemporaryLocked].includes(errorData.resultCode)) {
			authStore.setLockData({
				resultCode: errorData?.resultCode ?? 0,
				expireTime: errorData?.fullMessage ?? "",
			});
			return Promise.reject(error);
		}

		return Promise.reject(error).catch(processError);
	},
);

export default axiosInstance;
