import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Divider} from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { getBetshopTransactionsReport, setBetshopTransactionsReportFilters } from "store/actions/dashboard/reports/betshopTransactions.action";

import { makeTransactionText } from "utils/wallet";

import { WALLET_OPERATION_TYPE } from "constants/wallet.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

/** Betshop Transaction Report Page FiltersComponent */

const Filters = ({
    setBetshopTransactionsReportFilters,
    getBetshopTransactionsReport,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const availableOperationTypes = [
        WALLET_OPERATION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER,
        WALLET_OPERATION_TYPE.BETSHOP_MANAGER_WITHDRAWAL_CASHIER,
        WALLET_OPERATION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT,
        WALLET_OPERATION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT,
    ]

    const [ cashierNames, getCashierNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CASHIER,
        autoGet: false
    });
    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={getBetshopTransactionsReport}
            setFiltersFn={setBetshopTransactionsReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName="groupId"
            onInit={() => {
                getCashierNames();
                getBetshopNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            enabledMountsCount={12}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.transactionType')}
                        name="transactionTypes"
                    >
                        <Select
                            options={
                                availableOperationTypes.map(type => (
                                    { value: type, text: makeTransactionText(type)}
                                ))
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.transactionType')}`}
                            isMultiple={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                    <Divider className='rt--filters-divider' />
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.betshop')}
                        name="betShopId"
                    >
                        <Select
                            options={
                                [
                                    { value: null, text: t("backoffice.common.all")},
                                    ...betshopNames.map(b => (
                                        { value: b.id, text: b.name}
                                    ))
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.betshop')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.cashierUsernameOrId')}
                        name="cashier"
                    >
                        <AutoComplete
                            placeholder={
                                `${t('backoffice.common.enter')} ${t('backoffice.reports.cashierUsernameOrId')}`}
                            items={cashierNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get betshop transactions report */
    getBetshopTransactionsReport: PropTypes.func,
    /** Redux action to set betshop transactions report sorting details */
    setBetshopTransactionsReportFilters: PropTypes.func,
    /** Redux state property, errors filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getBetshopTransactionsReport: nextPage => {
            dispatch(getBetshopTransactionsReport(nextPage));
        },
        setBetshopTransactionsReportFilters: filters => {
            dispatch(setBetshopTransactionsReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.betshopTransactionsReport.filters,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
