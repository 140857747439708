import PropTypes from 'prop-types';

import { 
    COMMISSION_PLAN_SOURCE, 
    COMMISSION_PLAN_TYPE,
    COMMISSION_PLAN_CALCULATION_PERIOD,
} from "constants/commission.constants"

export default PropTypes.shape({
    id: PropTypes.number,
    commissionId: PropTypes.number,
    name: PropTypes.string,
    period: PropTypes.oneOf(Object.values(COMMISSION_PLAN_CALCULATION_PERIOD)),
    source: PropTypes.oneOf(Object.values(COMMISSION_PLAN_SOURCE)),
    sourceDirection: PropTypes.number,
    nextCalculationDateTime: PropTypes.string,
    currencyCode: PropTypes.string,
    sourceAmount: PropTypes.number,
    amount: PropTypes.number,
    from: PropTypes.string,
    to: PropTypes.string,
    state: PropTypes.number,
    onlineProviders: PropTypes.arrayOf(PropTypes.string),
    retailProviders: PropTypes.number,
    type: PropTypes.oneOf(Object.values(COMMISSION_PLAN_TYPE)),
    calculatedAt: PropTypes.string,
    count: PropTypes.number,
    groupId: PropTypes.number,
    previousCalculations: PropTypes.arrayOf(PropTypes.object)
})