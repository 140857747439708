import React, {memo} from 'react';
import {Checkbox, Form} from "antd";
import Tooltip from "components/common/tooltip";
import {isCheckedAll} from "components/common/permissions/helpers/isCheckedAll";
import {isUnCheckedAll} from "components/common/permissions/helpers/isUnCheckedAll";
import {useTranslation} from "react-i18next";

const withWrapper = (WrappedComponent) => ({permissions, formInstance, fieldName, ...props}) => {
    Form.useWatch(fieldName ? [fieldName, props.resource] : [props.resource], formInstance);

    const { getFieldValue, getFieldsValue } = formInstance;

    const isResourceHasAction = (resource, action) => {
        const permission = permissions.find(p => p.resource === resource);
        if (!permission) return false;
        return permission.actions.some(a => a.action === action && a.canEdit) || permission.subPermissions.some(p => p.actions.some(a => a.action === action && a.canEdit));
    }

    const isAllChecked = props.editable ? isCheckedAll({
        resource: props.resource,
        action: props.action,
        fieldName,
        getFieldValue,
        getFieldsValue
    }) : false;

    const isAllUnChecked = props.editable ? isUnCheckedAll({
        resource: props.resource,
        action: props.action,
        fieldName,
        getFieldsValue,
        getFieldValue
    }) : false;

    const resourceHasAction = isResourceHasAction(props.resource, props.action);

    return (
        <WrappedComponent
            {...props}
            isAllChecked={isAllChecked}
            isAllUnChecked={isAllUnChecked}
            resourceHasAction={resourceHasAction}
        />
    )
}

const TableHeaderCell = ({
    resource,
    action,
    editable,
    disabled,
    isAllChecked,
    isAllUnChecked,
    resourceHasAction,
    onCheckAll,
    onUnCheckAll
}) => {
    const { t } = useTranslation();

    return (
        <div className='rt--flex rt--align-center rt--justify-between'>
            <div className="rt--permissions-table-content rt--flex rt--align-center rt--justify-between">
                {
                    editable && !disabled && resourceHasAction &&
                    (
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mr-8">
                            <Form.Item
                                className="rt--form-item-without-margin rt--permissions-table-checkbox"
                            >
                                <Tooltip
                                    title={isAllChecked ? t("backoffice.permissions.deSelectAll") : t("backoffice.permissions.selectAll")}
                                >
                                    <Checkbox
                                        checked={isAllChecked}
                                        indeterminate={!isAllUnChecked && !isAllChecked}
                                        onClick={e => !isAllChecked ? onCheckAll(resource, action, e) : onUnCheckAll(resource, action, e)}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </div>
                    )
                }
                <span>{t(`backoffice.permissions.${action}`)}</span>
            </div>
        </div>
    );
};

export default withWrapper(memo(TableHeaderCell));
