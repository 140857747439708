export const BET_PRODUCT_TYPE = {
    ONLINE: 1,
    RETAIL: 2
}

export const BET_STATE = {
    PENDING: 1,
    RETURN: 2,
    WON: 4,
    SEMIWON: 8,
    SEMILOST: 16,
    LOST: 32,
    CANCELLED: 64,
    REJECTED_BY_PROVIDER: 128,
    CASHED_OUT: 'cashedout',
	PAID_OUT: "*",
}

export const BET_TYPE = {
    SINGLE: 1,
    MULTI: 2,
    SYSTEM: 3
}

export const FAVORITE_BETS = ["x2", "x3", "x4", "x5", "x7", "x10", "x20", "x50", "Min", "Max"];

export const ONLINE_BET_TYPE = {
    BET: 1,
    BONUS: 2
}

export const BETTING_TYPE = {
    PREMATCH: 1,
    LIVE: 2,
    KOMBI: 3
}

export const BET_GENERATION_TYPE = {
    AGGREGATED: 1,
    SPORT: 2,
    OTHER_COSTS: 4
}

export const PLATFORM_BET_STATE = {
    PENDING: 1,
    WON: 2,
    LOST: 3,
    CANCELLED: 4
}
