import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';

import { addAgentCurrency, getAgentAvailableCurrencies } from "store/actions/dashboard/agentSystem/agents/currencies.action";

import { WALLET_OWNER_TYPE } from "constants/wallet.constants";
import { USER_TYPE } from "constants/user.constants";

import currencyType from 'types/currency/currency.type';

/** Agent Edit Page Currency Add popup Component */
const CurrencyAddComponent = ({
    isSaving,
    addAgentCurrency,
    getAgentAvailableCurrencies,
    availableCurrencies,
    isAvailableLoading,
    currencies,
    onClose
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const { search } = useLocation();

    const entityRole = (new URLSearchParams(search)).get("role") || WALLET_OWNER_TYPE.AGENT;
    const ownerType= Number(entityRole) === USER_TYPE.AGENT_PLAYER ? WALLET_OWNER_TYPE.AGENT_PLAYER : WALLET_OWNER_TYPE.AGENT 

    /** Load agent available currencies */
    useEffect(() => {
        getAgentAvailableCurrencies(searchParams.id);
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf CurrencyAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                addAgentCurrency( searchParams.id, data.currency, ownerType, onClose)
            }).catch(ex => { 
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.currencies.addCurrency')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{}}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.currencies.selectCurrency')} *`}
                            name="currency"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            className='rt--form-item-without-margin'
                        >
                            <Select
                                loading={isAvailableLoading}
                                options={
                                    Object.keys(availableCurrencies)
                                        .filter(item => !currencies.some(c => c.currency === item.toUpperCase()))
                                        .map(item => (
                                            { value: item.toUpperCase(), text: item.toUpperCase() + " - " + availableCurrencies[item] }
                                        ))
                                }
                                placeholder={`${t('backoffice.currencies.selectCurrencyPlaceholder')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                search={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** CurrencyAddComponent propTypes
    * PropTypes
*/
CurrencyAddComponent.propTypes = {
    /** Redux state property, is true when creating betshop request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add currency to agent */
    addAgentCurrency: PropTypes.func,
    /** Redux action to get available currencies of agent */
    getAgentAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the object of available currencies of agent */
    availableCurrencies: PropTypes.object,
    /** Redux state property, is true when loading available currencies of agent */
    isAvailableLoading: PropTypes.bool,
    /** Redux state, represents the currencies of current editing agent  */
    currencies: PropTypes.arrayOf(currencyType),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addAgentCurrency: (id, currency, ownerType, onSuccess) => {
            dispatch(addAgentCurrency(id, currency, ownerType, onSuccess));
        },
        getAgentAvailableCurrencies: id => {
            dispatch(getAgentAvailableCurrencies(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.agents.isSaving,
        availableCurrencies: state.agents.edit.currencies.availableCurrencies,
        isAvailableLoading: state.agents.edit.currencies.isAvailableLoading,
        currencies: state.agents.edit.currencies.currencies
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyAddComponent)