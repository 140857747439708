import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import DateRangePicker from 'components/common/dateRangePicker';

import { getAgentBetshops, setAgentBetshopsFilters } from "store/actions/dashboard/agentSystem/agents/agents.action";

import useAutosuggestion from 'hooks/useAutosuggestion';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

/** Agent Page Betshops Filters Component */

const Filters = ({
    setAgentBetshopsFilters,
    getAgentBetshops,
    filters,
    agentId
}) => {
    const { t } = useTranslation();

    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP, 
        actionParams: agentId, 
        autoGet: false
    });

    return (
        <div onClick={e => e.stopPropagation()}>
            <FiltersWrapper
                loadFn={() => getAgentBetshops(agentId)}
                setFiltersFn={setAgentBetshopsFilters}
                filters={filters}
                updateProps={[agentId]}
                datePickerFields={[
                    { name: "date", time: false }
                ]}
                onInit={() => {
                    getBetshopNames();
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.betshops.betshopNameOrId')}
                            name="nameOrId"
                        >
                            <AutoComplete
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.betshopNameOrId')}`}
                                items={betshopNames}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.common.creationDate')}
                            name="date"
                        >
                            <DateRangePicker showTime={false} />
                        </Form.Item>
                    </Col>
                </Row>
            </FiltersWrapper>
        </div>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get agent betshop list */
    getAgentBetshops: PropTypes.func,
    /** Redux action to set agent betshop list filters */
    setAgentBetshopsFilters: PropTypes.func,
    /** Redux state property, user list betshops filters */
    filters: PropTypes.object,
    /** Agent id */
    agentId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAgentBetshops: agentId => {
            dispatch(getAgentBetshops(agentId))
        },
        setAgentBetshopsFilters: filters => {
            dispatch(setAgentBetshopsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.agents.betshops.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);