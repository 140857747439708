//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getBetshopPerformanceReport,
    setBetshopPerformanceReportFilters,
    setBetshopPerformanceReportSorting,
    getBetshopPerformanceReportTotals
} from 'store/actions/dashboard/reports/betshopPerformanceReport.action';
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Filters from './filters.components';
import TotalsComponent from 'components/common/totals';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
//#endregion

//#region utils
import { isMobile } from 'utils/common';
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import Paths from 'constants/path.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';
import { BETSHOP_PERFORMANCE_REPORT } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import betshopPerformanceReportType from 'types/reports/betshopPerformanceReport.type';
import betshopPerformanceReportTotalsType from "types/reports/betshopPerformanceReportTotals.type";
//#endregion

/** Betshop Performance Report Page Component */
const BetshopPerformanceReport = ({
    globalProjectId,
    isLoading,
    report,
    total,
    sorting,
    filters,
    getBetshopPerformanceReport,
    setBetshopPerformanceReportFilters,
    setBetshopPerformanceReportSorting,
    getBetshopPerformanceReportTotals,
    totals,
    isTotalsLoading,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { formatAmount } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: BETSHOP_PERFORMANCE_REPORT });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE,
        action: PERMISSION_ACTION.EXPORT
    })

    const hasTotalsPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE_TOTALS,
        action: PERMISSION_ACTION.VIEW
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (searchValue) => {
        setBetshopPerformanceReportFilters({
            ...filters,
            betShopNameOrId: searchValue
        })
    }

    const handleRightArrowIconClick = (record) => {
        const d = {
            from : filters.from,
            to : filters.to
        }
        navigate(
            `${Paths.REPORTS_CASHIER_PERFORMANCE_REPORT}/${record.betShopName}` +
            `?data=${btoa(JSON.stringify(d))}`
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                isCashierReport: false,
                formatAmount
            }
        });
    }, [includedColumns, formatAmount])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: (
            <Filters />
        ),
        search: {
            placeholder: t("backoffice.performancereport.searchByBetshopNameOrId"),
            loadFn: getBetshopPerformanceReport,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.BETSHOP,
                dependencies: [globalProjectId]
            },
            onSearch: handleSearchChange,
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.betshopPerformanceReport') }],
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.betshopPerformanceReport"),
            url: ApiUrls.EXPORT_BETSHOP_PERFORMANCE_REPORT,
            filters: filters,
            columns: columnsForExport
        }
    }

    //#endregion

    return (
        <MainDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobile() && hasTotalsPermission}
            extraContent={ hasTotalsPermission ?
                {
                    content: (
                        <TotalsComponent
                            getTotals={getBetshopPerformanceReportTotals}
                            dependencies={[globalProjectId, filters]}
                            totals={totals}
                            isLoading={isTotalsLoading}
                            currencyCode={filters.currencies}
                            totalType={REPORT_TOTAL_TYPE.BETSHOP_PERFORMANCE_REPORT}
                        />
                    )
                } : undefined
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={report}
                loadFn={getBetshopPerformanceReport}
                updateProps={[globalProjectId]}
                sorting={sorting}
                total={total}
                enableReload={true}
                setSortingFn={setBetshopPerformanceReportSorting}
                filters={filters}
                setFiltersFn={setBetshopPerformanceReportFilters}
                actions={[{
                    title: t('backoffice.common.deepView'),
                    icon: "icon-right",
                    onClick: handleRightArrowIconClick
                }]}
            />
        </MainDashboardLayout>
    );
}

/** BetshopPerformanceReport propTypes
 * PropTypes
*/
BetshopPerformanceReport.propTypes = {
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to get betshop performance report */
    getBetshopPerformanceReport: PropTypes.func,
    /** Redux state property, is true when loading betshop performance report */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when loading betshop performance report totals */
    isTotalsLoading: PropTypes.bool,
    /** Redux state property, represents the betshop performance report  */
    report: PropTypes.arrayOf(betshopPerformanceReportType),
    /** Redux state property, betshop performance report total count */
    total: PropTypes.number,
    /** Redux state property, betshop performance report sorting details */
    sorting: sortingType,
    /** Redux state property, betshop performance report filters */
    filters: PropTypes.object,
    /** Redux action to set betshop performance report filters */
    setBetshopPerformanceReportFilters: PropTypes.func,
    /** Redux action to set betshop performance report sorting details */
    setBetshopPerformanceReportSorting: PropTypes.func,
    /** Redux action to get betshop performance report totals */
    getBetshopPerformanceReportTotals: PropTypes.func,
    /** Redux state property, represents the betshop performance report totals */
    totals: betshopPerformanceReportTotalsType,
};

const mapDispatchToProps = dispatch => ({
    getBetshopPerformanceReport: nextPage => {
        dispatch(getBetshopPerformanceReport(nextPage));
    },
    setBetshopPerformanceReportFilters: (filters, keepPage) => {
        dispatch(setBetshopPerformanceReportFilters(filters, keepPage));
    },
    setBetshopPerformanceReportSorting: sorting => {
        dispatch(setBetshopPerformanceReportSorting(sorting));
    },
    getBetshopPerformanceReportTotals: () => {
        dispatch(getBetshopPerformanceReportTotals());
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.betshopPerformanceReport.isLoading,
        isTotalsLoading: state.betshopPerformanceReport.isTotalsLoading,
        report: state.betshopPerformanceReport.report,
        total: state.betshopPerformanceReport.total,
        sorting: state.betshopPerformanceReport.sorting,
        filters: state.betshopPerformanceReport.filters,
        globalProjectId: state.common.globalProjectId,
        totals: state.betshopPerformanceReport.totals,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetshopPerformanceReport);
