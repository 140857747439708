import axios from 'axios';
import { isResponseSuccess } from "utils/request";

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import { TOKEN_TYPE } from 'constants/auth.constants';

import { loginUser, logout } from 'utils/auth';
import sessionStorageUtils from 'utils/sessionStorage';

import { getEnvironment, isFunction } from 'utils/common';

import {
    AUTHENTICATE_ACTION_BEFORE,
    AUTHENTICATE_ACTION_FINISH,
    AUTHENTICATE_ACTION_SET_EXPIRE,
    AUTHENTICATE_ACTION_SET_QRBASE64,
    AUTHENTICATE_ACTION_SET_WS_TOKEN,
    AUTHENTICATE_ACTION_SET_LOCK_DATA,
    SET_LAST_LOGINED_USERNAME
} from '../../actionTypes';
import {AGENTS} from "constants/pageName.constants";

const setAuthenticateActionBefore = () => ({
    type: AUTHENTICATE_ACTION_BEFORE
})

const setAuthenticateActionFinished = () => ({
    type: AUTHENTICATE_ACTION_FINISH
})

const setAuthenticateActionSetExpire = expires => ({
    type: AUTHENTICATE_ACTION_SET_EXPIRE,
    payload: { expires }
})

export const setAuthenticateActionWSToken = wsToken => ({
    type: AUTHENTICATE_ACTION_SET_WS_TOKEN,
    payload: { wsToken }
})

const setQRBase64 = base64 => ({
    type: AUTHENTICATE_ACTION_SET_QRBASE64,
    payload: { base64 }
})

export const setTokenExpiration = expires => {
    return dispatch => {
        dispatch(setAuthenticateActionSetExpire(expires));
    }
}

export const setAccountLocked = lockData => ({
    type: AUTHENTICATE_ACTION_SET_LOCK_DATA,
    payload: { lockData }
})

const setLastLoginedUserName = (userName) => ({
    type: SET_LAST_LOGINED_USERNAME,
    payload: { userName }
})

export const authenticate = (userName, password, token, cb) => {
    return dispatch => {
        dispatch(setAuthenticateActionBefore());

        dispatch(setLastLoginedUserName(userName));

        return axios({
            url: ApiUrls.AUTHENTICATE,
            method: Methods.POST,
            data: { userName, password, token },
            headers: {
                'et': getEnvironment()
            }
        })
        .then(({ data }) => {
            dispatch(setAuthenticateActionFinished());
            if(isResponseSuccess(data)) {
                const user = { ... ( data?.value ?? {}), userName: userName};
                cb && cb(user);
                dispatch(setAccountLocked(null));
            } else {
                console.log(data)
            }
        })
        .catch(() => {
            dispatch(setAuthenticateActionFinished());
        })
    }
}

export const getTwoFactorQR = token => {
    return dispatch => {
        return axios({
            url: ApiUrls.AUTHENTICATE_QR,
            method: Methods.GET,
            headers: {
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(({data}) => {
            dispatch(setQRBase64(data.value));
        })
        .catch(() => {})
    }
}

export const verifyQRCode = ( token, code, onVerifyFailCB ) => {
    return dispatch => {
        dispatch(setAuthenticateActionBefore());
        return axios({
            url: ApiUrls.AUTHENTICATE_TOKEN,
            method: Methods.POST,
            headers: {
                'Authorization' : 'Bearer ' + token,
                'et': getEnvironment()
            },
            data: {token : code}
        })
        .then(({data}) => {
            dispatch(setAuthenticateActionFinished());
            if(isResponseSuccess(data)) {
                const value = data?.value ?? {};
                if(( value.tokenType ?? "" ) === TOKEN_TYPE.NONE){
                    loginUser( value );

                    sessionStorageUtils.set("selectedCompanyId", value.companyId ?? null);
                    sessionStorageUtils.set("selectedProjectId", value.projectId ?? null);
                    sessionStorageUtils.set("selectedProjectType", value.projectType ?? null);
                    sessionStorageUtils.set("selectedProjectMode", value.projectMode ?? null);
                    sessionStorageUtils.set("selectedPaymentType", value.paymentType ?? 0);

                    setTimeout(() => {
                        location.reload();
                    }, 0)
                }
            }
        })
        .catch(() => {
            if (isFunction(onVerifyFailCB)) {
                onVerifyFailCB();
            }

            dispatch(setAuthenticateActionFinished());
        })
    }
}

export const switchAccount = id => {
    return dispatch => {
        dispatch(setAuthenticateActionBefore());
        return axios({
            url: ApiUrls.SWITCH_ACCOUNT,
            method: Methods.POST,
            data: { id },
            headers: {
                'et': getEnvironment()
            }
        })
        .then(({ data }) => {
            if(isResponseSuccess(data)) {
                dispatch(setAccountLocked(null));
                const user = { ... ( data?.value ?? {})};
                sessionStorageUtils.remove("userInfo");
                sessionStorageUtils.remove("unreadNotificationsCount");
                sessionStorageUtils.remove(AGENTS);
                loginUser( user );
                setTimeout(() => {
                    location.reload();
                }, 0)
            } else {
                dispatch(setAuthenticateActionFinished());
                console.log(data)
            }
        })
        .catch(() => {
            dispatch(setAuthenticateActionFinished());
        })
    }
}

export const switchBackAccount = () => {
    return dispatch => {
        dispatch(setAuthenticateActionBefore());
        return axios({
            url: ApiUrls.SWITCH_BACK_ACCOUNT,
            method: Methods.POST,
            data: { },
            headers: {
                'et': getEnvironment()
            }
        })
        .then(({ data }) => {
            if(isResponseSuccess(data)) {
                dispatch(setAccountLocked(null));
                const user = { ... ( data?.value ?? {})};
                sessionStorageUtils.remove("userInfo");
                sessionStorageUtils.remove("unreadNotificationsCount");
                sessionStorageUtils.remove(AGENTS);
                loginUser(user);
                setTimeout(() => {
                    location.reload();
                }, 0)
            } else {
                dispatch(setAuthenticateActionFinished());
                logout();
            }
        })
        .catch(() => {
            dispatch(setAuthenticateActionFinished());
        })
    }
}
