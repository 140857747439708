import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { SET_PROJECT_LIMIT_SETTINGS } from "../../../actionTypes";

const setProjectLimitSettings = limitSettings => ({
    type: SET_PROJECT_LIMIT_SETTINGS,
    payload: { limitSettings }
})

export const getProjectLimitSettings = (id, currencyCode ) => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_LIMITS,
            method: Methods.GET,
            params: { id, currencyCode }
        })
        .then(({data : {value: limitSettings}}) => {
            dispatch(setProjectLimitSettings(limitSettings))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectLimitSettings = data => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_LIMITS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectLimitSettings(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
