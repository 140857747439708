import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const getPlayersProductReport = (filters, sorting) => {
    return axios({
        url: `${ApiUrls.GET_PLAYERS_PRODUCT_REPORT}`,
        method: Methods.GET,
        params: {
            ...filters,
            ...sorting
        },
    }).then(({data: {value}}) => {
        return {
            total: value.item1,
            data: value.item2
        }
    });
}

export const getPlayersProductReportTotals = (filters) => {
    return axios({
        url: `${ApiUrls.GET_PLAYERS_PRODUCT_REPORT_TOTALS}`,
        method: Methods.GET,
        params: {
            ...filters,
        },
    }).then(({data: {value}}) => {
        return value
    });
}
