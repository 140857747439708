import PATHS from "NEW/src/core/api/paths";
import { AUTHORIZATION, GRANT_TYPE } from "NEW/src/core/data/constants/api";
import { ENVIRONMENT_TYPE_PARAM_NAME } from "NEW/src/core/data/constants/environment";
import { httpService } from "NEW/src/core/services/httpService";
import {getEnvironment} from "utils/common";

export const accountService = {
	login(data, signal) {
		return httpService.post({
			path: PATHS.AUTHENTICATE,
			signal,
			requestBody: data,
			configs: {
				headers: {
					[ENVIRONMENT_TYPE_PARAM_NAME]: getEnvironment(),
				},
			},
		});
	},

	refreshToken(refreshToken, signal) {
		return httpService.post({
			path: PATHS.REFRESH_TOKEN,
			signal,
			requestBody: {
				refreshToken,
			},
			configs: {
				headers: {
					[GRANT_TYPE]: "refresh_token",
					[ENVIRONMENT_TYPE_PARAM_NAME]: getEnvironment(),
				},
			},
		});
	},

	authenticateQR(token, signal) {
		return httpService.get({
			path: PATHS.AUTHENTICATE_QR,
			signal,
			configs: {
				headers: {
					[AUTHORIZATION]: "Bearer " + token,
					[ENVIRONMENT_TYPE_PARAM_NAME]: getEnvironment(),
				},
			},
		});
	},

	authenticateToken(token, signal) {
		return httpService.post({
			path: PATHS.AUTHENTICATE_TOKEN,
			signal,
			configs: {
				headers: {
					[ENVIRONMENT_TYPE_PARAM_NAME]: getEnvironment(),
				},
			},
			requestBody: { token },
		});
	},

	getUserInfo(signal) {
		return httpService.get({
			path: PATHS.GET_USER_INFO,
			signal,
		});
	},

	changeProject(data, signal) {
		return httpService.post({
			path: PATHS.CHANGE_ACCOUNT_PROJECT,
			signal,
			requestBody: data,
		});
	},

	getAccountPasswordSettings(token) {
		return httpService.get({
			path: PATHS.GET_ACCOUNT_PASSWORD_SETTINGS,
			configs: {
				headers: {
					[AUTHORIZATION]: "Bearer " + token,
				},
			},
		});
	},

	resetPassword(token, data) {
		return httpService.post({
			path: PATHS.RESET_PASSWORD,
			configs: {
				headers: {
					[AUTHORIZATION]: "Bearer " + token,
				},
			},
			requestBody: data,
		});
	},

	setPassword(token, data) {
		return httpService.post({
			path: PATHS.SET_PASSWORD,
			configs: {
				headers: {
					[AUTHORIZATION]: "Bearer " + token,
				},
			},
			requestBody: data,
		});
	},

	changeNotificationsSettings(data) {
		return httpService.post({
			path: PATHS.CHANGE_NOTIFICATION_SETTINGS,
			requestBody: data,
		});
	},

	switch(id) {
		return httpService.post({
			path: PATHS.SWITCH_ACCOUNT,
			requestBody: {
				id,
			},
		});
	},

	switchBack() {
		return httpService.post({
			path: PATHS.SWITCH_BACK_ACCOUNT,
		});
	},

	getAgentBalance() {
		return httpService.get({
			path: PATHS.GET_AGENT_BALANCE,
		});
	},
};
