import {COMPARISON_ERROR_MESSAGES, COMPARISON_TYPE} from "constants/validator.constants";

const methods = {
    [COMPARISON_TYPE.GREATER_THEN]: (a, b) => a > b,
    [COMPARISON_TYPE.LESS_THEN]: (a, b) => a < b,
    [COMPARISON_TYPE.EQUAL]: (a, b) => a === b,
    [COMPARISON_TYPE.NOT_EQUAL]: (a, b) => a !== b,
    [COMPARISON_TYPE.GREATER_OR_EQUAL]: (a, b) => a >= b,
    [COMPARISON_TYPE.LESS_OR_EQUAL]: (a, b) => a <= b,
}

export const compareNumbers = (value, compareValue, method) => {
    const compareFn = methods[method];

    if (!compareFn) {
        throw new Error(`Invalid comparison method: ${method}`);
    }

    const result = compareFn(value, compareValue)
    const error = result ? '' : COMPARISON_ERROR_MESSAGES[method] || '';

    return {
        result,
        error,
    };
}
