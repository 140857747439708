import React from 'react';
import PropTypes from 'prop-types';

import { useLocation } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import RegistrationFormComponent from './form';

import {binaryToFlags, getHashValue, isFranchisingMode} from 'utils/common';

import { PROJECT_TYPE, REGISTRATION_FORM_TYPE } from "constants/project.constants";

/** Project Edit Page Registration Form Tab Component */
const RegistratinFormComponent = ({
    onTabChange,
    tabId
}) => {
    const { t } = useTranslation();

    const { search } = useLocation();

    const entityType = (new URLSearchParams(search)).get("type");

    const isActive = tabId === getHashValue('tab');

    const items = [
        {
            title: isFranchisingMode() ? t("backoffice.projects.betshopOwner") : t("backoffice.projects.agent"),
            component: <RegistrationFormComponent registrationFormType={REGISTRATION_FORM_TYPE.AGENT} />,
            disabled: !binaryToFlags(Object.values(PROJECT_TYPE), entityType).includes(PROJECT_TYPE.AGENT_SYSTEM)
        },
        {
            title: t("backoffice.projects.player"),
            component: <RegistrationFormComponent registrationFormType={REGISTRATION_FORM_TYPE.PLAYER} />
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
        />
    )
}

/** RegistratinFormComponent propTypes
    * PropTypes
*/
RegistratinFormComponent.propTypes = {
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Tab identifier*/
    tabId: PropTypes.string
}

export default RegistratinFormComponent;
