import { DEFAULT_LOCALE } from "NEW/src/core/data/constants/language/defaultLocale";
import { getLocale } from "NEW/src/core/helpers/language/getLocale";
import { isString } from "NEW/src/core/helpers/typeChecks/isString";
import {authStore, isAuthenticatedSelector} from "NEW/src/core/stores/authStore";

class Translations {
	constructor(locale) {
		this.locale = locale || DEFAULT_LOCALE;
		this._handlers = [];
		this._translations = {};
	}

	init() {
		return this.fetchLocale(this.locale).then(() => this.triggerHandlers());
	}

	async changeLocale(locale) {
		if (!this._translations[locale]) {
			await this.fetchLocale(locale);
		}

		this.locale = locale;
		this.triggerHandlers();

		return this.locale;
	}

	async fetchLocale(locale) {
		let translations = {};

		try {
			const response = await fetch(
				`${import.meta.env.SYSTEM_CDN_URL}/system/translations/backoffice_${locale}.json`,
			);

			translations = await response.json();
		} catch (e) {
			console.error(`Failed to fetch translations for locale "${locale}":`, e);

			translations = {};
		}

		this._translations[locale] = translations;
	}

	triggerHandlers() {
		this._handlers.forEach((handler) => handler(this.locale));
	}

	subscribe(handler) {
		this._handlers.push(handler);
		// TODO: REMOVEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE
		handler(this.locale);
	}

	unsubscribe(handler) {
		this._handlers = this._handlers.filter((h) => h !== handler);
	}

	translate(key) {
		const segments = key.split(".");

		let translation = key;
		let current = this._translations[this.locale];

		if (!current) {
			return translation;
		}

		for (let segment of segments) {
			if (current[segment]) {
				current = current[segment];
			} else {
				return translation;
			}
		}

		if (isString(current)) {
			translation = current;
		}

		return translation;
	}
}

const localeFromStorage = getLocale();

export const translations = new Translations(localeFromStorage, "backoffice");
