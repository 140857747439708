import { message } from 'antd'

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { isResponseSuccess } from "utils/request";
import {
    SET_DB_CONNECTIONS_ACTION_BEFORE,
    SET_DB_CONNECTIONS_ACTION_FINISH,
    SET_DB_CONNECTIONS
} from "../../../../actionTypes";

const setDBConnectionsActionBefore = () => ({
    type: SET_DB_CONNECTIONS_ACTION_BEFORE,
});

const setDBConnectionsActionFinished = () => ({
    type: SET_DB_CONNECTIONS_ACTION_FINISH,
});

const setDBConnections = connections => ({
    type: SET_DB_CONNECTIONS,
    payload: { connections },
});

export const getDBConnections = () => {
    return dispatch => {

        dispatch(setDBConnectionsActionBefore());

        return axios({
            url: ApiUrls.GET_DB_CONNECTIONS,
            method: Methods.GET,
            params: {},
        })
            .then(({ data: { value: connections } }) => {
                dispatch(setDBConnections(connections));
                dispatch(setDBConnectionsActionFinished());
            })
            .catch((ex) => {
                dispatch(setDBConnectionsActionFinished());
            });
    }
}

export const killConnection = id => {
    return dispatch => {

        dispatch(setDBConnectionsActionBefore());

        return axios({
            url: ApiUrls.KILL_DB_CONNECTIONS,
            method: Methods.POST,
            data: {
                id
            },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getDBConnections());
                } else {
                    dispatch(setDBConnectionsActionFinished());
                }
            })
            .catch((ex) => {
                dispatch(setDBConnectionsActionFinished());
            });
    }
}
