import { SESSION_EXPIRATION, SESSION_REFRESH_MODAL_DELAY } from "NEW/src/core/data/constants/common";

import { Observable } from "NEW/src/core/lib/observable";

export class SessionManager {
	static _instance = null;

	static get instance() {
		return SessionManager._instance || new SessionManager();
	}

	constructor() {
		if (SessionManager._instance) {
			return SessionManager._instance;
		}

		SessionManager._instance = this;

		this._warningTimer = null;
		this._endTimer = null;

		this._triggerWarningAfter = (SESSION_EXPIRATION - SESSION_REFRESH_MODAL_DELAY) * 1000;

		this._isStarted = false;

		this.sessionEnd = new Observable();
		this.sessionWarning = new Observable();
	}

	startSession() {
		this._isStarted = true;
		this._startSessionWarningCountdown();
	}

	refresh() {
		this._clear();
		if (this._isStarted) {
			this._startSessionWarningCountdown();
		}
	}

	stopSession() {
		this._isStarted = false;
		this._clear();
	}

	_clear() {
		clearTimeout(this._endTimer);
		clearTimeout(this._warningTimer);
	}

	_startSessionWarningCountdown() {
		clearTimeout(this._warningTimer);
		this._warningTimer = setTimeout(() => {
			this.sessionWarning.notify();
			this._startSessionEndCountdown();
		}, this._triggerWarningAfter);
	}

	_startSessionEndCountdown() {
		this._endTimer = setTimeout(() => {
			this.sessionEnd.notify();
		}, SESSION_REFRESH_MODAL_DELAY * 1000);
	}
}
