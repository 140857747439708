import React from 'react';

import {connect} from "react-redux";

import useFormat from "hooks/useFormat";

const TitleWithCurrencySymbol = ({ title, currency }) => {
    const { formatCurrencyWithSymbol } = useFormat();

    return formatCurrencyWithSymbol(title, currency);
};

const mapStateToProps = state => ({
    currency: state.profile.currency,
});

export default connect(mapStateToProps)(TitleWithCurrencySymbol);
