import React, {memo, useMemo} from 'react';
import {Checkbox, Form} from "antd";
import Tooltip from "components/common/tooltip";
import SecurityLevel from "components/common/permissions/components/securityLevel";
import {isFieldReadonly} from "components/common/permissions/helpers/isFieldReadonly";
import {makeDescription} from "components/common/permissions/helpers/makeDescription";
import {useTranslation} from "react-i18next";
import {PERMISSION_ACTION} from "constants/permissions.constants";
import {getPermissionDependency} from "components/common/permissions/helpers/getResourceDependencies";

const withWrapper = (WrappedComponent) => ({formInstance, permissions, ...props}) => {
    const { getFieldValue, getFieldsValue } = formInstance;

    const dependency = useMemo(() => (
        getPermissionDependency(permissions, props.permission, props.parent, props.action)
    ), [permissions, props.permission, props.parent, props.action]);

    Form.useWatch(props.fieldName ? [props.fieldName, ...dependency] : [...dependency], formInstance);

    const actionData = props.permission.actions.find(a => a.action === props.action);

    if (!actionData) return null;

    const fieldDisabled = props.editable ? (
        !actionData.canEdit ||
        isFieldReadonly({
            resource: props.permission.resource,
            parent: !props.permission.isParent ? props.parent?.resource ?? null : null,
            action: props.action,
            disabled: props.disabled,
            fieldName: props.fieldName,
            getFieldsValue,
            getFieldValue
        })
    ) : false;

    return (
        <WrappedComponent
            {...props}
            fieldDisabled={fieldDisabled}
            actionData={actionData}
        />
    )
}

const Cell = ({
    permission,
    action,
    parent,
    editable,
    fieldName,
    onChange,
    fieldDisabled,
    actionData
}) => {
    const { t } = useTranslation();

    const name = permission.isParent ? (fieldName ? [fieldName] : []).concat([permission.resource, "parent", action]) : (fieldName ? [fieldName] : []).concat([parent?.resource ?? null, permission.resource, action]);

    return (
        <div className='rt--flex rt--align-center'>
            <div className="rt--permissions-table-content rt--flex rt--align-center">
                {
                    (actionData && editable) && (
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mr-8">
                            <Form.Item
                                name={name}
                                valuePropName="checked"
                                className="rt--form-item-without-margin rt--permissions-table-checkbox"
                            >
                                <Checkbox
                                    onChange={e => onChange(permission.resource, !permission.isParent ? parent?.resource ?? null : null, action, e.target.checked)}
                                    disabled={fieldDisabled}
                                />
                            </Form.Item>
                        </div>
                    )
                }

                <Tooltip
                    title={actionData ? makeDescription(permission.resource, action, t) : ""}
                    enableMobile={true}
                >
                    <span className="rt--permissions-table-description">{actionData ? makeDescription(permission.resource, action, t) : ""}</span>
                </Tooltip>
            </div>

            <SecurityLevel permission={actionData} />

        </div>
    );
};

export default withWrapper(memo(Cell));
