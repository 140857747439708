export const timezones = [
	{
		id: "8023cfd535524c1a851ca66108cfa80a",
		description: "(UTC-11:00) Coordinated Universal Time-11",
		timeZone: -11,
	},
	{
		id: "db9b6cd6517f4723b3b214c75800cb33",
		description: "(UTC-10:00) Aleutian Islands",
		timeZone: -10,
	},
	{ id: "005f4557a987475b88f1fd3d7b940eb9", description: "(UTC-10:00) Hawaii", timeZone: -10 },
	{
		id: "c6072315262c4e4eab04ba26152b87a8",
		description: "(UTC-09:30) Marquesas Islands",
		timeZone: -9.5,
	},
	{ id: "21e44a5922bc4ed38164afb85cd5e933", description: "(UTC-09:00) Alaska", timeZone: -9 },
	{
		id: "7d70f0bede224412b179624b8d3f5376",
		description: "(UTC-09:00) Coordinated Universal Time-09",
		timeZone: -9,
	},
	{
		id: "0e0bf477a7114f11b7e2c3ed959f7c87",
		description: "(UTC-08:00) Baja California",
		timeZone: -8,
	},
	{
		id: "147f82392a6f4e7bad276f70e0ca63fc",
		description: "(UTC-08:00) Coordinated Universal Time-08",
		timeZone: -8,
	},
	{
		id: "3dd47b0f5ad94512af6e5655d73e01ac",
		description: "(UTC-08:00) Pacific Time (US & Canada)",
		timeZone: -8,
	},
	{ id: "32bfaac854294b50a6e2a810114961ac", description: "(UTC-07:00) Arizona", timeZone: -7 },
	{
		id: "4197717458344a14a9f178d226d950f3",
		description: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
		timeZone: -7,
	},
	{
		id: "addffb3441f6430dbc46536b8958f232",
		description: "(UTC-07:00) Mountain Time (US & Canada)",
		timeZone: -7,
	},
	{
		id: "0ed09e4bddd9434aa66829b261fba2f9",
		description: "(UTC-06:00) Central America",
		timeZone: -6,
	},
	{
		id: "b96918475d404cdba0003d42852c5dd3",
		description: "(UTC-06:00) Central Time (US & Canada)",
		timeZone: -6,
	},
	{
		id: "8b53063a1ca44adf91ca7ab412ddf625",
		description: "(UTC-06:00) Easter Island",
		timeZone: -6,
	},
	{
		id: "ee64b05d828a4f638c55a401be419847",
		description: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
		timeZone: -6,
	},
	{
		id: "e053a2676cab4e9895d67fdcff8604d6",
		description: "(UTC-06:00) Saskatchewan",
		timeZone: -6,
	},
	{
		id: "27dceadf880444429822892f53ff5c49",
		description: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
		timeZone: -5,
	},
	{ id: "cf9f1d67721a4eb897a06e7f4b345328", description: "(UTC-05:00) Chetumal", timeZone: -5 },
	{
		id: "86ef93a93886464fbe5a0235b4fbb087",
		description: "(UTC-05:00) Eastern Time (US & Canada)",
		timeZone: -5,
	},
	{ id: "a79ac54533da4647a6a277cdf25335e4", description: "(UTC-05:00) Haiti", timeZone: -5 },
	{ id: "bb6b3efcb8b4410497fe38f92862cdf3", description: "(UTC-05:00) Havana", timeZone: -5 },
	{
		id: "7d00a79401ec4f70b648ba2a13c01d8a",
		description: "(UTC-05:00) Indiana (East)",
		timeZone: -5,
	},
	{
		id: "13f02a4341fc4099b59cf8a05a135d19",
		description: "(UTC-05:00) Turks and Caicos",
		timeZone: -5,
	},
	{ id: "9e5e1004342c41cc8d8c8d2405d62ce5", description: "(UTC-04:00) Asuncion", timeZone: -4 },
	{
		id: "081a49016fef46fb920bae41659d94d8",
		description: "(UTC-04:00) Atlantic Time (Canada)",
		timeZone: -4,
	},
	{ id: "1704789726dd4f0bb6252d7b81db2475", description: "(UTC-04:00) Caracas", timeZone: -4 },
	{ id: "bd2de6e0e8fa438aa82343f967884d66", description: "(UTC-04:00) Cuiaba", timeZone: -4 },
	{
		id: "4e224092ecd3411ebbecaa3868df05de",
		description: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
		timeZone: -4,
	},
	{ id: "3966fb6df5f44adcbaa64eeafef54617", description: "(UTC-04:00) Santiago", timeZone: -4 },
	{
		id: "918d86fcbe5e49f1bb000b7b9a6c0a86",
		description: "(UTC-03:30) Newfoundland",
		timeZone: -3.5,
	},
	{ id: "c5ac3d345af04cc5a7f0c054e0f0c9e7", description: "(UTC-03:00) Araguaina", timeZone: -3 },
	{ id: "89203e7f4bd742aeb28121ba1aea996e", description: "(UTC-03:00) Brasilia", timeZone: -3 },
	{
		id: "344c18d80cff47acb6d8e2435ea6d0df",
		description: "(UTC-03:00) Cayenne, Fortaleza",
		timeZone: -3,
	},
	{
		id: "dc347bf956eb45a686d8cc41e71db179",
		description: "(UTC-03:00) City of Buenos Aires",
		timeZone: -3,
	},
	{ id: "fa261ffc06b64bdeb4d1ebcf5c6c3a65", description: "(UTC-03:00) Greenland", timeZone: -3 },
	{ id: "5e14cc17900d4b2d9d8a6a419293e696", description: "(UTC-03:00) Montevideo", timeZone: -3 },
	{
		id: "3cfed8d3dfbd466893502cf88bdda91f",
		description: "(UTC-03:00) Punta Arenas",
		timeZone: -3,
	},
	{
		id: "55fe61a0518e4bb3ba272af1f678fb6f",
		description: "(UTC-03:00) Saint Pierre and Miquelon",
		timeZone: -3,
	},
	{ id: "e0a31998b8e540b98ec57abdb6290465", description: "(UTC-03:00) Salvador", timeZone: -3 },
	{
		id: "c9e06aa0509447bc96113f349f04af2e",
		description: "(UTC-02:00) Coordinated Universal Time-02",
		timeZone: -2,
	},
	{
		id: "e8bce93e71c94a5fbcc8d1197f7f9456",
		description: "(UTC-02:00) Mid-Atlantic - Old",
		timeZone: -2,
	},
	{ id: "4b133417453d43c9adeb38b857f36193", description: "(UTC-01:00) Azores", timeZone: -1 },
	{
		id: "9bd4441b28b54029aac39db11858379d",
		description: "(UTC-01:00) Cabo Verde Is.",
		timeZone: -1,
	},
	{
		id: "d2aa5722d3384d1792041f01dcabbec9",
		description: "(UTC) Coordinated Universal Time",
		timeZone: 0,
	},
	{
		id: "9176f87ae3f8439cb9e56b875de3c259",
		description: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
		timeZone: 0,
	},
	{
		id: "4bb58827be794fb386c50513334a0a00",
		description: "(UTC+00:00) Monrovia, Reykjavik",
		timeZone: 0,
	},
	{ id: "a385e6d417874cc38e94b50485a0b159", description: "(UTC+00:00) Sao Tome", timeZone: 0 },
	{ id: "b742d74bc45f4358abb5f5e84fb04182", description: "(UTC+01:00) Casablanca", timeZone: 1 },
	{
		id: "ac16ac8f22274cbda9bb906d3c2f53b1",
		description: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
		timeZone: 1,
	},
	{
		id: "81d2455cfd3d47299a0fc23753c80e29",
		description: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
		timeZone: 1,
	},
	{
		id: "a6d3e3ee680e434a966ed58ebcc35447",
		description: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
		timeZone: 1,
	},
	{
		id: "8ef6871e751e404aac6511d8b1b097f3",
		description: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
		timeZone: 1,
	},
	{
		id: "680a87a387374d47b07039e0c04d6a21",
		description: "(UTC+01:00) West Central Africa",
		timeZone: 1,
	},
	{ id: "7cfe04f51ace4b148c7a4c60e8571b74", description: "(UTC+02:00) Amman", timeZone: 2 },
	{
		id: "b1f47aa6acf34630bf2606292e45222e",
		description: "(UTC+02:00) Athens, Bucharest",
		timeZone: 2,
	},
	{ id: "4d9416e8fa674ffabac36dd4db7d4692", description: "(UTC+02:00) Beirut", timeZone: 2 },
	{ id: "53a23b4f80064b83beb684d0fb56ebc0", description: "(UTC+02:00) Cairo", timeZone: 2 },
	{ id: "1d8d9f07f0aa4d9c8f95f3caffe3adb6", description: "(UTC+02:00) Chisinau", timeZone: 2 },
	{ id: "592656199c7440de87311c9b70f50a9d", description: "(UTC+02:00) Damascus", timeZone: 2 },
	{
		id: "c407079abf954958adfdda19b3f7890f",
		description: "(UTC+02:00) Gaza, Hebron",
		timeZone: 2,
	},
	{
		id: "05308acf7df548b1a597d59101207e53",
		description: "(UTC+02:00) Harare, Pretoria",
		timeZone: 2,
	},
	{
		id: "34fb88904b6748719c30cc52183d7565",
		description: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
		timeZone: 2,
	},
	{ id: "ef07565a0ac44a3cb77daf33d4037c35", description: "(UTC+02:00) Jerusalem", timeZone: 2 },
	{ id: "25418985d87a4c4286969d836ff95b71", description: "(UTC+02:00) Kaliningrad", timeZone: 2 },
	{ id: "85cbe03cf761402c947484b4e66e94e9", description: "(UTC+02:00) Khartoum", timeZone: 2 },
	{ id: "1a401cdf16564ffbbdb3d7234917a86a", description: "(UTC+02:00) Tripoli", timeZone: 2 },
	{ id: "7121328d3fdd43199db55b5a8d035483", description: "(UTC+02:00) Windhoek", timeZone: 2 },
	{ id: "6d34391650bd48f0bc67c4fd6cd6bfa7", description: "(UTC+03:00) Baghdad", timeZone: 3 },
	{ id: "6e40731658354ed189e1ea5825cd31ca", description: "(UTC+03:00) Istanbul", timeZone: 3 },
	{
		id: "3d430807073f46d88d86e0a84d7dd380",
		description: "(UTC+03:00) Kuwait, Riyadh",
		timeZone: 3,
	},
	{ id: "305db24703ea4f4482c44bdc7df30c60", description: "(UTC+03:00) Minsk", timeZone: 3 },
	{
		id: "917de654ed5f4934b5cf0b5b810ed17f",
		description: "(UTC+03:00) Moscow, St. Petersburg",
		timeZone: 3,
	},
	{ id: "00803276d23742d5acac67282c0d4d74", description: "(UTC+03:00) Nairobi", timeZone: 3 },
	{ id: "4dcb3b96fc2f41d48ab44ed1bd54c61d", description: "(UTC+03:30) Tehran", timeZone: 3.5 },
	{
		id: "dd2bc89128c74fb28b8ecdcdd91962b8",
		description: "(UTC+04:00) Abu Dhabi, Muscat",
		timeZone: 4,
	},
	{
		id: "1f76869cac8b4383acdca43f43e1777f",
		description: "(UTC+04:00) Astrakhan, Ulyanovsk",
		timeZone: 4,
	},
	{ id: "06d604650c0842bcabb7c70ec1ca9cba", description: "(UTC+04:00) Baku", timeZone: 4 },
	{
		id: "b1d538647fda47c586513d04e55c324f",
		description: "(UTC+04:00) Izhevsk, Samara",
		timeZone: 4,
	},
	{ id: "21dc4f5cd4ce4359bc32a386cfce7bf6", description: "(UTC+04:00) Port Louis", timeZone: 4 },
	{ id: "305aebbbbccb41ed9cc3e22118e1670d", description: "(UTC+04:00) Saratov", timeZone: 4 },
	{ id: "02241927ba804ea0b5c193ee0acfb990", description: "(UTC+04:00) Tbilisi", timeZone: 4 },
	{ id: "0f8c8b4443754b88b21b68f7be49dbdd", description: "(UTC+04:00) Volgograd", timeZone: 4 },
	{ id: "13fde871afa14266824eb1bf48f1f251", description: "(UTC+04:00) Yerevan", timeZone: 4 },
	{ id: "6b647c1d4bf54bd69daa4fe3df45e409", description: "(UTC+04:30) Kabul", timeZone: 4.5 },
	{
		id: "b9924861dba74bbfb094aa37bc253082",
		description: "(UTC+05:00) Ashgabat, Tashkent",
		timeZone: 5,
	},
	{
		id: "bf18883c3867426a80834235751ef460",
		description: "(UTC+05:00) Ekaterinburg",
		timeZone: 5,
	},
	{
		id: "256b11b6b1fd4a08aa0c526f3dbdd22d",
		description: "(UTC+05:00) Islamabad, Karachi",
		timeZone: 5,
	},
	{ id: "d8ab086555c446f5b56665bc777a4320", description: "(UTC+05:00) Qyzylorda", timeZone: 5 },
	{
		id: "556e17ece7e44d92834083d5f57d46cb",
		description: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
		timeZone: 5.5,
	},
	{
		id: "3ee0a6e9971c4311a5a3877c748e015f",
		description: "(UTC+05:30) Sri Jayawardenepura",
		timeZone: 5.5,
	},
	{
		id: "bc9ac4d88759483ab07a5838ceaf3410",
		description: "(UTC+05:45) Kathmandu",
		timeZone: 5.75,
	},
	{ id: "a6ec10da622c40dd9ab220b7dd36bae1", description: "(UTC+06:00) Astana", timeZone: 6 },
	{ id: "5b6c4a509839449da3e14ffe904d6fd8", description: "(UTC+06:00) Dhaka", timeZone: 6 },
	{ id: "657aa7bf40ca48fb82ad3df50b795ca5", description: "(UTC+06:00) Omsk", timeZone: 6 },
	{
		id: "552ce02b706b4a48bb5fe445d88e5a40",
		description: "(UTC+06:30) Yangon (Rangoon)",
		timeZone: 6.5,
	},
	{
		id: "7e3902daf1a043f2a37aeb445cbc7bfb",
		description: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
		timeZone: 7,
	},
	{
		id: "4bfb28429e004187b04f416f6868be05",
		description: "(UTC+07:00) Barnaul, Gorno-Altaysk",
		timeZone: 7,
	},
	{ id: "be90852370ab4ddaa88ad2aad15ee861", description: "(UTC+07:00) Hovd", timeZone: 7 },
	{ id: "b27738c1aa5a44e18dc56216a619a0b6", description: "(UTC+07:00) Krasnoyarsk", timeZone: 7 },
	{ id: "e2c3653483c64e888b06bfcbe42a70ee", description: "(UTC+07:00) Novosibirsk", timeZone: 7 },
	{ id: "2f27f34bca6345a2888b978972a2633b", description: "(UTC+07:00) Tomsk", timeZone: 7 },
	{
		id: "b473f99a5be84042beb588e62765b0ef",
		description: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
		timeZone: 8,
	},
	{ id: "e44d01ba3bbd4b7b8a7560f466ba57ef", description: "(UTC+08:00) Irkutsk", timeZone: 8 },
	{
		id: "8821e267ca684078a44943b000cc2846",
		description: "(UTC+08:00) Kuala Lumpur, Singapore",
		timeZone: 8,
	},
	{ id: "e2357a496af14130b676e40f8c2ac9ff", description: "(UTC+08:00) Perth", timeZone: 8 },
	{ id: "43f0d70929684f30b747a6ae26f30c99", description: "(UTC+08:00) Taipei", timeZone: 8 },
	{ id: "152269ff7f1f4de6a516bb7b5b7e57bb", description: "(UTC+08:00) Ulaanbaatar", timeZone: 8 },
	{ id: "49debcbbf03c46469ac7c99ee847b92b", description: "(UTC+08:45) Eucla", timeZone: 8.75 },
	{ id: "e4a9be9ecd5c4656a2d2c23064c4f2dc", description: "(UTC+09:00) Chita", timeZone: 9 },
	{
		id: "821befd0db014303af999eb79890cfec",
		description: "(UTC+09:00) Osaka, Sapporo, Tokyo",
		timeZone: 9,
	},
	{ id: "d6f8e83f6cf44f48a85fd87d59842d1b", description: "(UTC+09:00) Pyongyang", timeZone: 9 },
	{ id: "5fb2d85fba6b499d81ce9868fa1dd773", description: "(UTC+09:00) Seoul", timeZone: 9 },
	{ id: "c6877518a258484886c755e0417e1c9c", description: "(UTC+09:00) Yakutsk", timeZone: 9 },
	{ id: "ab08e5f410f34dffb8c0aa7538ec031b", description: "(UTC+09:30) Adelaide", timeZone: 9.5 },
	{ id: "ddf189da39204d658f9cc9e64e190217", description: "(UTC+09:30) Darwin", timeZone: 9.5 },
	{ id: "f99d8c5686ab44b0a755d310ff86f548", description: "(UTC+10:00) Brisbane", timeZone: 10 },
	{
		id: "69783018aff747eaa640f942f9c25053",
		description: "(UTC+10:00) Canberra, Melbourne, Sydney",
		timeZone: 10,
	},
	{
		id: "5cc7637d48334628831b31f81b2a2a00",
		description: "(UTC+10:00) Guam, Port Moresby",
		timeZone: 10,
	},
	{ id: "807f3ae7b51846efbad7d78153de564e", description: "(UTC+10:00) Hobart", timeZone: 10 },
	{
		id: "fee2caef37514e488c4fb5811f5da26e",
		description: "(UTC+10:00) Vladivostok",
		timeZone: 10,
	},
	{
		id: "c13ccb7dec14469cbffdb809e3b8ef79",
		description: "(UTC+10:30) Lord Howe Island",
		timeZone: 10.5,
	},
	{
		id: "ef446fcc5f0045f9ab08fa63a4318311",
		description: "(UTC+11:00) Bougainville Island",
		timeZone: 11,
	},
	{ id: "726b8db9bc804b818e605fa969c08b93", description: "(UTC+11:00) Chokurdakh", timeZone: 11 },
	{ id: "396fccdb84124dca8b9430e59dd427e5", description: "(UTC+11:00) Magadan", timeZone: 11 },
	{
		id: "0b05ecb4366b49f9bf6c4d6e515c2eda",
		description: "(UTC+11:00) Norfolk Island",
		timeZone: 11,
	},
	{ id: "6b040a644fbb406ca29618a9421ebd64", description: "(UTC+11:00) Sakhalin", timeZone: 11 },
	{
		id: "c65bd5d6400b4766a66f9acb88cfdccd",
		description: "(UTC+11:00) Solomon Is., New Caledonia",
		timeZone: 11,
	},
	{
		id: "1d6f7db6d6294507975ff54f66c8f6f6",
		description: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
		timeZone: 12,
	},
	{
		id: "3b5ed0d37627495da832e20198e3d1d0",
		description: "(UTC+12:00) Auckland, Wellington",
		timeZone: 12,
	},
	{
		id: "b3189cb1c367468487d9f4c356e8e182",
		description: "(UTC+12:00) Coordinated Universal Time+12",
		timeZone: 12,
	},
	{ id: "aeb8ab0129c14d9db7a53a9a76f37dd0", description: "(UTC+12:00) Fiji", timeZone: 12 },
	{
		id: "698ceac136db46bbaf254515c304daa7",
		description: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
		timeZone: 12,
	},
	{
		id: "180632515abd439e9e072d6ff79526a3",
		description: "(UTC+12:45) Chatham Islands",
		timeZone: 12.75,
	},
	{
		id: "46a28fe593b34cf5b5a42aad1ce45153",
		description: "(UTC+13:00) Coordinated Universal Time+13",
		timeZone: 13,
	},
	{ id: "8a0953ad047141eab500a4cf4f7e3528", description: "(UTC+13:00) Nuku'alofa", timeZone: 13 },
	{ id: "3cf65efdf0614d9495b72617ce71d231", description: "(UTC+13:00) Samoa", timeZone: 13 },
	{
		id: "03e5b1c92ee44ba8b5aeb02b6c5ff88c",
		description: "(UTC+14:00) Kiritimati Island",
		timeZone: 14,
	},
];

export const DATE_FORMAT = {
	FORMAT_1: 1,
	FORMAT_2: 2,
	FORMAT_3: 3,
	FORMAT_4: 4,
	FORMAT_5: 5,
	FORMAT_6: 6,
};

export const DATE_PICKER_RANGES = {
	TODAY: "today",
	YESTERDAY: "yesterday",
	THIS_MONTH: "thisMonth",
	LAST_MONTH: "lastMonth",
	LAST_3_MONTH: "last3Month",
	YTD: "ytd",
};

export const TIME_FORMAT = {
	_24_HOUR: 1,
	_12_HOUR: 2,
};
