import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from "react-router-dom";

import EntityActions from "components/common/entityActions";


import {
    activateCommissionPlan,
    deactivateCommissionPlan,
    deleteCommissionPlan,
    removeCommissionPlan
} from "store/actions/dashboard/agentSystem/commissionPlans/commissionPlans.action";

import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { AGENT_TYPE } from "constants/agent.constants"
import userInfoType from 'types/profile/userInfo.type';
import commissionPlanSettingsType from "types/agent/commissionPlanSettings.type";

/** Commission Plan Actions Button Component */
const CommissionPlanActionsComponent = ({
    commissionPlanSettings,
    activateCommissionPlan,
    deactivateCommissionPlan,
    deleteCommissionPlan,
    removeCommissionPlan,
    agentType,
    userInfo
}) => {

    const { t } = useTranslation();

    const searchParams = useParams();
    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const commissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");
    const commissionPlanName = (new URLSearchParams(search)).get("commissionPlanName");

    /** Actions list */
    const actions = [

        /** Deactivate/Activate */
        {
            key: "block",
            buttonType: commissionPlanSettings.enabled ? "primary" : "secondary",
            buttonClassName: commissionPlanSettings.enabled ? "rt--button-danger" : "rt--button-secondary",
            title: !commissionPlanSettings.enabled ? t('backoffice.commissionplans.activate') : t('backoffice.commissionplans.deactivate'),
            onClick: () => {
                if (commissionPlanSettings.enabled) {
                    deactivateCommissionPlan(commissionPlanId, searchParams.id)
                } else {
                    activateCommissionPlan(commissionPlanId, searchParams.id)
                }
            },
            icon: "icon-block",
            isConfirmation: true,
            confirmationMessage: commissionPlanSettings.enabled ? t('backoffice.commissionplans.commissionPlanWillBeDeactivatedForSubTree') : t('backoffice.commissionplans.commissionPlanWillBeActivatedForSubTree'),
            disabled: (
                !hasPermission({ resource: agentType === AGENT_TYPE.AGENT ? PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN : PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.MODIFY }) || 
                (agentType === AGENT_TYPE.AGENT && searchParams.id === userInfo.id)
            )
        },
        /** Delete */
        {
            key: "delete",
            buttonType: "danger",
            buttonClassName: "rt--button-danger",
            title: agentType === AGENT_TYPE.AGENT ? t('backoffice.common.remove') : t('backoffice.common.delete'),
            icon: "icon-delete",
            onClick: () => {
                if (agentType === AGENT_TYPE.AGENT) {
                    removeCommissionPlan(commissionPlanId, searchParams.id, () => {
                        navigate((pathname + search + hash).replace("&commissionPlanId=" + commissionPlanId, "").replace("&commissionPlanName=" + commissionPlanName, ""), {replace: true})
                    })
                } else {
                    deleteCommissionPlan(commissionPlanId, searchParams.id, () => {
                        navigate((pathname + search + hash).replace("&commissionPlanId=" + commissionPlanId, "").replace("&commissionPlanName=" + commissionPlanName, ""), {replace: true})
                    })
                }
            },
            isConfirmation: true,
            confirmationMessage: agentType === AGENT_TYPE.AGENT ? t("backoffice.commissionplans.doYouWanttoRemoveCommissionPlan") : t("backoffice.commissionplans.doYouWanttoDeleteCommissionPlan"),
            disabled: (
                !hasPermission({ resource: agentType === AGENT_TYPE.AGENT ? PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN : PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.DELETE }) ||
                (agentType === AGENT_TYPE.AGENT && searchParams.id === userInfo.id) 
            )
        },
        
    ]

    return (
        <Fragment>
            <EntityActions 
                actions={actions}
            />
        </Fragment>
        
    )
}

/** CommissionPlanActionsComponent propTypes
    * PropTypes
*/
CommissionPlanActionsComponent.propTypes = {
    /** Redux state, represents the ranges settings of current editing commision plan  */
    commissionPlanSettings: commissionPlanSettingsType,
    /** Redux action to activate commision plan */
    activateCommissionPlan: PropTypes.func,
    /** Redux action to deactivate commision plan */
    deactivateCommissionPlan: PropTypes.func,
    /** Redux action to get delete commission plan */
    deleteCommissionPlan: PropTypes.func,
    /** Redux action to remove commission plan from agent */
    removeCommissionPlan: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Agent type */
    agentType: PropTypes.oneOf(Object.values(AGENT_TYPE))
}

const mapDispatchToProps = dispatch => (
    {
        activateCommissionPlan: (id, agentId) => {
            dispatch(activateCommissionPlan(id, agentId));
        },
        deactivateCommissionPlan: (id, agentId) => {
            dispatch(deactivateCommissionPlan(id, agentId));
        },
        deleteCommissionPlan: (id, agentId, onSuccess) => {
            dispatch(deleteCommissionPlan(id, agentId, onSuccess));
        },
        removeCommissionPlan: (id, agentId, onSuccess) => {
            dispatch(removeCommissionPlan(id, agentId, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        commissionPlanSettings: state.commissionPlans.edit.settings,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanActionsComponent)