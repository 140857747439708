import React, {useEffect, useState} from 'react';
import {Carousel} from "antd";
import PieChart from "pages/dashboard/components/pieChart";
import { Tabs as AntTabs } from 'antd';

const UsersTabMobile = ({ pieChartFilters, items, pieChartItems, updateLastUpdatedDate, onTabChange}) => {
    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);

    const handleCarouselChange = (prevIndex, nextIndex) => {
        setCarouselActiveIndex(nextIndex);
    }

    const handleTabChange = (key) => {
        onTabChange(key);
    }

    useEffect(() => {
        onTabChange(items[0].key)
    }, [])

    return (
        <div className="rt--dashboard-users">
            <div className='rt--dashboard-carousel rt--pb-28'>
                <Carousel infinite={false} dots beforeChange={handleCarouselChange}>
                    {pieChartItems.map((chart, index) => (
                        <PieChart
                            key={chart.id}
                            entityType={chart.entityType}
                            filters={pieChartFilters}
                            updateLastUpdatedDate={updateLastUpdatedDate}
                            isActive={carouselActiveIndex === index}
                        />
                    ))}
                </Carousel>
            </div>

            <div>
                <AntTabs
                    animated={false}
                    destroyInactiveTabPane={true}
                    className='rt--tabs rt--chart-tab'
                    items={items}
                    onChange={handleTabChange}
                />
            </div>
        </div>
    );
};

export default UsersTabMobile;
