import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUser } from 'utils/auth';
import { isFranchisingMode, isMobile } from "utils/common";
import { hasPermission } from 'utils/permissions';

import useProjectType from 'hooks/useProjectType';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';

import { USER_ROLE } from "constants/user.constants";
import { WALLET_TYPE } from "constants/wallet.constants";

import CurrenciesDropdownMobile from './mobile';
import CurrenciesDropdownDesktop from './desktop';

import currencyType from 'types/profile/currency.type';

/** Currencies Dropdown */
const CurrenciesDropdown = ({
    currencies
}) => {

    const { t } = useTranslation();

    const { hasLiteMode } = useProjectType();


    /** Function, to get avalable wallets for current user
       * @function
       * @returns {array} wallets
       * @memberOf CurrenciesDropdown
   */
   const getAvailableWallets = () => {
        let wallets = [];
        const userRole = getUser()?.role;

        /** Balance */
        if (
            (
                userRole === USER_ROLE.ADMIN && hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_WALLETS, action: PERMISSION_ACTION.VIEW })
            ) || !isFranchisingMode()
        ) {
            wallets.push({
                type: WALLET_TYPE.BALANCE,
                title: t("backoffice.common.balance")
            })
        }

        /** Credit */
        if (
            (
                userRole === USER_ROLE.AGENT || userRole === USER_ROLE.AGENT_PLAYER
            ) && !hasLiteMode
        ) {
            wallets.push({
                type: WALLET_TYPE.CREDIT,
                title: t("backoffice.common.credit")
            })
        }

        /** Credit Line */
        if (
            userRole === USER_ROLE.AGENT && !hasLiteMode
        ) {
            wallets.push({
                type: WALLET_TYPE.CREDIT_LINE,
                title: t("backoffice.common.creditLine")
            })
        }

        return wallets
    }

    /** Function, to make credit Line text
       * @function
       * @param {string} type - wallet type
       * @param {object} currency
       * @returns {string}
       * @memberOf CurrenciesDropdown
   */
    const makeWalletAmount = (type, currencyCode) => {
        let amount = 0;
        const currency = currencies.find(c => c.currencyCode === currencyCode?.toUpperCase());
        if(!currency){
            return amount;
        } 

        const wallet = currency.balances.find(b => b.type === type);
        if(!wallet){
            return amount;
        }
        
        return wallet.amount;
    }

    const sortCurrencies = curs => {
        const sortedCurrencies = [...curs];

        const availableWallets = getAvailableWallets();
        if(availableWallets.some(w => w.type === WALLET_TYPE.BALANCE)){
            sortedCurrencies.sort((c1, c2) => {
                const c1Balance = makeWalletAmount(WALLET_TYPE.BALANCE, c1.currencyCode);
                const c2Balance = makeWalletAmount(WALLET_TYPE.BALANCE, c2.currencyCode);
                return Number(c2Balance) - Number(c1Balance);
            })
        }

        return sortedCurrencies;
    }

    return (
        isMobile()
        ? <CurrenciesDropdownMobile 
            availableWallets={getAvailableWallets()}
            makeWalletAmount={makeWalletAmount}
            currencies={sortCurrencies(currencies)}
        />
        : <CurrenciesDropdownDesktop 
            availableWallets={getAvailableWallets()}
            makeWalletAmount={makeWalletAmount}
            currencies={sortCurrencies(currencies)}
        />
    );
}

/** CurrenciesDropdown propTypes
    * PropTypes
*/
CurrenciesDropdown.propTypes = {
    /** Redux state property, User currencies */
    currencies: PropTypes.arrayOf(currencyType)
}

const mapStateToProps = state => {
    return {
        currencies: state.profile.currencies
    }
}

export default connect(mapStateToProps, null)(CurrenciesDropdown)
