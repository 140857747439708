import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getPermissionRequestsCount } from 'store/actions/dashboard/userManagement/requests/requests.action';

import { flagsToBinary } from 'utils/common';
import { hasPermission } from 'utils/permissions';

import PATHS from 'constants/path.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';

import useFirstRender from 'hooks/useFirstRender';

/** Pending Requests Note Component */
const PendingRequestsNote = forwardRef(({
    objectId,
    actionTypes = [],
    objectType,
    dependencies = [],
    getPermissionRequestsCount,
    count
}, ref) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const flag = flagsToBinary(actionTypes);

    const requestsCount = count[flag] ?? 0;

    const hasPermissionRequestViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
        action: PERMISSION_ACTION.VIEW
    })

    useFirstRender({
        afterFirstRenderCB: () => {
            getPermissionRequestsCount(objectId, actionTypes, objectType);
        },
        dependencies: dependencies
    })

    useImperativeHandle(ref, () => ({
        getCount: () => requestsCount
    }))

    return requestsCount > 0 ? (
        <div
            className={
                'rt--alert-info ' + 
                (hasPermissionRequestViewPermission ? " rt--cursor-pointer": "") + 
                (flag === PERMISSION_REQUEST_TYPE.GROUP_MODIFY ? " rt--mb-16": "")
            }
            onClick={() => {
                if (hasPermissionRequestViewPermission) {
                    navigate(`${PATHS.PERMISSION_REQUESTS}?objectId=${objectId}&actionTypes=${flag}&objectType=${objectType}`);
                }
            }}
        >
            <div className='rt--flex rt--align-center rt--alert-info-content'>
                <i className='icon-info rt--font-bigest' />
                <span className='rt--pl-4 rt--title rt--font-bold rt--font-regular'>{t("backoffice.users.pendingRequestsInfoMessage").replace("%%count%%", requestsCount)}</span>
            </div>
        </div>
    ) : null
})

/** PendingRequestsNote propTypes
    * PropTypes
*/
PendingRequestsNote.propTypes = {
    /** Object Id */
    objectId: PropTypes.string,
    /** Action Type */
    actionTypes: PropTypes.arrayOf(PropTypes.number),
    /** Object Type */
    objectType: PropTypes.number,
    /** Dependencies */
    dependencies: PropTypes.array,
    /** Redux action, to get permission requests */
    getPermissionRequestsCount: PropTypes.func,
    /** Redux state property, permission requests counts */
    count: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getPermissionRequestsCount: (objectId, actionType, objectType) => {
            dispatch(getPermissionRequestsCount(objectId, actionType, objectType))
        }
    }
)

const mapStateToProps = state => {
    return {
        count: state.permissionRequests.count
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null, 
    { forwardRef: true }
)(
    PendingRequestsNote
);