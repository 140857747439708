import React, {useEffect} from "react";
import { Outlet } from "react-router";

import logoMobile from "NEW/public/images/logos/logoMobile.svg";

import LanguagesDropdown from "NEW/src/layouts/global/auth/components/languagesDropdown";

import { isMobile } from "NEW/src/core/helpers/appEnvironment/isMobile";
import { classNames } from "NEW/src/core/helpers/common/classNames";

import AuthSidebar from "NEW/src/layouts/global/auth/sidebar";
import {isRetailProject} from "NEW/src/core/helpers/projectEnvironmanet/isRetailProject";

const AuthLayout = () => {
	useEffect(() => {
		const titleElement = document.getElementsByTagName("title")[0];

		if (titleElement) {
			titleElement.innerText = isRetailProject() ? "Retail Solution" : "Agent System"
		}
	}, [])

	const headerClassNames = classNames(
		"rt--auth-header rt--flex rt--align-center",
		isMobile() ? "rt--justify-between" : "rt--justify-end",
	);
	const contentClassNames = classNames(
		"rt--auth-content rt--flex rt--align-center",
		isMobile() ? "" : "rt--flex-equal",
	);

	return (
		<>
			<div className="rt--auth rt--flex">
				<div className={headerClassNames}>
					{isMobile() ? <img src={logoMobile} alt="Logo" /> : null}
					<LanguagesDropdown />
				</div>

				{!isMobile() && <AuthSidebar />}

				<div className={contentClassNames}>
					<Outlet />
				</div>
			</div>
		</>
	);
};

export default AuthLayout;
