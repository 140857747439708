import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import EntityActions from "components/common/entityActions"
import ReparentComponent from "components/common/reparent";
import SetPasswordComponent from "../player-setPassword.component";

import { changePlayerState } from "store/actions/dashboard/players/players.action";

import { hasPermission } from 'utils/permissions';
import { hasPlayerReparentAccess, hasPlayerSetPasswordAccess, hasPlayerBlockAccess } from "utils/access";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { REPARENT_ENTITY_TYPE } from 'constants/agent.constants';
import { PLAYER_STATE } from 'constants/player.constants';

import userInfoType from 'types/profile/userInfo.type';
import playerGeneralInfoType from "types/player/generalInfo.type";

/** Player Actions Button Component */
const PlayerActionsComponent = ({
    generalInfo,
    userInfo,
    changePlayerState
}) => {

    const { t } = useTranslation();

    const searchParams = useParams();

    /** Flag to show/hide reparent popup */
    const [showReparentPopup, setShowReparentPopup] = useState(false);

    /** Flag to show/hide set password popup */
    const [showSetPasswordPopup, setShowSetPasswordPopup] = useState(false);

    /** Actions list */
    const actions = [
        /** Reparent */
        {
            key: "reparent",
            buttonType: "primary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.reparent'),
            icon: "icon-reparent",
            onClick: () => setShowReparentPopup(true),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.MODIFY }) ||
                !hasPlayerReparentAccess(userInfo, generalInfo.parentId)
            )
        },
        /** Set Password */
        {
            key: "setPassword",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.setPassword'),
            icon: "icon-password",
            onClick: () => setShowSetPasswordPopup(true),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.MODIFY }) || 
                generalInfo.state === PLAYER_STATE.BLOCKED ||
                !hasPlayerSetPasswordAccess(userInfo, generalInfo.parentId)
            )
        },
        /** Block/Unblock */
        {
            key: "block",
            buttonType: generalInfo.state === PLAYER_STATE.BLOCKED ? "primary" : "secondary",
            buttonClassName: generalInfo.state === PLAYER_STATE.BLOCKED ? "rt--button-danger" : "rt--button-secondary",
            title: generalInfo.state === PLAYER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            onClick: () => {
                changePlayerState({
                    id: searchParams.id,
                    enabled: generalInfo.state === PLAYER_STATE.BLOCKED
                })
            },
            icon: "icon-block",
            isConfirmation: true,
            confirmationMessage: generalInfo.state === PLAYER_STATE.BLOCKED ? t('backoffice.players.doYouWanttoUnblockPlayer') : t('backoffice.players.doYouWanttoBlockPlayer'),
            disabled: (
                !hasPermission({ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.MODIFY }) ||
                !hasPlayerBlockAccess(userInfo, generalInfo.parentId)
            )
        }
    ]

    return (
        <Fragment>
            <EntityActions 
                actions={actions}
            />

            {
                showReparentPopup && (
                    <ReparentComponent
                        onClose={() => setShowReparentPopup(false)}
                        type={REPARENT_ENTITY_TYPE.PLAYER}
                        ids={[searchParams.id]}
                    />
                )
            }

            {
                showSetPasswordPopup && (
                    <SetPasswordComponent
                        onClose={() => setShowSetPasswordPopup(false)}
                    />
                )
            }
        </Fragment>
        
    )
}

/** PlayerActionsComponent propTypes
    * PropTypes
*/
PlayerActionsComponent.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux state, represents the general info of current editing player  */
    generalInfo: playerGeneralInfoType,
    /** Redux action to block/unblock Player */
    changePlayerState: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        changePlayerState: data => {
            dispatch(changePlayerState(data));
        },
    }
)

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        generalInfo: state.players.edit.general,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerActionsComponent)