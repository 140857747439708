import PropTypes from 'prop-types';

import { SOCIAL_MEDIA_TYPE } from "constants/payments.constants"

export default PropTypes.shape({
    workingSchedule: PropTypes.object,
    translations: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string
    })),
    contacts: PropTypes.arrayOf(PropTypes.shape({
        socialMediaType: PropTypes.oneOf(Object.values(SOCIAL_MEDIA_TYPE)),
        linkInfo: PropTypes.string
    }))
})