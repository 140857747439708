import { useContext } from "react";

import TransferContext from "../context";

const useTransfer = () => {
    const context = useContext(TransferContext);
    
    return context;
};

export default useTransfer;