import PropTypes from 'prop-types';

import { 
    COMMISSION_PLAN_SOURCE, 
    COMMISSION_PLAN_TYPE,
    COMMISSION_PLAN_CALCULATION_PERIOD
} from "constants/commission.constants"


export default PropTypes.shape({
    currencyCode: PropTypes.string,
    source: PropTypes.oneOf(Object.values(COMMISSION_PLAN_SOURCE)),
    type: PropTypes.oneOf(Object.values(COMMISSION_PLAN_TYPE)),
    period: PropTypes.oneOf(Object.values(COMMISSION_PLAN_CALCULATION_PERIOD)),
    onlineProviders: PropTypes.arrayOf(PropTypes.string),
    retailProviders: PropTypes.number,
    sourceDirections: PropTypes.number,
    id: PropTypes.number
})