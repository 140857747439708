export const tableColumns = [
    {
        title: "backoffice.errorlogs.controller/action",
        dataIndex: "controller.action",
        key: "controller.action",
        mobileLevel: 1,
        render: (_, record) => (
            (record?.controller ?? "") + "/" + (record?.action ?? "")
        )
    },
    {
        title: "backoffice.errorlogs.message",
        dataIndex: "message",
        middle: true,
        mobileLevel: 2,
        copy: true,
    },
    {
        title: "backoffice.errorlogs.time",
        dataIndex: "errorTime",
        isDateTime: true,
        mobileLevel: 3
    },
];
