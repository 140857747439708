import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setWalletActionBefore, setWalletActionFinished } from './wallet.action';

import { 
    SET_WALLET_HISTORY,
    SET_WALLET_HISTORY_SORTING,
    SET_WALLET_HISTORY_FILTERS
} from "../../../actionTypes";

import { isMobile } from "utils/common";

const setWalletHistory = (transfers, add) => ({
    type: SET_WALLET_HISTORY,
    payload: { transfers, add },
});

export const setWalletHistorySorting = sorting => ({
    type: SET_WALLET_HISTORY_SORTING,
    payload: { sorting },
});

export const setWalletHistoryFilters = filters => ({
    type: SET_WALLET_HISTORY_FILTERS,
    payload: { filters },
});

export const getWalletHistory = (ownerId, ownerType, nextPage) => {
    
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().wallet.history.sorting.page + 1 : getState().wallet.history.sorting.page;
        let params = {
            ...getState().wallet.history.sorting,
            ...getState().wallet.history.filters,
            page: page,
            ownerId: ownerId,
            ownerType: ownerType
        };

        if(page > 1 && getState().wallet.history.total <= (page - 1 ) * getState().wallet.history.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().wallet.history.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setWalletActionBefore());

        return axios({
            url: ApiUrls.GET_WALLET_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: data } }) => {
                dispatch(setWalletHistory(data, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setWalletHistorySorting({ ...getState().wallet.history.sorting, page: page }));
                dispatch(setWalletActionFinished());
            })
            .catch((ex) => {
                dispatch(setWalletActionFinished());
            });
    }
}
