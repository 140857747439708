import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { getCommissionPlanSettings } from "./settings.action";

import { 
    SET_COMMISSION_PLANS_ACTION_BEFORE,
    SET_COMMISSION_PLANS_ACTION_FINISH,
    SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE,
    SET_COMMISSION_PLANS_SAVE_ACTION_FINISH,
    SET_COMMISSION_PLANS,
    SET_AVAILABLE_COMMISSION_PLANS,
    SET_AVAILABLE_COMMISSION_PLANS_BEFORE,
    SET_AVAILABLE_COMMISSION_PLANS_FINISH
} from "../../../../actionTypes";

export const setCommissionPlansActionBefore = () => ({
    type: SET_COMMISSION_PLANS_ACTION_BEFORE,
});

export const setCommissionPlansActionFinished = () => ({
    type: SET_COMMISSION_PLANS_ACTION_FINISH,
});

export const setCommissionPlansSaveActionBefore = () => ({
    type: SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE,
});

export const setCommissionPlansSaveActionFinished = () => ({
    type: SET_COMMISSION_PLANS_SAVE_ACTION_FINISH,
});

const setCommissionPlans = commissionPlans => ({
    type: SET_COMMISSION_PLANS,
    payload: { commissionPlans }
})

export const getCommissionPlans = agentId  => {
    return dispatch => {
        dispatch(setCommissionPlansActionBefore());
        return axios({
            url: ApiUrls.GET_COMMISSION_PLANS,
            method: Methods.GET,
            params: { agentId }
        })
        .then(({data : {value: commissionPlans}}) => {
            dispatch(setCommissionPlans(commissionPlans))
            dispatch(setCommissionPlansActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansActionFinished());
        })
    }
}

export const createCommissionPlan = (commissionPlan, agentId, onSuccess) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_COMMISSION_PLAN,
            method: Methods.POST,
            data: {
                ...commissionPlan
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCommissionPlans(agentId));

                onSuccess && onSuccess();
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const deleteCommissionPlan = (id, agentId, onSuccess) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_COMMISSION_PLAN,
            method: Methods.DELETE,
            data: {
                id,
                agentId
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCommissionPlans(agentId));
                onSuccess && onSuccess();
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const addCommissionPlan = (id, agentId, onSuccess) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_COMMISSION_PLAN,
            method: Methods.POST,
            data: {
                id, 
                agentId
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCommissionPlans(agentId));
                onSuccess && onSuccess();
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const removeCommissionPlan = (id, agentId, onSuccess) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.REMOVE_COMMISSION_PLAN,
            method: Methods.DELETE,
            data: {
                id,
                agentId
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCommissionPlans(agentId));
                onSuccess && onSuccess();
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

const setAvailableCommissionPlansBefore = () => ({
    type: SET_AVAILABLE_COMMISSION_PLANS_BEFORE
})

const setAvailableCommissionPlansFinished = () => ({
    type: SET_AVAILABLE_COMMISSION_PLANS_FINISH
})

const setAvailableCommissionPlans = commissionPlans => ({
    type: SET_AVAILABLE_COMMISSION_PLANS,
    payload: { commissionPlans }
})

export const getAvailableCommissionPlans = id  => {
    return dispatch => {
        dispatch(setAvailableCommissionPlansBefore());
        return axios({
            url: ApiUrls.GET_AVAILABLE_COMMISSION_PLANS,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: commissionPlans}}) => {
            dispatch(setAvailableCommissionPlans(commissionPlans))
            dispatch(setAvailableCommissionPlansFinished());
        })
        .catch((ex) => {
            dispatch(setAvailableCommissionPlansFinished());
        })
    }
}

export const activateCommissionPlan = (id, agentId) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.ACTIVATE_COMMISSION_PLAN,
            method: Methods.POST,
            data: { id, agentId }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCommissionPlanSettings(id, agentId));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const deactivateCommissionPlan = (id, agentId) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.DEACTIVATE_COMMISSION_PLAN,
            method: Methods.POST,
            data: { id, agentId }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getCommissionPlanSettings(id, agentId));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}