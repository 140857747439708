import { VALID_GROUP_IDS } from "constants/translation.constants";

/** Function to validate translations array
     * @function
     * @returns {Object}
 */
export const validateTranslations = (translations, validLanguages) => {
    const length = translations.length;

    for(let i = 0; i < length; i++){
        let current = translations[i];
        if(!validLanguages.some(validLanguageCode => String(validLanguageCode).toUpperCase() === current.languageCode)){
            return Promise.reject(`Invalid LanguageCode - ${current.languageCode}`);
        }

        if(!VALID_GROUP_IDS.includes(current.groupId)){
            return Promise.reject(`Invalid GroupId - ${current.groupId}`);
        }

        if(current.createdAt || current.modifiedAt){
            return Promise.reject(`CreatedAt Or ModifiedAt Found`);
        }
    }
    return Promise.resolve();
}