import PropTypes from 'prop-types';

import { USER_STATE, USER_TYPE } from "constants/user.constants";

export default PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    state: PropTypes.oneOf(Object.values(USER_STATE)),
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    allowEdit: PropTypes.bool,
    mainCompany: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.string),
    avatarId: PropTypes.string,
    role: PropTypes.oneOf(Object.values(USER_TYPE))
})