import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {isFranchisingMode, isMobile} from "utils/common";

import {
    SET_AGENT_TRANSACTIONS_REPORT_ACTION_BEFORE,
    SET_AGENT_TRANSACTIONS_REPORT_ACTION_FINISH,
    SET_AGENT_TRANSACTIONS_REPORT,
    SET_AGENT_TRANSACTIONS_REPORT_SORTING,
    SET_AGENT_TRANSACTIONS_REPORT_FILTERS
} from "../../../actionTypes";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";
import {WALLET_OWNER_TYPE} from "constants/wallet.constants";

export const setAgentTransactionsReportActionBefore = () => ({
    type: SET_AGENT_TRANSACTIONS_REPORT_ACTION_BEFORE,
});

export const setAgentTransactionsReportActionFinished = () => ({
    type: SET_AGENT_TRANSACTIONS_REPORT_ACTION_FINISH,
});

const setAgentTransactionsReport = (report, add) => ({
    type: SET_AGENT_TRANSACTIONS_REPORT,
    payload: { report, add },
});

export const setAgentTransactionsReportSorting = sorting => ({
    type: SET_AGENT_TRANSACTIONS_REPORT_SORTING,
    payload: { sorting },
});

export const setAgentTransactionsReportFilters = filters => ({
    type: SET_AGENT_TRANSACTIONS_REPORT_FILTERS,
    payload: { filters },
});


export const getAgentTransactionsReport = nextPage => {
    return (dispatch, getState) => {

        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().agentTransactionsReport.sorting.page + 1 : getState().agentTransactionsReport.sorting.page;
        const filters = {...getState().agentTransactionsReport.filters}
        let participants = null;

        if (isFranchisingMode() && getUser().role === USER_ROLE.AGENT) {
            participants = [
                {
                    ownerType: WALLET_OWNER_TYPE.AGENT,
                    userNameOrId: getState().profile.userInfo.id
                },
                {
                    ownerType: WALLET_OWNER_TYPE.BETSHOP,
                    userNameOrId: filters.participant2
                }
            ]
        } else {
            if(filters.participant1Type || filters.participant2Type){
                participants = [];
            }

            if(filters.participant1Type){
                const participant1 = {
                    ownerType: filters.participant1Type
                }

                if(filters.participant1){
                    participant1.userNameOrId = filters.participant1
                }

                participants.push(participant1)
            }

            if(filters.participant2Type){
                const participant2 = {
                    ownerType: filters.participant2Type
                }

                if(filters.participant2){
                    participant2.userNameOrId = filters.participant2
                }

                participants.push(participant2)
            }
        }


        filters.participants = participants;

        delete filters.participant1;
        delete filters.participant2;
        delete filters.participant1Type;
        delete filters.participant2Type;
        delete filters.includeRelated;


        let data = {
            ...getState().agentTransactionsReport.sorting,
            ...filters,
            page: page
        };

        if(page > 1 && getState().agentTransactionsReport.total <= (page - 1 ) * getState().agentTransactionsReport.sorting.limit){
            return Promise.resolve();
        }

        dispatch(setAgentTransactionsReportActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_TRANSACTIONS_REPORT,
            method: Methods.POST,
            data: data,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setAgentTransactionsReport(report, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAgentTransactionsReportSorting({ ...getState().agentTransactionsReport.sorting, page: page }));
                dispatch(setAgentTransactionsReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentTransactionsReportActionFinished());
            });
    }
}
