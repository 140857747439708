import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setBetshopsActionBefore, setBetshopsActionFinished, setBetshopsSaveActionBefore, setBetshopsSaveActionFinished } from './betshops.action';

import { SET_BETSHOP_CURRENCY } from "../../../../actionTypes";

const setBetshopCurrency = currency => ({
    type: SET_BETSHOP_CURRENCY,
    payload: { currency }
})

export const getBetshopCurrency = id => {
    return dispatch => {
        dispatch(setBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_CURRENCY,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setBetshopCurrency(info))
            dispatch(setBetshopsActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsActionFinished());
        })
    }
}

export const saveBetshopCurrency = currency => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_BETSHOP_CURRENCY,
            method: Methods.POST,
            data: {
                ...currency
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBetshopCurrency(data.value));
            } 
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}

export const deleteBetshopCurrency = id => {
    return dispatch => {
        return axios({
            url: ApiUrls.DELETE_BETSHOP_CURRENCY,
            method: Methods.DELETE,
            data: {
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getBetshopCurrency(id));
            } 
        })
        .catch((ex) => {
            console.log(ex)
        })
    }
}
