import React, {useContext} from 'react';
import {RowContext} from "components/common/table/rowContext";

const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);

    return (
        <button
            type="text"
            className='rt--table-drag-handle'
            style={{cursor: 'move', touchAction: 'none'}}
            ref={setActivatorNodeRef}
            {...listeners}
        >
            <i className='icon-interactive' />
        </button>
    );
};

export default DragHandle;
