import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";

import { Form, Col, Row, Radio } from 'antd';

import ImageUploader from 'components/common/imageUploader';
import Modal from "components/common/modal";
import Input from 'components/common/input';

import { addAgentBankDetails, addAgentBankImg } from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action';

import { isMobile } from 'utils/common';

import { REGISTRATION_FORM_TYPE } from "constants/project.constants";
import { IMAGE_TYPE } from 'constants/common.constants';

const VALUER_TYPE = {
    TEXT: "text",
    FILE: "file"
}

/** Bank details adding Popup Component */
const BankDetailsAddComponent = ({
    isSaving,
    addAgentBankDetails,
    type,
    currencyCode,
    onClose,
    addAgentBankImg
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const searchParams = useParams();
    const { search } = useLocation();

    const currentBankId = (new URLSearchParams(search)).get("bankId");

    /** Fires when form submitted
       * @function
       * @memberOf BankDetailsAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(({ key, value, valueType }) => {
                let details = {};

                if (valueType === VALUER_TYPE.FILE) {
                    const formData = new FormData();

                    formData.append("key", key);
                    formData.append("agentId", searchParams.id);
                    formData.append("id", currentBankId);
                    formData.append("type", type);
                    formData.append("currencyCode", currencyCode);
                    formData.append("file", value);
                    
                    return addAgentBankImg(formData, onClose)
                }

                details = {
                    agentId: searchParams.id,
                    id: currentBankId,
                    currencyCode,
                    type,
                    key,
                    value,
                };
                addAgentBankDetails(details, onClose);
            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.payments.addDetailRow')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    key: "",
                    valueType: VALUER_TYPE.TEXT,
                    value: "",
                }}
            >
                <Row gutter={[16, 0]}>

                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.payments.key')} *`}
                            name="key"
                            rules={[{ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payments.key')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payments.key')}`}
                                autoFocus={isMobile()}
                                autoComplete="off"
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="valueType"
                            className='rt--form-item-without-height'
                            label={`${t('backoffice.payments.valueType')} *`}
                        >
                            <Radio.Group>
                                <Radio value={VALUER_TYPE.TEXT}>
                                    <div className='rt--payment-section-sub-block-items-item'>
                                        {t("backoffice.payments.text")}
                                    </div>
                                </Radio>

                                <Radio value={VALUER_TYPE.FILE}>
                                    <div className='rt--payment-section-sub-block-items-item'>
                                        {t("backoffice.payments.file")}
                                    </div>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                                const valueType = getFieldValue("valueType");

                                if (valueType === VALUER_TYPE.TEXT) {
                                    return (
                                        <Form.Item
                                            label={`${t('backoffice.payments.value')} *`}
                                            name="value"
                                            rules={[{ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }]}
                                            className='rt--general-form-item rt--form-item-without-margin'
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payments.value')}`}
                                        >
                                            <Input
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payments.value')}`}
                                                autoFocus={isMobile()}
                                                autoComplete="off"
                                                maxLength={100}
                                            />
                                        </Form.Item>
                                    )
                                }

                                return (
                                    <Form.Item
                                        name="value"
                                        rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                        className='rt--form-item-without-margin'
                                    >
                                        <ImageUploader
                                            size={1024 * 1024 * 1}
                                            disablePreview={true}
                                            remove={true}
                                            removeWithoutConfirmation={true}
                                            acceptedFormats={[ IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG ]}
                                            disableDownload={true}
                                            maxDimensions={{width: 400, height: 200}}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** BankDetailsAddComponent propTypes
    * PropTypes
*/
BankDetailsAddComponent.propTypes = {
    /** Redux state property, is true when creating translation request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add agent bank details */
    addAgentBankDetails: PropTypes.func,
    /** Redux action to add agent bank Image */
    addAgentBankImg: PropTypes.func,
    /** Bank details type */
    type: PropTypes.oneOf(Object.values(REGISTRATION_FORM_TYPE)),
    /** Selected Currency Code */
    currencyCode: PropTypes.string,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addAgentBankDetails: (details, onSuccess) => {
            dispatch(addAgentBankDetails(details, onSuccess));
        },
        addAgentBankImg: (formData, onSuccess) => {
            dispatch(addAgentBankImg(formData, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.agents.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsAddComponent)