import {
    SET_WALLET_ACTION_BEFORE,
    SET_WALLET_ACTION_FINISH,
    SET_WALLET_SAVE_ACTION_BEFORE,
    SET_WALLET_SAVE_ACTION_FINISH,
    SET_AGENT_WALLETS,
    SET_WALLET_HISTORY,
    SET_WALLET_HISTORY_SORTING,
    SET_WALLET_HISTORY_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_WALLET_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_WALLET_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_WALLET_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_WALLET_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_AGENT_WALLETS:
            return {
                ...state,
                agentWallet: {
                    ...state.agentWallet,
                    wallets: payload.wallets
                }
            }
        case SET_WALLET_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    transfers: !payload.add ? payload.transfers.item2 : state.history.transfers.concat(payload.transfers.item2),
                    total: payload.transfers.item1,
                }
            };

        case SET_WALLET_HISTORY_FILTERS:
            return {
                ...state,
                history: {
                    ...state.history,
                    filters: payload.filters,
                    sorting: {
                        ...state.history.sorting,
                        page: 1
                    }
                }
            };
        case SET_WALLET_HISTORY_SORTING:
            return {
                ...state,
                history: {
                    ...state.history,
                    sorting: payload.sorting
                }
            };
        default:
            return state;
    }
};
