import axios from "axios";

import Methods from "constants/methods.constants";

import { EXPORT_TYPE } from 'constants/common.constants';

import dateService from "utils/date";

export const exportData = (url, exportType, exportColumns, tableName, filters, isPostRequest) => {
    return () => {
        const method = isPostRequest ? Methods.POST : Methods.GET;
        const timeZone = dateService.getTimeZone();
        let columnsStr = "timeZone=" + timeZone + "&";
        exportColumns.forEach(c => {
            columnsStr += `ExportColumns[${c.key}]=` + c.title + "&"
        });
        let urlStr = url + (url.indexOf("?") > -1 ? "&" + columnsStr : "?" + columnsStr);

        let arrParams = {};
        if (filters) {
            Object.keys(filters).forEach(f => {
                if (Array.isArray(filters[f]) && filters[f].length > 0) {
                    arrParams[f] = filters[f]
                }
            })
        }

        let qsArrParamsStr = "";
        if(!isPostRequest){
            let qsArrParams = Object.keys(arrParams).map(p => arrParams[p].map(i => `${p}=${i}`).join("&"));
            if (qsArrParams.length) {
                qsArrParamsStr = "&" + qsArrParams.join("&")
            }
        }

        const f = { ...filters };

        Object.keys(arrParams).forEach(p => {
            delete f[p];
        })

        let params = {
            ...f
        };

        if(isPostRequest){
            const columnsData = exportColumns.reduce(
                (accumulator, currentValue) => {
                    console.log(currentValue['key'], currentValue['title'])
                    accumulator[currentValue['key']] = currentValue['title'];
                    return accumulator;
                },
                {},
            );

            params["ExportColumns"] = columnsData;
            params["ExportType"] = exportType;
            params["timeZone"] = timeZone;
            urlStr = url;
            params = {
                ...params,
                ...arrParams
            }
        }

        return axios({
            url: isPostRequest ? urlStr : `${urlStr}ExportType=${exportType}${qsArrParamsStr}`,
            method: method,
            params: !isPostRequest ? params : undefined,
            data: isPostRequest ? params : undefined,
            responseType: 'blob'
        })
            .then(({ data }) => {
                try {
                    const applicationType = exportType === EXPORT_TYPE.CSV ? "text/csv" : "application/pdf";
                    const blob = new Blob([data], { type: applicationType });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.target = "_blank";
                    const ext = exportType === EXPORT_TYPE.CSV ? ".csv" : ".pdf"
                    const fileName = `${tableName} - ${dateService.format(dateService.getNow())}`;
                    link.download = fileName + ext;
                    link.click();
                } catch (ex) {
                    console.log(ex)
                }

            })
            .catch(() => { })
    }
}
