import React from "react";

import i18n from "translations/config";

import Tooltip from "components/common/tooltip";

import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_STATUS, PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';

export const getTableColumns = (additionalProps) => {
    const {
        isHistory = false
    } = additionalProps;

    return [
        {
            title: "backoffice.users.username",
            dataIndex: 'userName',
            sorting: isHistory,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.users.actionType",
            dataIndex: 'actionType',
            mobileLevel: 2,
            render: value => value === PERMISSION_REQUEST_TYPE.GROUP_MODIFY ? i18n.t("backoffice.users.permissionGroupModify") :
                value === PERMISSION_REQUEST_TYPE.ADD_COMPANY_ACCESS ? i18n.t("backoffice.users.addCompanyAccess") :
                    value === PERMISSION_REQUEST_TYPE.DELETE_COMPANY_ACCESS ? i18n.t("backoffice.users.deleteCompanyAccess") :
                        value === PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS ? i18n.t("backoffice.users.addProjectAccess") :
                            value === PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS ? i18n.t("backoffice.users.deleteProjectAccess") :
                                value === PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP ? i18n.t("backoffice.users.addPermissionGroup") :
                                    value === PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP ? i18n.t("backoffice.users.deletePermissionGroup") :
                                        value === PERMISSION_REQUEST_TYPE.SET_PASSWORD ? i18n.t("backoffice.common.setPassword") : "",
            sorting: isHistory
        },
        {
            title: "backoffice.users.objectType",
            dataIndex: "objectType",
            mobileLevel: 3,
            render: value => value === PERMISSION_REQUEST_OBJECT_TYPE.PERMISSION_GROUP ? i18n.t("backoffice.users.permissionGroup") :
                value === PERMISSION_REQUEST_OBJECT_TYPE.USER ? i18n.t("backoffice.users.user") : "",
            sorting: isHistory
        },
        {
            title: 'backoffice.users.objectName',
            dataIndex: "objectName",
            mobileLevel: 4,
        },
        {
            title: "backoffice.permissiongroups.securityLevel",
            dataIndex: "groupLevel",
            render: value => value ?? "-",
            mobileLevel: 5
        },
        isHistory && {
            title: "backoffice.users.status",
            dataIndex: "status",
            render: value => value === PERMISSION_REQUEST_STATUS.APPROVED ? (
                <span className="rt--success-text">
                    {i18n.t("backoffice.users.approved")}
                </span>
            ) : value === PERMISSION_REQUEST_STATUS.REJECTED ? (
                <span className="rt--error-text">
                    {i18n.t("backoffice.users.rejected")}
                </span>
            ) : "",
            sorting: isHistory,
            mobileLevel: 6
        },
        {
            title: "backoffice.users.date",
            dataIndex: "createdAt",
            isDateTime: true,
            mobileLevel: !isHistory ? 6 : undefined,
            sorting: isHistory
        },
        isHistory && {
            title: "backoffice.users.modifiedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorting: isHistory,
            mobileLevel: 7,
        },
        isHistory && {
            title: "backoffice.users.modifiedBy",
            dataIndex: "modifiedBy",
            sorting: isHistory
        },
        {
            title: "backoffice.users.notes",
            dataIndex: isHistory ? "note" : "requestNote",
            short: true,
            mobileLevel: !isHistory ? 7 : undefined
        },
    ]
}

export const expandColumns = [
    {
        title: "#",
        dataIndex: "index",
        mobileLevel: 1,
    },
    {
        title: 'backoffice.users.change',
        dataIndex: "change",
        mobileLevel: 2,
        mobileRender: value => (
            <Tooltip
                title={value}
                trigger={["click"]}
                enableMobile={true}
                className="rt--tooltip-table"
            >
                <span className="rt--title rt--font-normal rt--table-card-view-row-value">
                    {value}
                </span>

            </Tooltip>
        )
    }
];