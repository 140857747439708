import axios from "axios";

import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

import {setPlayersActionBefore, setPlayersActionFinished} from "store/actions/dashboard/players/players.action";
import {
    SET_PLAYER_TRANSACTIONS_HISTORY,
    SET_PLAYER_TRANSACTIONS_HISTORY_FILTERS,
    SET_PLAYER_TRANSACTIONS_HISTORY_SORTING
} from "store/actionTypes";
import dateService from "utils/date";

const setPlayerTransactionsHistory = (history) => ({
    type: SET_PLAYER_TRANSACTIONS_HISTORY,
    payload: { history }
});

export const setPlayerTransactionsHistorySorting = sorting => ({
    type: SET_PLAYER_TRANSACTIONS_HISTORY_SORTING,
    payload: { sorting }
});

export const setPlayerTransactionsHistoryFilters = filters => ({
    type: SET_PLAYER_TRANSACTIONS_HISTORY_FILTERS,
    payload: { filters }
});

export const getPlayerTransactionsHistory = (id, nextPage) => {
    return (dispatch, getState) => {
        const state = getState().players.edit.playerTransactionsHistory;

        const filters = { ...state.filters };
        const sorting = { ...state.sorting };

        const page = nextPage === "first" ? 1 : nextPage === "next"? sorting.page + 1 : sorting.page;

        let from = dateService.mergeDateAndTime(filters.day, filters.time?.[0]);
        let to = dateService.mergeDateAndTime(filters.day, filters.time?.[1]);

        from = dateService.toISOString(from);
        to = dateService.toISOString(to);

        delete filters.day;
        delete filters.time;

        let data = {
            playerId: id,
           ...sorting,
           ...filters,
            from,
            to,
            page
        };

        dispatch(setPlayersActionBefore());

        return axios({
            url: ApiUrls.PLAYER_TRANSACTION_HISTORY,
            method: Methods.GET,
            params: data
        }).then(({ data: { value: history }}) => {
            dispatch(setPlayerTransactionsHistory({ ...history, playerTransactions: history.playerTransactions || [] }));

            if (nextPage) {
                dispatch(setPlayerTransactionsHistorySorting({ ...sorting, page: page }));
            }
        }).finally(() => {
            dispatch(setPlayersActionFinished());
        })
    }
}
