//#region react
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region libraries
import { Form, Row, Col, Spin } from 'antd';
//#endregion

//#region actions
import {
    saveBetshopGeneralInfo,
    getBetshopGeneralInfo,
    saveBetshopTranslation
} from "store/actions/dashboard/retail/betshops/general.action";
//#endregion

//#region components
import Table from "components/common/table";
import Select from "components/common/select";
import NumericInput from 'components/common/numericInput';
import Input from 'components/common/input';
import TabFormDashboardLayout from "components/layouts/tab/form";
import BetshopActionsComponent from "../../betshop-actions.component";
//#endregion

//#region utils
import { isFormChanged } from "utils/form";
import { getUser } from 'utils/auth';
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import { EMAIL_REGEX, TEL_REGEX } from "constants/regex.constants";
import { USER_STATE } from 'constants/user.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import betshopGeneralInfoType from "types/betshop/generalInfo.type";
//#endregion

const groupTranslations = (generalInfo) => {
    let translations = [];
    let generalInfoTranslations = generalInfo.translations || [];
    if (generalInfoTranslations.length === 0) return translations;

    const nameKey = generalInfoTranslations.find(k => k.key.startsWith("name_"));
    const addressKey = generalInfoTranslations.find(k => k.key.startsWith("address_"));
    if (!nameKey || !addressKey) {
        return translations;
    }

    const nameKeyTranslations = nameKey.translations || [];
    const addressKeyTranslations = addressKey.translations || [];

    const languages = nameKeyTranslations.map(t => t.languageCode);

    languages.forEach(lang => {
        translations.push({
            nameKey: nameKey.key,
            addressKey: addressKey.key,
            languageCode: lang,
            name: nameKeyTranslations.find(t => t.languageCode === lang)?.text ?? "",
            address: addressKeyTranslations.find(t => t.languageCode === lang)?.text ?? ""
        })
    })
    return translations;
}

const TABLE_ROW_UNIQUE_KEY = "languageCode";

/** Betshop Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    getBetshopGeneralInfo,
    saveBetshopGeneralInfo,
    saveBetshopTranslation,
    isSaving,
    isLoading,
    generalInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const { userState } = getUser();
    const isUserBlocked = userState === USER_STATE.BLOCKED;

    const tableData = groupTranslations(generalInfo);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETSHOP_GENERALINFO,
        action: PERMISSION_ACTION.MODIFY
    })

    const userCanEdit = hasModifyPermission && !isUserBlocked;

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleFieldEdit = useCallback((newValue, languageCode, type, key) => {
        saveBetshopTranslation(
            {
                id: searchParams.id,
                type,
                languageCode,
                text: newValue
            },
            key
        )
    }, [searchParams.id])

    const handleFormChange = (_, formValues) => {
        const formChanged = isFormChanged(
            {
                email: formValues.email,
                phoneNumber: formValues.phoneNumber,
                dst: formValues.dst,
            },
            {
                email: generalInfo.email,
                phoneNumber: generalInfo.phoneNumber,
                dst: generalInfo.dst,
            }
        )

        setIsFormTouched(formChanged);
    }

    const handleForm = () => {
        validateFields()
            .then(data => {
                saveBetshopGeneralInfo({
                    id: searchParams.id,
                    enabled: generalInfo.enabled,
                    dst: data.dst,
                    phoneNumber: data.phoneNumber,
                    email: data.email
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                havePermissionForEdit: userCanEdit,
                onFieldEdit: handleFieldEdit,
            }
        })
    }, [userCanEdit, handleFieldEdit])

    //#endregion

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    // Load betshop general info
    useEffect(() => {
        getBetshopGeneralInfo(searchParams.id);
    }, [])


    // Set form fields values, when data is loaded
    useEffect(() => {
        setFieldsValue({
            phoneNumber: generalInfo.phoneNumber,
            email: generalInfo.email,
            dst: String(generalInfo.dst)
        });
    }, [generalInfo])

    return (
        <TabFormDashboardLayout
            actions={<BetshopActionsComponent />}
            id={generalInfo.id}
            longId={generalInfo.longId}
            buttons={[{
                type: "primary",
                onClick: handleForm,
                text: t("backoffice.common.save"),
                enabled: userCanEdit,
                loading: isSaving,
                disabled: !isFormTouched
            }]}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    layout="vertical"
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    onValuesChange={handleFormChange}
                    initialValues={{
                        phoneNumber: generalInfo.phoneNumber,
                        email: generalInfo.email,
                        dst: String(generalInfo.dst)
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.betshops.code')}
                                className='rt--form-item-disabled rt--general-form-item'
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.code')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.code')}`}
                                    disabled={true}
                                    value={generalInfo.code}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.betshops.currency')}
                                className='rt--form-item-disabled rt--general-form-item'
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.currency')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.currency')}`}
                                    disabled={true}
                                    value={generalInfo.currencyCode}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.betshops.email')}
                                name="email"
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.email')}`}
                                rules={[
                                    { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                                    { max: 50, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.email')}`}
                                    maxLength={50}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.betshops.tel')}
                                name="phoneNumber"
                                className={'rt--general-form-item' + (!userCanEdit ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.tel')}`}
                                rules={[
                                    { pattern: TEL_REGEX, message: t('backoffice.validation.telFormat') },
                                    { max: 18, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                            >
                                <NumericInput
                                    maxLength={18}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.tel')}`}
                                    disabled={!userCanEdit}
                                    isMobileNumber={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.betshops.daylightSavingTime')}
                                name="dst"
                                className={(!userCanEdit ? " rt--form-item-disabled" : "")}
                            >
                                <Select
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.betshops.daylightSavingTime')}`}
                                    disabled={!userCanEdit}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    options={[
                                        { value: "-1", text: "-1" },
                                        { value: "0", text: "0" },
                                        { value: "1", text: "1" }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <span className='rt--form-section-title rt--title rt--flex rt--pb-16 rt--font-bold rt--font-biger'>
                        {t("backoffice.betshops.translations")}
                    </span>

                    <Table
                        columns={mainTableColumns}
                        data={tableData}
                        uniqueKey={TABLE_ROW_UNIQUE_KEY}
                        noPagination={true}
                        disableFullView={true}
                    />
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux action to get betshop General info */
    getBetshopGeneralInfo: PropTypes.func,
    /** Redux action to save betshop General info */
    saveBetshopGeneralInfo: PropTypes.func,
    /** Redux action to save betshop translation */
    saveBetshopTranslation: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing betshop  */
    generalInfo: betshopGeneralInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getBetshopGeneralInfo: id => {
            dispatch(getBetshopGeneralInfo(id));
        },

        saveBetshopGeneralInfo: data => {
            dispatch(saveBetshopGeneralInfo(data));
        },

        saveBetshopTranslation: (translation, key) => {
            dispatch(saveBetshopTranslation(translation, key))
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.betshops.edit.general,
        isSaving: state.betshops.isSaving,
        isLoading: state.betshops.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)