import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';

import { useLocation, useNavigate } from 'react-router';

import { Tabs as AntTabs } from 'antd';

import TabDashboardLayout from "components/layouts/tab";

import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';

import { classNames, getHashValue, updateLocationHash } from 'utils/common';

import useUnsavedForm from 'hooks/useUnsavedForm';
import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';
import {isTabVisible} from "components/common/tabs/helpers/isTabVisible";

/** Tabs */
const Tabs = ({
    items,
    mainPage,
    destroyInactiveTabPane,
    tabBarExtraContent,
    className
}) => {

    const { pathname, search, hash } = useLocation();
    const navigate = useNavigate();

    const entityName = (new URLSearchParams(search)).get("name");

    const [unsavedTabs, setUnsavedTabs] = useState([]);

    const [activeKey, setActiveKey] = useState(null);

    const { setToUnsavedForms, removeFromUnsavedForms } = useUnsavedForm(UNSAVED_FORM_PAGE_TYPE.TAB);

    const tabsWithIds = useMemo(() => (
        items.map((item, index) => ({...item, key: index.toString() }))
    ), [items]);

    const availableItems = useMemo(() => (
        tabsWithIds.filter(isTabVisible)
    ), [tabsWithIds]);

    /** Function fires on tab change
       * @function
       * @param {string} key - tab key
       * @memberOf Tabs
   */
    const handleTabsChange = (key) => {
        setActiveKey(key);
        updateLocationHash("tab=" + key);
    }

    const handleTabsChangeWithConfirmation = useUnsavedFormConfirmation({
        cb: handleTabsChange,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    /** Function to get first tab key
       * @function
       * @returns {number}
       * @memberOf Tabs
   */
    const getInitialActiveKey = () => availableItems[0]?.key;

    /** Function to make tab class name
       * @function
       * @description addes class "unsaved-tab" if tab has unsaved changes
       * @param {string} key - tab key
       * @returns {string}
       * @memberOf Tabs
   */
    const tabClassName = key => unsavedTabs.indexOf(key) > -1 ? "rt--tab-unsaved" : "";

    /** Fires when tab saved status changed
       * @function
       * @param {boolean} status - does tab have unsaved change
       * @param {string} key - tab key
       * @memberOf Tabs
   */
    const changeTabSavedStatus = (status, key) => {
        if (status && unsavedTabs.indexOf(key) === -1) {
            setToUnsavedForms({ key });
            setUnsavedTabs([...unsavedTabs, key]);
        } else if (!status) {
            removeFromUnsavedForms({ key });
            setUnsavedTabs(unsavedTabs.filter(t => t !== key));
        }
    }

    const tabWithSubTabsExist = availableItems.some(item => item.pathParams);

    const pathParamsValues = availableItems.reduce((acc, current) => {
        const obj = {...acc}
        if(current.pathParams){
            obj[current.pathParams.name] = (new URLSearchParams(search)).get(current.pathParams.name);
            obj[current.pathParams.id] = (new URLSearchParams(search)).get(current.pathParams.id);
        }
        return obj;
    }, {})

    /** Set default tab */
    useEffect(() => {
        const tabKey = getHashValue("tab");
        const selectedKey = tabKey ? tabKey : getInitialActiveKey()?.toString();
        const keyPresent = availableItems.find(item => item.key === selectedKey);

        if (keyPresent) {
            setActiveKey(selectedKey);
        } else {
            const defaultKey = getInitialActiveKey()?.toString();

            if (defaultKey) {
                setActiveKey(defaultKey);

                navigate(
                    (pathname + search + hash).replace(`tab=${tabKey}`, `tab=${defaultKey}`),
                    { replace: true }
                );
                return;
            }
        }

        /** Tabs indexes which has additional params */
        const keys = []

        availableItems.forEach((item) => {
            if(item.pathParams){
                keys.push(item.key)
            }
        });

        let shouldNavigate = false;
        let pathToNavigate = pathname + search + hash;

        for(let i = 0; i < keys.length; i++){
            const current = keys[i];

            if (tabKey !== current.toString()) {
                const foundItem = availableItems.find(item => item.key === keys[i]);

                if (!foundItem) {
                    return;
                }

                shouldNavigate = true;
                const pathParams = foundItem.pathParams;

                pathToNavigate = pathToNavigate.replace(`&${pathParams.id}=${pathParamsValues[pathParams.id]}`, "").replace(`&${pathParams.name}=${pathParamsValues[pathParams.name]}`, "")
            }
        }

        if(shouldNavigate){
            navigate(pathToNavigate, { replace: true })
        }
    }, [hash])

    const generateBreadCrumbs = () => {
        const breadcrumbs = Array.isArray(mainPage) ? [...mainPage] : [ mainPage ];
        const breadcrumb = {};

        if (!entityName) {
            return breadcrumbs;
        }

        breadcrumb.title = entityName;
        breadcrumb.path = undefined;

        let newBreadCrumb = null;

        availableItems.forEach(item => {
            if(item.pathParams){
                const id = item.pathParams.id;
                const name = item.pathParams.name;
                const encodedId = encodeURIComponent(pathParamsValues[id]);
                const encodedName = encodeURIComponent(pathParamsValues[name]);

                if (pathParamsValues[id]) {
                    breadcrumb.path = (pathname + search + hash).replace(`&${id}=` + encodedId, "").replace(`&${name}=` + encodedName, "");
                    breadcrumb.replace = true;
                    newBreadCrumb = { title: pathParamsValues[name] ?? "Unknown" }
                }
            }
        });

        breadcrumbs.push(breadcrumb);

        if (newBreadCrumb) {
            breadcrumbs.push(newBreadCrumb);
        }
        return breadcrumbs;
    }

    return (
        <TabDashboardLayout
            breadcrumbs={
                {
                    items: generateBreadCrumbs()
                }
            }
        >
            <AntTabs
                animated={false}
                activeKey={activeKey}
                destroyInactiveTabPane={destroyInactiveTabPane}
                onChange={ tabWithSubTabsExist ? handleTabsChangeWithConfirmation : handleTabsChange }
                className={classNames(
                    'rt--tabs',
                    className
                )}
                tabBarExtraContent={tabBarExtraContent}
                items={
                    availableItems
                        .map((tab) => (
                            {
                                key: tab.key,
                                label: (
                                    <span className={tabClassName(tab.key)}>
                                        {tab.title}
                                    </span>
                                ),
                                children: (
                                    React.cloneElement(
                                        tab.component,
                                        {
                                            onTabChange: status => changeTabSavedStatus(status, tab.key),
                                            tabId: tab.key
                                        }
                                    )
                                )
                            }
                        ))
                }
            />
        </TabDashboardLayout>
    )
}

/** Tabs propTypes
    * PropTypes
*/

Tabs.propTypes = {
    /** Items */
    items: PropTypes.arrayOf(PropTypes.shape({
        /** Tab title */
        title: PropTypes.string,
        /** Permissions */
        permissions: PropTypes.arrayOf(PropTypes.shape({
            /** Permission resource */
            resource: PropTypes.oneOf(Object.values(PERMISSION_RESOURCE)),
            /** Permission action */
            action: PropTypes.oneOf(Object.values(PERMISSION_ACTION))
        })),
        /** Component */
        component: PropTypes.node,
        /** Is disabled */
        disabled: PropTypes.bool,
        /** Additional pathParams */
        pathParams: PropTypes.shape({
            /** Name */
            name: PropTypes.string,
            /** id */
            id: PropTypes.string
        })
    })),
    /** Main page data */
    mainPage: PropTypes.oneOfType([
        PropTypes.shape({
            /** Main page title */
            title: PropTypes.string,
            /** Main page path */
            path: PropTypes.string
        }),
        PropTypes.arrayOf(PropTypes.shape({
            /** Main page title */
            title: PropTypes.string,
            /** Main page path */
            path: PropTypes.string
        }))
    ]),
    /** Destroy Inactive Tabs */
    destroyInactiveTabPane: PropTypes.bool,
    /** Content that will be displayed in the right corner  */
    tabBarExtraContent: PropTypes.node,
    /** Overwrite className */
    className: PropTypes.string
}

export default Tabs;
