import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, AutoComplete, Spin, Divider } from 'antd';

import Select from "components/common/select";
import NumericInput from "components/common/numericInput";
import Confirmation from "components/common/confirmation";
import TabFormDashboardLayout from "components/layouts/tab/form";

import { getBetshopCurrency, saveBetshopCurrency, deleteBetshopCurrency } from "store/actions/dashboard/retail/betshops/currencyAndLimits.action";
import { getProjectAvailableCurrencies } from "store/actions/dashboard/projects/projects.action";

import currencyType from 'types/currency/currency.type';

import { numberTransform, binaryToFlags } from "utils/common";
import { favoriteBetsRepeatingIndexes, validateMax, validateMin, validatePosMax } from "utils/currency";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { FAVORITE_BETS } from 'constants/bet.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/project.constants'

/** Betshop Edit Page Currency and Limits Tab Component */
const CurrencyAndLimitsComponent = ({
    getBetshopCurrency,
    saveBetshopCurrency,
    deleteBetshopCurrency,
    isSaving,
    isLoading,
    currency,
    isAvailableCurrenciesLoading,
    availableCurrencies,
    getProjectAvailableCurrencies,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [resetConfirmationPopup, setResetConfirmationPopup] = useState(false);

    const availableProviders = binaryToFlags(Object.values(PROJECT_PROVIDER_TYPE), currency.retailProvider);
    const virtualSportEnabled = availableProviders.includes(PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS);

    /** Load betshop currency, load company available currencies */
    useEffect(() => {
        getBetshopCurrency(searchParams.id);
        getProjectAvailableCurrencies();
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            code: currency.code,
            decimalCount: currency.decimalCount,
            favoriteBets: currency.favoriteBets,
            betLimit: currency.betLimit,
        })
    }, [currency])

    /** Fires when form submitted
       * @function
       * @memberOf CurrencyAndLimitsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveBetshopCurrency({
                    id: searchParams.id,
                    favoriteBets: data.favoriteBets,
                    betLimit: data.betLimit,
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    /** Fires on "Reset to Company's values" button click
       * @function
       * @memberOf CurrencyAndLimitsComponent
   */
    const handleReset = () => {
        deleteBetshopCurrency(searchParams.id);
        setIsFormTouched(false);
        setResetConfirmationPopup(false);
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    ...(
                        virtualSportEnabled ? [
                            {
                                type: "link",
                                onClick: () => setResetConfirmationPopup(true),
                                text: t("backoffice.currencies.resetToDefaultValues"),
                                enabled: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }),
                                disabled: !currency.isOverride && !isFormTouched
                            }
                        ] : []
                    ),
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged(
                        { ...formValues },
                        {
                            retailFavoriteBets: currency.favoriteBets,
                            betLimits: currency.betLimit,
                            code: currency.code
                        }
                    ))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.betshops.defaultCurrency')} *`}
                                name="code"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                                className="rt--form-item-disabled"

                            >
                                <Select
                                    options={
                                        Object.keys(availableCurrencies).map(item => (
                                            { value: item.toUpperCase(), text: availableCurrencies[item] }
                                        ))
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.betshops.defaultCurrency')}`}
                                    loading={isAvailableCurrenciesLoading}
                                    disabled={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.currencies.decimalCount')}
                                name="decimalCount"
                                className="rt--form-item-disabled"
                            >
                                <Select
                                    options={[
                                        { value: 0, text: "0" },
                                        { value: 1, text: "0,1" },
                                        { value: 2, text: "0,12" },
                                        { value: 3, text: "0,123" },
                                        { value: 4, text: "0,1234" },
                                        { value: 5, text: "0,12345" },
                                        { value: 6, text: "0,123456" },
                                        { value: 7, text: "0,1234567" },
                                        { value: 8, text: "0,12345678" },
                                    ]}
                                    disabled={true}
                                    placeholder={`${t("backoffice.common.select")} ${t('backoffice.currencies.decimalCount')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        virtualSportEnabled && (
                            <Fragment>

                                <Divider className='rt--form-section-divider' />
                                <Row gutter={[16, 0]}>
                                    <Col span={24}>
                                        <Form.Item>
                                            {
                                                currency.isOverride ? (
                                                    <div className="rt--tag-status rt--flex rt--align-center rt--justify-center" data-state="success">
                                                        <span className="rt--title rt--font-bold-rt--font-normal">{t('backoffice.currencies.overriden')}</span>
                                                    </div>
                                                ) : (
                                                    <div className="rt--tag-status rt--tag-status-currency rt--flex rt--align-center rt--justify-center" data-state="error">
                                                        <span className="rt--title rt--font-bold-rt--font-normal">{t('backoffice.currencies.notOverriden')}</span>
                                                    </div>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <h4
                                    className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                                    {
                                        t('backoffice.currencies.betAmountLimits')
                                    }
                                </h4>
                                <Row gutter={[16, 0]}>
                                    <Col xs={24} sm={12} xl={6}>
                                        <Form.Item
                                            label={t('backoffice.currencies.minSingle')}
                                            name={[`betLimit`, 'singleMin']}
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { type: "number", min: 0.01, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return validateMin(value, getFieldValue('betLimit').singleMax);
                                                    }
                                                })
                                            ]}
                                            validateFirst
                                            className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.minSingle')}`}
                                        >
                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.minSingle')}`}
                                                onChange={() => {
                                                    setTimeout(() => {
                                                        if (getFieldValue(['betLimit', 'singleMax']) !== "") {
                                                            validateFields([['betLimit', 'singleMax']])
                                                        }
                                                        const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                                        validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                                    }, 0)
                                                }}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} xl={6}>
                                        <Form.Item
                                            label={t('backoffice.currencies.minMulti')}
                                            name={[`betLimit`, 'multiMin']}
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { type: "number", min: 0.01, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return validateMin(value, getFieldValue('betLimit').multiMax);
                                                    }
                                                })
                                            ]}
                                            validateFirst
                                            className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.minMulti')}`}
                                        >
                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.minMulti')}`}
                                                onChange={() => {
                                                    setTimeout(() => {
                                                        if (getFieldValue(['betLimit', 'multiMax']) !== "") {
                                                            validateFields([['betLimit', 'multiMax']])
                                                        }
                                                        const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                                        validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                                    }, 0)
                                                }}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 0]}>
                                    <Col xs={24} sm={12} xl={6}>
                                        <Form.Item
                                            label={t('backoffice.currencies.maxSingle')}
                                            name={[`betLimit`, 'singleMax']}
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { type: "number", min: 0.01, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return validateMax(getFieldValue('betLimit').singleMin, value, getFieldValue('betLimit').singlePossibleWinMax);
                                                    }
                                                })
                                            ]}
                                            validateFirst
                                            className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxSingle')}`}
                                        >
                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxSingle')}`}
                                                onChange={() => {
                                                    setTimeout(() => {
                                                        if (getFieldValue(['betLimit', 'singleMin']) !== "") {
                                                            validateFields([['betLimit', 'singleMin']])
                                                        }
                                                        if (getFieldValue(['betLimit', 'singlePossibleWinMax']) !== "") {
                                                            validateFields([['betLimit', 'singlePossibleWinMax']])
                                                        }

                                                        const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                                        validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                                    }, 0)
                                                }}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} xl={6}>
                                        <Form.Item
                                            label={t('backoffice.currencies.maxMulti')}
                                            name={[`betLimit`, 'multiMax']}
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { type: "number", min: 0.01, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return validateMax(getFieldValue('betLimit').multiMin, value, getFieldValue('betLimit').multiPossibleWinMax);
                                                    }
                                                })
                                            ]}
                                            validateFirst
                                            className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxMulti')}`}
                                        >
                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxMulti')}`}
                                                onChange={() => {
                                                    setTimeout(() => {
                                                        if (getFieldValue(['betLimit', 'multiMin']) !== "") {
                                                            validateFields([['betLimit', 'multiMin']])
                                                        }
                                                        if (getFieldValue(['betLimit', 'multiPossibleWinMax']) !== "") {
                                                            validateFields([['betLimit', 'multiPossibleWinMax']])
                                                        }

                                                        const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                                        validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                                    }, 0)
                                                }}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
                                            />

                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row gutter={[16, 0]}>
                                    <Col xs={24} sm={12} xl={6}>
                                        <Form.Item
                                            label={t('backoffice.currencies.maxPossibleWinSingle')}
                                            name={[`betLimit`, 'singlePossibleWinMax']}
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { type: "number", min: 0.01, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return validatePosMax(getFieldValue('betLimit').singleMax, value);
                                                    }
                                                })
                                            ]}
                                            validateFirst
                                            className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxPossibleWinSingle')}`}
                                        >
                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxPossibleWinSingle')}`}
                                                onChange={() => {
                                                    setTimeout(() => {
                                                        if (getFieldValue(['betLimit', 'singleMax']) !== "") {
                                                            validateFields([['betLimit', 'singleMax']])
                                                        }
                                                    }, 0)
                                                }}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} xl={6}>
                                        <Form.Item
                                            label={t('backoffice.currencies.maxPossibleWinMulti')}
                                            name={[`betLimit`, 'multiPossibleWinMax']}
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { type: "number", min: 0.01, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return validatePosMax(getFieldValue('betLimit').multiMax, value);
                                                    }
                                                })
                                            ]}
                                            validateFirst
                                            className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxPossibleWinMulti')}`}
                                        >
                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxPossibleWinMulti')}`}
                                                onChange={() => {
                                                    setTimeout(() => {
                                                        if (getFieldValue(['betLimit', 'multiMax']) !== "") {
                                                            validateFields([['betLimit', 'multiMax']])
                                                        }
                                                    }, 0)
                                                }}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Divider className='rt--form-section-divider' />
                                <h4
                                    className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                                    {
                                        t('backoffice.currencies.favoriteBets')
                                    }
                                </h4>
                                <Row gutter={[16, 0]}>
                                    <Col xs={24} sm={12}>
                                        <Row gutter={[16, 0]}>
                                            {
                                                [0, 1, 2, 3, 4, 5, 6, 7].map(i => (
                                                    <Col xs={12} sm={12} xl={6} key={i}>
                                                        <Form.Item
                                                            name={["favoriteBets", i]}
                                                            rules={[
                                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        if (value.length > 20 || (
                                                                            (value !== "" && (isNaN(value) || Number(value) < 0.01)) &&
                                                                            !FAVORITE_BETS.includes(value)
                                                                        )) {
                                                                            return Promise.reject(t('backoffice.validation.fieldInvalid'))
                                                                        } else if (favoriteBetsRepeatingIndexes(getFieldValue("favoriteBets")).includes(i)) {
                                                                            return Promise.reject(t("backoffice.validation.favoriteBetsNoDuplicate"))
                                                                        } else if (!FAVORITE_BETS.includes(value) && value !== "") {
                                                                            let isMinValid = false, isMaxValid = false;
                                                                            if (getFieldValue('betLimit').multiMax === "" || getFieldValue('betLimit').singleMax === "" || Number(value) <= Number(getFieldValue('betLimit').multiMax) || Number(value) <= Number(getFieldValue('betLimit').singleMax))
                                                                                isMaxValid = true;
                                                                            if (getFieldValue('betLimit').multiMin === "" || getFieldValue('betLimit').singleMin === "" || Number(value) >= Number(getFieldValue('betLimit').multiMin) || Number(value) >= Number(getFieldValue('betLimit').singleMin))
                                                                                isMinValid = true;

                                                                            if (!isMaxValid) {
                                                                                return Promise.reject(t('backoffice.validation.favoriteBetsMax'));
                                                                            } else if (!isMinValid) {
                                                                                return Promise.reject(t('backoffice.validation.favoriteBetsMin'));
                                                                            }
                                                                        }
                                                                        return Promise.resolve();
                                                                    }
                                                                })
                                                            ]}
                                                            className={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : ""}
                                                        >
                                                            <AutoComplete
                                                                filterOption={() => true}
                                                                options={FAVORITE_BETS.map(value => ({ value }))}
                                                                onChange={(a, b, c) => {
                                                                    setTimeout(() => {
                                                                        const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                                                        validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                                                    }, 0)
                                                                }}
                                                                showArrow={true}
                                                                suffixIcon={(<i className="icon-down" />)}
                                                                getPopupContainer={trigger => trigger.parentElement}
                                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
                                                            >
                                                            </AutoComplete>
                                                        </Form.Item>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </Col>

                                </Row>
                            </Fragment>
                        )
                    }


                </Form>
                {
                    resetConfirmationPopup && (
                        <Confirmation
                            title={t('backoffice.currencies.resetToDefaultValues')}
                            message={t('backoffice.currencies.resetToDefaultValuesMessage')}
                            onOk={handleReset}
                            onCancel={() => setResetConfirmationPopup(false)}
                            isVisible={true}
                        />
                    )
                }
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** CurrencyAndLimitsComponent propTypes
    * PropTypes
*/
CurrencyAndLimitsComponent.propTypes = {
    /** Redux action to get betshop currency */
    getBetshopCurrency: PropTypes.func,
    /** Redux action to save betshop currency */
    saveBetshopCurrency: PropTypes.func,
    /** Redux action to reset betshop currency to partner currency */
    deleteBetshopCurrency: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, represents the betshop currency  */
    currency: currencyType,
    /** Redux action to get available currencies of project */
    getProjectAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the object of available currencies of company */
    availableCurrencies: PropTypes.object,
    /** Redux state property, is true when loading available currencies of company */
    isAvailableCurrenciesLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        saveBetshopCurrency: currency => {
            dispatch(saveBetshopCurrency(currency));
        },

        getBetshopCurrency: id => {
            dispatch(getBetshopCurrency(id))
        },

        deleteBetshopCurrency: id => {
            dispatch(deleteBetshopCurrency(id))
        },

        getProjectAvailableCurrencies: () => {
            dispatch(getProjectAvailableCurrencies())
        }
    }
)

const mapStateToProps = state => {
    return {
        currency: state.betshops.edit.currency,
        isSaving: state.betshops.isSaving,
        isLoading: state.betshops.isLoading,
        availableCurrencies: state.projects.availableCurrencies.availableCurrencies,
        isAvailableCurrenciesLoading: state.projects.availableCurrencies.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyAndLimitsComponent)