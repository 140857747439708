//#region react
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//#endregion

//#region actions
import {
    getPaymentRequests,
    setPaymentRequestsSorting,
    setPaymentRequestsFilters,
} from 'store/actions/dashboard/payments/paymentRequests/paymentRequests.action';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useProjectType from 'hooks/useProjectType';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import TransactionDetails from '../../transactionDetails';
import Filters from '../filters.component';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { objectHasDefinedValue } from 'utils/common';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import PATHS from "constants/path.constants";
import { PAYMENT_METHODS, WIDGET_TRANSACTION_TYPE } from "constants/project.constants";
import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { PAYMENT_REQUESTS } from 'constants/pageName.constants';
import { getTableColumns } from '../columns';
//#endregion

//#region hooks
import { useTransfer, TRANSFER_ENTITY_TYPE, TRANSFER_SUCCESS_EVENT_NAME } from 'components/common/transfer';
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import paymentRequestType from "types/paymentRequest/paymentRequest.type"
//#endregion

const TABLE_ROWS_UNIQUE_KEY = "transactionId";

const mapUserInfo = userInfo => {
    if (!userInfo) {
        return userInfo;
    }

    const info = { ...userInfo };
    delete info["Amount"];

    return info
}

/** Payment Requests page component */
const PaymentRequestsComponent = ({
    isLoading,
    getPaymentRequests,
    paymentRequests,
    setPaymentRequestsSorting,
    sorting,
    filters,
    globalProjectId,
    setPaymentRequestsFilters,
    type
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { show: showTransfer} = useTransfer();
    
    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PAYMENT_REQUESTS });

    const { hasBankTransfer, hasEpos } = useProjectType(); 

    /** Leave from page if global Payment Type is not bank transfer */
    useEffect(() => {
        if(!hasBankTransfer && !hasEpos){
            navigate(PATHS.DEFAULT);
        }
    }, [hasBankTransfer, hasEpos])

    const expandDisabledRowKeys = (
        paymentRequests
            .filter(requestData => !objectHasDefinedValue(mapUserInfo(requestData.userInfo)) || requestData.paymentType === PAYMENT_METHODS.EPOS)
            .map(requestData => requestData[TABLE_ROWS_UNIQUE_KEY])
    );

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const permissionResource = type === WIDGET_TRANSACTION_TYPE.DEPOSIT ? PERMISSION_RESOURCE.PAYMENT_DEPOSIT_TRANSACTION_REQUEST : PERMISSION_RESOURCE.PAYMENT_WITHDRAW_TRANSACTION_REQUEST;

    const hasDataExportPermission = hasPermission({
        resource: permissionResource,
        action: PERMISSION_ACTION.EXPORT,
    })

    const hasPaymentModifyPermission = hasPermission({
        resource: permissionResource,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setPaymentRequestsFilters({
            ...filters,
            userNameOrId: value
        }, type)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                type
            }
        });
    }, [includedColumns])
    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasPaymentModifyPermission) {
        tableRowActions.push(
            {
                title: t('backoffice.common.reject'),
                icon: "icon-close",
                disabled: record => !record.isEditable,
                onClick: record => {
                    showTransfer({
                        entityType: TRANSFER_ENTITY_TYPE.TRANSACTION,
                        transactionType: WALLET_TRANSACTION_TYPE.REJECT_TRANSACTION_REQUEST,
                        group: record.transactionType,
                        additionalData: {
                            transactionId: record.transactionId,
                            widgetType: type
                        }
                    })
                },
            },
            {
                title: t('backoffice.common.confirm'),
                icon: "icon-ok",
                disabled: record => !record.isEditable || record.paymentType === PAYMENT_METHODS.EPOS,
                onClick: record => {
                    showTransfer({
                        entityType: TRANSFER_ENTITY_TYPE.TRANSACTION,
                        transactionType: WALLET_TRANSACTION_TYPE.CONFIRM_TRANSACTION_REQUEST,
                        group: record.transactionType,
                        additionalData: {
                            transactionId: record.transactionId,
                            amount: record.amount,
                            widgetType: type
                        }
                    })
                },
            },
        )
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters type={type} />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.paymentRequests') }]
        },
    }

    headerPartsData.search = {
        placeholder: t("backoffice.payments.agentUsernameID"),
        loadFn: () => getPaymentRequests(type),
        autoSuggestion: {
            type: AUTOSUGGESTION_TYPE.AGENT,
            dependencies: [globalProjectId]
        },
        onSearch: handleSearchChange,
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.paymentRequests"),
            columns: columnsForExport,
            url: type === WIDGET_TRANSACTION_TYPE.DEPOSIT ? ApiUrls.EXPORT_PAYMENT_DEPOSIT_REQUESTS : ApiUrls.EXPORT_PAYMENT_WITHDRAW_REQUESTS,
            filters: filters,
        }
    }

    //#endregion

    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                if(
                    data.transactionType === WALLET_TRANSACTION_TYPE.CONFIRM_TRANSACTION_REQUEST || 
                    data.transactionType === WALLET_TRANSACTION_TYPE.REJECT_TRANSACTION_REQUEST
                ){
                    getPaymentRequests(data?.additionalData?.widgetType)
                }
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [])

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={paymentRequests}
                uniqueKey={TABLE_ROWS_UNIQUE_KEY}
                loadFn={() => getPaymentRequests(type)}
                updateProps={[globalProjectId]}
                sorting={sorting}
                setSortingFn={sorting => setPaymentRequestsSorting(sorting, type)}
                noPagination={true}
                enableReload={true}
                filters={filters}
                setFiltersFn={filters => setPaymentRequestsFilters(filters, type)}
                actions={tableRowActions}
                expandable={{
                    title: t("backoffice.payments.PlayerInfo"),
                    disabled: expandDisabledRowKeys,
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    details: record => Boolean(record.userInfo) && ({
                        data: record.userInfo,
                        content: (
                            <TransactionDetails
                                userInfo={record.userInfo}
                                widgetTransactionType={record.transactionType}
                                bankId={record.bankId}
                                paymentType={PAYMENT_METHODS.BANK_TRANSFER}
                            />
                        ),
                    }),
                }}
            />
           
        </TabTableDashboardLayout>
    );
}

/** PaymentRequestsComponent propTypes
 * PropTypes
*/
PaymentRequestsComponent.propTypes = {
    /** Redux action to get payment requests */
    getPaymentRequests: PropTypes.func,
    /** Redux state property, is true when loading bank transaction requests */
    isLoading: PropTypes.bool,
    /** Redux state property, transaction requests sorting details */
    sorting: sortingType,
    /** Redux state property, transaction requests filters */
    filters: PropTypes.object,
    /** Redux state property, transaction requests total count */
    total: PropTypes.number,
    /** Redux state property, represents the array of transaction requests */
    paymentRequests: PropTypes.arrayOf(paymentRequestType),
    /** Redux action to set transaction sorting details */
    setPaymentRequestsSorting: PropTypes.func,
    /** Redux action to set transaction filters */
    setPaymentRequestsFilters: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Transfer type */
    type: PropTypes.oneOf(Object.values(WIDGET_TRANSACTION_TYPE))
};

const mapDispatchToProps = dispatch => ({
    getPaymentRequests: type => {
        dispatch(getPaymentRequests(type));
    },
    setPaymentRequestsSorting: (sorting, type) => {
        dispatch(setPaymentRequestsSorting(sorting, type));
    },
    setPaymentRequestsFilters: (filters, type, keepPage) => {
        dispatch(setPaymentRequestsFilters(filters, type, keepPage));
    },
});

const mapStateToProps = (state, props) => {
    const type = props.type;
    return {
        isLoading: state.paymentRequests.isLoading,
        paymentRequests: state.paymentRequests[type].paymentRequests,
        sorting: state.paymentRequests[type].sorting,
        filters: state.paymentRequests[type].filters,
        globalProjectId: state.common.globalProjectId,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentRequestsComponent);