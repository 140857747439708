import React from 'react';

import PropTypes from "prop-types";

import {classNames} from "utils/common";

const RadioOption = ({value, selected, onClick, children}) => {
    const handleClick = () => {
        onClick(value);
    }

    return (
        <button className={classNames("rt--radio-buttons-option", selected && "rt--radio-buttons-option__selected")} onClick={handleClick}>
            {children}
        </button>
    );
};

RadioOption.propTypes = {
    value: PropTypes.any,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node
}

export default RadioOption;
