import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { TICKET_GENERAL_DETAILS, TICKET_BET_DETAILS, TICKET_PREVIEW_VALUES } from 'constants/ticket.constants';
import { binaryToFlags } from 'utils/common';
import { getUser } from 'utils/auth';

import payoutTicketType from "types/payoutTicket/payoutTicket.type";

import barCodeImage from 'assets/images/barcode.svg';
import retailLogoImage from 'assets/images/logo-retail-blue.svg';

/** Payout Ticket Preview Component */
const PayoutTicketPreviewComponent = ({
    payoutTicket,
    currentPayoutTicket
}) => {

    const { t } = useTranslation();

    const companyLongId = getUser()?.companyLongId ?? null;
    const projectLongId = getUser()?.projectLongId ?? null;

    const generalDetails = binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), currentPayoutTicket.generalDetails);
    const betDetails = binaryToFlags(Object.values(TICKET_BET_DETAILS), currentPayoutTicket.betDetails);
    
    const logo = payoutTicket.ticketLogoId ? `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${payoutTicket.ticketLogoId}_ticket_logo.png` : null

    return (
        <div id="rt--payout-ticket-preview" className="rt--print-wrapper rt--pt-20 rt--pb-20 rt--pl-16 rt--pr-16">
            <div className="rt--print">
                <div className="rt--print-header rt--justify-center rt--flex rt--mb-16">
                    {
                        logo ?
                            <img src={logo} alt="logo" /> :
                            <img src={retailLogoImage} alt="logo" />
                    }
                </div>
                {
                    (
                        generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_NAME) ||
                        generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS) ||
                        generalDetails.includes(TICKET_GENERAL_DETAILS.TICKET_ID) ||
                        generalDetails.includes(TICKET_GENERAL_DETAILS.CASHIER_USERNAME)
                    ) && (
                        <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                            {
                                generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_NAME) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.betshopName')}</span>
                                        <span className='rt--title rt--font-normal rt--font-regular'>{TICKET_PREVIEW_VALUES.BETSHOP_NAME}</span>
                                    </div>
                                )
                            }
                            {
                                generalDetails.includes(TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.betshopAddress')}</span>
                                        <span className='rt--title rt--font-normal rt--font-regular'>{TICKET_PREVIEW_VALUES.BETSHOP_ADDRESS}</span>
                                    </div>
                                )
                            }
                            {
                                generalDetails.includes(TICKET_GENERAL_DETAILS.CASHIER_USERNAME) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.cashier')}</span>
                                        <span className='rt--title rt--font-normal rt--font-regular'>{TICKET_PREVIEW_VALUES.CASHIER_USERNAME}</span>
                                    </div>
                                )
                            }
                            {
                                generalDetails.includes(TICKET_GENERAL_DETAILS.TICKET_ID) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.ticketId')}</span>
                                        <span className='rt--title rt--font-normal rt--font-regular'>{TICKET_PREVIEW_VALUES.TICKET_ID}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {
                    (
                        betDetails.includes(TICKET_BET_DETAILS.BET_AMOUNT) ||
                        betDetails.includes(TICKET_BET_DETAILS.BET_TYPE) ||
                        betDetails.includes(TICKET_BET_DETAILS.FACTOR) ||
                        betDetails.includes(TICKET_BET_DETAILS.POSSIBLE_WIN) ||
                        betDetails.includes(TICKET_BET_DETAILS.WIN_AMOUNT) ||
                        betDetails.includes(TICKET_BET_DETAILS.CALCULATION_DATE) ||
                        betDetails.includes(TICKET_BET_DETAILS.BET_STATUS)
                    ) && (
                        <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                            <div className="rt--print-info-bet">
                                {
                                    betDetails.includes(TICKET_BET_DETAILS.BET_AMOUNT) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.betAmount')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{`${TICKET_PREVIEW_VALUES.BET_AMOUNT} ${TICKET_PREVIEW_VALUES.CURRENCY}`}</span>
                                        </div>
                                    )
                                }
                                {
                                    betDetails.includes(TICKET_BET_DETAILS.BET_TYPE) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.betType')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{TICKET_PREVIEW_VALUES.BET_TYPE}</span>
                                        </div>
                                    )
                                }
                                {
                                    betDetails.includes(TICKET_BET_DETAILS.FACTOR) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.factor')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{TICKET_PREVIEW_VALUES.FACTOR}</span>
                                        </div>
                                    )
                                }

                                {
                                    betDetails.includes(TICKET_BET_DETAILS.POSSIBLE_WIN) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.possibleWin')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{`${TICKET_PREVIEW_VALUES.POSSIBLE_WIN} ${TICKET_PREVIEW_VALUES.CURRENCY}`}</span>
                                        </div>
                                    )
                                }

                                {
                                    betDetails.includes(TICKET_BET_DETAILS.WIN_AMOUNT) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.winAmount')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{`${TICKET_PREVIEW_VALUES.WIN_AMOUNT} ${TICKET_PREVIEW_VALUES.CURRENCY}`}</span>
                                        </div>
                                    )
                                }
                                {
                                    betDetails.includes(TICKET_BET_DETAILS.CALCULATION_DATE) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.calculationDate')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{TICKET_PREVIEW_VALUES.CALCULATION_DATE}</span>
                                        </div>
                                    )
                                }
                                
                                {
                                    betDetails.includes(TICKET_BET_DETAILS.BET_STATUS) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.betStatus')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{TICKET_PREVIEW_VALUES.BET_STATUS}</span>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    )
                }

                {
                    (
                        betDetails.includes(TICKET_BET_DETAILS.PAYOUT_TIME) ||
                        betDetails.includes(TICKET_BET_DETAILS.PAYOUT_BY)
                    ) && (
                        <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                            {
                                betDetails.includes(TICKET_BET_DETAILS.PAYOUT_TIME) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.payoutTime')}</span>
                                        <b className='rt--title rt--font-normal rt--font-bold'>{TICKET_PREVIEW_VALUES.PAYOUT_TIME}</b>
                                    </div>
                                )
                            }
                            {
                                betDetails.includes(TICKET_BET_DETAILS.PAYOUT_BY) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.payoutticket.payoutBy')}</span>
                                        <b className='rt--title rt--font-normal rt--font-bold'>{TICKET_PREVIEW_VALUES.PAYOUT_BY}</b>
                                    </div>
                                )
                            }
                        </div>
                    )
                }


                <div className="rt--print-footer">
                    <div className="rt--print-footer-inner">
                        <div className="rt--print-footer-barcode rt--mb-16">
                            {
                                currentPayoutTicket.enableBarcodePrinting && (
                                    <Fragment>
                                        <img src={barCodeImage} alt="barcode" />
                                    </Fragment>
                                )
                            }
                        </div>
                        {
                            currentPayoutTicket.customText && (
                                <span className="rt--title rt--font-normal rt--font-regular rt--print-footer-custom-text">{currentPayoutTicket.customText}</span>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

/** Ticket propTypes
    * PropTypes
*/
PayoutTicketPreviewComponent.propTypes = {
    /** Redux state, represents the current editing payout ticket(ticket in preview)  */
    currentPayoutTicket: payoutTicketType,
    /** Redux state, represents the current loaded payout ticket(ticket in server)  */
    payoutTicket: payoutTicketType
}

const mapStateToProps = state => {
    return {
        currentPayoutTicket: state.payoutTicket.currentPayoutTicket,
        payoutTicket: state.payoutTicket.payoutTicket
    }
}

export default connect(
    mapStateToProps,
    null
)(
    PayoutTicketPreviewComponent
);