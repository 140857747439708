import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { getAgentWallets } from "store/actions/dashboard/wallet/agentWallet.action";

import { WALLET_TYPE, WALLET_OWNER_TYPE } from "constants/wallet.constants";

import useFormat from 'hooks/useFormat';

import { isFranchisingMode } from "utils/common";

import walletType from 'types/wallet/wallet.type';

import { TRANSFER_SUCCESS_EVENT_NAME } from 'components/common/transfer';

/** Agent Edit Page Agent wallets Component */
const AgentWalletsComponent = ({
    getAgentWallets,
    agentWallets,
    ownerType
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const { formatAmount } = useFormat();

    /** Load agent wallets */
    useEffect(() => {
        getAgentWallets(searchParams.id, ownerType);
    }, [])

    const allCurrencies = [...new Set(agentWallets.map(w => w.currencyCode))]

    /** Function to get amount of wallet
       * @function
       * @param {number} walletType
       * @param {string} currency
       * @returns {string}
       * @memberOf SessionsComponent
    */
    const getAmount = (walletType, currency) => {
        const wallet = agentWallets.find(w => currency === w.currencyCode && w.type === walletType);
        let amount = wallet?.amount ?? 0;

        return formatAmount( amount, currency)
    }

    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                getAgentWallets(searchParams.id, ownerType);
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [])

    return agentWallets.length > 0 ? (
        <div className='rt--flex rt--align-center rt--overflow-x rt--no-scrollbar'>
            {
                allCurrencies.map(cur => (
                    <div
                        key={cur}
                        className="rt--widget-wallet rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8 rt--mr-16"
                    >
                        <div className='rt--flex rt--align-center rt--justify-center rt--widget-wallet-inner'>
                            <span className='rt--title rt--font-extra-bold rt--font-big rt--pr-12 rt--widget-wallet-title'>{cur}</span>
                            {
                                (ownerType !== WALLET_OWNER_TYPE.AGENT || !isFranchisingMode()) &&
                                (
                                    <div className='rt--flex rt--flex-col rt--align-start rt--ml-16'>
                                        <b className='rt--title rt--font-regular rt--font-small'>{t("backoffice.common.balance")}:</b>
                                        <span className='rt--title rt--font-bold rt--font-big'>
                                            {
                                                getAmount(WALLET_TYPE.BALANCE, cur)
                                            }
                                        </span>
                                    </div>
                                )
                            }


                            {
                                (
                                    ownerType === WALLET_OWNER_TYPE.AGENT ||
                                    ownerType === WALLET_OWNER_TYPE.AGENT_PLAYER
                                ) && (
                                    <Fragment>
                                        <div className='rt--flex rt--flex-col rt--align-start rt--ml-16'>
                                            <b className='rt--title rt--font-regular rt--font-small'>{t("backoffice.common.credit")}:</b>
                                            <span className='rt--title rt--font-bold rt--font-big'>
                                                {
                                                    getAmount(WALLET_TYPE.CREDIT, cur)                                                    
                                                }
                                            </span>
                                        </div>
                                        {
                                            (
                                                ownerType === WALLET_OWNER_TYPE.AGENT
                                            ) && (
                                                <div className='rt--flex rt--flex-col rt--align-start rt--ml-16'>
                                                    <b className='rt--title rt--font-regular rt--font-small'>{t("backoffice.common.creditLine")}:</b>
                                                    <span className='rt--title rt--font-bold rt--font-big'>
                                                        {
                                                            getAmount(WALLET_TYPE.CREDIT_LINE, cur)   
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        }

                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                ))

            }
        </div>
    ) : <Fragment />
}

/** AgentWalletsComponent propTypes
    * PropTypes
*/
AgentWalletsComponent.propTypes = {
    /** Redux action to get agent wallets */
    getAgentWallets: PropTypes.func,
    /** Redux state, represents the agent wallets of current editing agent  */
    agentWallets: PropTypes.arrayOf(walletType),
    /** Wallet owner type */
    ownerType: PropTypes.oneOf(Object.values(WALLET_OWNER_TYPE))
}

const mapDispatchToProps = dispatch => (
    {
        getAgentWallets: (id, ownerType) => {
            dispatch(getAgentWallets(id, ownerType));
        }
    }
)

const mapStateToProps = state => {
    return {
        agentWallets: state.wallet.agentWallet.wallets
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentWalletsComponent)