import React, {memo} from 'react';
import {toLowerCaseFirstLetter} from "utils/common";
import {Checkbox, Form} from "antd";
import Tooltip from "components/common/tooltip";
import {makeName} from "components/common/permissions/helpers/makeName";
import {useTranslation} from "react-i18next";
import {mapping} from "components/common/permissions/constants";
import {isCheckedAll} from "components/common/permissions/helpers/isCheckedAll";
import {isUnCheckedAll} from "components/common/permissions/helpers/isUnCheckedAll";
import {hasEditablePermission} from "components/common/permissions/helpers/hasEditablePermission";

const withWrapper = (WrappedComponent) => ({formInstance, permissions, fieldName, ...props}) => {
    const { getFieldValue, getFieldsValue } = formInstance;

    Form.useWatch(fieldName ? [fieldName, props.permission.resource] : [props.permission.resource], formInstance);

    const isAllChecked = props.editable ? isCheckedAll({resource: props.permission.resource, fieldName, getFieldsValue, getFieldValue}) : false;
    const isAllUnChecked = props.editable ? isUnCheckedAll({resource: props.permission.resource, fieldName, getFieldsValue, getFieldValue}) : false;
    const hasPermission = props.editable ? hasEditablePermission(permissions, props.permission.resource) : false;

    return (
        <WrappedComponent
            {...props}
            isAllChecked={isAllChecked}
            isAllUnChecked={isAllUnChecked}
            hasPermission={hasPermission}
        />
    )
}

const CollapseHeader = ({
    editable,
    disabled,
    permission: {resource},
    onCheckAll,
    onUnCheckAll,
    isAllChecked,
    isAllUnChecked,
    hasPermission
}) => {
    const { t } = useTranslation();

    return (
        <div
            className="rt--permissions-collapse-panel-header rt--flex rt--align-center rt--justify-between">
            <div
                className="rt--permissions-collapse-panel-header-inner rt--flex rt--align-center">
                <i className={mapping[toLowerCaseFirstLetter(resource)]?.icon + " rt--font-bigest"}/>
                <span
                    className='rt--title rt--font-normal rt--font-bold rt--pl-4'>{makeName(resource, t)}</span>
            </div>
            {
                editable && !disabled &&
                (
                    <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                        <Form.Item
                            className="rt--form-item-without-margin rt--permissions-table-checkbox"
                        >
                            <Tooltip
                                title={isAllChecked ? t("backoffice.permissions.deSelectAll") : t("backoffice.permissions.selectAll")}
                            >
                                <Checkbox
                                    checked={isAllChecked}
                                    indeterminate={!isAllUnChecked && !isAllChecked}
                                    onClick={e => !isAllChecked ? onCheckAll(resource, null, e) : onUnCheckAll(resource, null, e)}
                                    disabled={!hasPermission}
                                />
                            </Tooltip>
                        </Form.Item>
                    </div>
                )
            }
        </div>
    );
};

export default withWrapper(memo(CollapseHeader));
