import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { setNotificationSound } from 'store/actions/dashboard/notifications/notifications.action';

import audioFile from 'assets/media/notification.mp3';
import {NOTIFICATION_SOUND_TYPES} from "../../../constants/notification.constants";

/** Notification sound Component */
const NotificationSound = ({
    setNotificationSound,
    notificationSound
}) => {
    const isMuted = notificationSound === NOTIFICATION_SOUND_TYPES.SILENT

    useEffect(() => {
        if (isMuted) {
            setNotificationSound(false);
        }
    }, [isMuted]);

    if (isMuted) {
        return null;
    }

    return (
        <audio autoPlay src={audioFile} onEnded={() => setNotificationSound(false)}/>
    )
}

/** NotificationSound propTypes
    * PropTypes
*/
NotificationSound.propTypes = {
    /** Redux action for notification sound */
    setNotificationSound: PropTypes.func,
    /** Property for notification sound setting */
    notificationSound: PropTypes.number
}

const mapDispatchToProps = dispatch => (
    {
        setNotificationSound: sound => {
            dispatch(setNotificationSound(sound))
        }
    }
)

export default connect(null, mapDispatchToProps)(NotificationSound);
