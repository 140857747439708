import i18n from "translations/config";

export const getTableColumns = (additionalProps) => {
    const {
        havePermissionForEdit,
        onTranslationFieldsChange,
    } = additionalProps;

    return [
        {
            title: "backoffice.payments.language",
            dataIndex: "languageCode",
            width: 180,
            mobileLevel: 1,
        },
        {
            title: "backoffice.payments.bankName",
            dataIndex: "name",
            maxLength: 100,
            mobileLevel: 2,
            editable: havePermissionForEdit,
            editableLabel: () => i18n.t("backoffice.payments.bankName"),
            editableTitle: () => i18n.t("backoffice.payments.language"),
            onEdit: (value, record) => {
                onTranslationFieldsChange(
                    value,
                    record.languageCode,
                    record.nameKey,
                )
            },
        }
    ];
}