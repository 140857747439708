import PATHS from "NEW/src/core/api/paths";
import { ENVIRONMENT_TYPE_PARAM_NAME } from "NEW/src/core/data/constants/environment";
import { httpService } from "NEW/src/core/services/httpService";
import {getEnvironment} from "utils/common";

export const systemLanguageService = {
	getSystemLanguages(signal) {
		return httpService.get({
			path: PATHS.GET_SYSTEM_AVAILABLE_PUBLISHED_LANGUAGES,
			signal,
			configs: {
				headers: {
					[ENVIRONMENT_TYPE_PARAM_NAME]: getEnvironment(),
				},
			},
		});
	},
};
