/** Auth */

export const AUTHENTICATE_ACTION_BEFORE = "AUTHENTICATE_ACTION_BEFORE";
export const AUTHENTICATE_ACTION_FINISH = "AUTHENTICATE_ACTION_FINISH";
export const AUTHENTICATE_ACTION_SET_EXPIRE = "AUTHENTICATE_ACTION_SET_EXPIRE";
export const AUTHENTICATE_ACTION_SET_QRBASE64 = "AUTHENTICATE_ACTION_SET_QRBASE64";
export const AUTHENTICATE_ACTION_SET_WS_TOKEN = "AUTHENTICATE_ACTION_SET_WS_TOKEN";
export const AUTHENTICATE_ACTION_SET_LOCK_DATA = "AUTHENTICATE_ACTION_SET_LOCK_DATA";
export const SET_LAST_LOGINED_USERNAME = "SET_LAST_LOGINED_USERNAME";
export const SET_PASSWORD_SETTINGS_ACTION_BEFORE = "SET_PASSWORD_SETTINGS_ACTION_BEFORE";
export const SET_PASSWORD_SETTINGS_ACTION_FINISH = "SET_PASSWORD_SETTINGS_ACTION_FINISH";
export const SET_PASSWORD_SETTINGS = "SET_PASSWORD_SETTINGS";
export const SET_RESET_PASSWORD_ACTION_BEFORE = "SET_RESET_PASSWORD_ACTION_BEFORE";
export const SET_RESET_PASSWORD_ACTION_FINISH = "SET_RESET_PASSWORD_ACTION_FINISH";
export const SET_ACCOUNTS_ACTION_BEFORE = "SET_ACCOUNTS_ACTION_BEFORE";
export const SET_ACCOUNTS_ACTION_FINISH = "SET_ACCOUNTS_ACTION_FINISH";
export const SET_ACCOUNTS_SAVE_ACTION_BEFORE = "SET_ACCOUNTS_SAVE_ACTION_BEFORE";
export const SET_ACCOUNTS_SAVE_ACTION_FINISH = "SET_ACCOUNTS_SAVE_ACTION_FINISH";
export const SET_ACCOUNT_PROJECT_ACCESS = "SET_ACCOUNT_PROJECT_ACCESS";

/** Common */

export const SET_GLOBAL_COMPANY_ID = "SET_GLOBAL_COMPANY_ID";
export const SET_GLOBAL_PROJECT_ID = "SET_GLOBAL_PROJECT_ID";
export const SET_GLOBAL_PROJECT_TYPE = "SET_GLOBAL_PROJECT_TYPE";
export const SET_GLOBAL_PROJECT_MODE = "SET_GLOBAL_PROJECT_MODE";
export const SET_GLOBAL_PAYMENT_TYPE = "SET_GLOBAL_PAYMENT_TYPE";
export const SET_GLOBAL_PLAT_VERSION = "SET_GLOBAL_PLAT_VERSION";

export const SET_COMPANY_AVAILABLE_LANGUAGES_BEFORE = "SET_COMPANY_AVAILABLE_LANGUAGES_BEFORE";
export const SET_COMPANY_AVAILABLE_LANGUAGES_FINISH = "SET_COMPANY_AVAILABLE_LANGUAGES_FINISH";
export const SET_COMPANY_AVAILABLE_LANGUAGES = "SET_COMPANY_AVAILABLE_LANGUAGES";

export const SET_GAME_PROVIDERS = "SET_GAME_PROVIDERS";

/** Published Languages */

export const SET_PUBLISH_LANGUAGES_ACTION_BEFORE = "SET_PUBLISH_LANGUAGES_ACTION_BEFORE";
export const SET_PUBLISH_LANGUAGES_ACTION_FINISH = "SET_PUBLISH_LANGUAGES_ACTION_FINISH";
export const SET_PUBLISH_LANGUAGES = "SET_PUBLISH_LANGUAGES";
export const SET_TRANSLATIONS_LOADED = "SET_TRANSLATIONS_LOADED";

/** Companies */

export const SET_COMPANIES_ACTION_BEFORE = "SET_COMPANIES_ACTION_BEFORE";
export const SET_COMPANIES_ACTION_FINISH = "SET_COMPANIES_ACTION_FINISH";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_COMPANIES_SORTING = "SET_COMPANIES_SORTING";
export const SET_COMPANIES_FILTERS = "SET_COMPANIES_FILTERS";
export const SET_COMPANIES_SAVE_ACTION_BEFORE = "SET_COMPANIES_SAVE_ACTION_BEFORE";
export const SET_COMPANIES_SAVE_ACTION_FINISH = "SET_COMPANIES_SAVE_ACTION_FINISH";
export const SET_COMPANY_GENERAL_INFO = "SET_COMPANY_GENERAL_INFO";
export const SET_COMPANY_LANGUAGES = "SET_COMPANY_LANGUAGES";

export const SET_COMPANY_PASSWORD_SETTINGS = "SET_COMPANY_PASSWORD_SETTINGS";
export const SET_COMPANY_TOKEN_SETTINGS = "SET_COMPANY_TOKEN_SETTINGS";
export const SET_COMPANY_AUTHENTICATION_SETTINGS = "SET_COMPANY_AUTHENTICATION_SETTINGS";

/** Agents */

export const SET_AGENTS_ACTION_BEFORE = "SET_AGENTS_ACTION_BEFORE";
export const SET_AGENTS_ACTION_FINISH = "SET_AGENTS_ACTION_FINISH";
export const SET_AGENTS_SAVE_ACTION_BEFORE = "SET_AGENTS_SAVE_ACTION_BEFORE";
export const SET_AGENTS_SAVE_ACTION_FINISH = "SET_AGENTS_SAVE_ACTION_FINISH";
export const SET_AGENTS = "SET_AGENTS";
export const SET_AGENTS_SORTING = "SET_AGENTS_SORTING";
export const SET_AGENTS_FILTERS = "SET_AGENTS_FILTERS";
export const SET_AGENT_SUB_AGENTS_ACTION_BEFORE = "SET_AGENT_SUB_AGENTS_ACTION_BEFORE";
export const SET_AGENT_SUB_AGENTS_ACTION_FINISH = "SET_AGENT_SUB_AGENTS_ACTION_FINISH";
export const SET_AGENT_SUB_AGENTS = "SET_AGENT_SUB_AGENTS";
export const SET_AGENT_SUB_AGENTS_SORTING = "SET_AGENT_SUB_AGENTS_SORTING";
export const SET_AGENT_SUB_AGENTS_FILTERS = "SET_AGENT_SUB_AGENTS_FILTERS";
export const SET_AGENT_PLAYERS_ACTION_BEFORE = "SET_AGENT_PLAYERS_ACTION_BEFORE";
export const SET_AGENT_PLAYERS_ACTION_FINISH = "SET_AGENT_PLAYERS_ACTION_FINISH";
export const SET_AGENT_PLAYERS = "SET_AGENT_PLAYERS";
export const SET_AGENT_PLAYERS_SORTING = "SET_AGENT_PLAYERS_SORTING";
export const SET_AGENT_PLAYERS_FILTERS = "SET_AGENT_PLAYERS_FILTERS";
export const SET_UPDATE_AGENT_PLAYER_DATA = "SET_UPDATE_AGENT_PLAYER_DATA"
export const SET_AGENT_PLAYER_TOTALS = "SET_AGENT_PLAYER_TOTALS"
export const SET_AGENT_PLAYER_TOTALS_ACTION_BEFORE = "SET_AGENT_PLAYER_TOTALS_ACTION_BEFORE";
export const SET_AGENT_PLAYER_TOTALS_ACTION_FINISH = "SET_AGENT_PLAYER_TOTALS_ACTION_FINISH";
export const SET_AGENT_BETSHOPS_ACTION_BEFORE = "SET_AGENT_BETSHOPS_ACTION_BEFORE";
export const SET_AGENT_BETSHOPS_ACTION_FINISH = "SET_AGENT_BETSHOPS_ACTION_FINISH";
export const SET_AGENT_BETSHOPS = "SET_AGENT_BETSHOPS";
export const SET_AGENT_BETSHOPS_SORTING = "SET_AGENT_BETSHOPS_SORTING";
export const SET_AGENT_BETSHOPS_FILTERS = "SET_AGENT_BETSHOPS_FILTERS";
export const SET_AGENT_BALANCE_OR_CREDIT = "SET_AGENT_BALANCE_OR_CREDIT";
export const SET_AGENT_REGISTRATION_FORM_BEFORE = "SET_AGENT_REGISTRATION_FORM_BEFORE";
export const SET_AGENT_REGISTRATION_FORM_FINISH = "SET_AGENT_REGISTRATION_FORM_FINISH";
export const SET_AGENT_REGISTRATION_FORM = "SET_AGENT_REGISTRATION_FORM";
export const SET_AVAILABLE_AGENTS_FOR_REPARENTING_BEFORE = "SET_AVAILABLE_AGENTS_FOR_REPARENTING_BEFORE";
export const SET_AVAILABLE_AGENTS_FOR_REPARENTING_FINISH = "SET_AVAILABLE_AGENTS_FOR_REPARENTING_FINISH";
export const SET_AVAILABLE_AGENTS_FOR_REPARENTING = "SET_AVAILABLE_AGENTS_FOR_REPARENTING";
export const SET_AGENT_GENERAL_INFO = "SET_AGENT_GENERAL_INFO";
export const SET_AGENT_ADDITIONAL_ACCESSES = "SET_AGENT_ADDITIONAL_ACCESSES";
export const SET_AGENT_CURRENCIES = "SET_AGENT_CURRENCIES";
export const SET_AGENT_CURRENCIES_ACTION_BEFORE = "SET_AGENT_CURRENCIES_ACTION_BEFORE";
export const SET_AGENT_CURRENCIES_ACTION_FINISH = "SET_AGENT_CURRENCIES_ACTION_FINISH";
export const SET_AGENT_AVAILABLE_CURRENCIES_BEFORE = "SET_AGENT_AVAILABLE_CURRENCIES_BEFORE";
export const SET_AGENT_AVAILABLE_CURRENCIES_FINISHED = "SET_AGENT_AVAILABLE_CURRENCIES_FINISHED";
export const SET_AGENT_AVAILABLE_CURRENCIES = "SET_AGENT_AVAILABLE_CURRENCIES";
export const SET_AGENT_LIMIT_SETTINGS = "SET_AGENT_LIMIT_SETTINGS";
export const SET_AGENT_PAYMENT_METHODS = "SET_AGENT_PAYMENT_METHODS";
export const SET_AGENT_EPOS_SETTINGS = "SET_AGENT_EPOS_SETTINGS";
export const SET_AGENT_EPOS_TRANSLATIONS = "SET_AGENT_EPOS_TRANSLATIONS";
export const SET_AGENT_BANK_LIST = "SET_AGENT_BANK_LIST";
export const SET_AGENT_AVAILABLE_BANK_LIST = "SET_AGENT_AVAILABLE_BANK_LIST";
export const SET_AGENT_BANK_INFO = "SET_AGENT_BANK_INFO";
export const SET_AGENT_BANK_DETAILS = "SET_AGENT_BANK_DETAILS";
export const SET_AGENT_BANK_WIDGET = "SET_AGENT_BANK_WIDGET";
export const SET_AGENT_TOTALS_ACTION_BEFORE = "SET_AGENT_TOTALS_ACTION_BEFORE";
export const SET_AGENT_TOTALS_ACTION_FINISH = "SET_AGENT_TOTALS_ACTION_FINISH";
export const SET_AGENT_TOTALS = "SET_AGENTS_TOTALS";

/** Commissions */

export const SET_COMMISSION_PLANS_ACTION_BEFORE = "SET_COMMISSION_PLANS_ACTION_BEFORE";
export const SET_COMMISSION_PLANS_ACTION_FINISH = "SET_COMMISSION_PLANS_ACTION_FINISH";
export const SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE = "SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE";
export const SET_COMMISSION_PLANS_SAVE_ACTION_FINISH = "SET_COMMISSION_PLANS_SAVE_ACTION_FINISH";
export const SET_COMMISSION_PLANS = "SET_COMMISSION_PLANS";
export const SET_AVAILABLE_COMMISSION_PLANS = "SET_AVAILABLE_COMMISSION_PLANS";
export const SET_AVAILABLE_COMMISSION_PLANS_BEFORE = "SET_AVAILABLE_COMMISSION_PLANS_BEFORE";
export const SET_AVAILABLE_COMMISSION_PLANS_FINISH = "SET_AVAILABLE_COMMISSION_PLANS_FINISH";
export const SET_COMMISSION_PLAN_SETTINGS = "SET_COMMISSION_PLAN_SETTINGS";
export const SET_COMMISSION_PLAN_SPORT_SETTINGS = "SET_COMMISSION_PLAN_SPORT_SETTINGS";
export const SET_COMMISSION_PLAN_AGENTS = "SET_COMMISSION_PLAN_AGENTS";
export const SET_COMMISSION_PLAN_AGENTS_SORTING = "SET_COMMISSION_PLAN_AGENTS_SORTING";
export const SET_COMMISSION_PLAN_AGENTS_FILTERS = "SET_COMMISSION_PLAN_AGENTS_FILTERS";

/** Network Overview */

export const SET_NETWORK_OVERVIEW_ACTION_BEFORE = "SET_NETWORK_OVERVIEW_ACTION_BEFORE";
export const SET_NETWORK_OVERVIEW_ACTION_FINISH = "SET_NETWORK_OVERVIEW_ACTION_FINISH";
export const SET_NETWORK_SUB_AGENTS = "SET_NETWORK_SUB_AGENTS";
export const RESET_NETWORK_SUB_AGENTS = "RESET_NETWORK_SUB_AGENTS";
export const SET_NETWORK_LAYERS = "SET_NETWORK_LAYERS";

/** Wallets */

export const SET_WALLET_ACTION_BEFORE = "SET_WALLET_ACTION_BEFORE";
export const SET_WALLET_ACTION_FINISH = "SET_WALLET_ACTION_FINISH";
export const SET_WALLET_SAVE_ACTION_BEFORE = "SET_WALLET_SAVE_ACTION_BEFORE";
export const SET_WALLET_SAVE_ACTION_FINISH = "SET_WALLET_SAVE_ACTION_FINISH";
export const SET_AGENT_WALLETS = "SET_AGENT_WALLETS";
export const SET_WALLET_HISTORY = "SET_WALLET_HISTORY";
export const SET_WALLET_HISTORY_SORTING = "SET_WALLET_HISTORY_SORTING";
export const SET_WALLET_HISTORY_FILTERS = "SET_WALLET_HISTORY_FILTERS";

/** Projects */

export const SET_PROJECTS_ACTION_BEFORE = "SET_PROJECTS_ACTION_BEFORE";
export const SET_PROJECTS_ACTION_FINISH = "SET_PROJECTS_ACTION_FINISH";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_PROJECTS_SORTING = "SET_PROJECTS_SORTING";
export const SET_PROJECTS_FILTERS = "SET_PROJECTS_FILTERS";
export const SET_PROJECTS_SAVE_ACTION_BEFORE = "SET_PROJECTS_SAVE_ACTION_BEFORE";
export const SET_PROJECTS_SAVE_ACTION_FINISH = "SET_PROJECTS_SAVE_ACTION_FINISH";
export const SET_PROJECT_GENERAL_INFO = "SET_PROJECT_GENERAL_INFO";
export const SET_PROJECT_INTEGRATION = "SET_PROJECT_INTEGRATION";
export const SET_RETAIL_PROVIDERS = "SET_RETAIL_PROVIDERS";
export const SET_PROJECT_CHATUI = "SET_PROJECT_CHATUI";

export const SET_PROJECT_CURRENCIES = "SET_PROJECT_CURRENCIES";
export const SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE = "SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE";
export const SET_PROJECT_AVAILABLE_CURRENCIES_FINISH = "SET_PROJECT_AVAILABLE_CURRENCIES_FINISH";
export const SET_PROJECT_AVAILABLE_CURRENCIES = "SET_PROJECT_AVAILABLE_CURRENCIES";

export const SET_PROJECT_AGENT_SYSTEM_NETWORK = "SET_PROJECT_AGENT_SYSTEM_NETWORK";
export const SET_PROJECT_AGENT_SYSTEM_NETWORK_LABEL = "SET_PROJECT_AGENT_SYSTEM_NETWORK_LABEL";
export const SET_PROJECT_AGENT_REGISTRATION_FORM = "SET_PROJECT_AGENT_REGISTRATION_FORM";
export const SET_PROJECT_AGENT_ADMIN_FEE = "SET_PROJECT_AGENT_ADMIN_FEE";
export const SET_PROJECT_PLAYER_REGISTRATION_FORM = "SET_PROJECT_PLAYER_REGISTRATION_FORM";
export const SET_PROJECT_LIMIT_SETTINGS = "SET_PROJECT_LIMIT_SETTINGS";
export const SET_PROJECT_FAVORITE_AMOUNTS = "SET_PROJECT_FAVORITE_AMOUNTS";
export const SET_PROJECT_PROVIDER_SETTINGS = "SET_PROJECT_PROVIDER_SETTINGS";
export const SET_PROJECT_PAYMENT_METHODS = "SET_PROJECT_PAYMENT_METHODS";
export const SET_PROJECT_BANKS = "SET_PROJECT_BANKS";
export const SET_PROJECT_BANK_GENERAL_INFO = "SET_PROJECT_BANK_GENERAL_INFO";
export const SET_PROJECT_BANK_FORM = "SET_PROJECT_BANK_FORM";
export const SET_PROJECT_BANK_FORM_TRANSLATION = "SET_PROJECT_BANK_FORM_TRANSLATION";
export const SET_PROJECT_BANK_NAME_TRANSLATION = "SET_PROJECT_BANK_NAME_TRANSLATION";
export const SET_PROJECT_PAYMENT_WIDGET = "SET_PROJECT_PAYMENT_WIDGET";
export const SET_PROJECT_CONFIGS = "SET_PROJECT_CONFIGS";
export const SET_PROJECT_SMS_PROVIDERS = "SET_PROJECT_SMS_PROVIDERS";
export const SET_PROJECT_SMS_PROVIDER = "SET_PROJECT_SMS_PROVIDER";

/** Agent Transactins Report */

export const SET_AGENT_TRANSACTIONS_REPORT_ACTION_BEFORE = "SET_AGENT_TRANSACTIONS_REPORT_ACTION_BEFORE";
export const SET_AGENT_TRANSACTIONS_REPORT_ACTION_FINISH = "SET_AGENT_TRANSACTIONS_REPORT_ACTION_FINISH";
export const SET_AGENT_TRANSACTIONS_REPORT = "SET_AGENT_TRANSACTIONS_REPORT";
export const SET_AGENT_TRANSACTIONS_REPORT_SORTING = "SET_AGENT_TRANSACTIONS_REPORT_SORTING";
export const SET_AGENT_TRANSACTIONS_REPORT_FILTERS = "SET_AGENT_TRANSACTIONS_REPORT_FILTERS";

/** Cashier Transactions Report */

export const SET_CASHIER_TRANSACTIONS_REPORT_ACTION_BEFORE = "SET_CASHIER_TRANSACTIONS_REPORT_ACTION_BEFORE";
export const SET_CASHIER_TRANSACTIONS_REPORT_ACTION_FINISH = "SET_CASHIER_TRANSACTIONS_REPORT_ACTION_FINISH";
export const SET_CASHIER_TRANSACTIONS_REPORT = "SET_CASHIER_TRANSACTIONS_REPORT";
export const SET_CASHIER_TRANSACTIONS_REPORT_SORTING = "SET_CASHIER_TRANSACTIONS_REPORT_SORTING";
export const SET_CASHIER_TRANSACTIONS_REPORT_FILTERS = "SET_CASHIER_TRANSACTIONS_REPORT_FILTERS";

/** Betshop Transactions Report */

export const SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_BEFORE = "SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_BEFORE";
export const SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_FINISH = "SET_BETSHOP_TRANSACTIONS_REPORT_ACTION_FINISH";
export const SET_BETSHOP_TRANSACTIONS_REPORT = "SET_BETSHOP_TRANSACTIONS_REPORT";
export const SET_BETSHOP_TRANSACTIONS_REPORT_SORTING = "SET_BETSHOP_TRANSACTIONS_REPORT_SORTING";
export const SET_BETSHOP_TRANSACTIONS_REPORT_FILTERS = "SET_BETSHOP_TRANSACTIONS_REPORT_FILTERS";

/** Commission Calculations Report */

export const SET_COMMISSION_CALCULATIONS_REPORT_ACTION_BEFORE = "SET_COMMISSION_CALCULATIONS_REPORT_ACTION_BEFORE";
export const SET_COMMISSION_CALCULATIONS_REPORT_ACTION_FINISH = "SET_COMMISSION_CALCULATIONS_REPORT_ACTION_FINISH";
export const SET_COMMISSION_CALCULATIONS_REPORT = "SET_COMMISSION_CALCULATIONS_REPORT";
export const SET_COMMISSION_CALCULATIONS_REPORT_SORTING = "SET_COMMISSION_CALCULATIONS_REPORT_SORTING";
export const SET_COMMISSION_CALCULATIONS_REPORT_FILTERS = "SET_COMMISSION_CALCULATIONS_REPORT_FILTERS";

/** Agent Performance Report */

export const SET_AGENT_PERFORMANCE_REPORT_ACTION_BEFORE = "SET_AGENT_PERFORMANCE_REPORT_ACTION_BEFORE";
export const SET_AGENT_PERFORMANCE_REPORT_ACTION_FINISH = "SET_AGENT_PERFORMANCE_REPORT_ACTION_FINISH";
export const SET_AGENT_PERFORMANCE_REPORT = "SET_AGENT_PERFORMANCE_REPORT";
export const SET_AGENT_PERFORMANCE_REPORT_FILTERS = "SET_AGENT_PERFORMANCE_REPORT_FILTERS";
export const SET_AGENT_PERFORMANCE_REPORT_SORTING = "SET_AGENT_PERFORMANCE_REPORT_SORTING";
export const SET_AGENT_PERFORMANCE_REPORT_TOTALS_BEFORE = "SET_AGENT_PERFORMANCE_REPORT_TOTALS_BEFORE";
export const SET_AGENT_PERFORMANCE_REPORT_TOTALS_FINISH = "SET_AGENT_PERFORMANCE_REPORT_TOTALS_FINISH";
export const SET_AGENT_PERFORMANCE_REPORT_TOTALS = "SET_AGENT_PERFORMANCE_REPORT_TOTALS";

/** Player Performance Report */

export const SET_PLAYER_PERFORMANCE_REPORT_ACTION_BEFORE = "SET_PLAYER_PERFORMANCE_REPORT_ACTION_BEFORE";
export const SET_PLAYER_PERFORMANCE_REPORT_ACTION_FINISH = "SET_PLAYER_PERFORMANCE_REPORT_ACTION_FINISH";
export const SET_PLAYER_PERFORMANCE_REPORT = "SET_PLAYER_PERFORMANCE_REPORT";
export const SET_PLAYER_PERFORMANCE_REPORT_FILTERS = "SET_PLAYER_PERFORMANCE_REPORT_FILTERS";
export const SET_PLAYER_PERFORMANCE_REPORT_SORTING = "SET_PLAYER_PERFORMANCE_REPORT_SORTING";
export const SET_PLAYER_PERFORMANCE_REPORT_TOTALS = "SET_PLAYER_PERFORMANCE_REPORT_TOTALS";
export const SET_PLAYER_PERFORMANCE_REPORT_TOTALS_BEFORE = "SET_PLAYER_PERFORMANCE_REPORT_TOTALS_BEFORE";
export const SET_PLAYER_PERFORMANCE_REPORT_TOTALS_FINISH = "SET_PLAYER_PERFORMANCE_REPORT_TOTALS_FINISH";

/** Betshop Performance Report */

export const SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE = "SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE";
export const SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH = "SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH";
export const SET_BETSHOP_PERFORMANCE_REPORT = "SET_BETSHOP_PERFORMANCE_REPORT";
export const SET_BETSHOP_PERFORMANCE_REPORT_FILTERS = "SET_BETSHOP_PERFORMANCE_REPORT_FILTERS";
export const SET_BETSHOP_PERFORMANCE_REPORT_SORTING = "SET_BETSHOP_PERFORMANCE_REPORT_SORTING";
export const SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_BEFORE = "SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_BEFORE";
export const SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_FINISH = "SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_FINISH";
export const SET_BETSHOP_PERFORMANCE_REPORT_TOTALS = "SET_BETSHOP_PERFORMANCE_REPORT_TOTALS";

/** Cashier Performance Report */

export const SET_CASHIER_PERFORMANCE_REPORT = "SET_CASHIER_PERFORMANCE_REPORT";
export const SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE = "SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE";
export const SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH = "SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH";

/** Product Report */

export const SET_PRODUCT_REPORT = "SET_PRODUCT_REPORT";
export const SET_PRODUCT_REPORT_ACTION_BEFORE = "SET_PRODUCT_REPORT_ACTION_BEFORE";
export const SET_PRODUCT_REPORT_ACTION_FINISH = "SET_PRODUCT_REPORT_ACTION_FINISH";
export const SET_PRODUCT_REPORT_FILTERS = "SET_PRODUCT_REPORT_FILTERS";

/** Agent Calculatoin History */

export const SET_AGENT_CALCULATION_HISTORY_ACTION_BEFORE = "SET_AGENT_CALCULATION_HISTORY_ACTION_BEFORE";
export const SET_AGENT_CALCULATION_HISTORY_ACTION_FINISH = "SET_AGENT_CALCULATION_HISTORY_ACTION_FINISH";
export const SET_AGENT_CALCULATION_HISTORY = "SET_AGENT_CALCULATION_HISTORY";
export const SET_AGENT_CALCULATION_HISTORY_SORTING = "SET_AGENT_CALCULATION_HISTORY_SORTING";
export const SET_AGENT_CALCULATION_HISTORY_FILTERS = "SET_AGENT_CALCULATION_HISTORY_FILTERS";

/** Players */

export const SET_PLAYERS_ACTION_BEFORE = "SET_PLAYERS_ACTION_BEFORE";
export const SET_PLAYERS_ACTION_FINISH = "SET_PLAYERS_ACTION_FINISH";
export const SET_PLAYERS = "SET_PLAYERS";
export const SET_PLAYERS_SORTING = "SET_PLAYERS_SORTING";
export const SET_PLAYERS_FILTERS = "SET_PLAYERS_FILTERS";
export const SET_PLAYERS_SAVE_ACTION_BEFORE = "SET_PLAYERS_SAVE_ACTION_BEFORE";
export const SET_PLAYERS_SAVE_ACTION_FINISH = "SET_PLAYERS_SAVE_ACTION_FINISH";
export const SET_PLAYER_GENERAL_INFO = "SET_PLAYER_GENERAL_INFO";
export const SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID = "SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID";
export const SET_UPDATE_PLAYER_DATA = "SET_UPDATE_PLAYER_DATA";
export const SET_PLAYER_BETS = "SET_PLAYER_BETS";
export const SET_PLAYER_BETS_SORTING = "SET_PLAYER_BETS_SORTING";
export const SET_PLAYER_BETS_FILTERS = "SET_PLAYER_BETS_FILTERS";
export const SET_PLAYER_REGISTRATION_FORM_BEFORE = "SET_PLAYER_REGISTRATION_FORM_BEFORE";
export const SET_PLAYER_REGISTRATION_FORM_FINISH = "SET_PLAYER_REGISTRATION_FORM_FINISH";
export const SET_PLAYER_REGISTRATION_FORM = "SET_PLAYER_REGISTRATION_FORM";
export const SET_PLAYER_WITHDRAWAL = "SET_PLAYER_WITHDRAWAL";
export const SET_PLAYER_TRANSACTIONS_HISTORY = "SET_PLAYER_TRANSACTIONS_HISTORY"
export const SET_PLAYER_TRANSACTIONS_HISTORY_SORTING = "SET_PLAYER_TRANSACTIONS_HISTORY_SORTING";
export const SET_PLAYER_TRANSACTIONS_HISTORY_FILTERS = "SET_PLAYER_TRANSACTIONS_HISTORY_FILTERS";

/** Bet History */

export const SET_BET_HISTORY_ACTION_BEFORE = "SET_BET_HISTORY_ACTION_BEFORE";
export const SET_BET_HISTORY_ACTION_FINISH = "SET_BET_HISTORY_ACTION_FINISH";
export const SET_RETAIL_PENDING_BET_HISTORY = "SET_RETAIL_PENDING_BET_HISTORY";
export const SET_RETAIL_PENDING_BET_HISTORY_SORTING = "SET_RETAIL_PENDING_BET_HISTORY_SORTING";
export const SET_RETAIL_PENDING_BET_HISTORY_FILTERS = "SET_RETAIL_PENDING_BET_HISTORY_FILTERS";
export const SET_RETAIL_SETTLED_BET_HISTORY = "SET_RETAIL_SETTLED_BET_HISTORY";
export const SET_RETAIL_SETTLED_BET_HISTORY_SORTING = "SET_RETAIL_SETTLED_BET_HISTORY_SORTING";
export const SET_RETAIL_SETTLED_BET_HISTORY_FILTERS = "SET_RETAIL_SETTLED_BET_HISTORY_FILTERS";
export const SET_RETAIL_SETTLED_BET_TOTALS = "SET_RETAIL_SETTLED_BET_TOTALS"

export const SET_ONLINE_BET_HISTORY = "SET_ONLINE_BET_HISTORY";
export const SET_ONLINE_BET_HISTORY_SORTING = "SET_ONLINE_BET_HISTORY_SORTING";
export const SET_ONLINE_BET_HISTORY_FILTERS = "SET_ONLINE_BET_HISTORY_FILTERS";
export const SET_ONLINE_BET_HISTORY_LOADING = "SET_ONLINE_BET_HISTORY_LOADING";
export const SET_ONLINE_BETS_TOTALS = "SET_ONLINE_BETS_TOTALS";

export const SET_REAL_TIME_SPORT_BETS = "SET_REAL_TIME_SPORT_BETS";
export const SET_REAL_TIME_SPORT_BETS_SORTING = "SET_REAL_TIME_SPORT_BETS_SORTING";
export const SET_REAL_TIME_SPORT_BETS_FILTERS = "SET_REAL_TIME_SPORT_BETS_FILTERS";
export const SET_REAL_TIME_SPORT_BET_DETAILS = "SET_REAL_TIME_SPORT_BET_DETAILS";

export const SET_REAL_TIME_CASINO_BETS = "SET_REAL_TIME_CASINO_BETS";
export const SET_REAL_TIME_CASINO_BETS_SORTING = "SET_REAL_TIME_CASINO_BETS_SORTING";
export const SET_REAL_TIME_CASINO_BETS_FILTERS = "SET_REAL_TIME_CASINO_BETS_FILTERS";

export const SET_AGGREGATED_BET_HISTORY = "SET_AGGREGATED_BET_HISTORY";
export const SET_AGGREGATED_BET_HISTORY_SORTING = "SET_AGGREGATED_BET_HISTORY_SORTING";
export const SET_AGGREGATED_BET_HISTORY_FILTERS = "SET_AGGREGATED_BET_HISTORY_FILTERS";
export const SET_AGGREGATED_BET_HISTORY_LOADING = "SET_AGGREGATED_BET_HISTORY_LOADING";
export const SET_AGGREGATED_BETS_TOTALS = "SET_AGGREGATED_BETS_TOTALS";
export const SET_AGGREGATED_BETS_TOTALS_BEFORE = "SET_AGGREGATED_BETS_TOTALS_BEFORE";
export const SET_AGGREGATED_BETS_TOTALS_FINISH = "SET_AGGREGATED_BETS_TOTALS_FINISH";

/** Betshops */

export const SET_BETSHOPS_ACTION_BEFORE = "SET_BETSHOPS_ACTION_BEFORE";
export const SET_BETSHOPS_ACTION_FINISH = "SET_BETSHOPS_ACTION_FINISH";
export const SET_BETSHOPS = "SET_BETSHOPS";
export const SET_BETSHOPS_SORTING = "SET_BETSHOPS_SORTING";
export const SET_BETSHOPS_FILTERS = "SET_BETSHOPS_FILTERS";
export const SET_BETSHOPS_SAVE_ACTION_BEFORE = "SET_BETSHOPS_SAVE_ACTION_BEFORE";
export const SET_BETSHOPS_SAVE_ACTION_FINISH = "SET_BETSHOPS_SAVE_ACTION_FINISH";
export const SET_BETSHOP_AVAILABLE_CURRENCIES_BEFORE = "SET_BETSHOP_AVAILABLE_CURRENCIES_BEFORE";
export const SET_BETSHOP_AVAILABLE_CURRENCIES_FINISH = "SET_BETSHOP_AVAILABLE_CURRENCIES_FINISH";
export const SET_BETSHOP_AVAILABLE_CURRENCIES = "SET_BETSHOP_AVAILABLE_CURRENCIES";
export const SET_BETSHOP_GENERAL_INFO = "SET_BETSHOP_GENERAL_INFO";
export const SET_BETSHOP_TRANSLATION = "SET_BETSHOP_TRANSLATION";
export const SET_BETSHOP_CURRENCY = "SET_BETSHOP_CURRENCY";
export const SET_BETSHOP_LIMIT_SETTINGS = "SET_BETSHOP_LIMIT_SETTINGS";
export const SET_BETSHOP_ADDITIONAL_ACCESS = "SET_BETSHOP_ADDITIONAL_ACCESS";
export const SET_BETSHOP_IP_ACCESS_RULE = "SET_BETSHOP_IP_ACCESS_RULE";
export const SET_BETSHOP_BALANCE = "SET_BETSHOP_BALANCE";

/** Cashiers */

export const SET_CASHIERS_ACTION_BEFORE = "SET_CASHIERS_ACTION_BEFORE";
export const SET_CASHIERS_ACTION_FINISH = "SET_CASHIERS_ACTION_FINISH";
export const SET_CASHIERS = "SET_CASHIERS";
export const SET_CASHIERS_SORTING = "SET_CASHIERS_SORTING";
export const SET_CASHIERS_FILTERS = "SET_CASHIERS_FILTERS";
export const SET_CASHIERS_SAVE_ACTION_BEFORE = "SET_CASHIERS_SAVE_ACTION_BEFORE";
export const SET_CASHIERS_SAVE_ACTION_FINISH = "SET_CASHIERS_SAVE_ACTION_FINISH";
export const SET_CASHIER_GENERAL_INFO = "SET_CASHIER_GENERAL_INFO";
export const SET_CASHIER_AVAILABLE_BETSHOPS_BEFORE = "SET_CASHIER_AVAILABLE_BETSHOPS_BEFORE";
export const SET_CASHIER_AVAILABLE_BETSHOPS_FINISH = "SET_CASHIER_AVAILABLE_BETSHOPS_FINISH";
export const SET_CASHIER_AVAILABLE_BETSHOPS = "SET_CASHIER_AVAILABLE_BETSHOPS";
export const SET_CASHIER_ADDITIONAL_ACCESS = "SET_CASHIER_ADDITIONAL_ACCESS";

/** Betshop Managers */

export const SET_BETSHOP_MANAGERS_ACTION_BEFORE = "SET_BETSHOP_MANAGERS_ACTION_BEFORE";
export const SET_BETSHOP_MANAGERS_ACTION_FINISH = "SET_BETSHOP_MANAGERS_ACTION_FINISH";
export const SET_BETSHOP_MANAGERS = "SET_BETSHOP_MANAGERS";
export const SET_BETSHOP_MANAGERS_SORTING = "SET_BETSHOP_MANAGERS_SORTING";
export const SET_BETSHOP_MANAGERS_FILTERS = "SET_BETSHOP_MANAGERS_FILTERS";
export const SET_BETSHOP_MANAGERS_SAVE_ACTION_BEFORE = "SET_BETSHOP_MANAGERS_SAVE_ACTION_BEFORE";
export const SET_BETSHOP_MANAGERS_SAVE_ACTION_FINISH = "SET_BETSHOP_MANAGERS_SAVE_ACTION_FINISH";
export const SET_BETSHOP_MANAGER_GENERAL_INFO = "SET_BETSHOP_MANAGER_GENERAL_INFO";
export const SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_BEFORE = "SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_BEFORE";
export const SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_FINISH = "SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_FINISH";
export const SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS = "SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS";

/** Users */

export const SET_USERS_ACTION_BEFORE = "SET_USERS_ACTION_BEFORE";
export const SET_USERS_ACTION_FINISH = "SET_USERS_ACTION_FINISH";
export const SET_USERS = "SET_USERS";
export const SET_USERS_SORTING = "SET_USERS_SORTING";
export const SET_USERS_FILTERS = "SET_USERS_FILTERS";
export const SET_USERS_SAVE_ACTION_BEFORE = "SET_USERS_SAVE_ACTION_BEFORE";
export const SET_USERS_SAVE_ACTION_FINISH = "SET_USERS_SAVE_ACTION_FINISH";
export const SET_USER_AVAILABLE_COMPANIES_BEFORE = "SET_USER_AVAILABLE_COMPANIES_BEFORE";
export const SET_USER_AVAILABLE_COMPANIES_FINISH = "SET_USER_AVAILABLE_COMPANIES_FINISH";
export const SET_USER_AVAILABLE_COMPANIES = "SET_USER_AVAILABLE_COMPANIES";
export const SET_USER_GENERAL_INFO = "SET_USER_GENERAL_INFO";
export const SET_USER_PERMISSION_GROUPS = "SET_USER_PERMISSION_GROUPS";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE = "SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH = "SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS = "SET_USER_AVAILABLE_PERMISSION_GROUPS";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const SET_USER_ADDITIONAL_ACCESSES = "SET_USER_ADDITIONAL_ACCESSES";
export const SET_USER_COMPANY_ACCESS = "SET_USER_COMPANY_ACCESS";

/** Access Managers */

export const SET_ACCESS_MANAGERS_ACTION_BEFORE = "SET_ACCESS_MANAGERS_ACTION_BEFORE";
export const SET_ACCESS_MANAGERS_ACTION_FINISH = "SET_ACCESS_MANAGERS_ACTION_FINISH";
export const SET_ACCESS_MANAGERS = "SET_ACCESS_MANAGERS";
export const SET_ACCESS_MANAGERS_SORTING = "SET_ACCESS_MANAGERS_SORTING";
export const SET_ACCESS_MANAGERS_FILTERS = "SET_ACCESS_MANAGERS_FILTERS";
export const SET_ACCESS_MANAGERS_SAVE_ACTION_BEFORE = "SET_ACCESS_MANAGERS_SAVE_ACTION_BEFORE";
export const SET_ACCESS_MANAGERS_SAVE_ACTION_FINISH = "SET_ACCESS_MANAGERS_SAVE_ACTION_FINISH";
export const SET_ACCESS_MANAGER_GENERAL_INFO = "SET_ACCESS_MANAGER_GENERAL_INFO";

/** User Info */

export const SET_USER_INFO_ACTION_BEFORE = "SET_USER_INFO_ACTION_BEFORE";
export const SET_USER_INFO_ACTION_FINISH = "SET_USER_INFO_ACTION_FINISH";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_CURRENCY = "SET_USER_CURRENCY";
export const UPDATE_USER_AVATAR = "UPDATE_USER_AVATAR";
export const SET_CHANGE_PROJECT_ACTION_BEFORE = "SET_CHANGE_PROJECT_ACTION_BEFORE";
export const SET_CHANGE_PROJECT_ACTION_FINISH = "SET_CHANGE_PROJECT_ACTION_FINISH";
export const SET_NOTIFICATION_SETTINGS = "SET_NOTIFICATION_SETTINGS";

/** Profile */

export const SET_PROFILE_ACTION_BEFORE = "SET_PROFILE_ACTION_BEFORE";
export const SET_PROFILE_ACTION_FINISH = "SET_PROFILE_ACTION_FINISH";
export const SET_PROFILE_SAVE_ACTION_BEFORE = "SET_PROFILE_SAVE_ACTION_BEFORE";
export const SET_PROFILE_SAVE_ACTION_FINISH = "SET_PROFILE_SAVE_ACTION_FINISH";
export const SET_PERSONAL_INFORMATION = "SET_PERSONAL_INFORMATION";
export const SET_FORMAT_SETTINGS = "SET_FORMAT_SETTINGS";
export const CHANGE_PASSWORD_ACTION_BEFORE = "CHANGE_PASSWORD_ACTION_BEFORE";
export const CHANGE_PASSWORD_ACTION_FINISH = "CHANGE_PASSWORD_ACTION_FINISH";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const UPDATE_CURRENCIES = "UPDATE_CURRENCIES";

/** Permissions */

export const SET_PERMISSIONS_ACTION_BEFORE = "SET_PERMISSIONS_ACTION_BEFORE";
export const SET_PERMISSIONS_ACTION_FINISH = "SET_PERMISSIONS_ACTION_FINISH";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_PERMISSION_RESOURCES_ACTION_BEFORE = "SET_PERMISSION_RESOURCES_ACTION_BEFORE";
export const SET_PERMISSION_RESOURCES_ACTION_FINISH = "SET_PERMISSION_RESOURCES_ACTION_FINISH";
export const SET_PERMISSIONS_RESOURCES = "SET_PERMISSIONS_RESOURCES";

/** Permission Groups */

export const SET_PERMISSION_GROUPS_ACTION_BEFORE = "SET_PERMISSION_GROUPS_ACTION_BEFORE";
export const SET_PERMISSION_GROUPS_ACTION_FINISH = "SET_PERMISSION_GROUPS_ACTION_FINISH";
export const SET_PERMISSION_GROUPS = "SET_PERMISSION_GROUPS";
export const SET_PERMISSION_GROUPS_SORTING = "SET_PERMISSION_GROUPS_SORTING";
export const SET_PERMISSION_GROUPS_SAVE_ACTION_BEFORE = "SET_PERMISSION_GROUPS_SAVE_ACTION_BEFORE";
export const SET_PERMISSION_GROUPS_SAVE_ACTION_FINISH = "SET_PERMISSION_GROUPS_SAVE_ACTION_FINISH";
export const SET_PERMISSION_GROUP_GENERAL_INFO = "SET_PERMISSION_GROUP_GENERAL_INFO";
export const SET_PERMISSION_GROUP_USERS = "SET_PERMISSION_GROUP_USERS";
export const SET_PERMISSION_GROUP_USERS_SORTING = "SET_PERMISSION_GROUP_USERS_SORTING";
export const SET_PERMISSION_GROUP_USERS_FILTERS = "SET_PERMISSION_GROUP_USERS_FILTERS";
export const SET_GROUP_AS_DEFAULT = "SET_GROUP_AS_DEFAULT";

/** Permission Requets */

export const SET_PERMISSION_REQUESTS_ACTION_BEFORE = "SET_PERMISSION_REQUESTS_ACTION_BEFORE";
export const SET_PERMISSION_REQUESTS_ACTION_FINISH = "SET_PERMISSION_REQUESTS_ACTION_FINISH";
export const SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE = "SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE";
export const SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH = "SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH";
export const SET_PERMISSION_REQUESTS = "SET_PERMISSION_REQUESTS";
export const SET_PERMISSION_REQUESTS_COUNT = "SET_PERMISSION_REQUESTS_COUNT";
export const SET_PERMISSION_REQUESTS_HISTORY = "SET_PERMISSION_REQUESTS_HISTORY";
export const SET_PERMISSION_REQUESTS_HISTORY_SORTING = "SET_PERMISSION_REQUESTS_HISTORY_SORTING";
export const SET_PERMISSION_REQUESTS_HISTORY_FILTERS = "SET_PERMISSION_REQUESTS_HISTORY_FILTERS";

/** Sessions */

export const SET_SESSIONS_ACTION_BEFORE = "SET_SESSIONS_ACTION_BEFORE";
export const SET_SESSIONS_ACTION_FINISH = "SET_SESSIONS_ACTION_FINISH";
export const SET_SESSIONS = "SET_SESSIONS";
export const SET_SESSIONS_SORTING = "SET_SESSIONS_SORTING";
export const SET_SESSIONS_FILTERS = "SET_SESSIONS_FILTERS";
export const SET_SESSIONS_SAVE_ACTION_BEFORE = "SET_SESSIONS_SAVE_ACTION_BEFORE";
export const SET_SESSIONS_SAVE_ACTION_FINISH = "SET_SESSIONS_SAVE_ACTION_FINISH";

/** System Currencies */

export const SET_SYSTEM_CURRENCIES_ACTION_BEFORE = "SET_SYSTEM_CURRENCIES_ACTION_BEFORE";
export const SET_SYSTEM_CURRENCIES_ACTION_FINISH = "SET_SYSTEM_CURRENCIES_ACTION_FINISH";
export const SET_SYSTEM_CURRENCIES = "SET_SYSTEM_CURRENCIES";
export const SET_SYSTEM_CURRENCIES_SORTING = "SET_SYSTEM_CURRENCIES_SORTING";
export const SET_SYSTEM_CURRENCIES_SAVE_ACTION_BEFORE = "SET_SYSTEM_CURRENCIES_SAVE_ACTION_BEFORE";
export const SET_SYSTEM_CURRENCIES_SAVE_ACTION_FINISH = "SET_SYSTEM_CURRENCIES_SAVE_ACTION_FINISH";
export const SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_BEFORE = "SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_BEFORE";
export const SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_FINISH = "SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_FINISH";
export const SET_SYSTEM_AVAILABLE_CURRENCIES = "SET_SYSTEM_AVAILABLE_CURRENCIES";
export const SET_AVAILABLE_CURRENCIES = "SET_AVAILABLE_CURRENCIES";

/** Languages */

export const SET_SYSTEM_LANGUAGES_ACTION_BEFORE = "SET_SYSTEM_LANGUAGES_ACTION_BEFORE";
export const SET_SYSTEM_LANGUAGES_ACTION_FINISH = "SET_SYSTEM_LANGUAGES_ACTION_FINISH";
export const SET_SYSTEM_LANGUAGES = "SET_SYSTEM_LANGUAGES";
export const SET_SYSTEM_LANGUAGES_SORTING = "SET_SYSTEM_LANGUAGES_SORTING";
export const SET_SYSTEM_LANGUAGES_SAVE_ACTION_BEFORE = "SET_SYSTEM_LANGUAGES_SAVE_ACTION_BEFORE";
export const SET_SYSTEM_LANGUAGES_SAVE_ACTION_FINISH = "SET_SYSTEM_LANGUAGES_SAVE_ACTION_FINISH";
export const SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_BEFORE = "SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_BEFORE";
export const SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_FINISH = "SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_FINISH";
export const SET_SYSTEM_AVAILABLE_LANGUAGES = "SET_SYSTEM_AVAILABLE_LANGUAGES";
export const SET_AVAILABLE_LANGUAGES = "SET_AVAILABLE_LANGUAGES";

/** Translations */

export const SET_TRANSLATIONS_ACTION_BEFORE = "SET_TRANSLATIONS_ACTION_BEFORE";
export const SET_TRANSLATIONS_ACTION_FINISH = "SET_TRANSLATIONS_ACTION_FINISH";
export const SET_TRANSLATION_GROUPS_ACTION_BEFORE = "SET_TRANSLATION_GROUPS_ACTION_BEFORE";
export const SET_TRANSLATION_GROUPS_ACTION_FINISH = "SET_TRANSLATION_GROUPS_ACTION_FINISH";
export const SET_TRANSLATION_GROUPS = "SET_TRANSLATION_GROUPS";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const UPDATE_TRANSLATION = "UPDATE_TRANSLATION";
export const SET_TRANSLATIONS_SAVE_ACTION_BEFORE = "SET_TRANSLATIONS_SAVE_ACTION_BEFORE";
export const SET_TRANSLATIONS_SAVE_ACTION_FINISH = "SET_TRANSLATIONS_SAVE_ACTION_FINISH";
export const SET_TRANSLATIONS_SYSTEM_DEFAULT = "SET_TRANSLATIONS_SYSTEM_DEFAULT";
export const SET_TRANSLATIONS_PUBLISH_ACTION_BEFORE = "SET_TRANSLATIONS_PUBLISH_ACTION_BEFORE";
export const SET_TRANSLATIONS_PUBLISH_ACTION_FINISH = "SET_TRANSLATIONS_PUBLISH_ACTION_FINISH";
export const SET_TRANSLATIONS_UNPUBLISH_ACTION_BEFORE = "SET_TRANSLATIONS_UNPUBLISH_ACTION_BEFORE";
export const SET_TRANSLATIONS_UNPUBLISH_ACTION_FINISH = "SET_TRANSLATIONS_UNPUBLISH_ACTION_FINISH";

/** Payout Ticket */

export const SET_PAYOUT_TICKET_ACTION_BEFORE = "SET_PAYOUT_TICKET_ACTION_BEFORE";
export const SET_PAYOUT_TICKET_ACTION_FINISH = "SET_PAYOUT_TICKET_ACTION_FINISH";
export const SET_PAYOUT_TICKET = "SET_PAYOUT_TICKET";
export const SET_CURRENT_PAYOUT_TICKET = "SET_CURRENT_PAYOUT_TICKET";
export const SET_PAYOUT_TICKET_SAVE_ACTION_BEFORE = "SET_PAYOUT_TICKET_SAVE_ACTION_BEFORE";
export const SET_PAYOUT_TICKET_SAVE_ACTION_FINISH = "SET_PAYOUT_TICKET_SAVE_ACTION_FINISH";
export const SET_PAYOUT_TICKET_LOGO = "SET_PAYOUT_TICKET_LOGO";

/** Notifications */

export const SET_NOTIFICATIONS_ACTION_BEFORE = "SET_NOTIFICATIONS_ACTION_BEFORE";
export const SET_NOTIFICATIONS_ACTION_FINISH = "SET_NOTIFICATIONS_ACTION_FINISH";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_UNREAD_NOTIFICATION_COUNT = "SET_UNREAD_NOTIFICATION_COUNT";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const SET_NOTIFICATION_SOUND = "SET_NOTIFICATION_SOUND";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_ALL_NOTIFICATIONS = "DELETE_ALL_NOTIFICATIONS";
export const MARK_AS_READ_NOTIFICATION = "MARK_AS_READ_NOTIFICATION";
export const MARK_ALL_AS_READ_NOTIFICATIONS = "MARK_ALL_AS_READ_NOTIFICATIONS";

/** Failed Transactions */

export const SET_FAILED_TRANSACTIONS_ACTION_BEFORE = "SET_FAILED_TRANSACTIONS_ACTION_BEFORE";
export const SET_FAILED_TRANSACTIONS_ACTION_FINISH = "SET_FAILED_TRANSACTIONS_ACTION_FINISH";
export const SET_FAILED_TRANSACTIONS = "SET_FAILED_TRANSACTIONS";
export const SET_FAILED_TRANSACTIONS_SORTING = "SET_FAILED_TRANSACTIONS_SORTING";
export const SET_FAILED_TRANSACTIONS_FILTERS = "SET_FAILED_TRANSACTIONS_FILTERS";

/** User Logs */

export const SET_USER_LOGS_ACTION_BEFORE = "SET_USER_LOGS_ACTION_BEFORE";
export const SET_USER_LOGS_ACTION_FINISH = "SET_USER_LOGS_ACTION_FINISH";
export const SET_USER_LOGS = "SET_USER_LOGS";
export const SET_USER_LOGS_SORTING = "SET_USER_LOGS_SORTING";
export const SET_USER_LOGS_FILTERS = "SET_USER_LOGS_FILTERS";
export const SET_USER_LOG_DETAILS = "SET_USER_LOG_DETAILS";
export const SET_USER_LOG_RESOURCES = "SET_USER_LOG_RESOURCES";

/** Errors */

export const SET_ERRORS_ACTION_BEFORE = "SET_ERRORS_ACTION_BEFORE";
export const SET_ERRORS_ACTION_FINISH = "SET_ERRORS_ACTION_FINISH";
export const SET_ERRORS = "SET_ERRORS";
export const SET_ERRORS_SORTING = "SET_ERRORS_SORTING";
export const SET_ERRORS_FILTERS = "SET_ERRORS_FILTERS";

/** Platform Requests Log */

export const SET_REQUESTS_ACTION_BEFORE = "SET_REQUESTS_ACTION_BEFORE";
export const SET_REQUESTS_ACTION_FINISH = "SET_REQUESTS_ACTION_FINISH";
export const SET_REQUESTS = "SET_REQUESTS";
export const SET_REQUESTS_SORTING = "SET_REQUESTS_SORTING";
export const SET_REQUESTS_FILTERS = "SET_REQUESTS_FILTERS";

/** Generations */
export const SET_GENERATIONS_ACTION_BEFORE = "SET_GENERATIONS_ACTION_BEFORE";
export const SET_GENERATIONS_ACTION_FINISH = "SET_GENERATIONS_ACTION_FINISH";
export const SET_GENERATIONS = "SET_GENERATIONS";

/** Jobs */

export const SET_JOBS_ACTION_BEFORE = "SET_JOBS_ACTION_BEFORE";
export const SET_JOBS_ACTION_FINISH = "SET_JOBS_ACTION_FINISH";
export const SET_JOBS = "SET_JOBS";
export const UPDATE_JOB = "UPDATE_JOB";
export const SET_JOBS_SAVE_ACTION_BEFORE = "SET_JOBS_SAVE_ACTION_BEFORE";
export const SET_JOBS_SAVE_ACTION_FINISH = "SET_JOBS_SAVE_ACTION_FINISH";
export const SET_JOB_SETTINGS = "SET_JOB_SETTINGS";
export const SET_JOB_DETAILS_ACTION_BEFORE = "SET_JOB_DETAILS_ACTION_BEFORE";
export const SET_JOB_DETAILS_ACTION_FINISH = "SET_JOB_DETAILS_ACTION_FINISH";
export const SET_JOB_DETAILS = "SET_JOB_DETAILS";

/** DB Connections */

export const SET_DB_CONNECTIONS_ACTION_BEFORE = "SET_DB_CONNECTIONS_ACTION_BEFORE";
export const SET_DB_CONNECTIONS_ACTION_FINISH = "SET_DB_CONNECTIONS_ACTION_FINISH";
export const SET_DB_CONNECTIONS = "SET_DB_CONNECTIONS";

/** Monitoring */

export const SET_MONITORING_ACTION_BEFORE = "SET_MONITORING_ACTION_BEFORE";
export const SET_MONITORING_ACTION_FINISH = "SET_MONITORING_ACTION_FINISH";
export const SET_MONITORING_SERVICE_ACTION_BEFORE = "SET_MONITORING_SERVICE_ACTION_BEFORE";
export const SET_MONITORING_SERVICE_ACTION_FINISH = "SET_MONITORING_SERVICE_ACTION_FINISH";
export const SET_MONITORING_SERVICES = "SET_MONITORING_SERVICES";
export const SET_MONITORING_SERVICE = "SET_MONITORING_SERVICE";

/** Calculate Reports */

export const SET_CALCULATE_REPORTS_ACTION_BEFORE = "SET_CALCULATE_REPORTS_ACTION_BEFORE";
export const SET_CALCULATE_REPORTS_ACTION_FINISH = "SET_CALCULATE_REPORTS_ACTION_FINISH";

/** Post Deployment Actions */

export const SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE = "SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE";
export const SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH = "SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH";
export const SET_LANGUAGES_ACTION_BEFORE = "SET_LANGUAGES_ACTION_BEFORE";
export const SET_LANGUAGES_ACTION_FINISH = "SET_LANGUAGES_ACTION_FINISH";
export const SET_LANGUAGES_ACTION = "SET_LANGUAGES_ACTION";
export const SET_EXPORT_ACTION_BEFORE = "SET_EXPORT_ACTION_BEFORE";
export const SET_EXPORT_ACTION_FINISH = "SET_EXPORT_ACTION_FINISH";

/** Timezones */

export const SET_SYSTEM_TIMEZONES_ACTION_BEFORE = "SET_SYSTEM_TIMEZONES_ACTION_BEFORE";
export const SET_SYSTEM_TIMEZONES_ACTION_FINISH = "SET_SYSTEM_TIMEZONES_ACTION_FINISH";
export const SET_SYSTEM_TIMEZONES = "SET_SYSTEM_TIMEZONES";

/** Platforms */

export const SET_PLATFORMS_ACTION_BEFORE = "SET_PLATFORMS_ACTION_BEFORE";
export const SET_PLATFORMS_ACTION_FINISH = "SET_PLATFORMS_ACTION_FINISH";
export const SET_PLATFORMS = "SET_PLATFORMS";
export const SET_PLATFORM_TEST_ACTION_BEFORE = "SET_PLATFORM_TEST_ACTION_BEFORE";
export const SET_PLATFORM_TEST_ACTION_FINISH = "SET_PLATFORM_TEST_ACTION_FINISH";
export const SET_PLATFORM_TESTS = "SET_PLATFORM_TESTS";

/** Bank Transfer Requests */

export const SET_PAYMENT_REQUESTS_ACTION_BEFORE = "SET_PAYMENT_REQUESTS_ACTION_BEFORE";
export const SET_PAYMENT_REQUESTS_ACTION_FINISH = "SET_PAYMENT_REQUESTS_ACTION_FINISH";
export const SET_PAYMENT_REQUESTS = "SET_PAYMENT_REQUESTS";
export const SET_PAYMENT_REQUESTS_SORTING = "SET_PAYMENT_REQUESTS_SORTING";
export const SET_PAYMENT_REQUESTS_FILTERS = "SET_PAYMENT_REQUESTS_FILTERS";
export const SET_BANK_FIELD_TRANSLATIONS_ACTION_BEFORE = "SET_BANK_FIELD_TRANSLATIONS_ACTION_BEFORE";
export const SET_BANK_FIELD_TRANSLATIONS_ACTION_FINISH = "SET_BANK_FIELD_TRANSLATIONS_ACTION_FINISH";
export const SET_BANK_FIELD_TRANSLATIONS = "SET_BANK_FIELD_TRANSLATIONS";

/** Payments History */

export const SET_PAYMENT_HISTORY_ACTION_BEFORE = "SET_PAYMENT_HISTORY_ACTION_BEFORE";
export const SET_PAYMENT_HISTORY_ACTION_FINISH = "SET_PAYMENT_HISTORY_ACTION_FINISH";
export const SET_PAYMENT_HISTORY = "SET_PAYMENT_HISTORY";
export const SET_PAYMENT_HISTORY_SORTING = "SET_PAYMENT_HISTORY_SORTING";
export const SET_PAYMENT_HISTORY_FILTERS = "SET_PAYMENT_HISTORY_FILTERS";

