import React, { useEffect } from 'react';

import logoSign from "assets/images/logo-sign.svg";
import logoSignWhite from "assets/images/logo-sign-white.svg";

import { getEnvironment } from 'utils/common';

import { ENVIRONMENT_TYPE } from 'constants/common.constants';

import particles from "utils/particles";

/** Auth Layout Sidebar component */
const AuthSidebar = () => {

    useEffect(() => {
        particles();
    }, [])


    return (
        <div className='rt--auth-sidebar'>
            {
                getEnvironment() === ENVIRONMENT_TYPE.RETAIL ? <img src={logoSignWhite} alt="logo" /> : <img src={logoSign} alt="logo" />
            }
        </div>
    )
}

export default AuthSidebar;