import React from 'react';

import {isMobile} from "utils/common";

import UsersFiltersDesktop from "pages/dashboard/tabs/usersTab/filters/desktop";
import UsersFiltersMobile from "pages/dashboard/tabs/usersTab/filters/mobile";
import {FILTER_TYPE} from "constants/common.constants";

const UsersFilters = ({ filters, onChange, currencyOptions }) => {
    const handleDesktopFiltersChange = (value) => {
        onChange("global", value);
    }

    const handleMobileFiltersChange = (value) => {
        onChange("", value);

        if (value.leaderboard?.betShopNameOrId) {
            handleDesktopFiltersChange({ filterType: FILTER_TYPE.NETWORK });
        }
    }

    if (isMobile()) {
        return (
            <UsersFiltersMobile
                filters={filters}
                currencyOptions={currencyOptions}
                onChange={handleMobileFiltersChange}
            />
        );
    }

    return (
        <UsersFiltersDesktop
            filters={filters}
            onChange={handleDesktopFiltersChange}
        />
    );
};

export default UsersFilters;
