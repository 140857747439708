import React from 'react';
import {useTranslation} from "react-i18next";

const NotificationsEmpty = () => {
    const { t } = useTranslation();

    return (
        <div className="rt--notifications-empty rt--flex rt--flex-col rt--align-center rt--justify-center">
            <>
                <i className="icon-speaker rt--font-bigest"/>
                <span
                    className="rt--title rt--font-small rt--font-bold rt--pt-16">{t('backoffice.notifications.noNotifications')}</span>
            </>
        </div>
    );
};

export default NotificationsEmpty;
