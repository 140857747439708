import PropTypes from 'prop-types';

import { WALLET_TYPE, WALLET_OWNER_TYPE } from "constants/wallet.constants"

export default PropTypes.shape({
    currencyCode: PropTypes.string,
    amount: PropTypes.number,
    type: PropTypes.oneOf(Object.values(WALLET_TYPE)),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ownerType: PropTypes.oneOf(Object.values(WALLET_OWNER_TYPE)),
})