import { useEffect, useState } from "react";

import { getFinancialLeaderBoardData } from "../api";

import { FINANCIAL_DASHBOARD_FILTER_NAMES } from "../constants";

const useFinancialLeaderboard = (dataType, filters, updateLastUpdatedDate, endpoint) => {
    const [leaderboardState, setLeaderboardState] = useState({
        leaderBoardData: [],
        isLoading: false,
    })

    useEffect(() => {
        if (!filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]) {
            return;
        }

        setLeaderboardState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getFinancialLeaderBoardData({
            ...filters,
            // filterType: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE],
            // currencyCode: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY],
            // timePeriod: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD],
            dataType,
            endpoint,
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;

                updateLastUpdatedDate(payload.lastUpdateAt)
                setLeaderboardState((prevState) => {
                    return {
                        ...prevState,
                        leaderBoardData: payload.data,
                        isLoading: false,
                    }
                })
            })
            .catch(() => {
                setLeaderboardState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                    }
                })
            })

        return () => {
            cancelRequest()
        }
    }, [dataType, filters, endpoint, updateLastUpdatedDate])

    return leaderboardState;
}

export default useFinancialLeaderboard;
