import { getUser } from 'utils/auth';

import { USER_ROLE } from "constants/user.constants";

/** Checks if has Access to block agent
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
export const hasAgentBlockAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT){
        if(userInfo.id === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowBlockNonDirectAgents;
    }

    return false;
}

/** Checks if has Access to reparent agent
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
export const hasAgentReparentAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT){
        if(userInfo.id === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowReparentNonDirectAgents;
    }

    return false;
}

/** Checks if has Access to delete agent
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
export const hasAgentDeleteAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT){
        if(userInfo.id === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowDeleteNonDirectAgents;
    }

    return false;
}

/** Checks if has Access to set pasword agent
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
export const hasAgentSetPasswordAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT){
        if(userInfo.id === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowSetPasswordNonDirectAgents;
    }

    return false;
}

/** Checks if has Access to do non direct transactions
     * @function
     * @params {object} userInfo - userInfo
     * @returns {boolean}
 */
export const hasAgentNonDirectTransactionAccess = userInfo => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    return userInfo.additionalAccesses?.allowNonDirectTransactions;
}

/** Checks if has Access to block player
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
export const hasPlayerBlockAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT || getUser()?.role === USER_ROLE.PLAYER_AGENT){
        if(userInfo.longId === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowBlockNonDirectPlayers;
    }

    return false;
}

/** Checks if has Access to block player
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
export const hasPlayerReparentAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.PLAYER_AGENT) return false;

    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT){
        if(userInfo.longId === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowReparentNonDirectPlayers;
    }

    return false;
}

/** Checks if has Access to set password player
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
 export const hasPlayerSetPasswordAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT || getUser()?.role === USER_ROLE.PLAYER_AGENT){
        if(userInfo.longId === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowSetPasswordNonDirectPlayers;
    }

    return false;
}

/** Checks if has Access to do non direct transactions to player
     * @function
     * @params {object} userInfo - userInfo
     * @returns {boolean}
 */
export const hasPlayerNonDirectTransactionAccess = userInfo => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    return userInfo.additionalAccesses?.allowNonDirectPlayerTransactions;
}

/** Checks if has Access to block player
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
export const hasBetshopBlockAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT){
        if(userInfo.id === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowBlockNonDirectBetShops;
    }

    return false;
}

/** Checks if has Access to block player
     * @function
     * @params {object} userInfo - userInfo
     * @params {string} parentId - agent parent id
     * @returns {boolean}
 */
 export const hasBetshopReparentAccess = ( userInfo, parentId) => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    if(getUser()?.role === USER_ROLE.AGENT){
        if(userInfo.id === parentId){
            return true;
        } 
        return userInfo.additionalAccesses.allowReparentNonDirectBetShops;
    }

    return false;
}

/** Checks if has Access to do non direct transactions to betshop
     * @function
     * @params {object} userInfo - userInfo
     * @returns {boolean}
 */
export const hasBetshopNonDirectTransactionAccess = userInfo => {
    if(getUser()?.role === USER_ROLE.ADMIN) return true;

    return userInfo.additionalAccesses?.allowNonDirectBetShopTransactions;
}