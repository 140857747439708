import React from 'react';

import PropTypes from 'prop-types';

import arFlag from "assets/images/flags/ar.svg";
import bgFlag from "assets/images/flags/bg.svg";
import bnFlag from "assets/images/flags/bn.svg";
import deFlag from "assets/images/flags/de.svg";
import enFlag from "assets/images/flags/en.svg";
import esFlag from "assets/images/flags/es.svg";
import faFlag from "assets/images/flags/fa.svg";
import frFlag from "assets/images/flags/fr.svg";
import heFlag from "assets/images/flags/he.svg";
import hiFlag from "assets/images/flags/hi.svg";
import hyFlag from "assets/images/flags/hy.svg";
import koFlag from "assets/images/flags/ko.svg";
import ptFlag from "assets/images/flags/pt.svg";
import roFlag from "assets/images/flags/ro.svg";
import ruFlag from "assets/images/flags/ru.svg";
import trFlag from "assets/images/flags/tr.svg";
import zhFlag from "assets/images/flags/zh.svg";

const mapping = {
    "ar": arFlag,
    "bg": bgFlag,
    "bn": bnFlag,
    "de": deFlag,
    "en": enFlag,
    "es": esFlag,
    "fa": faFlag,
    "fr": frFlag,
    "he": heFlag,
    "hi": hiFlag,
    "hy": hyFlag,
    "ko": koFlag,
    "pt": ptFlag,
    "ro": roFlag,
    "ru": ruFlag,
    "tr": trFlag,
    "zh": zhFlag
}

/** Flag Component */
const Flag = ({ 
    code
}) => {
    const flagPath = mapping[code.toLowerCase()] ? `url(${mapping[code.toLowerCase()]}` : null; 

    return (
        <div className="rt--flag">
            <div 
                className="rt--flag-icon" 
                style={
                    code ? { 
                        backgroundImage: flagPath
                    } : {}
                }
            />
        </div>
    )
}

/** Flag propTypes
    * PropTypes
*/
Flag.propTypes = {
    /** Flag code */
    code: PropTypes.string
}

export default Flag;