import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import Tooltip from 'components/common/tooltip';
import { Spin } from 'antd';

const CardView = ({
    totalsData,
    enableReload = true,
    tooltipText,
    onReloadIconClick,
    isLoading = false
}) => {
    const { t } = useTranslation();

    return (
        <Spin spinning={isLoading} style={{ maxHeight: "100px" }}>
            <div className="rt--flex rt--justify-between rt--align-center">
                <div className="rt--flex rt--align-center">
                    <h4 className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                        {t(`backoffice.bets.totals`)}
                    </h4>
                    {tooltipText && (
                        <Tooltip
                            title={tooltipText}
                            trigger={["hover", "click"]}
                            placement="top"
                            enableMobile={true}
                        >
                            <i className='icon-info rt--font-biger rt--mb-16 rt--mt-8 rt--ml-4' />
                        </Tooltip>
                    )}
                </div>

                {enableReload && (
                    <i
                        className="icon-reload rt--font-bigest rt--cursor-pointer rt--mb-16 rt--mt-8"
                        onClick={onReloadIconClick}
                    />
                )}
            </div>
            <div className='rt--flex rt--align-center rt--overflow-x rt--no-scrollbar rt--mb-16'>
                {totalsData.map(data => {
                    return (
                        <div
                            key={data.currency}
                            className="rt--widget-wallet rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8 rt--mr-16 rt--mb-4"
                        >
                            <div className='rt--flex rt--align-center rt--justify-center rt--widget-wallet-inner'>
                                <span className='rt--title rt--font-extra-bold rt--font-big rt--pr-12 rt--widget-wallet-title'>
                                    {data.currency.toUpperCase()}
                                </span>
                                <div className='rt--flex rt--flex-col rt--align-start rt--ml-16'>
                                    <b className='rt--title rt--font-regular rt--font-small'>
                                        {t("backoffice.common.balance")}:
                                    </b>
                                    <span className='rt--title rt--font-bold rt--font-big'>
                                        {data.balance}
                                    </span>
                                </div>
                                <div className='rt--flex rt--flex-col rt--align-start rt--ml-16'>
                                    <b className='rt--title rt--font-regular rt--font-small'>
                                        {t("backoffice.common.withdrawableBalance")}:
                                    </b>
                                    <span className='rt--title rt--font-bold rt--font-big'>
                                        {data.withdrawableBalance}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </Spin>

    );
}

/** CardView propTypes
 * PropTypes
*/
CardView.propTypes = {
    totalsData: PropTypes.array,
    enableReload: PropTypes.bool,
    isLoading: PropTypes.bool,
    tooltipText: PropTypes.string,
    onReloadIconClick: PropTypes.func,
};

export default CardView;