import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_GLOBAL_COMPANY_ID,
    SET_GLOBAL_PROJECT_ID,
    SET_GLOBAL_PROJECT_TYPE,
    SET_GLOBAL_PAYMENT_TYPE,
    SET_COMPANY_AVAILABLE_LANGUAGES_BEFORE,
    SET_COMPANY_AVAILABLE_LANGUAGES_FINISH,
    SET_COMPANY_AVAILABLE_LANGUAGES,
    SET_GAME_PROVIDERS,
    SET_GLOBAL_PLAT_VERSION, SET_GLOBAL_PROJECT_MODE
} from "../../../actionTypes";

export const setGlobalCompanyId = id => ({
    type: SET_GLOBAL_COMPANY_ID,
    payload: { id },
});

export const setGlobalProjectId = id => ({
    type: SET_GLOBAL_PROJECT_ID,
    payload: { id },
});

export const setGlobalProjectType = type => ({
    type: SET_GLOBAL_PROJECT_TYPE,
    payload: { type },
});

export const setGlobalProjectMode = mode => ({
    type: SET_GLOBAL_PROJECT_MODE,
    payload: { mode },
})

export const setGlobalPaymentType = type => ({
    type: SET_GLOBAL_PAYMENT_TYPE,
    payload: { type },
});

export const setGlobalPlatVersion = version => ({
    type: SET_GLOBAL_PLAT_VERSION,
    payload: { version },
})

const setCompanyAvailableLanguagesBefore = () => ({
    type: SET_COMPANY_AVAILABLE_LANGUAGES_BEFORE,
});

const setCompanyAvailableLanguagesFinished = () => ({
    type: SET_COMPANY_AVAILABLE_LANGUAGES_FINISH,
});

/** Company available languages */

const setCompanyAvailableLanguages = languages => ({
    type: SET_COMPANY_AVAILABLE_LANGUAGES,
    payload: { languages }
})

export const getCompanyAvailableLanguages = () => {
    return dispatch => {
        dispatch(setCompanyAvailableLanguagesBefore());
        return axios({
            url: ApiUrls.GET_COMPANY_AVAILABLE_LANGUAGES,
            method: Methods.GET
        })
        .then(({data : {value: languages}}) => {
            dispatch(setCompanyAvailableLanguages(languages))
            dispatch(setCompanyAvailableLanguagesFinished());
        })
        .catch((ex) => {
            dispatch(setCompanyAvailableLanguagesFinished());
        })
    }
}

/** Game providers */

const setGameProviders = providers => ({
    type: SET_GAME_PROVIDERS,
    payload: { providers }
})

export const getGameProviders = ()  => {
    return ( dispatch, getState ) => {
        const gameProviders = getState().common.gameProviders;
        if(gameProviders.length > 0) return;

        return axios({
            url: ApiUrls.GET_GAME_PROVIDERS,
            method: Methods.GET
        })
        .then(({data : {value: providers}}) => {
            dispatch(setGameProviders(providers || []))
        })
        .catch((ex) => {
            console.log(ex)
        })
    }
}
