import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Form, Row, Col, message } from "antd";

import TextAreaInput from 'components/common/textAreaInput';
import NumericInput from 'components/common/numericInput';

import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';
import { AMOUNT_REGEX } from 'constants/regex.constants';

import { TRANSFER_ENTITY_TYPE, TRANSFER_POPUP_OK_ACTION_EVENT_NAME, TRANSFER_SUCCESS_EVENT_NAME } from '../../../../constants';

import useTransfer from '../../../../hooks/useTransfer';

import { isResponseSuccess } from 'utils/request';
import { Version } from 'utils/version';
import { triggerCustomEvent } from 'utils/common';

import { amountValidator, formatNotes } from "../../../../helpers";

import {
    rollbackTransaction,
    confirmTransactionRequest,
    rejectTransactionRequest,
    adjustTransactionRequest
} from '../../../../api';

const operationsMapping = {
    [WALLET_TRANSACTION_TYPE.ROLLBACK] : rollbackTransaction,
    [WALLET_TRANSACTION_TYPE.CONFIRM_TRANSACTION_REQUEST] : confirmTransactionRequest,
    [WALLET_TRANSACTION_TYPE.REJECT_TRANSACTION_REQUEST] : rejectTransactionRequest,
    [WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST] : adjustTransactionRequest
}

const TransactionTransferForm = ({
    platVersion
}) => {
    const { t } = useTranslation();

    const defaultVersion = new Version("v1.0");
    const platformVersionInstance = new Version(platVersion);

    const { transferData, setSaving, setTransferResultData, hide: hideTransfer } = useTransfer();

    const { transactionType, additionalData } = transferData ?? {};

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const showAmountField = (
        transactionType === WALLET_TRANSACTION_TYPE.ROLLBACK ||
        transactionType === WALLET_TRANSACTION_TYPE.CONFIRM_TRANSACTION_REQUEST ||
        transactionType === WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST
    )

    const canChangeAmountField = transactionType === WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST || !defaultVersion.isLessThan(platformVersionInstance)


   const onTransferRequest = data => {
        if (isResponseSuccess(data)) {
            if(transactionType !== WALLET_TRANSACTION_TYPE.REJECT_TRANSACTION_REQUEST){
                const resp = data.value;
                if(resp){
                    setTransferResultData(resp?.wallets);
                    triggerCustomEvent(
                        TRANSFER_SUCCESS_EVENT_NAME,
                        {
                            transactionType : transactionType,
                            entityType: TRANSFER_ENTITY_TYPE.TRANSACTION,
                            wallets: resp?.wallets,
                            additionalData: {
                                widgetType: additionalData?.widgetType
                            }
                        }
                    );
                }
            } else {
                triggerCustomEvent(
                    TRANSFER_SUCCESS_EVENT_NAME,
                    {
                        transactionType : transactionType,
                        entityType: TRANSFER_ENTITY_TYPE.TRANSACTION,
                        additionalData: {
                            widgetType: additionalData?.widgetType
                        }
                    }
                );
                message.success(data.message);
                hideTransfer()
            }
        }
    }

    const handler = useCallback(() => {
        validateFields()
            .then(data => {
                setSaving(true);

                const d = {
                    notes: formatNotes(data.notes)
                }

                if(
                    transactionType === WALLET_TRANSACTION_TYPE.ROLLBACK ||
                    transactionType === WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST
                ){
                    d.groupId = additionalData?.groupId;
                } else {
                    d.id = additionalData?.transactionId;
                }

                if(
                    transactionType === WALLET_TRANSACTION_TYPE.CONFIRM_TRANSACTION_REQUEST ||
                    transactionType === WALLET_TRANSACTION_TYPE.ADJUST_TRANSACTION_REQUEST
                ){
                    d.amount = Number(data.amount)
                }

                operationsMapping[transactionType]?.(d, platVersion)
                    .then(data => onTransferRequest(data))
                    .finally(() => setSaving(false));

            }).catch(ex => {
                console.log(ex)
            })

    }, [transactionType])

    /** On Transfer */
    useEffect(() => {
        document.addEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);
        }
    }, [handler])

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
            initialValues={{
                amount: additionalData?.amount ?? 0
            }}
        >
            <Row gutter={[16, 0]}>
                {
                    showAmountField && (
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.wallet.amount')} *`}
                                name="amount"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') },
                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                    { validator: amountValidator }
                                ]}
                                validateFirst
                                className={"rt--general-form-item" + (!canChangeAmountField ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                    disabled={!canChangeAmountField}
                                />
                            </Form.Item>
                        </Col>
                    )
                }

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.wallet.notes')}
                        name="notes"
                        rules={[
                            {max: 1000, message: t('backoffice.validation.fieldInvalid')}
                        ]}
                        className="rt--form-item-without-margin rt--general-form-item"
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                    >
                        <TextAreaInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                            maxLength={1000}
                            rows={6}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

/** TransactionTransferForm propTypes
    * PropTypes
*/
TransactionTransferForm.propTypes = {
    /** Redux state property, Platform Version */
    platVersion: PropTypes.number
}

const mapStateToProps = state => ({
    platVersion: state.common.platVersion
})

export default connect(mapStateToProps, null)(TransactionTransferForm);
