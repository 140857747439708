import React from "react";
import PropTypes from "prop-types";

const Tag = ({ isMaxTag, label, onClose, disabled }) => {
    const handleCloseIconClick = (e) => {
        if (disabled) {
            return;
        }

        onClose(e);
    };

    return (
        <div className="rt--tag rt--flex rt--justify-between rt--align-center rt--pl-8 rt--pr-2 rt--mr-4">
            <span className={"rt--title rt--font-normal rt--font-regular rt--pr-4"}>
                {label}
            </span>
            {!isMaxTag && <i className="icon-close rt--font-big" onClick={handleCloseIconClick} />}
        </div>
    );
};

/** Tag propTypes
 * PropTypes
 */
Tag.propTypes = {
    /** Is maximum tag */
    isMaxTag: PropTypes.bool,
    /** Tag label */
    label: PropTypes.string,
    /** Callback which called on tag close icon click */
    onClose: PropTypes.func,
    /** Is disabled */
    disabled: PropTypes.bool,
};

export default Tag;
