import React from "react";

import { isFranchisingMode } from "utils/common";

import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';

export const getTableColumns = (additionalProps) => {
    const {
        formatAmount,
        totalType,
        hasLiteMode
    } = additionalProps;

    switch (totalType) {
        case REPORT_TOTAL_TYPE.BET_HISTORY:
            return [
                {
                    title: "backoffice.bets.currency",
                    dataIndex: "currency",
                    render: currencyCode => currencyCode.toUpperCase(),
                    mobileLevel: 1,
                },
                {
                    title: "backoffice.bets.amount",
                    dataIndex: "betAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 2,
                },
                {
                    title: "backoffice.bets.winAmount",
                    dataIndex: "winAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 3,
                },
                {
                    title: "backoffice.bets.payoutAmount",
                    dataIndex: "paidoutAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 3,
                },
                {
                    title: "backoffice.bets.ggr",
                    dataIndex: "ggr",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 4,
                },
                {
                    title: "backoffice.bets.ngr",
                    dataIndex: "ngr",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 5,
                },
            ];
        case REPORT_TOTAL_TYPE.REAL_TIME_BETS:
            return [
                {
                    title: "backoffice.bets.currency",
                    dataIndex: "currency",
                    render: (currencyCode) => currencyCode.toUpperCase(),
                    mobileLevel: 1,
                },
                {
                    title: "backoffice.bets.amount",
                    dataIndex: "amount",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 2,
                },
                {
                    title: "backoffice.bets.win",
                    dataIndex: "won",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 3,
                },
                {
                    title: "backoffice.bets.possibleWin",
                    dataIndex: "possibleWin",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 4,
                }
            ];
        case REPORT_TOTAL_TYPE.PLAYER_BETS:
            return [
                {
                    title: "backoffice.bets.currency",
                    dataIndex: "currency",
                    render: (currencyCode) => currencyCode.toUpperCase(),
                    mobileLevel: 1,
                },
                {
                    title: "backoffice.bets.amount",
                    dataIndex: "turnover",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 2,
                },
                {
                    title: "backoffice.bets.win",
                    dataIndex: "won",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 3
                }
            ];
        case REPORT_TOTAL_TYPE.PLAYER_PERFORMANCE_REPORT:
            return [
                {
                    title: "backoffice.bets.currency",
                    dataIndex: "currency",
                    render: (currencyCode) => currencyCode.toUpperCase(),
                    mobileLevel: 1,
                },
                // {
                //     title: "backoffice.performancereport.firstTimeDepositCount",
                //     dataIndex: "ftdCount",
                //     isNumeric: true,
                //     mobileLevel: 2,
                // },
                {
                    title: "backoffice.performancereport.depositCount",
                    dataIndex: "depositCount",
                    isNumeric: true,
                    mobileLevel: 2,
                },
                {
                    title: "backoffice.performancereport.depositAmount",
                    dataIndex: "depositAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 3,
                },
                {
                    title: "backoffice.performancereport.withdrawCount",
                    dataIndex: "withdrawCount",
                    isNumeric: true,
                    mobileLevel: 4,
                },
                {
                    title: "backoffice.performancereport.withdrawalAmount",
                    dataIndex: "withdrawAmount",
                    render: (value, record) => <span className='rt--table-withdrawal'>{formatAmount(value, record.currency)}</span>,
                    mobileLevel: 5,
                },
                {
                    title: "backoffice.performancereport.netDepositAmount",
                    dataIndex: "netDepositAmount",
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    ),
                    mobileLevel: 6
                },
                {
                    title: "backoffice.performancereport.turnover",
                    dataIndex: "turnover",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.winAmount",
                    dataIndex: "won",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.ggr",
                    dataIndex: "ggr",
                    render: (_, record) => (
                        <span className={record.ggr < 0 ? "rt--error-text" : ""}>
                            {formatAmount(record.ggr, record.currency)}
                        </span>
                    )
                },
                !hasLiteMode && {
                    title: "backoffice.performancereport.otherCosts",
                    dataIndex: "otherCosts",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                !hasLiteMode && {
                    title: "backoffice.performancereport.ngr",
                    dataIndex: "ngr",
                    render: (_, record) => (
                        <span className={record.ngr < 0 ? "rt--error-text" : ""}>
                            {formatAmount(record.ngr, record.currency)}
                        </span>
                    )
                }
            ];
        case REPORT_TOTAL_TYPE.AGENT_PERFORMANCE_REPORT:
            return [
                {
                    title: "backoffice.bets.currency",
                    dataIndex: "currency",
                    render: (currencyCode) => currencyCode.toUpperCase(),
                    mobileLevel: 1,
                },
                {
                    title: "backoffice.performancereport.playerSignUps",
                    dataIndex: "playerSignups",
                    isNumeric: true,
                    mobileLevel: 2,
                },
                {
                    title: "backoffice.performancereport.firstTimeDepositCount",
                    dataIndex: "firstTimeDepositCount",
                    isNumeric: true,
                },
                {
                    title: "backoffice.performancereport.depositCount",
                    dataIndex: "depositCount",
                    isNumeric: true,
                    mobileLevel: 3,
                },
                {
                    title: "backoffice.performancereport.depositAmount",
                    dataIndex: "depositAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 4,
                },
                {
                    title: "backoffice.performancereport.withdrawCount",
                    dataIndex: "withdrawCount",
                    isNumeric: true,
                    mobileLevel: 5,
                },
                {
                    title: "backoffice.performancereport.withdrawalAmount",
                    dataIndex: "withdrawAmount",
                    render: (value, record) => <span className='rt--table-withdrawal'>{formatAmount(value, record.currency)}</span>,
                    mobileLevel: 6,
                },
                {
                    title: "backoffice.performancereport.netDepositAmount",
                    dataIndex: "netDepositAmount",
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    )
                },
                {
                    title: "backoffice.performancereport.turnover",
                    dataIndex: "turnover",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.winAmount",
                    dataIndex: "won",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.ggr",
                    dataIndex: "ggr",
                    render: (_, record) => (
                        <span className={record.ggr < 0 ? "rt--error-text" : ""}>
                            {formatAmount(record.ggr, record.currency)}
                        </span>
                    )
                },
                !hasLiteMode && {
                    title: "backoffice.performancereport.otherCosts",
                    dataIndex: "otherCosts",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                !hasLiteMode && {
                    title: "backoffice.performancereport.ngr",
                    dataIndex: "ngr",
                    render: (_, record) => (
                        <span className={record.ngr < 0 ? "rt--error-text" : ""}>
                            {formatAmount(record.ngr, record.currency)}
                        </span>
                    )
                },
                !hasLiteMode && {
                    title: "backoffice.performancereport.networkCommissions",
                    dataIndex: "networkCommissions",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                !hasLiteMode && {
                    title: "backoffice.performancereport.directCommissions",
                    dataIndex: "directCommissions",
                    render: (value, record) => formatAmount(value, record.currency)
                },
            ];
        case REPORT_TOTAL_TYPE.BETSHOP_PERFORMANCE_REPORT:
            return [
                {
                    title: "backoffice.bets.currency",
                    dataIndex: "currency",
                    render: (currencyCode) => currencyCode.toUpperCase(),
                    mobileLevel: 1,
                },
                {
                    title: "backoffice.performancereport.depositCount",
                    dataIndex: "depositCount",
                    isNumeric: true,
                    mobileLevel: 2,
                },
                {
                    title: "backoffice.performancereport.depositAmount",
                    dataIndex: "depositAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                    mobileLevel: 3,
                },
                {
                    title: "backoffice.performancereport.withdrawCount",
                    dataIndex: "withdrawCount",
                    isNumeric: true,
                    mobileLevel: 4,
                },
                {
                    title: "backoffice.performancereport.withdrawalAmount",
                    dataIndex: "withdrawAmount",
                    render: (value, record) => <span className='rt--table-withdrawal'>{formatAmount(value, record.currency)}</span>,
                    mobileLevel: 5,
                },
                {
                    title: "backoffice.performancereport.netDepositAmount",
                    dataIndex: "netDepositAmount",
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    )
                },
                {
                    title: "backoffice.performancereport.betCount",
                    dataIndex: "betCount",
                    isNumeric: true,
                },
                {
                    title: "backoffice.performancereport.betAmount",
                    dataIndex: "betAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.winBetCount",
                    dataIndex: "wonCount",
                    isNumeric: true,
                },
                {
                    title: "backoffice.performancereport.winAmount",
                    dataIndex: "wonAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.payoutCount",
                    dataIndex: "paidoutCount",
                    isNumeric: true,
                },
                {
                    title: "backoffice.performancereport.payoutAmount",
                    dataIndex: "paidoutAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.bonusBetAmount",
                    dataIndex: "bonusBetAmount",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                {
                    title: "backoffice.performancereport.bonusWin",
                    dataIndex: "bonusWonAmount",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                {
                    title: "backoffice.performancereport.bonusRedeem",
                    dataIndex: "bonusRedeemAmount",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                {
                    title: "backoffice.performancereport.wonNotClaimedBetCount",
                    dataIndex: "wonNotClaimedBetCount",
                    isNumeric: true,
                },
                {
                    title: "backoffice.performancereport.wonNotClaimedBetAmount",
                    dataIndex: "wonNotClaimedBetAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.cancelledBetCount",
                    dataIndex: "cancelledBetsCount",
                    isNumeric: true,
                },
                {
                    title: "backoffice.performancereport.cancelledBetAmount",
                    dataIndex: "cancelledBetsAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.registeredPlayers",
                    dataIndex: "playerSignups",
                    isNumeric: true,
                },
                {
                    title: "backoffice.performancereport.taxAmount",
                    dataIndex: "taxAmount",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                {
                    title: "backoffice.performancereport.totalIn",
                    dataIndex: "totalInAmount",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                {
                    title: "backoffice.performancereport.totalOut",
                    dataIndex: "totalOutAmount",
                    render: (value, record) => formatAmount(value, record.currency),
                },
                {
                    title: "backoffice.performancereport.ggr",
                    dataIndex: "gGR",
                    mobileLevel: 5,
                    render: (_, record) => (
                        <span className={record.ggr < 0 ? "rt--error-text" : ""}>
                            {formatAmount(record.ggr, record.currency)}
                        </span>
                    )
                },
                {
                    title: "backoffice.performancereport.ngr",
                    dataIndex: "nGR",
                    render: (_, record) => (
                        <span className={record.ngr < 0 ? "rt--error-text" : ""}>
                            {formatAmount(record.ngr, record.currency)}
                        </span>
                    )
                },
            ]
        case REPORT_TOTAL_TYPE.PRODUCT_REPORT:
            return [
                {
                    title: "backoffice.performancereport.betAmount",
                    dataIndex: "betAmount",
                    render: (value, record) => formatAmount(value, record.currencyCode),
                    mobileLevel: 1
                },
                {
                    title: "backoffice.performancereport.winAmount",
                    dataIndex: "winAmount",
                    render: (value, record) => formatAmount(value, record.currencyCode),
                    mobileLevel: 2
                },
                {
                    title: "backoffice.performancereport.ggr",
                    dataIndex: "ggr",
                    render: (value, record) => formatAmount(value, record.currencyCode),
                    mobileLevel: 3
                },
                {
                    title: "backoffice.performancereport.earning",
                    dataIndex: "earning",
                    render: (value, record) => formatAmount(value, record.currencyCode),
                    mobileLevel: 4
                },
                {
                    title: "backoffice.performancereport.toPay",
                    dataIndex: "toPay",
                    render: (value, record) => formatAmount(value, record.currencyCode),
                    mobileLevel: 5
                },
            ]
        case REPORT_TOTAL_TYPE.NEW_PRODUCT_REPORT:
            return [
                {
                    title: "backoffice.reports.currency",
                    dataIndex: "currency",
                    mobileLevel: 1,
                    render: currencyCode => currencyCode.toUpperCase(),
                },
                {
                    title: "backoffice.reports.sportTurnover",
                    dataIndex: "sportTurnover",
                    mobileLevel: 2,
                    render: (value, record) => formatAmount(value, record.currency)
                },
                {
                    title: "backoffice.reports.sportGgr",
                    dataIndex: "sportGGR",
                    mobileLevel: 3,
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    ),
                },
                {
                    title: "backoffice.reports.sportNgr",
                    dataIndex: "sportNGR",
                    mobileLevel: 4,
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    ),
                },
                {
                    title: "backoffice.reports.casinoTurnover",
                    dataIndex: "casinoTurnover",
                    mobileLevel: 5,
                    render: (value, record) => formatAmount(value, record.currency)
                },
                {
                    title: "backoffice.reports.casinoGgr",
                    dataIndex: "casinoGGR",
                    mobileLevel: 6,
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    ),
                },
                {
                    title: "backoffice.reports.casinoNgr",
                    dataIndex: "casinoNGR",
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    ),
                },
                {
                    title: "backoffice.reports.totalTurnover",
                    dataIndex: "totalTurnover",
                    render: (value, record) => formatAmount(value, record.currency)
                },
                {
                    title: "backoffice.reports.totalGgr",
                    dataIndex: "totalGGR",
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    ),
                },
                {
                    title: "backoffice.reports.totalNgr",
                    dataIndex: "totalNGR",
                    render: (value, record) => (
                        <span className={value < 0 ? "rt--error-text" : ""}>
                            {formatAmount(value, record.currency)}
                        </span>
                    ),
                },
            ]
        case REPORT_TOTAL_TYPE.AGENTS:
            return [
                {
                    title: "backoffice.agents.currency",
                    dataIndex: "currency",
                    render: (currencyCode) => currencyCode.toUpperCase(),
                    mobileLevel: 1,
                },
                !isFranchisingMode() && {
                    title: "backoffice.common.balance",
                    dataIndex: "balance",
                    mobileLevel: 2,
                    render: (value, record) => formatAmount(value, record.currency),
                },
                !hasLiteMode && {
                    title: "backoffice.common.credit",
                    dataIndex: "credit",
                    mobileLevel: 3,
                    render: (value, record) => formatAmount(value, record.currency),
                },
                !hasLiteMode && {
                    title: "backoffice.common.creditLine",
                    dataIndex: "creditLine",
                    mobileLevel: 4,
                    render: (value, record) => formatAmount(value, record.currency),
                },
            ]
        default:
            return [];
    }
}
