import PropTypes from 'prop-types';

import { USER_STATE } from 'constants/user.constants';

export default PropTypes.shape({
    id: PropTypes.number,
    agentUserName: PropTypes.string,
    agentLevel: PropTypes.number,
    agentState: PropTypes.oneOf(Object.values(USER_STATE)),
    agentRegistrationDate: PropTypes.string,
    playerSignups: PropTypes.number,
    depositUniqueCount: PropTypes.number,
    withdrawUniqueCount: PropTypes.number,
    withdrawCount: PropTypes.number,
    firstTimeDepositCount: PropTypes.number,
    performanceDetails: PropTypes.arrayOf(PropTypes.shape({
        currencyCode: PropTypes.string,
        playerSignups: PropTypes.number,
        depositCount: PropTypes.number,
        depositUniqueCount: PropTypes.number,
        withdrawUniqueCount: PropTypes.number,
        withdrawCount: PropTypes.number,
        withdrawAmount: PropTypes.number,
        depositAmount: PropTypes.number,
        netDepositAmount: PropTypes.number,
        turnover: PropTypes.number,
        won: PropTypes.number,
        ggr: PropTypes.number,
        ngr: PropTypes.number,
        otherCosts: PropTypes.number,
        directCommissions: PropTypes.number,
        networkCommissions: PropTypes.number,
        firstTimeDepositCount: PropTypes.number
    }))
})
