import {ROUTES} from "NEW/src/core/data/constants/routes";
import withAnonymousHoc from "NEW/src/core/HOCs/withAnonymous";
import {loginPageRoute} from "NEW/src/pages/login";
import {Navigate, Route} from "react-router-dom";
import AuthLayout from "NEW/src/layouts/global/auth";
import {resetPasswordPageRoute} from "NEW/src/pages/resetPassword";
import AppWrapper from "NEW/src/app/appWrapper";
import BubbleError from "NEW/src/app/providers/routerProvider/bubbleError";
import NEWAppProviders from "NEW/INTEGRATION/NEWAppProviders";

const UnAuthorizedUserLayout = withAnonymousHoc(AuthLayout);

const routes = [
    {
        key: 'root',
        element: (
            <NEWAppProviders>
                <AppWrapper />
            </NEWAppProviders>
        ),
        errorElement: <BubbleError />,
        children: [
            {
                path: ROUTES.LOGIN,
                element: <UnAuthorizedUserLayout />,
                children: [loginPageRoute, resetPasswordPageRoute],
            },
            {
                path: "*", // handle all unfamiliar paths
                element: <Navigate to={ROUTES.ROOT} replace />,
            }
        ]
    },
]

const mapToElements = (items) => {
    if (!items) return null;
    return items.map((route) => <Route key={route.key || route.path} path={route.path} element={route.element} children={mapToElements(route.children)} />);
}

export const ELEMENT_ROUTES = mapToElements(routes);
