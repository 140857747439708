import {SET_PROJECT_AGENT_ADMIN_FEE} from "store/actionTypes";
import axios from "axios";
import Methods from "constants/methods.constants";
import {
    setProjectsActionBefore,
    setProjectsActionFinished,
    setProjectsSaveActionBefore, setProjectsSaveActionFinished
} from "store/actions/dashboard/projects/projects.action";
import ApiUrls from "constants/api.constants";
import {isResponseSuccess} from "utils/request";
import {message} from "antd";

const setProjectAgentAdministrativeFee = (administrativeFee) => ({
    type: SET_PROJECT_AGENT_ADMIN_FEE,
    payload: { administrativeFee }
})

export const getProjectAgentAdministrativeFee = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        axios({
            url: ApiUrls.GET_PROJECT_AGENT_ADMIN_FEE,
            method: Methods.GET,
        }).then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setProjectAgentAdministrativeFee(data.value))
            }
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectAgentAdministrativeFee = (data) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());

        axios({
            url: ApiUrls.SAVE_PROJECT_AGENT_ADMIN_FEE,
            method: Methods.POST,
            data
        }).then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectAgentAdministrativeFee(data.value))
            }
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
