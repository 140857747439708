import React, { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Form, Row, Col } from 'antd';

import TextAreaInput from 'components/common/textAreaInput';
import NumericInput from 'components/common/numericInput';

import { isResponseSuccess } from 'utils/request';
import { triggerCustomEvent } from 'utils/common';

import { amountValidator, formatNotes} from "../../../../helpers";


import { AMOUNT_REGEX } from 'constants/regex.constants';

import { TRANSFER_ENTITY_TYPE, TRANSFER_POPUP_OK_ACTION_EVENT_NAME, TRANSFER_SUCCESS_EVENT_NAME } from '../../../../constants';

import { playerEposDeposit } from '../../../../api';

import useTransfer from '../../../../hooks/useTransfer';

import playerType from 'types/player/player.type';
import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';

const EposDepositPlayerForm = ({
    player
}) => {

    const { t } = useTranslation();

    const { setSaving, setTransferResultData } = useTransfer();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const onTransferRequest = data => {
        if (isResponseSuccess(data)) {
            const resp = data.value;
            if(resp){
                setTransferResultData(resp?.wallets);
                triggerCustomEvent(
                    TRANSFER_SUCCESS_EVENT_NAME,
                    {
                        transactionType : WALLET_TRANSACTION_TYPE.PLAYER_DEPOSIT,
                        entityType: TRANSFER_ENTITY_TYPE.PLAYER,
                        wallets: resp?.wallets
                    }
                );
            }
        }
    }

    const handler = useCallback(() => {
        validateFields()
            .then(data => {
                setSaving(true);

                const d = {
                    playerId: player?.id,
                    amount: Number(data.amount),
                    notes: formatNotes(data.notes)
                }

                playerEposDeposit(d)
                    .then(data => onTransferRequest(data))
                    .finally(() => setSaving(false));

            }).catch(ex => {
                console.log(ex)
            })

    }, [])

    /** On Transfer */
    useEffect(() => {
        document.addEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);
        }
    }, [handler])

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={`${t('backoffice.wallet.amount')} *`}
                        name="amount"
                        rules={[
                            { required: true, message: t('backoffice.validation.fieldRequired') },
                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                            { validator: amountValidator }
                        ]}
                        validateFirst
                        className="rt--general-form-item"
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                    >
                        <NumericInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.wallet.notes')}
                        name="notes"
                        rules={[
                            { max: 1000, message: t('backoffice.validation.fieldInvalid') }
                        ]}
                        className="rt--form-item-without-margin rt--general-form-item"
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                    >
                        <TextAreaInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                            maxLength={1000}
                            rows={6}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

EposDepositPlayerForm.propTypes = {
    player: playerType
}

export default EposDepositPlayerForm;
