import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import Input from 'components/common/input';
import DateRangePicker from 'components/common/dateRangePicker';

import { getAgentSubAgents, setAgentSubAgentsFilters } from "store/actions/dashboard/agentSystem/agents/agents.action";

import { FILTER_USER_STATE, FILTER_COUNTRY } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

import countries from "systemData/countries";


/** Agent Page Sub agents Filters Component */

const Filters = ({
    setAgentSubAgentsFilters,
    getAgentSubAgents,
    filters,
    agentId
}) => {
    const { t } = useTranslation();

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT, 
        actionParams: { id: agentId }, 
        autoGet: false
    });

    return (
        <div onClick={e => e.stopPropagation()}>
            <FiltersWrapper
                loadFn={() => getAgentSubAgents(agentId)}
                setFiltersFn={setAgentSubAgentsFilters}
                filters={filters}
                updateProps={[agentId]}
                datePickerFields={[
                    { name: "date", time: false },
                    { name: "lastLogin", time: false }
                ]}
                onInit={() => {
                    getAgentNames();
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.agents.usernameOrId')}
                            name="nameOrId"
                        >
                            <AutoComplete
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.usernameOrId')}`}
                                items={agentNames}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.common.status')}
                            name="state"
                        >
                            <Select
                                options={[
                                    { value: FILTER_USER_STATE.ALL, text: t('backoffice.common.all') },
                                    { value: FILTER_USER_STATE.IN_PROGRESS, text: t('backoffice.common.inactive') },
                                    { value: FILTER_USER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                    { value: FILTER_USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                    { value: FILTER_USER_STATE.DELETED, text: t('backoffice.common.deleted') }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.agents.email')}
                            name="email"
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.email')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.email')}`}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.agents.country')}
                            name="countries"
                        >
                            <Select
                                options={
                                    [
                                        { value: FILTER_COUNTRY.NONE, text: t('backoffice.common.none') },
                                        ...(
                                            countries.map(c => (
                                                { value: c.iso2, text: t(`backoffice.countries.${c.iso2}`) }
                                            ))
                                        )
                                    ]

                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.agents.country')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                isMultiple={true}
                                search={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.agents.registrationDate')}
                            name="date"
                        >
                            <DateRangePicker showTime={false} />
                        </Form.Item>
                    </Col>

                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.agents.lastLoginDate')}
                            name="lastLogin"
                            className='rt--form-item-without-margin'
                        >
                            <DateRangePicker showTime={false} />
                        </Form.Item>
                    </Col>
                </Row>
            </FiltersWrapper>
        </div>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get agent sub agents list */
    getAgentSubAgents: PropTypes.func,
    /** Redux action to set agent sub agents list filters */
    setAgentSubAgentsFilters: PropTypes.func,
    /** Redux state property, agent sub agents filters */
    filters: PropTypes.object,
    /** Agent id */
    agentId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAgentSubAgents: agentId => {
            dispatch(getAgentSubAgents(agentId))
        },
        setAgentSubAgentsFilters: filters => {
            dispatch(setAgentSubAgentsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.agents.subAgents.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);