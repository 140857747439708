import {
    SET_CALCULATE_REPORTS_ACTION_BEFORE,
    SET_CALCULATE_REPORTS_ACTION_FINISH
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_CALCULATE_REPORTS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_CALCULATE_REPORTS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
};
