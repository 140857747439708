//#region react
import React, { useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getAgentSubAgents,
    setAgentSubAgentsSorting,
    setAgentBalanceOrCredit
} from "store/actions/dashboard/agentSystem/agents/agents.action";
import { switchAccount } from "store/actions/auth/auth.action";
//#endregion

//#region components
import Table from "components/common/table";
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useProjectType from "hooks/useProjectType";
import { TRANSFER_ENTITY_TYPE, useTransfer } from 'components/common/transfer';
//#endregion

//#region utils
import { isFranchisingMode } from "utils/common";
import { getUser } from "utils/auth"
import {
    getDisabledExpandRowsIds,
    canTransferToAgent,
    canPayCommissionToAgent,
} from 'utils/agents';
import { hasPermission } from 'utils/permissions';
import { hasAgentEditPageAccess } from "utils/pageAccess";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { USER_STATE, USER_ROLE } from "constants/user.constants";
import Paths from 'constants/path.constants';
import { getExpandTableColumns, getTableColumns } from "./columns";
//#endregion

//#region types
import agentType from "types/agent/agent.type";
import sortingType from "types/common/sorting.type";
import userInfoType from 'types/profile/userInfo.type';
//#endregion

/** Agent Details Page Sub Agents List Component */
const SubAgentsComponent = ({
    getAgentSubAgents,
    agents,
    isLoading,
    total,
    setAgentSubAgentsSorting,
    sorting,
    globalProjectId,
    globalCompanyId,
    userInfo,
    agentId,
    switchAccount,
    addToNavBar,
    setAgentBalanceOrCredit
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { formatAmount } = useFormat();
    const { hasRetail, hasLiteMode } = useProjectType();

    const { show: showTransfer} = useTransfer();

    const franchisingMode = isFranchisingMode();

    const disabledExpandRowsIds = getDisabledExpandRowsIds(agents);

    const { role, userState } = getUser();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasAgentWalletsModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_WALLETS,
        action: PERMISSION_ACTION.MODIFY
    })

    const hasAgentModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT,
        action: PERMISSION_ACTION.MODIFY
    })

    const canNavigateToAgentEditPage = hasAgentEditPageAccess();

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleRightArrowIconClick = (record) => {
        const path = isFranchisingMode() ? Paths.BETSHOP_OWNERS_EDIT : Paths.AGENTS_EDIT;
        navigate(
            `${path}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.userName}` +
            `&role=${record.role}`
        )
    }

    const handleUserNameClick = useCallback((record) => {
        addToNavBar({
            navBarTitle: record.userName,
            navBarKey: record.id,
            ...record,
        });
    }, [])


    const setTransferPopup = (record, additionalData={}) => {
        const transferData = {
            entityType: TRANSFER_ENTITY_TYPE.AGENT,
            userId: record.id,
            ...additionalData
        }

        showTransfer(transferData);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        expandTableColumns,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns: getExpandTableColumns,
            additionalProps: {
                userInfo,
                hasRetail,
                franchisingMode,
                formatAmount,
                hasLiteMode,
                onUserNameClick: handleUserNameClick,
                canTransferToAgent,
                setTransferPopup
            }
        })
    }, [franchisingMode, hasRetail, handleUserNameClick, formatAmount])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (userState === USER_STATE.ACTIVE) {
        if (role !== USER_ROLE.ADMIN || hasAgentWalletsModifyPermission) {
            if (!hasLiteMode) {
                tableRowActions.push(
                    {
                        title: t("backoffice.agents.transfer"),
                        icon: "icon-transfer",
                        onClick: record => setTransferPopup(record),
                        disabled: record => !canTransferToAgent({ agentData: record, userInfo }),
                    },
                );

                tableRowActions.push(
                    {
                        title: t("backoffice.wallet.payCommission"),
                        icon: "icon-payCommission",
                        onClick: record => {
                            setTransferPopup(record, {
                                transactionType: WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT
                            });
                        },
                        disabled: record => !canPayCommissionToAgent({ agentData: record, userInfo }),
                    },
                );
            }
        }

    }

    if (role === USER_ROLE.ADMIN && hasAgentModifyPermission) {
        tableRowActions.push({
            title: isFranchisingMode() ? t("backoffice.common.loginAsBetshopOwner") : t("backoffice.common.loginAsAgent"),
            icon: "icon-loginAsAgent",
            onClick: record => switchAccount(record.id),
            disabled: record => record.state !== USER_STATE.IN_PROGRESS && record.state !== USER_STATE.ACTIVE && record.state !== USER_STATE.BLOCKED,
        })
    }

    if (canNavigateToAgentEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick,
            disabled: record => record.state === USER_STATE.DELETED || !hasAgentEditPageAccess(record.role)
        })
    }

    //#endregion


    return (
        <>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={agents}
                loadFn={nextPage => getAgentSubAgents(agentId, nextPage)}
                sorting={sorting}
                setSortingFn={setAgentSubAgentsSorting}
                total={total}
                updateProps={[agentId]}
                actions={tableRowActions}
                expandable={(
                    !hasLiteMode
                        ? {
                            title: t("backoffice.agents.currencies"),
                            disabled: disabledExpandRowsIds,
                            details: (rowData) => ({
                                columns: expandTableColumns,
                                data: rowData?.wallets,
                            }),
                        }
                        : null
                )}
            />

        </>
    );
};

/** SubAgentsComponent propTypes
 * PropTypes
*/
SubAgentsComponent.propTypes = {
    /** Redux action to get agent sub agents */
    getAgentSubAgents: PropTypes.func,
    /** Redux state property, represents the array of sub agents  */
    agents: PropTypes.arrayOf(agentType),
    /** Redux state property, is true when loading sub agents */
    isLoading: PropTypes.bool,
    /** Redux state property, sub agents total count */
    total: PropTypes.number,
    /** Redux action to set sub agents sorting details */
    setAgentSubAgentsSorting: PropTypes.func,
    /** Redux state property, agent sub agents sorting details */
    sorting: sortingType,
    /** Redux action to set agents balance or credit */
    setAgentBalanceOrCredit: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Agent id */
    agentId: PropTypes.string,
    /** Redux action to switch account */
    switchAccount: PropTypes.func,
    /** Function to update dashboard navBar */
    addToNavBar: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getAgentSubAgents: (agentId, nextPage) => {
        dispatch(getAgentSubAgents(agentId, nextPage));
    },
    setAgentSubAgentsSorting: sorting => {
        dispatch(setAgentSubAgentsSorting(sorting));
    },
    switchAccount: id => {
        dispatch(switchAccount(id));
    },
    setAgentBalanceOrCredit: wallets => {
        dispatch(setAgentBalanceOrCredit(wallets));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.agents.subAgents.isLoading,
        agents: state.agents.subAgents.agents,
        total: state.agents.subAgents.total,
        sorting: state.agents.subAgents.sorting,
        globalProjectId: state.common.globalProjectId,
        globalCompanyId: state.common.globalCompanyId,
        userInfo: state.profile.userInfo
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubAgentsComponent);
