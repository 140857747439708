import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import UsersComponent from '../edit/sections/users';

import { isFranchisingMode } from 'utils/common';

import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION, PERMISSION_TYPE } from "constants/permissions.constants";
import { USER_TYPE } from "constants/user.constants";

/** Permission Group Edit Page Component */
const PermissionGroupEditComponent = () => {
    const { t } = useTranslation();

    const { search } = useLocation();

    const userType = Number((new URLSearchParams(search)).get("type") || USER_TYPE.ADMIN);

    const entityGroupType = (new URLSearchParams(search)).get("groupType");

    const items = [
        {
            title: t("backoffice.permissiongroups.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title: userType === USER_TYPE.ADMIN ? t("backoffice.permissiongroups.admins") : 
                                    isFranchisingMode() ? t("backoffice.permissiongroups.betshopOwners") : 
                                        Number(entityGroupType) === PERMISSION_TYPE.AGENT_PLAYER ? 
                                        t("backoffice.permissiongroups.playerAgents") : t("backoffice.permissiongroups.agents"),
            permissions: [{ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW }],
            component: <UsersComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.permissionGroups'), path: `${Paths.PERMISSION_GROUPS}?type=${userType}` }}
        />
    )
}


export default PermissionGroupEditComponent;