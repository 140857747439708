import React from "react";
import i18n from "translations/config";

import Status from "components/common/status";

import { STATUS_TOOLTIP_PLACEMENT, STATUS_TYPES } from "constants/status.constants";

export const tableColumns = [
    {
        title: "backoffice.jobs.name",
        dataIndex: "name",
        width: 300,
        alwaysVisible: true,
        mobileLevel: 1,
        tooltip: (_, record) => (
            <span>
                <b>{i18n.t("backoffice.jobs.cronExpression") + ": "}</b>
                <span>{record.cronExpression}</span>
                <br />
                <b>{i18n.t("backoffice.jobs.cronDescription") + ": "}</b>
                <span>{record.cronDescription}</span>
            </span>
        ),
    },
    {
        title: "backoffice.jobs.state",
        dataIndex: "state",
        mobileLevel: 6,
        render: (state, record) => (
            <Status
                type={STATUS_TYPES.JOB}
                status={state}
                tooltipOptions={{
                    placement: STATUS_TOOLTIP_PLACEMENT.WHOLE_BLOCK,
                    title: record.message
                }}
            />
        ),
    },
    {
        title: "backoffice.jobs.elapsedMilliseconds",
        dataIndex: "elapsedMilliseconds",
        mobileLevel: 2,
    },
    {
        title: "backoffice.jobs.startTime",
        dataIndex: "startTime",
        isDateTime: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.jobs.nextFireTime",
        dataIndex: "nextFireTime",
        isDateTime: true,
        mobileLevel: 4,
    },
    {
        title: "backoffice.jobs.serverName",
        dataIndex: "serverName",
        mobileLevel: 5,
    },
]

export const expandColumns = [
    {
        title: "backoffice.jobs.state",
        dataIndex: "state",
        mobileLevel: 1,
        render: (state, record) => (
            <Status
                type={STATUS_TYPES.JOB}
                status={state}
                tooltipOptions={{
                    placement: STATUS_TOOLTIP_PLACEMENT.WHOLE_BLOCK,
                    title: record.message
                }}
            />
        ),
    },
    {
        title: "backoffice.jobs.elapsedMilliseconds",
        dataIndex: "elapsedMilliseconds",
        mobileLevel: 2,
    },
    {
        title: "backoffice.jobs.startTime",
        dataIndex: "startTime",
        mobileLevel: 3,
        isDateTime: true
    },
    {
        title: "backoffice.jobs.nextFireTime",
        dataIndex: "nextFireTime",
        mobileLevel: 4,
        isDateTime: true
    },
    {
        title: "backoffice.jobs.serverName",
        dataIndex: "serverName",
        mobileLevel: 5,
    },
];
