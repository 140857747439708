import {useEffect, useState} from "react";
import {DASHBOARD_TAB_NAMES} from "pages/dashboard/tabs/constants";
import {DATA_TYPES, ENTITY_TYPE, TIME_PERIOD} from "pages/dashboard/constants";
import {FILTER_TYPE} from "constants/common.constants";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";
import {useSelector} from "react-redux";
import sessionStorage from "utils/sessionStorage";

const getDefaultFilters = (hasRetail) => ({
    [DASHBOARD_TAB_NAMES.STATISTICS]: {
        global: {
            betShopNameOrId: "",
            timePeriod: TIME_PERIOD.TODAY,
            entityType: hasRetail ? ENTITY_TYPE.BETSHOP : ENTITY_TYPE.PLAYER,
            filterType: FILTER_TYPE.NETWORK,
            currencyCode: ""
        }
    },
    [DASHBOARD_TAB_NAMES.USERS]: {
        global: {
            filterType: FILTER_TYPE.NETWORK,
        },
        leaderboard: {
            betShopNameOrId: "",
            timePeriod: TIME_PERIOD.TODAY,
            dataType: DATA_TYPES.GGR,
            currencyCode: ""
        }
    }
})

const useDashboardState = (hasRetail, currencies) => {
    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
    const [activeTab, setActiveTab] = useState(DASHBOARD_TAB_NAMES.STATISTICS);
    const [filters, setFilters] = useState(() => getDefaultFilters(hasRetail));

    const globalProjectId = useSelector(state => state.common.globalProjectId);

    const { role } = getUser();

    useEffect(() => {
        setFilters(() => getDefaultFilters(hasRetail));
    }, [globalProjectId, hasRetail]);

    useEffect(() => {
        if (currencies.length) {
            setFilters(state => {
                if (
                    !state[DASHBOARD_TAB_NAMES.STATISTICS].global.currencyCode ||
                    !state[DASHBOARD_TAB_NAMES.USERS].leaderboard.currencyCode
                ) {
                    let currencyFilterInitialValue = "";

                    if (role === USER_ROLE.ADMIN) {
                        currencyFilterInitialValue = currencies.find(currencyData => currencyData.currency.isDefault).currencyCode;
                    }

                    if (role === USER_ROLE.AGENT) {
                        const userSelectedCurrency = sessionStorage.get("currency");
                        currencyFilterInitialValue = userSelectedCurrency ? userSelectedCurrency : currencies.at(0).currencyCode;
                    }

                    return {
                        ...state,
                        [DASHBOARD_TAB_NAMES.STATISTICS]: {
                            ...state[DASHBOARD_TAB_NAMES.STATISTICS],
                            global: {
                                ...state[DASHBOARD_TAB_NAMES.STATISTICS].global,
                                currencyCode: currencyFilterInitialValue
                            }
                        },
                        [DASHBOARD_TAB_NAMES.USERS]: {
                            ...state[DASHBOARD_TAB_NAMES.USERS],
                            leaderboard: {
                                ...state[DASHBOARD_TAB_NAMES.USERS].leaderboard,
                                currencyCode: currencyFilterInitialValue
                            }
                        }
                    }
                }

                return state;
            });
        }
    }, [currencies]);

    return { lastUpdatedDate, setLastUpdatedDate, activeTab, setActiveTab, filters, setFilters };
};

export default useDashboardState;
