//#region react
import React from 'react';
import PropTypes from 'prop-types';
//#endregion

//#region components
import Tag from '../tag';
//#endregion

//#region utils
import { classNames } from 'utils/common';
import { getPropFromMatchObj } from '../helpers';
//#endregion

const Selector = ({
    options,
    className,
    onClick,
    disabled,
    isDark,
    isMultiple,
    placeholder,
    value,
    withLabel,
    onTagClose,
    allowClear,
    onClear,
}) => {

    const optionsHasNull = options.some(option => option.value === null);

    const renderSelectorContent = () => {
        //#region ---------------------SHOW PLACEHOLDER--------------------- //

        const showPlaceholderInCaseOfNull = (!optionsHasNull && value === null);

        if (value === undefined || showPlaceholderInCaseOfNull || (Array.isArray(value) && value.length === 0)) {
            return (
                <span className="rt--title rt--font-normal rt--font-regular rt--select-button-placeholder">
                    {placeholder}
                </span>
            )
        }

        //#endregion

        //#region --------------------MULTIPLE MODE VIEW-------------------- //

        if (isMultiple) {
            return (
                <div className="rt--flex rt--align-center rt--flex-equal rt--select-button-tags rt--mr-8">
                    {value.map(selectedValue => (
                        <Tag
                            key={selectedValue}
                            label={getPropFromMatchObj({ value: selectedValue, options, prop: "text" })}
                            disabled={disabled}
                            onClose={(e) => onTagClose(selectedValue, e)}
                        />
                    ))}
                </div>
            )
        }

        //#endregion

        //#region ---------------------SINGLE MODE VIEW--------------------- //

        return (
            <div className="rt--flex rt--justify-between rt--align-center rt--flex-equal">
                <span className="rt--title rt--font-normal rt--font-regular rt--select-button-value">
                    {getPropFromMatchObj({ value, options, prop: "text" })}
                </span>
                {withLabel && (
                    <span className="rt--title rt--font-small rt--font-regular rt--select-button-label rt--pl-16 rt--pr-16">
                        {getPropFromMatchObj({ value, options, prop: "label" })}
                    </span>
                )}
            </div>
        )

        //#endregion
    }

    return (
        <div
            className={classNames(
                "rt--select-button rt--pl-12 rt--pr-8 rt--flex rt--align-center rt--justify-between",
                disabled && "rt--select-button-disabled",
                isDark && "rt--select-button-dark",
                className
            )}
            onClick={onClick}
        >
            {renderSelectorContent()}

            {
                (allowClear && !isMultiple && value)
                    ? (
                        <i
                            className="icon-delete rt--font-bigest"
                            onClick={onClear}
                        />
                    ) : (
                        <i
                            className={classNames(
                                isDark ? "icon-select-down" : "icon-down",
                                "rt--font-bigest",
                            )}
                        />
                    )
            }
        </div>
    );
}

/** Selector propTypes
    * PropTypes
*/
Selector.propTypes = {
    /** Select options */
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        label: PropTypes.string,
        subs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })),
    /** Input value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
    /** Is disabled */
    disabled: PropTypes.bool,
    /** Placeholder */
    placeholder: PropTypes.string,
    /** Classname */
    className: PropTypes.string,
    /** Are the options with label */
    withLabel: PropTypes.bool,
    /** Is Multiple mode select */
    isMultiple: PropTypes.bool,
    /** Is dark mode dropdown */
    isDark: PropTypes.bool,
    /** Callback which called on selector click */
    onClick: PropTypes.func,
    /** Callback which called on clear icon click */
    onClear: PropTypes.func,
    /** Callback which called on tag close icon click */
    onTagClose: PropTypes.func,
    /** Allow Clear selected value */
    allowClear: PropTypes.bool,
}

export default Selector;
