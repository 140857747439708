import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { ConfigProvider } from "antd";

import enLang from 'antd/es/locale/en_GB';

import OutsideAlerter from "components/common/outsideAlerter";
import TimePicker from "components/common/timepicker";

const TimePickerCell = ({
    value,
    onEdit,
    record,
}) => {
    const [editing, setEditing] = useState(false);

    const [initialValue, setInitialValue] = useState(value);
    const [currentValue, setCurrentValue] = useState(value);

    const [dropdownOpened, setDropdownOpened] = useState(false);

    const timePickerRef = useRef(null);

    /** Fires on Editing cancel
       * @function
       * @memberOf TimePickerCell
    */
    const cancelEditing = () => {
        setEditing(false);
        setCurrentValue(initialValue);
    }

    /** Fires on ok button click
       * @function
       * @memberOf TimePickerCell`
    */
    const handleEdit = () => {
        if (typeof onEdit === 'function') {
            onEdit(currentValue, record);
        }

        setEditing(false);
    }

    const handleTimepickerChange = (timeRange) => {
        setCurrentValue(timeRange);
    };

    const handleTimePickerOpeningChange = (isOpening) => {
        if (isOpening) {
            const timePickerOpenEvent = new CustomEvent("timePickerOpenEvent", {'detail': timePickerRef.current});

            window.dispatchEvent(timePickerOpenEvent);
            setEditing(true);
        }

        setDropdownOpened(isOpening);
    }

    const handleTimePickerButtonClick = e => {
        const target = e.target.closest(".rt--table-col-timepicker-button");
        const prevElement = target.previousElementSibling;
        prevElement.click()
    }

    useEffect(() => {
        const handler = (e) => {
            if (e.detail === timePickerRef.current) {
                return;
            }

            cancelEditing()
        }

        window.addEventListener(
            "timePickerOpenEvent",
            handler
        )

        return () => {
            window.removeEventListener(
                "timePickerOpenEvent",
                handler
            )
        }
    }, []);

    return (
        <OutsideAlerter
            callback={() => {
                if (dropdownOpened) {
                    return;
                }

                setTimeout(cancelEditing, 200);
            }}
        >
            <div
                className={
                    `rt--table-col rt--table-editable-col rt--table-col-timepicker ${editing ? "rt--table-col-timepicker-active" : ""
                    } rt--flex rt--align-center rt--mt-1 rt--mb-1`
                }
                ref={timePickerRef}
            >
                <ConfigProvider
                    locale={enLang}
                >
                    <TimePicker.RangePicker
                        onOpenChange={handleTimePickerOpeningChange}
                        value={currentValue}
                        onChange={handleTimepickerChange}
                        suffixIcon={false}
                        format='HH:mm'
                        allowClear={false}
                        placeholder={""}
                        inputReadOnly={true}
                        className='rt--table-timepicker'
                        popupClassName='rt--table-timepicker-dropdown'
                        separator={<div className='rt--table-timepicker-separator' />}
                        placement="bottomRight"
                        bordered={false}
                        showNow={false}
                        locale={enLang}
                    />
                </ConfigProvider>

                {
                    editing ? (
                        <div className="rt--table-col-input-actions rt--flex rt--align-center rt--justify-end">
                            <div className="rt--table-col-input-actions-wrapper">
                                <i className="icon-ok rt--font-big rt--table-col-editable-input-actions-ok" onClick={handleEdit} />
                            </div>
                            <div className="rt--table-col-input-actions-wrapper">
                                <i className="icon-cancel rt--font-big rt--table-col-editable-input-actions-cancel" onClick={cancelEditing} />
                            </div>
                        </div>
                    ) : (
                        <div
                            className="rt--table-col-timepicker-button rt--ml-8 rt--cursor-pointer"
                            onClick={handleTimePickerButtonClick}
                        >
                            <i className="icon-edit rt--font-big" />
                        </div>
                    )
                }
            </div>
        </OutsideAlerter>
    );
}

/** TimePickerCell propTypes
 * PropTypes
*/

TimePickerCell.propTypes = {
    /** Cell value */
    value: PropTypes.array,
    /** Function to call on cell edit */
    onEdit: PropTypes.func,
    /** The record of row */
    record: PropTypes.object,
}

export default memo(TimePickerCell);
