import React from "react";

import RetailBetHistoryComponent from "pages/betHistory/retail/betHistory.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const RetailBetHistoryRoute = () => {
    return <RetailBetHistoryComponent />;
};

export default withPermission(
    withAuth(RetailBetHistoryRoute),
    { resource: PERMISSION_RESOURCE.BETS_RETAIL, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
