import React, {forwardRef, useRef} from 'react';
import PropTypes from "prop-types";

const Scroller = forwardRef(
    ( {className, children, onScroll, onScrollEnd, offset}, ref) => {
    const scrollEndTriggered = useRef(false);

    const handleScroll = ({target: {clientHeight, scrollTop, scrollHeight}}) => {
        const triggerLinePassed = clientHeight + scrollTop + offset >= scrollHeight;

        if (triggerLinePassed) {
            if (!scrollEndTriggered.current) {
                onScrollEnd?.();
                scrollEndTriggered.current = true;
            }
        } else {
            scrollEndTriggered.current = false;
        }
    }

    return (
        <div
            ref={ref}
            className={className}
            style={{overflow: "auto", height: '100%'}}
            onScroll={event => {
                onScroll?.(event);
                handleScroll(event);
            }}
        >
            {children}
        </div>
    );
});

Scroller.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onScroll: PropTypes.func,
    onScrollEnd: PropTypes.func,
    offset: PropTypes.number,
}

export default Scroller;
