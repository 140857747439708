import React from "react";

import BetshopsEditComponent from "pages/retail/betshops/edit/betshop-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
	PERMISSION_RESOURCE,
	PERMISSION_ACTION,
} from "constants/permissions.constants";

import { USER_ROLE } from "constants/user.constants";

import { getUser } from "utils/auth";

const BetshopsEditRoute = () => {
	return <BetshopsEditComponent />;
};

export default withPermission(
	withAuth(BetshopsEditRoute),
	[
		{
			resource: PERMISSION_RESOURCE.BETSHOP_GENERALINFO,
			action: PERMISSION_ACTION.VIEW,
		},
		{
			resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES,
			action: PERMISSION_ACTION.VIEW,
		},
		{
			resource: (
				getUser()?.role === USER_ROLE.ADMIN
					? PERMISSION_RESOURCE.BETSHOP_WALLETS
					: PERMISSION_RESOURCE.BETSHOP
			),
			action: PERMISSION_ACTION.VIEW,
		},
		{
			resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS,
			action: PERMISSION_ACTION.VIEW,
		},
		{
			resource: PERMISSION_RESOURCE.BETSHOP_IP_ACCESS_RULE,
			action: PERMISSION_ACTION.VIEW,
		},
		{
			resource: PERMISSION_RESOURCE.BETSHOP_ADDITIONAL_ACCESS,
			action: PERMISSION_ACTION.VIEW,
		},
	],
	Paths.DEFAULT
);
