import {hasOneOfPermissions} from "utils/permissions";

export const isTabVisible = tab => {
    if (tab.permissions) {
        if (!hasOneOfPermissions(tab.permissions)) {
            return false;
        }
    }

    return !tab.disabled;
}
