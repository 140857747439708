import React, {useMemo} from 'react';
import {Col, Row} from "antd";
import PieChart from "pages/dashboard/components/pieChart";
import { Tabs as AntTabs } from 'antd';
import LeaderboardFilters from "pages/dashboard/tabs/usersTab/filters/laederbord";
import {useDashboardContext} from "pages/dashboard/contexts/dashboardContext";

const UsersTabDesktop = ({
    onFiltersChange,
    updateLastUpdatedDate,
    currencyOptions,
    leaderboardFilters,
    pieChartFilters,
    items,
    pieChartItems,
    onTabChange,
    dataTypeOptions
}) => {
    const { state: { leaderboardTab } } = useDashboardContext();

    const handleFiltersChange = (value) => {
        onFiltersChange(value);
    }

    const handleTabChange = (key) => {
        onTabChange(key);
    }

    const pieCharts = useMemo(() => {
        return (
            <Row gutter={[16, 16]}>
                {pieChartItems.map(chart => (
                    <Col key={chart.id} span={Math.floor(24 / pieChartItems.length)}>
                        <PieChart
                            entityType={chart.entityType}
                            filters={pieChartFilters}
                            updateLastUpdatedDate={updateLastUpdatedDate}
                        />
                    </Col>
                ))}
            </Row>
        );
    }, [pieChartFilters, pieChartItems, updateLastUpdatedDate])

    return (
        <div className="rt--dashboard-users">
            {pieCharts}

            <div>
                <div className="rt--chart-section rt--pl-16 rt--pt-16 rt--pr-16 rt--pb-16">
                    <AntTabs
                        activeKey={leaderboardTab}
                        animated={false}
                        destroyInactiveTabPane={true}
                        className='rt--tabs rt--chart-tab'
                        items={items}
                        onChange={handleTabChange}
                        tabBarExtraContent={(
                            <LeaderboardFilters
                                filters={leaderboardFilters}
                                onChange={handleFiltersChange}
                                dataTypeOptions={dataTypeOptions}
                                currencyOptions={currencyOptions}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default UsersTabDesktop;
