import axios from 'axios';

import store from 'store/configureStore';

import { setAccountLocked } from "store/actions/auth/auth.action";

import { isAuthenticated, getUser, logout, refreshToken, startWatchingRefreshTokenExpiration } from 'utils/auth';

import LanguageUtils from 'utils/languages';

import { showError } from 'utils/message';
import { RESPONSE_CODES } from 'constants/errorCode.constants';
import ApiUrls from 'constants/api.constants';

const CANCELED_REQUEST_CODE = "ERR_CANCELED";

axios.defaults.baseURL = import.meta.env.SYSTEM_API_URL;

axios.interceptors.request.use( config => {
    
    // if(config.method === "get"){
    //     config.url = config.url.indexOf("?") > -1 ? config.url + "&timestump=" + Date.now() : config.url + "?timestump=" + Date.now()
    // }

    // set current language
    const currentLang = LanguageUtils.getSelectedLanguage();
    if (currentLang) {
        config.headers['Accept-Language'] = currentLang;
    }
    

    if(isAuthenticated()){
        if (config?.headers?.grant_type !== 'refresh_token') {
            const token = getUser()?.token;
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }

            //Set default content type
            if (!config.headers['Content-Type'] || config.headers['Content-Type'] === 'text/plain;charset=utf-8') {
                config.headers['Content-Type'] = 'application/json;charset=utf-8';
            }

        } else {
            delete config.headers['grant_type'];
        }

        // Trim params and data

        if(config.params){
            const newParams = {}
            Object.keys(config.params).map(key => {
                if(typeof config.params[key] === "string"){
                    if(key && typeof key === "string" && !key.toLowerCase().includes("password")){
                        newParams[key] = config.params[key].trim();
                    } else {
                        newParams[key] = config.params[key]
                    }
                    
                    if(newParams[key] === ""){
                        newParams[key] = null
                    }
                } else {
                    newParams[key] = config.params[key]
                }
            })
        }
        if(config.data && !(config.data instanceof FormData)){
            try{
                const newData = {};
                let parsed = config.data;
                if(typeof config.data === 'string'){
                    try{
                        parsed = JSON.parse(config.data);
                    }catch(ex){console.log("err")}
                }

                Object.keys(parsed).map(key => {
                    if(typeof parsed[key] === "string"){
                        if(key && typeof key === "string" && !key.toLowerCase().includes("password")){
                            newData[key] = parsed[key].trim();
                        } else {
                            newData[key] = parsed[key]
                        }
                        if(newData[key] === ""){
                            newData[key] = null
                        }
                    } else {
                        newData[key] = parsed[key]
                    }
                })
                config.data = newData;
            }catch(ex){console.log(ex)}
        }
    }
    
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use( ({ status , data }) => {
    if( (data?.resultCode ?? 0 ) !== RESPONSE_CODES.Success && !(data instanceof Blob) && !(data instanceof Array) ){
        showError(data?.message ?? "", data?.resultCode ?? 0, data?.resource ?? "");
    }
    startWatchingRefreshTokenExpiration();
    return { status, data };
}, error => {
    if (error.code === CANCELED_REQUEST_CODE) {
        return Promise.reject(error);
    }

    if(!error.response){
        showError("CORS ERROR");
        return Promise.reject(error);
    }
    let status = error.response.status;
    let errorData = error.response.data;
    let errorConfig = error.response.config;
    
    const isBlobResponseType = error.request.responseType === 'blob' && error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') != -1

    const errorResultCode = errorData?.resultCode ?? 0;
    startWatchingRefreshTokenExpiration();
    if(status >= 400) {
        if(errorResultCode === RESPONSE_CODES.Unauthorized || isBlobResponseType){ 
            if(status === 401){
                const refresh_token = getUser()?.refreshToken;
                if(refresh_token) {
                    const config = error.response.config;
                    return refreshToken(refresh_token, config);
                } else {
                    logout();
                }
            } else if ( status === 400 && !isBlobResponseType){
                showError(errorData?.message ?? "", errorData?.resultCode ?? 0, errorData?.resource ?? "");
                logout();
            }
        } else if(errorConfig?.url?.includes(ApiUrls.SWITCH_BACK_ACCOUNT)){
            logout();
        } else if(
            errorData?.resultCode !== RESPONSE_CODES.AccountLoginLocked && 
            errorData?.resultCode !== RESPONSE_CODES.AccountLoginTemporaryLocked
        ){
            showError(errorData?.message ?? "", errorData?.resultCode ?? 0, errorData?.resource ?? "");
        } else {
            store.dispatch(setAccountLocked({
                resultCode: errorData?.resultCode ?? 0,
                expireTime: errorData?.fullMessage ?? "",
                userName: store.getState().auth.lastLoginedUserName
            }));
        }
    }
    return Promise.reject(error);
});
