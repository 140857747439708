import { useContext } from "react";
import { useTranslation } from "react-i18next";

import UnsavedFormsContext from "components/common/unsavedFormsProvider/unsavedFormsContext";

import useConfirmation from "./useConfirmation";

import { hasValue } from "utils/common";

import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";

const useUnsavedFormConfirmation = ({ cb, dependencies = [] }) => {
    if (!Array.isArray(dependencies)) {
        console.error(`
            Invalid input for dependencies in useUnsavedFormConfirmation hook.
            Expected an array of form types (${Object.values(UNSAVED_FORM_PAGE_TYPE).join(', ')}),
            but received ${typeof dependencies}.
            Please provide an array of form types using the UNSAVED_FORM_PAGE_TYPE constant.
        `)

        return;
    }

    const { t } = useTranslation();

    const { unsavedForms } = useContext(UnsavedFormsContext);

    const { showConfirmationPopup } = useConfirmation({
        title: t("backoffice.common.confirmAction"),
        message: t("backoffice.common.withoutSavingMessage"),
        okText: t("backoffice.common.discard"),
        cancelText: t("backoffice.common.cancel")
    });

    const handleUnsavedFormConfirmation = (...args) => {
        let needConfirmation = false;

        for (let formPageType of dependencies) {
            if (hasValue(unsavedForms[formPageType])) {
                needConfirmation = true;
            }
        }

        if (needConfirmation) {
            showConfirmationPopup({
                onConfirm: () => cb(...args),
            })

            return;
        }

        cb(...args);
    }

    return handleUnsavedFormConfirmation;
}

export default useUnsavedFormConfirmation;