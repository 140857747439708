export const USER_STATE = {
	IN_PROGRESS: 1 << 0,
	ACTIVE: 1 << 1,
	BLOCKED: 1 << 2,
	EXPIRED: 1 << 3,
	DELETED: 1 << 4,
	LOCKED2FA: 1 << 5,
	LOCKEDLOGIN: 1 << 6,
};

export const TOKEN_TYPE = {
	NONE: "None",
	QR: "QR",
	TOKEN: "Token",
	PASSWORD_EXPIRED: "PasswordExpired",
};
