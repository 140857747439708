import store from 'store/configureStore';

import { getUser } from './auth';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { USER_ROLE, USER_STATE } from 'constants/user.constants';

/** Get current user permissions
     * @function
     * @returns {Object}
 */
export const getPermissions = () => {
    const userInfo = store.getState().profile.userInfo;

    if(getUser()?.role === USER_ROLE.ACCESS_MANAGER){
        return {
            [PERMISSION_RESOURCE.ADMIN] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.ADMIN_GENERALINFO] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY],
            [PERMISSION_RESOURCE.ADMIN_PERMISSIONS] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS] : [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE, PERMISSION_ACTION.DELETE],
            [PERMISSION_RESOURCE.PERMISSION_GROUP]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.CREATE],
            [PERMISSION_RESOURCE.PERMISSION_REQUESTS]: [PERMISSION_ACTION.VIEW],
            [PERMISSION_RESOURCE.ACCESS_MANAGER]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.CREATE],
            [PERMISSION_RESOURCE.ACCESS_MANAGER_GENERALINFO]: [PERMISSION_ACTION.VIEW, PERMISSION_ACTION.MODIFY],
        }
    }

    return userInfo?.permissions ?? null;
}

/** Check if has permission
     * @function
     * @param {string} permission - permission 
     * @returns {boolean}
 */
export const hasPermission = permission => {
    const permissions = getPermissions();

    if(
        getUser()?.userState === USER_STATE.BLOCKED && 
        [PERMISSION_ACTION.CREATE, PERMISSION_ACTION.MODIFY, PERMISSION_ACTION.DELETE].includes(permission.action))
    {
        return false
    }

    return (permissions?.[permission.resource] ?? []).includes(permission.action);
}

/** Check if has permissions
     * @function
     * @param {array} permissions - permissions array
     * @returns {boolean}
 */
export const hasPermissions = permissions => {

    return permissions.every(perm => hasPermission(perm));
}

/** Check if has one of permissions
     * @function
     * @param {array} permissions - permissions array
     * @returns {boolean}
 */
export const hasOneOfPermissions = permissions => {
    return permissions.some(perm => hasPermission(perm));
}