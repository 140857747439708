import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import {
    getFailedTransactions,
    setFailedTransactionsFilters
} from 'store/actions/dashboard/failedTransactions/failedTransactions.action';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DateRangePicker from 'components/common/dateRangePicker';

import { DATE_PICKER_RANGES } from 'constants/common.constants';
import { ACTION_TYPES } from 'constants/failedTransactions.constants';
import { PAYMENT_METHODS } from 'constants/project.constants';
import NumericInput from 'components/common/numericInput';

/** Failed Transactions Page Filters Component */
const Filters = ({
    setFailedTransactionsFilters,
    getFailedTransactions,
    filters,
    globalCompanyId
}) => {
    const { t } = useTranslation();

    return (
        <FiltersWrapper
            loadFn={getFailedTransactions}
            setFiltersFn={setFailedTransactionsFilters}
            filters={filters}
            updateProps={[globalCompanyId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName="id"
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            enabledMountsCount={3}
                            disabledRanges={[DATE_PICKER_RANGES.YTD]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.failedtransactions.groupId')}
                        name="groupId"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.failedtransactions.groupId')}`}
                    >
                        <NumericInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.failedtransactions.groupId')}`}
                            isInteger={true}
                            maxLength={12}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.failedtransactions.actionType')}
                        name="actionType"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: ACTION_TYPES.DEPOSIT, text: t('backoffice.common.deposit') },
                                { value: ACTION_TYPES.WITHDRAWAL, text: t('backoffice.common.withdrawal') },
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.failedtransactions.actionType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.failedtransactions.paymentType')}
                        name="paymentType"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: PAYMENT_METHODS.STANDART, text: t('backoffice.payments.standart') },
                                { value: PAYMENT_METHODS.EPOS, text: t('backoffice.payments.epos') },
                                { value: PAYMENT_METHODS.BANK_TRANSFER, text: t('backoffice.payments.bankTransfer') },
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.failedtransactions.paymentType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get failed transactions */
    getFailedTransactions: PropTypes.func,
    /** Redux action to set failed transactions filters */
    setFailedTransactionsFilters: PropTypes.func,
    /** Redux state property, failed Transactions filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalCompanyId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getFailedTransactions: () => {
            dispatch(getFailedTransactions());
        },
        setFailedTransactionsFilters: filters => {
            dispatch(setFailedTransactionsFilters(filters));
        },
    }
)

const mapStateToProps = state => {
    return {
        filters: state.failedTransactions.filters,
        globalCompanyId: state.common.globalCompanyId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);