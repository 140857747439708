import React from 'react';

import {useTranslation} from "react-i18next";

const NewTag = () => {
    const { t } = useTranslation();

    return (
        <div className="rt--new-tag">
            <span>{t('backoffice.common.new')}</span>
        </div>
    );
};

export default NewTag;
