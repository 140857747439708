import React from 'react';
import PropTypes from 'prop-types';

import StandardView from './standardView';
import DropdownView from './dropdownView';
import FloatingView from './floatIngView';

import { isMobile } from "utils/common";

const DESKTOP_MAX_COUNT = 2;

const splitActions = (actions, maxNumberOfActionsInRow) => {
    let visibleActions = [], dropdownActions = [];

    if (actions.length <= maxNumberOfActionsInRow) {
        visibleActions = [...actions]
    } else {
        visibleActions = actions.slice( 0 - maxNumberOfActionsInRow + 1)
        dropdownActions = actions.slice(0, actions.length - maxNumberOfActionsInRow + 1)
    }
    return {
        visible: visibleActions,
        dropdown: dropdownActions,
    }
}

const CommonActions = ({ 
    rowData, 
    actions, 
    maxNumberOfActionsInRow = DESKTOP_MAX_COUNT 
}) => {
    // In the actionData object, the disabled property is a function
    const activeActions = actions.filter(actionData => !actionData.disabled?.(rowData));

    if (activeActions.length === 0) {
        return null;
    }

    const splitedActions = splitActions(activeActions, maxNumberOfActionsInRow)

    return isMobile() ? (
        <div className='rt--flex rt--flex-col'>
            <StandardView actions={splitedActions.visible} rowData={rowData} />
            {
                splitedActions.dropdown.length > 0 && (
                    <DropdownView actions={splitedActions.dropdown} rowData={rowData} />
                )
            }
        </div>
    ) : (
        <div className='rt--flex rt--align-center rt--justify-end'>
            <FloatingView actions={activeActions} rowData={rowData} />
        </div>
    )
}

/** CommonActions propTypes
 * PropTypes
*/
CommonActions.propTypes = {
    actions: PropTypes.array,
    rowData: PropTypes.object,
    maxNumberOfActionsInRow: PropTypes.number
}

export default CommonActions;