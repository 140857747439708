import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.number,
    betShopName: PropTypes.string,
    currencyCode: PropTypes.string,
    agentId: PropTypes.number,
    agentUserName: PropTypes.string,
    playerSignups: PropTypes.number,
    depositCount: PropTypes.number,
    depositAmount: PropTypes.number,
    withdrawCount: PropTypes.number,
    withdrawAmount: PropTypes.number,
    netDepositAmount: PropTypes.number,
    betAmount: PropTypes.number,
    betCount: PropTypes.number,
    wonAmount: PropTypes.number,
    wonCount: PropTypes.number,
    paidoutAmount: PropTypes.number,
    paidoutCount: PropTypes.number,
    bonusBetAmount: PropTypes.number,
    bonusRedeemAmount: PropTypes.number,
    bonusWonAmount: PropTypes.number,
    wonNotClaimedBetAmount: PropTypes.number,
    wonNotClaimedBetCount: PropTypes.number,
    cancelledBetsAmount: PropTypes.number,
    cancelledBetsCount: PropTypes.number,
    ggr: PropTypes.number,
    ngr: PropTypes.number,
    totalInAmount: PropTypes.number,
    totalOutAmount: PropTypes.number,
    taxAmount: PropTypes.number
})