import { binaryToFlags, flagsToBinary } from 'utils/common';

import { PROJECT_PROVIDER_TYPE } from "constants/project.constants";
import { TRANSLATION_SOURCE_TYPE } from "constants/translation.constants";

import {
	SET_PROJECTS_ACTION_BEFORE,
	SET_PROJECTS_ACTION_FINISH,
	SET_PROJECTS,
	SET_PROJECTS_SORTING,
	SET_PROJECTS_FILTERS,
	SET_PROJECTS_SAVE_ACTION_BEFORE,
	SET_PROJECTS_SAVE_ACTION_FINISH,
	SET_PROJECT_GENERAL_INFO,
	SET_PROJECT_CURRENCIES,
	SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE,
	SET_PROJECT_AVAILABLE_CURRENCIES_FINISH,
	SET_PROJECT_AVAILABLE_CURRENCIES,
	SET_PROJECT_AGENT_SYSTEM_NETWORK,
	SET_PROJECT_AGENT_SYSTEM_NETWORK_LABEL,
	SET_PROJECT_AGENT_REGISTRATION_FORM,
	SET_PROJECT_PLAYER_REGISTRATION_FORM,
	SET_PROJECT_LIMIT_SETTINGS,
    SET_PROJECT_FAVORITE_AMOUNTS,
	SET_PROJECT_PROVIDER_SETTINGS,
	SET_PROJECT_CONFIGS,
	SET_PROJECT_INTEGRATION,
	SET_PROJECT_CHATUI,
	SET_RETAIL_PROVIDERS,
	SET_PROJECT_PAYMENT_METHODS,
	SET_PROJECT_BANKS,
	SET_PROJECT_BANK_GENERAL_INFO,
	SET_PROJECT_BANK_FORM,
	SET_PROJECT_BANK_FORM_TRANSLATION,
	SET_PROJECT_PAYMENT_WIDGET,
	SET_PROJECT_BANK_NAME_TRANSLATION,
	SET_PROJECT_SMS_PROVIDERS,
	SET_PROJECT_SMS_PROVIDER, SET_PROJECT_AGENT_ADMIN_FEE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_PROJECTS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true,
			};
		case SET_PROJECTS_ACTION_FINISH:
			return {
				...state,
				isLoading: false,
			};
		case SET_PROJECTS:
			return {
				...state,
				projects: !payload.add ? payload.projects.item2 : state.projects.concat(payload.projects.item2),
				total: payload.projects.item1,
			};

		case SET_PROJECTS_FILTERS:
			return {
				...state,
				filters: payload.filters,
				sorting: {
					...state.sorting,
					page: 1
				}
			};
		case SET_PROJECTS_SORTING:
			return {
				...state,
				sorting: payload.sorting,
			};
		case SET_PROJECTS_SAVE_ACTION_BEFORE:
			return {
				...state,
				isSaving: true,
			};
		case SET_PROJECTS_SAVE_ACTION_FINISH:
			return {
				...state,
				isSaving: false,
			};
		case SET_PROJECT_GENERAL_INFO:
			return {
				...state,
				edit: {
					...state.edit,
					provider: payload.info.retailProvider,
					general: {
						...payload.info
					}
				}
			}
		case SET_PROJECT_CURRENCIES:
			return {
				...state,
				edit: {
					...state.edit,
					currencies: payload.currencies.currencies,
					provider: payload.currencies.provider
				}
			}
		case SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE:
			return {
				...state,
				availableCurrencies: {
					...state.availableCurrencies,
					isLoading: true
				}
			}
		case SET_PROJECT_AVAILABLE_CURRENCIES_FINISH:
			return {
				...state,
				availableCurrencies: {
					...state.availableCurrencies,
					isLoading: false
				}
			}
		case SET_PROJECT_AVAILABLE_CURRENCIES:
			return {
				...state,
				availableCurrencies: {
					...state.availableCurrencies,
					availableCurrencies: payload.currencies
				}
			}
		case SET_PROJECT_AGENT_SYSTEM_NETWORK:
			return {
				...state,
				edit: {
					...state.edit,
					agentSystem: {
						...state.edit.agentSystem,
						network: {
							...state.edit.agentSystem.network,
							network: payload.network
						}
					}
				}
			}
		case SET_PROJECT_AGENT_SYSTEM_NETWORK_LABEL:
			return {
				...state,
				edit: {
					...state.edit,
					agentSystem: {
						...state.edit.agentSystem,
						network: {
							...state.edit.agentSystem.network,
							network: state.edit.agentSystem.network.network.map(l => ({ ...l, label: l.layer === payload.level.layer ? payload.level.label : l.label }))
						}
					}
				}
			}
		case SET_PROJECT_AGENT_REGISTRATION_FORM:
			return {
				...state,
				edit: {
					...state.edit,
					registrationForm: {
						...state.edit.registrationForm,
						agentRegistrationForm: payload.registrationForm
					}
				}
			}
		case SET_PROJECT_AGENT_ADMIN_FEE:
			return {
				...state,
				edit: {
					...state.edit,
					agentSystem: {
						...state.edit.agentSystem,
						administrativeFee: payload.administrativeFee
					}
				}
			}
		case SET_PROJECT_PLAYER_REGISTRATION_FORM:
			return {
				...state,
				edit: {
					...state.edit,
					registrationForm: {
						...state.edit.registrationForm,
						playerRegistrationForm: payload.registrationForm
					}
				}
			}
		case SET_PROJECT_LIMIT_SETTINGS:
			return {
				...state,
				edit: {
					...state.edit,
					limitSettings: payload.limitSettings
				}
			}
        case SET_PROJECT_FAVORITE_AMOUNTS:
			return {
				...state,
				edit: {
					...state.edit,
					favoriteAmounts: payload.favoriteAmounts
				}
			}
		case SET_PROJECT_CONFIGS:
			return {
				...state,
				edit: {
					...state.edit,
					configs: {
						...state.edit.configs,
						main: payload.configs
					}
				}
			}
		case SET_PROJECT_SMS_PROVIDERS:
			return {
				...state,
				edit: {
					...state.edit,
					configs: {
						...state.edit.configs,
						smsConfigs: {
							...state.edit.configs.smsConfigs,
							smsProviders: payload.providers,
						}
					}
				}
			}
		case SET_PROJECT_SMS_PROVIDER:
			return {
				...state,
				edit: {
					...state.edit,
					configs: {
						...state.edit.configs,
						smsConfigs: {
							...state.edit.configs.smsConfigs,
							projectProvider: payload.projectProvider,
						}
					}
				}
			}
		case SET_PROJECT_PROVIDER_SETTINGS:
			return {
				...state,
				edit: {
					...state.edit,
					providerSettings: payload.data,
					provider: flagsToBinary(Object.keys(payload.data)
						.map(key => (
							{
								key: key === "sportsbookSettings" ? PROJECT_PROVIDER_TYPE.SPORTBOOK : PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS,
								enabled: payload.data[key].enabled
							}
						)).filter(p => p.enabled).map(p => p.key))
				}
			}
		case SET_PROJECT_INTEGRATION:
			return {
				...state,
				edit: {
					...state.edit,
					integration: {
						...payload.integration
					}
				}
			}
		case SET_PROJECT_PAYMENT_METHODS:
			return {
				...state,
				edit: {
					...state.edit,
					payment: {
						...state.edit.payment,
						config: payload.config
					},
					limitSettings: {
						...state.edit.limitSettings,
						paymentType: payload.config.type,
						walletTypeIsCreditLine: payload.config.walletTypeIsCreditLine
					}
				}
			}
		case SET_PROJECT_BANKS:
			return {
				...state,
				edit: {
					...state.edit,
					payment: {
						...state.edit.payment,
						banks: {
							...state.edit.payment.banks,
							banks: payload,
						}
					}
				}
			}
		case SET_PROJECT_BANK_GENERAL_INFO:
			return {
				...state,
				edit: {
					...state.edit,
					payment: {
						...state.edit.payment,
						banks: {
							...state.edit.payment.banks,
							edit: {
								...state.edit.payment.banks.edit,
								info: {
									...payload
								}
							}
						}
					}
				}
			}
		case SET_PROJECT_BANK_NAME_TRANSLATION:
			return {
				...state,
				edit: {
					...state.edit,
					payment: {
						...state.edit.payment,
						banks: {
							...state.edit.payment.banks,
							edit: {
								...state.edit.payment.banks.edit,
								info: {
									...state.edit.payment.banks.edit.info,
									translations: state.edit.payment.banks.edit.info.translations.map(tr => tr.key === payload.key ?
										{
											...tr,
											translations: tr.translations.map(t => t.languageCode === payload.translation.languageCode ? payload.translation : t)
										} : tr)
								}
							}
						}
					}
				}
			}
		case SET_PROJECT_BANK_FORM:
			return {
				...state,
				edit: {
					...state.edit,
					payment: {
						...state.edit.payment,
						banks: {
							...state.edit.payment.banks,
							edit: {
								...state.edit.payment.banks.edit,
								bankForm: {
									...state.edit.payment.banks.edit.bankForm,
									[payload.type]: {
										...state.edit.payment.banks.edit.bankForm[payload.type],
										forms: payload.form.forms.filter(f => f.name !== "Amount"), //Remove Amount as it can still come from back, for old projects
										translations: payload.setTranslations ? payload.form.translations : state.edit.payment.banks.edit.bankForm[payload.type].translations
									}
								}
							}
						}
					}
				}
			}
		case SET_PROJECT_BANK_FORM_TRANSLATION:
			return {
				...state,
				edit: {
					...state.edit,
					payment: {
						...state.edit.payment,
						banks: {
							...state.edit.payment.banks,
							edit: {
								...state.edit.payment.banks.edit,
								bankForm: {
									...state.edit.payment.banks.edit.bankForm,
									[payload.type]: {
										...state.edit.payment.banks.edit.bankForm[payload.type],
										translations: state.edit.payment.banks.edit.bankForm[payload.type].translations.map(tr => {
											let t = { ...tr };
											if (tr.key === payload.key) {
												t.translations = t.translations.map(item => {
													if (item.languageCode === payload.translation.languageCode) {
														return payload.translation
													} else if (payload.translation.languageCode === "EN" && item.source === TRANSLATION_SOURCE_TYPE.SYSTEM_DEFAULT) {
														return { ...item, text: payload.translation.text }
													} else {
														return item;
													}
												}
												)
											}
											return t;
										})
									}
								}
							}
						}
					}
				}
			}
		case SET_PROJECT_PAYMENT_WIDGET:
			return {
				...state,
				edit: {
					...state.edit,
					payment: {
						...state.edit.payment,
						banks: {
							...state.edit.payment.banks,
							edit: {
								...state.edit.payment.banks.edit,
								widget: {
									...state.edit.payment.banks.edit.widget,
									[payload.transactionType]: payload.url
								}
							}
						}
					}
				}
			}
		case SET_PROJECT_CHATUI:
			return {
				...state,
				edit: {
					...state.edit,
					chatUI: {
						...payload.chatUI
					}
				}
			}
		case SET_RETAIL_PROVIDERS:
			return {
				...state,
				retailProviders: binaryToFlags(Object.values(PROJECT_PROVIDER_TYPE), payload.providers)
			};
		default:
			return state;
	}
};
