import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import useDate from 'hooks/useDate';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import Input from 'components/common/input';
import AutoComplete from "components/common/autoComplete";

import { setRequestsFilters, getRequests } from "store/actions/dashboard/developer/requests/requests.action";

import { MONTHS } from "constants/common.constants"
import { PLATFORM_ERROR_CODES } from "constants/errorCode.constants";
import { INTEGRATION_TYPE } from 'constants/project.constants';

import companyType from 'types/company/company.type';

/** Requests Page Filters Component */

const Filters = ({
    setRequestsFilters,
    getRequests,
    filters,
    type,
    companies
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const YEARS = Array(dateService.getCurrentYMD().year - 2020 + 1).fill().map((_, idx) => 2020 + idx);

    const projects = useMemo(() => {
        return companies.reduce((acc, company) => {
            const projects = company.projects ?? [];
            projects.forEach(p => {
                acc.push({id: p.id, name: p.name})
            })
            return acc;
        }, []);
    }, [companies])

    return (
        <FiltersWrapper
            loadFn={() => getRequests(type)}
            setFiltersFn={filters => setRequestsFilters(filters, type)}
            filters={filters}
        >
            <Row gutter={[16, 0]}>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.action')}
                        name="action"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.action')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.action')}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.project')}
                        name="projectId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.project')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            items={projects}
                            valueMapper={(projects, value) => {

                                const foundItem = projects.find(item => item.id === value);

                                if (foundItem) {
                                    return foundItem.name;
                                }

                                return value;
                            }}

                            valueGetter={(projects, value) => {
                                const searchedItem = value !== "" ? projects.find(p => (
                                    p.id === value ||
                                    p.name?.toLowerCase() === value.toLowerCase()
                                )) || null : null;
                                
                                return searchedItem?.id ?? value;
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.status')}
                        name="status"
                    >
                        <Select
                                options={[
                                    { value: "", text: t('backoffice.common.all') },
                                    { value: PLATFORM_ERROR_CODES.BAD_REQUEST, text: t('backoffice.errorlogs.badRequest') },
                                    { value: PLATFORM_ERROR_CODES.UNAUTHORIZED, text: t('backoffice.errorlogs.unauthorized') },
                                    { value: PLATFORM_ERROR_CODES.FORBIDDEN, text: t('backoffice.errorlogs.forbidden') },
                                    { value: PLATFORM_ERROR_CODES.NOT_FOUND, text: t('backoffice.errorlogs.notFound') }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.month')}
                        name="month"
                    >
                        <Select
                            options={
                                Object.values(MONTHS).map(m => (
                                    { value: m, text: t('backoffice.months.month' + m) }
                                ))
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.common.month")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.year')}
                        name="year"
                    >
                        <Select
                            options={
                                YEARS.map(y => (
                                    { value: y, text: y }
                                ))
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.common.year")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get requests */
    getRequests: PropTypes.func,
    /** Redux action to set requests filters */
    setRequestsFilters: PropTypes.func,
    /** Redux state property, requests filters */
    filters: PropTypes.object,
    /** Type */
    type: PropTypes.oneOf(Object.values(INTEGRATION_TYPE)),
    /** Redux state property, represents the array of all companies  */
    companies: PropTypes.arrayOf(companyType),
}

const mapDispatchToProps = dispatch => (
    {
        getRequests: type => {
            dispatch(getRequests(false, type))
        },
        setRequestsFilters: (filters, type) => {
            dispatch(setRequestsFilters(filters, type))
        }
    }
)

const mapStateToProps = (state, props) => {
    const type = props.type;
    return {
        filters: state.requests[type].filters,
        companies: state.profile.userInfo.companies,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);