import React from "react";

import BetshopPerformanceReport from "pages/reports/betshopPerformanceReport/betshopPerformanceReport.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const BetshopPerformanceReportRoute = () => {
    return <BetshopPerformanceReport />;
};

export default withPermission(
    withAuth(BetshopPerformanceReportRoute),
    {
        resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
