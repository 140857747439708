import React from "react";

import PlayersEditComponent from "pages/players/edit/player-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const PlayersEditRoute = () => {
    return <PlayersEditComponent />;
};
export default withPermission(
    withAuth(PlayersEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.PLAYER_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.BETS_ONLINE,
            action: PERMISSION_ACTION.VIEW,
        },
    ],
    Paths.DEFAULT
);
