
import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_AGENT_CALCULATION_HISTORY_ACTION_BEFORE,
    SET_AGENT_CALCULATION_HISTORY_ACTION_FINISH,
    SET_AGENT_CALCULATION_HISTORY,
    SET_AGENT_CALCULATION_HISTORY_SORTING,
    SET_AGENT_CALCULATION_HISTORY_FILTERS
} from "../../../actionTypes";

const setAgentCalculationHistoryActionBefore = () => ({
    type: SET_AGENT_CALCULATION_HISTORY_ACTION_BEFORE,
});

const setAgentCalculationHistoryActionFinished = () => ({
    type: SET_AGENT_CALCULATION_HISTORY_ACTION_FINISH,
});

const setAgentCalculationHistory = (history, add) => ({
    type: SET_AGENT_CALCULATION_HISTORY,
    payload: { history, add },
});

export const setAgentCalculationHistorySorting = sorting => ({
    type: SET_AGENT_CALCULATION_HISTORY_SORTING,
    payload: { sorting },
});

export const setAgentCalculationHistoryFilters = filters => ({
    type: SET_AGENT_CALCULATION_HISTORY_FILTERS,
    payload: { filters },
});

export const getAgentCalculationHistory = (id, nextPage) => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().agentCalculationHistory.sorting.page + 1 : getState().agentCalculationHistory.sorting.page;
        
        let data = {
            ...getState().agentCalculationHistory.sorting,
            ...getState().agentCalculationHistory.filters,
            id: id,
            page: page
        };

        if(page > 1 && getState().agentCalculationHistory.total <= (page - 1 ) * getState().agentCalculationHistory.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().agentCalculationHistory.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setAgentCalculationHistoryActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_CALCULATION_HISTORY,
            method: Methods.GET,
            params: data,
        })
            .then(({ data: { value: history } }) => {
                dispatch(setAgentCalculationHistory(history, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAgentCalculationHistorySorting({ ...getState().agentCalculationHistory.sorting, page: page }));
                dispatch(setAgentCalculationHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentCalculationHistoryActionFinished());
            });
    }
}
