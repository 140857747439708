import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

//#region actions
import {
    getCommissionPlanAgents,
    setCommissionPlanAgentsSorting,
    setCommissionPlanAgentsFilters
} from "store/actions/dashboard/agentSystem/commissionPlans/agents.action";
//#endregion

//#region components
import Table from "components/common/table";
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isFranchisingMode } from 'utils/common';
import { hasPermission } from 'utils/permissions';
import { hasAgentEditPageAccess } from 'utils/pageAccess';
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import { COMISSION_AGENTS } from "constants/pageName.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import PATHS from 'constants/path.constants';
import { USER_TYPE } from 'constants/user.constants';
import { getTableColumns } from "./columns";
//#endregion

//#region types
import agentType from 'types/agent/agent.type';
import sortingType from 'types/common/sorting.type';
//#endregion

/** Commision Plan Agents Component */
const CommissionPlanAgentsComponent = ({
    getCommissionPlanAgents,
    setCommissionPlanAgentsSorting,
    setCommissionPlanAgentsFilters,
    agents,
    isLoading,
    sorting,
    filters,
    total,
    globalCompanyId,
    globalProjectId,
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { search } = useLocation();

    const currentCommissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: COMISSION_AGENTS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN,
        action: PERMISSION_ACTION.EXPORT
    })

    const canNavigateToAgentEditPage = hasAgentEditPageAccess();

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setCommissionPlanAgentsFilters({
            ...filters,
            userNameOrId: value
        })
    }

    const handleRightArrowIconClick = (record) => {
        const path = isFranchisingMode() ? PATHS.BETSHOP_OWNERS_EDIT : PATHS.AGENTS_EDIT;

        navigate(
            `${path}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.userName}` +
            `&role=${USER_TYPE.AGENT}`
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                franchisingMode: isFranchisingMode()
            }
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [{
        title: t('backoffice.common.deepView'),
        icon: "icon-right",
        onClick: handleRightArrowIconClick,
        disabled: () => !canNavigateToAgentEditPage
    }];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        search: {
            placeholder: t("backoffice.commissionplans.usernameOrId"),
            loadFn: () => getCommissionPlanAgents(currentCommissionPlanId),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AGENT
            },
            onSearch: handleSearchChange,
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            url: `${ApiUrls.EXPORT_COMMISSION_PLAN_AGENTS}?id=${currentCommissionPlanId}`,
            columns: columnsForExport,
            filters: filters,
            tableName: (
                isFranchisingMode()
                    ? t('backoffice.menu.betshopOwners')
                    : t('backoffice.menu.agents')
            ),
        }
    }

    //#endregion

    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={agents}
                loadFn={nextPage => getCommissionPlanAgents(currentCommissionPlanId, nextPage)}
                total={total}
                sorting={sorting}
                setSortingFn={setCommissionPlanAgentsSorting}
                disableFullView={true}
                actions={tableRowActions}
            />
        </SubTabTableDashboardLayout>
    )
}

/** CommissionPlanAgentsComponent propTypes
    * PropTypes
*/
CommissionPlanAgentsComponent.propTypes = {
    /** Redux action, to get commission plan agents */
    getCommissionPlanAgents: PropTypes.func,
    /** Redux action, to set commission plan agents sorting */
    setCommissionPlanAgentsSorting: PropTypes.func,
    /** Redux action, to set commission plan agents filters */
    setCommissionPlanAgentsFilters: PropTypes.func,
    /** Redux state property, array of commission plan agents */
    agents: PropTypes.arrayOf(agentType),
    /** Redux state property, is true when loading commission plan agents */
    isLoading: PropTypes.bool,
    /** Redux state property, commission plan agents sorting details */
    sorting: sortingType,
    /** Redux state property, commission plan agents filters */
    filters: PropTypes.object,
    /** Redux state property, commission plan agents total */
    total: PropTypes.number,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionPlanAgents: (id, nextPage) => {
            dispatch(getCommissionPlanAgents(id, nextPage))
        },

        setCommissionPlanAgentsSorting: sorting => {
            dispatch(setCommissionPlanAgentsSorting(sorting));
        },

        setCommissionPlanAgentsFilters: sorting => {
            dispatch(setCommissionPlanAgentsFilters(sorting));
        },
    }
)

const mapStateToProps = state => {
    return {
        agents: state.commissionPlans.edit.agents.agents,
        sorting: state.commissionPlans.edit.agents.sorting,
        filters: state.commissionPlans.edit.agents.filters,
        total: state.commissionPlans.edit.agents.total,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanAgentsComponent)