import PropTypes from "prop-types";

import searchType from "types/components/search.type";
import columnsButtonType from "types/components/columnsButton.type";
import exportButtonType from "types/components/exportButton.type";
import buttonType from "types/components/button.type";
import breadcrumbsType from "types/components/breadcrumbs.type";

export default PropTypes.shape({
    /** Search options */
    search: PropTypes.oneOfType([
        PropTypes.shape(searchType),
        PropTypes.arrayOf(PropTypes.shape(searchType))
    ]),
    /** Filters component */
    filters: PropTypes.node,
    /** Columns Button */
    columns: PropTypes.shape(columnsButtonType),
    /** Export Button */
    export: PropTypes.shape(exportButtonType),
    /** Buttons */
    buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
    /** Breadcrumbs */
    breadcrumbs: PropTypes.shape(breadcrumbsType),
    /** Extra content component */
    extra: PropTypes.node,
    /** Left content component, near search */
    leftContent: PropTypes.node,
})
