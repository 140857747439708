import React from "react";

import AgentsEditComponent from "pages/agentSystem/agents/edit/agent-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

import { USER_ROLE } from "constants/user.constants";

import { getUser } from "utils/auth";

const AgentsEditRoute = () => {
    return <AgentsEditComponent />;
};

export default withPermission(
    withAuth(AgentsEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.AGENT_CURRENCIES,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.AGENT_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: (
                getUser()?.role === USER_ROLE.ADMIN
                    ? PERMISSION_RESOURCE.AGENT_WALLETS
                    : PERMISSION_RESOURCE.AGENT
            ),
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.AGENT_SESSIONS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.AGENT_PERMISSIONS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.AGENT_LIMITS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.AGENT_ADDITIONAL_ACCESSES,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.AGENT_PAYMENT,
            action: PERMISSION_ACTION.VIEW,
        },
    ],
    Paths.DEFAULT
);
