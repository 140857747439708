import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { FILTER_TYPE } from 'constants/common.constants';

import axios from "axios";

import {
    SET_CASHIER_PERFORMANCE_REPORT,
    SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH
} from "../../../actionTypes";

const setCashierPerformanceReportActionBefore = () => ({
    type: SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE,
});

const setCashierPerformanceReportActionFinished = () => ({
    type: SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH,
});

const setCashierPerformanceReport = report => ({
    type: SET_CASHIER_PERFORMANCE_REPORT,
    payload: { report },
});

export const getCashierPerformanceReport = (betShopNameOrId, from, to) => {
    return dispatch => {

        let data = {
            betShopNameOrId,
            from,
            to,
            filterType: FILTER_TYPE.NETWORK
        };

        dispatch(setCashierPerformanceReportActionBefore());

        return axios({
            url: ApiUrls.GET_CASHIER_PERFORMANCE_REPORT,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const report = data.value?.item2;

                dispatch(setCashierPerformanceReport(report));

                dispatch(setCashierPerformanceReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setCashierPerformanceReportActionFinished());
            });
    }
}