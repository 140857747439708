import React from 'react';
import PropTypes from 'prop-types';

import TitleWithTooltip from './titleWithTooltip';

import { classNames } from 'utils/common';

import { ORDER_DIRECTION } from "constants/common.constants";

const TitleWithSortingIcon = ({ title, order, tooltip }) => {
    return (
        <div className="rt--table-col-title rt--flex rt--align-center">
            {
                tooltip ? <TitleWithTooltip title={title} tooltip={tooltip} /> : title
            }
            {<i
                className={classNames(
                    order === ORDER_DIRECTION.DESC ? "icon-sort-down" : "icon-sort-up",
                    "rt--font-bigest",
                    "rt--ml-4"
                )}
            />}
        </div>
    );
}

/** TitleWithSortingIcon propTypes
 * PropTypes
*/
TitleWithSortingIcon.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    tooltip: PropTypes.string,
    order: PropTypes.number,
}

export default TitleWithSortingIcon;