import {getUser} from "utils/auth";
import {WALLET_OPERATION_TYPE, WALLET_OWNER_TYPE} from "constants/wallet.constants";
import {isFranchisingMode} from "utils/common";

export const getAvailableOperationTypes = ({
    ownerType,
    hasLiteMode
}) => {
    const isWalletConfigNonRelated = getUser().isStandartPaymentWithRelatedWallets === false;

    let walletOperationTypes = [];

    if (ownerType === WALLET_OWNER_TYPE.AGENT) {
        if (isFranchisingMode()) {
            walletOperationTypes = [
                WALLET_OPERATION_TYPE.RECALCULATE,
                WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
                WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
                WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_BY_CASH,
                WALLET_OPERATION_TYPE.AGENT_COMISSION_CALCULATION,
                WALLET_OPERATION_TYPE.AGENT_COMISSION_RECALCULATION,
                WALLET_OPERATION_TYPE.AGENT_BETSHOP_DEPOSIT,
                WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL,
                WALLET_OPERATION_TYPE.ROLLBACK
            ]
        } else {
            if (hasLiteMode) {
                walletOperationTypes = [
                    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE,
                    WALLET_OPERATION_TYPE.ROLLBACK,
                ];
            } else {
                walletOperationTypes = [
                    WALLET_OPERATION_TYPE.RECALCULATE,
                    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
                    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT,
                    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT,
                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT,
                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT,
                    WALLET_OPERATION_TYPE.AGENT_GIVE_CREDIT,
                    WALLET_OPERATION_TYPE.AGENT_COMISSION_CALCULATION,
                    WALLET_OPERATION_TYPE.AGENT_COMISSION_RECALCULATION,
                    WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_BY_CASH,
                    WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_NON_CASH,
                    WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_WITH_DEBT,
                    WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE,
                    WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_DEBT,
                    WALLET_OPERATION_TYPE.AGENT_BETSHOP_DEPOSIT,
                    WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL,
                    WALLET_OPERATION_TYPE.ROLLBACK,
                ]
            }
        }
    } else if (ownerType === WALLET_OWNER_TYPE.SUPER_AGENT) {
        if (isFranchisingMode()) {
            walletOperationTypes = [
                WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_DEPOSIT_AGENT,
                WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_WITHDRAW_AGENT,
                WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
                WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
                WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT_BY_CASH,
                WALLET_OPERATION_TYPE.ROLLBACK
            ]
        } else {
            if (hasLiteMode) {
                walletOperationTypes = [
                    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BALANCE,
                    WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE,
                ];
            } else {
                walletOperationTypes = [
                    WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_DEPOSIT_AGENT,
                    WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_WITHDRAW_AGENT,
                    WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT_BY_CASH,
                    WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT,
                    WALLET_OPERATION_TYPE.ROLLBACK
                ]
            }
        }
    } else {
        walletOperationTypes = [
            WALLET_OPERATION_TYPE.BET,
            WALLET_OPERATION_TYPE.CANCEL_BET,
            WALLET_OPERATION_TYPE.PAIDOUT,
            WALLET_OPERATION_TYPE.REJECT_BET,
            WALLET_OPERATION_TYPE.RECALCULATE,
            WALLET_OPERATION_TYPE.AGENT_BETSHOP_DEPOSIT,
            WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL,
            WALLET_OPERATION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT,
            WALLET_OPERATION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT,
            WALLET_OPERATION_TYPE.SUPER_AGENT_DEPOSIT_BETSHOP,
            WALLET_OPERATION_TYPE.SUPER_AGENT_WITHDRAWAL_BETSHOP
        ]
    }

    if(isWalletConfigNonRelated){
        walletOperationTypes = walletOperationTypes.filter(t => ![
            WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
            WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT,
            WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
            WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT,
            WALLET_OPERATION_TYPE.AGENT_GIVE_CREDIT,
            WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_WITH_DEBT,
            WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_DEBT
        ].includes(t))
    } else {
        walletOperationTypes = walletOperationTypes.filter(t => ![
            WALLET_OPERATION_TYPE.AGENT_DEPOSIT_TO_CREDIT_LINE,
            WALLET_OPERATION_TYPE.AGENT_WITHDRAW_FROM_CREDIT_LINE
        ].includes(t))
    }

    return walletOperationTypes;
}
