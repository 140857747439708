import React from 'react';
import ReactDOM from 'react-dom/client';
import {HotJarManager} from "utils/hotjar";

import './assets/css/typography.css';
import './assets/css/scss/style.scss';

import App from './App.jsx';

HotJarManager.init();

const root = ReactDOM.createRoot(document.getElementById("root"));

// if (import.meta.env.MODE === "production" && location.protocol !== 'https:') {
//     location.replace(`https:${location.href.substring(location.protocol.length)}`);
// } else {
//     root.render(<App />);
// }
root.render(<App />);
