//#region react
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region actions
import {
    getProjectAgentSystemNetwork,
    changeProjectAgentSystemNetworkLabel,
    addProjectAgentSystemNetworkLayer,
    deleteProjectAgentSystemNetworkLayer
} from "store/actions/dashboard/projects/network.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import Table from "components/common/table";
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
//#endregion

//#region utils
import { hasPermission } from "utils/permissions";
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { PROJECT_NETWORK } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import agentSystemLevelType from "types/agentSystem/level.type";
//#endregion

const TABLE_ROW_UNIQUE_KEY = "layer";

/** Project Edit Page Agent System Tab Network SubTab Component */
const NetworkComponent = ({
    getProjectAgentSystemNetwork,
    changeProjectAgentSystemNetworkLabel,
    addProjectAgentSystemNetworkLayer,
    deleteProjectAgentSystemNetworkLayer,
    network,
    isLoading
}) => {
    const { t } = useTranslation();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PROJECT_NETWORK });

    const tableData = network.map(data => ({
        ...data,
        label: Boolean(data.label) ? data.label : "Agent",
    }))

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_AGENT_SYSTEM,
        action: PERMISSION_ACTION.MODIFY,
    })

    const hasDeletePermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_AGENT_SYSTEM,
        action: PERMISSION_ACTION.DELETE,
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleLabelFieldChange = (layer, newLabel) => {
        changeProjectAgentSystemNetworkLabel(layer, newLabel)
    }

    const handleLayerAddition = (layerAmount) => {
        addProjectAgentSystemNetworkLayer(layerAmount);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
    } = tableColumnsCreator({
        mainColumns: getTableColumns,
        includedColumns,
        constructForInclude: true,
        additionalProps: {
            onLabelEdit: handleLabelFieldChange
        }
    })

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            icon: "icon-close",
            onClick: () => deleteProjectAgentSystemNetworkLayer(),
            disabled: record => record.layer !== network.length || record.layer === 1,
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            text: t("backoffice.projects.addLevel"),
            icon: "icon-plus",
            type: "primary",
            enabled: hasModifyPermission && network.length > 0,
            onClick: () => handleLayerAddition(1),
            dropdown: {
                items: [4, 8],
                renderText: i => `+ ${i}`,
                onClick: handleLayerAddition,
            },
        }
    }

    //#endregion

    return (
        <SubTabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={tableData}
                loadFn={getProjectAgentSystemNetwork}
                actions={tableRowActions}
                uniqueKey={TABLE_ROW_UNIQUE_KEY}
                noPagination={true}
                smallInMobile={true}
                mobileLayoutVertical={true}
                disableFullView={true}
            />
        </SubTabTableDashboardLayout>
    )
}

/** NetworkComponent propTypes
 * PropTypes
*/
NetworkComponent.propTypes = {
    /** Redux action to get project agent system network */
    getProjectAgentSystemNetwork: PropTypes.func,
    /** Redux action to change network level label */
    changeProjectAgentSystemNetworkLabel: PropTypes.func,
    /** Redux action to add network level */
    addProjectAgentSystemNetworkLayer: PropTypes.func,
    /** Redux action to delete network level */
    deleteProjectAgentSystemNetworkLayer: PropTypes.func,
    /** Redux state property, represents project agent system network  */
    network: PropTypes.arrayOf(agentSystemLevelType),
    /** Redux state property, is true when loading project agent system network */
    isLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => (
    {
        getProjectAgentSystemNetwork: () => {
            dispatch(getProjectAgentSystemNetwork());
        },
        changeProjectAgentSystemNetworkLabel: (layerId, label) => {
            dispatch(changeProjectAgentSystemNetworkLabel(layerId, label));
        },
        addProjectAgentSystemNetworkLayer: count => {
            dispatch(addProjectAgentSystemNetworkLayer(count));
        },
        deleteProjectAgentSystemNetworkLayer: () => {
            dispatch(deleteProjectAgentSystemNetworkLayer());
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.projects.isLoading,
        network: state.projects.edit.agentSystem.network.network,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkComponent)