import React from 'react';

import DigitalCode from "assets/images/digital-code.svg"

const TwoFactorDigitalCodeView = () => {
	return (
		<div className='rt--mt-16 rt--mb-16'>
			<img src={DigitalCode} alt="Digital Code"/>
		</div>
	);
}
 
export default TwoFactorDigitalCodeView;