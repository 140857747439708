import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'translations/config';
import { useParams, useLocation } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import TextAreaInput from 'components/common/textAreaInput';

import { addPermissionGroupUsers } from "store/actions/dashboard/userManagement/permissionGroups/users.action";

import { isFranchisingMode } from "utils/common";
import { getUser } from 'utils/auth';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { USER_TYPE, USER_ROLE } from "constants/user.constants";
import { PERMISSION_TYPE } from "constants/permissions.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import userInfoType from 'types/profile/userInfo.type';

const getAvailableAgentLayerFilterOptions = (availableUsers) => {
    const layersSet = ["", ...new Set(availableUsers.map(userData => userData.hierarchyLevel))];

    return layersSet.map(layer => {
        return {
            value: layer,
            text: layer === "" ? i18n.t("backoffice.common.all") : `${i18n.t("backoffice.agents.level")} ${layer}`,
        }
    })

}

/** User Adding Popup Component */
const UserAddComponent = ({
    isSaving,
    addPermissionGroupUsers,
    userInfo,
    onClose,
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();
    const { search } = useLocation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const userRole = getUser().role;

    const userType = Number((new URLSearchParams(search)).get("type") || USER_TYPE.ADMIN);

    const entityGroupType = Number((new URLSearchParams(search)).get("groupType"));

    /** Available user names */
    const [ permissionGroupAvailableUsers ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.USER_AVAILABLE_PERMISSION_GROUP, 
        actionParams: searchParams.id
    });

    const handleLayerFieldChange = () => {
        setFieldsValue({ "ids": [] })
    }

    /** Fires when form submitted
       * @function
       * @memberOf UserAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {

                addPermissionGroupUsers(data.ids, searchParams.id, data.note, onClose);

            }).catch(() => { })
    }

    const layerFieldOptions = getAvailableAgentLayerFilterOptions(
        permissionGroupAvailableUsers.filter(item => item.id !== userInfo.id)
    )

    return (
        <Modal
            title={
                userType === USER_TYPE.ADMIN ? t('backoffice.permissiongroups.addAdmin') :
                    isFranchisingMode() ? t("backoffice.permissiongroups.addBetshopOwner") :
                        entityGroupType === PERMISSION_TYPE.AGENT_PLAYER ? t("backoffice.permissiongroups.addPlayerAgent") : t("backoffice.permissiongroups.addAgent")
            }
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    agentLayer: ""
                }}
            >
                <Row gutter={[16, 0]}>
                    {(userType === USER_TYPE.AGENT && !isFranchisingMode()) && (
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.performancereport.agentLevel')}
                                name="agentLayer"
                            >
                                <Select
                                    options={layerFieldOptions}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.performancereport.agentLevel')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    onChange={handleLayerFieldChange}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={24}>
                        <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                                let fieldOptions = permissionGroupAvailableUsers.filter(item => item.id !== userInfo.id)

                                const selectedAgentLayer = getFieldValue("agentLayer");

                                if (userType === USER_TYPE.AGENT && !isFranchisingMode() && selectedAgentLayer !== "") {
                                    fieldOptions = fieldOptions.filter(item => item.hierarchyLevel === selectedAgentLayer)
                                }

                                return (
                                    <Form.Item
                                        label={
                                            (
                                                userType === USER_TYPE.ADMIN ? t('backoffice.permissiongroups.admins') :
                                                    isFranchisingMode() ? t('backoffice.permissiongroups.betshopOwners') :
                                                        t('backoffice.permissiongroups.agents')
                                            ) + " *"
                                        }
                                        name="ids"
                                        rules={[
                                            { required: true, message: t('backoffice.validation.fieldRequired') },
                                        ]}
                                    >
                                        <Select
                                            options={
                                                fieldOptions.map(item => (
                                                    { value: item.id, text: item.name }
                                                ))
                                            }
                                            placeholder={`${t('backoffice.common.select')} ${userType === USER_TYPE.ADMIN ? t('backoffice.permissiongroups.admins') : isFranchisingMode() ? t('backoffice.permissiongroups.betshopOwners') : t('backoffice.permissiongroups.agents')}`}
                                            isMultiple={true}
                                            search={true}
                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    {
                        userRole === USER_ROLE.ACCESS_MANAGER && (
                            <Col span={24} >
                                <Form.Item
                                    label={`${t('backoffice.users.notes')} *`}
                                    name="note"
                                    rules={[
                                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                        { max: 1000, message: t('backoffice.validation.fieldInvalid') },
                                        { min: 6, message: t('backoffice.validation.fieldInvalid') }
                                    ]}
                                    className="rt--form-item-without-margin rt--general-form-item"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                >
                                    <TextAreaInput
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                        maxLength={1000}
                                        rows={6}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>
            </Form>
        </Modal>
    )
}

/** UserAddComponent propTypes
    * PropTypes
*/
UserAddComponent.propTypes = {
    /** Redux state property, is true when adding user request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add users to permission group */
    addPermissionGroupUsers: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addPermissionGroupUsers: (ids, groupId, note, onSuccess) => {
            dispatch(addPermissionGroupUsers(ids, groupId, note, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.permissionGroups.isSaving,
        userInfo: state.profile.userInfo,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAddComponent)