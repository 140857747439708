export const COLORS_LIST = [
    ["--brand-color", "--secondary-color"],
    ["--bg-color", "--input-bg-color", "--message-bg-color", "--message-bg-color-active", "--upload-progress-bg", "--file-bg"],
    ["--text-color", "--text-color-2", "--text-color-passive", "--icon-color"],
    ["--avatar-bg-color-1", "--avatar-bg-color-2", "--avatar-bg-color-3", "--avatar-bg-color-4"]
]

export const COLOR_DEFAULTS = {
    "--brand-color": "#181827",
    "--secondary-color": "#0097C3",
    "--bg-color": "#FFFFFF",
    "--border-color": "#EFF0F7",
    "--input-bg-color": "#F2F3FB",
    "--message-bg-color": "#F7F7FC",
    "--message-bg-color-active": "#444D6B",
    "--upload-progress-bg": "#D9DBE9",
    "--file-bg": "#FBFBFF",
    "--text-color": "#14142B",
    "--text-color-2": "#FFFFFF",
    "--text-color-passive": "#A0A3BD",
    "--icon-color": "#A0A3BD",
    "--avatar-bg-color-1": "#FF896F",
    "--avatar-bg-color-2": "#6FCFB8",
    "--avatar-bg-color-3": "#FFB673",
    "--avatar-bg-color-4": "#6EE8C7"
}