import { useSelector } from 'react-redux';

import { binaryToFlags } from 'utils/common';

import { PAYMENT_METHODS, PROJECT_TYPE } from 'constants/project.constants';
import {PROJECT_MODE} from "constants/common.constants";

const selectGlobalProjectType = state => state.common.globalProjectType;
const selectGlobalPaymentType = state => state.common.globalPaymentType;
const selectGlobalProjectMode = state => state.common.globalProjectMode;

const useProjectType = () => {

    const globalProjectType = useSelector(selectGlobalProjectType);
    const globalPaymentType = useSelector(selectGlobalPaymentType);
    const globalProjectMode = useSelector(selectGlobalProjectMode);

    const projectTypes = binaryToFlags(Object.values(PROJECT_TYPE), Number(globalProjectType));
    const paymentTypes = binaryToFlags(Object.values(PAYMENT_METHODS), globalPaymentType ?? 0 );

    const hasAgentSystem = projectTypes.includes(PROJECT_TYPE.AGENT_SYSTEM);
    const hasRetail = projectTypes.includes(PROJECT_TYPE.RETAIL_SYSTEM);
    const hasLiteMode = globalProjectMode === PROJECT_MODE.LITE;

    const hasStandartPayment = paymentTypes.includes(PAYMENT_METHODS.STANDART);
    const hasBankTransfer = paymentTypes.includes(PAYMENT_METHODS.BANK_TRANSFER);
    const hasEpos = paymentTypes.includes(PAYMENT_METHODS.EPOS);

    return {
        hasAgentSystem,
        hasRetail,
        hasLiteMode,
        hasStandartPayment,
        hasBankTransfer,
        hasEpos
    }
}

export default useProjectType;
