import {
	SET_PAYMENT_REQUESTS_ACTION_BEFORE,
	SET_PAYMENT_REQUESTS_ACTION_FINISH,
	SET_PAYMENT_REQUESTS_SORTING,
	SET_PAYMENT_REQUESTS_FILTERS,
	SET_PAYMENT_REQUESTS,
	SET_BANK_FIELD_TRANSLATIONS_ACTION_BEFORE,
	SET_BANK_FIELD_TRANSLATIONS_ACTION_FINISH,
	SET_BANK_FIELD_TRANSLATIONS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	let transactions = [];
	switch (type) {
		case SET_PAYMENT_REQUESTS_ACTION_BEFORE:
			return {
				...state,
				isLoading: true,
			};
		case SET_PAYMENT_REQUESTS_ACTION_FINISH:
			return {
				...state,
				isLoading: false,
			};
		case SET_PAYMENT_REQUESTS:
			transactions = payload.transactions.map(t => {
				let userInfo = t.userInfo ? JSON.parse(t.userInfo) : t.userInfo;
				if (userInfo) {
					delete userInfo.amount;
				}
				return { ...t, userInfo }
			});
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					paymentRequests: transactions,
				}
			};
		case SET_PAYMENT_REQUESTS_SORTING:
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					sorting: payload.sorting,
				}
				
			};
		case SET_PAYMENT_REQUESTS_FILTERS:
			return {
				...state,
				[payload.type]: {
					...state[payload.type],
					filters: payload.filters,
					sorting: {
						...state[payload.type].sorting,
						page: payload.keepPage ? state[payload.type].sorting.page : 1,
					},
				}
				
			};
		case SET_BANK_FIELD_TRANSLATIONS_ACTION_BEFORE:
			return {
				...state,
				isTranslationsLoading: true
			}
		case SET_BANK_FIELD_TRANSLATIONS_ACTION_FINISH:
			return {
				...state,
				isTranslationsLoading: false
			}
		case SET_BANK_FIELD_TRANSLATIONS:
			return {
				...state,
				translations: {
					...state.translations,
					[payload.bankId + "_" + payload.type]: payload.translations
				}
			}
		default:
			return state;
	}
};
