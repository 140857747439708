import { isMobile } from "NEW/src/core/helpers/appEnvironment/isMobile";
import Flag from "NEW/src/core/ui/flag";

export const languagesDropdownMenu = (selectedLocale, languages, t) =>
	Object.keys(languages).map((locale) => ({
		key: locale,
		label: (
			<div
				className={
					"rt--flex rt--align-center rt--justify-between rt--languages-list-item" +
					(locale === selectedLocale ? " rt--languages-list-item-selected" : "")
				}
			>
				<div
					className={
						"rt--flex rt--align-center rt--justify-between" +
						(isMobile() ? " rt--flex-row-reverse" : " rt--flex-equal")
					}
				>
					<span
						className={
							"rt--title rt--font-normal rt--font-regular " +
							(isMobile() ? "rt--pl-8" : "rt--pr-8")
						}
					>
						{t(`backoffice.languages.${locale}`)}
					</span>
					<Flag code={locale} />
				</div>
				{locale === selectedLocale && isMobile() && (
					<i className="icon-ok rt--font-bigest" />
				)}
			</div>
		),
	}));
