import React from 'react';

import TabTableDashboardLayout from "components/layouts/tab/table";
import {isFranchisingMode} from "utils/common";
import RetailStatisticsTab from "pages/dashboard/tabs/statisticsTab/retails";
import AgentStatisticsTab from "pages/dashboard/tabs/statisticsTab/agent";

const StatisticsTab = ({ filters, updateLastUpdatedDate }) => {
    const globalFilters = filters.global;

    return (
        <TabTableDashboardLayout
            headerBorder={false}
        >
            {
                isFranchisingMode()
                    ? <RetailStatisticsTab filters={globalFilters} updateLastUpdatedDate={updateLastUpdatedDate} />
                    : <AgentStatisticsTab filters={globalFilters} updateLastUpdatedDate={updateLastUpdatedDate} />
            }
        </TabTableDashboardLayout>
    );
};

export default StatisticsTab;
