import { isArray } from "./isArray";
import { isNull } from "./isNull";

export const isObjectLiteral = (value) => {
	if (isNull(value)) {
		return false;
	}

	if (isArray(value)) {
		return false;
	}

	return typeof value === "object";
};
