import React, {useState} from 'react';
import PropTypes from "prop-types";

import {connect} from "react-redux";

import {Button} from "antd";

import {useTranslation} from "react-i18next";

import {hasPermission} from "utils/permissions";
import {isFranchisingMode} from "utils/common";

import useProjectType from "hooks/useProjectType";

import Modal from "components/common/modal";
import CardChooser from "components/common/cardChooser";
import PlayerCreateComponent from "pages/players/player-create.component";
import AgentCreateComponent from "pages/agentSystem/agents/agent-create.component";

import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import {chooserItems, CREATE_MODAL_TYPE} from "components/common/header/createButton/constants";

const CreateButton = ({ globalProjectId }) => {
    const [chooserModal, setChooserModal] = useState(false);
    const [playerModal, setPlayerModal] = useState(false);
    const [agentModal, setAgentModal] = useState(false);

    const { t } = useTranslation();

    const { hasAgentSystem } = useProjectType();

    const hasPlayerCreatePermission = (
        hasPermission({
            resource: PERMISSION_RESOURCE.PLAYER,
            action: PERMISSION_ACTION.CREATE
        }) &&
        !isFranchisingMode() &&
        hasAgentSystem
    );

    const hasAgentCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT,
        action: PERMISSION_ACTION.CREATE
    });

    const hideButton = (
        !globalProjectId ||
        (!hasPlayerCreatePermission && !hasAgentCreatePermission)
    );

    const handleCreateClick = () => {
        if (!hasPlayerCreatePermission || !hasAgentCreatePermission) {
            if (hasPlayerCreatePermission) {
                setPlayerModal(true);
                return;
            }

            if (hasAgentCreatePermission) {
                setAgentModal(true);
                return;
            }
        }

        setChooserModal(true);
    }

    const handleChooserClose = () => {
        setChooserModal(false);
    }

    const handleChooserSelect = (value) => {
        setChooserModal(false);

        if (value === CREATE_MODAL_TYPE.AGENT) {
            setAgentModal(true);
        }

        if (value === CREATE_MODAL_TYPE.PLAYER) {
            setPlayerModal(true);
        }
    }

    const handlePlayerModalClose = () => {
        setPlayerModal(false);
    }

    const handleAgentModalClose = () => {
        setAgentModal(false);
    }

    if (hideButton) {
        return null;
    }

    return (
        <>
            <Button
                icon={<i className="icon-plus rt--font-bigest" />}
                type="primary"
                className="rt--button rt--button-main rt--ml-16 rt--mr-16"
                onClick={handleCreateClick}
            >
                {t("backoffice.common.create")}
            </Button>

            {chooserModal && (
                <Modal
                    title={t("backoffice.common.create")}
                    hideOkButton
                    cancelText={t('backoffice.common.cancel')}
                    onCancel={handleChooserClose}
                >
                    <CardChooser items={chooserItems(t)} onSelect={handleChooserSelect} />
                </Modal>
            )}

            {agentModal && (
                <AgentCreateComponent
                    onClose={handleAgentModalClose}
                />
            )}

            {playerModal && (
                <PlayerCreateComponent
                    onClose={handlePlayerModalClose}
                />
            )}
        </>
    );
};

CreateButton.propTypes = {
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(mapStateToProps, null)(CreateButton);
