export const getTableColumns = (additionalProps) => {
    const { 
        formatAmount
    } = additionalProps;

    return [
        {
            title: "backoffice.betshops.id",
            dataIndex: "longId",
            copy: true,
            sorter: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.betshops.name",
            dataIndex: "name",
            sorter: true,
            mobileLevel: 3,
            render: (value, record) => (
                Boolean(value)
                    ? record.isTesting ? `${value} (Test)` : value
                    : "-"
            ),
        },
        {
            title: "backoffice.betshops.createdAt",
            dataIndex: "createdAt",
            mobileLevel: 2,
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.betshops.currency",
            dataIndex: "currencyCode",
            mobileLevel: 4,
        },
        {
            title: "backoffice.common.balance",
            dataIndex: "balance",
            mobileLevel: 5,
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode),
        },
        {
            title: "backoffice.common.limit",
            dataIndex: "limit",
            mobileLevel: 6,
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode)
        },
    ]
};