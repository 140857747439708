import React, {useEffect, useState} from 'react';

import PropTypes from "prop-types";

import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {AutoComplete, Col, Form, message, Row, Spin} from "antd";

import NumericInput from "components/common/numericInput";
import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import useProjectType from "hooks/useProjectType";

import {numberTransform} from "utils/common";
import {hasPermission} from "utils/permissions";
import {favoriteBetsRepeatingIndexes, validateMax, validateMin, validatePosMax} from "utils/currency";

import {FAVORITE_BETS} from "constants/bet.constants";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import {getVSBetAmounts, saveVSBetAmounts} from "pages/projects/edit/sections/favAmountsAndLimits/vsBetAmounts/api";
import {isFormChanged} from "utils/form";


const VSBetsAmounts = ({
    availableCurrencies,
    currency,
    onTabChange,
    handleCurrencyChange,
}) => {
    const [initialData, setInitialData] = useState(null);
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const [formInstance] = Form.useForm();

    const { t } = useTranslation();
    const { hasLiteMode } = useProjectType();

    const { validateFields, getFieldValue, setFieldsValue, resetFields } = formInstance;

    const userCanModify = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VS_BET_LIMITS, action: PERMISSION_ACTION.MODIFY });

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched]);

    useEffect(() => {
        if (currency) {
            setIsLoading(true);

            getVSBetAmounts(currency.toUpperCase())
                .then(({ data: { value } }) => {
                    setInitialData(value);
                    resetFields();
                    setFieldsValue(value);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [currency]);

    const handleForm = () => {
        validateFields()
            .then(() => {
                setIsSaving(true);

                saveVSBetAmounts({ ...formInstance.getFieldsValue(), code: currency.toUpperCase() })
                    .then(({ data }) => {
                        setInitialData(data.value);
                        setFieldsValue(data.value);
                        setIsFormTouched(false);

                        message.success(data.message);
                    })
                    .finally(() => {
                        setIsSaving(false);
                    });
            });
    }

    const handleFormValuesChange = () => {
        setIsFormTouched(isFormChanged(formInstance.getFieldsValue(), initialData));
    }

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        loading: isSaving,
                        enabled: true,
                        disabled: !isFormTouched || !userCanModify
                    }
                ]
            }
            dropdown={
                {
                    value: currency,
                    onChange: value => handleCurrencyChange(value, isFormTouched, setIsFormTouched),
                    items: Object.keys(availableCurrencies).map(item => ({
                        key: item.toLowerCase(),
                        value: item.toUpperCase()
                    })),
                    small: true,
                    disabled: hasLiteMode
                }
            }
        >
            <Spin spinning={isLoading}>
                <Form
                    form={formInstance}
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={handleFormValuesChange}
                    disabled={!userCanModify}
                >
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                        {
                            t('backoffice.currencies.betAmountLimits')
                        }
                    </h4>

                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                label={t('backoffice.currencies.minSingle')}
                                name={[`betLimit`, 'singleMin']}
                                rules={[
                                    {required: true, message: t('backoffice.validation.fieldRequired')},
                                    {
                                        type: "number",
                                        min: 0.01,
                                        message: t('backoffice.validation.fieldInvalid'),
                                        transform: numberTransform
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return validateMin(value, getFieldValue('betLimit').singleMax);
                                        }
                                    })
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.minSingle')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.minSingle')}`}
                                    onChange={() => {
                                        setTimeout(() => {
                                            if (getFieldValue(['betLimit', 'singleMax']) !== "") {
                                                validateFields([['betLimit', 'singleMax']])
                                            }
                                            const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                            validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                        }, 0)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                label={t('backoffice.currencies.minMulti')}
                                name={[`betLimit`, 'multiMin']}
                                rules={[
                                    {required: true, message: t('backoffice.validation.fieldRequired')},
                                    {
                                        type: "number",
                                        min: 0.01,
                                        message: t('backoffice.validation.fieldInvalid'),
                                        transform: numberTransform
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return validateMin(value, getFieldValue('betLimit').multiMax);
                                        }
                                    })
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.minMulti')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.minMulti')}`}
                                    onChange={() => {
                                        setTimeout(() => {
                                            if (getFieldValue(['betLimit', 'multiMax']) !== "") {
                                                validateFields([['betLimit', 'multiMax']])
                                            }
                                            const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                            validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                        }, 0)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={8}>
                            <Form.Item
                                label={t('backoffice.currencies.maxPossibleWinSingle')}
                                name={[`betLimit`, 'singlePossibleWinMax']}
                                rules={[
                                    {required: true, message: t('backoffice.validation.fieldRequired')},
                                    {
                                        type: "number",
                                        min: 0.01,
                                        message: t('backoffice.validation.fieldInvalid'),
                                        transform: numberTransform
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return validatePosMax(getFieldValue('betLimit').singleMax, value);
                                        }
                                    })
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxPossibleWinSingle')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxPossibleWinSingle')}`}
                                    onChange={() => {
                                        setTimeout(() => {
                                            if (getFieldValue(['betLimit', 'singleMax']) !== "") {
                                                validateFields([['betLimit', 'singleMax']])
                                            }
                                        }, 0)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={8}>
                            <Form.Item
                                label={t('backoffice.currencies.maxSingle')}
                                name={[`betLimit`, 'singleMax']}
                                rules={[
                                    {required: true, message: t('backoffice.validation.fieldRequired')},
                                    {
                                        type: "number",
                                        min: 0.01,
                                        message: t('backoffice.validation.fieldInvalid'),
                                        transform: numberTransform
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return validateMax(getFieldValue('betLimit').singleMin, value, getFieldValue('betLimit').singlePossibleWinMax);
                                        }
                                    })
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxSingle')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxSingle')}`}
                                    onChange={() => {
                                        setTimeout(() => {
                                            if (getFieldValue(['betLimit', 'singleMin']) !== "") {
                                                validateFields([['betLimit', 'singleMin']])
                                            }
                                            if (getFieldValue(['betLimit', 'singlePossibleWinMax']) !== "") {
                                                validateFields([['betLimit', 'singlePossibleWinMax']])
                                            }

                                            const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                            validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                        }, 0)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={8}>
                            <Form.Item
                                label={t('backoffice.currencies.maxMulti')}
                                name={[`betLimit`, 'multiMax']}
                                rules={[
                                    {required: true, message: t('backoffice.validation.fieldRequired')},
                                    {
                                        type: "number",
                                        min: 0.01,
                                        message: t('backoffice.validation.fieldInvalid'),
                                        transform: numberTransform
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return validateMax(getFieldValue('betLimit').multiMin, value, getFieldValue('betLimit').multiPossibleWinMax);
                                        }
                                    })
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxMulti')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxMulti')}`}
                                    onChange={() => {
                                        setTimeout(() => {
                                            if (getFieldValue(['betLimit', 'multiMin']) !== "") {
                                                validateFields([['betLimit', 'multiMin']])
                                            }
                                            if (getFieldValue(['betLimit', 'multiPossibleWinMax']) !== "") {
                                                validateFields([['betLimit', 'multiPossibleWinMax']])
                                            }

                                            const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "")
                                            validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                        }, 0)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={8}>
                            <Form.Item
                                label={t('backoffice.currencies.maxPossibleWinMulti')}
                                name={[`betLimit`, 'multiPossibleWinMax']}
                                rules={[
                                    {required: true, message: t('backoffice.validation.fieldRequired')},
                                    {
                                        type: "number",
                                        min: 0.01,
                                        message: t('backoffice.validation.fieldInvalid'),
                                        transform: numberTransform
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return validatePosMax(getFieldValue('betLimit').multiMax, value);
                                        }
                                    })
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!userCanModify ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxPossibleWinMulti')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.currencies.maxPossibleWinMulti')}`}
                                    onChange={() => {
                                        setTimeout(() => {
                                            if (getFieldValue(['betLimit', 'multiMax']) !== "") {
                                                validateFields([['betLimit', 'multiMax']])
                                            }
                                        }, 0)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                        {
                            t('backoffice.currencies.favoriteBets')
                        }
                    </h4>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} lg={8}>
                            <Row gutter={[16, 0]}>
                                {
                                    [0, 1, 2, 3, 4, 5, 6, 7].map(i => (
                                        <Col xs={12} sm={6} key={i}>
                                            <Form.Item
                                                name={["favoriteBets", i]}
                                                rules={[
                                                    {required: true, message: t('backoffice.validation.fieldRequired')},
                                                    ({getFieldValue}) => ({
                                                        validator(rule, value) {
                                                            if (value.length > 20 || (
                                                                (value !== "" && (isNaN(value) || Number(value) < 0.01)) &&
                                                                !FAVORITE_BETS.includes(value)
                                                            )) {
                                                                return Promise.reject(t('backoffice.validation.fieldInvalid'))
                                                            } else if (favoriteBetsRepeatingIndexes(getFieldValue("favoriteBets")).includes(i)) {
                                                                return Promise.reject(t("backoffice.validation.favoriteBetsNoDuplicate"))
                                                            } else if (!FAVORITE_BETS.includes(value) && value !== "") {
                                                                let isMinValid = false, isMaxValid = false;
                                                                if (getFieldValue('betLimit').multiMax === "" || getFieldValue('betLimit').singleMax === "" || Number(value) <= Number(getFieldValue('betLimit').multiMax) || Number(value) <= Number(getFieldValue('betLimit').singleMax))
                                                                    isMaxValid = true;
                                                                if (getFieldValue('betLimit').multiMin === "" || getFieldValue('betLimit').singleMin === "" || Number(value) >= Number(getFieldValue('betLimit').multiMin) || Number(value) >= Number(getFieldValue('betLimit').singleMin))
                                                                    isMinValid = true;

                                                                if (!isMaxValid) {
                                                                    return Promise.reject(t('backoffice.validation.favoriteBetsMax'));
                                                                } else if (!isMinValid) {
                                                                    return Promise.reject(t('backoffice.validation.favoriteBetsMin'));
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                                className={!userCanModify ? "rt--form-item-disabled" : ""}
                                            >
                                                <AutoComplete
                                                    placement='topLeft'
                                                    filterOption={() => true}
                                                    options={FAVORITE_BETS.map(value => ({value}))}
                                                    onChange={() => {
                                                        setTimeout(() => {
                                                            const nonEmpty = getFieldValue("favoriteBets").map((f, i) => f !== "" ? i : f).filter(f => f !== "");
                                                            validateFields(nonEmpty.map(f => ["favoriteBets", f]))
                                                        }, 0)
                                                    }}
                                                    showArrow={true}
                                                    suffixIcon={(<i className="icon-down"/>)}
                                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    );
};

VSBetsAmounts.propTypes = {
    availableCurrencies: PropTypes.object,
    currency: PropTypes.string,
    handleCurrencyChange: PropTypes.func,
    onTabChange: PropTypes.func,
};

export default VSBetsAmounts;
