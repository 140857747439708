import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Spin } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import FormBuilder from 'components/common/formBuilder';

import {
    getAgentRegistrationForm,
    saveAgentRegistrationForm,
    getPlayerRegistrationForm,
    savePlayerRegistrationForm
} from "store/actions/dashboard/projects/registrationForm.action";

import registrationFormType from "types/project/registrationForm.type";

import { hasPermission } from 'utils/permissions';

import { COMPANY_CONTROL_RULE } from "constants/company.constants";
import { REGISTRATION_FORM_TYPE } from "constants/project.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Project Edit Page Registration Form Tab SubTab Component */
const RegistrationFormComponent = ({
    isLoading,
    isSaving,
    getAgentRegistrationForm,
    saveAgentRegistrationForm,
    getPlayerRegistrationForm,
    savePlayerRegistrationForm,
    agentRegistrationForm,
    playerRegistrationForm,
    registrationFormType,
    onTabChange
}) => {
    const { t } = useTranslation();

    const formBuilderRef = useRef({
        handleForm: null,
        isFormTouched: null,
    });

    useEffect(() => {
        if (registrationFormType === REGISTRATION_FORM_TYPE.AGENT) {
            getAgentRegistrationForm()
        } else if (registrationFormType === REGISTRATION_FORM_TYPE.PLAYER) {
            getPlayerRegistrationForm()
        }
    }, [])

    const registrationForm = registrationFormType === REGISTRATION_FORM_TYPE.AGENT ? agentRegistrationForm : playerRegistrationForm;

    const handleFormFinish = (values) => {
        if (registrationFormType === REGISTRATION_FORM_TYPE.AGENT) {
            saveAgentRegistrationForm(values)
        } else if (registrationFormType === REGISTRATION_FORM_TYPE.PLAYER) {
            savePlayerRegistrationForm(values)
        }
    }

    const validateFormFieldsValue = (values) => {
        if (registrationFormType === REGISTRATION_FORM_TYPE.PLAYER) {
            if (![values["UserName"], values["Email"], values["PhoneNumber"]].includes(COMPANY_CONTROL_RULE.REQUIRED)) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: () => formBuilderRef.current.handleForm(),
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !formBuilderRef.current.isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading}>
                <FormBuilder 
                    registrationForm={registrationForm}
                    onTabChange={onTabChange}
                    userCanModify={hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM, action: PERMISSION_ACTION.MODIFY })}
                    ref={formBuilderRef}
                    validateFormValues={validateFormFieldsValue}
                    onFormFinish={handleFormFinish}
                    fieldNameRender={fieldName => t("backoffice.registrationform." + fieldName)}
                    fieldOrder={[
                    'UserName',
                    'Email',
                    'PhoneNumber',
                    'Password',
                    'ConfirmPassword',
                    'ParentId',
                    'CurrencyCodes',
                    'CurrencyCode',
                    'FirstName',
                    'MiddleName',
                    'LastName',
                    'BirthDate',
                    'Gender',
                    'Country',
                    'City',
                    'Address',
                    'ZipCode',
                    'DocumentType',
                    'DocumentNumber',
                    'Attachment'
                ]}
                />
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** RegistrationFormComponent propTypes
 * PropTypes
*/
RegistrationFormComponent.propTypes = {
    /** Redux state property, is true when loading registration form data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving registration form data */
    isSaving: PropTypes.bool,
    /** Redux action to get agent registration form */
    getAgentRegistrationForm: PropTypes.func,
    /** Redux action to save agent registration form */
    saveAgentRegistrationForm: PropTypes.func,
    /** Redux action to get player registration form */
    getPlayerRegistrationForm: PropTypes.func,
    /** Redux action to save player registration form */
    savePlayerRegistrationForm: PropTypes.func,
    /** Redux state property, represents project agent registration form  */
    agentRegistrationForm: PropTypes.arrayOf(registrationFormType),
    /** Redux state property, represents player registration form  */
    playerRegistrationForm: PropTypes.arrayOf(registrationFormType),
    /** Registration Form Type */
    registrationFormType: PropTypes.oneOf(Object.values(REGISTRATION_FORM_TYPE)),
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => (
    {
        getAgentRegistrationForm: () => {
            dispatch(getAgentRegistrationForm());
        },
        saveAgentRegistrationForm: form => {
            dispatch(saveAgentRegistrationForm(form));
        },
        getPlayerRegistrationForm: () => {
            dispatch(getPlayerRegistrationForm());
        },
        savePlayerRegistrationForm: form => {
            dispatch(savePlayerRegistrationForm(form));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.projects.isLoading,
        isSaving: state.projects.isSaving,
        agentRegistrationForm: state.projects.edit.registrationForm.agentRegistrationForm,
        playerRegistrationForm: state.projects.edit.registrationForm.playerRegistrationForm,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormComponent)