//#region react
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from 'react-router-dom';
//#endregion

//#region actions
import {
    getAgentCalculationHistory,
    setAgentCalculationHistorySorting,
    setAgentCalculationHistoryFilters
} from "store/actions/dashboard/reports/agentCalculationHistory.action";
//#endregion

//#region hooks
import useFormat from "hooks/useFormat";
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
//#endregion

//#region utils
import { isFranchisingMode } from "utils/common";
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import Paths from 'constants/path.constants';
import { AGENT_CALCULATION_REPORT } from "constants/pageName.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import agentCalculationHistoryType from "types/reports/agentCalculationHistory.type";
//#endregion

const ROW_UNIQUE_KEY_PROP = "agentId";

/** Agent Calculation History Page Component */
const AgentCalculationHistoryComponent = ({
    getAgentCalculationHistory,
    history,
    isLoading,
    total,
    setAgentCalculationHistorySorting,
    setAgentCalculationHistoryFilters,
    sorting,
    filters
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();
    const { search } = useLocation();

    const { formatNumber } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AGENT_CALCULATION_REPORT });

    const queryParams = new URLSearchParams(search);
    const entityName = queryParams.get("name");

    const franchisingMode = isFranchisingMode()

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasPermissionToExport = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_COMMISSION_CALCULATIONS,
        action: PERMISSION_ACTION.EXPORT
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAgentCalculationHistoryFilters({
            ...filters,
            userNameOrId: value
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                franchisingMode,
                formatNumber
            }
        })
    }, [franchisingMode, includedColumns, formatNumber])

    //#endregion

    //#region  -------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        breadcrumbs: {
            items: [
                {
                    title: t('backoffice.menu.commissionCalculations'),
                    path: Paths.REPORTS_COMMISSION_CALCULATIONS
                },
                { title: entityName || "Commission Plan" }
            ]
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            onSearch: handleSearchChange,
            loadFn: () => getAgentCalculationHistory(searchParams.id),
            placeholder: t("backoffice.commissioncalculations.usernameOrId"),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AGENT,
            }
        },
    }

    if (hasPermissionToExport) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.commissionCalculations"),
            url: ApiUrls.EXPORT_AGENT_CALCULATION_HISTORY,
            filters: { id: searchParams.id }
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={history}
                loadFn={() => getAgentCalculationHistory(searchParams.id)}
                sorting={sorting}
                setSortingFn={setAgentCalculationHistorySorting}
                total={total}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
            />
        </MainDashboardLayout>
    )
};

/** AgentCalculationHistoryComponent propTypes
 * PropTypes
*/
AgentCalculationHistoryComponent.propTypes = {
    /** Redux action to get agent calculation history */
    getAgentCalculationHistory: PropTypes.func,
    /** Redux state property, represents the commission calculation report  */
    history: PropTypes.arrayOf(agentCalculationHistoryType),
    /** Redux state property, is true when loading agent calculation history */
    isLoading: PropTypes.bool,
    /** Redux state property, agent calculation history total count */
    total: PropTypes.number,
    /** Redux action to set agent calculation history sorting details */
    setAgentCalculationHistorySorting: PropTypes.func,
    /** Redux action to set agent calculation history filters */
    setAgentCalculationHistoryFilters: PropTypes.func,
    /** Redux state property, agent calculation history sorting details */
    sorting: sortingType,
    /** Redux state property, gent calculation history filters */
    filters: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
    getAgentCalculationHistory: (id, nextPage) => {
        dispatch(getAgentCalculationHistory(id, nextPage));
    },
    setAgentCalculationHistorySorting: sorting => {
        dispatch(setAgentCalculationHistorySorting(sorting));
    },
    setAgentCalculationHistoryFilters: filters => {
        dispatch(setAgentCalculationHistoryFilters(filters));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.agentCalculationHistory.isLoading,
        history: state.agentCalculationHistory.history,
        total: state.agentCalculationHistory.total,
        sorting: state.agentCalculationHistory.sorting,
        filters: state.agentCalculationHistory.filters,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgentCalculationHistoryComponent);
