import {
    SET_COMMISSION_PLANS_ACTION_BEFORE,
    SET_COMMISSION_PLANS_ACTION_FINISH,
    SET_COMMISSION_PLANS,
    SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE,
    SET_COMMISSION_PLANS_SAVE_ACTION_FINISH,
    SET_AVAILABLE_COMMISSION_PLANS,
    SET_AVAILABLE_COMMISSION_PLANS_BEFORE,
    SET_AVAILABLE_COMMISSION_PLANS_FINISH,
    SET_COMMISSION_PLAN_SETTINGS,
    SET_COMMISSION_PLAN_SPORT_SETTINGS,
    SET_COMMISSION_PLAN_AGENTS,
    SET_COMMISSION_PLAN_AGENTS_SORTING,
    SET_COMMISSION_PLAN_AGENTS_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_COMMISSION_PLANS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_COMMISSION_PLANS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_COMMISSION_PLANS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_COMMISSION_PLANS:
            return {
                ...state,
                commissionPlans: payload.commissionPlans
            };
        case SET_AVAILABLE_COMMISSION_PLANS_BEFORE:
            return {
                ...state,
                isAvailableCommissionPlansLoading: true
            };
        case SET_AVAILABLE_COMMISSION_PLANS_FINISH:
            return {
                ...state,
                isAvailableCommissionPlansLoading: false
            };
        case SET_AVAILABLE_COMMISSION_PLANS:
            return {
                ...state,
                availableCommissionPlans: payload.commissionPlans
            };
        case SET_COMMISSION_PLAN_SETTINGS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    settings: payload.data
                }
            };
        case SET_COMMISSION_PLAN_SPORT_SETTINGS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    sportSettings: {
                        ...state.edit.sportSettings,
                        [payload.type]: payload.data
                    }
                }
            };
            case SET_COMMISSION_PLAN_AGENTS:
                return {
                    ...state,
                    edit: {
                        ...state.edit,
                        agents: {
                            ...state.edit.agents,
                            agents: !payload.add ? payload.agents.item2 : state.edit.agents.agents.concat(payload.agents.item2),
                            total: payload.agents.item1,
                        },
                    }
                };
            case SET_COMMISSION_PLAN_AGENTS_SORTING:
                return {
                    ...state,
                    edit: {
                        ...state.edit,
                        agents: {
                            ...state.edit.agents,
                            sorting: payload.sorting
                        },
                    },
                };
            case SET_COMMISSION_PLAN_AGENTS_FILTERS:
                return {
                    ...state,
                    edit: {
                        ...state.edit,
                        agents: {
                            ...state.edit.agents,
                            filters: payload.filters,
                            sorting: {
                                ...state.edit.agents.sorting,
                                page: 1
                            }
                        },
                    },
                };
        default:
            return state;
    }
};
