import React from "react";

import PerformanceReportComponent from "pages/reports/performanceReport/performanceReport.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const PerformanceReportRoute = () => {
    return <PerformanceReportComponent />;
};

export default withPermission(
    withAuth(PerformanceReportRoute),
    [
        {
            resource: PERMISSION_RESOURCE.REPORTS_AGENT_PERFORMANCE,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.REPORTS_PLAYER_PERFORMANCE,
            action: PERMISSION_ACTION.VIEW,
        }
    ],
    Paths.DEFAULT
);
