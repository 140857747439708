import {
    SET_BETSHOP_MANAGERS_ACTION_BEFORE,
    SET_BETSHOP_MANAGERS_ACTION_FINISH,
    SET_BETSHOP_MANAGERS,
    SET_BETSHOP_MANAGERS_SORTING,
    SET_BETSHOP_MANAGERS_FILTERS,
    SET_BETSHOP_MANAGERS_SAVE_ACTION_BEFORE,
    SET_BETSHOP_MANAGERS_SAVE_ACTION_FINISH,
    SET_BETSHOP_MANAGER_GENERAL_INFO,
    SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_BEFORE,
    SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_FINISH,
    SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BETSHOP_MANAGERS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BETSHOP_MANAGERS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_BETSHOP_MANAGERS:
            return {
                ...state,
                betshopManagers: !payload.add ? payload.betshopManagers.item2 : state.betshopManagers.concat(payload.betshopManagers.item2),
                total: payload.betshopManagers.item1,
            };

        case SET_BETSHOP_MANAGERS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_BETSHOP_MANAGERS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_BETSHOP_MANAGERS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_BETSHOP_MANAGERS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_BETSHOP_MANAGER_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_BEFORE:
            return {
                ...state,
                availableBetshops: {
                    ...state.availableBetshops,
                    isLoading: true
                }
            }
        case SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS_FINISH:
            return {
                ...state,
                availableBetshops: {
                    ...state.availableBetshops,
                    isLoading: false
                }
            }
        case SET_BETSHOP_MANAGER_AVAILABLE_BETSHOPS:
            return {
                ...state,
                availableBetshops: {
                    ...state.availableBetshops,
                    availableBetshops: payload.betshops
                }
            }
        default:
            return state;
    }
};
