import { useContext, useEffect } from "react";

import UnsavedFormsContext from "components/common/unsavedFormsProvider/unsavedFormsContext";

const useUnsavedForm = (pageType = "") => {
    const {
        setToUnsavedForms,
        removeFromUnsavedForms,
        resetUnsavedForms,
    } = useContext(UnsavedFormsContext)

    const setToUnsavedFormsWrapped = (data = {}) => {
        const { type = pageType, key, value } = data;

        setToUnsavedForms({ type, key, value });
    }

    const removeFromUnsavedFormsWrapped = (data = {}) => {
        const { type = pageType, key } = data;

        removeFromUnsavedForms({ type, key });
    }

    useEffect(() => {
        return () => {
            resetUnsavedForms(pageType);
        }
    }, []);

    return {
        setToUnsavedForms: setToUnsavedFormsWrapped,
        removeFromUnsavedForms: removeFromUnsavedFormsWrapped,
    };
}
 
export default useUnsavedForm;