import React from 'react';

import { LEADERBOARD_TYPE } from "../constants";

export const getTableColumns = (additionalProps) => {
    const {
        type,
        currencyCode,
        formatAmount
    } = additionalProps;

    return [
        {
            title: "#",
            render: (value, rowData, index) => <div className={'rt--chart-top-user-num rt--chart-top-user-num' + '-' + (index + 1)}>
                <span className={'rt--font-normal rt--font-regular'}>
                    {index + 1}
                </span>
            </div>
        },
        {
            title: "backoffice.dashboard.id",
            dataIndex: "id",
            copy: true,
        },
        {
            title: "backoffice.dashboard.username",
            dataIndex: "userName",
            render: (_, record) => record.userName || record.name
        },
        type === LEADERBOARD_TYPE.FINANCIAL && {
            title: "backoffice.dashboard.amount",
            dataIndex: "amount",
            render: value => formatAmount(value, currencyCode)
        },
        {
            title: "backoffice.dashboard.count",
            dataIndex: "count",
            isNumeric: true,
        }
    ];
}
