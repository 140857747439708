import {
    SET_NOTIFICATIONS_ACTION_BEFORE,
    SET_NOTIFICATIONS_ACTION_FINISH,
    SET_NOTIFICATIONS,
    ADD_NOTIFICATION,
    SET_NOTIFICATION_SOUND,
    DELETE_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION,
    SET_UNREAD_NOTIFICATION_COUNT,
    MARK_ALL_AS_READ_NOTIFICATIONS,
    DELETE_ALL_NOTIFICATIONS
} from '../../actionTypes';

import sessionStorageUtils from "utils/sessionStorage";

import { NOTIFICATION_STATE_ENUM } from 'constants/notification.constants';

export default (state = {}, { type, payload }) => {
    let notifications = [];
    let count = 0;
    switch (type) {
        case SET_NOTIFICATIONS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_NOTIFICATIONS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: [...(state.notifications || []), ...payload.notifications],
                canLoadMore: payload.notifications.length === 20
            }

        case SET_UNREAD_NOTIFICATION_COUNT:
            sessionStorageUtils.set("unreadNotificationsCount", payload.count);

            return {
               ...state,
                unreadNotificationsCount: payload.count
            }

        case ADD_NOTIFICATION:
            notifications = [...state.notifications];
            count = state.unreadNotificationsCount;

            if(notifications.every(n => n.id !== payload.notification.id)){
                notifications.unshift(payload.notification);
                count++;
            }
            sessionStorageUtils.set("unreadNotificationsCount", count);

            return {
                ...state,
                notifications: notifications,
                unreadNotificationsCount: count,
                sound: true
            }
        case SET_NOTIFICATION_SOUND:
            return {
                ...state,
                sound: payload.show
            }
        case MARK_AS_READ_NOTIFICATION:
            count = state.unreadNotificationsCount;

            notifications = state.notifications.map(n => {
                if (n.id === payload.id && n.state === NOTIFICATION_STATE_ENUM.UNREAD) {
                    count--;
                    return {...n, state: NOTIFICATION_STATE_ENUM.READ};
                }

                return n;
            });

            sessionStorageUtils.set("unreadNotificationsCount", count);

            return {
                ...state,
                notifications: notifications,
                unreadNotificationsCount: count
            }
        case MARK_ALL_AS_READ_NOTIFICATIONS:
            notifications = state.notifications.map(n => ({...n, state: NOTIFICATION_STATE_ENUM.READ}));
            sessionStorageUtils.set("unreadNotificationsCount", 0);

            return {
                ...state,
                notifications: notifications,
                unreadNotificationsCount: 0
            }
        case DELETE_NOTIFICATION:
            count = state.unreadNotificationsCount;

            notifications = state.notifications.filter(n => {
                if (n.id === payload.id) {
                    if (n.state === NOTIFICATION_STATE_ENUM.UNREAD) {
                        count--;
                    }

                    return false;
                }

                return true;
            });

            sessionStorageUtils.set("unreadNotificationsCount", count);
            return {
                ...state,
                notifications: notifications,
                unreadNotificationsCount: count
            }
        case DELETE_ALL_NOTIFICATIONS:
            sessionStorageUtils.set("unreadNotificationsCount", 0);
            return {
                ...state,
                notifications: [],
                unreadNotificationsCount: 0
            }
        default:
            return state;
    }
}
