import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import PasswordRules from "components/common/passwordRules";
import Input from 'components/common/input';

import { getCompanyPasswordSettings } from "store/actions/dashboard/companies/passwordSettings.action";

import { setBetshopManagerPassword } from "store/actions/dashboard/retail/betshopManagers/betshopManagers.action";

import { validatePassword } from "utils/password";

import companyPasswordSettingsType from "types/company/passwordSettings.type";

/** Betshop Manager Set Password Popup Component */
const BetshopManagerSetPasswordComponent = ({
    isSaving,
    getCompanyPasswordSettings,
    passwordSettings,
    setBetshopManagerPassword,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    const searchParams = useParams();

    useEffect(() => {
        getCompanyPasswordSettings();
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf BetshopManagerSetPasswordComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                setBetshopManagerPassword({
                    newPassword: data.newPassword,
                    confirmPassword: data.confirmPassword
                }, searchParams.id, onClose)

            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.common.setPassword')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    newPassword: "",
                    confirmPassword: ""
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.common.newPassword')} *`}
                            name="newPassword"
                            validateFirst
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        return validatePassword(value, passwordSettings);
                                    }
                                })
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.common.newPassword')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.common.newPassword')}`}
                                type='Password'
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.common.confirmPassword')} *`}
                            name="confirmPassword"
                            validateFirst
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value !== getFieldValue("newPassword")) {
                                            return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.confirmPassword')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.confirmPassword')}`}
                                type='Password'
                                onPaste={e => e.preventDefault()}
                                onContextMenu={e => e.preventDefault()}
                                onCopy={e => e.preventDefault()}
                            />
                        </Form.Item>
                    </Col>

                    {
                        Object.keys(passwordSettings).length > 0 ?
                            (
                                <Col span={24}>
                                    <Form.Item className='rt--form-item-without-margin'>
                                        <PasswordRules passwordSettings={passwordSettings} />
                                    </Form.Item>
                                </Col>
                            ) : null
                    }
                </Row>
            </Form>
        </Modal>
    )
}

/** BetshopManagerSetPasswordComponent propTypes
    * PropTypes
*/
BetshopManagerSetPasswordComponent.propTypes = {
    /** Redux state property, is true when Betshop Manager set password request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to get password settings */
    getCompanyPasswordSettings: PropTypes.func,
    /** Redux state property, represents company agent system password settings */
    passwordSettings: companyPasswordSettingsType,
    /** Redux action to set Betshop Manager password */
    setBetshopManagerPassword: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getCompanyPasswordSettings: () => {
            dispatch(getCompanyPasswordSettings());
        },

        setBetshopManagerPassword: (data, id, onSuccess) => {
            dispatch(setBetshopManagerPassword(data, id, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.betshopManagers.isSaving,
        passwordSettings: state.companies.edit.passwordSettings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BetshopManagerSetPasswordComponent)