import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
    deactivateAgentBank,
    activateAgentBank,
    deleteAgentBank,
} from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action';

import EntityActions from "components/common/entityActions";
import Confirmation from "components/common/confirmation";

import { BANK_STATUS } from 'constants/project.constants';

const CONFIRMATION_POPUP_DEFAULT_DATA = {
    isVisible: false,
    title: "",
    message: "",
    onCancel: null,
    onOk: null,
}

/** Bank Edit Actions Button Component */
const BankEditActionsComponent = ({
    deactivateAgentBank,
    activateAgentBank,
    deleteAgentBank,
    info,
}) => {
    const { t } = useTranslation();
    const { search, pathname, hash } = useLocation();
    const searchParams = useParams();
    const navigate = useNavigate();

    const currentBankId = (new URLSearchParams(search)).get("bankId");
    const currentBankName = (new URLSearchParams(search)).get("bankName");

    /** Flag to show/hide bank action popup */
    const [confirmationPopupData, setConfirmationPopupData] = useState({
         ...CONFIRMATION_POPUP_DEFAULT_DATA
    });

    const hideConfirmationPopUp = () => {
        setConfirmationPopupData({ ...CONFIRMATION_POPUP_DEFAULT_DATA })
    }

    const showConfirmationPopup = (popupData) => {
        setConfirmationPopupData({
            isVisible: true,
            onCancel: hideConfirmationPopUp,
            ...popupData
        })
    }


    /** Actions list */
    return (
        <Fragment>
            <EntityActions
                actions={[
                    info.state === BANK_STATUS.ACTIVE
                    ? {
                        icon: "icon-reparent",
                        key: "deactivate",
                        buttonType: "secondary",
                        buttonClassName: "rt--button-secondary",
                        title: t('backoffice.common.deactivate'),
                        onClick: () => showConfirmationPopup({
                            title: t('backoffice.payments.deactivateBank'),
                            message: t('backoffice.payments.deactivateBankMessage'),
                            onOk: () => {
                                deactivateAgentBank(currentBankId, searchParams.id)
                            },
                        }),
                        disabled: false
                    } : {
                        icon: "icon-reparent",
                        key: "activate",
                        buttonType: "secondary",
                        buttonClassName: "rt--button-secondary",
                        title: t('backoffice.common.activate'),
                        onClick: () => showConfirmationPopup({
                            title: t('backoffice.payments.activateBank'),
                            message: t('backoffice.payments.activateBankMessage'),
                            onOk: () => {
                                activateAgentBank(currentBankId, searchParams.id)
                            },
                        }),
                        disabled: false
                    },
                    {
                        icon: "icon-trash",
                        key: "delete",
                        buttonType: "secondary",
                        buttonClassName: "rt--button-secondary",
                        title: t('backoffice.common.delete'),
                        onClick: () => showConfirmationPopup({
                            title: t('backoffice.payments.deleteBank'),
                            message: t('backoffice.payments.deleteBankMessage'),
                            onOk: () => {
                                deleteAgentBank(
                                    currentBankId,
                                    searchParams.id,
                                    info.currencies,
                                    () => {
                                        navigate(
                                            (pathname + search + hash)
                                                .replace("&bankId=" + encodeURIComponent(currentBankId), "")
                                                .replace("&bankName=" + encodeURIComponent(currentBankName), ""),
                                            { replace: true },
                                        )
                                    },
                                )
                            },

                        }),
                        disabled: false
                    },
                ]}
            />

            <Confirmation
                {...confirmationPopupData}
            />
        </Fragment>

    )
}

/** BankEditActionsComponent propTypes
    * PropTypes
*/
BankEditActionsComponent.propTypes = {
    /** Redux action to deactivate payment bank */
    deactivateAgentBank: PropTypes.func,
    /** Redux action to activate payment bank */
    activateAgentBank: PropTypes.func,
    /** Redux action to delete payment bank */
    deleteAgentBank: PropTypes.func,
    /** Redux state property, current bank general info */
    info: PropTypes.object,
}

const mapDispatchToProps = dispatch => (
    {
        deactivateAgentBank: (id, agentId) => {
            dispatch(deactivateAgentBank(id, agentId));
        },
        activateAgentBank: (id, agentId) => {
            dispatch(activateAgentBank(id, agentId));
        },
        deleteAgentBank: (id, agentId, currencies, onSuccess) => {
            dispatch(deleteAgentBank(id, agentId, currencies, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        info: state.agents.edit.payment.banks.edit.info
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BankEditActionsComponent)
