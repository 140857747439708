import React, {useMemo} from 'react';
import Select from "components/common/select";
import {FILTER_TYPE} from "constants/common.constants";
import {useTranslation} from "react-i18next";
import useProjectType from "hooks/useProjectType";
import {isFranchisingMode} from "utils/common";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";

const UsersFiltersDesktop = ({ filters, onChange }) => {
    const { t } = useTranslation();
    const { hasAgentSystem } = useProjectType();

    const mappedFilters = useMemo(() => ({ ...filters.global,...filters.leaderboard }), [filters]);

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;
    const hasSelectedBetShop = !!mappedFilters.betShopNameOrId;

    if (!hasAgentSystem || isBetShopOwner || hasSelectedBetShop) {
        return null;
    }

    return (
        <Select
            options={[
                {value: FILTER_TYPE.DIRECT, text: t("backoffice.dashboard.direct")},
                {value: FILTER_TYPE.NETWORK, text: t("backoffice.dashboard.network")},
            ]}
            value={mappedFilters.filterType}
            onChange={(value) => onChange({"filterType": value})}
            className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle"
            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
        />
    );
};

export default UsersFiltersDesktop;
