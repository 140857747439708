import { makeTransactionText } from "utils/wallet";

export const getTableColumns = ({hasLiteMode}) => {
    const columns = [
        {
            title: "backoffice.wallet.transactionId",
            dataIndex: "transactionId",
            copy: true,
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.wallet.amount",
            dataIndex: "transactionAmount",
            sorter: true,
            isNumeric: true,
            mobileLevel: 3,
            render: value => Math.abs(value),
        },
        {
            title: "backoffice.wallet.transactionTime",
            dataIndex: "transactionTime",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.wallet.username",
            dataIndex: "userName",
            alwaysVisible: true,
            mobileLevel: 5,
        },
        {
            title: "backoffice.wallet.transactionType",
            dataIndex: "transactionType",
            sorter: true,
            mobileLevel: 4,
            render: value => makeTransactionText(value, hasLiteMode),
        }
    ];

    if (!hasLiteMode) {
        columns.push(
            {
                title: "backoffice.wallet.currency",
                dataIndex: "currencyCode",
                sorter: true,
                mobileLevel: 6,
            },
            {
                title: 'backoffice.wallet.notes',
                dataIndex: 'notes',
                short: true,
            }
        )
    }

    return columns;
};
