import React, {useMemo} from 'react';

import {useTranslation} from "react-i18next";

import {Col, Form, Row} from "antd";

import Select from "components/common/select";
import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";
import AutoComplete from "components/common/autoComplete";

import useAutosuggestion from "hooks/useAutosuggestion";

import {FILTER_TYPE} from "constants/common.constants";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";

const Filters = ({
    globalProjectId,
    currencies,
    filters,
    onFiltersChange,
}) => {
    const { t } = useTranslation();

    const [ agents, getAgents ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        autoGet: false
    });

    const currencyOptions = useMemo(() => {
        let res =  [{ text: t('backoffice.common.all'), value: '' }];

        res = [...res, ...currencies.map(currency => ({ text: currency.currencyCode.toUpperCase(), value: currency.currencyCode.toUpperCase() }))]

        return res;
    }, [currencies, t])

    return (
        <FiltersWrapper
            updateProps={[globalProjectId]}
            setFiltersFn={onFiltersChange}
            filters={filters}
            searchFieldName="playerUsernameOrId"
            datePickerFields={[
                { name: "date", time: true },
                { name: "playerRegistrationDate", time: false },
            ]}
            loadFn={() => {}}
            onInit={() => {
                getAgents();
            }}
        >
            <Row gutter={[16, 0]}>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.reports.date")}
                        name="date"
                    >
                        <DateRangePicker showTime />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.registrationDate')}
                        name="playerRegistrationDate"
                    >
                        <DateRangePicker />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.currency')}
                        name="currency"
                    >
                        <Select
                            options={currencyOptions}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.agentUsernameId')}
                        name="agentUserNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.agentUsernameId')}`}
                            items={agents}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.type')}
                        name="type"
                    >
                        <Select
                            options={[
                                {value: FILTER_TYPE.NETWORK, text: t('backoffice.reports.network')},
                                {value: FILTER_TYPE.DIRECT, text: t('backoffice.reports.direct')},
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    );
};

export default Filters;
