import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';

import { Spin } from "antd";

import { getNetworkSubNodes, resetNetworkSubNodes } from "store/actions/dashboard/agentSystem/network/network.action";

import { TREE_NODE_TYPE } from "constants/common.constants";
import { USER_ROLE } from "constants/user.constants";

import { getUser } from "utils/auth";
import { isFranchisingMode, isMobile } from "utils/common";

import useFormat from "hooks/useFormat";

import treeType from "types/agent/tree.type";

/** Network Overview Page Component */
const NetworkGraph = ({
    searchValue,
    getNetworkSubNodes,
    resetNetworkSubNodes,
    tree,
    isLoading,
    globalProjectId
}) => {

    const chartContainerRef = useRef(null);

    const [chart, setChart] = useState(null);
    const [lastData, setLastData] = useState([]);

    const { formatNumber } = useFormat();

    /** Load agent Names, network tree */
    useEffect(() => {
        getNetworkSubNodes(searchValue);
        return () => {
            setChart(null);
            setLastData([]);
            resetNetworkSubNodes()
        }
    }, [globalProjectId, searchValue])

    const treeData = useMemo(() => {
        const result = [];
        const loadedNodes = Object.keys(tree);

        const rootKey = searchValue ?? "root";

        if (loadedNodes.length > 0 && tree[rootKey]) {
            /** Push Root Element */
            result.push(
                {
                    id: tree[rootKey].id,
                    parentId: null,
                    type: getUser()?.role === USER_ROLE.ADMIN ? TREE_NODE_TYPE.PROJECT : TREE_NODE_TYPE.AGENT,
                    name: tree[rootKey].userName,
                    subAgentsCount: tree[rootKey].subAgentsCount,
                    playerCount: tree[rootKey].playerCount,
                    betshopCount: tree[rootKey].betshopCount,
                    isRoot: true
                }
            )
        }

        loadedNodes.forEach(node => {
            const nodeData = tree[node];
            if (nodeData.nodes) {
                nodeData.nodes.forEach(n => {
                    result.push(
                        {
                            id: n.id,
                            parentId: nodeData.id,
                            type: TREE_NODE_TYPE.AGENT,
                            name: n.userName,
                            subAgentsCount: n.subAgentsCount,
                            playerCount: n.playerCount,
                            betshopCount: n.betshopCount
                        }
                    )
                })
            }
        })
        return result;
    }, [tree])

    const fetchChildren = node => {
        if (tree[node.id] || node.data?.isRoot) return;
        getNetworkSubNodes(node.id);
    }

    const createChart = () => {
        const newChart = new OrgChart()
            .initialExpandLevel(0)
            .defaultFont("SF-Pro-Display")
            .compact(false)
            .container(chartContainerRef.current)
            .rootMargin(0)
            .nodeHeight(_ => 70)
            .nodeWidth(_ => 275)
            .svgHeight(window.innerHeight - (isMobile() ? 140 : 228))
            .svgWidth(chartContainerRef.current.offsetWidth)
            .childrenMargin(_ => 58)
            .neighbourMargin(_ => 24)
            .linkYOffset(0)
            .nodeButtonWidth(_ => 16)
            .nodeButtonHeight(_ => 16)
            .nodeButtonX(_ => -8)
            .nodeButtonY(_ => -8)
            .nodeUpdate(function () {
                d3.select(this).select('.node-rect').attr('stroke', 'none');
                d3.select(this).select(".node-button-g")
                    .attr("display", ({ data }) => {
                        return data.subAgentsCount > 0 ? "static" : 'none'
                    })
                    .attr("opacity", ({ data }) => {
                        return data.subAgentsCount > 0 ? 1 : 0
                    })

            })
            .linkUpdate(function () {
                d3.select(this)
                    .attr("stroke", '#D9DBE9')
                    .attr("stroke-width", 1)
            })
            .nodeContent((d, i, arr, state) => {
                const data = d.data;

                const agentLabel = data.subAgentsCount > 0 ? `
                    <div class="rt--tree-item-label rt--mr-6 rt--flex rt--align-center rt--pt-4 rt--pb-4 rt--pl-6 rt--pr-6">
                        <i class="icon-agent rt--font-normal rt--mr-2" /></i>
                        <span class="rt--text-secondary rt--font-small rt--font-medium">${formatNumber(data.subAgentsCount)}</span>
                    </div>
                ` : "";
                const playerLabel = !isFranchisingMode() && data.playerCount > 0 ? `
                    <div class="rt--tree-item-label rt--mr-6 rt--flex rt--align-center rt--pt-4 rt--pb-4 rt--pl-6 rt--pr-6">
                        <i class="icon-player rt--font-normal rt--mr-2" /></i>
                        <span class="rt--text-secondary rt--font-small rt--font-medium">${formatNumber(data.playerCount)}</span>
                    </div>
                ` : "";
                const betshopLabel = data.betshopCount > 0 ? `
                    <div class="rt--tree-item-label rt--mr-6 rt--flex rt--align-center rt--pt-4 rt--pb-4 rt--pl-6 rt--pr-6">
                        <i class="icon-retail rt--font-normal rt--mr-2" /></i>
                        <span class="rt--text-secondary rt--font-small rt--font-medium">${formatNumber(data.betshopCount)}</span>
                    </div>
                ` : "";

                return `
                    <div class="rt--tree-item rt--flex rt--align-center rt--justify-between rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12 ${data._upToTheRootHighlighted ? 'rt--tree-item-highlighted' : ''}">
                        <div class="rt--tree-item-icon rt--flex rt--align-center rt--justify-center">
                            <i class="${data.type === TREE_NODE_TYPE.AGENT ? 'icon-agent' : 'icon-globe'} rt--font-bigest"></i>
                        </div>
                        <div class="rt--flex-equal rt--flex rt--flex-col rt--pl-8">
                            <div class="rt--mb-4 rt--tree-item-title" title="${data.name}">
                                <span class="rt--title rt--font-medium rt--font-big">${data.name}</span>
                            </div>
                            <div class="rt--flex rt--align-center">
                                ${agentLabel}
                                ${playerLabel}
                                ${betshopLabel}
                            </div>
                        </div>
                    </div>
                `
            })
            .buttonContent(({ node, state }) => {
                const icon = node.children ? `<span class="rt--title rt--font-small rt--font-bold">-</span>` : `<i class="icon-plus rt--font-small"></i>`
                return `
                    <div class="rt--tree-item-button rt--flex rt--align-center rt--justify-center ${node.children ? 'rt--tree-item-button-close' : ''}">${icon}</div>
                `
            })
            .onExpandOrCollapse(node => {
                if (node.children) {
                    newChart.setUpToTheRootHighlighted(node.id).render();
                    fetchChildren(node);
                } else {
                    newChart.clearHighlighting()
                }
                newChart.setActiveNodeCentered(true).render()
            })
            .data(treeData)
            .render();
        setChart(newChart);

        setLastData(treeData.map(d => d.id));
    }

    const updateChart = () => {
        chart
            .onExpandOrCollapse(node => {
                fetchChildren(node);

                if (node.children) {
                    chart.setUpToTheRootHighlighted(node.id).render();
                } else {
                    chart.setExpanded(node.id);
                    chart.clearHighlighting();
                    chart.setActiveNodeCentered(true).render();
                }
            })

        const addedNodes = treeData.filter(d => !lastData.includes(d.id));

        if (lastData.length === 0 && treeData.length > 0) {
            chart.data(treeData).render();
            const rootElement = treeData.find(t => t.isRoot);

            if (rootElement) {
                chart.setCentered(rootElement.id).render();
            }
        } else {
            if (addedNodes.length > 0) {
                addedNodes.forEach(n => {
                    chart.addNode(n)
                })
                chart.setExpanded(addedNodes[0].id).setUpToTheRootHighlighted(addedNodes[0].parentId).render();
            }
        }

        setLastData(treeData.map(d => d.id));

    }

    const createOrUpdateChart = () => {
        if (!chartContainerRef.current) return;

        if (!chart) {
            createChart();
        } else {
            updateChart();
        }
    }

    useEffect(() => {
        createOrUpdateChart()
    }, [treeData])

    return (
        <div className="rt--tree">
            <Spin spinning={isLoading}>
                <div className="rt--tree-container" ref={chartContainerRef}/>
            </Spin>
        </div>
    );
};

/** NetworkGraph propTypes
 * PropTypes
 */
NetworkGraph.propTypes = {
    /** Prop, represents search value */
    searchValue: PropTypes.string,
    /** Redux action to get network sub nodes */
    getNetworkSubNodes: PropTypes.func,
    /** Redux action to reset network sub nodes */
    resetNetworkSubNodes: PropTypes.func,
    /** Redux state property, represents the array of network sub nodes  */
    tree: PropTypes.objectOf(treeType),
    /** Redux state property, is true when loading network sub nodes */
    isLoading: PropTypes.bool,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getNetworkSubNodes: id => {
        dispatch(getNetworkSubNodes(id));
    },

    resetNetworkSubNodes: () => {
        dispatch(resetNetworkSubNodes());
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.network.isLoading,
        tree: state.network.tree,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NetworkGraph);
