import { PERMISSION_RESOURCE } from "NEW/src/core/data/constants/permissions";
import { ROUTES } from "NEW/src/core/data/constants/routes";

export const NOTIFICATION_STATE_ENUM = {
	UNREAD: 1,
	READ: 2,
};

export const NOTIFICATION_TYPES_ENUM = {
	COMPANY: 1,
	PROJECT: 2,
	LIMIT_EXCEEDED: 3,
	COMMISSION_PLAN: 4,
	PAYMENT_REQUEST: 5,
	REQUEST_PROCESSED: 6,
	ACCESS_MANAGER_REQUEST: 7,
	AGENT: 8,
	NON_DIRECT: 9,
	WALLET_TRANSACTION: 10,
	REPARENT: 11,
};

export const ERROR_NOTIFICATION_CONTENT = {
	title: "Unknown Notification",
	description: (
		<p className="rt--error-text">
			We apologize for the error, notification are temporarily unavailable, but we are working
			to fix it. Thank you for your understanding
		</p>
	),
};

const NOTIFICATION_ACTIONS_ENUM = {
	ADD: "Add",
	REMOVE: "Remove",
	CREATE: "Create",
	MODIFY: "Modify",
	ATTENTION: "Attention",
	TRANSACTION: "Transaction",
};

const TRANSLATION_SIGNS_ENUM = {
	RESOURCE: "%X%",
	USERNAME: "%Y%", // User performing the action
	OBJECT_NAME: "%Z%", // User/Object on which the action was performed
	AMOUNT: "%K%",
};

export const RESOURCES_ENUM = {
	GENERAL_INFO: "GeneralInfo",
	WALLETS: "Wallets",
	LIMITS: "Limits",
	CURRENCY: "Currency",
	ADDITIONAL_ACCESS: "AdditionalAccess",
	PERMISSIONS_GROUPS: "PermissionsGroups",
	COMMISSION_PLAN: "Commission_Plan",
	PLAYER: "Player",
	BETSHOP: "Betshop",
	AGENT: "Agent",
	PASSWORD_SETTINGS: "PasswordSettings",
	TOKEN_SETTINGS: "TokenSettings",
	AUTHENTICATION_SETTINGS: "AuthenticationSettings",
	NETWORK: "Network",
	PROJECT: "Project",
	REGISTRATION_FORM_AGENT: "RegistrationFormAgent",
	REGISTRATION_FORM_PLAYER: "RegistrationFormPlayer",
	COMPANY_ACCESS: "Company_Access",
	PROJECT_ACCESS: "Project_Access",
	PAYMENT_CONFIG: "PaymentConfig",
	BETSHOP_OWNER: "BetShopOwner",
};

const OBJECT_TYPES_ENUM = {
	// Reparent
	NEW_PARENT: "NewParent",
	OLD_PARENT: "OldParent",
	AGENT: "Agent",

	// Transaction
	DEPOSIT: "Deposit",
	WITHDRAWAL: "Withdrawal",
	COMMISSION_PLAN: "Commission_Plan",
	ROLLBACK: "Rollback",
};

export const NOTIFICATION_SOUND_TYPES = {
	DEFAULT: 1,
	SILENT: 2,
};

export const PREDEFINED_DATA_BY_TYPE = (t) => ({
	//#region TYPE - COMPANY
	[NOTIFICATION_TYPES_ENUM.COMPANY]: {
		// ACTION - MODIFY
		[NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
			titleTranslationSource: "backoffice.notifications.companyModifyTitle",
			descriptionTranslationSource: "backoffice.notifications.companyModifyDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},
	},
	//#endregion

	//#region TYPE - PROJECT
	[NOTIFICATION_TYPES_ENUM.PROJECT]: {
		// ACTION - MODIFY
		[NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
			titleTranslationSource: "backoffice.notifications.projectModifyTitle",
			descriptionTranslationSource: "backoffice.notifications.projectModifyDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},

		// ACTION - ADD
		[NOTIFICATION_ACTIONS_ENUM.ADD]: {
			titleTranslationSource: "backoffice.notifications.projectAddTitle",
			descriptionTranslationSource: "backoffice.notifications.projectAddDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},

		// ACTION - REMOVE
		[NOTIFICATION_ACTIONS_ENUM.REMOVE]: {
			titleTranslationSource: "backoffice.notifications.projectRemoveTitle",
			descriptionTranslationSource: "backoffice.notifications.projectRemoveDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},
	},
	//#endregion

	//#region TYPE - LIMIT_EXCEEDED
	[NOTIFICATION_TYPES_ENUM.LIMIT_EXCEEDED]: {
		// ACTION - DEPOSIT
		[NOTIFICATION_ACTIONS_ENUM.DEPOSIT]: {
			titleTranslationSource: "",
			descriptionTranslationSource: "backoffice.notifications.",
		},

		// ACTION - WITHDRAWAL
		[NOTIFICATION_ACTIONS_ENUM.WITHDRAWAL]: {
			titleTranslationSource: "",
			descriptionTranslationSource: "backoffice.notifications.",
		},

		// ACTION - ATTENTION
		[NOTIFICATION_ACTIONS_ENUM.ATTENTION]: {
			titleTranslationSource: "backoffice.notifications.limitExceededAttentionTitle",
			descriptionTranslationSource:
				"backoffice.notifications.limitExceededAttentionDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
					.replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.threshold);
			},
		},
	},
	//#endregion

	//#region TYPE - COMMISSION_PLAN
	[NOTIFICATION_TYPES_ENUM.COMMISSION_PLAN]: {
		// ACTION - MODIFY
		[NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
			titleTranslationSource: "backoffice.notifications.commissionPlanModifyTitle",
			descriptionTranslationSource:
				"backoffice.notifications.commissionPlanModifyDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.querydata?.commissionPlanName)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},

		// ACTION - ADD
		[NOTIFICATION_ACTIONS_ENUM.ADD]: {
			titleTranslationSource: "backoffice.notifications.commissionPlanAddTitle",
			descriptionTranslationSource: "backoffice.notifications.commissionPlanAddDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.querydata?.commissionPlanName)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},

		// ACTION - REMOVE
		[NOTIFICATION_ACTIONS_ENUM.REMOVE]: {
			titleTranslationSource: "backoffice.notifications.commissionPlanRemoveTitle",
			descriptionTranslationSource:
				"backoffice.notifications.commissionPlanRemoveDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.querydata?.commissionPlanName)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},

		// ACTION - CREATE
		[NOTIFICATION_ACTIONS_ENUM.CREATE]: {
			titleTranslationSource: "backoffice.notifications.commissionPlanCreateTitle",
			descriptionTranslationSource:
				"backoffice.notifications.commissionPlanCreateDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.querydata?.commissionPlanName)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},
	},
	//#endregion

	//#region TYPE - ACCESS_MANAGER_REQUEST
	[NOTIFICATION_TYPES_ENUM.ACCESS_MANAGER_REQUEST]: {
		//ACTION - ADD
		[NOTIFICATION_ACTIONS_ENUM.ADD]: {
			titleTranslationSource: "backoffice.notifications.accessManagerAddTitle",
			descriptionTranslationSource: "backoffice.notifications.accessManagerAddDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname);
			},
		},

		//ACTION - REMOVE
		[NOTIFICATION_ACTIONS_ENUM.REMOVE]: {
			titleTranslationSource: "backoffice.notifications.accessManagerDeleteTitle",
			descriptionTranslationSource: "backoffice.notifications.accessManagerDeleteDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectName);
			},
		},

		//ACTION - MODIFY
		[NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
			titleTranslationSource: "backoffice.notifications.accessManagerModifyTitle",
			descriptionTranslationSource: "backoffice.notifications.accessManagerModifyDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname);
			},
		},
	},
	//#endregion

	//#region TYPE - AGENT
	[NOTIFICATION_TYPES_ENUM.AGENT]: {
		// ACTION - MODIFY
		[NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
			titleTranslationSource: "backoffice.notifications.userModifyTitle",
			descriptionTranslationSource: "backoffice.notifications.userModifyDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},

		// ACTION - ADD
		[NOTIFICATION_ACTIONS_ENUM.ADD]: {
			titleTranslationSource: "backoffice.notifications.userAddTitle",
			descriptionTranslationSource: "backoffice.notifications.userAddDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},

		// ACTION - REMOVE
		[NOTIFICATION_ACTIONS_ENUM.REMOVE]: {
			titleTranslationSource: "backoffice.notifications.userRemoveTitle",
			descriptionTranslationSource: "backoffice.notifications.userRemoveDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username);
			},
		},
	},
	//#endregion

	//#region TYPE - NON_DIRECT
	[NOTIFICATION_TYPES_ENUM.NON_DIRECT]: {
		// ACTION - MODIFY
		[NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
			titleTranslationSource: "backoffice.notifications.nonDirectModifyTitle",
			descriptionTranslationSource: "backoffice.notifications.nonDirectModifyDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectName);
			},
		},

		// ACTION - CREATE
		[NOTIFICATION_ACTIONS_ENUM.CREATE]: {
			titleTranslationSource: "backoffice.notifications.nonDirectCreateTitle",
			descriptionTranslationSource: "backoffice.notifications.nonDirectCreateDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
					.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname);
			},
		},

		// ACTION - TRANSACTION
		[NOTIFICATION_ACTIONS_ENUM.TRANSACTION]: {
			// OBJECT_TYPE - DEPOSIT
			[OBJECT_TYPES_ENUM.DEPOSIT]: {
				titleTranslationSource: "backoffice.notifications.nonDirectTransactionDepositTitle",
				descriptionTranslationSource:
					"backoffice.notifications.nonDirectTransactionDepositDescription",
				titleMaker: (data, translationSource) => {
					return t(translationSource).replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					);
				},
				descriptionMaker: (data, translationSource) => {
					return t(translationSource)
						.replace(
							TRANSLATION_SIGNS_ENUM.RESOURCE,
							t(`backoffice.resources.${data.resource}`),
						)
						.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
						.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
						.replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.amount);
				},
			},

			// OBJECT_TYPE - WITHDRAWAL
			[OBJECT_TYPES_ENUM.WITHDRAWAL]: {
				titleTranslationSource:
					"backoffice.notifications.nonDirectTransactionWithdrawalTitle",
				descriptionTranslationSource:
					"backoffice.notifications.nonDirectTransactionWithdrawalDescription",
				titleMaker: (data, translationSource) => {
					return t(translationSource).replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					);
				},
				descriptionMaker: (data, translationSource) => {
					return t(translationSource)
						.replace(
							TRANSLATION_SIGNS_ENUM.RESOURCE,
							t(`backoffice.resources.${data.resource}`),
						)
						.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
						.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
						.replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.amount);
				},
			},

			// OBJECT_TYPE - ROLLBACK
			[OBJECT_TYPES_ENUM.ROLLBACK]: {
				titleTranslationSource:
					"backoffice.notifications.nonDirectTransactionRollbackTitle",
				descriptionTranslationSource:
					"backoffice.notifications.nonDirectTransactionRollbackDescription",
				titleMaker: (data, translationSource) => {
					return t(translationSource).replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					);
				},
				descriptionMaker: (data, translationSource) => {
					return t(translationSource)
						.replace(
							TRANSLATION_SIGNS_ENUM.RESOURCE,
							t(`backoffice.resources.${data.resource}`),
						)
						.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
						.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
						.replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.amount);
				},
			},
		},
	},
	//#endregion

	//#region TYPE - REPARENT
	[NOTIFICATION_TYPES_ENUM.REPARENT]: {
		// ACTION - MODIFY
		[NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
			// OBJECT_TYPE - NEW_PARENT
			[OBJECT_TYPES_ENUM.NEW_PARENT]: {
				titleTranslationSource: "backoffice.notifications.reparentTitle",
				descriptionTranslationSource:
					"backoffice.notifications.reparentNewParentDescription",
				titleMaker: (data, translationSource) => {
					return t(translationSource).replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					);
				},
				descriptionMaker: (data, translationSource) => {
					const transaction =
						data.resource === RESOURCES_ENUM.AGENT
							? translationSource
							: "backoffice.notifications.reparentNewParentDescriptionBetshopPlayer";

					return t(transaction)
						.replace(
							TRANSLATION_SIGNS_ENUM.RESOURCE,
							t(`backoffice.resources.${data.resource}`),
						)
						.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
						.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname);
				},
			},

			// OBJECT_TYPE - OLD_PARENT
			[OBJECT_TYPES_ENUM.OLD_PARENT]: {
				titleTranslationSource: "backoffice.notifications.reparentTitle",
				descriptionTranslationSource:
					"backoffice.notifications.reparentOldParentDescription",
				titleMaker: (data, translationSource) => {
					return t(translationSource).replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					);
				},
				descriptionMaker: (data, translationSource) => {
					const transaction =
						data.resource === RESOURCES_ENUM.AGENT
							? translationSource
							: "backoffice.notifications.reparentOldParentDescriptionBetshopPlayer";

					return t(transaction)
						.replace(
							TRANSLATION_SIGNS_ENUM.RESOURCE,
							t(`backoffice.resources.${data.resource}`),
						)
						.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
						.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname);
				},
			},

			// OBJECT_TYPE - AGENT
			[OBJECT_TYPES_ENUM.AGENT]: {
				titleTranslationSource: "backoffice.notifications.reparentTitle",
				descriptionTranslationSource: "backoffice.notifications.reparentAgentDescription",
				titleMaker: (data, translationSource) => {
					return t(translationSource).replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					);
				},
				descriptionMaker: (data, translationSource) => {
					return t(translationSource)
						.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
						.replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname);
				},
			},
		},
	},
	//#endregion

	//#region TYPE - WALLET_TRANSACTION
	[NOTIFICATION_TYPES_ENUM.WALLET_TRANSACTION]: {
		[NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
			titleTranslationSource: "backoffice.notifications.walletTransactionModifyTitle",
			descriptionTranslationSource:
				"backoffice.notifications.walletTransactionModifyDescription",
			titleMaker: (data, translationSource) => {
				return t(translationSource).replace(
					TRANSLATION_SIGNS_ENUM.RESOURCE,
					t(`backoffice.resources.${data.resource}`),
				);
			},
			descriptionMaker: (data, translationSource) => {
				return t(translationSource)
					.replace(
						TRANSLATION_SIGNS_ENUM.RESOURCE,
						t(`backoffice.resources.${data.resource}`),
					)
					.replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
					.replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.amount);
			},
		},
	},
	//#endregion
});

export const getPathsForEachTypeOfResource = (isFranchisingMode) => ({
	//#region - AGENT
	[NOTIFICATION_TYPES_ENUM.AGENT]: {
		[RESOURCES_ENUM.GENERAL_INFO]: {
			permissionResource: PERMISSION_RESOURCE.AGENT_GENERALINFO,
			path: isFranchisingMode ? ROUTES.BETSHOP_OWNERS_EDIT : ROUTES.AGENTS_EDIT,
			hash: "#tab=0",
		},
		[RESOURCES_ENUM.CURRENCY]: {
			permissionResource: PERMISSION_RESOURCE.AGENT_CURRENCIES,
			path: isFranchisingMode ? ROUTES.BETSHOP_OWNERS_EDIT : ROUTES.AGENTS_EDIT,
			hash: "#tab=3",
		},
		[RESOURCES_ENUM.LIMITS]: {
			permissionResource: PERMISSION_RESOURCE.AGENT_LIMITS,
			path: isFranchisingMode ? ROUTES.BETSHOP_OWNERS_EDIT : ROUTES.AGENTS_EDIT,
			hash: "#tab=4",
		},
		[RESOURCES_ENUM.ADDITIONAL_ACCESS]: {
			permissionResource: PERMISSION_RESOURCE.AGENT_ADDITIONAL_ACCESSES,
			path: isFranchisingMode ? ROUTES.BETSHOP_OWNERS_EDIT : ROUTES.AGENTS_EDIT,
			hash: "#tab=6",
		},
		[RESOURCES_ENUM.PERMISSIONS_GROUPS]: {
			permissionResource: PERMISSION_RESOURCE.AGENT_PERMISSIONS,
			path: isFranchisingMode ? ROUTES.BETSHOP_OWNERS_EDIT : ROUTES.AGENTS_EDIT,
			hash: "#tab=5&subtab=0",
		},
	},
	//#endregion

	//#region - COMPANY
	[NOTIFICATION_TYPES_ENUM.COMPANY]: {
		[RESOURCES_ENUM.GENERAL_INFO]: {
			permissionResource: PERMISSION_RESOURCE.COMPANY_GENERALINFO,
			path: ROUTES.COMPANIES_EDIT,
			hash: "#tab=0",
		},
		[RESOURCES_ENUM.PASSWORD_SETTINGS]: {
			permissionResource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS,
			path: ROUTES.COMPANIES_EDIT,
			hash: "#tab=1&subtab=0",
		},
		[RESOURCES_ENUM.TOKEN_SETTINGS]: {
			permissionResource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS,
			path: ROUTES.COMPANIES_EDIT,
			hash: "#tab=1&subtab=1",
		},
		[RESOURCES_ENUM.AUTHENTICATION_SETTINGS]: {
			permissionResource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS,
			path: ROUTES.COMPANIES_EDIT,
			hash: "#tab=1&subtab=2",
		},
	},
	//#endregion

	//#region - PROJECT
	[NOTIFICATION_TYPES_ENUM.PROJECT]: {
		[RESOURCES_ENUM.GENERAL_INFO]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_GENERALINFO,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=0",
		},
		[RESOURCES_ENUM.CURRENCY]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_CURRENCIES,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=1",
		},
		[RESOURCES_ENUM.WALLETS]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_WALLETS,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=3",
		},
		[RESOURCES_ENUM.LIMITS]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_LIMITS,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=4",
		},
		[RESOURCES_ENUM.AGENT]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=5&subtab=0",
		},
		[RESOURCES_ENUM.BETSHOP_OWNER]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=5&subtab=0",
		},
		[RESOURCES_ENUM.PLAYER]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=5&subtab=1",
		},
		[RESOURCES_ENUM.PAYMENT_CONFIG]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_PAYMENT,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=8",
		},
	},
	//#endregion

	//#region - NON_DIRECT
	[NOTIFICATION_TYPES_ENUM.NON_DIRECT]: {
		// [RESOURCES_ENUM.AGENT]: {
		//     permissionResource: PERMISSION_RESOURCE.AGENT_GENERALINFO,
		//     path: ROUTES.AGENTS_EDIT,
		//     hash: "#tab=0",
		// },
		[RESOURCES_ENUM.PLAYER]: {
			permissionResource: PERMISSION_RESOURCE.PLAYER_GENERALINFO,
			path: ROUTES.PLAYERS_EDIT,
			hash: "#tab=0",
		},
		[RESOURCES_ENUM.BETSHOP]: {
			permissionResource: PERMISSION_RESOURCE.BETSHOP_GENERALINFO,
			path: ROUTES.BETSHOPS_EDIT,
			hash: "#tab=0",
		},
	},
	//#endregion

	//#region - COMMISSION_PLAN
	[NOTIFICATION_TYPES_ENUM.COMMISSION_PLAN]: {
		[RESOURCES_ENUM.AGENT]: {
			permissionResource: PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN,
			path: ROUTES.AGENTS_EDIT,
			hash: "#tab=2",
		},
		[RESOURCES_ENUM.PROJECT]: {
			permissionResource: PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=2&subtab=1",
		},
	},
	//#endregion

	//#region - WALLET_TRANSACTION
	[NOTIFICATION_TYPES_ENUM.WALLET_TRANSACTION]: {
		[RESOURCES_ENUM.COMMISSION_PLAN]: {
			permissionResource: PERMISSION_RESOURCE.AGENT_WALLETS,
			path: ROUTES.AGENTS_EDIT,
			hash: "#tab=1",
		},
		[RESOURCES_ENUM.AGENT]: {
			permissionResource: PERMISSION_RESOURCE.AGENT,
			path: ROUTES.AGENTS_EDIT,
			hash: "#tab=1",
		},
		[RESOURCES_ENUM.PROJECT]: {
			permissionResource: PERMISSION_RESOURCE.PROJECT_WALLETS,
			path: ROUTES.PROJECTS_EDIT,
			hash: "#tab=3",
		},
	},
	//#endregion

	//#region - ACCESS_MANAGER_REQUEST
	[NOTIFICATION_TYPES_ENUM.ACCESS_MANAGER_REQUEST]: {
		[RESOURCES_ENUM.PERMISSIONS_GROUPS]: {
			permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
			path: ROUTES.PERMISSION_REQUESTS,
		},
		[RESOURCES_ENUM.COMMISSION_PLAN]: {
			permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
			path: ROUTES.PERMISSION_REQUESTS,
		},
		[RESOURCES_ENUM.PROJECT_ACCESS]: {
			permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
			path: ROUTES.PERMISSION_REQUESTS,
		},
		[RESOURCES_ENUM.COMPANY_ACCESS]: {
			permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
			path: ROUTES.PERMISSION_REQUESTS,
		},
		[RESOURCES_ENUM.GENERAL_INFO]: {
			permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
			path: ROUTES.PERMISSION_REQUESTS,
		},
	},
	//#endregion
});
