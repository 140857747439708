import React from "react";

import Status from "components/common/status";
import LockInfo from "components/common/lockInfo";

import { USER_STATE } from "constants/user.constants";
import {
    STATUS_TOOLTIP_PLACEMENT,
    STATUS_TYPES
} from "constants/status.constants";

export const tableColumns = [
    {
        title: "backoffice.users.firstName",
        dataIndex: "firstName",
        mobileLevel: 2,
        sorter: true,
    },
    {
        title: "backoffice.users.lastName",
        dataIndex: "lastName",
        sorter: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.users.username",
        dataIndex: "userName",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.users.status",
        dataIndex: "state",
        render: (value, record) => (
            <Status
                status={value}
                type={STATUS_TYPES.USER}
                showIcon={[USER_STATE.LOCKED2FA, USER_STATE.LOCKEDLOGIN].includes(value)}
                tooltipOptions={{
                    placement: STATUS_TOOLTIP_PLACEMENT.ICON,
                    title: (
                        <LockInfo
                            is2FALocked={record?.loginAttemptDetails?.is2FALocked}
                            lastLogin={record?.lastLogin}
                            lockTime={record?.loginAttemptDetails?.lockTime}
                        />
                    )
                }}
            />
        ),
        sorter: true,
        mobileLevel: 5
    },
    {
        title: "backoffice.users.email",
        dataIndex: "email",
        sorter: true,
    },
    {
        title: "backoffice.users.id",
        dataIndex: "longId",
        sorter: true,
        copy: true,
        alwaysVisible: true,
    },
    {
        title: "backoffice.users.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
    },
    {
        title: "backoffice.users.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 4,
    },
    {
        title: "backoffice.users.lastLoginAt",
        dataIndex: "lastLogin",
        sorter: true,
        isDateTime: true,
    },
    {
        title: "backoffice.users.lastPasswordChangeAt",
        dataIndex: "lastPasswordChange",
        sorter: true,
        isDateTime: true,
    },
    {
        title: "backoffice.users.lastBlockedAt",
        dataIndex: "lastBlocked",
        sorter: true,
        isDateTime: true,
    },
    {
        title: "backoffice.users.lastBlockedBy",
        dataIndex: "lastBlockedBy",
        render: (value) => value || "-",
    },
];
