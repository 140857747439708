import PATHS from "NEW/src/core/api/paths";
import { httpService } from "NEW/src/core/services/httpService";

export const projectService = {
	getProjectBalance(signal) {
		return httpService.get({
			path: PATHS.GET_PROJECT_BALANCE,
			signal,
		});
	},
};
