import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import Tooltip from "components/common/tooltip";
import TextAreaInput from 'components/common/textAreaInput';

import { addUserCompanyAccess } from "store/actions/dashboard/userManagement/users/companyAccess.action";

import { getUser } from 'utils/auth';

import { USER_ROLE } from 'constants/user.constants';

import accountCompanyAccessType from 'types/user/companyAccess.type';

/** Company Adding Popup Component */
const CompanyAddComponent = ({
    isSaving,
    addUserCompanyAccess,
    companyAccess,
    onClose
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const userRole = getUser()?.role;

    /** Fires when form submitted
       * @function
       * @memberOf CompanyAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(({ ids, note }) => {
                const companyIds = Array.isArray(ids) ? ids : [ids];

                addUserCompanyAccess(searchParams.id, companyIds, note, onClose);
            }).catch(() => { })
    }


    return (
        <Modal
            title={t('backoffice.users.addCompany')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={(
                                <div className='rt--flex rt--align-center'>
                                    <span className='rt--title rt--font-regular rt--font-normal rt--pr-4'>
                                        {
                                            `${t('backoffice.common.select')} ${t('backoffice.users.company')}`
                                        }
                                    </span>
                                    <Tooltip
                                        title={<span dangerouslySetInnerHTML={{ __html: t('backoffice.users.companiesInfo') }}></span>}
                                        trigger={["hover", "click"]}
                                        placement="bottomLeft"
                                        enableMobile={true}
                                    >
                                        <i className='icon-info rt--font-big' />
                                    </Tooltip>
                                </div>
                            )}
                            name="ids"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={
                                    companyAccess.availableCompanies
                                        .filter(item =>
                                            (!companyAccess.mainCompany || item.id !== companyAccess.mainCompany.id) &&
                                            !companyAccess.companies.some(p => p.id === item.id)
                                        ).map(item => (
                                            { value: item.id, text: item.id === "*" ? t('backoffice.common.all') : item.name }
                                        ))
                                }
                                isMultiple={userRole === USER_ROLE.ACCESS_MANAGER}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.company')}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    {
                        userRole === USER_ROLE.ACCESS_MANAGER && (
                            <Col span={24} >
                                <Form.Item
                                    label={`${t('backoffice.users.notes')} *`}
                                    name="note"
                                    rules={[
                                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                        { max: 1000, message: t('backoffice.validation.fieldInvalid') },
                                        { min: 6, message: t('backoffice.validation.fieldInvalid') }
                                    ]}
                                    className="rt--form-item-without-margin rt--general-form-item"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                >
                                    <TextAreaInput
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                        maxLength={1000}
                                        rows={6}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }

                </Row>
            </Form>
        </Modal>
    )
}

/** CompanyAddComponent propTypes
    * PropTypes
*/
CompanyAddComponent.propTypes = {
    /** Redux state property, is true when user company access is adding */
    isSaving: PropTypes.bool,
    /** Redux action to add users company access */
    addUserCompanyAccess: PropTypes.func,
    /** Redux state property, represents the company access info of user  */
    companyAccess: accountCompanyAccessType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addUserCompanyAccess: (ids, companyId, note, onSuccess) => {
            dispatch(addUserCompanyAccess(ids, companyId, note, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.account.isSaving,
        companyAccess: state.users.edit.companyAccess
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAddComponent)