import React from 'react';

import i18n from 'translations/config';

export const getTableColumns = (additionalProps) => {
    const {
        formatAmount,
        hasLiteMode
    } = additionalProps;

    return [
        {
            title: 'backoffice.performancereport.product',
            dataIndex: "product",
            alwaysVisible: true,
            mobileLevel: 1,
            render: value => i18n.t(`backoffice.dashboard.${value}`),
        },
        {
            title: "backoffice.performancereport.betAmount",
            dataIndex: "betAmount",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode),
            mobileLevel: 2
        },
        {
            title: "backoffice.performancereport.winAmount",
            dataIndex: "winAmount",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode),
            mobileLevel: 3
        },
        {
            title: "backoffice.performancereport.ggr",
            dataIndex: "ggr",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode),
            mobileLevel: 4
        },
        {
            title: "backoffice.performancereport.commissionPercent",
            dataIndex: "commissionPercent",
            render: value => value === null || value === undefined ? "-" : `${value} %`,
            mobileLevel: 5
        },
        {
            title: "backoffice.performancereport.earning",
            dataIndex: "earning",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode),
            mobileLevel: 6
        },
        {
            title: "backoffice.performancereport.toPay",
            dataIndex: "toPay",
            withCurrencySymbol: hasLiteMode,
            render: (value, record) => formatAmount(value, record.currencyCode),
            mobileLevel: 7
        },
    ]

}

export const getExpandColumns = (additionalProps) => {
    const {
        formatAmount
    } = additionalProps;

    return [
        {
            title: 'backoffice.bets.provider',
            dataIndex: "providerName",
            alwaysVisible: true,
        },
        {
            title: "backoffice.performancereport.betAmount",
            dataIndex: "betAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.winAmount",
            dataIndex: "winAmount",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.ggr",
            dataIndex: "ggr",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.commissionPercent",
            dataIndex: "commissionPercent",
            render: value => value === null || value === undefined ? "-" : `${value} %`
        },
        {
            title: "backoffice.performancereport.earning",
            dataIndex: "earning",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.toPay",
            dataIndex: "toPay",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
    ]
}
