import { Button as AntButton } from "antd";
import PropTypes from "prop-types";

const Button = ({ children, ...restProps }) => {
	/**
	 * The Ant Button API is available at https://ant.design/components/button#api
	 */
	return <AntButton {...restProps}>{children}</AntButton>;
};

Button.propTypes = {
	children: PropTypes.node,
};

export default Button;
