import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Button, Switch } from 'antd';

import Input from 'components/common/input';

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { isMobile } from 'utils/common';

/** Project Edit Page Provider Settings Tab Golden Race form Component */
const GoldenRaceSettingsComponent = ({
    onSave,
    isSaving,
    settings,
    onTabChange
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [isFormSaving, setIsFormSaving] = useState(false);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...settings
        });
    }, [settings])


    /** Fires when form submitted
       * @function
       * @memberOf GoldenRaceSettingsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                setIsFormSaving(true)
                onSave(data);
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (!isSaving) {
            setIsFormSaving(isSaving)
        }
    }, [isSaving])

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <Form
            colon={false}
            form={formInstance}
            requiredMark={false}
            layout="vertical"
            initialValues={{}}
            onValuesChange={(changed, formValues) => setIsFormTouched(
                isFormChanged({ ...formValues }, { ...settings })
            )}
        >

            <div className='rt--widget'>
                <div className='rt--widget-header rt--pl-16 rt--pr-8 rt--flex rt--align-center rt--justify-between'>
                    <b className='rt--title rt--font-big rt--font-extra-bold'>{t("backoffice.common.GoldenRace")}</b>
                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                        <Form.Item
                            name="enabled"
                            valuePropName="checked"
                            className='rt--form-item-without-margin'
                        >
                            <Switch
                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className={'rt--widget-content' + (isMobile() ? " rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16" : " rt--pl-24 rt--pr-24 rt--pb-24 rt--pt-16")}>
                    <Row gutter={[12, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.projects.siteId')} *`}
                                name="siteId"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                    { min: 1, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.siteId')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.siteId')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                    maxLength={50}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.projects.privateKey')} *`}
                                name="privateKey"
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                    { min: 3, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.privateKey')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.privateKey')}`}
                                    maxLength={50}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.projects.publicKey')} *`}
                                name="publicKey"
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                    { min: 3, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.publicKey')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.publicKey')}`}
                                    maxLength={50}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.projects.accessKey')} *`}
                                name="accessKey"
                                className={'rt--form-item-without-margin rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 50, message: t('backoffice.validation.fieldInvalid') },
                                    { min: 3, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.accessKey')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.accessKey')}`}
                                    maxLength={50}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.MODIFY }) &&
                        <div className="rt--form-buttons rt--form-buttons-static">
                            <Button
                                loading={isFormSaving}
                                type="primary"
                                htmlType="submit"
                                className="rt--button"
                                onClick={handleForm}
                                disabled={!isFormTouched}
                            >
                                <span>{t('backoffice.common.save')}</span>
                            </Button>
                        </div>
                    }
                </div>
            </div>

        </Form>

    )
}

/** GoldenRaceSettingsComponent propTypes
    * PropTypes
*/
GoldenRaceSettingsComponent.propTypes = {
    /** Fubction to call on save */
    onSave: PropTypes.func,
    /** Is true when settings is saving */
    isSaving: PropTypes.bool,
    /** Current values of form  */
    settings: PropTypes.shape({
        siteId: PropTypes.string,
        secret: PropTypes.string,
        enabled: PropTypes.bool
    }),
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default GoldenRaceSettingsComponent;