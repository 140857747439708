import React from "react";

import { isFranchisingMode } from "utils/common";
import { getUser } from "utils/auth";

import { USER_ROLE } from "constants/user.constants";

export const getTableColumns = (additionalProps) => {

    const {
        isCashierReport,
        formatAmount
    } = additionalProps;

    return [
        {
            title: isCashierReport ? "backoffice.performancereport.cashierId" : "backoffice.performancereport.betShopId",
            dataIndex: isCashierReport ? "cashierId" : "id",
            copy: true,
            sorter: !isCashierReport,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: isCashierReport ? "backoffice.performancereport.cashierUsername" : "backoffice.performancereport.betShopName",
            dataIndex: isCashierReport ? "cashierUserName" : "betShopName",
            alwaysVisible: true,
            mobileLevel: 2,
            render: (userName) => {
                if (!Boolean(userName)) {
                    return "-"
                }

                return userName;
            }
        },
        ((
            !isFranchisingMode() || getUser()?.role === USER_ROLE.ADMIN
        ) && !isCashierReport) && {
            title: "backoffice.performancereport.belongsTo",
            dataIndex: "agentUserName",
            mobileLevel: 3
        },
        !isCashierReport && {
            title: "backoffice.performancereport.currency",
            dataIndex: "currencyCode",
            mobileLevel: 4
        },
        {
            title: "backoffice.performancereport.depositCount",
            dataIndex: "depositCount",
            sorter: !isCashierReport,
            isNumeric: true,
            mobileLevel: isCashierReport ? 3 : undefined
        },
        {
            title: "backoffice.performancereport.depositAmount",
            dataIndex: "depositAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
            mobileLevel: isCashierReport ? 4 : undefined
        },
        {
            title: "backoffice.performancereport.withdrawCount",
            dataIndex: "withdrawCount",
            sorter: !isCashierReport,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.withdrawalAmount",
            dataIndex: "withdrawAmount",
            sorter: !isCashierReport,
            render: (value, record) => <span className='rt--table-withdrawal'>{formatAmount(value, record.currencyCode)}</span>
        },
        {
            title: "backoffice.performancereport.netDepositAmount",
            dataIndex: "netDepositAmount",
            sorter: !isCashierReport,
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.performancereport.betCount",
            dataIndex: "betCount",
            sorter: !isCashierReport,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.betAmount",
            dataIndex: "betAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.winBetCount",
            dataIndex: "wonCount",
            sorter: !isCashierReport,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.winAmount",
            dataIndex: "wonAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.payoutCount",
            dataIndex: "paidoutCount",
            sorter: !isCashierReport,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.payoutAmount",
            dataIndex: "paidoutAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.bonusBetAmount",
            dataIndex: "bonusBetAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.bonusWin",
            dataIndex: "bonusWonAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.bonusRedeem",
            dataIndex: "bonusRedeemAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.wonNotClaimedBetCount",
            dataIndex: "wonNotClaimedBetCount",
            sorter: !isCashierReport,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.wonNotClaimedBetAmount",
            dataIndex: "wonNotClaimedBetAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.cancelledBetCount",
            dataIndex: "cancelledBetsCount",
            sorter: !isCashierReport,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.cancelledBetAmount",
            dataIndex: "cancelledBetsAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.registeredPlayers",
            dataIndex: "playerSignups",
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.taxAmount",
            dataIndex: "taxAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.totalIn",
            dataIndex: "totalInAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.totalOut",
            dataIndex: "totalOutAmount",
            sorter: !isCashierReport,
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.performancereport.ggr",
            dataIndex: "gGR",
            sorter: !isCashierReport,
            mobileLevel: 5,
            render: (_, record) => (
                <span className={record.ggr < 0 ? "rt--error-text" : ""}>
                    {formatAmount(record.ggr, record.currencyCode)}
                </span>
            )
        },
        {
            title: "backoffice.performancereport.ngr",
            dataIndex: "nGR",
            sorter: !isCashierReport,
            mobileLevel: 6,
            render: (_, record) => (
                <span className={record.ngr < 0 ? "rt--error-text" : ""}>
                    {formatAmount(record.ngr, record.currencyCode)}
                </span>
            )
        },
    ]
};
