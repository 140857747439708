import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Input from 'components/common/input';
import DateRangePicker from 'components/common/dateRangePicker';

import { getAgentPlayers, getAgentPlayersTotals, setAgentPlayersFilters } from "store/actions/dashboard/agentSystem/agents/agents.action";

/** Agent Page Players Filters Component */

const Filters = ({
    setAgentPlayersFilters,
    getAgentPlayers,
    filters,
    agentId,
    getAgentPlayersTotals,
}) => {
    const { t } = useTranslation();

    return (
        <div onClick={e => e.stopPropagation()}>
            <FiltersWrapper
                loadFn={() => {
                    getAgentPlayers(agentId);
                    getAgentPlayersTotals(agentId);
                }}
                setFiltersFn={setAgentPlayersFilters}
                filters={filters}
                updateProps={[agentId]}
                datePickerFields={[
                    { name: "date", time: false }
                ]}
            >
                <Row gutter={[16, 0]}>

                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.players.email')}
                            name="email"
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.email')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.players.email')}`}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.common.creationDate')}
                            name="date"
                        >
                            <DateRangePicker showTime={false} />
                        </Form.Item>
                    </Col>
                </Row>
            </FiltersWrapper>
        </div>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get agent player list */
    getAgentPlayers: PropTypes.func,
    /** Redux action to get agent player totals */
    getAgentPlayersTotals: PropTypes.func,
    /** Redux action to set agent player list filters */
    setAgentPlayersFilters: PropTypes.func,
    /** Redux state property, user list splayers filters */
    filters: PropTypes.object,
    /** Agent id */
    agentId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAgentPlayers: agentId => {
            dispatch(getAgentPlayers(agentId))
        },
        getAgentPlayersTotals: agentId => {
            dispatch(getAgentPlayersTotals(agentId))
        },
        setAgentPlayersFilters: filters => {
            dispatch(setAgentPlayersFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.agents.players.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);