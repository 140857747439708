import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import BetsComponent from '../edit/sections/bets';
import PlayerTransactionHistory from "../edit/sections/playerTransactionHistory";

import Paths from 'constants/path.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Version} from "utils/version";


/** Player Edit Page Component */
const PlayerEditComponent = ({platVersion}) => {
    const { t } = useTranslation();

    const currentVersion = new Version(platVersion);
    const hideVersion = new Version('v1.2');

    const hideCasinoBets = currentVersion.isEqual(hideVersion);

    const items = [
        {
            title: t("backoffice.players.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.PLAYER_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.players.bets"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETS_ONLINE, action: PERMISSION_ACTION.VIEW }],
            component: <BetsComponent />,
            disabled: hideCasinoBets
        },
        {
            title: t("backoffice.players.playerTransactionsHistory"),
            permissions: [{ resource: PERMISSION_RESOURCE.PLAYER_TRANSACTIONS_HISTORY, action: PERMISSION_ACTION.VIEW }],
            component: <PlayerTransactionHistory />
        }
    ];

    return (
        <Tabs
            items={items}
            mainPage={{ title: t('backoffice.menu.players'), path: Paths.PLAYERS }}
        />
    )
}

PlayerEditComponent.propTypes = {
    platVersion: PropTypes.string
}

const mapStateToProps = (state) => ({
    platVersion: state.common.platVersion
})

export default connect(mapStateToProps)(PlayerEditComponent);
