import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { Form, Row, Col, Spin, Switch } from 'antd';

import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';

import AgentActionsComponent from '../../agent-actions.component';
import TabFormDashboardLayout from "components/layouts/tab/form";

import { EMAIL_REGEX, TEL_REGEX, ADDRESS_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX } from "constants/regex.constants";
import { COMPANY_CONTROL_RULE, COMPANY_CONTROL_TYPE } from 'constants/company.constants';
import { USER_GENDER, USER_STATE, USER_TYPE } from 'constants/user.constants';
import Paths from 'constants/path.constants';

import { getAgentGeneralInfo, saveAgentGeneralInfo } from "store/actions/dashboard/agentSystem/agents/general.action";
import { getAgentRegistrationForm } from "store/actions/dashboard/agentSystem/agents/agents.action";

import agentGeneralInfoType from "types/agent/generalInfo.type";
import registrationFormType from "types/project/registrationForm.type";
import userInfoType from 'types/profile/userInfo.type';

import { flagsToBinary, toLowerCaseFirstLetter } from 'utils/common';
import { isFormChanged } from "utils/form";

import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import countries from 'systemData/countries';
import useProjectType from "hooks/useProjectType";


/** Agent Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    getAgentGeneralInfo,
    saveAgentGeneralInfo,
    isSaving,
    isLoading,
    generalInfo,
    registrationForm,
    isRegistrationFormLoading,
    getAgentRegistrationForm,
    userInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();
    const { search } = useLocation();
    const navigate = useNavigate();
    const { hasLiteMode } = useProjectType();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const queryParams = new URLSearchParams(search);

    const openedFromPerformanceReport = queryParams.get("fromPerformanceReport");
    const globalCompanyId = queryParams.get("cid");
    const globalProjectId = queryParams.get("pid");

    /** Load partner general info */
    useEffect(() => {
        getAgentGeneralInfo(searchParams.id)
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...generalInfo
        })

        if (openedFromPerformanceReport) {
            /**
             * Here we don't chnage route
             * Only update url param (from userName to userId) and adding missing parts of query params
             * We need to do this if this page opened from a performance report where we don't have enught data to do so
             */
            navigate(
                `${Paths.REPORTS_AGENT_PERFORMANCE_REPORT_EDIT}/${generalInfo.id}` +
                `?cid=${globalCompanyId}` +
                `&pid=${globalProjectId}` +
                `&name=${generalInfo.userName}` +
                `&fromPerformanceReport=${true}` +
                `&role=${generalInfo.role}`,
                {replace: true}
            )
        }
    }, [generalInfo])

    useEffect(() => {
        getAgentRegistrationForm();
    }, [])

    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf GeneralInfoComponent
   */
    const formChanged = formValues => {
        if (formValues.type !== undefined) formValues.type = flagsToBinary(formValues.type);
        return isFormChanged({ ...formValues, id: searchParams.id }, { ...generalInfo })
    }

    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveAgentGeneralInfo({
                    ...data,
                    role: USER_TYPE.AGENT,
                    id: searchParams.id
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }


    /** Get field max length
       * @function
       * @param {object} - control
       * @returns {number}
       * @memberOf GeneralInfoComponent
   */
    const getFieldMaxLength = control => {
        switch (control.name) {
            case "UserName":
                return 30;
            case "FirstName":
            case "LastName":
            case "MiddleName":
                return 48;
            case "PhoneNumber":
                return 18;
            case "Address":
                return 102;
            default:
                return undefined;
        }
    }

    /** Get rules for control
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf GeneralInfoComponent
   */
    const getRulesForField = control => {
        const rules = [];

        if (control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED) {
            if (control.controlType === COMPANY_CONTROL_TYPE.SELECT) {
                rules.push({ required: true, message: t('backoffice.validation.fieldRequired') })
            } else {
                rules.push({ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') })
            }
        }

        switch (control.name) {
            case "Email":
                rules.push({ pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') });
                break;
            case "FirstName":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case "LastName":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case "MiddleName":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            case "PhoneNumber":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: TEL_REGEX, message: t('backoffice.validation.telFormat') });
                break;
            case "Address":
                rules.push({ max: getFieldMaxLength(control), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: ADDRESS_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            default:
                break;
        }

        return rules;
    }

    /** Get data for select control
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf AgentCreateComponent
   */
    const getSelectData = control => {
        const data = {
            "Country": {
                items: countries.map(c => ({
                    value: c.iso2,
                    text: t(`backoffice.countries.${c.iso2}`)
                })),
                showSearch: true
            },
            "Gender": {
                items: [
                    { value: USER_GENDER.MALE, text: t("backoffice.common.male") },
                    { value: USER_GENDER.FEMALE, text: t("backoffice.common.female") },
                    { value: USER_GENDER.NONE, text: t("backoffice.common.other") }
                ],
                showSearch: false,
            }
        };
        return data[control.name] ?? { items: [] };
    }

    const validateMinMax = (value) => {
        if (value === '') {
            return Promise.resolve();
        }

        if (Number(value) >= 0 && Number(value) <= 100) {
            return Promise.resolve();
        }

        return Promise.reject(
            t("backoffice.validation.mustBeBetween")
                .replace("%X%", 0)
                .replace("%Y%", 100)
        );
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Can edit general info */
    const canEdit = hasPermission({ resource: PERMISSION_RESOURCE.AGENT_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) && searchParams.id !== userInfo.id

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            actions={<AgentActionsComponent />}
            id={generalInfo.id}
            longId={generalInfo.longId}
        >

            <Spin spinning={isLoading || isRegistrationFormLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{}}
                    onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.agents.userName')}
                                name="userName"
                                className='rt--form-item-disabled'
                            >
                                <Input
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>

                        {
                            registrationForm
                                .filter(
                                    control => control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN &&
                                        !["UserName", "Password", "ConfirmPassword", "ParentId", "CurrencyCodes"].includes(control.name)
                                ).map(control => (
                                    <Col xs={24} sm={12} xl={6} key={control.name} >
                                        {
                                            control.controlType === COMPANY_CONTROL_TYPE.INPUT ?
                                                control.name === "PhoneNumber" ? (
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        validateFirst
                                                        className={"rt--general-form-item" + (!canEdit ? " rt--form-item-disabled" : "")}
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                    >
                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            maxLength={getFieldMaxLength(control)}
                                                            disabled={!canEdit}
                                                            isMobileNumber={true}

                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        validateFirst
                                                        className={"rt--general-form-item" + (!canEdit ? " rt--form-item-disabled" : "")}
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                    >
                                                        <Input
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            maxLength={getFieldMaxLength(control)}
                                                            disabled={!canEdit}
                                                        />
                                                    </Form.Item>
                                                ) : control.controlType === COMPANY_CONTROL_TYPE.SELECT ?
                                                    <Form.Item
                                                        label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                        name={toLowerCaseFirstLetter(control.name)}
                                                        rules={getRulesForField(control)}
                                                        validateFirst
                                                        className={"rt--general-form-item" + (!canEdit ? " rt--form-item-disabled" : "")}
                                                    >
                                                        <Select
                                                            options={
                                                                getSelectData(control).items
                                                            }
                                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            disabled={!canEdit}
                                                            search={getSelectData(control).showSearch}
                                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                        />
                                                    </Form.Item>
                                                    : null
                                        }

                                    </Col>
                                ))
                        }
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.agents.comments')}
                                name="comments"
                                rules={[
                                    { max: 1000, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!canEdit ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.comments')}`}
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.comments')}`}
                                    maxLength={1000}
                                    rows={1}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        {hasLiteMode && (
                            <Col xs={24} sm={12} xl={6}>
                                <Form.Item
                                    label={t('backoffice.agents.commission')}
                                    name="commissionPercent"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('backoffice.validation.fieldRequired')
                                        },
                                        () => ({
                                            validator: (_, value) => validateMinMax(value)
                                        })
                                    ]}
                                    validateFirst={true}
                                    className={'rt--general-form-item' + (!canEdit ? " rt--form-item-disabled" : "")}
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.commission')}`}
                                >
                                    <NumericInput
                                        suffix="%"
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.commission')}`}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name="passwordExpirationEnabled"
                                    valuePropName="checked"
                                    className={'rt--form-item-without-margin' + (!canEdit ? " rt--form-item-disabled" : "")}
                                >
                                    <Switch
                                        disabled={
                                            !canEdit || generalInfo.state === USER_STATE.IN_PROGRESS
                                        }
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.agents.forcePasswordChange')}</label>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name="allowEdit"
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch
                                        disabled={!canEdit}
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.users.allowEdit')}</label>

                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout >
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux action to get agent General info */
    getAgentGeneralInfo: PropTypes.func,
    /** Redux action to save agent General info */
    saveAgentGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing agent  */
    generalInfo: agentGeneralInfoType,
    /** Redux state property, is true when registration form is loading */
    isRegistrationFormLoading: PropTypes.bool,
    /** Redux state property, represents the registration form  */
    registrationForm: PropTypes.arrayOf(registrationFormType),
    /** Redux action to get registration form */
    getAgentRegistrationForm: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAgentGeneralInfo: id => {
            dispatch(getAgentGeneralInfo(id));
        },

        saveAgentGeneralInfo: data => {
            dispatch(saveAgentGeneralInfo(data));
        },

        getAgentRegistrationForm: () => {
            dispatch(getAgentRegistrationForm());
        },
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.agents.edit.general,
        isSaving: state.agents.isSaving,
        isLoading: state.agents.isLoading,
        registrationForm: state.agents.registrationForm,
        isRegistrationFormLoading: state.agents.isRegistrationFormLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)
