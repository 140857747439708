//#region React
import { useState } from "react";
//#endregion

//#region Helpers
import { getActiveItem } from "../helpers";
//#endregion

const INDEX_NOT_FOUND = -1;

const useNavBar = ({
    defaultPage,
    replace = false,
    ignoreFirstItemAsActive = true,
    initialNavItems,
}) => {
    const [items, setItems] = useState(() => {
        if (Array.isArray(initialNavItems)) {
            return initialNavItems;
        }

        return [{ navBarTitle: defaultPage }];
    });

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const addToNavBar = (itemObj) => {
        setItems(prevState => {
            if (prevState.includes(itemObj.navBarTitle)) {
                return prevState;
            }

            /**
             * In the case of replace === true,
             * the first element must be a page and
             * the second must be replaced each time
             */
            if (replace && prevState.length === 2) {
                prevState[1] = itemObj;

                return [...prevState];
            }

            return [...prevState, itemObj];
        })
    }

    const resetNavBar = () => {
        setItems([{ navBarTitle: defaultPage }]);
    }

    const navigateToTarget = (navBarKey) => {
        if (!navBarKey) {
            return;
        }

        removeItemsAfterClickedItem(navBarKey);
    }

    //#endregion

    //#region --------------------------------- _INTERNAL_ HANDLERS -----------------------------------//

    const removeItemsAfterClickedItem = (navBarKey) => {
        const indexOfClickedItem = items.findIndex(item => item.navBarKey === navBarKey);

        if (indexOfClickedItem === INDEX_NOT_FOUND) {
            return;
        }

        setItems(prevState => {
            prevState.length = indexOfClickedItem + 1
            const newItems = prevState;

            return [...newItems];
        })
    }

    const removeLastItem = () => {
        setItems(prevState => {
            prevState.pop();

            return [...prevState];
        })
    }

    //#endregion

    const navBarInstance = {
        navBarActiveItem: getActiveItem({ items, ignoreFirstItemAsActive }),
        navBarItems: items,
        addToNavBar,
        resetNavBar,
        navigateToTarget,
        _INTERNAL_: {
            removeItemsAfterClickedItem,
            removeLastItem,
        }
    }

    return [navBarInstance];
}

export default useNavBar;