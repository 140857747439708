import React from 'react';
import i18n from "translations/config";

const isImgFile = (value) => {
    return value.startsWith("[") && value.endsWith("]")
}

export const getTableColumns = (additionalProps) => {
    const { havePermissionForEdit, onFieldsChange } = additionalProps;

    return [
        {
            title: "backoffice.payments.key",
            dataIndex: "key",
            editable: havePermissionForEdit,
            maxLength: 50,
            width: "50%",
            mobileLevel: 1,
            editableLabel: () => i18n.t("backoffice.payments.key"),
            editableTitle: () => i18n.t("backoffice.payments.key"),
            onEdit: (value, record) => {
                onFieldsChange({
                    key: record.keyName,
                    value: record.valueName,
                    text: value,
                    translationKey: record.keyKey,
                });
            },
        },
        {
            title: "backoffice.payments.value",
            dataIndex: "value",
            editable: havePermissionForEdit,
            maxLength: 100,
            width: "50%",
            mobileLevel: 2,
            disableEdit: (value) => isImgFile(value),
            editableLabel: () => i18n.t("backoffice.payments.value"),
            editableTitle: () => i18n.t("backoffice.payments.value"),
            onEdit: (value, record) => {
                onFieldsChange({
                    key: record.keyName,
                    value: record.valueName,
                    text: value,
                    translationKey: record.valueKey,
                });
            },
            render: (value) => {
                if (!isImgFile(value)) {
                    return value; 
                }

                const imgPath = value.slice(1, value.length - 1);
                const imgURL = `${import.meta.env.SYSTEM_CDN_URL}/${imgPath}`;

                return (
                    <div className="rt--table-col-img rt--mt-6 rt--mb-6">
                        <a href={imgURL} target='_blank' rel="noreferrer">
                            <img src={imgURL} alt="attachment" />
                        </a>
                    </div>
                )
            }
        },
    ];
};
