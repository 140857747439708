import React from 'react';

import {useTranslation} from "react-i18next";

import Tabs from "components/common/tabs";

import AgentComponent from "pages/reports/newProductReport/sections/agent";
import PlayerComponent from "pages/reports/newProductReport/sections/player";

import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import Paths from "constants/path.constants";

const NewProductReportComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.reports.agent"),
            component: <AgentComponent />
        },
        {
            title: t("backoffice.reports.player"),
            component: <PlayerComponent />
        }
    ];

    return (
        <Tabs
            items={items}
            mainPage={{ title: t('backoffice.menu.newProductReport'), path: Paths.PLAYERS }}
        />
    );
};

export default NewProductReportComponent;
