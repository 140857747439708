import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setAccountsActionBefore, setAccountsActionFinished, setAccountsSaveActionBefore, setAccountsSaveActionFinished } from './accounts.action';

import { SET_ACCOUNT_PROJECT_ACCESS } from "../../../actionTypes";

const setAccountProjectAccess = projectAccess => ({
    type: SET_ACCOUNT_PROJECT_ACCESS,
    payload: { projectAccess }
})

export const getAccountProjectAccess = id => {
    return dispatch => {
        dispatch(setAccountsActionBefore());

        return axios({
            url: ApiUrls.GET_ACCOUNT_PROJECT_ACCESS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: projectAccess}}) => {
            dispatch(setAccountProjectAccess(projectAccess))
            dispatch(setAccountsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAccountsActionFinished());
        })
    }
}

export const addAccountProjectAccess = (id, companyId, projectIds, note) => {
    return dispatch => {
        dispatch(setAccountsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_ACCOUNT_PROJECT_ACCESS,
            method: Methods.POST,
            data: {
                id, 
                companyId, 
                projectIds,
                note
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAccountProjectAccess(data.value))
            } 
            dispatch(setAccountsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAccountsSaveActionFinished());
        })
    }
}

export const deleteAccountProjectAccess = (id, companyId, projectId, note) => {
    return dispatch => {
        dispatch(setAccountsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_ACCOUNT_PROJECT_ACCESS,
            method: Methods.DELETE,
            data: {
                id, 
                companyId, 
                projectIds: [projectId], 
                note
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAccountProjectAccess(data.value));
            }
            dispatch(setAccountsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAccountsSaveActionFinished());
        })
    }
}
