import { NOTIFICATION_SOUND_TYPES } from "NEW/src/core/data/constants/notifications";
import { BaseStore } from "NEW/src/core/lib/baseStore";
import { apiServices } from "NEW/src/core/services/apiServices";
import { browserSessionStorage } from "NEW/src/core/services/storageService/browserSessionStorage";
import { globalInitialState } from "NEW/src/core/stores/globalStore/initialState";

export class GlobalStore extends BaseStore {
	constructor() {
		super(globalInitialState);
	}

	reset() {
		this.state = {
			...globalInitialState,
			languages: this.state.languages,
		};
	}

	changeUserInfoCurrency(code) {
		const currency = {
			...this.state.userInfo.currencies.find(
				(currency) => currency.code === code.toUpperCase(),
			),
		};

		browserSessionStorage.set("currency", currency);

		this.state.userInfo.currency = currency;
	}

	fetchUserInfo() {
		return apiServices.accountService
			.getUserInfo()
			.then(({ data: { value } }) => {
				const userInfo = {
					...value,
					currency: this.state.userInfo.currency,
					projectBalance: this.state.userInfo.projectBalance,
					agentBalance: this.state.userInfo.agentBalance,
				};

				if (!userInfo.currency) {
					userInfo.currencyCode = userInfo.currencies[0]?.code;
					userInfo.currency = userInfo.currencies[0];
					browserSessionStorage.set("currency", userInfo.currency);
				}

				userInfo.permissions = userInfo.permissions ?? {};

				this.state.userInfo = userInfo;
			})
			.catch(() => {
				this.state.userInfo = null;
			});
	}

	fetchSystemLanguages() {
		return apiServices.systemLanguageService
			.getSystemLanguages()
			.then(({ data: { value } }) => {
				this.state.languages = value;
			})
			.catch(() => {
				this.state.languages = {};
			});
	}

	fetchProjectBalance() {
		return apiServices.projectService
			.getProjectBalance()
			.then(({ data: { value } }) => {
				this.state.userInfo.projectBalance = value;
			})
			.catch(() => {
				this.state.userInfo.projectBalance = null;
			});
	}

	fetchAgentBalance() {
		return apiServices.accountService
			.getAgentBalance()
			.then(({ data: { value } }) => {
				this.state.userInfo.agentBalance = value;
			})
			.catch(() => {
				this.state.userInfo.agentBalance = null;
			});
	}

	toggleNotificationSound() {
		const newValue =
			this.state.userInfo.notificationSetting.notificationSound ===
			NOTIFICATION_SOUND_TYPES.SILENT
				? NOTIFICATION_SOUND_TYPES.DEFAULT
				: NOTIFICATION_SOUND_TYPES.SILENT;
		const prevSettings = { ...this.state.userInfo.notificationSetting };

		this.state.userInfo.notificationSetting.notificationSound = newValue;

		apiServices.accountService
			.changeNotificationsSettings({
				id: this.state.userInfo.id,
				notificationSound: newValue,
			})
			.then(
				({
					data: {
						value: { notificationSettings },
					},
				}) => {
					this.state.userInfo.notificationSetting = notificationSettings;
				},
			)
			.catch(() => {
				this.state.userInfo.notificationSetting = prevSettings;
			});
	}
}
