import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import useTransfer from '../../../../hooks/useTransfer';

import { TRANSFER_OPERATION_GROUP } from '../../../../constants';

import playerType from 'types/player/player.type';
import currencyType from 'types/profile/currency.type';

const EposTransferPlayerInfo = ({
    player,
    currencies
}) => {

    const { t } = useTranslation();

    const { transferData } = useTransfer();

    const { group } = transferData ?? {};

    const hasCurrencySupport = currencies.some(c => c.currencyCode === (player?.currencyCode ?? ""))

    return (
        <Fragment>
            <div className='rt--transfer-player-info rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16 rt--mb-16'>
                <div className='rt--flex rt--align-center rt--mb-16'>
                    <div className='rt--transfer-player-info-avatar rt--flex rt--align-center rt--justify-center'>
                        <i className="icon-avatar rt--font-big" />
                    </div>
                    <span className='rt--title rt--pl-8 rt--pr-8 rt--font-big rt--font-bold'>
                        {
                            player?.firstName && player?.lastName ? `${player?.firstName ?? ""} ${player?.lastName ?? ""}` : player?.userName ?? ""
                        }
                    </span>
                </div>
                <div className='rt--flex rt--align-center rt--transfer-player-info-details'>
                    <div className='rt--flex rt--flex-col rt--flex-equal'>
                        <span className='rt--title rt--font-normal rt--font-regular rt--pb-4'>{t("backoffice.players.username")}</span>
                        <b className='rt--title rt--font-regular rt--font-big'>{player?.userName ?? ""}</b>
                    </div>
                    <div className='rt--flex rt--flex-col rt--flex-equal'>
                        <span className='rt--title rt--font-normal rt--font-regular rt--pb-4'>{t("backoffice.players.currency")}</span>
                        <b className='rt--title rt--font-regular rt--font-big'>{player?.currencyCode ?? ""}</b>
                    </div>
                </div>
            </div>
            {
                !hasCurrencySupport && (
                    <div className='rt--transfer-currency rt--mb-16 rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16 rt--flex rt--align-center '>
                        <i className='icon-info rt--font-bigest' />
                        <span className='rt--title rt--font-medium rt--font-normal rt--pl-4 rt--pr-4'>
                            { group === TRANSFER_OPERATION_GROUP.DEPOSIT ? t('backoffice.wallet.cantDepositInThisCurrency') : t('backoffice.wallet.cantWithdrawFromThisCurrency') }
                        </span>
                    </div>
                )
            }
        </Fragment>
    )
}   

EposTransferPlayerInfo.propTypes = {
    /** Player */
    player: playerType,
    /** Redux state property, Account currencies */
    currencies: PropTypes.arrayOf(currencyType),
}

const mapStateToProps = state => {
    return {
        currencies: state.profile.currencies
    }
}

export default connect(mapStateToProps, null)(EposTransferPlayerInfo);