import PropTypes from 'prop-types';

import { USER_TYPE } from "constants/user.constants";

const nodeType = {
    id: PropTypes.string,
    companyId: PropTypes.string,
    playerCount: PropTypes.number,
    betshopCount: PropTypes.number,
    userName: PropTypes.string,
    nodeType: PropTypes.oneOf(Object.values(USER_TYPE))
}

nodeType.nodes = PropTypes.arrayOf(PropTypes.shape(nodeType))

export default PropTypes.shape(nodeType)