import React from 'react';

import WalletHistory from "pages/wallet/history";

import { WALLET_OWNER_TYPE } from "constants/wallet.constants";

/** Project Edit Page Wallets Tab Component */
const WalletsComponent = () => {

    return (
        <WalletHistory 
            ownerType={WALLET_OWNER_TYPE.SUPER_AGENT}
        />
    )
}

/** WalletsComponent propTypes
    * PropTypes
*/
WalletsComponent.propTypes = {
}

export default WalletsComponent