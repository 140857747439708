import React from 'react';
import PropTypes from 'prop-types';

import MobileFilters from './mobile';
import DesktopFilters from './desktop';

import { isMobile } from 'utils/common';

const Filters = ({ updateFilters, filters, showTimePeriodEntityType, hasRetail }) => {
    return (
        isMobile()
            ? <MobileFilters filters={filters} setFinancialFilters={updateFilters} showTimePeriodEntityType={showTimePeriodEntityType} hasRetail={hasRetail} />
            : <DesktopFilters filters={filters} updateFilters={updateFilters} showTimePeriodEntityType={showTimePeriodEntityType} hasRetail={hasRetail} />
    )
}

Filters.propTypes = {
    filters: PropTypes.object.isRequired,
    updateFilters: PropTypes.func.isRequired,
    showTimePeriodEntityType: PropTypes.bool,
    hasRetail: PropTypes.bool,
}

export default Filters