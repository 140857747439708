import {
    SET_NETWORK_LAYERS,
    SET_NETWORK_OVERVIEW_ACTION_BEFORE,
    SET_NETWORK_OVERVIEW_ACTION_FINISH,
    SET_NETWORK_SUB_AGENTS,
    RESET_NETWORK_SUB_AGENTS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_NETWORK_OVERVIEW_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_NETWORK_OVERVIEW_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_NETWORK_SUB_AGENTS:
            return {
                ...state,
                tree: {
                    ...state.tree,
                    [payload.id ?? "root"]: payload.value
                }
            }
        case RESET_NETWORK_SUB_AGENTS:
            return {
                ...state,
                tree: {}
            }
        case SET_NETWORK_LAYERS:
            return {
                ...state,
                layers: payload
            }
        default:
            return state;
    }
};
