import {PERMISSION_ACTION} from "constants/permissions.constants";

export const isFieldReadonly = ({
    resource,
    parent,
    action,
    fieldName,
    getFieldValue,
    getFieldsValue,
    disabled
}) => {
    if (disabled) return true;

    const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };

    if ((!parent && !values[resource]) || (parent && (!values[parent] || !values[parent][resource]))) return false;

    let isReadonly = false;
    if (!parent) {
        if (action === PERMISSION_ACTION.VIEW) {
            Object.keys(values[resource]).forEach(res => {
                if (isReadonly) return false;
                Object.keys(values[resource][res]).forEach(a => {
                    if ((a !== PERMISSION_ACTION.VIEW || res !== "parent") && values[resource][res][a] === true) {
                        isReadonly = true;
                        return false;
                    }
                })
            })
        }
    } else {
        if (action === PERMISSION_ACTION.VIEW) {
            if (values[parent][resource][PERMISSION_ACTION.MODIFY] === true || values[parent][resource][PERMISSION_ACTION.DELETE] === true || values[parent][resource][PERMISSION_ACTION.CREATE] === true || values[parent][resource][PERMISSION_ACTION.EXPORT] === true) {
                isReadonly = true;
            }
            if (resource.includes("_GeneralInfo") && (values[parent]["parent"][PERMISSION_ACTION.MODIFY] === true || values[parent]["parent"][PERMISSION_ACTION.DELETE] === true)) {
                isReadonly = true;
            }
        }
    }
    return isReadonly;
}
