import {
    SET_PRODUCT_REPORT,
    SET_PRODUCT_REPORT_ACTION_BEFORE,
    SET_PRODUCT_REPORT_ACTION_FINISH,
    SET_PRODUCT_REPORT_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PRODUCT_REPORT_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_PRODUCT_REPORT_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_PRODUCT_REPORT:
            return {
                ...state,
                report: payload.report
            }
        case SET_PRODUCT_REPORT_FILTERS:
            return {
                ...state,
                filters: payload.filters
            }
        default:
            return state;
    }
}