import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Checkbox, Spin } from 'antd';

import Tooltip from 'components/common/tooltip';
import TabFormDashboardLayout from "components/layouts/tab/form";

import { getAgentAdditionalAccesses, saveAgentAdditionalAccesses } from "store/actions/dashboard/agentSystem/agents/additionalAccesses.action";

import useProjectType from 'hooks/useProjectType';

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';
import { getUser } from 'utils/auth';
import { isRTL } from 'utils/common';

import { USER_ROLE } from "constants/user.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import agentAdditionalAccessesType from "types/agent/additionalAccesses.type";
import userInfoType from 'types/profile/userInfo.type';

import LanguageUtils from 'utils/languages';

const SECTION_FIELD_NAMES_OBJ = {
    agents: [
        "allowCreateNonDirectAgents",
        "allowBlockNonDirectAgents",
        "allowDeleteNonDirectAgents",
        "allowReparentNonDirectAgents",
        "allowSetPasswordNonDirectAgents",
        "allowNonDirectTransactions",
    ],
    players: [
        "allowCreateNonDirectPlayers",
        "allowBlockNonDirectPlayers",
        "allowReparentNonDirectPlayers",
        "allowSetPasswordNonDirectPlayers",
        "allowNonDirectPlayerTransactions",
    ],
    betshops: [
        "allowCreateNonDirectBetShops",
        "allowBlockNonDirectBetShops",
        "allowReparentNonDirectBetShops",
        "allowNonDirectBetShopTransactions",
    ]
}

// flat array which contains only field names
const ALL_FIELD_NAMES = Object.values(SECTION_FIELD_NAMES_OBJ).flat();

/** Agent Edit Page Additional Accesses Tab Component */
const AdditionalAccessesComponent = ({
    getAgentAdditionalAccesses,
    saveAgentAdditionalAccesses,
    isSaving,
    isLoading,
    additionalAccesses,
    userInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const { hasRetail } = useProjectType();

    const currentLn = LanguageUtils.getSelectedLanguage();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const currentUserAdditionalAccesses = userInfo?.additionalAccesses ?? {};

    const sectionFieldNamesObj = { ...SECTION_FIELD_NAMES_OBJ };
    if(!hasRetail){
        delete sectionFieldNamesObj["betshops"]
    }

    useEffect(() => {
        if (searchParams.id) {
            getAgentAdditionalAccesses(searchParams.id)
        }
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue(Object.fromEntries(new Map(
            ALL_FIELD_NAMES.map(field => ([field, additionalAccesses[field]]))
        )));
    }, [additionalAccesses])


    /** Fires when form submitted
       * @function
       * @memberOf AdditionalAccessesComponent
   */
    const handleForm = forceSave => {
        validateFields()
            .then(data => {
                const d = {};
                ALL_FIELD_NAMES.forEach(f => {
                    d[f] = data[f] ?? false
                })
                saveAgentAdditionalAccesses({
                    ...d,
                    id: searchParams.id
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Function, returns if fields are editable
       * @function
       * @returns {boolean}
       * @memberOf AdditionalAccessesComponent
   */
    const canEdit = () => hasPermission({ resource: PERMISSION_RESOURCE.AGENT_ADDITIONAL_ACCESSES, action: PERMISSION_ACTION.MODIFY }) && searchParams.id !== userInfo.id

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit(),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={
                        Object.fromEntries(new Map(
                            ALL_FIELD_NAMES.map(f => ([f, additionalAccesses[f]]))
                        ))
                    }
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues },
                        Object.fromEntries(new Map(
                            ALL_FIELD_NAMES.map(f => ([f, additionalAccesses[f]]))
                        ))
                    ))}
                >
                    <Row gutter={[16, 0]}>
                        {Object.entries(sectionFieldNamesObj).map(([sectionName, fields], index) => {
                            return (
                                <Col xs={24} md={12} lg={8} key={index}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {t(`backoffice.agents.${sectionName}`)}
                                    </h4>
                                    <div className='rt--checkbox-group-list rt--mb-16'>
                                        {fields.map((field, index) => (
                                            <div className={"rt--flex rt--align-center" + (index !== fields.length - 1 ? " rt--mb-16" : "")} key={field}>
                                                <Form.Item
                                                    name={field}
                                                    valuePropName="checked"
                                                    className='rt--form-item-without-margin rt--form-item-inline'
                                                >
                                                    <Checkbox
                                                        disabled={!canEdit() || (getUser()?.role === USER_ROLE.AGENT && !currentUserAdditionalAccesses[field])}
                                                    />
                                                </Form.Item>
                                                <div className='rt--flex rt--align-center'>
                                                    <span className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--pr-4'>{t(`backoffice.agents.${field}`)}</span>
                                                    <Tooltip
                                                        title={t(`backoffice.agents.${field}Tooltip`)}
                                                        trigger={["hover", "click"]}
                                                        placement={(isRTL(currentLn) ? 'left' : 'right')}
                                                        enableMobile={true}
                                                    >
                                                        <i className='icon-info rt--font-prebigest' />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** AdditionalAccessesComponent propTypes
    * PropTypes
*/
AdditionalAccessesComponent.propTypes = {
    /** Redux action to get agent Additional Accesses */
    getAgentAdditionalAccesses: PropTypes.func,
    /** Redux action to save agent Additional Accesses */
    saveAgentAdditionalAccesses: PropTypes.func,
    /** Redux state property, is true when additional accesses is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when additional accesses is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the additional accesses of current editing agent  */
    additionalAccesses: agentAdditionalAccessesType,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAgentAdditionalAccesses: id => {
            dispatch(getAgentAdditionalAccesses(id));
        },

        saveAgentAdditionalAccesses: data => {
            dispatch(saveAgentAdditionalAccesses(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        additionalAccesses: state.agents.edit.additionalAccesses,
        isSaving: state.agents.isSaving,
        isLoading: state.agents.isLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalAccessesComponent)