//#region react
import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useProjectType from "hooks/useProjectType";
//#endregion

//#region actions
import {
    getAgentPlayers,
    updateAgentPlayerData,
    setAgentPlayersSorting,
    getAgentPlayersTotals
} from "store/actions/dashboard/agentSystem/agents/agents.action";
//#endregion

//#region components
import Table from "components/common/table";
import TotalsComponent from "components/common/totals";
//#endregion

//#region utils
import { getUser } from "utils/auth";
import { hasPlayerNonDirectTransactionAccess } from "utils/access";
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region hooks
import { TRANSFER_ENTITY_TYPE, TRANSFER_SUCCESS_EVENT_NAME, useTransfer } from "components/common/transfer";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { PLAYER_STATE } from "constants/player.constants";
import { USER_STATE, USER_ROLE } from 'constants/user.constants';
import { TOTALS_VIEW_TYPE } from "components/common/totals/constants";
import Paths from 'constants/path.constants';
import { getTableColumns } from "./columns";
//#endregion

//#region types
import playerType from "types/player/player.type";
import sortingType from "types/common/sorting.type";
import userInfoType from 'types/profile/userInfo.type';
//#endregion


const canTransferToPlayer = ({ player, userInfo, hasStandartPayment }) => {

    if (player.state !== PLAYER_STATE.ACTIVE) {
        return false;
    }

    if (!hasStandartPayment) {
        return false
    }

    if (getUser()?.role !== USER_ROLE.ADMIN) {
        return (
            hasPlayerNonDirectTransactionAccess(userInfo) ||
            player.parentId === userInfo.longId
        )
    }

    return true;

}

/** Agent Details Page Players List Component */
const PlayersComponent = ({
    getAgentPlayers,
    players,
    isLoading,
    total,
    setAgentPlayersSorting,
    sorting,
    globalProjectId,
    globalCompanyId,
    userInfo,
    updateAgentPlayerData,
    agentId,
    getAgentPlayersTotals,
    totals,
    isTotalsLoading,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { show: showTransfer} = useTransfer()

    const { userState } = getUser();

    const { hasStandartPayment, hasLiteMode } = useProjectType();

    const { formatAmount } = useFormat();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasPlayerGeneralInfoViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER_GENERALINFO,
        action: PERMISSION_ACTION.VIEW
    })

    const hasTotalsPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER_TOTALS,
        action: PERMISSION_ACTION.VIEW
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.PLAYERS_EDIT}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.userName}`
        )
    }

    const showTransferPopup = (record, group) => {
        const { id, currencyCode, balance, withdrawableBalance, userName } = record;
        const transferData = {
            userId: id,
            entityType: TRANSFER_ENTITY_TYPE.PLAYER,
            currencyCode: currencyCode,
            additionalData: {
                balance,
                withdrawableBalance,
                userName
            },
        }

        if(group){
            transferData.group = group;
        }

        showTransfer(transferData)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                userInfo,
                hasStandartPayment,
                formatAmount,
                hasLiteMode,
                showTransferPopup,
                canTransferToPlayer,
                hasPlayerGeneralInfoViewPermission
            }
        })
    }, [formatAmount])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasPlayerGeneralInfoViewPermission) {
        tableRowActions.push({
            title: t('backoffice.common.refresh'),
            icon: "icon-reload",
            onClick: record => updateAgentPlayerData(record.id),
        });

        if (userState === USER_STATE.ACTIVE) {
            if (!hasLiteMode) {
                tableRowActions.push({
                    title: t("backoffice.agents.transfer"),
                    icon: "icon-transfer",
                    onClick: record => showTransferPopup(record),
                    disabled: record => !canTransferToPlayer({ player: record, userInfo, hasStandartPayment }),
                });
            }
        }
    }



    if (hasPlayerGeneralInfoViewPermission) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick,
        })
    }

    //#endregion


    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                if(
                    data.entityType === TRANSFER_ENTITY_TYPE.PLAYER
                ){
                    getAgentPlayers(agentId);
                }
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [])

    return (
        <>
            {hasTotalsPermission && (
                <TotalsComponent
                    isLoading={isTotalsLoading}
                    getTotals={() => getAgentPlayersTotals(agentId)}
                    dependencies={[globalProjectId]}
                    viewType={TOTALS_VIEW_TYPE.CARD}
                    totals={totals}
                    tooltipText={t("backoffice.agents.playersTotalTooltip")}
                />
            )}
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={players}
                loadFn={nextPage => getAgentPlayers(agentId, nextPage)}
                sorting={sorting}
                setSortingFn={setAgentPlayersSorting}
                total={total}
                updateProps={[agentId]}
                actions={tableRowActions}
                disableFullView={true}
            />
        </>
    );
};

/** PlayersComponent propTypes
 * PropTypes
*/
PlayersComponent.propTypes = {
    /** Redux action to get agent players List */
    getAgentPlayers: PropTypes.func,
    /** Redux action to get agent players totals */
    getAgentPlayersTotals: PropTypes.func,
    /** Redux state property, represents the array of players  */
    players: PropTypes.arrayOf(playerType),
    /** Redux state property, is true when loading players */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when loading players totals */
    isTotalsLoading: PropTypes.bool,
    /** Redux state property, players total count */
    total: PropTypes.number,
    /** Redux action to set agent players sorting details */
    setAgentPlayersSorting: PropTypes.func,
    /** Redux state property, players sorting details */
    sorting: sortingType,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Agent id */
    agentId: PropTypes.string,
    /** Redux action to update player data */
    updateAgentPlayerData: PropTypes.func,
    /** Redux state property, represents the object of player totals */
    totals: PropTypes.objectOf(PropTypes.shape({
        balance: PropTypes.number,
        withdrawableBalance: PropTypes.number
    })),
};

const mapDispatchToProps = dispatch => ({
    getAgentPlayers: (agentId, nextPage) => {
        dispatch(getAgentPlayers(agentId, nextPage));
    },
    setAgentPlayersSorting: sorting => {
        dispatch(setAgentPlayersSorting(sorting));
    },
    updateAgentPlayerData: id => {
        dispatch(updateAgentPlayerData(id));
    },
    getAgentPlayersTotals: agentId => {
        dispatch(getAgentPlayersTotals(agentId));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.agents.players.isLoading,
        isTotalsLoading: state.agents.players.isTotalsLoading,
        players: state.agents.players.players,
        totals: state.agents.players.totals,
        total: state.agents.players.total,
        sorting: state.agents.players.sorting,
        globalProjectId: state.common.globalProjectId,
        globalCompanyId: state.common.globalCompanyId,
        userInfo: state.profile.userInfo
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayersComponent);
