import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useDate from 'hooks/useDate';

let timer;

/** Sidebar component */

const Clock = ({
    collapsed
}) => {

    const { dateService } = useDate();

    const [date, setDate] = useState(new Date());

    /** Run the clock */
    useEffect(() => {
        if (collapsed) {
            clearTimeout(timer);
        } else {
            setDate(new Date());
            runClock();
        }

        return () => {
            clearTimeout(timer);
        }
    }, [collapsed]);

    const getUntilNext = () => {
        const currentTime = dateService.getNow();
        let nextTime = dateService.getNow();
        nextTime = dateService.setUnit(nextTime, "seconds", 0);
        nextTime = dateService.setUnit(nextTime, "milliseconds", 0);
        nextTime = dateService.addUnit(nextTime, 1, "minutes", 1);
        return dateService.getDifference(nextTime, currentTime, "milliseconds");
    }

    const runClock = () => {
        const untilNext = getUntilNext();
        timer = setTimeout(() => {
            setDate(new Date());

            runClock();
        }, untilNext);
    }

    return (
        <div className='rt--flex'>
            <span className='rt--sidebar-content-footer-date rt--font-small rt--font-bold'>{dateService.format(date)}</span>
        </div>
    )

}

Clock.propTypes = {
    /** Is Sidebar collapsed */
    collapsed: PropTypes.bool
}

export default Clock;
