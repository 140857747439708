export const getTableColumns = (additionalProps) => {
    const {
        franchisingMode,
    } = additionalProps;
    return [
        {
            title: "backoffice.commissionplans.userid",
            dataIndex: "longId",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1
        },
        {
            title: "backoffice.commissionplans.userName",
            dataIndex: "userName",
            alwaysVisible: true,
            mobileLevel: 2
        },
        !franchisingMode && {
            title: "backoffice.commissionplans.level",
            dataIndex: "level",
            mobileLevel: 3
        },
        {
            title: "backoffice.commissionplans.addedAt",
            dataIndex: "addedAt",
            mobileLevel: 4,
            isDateTime: true
        },
        {
            title: "backoffice.commissionplans.addedBy",
            dataIndex: "addedBy",
            mobileLevel: 5
        },
    ]
}