import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import Tabs from 'components/common/tabs';

import NetworkComponent from './network';
import CommissionPlansComponent from '../../../../agentSystem/commissionPlans';
import AdministrativeFee from "pages/projects/edit/sections/agentSystem/administrativeFee";

import {getHashValue, isFranchisingMode} from "utils/common";

import { AGENT_TYPE } from "constants/agent.constants"
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import useProjectType from "hooks/useProjectType";


/** Project Edit Page AgentSystem Tab Component */
const AgentSystemComponent = ({
    onTabChange,
    tabId
}) => {
    const { t } = useTranslation();

    const { search } = useLocation();
    const { hasLiteMode } = useProjectType();

    const isActive = tabId === getHashValue('tab');

    const commissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");

    const items = [
        {
            title: t("backoffice.projects.network"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_AGENT_SYSTEM, action: PERMISSION_ACTION.VIEW }],
            component: <NetworkComponent />,
            disabled: isFranchisingMode()
        },
        {
            title: t("backoffice.projects.commissionPlans"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW }],
            component: <CommissionPlansComponent agentType={AGENT_TYPE.SUPER_AGENT} />,
            disabled: hasLiteMode
        },
        {
            title: t("backoffice.projects.administrativeFee"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW }],
            component: <AdministrativeFee agentType={AGENT_TYPE.SUPER_AGENT} />,
            disabled: hasLiteMode
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
            hidden={Boolean(commissionPlanId)}
        />
    )

}

/** AgentSystemComponent propTypes
    * PropTypes
*/
AgentSystemComponent.propTypes = {
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Tab identifier*/
    tabId: PropTypes.string
}

export default AgentSystemComponent;
