import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Switch, Spin } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";

import Select from 'components/common/select';
import Input from 'components/common/input';

import BetshopManagerActionsComponent from '../../betshopManager-actions.component';

import { USER_STATE } from "constants/user.constants";
import { EMAIL_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX } from "constants/regex.constants";

import { getBetshopManagerGeneralInfo, saveBetshopManagerGeneralInfo } from "store/actions/dashboard/retail/betshopManagers/general.action";
import { getBetshopManagerAvailableBetshops } from 'store/actions/dashboard/retail/betshopManagers/betshopManagers.action';

import betshopManagerGeneralInfoType from "types/betshopManager/generalInfo.type";

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { BETSHOP_ACCESS_TYPE } from 'constants/betshop.constants';

/** Betshop Managers Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    getBetshopManagerGeneralInfo,
    saveBetshopManagerGeneralInfo,
    isSaving,
    isLoading,
    generalInfo,
    availableBetshops,
    isAvailableBetshopsLoading,
    getBetshopManagerAvailableBetshops,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Load betshop Manager general info */
    useEffect(() => {
        getBetshopManagerGeneralInfo(searchParams.id)
    }, [])

    /** Load betshop Manager available betshops */
    useEffect(() => {
        getBetshopManagerAvailableBetshops(searchParams.id)
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...generalInfo
        })
    }, [generalInfo])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            firstName: generalInfo.firstName,
            lastName: generalInfo.lastName,
            email: generalInfo.email,
            betShopIds: Object.keys(generalInfo.betShops || {}),
            passwordExpirationEnabled: generalInfo.passwordExpirationEnabled
        });
    }, [generalInfo])

    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveBetshopManagerGeneralInfo({
                    ...data,
                    id: searchParams.id,
                    additionalAccess: BETSHOP_ACCESS_TYPE.PLAYER_CREATE | BETSHOP_ACCESS_TYPE.PAYMENT | BETSHOP_ACCESS_TYPE.BETS
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            actions={<BetshopManagerActionsComponent />}
            id={generalInfo.id}
            longId={generalInfo.longId}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        firstName: generalInfo.firstName,
                        lastName: generalInfo.lastName,
                        email: generalInfo.email,
                        betShopIds: Object.keys(generalInfo.betShops || {}),
                        passwordExpirationEnabled: generalInfo.passwordExpirationEnabled
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, {
                        firstName: generalInfo.firstName,
                        lastName: generalInfo.lastName,
                        email: generalInfo.email,
                        betShopIds: Object.keys(generalInfo.betShops || {}),
                        passwordExpirationEnabled: generalInfo.passwordExpirationEnabled
                    }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.betshopmanagers.username')} *`}
                                className={'rt--general-form-item rt--form-item-disabled'}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshopmanagers.username')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshopmanagers.username')}`}
                                    value={generalInfo.userName}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.betshopmanagers.email')}
                                name="email"
                                rules={[
                                    { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                                    { max: 50, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshopmanagers.email')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshopmanagers.email')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.betshopmanagers.firstName')} *`}
                                name="firstName"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid')}
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshopmanagers.firstName')}`}
                            >
                                <Input
                                    maxLength={48}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshopmanagers.firstName')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.betshopmanagers.lastName')} *`}
                                name="lastName"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid')}
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshopmanagers.lastName')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshopmanagers.lastName')}`}
                                    maxLength={48}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.betshopmanagers.betshop')}
                                name="betShopIds"
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                className={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        Object.keys(availableBetshops).map(item => (
                                            { value: item, text: availableBetshops[item] }
                                        ))
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.betshopmanagers.betshop')}`}
                                    loading={isAvailableBetshopsLoading}
                                    isMultiple={true}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                                <Form.Item
                                    name="passwordExpirationEnabled"
                                    valuePropName="checked"
                                    className={'rt--form-item-without-margin' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                >
                                    <Switch
                                        disabled={
                                            !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_MANAGER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ||
                                            generalInfo.state === USER_STATE.IN_PROGRESS
                                        }
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.betshopmanagers.forcePasswordChange')}</label>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux action to get betshop Manager General info */
    getBetshopManagerGeneralInfo: PropTypes.func,
    /** Redux action to save betshop Manager General info */
    saveBetshopManagerGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing betshop Manager  */
    generalInfo: betshopManagerGeneralInfoType,
    /** Redux action to get available betshops of betshop Manager */
    getBetshopManagerAvailableBetshops: PropTypes.func,
    /** Redux state property, represents the object of available betshops of betshop Manager */
    availableBetshops: PropTypes.object,
    /** Redux state property, is true when loading available betshops of betshop Manager */
    isAvailableBetshopsLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getBetshopManagerGeneralInfo: id => {
            dispatch(getBetshopManagerGeneralInfo(id));
        },

        saveBetshopManagerGeneralInfo: data => {
            dispatch(saveBetshopManagerGeneralInfo(data));
        },

        getBetshopManagerAvailableBetshops: id => {
            dispatch(getBetshopManagerAvailableBetshops(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.betshopManagers.edit.general,
        isSaving: state.betshopManagers.isSaving,
        isLoading: state.betshopManagers.isLoading,
        availableBetshops: state.betshopManagers.availableBetshops.availableBetshops,
        isAvailableCurrenciesLoading: state.betshopManagers.availableBetshops.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)