//#region react
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
//#endregion

import { Collapse } from "antd";

//#region components
import SubAgents from "./subAgents";
import Betshops from "./betshops";
import Players from "./players";
import SubAgentsFilters from "./subAgents/filters.component";
import BetshopsFilters from "./betshops/filters.component";
import PlayersFilters from "./players/filters.component";
//#endregion

//#region utils
import { isFranchisingMode } from "utils/common";
import { hasPermission } from 'utils/permissions';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
//#endregion

const COLLAPSE_PANEL_KEYS = {
    SUB_AGENTS: "1",
    PLAYERS: "2",
    BETSHOPS: "3"
}

const renderCollapseHeader = ({ name, icon }) => {
    return (
        <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
            <div className="rt--flex rt--align-center rt--collapse-header">
                <i className="icon-down rt--font-bigest rt--mr-2" />
                <i className={`${icon} rt--font-bigest`} />
                <span className="rt--title rt--font-normal rt--font-bold rt--pl-4 rt--pr-4">
                    {name}
                </span>
            </div>
        </div>
    )
}

/** Agent Details Page Component */
const AgentDetailsComponent = ({
    currentAgent,
    addToNavBar,
}) => {
    const { t } = useTranslation();

    const { subAgentsCount, playersCount, betShopsCount } = currentAgent;

    const franchisingMode = isFranchisingMode()

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasPlayersViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER,
        action: PERMISSION_ACTION.VIEW
    })

    const hasBetshopsViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETSHOP,
        action: PERMISSION_ACTION.VIEW
    })

    //#endregion

    const panels = (() => {
        const res = [];

        if (subAgentsCount > 0 && !franchisingMode) {
            res.push({
                label: renderCollapseHeader({ name: t("backoffice.agents.subAgentList"), icon: "icon-agent" }),
                key: COLLAPSE_PANEL_KEYS.SUB_AGENTS,
                showArrow: false,
                extra: <SubAgentsFilters agentId={currentAgent.id} />,
                children: <SubAgents addToNavBar={addToNavBar} agentId={currentAgent.id} />
            });
        }

        if (playersCount > 0 &&!franchisingMode && hasPlayersViewPermission) {
            res.push({
                label: renderCollapseHeader({ name: t("backoffice.agents.playerList"), icon: "icon-player" }),
                key: COLLAPSE_PANEL_KEYS.PLAYERS,
                showArrow: false,
                extra: <PlayersFilters agentId={currentAgent.id} />,
                children: <Players agentId={currentAgent.id} />
            })
        }

        if (betShopsCount > 0 && hasBetshopsViewPermission) {
            res.push({
                label: renderCollapseHeader({ name: t("backoffice.agents.betshopList"), icon: "icon-retail" }),
                key: COLLAPSE_PANEL_KEYS.BETSHOPS,
                showArrow: false,
                extra: <BetshopsFilters agentId={currentAgent.id} />,
                children: <Betshops agentId={currentAgent.id} />
            })
        }

        return res;
    })();

    return (
        <Collapse
            className="rt--collapse"
            defaultActiveKey={[COLLAPSE_PANEL_KEYS.SUB_AGENTS]}
            items={panels}
        />
    );
};

/** AgentDetailsComponent propTypes
 * PropTypes
*/
AgentDetailsComponent.propTypes = {
    currentAgent: PropTypes.object,
    addToNavBar: PropTypes.func,
};

export default AgentDetailsComponent;
