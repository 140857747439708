import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { message, Popover, Button } from 'antd';

import Paths from 'constants/api.constants';

import Upload from 'components/common/upload';

import { getUser } from 'utils/auth';

import ChangePasswordComponent from 'pages/profile/changePassword.component';

import { deleteAccountAvatar } from 'store/actions/dashboard/account/avatar.action';

import { USER_ROLE } from "constants/user.constants";

import userInfoType from "types/profile/userInfo.type";

/** User Avatar upload Component */
const AvatarUpload = ({
    userId,
    userLongId,
    name,
    avatarId,
    deleteAccountAvatar,
    onSuccess,
    canEdit,
    isProfile,
    userInfo
}) => {

    const { t } = useTranslation();

    const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);

    const [isPopoverVisible, setIsPopoverVisible] = useState(false);

    const companyLongId = isProfile ? (userInfo?.mainCompanyLongId ?? null) : (getUser()?.companyLongId ?? null);
    const projectLongId = getUser()?.projectLongId ?? null;

    const buildAvatarPath = avatar => {
        if (!avatar) return null;
        if (getUser()?.role === USER_ROLE.ADMIN || getUser()?.role === USER_ROLE.ACCESS_MANAGER) {
            return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/users/${userLongId}/${avatar}_large.png`
        } else {
            return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/users/${userLongId}/${avatar}_large.png`
        }
    }

    const [avatar, setAvatar] = useState(avatarId ? buildAvatarPath(avatarId) : null);

    useEffect(() => {
        setAvatar(avatarId ? buildAvatarPath(avatarId) : null)
    }, [avatarId])

    /** Fires when logo uploaded
         * @function
         * @param {object} logo - the uploaded logo
         * @memberOf AvatarUpload
     */
    const onUpload = logo => {
        const msg = logo?.response?.message;
        msg && message.success(msg);
        setAvatar(buildAvatarPath(logo?.response?.value ?? null))
        onSuccess && onSuccess(logo?.response?.value ?? null);
    };

    /** Fires on remove button click
         * @function
         * @param {object} e - event object
         * @memberOf AvatarUpload
     */
    const handleAvatarDelete = e => {
        e.stopPropagation()
        deleteAccountAvatar(userId, () => {
            setAvatar(null)
            onSuccess && onSuccess();
        }, isProfile);
    }

    return (
        <div className="rt--avatar-upload-container rt--flex rt--align-center rt--flex-col">
            <div className="rt--avatar-upload rt--mb-16 rt--flex rt--align-center rt--justify-center rt--mt-30">

                {
                    canEdit &&
                    (
                        <Popover
                            content={(
                                <div className="rt--avatar-upload-popover-content rt--flex rt--flex-col">

                                    <span className='rt--title rt--font-regular rt--font-normal rt--avatar-upload-popover-content-title'>
                                        {t('backoffice.users.avatarUploadTooltip')}
                                    </span>
                                    <span className='rt--avatar-upload-popover-content-title rt--title rt--font-regular rt--font-normal rt--mt-4'>
                                        {t('backoffice.users.maxSize')}
                                    </span>
                                    <div className='rt--flex rt--align-center rt--mt-24 rt--avatar-upload-popover-content-buttons'>
                                        {
                                            avatar && (
                                                <Button
                                                    className="rt--button-link rt--avatar-upload-popover-delete rt--mr-16"
                                                    onClick={e => { setIsPopoverVisible(false); handleAvatarDelete(e) }}
                                                    type="link"
                                                >
                                                    <span>{t('backoffice.users.deletePhoto')}</span>
                                                </Button>
                                            )
                                        }
                                        <Upload
                                            action={`${import.meta.env.SYSTEM_API_URL}${isProfile ? Paths.UPLOAD_PROFILE_AVATAR : Paths.UPLOAD_AVATAR}`}
                                            onChange={onUpload}
                                            onBeforeUpload={() => {
                                                setIsPopoverVisible(false);
                                            }}
                                            data={{ id: userId }}
                                            extensions={["image/png", "image/jpeg", "image/jpg"]}
                                            //size={1024 * 1024}
                                            showUploadList={false}
                                            disabled={!canEdit}
                                        >
                                            <Button type="primary" className={"rt--button" + (avatar ? " rt--avatar-upload-popover-change" : " rt--avatar-upload-popover-upload")}>
                                                <span>{avatar ? t('backoffice.users.changePhoto') : t('backoffice.users.uploadPhoto')}</span>
                                            </Button>
                                        </Upload>
                                    </div>



                                </div>
                            )}
                            title="Title"
                            trigger="click"
                            placement="bottomLeft"
                            overlayClassName="rt--avatar-upload-popover"
                            open={isPopoverVisible}
                            onVisibleChange={visible => setIsPopoverVisible(visible)}
                        >
                            <div className="rt--avatar-upload-button rt--flex rt--align-center rt--justify-center" onClick={() => setIsPopoverVisible(true)}>
                                <i className="icon-edit rt--font-bigest" />
                            </div>
                        </Popover>
                    )
                }
                {
                    avatar ?
                        <img src={avatar} alt="avatar" /> :
                        <i className="icon-avatar rt--avatar-upload-icon" />
                }

            </div>
            {name && <h4 className="rt--title rt--font-big rt--font-bold">{name}</h4>}

            {
                isProfile && (
                    <Button
                        type="link"
                        className="rt--button-link"
                        onClick={() => setShowChangePasswordPopup(true)}
                    >
                        <span>{t("backoffice.profile.changePassword")}</span>
                    </Button>
                )
            }

            {
                showChangePasswordPopup ? <ChangePasswordComponent onClose={() => setShowChangePasswordPopup(false)} /> : null
            }
        </div>
    )
}

/** AvatarUpload propTypes
    * PropTypes
*/
AvatarUpload.propTypes = {
    /** User id */
    userId: PropTypes.string,
    /** User long id */
    userLongId: PropTypes.number,
    /** User name */
    name: PropTypes.string,
    /** User avatar id */
    avatarId: PropTypes.string,
    /** Redux action to delete user avatar */
    deleteAccountAvatar: PropTypes.func,
    /** Can user avatar */
    canEdit: PropTypes.bool,
    /** Function to fire on upload success */
    onSuccess: PropTypes.func,
    /** Is profile avatar */
    isProfile: PropTypes.bool,
    /** Redux state property, the user info */
    userInfo: userInfoType,
}

const mapDispatchToProps = dispatch => (
    {
        deleteAccountAvatar: (id, onSuccess, isProfile) => {
            dispatch(deleteAccountAvatar(id, onSuccess, isProfile));
        }
    }
)

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarUpload)
