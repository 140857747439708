import React, {useMemo} from 'react';

import {useTranslation} from "react-i18next";

import {Col, Form, Row} from "antd";

import Select from "components/common/select";
import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";

import {AGENT_REPORT_TYPES} from "constants/reports.constants";

const Filters = ({
    globalProjectId,
    currencies,
    filters,
    networkLayers,
    onFiltersChange,
}) => {
    const { t } = useTranslation();

    const currencyOptions = useMemo(() => {
        let res =  [{ text: t('backoffice.common.all'), value: '' }];

        res = [...res, ...currencies.map(currency => ({ text: currency.currencyCode.toUpperCase(), value: currency.currencyCode.toUpperCase() }))]

        return res;
    }, [currencies, t]);

    const levelOptions = useMemo(() => {
        return [{value: '', text: t('backoffice.common.all')}, ...networkLayers.map(layer => ({ text: layer, value: layer }))]
    }, [networkLayers]);

    return (
        <FiltersWrapper
            updateProps={[globalProjectId]}
            setFiltersFn={onFiltersChange}
            filters={filters}
            loadFn={() => {}}
            searchFieldName="agentUsernameOrId"
            datePickerFields={[
                { name: "date", time: true },
                { name: "agentRegistrationDate", time: false },
            ]}
        >
            <Row gutter={[16, 0]}>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.reports.date")}
                        name="date"
                    >
                        <DateRangePicker
                            showTime
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.registrationDate')}
                        name="agentRegistrationDate"
                    >
                        <DateRangePicker />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.agentLevel')}
                        name="agentLevel"
                    >
                        <Select
                            options={levelOptions}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.currency')}
                        name="currency"
                    >
                        <Select
                            options={currencyOptions}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.type')}
                        name="type"
                    >
                        <Select
                            options={[
                                {value: AGENT_REPORT_TYPES.NETWORK, text: t('backoffice.reports.network')},
                                {value: AGENT_REPORT_TYPES.DIRECT, text: t('backoffice.reports.direct')},
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    );
};

export default Filters;
