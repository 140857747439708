import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import useDate from 'hooks/useDate';

import { setAccountLocked } from "store/actions/auth/auth.action";

import { RESPONSE_CODES } from "constants/errorCode.constants";

let interval = null;

/** Login Page Locked message Component */

const LockedComponent = ({
    lockData,
    setAccountLocked
}) => {

    const { t } = useTranslation();

    const { dateService } = useDate();

    const [countDown, setCountDown] = useState(null)

    /** Start interval */
    useEffect(() => {
        clearInterval(interval);
        if (lockData.resultCode === RESPONSE_CODES.AccountLoginTemporaryLocked) {
            interval = setInterval(() => {
                if (lockData) {
                    const expireTime = lockData.expireTime;
                    const date = dateService.makeUtcDateToLocal(expireTime);
                    
                    const diff = dateService.getDifference(dateService.getNow(), date, "seconds")
                    
                    if (diff <= 0) {
                        setCountDown(
                            dateService.toString((0 - diff) * 1000, 'mm:ss')
                        );
                    } else {
                        setCountDown(null);
                        setAccountLocked(null);
                        clearInterval(interval);
                    }
                }
            }, 1000)
        }
    }, [lockData.expireTime])

    useEffect(() => {
        return () => clearInterval(interval);
    }, [])

    return (
        <div className='rt--auth-locked rt--mb-16 rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16'>
            <b className='rt--title rt--font-medium rt--font-normal'>
                {`${lockData.userName} `}
                {
                    lockData.resultCode === RESPONSE_CODES.AccountLoginTemporaryLocked
                        ? t("backoffice.authentication.accountTemporaryLockedText")
                        : t("backoffice.authentication.accountLockedText")
                }
            </b>
            {
                lockData.resultCode === RESPONSE_CODES.AccountLoginTemporaryLocked && (
                    <div className='rt--mt-8 rt--auth-locked-countdown'>
                        <span className='rt--title rt--font-medium rt--font-normal rt--pt-8'>{countDown || " "}</span>
                    </div>
                )
            }

        </div>
    )
}

LockedComponent.propTypes = {
    /** Redux state property the lock data */
    lockData: PropTypes.shape({
        resultCode: PropTypes.number,
        expireTime: PropTypes.string,
        userName: PropTypes.string,
    }),
    /** Redux action to set account locked data */
    setAccountLocked: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        setAccountLocked: lockedData => {
            dispatch(setAccountLocked(lockedData))
        }
    }
)

const mapStateToProps = state => {
    return {
        lockData: state.auth.lockData
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    LockedComponent
);