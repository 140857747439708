//#region React
import React from 'react';
import PropTypes from 'prop-types';
//#endregion

//#region Utils
import { isFunction } from "utils/common";
//#endregion

const MobileNavBar = ({
    navBarInstance,
    onItemClick,
}) => {
    const { navBarActiveItem, _INTERNAL_ } = navBarInstance;
    const { removeLastItem } = _INTERNAL_;

    const handleItemInternalClick = (e) => {
        removeLastItem();

        if (isFunction(onItemClick)) {
            onItemClick(e);
        }
    }

    return (
        <div className="rt--navigation">
            <i
                className="icon-left rt--font-bigest rt--navigation-previous"
                onClick={(e) => handleItemInternalClick(e)}
            />

            <span className="rt--navigation-item rt--navigation-item-active">
                {navBarActiveItem.navBarTitle}
            </span>
        </div>
    );
}

/** MobileNavBar propTypes
 * PropTypes
*/
MobileNavBar.propTypes = {
    navBarInstance: PropTypes.object.isRequired,
    onItemClick: PropTypes.func,
}

export default MobileNavBar;