import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import Tabs from "components/common/tabs";

import ProviderSettingsComponent from './providerSettings';
import PlatformIntegrationComponent from './platform';
import ChatIntegrationComponent from './chat';

import {binaryToFlags, getHashValue} from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { PROJECT_TYPE } from 'constants/project.constants';


/** Project Edit Page Integration Tab Component */
const IntegrationComponent = ({
    tabId,
    onTabChange
}) => {
    const { t } = useTranslation();

    const { search } = useLocation();

    const entityType = (new URLSearchParams(search)).get("type");

    const hasRetail = binaryToFlags(Object.values(PROJECT_TYPE), Number(entityType)).includes(PROJECT_TYPE.RETAIL_SYSTEM);
    const isActive = tabId === getHashValue('tab');

    const items = [
        {
            title: t("backoffice.projects.retailProviders"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.VIEW }],
            component: <ProviderSettingsComponent />,
            disabled: !hasRetail
        },
        {
            title: t("backoffice.projects.platform"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.VIEW }],
            component: <PlatformIntegrationComponent />
        },
        {
            title: t("backoffice.projects.chat"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.VIEW }],
            component: <ChatIntegrationComponent />
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
        />
    )
}

/** IntegrationComponent propTypes
    * PropTypes
*/
IntegrationComponent.propTypes = {
    /** Tab identifier*/
    tabId: PropTypes.string,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default IntegrationComponent;
