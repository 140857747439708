import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Checkbox, message, Switch, Spin } from 'antd';

import {
    getPayoutTicket,
    savePayoutTicket,
    setCurrentPayoutTicket,
    setPayoutTicketLogo,
    deletePayoutTicketLogo
} from "store/actions/dashboard/cms/payoutTicket/payoutTicket.action";

import TextAreaInput from 'components/common/textAreaInput';
import MainFormDashboardLayout from "components/layouts/main/form";
import Selecter from 'components/common/selecter';
import ImageUploader from "components/common/imageUploader";
import Modal from "components/common/modal";
import PreviewTicket from "./preview.component";

import useUnsavedForm from "hooks/useUnsavedForm";

import { flagsToBinary, binaryToFlags, printElement, isMobile } from "utils/common";
import { getUser } from 'utils/auth';
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { TICKET_GENERAL_DETAILS, TICKET_BET_DETAILS } from "constants/ticket.constants";
import ApiUrls from 'constants/api.constants';
import { IMAGE_TYPE, UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";

import payoutTicketType from "types/payoutTicket/payoutTicket.type";

/** Payout Ticket Page Component */
const PayoutTicketComponent = ({
    getPayoutTicket,
    savePayoutTicket,
    setCurrentPayoutTicket,
    setPayoutTicketLogo,
    deletePayoutTicketLogo,
    isLoading,
    isSaving,
    globalProjectId,
    payoutTicket,
    currentPayoutTicket
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [ticketPreview, setTicketPreview] = useState(false);

    const {
        setToUnsavedForms,
        removeFromUnsavedForms,
    } = useUnsavedForm(UNSAVED_FORM_PAGE_TYPE.TAB); // TODO: add one more unsaved form page type <page>

    /** Load company payout ticket */
    useEffect(() => {
        globalProjectId && getPayoutTicket();
    }, [globalProjectId]);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...payoutTicket,
            generalDetails: binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), payoutTicket.generalDetails),
            betDetails: binaryToFlags(Object.values(TICKET_BET_DETAILS), payoutTicket.betDetails)
        });
    }, [payoutTicket]);

    useEffect(() => {
        if (isFormTouched) {
            setToUnsavedForms({ key: "payoutTicket" });
        } else {
            removeFromUnsavedForms({ key: "payoutTicket" });
        }
    }, [isFormTouched]);

    /** Fires when form submitted
         * @function
         * @memberOf PayoutTicketComponent
     */
    const handleForm = () => {
        validateFields()
            .then(data => {
                savePayoutTicket({
                    ...data,
                    generalDetails: flagsToBinary(data.generalDetails),
                    betDetails: flagsToBinary(data.betDetails),
                });
                setIsFormTouched(false);
            }).catch(() => { })
    }


    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf PayoutTicketComponent
   */
    const formChanged = formValues => {
        if (formValues.generalDetails) formValues.generalDetails = flagsToBinary(formValues.generalDetails);
        if (formValues.betDetails) formValues.betDetails = flagsToBinary(formValues.betDetails);
        return isFormChanged(formValues, { ...payoutTicket })
    }


    /** Fires when any field value is changed in TicketConfigComponent component
         * @function
         * @param {string} field - field name
         * @param {string} value - field value
         * @memberOf PayoutTicketComponent
    */
    const onTicketChange = (field, val) => {
        setCurrentPayoutTicket({
            ...currentPayoutTicket,
            [field]: field === "generalDetails" || field === "betDetails" ? flagsToBinary(val) : val
        })
    }

    /** Fires when logo uploaded
         * @function
         * @param {object} logo - the uploaded logo
         * @memberOf PayoutTicketComponent
    */
    const onUpload = logo => {
        const msg = logo?.message ?? null;
        msg && message.success(msg);
        setPayoutTicketLogo(logo?.value)
    };

    /** Function to build logo path
         * @function
         * @param {object} logoId - the uploaded logo id
         * @memberOf PayoutTicketComponent
    */
    const buildLogoPath = logo => {
        if(!logo) return null;
        const companyLongId = getUser()?.companyLongId ?? null;
        const projectLongId = getUser()?.projectLongId ?? null;
        return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${logo}_ticket_logo.png`
    };

    return (
        <MainFormDashboardLayout
            breadcrumbs={
                {
                    items: [
                        { title: t('backoffice.menu.payoutTicket') }
                    ]
                }
            }
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }

            header={
                {
                    button: {
                        icon: 'icon-eye',
                        type: "primary",
                        onClick: () => setTicketPreview(true),
                        text: t("backoffice.payoutticket.preview"),
                        enabled: isMobile()
                    }
                }
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        enableBarcodePrinting: payoutTicket.enableBarcodePrinting,
                        printPayoutTickets: payoutTicket.printPayoutTickets,
                        generalDetails: binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), payoutTicket.generalDetails),
                        betDetails: binaryToFlags(Object.values(TICKET_BET_DETAILS), payoutTicket.betDetails),
                        customText: payoutTicket.customText
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
                >
                    <div className="rt--payout-ticket rt--flex">
                        <div className="rt--payout-ticket-form rt--flex-equal rt--mr-24">
                        <Row gutter={[16, 0]}>
                            <Col span={isMobile() ? 24 : 8}>
                                {
                                    !isLoading && (
                                        <div className="rt--mb-24">
                                            <ImageUploader
                                                uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.UPLOAD_PAYOUT_TICKET_LOGO}`}
                                                defaultFile={payoutTicket.ticketLogoId ? {
                                                    url: buildLogoPath(payoutTicket.ticketLogoId),
                                                    status: "done",
                                                    percent: 100
                                                } : null}
                                                remove={hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.DELETE }) ? {
                                                    handler: deletePayoutTicketLogo
                                                } : null}
                                                data={{
                                                    projectId: globalProjectId
                                                }}
                                                fileBuilder={value => ({
                                                    url: buildLogoPath(value)
                                                })}
                                                acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                                size={1024 * 1024}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.CREATE })}
                                                onSuccess={onUpload}
                                                updateProps={[payoutTicket.ticketLogoId]}
                                                title={t('backoffice.payoutticket.header')}
                                                titleTooltip={t('backoffice.payoutticket.headerInfo')}
                                            />
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                                {
                                    t('backoffice.payoutticket.generalDetails')
                                }
                            </h4>
                            <Row gutter={[16, 0]}>
                                <Col span={24} >
                                    <Form.Item
                                        name="generalDetails"
                                    >
                                        <Selecter
                                            items={[
                                                { title: t('backoffice.payoutticket.betshopName'), value: TICKET_GENERAL_DETAILS.BETSHOP_NAME },
                                                { title: t('backoffice.payoutticket.betshopAddress'), value: TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS },
                                                { title: t('backoffice.payoutticket.cashier'), value: TICKET_GENERAL_DETAILS.CASHIER_USERNAME },
                                                { title: t('backoffice.payoutticket.ticketId'), value: TICKET_GENERAL_DETAILS.TICKET_ID }
                                            ]}
                                            onChange={val => onTicketChange("generalDetails", val)}
                                            disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.MODIFY })}                                        
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                                {
                                    t('backoffice.payoutticket.betDetails')
                                }
                            </h4>
                            <Row gutter={[16, 0]}>
                                <Col span={24} >
                                    <Form.Item
                                        name="betDetails"
                                    >
                                        <Selecter
                                            items={[
                                                { title: t('backoffice.payoutticket.betAmount'), value: TICKET_BET_DETAILS.BET_AMOUNT },
                                                { title: t('backoffice.payoutticket.betType'), value: TICKET_BET_DETAILS.BET_TYPE },
                                                { title: t('backoffice.payoutticket.factor'), value: TICKET_BET_DETAILS.FACTOR },
                                                { title: t('backoffice.payoutticket.possibleWin'), value: TICKET_BET_DETAILS.POSSIBLE_WIN },
                                                { title: t('backoffice.payoutticket.winAmount'), value: TICKET_BET_DETAILS.WIN_AMOUNT },
                                                { title: t('backoffice.payoutticket.calculationDate'), value: TICKET_BET_DETAILS.CALCULATION_DATE },
                                                { title: t('backoffice.payoutticket.betStatus'), value: TICKET_BET_DETAILS.BET_STATUS },
                                                { title: t('backoffice.payoutticket.payoutTime'), value: TICKET_BET_DETAILS.PAYOUT_TIME },
                                                { title: t('backoffice.payoutticket.payoutBy'), value: TICKET_BET_DETAILS.PAYOUT_BY }
                                            ]}
                                            onChange={val => onTicketChange("betDetails", val)}
                                            disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.MODIFY })}
                                        />

                                    </Form.Item>
                                </Col>
                            </Row>
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                                {
                                    t('backoffice.payoutticket.barcode')
                                }
                            </h4>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-14">
                                        <Form.Item
                                            name="enableBarcodePrinting"
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch
                                                onChange={val => onTicketChange("enableBarcodePrinting", val)}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.payoutticket.enableBrcode')}</label>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 0]}>
                                <Col xs={24} sm={24} xl={12} xxl={8}>
                                    <Form.Item
                                        label={t('backoffice.payoutticket.customText')}
                                        name="customText"
                                        rules={[{ max: 300, message: t('backoffice.validation.fieldInvalid') }]}
                                        className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payoutticket.customText')}`}
                                    >
                                        <TextAreaInput
                                            rows={4}
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payoutticket.customText')}`}
                                            maxLength={300}
                                            onChange={e => { onTicketChange("customText", e.target.value) }}
                                            disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.MODIFY })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mt-8">
                                        <Form.Item
                                            className='rt--form-item-inline rt--form-item-without-margin'
                                            name="printPayoutTickets"
                                            valuePropName='checked'
                                        >
                                            <Checkbox
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                        <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.payoutticket.printPayoutTickets')}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {
                            !isMobile() && (
                                <div className="rt--payout-ticket-preview">
                                    <div className="rt--payout-ticket-preview-inner">
                                        <PreviewTicket />
                                    </div>
                                    {
                                        hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.MODIFY }) && (
                                            <Button
                                                type="primary"
                                                className="rt--button rt--button-main rt--mt-16"
                                                onClick={() => printElement("rt--payout-ticket-preview")}
                                            >
                                                <i className="icon-print rt--font-bigest"></i>
                                                {t("backoffice.common.print")}
                                            </Button>
                                        )
                                    }
                                </div>
                            )
                        }


                    </div>


                </Form>
            </Spin>
            {
                ticketPreview && (
                    <Modal
                        title={t("backoffice.payoutticket.preview")}
                        onCancel={() => setTicketPreview(false)}
                    >
                        <div className="rt--payout-ticket-preview-inner">
                            <PreviewTicket />
                        </div>
                    </Modal>
                )
            }
        </MainFormDashboardLayout>

    );
};

/** PayoutTicketComponent propTypes
 * PropTypes
*/
PayoutTicketComponent.propTypes = {
    /** Redux action to get payout ticket */
    getPayoutTicket: PropTypes.func,
    /** Redux action to save payout ticket */
    savePayoutTicket: PropTypes.func,
    /** Redux action to set current payout ticket */
    setCurrentPayoutTicket: PropTypes.func,
    /** Redux action to delete payout ticket logo */
    deletePayoutTicketLogo: PropTypes.func,
    /** Redux action to set payout ticket logo */
    setPayoutTicketLogo: PropTypes.func,
    /** Redux state, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, is true when loading payout ticket */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving payout ticket */
    isSaving: PropTypes.bool,
    /** Redux state, represents the current loaded payout ticket(ticket in server)  */
    payoutTicket: payoutTicketType,
    /** Redux state, represents the current editing payout ticket(ticket in preview)  */
    currentPayoutTicket: payoutTicketType
};

const mapDispatchToProps = dispatch => ({
    getPayoutTicket: () => {
        dispatch(getPayoutTicket());
    },
    savePayoutTicket: ticket => {
        dispatch(savePayoutTicket(ticket))
    },
    setCurrentPayoutTicket: ticket => {
        dispatch(setCurrentPayoutTicket(ticket))
    },
    deletePayoutTicketLogo: () => {
        dispatch(deletePayoutTicketLogo())
    },
    setPayoutTicketLogo: logo => {
        dispatch(setPayoutTicketLogo(logo))
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.payoutTicket.isLoading,
        isSaving: state.payoutTicket.isSaving,
        currentPayoutTicket: state.payoutTicket.currentPayoutTicket,
        payoutTicket: state.payoutTicket.payoutTicket,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutTicketComponent);
