import { message } from "antd";

import {
	SET_PROJECT_SMS_PROVIDER,
	SET_PROJECT_SMS_PROVIDERS,
} from "store/actionTypes";
import {
	setProjectsActionBefore,
	setProjectsActionFinished,
	setProjectsSaveActionBefore,
	setProjectsSaveActionFinished,
} from "./projects.action";

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isFunction } from "utils/common";

const setSMSProviders = (providers) => ({
	type: SET_PROJECT_SMS_PROVIDERS,
	payload: { providers },
});

export const setSMSProjectProvider = (projectProvider) => ({
	type: SET_PROJECT_SMS_PROVIDER,
	payload: { projectProvider },
});

export const getSMSProviders = () => {
	return async (dispatch) => {
		dispatch(setProjectsActionBefore());

		return axios({
			url: ApiUrls.GET_PROJECT_SMS_PROVIDERS,
			method: Methods.GET,
			params: {},
		})
			.then(({ data: { value } }) => {
				dispatch(setSMSProviders(value));
				dispatch(setProjectsActionFinished());
			})
			.catch((ex) => {
				dispatch(setProjectsActionFinished());
			});
	};
};

export const getSMSProjectProvider = () => {
	return (dispatch) => {
		dispatch(setProjectsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PROJECT_SMS_PROVIDER}`,
			method: Methods.GET,
			params: {},
		})
			.then(({ data: { value } }) => {
				dispatch(
					setSMSProjectProvider(value.provider === 0 ? null : value)
				);
				dispatch(setProjectsActionFinished());
			})
			.catch((ex) => {
				dispatch(setProjectsActionFinished());
			});
	};
};

export const saveSMSProjectProvider = (data, onSuccess) => {
	return (dispatch) => {
		dispatch(setProjectsSaveActionBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_PROJECT_SMS_PROVIDER}`,
			method: Methods.POST,
			data: {
				...data,
			},
		})
			.then(({ data }) => {
				if (isResponseSuccess(data)) {
					message.success(data.message);
					dispatch(setSMSProjectProvider(data.value));

					if (isFunction(onSuccess)) {
						onSuccess()
					}
				}
				dispatch(setProjectsSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setProjectsSaveActionFinished());
			});
	};
};

export const sendTestSMS = (recipient) => {
	return () => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SEND_TEST_SMS}`,
			method: Methods.POST,
			data: {
				recipient,
			},
		})
			.then(({ data }) => {
				if (isResponseSuccess(data)) {
					message.success(data.message);
				}
			})
			.catch((ex) => { });
	};
};
