import i18n from "translations/config";
import React from "react";

export const getTableColumns = (additionalProps) => {
    const { isAdmin } = additionalProps;

    return [
        {
            title: "backoffice.permissiongroups.name",
            dataIndex: "name",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
            render: (value, record) => (
                <div className="rt--flex rt--align-center">
                    <span className="rt--title rt--font-regular rt--font-normal">{value}</span>
                    {
                        record.isDefault && (
                            <div className='rt--tag rt--table-tag rt--flex rt--justify-between rt--align-center rt--pl-8 rt--pr-8 rt--ml-8 rt--mr-8'>
                                <span className='rt--title rt--font-normal rt--font-bold'>{i18n.t("backoffice.common.default")}</span>
                            </div>
                        )
                    }
                </div>
            )
        },
        {
            title: "backoffice.permissiongroups.description",
            dataIndex: "description",
            short: true,
            mobileLevel: 3,
        },
        isAdmin && {
            title: "backoffice.permissiongroups.securityLevel",
            dataIndex: "securityLevel",
            mobileLevel: 4,
        },
        {
            title: "backoffice.permissiongroups.userCount",
            dataIndex: "users",
            mobileLevel: 5,
            render: (value) => value.length,
        },

        {
            title: "backoffice.permissiongroups.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.permissiongroups.lastUpdatedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.permissiongroups.lastUpdatedBy",
            dataIndex: "modifiedBy",
            sorter: true,
        },
    ];
};
