import React from 'react';
import { Tree as AntTree } from 'antd';

const Tree = ({data, ...props}) => {
    return (
        <AntTree className="rt--expandable-tree" treeData={data} {...props} />
    );
};

export default Tree;
