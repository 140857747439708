import { useCallback, useEffect, useState } from "react";

import { translations } from "NEW/src/core/lib/translations";

export const useTranslation = () => {
	const [locale, setLocale] = useState(translations.locale);
	// TODO: REMOVEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE
	const [_, forceRender] = useState({});

	const handler = useCallback((locale) => {
		setLocale(locale);
		forceRender({});
	}, []);


	useEffect(() => {
		translations.subscribe(handler);

		return () => {
			translations.unsubscribe(handler);
		};
	}, [handler]);

	const t = (key) => translations.translate(key);

	return { locale, t };
};
