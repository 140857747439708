import {
    SET_BET_HISTORY_ACTION_BEFORE,
    SET_BET_HISTORY_ACTION_FINISH
} from "../../../actionTypes";

export const setBetHistoryActionBefore = () => ({
    type: SET_BET_HISTORY_ACTION_BEFORE,
});

export const setBetHistoryActionFinished = () => ({
    type: SET_BET_HISTORY_ACTION_FINISH
});
