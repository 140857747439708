import {
    SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_BETSHOP_PERFORMANCE_REPORT,
    SET_BETSHOP_PERFORMANCE_REPORT_FILTERS,
    SET_BETSHOP_PERFORMANCE_REPORT_SORTING,
    SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_BEFORE,
    SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_FINISH,
    SET_BETSHOP_PERFORMANCE_REPORT_TOTALS
} from "../../actionTypes";


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_BETSHOP_PERFORMANCE_REPORT:
            return {
                ...state,
                report: !payload.add ? payload.report : state.report.concat(payload.report),
                total: payload.total,
            }
        case SET_BETSHOP_PERFORMANCE_REPORT_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: payload.keepPage ? state.sorting.page : 1
                }
            }
        case SET_BETSHOP_PERFORMANCE_REPORT_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_BEFORE:
            return {
                ...state,
                isTotalsLoading: true,
            };
        case SET_BETSHOP_PERFORMANCE_REPORT_TOTALS_FINISH:
            return {
                ...state,
                isTotalsLoading: false,
            };
        case SET_BETSHOP_PERFORMANCE_REPORT_TOTALS:
            return {
                ...state,
                totals: payload.totals,
            };
        default:
            return state;
    }
}