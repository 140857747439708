import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Radio, Form, Row, Col, Divider, Spin } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Select from "components/common/select";
import Input from 'components/common/input';
import Tooltip from 'components/common/tooltip';

import { getCompanyPasswordSettings, saveCompanyPasswordSettings } from "store/actions/dashboard/companies/passwordSettings.action";

import companyPasswordSettingsType from "types/company/passwordSettings.type";

import { isFormChanged } from "utils/form";

import { hasPermission } from 'utils/permissions';

import { isMobile } from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { COMPANY_PASSWORD_PATTERN_RULE, COMPANY_PASSWORD_PATTERN, COMPANY_PASSWORD_FORCE_CHANGE_TYPE } from 'constants/company.constants';

const LIMIT_POSSIBLE_VALUES = Array.from(Array(17).keys()).map(n => n + 8);

/** Company Edit Page Password Settings SubTab Component */
const PasswordSettingsComponent = ({
    isLoading,
    isSaving,
    getCompanyPasswordSettings,
    saveCompanyPasswordSettings,
    passwordSettings,
    onTabChange
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Make form initial values
      * @function
      * @returns {objects} - initial values
      * @memberOf PasswordSettingsComponent
  */
    const initialValues = () => ({
        passwordMinLimit: passwordSettings.passwordMinLimit,
        passwordMaxLimit: passwordSettings.passwordMaxLimit,
        forceChange: passwordSettings.forceChange,
        pattern: {
            type: COMPANY_PASSWORD_PATTERN.CONTAINS,
            regularExpression: passwordSettings.pattern.regularExpression,
            contain: passwordSettings.pattern.contain ? passwordSettings.pattern.contain.map(el => el.type + "_" + el.value) : []
        }
    })

    const [formCurrentValues, setFormCurrentValues] = useState(initialValues());

    const renderInfoTooltipContentTitleMust = () => (
        <div className='rt--registration-form-controls-info rt--pt-8 rt--pb-8'>
            <b className='rt--title rt--font-bold rt--font-uppercase rt--pb-4'>{t("backoffice.registrationform.must")}</b>
        </div>
    )

    const renderInfoTooltipContentTitleMay = () => (
        <div className='rt--registration-form-controls-info rt--pt-8 rt--pb-8'>
            <b className='rt--title rt--font-bold rt--font-uppercase rt--pb-4'>{t("backoffice.registrationform.may")}</b>
        </div>
    )

    const renderInfoTooltipContentTitleNone = () => (
        <div className='rt--registration-form-controls-info rt--pt-8 rt--pb-8'>
            <b className='rt--title rt--font-bold rt--font-uppercase rt--pb-4'>{t("backoffice.registrationform.none")}</b>
        </div>
    )

    /** Load company password settings */
    useEffect(() => {
        getCompanyPasswordSettings()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        const values = {
            ...passwordSettings,
            pattern: {
                ...passwordSettings.pattern,
                contain: passwordSettings.pattern.contain.map(c => c.type + "_" + c.value)
            }
        };
        setFieldsValue(values);
        setFormCurrentValues(values);
    }, [passwordSettings])

    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf PasswordSettingsComponent
   */
    const formChanged = formValues => {
        if (formValues.pattern !== undefined && formValues.pattern.contain !== undefined) {
            formValues.pattern.contain = formValues.pattern.contain.map(c => ({ type: Number(c.split("_")[0]), value: Number(c.split("_")[1]) }));
            formValues.pattern.regularExpression = null;
            formValues.pattern.type = COMPANY_PASSWORD_PATTERN.CONTAINS;
        }

        return isFormChanged(formValues, { ...passwordSettings })
    }

    /** Is true when no "must value selected"
       * @function
       * @description Check password settings pattern section "contains" field has at least 1 "must" value selected
       * @returns {boolean}
       * @memberOf PasswordSettingsComponent
   */
    const dontHaveMustValue = () => {
        const contain = formCurrentValues.pattern.contain ? [...formCurrentValues.pattern.contain] : null;
        if (!contain) {
            return false;
        }
        const result = !contain.map(c => Number(c.split("_")[1])).includes(COMPANY_PASSWORD_PATTERN_RULE.MUST);

        return result;
    }

    /** Fires when form submitted
       * @function
       * @memberOf PasswordSettingsComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                if (!dontHaveMustValue()) {
                    saveCompanyPasswordSettings({
                        ...data,
                        pattern: {
                            ...data.pattern,
                            contain: data.pattern.contain.map(c => ({ type: Number(c.split("_")[0]), value: Number(c.split("_")[1]) })),
                            type: COMPANY_PASSWORD_PATTERN.CONTAINS
                        }
                    })
                }

                setIsFormTouched(false);
            }).catch(() => { })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched || dontHaveMustValue()
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={initialValues()}
                    onValuesChange={(changed, formValues) => {
                        const valuesCopy1 = { ... (JSON.parse(JSON.stringify(formValues))) };
                        const valuesCopy2 = { ... (JSON.parse(JSON.stringify(formValues))) }
                        setFormCurrentValues(valuesCopy1);
                        setIsFormTouched(formChanged(valuesCopy2));
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={12} sm={12} lg={6} xl={4}>
                            <Form.Item
                                label={t('backoffice.companies.passwordMinLimit')}
                                name="passwordMinLimit"
                                className={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        LIMIT_POSSIBLE_VALUES
                                            .filter(v => !formCurrentValues.passwordMaxLimit || v <= formCurrentValues.passwordMaxLimit)
                                            .map(el => ({
                                                value: el, text: el
                                            }))
                                    }
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.passwordMinLimit")}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} lg={6} xl={4}>
                            <Form.Item
                                label={t('backoffice.companies.passwordMaxLimit')}
                                name="passwordMaxLimit"
                                className={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        LIMIT_POSSIBLE_VALUES
                                            .filter(v => !formCurrentValues.passwordMinLimit || v >= formCurrentValues.passwordMinLimit)
                                            .map(el => ({
                                                value: el, text: el
                                            }))
                                    }
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.passwordMaxLimit")}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={6} xl={4}>
                            <Form.Item
                                label={t('backoffice.companies.forceChange')}
                                name="forceChange"
                                className={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        Object.values(COMPANY_PASSWORD_FORCE_CHANGE_TYPE)
                                            .map((el, index) => ({
                                                value: el, text: el === COMPANY_PASSWORD_FORCE_CHANGE_TYPE.NEVER ? t('backoffice.common.never') : t('backoffice.common.every_N_month').replace("%N%", index)
                                            }))
                                    }
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.forceChange")}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className='rt--form-section-divider' />
                    <div className='rt--mb-36'>
                        <div className='rt--flex rt--registration-form-controls-section-wrapper'>
                            {
                                [0, 1].map(i => (
                                    <div className='rt--registration-form-controls-section' key={i}>
                                        <div className='rt--flex rt--registration-form-controls'>
                                            <div className='rt--flex rt--registration-form-controls-row rt--registration-form-controls-head rt--align-center rt--mb-16'>
                                                <div className='rt--registration-form-controls-name'></div>

                                                {
                                                    (isMobile() ?
                                                        <Tooltip
                                                            title={renderInfoTooltipContentTitleMust()}
                                                            trigger={["hover", "click"]}
                                                            placement="bottomLeft"
                                                            enableMobile={true}
                                                        >
                                                            <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-32'>
                                                                <span className='rt--title rt--font-bold rt--font-normal rt--font-uppercase'>{t("backoffice.companies.must")}</span>
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-32'>
                                                            <span className='rt--title rt--font-bold rt--font-normal rt--font-uppercase'>{t("backoffice.companies.must")}</span>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    (isMobile() ?
                                                        <Tooltip
                                                            title={renderInfoTooltipContentTitleMay()}
                                                            trigger={["hover", "click"]}
                                                            placement="bottomLeft"
                                                            enableMobile={true}
                                                        >
                                                            <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-32'>
                                                                <span className='rt--title rt--font-bold rt--font-normal rt--font-uppercase'>{t("backoffice.companies.may")}</span>
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-32'>
                                                            <span className='rt--title rt--font-bold rt--font-normal rt--font-uppercase'>{t("backoffice.companies.may")}</span>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    (isMobile() ?
                                                        <Tooltip
                                                            title={renderInfoTooltipContentTitleNone()}
                                                            trigger={["hover", "click"]}
                                                            placement="bottomLeft"
                                                            enableMobile={true}
                                                        >
                                                            <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center'>
                                                                <span className='rt--title rt--font-bold rt--font-normal rt--font-uppercase'>{t("backoffice.companies.none")}</span>
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center'>
                                                            <span className='rt--title rt--font-bold rt--font-normal rt--font-uppercase'>{t("backoffice.companies.none")}</span>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                        {
                                            passwordSettings.pattern.contain.map((el, ind) => (ind < 4 && i === 0) || (ind > 3 && i === 1) ? (
                                                <Form.Item
                                                    label=""
                                                    name={["pattern", "contain", ind]}
                                                    key={ind}
                                                >
                                                    <Radio.Group
                                                        className='rt--flex rt--registration-form-controls-row rt--align-center'
                                                        disabled={!hasPermission({ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                                    >
                                                        <div className='rt--registration-form-controls-name'>
                                                            <span className='rt--title rt--font-bold rt--font-normal'>{t(`backoffice.companies.containType${el.type}`)}</span>
                                                        </div>
                                                        <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-32'>
                                                            <Radio value={el.type + "_" + COMPANY_PASSWORD_PATTERN_RULE.MUST}></Radio>
                                                        </div>
                                                        <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-32'>
                                                            <Radio value={el.type + "_" + COMPANY_PASSWORD_PATTERN_RULE.MAY}></Radio>
                                                        </div>
                                                        <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center'>
                                                            <Radio value={el.type + "_" + COMPANY_PASSWORD_PATTERN_RULE.NONE}></Radio>
                                                        </div>

                                                    </Radio.Group>
                                                </Form.Item>
                                            ) : null)
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        {
                            dontHaveMustValue() && <span className="rt--error-text rt--title rt--font-normal rt--font-regular rt--pt-8" >{t('backoffice.companies.mustRequired')}</span>
                        }
                    </div>

                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** PasswordSettingsComponent propTypes
 * PropTypes
*/
PasswordSettingsComponent.propTypes = {
    /** Redux state property, is true when loading company password settings data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving company password settings data */
    isSaving: PropTypes.bool,
    /** Redux action to get company password settings */
    getCompanyPasswordSettings: PropTypes.func,
    /** Redux action to save company password settings */
    saveCompanyPasswordSettings: PropTypes.func,
    /** Redux state property, represents company password settings  */
    passwordSettings: companyPasswordSettingsType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => (
    {
        getCompanyPasswordSettings: () => {
            dispatch(getCompanyPasswordSettings());
        },
        saveCompanyPasswordSettings: rules => {
            dispatch(saveCompanyPasswordSettings(rules));
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.companies.isLoading,
        isSaving: state.companies.isSaving,
        passwordSettings: state.companies.edit.passwordSettings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordSettingsComponent)