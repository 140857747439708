import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setCashiersActionBefore, setCashiersActionFinished, setCashiersSaveActionBefore, setCashiersSaveActionFinished } from './cashiers.action';

import { SET_CASHIER_ADDITIONAL_ACCESS } from "../../../../actionTypes";

const setCashierAdditionalAccess = data => ({
    type: SET_CASHIER_ADDITIONAL_ACCESS,
    payload: { data }
})

export const getCashierAdditionalAccess = id => {
    return dispatch => {
        dispatch(setCashiersActionBefore());

        return axios({
            url: ApiUrls.GET_CASHIER_ADDITIONAL_ACCESS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: additionalAccess}}) => {
            dispatch(setCashierAdditionalAccess(additionalAccess))
            dispatch(setCashiersActionFinished());
        })
        .catch((ex) => {
            dispatch(setCashiersActionFinished());
        })
    }
}

export const saveCashierAdditionalAccess = data => {
    return dispatch => {
        dispatch(setCashiersSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_CASHIER_ADDITIONAL_ACCESS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCashierAdditionalAccess(data.value));
            } 
            dispatch(setCashiersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCashiersSaveActionFinished());
        })
    }
}
