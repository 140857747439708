import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setBetshopsActionBefore, setBetshopsActionFinished, setBetshopsSaveActionBefore, setBetshopsSaveActionFinished } from './betshops.action';

import { SET_BETSHOP_ADDITIONAL_ACCESS } from "../../../../actionTypes";

const setBetshopAdditionalAccess = data => ({
    type: SET_BETSHOP_ADDITIONAL_ACCESS,
    payload: { data }
})

export const getBetshopAdditionalAccess = id => {
    return dispatch => {
        dispatch(setBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_ADDITIONAL_ACCESS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: additionalAccess}}) => {
            dispatch(setBetshopAdditionalAccess(additionalAccess))
            dispatch(setBetshopsActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsActionFinished());
        })
    }
}

export const saveBetshopAdditionalAccess = data => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_BETSHOP_ADDITIONAL_ACCESS,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBetshopAdditionalAccess(data.value));
            } 
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}
