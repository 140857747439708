// import { layoutStore } from "NEW/layouts/global/main/slice";

import { authInitialState } from "NEW/src/core/stores/authStore/initialState";

import { setAuthData } from "NEW/src/core/helpers/auth/setAuthData";
import { getTimeZoneOffset } from "NEW/src/core/helpers/date/getTimeZoneOffset";
import { BaseStore } from "NEW/src/core/lib/baseStore";
import { SessionManager } from "NEW/src/core/lib/sessionManager";
import { apiServices } from "NEW/src/core/services/apiServices";
import dateService from "NEW/src/core/services/dateService";
import { browserSessionStorage } from "NEW/src/core/services/storageService/browserSessionStorage";
import {TOKEN_TYPE} from "NEW/src/core/data/constants/auth";
// import { globalStore } from "NEW/src/core/slice/globalSlice";

export class AuthStore extends BaseStore {
	constructor() {
		super(authInitialState);
	}

	logout() {
		browserSessionStorage.clear();
		SessionManager.instance.stopSession();

		this.state = {
			user: null,
			loggingInProgress: false,
		};

		// globalStore.reset();
	}

	setAuthUser(user) {
		setAuthData(user);

		dateService.setTimeZone(getTimeZoneOffset(user.timeZoneId));

		if (user.tokenType !== TOKEN_TYPE.NONE) {
			this.state.user = user;
		}
	}

	setLockData(lockData) {
		if (!lockData) {
			this.state.lockData = null;
			return;
		}

		this.state.lockData = {
			...lockData,
			userName: this.state.lastLoginedUserName,
		};
	}

	refreshToken() {
		return apiServices.accountService
			.refreshToken(this.state.user.refreshToken)
			.then(({ data: { value } }) => {
				this.setAuthUser(value);

				return value;
			});
	}

	authenticate(data) {
		this.state.loggingInProgress = true;
		this.state.lastLoginedUserName = data.userName;

		return apiServices.accountService
			.login(data)
			.then(({ data: { value } }) => {
				this.setAuthUser(value);
				this.setLockData(null);

				return value;
			})
			.finally(() => {
				this.state.loggingInProgress = false;
			});
	}

	verifyAuthenticationCode(token) {
		this.state.loggingInProgress = true;

		return apiServices.accountService
			.authenticateToken(token)
			.then(({ data: { value } }) => {
				this.setAuthUser(value);

				return value;
			})
			.finally(() => {
				this.state.loggingInProgress = false;
			});
	}

	changeProject(data) {
		return apiServices.accountService.changeProject(data).then(({ data: { value } }) => {
			this.setAuthUser(value);
		});
	}

	switch(id) {
		return apiServices.accountService.switch(id).then(({ data: { value } }) => {
			browserSessionStorage.clear();

			this.setAuthUser(value);

			//TODO: Add stores

			// layoutStore.setBlockerLoading(true);

			// globalStore.fetchUserInfo().then(() => {
			// 	layoutStore.setBlockerLoading(false);
			// });
		});
	}

	switchBack() {
		return apiServices.accountService.switchBack().then(({ data: { value } }) => {
			browserSessionStorage.clear();

			this.setAuthUser(value);

			// layoutStore.setBlockerLoading(true);
			//
			// globalStore.fetchUserInfo().then(() => {
			// 	layoutStore.setBlockerLoading(false);
			// });
		});
	}
}
