import React from "react";

import { useTranslation } from "react-i18next";

import Tabs from "components/common/tabs";

import Requests from "./requests";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { INTEGRATION_TYPE } from "constants/project.constants";

/** Requests Page Component */
const RequestsComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.projects.platform"),
            permissions: [{ resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }],
            component: <Requests type={INTEGRATION_TYPE.PLATFORM} />
        },
        {
            title: t("backoffice.menu.retail"),
            permissions: [{ resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }],
            component: <Requests type={INTEGRATION_TYPE.RETAIL} />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.requests') }}
        />
    )
};

export default RequestsComponent;
