import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from "react-router-dom";

import CommissionPlanListComponent from "./commissionPlan-list.component";
import CommissionPlanEditComponent from "./commissionPlan-edit.component";

import { AGENT_TYPE } from "constants/agent.constants";

/** Agent Edit Page Commission Plans Tab Component */
const CommissionPlansComponent = ({
    agentType
}) => {
    const { search } = useLocation();

    const [currentCommissionPlanId, setCurrentCommissionPlanId] = useState((new URLSearchParams(search)).get("commissionPlanId"));

    /** Change view depend on query string param change */
    useEffect(() => {
        const commissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");
        setCurrentCommissionPlanId(commissionPlanId);
    }, [search])


    return !currentCommissionPlanId ? (
        <CommissionPlanListComponent 
            agentType={agentType}
        />
    ) : (
        <CommissionPlanEditComponent
            commissionPlanId={currentCommissionPlanId}
            agentType={agentType}
        />
    )
}

/** CommissionPlansComponent propTypes
    * PropTypes
*/
CommissionPlansComponent.propTypes = {
    /** Agent type */
    agentType: PropTypes.oneOf(Object.values(AGENT_TYPE))
}

export default CommissionPlansComponent