import React from 'react';

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';

export const tableColumns = [
    {
        title: "backoffice.payments.bankId",
        dataIndex: "id",
        copy: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.payments.bankName",
        dataIndex: "name",
        alwaysVisible: true,
        mobileLevel: 2,
    },
    {
        title: "backoffice.payments.creationDate",
        dataIndex: "createdAt",
        isDateTime: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.common.status",
        dataIndex: "state",
        render: state => <Status status={state} type={STATUS_TYPES.BANK} />,
        mobileLevel: 4,
    },
    {
        title: "backoffice.payments.currencies",
        dataIndex: "currencies",
        multi: true,
        mobileLevel: 5,
    }
];