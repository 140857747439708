import React, {memo} from 'react';
import Table from "components/common/table";

const TranslationsTable = ({loading, columns, data}) => {
    return (
        <Table
            uniqueKey="key"
            noAction
            noPagination
            disableFullView
            loading={loading}
            columns={columns}
            data={data}
        />
    );
};

export default memo(TranslationsTable);
