//#region react
import React, { useMemo } from "react";
import PropTypes from 'prop-types';
import i18n from "translations/config";
//#endregion

//#region hooks
import useFinancialLineChart from "../hooks/useFinancialLineChart";
import useFormat from "hooks/useFormat";
import useDate from "hooks/useDate";
import useProjectType from "hooks/useProjectType";
//#endregion

//#region components
import LineChart from "components/common/lineChart";
//#endregion

//#region constants
import { DATA_TYPES, FINANCIAL_DASHBOARD_FILTER_NAMES } from "../constants";
//#endregion

//#region utils
import {
    getFinancialTooltipHTML,
    getOperationsLineChartData,
} from "utils/dashboard";
import {
    getCheckPointsTooltipConfigs,
    getLegendConfigs,
    getMainLineConfigs,
    getXAxisConfigs
} from "utils/lineChart/configCreators";
import { constructLineChartConfigs } from "utils/lineChart";
//#endregion

const getLinesData = ({
    dataType,
    hasRetail,
    hasLiteMode,
    timePeriod,
    chartData,
    playerLineColor,
    betshopLineColor,
    dateService
}) => {
    if (hasLiteMode) {
        if (dataType === DATA_TYPES.DEPOSIT_AMOUNT) {
            const playerLineData = getOperationsLineChartData(chartData, timePeriod, "depositAmount", dateService);

            return {
                linesData: { playerLineData },
                lineConfigs: [
                    getMainLineConfigs({
                        data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                        name: i18n.t("backoffice.dashboard.player"),
                        mainLineColor: playerLineColor,
                    }),
                ]
            };
        }

        if (dataType === DATA_TYPES.WITHDRAW_AMOUNT) {
            const playerLineData = getOperationsLineChartData(chartData, timePeriod, "withdrawAmount", dateService);

            return {
                linesData: { playerLineData },
                lineConfigs: [
                    getMainLineConfigs({
                        data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                        name: i18n.t("backoffice.dashboard.player"),
                        mainLineColor: playerLineColor,
                    }),
                ]
            };
        }
    }

    if ([DATA_TYPES.COMMISSIONS, DATA_TYPES.NET_DEPOSIT].includes(dataType)) {
        const playerLineData = getOperationsLineChartData(chartData, timePeriod, "amount", dateService);

        return {
            linesData: { playerLineData },
            lineConfigs: [
                getMainLineConfigs({
                    data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                    name: i18n.t("backoffice.dashboard.player"),
                    mainLineColor: playerLineColor,
                })
            ]
        }
    }

    if (hasRetail) {
        const playerLineData = getOperationsLineChartData(chartData, timePeriod, "amount", dateService);
        const betshopLineData = getOperationsLineChartData(chartData, timePeriod, "betShopAmount", dateService);

        return {
            linesData: { playerLineData, betshopLineData },
            lineConfigs: [
                getMainLineConfigs({
                    data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                    name: i18n.t("backoffice.dashboard.player"),
                    mainLineColor: playerLineColor,
                }),
                getMainLineConfigs({
                    data: typeof betshopLineData.values === 'function' ? [] : betshopLineData.values,
                    name: i18n.t("backoffice.dashboard.betShop"),
                    mainLineColor: betshopLineColor,
                }),
            ]
        }
    }

    const playerLineData = getOperationsLineChartData(chartData, timePeriod, "amount", dateService);

    return {
        linesData: { playerLineData },
        lineConfigs: [
            getMainLineConfigs({
                data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                mainLineColor: playerLineColor,
                name: i18n.t("backoffice.dashboard.player"),
            })
        ]
    }

}

const calcTotalAmountForPeriod = (chart) => {
    return chart.reduce((acc, data) => acc + data.amount + data.betShopAmount, 0);
}

const FinancialLineChart = ({
    title,
    dataType,
    playerLineColor,
    betshopLineColor,
    disableLegend = false,
    filters,
    updateLastUpdatedDate,
    isActive,
    calcTotalAmount,
}) => {
    const { chartData, isLoading } = useFinancialLineChart(dataType, filters, updateLastUpdatedDate, isActive);
    const { formatAmount, formatCurrencyWithSymbol, getCurrencySymbol } = useFormat();

    const { dateService } = useDate();

    const { hasRetail, hasLiteMode } = useProjectType();

    const calcTotal = calcTotalAmount || calcTotalAmountForPeriod;

    let prefix = filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY];

    if (hasLiteMode) {
        prefix = getCurrencySymbol(filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]);
    }

    const configs = useMemo(() => {
        const { linesData, lineConfigs } = getLinesData({
            dataType,
            playerLineColor,
            betshopLineColor,
            hasRetail,
            hasLiteMode,
            timePeriod: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD],
            chartData: chartData,
            dateService
        });

        return constructLineChartConfigs({
            legend: disableLegend ? undefined : getLegendConfigs(),
            xAxisConfigs: getXAxisConfigs({
                checkPoints: linesData.playerLineData.categories,
            }),
            checkPointsTooltipConfigs: getCheckPointsTooltipConfigs({
                tooltipHTMLGenerator: (element) => (
                    getFinancialTooltipHTML(
                        element,
                        {
                            hasLiteMode,
                            chartData: chartData,
                            currencyCode: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY],
                            dataType,
                            timePeriod: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD],
                            dates: element.series.name === i18n.t("backoffice.dashboard.betShop") ? linesData.betshopLineData.dates : linesData.playerLineData.dates,
                            counts: element.series.name === i18n.t("backoffice.dashboard.betShop") ? linesData.betshopLineData.counts : linesData.playerLineData.counts,
                            formatAmount,
                            formatCurrencyWithSymbol,
                            dateService
                        }
                    )
                )
            }),
            lines: lineConfigs,
        })
    }, [chartData, dateService])

    return (
        <LineChart
            title={title}
            configs={configs}
            hideChart={chartData.length === 0}
            isLoading={isLoading}
            total={`${prefix} ${formatAmount(calcTotal(chartData), filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY])}`}
        />
    )
};

FinancialLineChart.propTypes = {
    title: PropTypes.string,
    dataType: PropTypes.oneOf(Object.values(DATA_TYPES)),
    filters: PropTypes.object,
    playerLineColor: PropTypes.string,
    betshopLineColor: PropTypes.string,
    disableLegend: PropTypes.bool,
    updateLastUpdatedDate: PropTypes.func,
    isActive: PropTypes.bool,
    calcTotalAmount: PropTypes.func,
}

export default FinancialLineChart;
