import React from "react";

import BetshopsComponent from "pages/retail/betshops/betshops.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const BetshopsRoute = () => {
    return <BetshopsComponent />;
};

export default withPermission(
    withAuth(BetshopsRoute),
    { resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
