import { WALLET_TYPE } from "constants/wallet.constants";

import {
    SET_BETSHOPS_ACTION_BEFORE,
    SET_BETSHOPS_ACTION_FINISH,
    SET_BETSHOPS,
    SET_BETSHOPS_SORTING,
    SET_BETSHOPS_FILTERS,
    SET_BETSHOPS_SAVE_ACTION_BEFORE,
    SET_BETSHOPS_SAVE_ACTION_FINISH,
    SET_BETSHOP_AVAILABLE_CURRENCIES_BEFORE,
    SET_BETSHOP_AVAILABLE_CURRENCIES_FINISH,
    SET_BETSHOP_AVAILABLE_CURRENCIES,
    SET_BETSHOP_GENERAL_INFO,
    SET_BETSHOP_TRANSLATION,
    SET_BETSHOP_CURRENCY,
    SET_BETSHOP_LIMIT_SETTINGS,
    SET_BETSHOP_ADDITIONAL_ACCESS,
    SET_BETSHOP_IP_ACCESS_RULE,
    SET_BETSHOP_BALANCE
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BETSHOPS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BETSHOPS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_BETSHOPS:
            return {
                ...state,
                betshops: !payload.add ? payload.betshops.item2 : state.betshops.concat(payload.betshops.item2),
                total: payload.betshops.item1,
            };

        case SET_BETSHOPS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_BETSHOPS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_BETSHOPS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_BETSHOPS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_BETSHOP_BALANCE:
            return {
                ...state,
                betshops: state.betshops.map(betshop => {
                    const wallet = payload.wallets.find(w => w.id === betshop.longId);

                    if(wallet){
                        return ({
                            ...betshop,
                            balance: wallet.type === WALLET_TYPE.BALANCE ? wallet.totalAmount : betshop.balance,
                            limit: wallet.type === WALLET_TYPE.LIMIT ? wallet.totalAmount : betshop.limit
                        })
                    } else {
                        return betshop
                    }
                }),
            };
        case SET_BETSHOP_AVAILABLE_CURRENCIES_BEFORE:
            return {
                ...state,
                availableCurrencies: {
                    ...state.availableCurrencies,
                    isLoading: true
                }
            }
        case SET_BETSHOP_AVAILABLE_CURRENCIES_FINISH:
            return {
                ...state,
                availableCurrencies: {
                    ...state.availableCurrencies,
                    isLoading: false
                }
            }
        case SET_BETSHOP_AVAILABLE_CURRENCIES:
            return {
                ...state,
                availableCurrencies: {
                    ...state.availableCurrencies,
                    availableCurrencies: payload.currencies
                }
            }
        case SET_BETSHOP_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case SET_BETSHOP_TRANSLATION:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...state.edit.general,
                        translations: state.edit.general.translations.map(tr => tr.key === payload.key ? 
                            { 
                                ...tr,
                                translations: tr.translations.map(t => t.languageCode === payload.translation.languageCode ? payload.translation : t)
                            } : tr)
                    }
                }
            }
        case SET_BETSHOP_CURRENCY:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    currency: {
                        ...payload.currency
                    }
                }
            }
        case SET_BETSHOP_LIMIT_SETTINGS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    limitSettings: payload.data
                }
            }
        case SET_BETSHOP_ADDITIONAL_ACCESS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    additionalAccess: payload.data
                }
            }
        case SET_BETSHOP_IP_ACCESS_RULE:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    ipAccessRule: payload.ipAccess
                }
            }
        default:
            return state;
    }
};
