import {
    SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_CASHIER_PERFORMANCE_REPORT
} from "../../actionTypes";


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_CASHIER_PERFORMANCE_REPORT:
            return {
                ...state,
                report: !payload.add ? payload.report : state.report.concat(payload.report),
            }
        default:
            return state;
    }
}