import React from 'react';
import PropTypes from 'prop-types';

import StandartTransferForm from './standart';
import EposTransferForm from './epos';

import useProjectType from 'hooks/useProjectType';

import { TRANSFER_ENTITY_TYPE } from '../../constants';

const TransferForm = ({
    entityType
}) => {

    const { hasStandartPayment, hasEpos } = useProjectType();

    if (entityType !== TRANSFER_ENTITY_TYPE.PLAYER || hasStandartPayment) {
        return (
            <StandartTransferForm 
                entityType={entityType}
            />
        )
    }

    if (hasEpos) {
        return <EposTransferForm />;
    }

    return null;
}   

/** TransferForm propTypes
    * PropTypes
*/
TransferForm.propTypes = {
    entityType: PropTypes.oneOf(Object.values(TRANSFER_ENTITY_TYPE))
}

export default TransferForm;