import React from "react";

import ProductReportComponent from "pages/reports/productReport/productReport.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const ProductReportRoute = () => {
    return <ProductReportComponent />;
};

export default withPermission(
    withAuth(ProductReportRoute),
    {
        resource: PERMISSION_RESOURCE.REPORTS_AGENT_PERFORMANCE,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
