import React from 'react';

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {typeToIconMap} from "pages/agentSystem/network/sections/networkTree/components/treeNodeTitle/constants";
import {ROUTES} from "NEW/src/core/data/constants/routes";
import {USER_TYPE} from "constants/user.constants";

import TreeNodeTag from "pages/agentSystem/network/sections/networkTree/components/treeNodeTag";
import {isFranchisingMode} from "utils/common";

const TreeNodeTitle = ({id, name, type, subAgentsCount, playersCount, betshopsCount, globalCompanyId, globalProjectId}) => {
    const icon = typeToIconMap[type] || null;
    const getAgentURL = () => {
        return `${ROUTES.AGENTS_EDIT}/${id}?cid=${globalCompanyId}&pid=${globalProjectId}&name=${name}&role=${type}`
    }

    const getProjectURL = () => {
        return `${ROUTES.PROJECTS_EDIT}/${id}?cid=${globalCompanyId}&name=${name}&role=${type}`
    }

    const url = type === USER_TYPE.AGENT ? getAgentURL() : getProjectURL();

    return (
        <div className="rt--expandable-tree-node">
            <div className="rt--expandable-tree-node-left">
                {icon}

                <Link to={url}>
                    <span className="rt--title rt--font-normal rt--font-regular rt--link">
                        <span className="rt--expandable-tree-node-name">
                            {name}
                        </span>
                    </span>
                </Link>
            </div>

            <div className="rt--expandable-tree-node-tags">
                {!!subAgentsCount && (
                    <TreeNodeTag icon={<i className="icon-agent"/>}>
                        {subAgentsCount}
                    </TreeNodeTag>
                )}

                {!isFranchisingMode() && !!playersCount && (
                    <TreeNodeTag icon={<i className="icon-player"/>}>
                        {playersCount}
                    </TreeNodeTag>
                )}

                {!!betshopsCount && (
                    <TreeNodeTag icon={<i className="icon-retail"/>}>
                        {betshopsCount}
                    </TreeNodeTag>
                )}
            </div>
        </div>
    );
};

TreeNodeTitle.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    subAgentsCount: PropTypes.number,
    playersCount: PropTypes.number,
    betshopsCount: PropTypes.number,
    globalCompanyId: PropTypes.string,
    globalProjectId: PropTypes.string,
}

const mapStateToProps = (state) => ({
    globalCompanyId: state.common.globalCompanyId,
    globalProjectId: state.common.globalProjectId,
})

export default connect(mapStateToProps)(TreeNodeTitle);
