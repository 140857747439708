import React, {useEffect, useState, forwardRef, useImperativeHandle, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';

import {Collapse, Form, Checkbox, Spin} from "antd";

import Table from 'components/common/table';
import Tooltip from 'components/common/tooltip';

import {PERMISSION_ACTION} from "constants/permissions.constants";

import permissionType from 'types/permission/permission.type';

import {toLowerCaseFirstLetter} from "utils/common";

import {makeName} from "components/common/permissions/helpers/makeName";
import CollapseHeader from "components/common/permissions/components/collapseHeader";
import {mapping} from "components/common/permissions/constants";
import {isCheckedAll} from "components/common/permissions/helpers/isCheckedAll";
import {isUnCheckedAll} from "components/common/permissions/helpers/isUnCheckedAll";
import {hasEditablePermission} from "components/common/permissions/helpers/hasEditablePermission";
import SecurityLevel from "components/common/permissions/components/securityLevel";
import {isFieldReadonly} from "components/common/permissions/helpers/isFieldReadonly";
import {makeDescription} from "components/common/permissions/helpers/makeDescription";
import Cell from "components/common/permissions/components/cell";
import TableHeaderCell from "components/common/permissions/components/tableHeaderCell";

/** Permissions List Component */

const PermissionsComponent = forwardRef(({
    permissions,
    isLoading,
    formInstance = {},
    initialFormValues,
    editable,
    disabled,
    onCheckAll,
    onChange,
    fieldName
}, ref) => {

    const {t} = useTranslation();

    const [isAllChecked, setIsAllChecked] = useState(false);

    const {setFieldsValue, getFieldsValue, getFieldValue} = formInstance;

    useEffect(() => {
        onChange && onChange(isAllChecked)
    }, [isAllChecked])

    /** Open all by default */
    useEffect(() => {
        if (editable) {
            setIsAllChecked(isCheckedAll({fieldName, getFieldValue, getFieldsValue}));
        }
    }, [initialFormValues, editable]);

    /** Function to render mobile row
     * @param {object} permission
     * @param {string} action
     * @param {string} parent - parent resource
     * @function
     * @memberOf PermissionsComponent
     */
    const renderMobileRow = (permission, action, parent) => {
        const actionData = permission.actions.find(a => a.action === action);

        return (
            <div className="rt--permissions-table-content rt--flex rt--align-center">
                <div className='rt--flex rt--flex-col rt--justify-center rt--align-start'>
                    <div className='rt--flex rt--align-center rt--justify-start'>
                        <span
                            className='rt--title rt--font-normal rt--font-regular rt--permissions-table-content-action-title rt--mr-2'>{t(`backoffice.permissions.${action}`)}</span>

                        <SecurityLevel permission={actionData}/>
                    </div>
                    <Tooltip
                        title={actionData ? makeDescription(permission.resource, action, t) : ""}
                        enableMobile={true}
                    >
                        <span
                            className="rt--title rt--font-normal rt--font-regular rt--permissions-table-description rt--pt-2">{actionData ? makeDescription(permission.resource, action, t) : ""}</span>
                    </Tooltip>
                </div>
                {
                    (actionData && editable) && (
                        <div className="rt--flex rt--align-center rt--form-item-checkbox rt--flex-equal rt--justify-end">
                            <Form.Item
                                name={permission.isParent ? (fieldName ? [fieldName] : []).concat([permission.resource, "parent", action]) : (fieldName ? [fieldName] : []).concat([parent?.resource ?? null, permission.resource, action])}
                                valuePropName="checked"
                                className="rt--form-item-without-margin rt--permissions-table-checkbox"
                            >
                                <Checkbox
                                    onChange={e => handleChange(permission.resource, !permission.isParent ? (parent?.resource ?? null) : null, action, e.target.checked)}
                                    disabled={
                                        !actionData.canEdit ||
                                        isFieldReadonly({
                                            resource: permission.resource,
                                            parent: !permission.isParent ? (parent?.resource ?? null) : null,
                                            action,
                                            disabled,
                                            fieldName,
                                            getFieldValue,
                                            getFieldsValue
                                        })
                                    }
                                />
                            </Form.Item>
                        </div>
                    )
                }

            </div>
        )

    }

    /** Function to render table for permissions
     * @param {object} permission
     * @function
     * @memberOf PermissionsComponent
     */
    const renderTable = permission => {
        let tableData = [];
        tableData.push({...permission, isParent: true})
        tableData = tableData.concat(permission.subPermissions)

        return (
            <div className="rt--permissions-table" key={permission.resource}>
                <Table
                    loading={isLoading}
                    columns={[
                        {
                            title: "",
                            dataIndex: 'name',
                            render: (value, record) =>
                                <Tooltip
                                    title={makeName(record.resource, t)}
                                >
                                    <span
                                        className="rt--permissions-table-description">{makeName(record.resource, t)}</span>
                                </Tooltip>,
                            mobileLevel: 1,
                            showOnlyValue: true
                        },
                        {
                            title: (
                                <TableHeaderCell
                                    resource={permission.resource}
                                    action={PERMISSION_ACTION.VIEW}
                                    permissions={permissions}
                                    formInstance={formInstance}
                                    fieldName={fieldName}
                                    editable={editable}
                                    disabled={disabled}
                                    onUnCheckAll={unCheckAll}
                                    onCheckAll={checkAll}
                                />
                            ),
                            dataIndex: 'view',
                            render: (value, record) => (
                                <Cell
                                    disabled={disabled}
                                    editable={editable}
                                    permission={record}
                                    permissions={permissions}
                                    action={PERMISSION_ACTION.VIEW}
                                    fieldName={fieldName}
                                    parent={tableData[0]}
                                    formInstance={formInstance}
                                    onChange={handleChange}
                                />
                            ),
                            mobileLevel: 2,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.VIEW, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.VIEW),
                        },
                        {
                            title: (
                                <TableHeaderCell
                                    resource={permission.resource}
                                    action={PERMISSION_ACTION.CREATE}
                                    permissions={permissions}
                                    formInstance={formInstance}
                                    fieldName={fieldName}
                                    editable={editable}
                                    disabled={disabled}
                                    onUnCheckAll={unCheckAll}
                                    onCheckAll={checkAll}
                                />
                            ),
                            dataIndex: 'create',
                            render: (value, record) => (
                                <Cell
                                    disabled={disabled}
                                    editable={editable}
                                    permission={record}
                                    permissions={permissions}
                                    action={PERMISSION_ACTION.CREATE}
                                    fieldName={fieldName}
                                    parent={tableData[0]}
                                    formInstance={formInstance}
                                    onChange={handleChange}
                                />
                            ),
                            mobileLevel: 3,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.CREATE, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.CREATE),
                        },
                        {
                            title: (
                                <TableHeaderCell
                                    resource={permission.resource}
                                    action={PERMISSION_ACTION.MODIFY}
                                    permissions={permissions}
                                    formInstance={formInstance}
                                    fieldName={fieldName}
                                    editable={editable}
                                    disabled={disabled}
                                    onUnCheckAll={unCheckAll}
                                    onCheckAll={checkAll}
                                />
                            ),
                            dataIndex: 'modify',
                            render: (value, record) => (
                                <Cell
                                    disabled={disabled}
                                    editable={editable}
                                    permission={record}
                                    permissions={permissions}
                                    action={PERMISSION_ACTION.MODIFY}
                                    fieldName={fieldName}
                                    parent={tableData[0]}
                                    formInstance={formInstance}
                                    onChange={handleChange}
                                />
                            ),
                            mobileLevel: 4,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.MODIFY, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.MODIFY),
                        },
                        {
                            title: (
                                <TableHeaderCell
                                    resource={permission.resource}
                                    action={PERMISSION_ACTION.DELETE}
                                    permissions={permissions}
                                    formInstance={formInstance}
                                    fieldName={fieldName}
                                    editable={editable}
                                    disabled={disabled}
                                    onUnCheckAll={unCheckAll}
                                    onCheckAll={checkAll}
                                />
                            ),
                            dataIndex: 'delete',
                            render: (value, record) => (
                                <Cell
                                    disabled={disabled}
                                    editable={editable}
                                    permission={record}
                                    permissions={permissions}
                                    action={PERMISSION_ACTION.DELETE}
                                    fieldName={fieldName}
                                    parent={tableData[0]}
                                    formInstance={formInstance}
                                    onChange={handleChange}
                                />
                            ),
                            mobileLevel: 5,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.DELETE, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.DELETE),
                        },
                        {
                            title: (
                                <TableHeaderCell
                                    resource={permission.resource}
                                    action={PERMISSION_ACTION.EXPORT}
                                    permissions={permissions}
                                    formInstance={formInstance}
                                    fieldName={fieldName}
                                    editable={editable}
                                    disabled={disabled}
                                    onUnCheckAll={unCheckAll}
                                    onCheckAll={checkAll}
                                />
                            ),
                            dataIndex: 'export',
                            render: (value, record) => (
                                <Cell
                                    disabled={disabled}
                                    editable={editable}
                                    permission={record}
                                    permissions={permissions}
                                    action={PERMISSION_ACTION.EXPORT}
                                    fieldName={fieldName}
                                    parent={tableData[0]}
                                    formInstance={formInstance}
                                    onChange={handleChange}
                                />
                            ),
                            mobileLevel: 6,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.EXPORT, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.EXPORT),
                        },
                    ]}
                    data={tableData}
                    total={tableData.length}
                    actions={[]}
                    isDisabled={() => false}
                    noPagination={true}
                    uniqueKey="resource"
                    noAction={true}
                    mobileListView={true}
                    disableFullView={true}
                    fullViewClassName="rt--permissions-card-view"
                />

            </div>
        )
    }

    /** Fires on checkbox change
     * @function
     * @param {string} resource
     * @param {string} parent
     * @param {string} action
     * @param {boolean} checked
     * @memberOf PermissionsComponent
     */
    const handleChange = useCallback((resource, parent, action, checked) => {
        const {setFieldsValue, getFieldsValue, getFieldValue} = formInstance;

        const values = fieldName ? getFieldsValue()[fieldName] : getFieldsValue();
        let changed = false;
        let newValues = {};

        if (checked) {
            if (!parent) {
                if (action === PERMISSION_ACTION.DELETE || action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.EXPORT) {
                    changed = true;
                    newValues = {
                        [resource]: {
                            parent: {
                                ...values[resource].parent,
                                [PERMISSION_ACTION.VIEW]: true
                            }
                        }
                    };

                    if (values[resource][resource + "_GeneralInfo"] && (action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.DELETE)) {
                        newValues[resource][resource + "_GeneralInfo"] = {
                            ...values[resource][resource + "_GeneralInfo"],
                            [PERMISSION_ACTION.VIEW]: true
                        };
                    }
                }
            } else {
                changed = true;
                newValues = {
                    [parent]: {
                        parent: {
                            ...values[parent].parent,
                            [PERMISSION_ACTION.VIEW]: true
                        }
                    }
                };

                if (action === PERMISSION_ACTION.DELETE || action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.EXPORT) {
                    newValues[parent][resource] = {
                        ...values[parent][resource],
                        [PERMISSION_ACTION.VIEW]: true
                    };
                }
            }
        }

        if (changed) {
            setFieldsValue(fieldName ? {[fieldName]: newValues} : newValues);
        }
        setIsAllChecked(isCheckedAll({fieldName, getFieldValue, getFieldsValue}));
    }, [formInstance, fieldName]);

    /** Fires on select all click
     * @function
     * @param {string} resource
     * @param {string} action - if null then all actions
     * @param {object} e - click event object
     * @memberOf PermissionsComponent
     */
    const checkAll = (resource, action, e) => {
        e && e.stopPropagation();
        if (isCheckedAll({resource, action, fieldName, getFieldsValue, getFieldValue})) return;
        const values = fieldName ? {...getFieldValue(fieldName)} : {...getFieldsValue()};
        const newValues = {};

        const resources = resource ? [resource] : Object.keys(values);

        resources.forEach(r => {
            newValues[r] = {};
            Object.keys(values[r]).forEach(res => {
                newValues[r][res] = {};

                if (action && values[r][res][action] !== undefined) {
                    newValues[r][res][action] = true;

                    if (action === PERMISSION_ACTION.DELETE || action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.MODIFY) {
                        newValues[r][res][PERMISSION_ACTION.VIEW] = true;
                    }
                    if (res !== "parent") {
                        newValues[r]["parent"] = {
                            ...newValues[r]["parent"],
                            [PERMISSION_ACTION.VIEW]: true
                        };
                    }
                    if (res === "parent" && action === PERMISSION_ACTION.MODIFY && values[r][r + "_GeneralInfo"]) {
                        newValues[r] = {
                            ...newValues[r],
                            [r + "_GeneralInfo"]: {
                                [PERMISSION_ACTION.VIEW]: true
                            }
                        }
                    }
                } else if (!action) {
                    Object.keys(values[r][res]).forEach(a => {

                        newValues[r][res][a] = true;
                    })
                }
            })
        });

        setFieldsValue(fieldName ? {[fieldName]: newValues} : newValues);
        setIsAllChecked(isCheckedAll({fieldName, getFieldValue, getFieldsValue}));
        onCheckAll && onCheckAll();
    }

    /** Fires on deselect all click
     * @function
     * @param {string} resource
     * @param {string} action - if null then all actions
     * @param {object} e - click event object
     * @memberOf PermissionsComponent
     */
    const unCheckAll = (resource, action, e) => {
        e && e.stopPropagation();
        if (isUnCheckedAll({resource, action, fieldName, getFieldsValue, getFieldValue})) return;
        const values = fieldName ? {...getFieldValue(fieldName)} : {...getFieldsValue()};
        const newValues = {};

        const resources = resource ? [resource] : Object.keys(values);

        resources.forEach(r => {
            newValues[r] = {};

            Object.keys(values[r]).forEach(res => {
                newValues[r][res] = {};

                if (action) {
                    if (!isFieldReadonly({
                        resource: res === "parent" ? r : res,
                        parent: res === "parent" ? null : r,
                        action,
                        fieldName,
                        disabled,
                        getFieldsValue,
                        getFieldValue
                    })) {
                        newValues[r][res][action] = false;
                    }
                } else {
                    Object.keys(values[r][res]).forEach(a => {
                        newValues[r][res][a] = false;
                    })
                }
            })
        })

        setFieldsValue(fieldName ? {[fieldName]: newValues} : newValues);
        setIsAllChecked(isCheckedAll({resource, action, fieldName, getFieldsValue, getFieldValue}));
        onCheckAll && onCheckAll();
    }

    /** Sort Permissions */
    const sortedPermissions = useMemo(() => {
        return [...permissions].sort(
            (perm1, perm2) => {
                const perm1Order = mapping[toLowerCaseFirstLetter(perm1.resource)]?.order ?? 10000;
                const perm2Order = mapping[toLowerCaseFirstLetter(perm2.resource)]?.order ?? 10000;
                return perm1Order < perm2Order ? -1 : 1;
            }
        )
    }, [permissions]);

    const collapseItems = useMemo(() => (
        sortedPermissions.map(perm => (
            {
                key: perm.resource,
                disabled: !hasEditablePermission(permissions, perm.resource),
                forceRender: true,
                label: (
                    <CollapseHeader
                        disabled={disabled}
                        editable={editable}
                        fieldName={fieldName}
                        permission={perm}
                        formInstance={formInstance}
                        permissions={permissions}
                        onCheckAll={checkAll}
                        onUnCheckAll={unCheckAll}
                    />
                ),
                children: renderTable(perm)
            }
        ))
    ), [sortedPermissions, permissions])


    useImperativeHandle(ref, () => ({
        toggleAll: isAllChecked => {
            if (isAllChecked) {
                unCheckAll()
            } else {
                checkAll()
            }
        },

        isDisabled: !sortedPermissions.some(perm => hasEditablePermission(permissions, perm.resource))
    }), [permissions, onCheckAll])

    return (
        <Spin spinning={isLoading}>
            <Collapse
                accordion
                bordered={false}
                className="rt--permissions-collapse"
                expandIcon={() => <i className="icon-down rt--font-bigest" />}
                items={collapseItems}
            />
        </Spin>
    )
})

/** PermissionsComponent propTypes
 * PropTypes
 */
PermissionsComponent.propTypes = {
    /** permissions array to show */
    permissions: PropTypes.arrayOf(permissionType),
    /** Is true when loading permissions */
    isLoading: PropTypes.bool,
    /** Form instance object */
    formInstance: PropTypes.object,
    /** Form initial values */
    initialFormValues: PropTypes.object,
    /** Are permission editable */
    editable: PropTypes.bool,
    /** Are checkboxes disabled */
    disabled: PropTypes.bool,
    /** Function, which will fire on checking all */
    onCheckAll: PropTypes.func,
    /** Fires on change */
    onChange: PropTypes.func,
    /** The fild name in form */
    fieldName: PropTypes.string
}

export default PermissionsComponent;
