//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'translations/config';
import { useParams, useLocation } from "react-router-dom";
//#endregion

//#region actions
import {
    getPermissionGroupUsers,
    deletePermissionGroupUser,
    setPermissionGroupUsersSorting,
    setPermissionGroupUsersFilters
} from "store/actions/dashboard/userManagement/permissionGroups/users.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Notes from 'components/common/notes';
import PendingRequestsNote from 'components/common/pendingRequestsNote';

import UserAddComponent from "./user-add.component";
//#endregion

//#region utils
import { isFranchisingMode } from "utils/common";
import { getUser } from "utils/auth";
import { hasPermission } from "utils/permissions";
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { USER_TYPE, USER_ROLE } from "constants/user.constants";
import { PERMISSION_TYPE } from "constants/permissions.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { ADMIN_PERMISSION_GROUP_EDIT } from 'constants/pageName.constants';
import { tableColumns } from './columns';
//#endregion

//#region types
import permissionGroupUserType from 'types/permissionGroup/user.type';
import sortingType from 'types/common/sorting.type';
//#endregion

const getConfirmationTitleAndMessage = ({ isAdmin, franchisingMode }) => {
    if (isAdmin) {
        return {
            confirmationTitle: i18n.t("backoffice.permissiongroups.deleteAdmin"),
            confirmationMessage: i18n.t("backoffice.permissiongroups.doYouWanttoDeleteAdmin"),
        }
    }

    if (franchisingMode) {
        return {
            confirmationTitle: i18n.t("backoffice.permissiongroups.deleteBetshopOwner"),
            confirmationMessage: i18n.t("backoffice.permissiongroups.doYouWanttoDeleteBetshopOwner"),
        }
    }

    return {
        confirmationTitle: i18n.t("backoffice.permissiongroups.deleteAgent"),
        confirmationMessage: i18n.t("backoffice.permissiongroups.doYouWanttoDeleteAgent"),
    }
}

const getAddButtonText = ({ isAdmin, franchisingMode, entityGroupType }) => {
    if (isAdmin) {
        return i18n.t("backoffice.permissiongroups.addAdmin")
    }

    if (franchisingMode) {
        return i18n.t("backoffice.permissiongroups.addBetshopOwner")
    }

    if (entityGroupType === PERMISSION_TYPE.AGENT_PLAYER) {
        return i18n.t("backoffice.permissiongroups.addPlayerAgent")
    }

    return i18n.t("backoffice.permissiongroups.addAgent")
}

/** Permission Group Edit Page Users Tab Component */
const UsersComponent = ({
    getPermissionGroupUsers,
    deletePermissionGroupUser,
    setPermissionGroupUsersSorting,
    setPermissionGroupUsersFilters,
    sorting,
    filters,
    isLoading,
    users,
    total
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({
        pageName: ADMIN_PERMISSION_GROUP_EDIT
    });

    const userType = Number(queryParams.get("type") || USER_TYPE.ADMIN);
    const entityGroupType = Number(queryParams.get("groupType"));

    const isAdmin = userType === USER_TYPE.ADMIN;

    const franchisingMode = isFranchisingMode();

    const { userName, role: userRole } = getUser();

    /** Notes */
    const [notes, setNotes] = useState(null);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PERMISSION_GROUP,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setPermissionGroupUsersFilters({
            ...filters,
            userNameOrId: value
        })
    }

    const showAddPopup = () => {
        setIsAddPopupVisible(true);
    }

    const hideAddPopup = () => {
        setIsAddPopupVisible(false);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            includedColumns,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasModifyPermission) {
        const { confirmationTitle, confirmationMessage } = getConfirmationTitleAndMessage({
            isAdmin,
            franchisingMode
        })

        tableRowActions.push({
            title: t('backoffice.common.delete'),
            icon: "icon-trash",
            showConfirmation: true,
            onClick: record => handleDelete(record.id),
            disabled: record => record.userName === userName,
            confirmationTitle,
            confirmationMessage,
        })
    }

    /** Actions */
    const handleDelete = id => {
        if (userRole !== USER_ROLE.ACCESS_MANAGER) {
            deletePermissionGroupUser(
                id,
                searchParams.id
            )
        } else {
            setNotes({
                id
            })
        }
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const addButtonText = getAddButtonText({
        isAdmin,
        franchisingMode,
        entityGroupType,
    })

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            placeholder: t("backoffice.permissiongroups.usernameOrId"),
            onSearch: handleSearchChange,
            loadFn: () => getPermissionGroupUsers(searchParams.id),
            autoSuggestion: {
                type: isAdmin ? AUTOSUGGESTION_TYPE.USER : AUTOSUGGESTION_TYPE.AGENT,
                actionParams: !isAdmin ? {
                    userRole: userType
                } : undefined
            }
        },
        button: {
            icon: "icon-plus",
            type: "primary",
            onClick: showAddPopup,
            enabled: hasModifyPermission,
            text: addButtonText,
        }
    }

    //#endregion

    // Close user add/edit popup, after save
    useEffect(() => {
        hideAddPopup();
    }, [users])

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={users}
                loadFn={nextPage => getPermissionGroupUsers(searchParams.id, nextPage)}
                setSortingFn={setPermissionGroupUsersSorting}
                sorting={sorting}
                total={total}
                actions={tableRowActions}
            />

            {isAddPopupVisible && <UserAddComponent onClose={hideAddPopup} />}

            {
                notes && (
                    <Notes
                        onClose={() => setNotes(null)}
                        onSuccess={value => {
                            deletePermissionGroupUser(
                                notes.id,
                                searchParams.id,
                                value
                            );
                        }}
                    />
                )
            }
        </TabTableDashboardLayout>
    )
}

/** UsersComponent propTypes
    * PropTypes
*/
UsersComponent.propTypes = {
    /** Redux action to get permission group users */
    getPermissionGroupUsers: PropTypes.func,
    /** Redux action to delete permission group user */
    deletePermissionGroupUser: PropTypes.func,
    /** Redux action to set permission group user sorting */
    setPermissionGroupUsersSorting: PropTypes.func,
    /** Redux action to set permission group user filters */
    setPermissionGroupUsersFilters: PropTypes.func,
    /** Redux state property, is true when permission group users are loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the users of current editing permission group  */
    users: PropTypes.arrayOf(permissionGroupUserType),
    /** Redux state property, represents the sorting data of users of current editing permission group */
    sorting: sortingType,
    /** Redux state property, represents the filters data of users of current editing permission group */
    filters: PropTypes.object,
    /** Redux state property, represents the total numbers of users of current editing permission group */
    total: PropTypes.number
}

const mapDispatchToProps = dispatch => (
    {
        getPermissionGroupUsers: (id, nextPage) => {
            dispatch(getPermissionGroupUsers(id, nextPage));
        },

        setPermissionGroupUsersSorting: sorting => {
            dispatch(setPermissionGroupUsersSorting(sorting))
        },

        setPermissionGroupUsersFilters: filters => {
            dispatch(setPermissionGroupUsersFilters(filters))
        },

        deletePermissionGroupUser: (id, groupId, note) => {
            dispatch(deletePermissionGroupUser(id, groupId, note));
        }
    }
)

const mapStateToProps = state => {
    return {
        users: state.permissionGroups.edit.users.users,
        isLoading: state.permissionGroups.isLoading,
        sorting: state.permissionGroups.edit.users.sorting,
        filters: state.permissionGroups.edit.users.filters,
        total: state.permissionGroups.edit.users.total
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent)