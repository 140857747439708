import React from 'react';
import PropTypes from 'prop-types';

import { isFunction } from "utils/common";

import Tooltip from 'components/common/tooltip';

const TableColumnContent = ({ columnData, rowData, onEdit }) => {
    const {
        mobileRender,
        render,
        dataIndex,
        editable,
        editableTitle,
        editableLabel,
        maxLength,
        regex,
        extraButtons,
        editableFieldType,
        disableEdit,
    } = columnData;

    const handleEditIconInternalClick = () => {
        const cellEditData = {
            maxLength,
            regex,
            extraButtons,
            rowData,
            editableFieldType,
            title: editableTitle(rowData),
            label: editableLabel(rowData),
            onSave: value => columnData.onEdit(value, rowData),
            initialValue: (
                isFunction(render)
                    ? render(rowData[dataIndex], rowData)
                    : rowData[dataIndex]
            ),
        }

        onEdit(cellEditData);
    }

    const valueToRender = (
        isFunction(mobileRender)
            ? mobileRender(rowData[dataIndex], rowData)
            : isFunction(render)
                ? render(rowData[dataIndex], rowData)
                : rowData[dataIndex]
    )

    return (
        <div className="rt--flex rt--align-center rt--table-item-col-inner">
            <span>
                <span className="rt--title rt--font-normal rt--font-bold">
                    {/*<Tooltip*/}
                    {/*    title={valueToRender}*/}
                    {/*    trigger={["hover", "click"]}*/}
                    {/*    placement="bottomLeft"*/}
                    {/*    enableMobile={true}*/}
                    {/*>*/}
                        {valueToRender}
                    {/*</Tooltip>*/}
                </span>
            </span>

            {
                Boolean(editable) && !(isFunction(disableEdit) && disableEdit(rowData[dataIndex], rowData)) && (
                    <i
                        className="icon-edit rt--font-prebigest rt--ml-2 rt--mr-2"
                        onClick={handleEditIconInternalClick}
                    />
                )
            }
        </div>
    )
}

/** TableColumnContent propTypes
 * PropTypes
*/
TableColumnContent.propTypes = {
    onEdit: PropTypes.func,
    rowData: PropTypes.object,
    columnData: PropTypes.object,
}

export default TableColumnContent
