import i18n from "translations/config";

import {
    PAYMENT_METHODS,
    WIDGET_TRANSACTION_TYPE,
} from "constants/project.constants";
import { TRANSACTION_STATE } from "constants/transaction.constants";

export const getTableColumns = additionalProps => {
    const {
        dateService
    } = additionalProps;
    return [
        {
            title: "backoffice.payments.TransactionID",
            dataIndex: "walletTransactionId",
            copy: true,
            alwaysVisible: true,
            render: (value) => value || "-",
        },
        {
            title: "backoffice.payments.id",
            dataIndex: "transactionId",
            copy: true,
        },
        {
            title: "backoffice.payments.paymentMethod",
            dataIndex: "paymentType",
            alwaysVisible: true,
            mobileLevel: 1,
            render: (paymentType) => (
                paymentType === PAYMENT_METHODS.BANK_TRANSFER
                    ? i18n.t("backoffice.payments.bankTransfer")
                    : i18n.t("backoffice.payments.epos")
            )
        },
        {
            title: "backoffice.payments.paymentLocation",
            dataIndex: "location",
        },
        {
            title: "backoffice.payments.transactionDate",
            dataIndex: "transactionTime",
            isDateTime: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.payments.playerExternalId",
            dataIndex: "playerId",
            copy: true,
            mobileLevel: 4,
        },
        {
            title: "backoffice.payments.playerUserName",
            dataIndex: "playerUserName",
            copy: true,
        },
        {
            title: "backoffice.payments.currency",
            dataIndex: "currencyCode",
            mobileLevel: 6,
        },
        {
            title: "backoffice.payments.Amount",
            dataIndex: "amount",
            isNumeric: true,
            sorting: true,
            mobileLevel: 5,
        },
        {
            title: "backoffice.payments.transactionState",
            dataIndex: "state",
            sorting: true,
            render: (state) => (
                state === TRANSACTION_STATE.APPROVED
                    ? i18n.t("backoffice.payments.approved")
                    : i18n.t("backoffice.payments.rejected")
            ),
            mobileLevel: 3
        },
        {
            title: "backoffice.payments.agentUserName",
            dataIndex: "agentUserName",
        },
        {
            title: "backoffice.payments.operatedBy",
            dataIndex: "userName",
        },
        {
            title: "backoffice.payments.creationDate",
            dataIndex: "createdAt",
            isDateTime: true,
        },
        {
            title: "backoffice.payments.processingTime",
            dataIndex: "processingTime",
            render: (_, record) => (
                record.transactionType === WIDGET_TRANSACTION_TYPE.DEPOSIT &&
                    record.paymentType === PAYMENT_METHODS.EPOS
                    ? "0"
                    : dateService.humanizeDuration(dateService.getDifference(record.transactionTime, record.createdAt, "milliseconds"))
            ),
            disableExport: true
        },
        {
            title: "backoffice.payments.notes",
            dataIndex: "notes",
            small: true,
        },
    ];
}

export const expandColumns = [
    {
        title: 'backoffice.payments.transactionDate',
        dataIndex: "transactionTime",
        isDateTime: true,
        mobileLevel: 1
    },
    {
        title: 'backoffice.payments.Amount',
        dataIndex: "amount",
        isNumeric: true,
        mobileLevel: 2,
    },
    {
        title: 'backoffice.payments.transactionState',
        dataIndex: "state",
        mobileLevel: 3,
        render: value => (
            value === TRANSACTION_STATE.APPROVED
                ? i18n.t("backoffice.payments.approved")
                : i18n.t("backoffice.payments.rejected")
        ),
    },
    {
        title: 'backoffice.payments.operatedBy',
        dataIndex: "userName",
        mobileLevel: 4,
    },
    {
        title: 'backoffice.payments.notes',
        dataIndex: "notes",
        small: true,
        mobileLevel: 5,
    }
]
