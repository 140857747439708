import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { setWalletActionBefore, setWalletActionFinished } from './wallet.action';

import { 
    SET_AGENT_WALLETS
} from "../../../actionTypes";

const setAgentWallets = wallets => ({
    type: SET_AGENT_WALLETS,
    payload: { wallets }
})

export const getAgentWallets = (id, ownerType)  => {
    return dispatch => {
        dispatch(setWalletActionBefore());
        return axios({
            url: ApiUrls.GET_AGENT_WALLETS,
            method: Methods.GET,
            params: { id, ownerType }
        })
        .then(({data : {value: wallets}}) => {
            dispatch(setAgentWallets(wallets.map(w => ({...w, id: id}))))
            dispatch(setWalletActionFinished());
        })
        .catch((ex) => {
            dispatch(setWalletActionFinished());
        })
    }
}
