export const PLAYER_STATE = {
    ACTIVE: 1 << 0,
    BLOCKED: 1 << 1,
    PARTIALY_BLOCKED: 1 << 2,
    UNKNOWN: 1 << 3
}

export const PLAYER_FIND_BY = {
    ID: 1,
    USERNAME: 2,
    EMAIL: 3,
    MOBILE: 4
}

export const PLAYERS_LITE_DEFAULT_COLUMNS = ['balance'];

export const PLAYER_TRANSACTION_TYPE = {
    BET: 1 << 0,
    WON: 1 << 1,
    DEPOSIT: 1 << 2,
    WITHDRAWAL: 1 << 3,
    OTHER: 1 << 4
}

export const PLAYER_TRANSACTION_DIRECTION = {
    DEBIT: 1 << 0,
    CREDIT: 1 << 1
}
