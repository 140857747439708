import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';
import Main from './sections/main';
import SMS from './sections/sms';

import { getHashValue } from "utils/common";

import { AGENT_TYPE } from "constants/agent.constants"
import { getProjectConfigs } from 'store/actions/dashboard/projects/configs.action';
import { connect } from 'react-redux';

import projectConfigsType from "types/project/configs.type";

/** Project Edit Page AgentSystem Tab Component */
const Configs = ({
	onTabChange,
	tabId,
	configs,
	getProjectConfigs
}) => {
	const { t } = useTranslation();

	//  const { search } = useLocation();

	const isActive = tabId === getHashValue('tab');

	useEffect(() => {
		getProjectConfigs();
	}, [])

	//  const commissionPlanId = (new URLSearchParams(search)).get("commissionPlanId");

	const items = [
		{
			title: t("backoffice.projects.main"),
			component: <Main />,
		},
		{
			title: t("backoffice.projects.SMS"),
			component: <SMS />,
			disabled: !configs.sendBetViaSMS

		}
	]

	return (
		<Tabs.SubTabs
			items={items}
			isParentActive={isActive}
			onTabChange={onTabChange}
		/>
	)

}

Configs.propTypes = {
	onTabChange: PropTypes.func,
	tabId: PropTypes.string,
	configs: projectConfigsType,
	getProjectConfigs: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
	{
		getProjectConfigs: () => {
			dispatch(getProjectConfigs())
		},
	}
)

const mapStateToProps = state => {
	return {
		configs: state.projects.edit.configs.main,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Configs);
