import React from "react";
import i18n from "translations/config";

import Status from "components/common/status";

import {
    STATUS_SIZE,
    STATUS_TOOLTIP_PLACEMENT,
    STATUS_TYPES
} from "constants/status.constants";

export const tableColumns = [
    {
        title: "backoffice.betshopmanagers.firstName",
        dataIndex: "firstName",
        mobileLevel: 2,
        sorter: true,
        render: (firstName, record) => (
            <Status
                status={record.isOnline ? "online" : "offline"}
                highlighted={false}
                showIndication={true}
                content={firstName}
                size={STATUS_SIZE.SMALL}
                type={STATUS_TYPES.ONLINE_OFFLINE}
                tooltipOptions={{
                    placement: STATUS_TOOLTIP_PLACEMENT.INDICATION,
                    title: (
                        record.isOnline
                            ? i18n.t("backoffice.common.online")
                            : i18n.t("backoffice.common.offline")
                    )
                }}
            />
        ),
    },
    {
        title: "backoffice.betshopmanagers.lastName",
        dataIndex: "lastName",
        sorter: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.betshopmanagers.username",
        dataIndex: "userName",
        sorter: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.betshopmanagers.betshop",
        dataIndex: "betShops",
        sorter: true,
        mobileLevel: 5,
        multi: true,
        multiMapper: (value) => (value ? Object.values(value) : []),
    },
    {
        title: "backoffice.betshopmanagers.status",
        dataIndex: "state",
        sorter: true,
        render: (userState) => {
            return (
                <Status
                    status={userState}
                    type={STATUS_TYPES.USER}
                />
            )
        },
    },
    {
        title: "backoffice.betshopmanagers.id",
        dataIndex: "longId",
        sorter: true,
        copy: true,
        alwaysVisible: true,
    },
    {
        title: "backoffice.betshopmanagers.createdAt",
        dataIndex: "createdAt",
        isDateTime: true,
        sorter: true,
    },
    {
        title: "backoffice.betshopmanagers.lastUpdatedAt",
        dataIndex: "modifiedAt",
        isDateTime: true,
        sorter: true,
        mobileLevel: 4,
    },
    {
        title: "backoffice.betshopmanagers.lastUpdateBy",
        dataIndex: "modifiedBy",
        sorter: true,
    },
    {
        title: "backoffice.betshopmanagers.lastLoginAt",
        dataIndex: "lastLogin",
        sorter: true,
        isDateTime: true,
    },
];
