import { useEffect, useState } from 'react';

import { getFinancialLineChartData } from '../api';

import { FINANCIAL_DASHBOARD_FILTER_NAMES } from '../constants';

const useFinancialLineChart = (dataType, filters, updateLastUpdatedDate, isActive) => {
    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: [],
    })

    useEffect(() => {
        // debugger;
        if (!filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]) {
            return;
        }

        if (isActive !== undefined && !isActive) {
            return;
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getFinancialLineChartData({
            filterType: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE],
            currencyCode: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY],
            timePeriod: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD],
            dataType,
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;

                updateLastUpdatedDate(payload.lastUpdateAt)
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        chartData: payload.data,
                        isLoading: false,
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [dataType, filters, updateLastUpdatedDate, isActive]);

    return chartState;
}

export default useFinancialLineChart;
