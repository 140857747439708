import React from 'react';

import TransferPopup from './transferPopup';

import useTransfer from "../hooks/useTransfer";

/** Transfer Popup Component */
const Transfer = () => {

    const { opened, transferData} = useTransfer();

    if(!opened || transferData === null){
        return null;
    }

    return (
        <TransferPopup />
    )
}

export default Transfer;