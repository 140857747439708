/**
 * IPv6/IPv4 address representation.
 *
 * It should not be instantiated directly by library consumers.
 */
 function Addr() {
    this._fields = [0, 0, 0, 0, 0, 0, 0, 0];
    this._attrs = {};
}

const _isAddr = addr => {
    if (typeof (addr) === 'object') {
        /* It must resemble an Addr object */
        if (Array.isArray(addr._fields) && typeof (addr._attrs) === 'object') {
            return true;
        }
    }
    return false;
}

const _ipv4Mapped = input => {
    var comp = [0, 0, 0, 0, 0, 0xffff];
    var i;
    for (i = 0; i < 6; i++) {
        if (input[i] != comp[i])
            return false;
    }
    return true;
}

const parseString = input => {
    input = input.toLowerCase();
    var result = new Addr();

    var ip6Fields = []; // hold unparsed hex fields
    var ip4Fields = []; // hold unparsed decimal fields
    var expIndex = null; // field index of '::' delimiter
    var value = '';  // accumulate unparsed hex/dec field
    var i, c;

    /*
     * No valid ipv6 is longer than 39 characters.
     * An extra character of leeway is there to tolerate some :: funny business.
     */
    if (input.length > 40) {
        throw new Error('Input too long');
    }

    for (i = 0; i < input.length; i++) {
        c = input[i];
        if (c === ':') {
            if ((i + 1) < input.length && input[i + 1] === ':') {
                /*
                 * Variable length '::' delimiter.
                 * Multiples would be ambiguous
                 */
                if (expIndex !== null) {
                    throw new Error('Multiple :: delimiters', i);
                }

                /*
                 * The value buffer can be empty for cases where the '::' delimiter is
                 * the first portion of the address.
                 */
                if (value !== '') {
                    ip6Fields.push(value);
                    value = '';
                }
                expIndex = ip6Fields.length;
                i++;
            } else {
                /*
                 * Standard ':' delimiter
                 * The value buffer cannot be empty since that would imply an illegal
                 * pattern such as ':::' or ':.'.
                 */
                if (value === '') {
                    throw new Error('illegal delimiter', i);
                }
                ip6Fields.push(value);
                value = '';
            }
        } else if (c === '.') {
            /*
             * Handle dotted quad notation for ipv4 and ipv4-mapped addresses.
             */
            ip4Fields.push(value);
            value = '';
        } else {
            value = value + c;
        }
    }
    /* Handle the last stashed value */
    if (value !== '') {
        if (ip4Fields.length !== 0) {
            ip4Fields.push(value);
        } else {
            ip6Fields.push(value);
        }
        value = '';
    } else {
        /* With no stashed value, the address must end with '::'. */
        if (expIndex !== ip6Fields.length || ip4Fields.length > 0) {
            throw new Error('Cannot end with delimiter besides ::');
        }
    }

    /* With values collected, ensure we don't have too many/few */
    if (ip4Fields.length === 0) {
        if (ip6Fields.length > 8) {
            throw new Error(input, 'Too many fields');
        } else if (ip6Fields.length < 8 && expIndex === null) {
            throw new Error(input, 'Too few fields');
        }
    } else {
        if (ip4Fields.length !== 4) {
            throw new Error(input, 'IPv4 portion must have 4 fields');
        }
        /* If this is a bare IP address, implicitly convert to IPv4 mapped */
        if (ip6Fields.length === 0 && expIndex === null) {
            result._attrs.ipv4Bare = true;
            ip6Fields = ['ffff'];
            expIndex = 0;
        }

        if (ip6Fields.length > 6) {
            throw new Error('Too many fields');
        } else if (ip6Fields.length < 6 && expIndex === null) {
            throw new Error('Too few fields');
        }
    }

    /* Parse integer values */
    var field, num;
    for (i = 0; i < ip6Fields.length; i++) {
        field = ip6Fields[i];
        num = parseInt(field, 16);
        if (num instanceof Error || num < 0 || num > 65535) {
            throw new Error('Invalid field value: ' + field);
        }
        ip6Fields[i] = num;
    }
    for (i = 0; i < ip4Fields.length; i++) {
        field = ip4Fields[i];
        num = parseInt(field, 10);
        if (num instanceof Error || num < 0 || num > 255) {
            throw new Error('Invalid field value: ' + field);
        }
        ip4Fields[i] = num;
    }

    /* Collapse IPv4 portion, if necessary */
    if (ip4Fields.length !== 0) {
        ip6Fields.push((ip4Fields[0] * 256) + ip4Fields[1]);
        ip6Fields.push((ip4Fields[2] * 256) + ip4Fields[3]);
    }

    /* Expand '::' delimiter into implied 0s */
    if (ip6Fields.length < 8 && expIndex !== null) {
        var filler = [];
        for (i = 0; i < (8 - ip6Fields.length); i++) {
            filler.push(0);
        }
        ip6Fields = Array.prototype.concat(
            ip6Fields.slice(0, expIndex),
            filler,
            ip6Fields.slice(expIndex)
        );
    }

    /*
     * If dotted-quad notation was used, ensure the input was either a bare ipv4
     * address or a valid ipv4-mapped address.
     */
    if (ip4Fields.length !== 0) {
        if (!_ipv4Mapped(ip6Fields)) {
            throw new Error('invalid dotted-quad notation');
        } else {
            result._attrs.ipv4Mapped = true;
        }
    }

    result._fields = ip6Fields;

    return result;
}

const parseLong = input => {
    if (input !== Math.floor(input)) {
        throw new Error('Value must be integer');
    }
    if (input < 0 || input > 0xffffffff) {
        throw new Error('Value must be 32 bit');
    }
    var out = new Addr();
    out._fields[7] = input & 0xffff;
    out._fields[6] = (input >>> 16);
    /* this is ipv4-mapped */
    out._fields[5] = 0xffff;
    out._attrs.ipv4Bare = true;
    out._attrs.ipv4Mapped = true;
    return out;
}

const ip6addrParse = input => {
    if (typeof (input) === 'string') {
        return parseString(input);
    } else if (typeof (input) === 'number') {
        return parseLong(input);
    } else if (typeof (input) === 'object' && _isAddr(input)) {
        return input;
    } else {
        throw new Error('Invalid argument: only string|number allowed');
    }
}

const _toAddr = input => {
    if (typeof (input) === 'string') {
        return ip6addrParse(input);
    } else if (_isAddr(input)) {
        return input;
    } else {
        throw new Error('Invalid argument: Addr or parsable string expected');
    }
}

export const ip6addrCompare = (a, b) => {
    const aAddr = _toAddr(a);
    const bAddr = _toAddr(b);

    var i;
    for (i = 0; i < 8; i++) {
        if (aAddr._fields[i] < bAddr._fields[i]) {
            return -1;
        } else if (aAddr._fields[i] > bAddr._fields[i]) {
            return 1;
        }
    }
    return 0;
}