import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Switch, Spin, Checkbox } from 'antd';

import Select from 'components/common/select';
import Input from 'components/common/input';

import TabFormDashboardLayout from "components/layouts/tab/form";
import CashierActionsComponent from '../../cashier-actions.component';

import { USER_STATE } from "constants/user.constants";
import { EMAIL_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX } from "constants/regex.constants";

import { getCashierGeneralInfo, saveCashierGeneralInfo } from "store/actions/dashboard/retail/cashiers/general.action";
import { getCashierAvailableBetshops } from 'store/actions/dashboard/retail/cashiers/cashiers.action';

import cashierGeneralInfoType from "types/cashier/generalInfo.type";

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Cashier Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    getCashierGeneralInfo,
    saveCashierGeneralInfo,
    isSaving,
    isLoading,
    generalInfo,
    availableBetshops,
    isAvailableBetshopsLoading,
    getCashierAvailableBetshops,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Load cashier general info */
    useEffect(() => {
        getCashierGeneralInfo(searchParams.id)
    }, [])

    /** Load cashier available betshops */
    useEffect(() => {
        getCashierAvailableBetshops(searchParams.id)
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...generalInfo
        })
    }, [generalInfo])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            firstName: generalInfo.firstName,
            lastName: generalInfo.lastName,
            email: generalInfo.email,
            betShopIds: Object.keys(generalInfo.betShops || {}),
            passwordExpirationEnabled: generalInfo.passwordExpirationEnabled
        });
    }, [generalInfo])

    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveCashierGeneralInfo({
                    ...data,
                    id: searchParams.id,
                    betShopIds: data.betShopIds,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    passwordExpirationEnabled: data.passwordExpirationEnabled
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            actions={<CashierActionsComponent />}
            id={generalInfo.id}
            longId={generalInfo.longId}
        >

            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        firstName: generalInfo.firstName,
                        lastName: generalInfo.lastName,
                        email: generalInfo.email,
                        betShopIds: Object.keys(generalInfo.betShops || {}),
                        passwordExpirationEnabled: generalInfo.passwordExpirationEnabled,
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({
                        firstName: generalInfo.firstName,
                        lastName: generalInfo.lastName,
                        email: generalInfo.email,
                        betShopIds: Object.keys(generalInfo.betShops || {}),
                        passwordExpirationEnabled: generalInfo.passwordExpirationEnabled
                    }, {
                        firstName: formValues.firstName,
                        lastName: formValues.lastName,
                        email: formValues.email,
                        betShopIds: formValues.betShopIds,
                        passwordExpirationEnabled: formValues.passwordExpirationEnabled
                    }
                    ))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.cashiers.username')} *`}
                                className={'rt--general-form-item rt--form-item-disabled'}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.username')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.username')}`}
                                    value={generalInfo.userName}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.cashiers.email')}
                                name="email"
                                rules={[
                                    { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                                    { max: 50, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.email')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.email')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.cashiers.firstName')} *`}
                                name="firstName"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.firstName')}`}
                            >
                                <Input
                                    maxLength={48}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.firstName')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.cashiers.lastName')} *`}
                                name="lastName"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst={true}
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.lastName')}`}
                            >
                                <Input
                                    maxLength={48}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.cashiers.lastName')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.cashiers.betshop')}
                                name="betShopIds"
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                className={!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={
                                        Object.keys(availableBetshops).map(item => (
                                            { value: item, text: availableBetshops[item] }
                                        ))
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.cashiers.betshop')}`}
                                    loading={isAvailableBetshopsLoading}
                                    isMultiple={true}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY })}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name="passwordExpirationEnabled"
                                    valuePropName="checked"
                                    className={'rt--form-item-without-margin' + (!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                >
                                    <Switch
                                        disabled={
                                            !hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_GENERALINFO, action: PERMISSION_ACTION.MODIFY }) ||
                                            generalInfo.state === USER_STATE.IN_PROGRESS
                                        }
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.cashiers.forcePasswordChange')}</label>
                            </div>
                        </Col>
                        
                    </Row>

                </Form>


            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux action to get cashier General info */
    getCashierGeneralInfo: PropTypes.func,
    /** Redux action to save cashier General info */
    saveCashierGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing cashier  */
    generalInfo: cashierGeneralInfoType,
    /** Redux action to get available betshops of cashier */
    getCashierAvailableBetshops: PropTypes.func,
    /** Redux state property, represents the object of available betshops of cashier */
    availableBetshops: PropTypes.object,
    /** Redux state property, is true when loading available betshops of cashier */
    isAvailableBetshopsLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getCashierGeneralInfo: id => {
            dispatch(getCashierGeneralInfo(id));
        },

        saveCashierGeneralInfo: data => {
            dispatch(saveCashierGeneralInfo(data));
        },

        getCashierAvailableBetshops: id => {
            dispatch(getCashierAvailableBetshops(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.cashiers.edit.general,
        isSaving: state.cashiers.isSaving,
        isLoading: state.cashiers.isLoading,
        availableBetshops: state.cashiers.availableBetshops.availableBetshops,
        isAvailableCurrenciesLoading: state.cashiers.availableBetshops.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)