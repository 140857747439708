import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Checkbox, Spin } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";
import { getCashierAdditionalAccess, saveCashierAdditionalAccess } from "store/actions/dashboard/retail/cashiers/additionalAccess.action";

import additionalAccessType from "types/cashier/additionalAccess.type";

import { binaryToFlags, flagsToBinary } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { BETSHOP_ACCESS_TYPE } from 'constants/betshop.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';


/** Cashier Edit Page Additional Access Tab Component */
const AdditionalAccessesComponent = ({
    getCashierAdditionalAccess,
    saveCashierAdditionalAccess,
    isSaving,
    isLoading,
    additionalAccess,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const allowedAccessTypes = binaryToFlags(Object.values(BETSHOP_ACCESS_TYPE), additionalAccess.allowedAdditionalAccessType)

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            additionalAccess: binaryToFlags(Object.values(BETSHOP_ACCESS_TYPE), additionalAccess.additionalAccess)
        })
    }, [additionalAccess])

    useEffect(() => {
        getCashierAdditionalAccess(searchParams.id);
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf AdditionalAccessesComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveCashierAdditionalAccess({
                    id: searchParams.id,
                    additionalAccess: flagsToBinary(data.additionalAccess)
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) =>
                        setIsFormTouched(
                            isFormChanged(
                                {
                                    additionalAccess: formValues.additionalAccess
                                },
                                {
                                    additionalAccess: binaryToFlags(Object.values(BETSHOP_ACCESS_TYPE), additionalAccess.additionalAccess)
                                }
                            )
                        )
                    }
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name="additionalAccess"
                                className='rt--form-item-inline'
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            >
                                <Checkbox.Group
                                    className='rt--checkbox-group-list'
                                    options={[
                                        {
                                            label: t('backoffice.betshops.allowPlayerRegistration'),
                                            value: BETSHOP_ACCESS_TYPE.PLAYER_CREATE,
                                            disabled: !allowedAccessTypes.includes(BETSHOP_ACCESS_TYPE.PLAYER_CREATE)
                                        },
                                        {
                                            label: t('backoffice.betshops.allowPayment'),
                                            value: BETSHOP_ACCESS_TYPE.PAYMENT,
                                            disabled: !allowedAccessTypes.includes(BETSHOP_ACCESS_TYPE.PAYMENT)
                                        },
                                        {
                                            label: t('backoffice.betshops.allowBetPlacement'),
                                            value: BETSHOP_ACCESS_TYPE.BETS,
                                            disabled: !allowedAccessTypes.includes(BETSHOP_ACCESS_TYPE.BETS)
                                        }
                                    ]}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.CASHIER_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** AdditionalAccessesComponent propTypes
    * PropTypes
*/
AdditionalAccessesComponent.propTypes = {
    /** Redux state property, is true when additional accesses is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when additional accesses is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the a additional accesses of current editing cashier  */
    additionalAccess: additionalAccessType,
    /** Redux action to save cashier additional access */
    saveCashierAdditionalAccess: PropTypes.func,
    /** Redux action to get cashier additional access */
    getCashierAdditionalAccess: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        saveCashierAdditionalAccess: data => {
            dispatch(saveCashierAdditionalAccess(data));
        },

        getCashierAdditionalAccess: id => {
            dispatch(getCashierAdditionalAccess(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        additionalAccess: state.cashiers.edit.additionalAccess,
        isSaving: state.cashiers.isSaving,
        isLoading: state.cashiers.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalAccessesComponent)