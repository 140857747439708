import React from "react";

import ProjectsEditComponent from "pages/projects/edit/project-edit.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const ProjectsEditRoute = () => {
    return <ProjectsEditComponent />;
};

export default withPermission(
    withAuth(ProjectsEditRoute),
    [
        {
            resource: PERMISSION_RESOURCE.PROJECT_CURRENCIES,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_AGENT_SYSTEM,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_GENERALINFO,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_WALLETS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_LIMITS,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_PAYMENT,
            action: PERMISSION_ACTION.VIEW,
        },
        {
            resource: PERMISSION_RESOURCE.PROJECT_REGULATIONS,
            action: PERMISSION_ACTION.VIEW,
        },
    ],
    Paths.DEFAULT
);
