import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Form, Button, Result } from 'antd';

import PasswordRules from "components/common/passwordRules";
import Input from 'components/common/input';
import TwoFactorComponent from "../login/twoFactor";


import { getPasswordSettings } from 'store/actions/auth/passwordSettings.action';
import { resetPassword, setPassword } from 'store/actions/auth/resetPassword.action';

import { validatePassword } from "utils/password";
import { getEnvironment } from 'utils/common';
import { loginUser } from 'utils/auth';
import sessionStorageUtils from 'utils/sessionStorage';

import Paths from 'constants/path.constants';
import { TOKEN_TYPE } from 'constants/auth.constants';

import companyPasswordSettingsType from "types/company/passwordSettings.type";


/** Reset Password Page Component */

const ResetPasswordComponent = ({
    resetPassword,
    setPassword,
    getPasswordSettings,
    passwordSettings,
    isLoading,
    isReseting,
    lockData,
}) => {

    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    const navigate = useNavigate();
    const { search } = useLocation();

    const [twoFactor, setTwoFactor] = useState(null);

    /** Load password settings */
    useEffect(() => {
        const token = (new URLSearchParams(search)).get("t");
        const type = (new URLSearchParams(search)).get("type") || "forgotPassword";
        if (token) {
            getPasswordSettings(token, type);
        }
    }, [])

     /** Fires after success login
       * @function
       * @param {Object} data - The data returned from server after login call
       * @memberOf ResetPasswordComponent
   */
    const authenticateCB = data => {
        const userName = (new URLSearchParams(search)).get("userName");
        if (data.tokenType === TOKEN_TYPE.NONE) {
            loginUser(data);
            setTimeout(() => {
                location.reload();
            }, 0)
        } else if (data.tokenType === TOKEN_TYPE.QR || data.tokenType === TOKEN_TYPE.TOKEN) {
            setTwoFactor({
                tokenType: data.tokenType,
                token: data.token,
                userName: userName
            });
        } else if (data.tokenType === TOKEN_TYPE.PASSWORD_EXPIRED) {
            navigate(`${Paths.RESET_PASSWORD}?t=${data.token}&type=forceChange&userName=${userName}&et=${getEnvironment()}`);
        }
    }


    /** Fires when form submitted
       * @function
       * @memberOf ResetPasswordComponent
   */
    const handleForm = () => {
        const token = (new URLSearchParams(search)).get("t");
        const type = (new URLSearchParams(search)).get("type") || "forgotPassword";
        validateFields()
            .then(({ newPassword, confirmPassword }) => {
                if (type === "forgotPassword") {
                    resetPassword(token, newPassword, confirmPassword, () => {
                        navigate(`${Paths.LOGIN}?et=${getEnvironment()}`);
                    });
                } else {
                    setPassword(token, newPassword, confirmPassword, data => {
                        authenticateCB(data)
                    });
                }
            }).catch(() => { })
    }

    useEffect(() => {
        if (lockData) {
            navigate(`${Paths.LOGIN}?et=${getEnvironment()}`);
        }
    }, [lockData])

    return (new URLSearchParams(search)).get("t") ? (
        <div className="rt--auth-form">
            <Form
                form={formInstance}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    newPassword: "",
                    confirmPassword: ""
                }}
            >
                <h4 className="rt--auth-form-title rt--pb-40">{t('backoffice.authentication.setNewPassword')}</h4>

                <Form.Item
                    label={`${t('backoffice.authentication.password')} *`}
                    name="newPassword"
                    validateFirst
                    rules={[
                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                return validatePassword(value, passwordSettings);
                            }
                        })
                    ]}
                    className='rt--general-form-item'
                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.authentication.password')}`}
                >
                    <Input
                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.authentication.password')}`}
                        type='Password'
                        onChange={() => {
                            setTimeout(() => {
                                if (getFieldValue('confirmPassword') !== "")
                                    validateFields(['confirmPassword'])
                            }, 0)
                        }} />

                </Form.Item>
                <Form.Item
                    label={`${t('backoffice.authentication.confirmPassword')} *`}
                    name="confirmPassword"
                    validateFirst
                    rules={[
                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (value !== getFieldValue("newPassword")) {
                                    return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                }
                                return Promise.resolve();
                            }
                        })
                    ]}
                    className='rt--general-form-item'
                    data-placeholder={`${t('backoffice.authentication.confirmPassword')}`}
                >

                    <Input 
                        type='Password'
                        placeholder={`${t('backoffice.authentication.confirmPassword')}`}
                        onPaste={e => e.preventDefault()}
                        onContextMenu={e => e.preventDefault()}
                        onCopy={e => e.preventDefault()} 
                    />
                </Form.Item>
                {
                    !isLoading && Object.keys(passwordSettings).length > 0 ?
                        <Form.Item>
                            <div className='rt--mb-40'>
                                <PasswordRules passwordSettings={passwordSettings} />
                            </div>
                        </Form.Item> :
                        null
                }
                <Form.Item>
                    <Button loading={isReseting} type="primary" htmlType="submit" className="button" onClick={handleForm}>
                        <span>{t('backoffice.common.save')}</span>
                    </Button>
                </Form.Item>
            </Form>

            {
                twoFactor && (
                    <TwoFactorComponent
                        tokenType={twoFactor.tokenType}
                        token={twoFactor.token}
                        userName={twoFactor.userName}
                        onClose={() => {
                            setTwoFactor(null)
                            navigate(`${Paths.LOGIN}?et=${getEnvironment()}`, { replace: true });
                        }}
                    />
                )
            }
        </div>
    ) : <Result status="warning" title={t('backoffice.authentication.noTokenFound')} />
}

/** ResetPasswordComponent propTypes
    * PropTypes
*/
ResetPasswordComponent.propTypes = {
    /** Redux state property, is true when reset password request is in process */
    isReseting: PropTypes.bool,
    /** Redux state property, is true when loading password settings */
    isLoading: PropTypes.bool,
    /** Redux action to get password settings */
    getPasswordSettings: PropTypes.func,
    /** Redux action to reset password */
    resetPassword: PropTypes.func,
    /** Redux action to set password */
    setPassword: PropTypes.func,
    /** Redux state property, the password settings */
    passwordSettings: companyPasswordSettingsType,
    /** Redux state property the lock data */
    lockData: PropTypes.shape({
        resultCode: PropTypes.number,
        expireTime: PropTypes.string
    })
}

const mapDispatchToProps = dispatch => (
    {
        getPasswordSettings: (token, type) => {
            dispatch(getPasswordSettings(token, type));
        },
        resetPassword: (token, newPassword, confirmPassword, onSuccess) => {
            dispatch(resetPassword(token, newPassword, confirmPassword, onSuccess))
        },
        setPassword: (token, newPassword, confirmPassword, onSuccess) => {
            dispatch(setPassword(token, newPassword, confirmPassword, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isReseting: state.auth.resetPassword.isReseting,
        isLoading: state.auth.passwordSettings.isLoading,
        passwordSettings: state.auth.passwordSettings.passwordSettings,
        lockData: state.auth.lockData
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ResetPasswordComponent
);