export const getTableColumns = (additionalProps) => {
    const { 
        franchisingMode,
        formatNumber,
    } = additionalProps;

    return [
        {
            title: (
                franchisingMode
                    ? "backoffice.commissioncalculations.betshopOwnerUsername"
                    : "backoffice.commissioncalculations.agentUsername"
            ),
            dataIndex: "agentUserName",
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: (
                franchisingMode
                    ? "backoffice.commissioncalculations.betshopOwnerId"
                    : "backoffice.commissioncalculations.agentId"
            ),
            dataIndex: "agentId",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.commissioncalculations.sourceOfCalculation",
            dataIndex: "sourceAmount",
            isNumeric: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.commissioncalculations.negativeSourceAmount",
            dataIndex: "negativeSourceAmount",
            render: value => formatNumber(value) || "-",
        },
    
        {
            title: "backoffice.commissioncalculations.calculatedAmount",
            dataIndex: "amount",
            isNumeric: true,
            mobileLevel: 4,
        },
        {
            title: "backoffice.commissioncalculations.carryOverAmount",
            dataIndex: "carryOverAmount",
            render: value => formatNumber(value) || "-",
            mobileLevel: 5,
        },
        {
            title: "backoffice.commissioncalculations.netProfitAmount",
            dataIndex: "netProfitAmount",
            isNumeric: true,
        },
        {
            title: "backoffice.commissioncalculations.currency",
            dataIndex: "currencyCode",
            mobileLevel: 6,
        }
    ]
}