import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Tabs as AntTabs } from 'antd';

import TabDashboardLayout from "components/layouts/tab";
import General from "./tabs/general";
import TopUsers from "./tabs/topUsers";
import Registrations from './tabs/registrations';
import LastUpdated from '../components/lastUpdated';

import useDate from 'hooks/useDate';
import useProjectType from 'hooks/useProjectType';

import { isMobile, getHashValue, updateLocationHash } from 'utils/common'

import PATHS from "constants/path.constants";
import { OPERATIONAL_TAB_NAMES } from '../constants';

const getActiveTab = () => {
    const tabKey = getHashValue("tab");

    return tabKey ? tabKey : OPERATIONAL_TAB_NAMES.GENERAL
}

const OperationalDashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { dateService } = useDate();
    const { hasRetail, hasStandartPayment } = useProjectType()

    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);

    const activeTabRef = useRef(getActiveTab());

    /** Leave from page if globalPayment type is not standart */
    useEffect(() => {
        if (!hasStandartPayment) {
            navigate(PATHS.DEFAULT);
        }
    }, [hasStandartPayment])

    const handleTabChange = (tab) => {
        updateLocationHash("tab=" + tab);
    }

    const updateLastUpdatedDate = useCallback((newDate) => {
        setLastUpdatedDate((prevState) => {
            if (lastUpdatedDate === null || dateService.isAfter(newDate, lastUpdatedDate)) {
                return newDate;
            }

            return prevState;
        })
    }, [])

    const tabs = [
        {
            key: OPERATIONAL_TAB_NAMES.GENERAL,
            label: <span>{t("backoffice.dashboard.general")}</span>,
            children: <General hasRetail={hasRetail} updateLastUpdatedDate={updateLastUpdatedDate} />
        },
        ...(isMobile()
                ? [
                    {
                        key: OPERATIONAL_TAB_NAMES.REGISTRATIONS,
                        label: <span>{t("backoffice.dashboard.registrations")}</span>,
                        children: <Registrations hasRetail={hasRetail} updateLastUpdatedDate={updateLastUpdatedDate} />
                    }
                ]
                : []
        ),
        {
            key: OPERATIONAL_TAB_NAMES.TOP_USERS,
            label: <span>{t("backoffice.dashboard.topUsers")}</span>,
            children: <TopUsers hasRetail={hasRetail} updateLastUpdatedDate={updateLastUpdatedDate} />
        }
    ]

    return (
        <TabDashboardLayout
            header={
                isMobile() ? { extra: <LastUpdated lastUpdatedDate={lastUpdatedDate} /> }: undefined
            }
            breadcrumbs={{
                items: [{ title: t('backoffice.menu.operational') }]
            }}
        >
            <AntTabs
                animated={false}
                defaultActiveKey={activeTabRef.current}
                destroyInactiveTabPane={true}
                onChange={handleTabChange}
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={
                    !isMobile() ? <LastUpdated lastUpdatedDate={lastUpdatedDate} /> : undefined
                }
                items={tabs}
            />
        </TabDashboardLayout>
    )
}

export default OperationalDashboard;
