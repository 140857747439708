import { getQueryParams } from "NEW/src/core/helpers/common/getQueryParams";

export const isMobile = () => {
	const isMobileBrowser = {
		Android: () => navigator.userAgent.match(/Android/i) !== null,
		BlackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
		iOS: () =>
			navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null ||
			(navigator.userAgent.includes("Mac") && "ontouchend" in document),
		Opera: () => navigator.userAgent.match(/Opera Mini/i) !== null,
		Windows: () =>
			navigator.userAgent.match(/IEMobile/i) !== null ||
			navigator.userAgent.match(/WPDesktop/i) !== null,
		any: () =>
			isMobileBrowser.Android() ||
			isMobileBrowser.BlackBerry() ||
			isMobileBrowser.iOS() ||
			isMobileBrowser.Opera() ||
			isMobileBrowser.Windows(),
	};
	return isMobileBrowser.any() || getQueryParams()["isMobile"] === "true";
};
