import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Checkbox, Spin } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";
import Tooltip from 'components/common/tooltip';

import { getUserAdditionalAccesses, saveUserAdditionalAccesses } from "store/actions/dashboard/userManagement/users/additionalAccesses.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import userAdditionalAccessesType from "types/user/additionalAccesses.type";
import userInfoType from 'types/profile/userInfo.type';

import LanguageUtils from 'utils/languages';

import { isRTL } from 'utils/common';

/** User Edit Page Additional Accesses Tab Component */
const AdditionalAccessesComponent = ({
    getUserAdditionalAccesses,
    saveUserAdditionalAccesses,
    isSaving,
    isLoading,
    additionalAccesses,
    userInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const currentLn = LanguageUtils.getSelectedLanguage();

    const currentUserAdditionalAccesses = userInfo?.additionalAccesses ?? {};

    useEffect(() => {
        if (searchParams.id) {
            getUserAdditionalAccesses(searchParams.id)
        }
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            allowCreate1stLevelAgents: additionalAccesses.allowCreate1stLevelAgents
        });

    }, [additionalAccesses])


    /** Fires when form submitted
       * @function
       * @memberOf AdditionalAccessesComponent
   */
    const handleForm = forceSave => {
        validateFields()
            .then(data => {

                saveUserAdditionalAccesses({
                    ...data,
                    id: searchParams.id
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Function, returns if fields are editable
       * @function
       * @returns {boolean}
       * @memberOf AdditionalAccessesComponent
    */
    const canEdit = () => hasPermission({ resource: PERMISSION_RESOURCE.ADMIN_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.MODIFY }) && searchParams.id !== userInfo.id

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit(),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        allowCreate1stLevelAgents: additionalAccesses.allowCreate1stLevelAgents
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, {
                        allowCreate1stLevelAgents: additionalAccesses.allowCreate1stLevelAgents
                    }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className='rt--checkbox-group-list'>
                                <div className="rt--flex rt--align-center">
                                    <Form.Item
                                        name="allowCreate1stLevelAgents"
                                        valuePropName="checked"
                                        className='rt--form-item-without-margin rt--form-item-inline'
                                    >
                                        <Checkbox
                                            disabled={!canEdit() || !currentUserAdditionalAccesses.allowCreate1stLevelAgents}
                                        />
                                    </Form.Item>
                                    <div className='rt--flex rt--align-center'>
                                        <span className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--pr-4'>{t('backoffice.users.allowCreate1stLevelAgents')}</span>
                                        <Tooltip
                                            title={t('backoffice.users.allowCreate1stLevelAgentsTooltip')}
                                            trigger={["hover", "click"]}
                                            placement={isRTL(currentLn) ? "bottomRight" : "bottomLeft"}
                                            enableMobile={true}
                                        >
                                            <i className='icon-info rt--font-prebigest' />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** AdditionalAccessesComponent propTypes
    * PropTypes
*/
AdditionalAccessesComponent.propTypes = {
    /** Redux action to get user Additional Accesses */
    getUserAdditionalAccesses: PropTypes.func,
    /** Redux action to save user Additional Accesses */
    saveUserAdditionalAccesses: PropTypes.func,
    /** Redux state property, is true when additional accesses is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when additional accesses is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the additional accesses of current editing user  */
    additionalAccesses: userAdditionalAccessesType,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getUserAdditionalAccesses: id => {
            dispatch(getUserAdditionalAccesses(id));
        },

        saveUserAdditionalAccesses: data => {
            dispatch(saveUserAdditionalAccesses(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        additionalAccesses: state.users.edit.additionalAccesses,
        isSaving: state.users.isSaving,
        isLoading: state.users.isLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalAccessesComponent)