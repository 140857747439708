import React, { Fragment } from 'react';

import ProfileDropdown from './profileDropdown';
import CurrenciesDropdown from './currenciesDropdown';
import CompaniesDropdown from '../companiesDropdown';
import Notifications from '../notifications';
import TransferDropdown from './transferDropdown';
import LoggedinAsAgent from './loggedinAsAgent';
import PlayerDepositSearch from '../playerDepositSearch';
import MobileLiteModeWallet from './mobileLiteModeWallet';
import CreateButton from "components/common/header/createButton";

import { isMobile } from "utils/common";
import { getUser } from 'utils/auth';
import { hasOneOfPermissions } from 'utils/permissions';

import useProjectType from 'hooks/useProjectType';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { USER_ROLE, USER_STATE } from "constants/user.constants";

/** Header component */

const Header = () => {

    const { hasEpos, hasLiteMode } = useProjectType();

    const singleCurrencyModeWallet = hasLiteMode && getUser()?.role === USER_ROLE.AGENT && isMobile();

    return (
        <Fragment>
            {
                isMobile() && <LoggedinAsAgent />
            }
            <div className='rt--header rt--flex rt--justify-between rt--align-center rt--pl-16 rt--pr-16'>

                {
                    isMobile() && (
                        <div
                            className='rt--header-menu-btn rt--flex rt--align-center'
                            onClick={() => document.body.classList.add("rt--body-open-sidebar")}
                        >
                            <i className='icon-menu' />
                        </div>
                    )
                }
                {
                    !isMobile() && <LoggedinAsAgent />
                }

                <CompaniesDropdown />

                {
                    singleCurrencyModeWallet && <MobileLiteModeWallet />
                }


                <div className='rt--header-right rt--flex rt--align-center rt--flex-equal rt--justify-end'>
                    <CreateButton />

                    {
                        !isMobile() && <PlayerDepositSearch />
                    }


                    {
                        getUser()?.role !== USER_ROLE.ACCESS_MANAGER && (
                            <CurrenciesDropdown />
                        )
                    }

                    {
                        (
                            getUser()?.role === USER_ROLE.AGENT &&
                            getUser()?.userState === USER_STATE.ACTIVE &&
                            (
                                hasEpos ||
                                hasOneOfPermissions(
                                    [
                                        { resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW },
                                        { resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW },
                                        { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW }
                                    ]
                                )
                            )
                        ) && (
                            <TransferDropdown />
                        )
                    }
                    {
                        !isMobile() && getUser()?.role !== USER_ROLE.ACCESS_MANAGER && (
                            <Notifications
                                renderButton={(
                                    unreadNotificationsCount,
                                    onClick
                                ) => (
                                    <div
                                        className='rt--header-actions rt--header-actions-content-icon rt--header-item rt--pr-16 rt--mr-16 rt--flex rt--align-center'
                                    >
                                        <div
                                            className='rt--flex rt--align-center'
                                            onClick={onClick}
                                        >
                                            <i className='icon-notification rt--font-bigest' />

                                            {unreadNotificationsCount > 0 && (
                                                <div className="rt--notifications-mark rt--flex rt--justify-center rt--align-center">
                                                    <span className='rt--title rt--font-smallest rt--font-bold'>{unreadNotificationsCount}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            />
                        )
                    }


                    <ProfileDropdown />
                </div>

            </div>
        </Fragment>
    )

}

export default Header;
