import React from 'react';
import i18n from "translations/config";

import Status from 'components/common/status';

import { PROJECT_PROVIDER_TYPE } from "constants/project.constants";
import { BET_STATE, BET_TYPE } from "constants/bet.constants";
import { STATUS_TYPES } from 'constants/status.constants';

const BET_TYPE_NAMES = {
    [BET_TYPE.SINGLE]: "single",
    [BET_TYPE.MULTI]: "multi",
}

const PROJECT_PROVIDER_TYPE_NAMES = {
    [PROJECT_PROVIDER_TYPE.SPORTBOOK]: "Sportsbook",
    [PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS]: "DGVirtuals",
}


export const getTableColumns = (additionalProps) => {
    const {
        formatAmount,
        isNonAnonymousBetsActive,
    } = additionalProps;

    return [
        {
            title: "backoffice.bets.betslipId",
            dataIndex: "id",
            copy: true,
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        isNonAnonymousBetsActive && {
            title: "backoffice.bets.playerId",
            dataIndex: "playerId",
            copy: true,
        },
        isNonAnonymousBetsActive && {
            title: "backoffice.bets.playerUserName",
            dataIndex: "playerUserName",
        },
        {
            title: 'backoffice.bets.betDate',
            dataIndex: 'betTime',
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.bets.provider",
            dataIndex: "provider",
            sorter: true,
            mobileLevel: 3,
            render: value => (
                Boolean(PROJECT_PROVIDER_TYPE_NAMES[value])
                    ? i18n.t(`backoffice.common.${PROJECT_PROVIDER_TYPE_NAMES[value]}`)
                    : ""
            ),
        },
        {
            title: "backoffice.bets.type",
            dataIndex: "betType",
            sorter: true,
            mobileLevel: 4,
            render: value => (
                Boolean(BET_TYPE_NAMES[value])
                    ? i18n.t(`backoffice.bets.${BET_TYPE_NAMES[value]}`)
                    : i18n.t("backoffice.bets.system")
            ),
        },
        {
            title: 'backoffice.bets.amount',
            dataIndex: 'amount',
            sorter: true,
            mobileLevel: 5,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime)
        },
        {
            title: 'backoffice.bets.currency',
            dataIndex: 'currencyCode',
            sorter: true,
            mobileLevel: 6,
        },
        {
            title: 'backoffice.bets.odds',
            dataIndex: 'factor',
            render: (value, record) => (
                (record.betType === BET_TYPE.SINGLE && record.bets.length > 1)
                    ? "-"
                    : Number(value).toFixed(2) + "x"
            )
        },
        {
            title: 'backoffice.bets.status',
            dataIndex: 'status',
            mobileLevel: 7,
            sorter: true,
            render: (status, record) => {
					const { paidoutTime, isCashedout } = record;

					return (
						<Status
                            status={(
                                paidoutTime
                                    ? BET_STATE.PAID_OUT
                                    : isCashedout
                                        ? BET_STATE.CASHED_OUT
                                        : status
                            )}
                            type={STATUS_TYPES.BET}
                        />
					)
				}
        },
        {
            title: 'backoffice.bets.calculatedAt',
            dataIndex: 'calculateTime',
            isDateTime: true,
            canBeIncluded: true,
        },
        {
            title: 'backoffice.bets.possibleWin',
            dataIndex: 'possibleWin',
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime)
        },
        {
            title: 'backoffice.bets.win',
            dataIndex: 'winning',
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime)
        },
        {
            title: 'backoffice.bets.placedBy',
            dataIndex: 'userName',
            alwaysVisible: true,
        },
        {
            title: 'backoffice.bets.betshopId',
            dataIndex: 'projectId',
            canBeIncluded: true,
        },
        {
            title: 'backoffice.bets.betshopName',
            dataIndex: 'projectName',
            canBeIncluded: true,
        },
        {
            title: 'backoffice.bets.paidOutAt',
            dataIndex: 'paidoutTime',
            isDateTime: true,
            canBeIncluded: true,
        },
        {
            title: 'backoffice.bets.ggr',
            dataIndex: 'ggr',
            canBeIncluded: true,
            render: value => Boolean(value) ? value : "-",
        },
    ]
}
