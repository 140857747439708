export const POPUP_SIZE = {
	SMALLEST: 360,
	SMALL: 446,
	MIDDLE: 500,
	BIG: 620,
	BIGEST: 910,
	EXTRA_BIG: 1200,
};

export const SIGNALR_CONNECTION_TYPES = {
	ADMIN: 0,
	JOB: 1,
	WIDGET: 2,
};

export const SESSION_EXPIRATION = 900;

export const SESSION_REFRESH_MODAL_DELAY = 10;
