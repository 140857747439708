import { hasPermission, hasOneOfPermissions } from 'utils/permissions';
import { binaryToFlags } from "utils/common";
import { getUser } from "utils/auth";

import { PROJECT_TYPE } from "constants/project.constants";
import { PAYMENT_METHODS } from 'constants/project.constants';

/** Check if menu item is available
   * @function
   * @returns {boolean}
*/
export const isMenuItemAvailable = ( item, projectType, projectId, paymentType, projectMode ) => {

    if(
        item.userRole && !item.userRole.includes(getUser()?.role)
    ){
        return false;
    }

    /** Check permissions */
    if (item.permission) {
        if (Array.isArray(item.permission)) {
            if (!hasOneOfPermissions(item.permission)) return false;
        } else {
            if (!hasPermission(item.permission)) return false;
        }
    }

    if (item.disabled) return false;

    /** Check project Type */
    const availableProjectTypes = binaryToFlags(Object.values(PROJECT_TYPE), projectType);

    if (item.projectType && availableProjectTypes.length > 0) {
        if (!item.projectType.some(value => availableProjectTypes.includes(value))) {
            return false;
        }
    }

    /** Check project Mode */
    if (item.projectMode && !item.projectMode.includes(projectMode)) {
        return false;
    }

    /** Check projectId */
    if (item.isProjectRequired && !projectId) {
        return false;
    }

    /** Check project Type */
    const availablePaymentTypes = binaryToFlags(Object.values(PAYMENT_METHODS), paymentType);

    /** Check Payment Type */
    if(item.paymentType){
        if (!item.paymentType.some(value => availablePaymentTypes.includes(value))) {
            return false;
        }
    }


    if(item.items && item.items.length > 0){
        if(item.items.every(i => !isMenuItemAvailable(i, projectType, projectId, paymentType, projectMode))){
            return false
        }
    }

    return true;
}
