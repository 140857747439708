//#region react
import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getPlayerBets,
    setPlayerBetsSorting,
    setPlayerBetsFilters
} from "store/actions/dashboard/players/bets.action";
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
import useDate from 'hooks/useDate';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import TotalsComponent from 'components/common/totals';
//#endregion

//#region utils
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';
import { PLAYER_BETS } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
import { SEARCH_TYPE } from 'components/common/search/constants';
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import onlineBetType from 'types/bet/onlineBet.type';
import betsTotalsType from 'types/bet/betsTotals.type';
//#endregion

const makeFiltersForExport = (filters, dateService) => {
    const exportFilters = { ...filters };
    delete exportFilters.day;
    delete exportFilters.time;

    let from = dateService.mergeDateAndTime(filters.day, filters.time[0]);
    let to = dateService.mergeDateAndTime(filters.day, filters.time[1]);

    from = dateService.toISOString(from);
    to = dateService.toISOString(to);

    exportFilters.from = from;
    exportFilters.to = to;

    return exportFilters;
}

/** Player Edit Page Bets Tab Component */
const PlayerBetsComponent = ({
    getPlayerBets,
    bets,
    total,
    totals,
    isLoading,
    setPlayerBetsSorting,
    setPlayerBetsFilters,
    sorting,
    filters
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const { formatNumber } = useFormat();

    const { dateService } = useDate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PLAYER_BETS });

    const exportFilters = makeFiltersForExport(filters, dateService)

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_ONLINE,
        action: PERMISSION_ACTION.EXPORT,
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setPlayerBetsFilters({
            ...filters,
            betId: value
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatNumber
            }
        });
    }, [includedColumns, formatNumber])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//


    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            type: SEARCH_TYPE.NUMERIC,
            placeholder: t("backoffice.bets.betId"),
            numeric: true,
            maxLength: 12,
            loadFn: () => getPlayerBets(searchParams.id),
            onSearch: handleSearchChange,
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.players.bets"),
            columns: columnsForExport,
            url: `${ApiUrls.EXPORT_PLAYER_BETS}?id=${searchParams.id}`,
            filters: exportFilters,
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={(
                <TotalsComponent
                    totals={totals}
                    isLoading={isLoading}
                    closable={false}
                    totalType={REPORT_TOTAL_TYPE.PLAYER_BETS}
                />
            )}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={bets}
                loadFn={nextPage => getPlayerBets(searchParams.id, nextPage)}
                sorting={sorting}
                setSortingFn={setPlayerBetsSorting}
                total={total}
                enableReload={true}
                filters={filters}
                setFiltersFn={setPlayerBetsFilters}
            />
        </TabTableDashboardLayout>
    )
}

/** PlayerBetsComponent propTypes
 * PropTypes
*/
PlayerBetsComponent.propTypes = {
    /** Redux action to get player bets */
    getPlayerBets: PropTypes.func,
    /** Redux state property, represents the array of player bets  */
    bets: PropTypes.arrayOf(onlineBetType),
    /** Redux state property, represents the array of player bets totals */
    totals: betsTotalsType,
    /** Redux state property, player bets total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading player bets */
    isLoading: PropTypes.bool,
    /** Redux action to set player bets sorting details */
    setPlayerBetsSorting: PropTypes.func,
    /** Redux action to set player bets filters details */
    setPlayerBetsFilters: PropTypes.func,
    /** Redux state property, player bets sorting details */
    sorting: sortingType,
    /** Redux state property, player bets filters */
    filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    getPlayerBets: (id, nextPage) => {
        dispatch(getPlayerBets(id, nextPage))
    },

    setPlayerBetsFilters: (sorting, keepPage) => {
        dispatch(setPlayerBetsFilters(sorting, keepPage));
    },

    setPlayerBetsSorting: filters => {
        dispatch(setPlayerBetsSorting(filters));
    }
});

const mapStateToProps = state => {
    return {
        isLoading: state.players.isLoading,
        bets: state.players.edit.bets.bets,
        total: state.players.edit.bets.total,
        sorting: state.players.edit.bets.sorting,
        filters: state.players.edit.bets.filters,
        totals: state.players.edit.bets.totals,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerBetsComponent);
