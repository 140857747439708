import React from "react";

import UsersComponent from "pages/userManagement/users/users.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const UsersRoute = () => {
    return <UsersComponent />;
};

export default withPermission(
    withAuth(UsersRoute),
    { resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
