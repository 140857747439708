import React from 'react';
import PropTypes from 'prop-types';

import SuperAgentTransferForm from './superAgent';
import AgentTransferForm from './agent';
import BetshopTransferForm from './betshop';
import PlayerTransferForm from './player';
import TransactionTransferForm from './transaction';

import { TRANSFER_ENTITY_TYPE } from '../../../constants';

const StandartTransferForm = ({
    entityType
}) => {

    return entityType === TRANSFER_ENTITY_TYPE.AGENT ? (
        <AgentTransferForm />
    ) : entityType === TRANSFER_ENTITY_TYPE.BETSHOP ? (
        <BetshopTransferForm />
    ) : entityType === TRANSFER_ENTITY_TYPE.PLAYER ? (
        <PlayerTransferForm />
    ) : entityType === TRANSFER_ENTITY_TYPE.SUPER_AGENT ? (
        <SuperAgentTransferForm />
    ) : entityType === TRANSFER_ENTITY_TYPE.TRANSACTION ? (
        <TransactionTransferForm />
    ) : null
}   

/** StandartTransferForm propTypes
    * PropTypes
*/
StandartTransferForm.propTypes = {
    entityType: PropTypes.oneOf(Object.values(TRANSFER_ENTITY_TYPE))
}

export default StandartTransferForm;