import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setBetshopsActionBefore, setBetshopsActionFinished, setBetshopsSaveActionBefore, setBetshopsSaveActionFinished } from './betshops.action';

import { 
    SET_BETSHOP_GENERAL_INFO,
    SET_BETSHOP_TRANSLATION 
} from "../../../../actionTypes";

const setBetshopGeneralInfo = info => ({
    type: SET_BETSHOP_GENERAL_INFO,
    payload: { info }
})

export const getBetshopGeneralInfo = id => {
    return dispatch => {
        dispatch(setBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOP_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setBetshopGeneralInfo(info))
            dispatch(setBetshopsActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsActionFinished());
        })
    }
}

export const saveBetshopGeneralInfo = betshop => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_BETSHOP_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...betshop
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBetshopGeneralInfo(data.value));
            } 
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}

const setBetshopTranslation = ( translation, key) => ({
    type: SET_BETSHOP_TRANSLATION,
    payload: { translation, key }
})

export const saveBetshopTranslation = (translation, key) => {
    return dispatch => {
        dispatch(setBetshopsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_BETSHOP_TRANSLATION,
            method: Methods.POST,
            data: {
                ...translation
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setBetshopTranslation(data.value, key));
            } 
            dispatch(setBetshopsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setBetshopsSaveActionFinished());
        })
    }
}
