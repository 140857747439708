import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Spin, Button, } from 'antd';

import {
	getSMSProjectProvider,
	getSMSProviders,
	saveSMSProjectProvider,
	sendTestSMS,
	setSMSProjectProvider
} from 'store/actions/dashboard/projects/smsProviders.action';

import SubTabFormDashboardLayout from 'components/layouts/tab/subtab/form';
import Input from 'components/common/input';
import Select from "components/common/select";
import NumericInput from 'components/common/numericInput';


import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';

const TWILIO = 3

const SMS = ({
	configs,
	onTabChange,
	getSMSProviders,
	getSMSProjectProvider,
	isSaving,
	isLoading,
	saveSMSProjectProvider,
	setSMSProjectProvider,
	sendTestSMS,
}) => {
	const { t } = useTranslation();

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState(TWILIO);

	const [formInstance] = Form.useForm();
	const { setFieldsValue, validateFields, getFieldValue, getFieldsValue } = formInstance;

	const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_REGULATIONS, action: PERMISSION_ACTION.MODIFY });

	const handleForm = () => {
		validateFields()
			.then(data => {
				const { param1, param2, param3, provider, sender } = data;

				const onSuccess = () => {
					setIsFormTouched(false);
				}

				saveSMSProjectProvider(
					{
						param1,
						param2,
						param3,
						provider,
						sender
					},
					onSuccess
				)
			}).catch(err => {
				console.log(err)
			})
	}

	const handleSendTestButtonClick = () => {
		const recipient = getFieldValue("testNumber")
		sendTestSMS(recipient);
	}

	const handleFormValuesChange = (formValues) => {
		const formValuesClone = { ...formValues };
		delete formValuesClone.testNumber;

		setIsFormTouched(
			isFormChanged(formValuesClone, { ...configs.projectProvider })
		)
	}

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched])

	useEffect(() => {
		(async () => {
			await getSMSProviders();
			getSMSProjectProvider();
		})()
	}, [])

	useEffect(() => {
		if (configs.projectProvider) {
			const { param1, param2, param3, provider, sender } = configs.projectProvider;
			setFieldsValue({
				param1,
				param2,
				param3,
				provider,
				sender,
			})

			return;
		}

		if (configs.smsProviders.length !== 0) {
			setFieldsValue({ provider: TWILIO })
		}
	}, [configs])

	useEffect(() => {
		const formValues = getFieldsValue();
		handleFormValuesChange(formValues);
	}, [selectedProvider])

	useEffect(() => {
		() => {
			setSMSProjectProvider(null)
		}
	}, [])

	return (
		<SubTabFormDashboardLayout
			buttons={
				[
					{
						type: "primary",
						onClick: handleForm,
						text: t("backoffice.common.save"),
						enabled: true,
						loading: isSaving,
						disabled: !isFormTouched
					}
				]
			}
		>
			<h4 className='rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-big'>
				{t("backoffice.projects.providerConfigs")}
			</h4>

			<Spin spinning={isLoading} wrapperClassName="rt--form-spin">
				<Form
					colon={false}
					form={formInstance}
					requiredMark={false}
					layout="vertical"
					initialValues={{
						provider: null,
						sender: "",
						testNumber: null,
					}}
					onValuesChange={(changedFieldValues, formValues) => {
						const changedField = Object.keys(changedFieldValues)[0];

						if (changedField === "provider" || changedField === "testNumber") {
							return;
						}

						handleFormValuesChange(formValues)
					}}
				>
					<Row>
						<Col xs={24} sm={24} xl={12}>
							<Row gutter={[16, 0]}>
								<Col xs={24} sm={12} xl={12}>
									<Form.Item
										label={`${t('backoffice.projects.providerName')} *`}
										name="provider"
										rules={[
											{ required: true, message: t('backoffice.validation.fieldRequired') },
										]}
										className={'rt--general-form-item' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
										data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.providerName')}`}
									>
										<Select
											options={configs.smsProviders.map(item => {
												return {
													value: item.provider,
													text: item.name
												}
											})}
											disabled={!hasModifyPermission}
											onChange={(selectedValue) => {
												if (configs.projectProvider) {
													if (selectedValue !== configs.projectProvider.provider) {
														setFieldsValue({
															param1: null,
															param2: null,
															param3: null,
															sender: "",
														})
													} else {
														const { param1, param2, param3, provider, sender } = configs.projectProvider;
														setFieldsValue({
															param1,
															param2,
															param3,
															provider,
															sender,
														})
													}
												}
												setSelectedProvider(selectedValue)
											}}
											placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.providerType')}`}
											getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
										/>
									</Form.Item>
								</Col>

								{configs.smsProviders.length !== 0 && (
									configs.smsProviders.map(providerData => {

										if (providerData.provider !== selectedProvider) {
											return <Fragment key={providerData.provider}></Fragment>
										}

										const providerParametersEntry = Object.entries(providerData.providerParameters);

										return (
											<Fragment key={providerData.provider}>
												{providerParametersEntry.map(([fieldName, fieldLabel]) => {
													return (
														<Col xs={24} sm={12} xl={12} key={fieldName}>
															<Form.Item
																label={`${t(`backoffice.projects.${fieldLabel}`)} *`}
																name={fieldName}
																className={'rt--general-form-item rt--flex-equal' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
																data-placeholder={t(`backoffice.projects.${fieldLabel}`)}
																rules={[
																	{ required: true, message: t('backoffice.validation.fieldRequired') },
																]}
															>
																<Input
																	placeholder={t(`backoffice.projects.${fieldLabel}`)}
																	disableFormatting
																	maxLength={50}
																	disabled={!hasModifyPermission}
																/>
															</Form.Item>
														</Col>
													)
												})}
											</Fragment>
										)
									})
								)}

								<Col xs={24} sm={12} xl={12}>
									<Form.Item
										label={`${t('backoffice.projects.sender')} *`}
										name="sender"
										className={'rt--general-form-item rt--flex-equal' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
										data-placeholder={t('backoffice.projects.sender')}
										rules={[
											{ required: true, message: t('backoffice.validation.fieldRequired') },
										]}
									>
										<Input
											placeholder={t('backoffice.projects.sender')}
											disableFormatting
											disabled={!hasModifyPermission}
										/>
									</Form.Item>
								</Col>

								<Col xs={24} sm={12} xl={12}>
									<div className='rt--flex rt--justify-between rt--align-center'>
										<Form.Item
											label={t('backoffice.projects.testNumber')}
											name="testNumber"
											className={'rt--general-form-item rt--flex-equal' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
											data-placeholder={t('backoffice.projects.testNumber')}
										>
											<NumericInput
												placeholder={t('backoffice.projects.testNumber')}
												disableFormatting={true}
												isMobileNumber={true}
												disabled={!hasModifyPermission}
											/>
										</Form.Item>
										<Form.Item
											label={" "}
											className={'rt--general-form-item'}
										>
											<Button
												type="primary"
												htmlType="button"
												className={"rt--button rt--button-main rt--ml-8 rt--mt-1" + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
												disabled={!hasModifyPermission}
												onClick={() => handleSendTestButtonClick()}
											>
												{t("backoffice.projects.sendTestSMS")}
											</Button>
										</Form.Item>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Spin>

		</SubTabFormDashboardLayout>
	)
}

SMS.propTypes = {
	/** Redux state property, is true when project configs is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when project configs is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the a project configs of current editing project  */
	configs: PropTypes.object,
	/** Redux action to get sms providers */
	getSMSProviders: PropTypes.func,
	/** Redux action to get sms provider */
	getSMSProjectProvider: PropTypes.func,
	/** Redux action to save sms provider */
	saveSMSProjectProvider: PropTypes.func,
	/** Redux action to set sms provider */
	setSMSProjectProvider: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
	{
		getSMSProviders: () => {
			dispatch(getSMSProviders())
		},
		getSMSProjectProvider: () => {
			dispatch(getSMSProjectProvider())
		},
		saveSMSProjectProvider: (data, onSuccess) => {
			dispatch(saveSMSProjectProvider(data, onSuccess))
		},
		setSMSProjectProvider: (data) => {
			dispatch(setSMSProjectProvider(data))
		},
		sendTestSMS: (recipient) => {
			dispatch(sendTestSMS(recipient))
		},
	}
)

const mapStateToProps = state => {
	return {
		configs: state.projects.edit.configs.smsConfigs,
		isSaving: state.projects.isSaving,
		isLoading: state.projects.isLoading
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SMS);