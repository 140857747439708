import React, { Fragment } from "react";

import { SEARCH_TYPE } from "./constants";

import AutoCompleteSearch from "./autocomplete";
import InputSearch from "./input";

import searchType from 'types/components/search.type';

/** Search Component */
const Search = ({
    type = SEARCH_TYPE.AUTOCOMPLETE,
    loadFn,
    placeholder,
    onSearch,
    onChange,
    maxLength,
    autoSuggestion,
    value: controledValue,
    inputRef,
    isInForm=false,
    openWithModalInMobile=false,
    className,
}) => {
    return type === SEARCH_TYPE.AUTOCOMPLETE ? (
        <AutoCompleteSearch
            ref={inputRef}
            className={className}
            loadFn={loadFn}
            onSearch={onSearch}
            onChange={onChange}
            placeholder={placeholder}
            autoSuggestion={autoSuggestion}
            isInForm={isInForm}
            openWithModalInMobile={openWithModalInMobile}
        />
    ) : type === SEARCH_TYPE.INPUT || type === SEARCH_TYPE.NUMERIC ? (
        <InputSearch
            loadFn={loadFn}
            onSearch={onSearch}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
            isNumeric={ type === SEARCH_TYPE.NUMERIC }
            value={controledValue}
            inputRef={inputRef}
        />
    ) : <Fragment />
}

/** Search propTypes
    * PropTypes
*/
Search.propTypes = searchType;

export default Search;

