import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import Tabs from "components/common/tabs";

import GeneralInfoComponent from '../edit/sections/general';
import SecuritySettingsComponent from '../edit/sections/securitySettings';
import LanguagesComponent from '../edit/sections/languages';

import Paths from 'constants/path.constants';

import { changeProject } from "store/actions/dashboard/profile/userInfo.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import companyType from "types/company/company.type";

/** Company Edit Page Component */
const CompanyEditComponent = ({
    changeProject,
    allCompanies,
    globalCompanyId
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    /** Set current editing company id as global company id */
    useEffect(() => {
        if (globalCompanyId !== searchParams.id) {
            setTimeout(() => {
                let projectId = undefined;
                const c = allCompanies.find(c => c.id === searchParams.id);
                if (c && c.projects && c.projects[0]) {
                    projectId = c.projects[0].id;
                }
                changeProject(searchParams.id, projectId);
            }, 0)
        }
    }, [])

    const items = [
        {
            title: t("backoffice.companies.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title:t("backoffice.companies.securitySettings"),
            permissions: [{ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.VIEW }],
            component: <SecuritySettingsComponent />
        },
        {
            title: t("backoffice.companies.languages"),
            permissions: [{ resource: PERMISSION_RESOURCE.COMPANY_LANGUAGES, action: PERMISSION_ACTION.VIEW }],
            component: <LanguagesComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.companies'), path: Paths.COMPANIES }}
        />
    )
}

/** CompanyEditComponent propTypes
    * PropTypes
*/
CompanyEditComponent.propTypes = {
    /** Redux action to change global company/project id */
    changeProject: PropTypes.func,
    /** Redux state property, represents the array of all companies  */
    allCompanies: PropTypes.arrayOf(companyType),
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
}

const mapStateToProps = state => {
    return {
        allCompanies: state.profile.userInfo.companies,
        globalCompanyId: state.common.globalCompanyId,
    }
}

const mapDispatchToProps = dispatch => (
    {
        changeProject: (companyId, projectId) => {
            dispatch(changeProject(companyId, projectId));
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditComponent);