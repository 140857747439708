import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import dateService from "utils/date";

import { setPlayersActionBefore, setPlayersActionFinished } from './players.action';

import { 
    SET_PLAYER_BETS, 
    SET_PLAYER_BETS_SORTING, 
    SET_PLAYER_BETS_FILTERS 
} from "../../../actionTypes";



const setPlayerBets = (bets, add) => ({
    type: SET_PLAYER_BETS,
    payload: { bets, add },
});

export const setPlayerBetsSorting = sorting => ({
    type: SET_PLAYER_BETS_SORTING,
    payload: { sorting },
});

export const setPlayerBetsFilters = (filters, keepPage) => ({
    type: SET_PLAYER_BETS_FILTERS,
    payload: { filters, keepPage },
});


export const getPlayerBets = ( id, nextPage) => {
    return (dispatch, getState) => {
        
        const bets = getState().players.edit.bets;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? bets.sorting.page + 1 : bets.sorting.page;
        
        let params = {
            ...bets.sorting,
            page: page
        };

        const filters = { ...bets.filters };
        delete filters.day;
        delete filters.time;

        let from = dateService.mergeDateAndTime(bets.filters.day, bets.filters.time[0]);
        let to = dateService.mergeDateAndTime(bets.filters.day, bets.filters.time[1]);

        from = dateService.toISOString(from);
        to = dateService.toISOString(to);

        params = {
            ...params,
            ...filters,
            from,
            to
        }

        if(page > 1 && bets.total <= (page - 1 ) * bets.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && bets.total === 0){
            return Promise.resolve();
        }

        params.id = id;
        
        dispatch(setPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_BETS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: playerBets } }) => {
                dispatch(setPlayerBets(playerBets, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setPlayerBetsSorting({ ...bets.sorting, page: page }));
                dispatch(setPlayersActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayersActionFinished());
            });
    }
}