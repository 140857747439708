import React, { Fragment } from "react"
import PropTypes from "prop-types";

import { Button } from "antd";

import Select from "components/common/select";

import { isMobile } from "utils/common";

import buttonType from 'types/components/button.type';

const PLACEMENT = {
    RIGHT: "right",
    LEFT: "left"
}

/** Sub Tab Form dashboard layout */
const SubTabFormDashboardLayout = ({
    children,
    buttons = [],
    actions,
    header,
    dropdown
}) => {
    const isMobileDevice = isMobile();
    const haveEnabledButtons = buttons.some(buttonProps => buttonProps.enabled);
    const enabledRightPlacedButtons = buttons.filter(
        buttonProps => buttonProps.placement !== PLACEMENT.LEFT && buttonProps.enabled
    );
    const enabledLeftPlacedButtons = buttons.filter(
        buttonProps => buttonProps.placement === PLACEMENT.LEFT && buttonProps.enabled
    );
    const showMobileHeaderDropdown = isMobile() && dropdown;
    const showHeaderButton = header && header.button && header.button.enabled;

    /** Function to Render dropdown
         * @function
         * @returns {JSX}
         * @memberOf SubTabFormDashboardLayout
    */
    const renderDropdown = () => (
        <Select
            options={
                dropdown.items.map(item => (
                    { value: item.key, text: item.value}
                ))
            }
            placeholder={dropdown.placeholder}
            value={dropdown.value}
            onChange={dropdown.onChange}
            className={"rt--subtab-form-layout-actions-dropdown" + (dropdown.small ? " rt--subtab-form-layout-actions-dropdown-small" : "")}
            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
        />
    )

    return (
        <div className="rt--subtab-form-layout">
            <div className="rt--subtab-form-layout-inner rt--flex rt--flex-col">
                <div className="rt--subtab-form-layout-content rt--flex rt--flex-col rt--flex-equal">
                    <div className="rt--subtab-form-layout-content-content rt--flex rt--flex-col rt--flex-equal">
                        {
                            (showHeaderButton || showMobileHeaderDropdown) && (
                                <div
                                    className={
                                        "rt--subtab-form-layout-content-content-header rt--align-center rt--flex rt--jsutify-brtween " +
                                        (
                                            !isMobile()
                                                ? "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16"
                                                : "rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12"
                                        ) +
                                        ((showMobileHeaderDropdown && !showHeaderButton) ? " rt--justify-end" : "")
                                    }
                                >
                                    {
                                        showHeaderButton && (
                                            <Button
                                                icon={<i className={header.button.icon + " rt--font-bigest"}></i>}
                                                type={header.button.type}
                                                className={
                                                    "rt--button " +
                                                    (header.button.className ? header.button.className : "") +
                                                    (header.button.type === "primary" ? "rt--button-main" : "")
                                                }
                                                onClick={header.button.onClick}
                                                disabled={!!header.button.disabled}
                                            >
                                                {
                                                    header.button.text
                                                }
                                            </Button>
                                        )
                                    }
                                    {
                                        isMobile() && dropdown
                                            ? <div className="rt--ml-8">{renderDropdown()}</div>
                                            : <Fragment />
                                    }
                                </div>
                            )
                        }
                        <div className={"rt--subtab-form-layout-content-content-form rt--flex-equal " + (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16" : "rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24")}>
                            {children}
                        </div>
                    </div>

                </div>
                {
                    ( actions || dropdown ) && (
                        <div className="rt--subtab-form-layout-actions">
                            {
                                actions ? actions : <Fragment />
                            }
                            {
                                dropdown && !isMobile() ? renderDropdown() : <Fragment />
                            }

                        </div>
                    )
                }

                {
                    haveEnabledButtons && (
                        <div
                            className={
                                ("rt--subtab-form-layout-footer") + (isMobileDevice ? " rt--flex" : " rt--flex rt--align-center") +
                                (isMobileDevice ? "rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8" : "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16") +
                                ((!isMobileDevice && enabledLeftPlacedButtons.length > 0) ? " rt--justify-between" : " rt--justify-end")
                            }
                        >
                            {
                                (!isMobileDevice && enabledLeftPlacedButtons.length > 0) && (
                                    <div className="rt--flex rt--align-center">
                                        {
                                            enabledLeftPlacedButtons.map(buttonProps => {
                                                const { type, className = "rt--button-primary", onClick, text, disabled = false, htmlType = "submit", loading } = buttonProps;
                                                return (
                                                    <Button
                                                        type={type}
                                                        className={"rt--button " + (className)}
                                                        onClick={onClick}
                                                        key={text}
                                                        disabled={disabled}
                                                        htmlType={htmlType}
                                                        loading={loading}
                                                    >
                                                        {
                                                            text
                                                        }

                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }

                            {
                                enabledRightPlacedButtons.length > 0 && (
                                    <div
                                        className={
                                            "rt--flex rt--align-center" +
                                            (isMobileDevice && enabledRightPlacedButtons.length > 1 ? " rt--justify-between" : " rt--justify-end")
                                        }
                                    >
                                        {
                                            enabledRightPlacedButtons.map(buttonProps => {
                                                const { type, className = "", onClick, text, disabled = false, htmlType = "submit", loading } = buttonProps;

                                                return (
                                                    <Button
                                                        type={type}
                                                        className={"rt--button rt--button-primary" + (className)}
                                                        onClick={onClick}
                                                        key={text}
                                                        disabled={disabled}
                                                        htmlType={htmlType}
                                                        loading={loading}
                                                    >
                                                        {
                                                            text
                                                        }

                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }

            </div>
        </div>
    )
}

/** SubTabFormDashboardLayout propTypes
 * PropTypes
*/
SubTabFormDashboardLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node,
    /** Buttons */
    buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
    /** Actions */
    actions: PropTypes.node,
    /** Header */
    header: PropTypes.shape({
        /** Header button */
        button: PropTypes.shape(buttonType)
    }),
    /** Dropdown options */
    dropdown: PropTypes.object,
}

export default SubTabFormDashboardLayout;
