import axios from "axios";

import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const getRootNodes = (agentId, playerId) => (
    axios({
        url: ApiUrls.GET_NETWORK_ROOT_NODES,
        method: Methods.GET,
        params: { agentId, playerId, includeBetShops: true, includePlayers: true },
    })
)
