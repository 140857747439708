import React from 'react';
import PropTypes from "prop-types";

import useDate from 'hooks/useDate';

import { NOTIFICATION_STATE_ENUM } from "constants/notification.constants";
import { USER_STATE } from "constants/user.constants";

import { getUser } from "utils/auth";
import { isString } from "utils/javaScriptTypes";
import { getNotificationContent } from "utils/notifications";

import notificationType from "types/notification/notification.type";

const NotificationItem = ({notification, onClick, onDelete}) => {

    const { dateService } = useDate();

    const notificationContent = getNotificationContent(notification);
    const showDeleteButton = getUser()?.userState === USER_STATE.ACTIVE;
    const isUnread = notification.state === NOTIFICATION_STATE_ENUM.UNREAD;

    return (
        <div
            className={"rt--notifications-item rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8 " + (isUnread ? " rt--notifications-item-unseen" : "")}
            key={notification.id}
            onClick={() => onClick(notification)}
        >
            <div
                className="rt--notifications-item-inner-head rt--mb-8 rt--flex rt--align-center rt--justify-between">
                <div className="rt--notifications-item-title rt--flex rt--align-center">
                                <span className='rt--title rt--font-small rt--font-bold'>
                                    {notificationContent.title}
                                </span>
                    {
                        isUnread && (
                            <div className="rt--notifications-item-mark rt--ml-4"/>
                        )
                    }
                </div>
                {
                    showDeleteButton && (
                        <div
                            className="rt--notifications-item-close rt--flex rt--align-center rt--justify-center"
                            onClick={e => onDelete(e, notification.id)}
                        >
                            <i className="icon-cancel-outline rt--font-biger"/>
                        </div>
                    )
                }
            </div>

            <span
                className="rt--notifications-item-desc rt--title rt--font-small rt--font-regular"
                title={isString(notificationContent.description) ? notificationContent.description : ""}
            >
                {notificationContent.description}
            </span>

            <div className="rt--notifications-item-time rt--font-small rt--font-bold rt--mt-8">
                { dateService.format(notification.time) }
            </div>
        </div>
    );
};

NotificationItem.propTypes = {
    notification: notificationType,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
}

export default NotificationItem;
