export const tableColumns = [
    {
        title: "backoffice.currencies.code",
        dataIndex: "currency",
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title:"backoffice.currencies.addedAt",
        dataIndex: "createdAt",
        isDateTime: true,
        mobileLevel: 2,
    },
    {
        title:"backoffice.currencies.addedBy",
        dataIndex: "createdBy",
        mobileLevel: 3,
    }
];