import { useEffect, useState } from "react";

import { getOperationalLineChartData } from "../api";

import { isMobile } from "utils/common";

import { OPERATIONAL_DASHBOARD_FILTER_NAMES } from "../constants";

const useOperationalLineChart = (filters, entityType, updateLastUpdatedDate) => {
    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: [],
    })

    useEffect(() => {
        if (!filters.currencyCode) {
            return;
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getOperationalLineChartData({
            filterType: filters[OPERATIONAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE],
            timePeriod: filters[OPERATIONAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD],
            entityType: entityType,
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;

                updateLastUpdatedDate(payload.lastUpdateAt)
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        chartData: payload.data,
                        isLoading: false,
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [filters, entityType, updateLastUpdatedDate]);

    return chartState;
}

export default useOperationalLineChart;
