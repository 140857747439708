import React from "react";

import { useTranslation } from "react-i18next";

import Tabs from "components/common/tabs";

import PaymentHistory from "./paymentHistory";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { WIDGET_TRANSACTION_TYPE } from "constants/project.constants";

/** Payment History Page Component */
const PaymentHistoryComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.payments.deposit"),
            permissions: [{ resource: PERMISSION_RESOURCE.PAYMENT_DEPOSIT_TRANSACTION_HISTORY, action: PERMISSION_ACTION.VIEW }],
            component: <PaymentHistory type={WIDGET_TRANSACTION_TYPE.DEPOSIT} />
        },
        {
            title: t("backoffice.payments.withdrawal"),
            permissions: [{ resource: PERMISSION_RESOURCE.PAYMENT_WITHDRAW_TRANSACTION_HISTORY, action: PERMISSION_ACTION.VIEW }],
            component: <PaymentHistory type={WIDGET_TRANSACTION_TYPE.WITHDRAWAL} />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.paymentHistory') }}
        />
    )
};

export default PaymentHistoryComponent;
