import PropTypes from 'prop-types';

import { NOTIFICATION_STATE_ENUM, NOTIFICATION_TYPES_ENUM } from "constants/notification.constants";

export default PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    state: PropTypes.oneOf(Object.values(NOTIFICATION_STATE_ENUM)),
    type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES_ENUM)),
    time: PropTypes.string,
    data: PropTypes.string
})