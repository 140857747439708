import React from "react";

import DashboardComponent from "pages/dashboard.component";

import withAuth from "hocs/withAuth";

const DashboardRoute = () => {
    return <DashboardComponent />;
};

export default withAuth(DashboardRoute);
