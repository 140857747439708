import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Button, Switch, Spin } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";
import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';

import Tooltip from 'components/common/tooltip';
import TimePicker from "components/common/timepicker";

import useDate from 'hooks/useDate';
import { TRANSFER_ENTITY_TYPE, useTransfer } from 'components/common/transfer';

import { getBetshopLimitSettings, saveBetshopLimitSettings } from "store/actions/dashboard/retail/betshops/limitSettings.action";

import { isFranchisingMode, isMobile } from "utils/common";
import { getUser } from "utils/auth";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { BETSHOP_LIMIT_FREQUENCY } from 'constants/betshop.constants';
import { USER_ROLE } from 'constants/user.constants';
import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import limitSettingsType from "types/betshop/limitSettings.type";

import timezones from 'systemData/timezones';

/** Betshop Edit Page Limit Settings Tab Component */
const LimitSettingsComponent = ({
    getBetshopLimitSettings,
    saveBetshopLimitSettings,
    isSaving,
    isLoading,
    limitSettings,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const { dateService } = useDate();

    const { show: showTransfer} = useTransfer()

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [isLimitAutoAdjustmentEnabled, setIsLimitAutoAdjustmentEnabled] = useState(false);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...limitSettings,
            frequencySettings: {
                ...limitSettings.frequencySettings,
                at: dateService.getDate(`${limitSettings.frequencySettings.at.hour}:${limitSettings.frequencySettings.at.minute}`, 'HH:mm')
            }
        })
        setIsLimitAutoAdjustmentEnabled(limitSettings.enabled)
    }, [limitSettings])

    useEffect(() => {
        getBetshopLimitSettings(searchParams.id);
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf LimitSettingsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveBetshopLimitSettings(
                    {
                        id: searchParams.id,
                        enabled: data.enabled,
                        fixedAmount: data.fixedAmount,
                        threshold: data.threshold,
                        allowNotification: data.allowNotification,
                        frequencySettings: {
                            every: data.frequencySettings.every,
                            at: {
                                hour: data.frequencySettings.at.hour(),
                                minute: data.frequencySettings.at.minutes()
                            }
                        }

                    },
                    () => setIsFormTouched(false),
                );

            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        allowNotification: limitSettings.allowNotification,
                        threshold: limitSettings.threshold,
                        enabled: limitSettings.enabled,
                        fixedAmount: limitSettings.fixedAmount,
                        frequencySettings: {
                            ...limitSettings.frequencySettings,
                            at: dateService.getDate(`${limitSettings.frequencySettings.at.hour}:${limitSettings.frequencySettings.at.minute}`, "HH:mm")
                        }
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged(
                        {
                            ...formValues,
                            frequencySettings: {
                                ...formValues.frequencySettings,
                                at: {
                                    hour: formValues.frequencySettings.at.hour(),
                                    minute: formValues.frequencySettings.at.minutes()
                                }
                            }
                        }, { ...limitSettings }))
                    }
                >
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                        {
                            t('backoffice.betshops.frequency')
                        }
                    </h4>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name="enabled"
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch
                                        disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                        onChange={e => {
                                            setIsLimitAutoAdjustmentEnabled(e);
                                            setTimeout(() => {
                                                if (isFormTouched)
                                                    validateFields(['fixedAmount'])
                                            }, 0)
                                        }}
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.betshops.limitAutoAdjustment')}</label>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.betshops.every')}
                                name={["frequencySettings", "every"]}
                                className={!isLimitAutoAdjustmentEnabled ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={[
                                        { value: BETSHOP_LIMIT_FREQUENCY.DAY, text: t("backoffice.common.day") },
                                        { value: BETSHOP_LIMIT_FREQUENCY.MONDAY, text: t("backoffice.weekdays.monday") },
                                        { value: BETSHOP_LIMIT_FREQUENCY.TUESDAY, text: t("backoffice.weekdays.tuesday") },
                                        { value: BETSHOP_LIMIT_FREQUENCY.WEDNESDAY, text: t("backoffice.weekdays.wednesday") },
                                        { value: BETSHOP_LIMIT_FREQUENCY.THURSDAY, text: t("backoffice.weekdays.thursday") },
                                        { value: BETSHOP_LIMIT_FREQUENCY.FRIDAY, text: t("backoffice.weekdays.friday") },
                                        { value: BETSHOP_LIMIT_FREQUENCY.SATURDAY, text: t("backoffice.weekdays.saturday") },
                                        { value: BETSHOP_LIMIT_FREQUENCY.SUNDAY, text: t("backoffice.weekdays.sunday") }
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.betshops.every')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    disabled={!isLimitAutoAdjustmentEnabled || !hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.betshops.at')}
                                name={["frequencySettings", "at"]}
                                className={"rt--form-timepicker" + (!isLimitAutoAdjustmentEnabled ? " rt--form-item-disabled" : "")}
                            >
                                <TimePicker
                                    format={dateService.getFormat(true, false)}
                                    allowClear={false}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    disabled={!isLimitAutoAdjustmentEnabled}
                                    className='rt--timepicker'
                                    popupClassName='rt--timepicker-dropdown'
                                    placement="bottomRight"
                                    showNow={false}
                                    inputReadOnly={true}
                                    open={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? false : undefined}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.betshops.timezone')}
                                name={["frequencySettings", "timeZoneId"]}
                                className='rt--form-item-disabled'
                            >
                                <Select
                                    options={
                                        timezones.map(item => (
                                            { value: item.id, text: item.description }
                                        ))
                                    }
                                    placeholder={`${t('backoffice.common.select')} t('backoffice.betshops.timezone')}`}
                                    disabled={true}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.betshops.daylightSavingTime')}
                                name={["frequencySettings", "dst"]}
                                className='rt--form-item-disabled'
                            >
                                <Select
                                    options={[
                                        { value: "-1", text: "-1" },
                                        { value: "0", text: "0" },
                                        { value: "1", text: "1" }
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.betshops.daylightSavingTime')}`}
                                    disabled={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-big'>
                        {
                            t('backoffice.betshops.limitAmount')
                        }
                    </h4>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={(
                                    <div className='rt--flex rt--align-center'>
                                        <span className='rt--title rt--font-bold rt--font-normal rt--pr-4'>{t('backoffice.betshops.fixedLimitAmount')}</span>
                                        <Tooltip
                                            title={<span dangerouslySetInnerHTML={{ __html: t('backoffice.betshops.fixedLimitAmountTooltip') }}></span>}
                                            trigger={["hover"]}
                                            placement="bottomLeft"
                                            enableMobile={true}
                                        >
                                            <i className='icon-info rt--font-big' />
                                        </Tooltip>
                                    </div>
                                )}
                                name="fixedAmount"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {

                                            if (!value && value !== 0 && getFieldValue("enabled")) {
                                                return Promise.reject(t('backoffice.validation.fieldRequired'))
                                            }
                                            if (Number(value) < 1 && getFieldValue("enabled")) {
                                                return Promise.reject(t('backoffice.validation.mustBeMore').replace("%X%", 1))
                                            }
                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                                validateFirst
                                className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.fixedLimitAmount')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.betshops.fixedLimitAmount')}`}
                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                />
                            </Form.Item>
                        </Col>
                        {
                            (
                                isFranchisingMode() &&
                                getUser()?.role === USER_ROLE.ADMIN &&
                                hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY })
                            ) && (
                                <Col xs={24} sm={12} xl={6}>

                                    <Form.Item
                                        label={isMobile() ? undefined : (
                                            <div className='rt--flex rt--align-center'>
                                                <span className='rt--title rt--font-bold rt--font-normal rt--pr-4'>&nbsp;</span>
                                            </div>
                                        )}
                                        className='rt--general-form-item'
                                    >
                                        <Button
                                            type="secondary"
                                            htmlType="button"
                                            className={"rt--button rt--button-secondary rt--button-icon" + (isMobile() ? " rt--button-full-width rt--button-thin" : "")}
                                            icon={<i className="icon-reload rt--font-bigest"></i>}
                                            onClick={() => {
                                                showTransfer({
                                                    entityType: TRANSFER_ENTITY_TYPE.BETSHOP,
                                                    userId: searchParams.id,
                                                    transactionType: WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_RESET
                                                })
                                            }}
                                        >
                                            <span>{t('backoffice.betshops.resetLimit')}</span>
                                        </Button>
                                    </Form.Item>

                                </Col>
                            )
                        }

                    </Row>
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-big'>
                        {
                            t('backoffice.betshops.limitNotification')
                        }
                    </h4>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name="allowNotification"
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch
                                        disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>
                                    {t('backoffice.betshops.allowLimitNotification')}
                                </label>
                            </div>
                        </Col>

                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => getFieldValue("allowNotification") && (
                                    <Form.Item
                                        label={`${t('backoffice.betshops.notificationThreshold')} *`}
                                        name="threshold"
                                        className={'rt--general-form-item'}
                                        dependencies={["allowNotification"]}
                                        rules={[
                                            { required: true, message: t('backoffice.validation.fieldRequired') },
                                            () => ({
                                                validator(_, value) {
                                                    if (Number(value) < 1) {
                                                        return Promise.reject(t('backoffice.validation.mustBeMore').replace("%X%", "1%"))
                                                    }

                                                    if (Number(value) > 99) {
                                                        return Promise.reject(t('backoffice.validation.mustBeLess').replace("%X%", "99%"))
                                                    }

                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                        validateFirst
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.betshops.enterThreshold')}`}
                                            disabled={!hasPermission({ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.MODIFY })}
                                        />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** LimitSettingsComponent propTypes
    * PropTypes
*/
LimitSettingsComponent.propTypes = {
    /** Redux state property, is true when limit settings is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when limit settings is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the limit settings of current editing betshop  */
    limitSettings: limitSettingsType,
    /** Redux action to save betshop limit settings */
    saveBetshopLimitSettings: PropTypes.func,
    /** Redux action to get betshop limit settings */
    getBetshopLimitSettings: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        saveBetshopLimitSettings: (data, onSuccess) => {
            dispatch(saveBetshopLimitSettings(data, onSuccess));
        },

        getBetshopLimitSettings: id => {
            dispatch(getBetshopLimitSettings(id))
        }
    }
)

const mapStateToProps = state => {
    return {
        limitSettings: state.betshops.edit.limitSettings,
        isSaving: state.betshops.isSaving,
        isLoading: state.betshops.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LimitSettingsComponent)