import React from 'react';

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';


export const getTableColumns = (additionalProps) => {
    const {
        formatAmount
    } = additionalProps;

    return [
        {
            title: "backoffice.betshops.id",
            dataIndex: "longId",
            copy: true,
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.betshops.name",
            dataIndex: "name",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 3,
            render: (value, record) => (
                Boolean(value)
                    ? record.isTesting ? `${value} (Test)` : value
                    : "-"
            ),
        },
        {
            title: "backoffice.betshops.status",
            dataIndex: "state",
            sorter: true,
            mobileLevel: 4,
            render: value => (
                <Status
                    status={value}
                    type={STATUS_TYPES.USER}
                />
            ),
        },
        {
            title: 'backoffice.betshops.code',
            dataIndex: 'code',
        },
        {
            title: "backoffice.betshops.belongsTo",
            dataIndex: "agentUserName",
            sorter: true,
            render: value => value || "-",
        },
        {
            title: "backoffice.betshops.currency",
            dataIndex: "currencyCode",
            mobileLevel: 5,
        },
        {
            title: "backoffice.common.balance",
            dataIndex: "balance",
            mobileLevel: 6,
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode),
        },
        {
            title: "backoffice.betshops.cashiersBalance",
            dataIndex: "cashiersBalance",
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode)
        },
        {
            title: "backoffice.common.limit",
            dataIndex: "limit",
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode)
        },
        {
            title: "backoffice.betshops.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.betshops.lastUpdatedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true,
        },
    ]
};
