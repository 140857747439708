import React from "react";

export const getTableColumns = ({ formatAmount }) => ([
    {
        title: "backoffice.reports.playerId",
        dataIndex: "playerId",
        copy: true,
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: "backoffice.reports.playerUserName",
        dataIndex: "playerUserName",
        mobileLevel: 2,
        alwaysVisible: true,
    },
    {
        title: "backoffice.reports.agentUserName",
        dataIndex: "agentUserName",
        mobileLevel: 3,
    },
    {
        title: "backoffice.reports.registrationDate",
        dataIndex: "registrationDate",
        mobileLevel: 4,
        isDateTime: true,
        alwaysVisible: true,
    },
    {
        title: "backoffice.reports.currency",
        dataIndex: "currency",
        mobileLevel: 5,
    },
    {
        title: "backoffice.reports.sportTurnover",
        dataIndex: "sportTurnover",
        mobileLevel: 6,
        render: (value, record) => formatAmount(value, record.currency)
    },
    {
        title: "backoffice.reports.sportGgr",
        dataIndex: "sportGGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.sportNgr",
        dataIndex: "sportNGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.casinoTurnover",
        dataIndex: "casinoTurnover",
        render: (value, record) => formatAmount(value, record.currency)
    },
    {
        title: "backoffice.reports.casinoGgr",
        dataIndex: "casinoGGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.casinoNgr",
        dataIndex: "casinoNGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.totalTurnover",
        dataIndex: "totalTurnover",
        render: (value, record) => formatAmount(value, record.currency)
    },
    {
        title: "backoffice.reports.totalGgr",
        dataIndex: "totalGGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
    {
        title: "backoffice.reports.totalNgr",
        dataIndex: "totalNGR",
        render: (value, record) => (
            <span className={value < 0 ? "rt--error-text" : ""}>
                {formatAmount(value, record.currency)}
            </span>
        ),
    },
])
