import PropTypes from 'prop-types';

export default PropTypes.shape({
    id: PropTypes.string,
    companyId: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    address: PropTypes.string,
    tel: PropTypes.string,
    email: PropTypes.string,
    ticketLanguageCode: PropTypes.string,
    isTesting: PropTypes.bool,
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    enabled: PropTypes.bool,
    balance: PropTypes.number,
    currencyCode: PropTypes.string,
    logoId: PropTypes.number,
    languageCode: PropTypes.string,
    dst: PropTypes.number,
})