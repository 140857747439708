import PropTypes from 'prop-types';

export default PropTypes.objectOf(PropTypes.shape({
    currencyCode: PropTypes.string,
    playerSignups: PropTypes.number,
    depositCount: PropTypes.number,
    withdrawCount: PropTypes.number,
    firstTimeDepositCount: PropTypes.number,
    withdrawAmount: PropTypes.number,
    depositAmount: PropTypes.number,
    netDepositAmount: PropTypes.number,
    turnover: PropTypes.number,
    won: PropTypes.number,
    ggr: PropTypes.number,
    ngr: PropTypes.number,
    otherCosts: PropTypes.number,
    directCommissions: PropTypes.number,
    networkCommissions: PropTypes.number,
}))