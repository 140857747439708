import { message } from 'antd'

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";
import { isResponseSuccess } from "utils/request";

import {
    SET_COMMISSION_CALCULATIONS_REPORT_ACTION_BEFORE,
    SET_COMMISSION_CALCULATIONS_REPORT_ACTION_FINISH,
    SET_COMMISSION_CALCULATIONS_REPORT,
    SET_COMMISSION_CALCULATIONS_REPORT_SORTING,
    SET_COMMISSION_CALCULATIONS_REPORT_FILTERS
} from "../../../actionTypes";

const setCommissionCalculationsReportActionBefore = () => ({
    type: SET_COMMISSION_CALCULATIONS_REPORT_ACTION_BEFORE,
});

const setCommissionCalculationsReportActionFinished = () => ({
    type: SET_COMMISSION_CALCULATIONS_REPORT_ACTION_FINISH,
});

const setCommissionCalculationsReport = (report, add) => ({
    type: SET_COMMISSION_CALCULATIONS_REPORT,
    payload: { report, add },
});

export const setCommissionCalculationsReportSorting = sorting => ({
    type: SET_COMMISSION_CALCULATIONS_REPORT_SORTING,
    payload: { sorting },
});

export const setCommissionCalculationsReportFilters = filters => ({
    type: SET_COMMISSION_CALCULATIONS_REPORT_FILTERS,
    payload: { filters },
});


export const getCommissionCalculationsReport = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().commissionCalculationsReport.sorting.page + 1 : getState().commissionCalculationsReport.sorting.page;
        
        let data = {
            ...getState().commissionCalculationsReport.sorting,
            ...getState().commissionCalculationsReport.filters,
            page: page
        };

        if(page > 1 && getState().commissionCalculationsReport.total <= (page - 1 ) * getState().commissionCalculationsReport.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().commissionCalculationsReport.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setCommissionCalculationsReportActionBefore());

        return axios({
            url: ApiUrls.GET_COMMISSION_CALCULATIONS_REPORT,
            method: Methods.GET,
            params: data,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setCommissionCalculationsReport(report, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCommissionCalculationsReportSorting({ ...getState().commissionCalculationsReport.sorting, page: page }));
                dispatch(setCommissionCalculationsReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setCommissionCalculationsReportActionFinished());
            });
    }
}

export const recalculateCommissionPlan = id => {
    
    return dispatch => {
        return axios({
            url: ApiUrls.RECALCULATE_COMMISSION_PLAN,
            method: Methods.POST,
            data: { id }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            } 
        })
        .catch((ex) => {
            console.log(ex)
        })
    }
}