import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Spin, Checkbox, Radio, Row, Col } from 'antd';

import {
    saveAgentPaymentSettings
} from 'store/actions/dashboard/agentSystem/agents/paymentsConfig.action'

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import { hasPermission } from 'utils/permissions';
import { binaryToFlags, hasValue } from 'utils/common';
import {
    constructFinaleData,
    constructFormData,
    doesUserMadeChanges
} from 'utils/payments';

import { PAYMENT_METHODS } from "constants/project.constants"
import { BANK_EPOS_VALUE, FIELD_NAMES } from 'constants/payments.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';

import agentPaymentConfigType from 'types/agent/agentPaymentConfig.type';
import userInfoType from 'types/profile/userInfo.type';

/** Agent Edit Page Payment config Tab Component */
const AgentPaymentConfigsComponent = ({
    saveAgentPaymentSettings,
    isSaving,
    isLoading,
    config,
    userInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_METHODS.STANDART);
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const {
        validateFields,
        setFieldsValue,
        getFieldValue,
        getFieldsValue,
    } = formInstance;

    const isStandardTypeSelected = selectedPaymentType === PAYMENT_METHODS.STANDART;

    const hasAgentPaymentModifyPermission = searchParams.id !== userInfo.id && (
        hasPermission({
            resource: PERMISSION_RESOURCE.AGENT_PAYMENT,
            action: PERMISSION_ACTION.MODIFY
        })
    )

    const handlePaymentTypeChange = ({ target: { value } }) => {
        setSelectedPaymentType(value);

        if (value === PAYMENT_METHODS.STANDART) {
            return;
        }

        const isEposOrBankSelected = getFieldValue(FIELD_NAMES.BANK) || getFieldValue(FIELD_NAMES.EPOS);

        if (isEposOrBankSelected) {
            return;
        }

        setFieldsValue({ [FIELD_NAMES.EPOS]: true });
    }

    const handleFormValuesChange = (changedFieldValue, fieldsValue) => {
        const isEposAndBankNotSelected = (
            !fieldsValue[FIELD_NAMES.BANK] &&
            !fieldsValue[FIELD_NAMES.EPOS]
        )

        if (isEposAndBankNotSelected) {
            const changedFieldName = Object.keys(changedFieldValue)[0];

            setFieldsValue({ [changedFieldName]: true });
            return;
        }

        setIsFormTouched(doesUserMadeChanges(
            { ...fieldsValue },
            selectedPaymentType,
            config,
        ))
    }

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                const requestBody = constructFinaleData(fieldsValue, selectedPaymentType);
                requestBody.id = searchParams.id;

                saveAgentPaymentSettings(requestBody);
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    const isOptionDisabled = type => {
        if(!hasAgentPaymentModifyPermission){
            return true;
        }

        const parentOptions = binaryToFlags(Object.values(PAYMENT_METHODS), config.allowedType);
        return !parentOptions.includes(type)
    }

    // Set form fields values, when data is loaded
    useEffect(() => {
        if (!hasValue(config)) {
            return;
        }

        const { formFieldsValue, selectedPaymentType } = constructFormData(config);

        setFieldsValue({
            ...formFieldsValue
        });
        setSelectedPaymentType(selectedPaymentType);
    }, [config])

    // Check whether the user made changes or not  
    useEffect(() => {
        if (!hasValue(config)) {
            return;
        }

        setIsFormTouched(doesUserMadeChanges(
            { ...getFieldsValue() },
            selectedPaymentType,
            config,
        ))
    }, [selectedPaymentType]);

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={[{
                type: "primary",
                text: t("backoffice.common.save"),
                enabled: hasAgentPaymentModifyPermission,
                loading: isSaving,
                disabled: !isFormTouched,
                onClick: handleForm,
            }]}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={handleFormValuesChange}
                    initialValues={{
                        [FIELD_NAMES.BANK]: false,
                        [FIELD_NAMES.EPOS]: true,
                    }}
                >
                    <Row>
                        <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24}>
                            <Radio.Group
                                onChange={handlePaymentTypeChange}
                                value={selectedPaymentType}
                                style={{ width: "100%" }}
                            >
                                <div className='rt--payment-section'>
                                    <Radio
                                        disabled={isOptionDisabled(PAYMENT_METHODS.STANDART)}
                                        value={PAYMENT_METHODS.STANDART}
                                    >
                                        {t("backoffice.payments.standart")}
                                    </Radio>
                                </div>
                                <div className='rt--payment-section'>
                                    <Radio
                                        value={BANK_EPOS_VALUE}
                                        disabled={
                                            isOptionDisabled(PAYMENT_METHODS.EPOS) &&
                                            isOptionDisabled(PAYMENT_METHODS.BANK_TRANSFER)
                                        }
                                    >
                                        {t("backoffice.payments.bankEpos")}
                                    </Radio>

                                    <div className='rt--payment-section-sub'>
                                        <Form.Item
                                            name={FIELD_NAMES.EPOS}
                                            className='rt--form-item-without-height rt--form-item-without-margin'
                                            valuePropName='checked'
                                        >
                                            <Checkbox
                                                disabled={
                                                    isStandardTypeSelected ||
                                                    isOptionDisabled(PAYMENT_METHODS.EPOS)
                                                }
                                            >
                                                {t("backoffice.payments.epos")}
                                            </Checkbox>
                                        </Form.Item>
                                    </div>

                                    <div className='rt--payment-section-sub'>
                                        <Form.Item
                                            name={FIELD_NAMES.BANK}
                                            className='rt--form-item-without-height rt--form-item-without-margin'
                                            valuePropName='checked'
                                        >
                                            <Checkbox
                                                value={PAYMENT_METHODS.BANK_TRANSFER}
                                                disabled={
                                                    isStandardTypeSelected ||
                                                    isOptionDisabled(PAYMENT_METHODS.BANK_TRANSFER)
                                                }
                                            >
                                                {t("backoffice.payments.bankTransfer")}
                                            </Checkbox>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** AgentPaymentConfigs propTypes
    * PropTypes
*/
AgentPaymentConfigsComponent.propTypes = {
    /** Redux action to save agent payment */
    saveAgentPaymentSettings: PropTypes.func,
    /** Redux state property, is true when agent payment is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when agent payment is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current editing agent payment config */
    config: agentPaymentConfigType,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        saveAgentPaymentSettings: data => {
            dispatch(saveAgentPaymentSettings(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.agents.isSaving,
        isLoading: state.agents.isLoading,
        config: state.agents.edit.payment.config,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentPaymentConfigsComponent)