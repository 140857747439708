import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GroupsComponent from './groups';
import PermissionsComponent from './permissions';

import { getUser } from 'utils/auth';

import { USER_ROLE, USER_TYPE } from 'constants/user.constants';
import {getHashValue} from "../../../../../../utils/common";

/** User Edit Page Permissions & Groups Tab Component */
const PermissionsAndGroupsComponent = ({
    tabId,
    permissionResource,
    userType,
    onTabChange,
}) => {
    const { t } = useTranslation();
    const isActive = tabId === getHashValue('tab');

    const items = [
        {
            title: t("backoffice.users.groups"),
            component: <GroupsComponent permissionResource={permissionResource} userType={userType}/>
        },
        {
            title: t("backoffice.users.permissions"),
            component: <PermissionsComponent permissionResource={permissionResource} />,
            disabled: getUser()?.role === USER_ROLE.ACCESS_MANAGER
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
        />
    )
}

/** PermissionsAndGroupsComponent propTypes
    * PropTypes
*/
PermissionsAndGroupsComponent.propTypes = {
    /** Tab identifier*/
    tabId: PropTypes.string,
    /** The permission resource */
    permissionResource: PropTypes.string,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** User type(agent/admin) */
    userType: PropTypes.oneOf([USER_TYPE.ADMIN, USER_TYPE.AGENT])
}


export default PermissionsAndGroupsComponent;
