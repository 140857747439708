import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Carousel, Col, Row } from 'antd';

import OperationalLineChart from 'pages/dashboard/components/operationalLineChart';
import PieChart from 'pages/dashboard/components/pieChart';
import TabTableDashboardLayout from 'components/layouts/tab/table';
import Filters from '../../filters';

import useFirstRender from 'hooks/useFirstRender';

import { isMobile } from 'utils/common';

import { ENTITY_TYPE, OPERATIONAL_DASHBOARD_FILTER_NAMES } from 'pages/dashboard/constants';
import { FILTER_TYPE } from 'constants/common.constants';

const DEFAULT_FILTER = {
    [OPERATIONAL_DASHBOARD_FILTER_NAMES.FILTER_TYPE]: FILTER_TYPE.NETWORK,
}

const selectGlobalProjectId = state => state.common.globalProjectId;

const General = ({
    hasRetail,
    updateLastUpdatedDate,
}) => {
    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER
    })
    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);

    const globalProjectId = useSelector(selectGlobalProjectId);

    const handleCarouselChange = (prevIndex, nextIndex) => {
        setCarouselActiveIndex(nextIndex);
    }

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters({ ...DEFAULT_FILTER })
        },
        dependencies: [globalProjectId, updateFilters]
    })

    return (
        !isMobile()
            ? (
                <TabTableDashboardLayout
                    header={{
                        content: (
                            <div className="rt--flex rt--align-center rt--justify-end rt--width-full">
                                <Filters hasRetail={hasRetail} filters={filters} updateFilters={updateFilters} showTimePeriodEntityType={false} />
                            </div>
                        )
                    }}
                    headerBorder={false}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <PieChart
                                entityType={ENTITY_TYPE.AGENT}
                                filters={filters}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        </Col>
                        <Col span={8}>
                            <PieChart
                                entityType={ENTITY_TYPE.PLAYER}
                                filters={filters}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        </Col>
                        <Col span={8}>
                            {hasRetail && (
                                <PieChart
                                    entityType={ENTITY_TYPE.BETSHOP}
                                    filters={filters}
                                    updateLastUpdatedDate={updateLastUpdatedDate}
                                />
                            )}
                        </Col>
                    </Row>
                    {!isMobile() && (
                        <div className='rt--operational-general rt--mt-24'>
                            <Row gutter={[16, 24]}>
                                <Col span={24}>
                                    <OperationalLineChart
                                        filters={filters}
                                        hasRetail={hasRetail}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </TabTableDashboardLayout>
            ) : (
                <TabTableDashboardLayout
                    header={{
                        filters: <Filters hasRetail={hasRetail} filters={filters} updateFilters={updateFilters} showTimePeriodEntityType={false} />
                    }}
                >
                    <div className='rt--financial-line-chart'>
                        <Carousel infinite={false} beforeChange={handleCarouselChange}>
                            <PieChart
                                entityType={ENTITY_TYPE.AGENT}
                                filters={filters}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                                isActive={carouselActiveIndex === 0}
                            />
                            <PieChart
                                entityType={ENTITY_TYPE.PLAYER}
                                filters={filters}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                                isActive={carouselActiveIndex === 1}
                            />
                            {hasRetail && (
                                <PieChart
                                    entityType={ENTITY_TYPE.BETSHOP}
                                    filters={filters}
                                    updateLastUpdatedDate={updateLastUpdatedDate}
                                    isActive={carouselActiveIndex === 2}
                                />
                            )}
                        </Carousel>
                    </div>
                </TabTableDashboardLayout>
            )
    )
}

General.propTypes = {
    filters: PropTypes.object,
    hasRetail: PropTypes.bool,
    updateLastUpdatedDate: PropTypes.func,
}

export default General;
