const FILTERS_SCOPES = {
    dataType: "leaderboard",
    currencyCode: "leaderboard",
    timePeriod: "leaderboard",
    betShopNameOrId: "leaderboard",
    filterType: "global"
}

const mapFiltersToScopes = (prevFilters, values) => {
    const res = {};

    Object.entries(values).forEach(([key, value]) => {
        const scope = FILTERS_SCOPES[key];

        if (!res[scope]) {
            res[scope] = {};
        }

        res[scope][key] = value;
    });

    return res;
}

export default mapFiltersToScopes;
