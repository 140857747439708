import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import axios from "axios";

import { isMobile } from "utils/common";

import {
    SET_PLAYER_PERFORMANCE_REPORT,
    SET_PLAYER_PERFORMANCE_REPORT_ACTION_BEFORE,
    SET_PLAYER_PERFORMANCE_REPORT_ACTION_FINISH,
    SET_PLAYER_PERFORMANCE_REPORT_FILTERS,
    SET_PLAYER_PERFORMANCE_REPORT_SORTING,
    SET_PLAYER_PERFORMANCE_REPORT_TOTALS,
    SET_PLAYER_PERFORMANCE_REPORT_TOTALS_BEFORE,
    SET_PLAYER_PERFORMANCE_REPORT_TOTALS_FINISH
} from "../../../actionTypes";

const setPlayerPerformanceReportActionBefore = () => ({
    type: SET_PLAYER_PERFORMANCE_REPORT_ACTION_BEFORE,
});

const setPlayerPerformanceReportActionFinished = () => ({
    type: SET_PLAYER_PERFORMANCE_REPORT_ACTION_FINISH,
});

const setPlayerPerformanceReport = (data, add) => ({
    type: SET_PLAYER_PERFORMANCE_REPORT,
    payload: { report: data.reports, total: data.total, add },
});

export const setPlayerPerformanceReportFilters = (filters, keepPage) => ({
    type: SET_PLAYER_PERFORMANCE_REPORT_FILTERS,
    payload: { filters, keepPage },
});

export const setPlayerPerformanceReportSorting = sorting => ({
    type: SET_PLAYER_PERFORMANCE_REPORT_SORTING,
    payload: { sorting },
});

export const getPlayerPerformanceReport = nextPage => {
    return (dispatch, getState) => {
        const state = getState()

        const page = nextPage === "first" ? 1 : nextPage === "next" ? state.playerPerformanceReport.sorting.page + 1 : state.playerPerformanceReport.sorting.page;
        let data = {
            ...state.playerPerformanceReport.sorting,
            ...state.playerPerformanceReport.filters,
            page: page
        };

        if (page > 1 && state.playerPerformanceReport.total <= (page - 1) * state.playerPerformanceReport.sorting.limit) {
            return Promise.resolve();
        }

        if (nextPage === "next" && state.playerPerformanceReport.total === 0) {
            return Promise.resolve();
        }

        dispatch(setPlayerPerformanceReportActionBefore());

        let currenciesStr = "";
        let currencies = state.playerPerformanceReport.filters.currencies;
        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }

        delete data.currencies;

        return axios({
            url: `${ApiUrls.GET_PLAYER_PERFORMANCE_REPORT}?${currenciesStr}`,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const reports = data.value?.item2;
                const total = data.value?.item1;

                dispatch(setPlayerPerformanceReport({ reports, total }, isMobile() && page !== 1));

                if (nextPage) {
                    dispatch(setPlayerPerformanceReportSorting({ ...getState().playerPerformanceReport.sorting, page: page }));
                }

                dispatch(setPlayerPerformanceReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setPlayerPerformanceReportActionFinished());
            });
    }
}

/** Totals */

const setPlayerPerformanceReportTotalsBefore = () => ({
    type: SET_PLAYER_PERFORMANCE_REPORT_TOTALS_BEFORE,
});

const setPlayerPerformanceReportTotalsFinished = () => ({
    type: SET_PLAYER_PERFORMANCE_REPORT_TOTALS_FINISH,
});

const setPlayerPerformanceReportTotals = totals => ({
    type: SET_PLAYER_PERFORMANCE_REPORT_TOTALS,
    payload: { totals },
});

export const getPlayerPerformanceReportTotals = () => {
    return (dispatch, getState) => {
        const state = getState();
        let data = {
            ...state.playerPerformanceReport.filters
        };

        let currenciesStr = "";
        let currencies = state.playerPerformanceReport.filters.currencies;
        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }

        delete data.currencies;

        dispatch(setPlayerPerformanceReportTotalsBefore());

        return axios({
            url: `${ApiUrls.GET_PLAYER_PERFORMANCE_REPORT_TOTALS}?${currenciesStr}`,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const totals = data.value;
                dispatch(setPlayerPerformanceReportTotals(totals));
                dispatch(setPlayerPerformanceReportTotalsFinished());
            })
            .catch((ex) => {
                dispatch(setPlayerPerformanceReportTotalsFinished());
            });
    }
}